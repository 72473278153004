"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleError = exports.handleFailure = exports.handleOnSuccessMutation = exports.handleOnSuccessQuery = exports.handleSuccess = void 0;

var _utils = require("../../utils");

const handleSuccess = messenger => response => response.error && handleFailure(messenger)(response);

exports.handleSuccess = handleSuccess;

const handleOnSuccessQuery = messenger => ({
  response,
  onSuccess,
  onFailure
}) => {
  if (!response) return;

  if (!response.error) {
    Boolean(onSuccess) && onSuccess({
      response
    });
  } else {
    handleFailure(messenger)({
      response
    });
    Boolean(onFailure) && onFailure({
      response
    });
  }
};

exports.handleOnSuccessQuery = handleOnSuccessQuery;

const handleOnSuccessMutation = messenger => ({
  response,
  message,
  onSuccess,
  onFailure
}) => {
  if (!response.error) {
    Boolean(message) && messenger(message);
    Boolean(onSuccess) && onSuccess({
      response
    });
  } else {
    handleFailure(messenger)({
      response
    });
    Boolean(onFailure) && onFailure({
      response
    });
  }
};

exports.handleOnSuccessMutation = handleOnSuccessMutation;

const handleFailure = messenger => ({
  response,
  onFailure
}) => {
  (0, _utils.logError)({
    response
  });
  messenger({
    type: 'warning',
    message: `Attenzione! ${response.message}`,
    severity: 'warning'
  });

  if (onFailure) {
    onFailure(response);
  }
};

exports.handleFailure = handleFailure;

const handleError = messenger => response => {
  (0, _utils.logError)(response);
  messenger({
    type: 'error',
    message: "Errore durante l'operazione",
    severity: 'error'
  });
};

exports.handleError = handleError;