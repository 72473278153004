"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useScadenzeConteggioReactQuery = exports.useRicercaScadenzePeriodo = exports.useScadenzeFilters = exports._ricercaScadenzePeriodoQuery = exports._ricercaScadenzePeriodoFilters = exports._ricercaScadenzePeriodoQueryRequestIDState = void 0;

var _recoil = require("recoil");

var _api = require("../../api");

var _moment = _interopRequireDefault(require("moment"));

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _utils = require("../../react/reactQuery/utils");

var _object = require("../../object.utils");

var _react = require("react");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

const historyEffect = ({
  setSelf,
  onSet
}) => {
  onSet((newValue, oldValue) => {
    const search = Object.keys(newValue).filter(k => newValue[k] != undefined || newValue[k] != null).map(key => `${key}=${newValue[key]}`).join("&"); // Con questo router non riusciamo a sincronizzare anche l'url
    // Per ora ci accontentiamo di tenere sincronizzato lo stato interno fino a quando non passeremo a ReactRouter

    window.$state.transitionTo('scadenzario', newValue, {
      location: true,
      inherit: true,
      relative: window.$state.$current,
      notify: false
    }); // history.push({
    //     pathname: '/scadenzario',
    //     search: search.length ? `?${search}` : ''
    // })
  });
};

const now = (0, _moment.default)();
const defaultState = {
  page: 1,
  limit: 50,
  dataInizio: now.format("x"),
  dataFine: now.add(30, 'days').format("x"),
  tipoScadenza: null
};

const _ricercaScadenzePeriodoQueryRequestIDState = (0, _recoil.atom)({
  key: '_ricercaScadenzePeriodoQueryRequestIDState',
  default: 0
});

exports._ricercaScadenzePeriodoQueryRequestIDState = _ricercaScadenzePeriodoQueryRequestIDState;

const _ricercaScadenzePeriodoFilters = (0, _recoil.atom)({
  key: '_ricercaScadenzePeriodoFilters',
  default: defaultState,
  effects_UNSTABLE: [historyEffect]
});

exports._ricercaScadenzePeriodoFilters = _ricercaScadenzePeriodoFilters;

const _ricercaScadenzePeriodoQuery = (0, _recoil.selector)({
  key: '_ricercaScadenzePeriodoAnagraficaQuery',
  get: function () {
    var _ref = _asyncToGenerator(function* ({
      get: _get
    }) {
      _get(_ricercaScadenzePeriodoQueryRequestIDState); // Add request ID as a dependency


      const {
        page,
        limit,
        dataInizio,
        dataFine,
        tipoScadenza
      } = _get(_ricercaScadenzePeriodoFilters);

      if (!dataInizio || !dataFine) return [];
      const response = yield (0, _api.ricercaScadenzePeriodo)({
        page,
        limit,
        dataInizio,
        dataFine,
        tipoScadenza
      });

      if (response.error) {
        throw response.error;
      }

      return response;
    });

    return function get(_x) {
      return _ref.apply(this, arguments);
    };
  }()
});

exports._ricercaScadenzePeriodoQuery = _ricercaScadenzePeriodoQuery;

const useScadenzeFilters = () => {
  const filters = (0, _recoil.useRecoilValue)(_ricercaScadenzePeriodoFilters);
  return [filters, {
    changeDataRange: (0, _recoil.useRecoilCallback)(({
      set
    }) => ({
      startDate,
      endDate
    }) => {
      set(_ricercaScadenzePeriodoFilters, filters => ({ ...filters,
        dataInizio: startDate,
        dataFine: endDate,
        page: defaultState.page,
        limit: defaultState.limit
      }));
    }),
    changeTipoScadenza: (0, _recoil.useRecoilCallback)(({
      set
    }) => tipoScadenza => {
      set(_ricercaScadenzePeriodoFilters, filters => ({ ...filters,
        tipoScadenza: tipoScadenza,
        page: defaultState.page,
        limit: defaultState.limit
      }));
    }),
    reset: (0, _recoil.useRecoilCallback)(({
      set
    }) => _ => {
      set(_ricercaScadenzePeriodoFilters, filters => ({ ...filters,
        ...defaultState
      }));
    })
  }];
};

exports.useScadenzeFilters = useScadenzeFilters;

const useRicercaScadenzePeriodo = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const [{
    page,
    limit,
    dataInizio,
    dataFine,
    tipoScadenza
  }, setFilters] = (0, _recoil.useRecoilState)(_ricercaScadenzePeriodoFilters);
  (0, _react.useEffect)(() => {
    window.$transitions.onExit({
      exiting: "navigation.scadenzario"
    }, (_transition, _state) => {
      setFilters(filters => ({ ...filters,
        ...defaultState
      }));
    });
  });
  const {
    data,
    isLoading,
    isFetching
  } = (0, _reactQuery.useQuery)([`ricercaScadenzePeriodo`, {
    page,
    limit,
    dataInizio,
    dataFine,
    tipoScadenza
  }], ({
    queryKey
  }) => {
    const {
      page,
      limit,
      dataInizio,
      dataFine,
      tipoScadenza
    } = queryKey[1];
    return (0, _api.ricercaScadenzePeriodo)({
      page,
      limit,
      dataInizio,
      dataFine,
      tipoScadenza
    });
  }, {
    staleTime: 1000 * 60 * 5,
    onSuccess: response => (0, _utils.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: response => response.error ? response : response === null || response === void 0 ? void 0 : response.resultsWrapper
  });
  return {
    scadenze: Boolean(data) ? data.values : [],
    count: Boolean(data) ? data.count : 0,
    isLoading: isLoading || isFetching,
    page,
    limit,
    changePage: newPage => setFilters(state => ({ ...state,
      page: newPage
    })),
    changeLimit: newLimit => setFilters(state => ({ ...state,
      page: 1,
      limit: newLimit
    })),
    refresh: () => queryClient.invalidateQueries(["ricercaScadenzePeriodo"])
  };
};

exports.useRicercaScadenzePeriodo = useRicercaScadenzePeriodo;

const useScadenzeConteggioReactQuery = ({
  periodo3090,
  onSuccess,
  onFailure
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isLoading,
    isFetching
  } = (0, _reactQuery.useQuery)([`scadenzeConteggio`, {
    periodo3090
  }], ({
    queryKey
  }) => (0, _api.scadenzeConteggio)({
    periodo3090: queryKey[1].periodo3090
  }), {
    staleTime: 1000 * 60 * 5,
    onSuccess: response => (0, _utils.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: res => (0, _object.getPathArrayOrEmptyArray)(["scadenzePerPeriodo"])(res)
  });
  return [Boolean(data) ? data : [], {
    isLoading: isLoading || isFetching
  }];
};

exports.useScadenzeConteggioReactQuery = useScadenzeConteggioReactQuery;