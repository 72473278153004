"use strict";

/**
 * Created by dan on 06/05/17.
 */
(function () {
  'use strict';

  angular.module('polcity.factories').factory('LoadingStatusBehaviour', ['$q', LoadingStatusBehaviourFactory]);
  /**
   * @ngdoc service
   * @name LoadingStatusBehaviour
   *
   * @description
   * Will extend the object with a boolean flag and two helper methods to toggle its value.
   * The two helper methods will be named `loadingPropName + 'StartLoading'` and `loadingPropName + 'StopLoading'`
   *
   *
   * @param $q
   * @returns {{extend: extend}}
   * @constructor
   */

  function LoadingStatusBehaviourFactory($q) {
    return {
      extend: extend
    };
    /**
     * @ngdoc method
     * @name LoadingStatusBehaviourFactory#extend
     *
     * @param {object} obj the object you want to attach the json to
     * @param {string} loadingPropName the name of the property you will read in your object methods, defaults to `'isLoading'`
     *
     * @returns {Promise}
     */

    function extend(obj, loadingPropName) {
      loadingPropName = loadingPropName || 'isLoading';
      obj[loadingPropName] = false;
      obj[loadingPropName + 'StartLoading'] = startLoading(loadingPropName).bind(obj);
      obj[loadingPropName + 'StopLoading'] = stopLoading(loadingPropName).bind(obj);
      return $q.when(obj);
    }

    function startLoading(propName) {
      return function () {
        this[propName] = true;
      };
    }

    function stopLoading(propName) {
      return function () {
        this[propName] = false;
      };
    }
  }
})();