"use strict";

(function () {
  "use strict";

  angular.module('polcity.cassa').config(['$stateProvider', 'cassaRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['cassaRoutes', routesRun]);

  function addRoutes($stateProvider, cassaRoutesProvider, $translatePartialLoader, AuthProvider) {
    R.forEach(function (routeDefinition) {
      const stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }
    }, cassaRoutesProvider.list());
  }

  function routesRun(cassaRoutes) {
    cassaRoutes.subscribeUIEvents();
  }
})();