"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMasterDetail = void 0;

var _react = require("react");

var _core = require("@material-ui/core");

var _utils = require("../../../../utils");

const useMasterDetail = props => {
  const {
    options,
    componentName,
    open: openProp,
    onOpen,
    onClose,
    selectionActive: selectionActiveProp,
    onSelectionActive,
    onSelectionDeactive,
    selectedItems: selectedItemsProp,
    onSelectionChange,
    activeItem: activeItemProp,
    onActiveItemChange,
    idProp = 'aggId',
    isOptionEqualToValue = (option, value) => option.aggId === (value === null || value === void 0 ? void 0 : value.aggId),
    minLengthQuery,
    freeSearchQuery: freeSearchQueryProp,
    onFreeSearchQueryChange
  } = props;
  const [open, setOpenState] = (0, _core.useControlled)({
    controlled: openProp,
    default: false,
    name: componentName,
    state: 'open'
  });

  const handleOpen = event => {
    setOpenState(true);

    if (onOpen) {
      onOpen(event);
    }
  };

  const handleClose = event => {
    setOpenState(false);

    if (onClose) {
      onClose(event);
    }
  };

  const [selectionActive, setSelectionActiveState] = (0, _core.useControlled)({
    controlled: selectionActiveProp,
    default: false,
    name: componentName,
    state: 'selectionActive'
  });

  const handleSelectionActive = event => {
    setSelectionActiveState(true);
    handleActiveItem("")(event);

    if (onSelectionActive) {
      onSelectionActive(event);
    }
  };

  const handleSelectionDeactive = event => {
    setSelectionActiveState(false);
    setSelectedItems([]);

    if (onSelectionDeactive) {
      onSelectionDeactive(event);
    }
  };

  const [selectedItems, setSelectedItems] = (0, _core.useControlled)({
    controlled: selectedItemsProp,
    default: [],
    name: componentName,
    state: 'selectedItems'
  });

  const handleItemSelection = option => event => {
    const selectedIndex = selectedItems.findIndex(s => isOptionEqualToValue(option, s));
    setSelectedItems(selectedItems => selectedIndex === -1 ? [...selectedItems, option] : (0, _utils.removeAt)(selectedItems, selectedIndex));

    if (onSelectionChange) {
      const lastOperation = {
        reason: selectedIndex === -1 ? "remove" : "add",
        item: option
      };
      onSelectionChange(event, selectedItems, lastOperation);
    }
  };

  const [activeItem, setActiveItem] = (0, _core.useControlled)({
    controlled: activeItemProp,
    default: "",
    name: componentName,
    state: 'activeItem'
  });

  const handleActiveItem = option => event => {
    setActiveItem(option ? option.aggId : "");

    if (onActiveItemChange) {
      onActiveItemChange(event, option ? option.aggId : "");
    }
  };

  const [freeSearchQuery, setFreeSearchQuery] = (0, _core.useControlled)({
    controlled: freeSearchQueryProp,
    default: "",
    name: componentName,
    state: 'freeSearchQuery'
  });
  const isFreeSearchActive = (0, _react.useCallback)(query => query && query.length >= minLengthQuery, [minLengthQuery]);
  const freeSearchActive = isFreeSearchActive(freeSearchQuery);

  const handleFreeSearchQueryChange = e => {
    setFreeSearchQuery(e.target.value);

    if (isFreeSearchActive(e.target.value)) {
      setSelectedItems([]);
      handleActiveItem("")(e);
      handleClose(e);
      handleSelectionDeactive(e);
    }

    if (onFreeSearchQueryChange) {
      onFreeSearchQueryChange(e, e.target.value, e.target.value === "" ? "clear" : "change");
    }
  };

  return {
    getCollapsibleProps: () => ({
      open: open,
      freeSearchActive: freeSearchActive,
      onClick: e => open ? handleClose(e) : handleOpen(e)
    }),
    getSelectableProps: () => ({
      onClick: e => selectionActive ? handleSelectionDeactive(e) : handleSelectionActive(e)
    }),
    getOptionProps: ({
      index,
      option
    }) => {
      const active = isOptionEqualToValue(option, activeItem);
      const selected = selectedItems.some(s => isOptionEqualToValue(option, s));
      return {
        key: `${option[idProp]}-${index}`,
        id: `${option[idProp]}-${index}`,
        onClick: selectionActive ? handleItemSelection(option) : handleActiveItem(option),
        selected: selected,
        selectionActive: selectionActive
      };
    },
    getSelectAllProps: () => ({
      emptySelection: !Boolean(selectedItems.length),
      onClick: e => selectedItems.length > 0 ? setSelectedItems([]) : setSelectedItems(options)
    }),
    getFreeSearchProps: (0, _react.useCallback)(() => ({
      value: freeSearchQuery,
      onChange: handleFreeSearchQueryChange
    }), [freeSearchQuery]),
    open,
    selectionActive,
    selectedItems,
    activeItem,
    freeSearchActive
  };
};

exports.useMasterDetail = useMasterDetail;