"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useInfrazioniWithSanzioniAccessorie = exports.useInfrazioni = void 0;

var _react = require("react");

var _api = require("../api");

var _reactQuery = require("react-query");

var _sanzioniAccessorie = require("./sanzioniAccessorie");

var _object = require("../object.utils");

const useInfrazioni = ({
  query = "",
  dataOra,
  minLength,
  order
}) => {
  const {
    isLoading,
    isFetching,
    isError,
    data,
    error,
    isIdle
  } = (0, _reactQuery.useQuery)(['infrazioniTestoLibero', {
    query,
    dataOra,
    order
  }], ({
    queryKey
  }) => (0, _api.ricercaInfrazioniTestoLibero)({
    query: queryKey[1].query,
    dataVerbale: queryKey[1].dataOra,
    order: queryKey[1].order
  }), {
    // enabled: Boolean(dataOra),
    staleTime: 1000 * 60 * 5,
    select: response => {
      if (response.error) return response;
      const infrazioni = response.resultsWrapper.values || [];
      return infrazioni.map(infrazione => ({ ...infrazione,
        ...(Boolean(infrazione.sanzioniAccessorie) ? {
          sanzioniAccessorie: infrazione.sanzioniAccessorie
        } : {
          sanzioniAccessorie: []
        }),
        decoratori: { // https://dev.azure.com/opensoftwaresrl/PolcityKube/_workitems/edit/234/
          ...infrazione.decoratori,
          decoratoriIds: (0, _object.getPathObjectOrEmptyObject)(["decoratori", "decoratoriIds"])(infrazione)
        }
      }));
    }
  });
  const items = isLoading || isIdle ? [] : data;
  return {
    isLoading,
    isFetching,
    isError,
    data: items,
    error
  };
};

exports.useInfrazioni = useInfrazioni;

const useInfrazioniWithSanzioniAccessorie = ({
  query = "",
  dataOra,
  order
}) => {
  const infrazioni = useInfrazioni({
    query,
    dataOra,
    order
  });
  const sanzioniAccessorie = (0, _sanzioniAccessorie.useSanzioniAccessorie)({
    descrizione: ""
  });
  const items = (0, _react.useMemo)(() => infrazioni.data.map(i => ({ ...i,
    sanzioniAccessorie: i.sanzioniAccessorie.map(s => {
      const sanzioneAcc = sanzioniAccessorie.data.find(f => f.codiceVeloce === s.codiceVeloce);
      return { ...sanzioneAcc,
        ...s
      };
    })
  })), [infrazioni.data, sanzioniAccessorie.data]);
  return {
    isLoading: infrazioni.isLoading || sanzioniAccessorie.isLoading,
    isFetching: infrazioni.isFetching || sanzioniAccessorie.isFetching,
    data: items,
    isError: infrazioni.isError || sanzioniAccessorie.isError,
    error: [infrazioni.error, sanzioniAccessorie.error]
  };
};

exports.useInfrazioniWithSanzioniAccessorie = useInfrazioniWithSanzioniAccessorie;