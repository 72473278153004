"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTdsListMenu = exports.tabelleDiSupportoList = exports.tabelleDiSupportoSearchState = exports.tabelleDiSupportoListState = void 0;

var _utils = require("./utils");

var _recoil = require("recoil");

var _utils2 = require("../../utils");

const tabelleDiSupportoListState = (0, _recoil.atom)({
  key: "tabelleDiSupportoListState",
  default: _utils.tabelleDiSupporto
});
exports.tabelleDiSupportoListState = tabelleDiSupportoListState;
const tabelleDiSupportoSearchState = (0, _recoil.atom)({
  key: "tabelleDiSupportoSearchState",
  default: ""
});
exports.tabelleDiSupportoSearchState = tabelleDiSupportoSearchState;
const tabelleDiSupportoList = (0, _recoil.selector)({
  key: "tabelleDiSupportoListFiltered",
  get: ({
    get
  }) => {
    const tds = get(tabelleDiSupportoListState);
    const query = get(tabelleDiSupportoSearchState);
    const result = tds.reduce((acc, val, index) => {
      acc = [...acc, {
        section: val.section,
        tabelle: (0, _utils2.sortArrOfObjAlphabeticallyByKey)([...val.tabelle.filter(tabella => tabella.name.toLowerCase().includes(query))], "name")
      }];
      return acc;
    }, []);
    return result.filter(item => item.tabelle.length);
  }
});
exports.tabelleDiSupportoList = tabelleDiSupportoList;

const useTdsListMenu = () => {
  const tds = (0, _recoil.useRecoilValue)(tabelleDiSupportoList);
  const [query, onQueryChange] = (0, _recoil.useRecoilState)(tabelleDiSupportoSearchState);
  return {
    tds,
    query,
    onQueryChange
  };
};

exports.useTdsListMenu = useTdsListMenu;