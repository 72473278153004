"use strict";

(function () {
  'use strict';
  /**
   * TODO
   * Add interceptor for request that checks if payload contains ".translations"
   * That means that some translations need to be saved if and when that request is successfull
   * So store inside an hash that url with the payload and on the success interceptor check for that url
   * and execute the save translations
   * cleanup the hash after that
   */

  angular.module('osTranslatable', []);
})();