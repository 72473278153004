"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Documento', ['restmod', function (restmod) {
    return restmod.model('documento').mix({
      $extend: {
        Scope: {
          $config: {
            baseEntityPath: 'documento-anagrafica/',
            api: {
              list: {
                url: 'ricerca/documenti-anagrafica',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/documento-anagrafica',
                method: 'POST'
              },
              destroy: {
                url: 'comando/cancella-documento-anagrafica',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', {
      $extend: {
        Scope: {},
        Record: {
          /*$fetch: function() {
              return this.$action(function() {
                  var request = this.$buildRequest('list');
                  request.data = {aggId: this.$pk};
                   this.$dispatch('before-forza-invio', [request]);
                  this.$send(request, function( _response ) {
                      this.$unwrap(_response.data);
                      this.$dispatch('after-forza-invio', [_response]);
                  }, function( _response ) {
                      this.$dispatch('after-forza-invio-error', [_response]);
                  });
              });
          },*/
          //Override della defaul $destroy
          $destroy: function () {
            return this.$action(function () {
              if (this.spedizioneId) {
                var request = this.$buildRequest('destroy', false);
                request.data = {
                  aggId: this.aggId,
                  spedizioni: [this.spedizioneId]
                };
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$unwrap(this.$getResponseData(_response));
                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) {
                  this.$scope.$remove(this);
                }
              }
            });
          }
        }
      }
    });
  }]);
})();