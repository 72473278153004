"use strict";

(function () {
  'use strict';

  angular.module('osBreadcrumb').controller('osBreadcrumbController', ['$scope', '$rootScope', '$state', 'storageService', osBreadcrumbController]);

  function osBreadcrumbController($scope, $rootScope, $state, storageService) {
    var me = this;
    me.breadcrumbs = [];

    me.$onInit = function () {
      me.breadcrumbs = getBreadCrumbs() || [];
    };
    /**
     * Events listeners
     */


    $rootScope.$watch(() => $rootScope.$currentState, function (newVal, oldVal) {
      if (newVal.name === 'navigation.dashboard' && oldVal.name === 'navigation.dashboard') {
        me.breadcrumbs = [];
        storeBreadCrumbs();
        return;
      }

      if (newVal === oldVal) {
        const breadcrumbs = storageService.getData(sessionStorage, 'breadcrumbs');

        if (!breadcrumbs) {
          //Edge case di inizializzazione dell'applicazione
          me.breadcrumbs = [newVal];
        }
      } else if (newVal.name === 'navigation.dashboard') {
        me.breadcrumbs = [];
        storeBreadCrumbs();
      } else {
        updateBreadCrumbs(newVal);
      }
    }, true);
    /**
     * Handlers
     */

    me.onHomeClick = function () {
      me.breadcrumbs = [];
      storeBreadCrumbs();
      $state.go('navigation.dashboard');
    };

    me.onBreadcrumbClick = function (crumb) {
      var params = {};
      params.reloadPreviousState = crumb.reloadPreviousState || false;
      params = R.merge(crumb.params, params);
      $state.go(crumb.route, params);
    };
    /**
     * Methods implementation
     */
    // TODO: RIVEDERE METODO AGGIORNAMENTO BREADCRUMBS E TUTTE LE CONDIZIONI


    function updateBreadCrumbs(_currentState) {
      var bcIsAlreadyPresent = false,
          _currentStateIndex;

      if ($rootScope.$previousState.name === $rootScope.$currentState.name) {
        return;
      }

      if (_currentState.isBase) {
        me.breadcrumbs = [];
      }

      if (me.breadcrumbs.length) {
        for (var v of me.breadcrumbs) {
          // CERCO IL _currentState NELL'ARRAY DI BC E SETTO IL FLAG bcIsAlreadyPresent
          if (R.equals(v.name, _currentState.name)) {
            bcIsAlreadyPresent = true;
            break;
          } else {
            bcIsAlreadyPresent = false;
          }
        }
      } // ciclo sulle bc per togliere la breadcrumbs "inserisci nuovo elemento"


      for (var br of me.breadcrumbs) {
        if (br.name.includes('new') || br.name.includes('New')) {
          me.breadcrumbs.splice(me.breadcrumbs.length - 1, 1);
        }
      }

      if (!bcIsAlreadyPresent) {
        // SE NON HO IL _currentState LO AGGIUNGO IN CODA ALL'ARRAY DI BC
        me.breadcrumbs = R.append(_currentState, me.breadcrumbs);
      } else {
        // SE HO GIA' IL _currentState TOLGO TUTTE LE BC DA QUELLO STATO FINO ALLA FINE DELL'ARRAY
        _currentStateIndex = R.findIndex(R.propEq('name', _currentState.name))(me.breadcrumbs);
        me.breadcrumbs.splice(_currentStateIndex + 1, me.breadcrumbs.length - 1);
      }

      storeBreadCrumbs();
    }

    function storeBreadCrumbs() {
      if (me.breadcrumbs.length) {
        storageService.storeItem(sessionStorage, 'breadcrumbs', me.breadcrumbs);
      } else {
        storageService.deleteItem(sessionStorage, 'breadcrumbs');
      }
    }

    function getBreadCrumbs() {
      var breadcrumbs = storageService.getData(sessionStorage, 'breadcrumbs'); // if( !breadcrumbs ) {
      // $state.go('navigation.dashboard');
      // return;
      // }

      return breadcrumbs;
    }
  }
})();