"use strict";

/**
 * Created by gb on 17/09/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.ricorso.ritentaRicorso').component('ricorsoRitentaRicorso', {
    templateUrl: 'ricorso/ritentaRicorso/ricorso.ritentaRicorso.component.html',
    controller: 'RicorsoRitentaRicorsoController',
    controllerAs: 'RicorsoRitentaRicorsoCtrl',
    bindings: {
      ricorso: '<'
    }
  });
})();