"use strict";

var _ScartatiDaRuoloRoot = _interopRequireDefault(require("./ScartatiDaRuoloRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.scartatiDaRuoli').provider('scartatiDaRuoliRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'scartatiDaRuoli',
    abstract: true,
    url: 'scartatiDaRuoli',
    templateUrl: 'scartatiDaRuoli/scartatiDaRuoli.html'
  }, {
    name: 'scartatiDaRuoli.list',
    role: 'ROLE_DECURTAZIONE_PUNTI_RICERCA',
    url: '',
    params: {
      reloadPreviousState: false
    },
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ScartatiDaRuoloRoot.default
      }
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('scartatiDaRuoli.scartatiDaRuoli__bcLabel');
      }]
    }
  }];
  routesProviderFactory.extend(this);
}