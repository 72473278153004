"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dettaglioOrganigramma = void 0;
const dettaglioOrganigramma = {
  "allowsChildren": true,
  "userObject": {
    "stazioneComandoId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
    "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
    "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
    "nomeComune": "Padova",
    "indirizzo": "-",
    "codiceUnivoco": "SAP PD",
    "descrizione": "CDO GEN. - UF. OPZ. | CNA | SAP PADOVA"
  },
  "children": [{
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "05970c5e-63dd-4bc7-8d69-b0c2977b1e76",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
      "nomeComune": "Padova",
      "indirizzo": "-",
      "codiceUnivoco": "SAP PD_1",
      "descrizione": "CDO GEN. - UF. OPZ."
    },
    "children": [{
      "allowsChildren": true,
      "userObject": {
        "stazioneComandoId": "123",
        "padreAggId": "5623",
        "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
        "nomeComune": "Padova figlio",
        "indirizzo": "-",
        "codiceUnivoco": "SAP PD_1_A",
        "descrizione": "CDO GEN. - UF. OPZ. figlio"
      },
      "children": [{
        "allowsChildren": true,
        "userObject": {
          "stazioneComandoId": "999999",
          "padreAggId": "5623",
          "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
          "nomeComune": "sblam",
          "indirizzo": "-",
          "codiceUnivoco": "Anfora",
          "descrizione": "fafafaa"
        }
      }]
    }]
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "9de731f2-bcdb-4a6c-87a7-893118080e06",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
      "nomeComune": "Padova",
      "indirizzo": "-",
      "codiceUnivoco": "SAP PD_2",
      "descrizione": "CNA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "95126972-fb0f-4d6c-8752-8de337ca7b92",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
      "nomeComune": "Padova",
      "indirizzo": "-",
      "codiceUnivoco": "SAP PD_3",
      "descrizione": "SAP PADOVA"
    },
    "children": [{
      "allowsChildren": true,
      "userObject": {
        "stazioneComandoId": "2dc55309-0a20-3394-68e6-4b55afbccac4",
        "padreAggId": "95126972-fb0f-4d6c-8752-8de337ca7b92",
        "riferimentoComuneId": "97912a3b-76c9-4da0-b5de-999fafc03c71",
        "nomeComune": "Noale",
        "indirizzo": "via test",
        "codiceUnivoco": "test",
        "descrizione": "descrizione test"
      }
    }]
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "0ee9f7e6-6bcb-4459-b0b3-743d710bd296",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "c8f585ed-157d-40b5-b8bf-420b0b3c34f0",
      "nomeComune": "Bagnoli di Sopra",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10487",
      "descrizione": "BAGNOLI DI SOPRA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "34ac30eb-4cea-497c-b252-6f3ac5be457d",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "338fc99f-97ca-40c5-9562-a9f088c488cb",
      "nomeComune": "Galzignano Terme",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10488",
      "descrizione": "GALZIGNANO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "93c09888-9cc6-4cb6-ad3e-c09b162166e9",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "d5b3c4a1-5820-49ac-8e90-bbd021167ee3",
      "nomeComune": "Montegrotto Terme",
      "indirizzo": "-",
      "codiceUnivoco": "UO_15225",
      "descrizione": "MONTEGROTTO TERME"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "fd3512ec-b2bd-45c4-9ab8-dd2a9efd0552",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "1a922fed-e8d2-427b-9239-94d34bbb69c0",
      "nomeComune": "Cittadella",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10349",
      "descrizione": "STA CITTADELLA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "efd44934-2ccb-461a-b0c1-53c230c27d1f",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "72dd9b90-cf06-4e94-8b29-8158511d8482",
      "nomeComune": "Carmignano di Brenta",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10350",
      "descrizione": "CARMIGNANO DI BRENTA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "011cf379-a1e5-4ed0-8ef3-ccd5fa071d54",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "e3b5aaa5-3fa6-453a-b3a7-ac06ec16fe5d",
      "nomeComune": "Gazzo",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10351",
      "descrizione": "GAZZO PADOVANO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "223d6b37-de8f-46c8-ac3e-2017e2dc2b12",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "b4882669-d97d-4041-9461-1f92a8d1f079",
      "nomeComune": "Piazzola sul Brenta",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10352",
      "descrizione": "PIAZZOLA SUL BRENTA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "6e0c22a0-7f82-4a26-aba2-b4237afbf4c0",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "6860e8a6-59b0-4cd7-82f2-f4e23bbb8eaf",
      "nomeComune": "Camposampiero",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10353",
      "descrizione": "CAMPOSAMPIERO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "f9b7c9d6-76e2-40b3-8111-a0c0e62870e2",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "b7045653-a3bc-4c01-8f64-c4bbb169907a",
      "nomeComune": "San Martino di Lupari",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10397",
      "descrizione": "SAN MARTINO LUPARI"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "84016d67-52c2-44f4-a065-6f4d750dbd81",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "79ddbddd-dbae-4d0e-9b6a-2754f54860ab",
      "nomeComune": "Piombino Dese",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10398",
      "descrizione": "PIOMBINO DESE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "f0898712-4d51-40d4-93e5-9ed4bff6aa26",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "1a922fed-e8d2-427b-9239-94d34bbb69c0",
      "nomeComune": "Cittadella",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10410",
      "descrizione": "NORM CITTADELLA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "2a4e5c79-24cc-4cb1-b97f-c01ccac8f253",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "205669a3-59c6-467a-89a3-781817688b14",
      "nomeComune": "Trebaseleghe",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10429",
      "descrizione": "TREBASELEGHE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "8bb2703e-87b9-4480-b875-630deb77a2b6",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "4a61ac9f-8430-4c42-99ee-7644414cb9bb",
      "nomeComune": "Campodarsego",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10451",
      "descrizione": "CAMPODARSEGO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "a22d09a5-3d67-4f00-afaf-24c51364e1fc",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "de0fd1d7-13e4-4c42-8e62-68fc4b1e18b9",
      "nomeComune": "Tombolo",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10481",
      "descrizione": "TOMBOLO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "0c7cdfe8-8c43-4e19-9b2e-984e0871337d",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "16b26119-3f94-4a1c-8fb4-660887734eeb",
      "nomeComune": "Piove di Sacco",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10379",
      "descrizione": "NORM PIOVE DI SACCO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "1c83614b-a05d-463a-8463-59d31dbb6dde",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "ac048488-fede-4a15-9291-5ea16d089e18",
      "nomeComune": "Agna",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10399",
      "descrizione": "AGNA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "63aa9c41-468b-4766-90db-c8a762993d47",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "16b26119-3f94-4a1c-8fb4-660887734eeb",
      "nomeComune": "Piove di Sacco",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10430",
      "descrizione": "PIOVE DI SACCO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "9af1d8e2-635f-48e6-99b1-73c5b71c9115",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "c60384b0-f949-4a5a-bfa8-9b54aaa81775",
      "nomeComune": "Codevigo",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10431",
      "descrizione": "CODEVIGO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "c17b2989-3a73-4414-8d34-1d506a987a6d",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "a6b2856c-d8db-45e0-b143-32c8cbff97c2",
      "nomeComune": "Bovolenta",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10453",
      "descrizione": "BOVOLENTA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "9d9fde00-953a-4708-a2ca-936ac0ae1bf3",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "15de840b-ef6f-4d32-b75b-18be5bf31c19",
      "nomeComune": "Albignasego",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10454",
      "descrizione": "ALBIGNASEGO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "61783b84-ab9d-41d6-9932-daa583b4240a",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "47b24c83-0d5a-4c02-82cb-a206778af1b9",
      "nomeComune": "Casalserugo",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10455",
      "descrizione": "CASALSERUGO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "7944d801-59dc-4b07-bf86-a74b2ef8eb11",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "d2d6754c-669a-4cba-bcca-9b4d437cb3a7",
      "nomeComune": "Legnaro",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10460",
      "descrizione": "LEGNARO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "372f0abf-453e-4f8c-8537-b871655d7295",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "698c0358-b1e0-4274-8801-91ff2ffa8d41",
      "nomeComune": "Ponte San Nicolò",
      "indirizzo": "-",
      "codiceUnivoco": "UO_15368",
      "descrizione": "Ponte San Nicolò"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "2a329d02-c1a6-47e7-8fd6-5c0b28180aa1",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "d1b2a505-ffc3-47ad-97fb-c56a1aadc2e2",
      "nomeComune": "Selvazzano Dentro",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10345",
      "descrizione": "SELVAZZANO DENTRO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "3f4cb05a-2fa4-4023-81df-3cb7781f55e5",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
      "nomeComune": "Padova",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10392",
      "descrizione": "NORM PADOVA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "370bc065-fa7e-4e93-afe1-9dcd1b8b641f",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
      "nomeComune": "Padova",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10393",
      "descrizione": "PADOVA PRINCIPALE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "30ac9aba-a3b3-46ed-82af-70f16f2e5515",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "928bf98e-b455-4519-b73c-fd6c64fa642d",
      "nomeComune": "Rubano",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10408",
      "descrizione": "SARMEOLA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "80e11857-26db-401c-ba4b-2b9087a1b577",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "78c5c61d-790e-471c-aea0-07515ff4074d",
      "nomeComune": "Vigodarzere",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10427",
      "descrizione": "VIGODARZERE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "f2542441-28c8-4c83-9eb7-bc29f55ad5e4",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "75ead0da-8d92-41dc-a576-f71866eba33b",
      "nomeComune": "Vigonza",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10428",
      "descrizione": "PIONCA DI VIGONZA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "0b157cde-c062-484f-b526-403f90406405",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "d3202b4c-ca13-46f2-8868-53c9f7dcb366",
      "nomeComune": "Limena",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10473",
      "descrizione": "LIMENA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "d04f424d-a567-4a4c-8d03-801e5c4ca39f",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
      "nomeComune": "Padova",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10782",
      "descrizione": "PADOVA PRATO DELLA VALLE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "c91e0ee3-6e69-4262-aa9d-973eb3591252",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "da6e0b9e-93ba-49bb-bd4f-81eefa5c50bb",
      "nomeComune": "Mestrino",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10783",
      "descrizione": "MESTRINO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "87601e3e-69f4-484d-8200-1ca4e1a26a9e",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "2987dba7-38bf-4d21-ae1b-11ed03ebbbe3",
      "nomeComune": "Noventa Padovana",
      "indirizzo": "-",
      "codiceUnivoco": "UO_15184",
      "descrizione": "NOVENTA PADOVANA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "81ae4467-011a-44b3-92f2-755fc2849258",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "f1e4b8ad-42df-4ba5-a0e0-c6500baf5eff",
      "nomeComune": "Cadoneghe",
      "indirizzo": "-",
      "codiceUnivoco": "UO_15314",
      "descrizione": "CADONEGHE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "d58647d6-9456-466e-b365-5fa1bf8dfbee",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "1a922fed-e8d2-427b-9239-94d34bbb69c0",
      "nomeComune": "Cittadella",
      "indirizzo": "-",
      "codiceUnivoco": "UO_16287",
      "descrizione": "CITTADELLA (CC FOR.)"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "b47390be-7591-4d1f-9ec6-0a967da32a5c",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "c683a3da-876f-407a-91bb-635598f4b0a5",
      "nomeComune": "Monselice",
      "indirizzo": "-",
      "codiceUnivoco": "UO_16297",
      "descrizione": "MONSELICE (CC FOR.)"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "aa1a2ddb-579f-417e-b43d-254d3d82f387",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "d5b3c4a1-5820-49ac-8e90-bbd021167ee3",
      "nomeComune": "Montegrotto Terme",
      "indirizzo": "-",
      "codiceUnivoco": "UO_16298",
      "descrizione": "MONTEGROTTO TERME (CC FOR.)"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "a454f064-ace5-49da-9bbc-f5e68e956498",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "78cee8dd-086c-46da-9282-5c046583a78d",
      "nomeComune": "Padova",
      "indirizzo": "-",
      "codiceUnivoco": "UO_16300",
      "descrizione": "PADOVA (CC FOR.)"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "dbc0ee50-d836-4a85-903d-de4fd6600e50",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "0755842f-0ee4-4422-8bc6-ce69b09455b7",
      "nomeComune": "Este",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10346",
      "descrizione": "ESTE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "b251acea-1015-4aac-abd3-a841e75f8e4f",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "4d69778c-7d66-4f34-b639-3e0ebdcc9ed8",
      "nomeComune": "Vescovana",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10347",
      "descrizione": "VESCOVANA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "cca03abe-eaf8-4b0f-99fd-e7baa20bf986",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "65b3d3e9-1c1c-42cd-b3de-16d0120970cd",
      "nomeComune": "Ponso",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10394",
      "descrizione": "PONSO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "82104ce8-65a9-4fa1-b388-c8fc2976cc86",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "177ba528-223f-4ee6-b202-3753a226f846",
      "nomeComune": "Montagnana",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10409",
      "descrizione": "MONTAGNANA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "ec526d44-f4c5-4344-97ee-1a26d94f8d59",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "0755842f-0ee4-4422-8bc6-ce69b09455b7",
      "nomeComune": "Este",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10413",
      "descrizione": "NORM ESTE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "5cda319d-f633-4e40-bfbd-6c6a18b4f542",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "0bd370f7-eec9-4cd9-89d4-6a24635190a2",
      "nomeComune": "Castelbaldo",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10414",
      "descrizione": "CASTELBALDO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "1b49f0f4-64f6-46b1-82a3-823481d33cd2",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "46a755ba-aff8-4743-b096-5846929a1a53",
      "nomeComune": "Piacenza d'Adige",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10439",
      "descrizione": "PIACENZA D'ADIGE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "2b138b72-3645-4001-b012-879a58ebd1bf",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "bb5528fc-4bd7-47dc-9584-080a8c8ac2f6",
      "nomeComune": "Casale di Scodosia",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10440",
      "descrizione": "CASALE DI SCODOSIA"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "f8bff291-81e3-4294-bfd4-893c02261ab9",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "dfacdcb8-760f-4093-9101-9603f0e1811e",
      "nomeComune": "Sant'Urbano",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10474",
      "descrizione": "CARMIGNANO SANT'URBANO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "30d21543-a066-4003-958e-e91d7fc19258",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "5c1810b9-5f35-49e6-8c9d-ce0421d3d11b",
      "nomeComune": "Solesino",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10476",
      "descrizione": "SOLESINO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "158a013d-866f-45b2-be6e-008ad7fe2390",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "1d5cbe22-e694-4e7b-a606-75cf89b2fa61",
      "nomeComune": "Boara Pisani",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10478",
      "descrizione": "BOARA PISANI"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "20ff854b-e055-4c23-a240-7e92d3e20496",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "b9d8466d-378e-4864-93b7-fec13bcd5a6b",
      "nomeComune": "Borgo Veneto",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10784",
      "descrizione": "BORGO VENETO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "527996d7-aee7-41b2-a31b-ac29a03863f7",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "9225fc2f-c9c3-4b9e-b4a4-255262ed15ea",
      "nomeComune": "Teolo",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10348",
      "descrizione": "TEOLO IN TREPONTI"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "2b3527fb-88e7-4c83-a829-fb3a85b86a07",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "58282fdc-c661-478e-9ebf-eedd99d88de4",
      "nomeComune": "Battaglia Terme",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10364",
      "descrizione": "BATTAGLIA TERME"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "3136898e-60d9-4d38-9194-afdb588a2aa9",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "3ea1c2fa-7769-49c0-bf9b-d93673b99f1f",
      "nomeComune": "Rovolon",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10377",
      "descrizione": "BASTIA ROVOLON"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "282b3155-e6cc-4a57-bae0-a3648dc872b3",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "f3e325a0-5ac3-42b0-8721-c3d6f292cd9c",
      "nomeComune": "Abano Terme",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10395",
      "descrizione": "ABANO TERME"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "f276721c-571c-44b9-a002-dea6af8f539e",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "90eeb540-eda1-4ce7-b7f2-eac6c78c1252",
      "nomeComune": "Conselve",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10396",
      "descrizione": "CONSELVE"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "b78d051e-8569-4e45-abe7-fc3a081ac0f5",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "f3e325a0-5ac3-42b0-8721-c3d6f292cd9c",
      "nomeComune": "Abano Terme",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10441",
      "descrizione": "NORM ABANO TERME"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "708bc85a-c9a5-4364-8a5c-42263e4f7015",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "a7068c7f-c953-4694-90de-a402039a60a9",
      "nomeComune": "Lozzo Atestino",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10450",
      "descrizione": "LOZZO ATESTINO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "144d84c7-fba4-4bb9-a450-81616b59716b",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "82084417-a711-4215-975c-2b1226f860de",
      "nomeComune": "Tribano",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10479",
      "descrizione": "TRIBANO"
    }
  }, {
    "allowsChildren": true,
    "userObject": {
      "stazioneComandoId": "b026dc15-1381-46d5-a28d-7ed62d8c7747",
      "padreAggId": "9d7732e7-ed88-4f9c-b1f2-b1f1a1801ed6",
      "riferimentoComuneId": "c683a3da-876f-407a-91bb-635598f4b0a5",
      "nomeComune": "Monselice",
      "indirizzo": "-",
      "codiceUnivoco": "UO_10480",
      "descrizione": "MONSELICE"
    }
  }]
};
exports.dettaglioOrganigramma = dettaglioOrganigramma;