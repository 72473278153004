"use strict";

(function () {
  'use strict';
  /**
   * Dona la possiblità ad un Model di essere selezionabile (singolo / multiplo)
   */

  angular.module('polcity.models').factory('Selectable', ['restmod', function (restmod) {
    return restmod.mixin({
      $extend: {
        Scope: {
          //Costruttore
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);
            collection.$isSelectable = true;
            collection.$selectedItem = null;
            collection.$selectedItems = [];
            return collection;
          }
        }
      }
    }, {
      $extend: {
        Collection: {
          $selectAll: function () {
            this.$resetSelectedItems();

            for (var i = 0; i < this.length; i++) {
              this.$addSelectedItem(this[i]);
            }
          },
          $setSelectedItem: function (item) {
            this.$selectedItem = item;
          },
          $addSelectedItem: function (item) {
            this.$selectedItems.push(item);
          },
          $removeSelectedItem: function (item) {
            var index = R.findIndex(R.propEq('$pk', item.$pk))(this.$selectedItems);
            this.$selectedItems = R.remove(index, 1, this.$selectedItems);
          },
          $resetSelectedItems: function () {
            this.$selectedItems = [];
          },
          $setSelectedItems: function (items) {
            this.$selectedItems = items;
          },
          $getSelectedItems: function () {
            return this.$selectedItems;
          },
          $getSelectedItemsCollection: function () {
            return this.$collection().$bulkAdd(this.$getSelectedItems());
          },
          $getSelectedItemsCount: function () {
            return this.$selectedItems.length;
          }
        }
      }
    });
  }]);
})();