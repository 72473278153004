"use strict";

/**
 * Created by MN 12/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('GestioneStradeFormController', ['$scope', '$state', '$stateParams', 'Command', '$tdsApiService', '$tdsGlobalBehaviors', GestioneStradeFormController]);

  function GestioneStradeFormController($scope, $state, $stateParams, Command, $tdsApiService, $tdsGlobalBehaviors) {
    var gestioneStradeFormCtrl = this,
        dettaglioCommand = '@dettaglioStrada',
        modificaCommand = '@modificaStrada',
        salvaCommand = '@salvaStrada',
        redirectTo = 'navigation.tdsGestioneStrade',
        comuneId = $stateParams.comuneId;
    gestioneStradeFormCtrl.comuneId = comuneId;
    /**
     * Discriminante per capire se sono in edit
     * @type {boolean}
     */

    gestioneStradeFormCtrl.editMode = false;
    /**
     * @Property
     * Configuro l'aggId a zero leggendolo dagli $stateParams nel caso sia in inserimento
     * oppure lo recupero dall'oggetto nel caso in cui sia in edit
     */

    gestioneStradeFormCtrl.aggId = $state.includes('navigation.tdsGestioneStradeEdit') ? $stateParams.aggId : null;
    /**
     * @Property
     * Configuro l'oggetto recuperandolo dagli $stateParams nel caso in cui sia in edit
     * oppure assegnando un oggetto vuoto nel caso in cui sia in inserimento
     */

    gestioneStradeFormCtrl.item = {};
    /**
     * @Property
     * Insieme di tipi di protocollo con cui riempire la dropdown
     */

    gestioneStradeFormCtrl.tipologieStrade = [];
    /**
     * Handler onInit function
     * @type {onInit}
     */

    gestioneStradeFormCtrl.$onInit = onInit;
    /**
     * Operazione eseguite in istanza del controller
     */

    function onInit() {
      // SE NEGLI STATE PARAMS HO L'AGGID POSSO ESSERE IN EDIT OPPURE AVER RICARICATO LA PAGINA
      if (gestioneStradeFormCtrl.aggId) {
        gestioneStradeFormCtrl.editMode = true;
        var params = {
          entityId: gestioneStradeFormCtrl.aggId
        };
        $tdsApiService.executeCommand(dettaglioCommand, params).then(function (res) {
          gestioneStradeFormCtrl.item = res;
        }).catch(function () {
          handleItemLoadError();
        });
      } else {
        gestioneStradeFormCtrl.editMode = false;
      }

      recuperaTipologieStrade().then(setTipologieStrade);
    }

    gestioneStradeFormCtrl.queryFrazioni = function (query) {
      var data = {
        aggId: comuneId
      };

      if (query) {
        data.nome = query;
      }

      return recuperaFrazioni(data);
    };

    gestioneStradeFormCtrl.afterInsertNuovaFrazione = function (_frazione) {
      gestioneStradeFormCtrl.item.frazioneId = _frazione.frazioneId;
    };
    /**
     * Recupera i comuni
     */


    function recuperaFrazioni(params) {
      return Command.execute('@ricercaFrazioni', R.assoc('aggId', comuneId, params)).then(function (res) {
        return res.resultsWrapper.values;
      });
    }
    /**
     * Chiamata rest per recuperare i tipi protocollo
     * @returns {*}
     */


    function recuperaTipologieStrade() {
      return Command.execute('@recuperaTipologieStrade');
    }
    /**
     * Setter della variabile competenze
     * @param tipologieStrade
     */


    function setTipologieStrade(tipologieStrade) {
      gestioneStradeFormCtrl.tipologieStrade = tipologieStrade;
    }
    /**
     * @property
     * Handler da eseguire al click sul pulsante del salvataggio
     * @param item
     */


    gestioneStradeFormCtrl.onSaveStradaButtonClick = function (item) {
      var command = gestioneStradeFormCtrl.editMode ? modificaCommand : salvaCommand;
      item.aggId = comuneId;
      return Command.execute(command, item).then(function () {
        $tdsGlobalBehaviors.notifica('success', gestioneStradeFormCtrl.editMode ? 'tds.modificaSuccess__notification' : 'tds.inserimentoSuccess__notification');
      }).then(function () {
        $tdsGlobalBehaviors.redirectTo(redirectTo, {
          comuneId: comuneId
        });
      }).catch(function (err) {
        $tdsGlobalBehaviors.notifica('error', 'tds.inserimentoError__notification');
      });
    };
    /****************
     * Utils
     ***************/


    function handleItemLoadError() {
      //Notifica errore
      $tdsGlobalBehaviors.notifica('error', 'tds.elementNotFount__notification'); //Go back

      $tdsGlobalBehaviors.redirectTo(redirectTo);
    }
  }
})();