"use strict";

/**
 * MN 13/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaDirezione', [RicercaDirezione]);

  function RicercaDirezione() {
    return {
      restrict: 'E',
      require: ['ngModel'],
      scope: {
        entity: '=?',
        label: '@?',
        onEnteTerritorialeChange: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', '$filter', controllerFn],
      controllerAs: 'ricercaDirezioneCtrl',
      templateUrl: 'osFramework/input/ricercaDirezione/ricercaDirezione.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter) {
      var me = this;
      me.groupRoles = ['ROLE_DIREZIONE_MARCIA_VEICOLI_RICERCA', 'ROLE_DIREZIONE_MARCIA_VEICOLI_DETTAGLIO'];

      me.getItemText = function (direzione) {
        var itemText;
        itemText = $filter('translatable')(direzione.descrizione);
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.aggId = null;
          return;
        }

        scope.aggId = viewValue;
      };

      scope.$watch('aggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();