"use strict";

/**
 * Created by MN 12/10/2018
 */
(function () {
  'use strict';

  angular.module('osTableComponent').component('osTableSearch', {
    templateUrl: 'osFramework/osTable/search/osTableSearch.component.html',
    controller: [osTableSearchController],
    controllerAs: 'osTableSearchCtrl',
    bindings: {
      searchLabel: '<',
      mdOnSearch: '&'
    }
  });

  function osTableSearchController() {
    var osTableSearchCtrl = this;

    osTableSearchCtrl.performSearch = function (searchText) {
      if (searchText.length > 2 || !searchText.length) {
        osTableSearchCtrl.mdOnSearch({
          searchText
        });
      }
    };
  }
})();