"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const BottomRightBox = (0, _core.withStyles)(theme => ({
  root: {
    position: "fixed !important",
    width: "60px",
    zIndex: 999,
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(2)
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  }
}))(_core.Box);
var _default = BottomRightBox;
exports.default = _default;