"use strict";

var _EsitiSpedizioniRoot = _interopRequireDefault(require("./EsitiSpedizioniRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.esitiSpedizioni').provider('esitiSpedizioniRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'esitiSpedizioni',
    abstract: true,
    url: 'esitiSpedizioni',
    templateUrl: 'esitiSpedizioni/esitiSpedizioni.html'
  }, {
    name: 'esitiSpedizioni.list',
    role: 'ROLE_ACQUISIZIONE_ESITI_RICERCA',
    url: '',
    params: {
      reloadPreviousState: false
    },
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _EsitiSpedizioniRoot.default
      }
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('esitiSpedizioni.esitiSpedizioni__bcLabel');
      }]
    }
  }];
  routesProviderFactory.extend(this);
}