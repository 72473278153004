"use strict";

/**
 * Created by macbookpro15tb on 26/05/17.
 */
(function () {
  'use strict';

  angular.module('osFramework').controller('VariablesDialogController', ['Command', '$mdDialog', 'LoadingStatusBehaviour', 'commandName', 'commandData', VariablesDialogController]);

  function VariablesDialogController(Command, $mdDialog, LoadingStatusBehaviour, commandName, commandData) {
    /////////////////////////
    var vc = this; /////////////////////////
    // Controller behaviours

    LoadingStatusBehaviour.extend(vc); /////////////////////////
    // Controller API

    this.resolve = resolve;
    this.reject = reject;
    this.select = select;
    this.selectedItem = null; /////////////////////////
    // Automatism
    //fetch the content and show it inside the dialog

    getVariabili(); /////////////////////////
    // Methods implementation

    function getVariabili() {
      vc.error = false;
      vc.isLoadingStartLoading();
      Command.execute(commandName, commandData).then(variablesToDialog).catch(errorToDialog).finally(vc.isLoadingStopLoading);
    }

    function variablesToDialog(remoteVariables) {
      vc.list = remoteVariables;
    }

    function errorToDialog(errorReason) {
      vc.error = errorReason;
    }

    function select(choosedItem) {
      vc.selectedItem = choosedItem;
    }

    function resolve(choosedItem) {
      $mdDialog.hide({
        value: choosedItem.value,
        conditional: vc.isConditional,
        inloop: choosedItem.inloop,
        type: choosedItem.type
      });
    }

    function reject() {
      $mdDialog.cancel();
    }
  }
})();