"use strict";

/**
 * Created by dan on 02/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.services').service('uiEvents', [declareUIEvents]);

  function declareUIEvents() {
    return {
      showToast: 'show toast',
      showNotificationFromTranslation: 'show notification from translation',
      showNavigation: 'show the main navigation',
      hideLayers: 'hide the current blocking layer',
      //typically after a failed network request
      stopProgress: 'stop any progress',
      showLoadingToast: 'show loading toast',
      hideLoadingToast: 'hide loading toast',
      publishRoutes: 'publish routes',
      notAuthenticated: 'not authenticated',
      showKeyboardHints: 'show keyboard hints',
      hideOsFabButton: 'hide os fab button',
      showOsFabButton: 'show os fab button',
      hideBackButton: 'hide back button',
      showBackButton: 'show back button',
      showInfowindow: 'show infowindow',
      navigationGoBack: 'navigation go back',
      osMapResize: 'osmap resize'
    };
  }
})();