"use strict";

var _jquery = _interopRequireDefault(require("jquery"));

var _Map = _interopRequireDefault(require("ol/Map"));

var _View = _interopRequireDefault(require("ol/View"));

var _proj = require("ol/proj");

var _map = require("./map");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('osMapField').directive('osMapV2', [osMapDirective]);

function osMapDirective() {
  return {
    restrict: 'E',
    scope: {
      lat: '=',
      lon: '='
    },
    template: '<div class="map"></div>',
    link: postLink
  };

  function postLink(scope, element) {
    var map = new _Map.default({
      layers: [_map.rasterLayer, _map.vectorLayer],
      target: (0, _jquery.default)('.map')[0],
      view: new _View.default({
        center: (0, _proj.fromLonLat)([scope.lon, scope.lat]),
        zoom: 16
      })
    });
    scope.$watchGroup(['lat', 'lon'], ([lat, lon]) => {
      (0, _map.removeAllMarkers)(_map.vectorSource);
      (0, _map.addMarker)(_map.vectorSource, lon, lat); //re-center the map

      map.getView().animate({
        center: (0, _proj.fromLonLat)([lon, lat]),
        duration: 2000
      });
    }); // var view;
    // var map;
    // var marker;
    //
    // //scope.$watchGroup(['lat', 'lon'], addMarker);
    //
    // createMap();
    //
    // function centerMap(latLon) {
    //     var duration = 2000;
    //     var zoom = view.getZoom();
    //     if (zoom < 10) {
    //         zoom = 10;
    //     }
    //     var parts = 2;
    //     var called = false;
    //     var location = ol.proj.fromLonLat(R.values(latLon).reverse());
    //
    //     function callback(complete) {
    //         --parts;
    //         if (called) {
    //             return;
    //         }
    //         if (parts === 0 || !complete) {
    //             called = true;
    //         }
    //     }
    //
    //     view.animate({
    //         center: location,
    //         duration: duration
    //     }, callback);
    //     view.animate({
    //         zoom: zoom - 1,
    //         duration: duration / 2
    //     }, {
    //         zoom: zoom,
    //         duration: duration / 2
    //     }, callback);
    // }
    //
    // function createMap() {
    //     return $timeout(function () {
    //         var centerPosition = scope.lat && scope.lon ? [scope.lat, scope.lon] : R.values(ROME_POSITION);
    //         view = new ol.View({
    //             center: ol.proj.fromLonLat(centerPosition.reverse()),
    //             zoom: 16
    //         });
    //         map = new ol.Map({
    //             target: $('.map')[0],
    //             layers: [
    //                 new ol.layer.Tile({
    //                     preload: 4,
    //                     source: new ol.source.OSM()
    //                 })
    //             ],
    //             interactions: ol.interaction.defaults({mouseWheelZoom: false}),
    //             // Improve user experience by loading tiles while animating. Will make
    //             // animations stutter on mobile or slow devices.
    //             loadTilesWhileAnimating: true,
    //             view: view
    //         });
    //
    //         //centerMap(centerPosition.reverse());
    //         addMarker(centerPosition.reverse());
    //
    //         $('body').on('resize', adjustMapSize(map));
    //         $timeout(function () {
    //             $('body').trigger('resize');
    //         });
    //
    //     });
    //
    // }
    //
    // function existsLayerFor(lonLat) {
    //     if (!marker) {
    //         return false;
    //     }
    //
    //     var features = vector.getSource().getFeatures();
    //     var found = false;
    //     // Go through this array and get coordinates of their geometry.
    //     features.forEach(function (feature) {
    //         var coordinates = feature.getGeometry().getCoordinates();
    //         var featureLonlat = ol.proj.transform(coordinates, 'EPSG:3857', 'EPSG:4326');
    //         if (featureLonlat[0] === lonLat[0] && featureLonlat[1] === featureLonlat[1]) {
    //             found = true;
    //         }
    //     });
    //
    //     return found;
    // }
    //
    // function addMarker(newValues, oldValues) {
    //     if (!map) {
    //         return;
    //     }
    //     var lat = parseFloat(newValues[0]);
    //     var lon = parseFloat(newValues[1]);
    //     if (!lat || !lon) {
    //         return;
    //     }
    //
    //     var oldLat = oldValues ? parseFloat(oldValues[0]) : -1;
    //     var oldLon = oldValues ? parseFloat(oldValues[1]) : -1;
    //     if (lat === oldLat && lon === oldLon && existsLayerFor([lon, lat])) {
    //         return;
    //     }
    //
    //     // if a marker exists remove it
    //     if (map && marker) {
    //         map.removeLayer(marker);
    //     }
    //
    //     // add marker in new position
    //     var vectorSource = new ol.source.Vector({
    //         //create empty vector
    //     });
    //
    //     //and then declare your style with img and imgSize
    //     var style = new ol.style.Style({
    //         image: new ol.style.Icon({
    //             opacity: 1,
    //             src: "https://upload.wikimedia.org/wikipedia/commons/e/ec/RedDot.svg",
    //             //src: 'data:image/svg+xml;utf8,' + svgMarker,
    //             scale: 1,
    //             anchor: [0.5, 1]
    //         })
    //     });
    //
    //     var iconFeature = new ol.Feature({
    //         geometry: new ol.geom.Point(ol.proj.transform([lon, lat], 'EPSG:4326', 'EPSG:3857'))
    //     });
    //
    //     iconFeature.setStyle(style);
    //
    //     vectorSource.addFeature(iconFeature);
    //     marker = new ol.layer.Vector({
    //         source: vectorSource
    //     });
    //
    //     // marker.lonLat = [lon, lat];
    //
    //     map.addLayer(marker);
    //
    //     if (!mapIsCenteredIn(lat, lon)) {
    //         centerMap({
    //             lat: lat,
    //             lon: lon
    //         });
    //     }
    // }
    //
    // function mapIsCenteredIn(lat, lon) {
    //     var centerCoordinates = map.getView().getCenter();
    //     var mapLonLat = ol.proj.transform(centerCoordinates, 'EPSG:3857', 'EPSG:4326');
    //     return mapLonLat[0] === lon && mapLonLat[1] === lat;
    // }
    //
    // function adjustMapSize(map) {
    //     return function (ev) {
    //         console.log('adjustMapSize -> ', ev);
    //         $timeout(function () {
    //             map.updateSize()
    //         });
    //     }
    //
    // }
  }
}