"use strict";

/**
 * Refactor by MN 05/06/2018
 *
 * Questa direttiva stampa una lista chiave/valore in base alle proprietà passate come attributo durante il suo utilizzo
 */
(function () {
  'use strict';

  angular.module('polcity.verbali').directive('osVerbale', osVerbaleDirective);

  function osVerbaleDirective() {
    return {
      restrict: 'E',
      scope: {
        customListProperties: '=customListProperties',
        item: '='
      },
      template: '<div class="md-whiteframe-4dp md-list-item-text" layout layout-padding>' + '<div flex="33" ng-repeat="prop in listProperties">' + '                        <span class="os-list__label">{{prop.label}}</span>\n' + '                        <p class="os-list__value">{{prop.value | uppercase}}</p>\n' + '                    </div>\n' + '</div>',
      link: postLink
    };

    function postLink(scope) {
      scope.listProperties = prepareItem(scope.item, scope.customListProperties);
      /**
       * Crea un oggetto chiave valore per ogni proprietà specificata nelle customListProperties. Nel caso in cui la proprietà non ci sia restituisce "--"
       * @param item
       * @param customListProperties
       * @returns {Array}
       */

      function prepareItem(item, customListProperties) {
        var result = [];

        for (var i = 0; i < customListProperties.length; i++) {
          //handle nested properties
          var path = customListProperties[i].key.split(".");
          result.push({
            label: customListProperties[i].label,
            value: customListProperties[i].renderer ? customListProperties[i].renderer(item) : R.path(path, item) ? R.path(path, item) : '--'
          });
        }

        return result;
      }
    }
  }
})();