"use strict";

/**
 * Created by macbookpro15tb on 01/09/17.
 */
(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name polcity.entityToPdf
   *
   * @description
   * This modules exports a mdButton that when clicked will show an mdDialog with 4 mdSelect to expose the
   * entity-to-pdf functionality to the final user.
   * The button accepts up to 2 parameters, the first parameter needs to be the namespace of the main entity you want
   * to export to pdf, the second parameter, optional, when provided will pre-fill the first mdSelect and make it readonly
   */

  angular.module('polcity.entityToPdf', ['polcity.api', 'pascalprecht.translate' //'ngMaterial'
  ]); // module constants

  angular.module('polcity.entityToPdf').constant('FETCH_PROJECTIONS_COMMAND', '@listProjections').constant('FETCH_PRINT_FUNCTIONS_COMMAND', '@listPrintFunctions').constant('FETCH_PRINT_TEMPLATES_COMMAND', '@listPrintTemplates').constant('FETCH_ENTITIES_COMMAND', '@listEntities').constant('FETCH_DEFAULT_ENTITIY_COMMAND', '@getDefaultEntity').constant('TO_PDF_COMMAND', '@getPdf'); // add translations

  angular.module('polcity.entityToPdf').config(['$translatePartialLoaderProvider', addTranslations]);

  function addTranslations($translatePartialLoader) {
    $translatePartialLoader.addPart('osFramework/entityToPdf');
  } // subscribe commands to the api


  angular.module('polcity.entityToPdf').run(['CommandsList', 'FETCH_PROJECTIONS_COMMAND', 'FETCH_PRINT_FUNCTIONS_COMMAND', 'FETCH_PRINT_TEMPLATES_COMMAND', 'FETCH_ENTITIES_COMMAND', 'FETCH_DEFAULT_ENTITIY_COMMAND', 'TO_PDF_COMMAND', addCommands]);

  function addCommands(CommandsList, FETCH_PROJECTIONS_COMMAND, FETCH_PRINT_FUNCTIONS_COMMAND, FETCH_PRINT_TEMPLATES_COMMAND, FETCH_ENTITIES_COMMAND, FETCH_DEFAULT_ENTITIY_COMMAND, TO_PDF_COMMAND) {
    var commands = {};
    /*commands[FETCH_PROJECTIONS_COMMAND] = {
      method: 'GET',
      url: 'aggregato/elementi'
    };
     commands[FETCH_PRINT_FUNCTIONS_COMMAND] = {
      url: 'funzione-stampa/ricerca/funzioni-stampe',
      method: 'POST',
      transformRequest: function (requestData) {
        // the api is paginated but this component needs to always show all the functions,
        // for now we use this sad palliative
        return angular.toJson({indice: 0, numeroElementi: 999, filtro: requestData});
      },
      transformResponse: R.pipe(angular.fromJson, R.prop('risultato'))
    };*/

    commands[FETCH_PRINT_TEMPLATES_COMMAND] = {
      url: 'modello-stampa/ricerca/modelli-stampa',
      method: 'GET'
      /*transformRequest: function (requestData) {
        // force the inUso to true to ensure that only acgive print templates will be prompted to the user
        return angular.toJson({
          indice: 0,
          numeroElementi: 999,
          filtro: {funzione: [R.prop('aggId', requestData)], inUso: true}
        });
      },
      transformResponse: R.pipe(angular.fromJson, R.prop('risultato'))*/

    };
    commands[FETCH_DEFAULT_ENTITIY_COMMAND] = {
      url: 'aggregato/proiezione-radice',
      method: 'GET'
    };
    commands[FETCH_ENTITIES_COMMAND] = {
      url: 'aggregato/items',
      method: 'GET'
    };
    /*commands[TO_PDF_COMMAND] = {
      url: 'incidente/stampa',
      method: 'POST'
      //replaceParams: true
    };*/

    CommandsList.add(commands);
  }
})();