"use strict";

angular.module('polcity.appCommands').constant('URLS', {
  dashboard: {
    verbaliSeguiti: {
      url: 'dashboard/ricerca/verbali-seguiti',
      method: 'GET'
    },
    statiVerbale: {
      url: 'dashboard/ricerca/stato-verbale',
      method: 'GET'
    },
    tipiAccertamento: {
      url: 'dashboard/ricerca/codice-verbale',
      method: 'GET'
    },
    lotti: {
      url: 'dashboard/lotto/ricerca-veloce',
      method: 'GET'
    },
    tipiRilevatore: {
      url: 'dashboard/rilevazione/rilevatore',
      method: 'GET'
    },
    milestoneRilevazioni: {
      url: 'dashboard/rilevazione/milestone',
      method: 'GET'
    },
    // eventiApplicazione: {url: 'dashboard/ricerca/verbali-pericolo', method: 'GET'},
    userEvents: {
      ricerca: {
        url: 'dashboard/userevent/ricerca',
        method: 'GET'
      },
      crea: {
        url: 'dashboard/userevent/comando/inizializza',
        method: 'POST'
      },
      modifica: {
        url: 'dashboard/userevent/comando/modifica',
        method: 'POST'
      },
      elimina: {
        url: 'dashboard/userevent/comando/cancella',
        method: 'POST'
      }
    }
  },
  entitaPagabili: {
    ricerca: {
      url: 'payable/ricerca-testo-libero',
      method: 'GET'
    }
  },
  anagrafica: {
    ricercaTestoLibero: {
      url: 'anagrafica/ricerca-testo-libero',
      method: 'GET'
    },
    dettaglio: {
      url: 'anagrafica/dettaglio/anagrafica',
      method: 'POST'
    },
    crea: {
      url: 'anagrafica/comando/aggiungi-anagrafica',
      method: 'POST'
    },
    modifica: {
      url: 'anagrafica/comando/modifica-anagrafica',
      method: 'POST'
    },
    documento: {
      ricerca: {
        url: 'documento-anagrafica/ricerca/documenti-anagrafica',
        method: 'GET'
      },
      dettaglio: {
        url: 'documento-anagrafica/dettaglio/documento-anagrafica',
        method: 'POST'
      },
      crea: {
        url: 'documento-anagrafica/comando/aggiungi-documento-anagrafica',
        method: 'POST'
      },
      modifica: {
        url: 'documento-anagrafica/comando/modifica-documento-anagrafica',
        method: 'POST'
      },
      elimina: {
        url: 'documento-anagrafica/comando/cancella-documento-anagrafica',
        method: 'POST'
      }
    },
    veicolo: {
      ricerca: {
        url: 'veicolo-anagrafica/ricerca/veicoli-anagrafica',
        method: 'GET'
      },
      dettaglio: {
        url: 'veicolo-anagrafica/dettaglio/veicolo-anagrafica',
        method: 'POST'
      },
      crea: {
        url: 'veicolo-anagrafica/comando/aggiungi-veicolo-anagrafica',
        method: 'POST'
      },
      modifica: {
        url: 'veicolo-anagrafica/comando/modifica-veicolo-anagrafica',
        method: 'POST'
      },
      elimina: {
        url: 'veicolo-anagrafica/comando/cancella-veicolo-anagrafica',
        method: 'POST'
      }
    }
  },
  articoliCds: {
    ricercaTestoLibero: {
      url: 'violazione/ricerca-testo-libero',
      method: 'GET'
    },
    ricerca: {
      url: 'violazione/ricerca/violazioni',
      method: 'GET'
    },
    dettaglio: {
      url: 'violazione/dettaglio/violazione',
      method: 'POST'
    }
  },
  verbale: {
    bolletta: {
      url: 'bolletta/univocita/bolletta-numero-tipo-anno',
      method: 'POST'
    },
    ricercaVeloce: {
      url: 'verbale/ricerca-veloce',
      method: 'GET'
    },
    ricercaTestoLibero: {
      url: 'verbale/ricerca-testo-libero',
      method: 'GET'
    },
    dettaglio: {
      url: 'verbale/dettaglio/verbale',
      method: 'POST'
    },
    cancella: {
      url: 'verbale/comando/cancella-verbale',
      method: 'POST'
    },
    follow: {
      url: 'segui',
      method: 'POST'
    },
    unfollow: {
      url: 'smetti',
      method: 'POST'
    },
    creaSpedizioneManuale: {
      url: 'verbale/comando/spedizione-manuale',
      method: 'POST'
    },
    creaNotificaManuale: {
      url: 'verbale/comando/notifica-manuale',
      method: 'POST'
    },
    cancellaSpedizioneManuale: {
      url: 'verbale/comando/cancella-spedizioni',
      method: 'POST'
    }
  },
  lotto: {
    downloadArchivioSinPostel: {
      url: 'lotto/comando/download-archivio-sin-postel',
      method: 'GET'
    }
  },
  spedizione: {
    creaSpedizioneManuale: {
      url: 'spedizione/comando/spedizione-manuale',
      method: 'POST'
    },
    creaEsitoManuale: {
      url: 'spedizione/comando/esito-manuale',
      method: 'POST'
    },
    cancellaSpedizioneManuale: {
      url: 'spedizione/comando/cancella-spedizioni',
      method: 'POST'
    }
  },
  modelliStampa: {
    ricerca: {
      url: 'modello-stampa/ricerca/modelli-stampa',
      method: 'GET'
    },
    dettaglio: {
      url: 'modello-stampa/dettaglio/modello-stampa',
      method: 'POST'
    }
  },
  ricorsi: {
    dettaglio: {
      url: 'ricorso/dettaglio/ricorso',
      method: 'POST'
    },
    caricaTracciatoSana: {
      url: 'ricorso/import_sana',
      method: 'POST'
    },
    generaFascicolo: {
      url: 'ricorso/genera-fascicolo',
      method: 'POST'
    },
    controdeduzione: {
      url: "ricorso/ricerca/controdeduzione",
      method: "GET"
    }
  },
  decurtazione: {
    caricaFilePunti: {
      url: 'decurtazione-punti/comando/importazione-file-punti',
      method: 'POST'
    }
  },
  visure: {
    visuraPersonaFisica: {
      url: 'dtt/dettaglioPersonaFisicaVeicoli',
      method: 'GET'
    },
    visuraFiguraGiuridica: {
      url: 'dtt/dettaglioPersonaGiuridicaListaVeicoliCompleta',
      method: 'GET'
    },
    visuraVeicoloGenerale: {
      url: 'dtt/dettaglioVeicoloComproprietariTrasferimenti',
      method: 'GET'
    },
    visuraVeicoloParziale: {
      url: 'dtt/verificaCoperturaAssicurativaScadenzaRevisione',
      method: 'GET'
    }
  },
  agentiVisure: {
    ricerca: {
      url: 'agente-visura/ricerca/agenti-visura',
      method: 'GET'
    },
    dettaglio: {
      url: 'agente-visura/dettaglio/agente-visura',
      method: 'POST'
    },
    crea: {
      url: 'agente-visura/comando/aggiungi-agente-visura',
      method: 'POST'
    },
    modifica: {
      url: 'agente-visura/comando/modifica-agente-visura',
      method: 'POST'
    },
    elimina: {
      url: 'agente-visura/comando/cancella-agente-visura',
      method: 'POST'
    },
    download: {
      url: 'agente-visura/comando/download-agente-visura',
      method: 'POST'
    }
  },
  infrazioni: {
    ricerca: {
      url: 'violazione/ricerca-testo-libero',
      method: 'GET'
    },
    dettaglio: {
      url: 'violazione/dettaglio/violazione',
      method: 'POST'
    }
  },
  contabilita: {
    ricercaTestoLibero: {
      url: 'contabilita/ricerca-testo-libero',
      method: 'GET'
    },
    ricercaRegistrazione: {
      url: 'contabilita/ricerca-registrazione',
      method: 'GET'
    }
  },
  versamenti: {
    tipiVersamento: {
      url: 'versamento/lista/tipi-versamento',
      method: 'GET'
    },
    metodiVersamento: {
      url: 'versamento/lista/metodi-versamento',
      method: 'GET'
    }
  },
  tabelleDiSupporto: {
    nazioni: {
      ricerca: {
        url: 'stato/ricerca/stati',
        method: 'GET'
      },
      dettaglio: {
        url: 'stato/dettaglio/stato',
        method: 'POST'
      },
      crea: {
        url: 'stato/comando/aggiungi-stato',
        method: 'POST'
      },
      modifica: {
        url: 'stato/comando/modifica-stato',
        method: 'POST'
      },
      elimina: {
        url: 'stato/comando/cancella-stato',
        method: 'POST'
      }
    },
    gestioneDelTerritorio: {
      ricerca: {
        url: 'comune/ricerca/comuni',
        method: 'GET'
      },
      dettaglio: {
        url: 'comune/dettaglio/comune',
        method: 'POST'
      },
      crea: {
        url: 'comune/comando/aggiungi-comune',
        method: 'POST'
      },
      modifica: {
        url: 'comune/comando/modifica-comune',
        method: 'POST'
      },
      elimina: {
        url: 'comune/comando/cancella-comune',
        method: 'POST'
      }
    },
    comuni: {
      ricerca: {
        url: 'riferimento-comune/ricerca/riferimenti-comune',
        method: 'GET'
      },
      dettaglio: {
        url: 'riferimento-comune/dettaglio/riferimento-comune',
        method: 'POST'
      },
      crea: {
        url: 'riferimento-comune/comando/aggiungi-riferimento-comune',
        method: 'POST'
      },
      modifica: {
        url: 'riferimento-comune/comando/modifica-riferimento-comune',
        method: 'POST'
      },
      elimina: {
        url: 'riferimento-comune/comando/cancella-riferimento-comune',
        method: 'POST'
      }
    },
    frazioni: {
      ricerca: {
        url: 'frazione/ricerca/frazioni',
        method: 'GET'
      },
      dettaglio: {
        url: 'frazione/dettaglio/frazione',
        method: 'POST'
      },
      crea: {
        url: 'frazione/comando/aggiungi-frazione',
        method: 'POST'
      },
      modifica: {
        url: 'frazione/comando/modifica-frazione',
        method: 'POST'
      },
      elimina: {
        url: 'frazione/comando/cancella-frazione',
        method: 'POST'
      }
    },
    strade: {
      ricerca: {
        url: 'strada/ricerca/strade',
        method: 'GET'
      },
      dettaglio: {
        url: 'strada/dettaglio/strada',
        method: 'POST'
      },
      crea: {
        url: 'strada/comando/aggiungi-strada',
        method: 'POST'
      },
      modifica: {
        url: 'strada/comando/modifica-strada',
        method: 'POST'
      },
      elimina: {
        url: 'strada/comando/cancella-strada',
        method: 'POST'
      }
    },
    contoCorrente: {
      ricerca: {
        url: 'conto-corrente/ricerca/conti-correnti',
        method: 'GET'
      },
      dettaglio: {
        url: 'conto-corrente/dettaglio/conto-corrente',
        method: 'POST'
      },
      crea: {
        url: 'conto-corrente/comando/aggiungi-conto-corrente',
        method: 'POST'
      },
      modifica: {
        url: 'conto-corrente/comando/modifica-conto-corrente',
        method: 'POST'
      },
      elimina: {
        url: 'conto-corrente/comando/cancella-conto-corrente',
        method: 'POST'
      }
    },
    tipologieRimozioni: {
      ricerca: {
        url: 'tipologia-rimozione/ricerca/tipologie-rimozioni',
        method: 'GET'
      },
      dettaglio: {
        url: 'tipologia-rimozione/dettaglio/tipologia-rimozione',
        method: 'POST'
      },
      crea: {
        url: 'tipologia-rimozione/comando/aggiungi-tipologia-rimozione',
        method: 'POST'
      },
      modifica: {
        url: 'tipologia-rimozione/comando/modifica-tipologia-rimozione',
        method: 'POST'
      },
      elimina: {
        url: 'tipologia-rimozione/comando/cancella-tipologia-rimozione',
        method: 'POST'
      }
    },
    marcheModelliVeicoli: {
      ricerca: {
        url: 'marca-modello-veicolo/ricerca/marche-modelli-veicoli',
        method: 'GET'
      },
      dettaglio: {
        url: 'marca-modello-veicolo/dettaglio/marca-modello-veicolo',
        method: 'POST'
      },
      crea: {
        url: 'marca-modello-veicolo/comando/aggiungi-marca-modello-veicolo',
        method: 'POST'
      },
      modifica: {
        url: 'marca-modello-veicolo/comando/modifica-marca-modello-veicolo',
        method: 'POST'
      },
      elimina: {
        url: 'marca-modello-veicolo/comando/cancella-marca-modello-veicolo',
        method: 'POST'
      }
    },
    depositiFermiSequestri: {
      ricerca: {
        url: 'deposito-fermi-sequestri/ricerca/depositi-fermi-sequestri',
        method: 'GET'
      },
      dettaglio: {
        url: 'deposito-fermi-sequestri/dettaglio/deposito-fermi-sequestri',
        method: 'POST'
      },
      crea: {
        url: 'deposito-fermi-sequestri/comando/aggiungi-deposito-fermi-sequestri',
        method: 'POST'
      },
      modifica: {
        url: 'deposito-fermi-sequestri/comando/modifica-deposito-fermi-sequestri',
        method: 'POST'
      },
      elimina: {
        url: 'deposito-fermi-sequestri/comando/cancella-deposito-fermi-sequestri',
        method: 'POST'
      }
    },
    contatoriProtocollo: {
      ricerca: {
        url: 'contatore-protocollo/ricerca/contatori-protocollo',
        method: 'GET'
      },
      dettaglio: {
        url: 'contatore-protocollo/dettaglio/contatore-protocollo',
        method: 'POST'
      },
      crea: {
        url: 'contatore-protocollo/comando/aggiungi-contatore-protocollo',
        method: 'POST'
      },
      modifica: {
        url: 'contatore-protocollo/comando/modifica-contatore-protocollo',
        method: 'POST'
      },
      elimina: {
        url: 'contatore-protocollo/comando/cancella-contatore-protocollo',
        method: 'POST'
      }
    },
    naturaIncidente: {
      ricerca: {
        url: 'indici-valore?dominio=NATURA_INCIDENTE',
        method: 'GET'
      },
      crea: {
        url: 'comando/indice-valore',
        method: 'POST'
      },
      modifica: {
        url: 'comando/indice-valore',
        method: 'PUT'
      },
      elimina: {
        url: 'comando/indice-valore',
        method: 'DELETE'
      }
    },
    tipoStrada: {
      ricerca: {
        url: 'indici-valore?dominio=TIPO_STRADA',
        method: 'GET'
      },
      crea: {
        url: 'comando/indice-valore',
        method: 'POST'
      },
      modifica: {
        url: 'comando/indice-valore',
        method: 'PUT'
      },
      elimina: {
        url: 'comando/indice-valore',
        method: 'DELETE'
      }
    },
    particolaritaStrada: {
      ricerca: {
        url: 'indici-valore?dominio=PART_STRADA',
        method: 'GET'
      },
      crea: {
        url: 'comando/indice-valore',
        method: 'POST'
      },
      modifica: {
        url: 'comando/indice-valore',
        method: 'PUT'
      },
      elimina: {
        url: 'comando/indice-valore',
        method: 'DELETE'
      }
    },
    pavimentazioneStrada: {
      ricerca: {
        url: 'indici-valore?dominio=PAVIMENTAZIONE_STRADA',
        method: 'GET'
      },
      crea: {
        url: 'comando/indice-valore',
        method: 'POST'
      },
      modifica: {
        url: 'comando/indice-valore',
        method: 'PUT'
      },
      elimina: {
        url: 'comando/indice-valore',
        method: 'DELETE'
      }
    },
    condizioneLuce: {
      ricerca: {
        url: 'indici-valore?dominio=CONDIZIONE_LUCE',
        method: 'GET'
      },
      crea: {
        url: 'comando/indice-valore',
        method: 'POST'
      },
      modifica: {
        url: 'comando/indice-valore',
        method: 'PUT'
      },
      elimina: {
        url: 'comando/indice-valore',
        method: 'DELETE'
      }
    },
    tipoVeicoloIstat: {
      ricerca: {
        url: 'indici-valore?dominio=TIPO_VEICOLO_ISTAT',
        method: 'GET'
      },
      crea: {
        url: 'comando/indice-valore',
        method: 'POST'
      },
      modifica: {
        url: 'comando/indice-valore',
        method: 'PUT'
      },
      elimina: {
        url: 'comando/indice-valore',
        method: 'DELETE'
      }
    },
    carroAttrezziRimozione: {
      ricerca: {
        url: 'indici-valore?dominio=CARRO_ATTREZZI_RIMOZIONE',
        method: 'GET'
      },
      crea: {
        url: 'comando/indice-valore',
        method: 'POST'
      },
      modifica: {
        url: 'comando/indice-valore',
        method: 'PUT'
      },
      elimina: {
        url: 'comando/indice-valore',
        method: 'DELETE'
      }
    },
    depositerie: {
      ricerca: {
        url: 'indici-valore?dominio=DEPOSITERIE',
        method: 'GET'
      },
      crea: {
        url: 'comando/indice-valore',
        method: 'POST'
      },
      modifica: {
        url: 'comando/indice-valore',
        method: 'PUT'
      },
      elimina: {
        url: 'comando/indice-valore',
        method: 'DELETE'
      }
    },
    contenutiAllegati: {
      ricerca: {
        url: 'contenuto-allegato/ricerca/contenuti-allegati',
        method: 'GET'
      },
      dettaglio: {
        url: 'contenuto-allegato/dettaglio/contenuto-allegato',
        method: 'POST'
      },
      crea: {
        url: 'contenuto-allegato/comando/aggiungi-contenuto-allegato',
        method: 'POST'
      },
      modifica: {
        url: 'contenuto-allegato/comando/modifica-contenuto-allegato',
        method: 'POST'
      },
      elimina: {
        url: 'contenuto-allegato/comando/cancella-contenuto-allegato',
        method: 'POST'
      }
    },
    funzioniStampa: {
      ricerca: {
        url: 'funzione-stampa/ricerca/funzioni-stampa',
        method: 'GET'
      },
      dettaglio: {
        url: 'funzione-stampa/dettaglio/funzione-stampa',
        method: 'POST'
      },
      modifica: {
        url: 'funzione-stampa/comando/modifica-funzione-stampa',
        method: 'POST'
      },
      crea: {
        url: 'funzione-stampa/comando/aggiungi-funzione-stampa',
        method: 'POST'
      },
      elimina: {
        url: 'funzione-stampa/comando/cancella-funzione-stampa',
        method: 'POST'
      }
    },
    lingue: {
      ricerca: {
        url: 'lingua/ricerca/lingue',
        method: 'GET'
      },
      dettaglio: {
        url: 'lingua/dettaglio/lingua',
        method: 'POST'
      },
      modifica: {
        url: 'lingua/comando/modifica-lingua',
        method: 'POST'
      },
      crea: {
        url: 'lingua/comando/aggiungi-lingua',
        method: 'POST'
      },
      elimina: {
        url: 'lingua/comando/cancella-lingua',
        method: 'POST'
      }
    },
    localizzazione: {
      ricerca: {
        url: 'localizzazione/ricerca/localizzazioni',
        method: 'GET'
      },
      dettaglio: {
        url: 'localizzazione/dettaglio/localizzazione',
        method: 'POST'
      },
      modifica: {
        url: 'localizzazione/comando/modifica-localizzazione',
        method: 'POST'
      },
      crea: {
        url: 'localizzazione/comando/aggiungi-localizzazione',
        method: 'POST'
      },
      elimina: {
        url: 'localizzazione/comando/cancella-localizzazione',
        method: 'POST'
      }
    },
    enteTerritoriale: {
      ricerca: {
        url: 'ente-territoriale/ricerca/enti-territoriali',
        method: 'GET'
      },
      dettaglio: {
        url: 'ente-territoriale/dettaglio/ente-territoriale',
        method: 'POST'
      },
      modifica: {
        url: 'ente-territoriale/comando/modifica-ente-territoriale',
        method: 'POST'
      },
      crea: {
        url: 'ente-territoriale/comando/aggiungi-ente-territoriale',
        method: 'POST'
      },
      elimina: {
        url: 'ente-territoriale/comando/cancella-ente-territoriale',
        method: 'POST'
      }
    },
    enteSuperioreProvincia: {
      ricerca: {
        url: 'ente-territoriale/ricerca/enti-territoriali?tipoEnteTerritoriale=REGIONE',
        method: 'GET'
      }
    },
    ente: {
      ricerca: {
        url: 'ente/ricerca/enti',
        method: 'GET'
      },
      dettaglio: {
        url: 'ente/dettaglio/ente',
        method: 'POST'
      },
      modifica: {
        url: 'ente/comando/modifica-ente',
        method: 'POST'
      },
      crea: {
        url: 'ente/comando/aggiungi-ente',
        method: 'POST'
      },
      elimina: {
        url: 'ente/comando/cancella-ente',
        method: 'POST'
      }
    },
    carroAttrezzi: {
      ricerca: {
        url: 'carro-attrezzi/ricerca/carri-attrezzi',
        method: 'GET'
      },
      dettaglio: {
        url: 'carro-attrezzi/dettaglio/carro-attrezzi',
        method: 'POST'
      },
      modifica: {
        url: 'carro-attrezzi/comando/modifica-carro-attrezzi',
        method: 'POST'
      },
      crea: {
        url: 'carro-attrezzi/comando/aggiungi-carro-attrezzi',
        method: 'POST'
      },
      elimina: {
        url: 'carro-attrezzi/comando/cancella-carro-attrezzi',
        method: 'POST'
      }
    },
    tipoVeicolo: {
      ricerca: {
        url: 'tipo-veicolo/ricerca/tipi-veicolo',
        method: 'GET'
      },
      dettaglio: {
        url: 'tipo-veicolo/dettaglio/tipo-veicolo',
        method: 'POST'
      },
      modifica: {
        url: 'tipo-veicolo/comando/modifica-tipo-veicolo',
        method: 'POST'
      },
      crea: {
        url: 'tipo-veicolo/comando/aggiungi-tipo-veicolo',
        method: 'POST'
      },
      elimina: {
        url: 'tipo-veicolo/comando/cancella-tipo-veicolo',
        method: 'POST'
      }
    },
    tipiProprieta: {
      ricerca: {
        url: 'tipo-proprieta/ricerca/tipi-proprieta',
        method: 'GET'
      },
      dettaglio: {
        url: 'tipo-proprieta/dettaglio/tipo-proprieta',
        method: 'POST'
      },
      modifica: {
        url: 'tipo-proprieta/comando/modifica-tipo-proprieta',
        method: 'POST'
      },
      crea: {
        url: 'tipo-proprieta/comando/aggiungi-tipo-proprieta',
        method: 'POST'
      },
      elimina: {
        url: 'tipo-proprieta/comando/cancella-tipo-proprieta',
        method: 'POST'
      }
    },
    tipoBollettario: {
      ricerca: {
        url: 'bollettario/ricerca/bollettari',
        method: 'GET'
      },
      dettaglio: {
        url: 'bollettario/dettaglio/bollettario',
        method: 'POST'
      },
      modifica: {
        url: 'bollettario/comando/modifica-bollettario',
        method: 'POST'
      },
      crea: {
        url: 'bollettario/comando/aggiungi-bollettario',
        method: 'POST'
      },
      elimina: {
        url: 'bollettario/comando/cancella-bollettario',
        method: 'POST'
      }
    },
    tipoAccertamento: {
      ricerca: {
        url: 'tipo-accertamento/ricerca/tipi-accertamento',
        method: 'GET'
      },
      dettaglio: {
        url: 'tipo-accertamento/dettaglio/tipo-accertamento',
        method: 'POST'
      },
      modifica: {
        url: 'tipo-accertamento/comando/modifica-tipo-accertamento',
        method: 'POST'
      },
      crea: {
        url: 'tipo-accertamento/comando/aggiungi-tipo-accertamento',
        method: 'POST'
      },
      elimina: {
        url: 'tipo-accertamento/comando/cancella-tipo-accertamento',
        method: 'POST'
      }
    },
    motivazioneMancataContestazione: {
      ricerca: {
        url: 'motivazione-mancata-contestazione/ricerca/motivazioni-mancata-contestazione',
        method: 'GET'
      },
      dettaglio: {
        url: 'motivazione-mancata-contestazione/dettaglio/motivazione-mancata-contestazione',
        method: 'POST'
      },
      modifica: {
        url: 'motivazione-mancata-contestazione/comando/modifica-motivazione-mancata-contestazione',
        method: 'POST'
      },
      crea: {
        url: 'motivazione-mancata-contestazione/comando/aggiungi-motivazione-mancata-contestazione',
        method: 'POST'
      },
      elimina: {
        url: 'motivazione-mancata-contestazione/comando/cancella-motivazione-mancata-contestazione',
        method: 'POST'
      }
    },
    direzioneMarciaVeicoli: {
      ricerca: {
        url: 'direzione-marcia-veicoli/ricerca/direzioni-marcia-veicoli',
        method: 'GET'
      },
      dettaglio: {
        url: 'direzione-marcia-veicoli/dettaglio/direzione-marcia-veicoli',
        method: 'POST'
      },
      modifica: {
        url: 'direzione-marcia-veicoli/comando/modifica-direzione-marcia-veicoli',
        method: 'POST'
      },
      crea: {
        url: 'direzione-marcia-veicoli/comando/aggiungi-direzione-marcia-veicoli',
        method: 'POST'
      },
      elimina: {
        url: 'direzione-marcia-veicoli/comando/cancella-direzione-marcia-veicoli',
        method: 'POST'
      }
    },
    motivazioneArchiviazioneAccertamenti: {
      ricerca: {
        url: 'motivazione-archiviazione-accertamenti/ricerca/motivazioni-archiviazione-accertamenti',
        method: 'GET'
      },
      dettaglio: {
        url: 'motivazione-archiviazione-accertamenti/dettaglio/motivazione-archiviazione-accertamenti',
        method: 'POST'
      },
      modifica: {
        url: 'motivazione-archiviazione-accertamenti/comando/modifica-motivazione-archiviazione-accertamenti',
        method: 'POST'
      },
      crea: {
        url: 'motivazione-archiviazione-accertamenti/comando/aggiungi-motivazione-archiviazione-accertamenti',
        method: 'POST'
      },
      elimina: {
        url: 'motivazione-archiviazione-accertamenti/comando/cancella-motivazione-archiviazione-accertamenti',
        method: 'POST'
      }
    },
    relazioneNomeSesso: {
      ricerca: {
        url: 'relazione-nome-sesso/ricerca/relazioni-nome-sesso',
        method: 'GET'
      },
      dettaglio: {
        url: 'relazione-nome-sesso/dettaglio/relazione-nome-sesso',
        method: 'POST'
      },
      modifica: {
        url: 'relazione-nome-sesso/comando/modifica-relazione-nome-sesso',
        method: 'POST'
      },
      crea: {
        url: 'relazione-nome-sesso/comando/aggiungi-relazione-nome-sesso',
        method: 'POST'
      },
      elimina: {
        url: 'relazione-nome-sesso/comando/cancella-relazione-nome-sesso',
        method: 'POST'
      }
    },
    attivitaProduttiva: {
      ricerca: {
        url: 'attivita-produttiva/ricerca/attivita-produttive',
        method: 'GET'
      },
      dettaglio: {
        url: 'attivita-produttiva/dettaglio/attivita-produttiva',
        method: 'POST'
      },
      modifica: {
        url: 'attivita-produttiva/comando/modifica-attivita-produttiva',
        method: 'POST'
      },
      crea: {
        url: 'attivita-produttiva/comando/aggiungi-attivita-produttiva',
        method: 'POST'
      },
      elimina: {
        url: 'attivita-produttiva/comando/cancella-attivita-produttiva',
        method: 'POST'
      }
    },
    sanzioniAccessorie: {
      ricerca: {
        url: 'sanzione-accessoria/ricerca/sanzioni-accessorie',
        method: 'GET'
      },
      dettaglio: {
        url: 'sanzione-accessoria/dettaglio/sanzione-accessoria',
        method: 'POST'
      },
      modifica: {
        url: 'sanzione-accessoria/comando/modifica-sanzione-accessoria',
        method: 'POST'
      },
      crea: {
        url: 'sanzione-accessoria/comando/aggiungi-sanzione-accessoria',
        method: 'POST'
      },
      elimina: {
        url: 'sanzione-accessoria/comando/cancella-sanzione-accessoria',
        method: 'POST'
      }
    },
    motivazioneMancataApplicazione: {
      ricerca: {
        url: 'motivazione-mancata-applicazione/ricerca/motivazioni-mancata-applicazione',
        method: 'GET'
      },
      dettaglio: {
        url: 'motivazione-mancata-applicazione/dettaglio/motivazione-mancata-applicazione',
        method: 'POST'
      },
      modifica: {
        url: 'motivazione-mancata-applicazione/comando/modifica-motivazione-mancata-applicazione',
        method: 'POST'
      },
      crea: {
        url: 'motivazione-mancata-applicazione/comando/aggiungi-motivazione-mancata-applicazione',
        method: 'POST'
      },
      elimina: {
        url: 'motivazione-mancata-applicazione/comando/cancella-motivazione-mancata-applicazione',
        method: 'POST'
      }
    },
    leggi: {
      ricerca: {
        url: 'legge/ricerca/leggi',
        method: 'GET'
      },
      dettaglio: {
        url: 'legge/dettaglio/legge',
        method: 'POST'
      },
      modifica: {
        url: 'legge/comando/modifica-legge',
        method: 'POST'
      },
      crea: {
        url: 'legge/comando/aggiungi-legge',
        method: 'POST'
      },
      elimina: {
        url: 'legge/comando/cancella-legge',
        method: 'POST'
      }
    },
    rilevatoriInfrazioni: {
      ricerca: {
        url: 'rilevatore-infrazioni/ricerca/rilevatori-infrazioni',
        method: 'GET'
      },
      dettaglio: {
        url: 'rilevatore-infrazioni/dettaglio/rilevatore-infrazioni',
        method: 'POST'
      },
      modifica: {
        url: 'rilevatore-infrazioni/comando/modifica-rilevatore-infrazioni',
        method: 'POST'
      },
      crea: {
        url: 'rilevatore-infrazioni/comando/aggiungi-rilevatore-infrazioni',
        method: 'POST'
      },
      elimina: {
        url: 'rilevatore-infrazioni/comando/cancella-rilevatore-infrazioni',
        method: 'POST'
      }
    },
    luoghiRicoveroInfortunati: {
      ricerca: {
        url: 'ricovero-infortunati/ricerca/ricoveri-infortunati',
        method: 'GET'
      },
      dettaglio: {
        url: 'ricovero-infortunati/dettaglio/ricovero-infortunati',
        method: 'POST'
      },
      modifica: {
        url: 'ricovero-infortunati/comando/modifica-ricovero-infortunati',
        method: 'POST'
      },
      crea: {
        url: 'ricovero-infortunati/comando/aggiungi-ricovero-infortunati',
        method: 'POST'
      },
      elimina: {
        url: 'ricovero-infortunati/comando/cancella-ricovero-infortunati',
        method: 'POST'
      }
    },
    stazioniComando: {
      ricerca: {
        url: 'stazione-comando/ricerca/stazioni-comando',
        method: 'GET'
      },
      dettaglio: {
        url: 'stazione-comando/dettaglio/stazione-comando',
        method: 'POST'
      },
      modifica: {
        url: 'stazione-comando/comando/modifica-stazione-comando',
        method: 'POST'
      },
      crea: {
        url: 'stazione-comando/comando/aggiungi-stazione-comando',
        method: 'POST'
      },
      elimina: {
        url: 'stazione-comando/comando/cancella-stazione-comando',
        method: 'POST'
      }
    }
  },
  acquisizioneDati: {
    rilevazioni: {
      ricerca: {
        url: 'rilevazione-projection/ricerca/rilevazioni-projection',
        method: 'GET'
      },
      dettaglio: {
        url: 'rilevazione/dettaglio/rilevazione',
        method: 'POST'
      },
      immagini: {
        url: 'bpmn/get-immagini-da-validare',
        method: 'GET'
      },
      crea: {
        url: 'verbalizzazione/fase_1',
        method: 'POST'
      },
      cancella: {
        url: 'rilevazione/comando/cancella',
        method: 'POST'
      },
      ricercaTestoLibero: {
        url: 'rilevazione/ricerca-testo-libero',
        method: 'GET'
      }
    },
    versamenti: {
      acquisizioneBancopostaTxt: {
        url: 'versamento/bancoposta-txt',
        method: 'POST'
      },
      acquisizioneBollettinoReport: {
        url: 'versamento/bollettino-report',
        method: 'POST'
      },
      acquisizioneBollettinoBancoposteSepa: {
        url: 'versamento/sepa-bancoposta-txt',
        method: 'POST'
      }
    }
  },
  notifiche: {
    ricerca: {
      url: 'alert/ricerca/alert',
      method: 'GET'
    }
  },
  enums: {
    recuperaCompetenze: {
      url: 'globale/lista/competenze',
      method: 'GET'
    },
    tipiProtocollo: {
      url: 'globale/lista/tipo-protocollo',
      method: 'GET'
    },
    tipologieStrade: {
      url: 'globale/lista/tipi-strada',
      method: 'GET'
    },
    indiciValori: {
      url: 'globale/lista/indici-valore-dominio',
      method: 'GET'
    },
    tipiAllegato: {
      url: 'allegato-digitale/lista/tipi-allegato',
      method: 'GET'
    },
    disponibilitaAllegato: {
      url: 'globale/lista/tipi-disponibilita',
      method: 'GET'
    },
    moduliStampa: {
      url: 'globale/lista/moduli-stampa',
      method: 'GET'
    },
    funzioniModuliStampa: {
      url: 'globale/lista/funzioni-modulo-stampa',
      method: 'GET'
    },
    listaEntita: {
      url: 'aggregati/modulo',
      method: 'GET'
    },
    visibilitaFunzioneStampa: {
      url: 'globale/lista/visibilita-funzione-stampa',
      method: 'GET'
    },
    valute: {
      url: 'globale/lista/currencycode',
      method: 'GET'
    },
    entiTerritoriali: {
      url: 'globale/lista/tipo-ente-territoriale',
      method: 'GET'
    },
    tipiEnte: {
      url: 'globale/lista/tipo-ente',
      method: 'GET'
    },
    tipiMezzo: {
      url: 'globale/lista/tipi-mezzo',
      method: 'GET'
    },
    tipiMotore: {
      url: 'globale/lista/tipi-motore',
      method: 'GET'
    },
    tipiVerbali: {
      url: 'globale/lista/tipi-verbale',
      method: 'GET'
    },
    sessi: {
      url: 'globale/lista/sessi',
      method: 'GET'
    },
    tipiCodice: {
      url: 'globale/lista/tipi-codice',
      method: 'GET'
    },
    decoratoriAccessorie: {
      url: 'decoratore/lista/decoratori-accessorie',
      method: 'GET'
    },
    tipiRilevatori: {
      url: 'rilevatore-infrazioni/lista/tipi-rilevatore',
      method: 'GET'
    },
    modelliRilevatori: {
      url: 'globale/lista/modelli-rilevatore',
      method: 'GET'
    },
    postazioniRilevatori: {
      url: 'rilevatore-infrazioni/lista/tipi-postazione',
      method: 'GET'
    },
    tipiAccesso: {
      url: 'rilevatore-infrazioni/lista/tipi-accesso',
      method: 'GET'
    },
    posizioniRelative: {
      url: 'posizione-relativa/lista/posizioni-relative',
      method: 'GET'
    },
    listaProiezioni: {
      url: 'aggregati/proiezioni',
      method: 'GET'
    },
    tipiIncidente: {
      url: 'globale/lista/tipi-incidente',
      method: 'GET'
    },
    tipiGiorno: {
      url: 'globale/lista/tipi-giorno',
      method: 'GET'
    },
    modalitaRichieste: {
      url: 'globale/lista/incidente-modalita-richiesta',
      method: 'GET'
    },
    richiedentiIntervento: {
      url: 'globale/lista/incidente-richiedente-intervento',
      method: 'GET'
    },
    tipiTraffico: {
      url: 'globale/lista/tipi-traffico',
      method: 'GET'
    },
    condizioniMeteo: {
      url: 'globale/lista/condizioni-meteo',
      method: 'GET'
    },
    tipiVisibilita: {
      url: 'globale/lista/visibilita',
      method: 'GET'
    },
    fondiStradali: {
      url: 'globale/lista/fondi-stradale',
      method: 'GET'
    },
    statiManutenzioneStrada: {
      url: 'globale/lista/stati-manutenzione-strada',
      method: 'GET'
    },
    tipiSegnaletica: {
      url: 'globale/lista/tipi-segnaletica',
      method: 'GET'
    },
    tipiAnagrafica: {
      url: 'tipo-anagrafica/lista/tipi-anagrafica',
      method: 'GET'
    },
    listaCinture: {
      url: 'globale/lista/cinture',
      method: 'GET'
    },
    listaCasco: {
      url: 'globale/lista/casco',
      method: 'GET'
    },
    tipiDocumentoPersonale: {
      url: 'tipo-documento-personale/lista/tipi-documento-personale',
      method: 'GET'
    },
    tipiCategoriaPatente: {
      url: 'categoria-patente/lista/categorie-patente',
      method: 'GET'
    },
    listaSpecificheCQC: {
      url: 'specifica-cqc/lista/specifiche-cqc',
      method: 'GET'
    },
    tipiRilevazione: {
      url: 'rilevatore-infrazioni/lista/tipi-rilevazione',
      method: 'GET'
    },
    tipiRicorsi: {
      url: 'globale/lista/tipo-ricorso',
      method: 'GET'
    },
    modalitaPresentazioneRicorso: {
      url: 'ricorso/lista/modalita-presentazione-ricorso',
      method: 'GET'
    },
    ricorsoPresentatoA: {
      url: 'ricorso/lista/ricorso-presentato-a',
      method: 'GET'
    },
    ricorsoTipiPareri: {
      url: 'ricorso/lista/tipi-parere',
      method: 'GET'
    },
    statiRicorso: {
      url: 'ricorso/lista/stati-ricorso',
      method: 'GET'
    },
    sospensiveRicorso: {
      url: 'ricorso/lista/sospensiva',
      method: 'GET'
    },
    tipiTrasgressore: {
      url: 'tipo-trasgressore/lista/tipi-trasgressore',
      method: 'GET'
    },
    tipiNotifica: {
      url: 'tipo-notifica/lista/tipi-notifica',
      method: 'GET'
    },
    mezziNotifica: {
      url: 'mezzo/lista/mezzi',
      method: 'GET'
    },
    esitiSpedizione: {
      url: 'tipo-esito-spedizione/lista/tipi-esito-spedizione',
      method: 'GET'
    },
    tipiProprieta: {
      url: 'tipo-proprieta/lista/tipi-proprieta',
      method: 'GET'
    },
    statiVerbale: {
      url: 'verbale/lista/stati-verbale',
      method: 'GET'
    },
    tipiRichiesteVisuraPf: {
      url: 'visure/lista/tipo-richiesta-visura-pf',
      method: 'GET'
    },
    tipiRichiesteVisuraPg: {
      url: 'visure/lista/tipo-richiesta-visura-pg',
      method: 'GET'
    },
    originiNascita: {
      url: 'visure/lista/origine-nascita',
      method: 'GET'
    },
    tipiFermoSequestro: {
      url: 'fermo-sequestro/lista/tipi',
      method: 'GET'
    },
    trasportoACuraDi: {
      url: 'trasporto-a-cura-di/lista/trasporti-a-cura-di',
      method: 'GET'
    },
    tipiAffidamentoVeicolo: {
      url: 'tipo-affidamento/lista/tipi-affidamento',
      method: 'GET'
    },
    destinazioniLotto: {
      url: 'lotto/lista/tipi-destinazione',
      method: 'GET'
    },
    tipiLotto: {
      url: 'lotto/lista/tipi-lotto',
      method: 'GET'
    },
    tipiTracciatoVersamento: {
      url: 'versamento/tipi-versamento',
      method: 'GET'
    },
    contiEconomici: {
      url: 'contabilita/lista/piano-conti',
      method: 'GET'
    },
    calcoloVelocita: {
      url: 'globale/lista/calcolo-velocita',
      method: 'GET'
    },
    inviaRiceviPc: {
      url: 'globale/lista/invia-ricevi-pc',
      method: 'GET'
    },
    tracciatoInvio: {
      url: 'globale/lista/tracciato-invio',
      method: 'GET'
    },
    tipoStampante: {
      url: 'globale/lista/tipo-stampante',
      method: 'GET'
    },
    tipiBollettariAccertamento: {
      url: 'globale/lista/tipi-bollettari',
      method: 'GET'
    },
    nrRegistroAccertamenti: {
      url: 'globale/lista/registro-accertamenti',
      method: 'GET'
    },
    puntiPersoneFisiche: {
      url: 'globale/lista/punti-persone-fisiche',
      method: 'GET'
    },
    progressivoRicorsi: {
      url: 'globale/lista/progressivo-ricorsi',
      method: 'GET'
    },
    nomeAllegatiSana: {
      url: 'globale/lista/nome-allegati-sana',
      method: 'GET'
    },
    modalitaRipetizioneInserimento: {
      url: 'globale/lista/modalita-ripetizione-inserimento',
      method: 'GET'
    },
    dataRilevazioneVerbalizzazioneMassiva: {
      url: 'globale/lista/data-rilevazione-verbalizzazione-massiva',
      method: 'GET'
    },
    obbligatorioNonObblitatorio: {
      url: 'globale/lista/obbligatorio-non-obbligatorio',
      method: 'GET'
    },
    applicazioneSpeseBollo: {
      url: 'globale/lista/applicazione-spese-bollo',
      method: 'GET'
    },
    scontoInfrazioni: {
      url: 'globale/lista/sconto-infrazioni',
      method: 'GET'
    },
    misuraRidottaSconto: {
      url: 'globale/lista/misura-ridotta-sconto',
      method: 'GET'
    },
    stampaFineTurno: {
      url: 'globale/lista/stampa-fine-turno',
      method: 'GET'
    },
    inserimentoPagamento: {
      url: 'globale/lista/inserimento-pagamento',
      method: 'GET'
    },
    esportazioneInfrazioni: {
      url: 'globale/lista/esportazione-infrazione',
      method: 'GET'
    },
    statoAcquisizioneZtl: {
      url: 'globale/lista/stato-acquisizione',
      method: 'GET'
    },
    tipoVisualizzazioneFoto: {
      url: 'globale/lista/tipo-visualizzazione-foto',
      method: 'GET'
    },
    iterRilevazione: {
      url: 'globale/lista/iter-rilevazione',
      method: 'GET'
    },
    tipoSpeseDaImpostare: {
      url: 'globale/lista/tipo-spese-impostare',
      method: 'GET'
    },
    tipiOperazioniVisure: {
      url: 'globale/lista/tipi-operazione-visure',
      method: 'GET'
    },
    statusVisura: {
      url: 'globale/lista/status-visura',
      method: 'GET'
    },
    sistemiOperativi: {
      url: 'sistema-operativo',
      method: 'GET'
    },
    violazioneNatura: {
      url: 'violazione/natura',
      method: 'GET'
    },
    statiFermoSequestro: {
      url: 'fermo-sequestro/lista/stati',
      method: 'GET'
    },
    trasportoACuraDi: {
      url: 'trasporto-a-cura-di/lista/trasporti-a-cura-di',
      method: 'GET'
    },
    listaTipiAffidamento: {
      url: 'tipo-affidamento/lista/tipi-affidamento',
      method: 'GET'
    },
    sorgentiVisure: {
      url: 'globale/lista/sorgente-visura',
      method: 'GET'
    },
    statiDecurtazionePunti: {
      url: 'decurtazione-punti/lista/stati',
      method: 'GET'
    }
  },
  generali: {
    univocitaModuloFunzioneStampa: {
      url: 'funzione-stampa/ricerca/unique',
      method: 'GET'
    },
    statisticheSpedizioni: {
      url: 'statistiche-spedizioni/ricerca-veloce',
      method: 'GET'
    }
  }
});