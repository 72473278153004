"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatApiAutomaticaPostaDetail = exports.formatBollettinoSepaBpDetail = exports.formatBollettinoReportDetail = exports.formatBollettinoTxtDetail = exports.parseData = exports.parseImporto = void 0;

const parseImporto = importo => {
  const parteDecimale = importo.slice(-2);
  const parteIntera = importo.slice(0, -2);
  return parseFloat(parteIntera.concat('.', parteDecimale)).toFixed(2).concat('€');
};

exports.parseImporto = parseImporto;

const parseData = (date, dateFormat, dateFormatResult) => moment(date, dateFormat).format(dateFormatResult);
/**
 * TRACCIATO PER BOLLETTINO_TXT
 * {
 *   "PROGRESSIVO_CARICAMENTO": "08543908",
 *   "PROGRESSIVO_SELEZIONE": "0000001",
 *   "NUMERO_CC_BENEFICIARIO": "000069000248",
 *   "DATA_ACCETTAZIONE": "210607",
 *   "TIPO_DOCUMENTO": "674",
 *   "IMPORTO": "0000002940",
 *   "UFFICIO_E_SPORTELLO": "04201602",
 *   "DIVISA": "2",
 *   "DATA_CONTABILE_ACCREDITO": "210609",
 *   "CODICE_CLIENTE_CODICE_BANCOPOSTA": "0060039320211100",
 *   "CIN": "37",
 *   "TIPOLOGIA_ACCETTAZIONE_A_SPORTELLO": "DI",
 *   "BOLLETTINO_SOSTITUTIVO": "N",
 *   "aggId": "c0b03a26-f78b-e7fa-c748-01e01dd65772"
 * }
 */


exports.parseData = parseData;

const formatBollettinoTxtDetail = tracciato => {
  const result = {
    "Progressivo caricamento": tracciato.PROGRESSIVO_CARICAMENTO || "N.D.",
    "Progressivo selezione": tracciato.PROGRESSIVO_SELEZIONE || "N.D.",
    "Numero CC beneficiario": tracciato.NUMERO_CC_BENEFICIARIO || "N.D.",
    "Data accettazione": tracciato.DATA_ACCETTAZIONE ? parseData(tracciato.DATA_ACCETTAZIONE, "YYMMDD", "L") : "N.D.",
    "Tipo documento": tracciato.TIPO_DOCUMENTO || "N.D.",
    "Importo": tracciato.IMPORTO ? parseImporto(tracciato.IMPORTO) : "N.D.",
    "Ufficio e sportello": tracciato.UFFICIO_E_SPORTELLO || "N.D.",
    "Divisa": tracciato.DIVISA || "N.D.",
    "Data contabile accredito": tracciato.DATA_CONTABILE_ACCREDITO ? parseData(tracciato.DATA_CONTABILE_ACCREDITO, "YYMMDD", "L") : "N.D.",
    "Codice cliente codice bancoposta": tracciato.CODICE_CLIENTE_CODICE_BANCOPOSTA || "N.D.",
    "Cin": tracciato.CIN || "N.D.",
    "Tipologia accettazione a sportello": tracciato.TIPOLOGIA_ACCETTAZIONE_A_SPORTELLO || "N.D.",
    "Bollettino sostitutivo": tracciato.BOLLETTINO_SOSTITUTIVO || "N.D."
  };
  return result;
};
/**
 * TRACCIATO PER BOLLETTINO_REPORT
 * {
 *   "ACCOUNTNUMBER": "000010734242",
 *   "LOCATION": "VE",
 *   "DATACARICAMENTO": "",
 *   "DATAOPERAZIONE": "20150408",
 *   "CODICEPROVINCIA": "010",
 *   "CODICEUFFICIO": "0279",
 *   "TIPODOCUMENTO": "674",
 *   "IMPORTODOCUMENTO": "000000000002870",
 *   "DATAPREALLIBRAMENTO": "",
 *   "DATAPOSTALLIBRAMENTO": "20150410",
 *   "QUARTOCAMPO": "*0000018615651631*",
 *   "PROGRESSIVODIMARCAGGIO": "",
 *   "NCONTOTRAENTE": "",
 *   "DIVISA": "E",
 *   "FLAGINSANABILI": "B",
 *   "PROGRESSIVODISELEZIONE": "",
 *   "REPORT_VERSION": "*20150410164847 *",
 *   "SV": "X",
 *   "Immagine fronte": "BR_3_VE31073424220150409B000009FA.tif",
 *   "Immagine retro": "",
 *   "aggId": "8baadb60-314a-eb91-dd0d-1e63b05f3d4a"
 * }
 */


exports.formatBollettinoTxtDetail = formatBollettinoTxtDetail;

const formatBollettinoReportDetail = tracciato => {
  const result = {
    "Account number": tracciato.ACCOUNTNUMBER || "N.D.",
    "Location": tracciato.LOCATION || "N.D.",
    "Data caricamento": tracciato.DATACARICAMENTO ? parseData(tracciato.DATACARICAMENTO, "YYYYMMDD", "L") : "N.D.",
    "Data operazione": tracciato.DATAOPERAZIONE ? parseData(tracciato.DATAOPERAZIONE, "YYYYMMDD", "L") : "N.D.",
    "Codice provincia": tracciato.CODICEPROVINCIA || "N.D.",
    "Codice ufficio": tracciato.CODICEUFFICIO || "N.D.",
    "Tipo documento": tracciato.TIPODOCUMENTO || "N.D.",
    "Importo documento": tracciato.IMPORTODOCUMENTO ? parseImporto(tracciato.IMPORTODOCUMENTO) : "N.D.",
    "Data pre allibramento": tracciato.DATAPREALLIBRAMENTO ? parseData(tracciato.DATAPREALLIBRAMENTO, "YYYYMMDD", "L") : "N.D.",
    "Data post allibramento": tracciato.DATAPOSTALLIBRAMENTO ? parseData(tracciato.DATAPOSTALLIBRAMENTO, "YYYYMMDD", "L") : "N.D.",
    "Quartocampo": tracciato.QUARTOCAMPO || "N.D.",
    "Progressivo di marcaggio": tracciato.PROGRESSIVODIMARCAGGIO || "N.D.",
    "N contraente": tracciato.NCONTOTRAENTE || "N.D.",
    "Divisa": tracciato.DIVISA || "N.D.",
    "Flag insanabili": tracciato.FLAGINSANABILI || "N.D.",
    "Progressivo di selezione": tracciato.PROGRESSIVODISELEZIONE || "N.D.",
    "Report version": tracciato.REPORT_VERSION || "N.D.",
    "SV": tracciato.SV || "N.D."
  };
  return result;
};
/**
 *
 */


exports.formatBollettinoReportDetail = formatBollettinoReportDetail;

const formatBollettinoSepaBpDetail = tracciato => {
  const result = {
    "Ordinante": tracciato['12'] || 'N.D.',
    "Importo": tracciato['13'] || 'N.D.',
    "Data versamento": tracciato['15'] ? parseData(tracciato['15'], "YYYYMMDD", "L") : 'N.D.',
    "Data contabile": tracciato['16'] ? parseData(tracciato['16'], "YYYYMMDD", "L") : 'N.D.',
    "Causale": tracciato['21'] || 'N.D.'
  };
  return result;
};
/**
 * TRACCIATO PER API_AUTOMATICA_POSTA
 * {
 *   "quartoCampo": "006018212021110034",
 *   "tipoVersamento": "banca",
 *   "metodoVersamento": "ufficio_postale",
 *   "dataVersamento": 1561593600000,
 *   "dataContabile": 1546300800000,
 *   "importoVersamento": 116.9,
 *   "intestatarioRiferimento": "Nome Cognome",
 *   "numeroVersamento": "6666",
 *   "causaleBonifico": "Pagamento verbale 195315133/V/2021",
 *   "trnBonifico": "??",
 *   "attribuzioneAutomatica": false,
 *   "rilevazioneId": "7651c922-18d1-4ff5-83ae-56f46a6f3540",
 *   "verbaleDaPagare": null
 * }
 */


exports.formatBollettinoSepaBpDetail = formatBollettinoSepaBpDetail;

const formatApiAutomaticaPostaDetail = tracciato => {
  const result = {
    "Quarto campo": tracciato.quartoCampo || "N.D.",
    "Tipo versamento": tracciato.tipoVersamento || "N.D.",
    "Metodo versamento": tracciato.metodoVersamento || "N.D.",
    "Data versamento": tracciato.dataVersamento ? moment(tracciato.dataVersamento).format('L') : "N.D.",
    "Data contabile": tracciato.dataContabile ? moment(tracciato.dataContabile).format('L') : "N.D.",
    "Importo versamento": tracciato.importoVersamento ? tracciato.importoVersamento.toFixed(2).concat('€') : "N.D.",
    "Intestatario riferimento": tracciato.intestatarioRiferimento || "N.D.",
    "Numero versamento": tracciato.numeroVersamento || "N.D.",
    "Causale bonifico": tracciato.causaleBonifico || "N.D.",
    "Trn bonifico": tracciato.trnBonifico || "N.D."
  };
  return result;
};

exports.formatApiAutomaticaPostaDetail = formatApiAutomaticaPostaDetail;