"use strict";

/**
 * Refactored by GB on 27/05/2018
 *
 * Component per gestire i filtri veloci su osList.
 * Viene configurato attraverso la property quickFilters come array contenete un oggetto per tipologia di filtro.
 * Per ogni filtro è possibile impostare le proprietà
 * * name
 * * label
 * * type (text, number, enum, dateRange)
 * * disabled
 * * url (in caso di enum)
 *
 * Spara quickFilterAdd, quickFilterRemove, quickFilterReset
 *
 */
(function () {
  'use strict';

  angular.module('osListComponent').component('quickFilter', {
    //per come è definita osList serve un riferimento assoluto rispetto alla root
    templateUrl: 'osFramework/osList/quickFilter/quickFilter.component.html',
    controller: ['api', '$scope', '$filter', QuickFilterController],
    controllerAs: 'QuickFilterCtrl',
    bindings: {
      //elasticSearch           : '<',
      activeFilters: '<',
      quickFilters: '<',
      quickFilterAddHandler: '&',
      quickFilterRemoveHandler: '&',
      quickFilterResetHandler: '&'
    }
  });

  function QuickFilterController(api, $scope, $filter) {
    var me = this,
        allFilters = [];

    me.$onInit = function () {};
    /**
     * @event
     * Evento scatenato alla selezione di un filtro
     * $emit osList::quickFilterAdd
     * @params {clickedFilter}
     * @type {Function}
     */

    /**
     * @event
     * Evento scatenato alla rimozione di un filtro
     * $emit osList::quickFilterRemove
     * @params {key} La chiave del filtro rimosso
     * @type {Function}
     */

    /**
     * @event
     * Evento scatenato al reset dei filtri
     * $emit osList::quickFilterReset
     * @type {Function}
     */

    /**
     * GB 27/05/2018
     * Il metodo rimuove un filtro dagli activeFilters
     * @type {removeFilter}
     */


    me.removeFilter = removeFilter;
    /**
     * GB 27/05/2018
     * Handler per gestire la visualizzazione del bottone "Rimuovi Filtri"
     * @type {function(): boolean}
     */

    me.hasFilters = false;
    me.normalizedActiveFilters = {};
    $scope.$watch(() => me.activeFilters, (newValue, oldValue) => {
      if (newValue && newValue.length) {
        me.hasFilters = true;
        newValue.map(function (activeFilter) {
          var filter = R.find(R.propEq('name', activeFilter.name))(allFilters);
          me.normalizedActiveFilters[activeFilter.name] = Object.assign({}, me.normalizedActiveFilters[activeFilter.name] || {}, {
            label: filter.label,
            value: getFilterValue(activeFilter, filter.type, me.normalizedActiveFilters[activeFilter.name]),
            type: filter.type
          });
        });
      } else {
        //Reset dei filtri
        me.hasFilters = false;
        me.normalizedActiveFilters = {};
      }
    }, true);
    $scope.$watch(() => me.quickFilters, (newValue, oldValue) => {
      //workaround per mantenere la lista iniziale dei filtri
      allFilters = newValue && newValue.length > allFilters.length ? newValue : allFilters;
    });

    function getFilterValue(filter, type, normalizedFilter) {
      let value = null;

      switch (type) {
        case 'checkbox':
          value = filter.value === true ? 'SI' : 'NO';
          break;

        case 'dateRange':
          value = `${$filter('date')(filter.value[0], 'dd/MM/yyyy')} - ${$filter('date')(filter.value[1], 'dd/MM/yyyy')}`;
          break;

        case 'dateRangeDataDefinizione':
          value = `${$filter('date')(filter.value[0], 'dd/MM/yyyy')} - ${$filter('date')(filter.value[1], 'dd/MM/yyyy')}`;
          break;

        case 'ricercaLiberaVerbali':
          return normalizedFilter.verbale.verbaleId;
          break;

        case 'ricercaTipoAccertamento':
          return normalizedFilter.tipoAccertamento.codiceVeloce.concat(' - ', normalizedFilter.tipoAccertamento.nome);
          break;

        default:
          value = filter.value;
          break;
      }

      return value;
    }
    /**
     * GB 27/05/2018
     * Handler scatenato alla pressione del bottone "Rimuovi Filtri"
     * @type {clearFilters}
     */


    me.onClearFiltersClick = clearFilters;
    /**
     * GB 29/05/2018
     * Metodo utilizzato dagli activeFilter per sapere se il filtro attivo è di tipo dateRange
     * @type {function(*=): boolean}
     */

    me.isDateFilter = isDateFilter;
    /**
     * GB 12/07/2018
     * Propietà di appoggio per i risultati degli enum da visualizzare nelle select
     * @type {{}}
     */

    me.enum = {};
    /**
     * GB 12/07/2018
     * Handler che gestisce una query locale sugli item del filtro elastic
     * @param query
     * @param items
     * @returns {*}
     */

    me.queryItems = function (query, items) {
      var result = [];

      if (!query) {
        return items;
      }

      R.map(function (item) {
        if (item.value.indexOf(query) != -1) {
          result.push(item);
        }
      }, items);
      return result;
    };
    /**
     * GB 12/07/2018
     * Il medoto recupera dal server i valori di un enum field
     * @param item
     */


    me.queryRemoteItems = function (item) {
      fetchFilterValues(item.url).then(function (result) {
        vm.enum[item.alias] = result;
      });
    };
    /**
     * GB 27/05/2018
     * Il meodoto resituisce true / false a seconda che ci siano o meno filtri impostati
     * @returns {boolean}
     */

    /*function hasFilters() {
        return Object.keys(vm.activeFilters).length !== 0;
    }*/

    /**
     * GB 08/05/2018
     * Il metodo verifica se il tipo campo passato come argomento è un date
     * @param filter
     * @returns {boolean}
     */


    function isDateFilter(filter) {
      var isDateFilter = false; //Se il filtro è di tipo date

      if (filter.type && filter.type === 'dateRange') {
        isDateFilter = true;
      }

      return isDateFilter;
    }
    /**
     * Handler invocato alla selezione di un item dai campi elastic
     * @param item
     * @param selectedItem
     */


    me.addElasticFilter = function (item, selectedItem) {
      var filter = {
        type: item.type,
        label: item.label,
        name: item.name,
        value: selectedItem.value
      };
      addFilter(filter);
    };
    /**
     * Handler per l'aggiunta di un filtro dai componenti che estendono osRicercaEntità
     * @param params
     */


    me.addFilterFromRicerca = function (params) {
      var {
        entity,
        item
      } = params;

      if (entity) {
        var filter = {
          type: item.type,
          label: item.label,
          name: item.name,
          value: entity[item.filterProp] || entity[item.queryProp] || entity[item.name]
        };
        addFilter(filter);
      }
    };
    /**
     * Handler invocato alla selezione di un item dal campo select (enum)
     * @param item
     * @param selectedItem
     */


    me.addSelectFilter = function (item, selectedItem) {
      var filter = {
        type: item.type,
        name: item.name,
        label: item.label,
        value: selectedItem
      };
      addFilter(filter);
    };
    /**
     * Handler invocato alla selezione di un item dal campo dateRange
     * @param item
     * @param selectedItem
     */


    me.addDateFilter = function (item, minDate, maxDate) {
      if (!minDate || !maxDate) {
        return;
      }

      var filter = {
        type: item.type,
        label: item.label,
        name: item.name,
        value: [minDate, maxDate]
      };
      addFilter(filter);
    };
    /**
     * Handler invocato alla selezione di un item dai campi text e number
     * @param item
     * @param selectedItem
     */


    me.addDefaultFilter = function (item, value) {
      var filter = {
        type: item.type,
        label: item.label,
        name: item.name,
        value: value
      };
      addFilter(filter);
    };
    /**
     * GB 27/05/2018
     * Il metodo lancia un evento quickFilterChange al parent
     * @param clickedFilter
     */


    function addFilter(filter) {
      if (filterIsAlreadyInPlace(filter)) {
        return;
      } //Se !value il filtro va rimosso (ho cancellato manualmente)


      if (!filter.value) {
        removeFilter(filter.name);
      } else {
        me.quickFilterAddHandler({
          filter: filter
        });
        $scope.$emit('osList::quickFilterAdd', filter);
      }
    }

    function filterIsAlreadyInPlace(filter) {
      var result;

      if (R.isEmpty(me.activeFilters)) {
        return;
      }

      for (var f of me.activeFilters) {
        // SE VOGLIO AGGIUNGERE DUE FILTRI DIVERSI LO POSSO FARE

        /*if( f.name !== filter.name ) {
            result = false;
        }*/
        // SE STO CERCANDO DI AGGIUNGERE LO STESSO FILTRO CON LO STESSO VALORE BLOCCO TUTTO ALTRIMENTI VADO
        // AVANTI E TOLGO QUELLO VECCHIO PER NON AVERE LO STESSO FILTRO CON DUE VALORI DIVERSI
        if (f.name === filter.name && f.value === filter.value) {
          result = true;
          break;
        } else {
          //removeFilter(f.name);
          result = false;
        }
      }

      return result;
    }
    /**
     * GB 27/05/2018
     * Il metodo lancia un evento quickFilterChange al padre
     * @param key
     */


    function removeFilter(key) {
      me.quickFilterRemoveHandler({
        filterKey: key
      });
      $scope.$emit('osList::quickFilterRemove', key);
    }
    /**
     * GB 27/05/2018
     * Il metodo lancia un quickFilterReset al padre
     */


    function clearFilters() {
      me.quickFilterResetHandler();
      me.minDate = null;
      me.maxDate = null;
      me.minDateDataDefinizione = null;
      me.maxDateDataDefinizione = null;
      $scope.$emit('osList::quickFilterReset');
      $scope.$broadcast('osList::quickFilterReset');
    } // ---------- Utils

    /**
     * GB 27/08/2018
     * Interroga un endpoint del server
     * @param url
     * @returns {*}
     */


    function fetchFilterValues(url) {
      return api.execute({
        url: url
      });
    }
  }
})();