"use strict";

/**
 * Created by MN 29/10/2019
 */
(function () {
  'use strict';

  angular.module('polcity.acquisizioneDati').component('rilevazioniValidazioneImmagini', {
    templateUrl: 'acquisizioneDati/rilevazioni/validazione/rilevazioniValidazioneImmagini.component.html',
    controller: 'rilevazioniValidazioneImmaginiController'
  }).controller('rilevazioniValidazioneImmaginiController', ['$scope', 'OSNotification', '$stateParams', '$state', 'modalPresenter', 'Command', 'rolesGuardService', RilevazioniValidazioneImmaginiController]);

  function RilevazioniValidazioneImmaginiController($scope, OSNotification, $stateParams, $state, modalPresenter, Command, rolesGuardService) {
    var me = this,
        rilevazioneDetail;
    me.collection = $stateParams.rilevazioniDaValidare;
    me.tipoValidazione = $stateParams.milestone;
    me.activeIndex;
    me.coreVerbale = {};
    me.annullamento = {};
    me.modelliDiStampa = [];

    me.$onInit = function () {
      if (!me.collection || !me.collection.length) {
        $state.go('navigation.acquisizioneDati.rilevazioni');
        return;
      }

      if ($stateParams.rilevazioniDaValidare.length === 1) {
        me.rilevazione = $stateParams.rilevazioniDaValidare[0];
      }
    };
    /**
     * Watchers
     */


    $scope.$watch(() => me.rilevazione, function (rilevazione) {
      // IL TIPO RILEVATORE "assicurazione_revisione" NON PREVEDE IMMAGINI PER CUI EVITO DI FARE LA CHIAMATA PER RECUPERARLA
      if (rilevazione && rilevazione.tipoRilevatore !== 'assicurazione_revisione') {
        rilevazione.$getImmaginiRilevazione().$asPromise().then(function (res) {
          getRilevazioneDetail();
        }).catch(function (err) {
          OSNotification.warn({
            message: 'Non è stato possibile recuperare l\'immagine'
          });
        });
      } else if (rilevazione) {
        getRilevazioneDetail();
      }
    });
    $scope.$watch(() => me.collection, function (collection) {
      me.canNavigate = collection.length > 1;
    });
    /**
     * Handlers
     */

    me.onRilevazioneChange = function (params) {
      me.rilevazione = params.activeItem;
    };

    me.onOpenRigettaValidazioneForm = function () {
      toggleCausaleRequired();
    };

    me.onAnnullaRigettaValidazione = function (rigettaValidazioneForm) {
      resetRigettaValidazione(rigettaValidazioneForm);
    };

    me.onAccettaValidazione = function (validazioneImmaginiForm) {
      askUserConfirmation('info', 'acquisizioneDati.validazioneRilevazioni.validazioneRilevazione__title', 'acquisizioneDati.validazioneRilevazioni.validazioneRilevazione__body', 'acquisizioneDati.validazioneRilevazioni.validazioneRilevazioneConfirm__button').then(accettaValidazione.bind(me)).catch(function (err) {}).finally(function () {});
    };

    me.onRigettaValidazione = function (rigettaValidazioneForm) {
      askUserConfirmation('info', 'acquisizioneDati.validazioneRilevazioni.rigettaValidazione__title', 'acquisizioneDati.validazioneRilevazioni.rigettaValidazione__body', 'acquisizioneDati.validazioneRilevazioni.validazioneRilevazioneConfirm__button').then(rigettaValidazione.bind(me)).catch(function (err) {}).finally(function () {
        resetRigettaValidazione(rigettaValidazioneForm);
      });
    };

    me.onVerbalizzaValidazione = function (verbalizzaValidazioneForm) {
      askUserConfirmation('info', 'acquisizioneDati.validazioneRilevazioni.verbalizzaRilevazione__title', 'acquisizioneDati.validazioneRilevazioni.verbalizzaRilevazione__body', 'acquisizioneDati.validazioneRilevazioni.validazioneRilevazioneConfirm__button').then(verbalizzaValidazione.bind(me)).catch(function (err) {}).finally(function () {});
    };

    me.onRigettaVerbalizzazione = function (rigettaVerbalizzazioneForm) {
      askUserConfirmation('info', 'acquisizioneDati.validazioneRilevazioni.rigettaVerbalizzazione__title', 'acquisizioneDati.validazioneRilevazioni.rigettaVerbalizzazione__body', 'acquisizioneDati.validazioneRilevazioni.validazioneRilevazioneConfirm__button').then(rigettaVerbalizzazione.bind(me)).catch(function (err) {}).finally(function () {
        resetRigettaValidazione(rigettaVerbalizzazioneForm);
      });
    };
    /**
     * Apis
     */


    function getRilevazioneDetail() {
      me.rilevazione.$fetch();
      me.rilevazione.$on('after-fetch', function (res) {
        rilevazioneDetail = res.data;
        setCoreVerbale();
        fetchModelliStampa();
        getInfrazioneCommessa();
      });
    }

    function fetchModelliStampa() {
      if (!rolesGuardService.userHasRole('ROLE_MODELLO_STAMPA_RICERCA')) {
        return;
      }

      return Command.execute('@ricercaModelliStampa').then(function (res) {
        me.modelliDiStampa = res.resultsWrapper.values;
      });
    }

    function getInfrazioneCommessa() {
      return Command.execute('@dettaglioViolazione', {
        aggId: me.coreVerbale.infrazioni[0]
      }).then(function (res) {
        me.infrazioneCommessa = `${res.codiceLibreria} - ${res.codice} - ${res.descrizione}`;
      });
    }

    function accettaValidazione() {
      var tracciatoForApi = prepareTracciatoLavorabileForApi();
      me.rilevazione.$accettaValidazione(tracciatoForApi);
      me.rilevazione.$on('after-accetta-validazione', function (res) {
        me.rilevazione.$validata = true;
        incrementActiveIndex(me.rilevazione);
        notifyUser('success', 'acquisizioneDati.validazioneRilevazioni.validazioneRilevazioneSuccess__notification');
      });
      me.rilevazione.$on('after-accetta-validazione-error', function (res) {
        notifyUser('success', 'acquisizioneDati.validazioneRilevazioni.validazioneRilevazioneError__notification');
      });
    }

    function rigettaValidazione() {
      var causale = angular.copy(me.annullamento.causale),
          tracciatoResult = prepareTracciatoLavorabileForApi();
      me.rilevazione.$rigettaValidazione(causale, tracciatoResult);
      me.rilevazione.$on('after-rigetta-validazione', function (res) {
        me.rilevazione.$rigettata = true;
        incrementActiveIndex(me.rilevazione);
        notifyUser('success', 'acquisizioneDati.validazioneRilevazioni.rigettaValidazioneSuccess__notification');
      });
      me.rilevazione.$on('after-rigetta-validazione-error', function (res) {
        notifyUser('success', 'acquisizioneDati.validazioneRilevazioni.rigettaValidazioneError__notification');
      });
    }

    function verbalizzaValidazione() {
      me.rilevazione.$verbalizzaValidazione(me.coreVerbale);
      me.rilevazione.$on('after-verbalizza-validazione', function (res) {
        me.rilevazione.$verbalizzata = true;
        incrementActiveIndex(me.rilevazione);
        notifyUser('success', 'acquisizioneDati.validazioneRilevazioni.verbalizzazioneRilevazioneSuccess__notification');
      });
      me.rilevazione.$on('after-verbalizza-validazione-error', function (res) {
        notifyUser('success', 'acquisizioneDati.validazioneRilevazioni.verbalizzazioneRilevazioneError__notification');
      });
    }

    function rigettaVerbalizzazione() {
      var causale = angular.copy(me.annullamento.causale),
          tracciatoResult = prepareTracciatoLavorabileForApi();
      me.rilevazione.$rigettaValidazioneFinale(causale, tracciatoResult);
      me.rilevazione.$on('after-rigetta-validazione-finale', function (res) {
        me.rilevazione.$rigettataValidazioneFinale = true;
        incrementActiveIndex(me.rilevazione);
        notifyUser('success', 'acquisizioneDati.validazioneRilevazioni.rigettaValidazioneSuccess__notification');
      });
      me.rilevazione.$on('after-rigetta-validazione-finale-error', function (res) {
        notifyUser('success', 'acquisizioneDati.validazioneRilevazioni.rigettaValidazioneError__notification');
      });
    }
    /**
     * Methods implementation
     */


    function setCoreVerbale() {
      me.coreVerbale = retrieveCoreVerbale(); // innesto un oggetto tipoRelazioneStrada per permettere alla direttiva localitaVerbale di funzionare correttamente

      me.coreVerbale.tipoRelazioneStrada = {
        luogoAccertamentoTipoRelazioneStradaTipo: me.coreVerbale.luogoAccertamentoTipoRelazioneStradaTipo,
        luogoAccertamentoTipoRelazioneStradaStradaId: me.coreVerbale.luogoAccertamentoTipoRelazioneStradaStradaId,
        luogoAccertamentoTipoRelazioneStradaCivico: me.coreVerbale.luogoAccertamentoTipoRelazioneStradaCivico
      };
    }

    function retrieveCoreVerbale() {
      var tracciatoLavorabile, coreVerbale;
      tracciatoLavorabile = JSON.parse(rilevazioneDetail.tracciatoLavorabile);
      coreVerbale = JSON.parse(tracciatoLavorabile.variabiliProcesso.coreVerbale);
      return coreVerbale;
    }

    function prepareTracciatoLavorabileForApi() {
      var tracciatoLavorabileForApi;
      me.coreVerbale.luogoAccertamentoTipoRelazioneStradaTipo = me.coreVerbale.tipoRelazioneStrada.luogoAccertamentoTipoRelazioneStradaTipo;
      me.coreVerbale.luogoAccertamentoTipoRelazioneStradaStradaId = me.coreVerbale.tipoRelazioneStrada.luogoAccertamentoTipoRelazioneStradaStradaId;
      me.coreVerbale.luogoAccertamentoTipoRelazioneStradaCivico = me.coreVerbale.tipoRelazioneStrada.luogoAccertamentoTipoRelazioneStradaCivico;
      delete me.coreVerbale.tipoRelazioneStrada;
      tracciatoLavorabileForApi = JSON.parse(rilevazioneDetail.tracciatoLavorabile);
      tracciatoLavorabileForApi.variabiliProcesso.coreVerbale = JSON.stringify(me.coreVerbale);
      return tracciatoLavorabileForApi;
    }
    /**
     * Utils
     */


    function toggleCausaleRequired() {
      me.causaleRequired = !me.causaleRequired;
    }

    function resetForm(form) {
      if (form) {
        form.$setPristine();
        form.$setUntouched();
      }
    }

    function notifyUser(type, msg) {
      return OSNotification[type]({
        translationIndex: msg
      });
    }

    function askUserConfirmation(type, title, body, confirmBtn) {
      return modalPresenter[type]([title, body, confirmBtn, 'acquisizioneDati.validazioneRilevazioni.validazioneRilevazioneUndo__button']);
    }

    function incrementActiveIndex(rilevazione) {
      var rilevazioneIndex;

      for (var i = 0; i < me.collection.length; i++) {
        if (rilevazione.aggId === me.collection[i].aggId) {
          rilevazioneIndex = i;
        }
      } // me.activeIndex = rilevazioneIndex + 1;


      if (me.collection.$metadata.search.page > 1) {
        me.activeIndex = me.collection.$metadata.search.limit * (me.collection.$metadata.search.page - 1) + rilevazioneIndex + 1;
      } else {
        me.activeIndex = rilevazioneIndex + 1;
      }
    }

    function resetRigettaValidazione(rigettaValidazioneForm) {
      me.annullamento = {};
      resetForm(rigettaValidazioneForm);
      toggleCausaleRequired();
    }
  }
})();