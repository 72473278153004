"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGoToNavigation = void 0;

var _react = require("@uirouter/react");

var _routes = require("./routes");

const useGoToNavigation = ({
  name
}) => {
  const router = (0, _react.useRouter)();
  return params => router.stateService.go(`navigation.${_routes.routes[name]}`, params);
};

exports.useGoToNavigation = useGoToNavigation;