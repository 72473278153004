"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.currentSelectedMenuItem = exports._limit = exports._page = exports._trigger = void 0;

var _recoil = require("recoil");

const defaultPagination = {
  page: 1,
  limit: 10
};

const _trigger = (0, _recoil.atomFamily)({
  key: '_trigger',
  default: 0
});

exports._trigger = _trigger;

const _page = (0, _recoil.atomFamily)({
  key: '_page',
  default: defaultPagination.page
});

exports._page = _page;

const _limit = (0, _recoil.atomFamily)({
  key: '_limit',
  default: defaultPagination.limit
});

exports._limit = _limit;
const currentSelectedMenuItem = (0, _recoil.atom)({
  key: 'currentSelectedMenuItem',
  default: null
});
exports.currentSelectedMenuItem = currentSelectedMenuItem;