"use strict";

(function () {
  'use strict';

  angular.module('osCollectionCarousel').controller('osCollectionCarouselController', ['$scope', '$rootScope', '$state', osCollectionCarouselController]);

  function osCollectionCarouselController($scope, $rootScope, $state) {
    var me = this;
    let allCollection = [];
    me.activeIndex;
    me.activeItem = null;
    me.page;
    me.limit;

    me.$onInit = function () {
      if (!me.collection || !me.collection.length) {
        $state.go($rootScope.$previousState.name, $rootScope.$previousState.params);
      }

      setPageLimitTotal();
      me.canNavigate = me.total > 1;
      me.activeIndex = me.activeIndex ? me.activeIndex : 0;
      allCollection = [...allCollection, ...me.collection];
      me.collection.$on('before-fetch-many');
      me.collection.$on('after-fetch-many', function () {
        setPageLimitTotal();
        me.activeItem = getActiveItem(me.activeIndex);
      });
      me.collection.$on('after-fetch-many-error');
    };
    /**
     * Watchers
     * Controllo che il valore di index sia all'interno della collection (compreso tra 1 ed il totale degli elementi)
     * Se non è dentro (in negativo o in positivo) lo ricalcolo fino ad ottenere un valore che può andare bene
     * Una volta ottenuto un valore soddisfacente imposto il limite inferiore della pagina ed il limite superiore
     * Se l'indice è compreso nel limite setto activeElement con il valore della collection
     * Se l'indice non è compreso nei limiti cambio pagina e ricarico la collection
     */


    $scope.$watch(() => me.activeIndex, function (index) {
      var page, lowerBound, upperBound;

      if (index === undefined || index === null) {
        return;
      }

      if (index > me.total - 1) {
        me.activeIndex = index - me.total;
        return;
      } else if (index < 0) {
        me.activeIndex = me.total + index;
        return;
      }

      if (me.onActiveIndexChange) {
        me.onActiveIndexChange({
          activeIndex: me.activeIndex
        });
      }

      lowerBound = 0;
      upperBound = getUpperBound(me.page, me.limit);

      if (index >= lowerBound && index < upperBound) {
        me.activeItem = getActiveItem(index);
      } else if (index >= upperBound) {
        page = setNewPage(index, me.limit);
        loadPage(page, me.limit);
      }
    });
    $scope.$watch(() => me.activeItem, function (item) {
      if (me.onActiveItemChange) {
        me.onActiveItemChange({
          activeItem: item
        });
      }
    });
    $scope.$watch(() => me.collection, function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        allCollection = [...allCollection, ...me.collection];
        me.activeItem = allCollection[me.activeIndex];
      }
    }, true);
    /**
     * Handlers
     */

    me.onPreviousClick = function () {
      decreaseIndex(1);
    };

    me.onNextClick = function () {
      increaseIndex(1);
    };
    /**
     * Methods implementation
     */
    // limite superiore pagina corrente -> pagina 1 upperBound = 10


    function getUpperBound(page, limit) {
      var upperBound = page * limit;
      return upperBound;
    }

    function getActiveItem(index) {
      // TODO: controlli parametri ingresso
      return allCollection[index];
    }

    function setPageLimitTotal() {
      me.page = me.collection.$metadata.search.page;
      me.limit = me.collection.$metadata.search.limit;
      me.total = me.collection.$metadata.total;
    }

    function setNewPage(index, limit) {
      var newPage;

      if (index % limit === 0) {
        newPage = Math.ceil(index / limit) + 1;
      } else {
        newPage = Math.ceil(index / limit);
      }

      return newPage;
    }

    function decreaseIndex(amount) {
      me.activeIndex = me.activeIndex - amount;
    }

    function increaseIndex(amount) {
      me.activeIndex = me.activeIndex + amount;
    }
    /**
     * Apis
     */


    function loadPage(page, limit) {
      return me.collection.$changePage(page, limit);
    }
  }
})();