"use strict";

/**
 * Created by MN 05/03/2020
 */
(function () {
  'use strict';

  angular.module('polcity.widgetTypes').component('countersWidgetComponent', {
    templateUrl: 'dashboard/widgetTypes/counters/counters.widget.component.html',
    controller: 'CountersComponentController',
    controllerAs: '$ctrl',
    bindings: {
      countersWidgetData: '<'
    }
  }).controller('CountersComponentController', ['$scope', CountersComponentController]);

  function CountersComponentController($scope) {
    var me = this;
    me.noDataAvaiable = false;
    me.data = [{
      value: 'rilevazione_acquisita',
      count: 0
    }, {
      value: 'fallito_start_processo',
      count: 0
    }, {
      value: 'in_attesa_validazione_immagini',
      count: 0
    }, {
      value: 'scartato',
      count: 0
    }, {
      value: 'in_attesa_validazione_finale',
      count: 0
    }, {
      value: 'validazione_finale_rigettata',
      count: 0
    }, {
      value: 'verbalizzato',
      count: 0
    }];
    $scope.$watch(() => me.countersWidgetData.data, function (newVal, oldVal) {
      onInit();
    }, true);
    me.$onInit = onInit;

    function onInit() {
      if (!me.countersWidgetData) {
        return;
      }

      if (!me.countersWidgetData.data || !me.countersWidgetData.data.length) {
        me.noDataAvaiable = true;
      } else {
        manageMilestoneData();
      }
    }
    /**
     * Handlers
     */


    me.onRemoveWidget = function (_widget) {
      $scope.$emit('remove-widget', _widget);
    };
    /**
     * Methods
     */


    function manageMilestoneData() {
      for (var v of me.data) {
        for (var i of me.countersWidgetData.data) {
          if (v.value === i.value) {
            v.count = i.count;
          }
        }
      }
    }
  }
})();