"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sanzioneAccessoriaValidationSchema = exports.toApiSanzioneAccessoria = exports.fromApiSanzioneAccessoria = exports.sanzioneAccessoriaEmptyForm = exports.useSanzioniAccessorie = void 0;

var _reactQuery = require("react-query");

var _recoil = require("recoil");

var _api = require("../api");

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const useSanzioniAccessorie = ({
  descrizione
}) => {
  const {
    isLoading,
    isFetching,
    isError,
    data,
    error
  } = (0, _reactQuery.useQuery)(['sanzioniAccessorie', descrizione], ({
    queryKey
  }) => (0, _api.ricercaSanzioniAccessorie)({
    query: queryKey[1].descrizione
  }), {
    staleTime: 1000 * 60 * 5 // 5 minutes

  });
  const items = isLoading ? [] : data.resultsWrapper.values;
  return {
    isLoading,
    isFetching,
    isError,
    data: items,
    error
  };
};

exports.useSanzioniAccessorie = useSanzioniAccessorie;
const sanzioneAccessoriaEmptyForm = {
  codiceVeloce: "",
  descrizione: "",
  descrizioneStampa: [],
  tipoCodice: "",
  decoratore: "sanz_acc_generica",
  motivazioneMancataApplicazione: null,
  applicabile: true
};
exports.sanzioneAccessoriaEmptyForm = sanzioneAccessoriaEmptyForm;

const fromApiSanzioneAccessoria = remoteSanzione => {
  const sanzione = { ...(remoteSanzione.aggId ? {
      aggId: remoteSanzione.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(remoteSanzione),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(remoteSanzione),
    descrizioneStampa: (0, _object.getPathArrayOrEmptyArray)(["descrizioneStampa"])(remoteSanzione),
    descrizioneStampa_transId: (0, _object.getPathStringOrEmptyString)(["descrizioneStampa_transId"])(remoteSanzione),
    tipoCodice: (0, _object.getPathStringOrEmptyString)(["tipoCodice"])(remoteSanzione),
    decoratore: (0, _object.getPathStringOrEmptyString)(["decoratore"])(remoteSanzione),
    motivazioneMancataApplicazione: (0, _object.getPathStringOrNull)(["motivazioneMancataApplicazione", "motivazioneMancataApplicazioneId"])(remoteSanzione) ? {
      codiceVeloce: (0, _object.getPathStringOrEmptyString)(["motivazioneMancataApplicazione", "mmaCodiceVeloce"])(remoteSanzione),
      nome: (0, _object.getPathStringOrEmptyString)(["motivazioneMancataApplicazione", "mmaNome"])(remoteSanzione),
      descrizione: (0, _object.getPathStringOrEmptyString)(["motivazioneMancataApplicazione", "mmaDescrizioneStampa"])(remoteSanzione)
    } : null,
    applicabile: (0, _object.getPathBooleanOrFalse)(["applicabile"])(remoteSanzione)
  };
  return sanzione;
};

exports.fromApiSanzioneAccessoria = fromApiSanzioneAccessoria;

const toApiSanzioneAccessoria = values => {
  const sanzione = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(values),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(values),
    descrizioneStampa: (0, _object.getPathArrayOrEmptyArray)(["descrizioneStampa"])(values),
    descrizioneStampa_transId: (0, _object.getPathStringOrEmptyString)(["descrizioneStampa_transId"])(values),
    tipoCodice: (0, _object.getPathStringOrEmptyString)(["tipoCodice"])(values),
    decoratore: (0, _object.getPathStringOrEmptyString)(["decoratore"])(values),
    motivazioneMancataApplicazioneId: (0, _object.getPathStringOrEmptyString)(["motivazioneMancataApplicazione", "aggId"])(values),
    applicabile: (0, _object.getPathBooleanOrFalse)(["applicabile"])(values)
  };
  return sanzione;
};

exports.toApiSanzioneAccessoria = toApiSanzioneAccessoria;
const sanzioneAccessoriaValidationSchema = Yup.object().shape({
  codiceVeloce: Yup.string().required(_utils.requiredMessage),
  descrizione: Yup.string().required(_utils.requiredMessage),
  descrizioneStampa: Yup.array().ensure().min(1, _utils.requiredMessage).of(Yup.object().shape({
    traduzione: Yup.string().required(_utils.requiredMessage),
    languageTag: Yup.string().required(_utils.requiredMessage)
  })),
  tipoCodice: Yup.string().required(_utils.requiredMessage)
});
exports.sanzioneAccessoriaValidationSchema = sanzioneAccessoriaValidationSchema;