"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTdsHandleSave = exports.useTdsHandleError = exports.useTdsAggiungiModificaElementoReactQuery = exports.useTdsCancellaElementoReactQuery = exports.useTdsDettaglioElementoReactQuery = exports.useTdsRicercaReactQuery = exports.tdsListQueryParams = void 0;

var _recoil = require("recoil");

var _tdsApiMap = require("./tdsApiMap");

var _reactQuery = require("react-query");

var _messages = require("../global/messages");

var _react = require("react");

var _react2 = require("@uirouter/react");

var _utils = require("../../react/reactQuery/utils");

var _utils2 = require("../../utils");

const defaultListQueryParams = {
  page: 1,
  limit: 10,
  order: null,
  query: ""
};
const tdsListQueryParams = (0, _recoil.atomFamily)({
  key: 'tdsListQueryParams',
  default: ({
    name,
    params
  }) => {
    return { ...defaultListQueryParams,
      ...params
    };
  }
});
exports.tdsListQueryParams = tdsListQueryParams;

const useTdsRicercaReactQuery = ({
  name,
  params = {},
  minLength = 1,
  onSuccess
}) => {
  const [state, setState] = (0, _recoil.useRecoilState)(tdsListQueryParams({
    name,
    params
  }));
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    isLoading,
    data,
    isFetching
  } = (0, _reactQuery.useQuery)([`tds.`, name, state], ({
    queryKey
  }) => _tdsApiMap.tdsApiMap[name].searchApi(queryKey[2]), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    enabled: !Boolean(state.query) || Boolean(state.query.length > minLength),
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response)
  }); // COMMENTATO PER EVITARE CHE AD OGNI CAMBIO DI ROTTA, QUINDI DA LISTA A EDIT FACCIA PARTIRE UNA NUOVA CHIAMATA

  /* useEffect(() => {
       // Resettiamo lo stato quando usciamo dalla rotta radice
       window.$transitions.onExit({exiting: `navigation.tds${name}`}, () => {
           queryClient.invalidateQueries([`tds.`, name])
           setState(state => ({...state, ...defaultListQueryParams}))
       });
   }, []);*/

  return {
    items: (data === null || data === void 0 ? void 0 : data.resultsWrapper.values) || [],
    count: (data === null || data === void 0 ? void 0 : data.resultsWrapper.count) || 0,
    page: state.page,
    limit: state.limit,
    isLoading: isLoading || isFetching,
    changePage: page => setState(state => ({ ...state,
      page: page
    })),
    changeLimit: limit => setState(state => ({ ...state,
      page: 1,
      limit: limit
    })),
    changeOrder: order => setState(state => ({ ...state,
      order: order
    })),
    changeSearch: query => setState(state => ({ ...state,
      page: 1,
      query: query
    })),
    refresh: () => queryClient.invalidateQueries([`tds.`, name]),
    reset: () => setState(state => ({ ...state,
      ...defaultListQueryParams
    }))
  };
};

exports.useTdsRicercaReactQuery = useTdsRicercaReactQuery;

const useTdsDettaglioElementoReactQuery = ({
  name,
  idProp = "aggId",
  idValue
}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isFetching
  } = (0, _reactQuery.useQuery)([`tds.`, name, idProp, idValue], ({
    queryKey
  }) => _tdsApiMap.tdsApiMap[name].detailApi({
    [`${idProp}`]: idValue
  }), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    enabled: Boolean(idValue),
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response)
  });
  return {
    data,
    isFetching
  };
};

exports.useTdsDettaglioElementoReactQuery = useTdsDettaglioElementoReactQuery;

const useTdsCancellaElementoReactQuery = ({
  name
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(items => {
    let promises = [];
    promises = items.map(item => _tdsApiMap.tdsApiMap[name].deleteApi(item));
    return Promise.allSettled(promises);
  }, {
    onSuccess: promises => {
      const remoteErrors = promises.filter(promise => promise.value.error);
      const remoteErrorMessages = remoteErrors.length ? remoteErrors.map(error => error.value.message) : [];
      const uniqueRemoteErrorMessages = (0, _utils2.removeDuplicatesFromArray)(remoteErrorMessages);
      const str1 = `Attenzione! ${remoteErrors.length} `;
      const str2 = remoteErrors.length > 1 ? "elementi non sono stati cancellati." : "elemento non è stato cancellato.";
      const str3 = remoteErrorMessages.length ? uniqueRemoteErrorMessages : "";
      const defaultErrorMessageWithRemoteErrorMessages = `${str1} ${str2} ${str3}`;
      messenger(remoteErrors.length ? {
        type: 'warning',
        message: defaultErrorMessageWithRemoteErrorMessages,
        severity: 'warning'
      } : {
        type: 'success',
        message: "Cancellazione effettuata",
        severity: 'success'
      });
    },
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries([`tds.`, name])
  });
};

exports.useTdsCancellaElementoReactQuery = useTdsCancellaElementoReactQuery;

const useTdsAggiungiModificaElementoReactQuery = ({
  name,
  idProp = "aggId",
  onSuccess,
  onError,
  onSettled
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(item => Boolean(item[idProp]) ? _tdsApiMap.tdsApiMap[name].editApi(item) : _tdsApiMap.tdsApiMap[name].addApi(item), {
    onSuccess: response => response.error ? aggiungiModificaFailure(messenger)(response, onError) : aggiungiModificaSuccess(messenger)(response, onSuccess),
    onError: response => aggiungiModificaError(response, onError),
    onSettled: () => aggiungiModificaOnSettled(queryClient, [`tds.`, name], onSettled)
  });
};

exports.useTdsAggiungiModificaElementoReactQuery = useTdsAggiungiModificaElementoReactQuery;

const aggiungiModificaSuccess = messenger => (response, onSuccess) => {
  messenger({
    type: 'success',
    message: "Elemento salvato",
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

const aggiungiModificaFailure = messenger => (response, onError) => {
  messenger({
    type: 'warning',
    message: `Attenzione! ${response.message}`,
    severity: 'warning'
  });

  if (onError) {
    onError(response);
  }
};

const aggiungiModificaError = messenger => (response, onError) => {
  messenger({
    type: 'error',
    message: "Errore durante l'operazione",
    severity: 'error'
  });

  if (onError) {
    onError(response);
  }
};

const aggiungiModificaOnSettled = (queryClient, name, onSettled) => {
  if (onSettled) {
    onSettled();
  } else {
    queryClient.invalidateQueries(name);
  }
};

const useTdsHandleError = ({
  name,
  error
}) => {
  const router = (0, _react2.useRouter)();
  (0, _react.useEffect)(() => {
    error && router.stateService.go(`navigation.tds${name}`);
  }, [name, error]);
};

exports.useTdsHandleError = useTdsHandleError;

const useTdsHandleSave = ({
  name,
  edit,
  data,
  idProp = "aggId"
}) => {
  const router = (0, _react2.useRouter)();
  (0, _react.useEffect)(() => {
    if (edit || !Boolean(data)) return;
    data[idProp] ? router.stateService.go(`navigation.tds${name}Edit`, {
      [`${idProp}`]: data[idProp]
    }) : router.stateService.go(`navigation.tds${name}`);
  }, [name, data, edit, idProp]);
};

exports.useTdsHandleSave = useTdsHandleSave;