"use strict";

/**
 * Created by MN 09/10/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').controller('chartModalController', ['$scope', 'item', '$mdDialog', '$timeout', ChartModalController]);

  function ChartModalController($scope, item, $mdDialog, $timeout) {
    var me = this;

    me.$onInit = function () {
      setData();
    };

    $scope.closeDialog = function () {
      $mdDialog.hide();
    };

    function setData() {
      var modal = document.getElementById('displayable-area');

      if (!modal) {
        $timeout(function () {
          setData();
        }, 100);
      } else {
        item.detach();
        modal.append(item[0]);
      }
    }
  }
})();