"use strict";

(function () {
  'use strict';

  angular.module('osTranslatable').service('$translatable', ['api', 'DEFAULT_LOCALE', 'URLS', service]);

  function service(api, DEFAULT_LOCALE, URLS) {
    var me = this,
        availableLanguages,
        availableLocales;
    return {
      getAvailableLanguages: getAvailableLanguages,
      getAvailableLocales: getAvailableLocales,
      t: t
    };
    /*****************
     * Utils
     *****************/

    function getAvailableLanguages() {
      if (!availableLanguages) {
        availableLanguages = loadAvailableLanguages();
      }

      return availableLanguages;
    }

    ;

    function getAvailableLocales() {
      if (!availableLocales) {
        availableLocales = loadLocales();
      }

      return availableLocales;
    }

    ;

    function loadLocales() {
      var promise = fetchLocales().then(function (result) {
        return result.resultsWrapper.values;
      });
      return promise;
    }

    ;

    function loadAvailableLanguages() {
      var promise = fetchAvailableLanguages().then(function (result) {
        return result.resultsWrapper.values;
      });
      return promise;
    }

    ;
    /******************
     * Fetchers
     *****************/

    function fetchLocales() {
      return api.execute({
        url: URLS.tabelleDiSupporto.localizzazione.ricerca.url,
        params: {
          attiva: true
        }
      });
    }

    ;

    function fetchAvailableLanguages() {
      return api.execute({
        url: URLS.tabelleDiSupporto.lingue.ricerca.url,
        params: {
          attiva: true
        }
      });
    }

    ;
    /****************
     * Utils
     ****************/

    function t(item) {
      var defLocale;

      if (!item || !item.length) {
        return '--';
      } //FIXME: rimuovere questa gestione correggendo i codici locale dei partial


      if (DEFAULT_LOCALE == 'it') {
        defLocale = 'it_IT';
      } else if (DEFAULT_LOCALE == 'de') {
        defLocale = 'de_IT';
      }

      return R.find(R.propEq('languageTag', defLocale))(item).traduzione || '--';
    }
  }
})();