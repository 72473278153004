"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Violazione', ['restmod', function (restmod) {
    return restmod.model('violazione').mix({
      $extend: {
        Scope: {
          $config: {
            name: 'violazioni',
            baseEntityPath: 'violazione/',
            api: {
              list: {
                url: 'ricerca/violazioni',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/violazione',
                method: 'POST'
              },
              save: {
                url: 'comando/crea',
                method: 'POST'
              },
              edit: {
                url: 'comando/modifica',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'RicercaTestoLibero', 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl('list');
            };

            return collection;
          }
        },
        Record: {}
      }
    });
  }]);
})();