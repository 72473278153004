"use strict";

/**
 * Created by MN 14/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').service('$tdsApiService', ['$tdsGlobalBehaviors', 'Command', '$q', tdsApiService]);

  function tdsApiService($tdsGlobalBehaviors, Command, $q) {
    return {
      executeCommand: executeCommand,
      deleteCommand: deleteCommand,
      salvaElemento: salvaElemento,
      salvaElementoAndRedirect: salvaElementoAndRedirect
    };

    function executeCommand(commandName, prms) {
      var params = prms || {};
      return Command.execute(commandName, params);
    }

    function deleteCommand(commandName, items) {
      var me = this,
          deferred = $q.defer(),
          el = items.ngSelectedRowModel ? items.ngSelectedRowModel : items;
      el = el instanceof Array ? el : [el];
      $tdsGlobalBehaviors.askConfirmation('warn', 'tds.cancellaElemento__title', 'tds.cancellaElemento__body', 'tds.deleteConfirm__button', 'tds.undo__buttonText').then(doDelete.bind(me, commandName, el)).then($tdsGlobalBehaviors.notifica.bind(me, 'success', 'tds.eliminaSuccess__notification')).then(function () {
        deferred.resolve('resolve');
      }).catch(function (error) {
        if (error) {
          //Errore nella api di cancellazione
          $tdsGlobalBehaviors.notifica('warn', 'tds.eliminazioneError__notification');
        } else {
          //Operazione annullata
          $tdsGlobalBehaviors.notifica('info', 'tds.cancel__notification');
        }

        deferred.reject();
      });
      return deferred.promise;
    }

    function doDelete(deleteCommand, items) {
      var promises = [];
      R.forEach(function (item) {
        promises.push(executeCommand(deleteCommand, item));
      }, items);
      return Promise.all(promises);
    }

    function salvaElemento(commandName, item, editMode) {
      var me = this,
          params = item;
      return executeCommand(commandName, params).then(function () {
        $tdsGlobalBehaviors.notifica('success', editMode ? 'tds.modificaSuccess__notification' : 'tds.inserimentoSuccess__notification');
      }).catch(function (err) {
        $tdsGlobalBehaviors.notifica('error', 'tds.inserimentoError__notification');
      });
    }

    function salvaElementoAndRedirect(commandName, item, editMode, redirectTo, redirectPrms) {
      var redirectParams = redirectPrms || {};
      return salvaElemento(commandName, item, editMode).then(function () {
        $tdsGlobalBehaviors.redirectTo(redirectTo, redirectParams);
      });
    }
  }
})();