"use strict";

//filtro per impostare il formatto del numero incidente (10^5)
(function () {
  'use strict';
  /**
   * @ngdoc type
   * @name toUpper
   * @module polcity.filters
   *
   * @description
   * Will uppercase the string passed to it
   *
   * @usage
   * <hljs language="html">
   *     {{myString | toUpper}}
   * </hljs>
   */

  angular.module('polcity.filters').filter('filZero', function () {
    return function (n, len) {
      var num = parseInt(n, 10);
      len = parseInt(len, 10);

      if (isNaN(num) || isNaN(len)) {
        return n;
      }

      num = '' + num;

      while (num.length < len) {
        num = '0' + num;
      }

      return num;
    };
  });
})();