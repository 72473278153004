"use strict";

/**
 * Created by dan on 13/01/17.
 */
(function () {
  'use strict';

  angular.module('polcity.dashboard').controller('AddWidgetDialogController', ['$scope', '$mdDialog', 'avWidgets', AddWidgetDialogController]);

  function AddWidgetDialogController($scope, $mdDialog, avWidgets) {
    var me = this;
    me.avWidgets = avWidgets;
    me.types = ['chart', 'list', 'counters'];

    me.$onInit = function () {};
    /**
     * Handler eseguito sulla chiusura (X o annulla) della modale
     */


    me.toggleWidgetCheckBox = function (_widget) {
      _widget.selected = !_widget.selected;
    };

    me.onCloseClick = function () {
      $mdDialog.cancel();
    };

    me.onSaveClick = function () {
      $mdDialog.hide(me.avWidgets);
    };
  }
})();