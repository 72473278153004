"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _formik = require("formik");

var _utils = require("../utils");

var _verbale = require("../state/verbale/verbale");

var _object = require("../object.utils");

var _visure = require("../visure");

var _visure2 = require("../state/visure");

var _infrazioni = require("./infrazioni/infrazioni.utils");

var _contants = require("./infrazioni/contants");

var _utils2 = require("../state/verbale/utils");

var _isValid = _interopRequireDefault(require("date-fns/isValid"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// Raccogliamo in un unico post i side-effects del Verbale
// FIXME: forse si può fare con un hook?!
const VerbaleEffects = ({}) => {
  var _values$trasgressore, _touched$trasgressore2, _values$obbligato, _touched$obbligato2;

  const {
    values,
    touched,
    setValues,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    submitForm,
    initialValues
  } = (0, _formik.useFormikContext)();
  const {
    checkBolletta
  } = (0, _verbale.useVerbaleFacade)();
  const {
    aggiungiVisura
  } = (0, _visure2.useVisuraFacade)();
  touched.luogoAccertamento = touched.luogoAccertamento || {};
  const touchedRimozioneApplicabile = (0, _object.getPathBooleanOrFalse)(["sanzioni", "rimozione", "applicabile"])(touched);
  const rimozioneApplicabile = (0, _object.getPathBooleanOrFalse)(["sanzioni", "rimozione", "applicabile"])(values);
  const touchedFermoSequestro = (0, _object.getPathBooleanOrFalse)(["sanzioni", "fermoSequestro", "applicabile"])(touched);
  const fermoSequestroApplicabile = (0, _object.getPathBooleanOrFalse)(["sanzioni", "fermoSequestro", "applicabile"])(values);
  const touchedVeicoloTarga = (0, _object.getPathBooleanOrFalse)(["veicolo", "veicoloTarga"])(touched);
  const touchedVeicoloTipo = (0, _object.getPathBooleanOrFalse)(["veicolo", "veicoloTipo"])(touched);
  (0, _react.useEffect)(() => {
    if (touched.tipoAccertamento && values.tipoAccertamento && !isSubmitting) {
      const modelloStampa = values.tipoAccertamento.modelloStampa;
      const motivazioneMancataContestazione = values.tipoAccertamento.motivazioneMancataContestazione;
      setValues({ ...values,
        motivazioneMancataContestazione: motivazioneMancataContestazione,
        motivazioneMancataContestazioneDescrizioneStampa: motivazioneMancataContestazione ? motivazioneMancataContestazione.descrizioneStampa : [],
        modelloStampa: modelloStampa ? [modelloStampa] : [],
        // motivoMancataContestazioneId: values.tipoAccertamento.motivazioneMancataContestazioneId,
        // modelloStampaId: modelloStampa ? [modelloStampa] : [],
        modelloStampaLimitTo: modelloStampa ? [modelloStampa] : []
      });
      setFieldTouched("tipoAccertamento", false);
    }
  }, [values.tipoAccertamento, touched.tipoAccertamento]);
  (0, _react.useEffect)(() => {
    if (touched.motivazioneMancataContestazione) {
      setFieldValue("motivazioneMancataContestazioneDescrizioneStampa", (0, _object.getPathArrayOrEmptyArray)(["motivazioneMancataContestazione", "descrizioneStampa"])(values));
    }
  }, [touched.motivazioneMancataContestazione, values.motivazioneMancataContestazione]);
  (0, _react.useEffect)(() => {
    const payload = (0, _utils2.extractBolletta)(values);
    const initialPayload = (0, _utils2.extractBolletta)(initialValues);

    if (( // Almeno uno toccato (nel caso di modifica)
    touched.numero || touched.tipoAccertamento || touched.dataOraVerbale || touched.luogoAccertamento.luogoAccertamentoComune) && // Tutti definiti
    values.numero && values.dataOraVerbale && (0, _isValid.default)(values.dataOraVerbale) && (0, _object.getPathNonEmptyStringOrNull)(["tipoAccertamento", "aggId"])(values) && (0, _object.getPathNonEmptyStringOrNull)(["luogoAccertamento", "luogoAccertamentoComune", "aggId"])(values) && !isSubmitting // Avoid check on submit
    ) {
        if ((0, _utils.deepEqual)(payload, initialPayload)) {
          setFieldValue("bolletta", null);
          setFieldValue("bollettaIsFree", true);
        } else {
          const bolletta = checkBolletta(payload).then(res => {
            //FIXME with a better pattern
            setFieldValue("bolletta", res);
            setFieldValue("bollettaIsFree", res.bollettaIsFree);
          });
        }
      }
  }, [values.numero, values.tipoAccertamento, values.dataOraVerbale, values.luogoAccertamento.luogoAccertamentoComune, touched.numero, touched.tipoAccertamento, touched.dataOraVerbale, touched.luogoAccertamento.luogoAccertamentoComune]); // Quando cambia il comune resetto strada e i campi di tipoReazioneStrada

  (0, _react.useEffect)(() => {
    if (touched.luogoAccertamento.luogoAccertamentoComune && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        luogoAccertamento: {
          luogoAccertamentoStrada: null,
          luogoAccertamentoStradaId: '',
          luogoAccertamentoCivico: '',
          luogoAccertamentoKilometro: ''
        },
        tipoRelazioneStrada: {
          luogoAccertamentoTipoRelazioneStradaTipo: "",
          luogoAccertamentoTipoRelazioneStradaStrada: null,
          luogoAccertamentoTipoRelazioneStradaStradaId: "",
          luogoAccertamentoTipoRelazioneStradaCivico: "",
          luogoAccertamentoTipoRelazioneDescrizioneFronte: ""
        }
      }));
      setFieldTouched("luogoAccertamento.luogoAccertamentoComune", false);
    }
  }, [values.luogoAccertamento.luogoAccertamentoComune, touched.luogoAccertamento.luogoAccertamentoComune]); // Resetta i campi di veicolo e rimorchio quando viene selezionato il flag "pedone"

  (0, _react.useEffect)(() => {
    setValues((0, _utils.deepmerge)(values, {
      veicolo: {
        rimorchio: {}
      },
      trasgressoreTipo: values.pedone ? 'persona_fisica_figura_giuridica' : null
    }));
  }, [touched.pedone, values.pedone]);
  (0, _react.useEffect)(() => {
    if (touched.trasgressore && !values.trasgressore.trasgressore && !isSubmitting) {
      // on change trasgressore, clear field
      setValues({ ...values,
        decurtazionePuntiApplicataDocumentoId: [],
        documentiPresentati: [],
        dichiarazioniTrasgressore: "",
        obbligatoUgualeTrasgressore: false,
        trasgressore: { ...values.trasgressore,
          notificatoTrasgressore: true,
          trasgressoreNeoPatentato: false,
          trasgressoreObbligatoInSolido: true,
          trasgressoreDataNotifica: null
        }
      });
      setFieldTouched("trasgressore.trasgressore", false);
    }
  }, [values.trasgressore.trasgressore, touched.trasgressore]);
  (0, _react.useEffect)(() => {
    if (touched.obbligato && !values.obbligato.obbligato && !isSubmitting) {
      // on change obbligato, clear field
      setValues({ ...values,
        obbligato: { ...values.obbligato,
          notificatoObbligato: true,
          obbligatoDataNotifica: null
        }
      });
      setFieldTouched("obbligato.obbligato", false);
    }
  }, [values.obbligato.obbligato, touched.obbligato]);
  (0, _react.useEffect)(() => {
    var _touched$trasgressore;

    if ((_touched$trasgressore = touched.trasgressore) !== null && _touched$trasgressore !== void 0 && _touched$trasgressore.notificatoTrasgressore && !isSubmitting) {
      // on change trasgressore, clear field
      setValues({ ...values,
        trasgressore: { ...values.trasgressore,
          trasgressoreDataNotifica: null
        }
      });
      setFieldTouched("trasgressore.trasgressoreDataNotifica", false);
    }
  }, [(_values$trasgressore = values.trasgressore) === null || _values$trasgressore === void 0 ? void 0 : _values$trasgressore.notificatoTrasgressore, (_touched$trasgressore2 = touched.trasgressore) === null || _touched$trasgressore2 === void 0 ? void 0 : _touched$trasgressore2.notificatoTrasgressore]);
  (0, _react.useEffect)(() => {
    var _touched$obbligato;

    if ((_touched$obbligato = touched.obbligato) !== null && _touched$obbligato !== void 0 && _touched$obbligato.notificatoObbligato && !isSubmitting) {
      // on change trasgressore, clear field
      setValues({ ...values,
        obbligato: { ...values.obbligato,
          obbligatoDataNotifica: null
        }
      });
      setFieldTouched("obbligato.obbligatoDataNotifica", false);
    }
  }, [(_values$obbligato = values.obbligato) === null || _values$obbligato === void 0 ? void 0 : _values$obbligato.notificatoObbligato, (_touched$obbligato2 = touched.obbligato) === null || _touched$obbligato2 === void 0 ? void 0 : _touched$obbligato2.notificatoObbligato]); // Resetta i cambi dell'obbligato quando viene selezionato il flag obbligatoUgualeTrasgressore

  (0, _react.useEffect)(() => {
    if (touched.obbligatoUgualeTrasgressore && !isSubmitting) {
      // if oggligatoUgualeTrasgressore change from false to true, reset obbligato fieldd
      setValues({ ...values,
        obbligato: {
          obbligato: null,
          obbligatoAggId: "",
          obbligatoTipo: "",
          notificatoObbligato: true,
          obbligatoDataNotifica: null
        }
      });
      setFieldTouched("obbligatoUgualeTrasgressore", false);
    }
  }, [values.obbligatoUgualeTrasgressore, touched.obbligatoUgualeTrasgressore]); // RIMOZIONE - Al cambio del flag applicabile a false resetto i campi motivazioneMancataApplicazioneId e motivazioneMancataApplicazioneLibera

  (0, _react.useEffect)(() => {
    if (!rimozioneApplicabile && touchedRimozioneApplicabile && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        sanzioni: {
          rimozione: {
            motivazioneMancataApplicazioneId: "",
            motivazioneMancataApplicazioneLibera: ""
          }
        }
      }));
    }
  }, [rimozioneApplicabile, touchedRimozioneApplicabile, isSubmitting]); // FERMO E SEQUESTRO - Al cambio del flag applicabile a false resetto i campi motivazioneMancataApplicazioneId e motivazioneMancataApplicazioneLibera

  (0, _react.useEffect)(() => {
    if (!fermoSequestroApplicabile && touchedFermoSequestro && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        sanzioni: {
          fermoSequestro: {
            motivazioneMancataApplicazioneId: "",
            motivazioneMancataApplicazioneLibera: ""
          }
        }
      }));
    }
  }, [fermoSequestroApplicabile, touchedFermoSequestro, isSubmitting]); // Visura Targe in modifica verbale

  (0, _react.useEffect)(() => {
    const {
      choice,
      data
    } = values.visuraChoice || {};

    if (choice === "ins") {
      // Inserisco le informazioni della visura nel verbale (obbligato)
      setValues((0, _utils.deepmerge)(values, {
        obbligatoUgualeTrasgressore: false,
        obbligato: {
          obbligato: { ...data.anagrafica,
            aggId: data.anagrafica.anagraficaId
          },
          obbligatoAggId: data.anagrafica.anagraficaId
        },
        veicolo: {
          veicoloTarga: data.veicolo.targa,
          veicoloStatoId: data.veicolo.statoId,
          veicoloMarca: data.veicolo.marca,
          veicoloModello: data.veicolo.modello
        },
        visuraChoice: null
      }));
    } else if (choice === "new" && values.aggId) {
      setFieldValue("visuraChoice", null);
      const {
        agenteVisura,
        sorgenteVisura
      } = data;

      if (values.aggId && values.veicolo.veicoloTarga && values.veicolo.veicoloTipo) {
        const payload = (0, _visure.setContext)((0, _visure.getVisuraTargaData)(sorgenteVisura, values.veicolo.veicoloTipo.tipoMezzo, values.veicolo.veicoloTarga), "VERBALE_TARGA", values.aggId, agenteVisura.nomeCoda);
        aggiungiVisura(payload).then(() => {
          submitForm();
        }).finally(() => {// setFieldValue("visuraChoice", null);
        });
      }
    }
  }, [values.visuraChoice]); //Infrazioni e sanzioni accessorie

  (0, _react.useEffect)(() => {
    // const [sanzioniAccessorie, loading] = useSanzioniAccessorie("", 0);
    if (touched.infrazioni) {
      // Quando viene aggiunga / rimossa una infrazione dobbiamo gestire i decoratori associati ad essa o alle sue sanzioni accessorie
      // Le infrazioni ( articoli ) hanno 4 possibili decoratori: infrazione_rosso, infrazione_velocita, infrazione_ztl, infrazione_senza_documenti.
      // Ogni infrazione può avere n sanzioni accessorie collegate. Ogni sanzione accessoria è associata ad un decoratore di default generico (applicabile SI/NO con
      // eventuale motivoMancataApplicazione) oppure ad un decoratore sanz_acc_fermo_sequestro o sanz_acc_rimozione.
      const sanzioni = values.sanzioni; // Le sanzioni accessorie del verbale

      const gestioniSupplementari = (0, _infrazioni.getGestioniSupplementariFromInfrazioni)(values.infrazioni);
      setFieldValue("sanzioni", {
        rosso: gestioniSupplementari.rosso ? sanzioni.rosso || _contants.decoratori["infrazione_rosso"] : null,
        velocita: gestioniSupplementari.velocita ? sanzioni.velocita || _contants.decoratori["infrazione_velocita"] : null,
        ztl: gestioniSupplementari.ztl ? sanzioni.ztl || _contants.decoratori["infrazione_ztl"] : null,
        senzaDocumenti: gestioniSupplementari.senzaDocumenti ? sanzioni.senzaDocumenti || _contants.decoratori["infrazione_senza_documenti"] : null,
        fermoSequestro: gestioniSupplementari.fermoSequestro ? sanzioni.fermoSequestro || _contants.decoratori["sanz_acc_fermo_sequestro"] : null,
        rimozione: gestioniSupplementari.rimozione ? sanzioni.rimozione || _contants.decoratori["sanz_acc_rimozione"] : null,
        generico: gestioniSupplementari.generico.map(gen => {
          const alreadyPresent = sanzioni.generico.find(sg => sg.codiceVeloce === gen.codiceVeloce);
          return alreadyPresent || { ..._contants.decoratori["generico"],
            ...gen
          };
        })
      });
    }
  }, [values.infrazioni, touched.infrazioni]); // Gestione dataOraVerbalizzazione e dataOraDecorrenza

  (0, _react.useEffect)(() => {
    if (values.dataOraVerbale && (0, _isValid.default)(values.dataOraVerbale) && values.dataOraVerbaleEqualsDataOraViolazione) {
      setFieldValue("dataOraVerbalizzazione", values.dataOraVerbale);
      setFieldValue("dataOraDecorrenza", values.dataOraVerbale);
    }
  }, [values.dataOraVerbale, touched.dataOraVerbale, values.dataOraVerbaleEqualsDataOraViolazione, setFieldValue]);
  (0, _react.useEffect)(() => {
    if (touched.dataOraVerbaleEqualsDataOraViolazione) {
      if (values.dataOraVerbaleEqualsDataOraViolazione === false) {
        setFieldValue("dataOraVerbalizzazione", null);
      }
    }
  }, [touched.dataOraVerbaleEqualsDataOraViolazione, values.dataOraVerbaleEqualsDataOraViolazione, setFieldValue]);
  return null;
};

var _default = VerbaleEffects;
exports.default = _default;