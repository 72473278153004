"use strict";

var _utils = require("../../utils");

var _api = require("../../api");

angular.module('polcity.ricorso.generaFascicolo').controller('RicorsoGeneraFascicoloController', ['$scope', '$state', '$stateParams', 'FileSaver', 'OSNotification', 'Command', '$http', RicorsoGeneraFascicoloController]);

function RicorsoGeneraFascicoloController($scope, $state, $stateParams, FileSaver, OSNotification, Command, $http) {
  var me = this;
  me.isSaving = false;
  me.allegati = [];

  me.$onInit = function () {};

  me.onSelectedAllegatiChange = function (allegati) {
    me.allegati = allegati;
  };

  me.onAnnulla = function () {
    me.goBack();
  };

  const generaFascicoloApi = () => {
    return $http({
      method: "post",
      url: `ricorso/genera-fascicolo`,
      data: {
        ricorsoAggId: me.ricorso.$pk,
        allegatiAggId: me.allegati
      },
      headers: {
        Accept: '*/*'
      },
      "responseType": "arraybuffer",
      transformResponse: function (data, headersGetter, status) {
        const type = headersGetter("Content-Type");

        if (status == 200) {
          return data;
        }

        const decoder = new TextDecoder("utf-8");
        const domString = decoder.decode(data);
        const json = JSON.parse(domString);
        return json;
      }
    });
  };

  const scaricaFascicolo = response => {
    const headers = response.headers();
    const fileName = headers ? (0, _utils.getFileNameFromResponseHeaders)(headers) : 'Fascicolo ricorso';
    (0, _utils.downloadFile)(response.data, 'application/octet-stream', fileName);
  };

  me.onSalva = function () {
    generaFascicoloApi().then(scaricaFascicolo.bind(me)).then(notifyUser.bind(me, 'success', 'ricorso.generaFascicoloSuccess')).catch(function (err) {
      console.log('err', err); // notifyUser('error', 'ricorso.generaFascicoloError')
    });
  };

  me.goBack = function () {
    $state.go("^", {
      aggId: $stateParams.aggId
    });
  };

  function notifyUser(type, msg) {
    OSNotification[type]({
      translationIndex: msg
    });
  }
}