"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDeleteImportoViolazione = exports.useModificaImportoViolazione = exports.useAggiungiImportoViolazione = exports.useDettaglioImportoViolazioneReactQuery = exports.useRicercaImportiViolazioneReactQuery = exports._importiArticoloNormativaToEdit = exports._ricercaImportiViolazioneState = exports.defaultRicercaImportiViolazioneState = exports.importiViolazioneKeys = exports.importiViolazioneName = void 0;

var _reactQuery = require("react-query");

var _api = require("../../api");

var _utils = require("../../react/reactQuery/utils");

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _utils2 = require("../../utils");

var _soggetto = require("../soggetto/soggetto");

var _utils3 = require("./utils");

const importiViolazioneName = "importiViolazione";
exports.importiViolazioneName = importiViolazioneName;
const importiViolazioneKeys = {
  all: () => [importiViolazioneName],
  detail: aggId => [...importiViolazioneKeys.all(), "detail", ...(0, _utils2.insertIf)(aggId, {
    aggId
  })],
  quickSearch: params => [...importiViolazioneKeys.all(), "quickSearch", ...(0, _utils2.insertIf)(params, params)]
};
exports.importiViolazioneKeys = importiViolazioneKeys;
const defaultRicercaImportiViolazioneState = {
  page: 1,
  limit: 1000,
  order: "inVigoreDal,desc"
};
exports.defaultRicercaImportiViolazioneState = defaultRicercaImportiViolazioneState;

const _ricercaImportiViolazioneState = (0, _recoil.atomFamily)({
  key: '_ricercaImportiViolazioneState',
  default: params => {
    return { ...defaultRicercaImportiViolazioneState,
      ...params
    };
  }
});

exports._ricercaImportiViolazioneState = _ricercaImportiViolazioneState;

const _importiArticoloNormativaToEdit = (0, _recoil.atom)({
  key: "_importiArticoloNormativaToEdit",
  default: _utils3.importoEmptyForm
});

exports._importiArticoloNormativaToEdit = _importiArticoloNormativaToEdit;

const useRicercaImportiViolazioneReactQuery = ({
  aggId,
  page: pageProp,
  limit: limitProp,
  order: orderProp
}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const [{
    page,
    limit,
    order
  }, setState] = (0, _recoil.useRecoilState)(_ricercaImportiViolazioneState({
    page: pageProp,
    limit: limitProp,
    order: orderProp
  }));
  const {
    data: remoteImporti,
    isFetching: isFetchingImporti,
    isLoading: isLoadingImporti
  } = (0, _reactQuery.useQuery)(importiViolazioneKeys.quickSearch({
    aggId,
    page,
    limit,
    order
  }), ({
    queryKey
  }) => {
    const {
      aggId,
      page,
      limit,
      order
    } = queryKey[2];
    return (0, _api.ricercaImportiViolazione)({
      aggId: aggId,
      page: page,
      limit: limit,
      order: order
    });
  }, {
    enabled: Boolean(aggId),
    suspense: true,
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: response => response.error ? response : (response === null || response === void 0 ? void 0 : response.resultsWrapper.values) || []
  });
  const [head, ...tail] = remoteImporti;
  return {
    importi: remoteImporti,
    count: (remoteImporti || []).length,
    importoInVigore: head,
    storicoImporti: tail,
    page,
    limit,
    order,
    isLoading: isLoadingImporti || isFetchingImporti,
    changePage: _page => setState(state => ({ ...state,
      page: _page
    })),
    changeLimit: _limit => setState(state => ({ ...state,
      page: 1,
      limit: _limit
    }))
  };
}; // NON SI PUO' USARE PER RECUPERARE L'IMPORTO IN VIGORE PERCHE' NON TORNA IL DATO CORRETTO (NON SO SE DOVREBBE FARLO)


exports.useRicercaImportiViolazioneReactQuery = useRicercaImportiViolazioneReactQuery;

const useDettaglioImportoViolazioneReactQuery = aggId => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data: item,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(_soggetto.soggettiKeys.detail(aggId), ({
    queryKey
  }) => (0, _api.dettaglioImportiViolazione)({
    aggId: queryKey[2].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId),
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: data => (0, _utils3.fromApiImporto)(data)
  });
  return {
    item: item,
    isFetching: isFetching || isLoading
  };
};

exports.useDettaglioImportoViolazioneReactQuery = useDettaglioImportoViolazioneReactQuery;

const useAggiungiImportoViolazione = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Importo salvato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(values => (0, _api.aggiungiImportoViolazione)((0, _utils3.toApiImporto)(values)), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(importiViolazioneKeys.all())
  });
};

exports.useAggiungiImportoViolazione = useAggiungiImportoViolazione;

const useModificaImportoViolazione = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Importo salvato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(values => (0, _api.modificaImportoViolazione)((0, _utils3.toApiImporto)(values)), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(importiViolazioneKeys.all())
  });
};

exports.useModificaImportoViolazione = useModificaImportoViolazione;

const useDeleteImportoViolazione = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Importo cancellato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(values => (0, _api.cancellaImportoViolazione)({
    aggId: values.aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(importiViolazioneKeys.all())
  });
};

exports.useDeleteImportoViolazione = useDeleteImportoViolazione;