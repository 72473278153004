"use strict";

/**
 * Created by dan on 02/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.api').config(['$httpProvider', configApi]).config(['$provide', addAbortToHttpService])
  /*.run([
      '$rootScope',
      'authenticationInterceptor',
      '$state',
      redirectToLoginIfNotAuthorized
  ])*/
  .run(['$rootScope', '$http', abortPendingRequestOnRouteChange]);

  function abortPendingRequestOnRouteChange($rootScope, $http) {
    $rootScope.$on('$stateChangeStart', function () {
      angular.forEach($http.pendingRequests, function (request) {
        $http.abort(request);
      });
    });
  }

  function configApi($httpProvider) {
    ['exceptionHandlerInjector', //'authenticationInterceptor',
    'baseUrlInjector'].forEach(function (interceptorName) {
      $httpProvider.interceptors.push(interceptorName);
    });
  }
  /*function redirectToLoginIfNotAuthorized($rootScope, authenticationInterceptor, $state) {
       $rootScope.$on('$stateChangeStart', checkAndMaybeRedirect);
       function checkAndMaybeRedirect(event, toState) {
           if (!authenticationInterceptor.hasCredentials() && toState.name.indexOf('login') === -1) {
              event.preventDefault();
              $state.go('login', {}, { location: 'replace' });
          }
      }
  }*/

  /**
   * Decorates $http service with an abort method
   *
   * @param $provide
   */


  function addAbortToHttpService($provide) {
    $provide.decorator('$http', ['$delegate', '$q', function ($delegate, $q) {
      var getFn = $delegate.get;
      var cancelerMap = {};

      $delegate.get = function () {
        var cancelerKey, canceler, method;
        var args = [].slice.call(arguments);
        var url = args[0];
        var config = args[1] || {};

        if (config.timeout === null) {
          method = 'GET';
          cancelerKey = getCancelerKey(method, url);
          canceler = $q.defer();
          cancelerMap[cancelerKey] = canceler;
          config.timeout = canceler.promise;
          args[1] = config;
        }

        return getFn.apply(null, args);
      };

      $delegate.abort = function (request) {
        var cancelerKey, canceler;
        cancelerKey = getCancelerKey(request.method, request.url);
        canceler = cancelerMap[cancelerKey];

        if (!R.isNil(canceler) && request.timeout !== null && typeof request.timeout !== 'number') {
          canceler.reject();
          delete cancelerMap[cancelerKey];
        }
      };

      return $delegate;

      function getCancelerKey(method, url) {
        var formattedMethod = method.toLowerCase();
        var formattedUrl = encodeURI(url).toLowerCase().split('?')[0];
        return formattedMethod + '~' + formattedUrl + new Date().getTime();
      }
    }]);
  }
})();