"use strict";

/**
 * Created by MN 12/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('NazioniFormController', ['$stateParams', '$tdsApiService', '$tdsGlobalBehaviors', NazioniFormController]);

  function NazioniFormController($stateParams, $tdsApiService, $tdsGlobalBehaviors) {
    var nazioniFormCtrl = this,
        dettaglioCommand = '@dettaglioNazione',
        modificaCommand = '@modificaNazione',
        salvaCommand = '@salvaNazione',
        redirectTo = 'navigation.tdsNazioni';
    /**
     * Discriminante per capire se sono in edit
     * @type {boolean}
     */

    nazioniFormCtrl.editMode = false;
    /**
     * @Property
     * Configuro l'aggId a zero leggendolo dagli $stateParams nel caso sia in inserimento
     * oppure lo recupero dall'oggetto nel caso in cui sia in edit
     */

    nazioniFormCtrl.aggId = $stateParams.aggId;
    /**
     * @Property
     * Configuro l'oggetto recuperandolo dagli $stateParams nel caso in cui sia in edit
     * oppure assegnando un oggetto vuoto nel caso in cui sia in inserimento
     */

    nazioniFormCtrl.item = {};
    /**
     * Handler onInit function
     * @type {onInit}
     */

    nazioniFormCtrl.$onInit = onInit;
    nazioniFormCtrl.regExp = '[a-zA-Z]';
    /**
     * Operazioni eseguite in istanza del controller
     */

    function onInit() {
      // SE NEGLI STATE PARAMS HO L'AGGID POSSO ESSERE IN EDIT OPPURE AVER RICARICATO LA PAGINA
      if (nazioniFormCtrl.aggId) {
        nazioniFormCtrl.editMode = true;
        var params = {
          aggId: nazioniFormCtrl.aggId
        };
        $tdsApiService.executeCommand(dettaglioCommand, params).then(function (res) {
          nazioniFormCtrl.item = res;
        }).catch(function () {
          handleItemLoadError();
        });
      } else {
        nazioniFormCtrl.editMode = false;
      }
    }
    /**
     * @property
     * Handler da eseguire al click sul pulsante del salvataggio
     * @param item
     */


    nazioniFormCtrl.onSaveNazioneButtonClick = function (item) {
      var command = nazioniFormCtrl.editMode ? modificaCommand : salvaCommand;
      $tdsApiService.salvaElementoAndRedirect(command, item, nazioniFormCtrl.editMode, redirectTo);
    };
    /****************
     * Utils
     ***************/


    function handleItemLoadError() {
      //Notifica errore
      $tdsGlobalBehaviors.notifica('error', 'tds.elementNotFount__notification'); //Go back

      $tdsGlobalBehaviors.redirectTo(redirectTo);
    }
  }
})();