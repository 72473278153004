"use strict";

/**
 * Created by MN 10/12/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').controller('ContoEconomicoController', ['$state', ContoEconomicoController]);

  function ContoEconomicoController($state) {
    var me = this;
    me.nestedSectionIsOpen = false;
    me.expandedRowKey = null;
    me.mouseOverMainLevel = false;
    me.mouseOverMainKey = null;
    me.mouseOverFirstLevelNested = false;
    me.mouseOverFirstLevelNestedKey = null;
    me.contoRowIndexToHighlight = null;

    me.$onInit = function () {};

    me.$onChanges = function () {};
    /**
     * Handlers
     */


    me.onMouseOverUpperLevel = function (_level, _levelKey, _key) {
      me[_level] = true;
      me[_levelKey] = _key;
    };

    me.onMouseLeaveUpperLevel = function (_level, _levelKey) {
      me[_level] = false;
      me[_levelKey] = null;
    };

    me.onMouseOverContiRow = function (_index) {
      me.contoRowIndexToHighlight = _index;
    };

    me.onMouseLeaveContiRow = function () {
      me.contoRowIndexToHighlight = null;
    };

    me.onMouseLeaveUpperLevel = function (_level, _levelKey) {
      me[_level] = false;
      me[_levelKey] = null;
    };

    me.toggleNestedSection = function (_key) {
      if (!me.expandedRowKey) {
        openNestedSection(_key);
      } else if (me.expandedRowKey === _key) {
        closeNestedSection();
      } else if (me.expandedRowKey !== _key && me.nestedSectionIsOpen) {
        me.expandedRowKey = _key;
      }
    };

    me.onGoToArmonizzazioneContabile = function (_item) {
      var params = {
        filter: {
          conto: _item.valoreContoEnum
        }
      };
      $state.go('navigation.osContabilita.armonizzazioneContabileChartsView', params);
    };
    /**
     * Methods implementation
     */


    function closeNestedSection() {
      me.expandedRowKey = null;
      me.nestedSectionIsOpen = false;
    }

    function openNestedSection(_key) {
      me.expandedRowKey = _key;
      me.nestedSectionIsOpen = true;
    }
  }
})();