"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.esitiSpedizioniTutorial = exports.rimozioniTutorial = exports.ricorsiTutorial = exports.fermiESequestriTutorial = exports.versamentiTutorial = exports.rilevazioniTutorial = exports.creazioneLottiTutorial = exports.dashboardTutorial = exports.inserimentoVersamentoCassa = exports.creazioneAgentiTutorial = exports.pagamentoVerbaleDaVerbaliTutorial = exports.visuraDaVerbaleTutorial = exports.inserimentoVerbaleTutorial = exports.valoriGlobaliTutorial = exports.tabelleDiSupportoTutorialSecondaParte = exports.tabelleDiSupportoTutorialPrimaParte = exports.leggiArticoliNormativeTutorial = exports.creazioneAnagraficaTutorial = exports.installazioneAgenteVisuraMacOsTutorial = exports.installazioneAgenteVisuraWindowsTutorial = exports.creazioneAgentiVisureTutorial = exports.decurtazionePuntiTutorialNew = exports.presentazioneDocumentiTutorialNew = exports.visureTutorialNew = exports.tabelleSupportoTutorialNew = exports.verbalizzazioneAllegatoNonAssociatoTutorialNew = exports.cassaTutorialNew = exports.verbaleTutorialNew = exports.dashboardTutorialNew = exports.openSoftwareComunicareChannel = exports.tutorialBrowserWindowFeatures = exports.tutorialBrowserWindowTarget = void 0;
const tutorialBrowserWindowTarget = '_blank';
exports.tutorialBrowserWindowTarget = tutorialBrowserWindowTarget;
const tutorialBrowserWindowFeatures = 'toolbar=yes,scrollbars=yes,resizable=yes,width=600,height=600';
exports.tutorialBrowserWindowFeatures = tutorialBrowserWindowFeatures;
const openSoftwareComunicareChannel = 'https://www.youtube.com/channel/UCnhNu79eEesgFDB8sEfX-Lg'; ///////////////// LINK SU CARTELLA ACCESSIBILE AI CARABINIERI

exports.openSoftwareComunicareChannel = openSoftwareComunicareChannel;
const dashboardTutorialNew = "https://opensoftware.app.box.com/v/video-tutorial-armaposte/file/1027078279869";
exports.dashboardTutorialNew = dashboardTutorialNew;
const verbaleTutorialNew = "https://opensoftware.app.box.com/v/video-tutorial-armaposte/file/1027088304453";
exports.verbaleTutorialNew = verbaleTutorialNew;
const cassaTutorialNew = "https://opensoftware.app.box.com/v/video-tutorial-armaposte/file/1027092228188";
exports.cassaTutorialNew = cassaTutorialNew;
const verbalizzazioneAllegatoNonAssociatoTutorialNew = "https://opensoftware.app.box.com/v/video-tutorial-armaposte/file/1027080223955";
exports.verbalizzazioneAllegatoNonAssociatoTutorialNew = verbalizzazioneAllegatoNonAssociatoTutorialNew;
const tabelleSupportoTutorialNew = "https://opensoftware.app.box.com/v/video-tutorial-armaposte/file/1027090743521";
exports.tabelleSupportoTutorialNew = tabelleSupportoTutorialNew;
const visureTutorialNew = "https://opensoftware.app.box.com/v/video-tutorial-armaposte/file/1027091849211";
exports.visureTutorialNew = visureTutorialNew;
const presentazioneDocumentiTutorialNew = "https://opensoftware.app.box.com/v/video-tutorial-armaposte/file/1027073434414";
exports.presentazioneDocumentiTutorialNew = presentazioneDocumentiTutorialNew;
const decurtazionePuntiTutorialNew = "https://opensoftware.app.box.com/v/video-tutorial-armaposte/file/1027078260651"; ////////////////////////////////////////////////////////////
// AGENTI VISURA

exports.decurtazionePuntiTutorialNew = decurtazionePuntiTutorialNew;
const creazioneAgentiVisureTutorial = 'https://opensoftware.box.com/s/qg9wyi019rfnkn3pv35ab8say4fzynbg'; // 'https://www.youtube.com/watch?v=v6ckialWiDM';

exports.creazioneAgentiVisureTutorial = creazioneAgentiVisureTutorial;
const installazioneAgenteVisuraWindowsTutorial = 'https://opensoftware.box.com/s/1n1ga1q0b9gg6o5b6dauxzmvqw6q3djj'; // 'https://www.youtube.com/watch?v=AnL6xHxTFmA&t';

exports.installazioneAgenteVisuraWindowsTutorial = installazioneAgenteVisuraWindowsTutorial;
const installazioneAgenteVisuraMacOsTutorial = 'https://opensoftware.box.com/s/u04d9873s5dkphhyt0l05wwmk2vk0k71'; // 'https://www.youtube.com/watch?v=R00KI7EHZac&t';
// SOGGETTI/ANAGRAFICA

exports.installazioneAgenteVisuraMacOsTutorial = installazioneAgenteVisuraMacOsTutorial;
const creazioneAnagraficaTutorial = 'https://opensoftware.box.com/s/p0bh4pbigctmr0jnimopvrmdlq94uj3j'; // 'https://www.youtube.com/watch?v=pZD_hpAUd-0';
// ARTICOLI E NORMATIVE

exports.creazioneAnagraficaTutorial = creazioneAnagraficaTutorial;
const leggiArticoliNormativeTutorial = 'https://opensoftware.box.com/s/9b0gg6258r1awkw90akpju2ik0al9sqd'; // 'https://www.youtube.com/watch?v=IlAS_eA2T9I';
// TABELLE DI SUPPORTO

exports.leggiArticoliNormativeTutorial = leggiArticoliNormativeTutorial;
const tabelleDiSupportoTutorialPrimaParte = 'https://opensoftware.box.com/s/exvilqlmhzrc6sm15es5obeih77siw37'; // 'https://www.youtube.com/watch?v=NqHg7SwslXo';

exports.tabelleDiSupportoTutorialPrimaParte = tabelleDiSupportoTutorialPrimaParte;
const tabelleDiSupportoTutorialSecondaParte = 'https://opensoftware.box.com/s/2jptumhx776uoqtcyqxulolayfc8pky0'; // 'https://www.youtube.com/watch?v=27UJqZGCj_Y';
// VALORI GLOBALI

exports.tabelleDiSupportoTutorialSecondaParte = tabelleDiSupportoTutorialSecondaParte;
const valoriGlobaliTutorial = 'https://opensoftware.box.com/s/o3qty49jkn09fp04te3fjqgdsoplrpts'; // 'https://www.youtube.com/watch?v=zxsJwOWjZCI';
// VERBALI

exports.valoriGlobaliTutorial = valoriGlobaliTutorial;
const inserimentoVerbaleTutorial = 'https://opensoftware.box.com/s/k0e4r14jp9z7ba8k3o4eqmsahqqh3dxt'; // 'https://www.youtube.com/watch?v=wzXyHM9lqgA';

exports.inserimentoVerbaleTutorial = inserimentoVerbaleTutorial;
const visuraDaVerbaleTutorial = 'https://opensoftware.box.com/s/plvsumh2fzihetkgq4sc58fqv78rnfrd'; // 'https://www.youtube.com/watch?v=SGd5ZY0NPJ0';

exports.visuraDaVerbaleTutorial = visuraDaVerbaleTutorial;
const pagamentoVerbaleDaVerbaliTutorial = 'https://opensoftware.box.com/s/53az7d905mx9qu0g9z9ztcbeezjzdc4i'; // 'https://www.youtube.com/watch?v=2j24Oj-vfM4';
// UTENTI/AGENTI

exports.pagamentoVerbaleDaVerbaliTutorial = pagamentoVerbaleDaVerbaliTutorial;
const creazioneAgentiTutorial = 'https://opensoftware.box.com/s/hnt7obs83rcrfwqpii7zp3zsz3wpaukw'; // 'https://www.youtube.com/watch?v=8s-461NpRr4';
// CASSA

exports.creazioneAgentiTutorial = creazioneAgentiTutorial;
const inserimentoVersamentoCassa = 'https://opensoftware.box.com/s/ri8dfq4h51z9lbifsfxkyg79awue9fk3'; // 'https://www.youtube.com/watch?v=8iTUvJONZts';
// DASHBOARD

exports.inserimentoVersamentoCassa = inserimentoVersamentoCassa;
const dashboardTutorial = 'https://opensoftware.box.com/s/z0e3ut05uggynh44tloaveadd3h3tnwu'; // 'https://www.youtube.com/watch?v=6cOqqp7H-iQ';
// LOTTI

exports.dashboardTutorial = dashboardTutorial;
const creazioneLottiTutorial = 'https://opensoftware.box.com/s/i98hmqqd8w2pcjhymd51w60jey7lrfml'; // ACQUISIZIONE DATI - RILEVAZIONI

exports.creazioneLottiTutorial = creazioneLottiTutorial;
const rilevazioniTutorial = 'https://opensoftware.box.com/s/4qr03fix74wj3gjnaq05y0iql21qm39p'; // ACQUISIZIONE DATI - VERSAMENTI

exports.rilevazioniTutorial = rilevazioniTutorial;
const versamentiTutorial = 'https://opensoftware.box.com/s/5gso1lf48to9knek8lr9c0n0j4bkvbzx'; // FERMI E SEQUESTRI

exports.versamentiTutorial = versamentiTutorial;
const fermiESequestriTutorial = 'https://opensoftware.box.com/s/gevwwg1it3a5g30wah6f06qvoxpy68cl'; // RICORSI

exports.fermiESequestriTutorial = fermiESequestriTutorial;
const ricorsiTutorial = 'https://opensoftware.box.com/s/1lqocng80rnj7qg23h90pczabb4of0rl'; // RIMOZIONI

exports.ricorsiTutorial = ricorsiTutorial;
const rimozioniTutorial = 'https://opensoftware.box.com/s/uxwnn2n0pqtwscuktcb9ctcyhn018q20'; // ESITI SPEDIZIONI

exports.rimozioniTutorial = rimozioniTutorial;
const esitiSpedizioniTutorial = 'https://opensoftware.box.com/s/ije3zklknibaobyvu0wg8nmpct3hdqmw';
exports.esitiSpedizioniTutorial = esitiSpedizioniTutorial;