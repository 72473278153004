"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHrVrSpacing = exports.useVrSpacing = exports.useHrSpacing = void 0;

var _makeStyles = _interopRequireDefault(require("@material-ui/core/styles/makeStyles"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const useHrSpacing = (0, _makeStyles.default)(theme => ({
  root: ({
    size,
    type = 'margin'
  }) => ({
    '& > *': {
      [`${type}Right`]: theme.spacing(size === "small" ? 2 : size === "big" ? 6 : 4 //normal
      )
    },
    '& > *:last-child': {
      [`${type}Right`]: 0
    }
  })
}));
exports.useHrSpacing = useHrSpacing;
const useVrSpacing = (0, _makeStyles.default)(theme => ({
  root: ({
    size
  }) => ({
    '& > *': {
      marginBottom: theme.spacing(size === "small" ? 2 : size === "big" ? 6 : 4 //normal
      )
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  })
}));
exports.useVrSpacing = useVrSpacing;
const useHrVrSpacing = (0, _makeStyles.default)(theme => ({
  root: {
    '& > *': {
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    '& > *:last-child': {
      marginRight: 0
    }
  }
}));
exports.useHrVrSpacing = useHrVrSpacing;