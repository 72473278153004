"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pushMessage = exports.push = exports.useResetErrors = exports.useLastMessage = exports.useLastError = exports.lastMessage = exports.lastMessageType = exports.lastError = exports.messagesStack = exports.MESSAGES_STACK_SIZE = void 0;

var _recoil = require("recoil");

const MESSAGES_STACK_SIZE = 100;
exports.MESSAGES_STACK_SIZE = MESSAGES_STACK_SIZE;
const messagesStack = (0, _recoil.atom)({
  key: 'messagesStack',
  default: []
});
exports.messagesStack = messagesStack;
const lastError = (0, _recoil.selector)({
  key: 'lastError',
  get: ({
    get
  }) => {
    const m = get(messagesStack);
    const e = m.filter(msg => msg.type === "error");
    return e.length > 0 ? e.pop() : undefined;
  }
});
exports.lastError = lastError;
const lastMessageType = (0, _recoil.selectorFamily)({
  key: 'lastErrorType',
  get: type => ({
    get
  }) => {
    return get(messagesStack).filter(m => m.type === type).pop();
  }
});
exports.lastMessageType = lastMessageType;
const lastMessage = (0, _recoil.selector)({
  key: 'lastMessage',
  get: ({
    get
  }) => {
    const m = get(messagesStack);
    const lastM = m.length > 0 && m[m.length - 1];

    if (lastM) {
      const {
        message,
        ...others
      } = lastM;
      return Array.isArray(message) ? lastM : {
        message: [message],
        ...others
      };
    } else {
      return undefined;
    }
  }
});
exports.lastMessage = lastMessage;

const useLastError = key => (0, _recoil.useRecoilValue)(key ? lastMessageType(key) : lastError);

exports.useLastError = useLastError;

const useLastMessage = () => (0, _recoil.useRecoilValue)(lastMessage);

exports.useLastMessage = useLastMessage;

const useResetErrors = () => (0, _recoil.useResetRecoilState)(messagesStack);

exports.useResetErrors = useResetErrors;

const push = (item, maxSize) => s => maxSize === undefined || s.length < maxSize ? [...s, item] : [...s.slice(s.length - maxSize, s.length - 1), item];

exports.push = push;

const pushMessage = ({
  type,
  message,
  severity
}) => push({
  type,
  message,
  severity
}, MESSAGES_STACK_SIZE);

exports.pushMessage = pushMessage;