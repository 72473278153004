"use strict";

var _ContatoriProtocolloList = _interopRequireDefault(require("./ContatoriProtocolloList"));

var _ContatoreProtocolloEditRoot = _interopRequireDefault(require("./edit/ContatoreProtocolloEditRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('contatoriProtocolloTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsContatoriProtocollo',
    url: 'tds/contatoriProtocollo',
    role: 'ROLE_CONTATORE_PROTOCOLLO_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ContatoriProtocolloList.default
      }
    },
    resolve: {
      $label: () => "Contatori protocollo"
    }
  }, {
    name: 'tdsContatoriProtocolloEdit',
    url: 'tds/contatoriProtocollo/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_CONTATORE_PROTOCOLLO_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ContatoreProtocolloEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita contatore protocollo"
    }
  }];
  routesProviderFactory.extend(this);
}