"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.articoloNormativaFormValidationSchema = exports.getNaturaArticoloNormativa = exports.deserializeDecoratori = exports.toApiDecoratori = exports.toApiArticoloNormativa = exports.fromApiArticoloNormativa = exports.articoloNormativaEmptyForm = exports.articoloNormativaIsReadOnly = void 0;

var _object = require("../../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const articoloNormativaIsReadOnly = articoloNormativa => Boolean(articoloNormativa.natura == "LIBRERIA") && !articoloNormativa.isAClone;

exports.articoloNormativaIsReadOnly = articoloNormativaIsReadOnly;
const articoloNormativaEmptyForm = {
  sottocaso: "",
  numArticolo: "",
  commi: "",
  punti: "",
  titolo: "",
  descrizione: "",
  descrizioneLetterale: "",
  descrizioneStampa: [],
  descrizioneStampaLetterale: "",
  puntiLetterale: null,
  codice: "",
  codiceLibreria: "",
  sanzioniAccessorie: [],
  codiceVeloce: "",
  legge: null,
  leggeAggId: "",
  isAClone: false,
  lettera: "",
  periodo: "",
  enteRicorso: "",
  inVigoreDal: null,
  inVigoreAl: null,
  segnalazionePatente: false,
  articoliDiRiferimento: "",
  competenza: "",
  importi: {
    inVigoreDal: null,
    inVigoreAl: null,
    importoMinimo: 0,
    importoMassimo: 0,
    importoARuolo: 0,
    importoScontato: 0,
    minimoEditale: 0,
    importoMinimoNotturno: 0,
    importoMassimoNotturno: 0,
    importoARuoloNotturno: 0,
    importoScontatoNotturno: 0,
    minimoEditaleNotturno: 0
  },
  ambito: "",
  decoratori: []
};
exports.articoloNormativaEmptyForm = articoloNormativaEmptyForm;

const fromApiArticoloNormativa = remoteArticoloNormativa => {
  return { ...(remoteArticoloNormativa.aggId ? {
      aggId: remoteArticoloNormativa.aggId
    } : {}),
    sottocaso: (0, _object.getPathStringOrEmptyString)(["sottocaso"])(remoteArticoloNormativa),
    numArticolo: (0, _object.getPathStringOrEmptyString)(["numArticolo"])(remoteArticoloNormativa),
    commi: (0, _object.getPathStringOrEmptyString)(["commi"])(remoteArticoloNormativa),
    punti: (0, _object.getPathNumberOrEmptyString)(["punti"])(remoteArticoloNormativa),
    titolo: (0, _object.getPathStringOrEmptyString)(["titolo"])(remoteArticoloNormativa),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(remoteArticoloNormativa),
    descrizioneLetterale: (0, _object.getPathStringOrNull)(["descrizioneLetterale"])(remoteArticoloNormativa),
    descrizioneStampa: (0, _object.getPathArrayOrEmptyArray)(["descrizioneStampa"])(remoteArticoloNormativa),
    descrizioneStampa_transId: (0, _object.getPathStringOrEmptyString)(["descrizioneStampa_transId"])(remoteArticoloNormativa),
    descrizioneStampaLetterale: (0, _object.getPathStringOrNull)(["descrizioneStampaLetterale"])(remoteArticoloNormativa),
    puntiLetterale: (0, _object.getPathStringOrNull)(["puntiLetterale"])(remoteArticoloNormativa),
    codice: (0, _object.getPathStringOrEmptyString)(["codice"])(remoteArticoloNormativa),
    codiceLibreria: (0, _object.getPathStringOrEmptyString)(["codiceLibreria"])(remoteArticoloNormativa),
    sanzioniAccessorie: (0, _object.getPathArrayOrEmptyArray)(["sanzioniAccessorie"])(remoteArticoloNormativa),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(remoteArticoloNormativa),
    legge: (0, _object.getPathObjectOrNull)(["legge"])(remoteArticoloNormativa),
    leggeAggId: (0, _object.getPathStringOrEmptyString)(["leggeAggId"])(remoteArticoloNormativa),
    isAClone: (0, _object.getPathBooleanOrFalse)(["isAClone"])(remoteArticoloNormativa),
    lettera: (0, _object.getPathStringOrEmptyString)(["lettera"])(remoteArticoloNormativa),
    periodo: (0, _object.getPathStringOrEmptyString)(["periodo"])(remoteArticoloNormativa),
    enteRicorso: (0, _object.getPathStringOrEmptyString)(["enteRicorso"])(remoteArticoloNormativa),
    inVigoreDal: (0, _object.getPathNumberToDateOrNull)(["inVigoreDal"])(remoteArticoloNormativa),
    inVigoreAl: (0, _object.getPathNumberToDateOrNull)(["inVigoreAl"])(remoteArticoloNormativa),
    segnalazionePatente: (0, _object.getPathBooleanOrFalse)(["segnalazionePatente"])(remoteArticoloNormativa),
    articoliDiRiferimento: (0, _object.getPathStringOrEmptyString)(["articoliDiRiferimento"])(remoteArticoloNormativa),
    competenza: (0, _object.getPathStringOrEmptyString)(["competenza"])(remoteArticoloNormativa),
    // importi: getPathArrayOrEmptyArray(["importi"])(remoteArticoloNormativa),
    ambito: (0, _object.getPathStringOrEmptyString)(["ambito"])(remoteArticoloNormativa),
    natura: (0, _object.getPathStringOrEmptyString)(["natura"])(remoteArticoloNormativa),
    decoratori: (0, _object.getPathObjectOrEmptyObject)(["decoratori", "decoratoriIds"])(remoteArticoloNormativa)
  };
};

exports.fromApiArticoloNormativa = fromApiArticoloNormativa;

const toApiArticoloNormativa = values => {
  return { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    codiceLibreria: (0, _object.getPathStringOrEmptyString)(["codiceLibreria"])(values),
    numArticolo: (0, _object.getPathStringOrEmptyString)(["numArticolo"])(values),
    commi: (0, _object.getPathStringOrEmptyString)(["commi"])(values),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(values),
    titolo: (0, _object.getPathStringOrEmptyString)(["titolo"])(values),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(values),
    descrizioneStampa: (0, _object.getPathArrayOrEmptyArray)(["descrizioneStampa"])(values),
    descrizioneStampa_transId: (0, _object.getPathStringOrEmptyString)(["descrizioneStampa_transId"])(values),
    punti: (0, _object.getPathNumberOrNull)(["punti"])(values),
    enteRicorso: (0, _object.getPathStringOrNull)(["enteRicorso"])(values),
    inVigoreDal: (0, _object.getPathDateToNumberOrNull)(["inVigoreDal"])(values),
    inVigoreAl: (0, _object.getPathDateToNumberOrNull)(["inVigoreAl"])(values),
    segnalazionePatente: (0, _object.getPathBooleanOrFalse)(["segnalazionePatente"])(values),
    articoliDiRiferimento: (0, _object.getPathStringOrEmptyString)(["articoliDiRiferimento"])(values),
    competenza: (0, _object.getPathStringOrNull)(["competenza"])(values),
    sanzioniAccessorie: (0, _object.getPathArrayOrEmptyArray)(["sanzioniAccessorie"])(values).map(sanz => sanz.codiceVeloce),
    leggeAggId: (0, _object.getPathStringOrEmptyString)(["legge", "aggId"])(values),
    sottocaso: (0, _object.getPathStringOrEmptyString)(["sottocaso"])(values)
  };
};

exports.toApiArticoloNormativa = toApiArticoloNormativa;

const toApiDecoratori = values => {
  return {
    aggId: values.aggId,
    articoloCdsId: values.aggId,
    decoratoriIds: values.decoratori.reduce((acc, val) => ({ ...acc,
      ...{
        [`${val.alias}`]: true
      }
    }), {}),
    lettera: values.sottocaso
  };
};

exports.toApiDecoratori = toApiDecoratori;

const deserializeDecoratori = (decoratori, decoratoriArticoloNormativa) => {
  if (!decoratori || !decoratoriArticoloNormativa) return [];
  return Object.keys(decoratoriArticoloNormativa).reduce((acc, v) => {
    acc = [...acc, ...decoratori.filter(decoratore => decoratore.alias == v)];
    return acc;
  }, []);
};

exports.deserializeDecoratori = deserializeDecoratori;

const getNaturaArticoloNormativa = (type, isAClone) => {
  let natura, isReadOnly;

  if (!isAClone && !type) {
    natura = 'NUOVO_INSERIMENTO';
    isReadOnly = false;
  } else if (!isAClone && type === "LIBRERIA") {
    natura = 'LIBRERIA';
    isReadOnly = true;
  } else if (isAClone && type === "LIBRERIA") {
    natura = 'LIBRERIA_CLONE';
    isReadOnly = false;
  } else if (!isAClone && type === "ADDENDUM") {
    natura = 'ADDENDUM';
    isReadOnly = false;
  } else if (isAClone && type === "ADDENDUM") {
    natura = 'ADDENDUM_CLONE';
    isReadOnly = false;
  }

  return {
    natura,
    isReadOnly
  };
};

exports.getNaturaArticoloNormativa = getNaturaArticoloNormativa;
const articoloNormativaFormValidationSchema = Yup.object().shape({
  legge: Yup.object().required(_utils.requiredMessage).nullable(),
  codiceLibreria: Yup.string().required(_utils.requiredMessage),
  numArticolo: Yup.string().required(_utils.requiredMessage),
  codiceVeloce: Yup.string().required(_utils.requiredMessage),
  inVigoreDal: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  inVigoreAl: Yup.date().min(Yup.ref('inVigoreDal'), "Data inferiore a data in vigore dal").max(new Date(2999, 12, 31), 'Data non valida').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  titolo: Yup.string().required(_utils.requiredMessage),
  descrizione: Yup.string().required(_utils.requiredMessage),
  descrizioneStampa: Yup.array().ensure().min(1, _utils.requiredMessage).of(Yup.object().shape({
    traduzione: Yup.string().required(_utils.requiredMessage),
    languageTag: Yup.string().required(_utils.requiredMessage)
  }))
});
exports.articoloNormativaFormValidationSchema = articoloNormativaFormValidationSchema;