"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVerbaliMasterDetail = exports.useRefreshRicercaVerbaliTestoLiberoReactQuery = exports.useRicercaVerbaliTestoLiberoReactQuery = exports._availableSorters = exports._availableFilters = void 0;

var _recoil = require("recoil");

var _api = require("../../api");

var _hash = _interopRequireDefault(require("history/hash"));

var _freeSearch = require("../generics/freeSearch");

var _quickSearch = require("../generics/quickSearch");

var _masterDetail = require("../generics/masterDetail");

var _react = require("react");

var _verbale = require("../verbale/verbale");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _utils = require("../../react/reactQuery/utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// UI state VerbaliMasterDetails
const _availableFilters = (0, _recoil.atom)({
  key: '_availableFiltersVerbale',
  default: []
});

exports._availableFilters = _availableFilters;

const _availableSorters = (0, _recoil.atom)({
  key: '_availableSortersVerbale',
  default: [{
    name: 'idVerbale',
    label: 'Numero verbale',
    direction: null
  }, {
    name: 'numeroRegistro',
    label: 'Numero Registro',
    direction: null
  }, {
    name: 'dataOraVerbale',
    label: 'Data / Ora verbale',
    direction: null
  }]
});

exports._availableSorters = _availableSorters;

const syncWithUrl = state => {
  const search = new URLSearchParams(_hash.default.location.search);
  Object.keys(state).map(key => {
    if (state[key]) {
      search.has(key) ? search.set(key, state[key]) : search.append(key, state[key]);
    } else {
      search.delete(key);
    }
  });
  search.delete("state");
  search.delete("code");
  search.delete("session_state");

  _hash.default.push({
    pathname: '/verbali',
    search: search.toString().length ? `?${search.toString()}` : ""
  });
};

const useRicercaVerbaliTestoLiberoReactQuery = ({
  query,
  limit,
  minLength = 2
}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isLoading,
    isFetching
  } = (0, _reactQuery.useQuery)(_verbale.verbaliKeys.freeSearch({
    query,
    limit
  }), ({
    queryKey
  }) => (0, _api.ricercaVerbaleTestoLibero)({
    query: queryKey[2].query,
    limit: queryKey[2].limit
  }), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(query) && query.length >= minLength,
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: response => response.error ? response : (response === null || response === void 0 ? void 0 : response.resultsWrapper.values) || []
  });
  return {
    verbali: data || [],
    isLoading: isLoading || isFetching
  };
};

exports.useRicercaVerbaliTestoLiberoReactQuery = useRicercaVerbaliTestoLiberoReactQuery;

const useRefreshRicercaVerbaliTestoLiberoReactQuery = () => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  return () => queryClient.invalidateQueries(_verbale.verbaliKeys.freeSearch);
};

exports.useRefreshRicercaVerbaliTestoLiberoReactQuery = useRefreshRicercaVerbaliTestoLiberoReactQuery;
const useVerbaliQuickSearch = (0, _quickSearch.useQuickSearchReactQuery)({
  apiCallback: _api.ricercaVerbaleVeloce
});
const useVerbaliFreeSearchReactQuery = (0, _freeSearch.useFreeSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    query
  }) => (0, _api.ricercaVerbaleTestoLibero)({
    page,
    limit,
    query
  })
});

const useVerbaliMasterDetail = props => {
  const {
    name,
    page: pageProp,
    limit: limitProp,
    query: queryProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  } = props || {};
  const availableSorters = (0, _recoil.useRecoilValue)(_availableSorters);
  const minLengthQuery = 2;
  const freeSearchActive = (0, _react.useCallback)(_query => _query && _query.length >= minLengthQuery, [minLengthQuery]);
  const {
    open,
    selectionActive,
    activeItem,
    setState,
    reset: resetState
  } = (0, _masterDetail.useMasterDetailState)({
    name,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  });
  const {
    page,
    limit,
    items,
    isLoading,
    count,
    filters,
    activeFilters,
    activeSorters,
    activeSortersCount,
    changePage,
    changeLimit,
    addFilter,
    removeFilter,
    setActiveSorters,
    refresh,
    reset
  } = useVerbaliQuickSearch({
    resourceKeys: _verbale.verbaliKeys,
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp
  });
  const {
    items: fsItems,
    count: fsCount,
    isLoading: fsIsLoading,
    page: fsPage,
    limit: fsLimit,
    query,
    changePage: fsChangePage,
    changeLimit: fsChangeLimit,
    changeQuery,
    refresh: fsRefresh,
    reset: frReset
  } = useVerbaliFreeSearchReactQuery({
    resourceKeys: _verbale.verbaliKeys,
    name,
    page: pageProp,
    limit: limitProp,
    query: queryProp,
    minLength: 2
  });
  /**
   * Finchè usiamo ui-router non possiamo sincronizzare lo stato con l'url. Questo perchè ad ogni cambiamento di parametri ui.router smonta e rimonda da capo la vista
   * provocando la reinizializzazione dei componenti e degli atomi e selettori. Quando passeremo a React Router questa implementazione sarà utilizzabile
   */
  // syncWithUrl({
  //     page, limit, query,
  //     activeFilters: activeFilters && activeFilters.length ? JSON.stringify(activeFilters) : undefined,
  //     activeSorters: activeSorters && activeSorters.length ? JSON.stringify(activeSorters) : undefined,
  //     open: open ? 1 : 0,
  //     activeItem,
  //     selectionActive: selectionActive ? 1 : 0
  // });

  const resetMasterDetail = () => {
    reset();
    fsRefresh();
    resetState();
  };

  (0, _react.useEffect)(() => {
    // Resettiamo lo stato quando usciamo dalla rotta radice
    window.$transitions.onExit({
      exiting: 'navigation.verbali'
    }, () => {
      resetMasterDetail();
    });
  }, []);
  return {
    page,
    limit,
    isLoading: freeSearchActive(query) ? fsIsLoading : isLoading,
    query,
    items: freeSearchActive(query) ? fsItems : items,
    count: freeSearchActive(query) ? fsCount : count,
    availableFilters: filters.filter(f => f.count > 0),
    activeFilters,
    changePage,
    changeLimit,
    addFilter: props => {
      addFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    removeFilter: props => {
      removeFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    availableSorters,
    activeSorters,
    activeSortersCount: query ? 0 : activeSortersCount,
    setSorters: setActiveSorters,
    refresh: freeSearchActive(query) ? fsRefresh : refresh,
    changeFreeTextSearch: (value, reason) => {
      fsChangeLimit(20);
      changeQuery(value);
    },
    minLengthQuery,
    //Master detail props
    open,
    selectionActive,
    activeItem,
    setState,
    resetMasterDetail
  };
};

exports.useVerbaliMasterDetail = useVerbaliMasterDetail;