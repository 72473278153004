"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importoEmptyForm = exports.importoValidationSchema = exports.toApiImporto = exports.fromApiImporto = void 0;

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

var _object = require("../../object.utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const fromApiImporto = remoteImporto => {
  return { ...(remoteImporto.aggId ? {
      aggId: remoteImporto.aggId
    } : {}),
    canDelete: (0, _object.getPathBooleanOrFalse)(["canDelete"])(remoteImporto),
    inVigoreDal: (0, _object.getPathNumberToDateOrNull)(["inVigoreDal"])(remoteImporto),
    inVigoreAl: (0, _object.getPathNumberToDateOrNull)(["inVigoreAl"])(remoteImporto),
    letterale: (0, _object.getPathStringOrEmptyString)(["letterale"])(remoteImporto),
    tipoMezzo: (0, _object.getPathStringOrEmptyString)(["tipoMezzo"])(remoteImporto),
    importoMinimo: (0, _object.getPathNumberOrNull)(["importoMinimo"])(remoteImporto),
    importoMassimo: (0, _object.getPathNumberOrNull)(["importoMassimo"])(remoteImporto),
    importoScontato: (0, _object.getPathNumberOrNull)(["importoScontato"])(remoteImporto),
    importoARuolo: (0, _object.getPathNumberOrNull)(["importoARuolo"])(remoteImporto),
    minimoEditale: (0, _object.getPathNumberOrNull)(["minimoEditale"])(remoteImporto),
    importoMinimoNotturno: (0, _object.getPathNumberOrNull)(["importoMinimoNotturno"])(remoteImporto),
    importoMassimoNotturno: (0, _object.getPathNumberOrNull)(["importoMassimoNotturno"])(remoteImporto),
    importoScontatoNotturno: (0, _object.getPathNumberOrNull)(["importoScontatoNotturno"])(remoteImporto),
    importoARuoloNotturno: (0, _object.getPathNumberOrNull)(["importoARuoloNotturno"])(remoteImporto),
    minimoEditaleNotturno: (0, _object.getPathNumberOrNull)(["minimoEditaleNotturno"])(remoteImporto),
    importoMinimoSelezionato: (0, _object.getPathNumberOrNull)(["importoMinimoSelezionato"])(remoteImporto),
    importoMassimoSelezionato: (0, _object.getPathNumberOrNull)(["importoMassimoSelezionato"])(remoteImporto),
    importoARuoloSelezionato: (0, _object.getPathNumberOrNull)(["importoARuoloSelezionato"])(remoteImporto),
    importoScontatoSelezionato: (0, _object.getPathNumberOrNull)(["importoScontatoSelezionato"])(remoteImporto),
    minimoEditaleSelezionato: (0, _object.getPathNumberOrNull)(["minimoEditaleSelezionato"])(remoteImporto)
  };
};

exports.fromApiImporto = fromApiImporto;

const toApiImporto = values => {
  return { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    inVigoreDal: (0, _object.getPathDateToNumberOrNull)(["inVigoreDal"])(values),
    inVigoreAl: (0, _object.getPathDateToNumberOrNull)(["inVigoreAl"])(values),
    letterale: (0, _object.getPathStringOrEmptyString)(["letterale"])(values),
    tipoMezzo: (0, _object.getPathStringOrEmptyString)(["tipoMezzo"])(values),
    importoMinimo: (0, _object.getPathNumberOrNull)(["importoMinimo"])(values),
    importoMassimo: (0, _object.getPathNumberOrNull)(["importoMassimo"])(values),
    importoScontato: (0, _object.getPathNumberOrNull)(["importoScontato"])(values),
    importoARuolo: (0, _object.getPathNumberOrNull)(["importoARuolo"])(values),
    minimoEditale: (0, _object.getPathNumberOrNull)(["minimoEditale"])(values),
    importoMinimoNotturno: (0, _object.getPathNumberOrNull)(["importoMinimoNotturno"])(values),
    importoMassimoNotturno: (0, _object.getPathNumberOrNull)(["importoMassimoNotturno"])(values),
    importoScontatoNotturno: (0, _object.getPathNumberOrNull)(["importoScontatoNotturno"])(values),
    importoARuoloNotturno: (0, _object.getPathNumberOrNull)(["importoARuoloNotturno"])(values),
    minimoEditaleNotturno: (0, _object.getPathNumberOrNull)(["minimoEditaleNotturno"])(values),
    importoMinimoSelezionato: (0, _object.getPathNumberOrNull)(["importoMinimoSelezionato"])(values),
    importoMassimoSelezionato: (0, _object.getPathNumberOrNull)(["importoMassimoSelezionato"])(values),
    importoARuoloSelezionato: (0, _object.getPathNumberOrNull)(["importoARuoloSelezionato"])(values),
    importoScontatoSelezionato: (0, _object.getPathNumberOrNull)(["importoScontatoSelezionato"])(values),
    minimoEditaleSelezionato: (0, _object.getPathNumberOrNull)(["minimoEditaleSelezionato"])(values)
  };
};

exports.toApiImporto = toApiImporto;
const importoValidationSchema = Yup.object().shape({
  inVigoreDal: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  importoMinimo: Yup.number().required(_utils.requiredMessage).nullable(),
  importoMassimo: Yup.number().required(_utils.requiredMessage).nullable(),
  importoARuolo: Yup.number().required(_utils.requiredMessage).nullable(),
  importoScontato: Yup.number().required(_utils.requiredMessage).nullable(),
  minimoEditale: Yup.number().required(_utils.requiredMessage).nullable(),
  importoMinimoNotturno: Yup.number().required(_utils.requiredMessage).nullable(),
  importoMassimoNotturno: Yup.number().required(_utils.requiredMessage).nullable(),
  importoARuoloNotturno: Yup.number().required(_utils.requiredMessage).nullable(),
  importoScontatoNotturno: Yup.number().required(_utils.requiredMessage).nullable(),
  minimoEditaleNotturno: Yup.number().required(_utils.requiredMessage).nullable()
});
exports.importoValidationSchema = importoValidationSchema;
const importoEmptyForm = {
  letterale: "",
  tipoMezzo: "",
  inVigoreDal: null,
  inVigoreAl: null,
  importoMinimo: null,
  importoMassimo: null,
  importoARuolo: null,
  importoARuoloNotturno: null,
  importoScontato: null,
  importoMinimoNotturno: null,
  importoMassimoNotturno: null,
  importoScontatoNotturno: null,
  minimoEditale: null,
  minimoEditaleNotturno: null,
  importoMinimoSelezionato: null,
  importoMassimoSelezionato: null,
  importoARuoloSelezionato: null,
  importoScontatoSelezionato: null,
  minimoEditaleSelezionato: null
};
exports.importoEmptyForm = importoEmptyForm;