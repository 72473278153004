"use strict";

/**
 * Created by dan on 26/04/17.
 */
(function () {
  'use strict';
  /**
   * @ngdoc type
   * @name toUpper
   * @module polcity.filters
   *
   * @description
   * Will uppercase the string passed to it
   *
   * @usage
   * <hljs language="html">
   *     {{myString | toUpper}}
   * </hljs>
   */

  angular.module('polcity.filters').filter('toUpper', function () {
    return function (val) {
      if (!val) {
        return;
      }

      return R.toUpper(val);
    };
  });
})();