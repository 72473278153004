"use strict";

angular.module('polcity.presentazioneDocumenti').config(['$stateProvider', 'presentazioneDocumentiRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['presentazioneDocumentiRoutes', routesRun]);

function addRoutes($stateProvider, presentazioneDocumentiRoutesProvider, $translatePartialLoader, AuthProvider) {
  R.forEach(function (routeDefinition) {
    var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

    if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
      $stateProvider.state(stateName, routeDefinition);
    }
  }, presentazioneDocumentiRoutesProvider.list());
}

function routesRun(presentazioneDocumentiRoutes) {
  presentazioneDocumentiRoutes.subscribeUIEvents();
}