"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('FermoSequestro', ['restmod', function (restmod) {
    return restmod.model('fermoSequestro').mix({
      $extend: {
        Scope: {
          $config: {
            name: 'fermiSequestri',
            baseEntityPath: 'fermo-sequestro/',
            api: {
              list: {
                url: 'ricerca/fermi-sequestri',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/fermo-sequestro',
                method: 'POST'
              },
              destroy: {
                url: 'comando/cancella',
                method: 'POST'
              },
              save: {
                url: 'comando/nuovo',
                method: 'POST'
              },
              edit: {
                url: 'comando/modifica',
                method: 'POST'
              },
              restituzione: {
                url: 'comando/restituzione',
                method: 'POST'
              },
              annullaRestituzione: {
                url: 'comando/annulla-restituzione',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl('list');
            };

            return collection;
          }
        },
        Record: {
          $restituzione: function (_restituzione) {
            return this.$action(function () {
              var request = this.$buildRequest('restituzione');
              request.data = _restituzione;
              this.$dispatch('before-restituzione', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-restituzione', [_response]);
              }, function (_response) {
                this.$dispatch('after-restituzione-error', [_response]);
              });
            });
          },
          $annullaRestituzione: function () {
            return this.$action(function () {
              var request = this.$buildRequest('annullaRestituzione');
              request.data = {
                aggId: this.$pk
              };
              this.$dispatch('before-annulla-restituzione', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-annulla-restituzione', [_response]);
              }, function (_response) {
                this.$dispatch('after-annulla-restituzione-error', [_response]);
              });
            });
          },
          //Override della defaul $destroy
          $destroy: function () {
            return this.$action(function () {
              if (this.aggId) {
                var request = this.$buildRequest('destroy', true);
                request.data = {
                  aggId: this.aggId
                };
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$unwrap(this.$getResponseData(_response));
                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) {
                  this.$scope.$remove(this);
                }
              }
            });
          }
        }
      }
    });
  }]);
})();