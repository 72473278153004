"use strict";

(function () {
  'use strict';

  angular.module('polcity.filters').filter('nameFromSoggetto', function () {
    return function (soggetto) {
      if (soggetto !== undefined && soggetto !== null) {
        if (soggetto.tipoAnagrafica === 'persona_fisica') {
          return soggetto.cognome + ' ' + soggetto.nome;
        }

        if (soggetto.tipoAnagrafica === 'figura_giuridica') {
          return soggetto.ragioneSociale;
        }
      }

      return '?';
    };
  });
})();