"use strict";

/**
 * Created by GB on 23/11/2018
 *
 *
 */
(function () {
  'use strict';

  angular.module('osListComponent').directive('osListItem', osListItem);

  function osListItem() {
    return {
      restrict: 'E',
      require: '^osList',
      templateUrl: 'osFramework/osList/osListItem/osListItem.component.html',
      link: postLink,
      controllerAs: 'osListItemCtrl',
      transclude: true,
      controller: ['$scope', '$element', '$transclude', function ($scope, $element, $transclude) {
        var me = this;

        me.isSelected = function (item) {
          var isSelected = false,
              primaryKey = $scope.osList.primaryKey;

          if ($scope.osList.item) {
            isSelected = item[primaryKey] == $scope.osList.item[primaryKey] ? true : false;
          }

          return isSelected;
        };

        me.onToggleFollowClick = function (item) {
          if (item) {
            $scope.osList.toggleFollow(item);
          }
        };

        me.isChecked = function (item) {
          var primaryKey = $scope.osList.primaryKey;
          return R.findIndex(R.propEq(primaryKey, item[primaryKey]), $scope.osList.selectedItems) > -1;
        };
      }]
    };

    function postLink(scope, element, attrs, controllers, transclude) {
      scope.osList = controllers;
    }
  }
})();