"use strict";

/**
 * MN 13/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaEnteTerritoriale', [RicercaEnteTerritoriale]);

  function RicercaEnteTerritoriale() {
    return {
      restrict: 'E',
      require: ['ngModel'],
      scope: {
        entity: '=?',
        label: '@?',
        onEnteTerritorialeChange: '<?',
        isRequired: '<?',
        name: '@?',
        afterInsert: '<?'
      },
      controller: ['$scope', '$mdDialog', 'Command', controllerFn],
      controllerAs: 'ricercaEnteTerritorialeCtrl',
      templateUrl: 'osFramework/input/ricercaEnteTerritoriale/ricercaEnteTerritoriale.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $mdDialog, Command) {
      var me = this;
      me.groupRoles = ['ROLE_ENTE_TERRITORIALE_RICERCA', 'ROLE_ENTE_TERRITORIALE_DETTAGLIO'];

      me.getItemText = function (ente) {
        var itemText;
        itemText = ente.tipoEnteTerritoriale + ' - ' + ente.nome;
        return itemText;
      };

      me.nuovoEnteTerritoriale = function () {
        var self = this,
            confirmable = $mdDialog.show({
          templateUrl: 'osFramework/input/ricercaEnteTerritoriale/modal/insertEditEnteTerritoriale.modal.html',
          fullscreen: false,
          escapeToClose: true,
          controller: 'EnteTerritorialeFormController',
          controllerAs: 'enteTerritorialeFormCtrl',
          bindToController: true,
          focusOnOpen: true,
          multiple: true,
          locals: {
            hide: $mdDialog.cancel,
            save: $mdDialog.hide
          }
        }).then(function (enteTerritoriale) {
          return salvaEnteTerritoriale(enteTerritoriale);
        }).then(function (enteTerritoriale) {
          if (scope.afterInsert) {
            scope.afterInsert(enteTerritoriale);
          }
        }).catch(function (e) {
          console.log(e);
        });

        function salvaEnteTerritoriale(enteTerritoriale) {
          return Command.execute('@salvaEnteTerritoriale', enteTerritoriale);
        }
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.aggId = null;
          return;
        }

        scope.aggId = viewValue;
      };

      scope.$watch('aggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();