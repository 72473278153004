"use strict";

const beProtocol = window._env_.BE_PROTOCOL;
const beHost = window._env_.BE_HOST;
const bePort = window._env_.BE_PORT ? `:${window._env_.BE_PORT}` : ``;
angular.module('polcity.visure').component('newVisura', {
  bindings: {
    data: '<?',
    conf: '<?'
  },
  templateUrl: 'visure/newVisura/newVisura.component.html',
  controller: 'newVisuraController',
  controllerAs: 'newVisuraCtrl'
}).controller('newVisuraController', ['$scope', '$mdDialog', 'Visura', NewVisuraController]);

function NewVisuraController($scope, $mdDialog, Visura) {
  var me = this;

  me.$onInit = function () {};

  me.onNewVisuraClick = function () {
    $mdDialog.show({
      templateUrl: 'visure/newVisura/modal/newVisura.modal.html',
      controller: function () {
        var me = this,
            targaRegExp = new RegExp(/^(([a-zA-Z]{2}\d{3}[a-zA-Z]{2})|(([a-zA-Z]{2}|roma)(\d{5}|\d{6})))$/i),
            requestMockData = {
          metodoEffettuazioneVisura: 'SERVIZI',
          indirizzoBackend: `${beHost}${bePort}`,
          contestoVisura: 'LIBERA',
          rifAggId: '00000000-0000-0000-0000-000000000000'
        },
            visuraPatenteMockData = {
          'dettaglioPatenteRequestType': {
            'login': {},
            'ambitoPatenteBase': {
              'patente': {
                'numeroPatente': null
              }
            },
            'pdf': false,
            'pdfAnteprimaPatente': false
          }
        },
            visuraProprietarioMockData = {
          'personaFisicaVeicoliRequestType': {
            'login': {},
            'ambitoPersonaFisicaVeicoli': {
              'codiceFiscale': null
            },
            'pdf': false
          }
        };
        me.newVisura = {};
        me.showWarningTarga = false;
        $scope.$watch(() => me.newVisura.tipoOperazione, function (newVal, oldVal) {
          if (newVal === oldVal) {
            return;
          }

          me.newVisura = {
            tipoOperazione: newVal,
            nomeCoda: me.newVisura.nomeCoda || '',
            sorgenteDati: me.newVisura.sorgenteDati || ''
          };
        });

        me.onAgenteChange = function (entity) {
          if (R.isNil(entity.entity)) return;
          me.newVisura.nomeCoda = 'REQUEST_'.concat(entity.entity.nomeCoda);
        };

        me.checkTarga = function () {
          if (!me.newVisura.targa) {
            me.showWarningTarga = false;
            return;
          }

          if (!targaRegExp.exec(me.newVisura.targa)) {
            me.showWarningTarga = true;
          } else {
            me.showWarningTarga = false;
          }
        };

        me.hide = function () {
          $mdDialog.cancel();
        };

        me.save = function (_newVisura) {
          var visuraForApi = Visura.$build(_newVisura);
          visuraForApi = Object.assign(visuraForApi, requestMockData);
          visuraForApi.sorgenteDati = [_newVisura.sorgenteDati];

          switch (visuraForApi.tipoOperazione) {
            case 'VISURA_PATENTE':
              visuraPatenteMockData.dettaglioPatenteRequestType.ambitoPatenteBase.patente.numeroPatente = visuraForApi.numeroPatente;
              delete visuraForApi.numeroPatente;
              visuraForApi = Object.assign(visuraForApi, visuraPatenteMockData);
              break;

            case 'VISURA_PROPRIETARIO':
              visuraProprietarioMockData.personaFisicaVeicoliRequestType.ambitoPersonaFisicaVeicoli.codiceFiscale = visuraForApi.codiceFiscale;
              delete visuraForApi.codiceFiscale;
              visuraForApi = Object.assign(visuraForApi, visuraProprietarioMockData);
              break;
          }

          $mdDialog.hide(visuraForApi);
        };
      },
      controllerAs: 'newVisuraCtrl',
      fullscreen: false,
      escapeToClose: true,
      clickOutsideToClose: true,
      bindToController: true,
      focusOnOpen: true,
      multiple: false
    }).then(function (_newVisura) {
      return _newVisura.$aggiungiVisura(_newVisura.$getModel()).$then(function (res) {
        $scope.$emit('after-visura-added', res);
      });
    }).catch(function (_err) {
      console.log(_err);
    });
  };
}