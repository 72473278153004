"use strict";

/**
 * Created by MN 12/12/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('statoPatrimoniale', {
    templateUrl: 'osContabilita/bilancio/statoPatrimoniale/statoPatrimoniale.component.html',
    controller: 'StatoPatrimonialeController',
    controllerAs: 'statoPatrimonialeCtrl',
    bindings: {
      data: '<'
    }
  });
})();