"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listaPosizioniRelative = void 0;

var _recoil = require("recoil");

var _api = require("../api");

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

const listaPosizioniRelative = (0, _recoil.selector)({
  key: "listaPosizioniRelative",
  get: function () {
    var _ref = _asyncToGenerator(function* () {
      const response = yield (0, _api.listaPosizioniRelative)();

      if (response.error) {
        throw response.error;
      }

      return response;
    });

    return function get() {
      return _ref.apply(this, arguments);
    };
  }()
});
exports.listaPosizioniRelative = listaPosizioniRelative;