"use strict";

/**
 * GB 10/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaTipoVeicolo', [RicercaTipoVeicolo]);

  function RicercaTipoVeicolo() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaTipoVeicolo'],
      scope: {
        entity: '=?',
        label: '@?',
        onTipoVeicoloChange: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', '$filter', controllerFn],
      controllerAs: 'ricercaTipoVeicoloCtrl',
      templateUrl: 'osFramework/input/ricercaTipoVeicolo/ricercaTipoVeicolo.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter) {
      var me = this;
      me.groupRoles = ['ROLE_TIPO_VEICOLO_RICERCA', 'ROLE_TIPO_VEICOLO_DETTAGLIO'];

      me.getItemText = function (tipoVeicolo) {
        var itemText;
        itemText = $filter('translatable')(tipoVeicolo.descrizione);
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaTipoVeicoloCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.tipoVeicoloAggId = null;
          return;
        }

        scope.tipoVeicoloAggId = viewValue;
      };

      scope.$watch("tipoVeicoloAggId", function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();