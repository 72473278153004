"use strict";

/**
 * Created by stepulze on 13/04/17.
 */
(function () {
  'use strict';

  angular.module('polcity.articoliCds').config(['$stateProvider', 'articoliCdsRoutesProvider', 'AuthProvider', addRoutes]).run(['articoliCdsRoutes', routesRun]);

  function addRoutes($stateProvider, articoliCdsRoutesProvider, AuthProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }
    }, articoliCdsRoutesProvider.list());
  }

  function routesRun(articoliCdsRoutes) {
    articoliCdsRoutes.subscribeUIEvents();
  }
})();