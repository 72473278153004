"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('AllegatiDigitali', ['restmod', function (restmod) {
    return restmod.model('allegatiDigitali').mix({
      $extend: {
        Scope: {
          $config: {
            name: 'allegati',
            baseEntityPath: 'allegato-digitale-staging/',
            api: {
              list: {
                url: 'ricerca/allegati-digitali',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/allegato-digitale',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl("list");
            };

            return collection;
          }
        }
      }
    });
  }]);
})();