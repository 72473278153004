"use strict";

/**
 * Created by MN 19/09/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('mastrino', {
    templateUrl: 'osContabilita/mastrino/mastrino.component.html',
    controller: 'mastrinoController'
  }).controller('mastrinoController', ['$scope', 'mastrinoTitle', 'mastrinoData', '$mdDialog', MastrinoController]);

  function MastrinoController($scope, mastrinoTitle, mastrinoData, $mdDialog) {
    var me = this;
    me.mastrinoData = mastrinoData;
    me.mastrinoTitle = mastrinoTitle;

    me.$onInit = function () {};

    $scope.closeMastrinoDialog = function () {
      $mdDialog.hide();
    };
  }
})();