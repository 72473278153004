"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRuoliMasterDetail = exports._availableSorters = exports._availableFilters = exports.ruoliMasterDetailName = void 0;

var _recoil = require("recoil");

var _masterDetail = require("../generics/masterDetail");

var _quickSearch = require("../generics/quickSearch");

var _api = require("../../api");

var _utils = require("./utils");

var _ruoli = require("./ruoli");

const ruoliMasterDetailName = "ruoli";
exports.ruoliMasterDetailName = ruoliMasterDetailName;

const _availableFilters = (0, _recoil.atom)({
  key: '_availableFiltersRuoli',
  default: [{
    name: 'stato',
    label: 'Stato ruolo',
    value: null
  }, {
    name: 'protocollo',
    label: 'Protocollo',
    value: null
  }, {
    name: 'semestreAnno',
    label: 'Semestre/anno',
    value: null
  }]
});

exports._availableFilters = _availableFilters;

const _availableSorters = (0, _recoil.atom)({
  key: '_availableSortersRuoli',
  default: [{
    name: 'semestreAnno',
    label: 'Semestre/anno',
    direction: null
  }, {
    name: 'protocollo',
    label: 'Protocollo',
    direction: null
  }]
});

exports._availableSorters = _availableSorters;
const useRuoliQuickSearch = (0, _quickSearch.useQuickSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    order,
    stato,
    semestreAnno,
    protocollo
  }) => (0, _api.ricercaRuoli)({
    page,
    limit,
    order,
    stato: stato === null || stato === void 0 ? void 0 : stato.alias,
    semestreAnno: Boolean(semestreAnno) ? `${semestreAnno.semestre}/${semestreAnno.anno}` : null,
    protocollo
  })
});

const useRuoliMasterDetail = props => {
  const {
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  } = props || {};
  const availableSorters = (0, _recoil.useRecoilValue)(_availableSorters);
  const availableFilters = (0, _recoil.useRecoilValue)(_availableFilters);
  const {
    open,
    selectionActive,
    activeItem,
    setState,
    reset: resetState
  } = (0, _masterDetail.useMasterDetailState)({
    name,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  });
  const {
    page,
    limit,
    items,
    isLoading,
    count,
    activeFilters,
    activeSorters,
    activeSortersCount,
    changePage,
    changeLimit,
    addFilter,
    removeFilter,
    setActiveSorters,
    refresh
  } = useRuoliQuickSearch({
    resourceKeys: _ruoli.ruoliKeys,
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp
  });

  const resetMasterDetail = () => {
    resetState();
    refresh(_ruoli.ruoliKeys.quickSearch());
  };

  return {
    page,
    limit,
    isLoading: isLoading,
    ruoli: (0, _utils.fromApiRuoliQuickSearch)(items),
    count: count,
    availableFilters,
    activeFilters,
    changePage,
    changeLimit,
    addFilter: props => {
      activeFilters.length && removeFilter(props.name);
      addFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    removeFilter: props => {
      removeFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    availableSorters,
    activeSorters,
    activeSortersCount,
    setSorters: setActiveSorters,
    refresh: refresh,
    //Master detail props
    open,
    selectionActive,
    activeItem,
    setState,
    resetMasterDetail
  };
};

exports.useRuoliMasterDetail = useRuoliMasterDetail;