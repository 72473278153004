"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statiRuoliFakeData = exports.ruoliDettaglioFakeData = exports.ruoliRicercaVeloceFakeData = void 0;
const ruoliRicercaVeloceFakeData = {
  "filters": [{
    "name": "protocollo",
    "label": "Numero Lotto",
    "count": 10,
    "values": [{
      "value": "1/2022",
      "count": 10
    }, {
      "value": "1/2022",
      "count": 10
    }]
  }, {
    "name": "protocollo",
    "label": "Numero Lotto",
    "count": 10,
    "values": [{
      "value": "1/2022",
      "count": 10
    }, {
      "value": "1/2022",
      "count": 10
    }]
  }],
  "resultsWrapper": {
    "count": 2,
    "values": [{
      "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce9333",
      "protocollo": "1/2022",
      "semestre": "1/2022",
      // aggiungere ???
      "stato": "CREATO",
      "dataCreazione": 1634076000000,
      "dataFlusso": 1634076500000,
      "dataInvio": 1634076500000
    }, {
      "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
      "protocollo": "1/2022",
      "stato": "CREATO",
      "dataCreazione": 1634076000000,
      "dataFlusso": 1634076500000,
      "dataInvio": 1634076500000
    }]
  },
  "search": {
    "semestreAnno": "1/2022",
    "protocollo": "1/2022",
    "stato": "CREATO",
    "attiva": true,
    "excludedDb": true,
    "explodeId": true,
    "limit": 10,
    "order": "dataViolazioneVerbale,asc:dataScadenza,asc",
    "page": 1
  }
};
exports.ruoliRicercaVeloceFakeData = ruoliRicercaVeloceFakeData;
const ruoliDettaglioFakeData = {
  "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
  // aggiungere
  "protocollo": "1/2022",
  // aggiungere
  "stato": "CREATO",
  // aggiungere
  "semestre": "1/2022",
  // aggiungere ???
  "dataCreazione": 1634076000000,
  "dataFlusso": null,
  "dataInvio": null,
  "creatoDa": "287654 NG - Luogotenente carica speciale Alessandro Mazzanti.",
  "insoluti": [{
    "aggId": "123",
    // aggiungere
    "verbaleId": "1/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "432",
    "verbaleId": "2/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "4321",
    "verbaleId": "3/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "43f3122",
    "verbaleId": "4/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "1sq23",
    "verbaleId": "5/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "43fdas2",
    "verbaleId": "6/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "112vr23",
    "verbaleId": "7/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "4fvfe32",
    "verbaleId": "8/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "vsfda123",
    "verbaleId": "9/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "4vfeq32",
    "verbaleId": "10/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "12vdfvwe3",
    "verbaleId": "11/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "4123wfds32",
    "verbaleId": "12/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "1xzczxc23",
    "verbaleId": "13/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }, {
    "aggId": "43fqwefr2",
    "verbaleId": "14/V/2022",
    "dataViolazione": 1634076500000,
    "dataNotificaTrasgressore": 1634076500000,
    "dataNotificaObbligato": 1634076500000,
    "dataTerminePagamento": 1634076500000,
    "importoSaldato": 343.32,
    "importoInsoluto": 343.32,
    "nomeCognomeSoggettoPerRuolo": "Mario Sereno"
  }]
};
exports.ruoliDettaglioFakeData = ruoliDettaglioFakeData;
const statiRuoliFakeData = [{
  alias: "CREATO",
  label: "CREATO"
}, {
  alias: "FLUSSO_GENERATO",
  label: "FLUSSO GENERATO"
}, {
  alias: "INVIATO",
  label: "INVIATO"
}];
exports.statiRuoliFakeData = statiRuoliFakeData;