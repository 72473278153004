"use strict";

/**
 * Created by MN 20/09/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('armonizzazioneContabileChartsView', {
    templateUrl: 'osContabilita/armonizzazioneContabileChartsView/armonizzazioneContabileChartsView.component.html',
    controller: 'armonizzazioneContabileChartsView'
  }).controller('armonizzazioneContabileChartsView', ['$scope', 'ScritturaContabile', '$filter', '$mdDialog', '$stateParams', ArmonizzazioneContabileChartsView]);

  function ArmonizzazioneContabileChartsView($scope, ScritturaContabile, $filter, $mdDialog, $stateParams) {
    var me = this,
        mastrinoTitle;
    /**
     * Confiurazioni iniziali della collection scrittura contabile
     */

    $scope.scritturaContabile = ScritturaContabile.$collection({
      page: 1,
      limit: 10
    });
    $scope.scritturaContabile.$on('before-fetch-many', function (res) {
      $scope.isLoadingList = true;
    });
    $scope.scritturaContabile.$on('after-fetch-many', function (res) {
      $scope.isLoadingList = false;
      $scope.page = $scope.scritturaContabile.$metadata.search.page;
      $scope.limit = $scope.scritturaContabile.$metadata.search.limit;
      $scope.total = $scope.scritturaContabile.$metadata.total;
      $scope.filters = $scope.scritturaContabile.$metadata.filters;
      $scope.activeFilters = $scope.scritturaContabile.$getActiveFilters();
      setChartData($scope.filters);
      $stateParams.filter ? addFilter({
        name: R.keys($stateParams.filter)[0],
        value: $stateParams.filter[R.keys($stateParams.filter)[0]]
      }) : angular.noop();
    });
    $scope.scritturaContabile.$on('after-fetch-many-error', function (res) {
      $scope.isLoadingList = false;
    });
    $scope.column = [{
      text: 'Data versamento',
      dataIndex: 'data',
      renderer: function (item) {
        return moment(item.data).format('LL');
      }
    }, {
      text: 'Data registrazione',
      dataIndex: 'dateRegistrazione',
      renderer: function (item) {
        return moment(item.dateRegistrazione).format('LL');
      }
    }, {
      text: 'Conto',
      dataIndex: 'conto'
    }, {
      text: 'Descrizione',
      dataIndex: 'descrizione'
    }, {
      text: 'Dare',
      dataIndex: 'dareAvere',
      renderer: function (item) {
        return item.dareAvere === 'D' ? $filter('currency')(item.importo) : '';
      }
    }, {
      text: 'Avere',
      dataIndex: 'dareAvere',
      renderer: function (item) {
        return item.dareAvere === 'A' ? $filter('currency')(item.importo) : '';
      }
    }];
    $scope.chartData = [];
    $scope.menuIsOpen = false;

    me.$onInit = function () {
      $scope.filters = [];
      $scope.activeFilters = [];
      $scope.scritturaContabile.$ricercaVeloceNonPaginata();
    };
    /**
     * Handlers
     */


    $scope.onShowMastrinoClick = function () {
      var mastrinoData = calculateMastrinoData();
      mastrinoData.totali = calculateMastrinoTotali(mastrinoData);
      $mdDialog.show({
        controller: 'mastrinoController',
        controllerAs: '$ctrl',
        templateUrl: 'osContabilita/mastrino/mastrino.component.html',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          mastrinoTitle: mastrinoTitle,
          mastrinoData: mastrinoData
        }
      });
    };

    $scope.onPrintMenuClick = function ($mdMenu, ev) {
      $mdMenu.open(ev);
    };

    $scope.onMastrinoPrintBtnClick = function () {};

    $scope.onListaScrittureContabiliPrintBtnClick = function () {};

    $scope.onPageChange = function (query) {
      $scope.scritturaContabile.$ricercaVeloce({
        limit: query.limit,
        page: query.page
      });
    };
    /*******************************
     * Charts configuration
     *******************************/


    function setChartData(data) {
      $scope.chartData = data;
    }
    /**
     * Filtri temporali
     */


    $scope.firstDateFilterLabel = 'Data versamento';
    $scope.firstDateFilterKey = 'contabilitaDataRange';
    $scope.secondDateFilterLabel = 'Data registrazione';
    $scope.secondDateFilterKey = 'contabilitaDataRegistrazioneRange';

    $scope.addTimeRangeFilter = function (filterData) {
      addFilter({
        name: filterData.selectionType,
        value: filterData.timeRange
      });
    };
    /**
     * Filters management
     */


    $scope.addFiler = addFilter;

    function addFilter(f) {
      var filtro = f.filter ? f.filter : f;

      if (filterAlreadyInPlace(filtro)) {
        return;
      }

      if (filtro.name === 'conto') {
        mastrinoTitle = filtro.value;
      }

      $scope.scritturaContabile.$addFilter(filtro);
    }

    $scope.removeFilter = removeFilter;

    function removeFilter(filtro) {
      if ($stateParams.filter) {
        delete $stateParams.filter;
      }

      if (filtro.name === 'conto') {
        $scope.mastrinoDare = [];
        $scope.mastrinoAvere = [];
      }

      $scope.scritturaContabile.$removeFilter(filtro.name);
      $scope.$broadcast('reset-date-range');
      $scope.$broadcast('reset-importi-range');
    }

    $scope.resetFilter = function () {
      if ($stateParams.filter) {
        delete $stateParams.filter;
      }

      $scope.mastrinoDare = [];
      $scope.mastrinoAvere = [];
      $scope.scritturaContabile.$resetFilters();
      $scope.$broadcast('reset-date-range');
      $scope.$broadcast('reset-importi-range');
    };

    function filterAlreadyInPlace(filtro) {
      var activeFilter = $scope.scritturaContabile.$getActiveFilters(),
          filterIsPresent = false;

      for (var f of activeFilter) {
        if (f.value === filtro.value) {
          filterIsPresent = true;
        } // se ho già un filtro dello stesso tipo devo togliere quello "vecchio" dall'array dei filtri attivi
        // ma solo se ha un valore diverso (click sullo stesso filtro)


        if (f.name === filtro.name && f.value !== filtro.value) {
          removeFilter(f);
        }

        if (f.name === 'contabilitaDataRange' && filtro.name === 'contabilitaDataRegistrazioneRange' || f.name === 'contabilitaDataRegistrazioneRange' && filtro.name === 'contabilitaDataRange') {
          removeFilter(f);
        }
      }

      return filterIsPresent;
    }
    /**
     * Utils
     */


    function calculateMastrinoData() {
      var data = {
        dare: [],
        avere: []
      };

      for (var v of $scope.scritturaContabile) {
        if (v.dareAvere === 'A') {
          data.avere.push(v);
        } else if (v.dareAvere === 'D') {
          data.dare.push(v);
        }
      }

      return data;
    }

    function calculateMastrinoTotali(mastrinoData) {
      var totali = {
        totaleDare: 0,
        totaleAvere: 0
      };
      mastrinoData.dare.filter(function (value) {
        return totali.totaleDare += value.importo;
      });
      mastrinoData.avere.filter(function (value) {
        return totali.totaleAvere += value.importo;
      });
      return totali;
    }
  }
})();