"use strict";

const protocol = window._env_.BE_PROTOCOL;
const host = window._env_.BE_HOST;
const port = window._env_.BE_PORT ? `:${window._env_.BE_PORT}` : '';
const api = window._env_.API;
angular.module('polcity.services').service('endpoint', [service]);

function service() {
  return {
    get: build
  };

  function build() {
    return `${protocol}://${host}${port}/${api}`;
  }
}