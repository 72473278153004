"use strict";

var _RuoliMasterDetail = _interopRequireDefault(require("./masterDetail/RuoliMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.ruoli').provider('ruoliRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'ruoli',
    role: 'ROLE_LOTTO_RUOLI_RICERCA',
    url: 'ruoli?{page:int}&{limit:int}&{query:string}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _RuoliMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Ruoli"
    }
  }];
  routesProviderFactory.extend(this);
}