"use strict";

var _RicorsoEditRoot = _interopRequireDefault(require("./edit/RicorsoEditRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.ricorso').provider('ricorsoRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'ricorso',
    abstract: true,
    templateUrl: 'ricorso/ricorso.html'
  }, {
    name: 'ricorso.detail',
    url: 'ricorsi/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    params: {
      subView: null,
      aggId: null,
      ricorso: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: false
    },
    views: {
      $default: {
        component: 'ricorso'
      }
    },
    resolve: {
      ricorso: ['$q', '$state', '$stateParams', 'Ricorso', function ($q, $state, $stateParams, Ricorso) {
        if ($stateParams.ricorso) {
          return $stateParams.ricorso;
        } else {
          return Ricorso.$find($stateParams.aggId, {
            aggId: $stateParams.aggId
          }).$asPromise().catch(function (err) {
            return $state.go('navigation.ricorsi.list') && $q.reject(err);
          });
        }
      }],
      $label: ['ricorso', function (ricorso) {
        return ricorso.protocolloComunale;
      }]
    }
  }, {
    name: 'ricorso.edit',
    url: 'ricorsi/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}/edit',
    //uuid regex
    role: 'ROLE_RICORSO_MODIFICA',
    params: {
      aggId: {
        squash: true,
        value: null
      },
      ricorso: null,
      verbali: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: false
    },
    views: {
      $default: {
        component: _RicorsoEditRoot.default
      }
    },
    resolve: {
      $label: ['$stateParams', function ($stateParams) {
        return "Modifica ricorso";
      }]
    }
  }, {
    name: 'ricorso.detail.controdeduzione',
    url: '/controdeduzione/{controdeduzioneAggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex', //uuid regex
    role: 'ROLE_RICORSO_MODIFICA',
    params: {
      controdeduzione: null,
      controdeduzioneAggId: {
        squash: true,
        value: null
      }
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: false
    },
    views: {
      '@navigation.ricorso': {
        component: 'ricorsoControdeduzione'
      }
    },
    resolve: {
      controdeduzione: ['$q', '$state', '$stateParams', 'Controdeduzione', function ($q, $state, $stateParams, Controdeduzione) {
        if ($stateParams.controdeduzione) {
          return $stateParams.controdeduzione;
        } else {
          return $stateParams.controdeduzioneAggId ? Controdeduzione.$find($stateParams.controdeduzioneAggId, {
            controdeduzioneId: $stateParams.controdeduzioneAggId
          }).$asPromise().catch(function (err) {
            return $state.go('navigation.ricorso.detail') && $q.reject(err);
          }) : Controdeduzione.$build();
        }
      }],
      $label: ['controdeduzione', '$translate', function (controdeduzione, $translate) {
        var label;

        if (controdeduzione.$pk) {
          label = 'ricorso.modificaControdeduzione__bcLabel';
        } else {
          label = 'ricorso.aggiungiControdeduzione__bcLabel';
        }

        return $translate(label);
      }]
    }
  }, {
    name: 'ricorso.detail.esito',
    url: '/esito',
    params: {},
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: false
    },
    views: {
      '@navigation.ricorso': {
        component: 'ricorsoEsito'
      }
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('ricorso.aggiungiEsito__bcLabel');
      }]
    }
  }, {
    name: 'ricorso.detail.generaFascicolo',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: false
    },
    views: {
      '@navigation.ricorso': {
        component: 'ricorsoGeneraFascicolo'
      }
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('ricorso.generaFascicolo__bcLabel');
      }]
    }
  }, {
    name: 'ricorso.detail.ritentaRicorso',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: false
    },
    views: {
      '@navigation.ricorso': {
        component: 'ricorsoRitentaRicorso'
      }
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('ricorso.ritentaRicorso__bcLabel');
      }]
    }
  }];
  routesProviderFactory.extend(this);
}