"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tipiAccertamentoListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

var _enums = require("../../../state/enums");

const tipiAccertamentoListColumns = [{
  field: 'codiceVeloce',
  headerName: 'Codice veloce',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'nome',
  headerName: 'Nome',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'tempoRilevazione',
  headerName: 'Tempo di rilevazione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'tipoVerbale',
  headerName: 'Tipo verbale',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaTipiVerbale") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.tipiAccertamentoListColumns = tipiAccertamentoListColumns;