"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const localStorageEffect = (key, serializer = a => a) => ({
  setSelf,
  onSet
}) => {
  const savedValue = localStorage.getItem(key);

  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet(newValue => {
    if (!newValue) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(serializer(newValue)));
    }
  });
};

var _default = localStorageEffect;
exports.default = _default;