"use strict";

(function () {
  'use strict';

  angular.module('osAsyncDropdown').directive('osAsyncDropdown', ['Command', '$q', directive]);

  function directive(Command, $q) {
    return {
      template: function (element, attr) {
        var templateTag = element.find('item-template').detach(),
            html = templateTag.length ? templateTag.html() : element.html();

        if (!templateTag.length) {
          element.empty();
        }

        return '<md-autocomplete ng-required="ngRequired" md-input-name="autocomplete" md-selected-item="$selectedItem"\n' + '                 md-floating-label="{{label | translate}}" md-search-text="$searchText" md-items="$item in getMatches($searchText)"\n' + '                 ng-disabled="ngDisabled"  ' + '                 placeholder="{{placeholder}}" ' + '                 md-min-length="loadDataAtOnce" ' + '                 md-selected-item-change="dataToNgModel()"\n' + '                 md-item-text="$item[displayName] | translatable">\n' + '    <md-item-template>\n' + html + '    </md-item-template>\n' + '    <div ng-messages="form.autocomplete.$error">\n' + '        <div ng-message="required">\n' + '            <translate>form.error.required</translate>\n' + '        </div>\n' + '    </div>\n' + '   <md-not-found ng-show="!$parent.hideValue"> ' + '       <span translate translate-values="{textToMatch: $searchText}">form.errors.noMatchInList</span> ' + '   </md-not-found> ' + '</md-autocomplete>';
      },
      require: ['ngModel', '^form'],
      scope: {
        searchCommandName: '@searchCommand',
        detailCommandName: '@detailCommand',
        options: '=',
        ngRequired: '=',
        ngDisabled: '=',
        displayName: '@',
        onChange: '=',
        placeholder: '@',
        label: '@'
      },
      link: postLink
    };

    function postLink(scope, element, attrs, ctrls) {
      var ngModel = ctrls[0],
          defaultPromise = null;
      scope.form = ctrls[1];
      ngModel.$render = ngModelToData;
      scope.getMatches = getMatches;
      scope.dataToNgModel = dataToNgModel;

      if (scope.options.default) {
        tryDefault();
      } else {
        defaultPromise = $q.when();
      } // proprietà che, se impostata a true nelle options, consente, al click sul campo di input, di visualizzare tutti i risultati
      // ATTENZIONE PERCHE' VENGONO CARICATI TUTTI I RISULTATI SENZA TENERE CONTO DI EVENTUALI VINCOLI, COME NEL CASO DELLA
      // RICERCA DELLE STRADE NEI VERBALI DOVE DEVONO ESSERE VISUALIZZATE SOLO LE STRADE DI UN COMUNE E NON TUTTE!!!!


      if (scope.options.loadDataAtOnce) {
        scope.loadDataAtOnce = 0;
      }

      function tryDefault() {
        // search for the default value or with empty key
        defaultPromise = getMatches(scope.options.defaultValue || '').then(function (matches) {
          if (matches.length === 1) {
            scope.$selectedItem = matches[0];
            dataToNgModel(); // scope.$searchText = scope.$selectedItem[scope.displayName];
          }
        });
      }

      function getMatches(searchText) {
        var query = searchText;

        if (scope.options.params && searchText) {
          searchText = R.merge({
            query: searchText
          }, scope.options.params);
        } //FIXME: gestione temporanea in attesa di rimuovere questa porcheria di direttiva (GB)


        if (scope.options.method === "newApi") {
          var searchText = {
            query: query
          };
        }

        return Command.execute(scope.searchCommandName, searchText) //FIXME: gestione temporanea in attesa di rimuovere questa porcheria di direttiva (GB)
        .then(function (result) {
          if (result.hasOwnProperty("resultsWrapper")) {
            return result.resultsWrapper.values;
          } else {
            return result;
          }
        });
      }

      function dataToNgModel() {
        if (areThSame(ngModel.$modelValue, scope.$selectedItem)) {
          return;
        }

        if (scope.options.valueKey && R.has(scope.options.valueKey, scope.$selectedItem || {})) {
          ngModel.$setViewValue(scope.$selectedItem[scope.options.valueKey]);
        } else {
          ngModel.$setViewValue(scope.$selectedItem);
        }

        if (scope.onChange) {
          scope.onChange(scope.$selectedItem);
        }
      }

      function ngModelToData() {
        // the external model changed and we do not have the new full value
        // await the possible default
        defaultPromise.then(function () {
          if (ngModel.$modelValue && !areThSame(ngModel.$modelValue, scope.$selectedItem)) {
            // if `detailCommandName` was set fetch the detail and update the field view
            fetchSingleItem(ngModel.$modelValue).then(function (resp) {
              scope.$selectedItem = resp;
            }).catch(function (reason) {
              console.error(reason);
            });
          } else if (!areThSame(ngModel.$modelValue, scope.$selectedItem)) {
            scope.$selectedItem = ngModel.$modelValue;
          }
        });
      }

      function areThSame(src, compareTo) {
        if (scope.options.valueKey && R.is(Object, compareTo)) {
          return compareTo && compareTo[scope.options.valueKey] === src;
        }

        return src === compareTo;
      }
      /**
       *
       * @param {*} requestData
       * @return {Promise}
       */


      function fetchSingleItem(requestData) {
        if (!scope.detailCommandName) {
          return $q.reject({
            who: 'osAsyncDropdown',
            what: 'fetchSingleItem',
            why: 'missing detailCommand'
          });
        } //FIXME: gestione temporanea in attesa di rimuovere questa porcheria di direttiva (GB)


        if (scope.options.method === "newApi") {
          var entityId = requestData;
          requestData = {};
          requestData[scope.options.valueKey] = entityId;
        }

        return Command.execute(scope.detailCommandName, requestData);
      }
    }
  }
})();