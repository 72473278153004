"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _formik = require("formik");

var _utils = require("../../state/soggetto/utils");

var _utils2 = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const SoggettoEffects = () => {
  const {
    values,
    setValues,
    touched,
    isSubmitting,
    resetForm,
    setFieldTouched
  } = (0, _formik.useFormikContext)();
  (0, _react.useEffect)(() => {
    if (touched.tipoAnagrafica && !isSubmitting) {
      resetForm({
        values: Object.assign({}, _utils.soggettoEmptyForm, {
          tipoAnagrafica: values.tipoAnagrafica
        })
      });
    }
  }, [values.tipoAnagrafica]);
  (0, _react.useEffect)(() => {
    if (values.cfPi && (touched.sesso || touched.nome || touched.cognome || touched.dataNascita || touched.comuneNascita) && !isSubmitting) {
      setValues((0, _utils2.deepmerge)(values, {
        cfPi: ''
      }));
    }
  }, [values.sesso, values.nome, values.cognome, values.dataNascita, values.comuneNascita]);
  (0, _react.useEffect)(() => {
    if (touched.comuneResidenza && !isSubmitting) {
      setValues({ ...values,
        capResidenza: values.comuneResidenza ? values.comuneResidenza.cap : ""
      });
    }

    setFieldTouched("comuneResidenza", false);
  }, [values.comuneResidenza]);
  (0, _react.useEffect)(() => {
    if (touched.comuneDomicilio && !isSubmitting) {
      setValues((0, _utils2.deepmerge)(values, {
        capDomicilio: values.comuneDomicilio ? values.comuneDomicilio.cap : ""
      }));
    }

    setFieldTouched("comuneDomicilio", false);
  }, [values.comuneDomicilio]);
  (0, _react.useEffect)(() => {
    if (touched.comuneSedeLegale && !isSubmitting) {
      setValues((0, _utils2.deepmerge)(values, {
        capSedelegale: values.comuneSedeLegale ? values.comuneSedeLegale.cap : ""
      }));
    }

    setFieldTouched("comuneSedeLegale", false);
  }, [values.comuneSedeLegale]);
  return null;
};

var _default = SoggettoEffects;
exports.default = _default;