"use strict";

/**
 * Created by gb on 15/09/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.ricorso.esito').controller('RicorsoEsitoController', ['$scope', '$state', '$stateParams', 'OSNotification', RicorsoEsitoController]);

  function RicorsoEsitoController($scope, $state, $stateParams, OSNotification) {
    var me = this;
    me.isSaving = false;
    me.isLoadingProssimiStatiValidi = false;

    me.$onInit = function () {
      //Prepopoliamo alcune info utili
      me.esito = {
        aggId: me.ricorso.$pk,
        applicataSospensiva: false,
        rideterminataScadenza: false,
        importoInvariato: true,
        importoDaPagare: 0,
        speseDaPagare: 0
      };
      me.ricorso.$on("before-prossimi-stati-validi", function (res) {
        me.isLoadingProssimiStatiValidi = true;
      });
      me.ricorso.$on("after-prossimi-stati-validi", function (res) {
        me.isLoadingProssimiStatiValidi = false;
      });
      me.ricorso.$on("after-prossimi-stati-validi-error", function (res) {
        me.isLoadingProssimiStatiValidi = false;
        me.notifyUser("error", "Impossibile recuperare i prossimi stati validi del ricorso");
        me.goBack();
      });
      me.ricorso.$getProssimiStatiValidi(); //Chiama il be e aggiunge al ricorso la property $prossimiStatiValidi
    };

    $scope.$watch(() => me.esito, function (newVal, oldVal) {
      // i valori di applicataSospensiva e rideterminataScadenza possono essere entrambi a false o uno a true e l'altro a false, ma mai tutti e due a true
      if (newVal.applicataSospensiva != oldVal.applicataSospensiva) {
        me.esito.applicataSospensiva = newVal.applicataSospensiva;

        if (me.esito.applicataSospensiva && me.esito.rideterminataScadenza) {
          me.esito.rideterminataScadenza = false;
        }
      } else if (newVal.rideterminataScadenza != oldVal.rideterminataScadenza) {
        me.esito.rideterminataScadenza = newVal.rideterminataScadenza;

        if (me.esito.applicataSospensiva && me.esito.rideterminataScadenza) {
          me.esito.applicataSospensiva = false;
        }
      }
    }, true); //La sezione degli importi viene visualizzata solo per gli esiti diversi da ACCOLTO

    me.showImporti = function () {
      return me.esito && me.esito.esitoRicorso && me.esito.esitoRicorso != "ACCOLTO";
    };

    me.onAnnulla = function () {
      me.goBack();
    };

    me.onSalva = function () {
      me.isSaving = true;
      me.ricorso.$esitoRicorso(me.esito).$asPromise().then(function (res) {
        me.notifyUser("success", "Esito salvato con successo");
        me.goBack();
      }).catch(function (err) {
        console.log(err);
        me.notifyUser("error", "Impossibile salvare la modifica");
      }).finally(function () {
        me.isSaving = false;
      });
    };

    me.goBack = function () {
      $state.go("^", {
        aggId: $stateParams.aggId,
        subView: 'esito'
      });
    };

    me.notifyUser = function (type, msg) {
      OSNotification[type]({
        translationIndex: msg
      });
    };
  }
})();