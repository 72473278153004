"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gestioneStradeListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

var _enums = require("../../../state/enums");

const gestioneStradeListColumns = [{
  field: 'codiceVeloce',
  headerName: 'Codice veloce',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'tipologia',
  headerName: 'Tipo strada',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaTipiStrada") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'toponomastica',
  headerName: 'Toponomastica',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'nome',
  headerName: 'Nome',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'frazione',
  headerName: 'Frazione',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? value.descrizione : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'cap',
  headerName: 'CAP',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.gestioneStradeListColumns = gestioneStradeListColumns;