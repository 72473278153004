"use strict";

/**
 * Created by GB on 11/07/2018
 *
 * Component per gestire l'ordinamento su osList.
 *
 */
(function () {
  'use strict';

  angular.module('osListComponent').component('sorters', {
    //per come è definita osList serve un riferimento assoluto rispetto alla root
    templateUrl: 'osFramework/osList/sorters/sorters.component.html',
    controller: ['api', '$scope', SortersController],
    controllerAs: 'sortersController',
    bindings: {
      sorters: '<',
      activeSorters: '<',
      sorterAddHandler: '&',
      sorterRemoveHandler: '&'
    }
  });

  function SortersController(api, $scope) {
    var me = this;
    /**
     * Handler scatenato alla selezione di una tipologia di ordinamento
     * @param item: elemento da ordire
     * @param selectedValue: direzione dell'ordinamento
     */

    me.onSortOptionsChange = function (_key, _value) {
      if (_value.direction !== '-1') {
        var sorter = {
          label: _value.label,
          key: _key,
          direction: _value.direction
        };
        addSorter(sorter);
      } else {
        removeSorter(_key);
      }
    };

    me.onRemoveSorter = function (_key) {
      removeSorter(_key);
    };
    /***********************
     * Utils
     ***********************/


    function addSorter(sorter) {
      me.sorterAddHandler({
        sorter: sorter
      });
      $scope.$emit('osList::sorterAdd', sorter);
    }

    function removeSorter(sorterKey) {
      me.sorterRemoveHandler({
        sorterKey: sorterKey
      });
      $scope.$emit('osList::sorterRemove', sorterKey);
    }
  }
})();