"use strict";

var _ValoriGlobaliMasterDetail = _interopRequireDefault(require("./masterDetail/ValoriGlobaliMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.valoriGlobali').provider('valoriGlobaliRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'valoriGlobaliList',
    role: ['ROLE_SEZIONI_VALORI_GLOBALI', 'ROLE_LEGGI_VALORI_GLOBALI'],
    url: 'valoriGlobaliNew',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ValoriGlobaliMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Lista valori globali"
    }
  }];
  routesProviderFactory.extend(this);
}