"use strict";

/**
 * GB 10/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaStrada', [RicercaStrada]);

  function RicercaStrada() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaStrada'],
      scope: {
        entity: '=?',
        label: '@?',
        onStradaChange: '<?',
        isRequired: '<?',
        name: '@?',
        additionalQueryParams: '<?',
        afterInsert: '<?'
      },
      controller: ['$scope', '$filter', '$mdDialog', 'Command', controllerFn],
      controllerAs: 'ricercaStradaCtrl',
      templateUrl: 'osFramework/input/ricercaStrada/ricercaStrada.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter, $mdDialog, Command) {
      var me = this;
      me.groupRoles = ['ROLE_STRADA_RICERCA', 'ROLE_STRADA_DETTAGLIO'];

      me.getItemText = function (strada) {
        var itemText;
        itemText = strada.codiceVeloce + ' - ' + strada.toponomastica + ' ' + $filter('translatable')(strada.nome);
        return itemText;
      };

      me.nuovaStrada = function () {
        $mdDialog.show({
          templateUrl: 'osFramework/input/ricercaStrada/modal/insertEditStrada.modal.html',
          fullscreen: true,
          controller: 'GestioneStradeFormController',
          controllerAs: 'gestioneStradeFormCtrl',
          bindToController: true,
          focusOnOpen: true,
          locals: {
            hide: $mdDialog.cancel,
            save: $mdDialog.hide
          }
        }).then(function (strada) {
          return salvaStrada(strada, false);
        }).then(function (strada) {
          if (scope.afterInsert) {
            scope.afterInsert(strada);
          }
        }).catch(function (e) {
          console.log(e);
        });
      };

      function salvaStrada(strada, edit) {
        if (scope.additionalQueryParams.aggId) {
          strada.aggId = scope.additionalQueryParams.aggId;
        }

        return Command.execute('@salvaStrada', strada);
      }
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaStradaCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.stradaAggId = null;
          return;
        }

        scope.stradaAggId = viewValue;
      };

      scope.$watch('stradaAggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();