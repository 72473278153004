"use strict";

var _CassaRoot = _interopRequireDefault(require("./CassaRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.cassa').provider('cassaRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'cassa',
    role: 'ROLE_PAGAMENTO_CASSA',
    url: 'cassa',
    params: {
      reloadPreviousState: false
    },
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _CassaRoot.default
      }
    },
    resolve: {
      $label: () => "Cassa"
    }
  }];
  routesProviderFactory.extend(this);
}