"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useQuickSearchReactQuery = exports._quickSearchState = exports.getQsNs = void 0;

var _recoil = require("recoil");

var _react = require("react");

var _reactQuery = require("react-query");

const defaultQuickSearchState = {
  page: 1,
  limit: 10,
  activeFilters: [],
  activeSorters: []
};

const getQsNs = name => `pc-qs-${name}`;

exports.getQsNs = getQsNs;

const _quickSearchState = (0, _recoil.atomFamily)({
  key: '_quickSearchState',
  default: ({
    name,
    params
  }) => {
    return { ...defaultQuickSearchState,
      ...params
    };
  }
});

exports._quickSearchState = _quickSearchState;

const useQuickSearchReactQuery = ({
  apiCallback
}) => ({
  resourceKeys,
  name,
  page: pageProp,
  limit: limitProp,
  activeFilters: activeFiltersProp,
  activeSorters: activeSortersProp
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const ns = getQsNs(name);
  const [{
    page,
    limit,
    activeFilters,
    activeSorters
  }, setState] = (0, _recoil.useRecoilState)(_quickSearchState({
    name: ns,
    pageProp,
    limitProp,
    activeFiltersProp,
    activeSortersProp
  }));
  (0, _react.useEffect)(() => {
    window.$transitions.onExit({
      exiting: `navigation.${name}List`
    }, (transition, state) => {
      queryClient.removeQueries(resourceKeys.all());
    });
  });
  const {
    isLoading,
    data,
    isFetching
  } = (0, _reactQuery.useQuery)(resourceKeys.quickSearch({
    page,
    limit,
    activeFilters,
    activeSorters
  }), ({
    queryKey
  }) => {
    const {
      page,
      limit,
      activeFilters,
      activeSorters
    } = queryKey[2];
    const filters = activeFilters.length ? activeFilters.reduce((acc, f) => ({ ...acc,
      [f.name]: f.value
    }), {}) : null;
    const order = activeSorters.length ? activeSorters.map(s => s.direction != null ? `${s.name},${s.direction}` : null).join(":") : null;
    return apiCallback({
      page,
      limit,
      order,
      ...filters
    });
  }, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5
  });
  return {
    page: page,
    limit: limit,
    activeFilters: activeFilters,
    activeSorters: activeSorters,
    activeSortersCount: activeSorters.length,
    isLoading: isLoading || isFetching,
    items: (data === null || data === void 0 ? void 0 : data.resultsWrapper.values) || [],
    count: (data === null || data === void 0 ? void 0 : data.resultsWrapper.count) || 0,
    filters: (data === null || data === void 0 ? void 0 : data.filters) || [],
    changePage: page => setState(state => ({ ...state,
      page: page
    })),
    changeLimit: limit => setState(state => ({ ...state,
      page: 1,
      limit: limit
    })),
    addFilter: ({
      name,
      label,
      value,
      valueLabel
    }) => setState(state => ({ ...state,
      page: 1,
      activeFilters: [...state.activeFilters, {
        name,
        label,
        value,
        valueLabel
      }]
    })),
    removeFilter: name => setState(state => ({ ...state,
      page: 1,
      activeFilters: state.activeFilters.filter(f => f.name != name)
    })),
    setActiveSorters: sorters => setState(state => ({ ...state,
      page: 1,
      activeSorters: sorters.filter(s => s.direction != null)
    })),
    refresh: queryKey => queryClient.invalidateQueries(queryKey ? queryKey : resourceKeys.all()),
    reset: () => setState(state => ({ ...state,
      ...defaultQuickSearchState
    }))
  };
};

exports.useQuickSearchReactQuery = useQuickSearchReactQuery;