"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromApiVerbaleArchivioArmaposte = exports.fromApiRicercaVerbaiArchivioArmaposte = void 0;

var _utils = require("../../utils");

var _object = require("../../object.utils");

const fromApiRicercaVerbaiArchivioArmaposte = remoteVerbali => remoteVerbali.map(verbale => ({ ...verbale,
  dataOraVerbale: (0, _object.getPathStringOrEmptyString)(["dataOraVerbale"])(verbale) ? new Date(verbale.dataOraVerbale) : ""
}));

exports.fromApiRicercaVerbaiArchivioArmaposte = fromApiRicercaVerbaiArchivioArmaposte;

const fromApiVerbaleArchivioArmaposte = remoteVerbale => {
  return {
    annoVerbale: (0, _object.getPathNumberOrNull)(["annoVerbale"])(remoteVerbale),
    dataOraVerbale: (0, _object.getPathStringOrEmptyString)(["dataOraVerbale"])(remoteVerbale) ? new Date(remoteVerbale.dataOraVerbale) : "",
    idVerbale: (0, _object.getPathStringOrEmptyString)(["idVerbale"])(remoteVerbale),
    infrazioni: (0, _object.getPathArrayOrEmptyArray)(["infrazioni"])(remoteVerbale),
    luogoAccertamento: (0, _object.getPathObjectOrNull)(["luogoAccertamento"])(remoteVerbale),
    obbligato: (0, _object.getPathObjectOrNull)(["obbligato"])(remoteVerbale),
    trasgressore: (0, _object.getPathObjectOrNull)(["trasgressore"])(remoteVerbale),
    stazioneComando: (0, _object.getPathObjectOrNull)(["stazioneComando"])(remoteVerbale),
    veicolo: (0, _object.getPathObjectOrNull)(["veicolo"])(remoteVerbale),
    totaliImporti: (0, _object.getPathObjectOrNull)(["totaliImporti"])(remoteVerbale),
    allegati: (0, _object.getPathArrayOrEmptyArray)(["allegati"])(remoteVerbale),
    luogoAccertamentoString: (0, _object.getPathObjectOrNull)(["luogoAccertamento"])(remoteVerbale) ? `${(0, _object.getPathStringOrEmptyString)(["luogoAccertamento", "nome"])(remoteVerbale)} ${(0, _object.getPathStringOrEmptyString)(["luogoAccertamento", "enteTerritorialeSigla"])(remoteVerbale)}` : "--",
    spedizioni: (0, _object.getPathArrayOrEmptyArray)(["spedizioni"])(remoteVerbale).length ? (0, _object.getPathArrayOrEmptyArray)(["spedizioni"])(remoteVerbale).map(spedizione => ({ ...spedizione,
      dataSpedizione: (0, _object.getPathStringOrEmptyString)(["dataSpedizione"])(spedizione) ? new Date(spedizione.dataSpedizione) : "",
      dataNotifica: (0, _object.getPathStringOrEmptyString)(["dataNotifica"])(spedizione) ? new Date(spedizione.dataNotifica) : "",
      dataInoltro: (0, _object.getPathStringOrEmptyString)(["dataInoltro"])(spedizione) ? new Date(spedizione.dataInoltro) : ""
    })) : [],
    pagamenti: (0, _object.getPathArrayOrEmptyArray)(["pagamenti"])(remoteVerbale).length ? (0, _object.getPathArrayOrEmptyArray)(["pagamenti"])(remoteVerbale).map(pagamento => ({ ...pagamento,
      dataPagamento: (0, _object.getPathStringOrEmptyString)(["dataPagamento"])(pagamento) ? new Date(pagamento.dataPagamento) : ""
    })) : [],
    soggetti: [...(0, _utils.insertIf)(remoteVerbale.trasgressore, { ...remoteVerbale.trasgressore,
      posizione: "T",
      nominativo: `${(0, _utils.capitalize)((0, _object.getPathStringOrEmptyString)(["trasgressore", "nome"])(remoteVerbale))} ${(0, _utils.capitalize)((0, _object.getPathStringOrEmptyString)(["trasgressore", "cognome"])(remoteVerbale))}`,
      residenza: `
                    ${(0, _object.getPathStringOrEmptyString)(["trasgressore", "indirizzoResidenza"])(remoteVerbale)} 
                    ${(0, _object.getPathStringOrEmptyString)(["trasgressore", "civicoResidenza"])(remoteVerbale)}
                    ${(0, _object.getPathStringOrEmptyString)(["trasgressore", "capResidenza"])(remoteVerbale)} 
                    ${(0, _object.getPathStringOrEmptyString)(["trasgressore", "comuneResidenza", "nome"])(remoteVerbale)} 
                    ${(0, _object.getPathStringOrEmptyString)(["trasgressore", "comuneResidenza", "enteTerritorialeSigla"])(remoteVerbale)}
                `
    }), ...(0, _utils.insertIf)(remoteVerbale.obbligato, { ...remoteVerbale.obbligato,
      posizione: "OB",
      nominativo: `${(0, _utils.capitalize)((0, _object.getPathStringOrEmptyString)(["obbligato", "nome"])(remoteVerbale))} ${(0, _utils.capitalize)((0, _object.getPathStringOrEmptyString)(["obbligato", "cognome"])(remoteVerbale))}`,
      residenza: `
                    ${(0, _object.getPathStringOrEmptyString)(["obbligato", "indirizzoResidenza"])(remoteVerbale)} 
                    ${(0, _object.getPathStringOrEmptyString)(["obbligato", "civicoResidenza"])(remoteVerbale)}
                    ${(0, _object.getPathStringOrEmptyString)(["obbligato", "capResidenza"])(remoteVerbale)} 
                    ${(0, _object.getPathStringOrEmptyString)(["obbligato", "comuneResidenza", "nome"])(remoteVerbale)}
                    ${(0, _object.getPathStringOrEmptyString)(["obbligato", "comuneResidenza", "enteTerritorialeSigla"])(remoteVerbale)}
                `
    })]
  };
};

exports.fromApiVerbaleArchivioArmaposte = fromApiVerbaleArchivioArmaposte;