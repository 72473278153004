"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAllegatoMetaReactQuery = exports.useDownloadAllegatoReactQuery = exports.useAllegatiAggregatoReactQuery = void 0;

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _api = require("../../api");

var _utils = require("../../react/reactQuery/utils");

var _allegatiNonAssociati = require("../allegatiDigitaliNonAssociati/allegatiNonAssociati");

var _utils2 = require("../allegatiDigitaliNonAssociati/utils");

var _utils3 = require("../../utils");

const useAllegatiAggregatoReactQuery = ({
  aggId,
  aggregateCanonicalName,
  onSuccess,
  onFailure
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(_allegatiNonAssociati.allegatiDigitaliNonAssociatiKeys.aggregato({
    aggId,
    aggregateCanonicalName
  }), ({
    queryKey
  }) => (0, _api.ricercaAllegatiAggregato)({
    aggId: queryKey[2].aggId,
    aggregateCanonicalName: queryKey[2].aggregateCanonicalName
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId) && Boolean(aggregateCanonicalName),
    onSuccess: response => (0, _utils.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: response => {
      const allegati = response.reduce((acc, val) => {
        return [...acc, ...val.items.reduce((acc2, val2) => {
          return [...acc2, ...val2.attaches];
        }, [])];
      }, []);
      return allegati;
    }
  });
  return {
    allegatiAggregato: data,
    isLoading: isFetching || isLoading
  };
};

exports.useAllegatiAggregatoReactQuery = useAllegatiAggregatoReactQuery;

const useDownloadAllegatoReactQuery = ({
  onSuccess,
  onFailure
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Allegato scaricato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.scaricaAllegatoDigitale)({
    aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: response => (0, _utils2.handleDownloadAllegatoSuccess)({
      response
    })
  });
};

exports.useDownloadAllegatoReactQuery = useDownloadAllegatoReactQuery;

const useAllegatoMetaReactQuery = ({
  aggId,
  onSuccess,
  onFailure
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isFetching: isFetching,
    isLoading: isLoading
  } = (0, _reactQuery.useQuery)([`allegatoMeta`, {
    aggId
  }], ({
    queryKey
  }) => (0, _api.scaricaAllegatoDigitale)({
    aggId: queryKey[1].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId),
    onSuccess: response => (0, _utils.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response)
  });
  const fileType = Boolean(data) ? (0, _utils3.getFileType)(data.headers["content-type"]) : "";
  const fileName = Boolean(data) ? (0, _utils3.getFileNameFromResponseHeaders)(data.headers) : "";
  const src = Boolean(data) ? window.URL.createObjectURL((0, _utils3.byteArrayToBlob)(data.data, fileType)) : null;
  return {
    fileName,
    src,
    contentType: fileType,
    isLoading: isLoading || isFetching
  };
};

exports.useAllegatoMetaReactQuery = useAllegatoMetaReactQuery;