"use strict";

/**
 * Created by MN 09/01/2019
 */
(function () {
  'use strict';

  angular.module('polcity.modelliStampa').config(['$stateProvider', 'modelliStampaRoutesProvider', 'AuthProvider', addRoutes]).run(['modelliStampaRoutes', routesRun]);

  function addRoutes($stateProvider, modelliStampaRoutesProvider, AuthProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }
    }, modelliStampaRoutesProvider.list());
  }

  function routesRun(modelliStampaRoutes) {
    modelliStampaRoutes.subscribeUIEvents();
  }
})();