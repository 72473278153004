"use strict";

/**
 * Created by dan on 11/04/18.
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita', ['polcity.api', //'ngMaterial',
  'pascalprecht.translate' //'md.data.table'
  ]).config(['$translatePartialLoaderProvider', function ($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('osContabilita');
  }]);
})();