"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSoggettiMasterDetail = exports._availableSorters = exports._availableFilters = exports.soggettiMasterDetailName = void 0;

var _recoil = require("recoil");

var _masterDetail = require("../generics/masterDetail");

var _quickSearch = require("../generics/quickSearch");

var _api = require("../../api");

var _soggetto = require("./soggetto");

var _freeSearch = require("../generics/freeSearch");

var _react = require("react");

const soggettiMasterDetailName = "soggetti";
exports.soggettiMasterDetailName = soggettiMasterDetailName;

const _availableFilters = (0, _recoil.atom)({
  key: '_availableFiltersSoggetti',
  default: [{
    name: 'nome',
    label: 'Nome',
    value: null
  }, {
    name: 'cognome',
    label: 'Cognome',
    value: null
  }, {
    name: 'cfPi',
    label: 'CF / P. iva',
    value: null
  }]
});

exports._availableFilters = _availableFilters;

const _availableSorters = (0, _recoil.atom)({
  key: '_availableSortersSoggetti',
  default: [{
    name: 'nome',
    label: 'Nome',
    direction: null
  }, {
    name: 'cognome',
    label: 'Cognome',
    direction: null
  }]
});

exports._availableSorters = _availableSorters;
const useSoggettiQuickSearch = (0, _quickSearch.useQuickSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    order,
    nome,
    cognome,
    cfPi
  }) => (0, _api.ricercaAnagrafica)({
    page,
    limit,
    order,
    nome: nome,
    cognome: cognome,
    cfPi: cfPi
  })
});
const useSoggettiFreeSearchReactQuery = (0, _freeSearch.useFreeSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    query
  }) => (0, _api.ricercaAnagraficaTestoLibero)({
    page,
    limit,
    query
  })
});

const useSoggettiMasterDetail = props => {
  const {
    name,
    page: pageProp,
    limit: limitProp,
    query: queryProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  } = props || {};
  const availableSorters = (0, _recoil.useRecoilValue)(_availableSorters);
  const availableFilters = (0, _recoil.useRecoilValue)(_availableFilters);
  const minLengthQuery = 2;
  const freeSearchActive = (0, _react.useCallback)(query => query && query.length >= minLengthQuery, [minLengthQuery]);
  const {
    open,
    selectionActive,
    activeItem,
    setState,
    reset: resetState
  } = (0, _masterDetail.useMasterDetailState)({
    name,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  });
  const {
    page,
    limit,
    items,
    isLoading,
    count,
    activeFilters,
    activeSorters,
    activeSortersCount,
    changePage,
    changeLimit,
    addFilter,
    removeFilter,
    setActiveSorters,
    refresh,
    reset
  } = useSoggettiQuickSearch({
    resourceKeys: _soggetto.soggettiKeys,
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp
  });
  const {
    items: fsItems,
    count: fsCount,
    isLoading: fsIsLoading,
    query,
    changeLimit: fsChangeLimit,
    changeQuery,
    refresh: fsRefresh
  } = useSoggettiFreeSearchReactQuery({
    resourceKeys: _soggetto.soggettiKeys,
    name,
    page: pageProp,
    limit: limitProp,
    query: queryProp,
    minLength: 2
  });

  const resetMasterDetail = () => {
    reset();
    resetState();
    fsRefresh();
  };

  return {
    page,
    limit,
    isLoading: freeSearchActive(query) ? fsIsLoading : isLoading,
    query,
    items: freeSearchActive(query) ? fsItems : items,
    count: freeSearchActive(query) ? fsCount : count,
    availableFilters,
    activeFilters,
    changePage,
    changeLimit,
    addFilter: filter => {
      activeFilters.length && removeFilter(filter.name);
      addFilter(filter);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    removeFilter: filter => {
      removeFilter(filter);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    availableSorters,
    activeSorters,
    activeSortersCount: query ? 0 : activeSortersCount,
    setSorters: setActiveSorters,
    refresh: freeSearchActive(query) ? fsRefresh : refresh,
    changeFreeTextSearch: (value, _reason) => {
      fsChangeLimit(1000);
      changeQuery(value);
    },
    minLengthQuery,
    //Master detail props
    open,
    selectionActive,
    activeItem,
    setState,
    resetMasterDetail
  };
};

exports.useSoggettiMasterDetail = useSoggettiMasterDetail;