"use strict";

var _utils = require("../utils");

angular.module('polcity.acquisizioneDati').component('versamentiValidazioneImmagini', {
  templateUrl: 'acquisizioneDati/versamenti/validazione/versamentiValidazioneImmagini.component.html',
  controller: 'versamentiValidazioneImmaginiController'
}).controller('versamentiValidazioneImmaginiController', ['$scope', 'OSNotification', '$stateParams', '$state', 'modalPresenter', VersamentiValidazioneImmagini]);

function VersamentiValidazioneImmagini($scope, OSNotification, $stateParams, $state, modalPresenter) {
  var me = this;
  $scope.collection = $stateParams.versamentiDaValidare; // $scope.activeIndex = 25;

  $scope.verbaleToPay = null;
  $scope.soggettiList = [];
  $scope.dettaglioVerbalePanelOpen = false;
  $scope.versamentoForApi = {};
  $scope.detailTracciato = {};

  me.$onInit = function () {
    if (!$scope.collection || !$scope.collection.length) {
      $state.go('navigation.acquisizioneDati.versamenti');
      return;
    }

    if ($stateParams.versamentiDaValidare.length === 1) {
      me.versamento = $stateParams.versamentiDaValidare[0];
    }
  };

  me.onVersamentoChange = function (params) {
    me.versamento = params.activeItem;
  };

  me.onIndexChange = function (params) {
    me.index = params.activeIndex;
  };
  /**
   * Events
   */


  $scope.$watch(() => me.versamento, function (versamento) {
    if (versamento) {
      versamento.$getImmaginiVersamento().$asPromise().then(function (res) {}).catch(function (err) {}).finally(function () {
        setDefaultVersamento();
      });
    }
  });
  $scope.$watch(() => $scope.collection, function (collection) {
    $scope.canNavigate = collection.length > 1;
  });
  /**
   * Handlers
   */

  $scope.onSelectedVerbale = function (el) {
    if (justSelectedItem(el)) {
      OSNotification.info({
        message: 'Già selezionata'
      });
      return;
    }

    setSelectedVerbale(el);
  };

  $scope.onRemoveVerbaleClick = function () {
    $scope.verbaleToPay = null;
    $scope.versamentoForApi.aggId = undefined;
    $scope.soggettiList = [];
    $scope.dettaglioVerbalePanelOpen = false;
  };

  $scope.onInserisciVersamentoClick = function (validazioneImmaginiForm) {
    askUserConfirmation('info', 'acquisizioneDati.validazioneVersamenti.attribuzioneVersamento__title', 'acquisizioneDati.validazioneVersamenti.attribuzioneVersamento__body', 'acquisizioneDati.validazioneVersamenti.attribuzioneVersamentoConfirm__button').then(inserisciVersamento.bind(me)).catch(function (error) {
      notifyUser('error', 'acquisizioneDati.validazioneVersamenti.attribuzioneVersamento__error');
    }).finally(function () {
      resetVersamentoForm(validazioneImmaginiForm);
    });
  };

  function resetVersamentoForm(validazioneImmaginiForm) {
    if (validazioneImmaginiForm) {
      validazioneImmaginiForm.$setPristine();
      validazioneImmaginiForm.$setUntouched();
    }

    setDefaultVersamento();
  }
  /**
   * Methods implementation
   */


  function setDefaultVersamento() {
    var tracciato = JSON.parse(me.versamento.tracciato);

    if (me.versamento.tipoVersamento == 'bollettino_txt') {
      $scope.detailTracciato = formatTracciatoBollettinoTxtFields(tracciato);
    } else if (me.versamento.tipoVersamento == 'bollettino_sepa_bp') {
      $scope.detailTracciato = formatTracciatoBollettinoSepaBpFields(tracciato);
    } else if (me.versamento.tipoVersamento == 'bollettino_report') {
      $scope.detailTracciato = formatTracciatoBollettinoReportFields(tracciato);
    } else if (me.versamento.tipoVersamento == 'api_automatica_posta') {
      $scope.detailTracciato = formatTracciatoApiAutomaticaPostaFields(tracciato);
    }

    $scope.versamentoForApi = {};
    $scope.versamentoForApi.aggIdRilevazione = me.versamento.aggId;
    $scope.verbaleToPay = null;
    $scope.soggettiList = [];

    switch (me.versamento.tipoVersamento) {
      case 'bollettino_report':
        $scope.versamentoForApi.dataOperazione = moment(tracciato.DATAOPERAZIONE, "YYYYMMDD").valueOf();
        $scope.versamentoForApi.dataAccredito = moment(tracciato.DATAPOSTALLIBRAMENTO, "YYYYMMDD").valueOf();
        $scope.versamentoForApi.numeroVersamento = tracciato.QUARTOCAMPO ? tracciato.QUARTOCAMPO : "";
        $scope.versamentoForApi.importo = tracciato.IMPORTODOCUMENTO ? parseFloat($scope.detailTracciato.importo) : 0;
        break;

      case 'bollettino_txt':
        $scope.versamentoForApi.dataOperazione = moment(tracciato.DATA_ACCETTAZIONE, "YYMMDD").valueOf();
        $scope.versamentoForApi.dataAccredito = moment(tracciato.DATA_CONTABILE_ACCREDITO, "YYMMDD").valueOf();
        $scope.versamentoForApi.importo = tracciato.IMPORTO ? parseFloat($scope.detailTracciato.importo) : 0;
        break;

      case 'bollettino_sepa_bp':
        $scope.versamentoForApi.dataOperazione = moment(tracciato['15'], "YYYYMMDD").valueOf();
        $scope.versamentoForApi.dataAccredito = moment(tracciato['16'], "YYYYMMDD").valueOf();
        break;

      case 'api_automatica_posta':
        $scope.versamentoForApi.dataOperazione = moment(tracciato.dataVersamento);
        $scope.versamentoForApi.dataAccredito = moment(tracciato.dataContabile);
        $scope.versamentoForApi.numeroVersamento = tracciato.quartoCampo ? tracciato.quartoCampo : "";
        $scope.versamentoForApi.importo = tracciato.importoVersamento ? tracciato.importoVersamento : 0;
        break;

      default:
        $scope.versamentoForApi.dataOperazione = moment().valueOf();
        $scope.versamentoForApi.dataAccredito = moment().valueOf();
    }
  }

  function setSelectedVerbale(el) {
    $scope.verbaleToPay = el;
    $scope.soggettiList = setSoggetti(el.soggettiList);
    $scope.versamentoForApi.aggId = el.aggId;
  }

  function justSelectedItem(el) {
    var elIsPresent = false;

    if ($scope.verbaleToPay && el.aggId === $scope.verbaleToPay.aggId) {
      elIsPresent = true;
    }

    return elIsPresent;
  }

  const setSoggetti = (soggettiList = []) => {
    const result = {};
    result.trasgressore = soggettiList.find(soggetto => soggetto.tipo.toLowerCase() == 'trasgressore');
    result.obbligato = soggettiList.find(soggetto => soggetto.tipo.toLowerCase() == 'obbligato');
    return result;
  };

  const formatTracciatoBollettinoTxtFields = tracciato => {
    const result = {};
    result.codiceCliente = tracciato.CODICE_CLIENTE_CODICE_BANCOPOSTA || 'N.D.';
    result.importo = tracciato.IMPORTO ? (0, _utils.parseImporto)(tracciato.IMPORTO) : 'N.D.';
    result.dataAccettazione = tracciato.DATA_ACCETTAZIONE ? (0, _utils.parseData)(tracciato.DATA_ACCETTAZIONE, "YYMMDD", "L") : 'N.D.';
    result.dataContabile = tracciato.DATA_CONTABILE_ACCREDITO ? (0, _utils.parseData)(tracciato.DATA_CONTABILE_ACCREDITO, "YYMMDD", "L") : 'N.D.';
    return result;
  };

  const formatTracciatoBollettinoSepaBpFields = tracciato => {
    const result = {};
    result.ordinante = tracciato['12'] || 'N.D.';
    result.importo = tracciato['13'] || 'N.D.';
    result.dataVersamento = tracciato['15'] ? (0, _utils.parseData)(tracciato['15'], "YYYYMMDD", "L") : 'N.D.';
    result.dataContabile = tracciato['16'] ? (0, _utils.parseData)(tracciato['16'], "YYYYMMDD", "L") : 'N.D.';
    result.causale = tracciato['21'] || 'N.D.';
    return result;
  };

  const formatTracciatoBollettinoReportFields = tracciato => {
    const result = {};
    result.quartoCampo = tracciato.QUARTOCAMPO || 'N.D.';
    result.importo = tracciato.IMPORTODOCUMENTO ? (0, _utils.parseImporto)(tracciato.IMPORTODOCUMENTO) : 'N.D.';
    result.dataVersamento = tracciato.DATAOPERAZIONE ? (0, _utils.parseData)(tracciato.DATAOPERAZIONE, "YYYYMMDD", "L") : 'N.D.';
    result.dataContabile = tracciato.DATAPOSTALLIBRAMENTO ? (0, _utils.parseData)(tracciato.DATAPOSTALLIBRAMENTO, "YYYYMMDD", "L") : 'N.D.';
    return result;
  };

  const formatTracciatoApiAutomaticaPostaFields = tracciato => {
    const result = {};
    result.quartoCampo = tracciato.quartoCampo || 'N.D.';
    result.importo = tracciato.importoVersamento.toFixed(2).concat('€') || 'N.D.';
    result.dataVersamento = tracciato.dataVersamento ? moment(tracciato.dataVersamento).format('L') : 'N.D.';
    result.dataContabile = tracciato.dataContabile ? moment(tracciato.dataContabile).format('L') : 'N.D.';
    result.causaleBonifico = tracciato.causaleBonifico || 'N.D.';
    result.numeroVersamento = tracciato.numeroVersamento || 'N.D.';
    return result;
  };
  /**
   * Apis
   */


  function inserisciVersamento() {
    me.versamento.$attribuzioneManualeVersamento($scope.versamentoForApi);
    me.versamento.$on('after-attribuzione-manuale-versamento', function (res) {
      //FIXME: rimuovere quando l'informazione dell'attribuzione sarà dentro il versamento
      me.versamento.$validato = true;
      $scope.activeIndex = me.index + 1;
    });
  }
  /**
   * Utils
   */


  function askUserConfirmation(type, title, body, confirmBtn) {
    return modalPresenter[type]([title, body, confirmBtn, 'acquisizioneDati.validazioneVersamenti.undo__button']);
  }

  function notifyUser(type, msg) {
    return OSNotification[type]({
      translationIndex: msg
    });
  }
}