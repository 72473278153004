"use strict";

/**
 * Created by MN 19/02/2020
 */
angular.module('polcity.widgetTypes').controller('addCalendarEventController', ['$mdDialog', 'event', 'view', '$scope', addCalendarEventController]);

function addCalendarEventController($mdDialog, event, view, $scope) {
  var me = this;
  me.newEvent = Object.assign({}, event);
  me.editEventRoles = ['ROLE_DASHBOARD_USEREVENT_INIZIALIZZA', 'ROLE_DASHBOARD_USEREVENT_MODIFICA'];

  me.$onInit = function () {
    /* me.newEvent.start = me.newEvent.start
         .format();*/
    // if( me.newEvent.end && me.newEvent.allDay && view.type !== 'agendaDay' ) {
    //     me.newEvent.end = me.newEvent.end.add(-1, 'd');
    // }
    //
    // formatCreationDate();
  };
  /**
   * Controllo su data e ora inserito. La data di fine evento non può mai essere inferiore a quella di inizio
   */


  $scope.$watchGroup([() => me.newEvent.start, () => me.newEvent.end], function (newValArray) {
    if (newValArray && newValArray[0] !== newValArray[1] && moment(newValArray[1]).isBefore(moment(newValArray[0]))) {
      me.newEvent.end = me.newEvent.start;
    }
  });
  /**
   * Handlers
   * @param _event
   */

  me.onDeleteEvent = function (_event) {
    $mdDialog.cancel({
      reason: 'delete',
      deleteMsg: 'deleteEvent',
      event: _event
    });
  };

  me.onCloseModal = function () {
    $mdDialog.cancel({
      reason: 'close'
    });
  };

  me.showTimeField = false;

  me.showTime = function () {
    me.showTimeField = !me.showTimeField;
  };

  me.onCreateEventClick = function (_newEvent) {
    if (dateTimeIsDifferentFromMidnight(_newEvent.start) || dateTimeIsDifferentFromMidnight(_newEvent.end)) {
      _newEvent.allDay = false;
      _newEvent.eventCreationDate = moment(_newEvent.eventCreationDate).valueOf();
      _newEvent.start = moment(_newEvent.start).valueOf();
      _newEvent.start = moment(_newEvent.start).valueOf();
      _newEvent.end = moment(_newEvent.end).valueOf();
    } else {
      _newEvent.allDay = true;
      _newEvent.eventCreationDate = moment(_newEvent.eventCreationDate).valueOf();
      _newEvent.start = moment(_newEvent.start).valueOf(); // DEVO AGGIUNGERE UN GIORNO ALLA DATA DI FINE

      _newEvent.end = moment(_newEvent.end).add(1, 'd').valueOf();
    }

    $mdDialog.hide(_newEvent);
  };
  /**
   * Methods
   * @param date
   * @returns {boolean}
   */


  function dateTimeIsDifferentFromMidnight(date) {
    var isDifferent = false,
        myDate = moment(date),
        myDateAtMidnight = moment(date).clone().startOf('day');
    isDifferent = myDate.diff(myDateAtMidnight, 'minutes') !== 0;
    return isDifferent;
  }

  function formatCreationDate() {
    me.eventCreationDateFormatted = moment(me.newEvent.eventCreationDate).format('LLLL');
  }
}