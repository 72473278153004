"use strict";

angular.module('osFramework').directive('ricercaAgenteVisura', [RicercaAgenteVisura]);

function RicercaAgenteVisura() {
  return {
    restrict: 'E',
    require: ['ngModel', 'ricercaAgenteVisura'],
    scope: {
      entity: '=?',
      label: '@?',
      isRequired: '<?',
      name: '@?',
      onAgenteVisuraChange: '&?'
    },
    controller: ['$scope', controllerFn],
    controllerAs: 'ricercaAgenteVisuraCtrl',
    templateUrl: 'osFramework/input/ricercaAgenteVisura/ricercaAgenteVisura.directive.html',
    link: linkFn
  };

  function controllerFn(scope) {
    var me = this;
    me.groupRoles = ['notApplyRoleManagement', 'notApplyRoleManagement'];

    me.getItemText = function (agente) {
      var itemText;
      itemText = agente.nome.toUpperCase().concat(' - ', agente.descrizione.toUpperCase());
      return itemText;
    };
  }

  function linkFn(scope, element, attrs, controllers) {
    var ngModelCtrl = controllers[0],
        ricercaAgenteVisuraCtrl = controllers[1];

    ngModelCtrl.$render = function () {
      var viewValue = ngModelCtrl.$viewValue;

      if (!viewValue) {
        scope.aggId = null;
        return;
      }

      scope.aggId = viewValue;
    };

    scope.$watch('aggId', function (newVal) {
      if (!newVal) {
        newVal = '';
      }

      ngModelCtrl.$setViewValue(newVal);
    });
    scope.$watch("entity", function (newVal, oldVal) {
      if (R.isNil(newVal) || newVal == oldVal) {
        return;
      }

      if (scope.onAgenteVisuraChange) {
        scope.onAgenteVisuraChange({
          entity: newVal
        });
      }
    });
  }
}