"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleAggiungiModificaArticoloNormativaSuccess = exports.useDeleteArticoloNormativa = exports.useDuplicaArticoloNormativa = exports.useModificaRelazioneArticoloNormativaDecoratori = exports.useModificaArticoloNormativa = exports.useAggiungiArticoloNormativa = exports.useArticoloNormativa = exports.articoloNormativaKeys = void 0;

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _api = require("../../api");

var _utils = require("../../react/reactQuery/utils");

var _utils2 = require("../../utils");

var _articoliNormativeMasterDetail = require("./articoliNormativeMasterDetail");

var _utils3 = require("./utils");

const articoloNormativaKeys = {
  all: () => [_articoliNormativeMasterDetail.articoloNormativaMasterDetailName],
  detail: aggId => [...articoloNormativaKeys.all(), "detail", {
    aggId
  }],
  quickSearch: params => [...articoloNormativaKeys.all(), "quickSearch", ...(0, _utils2.insertIf)(params, params)],
  freeSearch: params => [...articoloNormativaKeys.all(), "freeSearch", ...(0, _utils2.insertIf)(params, params)]
};
exports.articoloNormativaKeys = articoloNormativaKeys;

const useArticoloNormativa = aggId => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data: remoteArticolo,
    isFetching: isFetchingRemoteArticolo,
    isLoading: isLoadingRemoteArticolo
  } = (0, _reactQuery.useQuery)(articoloNormativaKeys.detail(aggId), ({
    queryKey
  }) => (0, _api.dettaglioArticoloNormativa)({
    aggId: queryKey[2].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId),
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: res => (0, _utils3.fromApiArticoloNormativa)(res)
  });
  const {
    data: remoteLegge,
    isFetching: isFetchingLegge,
    isLoading: isLoadingLegge
  } = (0, _reactQuery.useQuery)([...articoloNormativaKeys.detail(aggId), `legge`, remoteArticolo.leggeAggId], () => (0, _api.dettaglioLegge)({
    aggId: remoteArticolo === null || remoteArticolo === void 0 ? void 0 : remoteArticolo.leggeAggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(remoteArticolo) && Boolean(remoteArticolo.leggeAggId),
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response)
  });
  const {
    data: decoratori,
    isFetching: isFetchingDecoratori,
    isLoading: isLoadingDecoratori
  } = (0, _reactQuery.useQuery)([...articoloNormativaKeys.detail(aggId), `decoratori`, remoteArticolo.aggId], () => (0, _api.listaDecoratori)({
    aggId: remoteArticolo === null || remoteArticolo === void 0 ? void 0 : remoteArticolo.leggeAggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(remoteArticolo),
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response)
  });
  const articoloNormativaExploded = { ...remoteArticolo,
    legge: remoteLegge || null,
    decoratori: (0, _utils3.deserializeDecoratori)(decoratori, (0, _utils2.removeFalse)(remoteArticolo.decoratori)) || []
  };
  return {
    item: aggId ? articoloNormativaExploded : _utils3.articoloNormativaEmptyForm,
    isFetching: isFetchingRemoteArticolo || isLoadingRemoteArticolo || isFetchingLegge || isLoadingLegge || isFetchingDecoratori || isLoadingDecoratori //|| isFetchingImporti || isLoadingImporti

  };
};

exports.useArticoloNormativa = useArticoloNormativa;

const useAggiungiArticoloNormativa = ({
  onSuccess,
  onFailure
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(values => (0, _api.creaArticoloNormativa)((0, _utils3.toApiArticoloNormativa)(values)), {
    onSuccess: (...data) => {
      const response = data[0];
      const formValues = data[1];
      response.error ? (0, _utils.handleFailure)(messenger)({
        response,
        onFailure
      }) : handleAggiungiModificaArticoloNormativaSuccess(messenger)({
        response,
        formValues,
        onSuccess
      });
    },
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(articoloNormativaKeys.all())
  });
};

exports.useAggiungiArticoloNormativa = useAggiungiArticoloNormativa;

const useModificaArticoloNormativa = ({
  onSuccess,
  onFailure
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(values => (0, _api.modificaArticoloNormativa)((0, _utils3.toApiArticoloNormativa)(values)), {
    onSuccess: (...data) => {
      const response = data[0];
      const formValues = data[1];
      response.error ? (0, _utils.handleFailure)(messenger)({
        response,
        onFailure
      }) : handleAggiungiModificaArticoloNormativaSuccess(messenger)({
        response,
        formValues,
        onSuccess
      });
    },
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(articoloNormativaKeys.all())
  });
};

exports.useModificaArticoloNormativa = useModificaArticoloNormativa;

const useModificaRelazioneArticoloNormativaDecoratori = () => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(values => (0, _api.modificaRelazioneArticoloNormativaDecoratori)((0, _utils3.toApiDecoratori)(values)), {
    onSuccess: response => response.error && (0, _utils.handleFailure)(messenger)({
      response
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(articoloNormativaKeys.all())
  });
};

exports.useModificaRelazioneArticoloNormativaDecoratori = useModificaRelazioneArticoloNormativaDecoratori;

const useDuplicaArticoloNormativa = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Articolo normativa duplicato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.duplicaArticoloNormativa)({
    aggId: aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(articoloNormativaKeys.all())
  });
};

exports.useDuplicaArticoloNormativa = useDuplicaArticoloNormativa;

const useDeleteArticoloNormativa = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Articolo normativa cancellata",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.cancellaArticoloNormativa)({
    aggId: aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(articoloNormativaKeys.all())
  });
};

exports.useDeleteArticoloNormativa = useDeleteArticoloNormativa;

const handleAggiungiModificaArticoloNormativaSuccess = messenger => ({
  response,
  formValues,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: 'Articolo / normativa salvato',
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess({
      response,
      formValues
    });
  }
};

exports.handleAggiungiModificaArticoloNormativaSuccess = handleAggiungiModificaArticoloNormativaSuccess;