"use strict";

/**
 * Created by MN 20/11/2019
 *
 * @description
 * Trasforma un oggetto con altri oggetti innestati in un array
 * https://github.com/petebacondarwin/angular-toArrayFilter
 *
 * @usage
 * <hljs lang="js">
 *     $scope.items =  {
 *          0: {date: new Date('12/23/2013')},
 *          1: {date: new Date('12/23/2011')},
 *          2: {date: new Date('12/23/2010')},
 *          3: {date: new Date('12/23/2015')}
 *    }
 * </hljs>
 *
 * <hljs lang="html">
 *      <div ng-repeat="item in items | toArray:false">
 *          {{item.date | date}}
 *      </div>
 * </hljs>
 *
 */
(function () {
  'use strict';

  angular.module('osFramework').filter('toArray', function () {
    return function (obj, addKey) {
      if (!angular.isObject(obj)) {
        return obj;
      }

      if (addKey === false) {
        return Object.keys(obj).map(function (key) {
          return obj[key];
        });
      } else {
        return Object.keys(obj).map(function (key) {
          var value = obj[key];
          return angular.isObject(value) ? Object.defineProperty(value, '$key', {
            enumerable: false,
            value: key
          }) : {
            $key: key,
            $value: value
          };
        });
      }
    };
  });
})();