"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRegistrazioniContabili = exports._ricercaRegistrazioniContabiliCount = exports._ricercaRegistrazioniContabiliValues = exports._ricercaRegistrazioniContabili = exports._ricercaRegistrazioniContabiliQuery = exports._ricercaRegistrazioniContabiliTrigger = void 0;

var _recoil = require("recoil");

var _api = require("../../api");

var _react = require("react");

var _hash = _interopRequireDefault(require("history/hash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

const historyEffect = ({
  setSelf,
  onSet
}) => {
  onSet((newValue, oldValue) => {
    const search = Object.keys(newValue).filter(k => newValue[k] != undefined || newValue[k] != null).map(key => `${key}=${newValue[key]}`).join("&"); // history.push({
    //     pathname: '/registrazioniContabili',
    //     search: search.length ? `?${search}` : ''
    // })
    // Con questo router non riusciamo a sincronizzare anche l'url
    // Per ora ci accontentiamo di tenere sincronizzato lo stato interno fino a quando non passeremo a ReactRouter

    window.$state.transitionTo('registrazioniContabili', newValue, {
      location: true,
      inherit: true,
      relative: window.$state.$current,
      notify: false
    });
  });
};

const defaultState = {
  dataDa: null,
  dataA: null,
  page: 1,
  limit: 10,
  verbale: null
};

const _ricercaRegistrazioniContabiliFilters = (0, _recoil.atom)({
  key: 'ricercaRegistrazioniContabiliFilters',
  default: defaultState,
  effects_UNSTABLE: [historyEffect]
});

const _ricercaRegistrazioniContabiliTrigger = (0, _recoil.atom)({
  key: '_ricercaRegistrazioniContabiliTrigger',
  default: 0
});

exports._ricercaRegistrazioniContabiliTrigger = _ricercaRegistrazioniContabiliTrigger;

const _ricercaRegistrazioniContabiliQuery = (0, _recoil.selector)({
  key: '_ricercaRegistrazioniContabiliQuery',
  get: function () {
    var _ref = _asyncToGenerator(function* ({
      get: _get
    }) {
      _get(_ricercaRegistrazioniContabiliTrigger);

      const {
        dataDa,
        dataA,
        page,
        limit,
        verbale
      } = _get(_ricercaRegistrazioniContabiliFilters);

      const dateRange = dataDa && dataA && dataDa < dataA ? [dataDa, dataA] : null;
      const response = yield (0, _api.ricercaRegistrazioniContabili)({
        contabilitaDataRange: dateRange,
        page,
        limit,
        rifId: verbale === null || verbale === void 0 ? void 0 : verbale.aggId
      });

      if (response.error) {
        throw response.error;
      }

      return response;
    });

    return function get(_x) {
      return _ref.apply(this, arguments);
    };
  }()
});

exports._ricercaRegistrazioniContabiliQuery = _ricercaRegistrazioniContabiliQuery;

const _ricercaRegistrazioniContabili = (0, _recoil.atom)({
  key: '_ricercaRegistrazioniContabili',
  default: _ricercaRegistrazioniContabiliQuery
});

exports._ricercaRegistrazioniContabili = _ricercaRegistrazioniContabili;

const _ricercaRegistrazioniContabiliValues = (0, _recoil.selector)({
  key: '_ricercaRegistrazioniContabiliValues',
  get: ({
    get
  }) => {
    return get(_ricercaRegistrazioniContabili).resultsWrapper.values;
  }
});

exports._ricercaRegistrazioniContabiliValues = _ricercaRegistrazioniContabiliValues;

const _ricercaRegistrazioniContabiliCount = (0, _recoil.selector)({
  key: '_ricercaRegistrazioniContabiliCount',
  get: ({
    get
  }) => {
    return get(_ricercaRegistrazioniContabili).resultsWrapper.count;
  }
});

exports._ricercaRegistrazioniContabiliCount = _ricercaRegistrazioniContabiliCount;

const useRegistrazioniContabili = ({
  dataDa,
  dataA,
  page,
  limit
}) => {
  const [filters, setFilters] = (0, _recoil.useRecoilState)(_ricercaRegistrazioniContabiliFilters);
  const loadable = (0, _recoil.useRecoilValueLoadable)(_ricercaRegistrazioniContabiliValues);
  const count = (0, _recoil.useRecoilValueLoadable)(_ricercaRegistrazioniContabiliCount);
  (0, _react.useEffect)(() => {
    setFilters(filters => ({ ...filters,
      dataDa,
      dataA,
      page: page ? page : filters.page,
      limit: limit ? limit : filters.limit
    }));
  }, [dataDa, dataA, page, limit]);
  return [loadable.valueMaybe() || [], loadable.state === "loading", {
    dataRange: {
      startDate: filters.dataDa,
      endDate: filters.dataA
    },
    verbale: filters.verbale,
    changeDataRange: (0, _recoil.useRecoilCallback)(({
      set
    }) => ({
      startDate,
      endDate
    }) => {
      set(_ricercaRegistrazioniContabiliFilters, filters => ({ ...filters,
        dataDa: startDate,
        dataA: endDate,
        page: defaultState.page,
        limit: defaultState.limit
      }));
    }),
    onChangeVerbale: (0, _recoil.useRecoilCallback)(({
      set
    }) => (e, verbale) => {
      set(_ricercaRegistrazioniContabiliFilters, filters => ({ ...filters,
        verbale,
        page: defaultState.page,
        limit: defaultState.limit
      }));
    }),
    page: filters.page,
    changePage: (0, _recoil.useRecoilCallback)(({
      set
    }) => page => {
      set(_ricercaRegistrazioniContabiliFilters, filters => ({ ...filters,
        page
      }));
    }),
    limit: filters.limit,
    changeLimit: (0, _recoil.useRecoilCallback)(({
      set
    }) => limit => {
      set(_ricercaRegistrazioniContabiliFilters, filters => ({ ...filters,
        limit,
        page: defaultState.page
      }));
    }),
    count: count.valueMaybe() || 0,
    reset: (0, _recoil.useRecoilCallback)(({
      set
    }) => _ => {
      set(_ricercaRegistrazioniContabiliFilters, filters => ({ ...filters,
        ...defaultState
      }));
    }),
    refresh: (0, _recoil.useRecoilCallback)(({
      set
    }) => _ => {
      set(_ricercaRegistrazioniContabiliTrigger, t => t + 1);
    })
  }];
};

exports.useRegistrazioniContabili = useRegistrazioniContabili;