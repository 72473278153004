"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toApiInserisciPagamentoCassa = exports.fromApiPayableItemsSearch = exports.toApiCalcolaImportiPayableItems = exports.versamentoValidationSchema = exports.versamentoEmptyForm = exports.fromApiCalcolaImporti = void 0;

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

var _object = require("../../object.utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const fromApiCalcolaImporti = data => ({ ...data,
  priceDetail: (data.priceDetail || []).map(({
    orderedDatePagamento,
    lastDataPagamento,
    ...rest
  }) => ({
    orderedDatePagamento: orderedDatePagamento.map(o => ({ ...(0, _object.extractPropNumberToDateOrNull)("date")(o),
      ...(0, _object.extractPropStringOrNull)("tipoData")(o)
    })),
    lastDataPagamento: lastDataPagamento ? { ...(0, _object.extractPropNumberToDateOrNull)("date")(lastDataPagamento),
      tipoData: lastDataPagamento.tipoData
    } : null,
    ...rest
  }))
});

exports.fromApiCalcolaImporti = fromApiCalcolaImporti;
const versamentoEmptyForm = {
  payableItemsWithSupplierUrl: [],
  tipoVersamento: "cassa",
  metodoVersamento: "contanti",
  numeroVersamento: "",
  riferimentoBolletta: "",
  causaleBonifico: "",
  dataVersamento: new Date(),
  dataContabile: new Date(),
  intestatarioRiferimento: "",
  importoVersamento: 0
};
exports.versamentoEmptyForm = versamentoEmptyForm;
const versamentoValidationSchema = Yup.object().shape({
  tipoVersamento: Yup.string().required(_utils.requiredMessage),
  metodoVersamento: Yup.string().required(_utils.requiredMessage),
  numeroVersamento: Yup.string().when("tipoVersamento", {
    is: value => value && (value == "ccp" || value == "banca"),
    then: Yup.string().required(_utils.requiredMessage)
  }),
  causaleBonifico: Yup.string().when("tipoVersamento", {
    is: value => value && value == "banca",
    then: Yup.string().required(_utils.requiredMessage)
  }),
  dataVersamento: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  dataContabile: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').min(Yup.ref('dataVersamento'), "Data contabile inferiore a data versamento").required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  intestatarioRiferimento: Yup.string().required(_utils.requiredMessage),
  importoVersamento: Yup.number().moreThan(0, "L'importo deve essere maggiore di 0").required(_utils.requiredMessage).nullable(),
  payableItemsWithSupplierUrl: Yup.array().min(1)
});
exports.versamentoValidationSchema = versamentoValidationSchema;

const toApiCalcolaImportiPayableItems = values => {
  return {
    supplierUrl: (0, _object.getPathStringOrEmptyString)(["supplierUrl"])(values),
    dataVersamento: (0, _object.getPathDateToNumberOrNull)(["dataVersamento"])(values)
  };
};

exports.toApiCalcolaImportiPayableItems = toApiCalcolaImportiPayableItems;

const fromApiPayableItemsSearch = response => {
  const result = response.map(item => ({ ...item,
    payableDescription: { ...item.payableDescription,
      dataOraVerbale: (0, _object.getPathNumberToDateOrNull)(["payableDescription", "dataOraVerbale"])(item)
    },
    payableItemCreationDate: (0, _object.getPathNumberToDateOrNull)(["payableItemCreationDate"])(item)
  }));
  return result;
};

exports.fromApiPayableItemsSearch = fromApiPayableItemsSearch;

const toApiInserisciPagamentoCassa = values => {
  return {
    causaleBonifico: (0, _object.getPathStringOrNull)(["causaleBonifico"])(values),
    dataContabile: (0, _object.getPathDateToNumberOrNull)(["dataContabile"])(values),
    dataVersamento: (0, _object.getPathDateToNumberOrNull)(["dataVersamento"])(values),
    importoVersamento: (0, _object.getPathNumberOrNull)(["importoVersamento"])(values),
    intestatarioRiferimento: (0, _object.getPathStringOrNull)(["intestatarioRiferimento"])(values),
    metodoVersamento: (0, _object.getPathStringOrNull)(["metodoVersamento"])(values),
    numeroVersamento: (0, _object.getPathStringOrNull)(["numeroVersamento"])(values),
    payIds: (0, _object.getPathArrayOrEmptyArray)(["payableItemsWithSupplierUrl"])(values).map(p => p.payId),
    riferimentoBolletta: (0, _object.getPathStringOrNull)(["riferimentoBolletta"])(values),
    tipoVersamento: (0, _object.getPathStringOrNull)(["tipoVersamento"])(values)
  };
};

exports.toApiInserisciPagamentoCassa = toApiInserisciPagamentoCassa;