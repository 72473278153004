"use strict";

/**
 * MN 13/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaRilevatore', [RicercaRilevatore]);

  function RicercaRilevatore() {
    return {
      restrict: 'E',
      require: ['ngModel'],
      scope: {
        entity: '=?',
        label: '@?',
        onRilevatoreChange: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', '$filter', controllerFn],
      controllerAs: 'ricercaRilevatoreCtrl',
      templateUrl: 'osFramework/input/ricercaRilevatore/ricercaRilevatore.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter) {
      var me = this;
      me.groupRoles = ['ROLE_RILEVATORE_INFRAZIONI_RICERCA', 'ROLE_RILEVATORE_INFRAZIONI_DETTAGLIO'];

      me.getItemText = function (rilevatore) {
        var itemText;
        itemText = rilevatore.codiceVeloce + ' - ' + rilevatore.descrizione;
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.aggId = null;
          return;
        }

        scope.aggId = viewValue;
      };

      scope.$watch('aggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();