"use strict";

/**
 * Created by dan on 02/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.api').constant('WhiteList', ['.html', 'translations', 'rules.json', 'menu.json', 'mockStatistiche.json', 'mockVersamentiNew.json', 'mockStatisticheFiltrate.json', 'mockModelliDiStampa.json', 'configuration.json', 'components', 'i18n', '.svg', 'logout', 'http://']).service('baseUrlInjector', ['endpoint', '$q', 'WhiteList', baseUrlInjector]);

  function baseUrlInjector(endpoint, $q, WhiteList) {
    return {
      request: prependBaseUrl
    };

    function prependBaseUrl(config) {
      var canEscape = true;
      WhiteList.forEach(function (url) {
        canEscape = canEscape && config.url.indexOf(url) === -1;
      });

      if (canEscape) {
        config.url = endpoint.get() + config.url;
      }

      return config || $q.when(config);
    }
  }
})();