"use strict";

/**
 * Created by stefano on 20/02/17.
 */
(function () {
  'use strict';

  angular.module('polcity.services').service('IndiciValoreService', ['api', IndiciValoreService]);

  function IndiciValoreService(api) {
    var isData = R.has('data');
    var isResultDto = R.has('resultsDTO');

    var service = function () {};

    service.getByDomain = function (domain) {
      return api.perform({
        url: 'indici-valore',
        method: 'GET',
        params: {
          dominio: domain,
          l: 100
        }
      }).then(function (responseData) {
        if (isData(responseData) && isResultDto(responseData.data)) {
          return responseData.data.resultsDTO;
        } else {
          return [];
        }
      });
    };

    service.getDomainValues = function () {
      return api.perform({
        url: 'globale/lista/indici-valore-dominio',
        method: 'GET'
      }).then(function (response) {
        return response.data;
      });
    };

    service.list = function (filters) {
      return api.perform({
        url: 'indici-valore',
        method: 'GET'
      }).then(function (response) {
        return response.data.resultsDTO;
      }).then(function (data) {
        data.results.forEach(function (item) {
          filters.forEach(function (filter) {
            filter.apply(item, [item]);
          });
        });
        return data;
      });
    };

    service.update = function (item) {
      return api.perform({
        url: 'indice-valore/' + item.aggId,
        method: 'PUT',
        data: item
      });
    };

    service.create = function (item) {
      return api.perform({
        url: 'indice-valore',
        method: 'POST',
        data: item
      }).then(function (response) {
        return response.data;
      });
    };

    return service;
  }
})();