"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCancellaSoggettoReactQuery = exports.useModificaSoggettoReactQuery = exports.useCreaSoggettoReactQuery = exports.useSoggettoReactQuery = exports.useSoggettiTestoLiberoReactQuery = exports.soggettiKeys = void 0;

var _recoil = require("recoil");

var _api = require("../../api");

var _utils = require("./utils");

var _messages = require("../global/messages");

var _utils2 = require("../../utils");

var _soggettiMasterDetail = require("./soggettiMasterDetail");

var _reactQuery = require("react-query");

var _utils3 = require("../../react/reactQuery/utils");

const soggettiKeys = {
  all: () => [_soggettiMasterDetail.soggettiMasterDetailName],
  detail: aggId => [...soggettiKeys.all(), "detail", ...(0, _utils2.insertIf)(aggId, {
    aggId
  })],
  quickSearch: params => [...soggettiKeys.all(), "quickSearch", ...(0, _utils2.insertIf)(params, params)],
  freeSearch: params => [...soggettiKeys.all(), "freeSearch", ...(0, _utils2.insertIf)(params, params)]
};
exports.soggettiKeys = soggettiKeys;

const useSoggettiTestoLiberoReactQuery = ({
  query,
  minLength = 2
}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isLoading,
    isFetching
  } = (0, _reactQuery.useQuery)(soggettiKeys.freeSearch({
    query
  }), ({
    queryKey
  }) => (0, _api.ricercaAnagraficaTestoLibero)({
    query: queryKey[2].query
  }), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(query) && query.length >= minLength,
    onSuccess: (0, _utils3.handleSuccess)(messenger),
    onError: response => (0, _utils3.handleError)(messenger)(response),
    select: response => response.error ? response : (response === null || response === void 0 ? void 0 : response.resultsWrapper.values) || []
  });
  return {
    data: data ? data : [],
    isLoading: isLoading || isFetching
  };
};

exports.useSoggettiTestoLiberoReactQuery = useSoggettiTestoLiberoReactQuery;

const useSoggettoReactQuery = aggId => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data: item,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(soggettiKeys.detail(aggId), ({
    queryKey
  }) => (0, _api.dettaglioAnagrafica)({
    aggId: queryKey[2].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId),
    onSuccess: (0, _utils3.handleSuccess)(messenger),
    onError: response => (0, _utils3.handleError)(messenger)(response),
    select: data => (0, _utils.fromApiSoggetto)(data)
  });
  return {
    item: item,
    isFetching: isFetching || isLoading
  };
};

exports.useSoggettoReactQuery = useSoggettoReactQuery;

const useCreaSoggettoReactQuery = ({
  onSuccess,
  onFailure
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Soggetto salvato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(soggetto => (0, _api.creaAnagrafica)((0, _utils.toApiSoggetto)(soggetto)), {
    onSuccess: response => (0, _utils3.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils3.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(soggettiKeys.all())
  });
};

exports.useCreaSoggettoReactQuery = useCreaSoggettoReactQuery;

const useModificaSoggettoReactQuery = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Soggetto modificato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(soggetto => (0, _api.modificaAnagrafica)((0, _utils.toApiSoggetto)(soggetto)), {
    onSuccess: response => (0, _utils3.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils3.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(soggettiKeys.all())
  });
};

exports.useModificaSoggettoReactQuery = useModificaSoggettoReactQuery;

const useCancellaSoggettoReactQuery = ({
  onSuccess,
  onFailure
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Soggetto cancellato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.cancellaAnagrafica)({
    aggId
  }), {
    onSuccess: response => (0, _utils3.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils3.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(soggettiKeys.all())
  });
};

exports.useCancellaSoggettoReactQuery = useCancellaSoggettoReactQuery;