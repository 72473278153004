"use strict";

/**
 * Created by dan on 19/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.navigation').provider('navigationKnownRoutes', ['$qProvider', navigationKnownRoutes]);

  function navigationKnownRoutes($qProvider) {
    var routes = [],
        deferred = $qProvider.$get().defer();

    this.$get = function () {
      return {
        list: getRoutes,
        push: push
      };
    };

    this.list = getRoutes;
    this.push = push;

    function getRoutes() {
      return deferred.promise;
    }

    function push(route) {
      if (!R.isArrayLike(route)) {
        route = [route];
      }

      routes.push(R.reject(pushed, route));
      routes = R.flatten(routes);
      deferred.notify(routes);

      function pushed(route) {
        return R.find(R.propEq('label', route.label))(routes) !== undefined;
      }
    }
  }
})();