"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.agentiVisureListColumns = void 0;

var _DataGridColumnTypes = require("../../react/components/ApiRefGrid/DataGridColumnTypes");

const agentiVisureListColumns = [{
  field: 'nome',
  headerName: 'Nome',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'codice',
  headerName: 'Codice',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'nomeCoda',
  headerName: 'Nome coda',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'stazioneComandoCodiceUnivoco',
  headerName: 'Stazione comando',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    row
  }) => `${row.stazioneComandoCodiceUnivoco} ${row.stazioneComandoRiferimentoComuneNome}`,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'agenteUsername',
  headerName: 'Username',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.agentiVisureListColumns = agentiVisureListColumns;