"use strict";

(function () {
  'use strict';

  angular.module('osTableComponent').directive('rowBody', [directiveFn]);

  function directiveFn() {
    return {
      restrict: 'E',
      transclude: {
        'rowwidget': '?rowwidget'
      },
      template: '<div ng-transclude="rowwidget"></div>',
      link: postLink,
      //require: ['ngModel'],
      controller: ['$scope', '$element', '$compile', function (scope, element, $compile) {
        var me = this,
            config = scope.rowwidget,
            //nuovo scope non isolato con parent quello passato come argomento
        //directiveScope = scope.$new(false, scope.rowwidget.scope),
        directive,
            properties = []; //Directive name / type

        directive = scope.rowwidget.directive; //Directive Params

        for (var param in scope.rowwidget.bind) {
          properties.push(param + '="' + scope.rowwidget.bind[param] + '"');
        } //Add current record to injected scope


        directiveScope.record = scope.record;
        var el = $compile("<" + directive + " " + properties.join(" ") + "\"></" + directive + ">")(directiveScope);
        element.append(el);
      }],
      scope: {
        rowwidget: '=',
        record: '<'
      }
    };

    function postLink(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];
    }
  }
})();