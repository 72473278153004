"use strict";

(function () {
  'use strict';

  angular.module('polcity.dashboard').controller('WidgetTipiAccertamento', ['$scope', '$timeout', WidgetTipiAccertamento]);

  function WidgetTipiAccertamento($scope, $timeout) {
    var me = this;
    $scope.statisticheOptions = {
      responsive: true,
      maintainAspectRatio: false
    };
    loadStatisticheLotto();
    /*********************
     * Utils
     *********************/

    function loadStatisticheLotto() {
      fetchStatisticheLotto().then(function (res) {
        setTipiAccertamento(res);
      });
    }
    /**********************
     * Setters
     **********************/

    /**
     * Valorizza le variabili dello scope che servono alla direttiva
     * per renderizzare il grafico
     * @param statistiche
     */


    function setTipiAccertamento(res) {
      $scope.pieChartData = res.data;
      $scope.pieChartLabels = res.labels;
      $scope.pieChartOptions = {
        legend: {
          display: true,
          position: 'left'
        }
      };
    }
    /*************************
     * Fetchers & Apis
     *************************/

    /**
     * Recupera le statistiche dei lotti dal server
     */


    function fetchStatisticheLotto() {
      return new Promise(function (resolve, reject) {
        $timeout(function () {
          var response = {
            data: [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)],
            labels: ['P', 'D', 'V']
          };
          resolve(response);
        }, 100);
      });
    }
  }
})();