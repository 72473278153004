"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDeserializeFormErrors = void 0;

const useDeserializeFormErrors = obj => Object.keys(obj).reduce((acc, key) => {
  if (Boolean(obj[key]) && obj[key] instanceof Object) {
    return [...acc, ...useDeserializeFormErrors(obj[key])];
  }

  return [...acc, {
    key: key,
    value: obj[key]
  }];
}, []);

exports.useDeserializeFormErrors = useDeserializeFormErrors;