"use strict";

/**
 * Created by MN 12/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('IndiciValoriListController', ['Command', '$state', IndiciValoriListController]);

  function IndiciValoriListController(Command, $state) {
    var indiciValoriListCtrl = this;
    indiciValoriListCtrl.indiciValori = [];

    indiciValoriListCtrl.$onInit = function () {
      recuperaIndiciValori().then(setIndiciValori);
    };

    function recuperaIndiciValori() {
      return Command.execute('@recuperaIndiciValori');
    }

    function setIndiciValori(indiciValori) {
      indiciValoriListCtrl.indiciValori = indiciValori;
    }

    indiciValoriListCtrl.goToSubTable = function (subTable) {
      switch (subTable) {
        case 'NATURA_INCIDENTE':
          $state.go('navigation.naturaIncidenteTds');
          break;

        case 'TIPO_STRADA':
          $state.go('navigation.tipoStradaTds');
          break;

        case 'PART_STRADA':
          $state.go('navigation.particolaritaStradaTds');
          break;

        case 'PAVIMENTAZIONE_STRADA':
          $state.go('navigation.pavimentazioneStradaTds');
          break;

        case 'CONDIZIONE_LUCE':
          $state.go('navigation.condizioneLuceTds');
          break;

        case 'TIPO_VEICOLO_ISTAT':
          $state.go('navigation.tipoVeicoloIstatTds');
          break;

        case 'CARRO_ATTREZZI_RIMOZIONE':
          $state.go('navigation.carroAttrezziRimozioneTds');
          break;

        case 'DEPOSITERIE':
          $state.go('navigation.depositerieTds');
          break;

        default:
          $state.go('navigation.indiciValoriTds');
      }
    };
  }
})();