"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Verbale', ['restmod', function (restmod) {
    var AGGREGATE_CANONICAL_NAME = 'eu.opensoftware.entities.Verbale';
    return restmod.model('verbale').mix({
      spedizioni: {
        hasMany: 'Spedizione'
      }
    }, {
      $extend: {
        Scope: {
          $config: {
            name: 'verbali',
            baseEntityPath: 'verbale/',
            api: {
              detail: {
                url: 'dettaglio/verbale',
                method: 'POST'
              },
              destroy: {
                url: 'comando/cancella-verbale',
                method: 'POST'
              },
              checkUnivocity: {
                url: 'bolletta/univocita/bolletta-numero-tipo-anno',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'RicercaVeloce', 'RicercaTestoLibero', 'Followable', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Record: {
          $aggregateCanonicalName: AGGREGATE_CANONICAL_NAME,
          //Override della defaul $destroy
          $destroy: function (_params) {
            return this.$action(function () {
              var url = this.$url('destroy');

              if (url) {
                var request = this.$buildRequest('destroy', true);
                request.data = Object.assign({}, {
                  aggId: this.$pk
                });
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) this.$scope.$remove(this);
              }
            });
          }
        }
      }
    });
  }]);
})();