"use strict";

/**
 * Created by dan on 01/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity').config(['$mdThemingProvider', setupNgMaterialTheme]);

  function setupNgMaterialTheme($mdThemingProvider) {
    //disable theme generation
    // $mdThemingProvider.generateThemesOnDemand(true);
    var neonRedMap = $mdThemingProvider.extendPalette('blue', {
      '400': '#345772',
      '500': '#8CB4E1',
      // '#0f76c7',//'#2196f3',//'#205081',//'#25a3c1',//'#20435D',
      '600': '#345772',
      '700': '#345772',
      '800': '#345772',
      '900': '#345772',
      'A100': '#57748D',
      'A200': '#57748D',
      'A400': '#57748D',
      'A700': '#57748D'
    }); // Register the new color palette map with the name <code>neonRed</code>

    $mdThemingProvider.definePalette('neonRed', neonRedMap); // Use that theme for the primary intentions

    $mdThemingProvider.theme('default').primaryPalette('neonRed').accentPalette('orange'); //#FB8C00
    // this theme gets applied to the confirm deletion dialogs

    $mdThemingProvider.theme('warnTheme');
  }
})();