"use strict";

(function () {
  'use strict';

  angular.module('polcity.interceptors', []).config(['$httpProvider', injectInterceptors]);

  function injectInterceptors($httpProvider) {
    ['disableUI'].forEach(function (interceptorName) {
      $httpProvider.interceptors.push(interceptorName);
    });
  }
})();