"use strict";

/**
 * Created by MN 25/02/2020
 */
(function () {
  'use strict';

  angular.module('polcity.lotti').controller('calendarEventsListController', ['$mdDialog', 'events', calendarEventsListController]);

  function calendarEventsListController($mdDialog, events) {
    var me = this;
    me.events = events;

    me.$onInit = function () {};

    me.onCloseModal = function () {
      $mdDialog.cancel();
    };
  }
})();