"use strict";

/**
 * Created by MN 20/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('TipoVeicoloIstatFormController', ['$stateParams', 'Command', '$tdsApiService', '$tdsGlobalBehaviors', TipoVeicoloIstatFormController]);

  function TipoVeicoloIstatFormController($stateParams, Command, $tdsApiService, $tdsGlobalBehaviors) {
    var tipoVeicoloIstatFormCtrl = this,
        dettaglioCommand = '@ricercaTipiVeicoliIstat',
        modificaCommand = '@modificaTipoVeicoloIstat',
        salvaCommand = '@salvaTipoVeicoloIstat',
        redirectTo = 'navigation.tipoVeicoloIstatTds';
    /**
     * Discriminante per capire se sono in edit
     * @type {boolean}
     */

    tipoVeicoloIstatFormCtrl.editMode = false;
    /**
     * @Property
     * Configuro l'aggId a zero leggendolo dagli $stateParams nel caso sia in inserimento
     * oppure lo recupero dall'oggetto nel caso in cui sia in edit
     */

    tipoVeicoloIstatFormCtrl.aggId = $stateParams.aggId;
    /**
     * @Property
     * Configuro l'oggetto recuperandolo dagli $stateParams nel caso in cui sia in edit
     * oppure assegnando un oggetto vuoto nel caso in cui sia in inserimento
     */

    tipoVeicoloIstatFormCtrl.item = $stateParams.item;
    /**
     * Container per i domini
     * @type {Array}
     */

    tipoVeicoloIstatFormCtrl.dominio = [];
    /**
     * Handler onInit function
     * @type {onInit}
     */

    tipoVeicoloIstatFormCtrl.$onInit = onInit;
    /**
     * Operazioni eseguite in istanza del controller
     */

    function onInit() {
      // SE NEGLI STATE PARAMS HO L'AGGID POSSO ESSERE IN EDIT OPPURE AVER RICARICATO LA PAGINA
      if (tipoVeicoloIstatFormCtrl.aggId) {
        tipoVeicoloIstatFormCtrl.editMode = true; // SE RICARICO LA PAGINA DEVO RECUPERARMI L'OGGETTO

        if (!tipoVeicoloIstatFormCtrl.item) {
          var params = {
            aggId: tipoVeicoloIstatFormCtrl.aggId
          };
          $tdsApiService.executeCommand(dettaglioCommand, params).then(function (res) {
            tipoVeicoloIstatFormCtrl.item = res.resultsDTO.results[0];
          }).catch(function () {
            handleItemLoadError();
          });
        }
      } else {
        tipoVeicoloIstatFormCtrl.editMode = false;
        tipoVeicoloIstatFormCtrl.item = {
          dominio: 'TIPO_VEICOLO_ISTAT'
        };
      }

      recuperaDominio().then(setDominio);
    }
    /**
     * Chiamata rest per recuperare gli indici valori che corrispondono al dominio
     * @returns {*}
     */


    function recuperaDominio() {
      return Command.execute('@recuperaIndiciValori');
    }
    /**
     * Setter dell'oggetto condiviso con la vista con la response della chiamata
     * @param dominio
     */


    function setDominio(dominio) {
      tipoVeicoloIstatFormCtrl.dominio = dominio;
    }
    /**
     * @property
     * Handler da eseguire al click sul pulsante del salvataggio
     * @param item
     */


    tipoVeicoloIstatFormCtrl.onSaveTipoVeicoloIstatButtonClick = function (item) {
      var command = tipoVeicoloIstatFormCtrl.editMode ? modificaCommand : salvaCommand;
      $tdsApiService.salvaElementoAndRedirect(command, item, tipoVeicoloIstatFormCtrl.editMode, redirectTo);
    };
    /****************
     * Utils
     ***************/


    function handleItemLoadError() {
      //Notifica errore
      $tdsGlobalBehaviors.notifica('error', 'tds.elementNotFount__notification'); //Go back

      $tdsGlobalBehaviors.redirectTo(redirectTo);
    }
  }
})();