"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTutorialsByHash = void 0;

var _react = _interopRequireDefault(require("react"));

var _utils = require("../../../utils");

var _tutorialLists = require("../../../state/tutorials/tutorialLists");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const useTutorialsByHash = () => {
  var _hash$match;

  const hash = (0, _utils.useHash)();
  const section = (_hash$match = hash.match(_utils.locationHashRegularExp)) !== null && _hash$match !== void 0 ? _hash$match : [];
  const path = Boolean(section[2]) ? section[2] : "";
  return _tutorialLists.tutorialsMap[path] || [];
};

exports.useTutorialsByHash = useTutorialsByHash;