"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCancellaAllegatoDigitaleNonAssociato = exports.useCaricaAllegatoDigitaleNonAssociato = exports.useAssociaAllegatoDigitaleNonAssociatoAVerbale = exports.useScaricaImmagineAllegatoDigitaleNonAssociato = exports.useAllegatoDigitaleNonAssociato = exports.allegatiDigitaliNonAssociatiKeys = void 0;

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _api = require("../../api");

var _utils = require("../../react/reactQuery/utils");

var _utils2 = require("../../utils");

var _allegatiDigitaliNonAssociatiMasterDetail = require("./allegatiDigitaliNonAssociatiMasterDetail");

var _utils3 = require("./utils");

const allegatiDigitaliNonAssociatiKeys = {
  all: () => [_allegatiDigitaliNonAssociatiMasterDetail.allegatiDigitaliNonAssociatiMasterDetailName],
  detail: aggId => [...allegatiDigitaliNonAssociatiKeys.all(), "detail", ...(0, _utils2.insertIf)(aggId, {
    aggId
  })],
  quickSearch: params => [...allegatiDigitaliNonAssociatiKeys.all(), "quickSearch", ...(0, _utils2.insertIf)(params, params)],
  aggregato: params => [...allegatiDigitaliNonAssociatiKeys.all(), "aggregato", ...(0, _utils2.insertIf)(params, params)]
};
exports.allegatiDigitaliNonAssociatiKeys = allegatiDigitaliNonAssociatiKeys;

const useAllegatoDigitaleNonAssociato = ({
  aggId,
  onSuccess,
  onFailure
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(allegatiDigitaliNonAssociatiKeys.detail(aggId), ({
    queryKey
  }) => (0, _api.dettaglioAllegatoDigitaleNonAssociato)({
    aggId: queryKey[2].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    onSuccess: response => (0, _utils.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response)
  });
  return {
    detail: data ? data : {},
    isLoading: isFetching || isLoading
  };
};

exports.useAllegatoDigitaleNonAssociato = useAllegatoDigitaleNonAssociato;

const useScaricaImmagineAllegatoDigitaleNonAssociato = ({
  aggId,
  onSuccess,
  onFailure
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data: image,
    isFetching: isFetching,
    isLoading: isLoading
  } = (0, _reactQuery.useQuery)([`immagineAllegatoDigitaleNonAssociato`, {
    aggId
  }], ({
    queryKey
  }) => (0, _api.scaricaAllegatoDigitale)({
    aggId: queryKey[1].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId),
    onSuccess: response => (0, _utils.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: response => {
      if (!response || !response.data || response.error) return response;
      const isPdf = response.headers["content-type"].indexOf("pdf") != -1;
      return {
        src: (0, _utils3.ensurePdf)(response.data, isPdf)
      };
    }
  });
  return {
    image,
    isLoading: isFetching || isLoading
  };
};

exports.useScaricaImmagineAllegatoDigitaleNonAssociato = useScaricaImmagineAllegatoDigitaleNonAssociato;

const useAssociaAllegatoDigitaleNonAssociatoAVerbale = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Allegato associato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(({
    allegatoNonAssociatoAggId,
    verbaleAggId
  }) => (0, _api.associaAllegatoDigitale)({
    allegatoNonAssociatoAggId,
    verbaleAggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(allegatiDigitaliNonAssociatiKeys.all())
  });
};

exports.useAssociaAllegatoDigitaleNonAssociatoAVerbale = useAssociaAllegatoDigitaleNonAssociatoAVerbale;

const useCaricaAllegatoDigitaleNonAssociato = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Allegato caricato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(({
    contenutoAllegatoId,
    file
  }) => (0, _api.caricaAllegatoDigitaleNonAssociato)({
    contenutoAllegatoId,
    file
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(allegatiDigitaliNonAssociatiKeys.all())
  });
};

exports.useCaricaAllegatoDigitaleNonAssociato = useCaricaAllegatoDigitaleNonAssociato;

const useCancellaAllegatoDigitaleNonAssociato = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Allegato cancellato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.cancellaAllegatoDigitale)({
    aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(allegatiDigitaliNonAssociatiKeys.quickSearch())
  });
};

exports.useCancellaAllegatoDigitaleNonAssociato = useCancellaAllegatoDigitaleNonAssociato;