"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useInsolutoCompletaCfPi = exports.useInsolutoCompletaSoggetto = exports.useInsoluto = exports.insolutiKeys = void 0;

var _utils = require("../../utils");

var _insolutiMasterDetail = require("./insolutiMasterDetail");

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _utils2 = require("../../react/reactQuery/utils");

var _api = require("../../api");

var _utils3 = require("./utils");

const insolutiKeys = {
  all: () => [_insolutiMasterDetail.insolutiMasterDetailName],
  detail: aggId => [...insolutiKeys.all(), "detail", ...(0, _utils.insertIf)(aggId, {
    aggId
  })],
  quickSearch: params => [...insolutiKeys.all(), "quickSearch", ...(0, _utils.insertIf)(params, params)]
};
exports.insolutiKeys = insolutiKeys;

const useInsoluto = aggId => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(insolutiKeys.detail(aggId), ({
    queryKey
  }) => (0, _api.dettaglioInsoluto)({
    aggId: queryKey[2].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    suspense: true,
    enabled: Boolean(aggId),
    onSuccess: (0, _utils2.handleSuccess)(messenger),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    select: data => (0, _utils3.fromApiInsoluto)(data)
  });
  return {
    insoluto: data,
    isLoading: isFetching || isLoading
  };
};

exports.useInsoluto = useInsoluto;

const useInsolutoCompletaSoggetto = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Soggetto inserito",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(values => (0, _api.insolutoCompletaSoggetto)((0, _utils3.toApiInsolutoCompletaSoggetto)(values)), {
    onSuccess: response => (0, _utils2.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(insolutiKeys.all())
  });
};

exports.useInsolutoCompletaSoggetto = useInsolutoCompletaSoggetto;

const useInsolutoCompletaCfPi = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Codice fiscale / partita iva inserito",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(values => (0, _api.insolutoCompletaCfPi)((0, _utils3.toApiInsolutoCfPiMancante)(values)), {
    onSuccess: response => (0, _utils2.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(insolutiKeys.all())
  });
};

exports.useInsolutoCompletaCfPi = useInsolutoCompletaCfPi;