"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('OsFetch', ['restmod', function (restmod) {
    return restmod.mixin(function () {
      /**
       * Override della default Record.$fetch:
       * Usiamo un metodo custom $buildRequest per costruire la richiesta in POST
       * per il dettaglio
       */
      this.define("Record.$fetch", function (_params) {
        return this.$action(function () {
          var request = this.$buildRequest('detail'); //Sto chiamando la $fetch su un'istanza del Record --> ho già l'agg id

          request.data = angular.extend(_params || {}, {
            aggId: this.$pk
          });
          this.$dispatch('before-fetch', [request]);
          this.$send(request, function (_response) {
            this.$unwrap(_response.data);
            this.$dispatch('after-fetch', [_response]);
          }, function (_response) {
            this.$dispatch('after-fetch-error', [_response]);
          });
        });
      })
      /**
       * @memberof CollectionApi#
       *
       * @description Begin a server request to populate collection. This method does not
       * clear the collection contents by default, use `$refresh` to reset and fetch.
       *
       * This method is for use in collections only.
       *
       * @param {object|function} _params Additional request parameters, not stored in collection,
       * if a function is given, then it will be called with the request object to allow requet customization.
       * @return {CollectionApi} self
       */
      //TODO: automatizzare i parametri di ricerca salvandoli all'interno del model e tenendoli sincronizzati
      .define("Collection.$fetch", function (_params, _req) {
        return this.$action(function () {
          //var request = this.$buildRequest(api);
          var request = _req || {
            method: 'GET',
            url: this.$url(),
            params: this.$params
          };

          if (_params) {
            request.params = Object.assign({}, request.params, _params);
          } // TODO: check that collection is bound.


          this.$dispatch('before-fetch-many', [request]).$send(request, function (_response) {
            this.$unwrap(_response.data).$dispatch('after-fetch-many', [_response]);
          }, function (_response) {
            this.$dispatch('after-fetch-many-error', [_response]);
          });
        });
      });
    });
  }]);
})();