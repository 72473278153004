"use strict";

/**
 * Created by MN 12/12/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').controller('StatoPatrimonialeController', [StatoPatrimonialeController]);

  function StatoPatrimonialeController() {
    var me = this;
    me.attivita = {};
    me.passivita = {};
    me.firstLevelNestedSectionIsOpen = false;
    me.firstLevelExpandedRowKey = null;
    me.secondLevelNestedSectionIsOpen = false;
    me.secondLevelExpandedRowKey = null;
    me.passivitaFirstLevelNestedSectionIsOpen = false;
    me.passivitaFirstLevelExpandedRowKey = null;
    me.mouseOverMainLevel = false;
    me.mouseOverMainLevelKey = null;
    me.mouseOverFirstLevelNested = false;
    me.mouseOverFirstLevelNestedKey = null;
    me.mouseOverSecondLevelNested = false;
    me.mouseOverSecondLevelNestedKey = null;
    me.mouseOverMainLevelPassivo = false;
    me.mouseOverMainLevelPassivoKey = null;
    me.mouseOverFirstLevelNestedPassivo = false;
    me.mouseOverFirstLevelNestedPassivoKey = null;
    me.columnIndex = null;
    me.contoRowIndexToHighlight = null;
    me.columnIndexPassivita = null;
    me.contoPassivitaRowIndexToHighlight = null;

    me.$onInit = function () {};

    me.$onChanges = function () {
      me.attivita = me.data.attivita;
      me.passivita = me.data.passivita;
    };
    /**
     * Handlers
     */


    me.onMouseOverUpperLevel = function (_level, _levelKey, _key, _index) {
      me[_level] = true;
      me[_levelKey] = _key;
      me.columnIndex = _index;
    };

    me.onMouseLeaveUpperLevel = function (_level, _levelKey) {
      me[_level] = false;
      me[_levelKey] = null;
      me.columnIndex = null;
    };

    me.toggleFirstNestedSection = function (_key) {
      if (!me.firstLevelExpandedRowKey) {
        openNestedSection('firstLevelNestedSectionIsOpen', 'firstLevelExpandedRowKey', _key);
      } else if (me.firstLevelExpandedRowKey === _key) {
        closeNestedSection('firstLevelNestedSectionIsOpen', 'firstLevelExpandedRowKey');
        closeNestedSection('secondLevelNestedSectionIsOpen', 'secondLevelExpandedRowKey');
      } else if (me.firstLevelExpandedRowKey !== _key && me.firstLevelNestedSectionIsOpen) {
        closeNestedSection('secondLevelNestedSectionIsOpen', 'secondLevelExpandedRowKey');
        me.firstLevelExpandedRowKey = _key;
      }
    };

    me.toggleSecondNestedSection = function (_key) {
      if (!me.secondLevelExpandedRowKey) {
        openNestedSection('secondLevelNestedSectionIsOpen', 'secondLevelExpandedRowKey', _key);
      } else if (me.secondLevelExpandedRowKey === _key) {
        closeNestedSection('secondLevelNestedSectionIsOpen', 'secondLevelExpandedRowKey');
      } else if (me.secondLevelExpandedRowKey !== _key && me.secondLevelNestedSectionIsOpen) {
        me.secondLevelExpandedRowKey = _key;
      }
    };

    me.togglePassivitaFirstNestedSection = function (_key) {
      if (!me.passivitaFirstLevelExpandedRowKey) {
        openNestedSection('passivitaFirstLevelNestedSectionIsOpen', 'passivitaFirstLevelExpandedRowKey', _key);
      } else if (me.passivitaFirstLevelExpandedRowKey === _key) {
        closeNestedSection('passivitaFirstLevelNestedSectionIsOpen', 'passivitaFirstLevelExpandedRowKey');
      } else if (me.passivitaFirstLevelExpandedRowKey !== _key && me.passivitaFirstLevelNestedSectionIsOpen) {
        me.passivitaFirstLevelExpandedRowKey = _key;
      }
    };

    me.onMouseOverContiRow = function (_index, _rowIndexToHighlight) {
      me[_rowIndexToHighlight] = _index;
    };

    me.onMouseLeaveContiRow = function (_rowIndexToHighlight) {
      me[_rowIndexToHighlight] = null;
    };
    /**
     * Methods implementation
     */


    function openNestedSection(_nestedSection, _expandedRow, _key) {
      me[_nestedSection] = true;
      me[_expandedRow] = _key;
    }

    function closeNestedSection(_nestedSection, _expandedRow) {
      me[_nestedSection] = false;
      me[_expandedRow] = null;
    }
  }
})();