"use strict";

(function () {
  'use strict';
  /**
   * Dona la possiblità ad un Model di essere seguito
   */

  angular.module('polcity.models').factory('Filterable', ['restmod', function (restmod) {
    var $activeFilters = {
      filters: [],
      elasticFilters: [],
      addFilter: function (filterName, filter) {
        if (!R.contains(filterName, this.filters)) {
          this.filters.push(filterName);
          this.elasticFilters.push(filter);
        }

        return this;
      },
      removeFilter: function (filterKey) {
        // this.filters = R.remove(R.indexOf(filterKey), 1, this.filters);
        var indexToRemove = R.indexOf(filterKey, this.filters);
        this.filters.splice(indexToRemove, 1);
        this.elasticFilters.splice(indexToRemove, 1);
        return this;
      },
      resetFilters: function () {
        this.filters = [];
        this.elasticFilters = [];
        return this;
      },
      getFilters: function () {
        return this.filters;
      }
    };
    return restmod.mixin({
      $extend: {
        Scope: {
          /**
           * @memberof ScopeApi#
           *
           * @description Builds a new collection bound to this scope.
           *
           * If scope is another collection then it will inherit its parameters
           *
           * Collections are bound to an api resource.
           *
           * @param  {object} _params  Additional query string parameters
           * @param  {object} _scope  Scope override (optional)
           * @return {CollectionApi} Model Collection
           *
           * @override
           */
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);
            collection.$activeFilters = angular.copy($activeFilters);
            collection.$isFilterable = true;
            return collection;
          }
        },
        Collection: {
          $initFilter: function (filters, elasticFilters) {
            this.$activeFilters.filters = filters;
            this.$activeFilters.elasticFilters = elasticFilters;
          },
          $addFilter: function (filter) {
            var search = R.path(['$metadata', 'search'], this);
            this.$activeFilters.addFilter(filter.name, filter);
            search[filter.name] = filter.value; //Usiamo la stessa collection per mantenere il riferimento

            this.$clear(); //Pulizia dei vecchi dati

            if (this.$isRicercaVeloce) {
              return this.$ricercaVeloce(search);
            } else {
              return this.$fetch(search);
            }
          },
          $addAggIdFilter: function (_filter) {
            this.$activeFilters.resetFilters();
            this.$clear(); //Pulizia dei vecchi dati

            if (this.$isRicercaVeloce) {
              return this.$ricercaVeloce(_filter);
            } else {
              return this.$fetch(_filter);
            }
          },
          $removeAggIdFilter: function () {
            var search = R.path(['$metadata', 'search'], this);

            if (search && search.aggId) {
              search.aggId = null;
            }

            this.$clear(); //Pulizia dei vecchi dati

            if (this.$isRicercaVeloce) {
              return this.$ricercaVeloce(search);
            } else {
              return this.$fetch(search);
            }
          },
          $removeFilter: function (filterKey) {
            var search = R.path(['$metadata', 'search'], this);
            this.$activeFilters.removeFilter(filterKey);
            search[filterKey] = null; //Usiamo la stessa collection per mantenere il riferimento

            this.$clear(); //Pulizia dei vecchi dati

            if (this.$isRicercaVeloce) {
              return this.$ricercaVeloce(search);
            } else {
              return this.$fetch(search);
            }
          },
          $resetFilters: function () {
            var search = R.path(['$metadata', 'search'], this),
                activeFilters = this.$activeFilters.getFilters();

            for (var i = 0; i < activeFilters.length; i++) {
              search[activeFilters[i]] = null;
            }

            this.$activeFilters.resetFilters(); //Usiamo la stessa collection per mantenere il riferimento

            this.$clear(); //Pulizia dei vecchi dati

            if (this.$isRicercaVeloce) {
              return this.$ricercaVeloce(search);
            } else {
              return this.$fetch(search);
            }
          },
          $getActiveFilters: function () {
            var search = R.path(['$metadata', 'search'], this),
                activeFiltersName = this.$activeFilters.getFilters(),
                activeFilters = [];

            for (var i = 0; i < activeFiltersName.length; i++) {
              activeFilters.push({
                name: activeFiltersName[i],
                value: search[activeFiltersName[i]],
                label: this.$activeFilters.elasticFilters[i].label
              });
            }

            return activeFilters;
          }
        }
      }
    });
  }]);
})();