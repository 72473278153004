"use strict";

/**
 * Created by dan on 07/12/16.
 */
(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name polcity.navigation
   *
   * @requires pascalprecht.translate
   */

  angular.module('polcity.navigation', ['pascalprecht.translate', 'polcity.api']);
  angular.module('polcity.navigation').constant('LOGOUT_URL', '/visual-polcity/logout').constant('REDIRECT_AFTER_LOGOUT', '/visual-polcity/login?logout');
})();