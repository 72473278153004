"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lottoFormValidationSchema = void 0;

var Yup = _interopRequireWildcard(require("yup"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const lottoFormValidationSchema = Yup.object().shape({
  tipoLotto: Yup.string().required('Questo campo è obbligatorio'),
  destinazione: Yup.string().required('Questo campo è obbligatorio'),
  lottoVuoto: Yup.string().required('Questo campo è obbligatorio')
}).test("rangeDataVerbale", 'Questo campo è obbligatorio', (value, context) => {
  if (value.tipoLotto === 'lettera_preruolo' && value.preRuoloFilters && (!value.preRuoloFilters.rangeDataVerbale.startDate || !value.preRuoloFilters.rangeDataVerbale.endDate)) {
    return context.createError({
      path: 'preRuoloFilters.rangeDataVerbale',
      message: 'Questo campo è obbligatorio'
    });
  }

  return false;
}).test("tipoLotto", 'Questo campo è obbligatorio', (value, context) => {
  if (value.tipoLotto === 'lettera_preruolo' && value.preRuoloFilters && !value.preRuoloFilters.tipoAccertamento) {
    return context.createError({
      path: 'preRuoloFilters.tipoAccertamento',
      message: 'Questo campo è obbligatorio'
    });
  }

  return false;
}).test("statiVerbale", 'Questo campo è obbligatorio', (value, context) => {
  var _value$preRuoloFilter;

  if (value.tipoLotto === 'lettera_preruolo' && value.preRuoloFilters && !((_value$preRuoloFilter = value.preRuoloFilters.statiVerbale) !== null && _value$preRuoloFilter !== void 0 && _value$preRuoloFilter.length)) {
    return context.createError({
      path: 'preRuoloFilters.statiVerbale',
      message: 'Questo campo è obbligatorio'
    });
  }

  return false;
});
exports.lottoFormValidationSchema = lottoFormValidationSchema;