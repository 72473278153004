"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tdsApiMap = void 0;

var _api = require("../../api");

var _tdsNameCostant = require("../../tds/tabelle/tdsNameCostant");

const tdsApiMap = {
  [`${_tdsNameCostant.nazioniTdsName}`]: {
    searchApi: _api.ricercaStati,
    detailApi: _api.dettaglioStato,
    addApi: _api.creaStato,
    editApi: _api.modificaStato,
    deleteApi: _api.cancellaStato
  },
  [`${_tdsNameCostant.carroattrezziTdsName}`]: {
    searchApi: _api.ricercaCarroAttrezzi,
    detailApi: _api.dettaglioCarroAttrezzi,
    addApi: _api.creaCarroattrezzi,
    editApi: _api.modificaCarroattrezzi,
    deleteApi: _api.cancellaCarroattrezzi
  },
  [`${_tdsNameCostant.riferimentiComuniTdsName}`]: {
    searchApi: _api.ricercaRiferimentiComune,
    detailApi: _api.dettaglioRiferimentoComune,
    addApi: _api.creaRiferimentoComune,
    editApi: _api.modificaRiferimentoComune,
    deleteApi: _api.cancellaRiferimentoComune
  },
  [`${_tdsNameCostant.contatoriProtocolloTdsName}`]: {
    searchApi: _api.ricercaContatoriProtocollo,
    detailApi: _api.dettaglioContatoreProtocollo,
    addApi: _api.creaContatoreProtocollo,
    editApi: _api.modificaContatoreProtocollo,
    deleteApi: _api.cancellaContatoreProtocollo
  },
  [`${_tdsNameCostant.contenutiAllegatiTdsName}`]: {
    searchApi: _api.ricercaContenutoAllegato,
    detailApi: _api.dettaglioContenutoAllegato,
    addApi: _api.creaContenutoAllegato,
    editApi: _api.modificaContenutoAllegato,
    deleteApi: _api.cancellaContenutoAllegato
  },
  [`${_tdsNameCostant.depositiFermiSequestriTdsName}`]: {
    searchApi: _api.ricercaDepositiFermiSequestri,
    detailApi: _api.dettaglioDepositoFermoSequestro,
    addApi: _api.creaDepositoFermoSequestro,
    editApi: _api.modificaDepositoFermoSequestro,
    deleteApi: _api.cancellaDepositoFermoSequestro
  },
  [`${_tdsNameCostant.entiTdsName}`]: {
    searchApi: _api.ricercaEnti,
    detailApi: _api.dettaglioEnte,
    addApi: _api.creaEnte,
    editApi: _api.modificaEnte,
    deleteApi: _api.cancellaEnte
  },
  [`${_tdsNameCostant.entiTerritorialiTdsName}`]: {
    searchApi: _api.ricercaEntiTerritoriali,
    detailApi: _api.dettaglioEnteTerritoriale,
    addApi: _api.creaEnteTerritoriale,
    editApi: _api.modificaEnteTerritoriale,
    deleteApi: _api.cancellaEnteTerritoriale
  },
  [`${_tdsNameCostant.funzioniStampaTdsName}`]: {
    searchApi: _api.ricercaFunzioniStampa,
    detailApi: _api.dettaglioFunzioneStampa,
    addApi: _api.creaFunzioneStampa,
    editApi: _api.modificaFunzioneStampa,
    deleteApi: _api.cancellaFunzioneStampa
  },
  [`${_tdsNameCostant.gestioneDelTerritorioTdsName}`]: {
    searchApi: _api.ricercaComuni,
    detailApi: _api.dettaglioComune,
    addApi: _api.creaComune,
    editApi: _api.modificaComune,
    deleteApi: _api.cancellaComune
  },
  [`${_tdsNameCostant.frazioniTdsName}`]: {
    searchApi: _api.ricercaFrazioni,
    detailApi: _api.dettaglioFrazione,
    addApi: _api.creaFrazione,
    editApi: _api.modificaFrazione,
    deleteApi: _api.cancellaFrazione
  },
  [`${_tdsNameCostant.gestioneStradeTdsName}`]: {
    searchApi: _api.ricercaStrade,
    detailApi: _api.dettaglioStrada,
    addApi: _api.creaStrada,
    editApi: _api.modificaStrada,
    deleteApi: _api.cancellaStrada
  },
  [`${_tdsNameCostant.gestioneContiCorrentiTdsName}`]: {
    searchApi: _api.ricercaContiCorrenti,
    detailApi: _api.dettaglioContoCorrente,
    addApi: _api.creaContoCorrente,
    editApi: _api.modificaContoCorrente,
    deleteApi: _api.cancellaContoCorrente
  },
  [`${_tdsNameCostant.lingueTdsName}`]: {
    searchApi: _api.ricercaLingue,
    detailApi: _api.dettaglioLingua,
    addApi: _api.creaLingua,
    editApi: _api.modificaLingua,
    deleteApi: _api.cancellaLingua
  },
  [`${_tdsNameCostant.localizzazioniTdsName}`]: {
    searchApi: _api.ricercaLocalizzazioni,
    detailApi: _api.dettaglioLocalizzazione,
    addApi: _api.creaLocalizzazione,
    editApi: _api.modificaLocalizzazione,
    deleteApi: _api.cancellaLocalizzazione
  },
  [`${_tdsNameCostant.marcheModelliVeicoliTdsName}`]: {
    searchApi: _api.ricercaMarcheModelliVeicoli,
    detailApi: _api.dettaglioMarcaModelloVeicolo,
    addApi: _api.creaMarcaModelloVeicolo,
    editApi: _api.modificaMarcaModelloVeicolo,
    deleteApi: _api.cancellaMarcaModelloVeicolo
  },
  [`${_tdsNameCostant.tipologieRimozioniTdsName}`]: {
    searchApi: _api.ricercaTipologieRimozioni,
    detailApi: _api.dettaglioTipologiaRimozione,
    addApi: _api.creaTipologiaRimozione,
    editApi: _api.modificaTipologiaRimozione,
    deleteApi: _api.cancellaTipologiaRimozione
  },
  [`${_tdsNameCostant.attivitaProduttiveTdsName}`]: {
    searchApi: _api.ricercaAttivitaProduttive,
    detailApi: _api.dettaglioAttivitaProduttiva,
    addApi: _api.creaAttivitaProduttiva,
    editApi: _api.modificaAttivitaProduttiva,
    deleteApi: _api.cancellaAttivitaProduttiva
  },
  [`${_tdsNameCostant.direzioneMarciaVeicoliTdsName}`]: {
    searchApi: _api.ricercaDirezioniMarciaVeicoli,
    detailApi: _api.dettaglioDirezioneMarciaVeicolo,
    addApi: _api.creaDirezioneMarciaVeicolo,
    editApi: _api.modificaDirezioneMarciaVeicolo,
    deleteApi: _api.cancellaDirezioneMarciaVeicolo
  },
  [`${_tdsNameCostant.motivazioniArchiviazioniAccertamentiTdsName}`]: {
    searchApi: _api.ricercaMotivazioniArchiviazioniAccertamenti,
    detailApi: _api.dettaglioMotivazioneArchiviazioneAccertamento,
    addApi: _api.creaMotivazioneArchiviazioneAccertamento,
    editApi: _api.modificaMotivazioneArchiviazioneAccertamento,
    deleteApi: _api.cancellaMotivazioneArchiviazioneAccertamento
  },
  [`${_tdsNameCostant.motivazioniMancataApplicazioneTdsName}`]: {
    searchApi: _api.ricercaMotivazioniMancataApplicazione,
    detailApi: _api.dettaglioMotivazioneMancataApplicazione,
    addApi: _api.creaMotivazioneMancataApplicazione,
    editApi: _api.modificaMotivazioneMancataApplicazione,
    deleteApi: _api.cancellaMotivazioneMancataApplicazione
  },
  [`${_tdsNameCostant.motivazioniMancataContestazioneTdsName}`]: {
    searchApi: _api.ricercaMotivazioniMancataContestazione,
    detailApi: _api.dettaglioMotivazioneMancataContestazione,
    addApi: _api.creaMotivazioneMancataContestazione,
    editApi: _api.modificaMotivazioneMancataContestazione,
    deleteApi: _api.cancellaMotivazioneMancataContestazione
  },
  [`${_tdsNameCostant.relazioniNomeSessoTdsName}`]: {
    searchApi: _api.ricercaRelazioniNomeSesso,
    detailApi: _api.dettaglioRelazioneNomeSesso,
    addApi: _api.creaRelazioneNomeSesso,
    editApi: _api.modificaRelazioneNomeSesso,
    deleteApi: _api.cancellaRelazioneNomeSesso
  },
  [`${_tdsNameCostant.rilevatoriInfrazioniTdsName}`]: {
    searchApi: _api.ricercaRilevatoriInfrazioni,
    detailApi: _api.dettaglioRilevatoreInfrazione,
    addApi: _api.creaRilevatoreInfrazioni,
    editApi: _api.modificaRilevatoreInfrazioni,
    deleteApi: _api.cancellaRilevatoreInfrazioni
  },
  [`${_tdsNameCostant.sanzioniAccessorieTdsName}`]: {
    searchApi: _api.ricercaSanzioniAccessorie,
    detailApi: _api.dettaglioSanzioneAccessoria,
    addApi: _api.creaSanzioneAccessoria,
    editApi: _api.modificaSanzioneAccessoria,
    deleteApi: _api.cancellaSanzioneAccessoria
  },
  [`${_tdsNameCostant.tipiAccertamentoTdsName}`]: {
    searchApi: _api.ricercaTipiAccertamento,
    detailApi: _api.dettaglioTipoAccertamento,
    addApi: _api.creaTipoAccertamento,
    editApi: _api.modificaTipoAccertamento,
    deleteApi: _api.cancellaTipoAccertamento
  },
  [`${_tdsNameCostant.tipiBollettariTdsName}`]: {
    searchApi: _api.ricercaTipiBollettari,
    detailApi: _api.dettaglioTipoBollettario,
    addApi: _api.creaTipoBollettario,
    editApi: _api.modificaTipoBollettario,
    deleteApi: _api.cancellaTipoBollettario
  },
  [`${_tdsNameCostant.tipiProprietaTdsName}`]: {
    searchApi: _api.ricercaTipiProprieta,
    detailApi: _api.dettaglioTipoProprieta,
    addApi: _api.creaTipoProprieta,
    editApi: _api.modificaTipoProprieta,
    deleteApi: _api.cancellaTipoProprieta
  },
  [`${_tdsNameCostant.tipiVeicoloTdsName}`]: {
    searchApi: _api.ricercaTipiVeicolo,
    detailApi: _api.dettaglioTipoVeicolo,
    addApi: _api.creaTipoVeicolo,
    editApi: _api.modificaTipoVeicolo,
    deleteApi: _api.cancellaTipoVeicolo
  },
  [`${_tdsNameCostant.leggiTdsName}`]: {
    searchApi: _api.ricercaLeggi,
    detailApi: _api.dettaglioLegge,
    addApi: _api.creaLegge,
    editApi: _api.modificaLegge,
    deleteApi: _api.cancellaLegge
  },
  [`${_tdsNameCostant.agentiVisureTdsName}`]: {
    searchApi: _api.ricercaAgentiVisure,
    detailApi: _api.dettaglioAgenteVisura,
    addApi: _api.creaAgenteVisura,
    editApi: _api.modificaAgenteVisura,
    deleteApi: _api.cancellaAgenteVisura
  }
};
exports.tdsApiMap = tdsApiMap;