"use strict";

/**
 *
 * @Description Servizio che permette di controllare, dato un oggetto, la presenza delle proprietà e stampare un pop up personalizzato nel caso in cui il campo non sia presente
 * si possono passare tra i campi da cercare anche oggetti innestati
 *
 * @param obj -> dev'essere un oggetto
 * @param requiredFields -> array di oggetti chiave valore
 *
 * Esempio di utilizzo:
 *
 *  var ricorso = {tipoRicorso: 'fakeTipoRicorso', enteId: '1010101010', comuneResidenzaId: {riferimentoComuneId: 1597894123196}, ...}
 *  var requiredFields = [
 *          {prop: 'tipoRicorso', label: 'Tipo Ricorso'},
 *          {prop: 'enteId', label: 'Ente'},
 *          {prop: 'comuneResidenzaId.riferimentoComuneId', label: 'Ricorrente'},
 *          {prop: 'dataPresentazione', label: 'Data presentazione'},
 *          {prop: 'ricorsoPresentatoA', label: 'Presentato a'},
 *          {prop: 'modalitaPresentazioneRicorso', label: 'Modalita presentazione'},
 *          {prop: 'responsabileId', label: 'Responsabile'}
 *  ];
 *
 *  CheckFieldsPresence.executeCheck(ricorso, requiredFields);
 *
 *  return boolean
 */
(function () {
  'use strict';

  angular.module('polcity.services').service('CheckFieldsPresence', ['OSNotification', CheckFieldsPresence]);

  function CheckFieldsPresence(OSNotification) {
    return {
      executeCheck: executeCheck
    };

    function executeCheck(obj, requiredFields) {
      var result = true;

      for (var i = 0; i < requiredFields.length; i++) {
        if (requiredFields[i].prop.indexOf('.') > -1) {
          var path = requiredFields[i].prop.split('.');

          if (!R.path(path, obj)) {
            result = false;
            notifyUser(requiredFields[i].label);
            break;
          }
        } else if (!obj[requiredFields[i].prop]) {
          result = false;
          notifyUser(requiredFields[i].label);
          break;
        }
      }

      return result;
    }

    function notifyUser(field) {
      OSNotification.info({
        translationIndex: 'missingDataFor__toastService',
        translationParam: {
          query: field
        }
      });
    }
  }
})();