"use strict";

var _ContenutoAllegatoEditRoot = _interopRequireDefault(require("./edit/ContenutoAllegatoEditRoot"));

var _ContenutiAllegatiList = _interopRequireDefault(require("./ContenutiAllegatiList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('contenutiAllegatiTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsContenutiAllegati',
    url: 'tds/contenutiAllegati',
    role: 'ROLE_CONTENUTO_ALLEGATO_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ContenutiAllegatiList.default
      }
    },
    resolve: {
      $label: () => "Contenuti degli allegati"
    }
  }, {
    name: 'tdsContenutiAllegatiEdit',
    url: 'tds/contenutiAllegati/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_CONTENUTO_ALLEGATO_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ContenutoAllegatoEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita contenuto allegato"
    }
  }];
  routesProviderFactory.extend(this);
}