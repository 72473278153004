"use strict";

(function () {
  'use strict';

  angular.module('polcity.osContabilita').provider('osContabilitaRoutes', ['routesProviderFactoryProvider', routeProvider]);

  function routeProvider(routesProviderFactory) {
    this.routes = [{
      name: 'osContabilita',
      abstract: true,
      url: 'osContabilita',
      templateUrl: 'osContabilita/osContabilita.html'
    }, {
      name: 'osContabilita.armonizzazioneContabile',
      role: 'ROLE_CONTABILITA_RICERCA_VELOCE',
      url: '/armonizzazione-contabile',
      params: {
        reloadPreviousState: false
      },
      breadcrumbs: {
        isBase: true,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: 'armonizzazioneContabileList'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return $translate('osContabilita.armonizzazioneContabile__bcLabel');
        }]
      }
    }, {
      name: 'osContabilita.armonizzazioneContabileChartsView',
      role: 'ROLE_CONTABILITA_RICERCA_VELOCE',
      url: '/armonizzazione-contabile-charts',
      params: {
        filter: null
      },
      breadcrumbs: {
        isBase: false,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: 'armonizzazioneContabileChartsView'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return $translate('armonizzazioneContabileChartsView.armonizzazioneContabileVistaGrafici__bcLabel');
        }]
      }
    }, {
      name: 'osContabilita.bilancio',
      role: 'ROLE_GENERA_BILANCIO',
      url: '/bilancio',
      breadcrumbs: {
        isBase: true,
        reloadPreviousState: false
      },
      views: {
        $default: {
          component: 'bilancio'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return 'Bilancio';
        }]
      }
    }, {
      name: 'osContabilita.compareBilanci',
      role: 'ROLE_GENERA_BILANCIO',
      url: '/confronta-bilanci',
      params: {
        yearsToCompare: null
      },
      breadcrumbs: {
        isBase: false,
        reloadPreviousState: false
      },
      views: {
        $default: {
          component: 'compareBilanci'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return 'Confronta bilanci';
        }]
      }
    }];
    routesProviderFactory.extend(this);
  }
})();