"use strict";

/**
 * Created by dario
 */
(function () {
  'use strict';
  /**
   * @ngdoc type
   * @name osEvents
   * @module polcity.utils
   * @description
   *
   * Contiene la lista degli eventi che l'applicazione usa.
   * <hljs lang="js">
   *     $scope.$broadcast(osEvents.verbaliInfrazioneDeleted, {data});
   * </hljs>
   *
   * <b>Eventi</b>
   * <dl>
   *     <dt>verbaliInfrazioneDeleted</dt>
   *     <dd>Cancellazione infrazione</dd>
   *     <dt>verbaliInfrazioneAdded</dt>
   *     <dd>Aggiunta infrazione</dd>
   *     <dt>verbaliDecoratoriChanged</dt>
   *     <dd>Variazione dei decoratori nel verbali</dd>
   * </dl>
   *
   **/

  angular.module('polcity.costants').constant('osEvents', {
    verbaliInfrazioneDeleted: 'verbali.infrazione::deleted',
    verbaliInfrazioneAdded: 'verbali.infrazione::added',
    verbaliDecoratoriChanged: 'verbali.decoratori::changed',
    verbaliEnableObbligato: 'verbali::enableObbligato',
    verbaliDisableObbligato: 'verbali::disableObbligato',
    verbaliEnableInfrazioni: 'verbali::enableInfrazioni',
    verbaliDisableInfrazioni: 'verbali::disableInfrazioni',
    verbaliDeleteAnagrafica: 'verbali::deleteAnagrafica',
    soggettiEnableDocumentiVeicoliTabs: 'osSoggetti::enableDocumentiVeicoliTabs',
    soggettiDisableDocumentiVeicoliTabs: 'osSoggetti::disableDocumentiVeicoliTabs',
    tipiAccertamentoAdded: 'usersManagement::added',
    masterDetailListSelectItem: 'masterDetail::selectItem',
    masterDetailListDeselectItem: 'masterDetail::deselectItem',
    masterDetailDeleteItem: 'masterDetail::deleteItem',
    masterDetailEditItem: 'masterDetail::editItem',
    masterDetailReload: 'masterDetail::reload',
    masterDetailHideDetail: 'masterDetail::hideDetail',
    userCreated: 'user::created',
    logout: 'auth::logout',
    login: 'auth::login',
    uploadSubmit: 'upload::submit',
    uploadSuccessItem: 'upload::successItem',
    incrementBlockingActions: 'navigation::incrementBlockingActions',
    decrementBlockingActions: 'navigation::decrementBlockingActions',
    uploadCancelAll: 'upload::cancelAll'
  });
})();