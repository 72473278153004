"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enteTerritorialeValidationSchema = exports.toApiEnteTerritoriale = exports.fromApiEnteTerritoriale = exports.enteTerritorialeEmptyForm = void 0;

var _object = require("../../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const enteTerritorialeEmptyForm = {
  sigla: "",
  nome: "",
  tipoEnteTerritoriale: "",
  stato: null,
  enteSuperiore: null
};
exports.enteTerritorialeEmptyForm = enteTerritorialeEmptyForm;

const fromApiEnteTerritoriale = values => {
  const enteTerritoriale = { ...(values.entityId ? {
      entityId: values.entityId
    } : {}),
    sigla: (0, _object.getPathStringOrEmptyString)(["sigla"])(values),
    nome: (0, _object.getPathStringOrEmptyString)(["nome"])(values),
    tipoEnteTerritoriale: (0, _object.getPathStringOrEmptyString)(["tipoEnteTerritoriale"])(values),
    stato: (0, _object.getPathNonEmptyStringOrNull)(["stato", "aggId"])(values) ? {
      aggId: (0, _object.getPathNonEmptyStringOrNull)(["stato", "aggId"])(values),
      nome: (0, _object.getPathNonEmptyStringOrNull)(["stato", "nome"])(values)
    } : null,
    enteSuperiore: (0, _object.getPathNonEmptyStringOrNull)(["enteSuperioreId"])(values) ? {
      entityId: (0, _object.getPathStringOrNull)(["enteSuperioreId"])(values),
      nome: (0, _object.getPathStringOrEmptyString)(["enteSuperioreNome"])(values),
      tipoEnteTerritoriale: (0, _object.getPathStringOrEmptyString)(["enteSuperioreTipoEnte"])(values)
    } : null
  };
  return enteTerritoriale;
};

exports.fromApiEnteTerritoriale = fromApiEnteTerritoriale;

const toApiEnteTerritoriale = values => {
  const enteTerritoriale = { ...(values.entityId ? {
      entityId: values.entityId
    } : {}),
    aggId: (0, _object.getPathStringOrEmptyString)(["stato", "aggId"])(values),
    // aggId dello stato
    sigla: (0, _object.getPathStringOrEmptyString)(["sigla"])(values),
    nome: (0, _object.getPathStringOrEmptyString)(["nome"])(values),
    tipoEnteTerritoriale: (0, _object.getPathStringOrEmptyString)(["tipoEnteTerritoriale"])(values),
    enteSuperioreId: (0, _object.getPathStringOrEmptyString)(["enteSuperiore", "entityId"])(values)
  };
  return enteTerritoriale;
};

exports.toApiEnteTerritoriale = toApiEnteTerritoriale;
const enteTerritorialeValidationSchema = Yup.object().shape({
  sigla: Yup.string().required(_utils.requiredMessage),
  nome: Yup.string().required(_utils.requiredMessage),
  tipoEnteTerritoriale: Yup.string().required(_utils.requiredMessage),
  stato: Yup.object().required(_utils.requiredMessage).nullable()
});
exports.enteTerritorialeValidationSchema = enteTerritorialeValidationSchema;