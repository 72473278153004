"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useResetCassaSection = exports.useInserisciPagamentoCassaForza = exports.useInserisciPagamentoCassa = exports.useImportoVersamentoCalcolato = exports.usePayableItemsWithSupplierUrlAndImporti = exports.useImportiPayableItemsWithSupplierUrl = exports.useCalcolaImportiPagamentoVerbale = exports.usePayableItems = exports._payableItemsWithoutPriceSupplierUrl = exports._payableItemsPriceSupplierUrl = exports._selectedPayableItemsWithSupplierUrl = exports._selectedPayableItems = void 0;

var _reactQuery = require("react-query");

var _api = require("../../api");

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _utils = require("../../react/reactQuery/utils");

var _utils2 = require("./utils");

var _dateFns = require("date-fns");

/**
 * I payable items di tipo "VERBALE" nella response dell'api di ricerca hanno un priceSupplierUrl che viene fornito dal
 * BE e che noi chiamiamo per avere una sorta di dettaglio delle somme da pagare e pagate del verbale.
 * Attualmente sono previsti, anche se tutti i servizi dedicati per la creazione sono "staccati", degli altri
 * payableItems di tipo "ZTL" (permesso ztl) che non forniscono un priceSupplierUrl; il costo totale da pagare viene
 * fornito dalla somma di price, priceAdditionaleCost, priceAdditionaleTax.
 * Da colloquio con Fabio è emerso che non possiamo liberarci del secondo tipo perchè, probabilmente, torneranno alla
 * ribalta in futuro, probabilmente con il cliente della guardia costiera.
 */
const _selectedPayableItems = (0, _recoil.atom)({
  key: '_selectedPayableItems',
  default: []
});

exports._selectedPayableItems = _selectedPayableItems;

const _selectedPayableItemsWithSupplierUrl = (0, _recoil.selector)({
  key: '_selectedPayableItemsWithSupplierUrl',
  get: ({
    get
  }) => {
    const selectedItems = get(_selectedPayableItems);
    return selectedItems.filter(i => Boolean(i.priceSupplierUrl));
  }
});

exports._selectedPayableItemsWithSupplierUrl = _selectedPayableItemsWithSupplierUrl;

const _payableItemsPriceSupplierUrl = (0, _recoil.selector)({
  key: "_payableItemsPriceSupplierUrl",
  get: ({
    get
  }) => {
    const selectedItems = get(_selectedPayableItems);
    return selectedItems.filter(i => Boolean(i.priceSupplierUrl)).map(i => i.priceSupplierUrl);
  }
});

exports._payableItemsPriceSupplierUrl = _payableItemsPriceSupplierUrl;

const _payableItemsWithoutPriceSupplierUrl = (0, _recoil.selector)({
  key: "_payableItemsWithoutPriceSupplierUrl",
  get: ({
    get
  }) => {
    const selectedItems = get(_selectedPayableItems);
    return selectedItems.filter(item => !Boolean(item.priceSupplierUrl));
  }
});

exports._payableItemsWithoutPriceSupplierUrl = _payableItemsWithoutPriceSupplierUrl;

const usePayableItems = query => {
  const {
    data,
    isLoading,
    isFetching
  } = (0, _reactQuery.useQuery)([`payableItems`, query], ({
    queryKey
  }) => (0, _api.ricercaPayableItems)({
    query: queryKey[1].query
  }), {
    staleTime: 1000 * 60 * 5,
    select: response => response.error ? response : (0, _utils2.fromApiPayableItemsSearch)(response === null || response === void 0 ? void 0 : response.resultsWrapper.values) || []
  });
  return {
    data,
    isLoading,
    isFetching
  };
};

exports.usePayableItems = usePayableItems;

const useCalcolaImportiPagamentoVerbale = ({
  supplierUrls,
  dataVersamento,
  onSuccess,
  onFailure
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const responses = (0, _reactQuery.useQueries)(supplierUrls.map(supplierUrl => {
    return {
      queryKey: [`supplierUrl`, {
        supplierUrl,
        dataVersamento
      }],
      queryFn: ({
        queryKey
      }) => (0, _api.calcolaImportiPayableItems)((0, _utils2.toApiCalcolaImportiPayableItems)(queryKey[1])),
      staleTime: 1000 * 60 * 5,
      suspense: false,
      // https://github.com/TanStack/query/issues/1523
      enabled: Boolean(supplierUrls.length) && Boolean(dataVersamento) && (0, _dateFns.isValid)(dataVersamento),
      onSuccess: response => (0, _utils.handleOnSuccessQuery)(messenger)({
        response,
        onSuccess,
        onFailure
      }),
      onError: response => (0, _utils.handleError)(messenger)(response)
    };
  })); //TODO: gestire gli errori

  return [(responses || []).some(res => res.isLoading || res.isFetching), responses.filter(r => r.status === "success").map(({
    data
  }) => (0, _utils2.fromApiCalcolaImporti)(data))];
};

exports.useCalcolaImportiPagamentoVerbale = useCalcolaImportiPagamentoVerbale;

const useImportiPayableItemsWithSupplierUrl = ({
  payableItems,
  dataVersamento
}) => useCalcolaImportiPagamentoVerbale({
  supplierUrls: payableItems.map(p => p.priceSupplierUrl),
  dataVersamento
});

exports.useImportiPayableItemsWithSupplierUrl = useImportiPayableItemsWithSupplierUrl;

const usePayableItemsWithSupplierUrlAndImporti = ({
  payableItems,
  dataVersamento
}) => {
  const [isLoading, importi] = useImportiPayableItemsWithSupplierUrl({
    payableItems,
    dataVersamento
  });
  return [isLoading, payableItems.map((p, index) => ({ ...p,
    ...(Boolean(importi[index]) ? {
      paymentsDetail: { ...importi[index]
      }
    } : {})
  }))];
};

exports.usePayableItemsWithSupplierUrlAndImporti = usePayableItemsWithSupplierUrlAndImporti;

const useImportoVersamentoCalcolato = ({
  payableItems,
  dataVersamento
}) => {
  const [isLoading, importiPayableItems] = useImportiPayableItemsWithSupplierUrl({
    payableItems,
    dataVersamento
  });
  const payableItemsWithousPriceSupplierUrl = (0, _recoil.useRecoilValue)(_payableItemsWithoutPriceSupplierUrl);
  return [isLoading, [...importiPayableItems.map(i => i.daPagare), ...payableItemsWithousPriceSupplierUrl.map(i => i.price + i.priceAddictionalCost + i.priceAddictionalTax)].reduce((acc, val) => acc + val, 0)];
};

exports.useImportoVersamentoCalcolato = useImportoVersamentoCalcolato;

const useInserisciPagamentoCassa = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Pagamento inserito",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(values => (0, _api.inserisciPagamentoCassa)((0, _utils2.toApiInserisciPagamentoCassa)(values)), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => {
      queryClient.invalidateQueries(["payableItems"]);
      queryClient.invalidateQueries(["supplierUrl"]);
    }
  });
};

exports.useInserisciPagamentoCassa = useInserisciPagamentoCassa;

const useInserisciPagamentoCassaForza = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Pagamento inserito",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(values => (0, _api.inserisciPagamentoCassaForza)((0, _utils2.toApiInserisciPagamentoCassa)(values)), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(["payableItems"])
  });
};

exports.useInserisciPagamentoCassaForza = useInserisciPagamentoCassaForza;

const useResetCassaSection = () => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const resetSelectedPayableItems = (0, _recoil.useResetRecoilState)(_selectedPayableItems);
  return () => {
    resetSelectedPayableItems();
    queryClient.removeQueries("payableItems");
    queryClient.removeQueries("supplierUrl");
  };
};

exports.useResetCassaSection = useResetCassaSection;