"use strict";

var _DecurtazioniPuntiMasterDetail = _interopRequireDefault(require("./masterDetail/DecurtazioniPuntiMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.decurtazioniPunti').provider('decurtazioniPuntiRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'decurtazioniList',
    role: 'ROLE_DECURTAZIONE_PUNTI_RICERCA',
    url: 'decurtazioni?{page:int}&{limit:int}&{query:string}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    params: {
      reloadPreviousState: false
    },
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _DecurtazioniPuntiMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Decurtazioni punti"
    }
  }];
  routesProviderFactory.extend(this);
}