"use strict";

(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name osAllegati
   *
   * @requires ngMaterial
   * @requires polcity.api
   * @requires polcity.upload
   */

  angular.module('osAllegati', []).run(['osAllegatiCommands', run]);

  function run(osAllegatiCommands) {
    osAllegatiCommands.publish();
  }
})();