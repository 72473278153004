"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

var _styles = require("@material-ui/core/styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const BoldTextTypography = (0, _styles.withStyles)({
  root: {
    fontWeight: "bold"
  }
})(_Typography.default);
var _default = BoldTextTypography;
exports.default = _default;