"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEsitoInErroreMessage = exports.getDatiRichiesta = exports.getEsito = exports.isVisuraOnComunicazioneError = exports.isVisuraOnError = exports.isSorgeteDatiACI = void 0;

const isSorgeteDatiACI = sorgenteDati => sorgenteDati.includes('ACI');

exports.isSorgeteDatiACI = isSorgeteDatiACI;

const isVisuraOnError = esito => esito === 'ERRORE';

exports.isVisuraOnError = isVisuraOnError;

const isVisuraOnComunicazioneError = comunicazione => comunicazione === 'KO';

exports.isVisuraOnComunicazioneError = isVisuraOnComunicazioneError;

const getEsito = esito => `Codice: ${esito.codiceEsito} - Descrizione: ${esito.descrizioneEsito}`;

exports.getEsito = getEsito;

const getDatiRichiesta = richiesta => richiesta;

exports.getDatiRichiesta = getDatiRichiesta;

const getEsitoInErroreMessage = errori => errori.map(errore => `Codice: ${errore.codiceErrore} - Descrizione: ${errore.descrizioneErrore}`);

exports.getEsitoInErroreMessage = getEsitoInErroreMessage;