"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _formik = require("formik");

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const RimozioniEffects = () => {
  const {
    values,
    touched,
    setValues,
    setFieldValue,
    setFieldTouched,
    isSubmitting
  } = (0, _formik.useFormikContext)();
  touched.datiLuogoAccertamento = touched.datiLuogoAccertamento || {
    relazioneStrada: {}
  };
  touched.datiRimozione = touched.datiRimozione || {}; // Quando cambia il comune resetto strada e i campi di tipoReazioneStrada

  (0, _react.useEffect)(() => {
    if (touched.datiLuogoAccertamento.comune && !values.datiLuogoAccertamento.comune && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        datiLuogoAccertamento: {
          strada: null,
          civico: '',
          kilometro: '',
          relazioneStrada: {
            tipoRelazioneStradaTipo: "",
            tipoRelazioneStradaStrada: null,
            tipoRelazioneStradaCivico: "",
            tipoRelazioneDescrizioneFronte: ""
          }
        }
      })); // setFieldTouched("datiLuogoAccertamento.comune", false)
    }
  }, [values.datiLuogoAccertamento.comune]); // Quando cambia la strada resetto civico e km

  (0, _react.useEffect)(() => {
    if (touched.datiLuogoAccertamento.strada && !values.datiLuogoAccertamento.strada && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        datiLuogoAccertamento: {
          civico: '',
          kilometro: '',
          relazioneStrada: {
            tipoRelazioneStradaTipo: "",
            tipoRelazioneStradaStrada: null,
            tipoRelazioneStradaCivico: "",
            tipoRelazioneDescrizioneFronte: ""
          }
        }
      }));
      values.datiLuogoAccertamento.strada && setFieldTouched("datiLuogoAccertamento.strada", false);
    }
  }, [values.datiLuogoAccertamento.strada, touched.datiLuogoAccertamento.strada]); // Quando cambia il tipo relazione, resetto

  (0, _react.useEffect)(() => {
    if (touched.datiLuogoAccertamento.relazioneStrada.tipoRelazioneStradaTipo && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        datiLuogoAccertamento: {
          relazioneStrada: {
            tipoRelazioneStradaStrada: null,
            tipoRelazioneStradaCivico: "",
            tipoRelazioneDescrizioneFronte: ""
          }
        }
      })); // setFieldTouched("datiLuogoAccertamento.comune", false)
    }
  }, [values.datiLuogoAccertamento.relazioneStrada.tipoRelazioneStradaTipo, touched.datiLuogoAccertamento.relazioneStrada.tipoRelazioneStradaTipo]); // Quando cambia la strada nel relazioneStrada resetto civico

  (0, _react.useEffect)(() => {
    if (touched.datiLuogoAccertamento.relazioneStrada.tipoRelazioneStradaStrada && !values.datiLuogoAccertamento.relazioneStrada.tipoRelazioneStradaStrada && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        datiLuogoAccertamento: {
          relazioneStrada: {
            tipoRelazioneStradaCivico: ""
          }
        }
      })); // setFieldTouched("datiLuogoAccertamento.comune", false)
    }
  }, [values.datiLuogoAccertamento.relazioneStrada.tipoRelazioneStradaStrada, touched.datiLuogoAccertamento.relazioneStrada.tipoRelazioneStradaStrada]); // Quando cambia rimozione effettuata, resetto rimozioneDataOra e rimozioneAccertataIl

  (0, _react.useEffect)(() => {
    if (touched.datiRimozione.rimozioneEffettuata && !values.datiRimozione.rimozioneEffettuata && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        datiRimozione: {
          rimozioneDataOra: null,
          rimozioneAccertataIl: null
        }
      }));
    }
  }, [values.datiRimozione.rimozioneEffettuata, touched.datiRimozione.rimozioneEffettuata]);
  return null;
};

var _default = RimozioniEffects;
exports.default = _default;