"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tipoBollettarioValidationSchema = exports.toApiTipoBollettario = exports.fromApiTipoBollettario = exports.tipoBollettarioEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const tipoBollettarioEmptyForm = {
  tipoAccertamento: null,
  numero: "",
  bolletteDal: "",
  bolletteAl: "",
  consegnato: null,
  restituito: null,
  note: ""
};
exports.tipoBollettarioEmptyForm = tipoBollettarioEmptyForm;

const fromApiTipoBollettario = remoteValues => {
  const tipoBollettario = { ...(remoteValues.aggId ? {
      aggId: remoteValues.aggId
    } : {}),
    ...(remoteValues.anno ? {
      anno: remoteValues.anno
    } : {}),
    ...(remoteValues.prossimaBolletta ? {
      prossimaBolletta: remoteValues.prossimaBolletta
    } : {}),
    tipoAccertamento: (0, _object.getPathStringOrNull)(["tipoAccertamento", "tipoAccertamentoId"])(remoteValues) ? {
      aggId: (0, _object.getPathStringOrEmptyString)(["tipoAccertamento", "tipoAccertamentoId"])(remoteValues),
      codiceVeloce: (0, _object.getPathStringOrEmptyString)(["tipoAccertamento", "tipoAccertamentoCodiceVeloce"])(remoteValues),
      nome: (0, _object.getPathStringOrEmptyString)(["tipoAccertamento", "tipoAccertamentoNome"])(remoteValues)
    } : null,
    numero: (0, _object.getPathNumberOrNull)(["numero"])(remoteValues),
    bolletteDal: (0, _object.getPathNumberOrNull)(["bolletteDal"])(remoteValues),
    bolletteAl: (0, _object.getPathNumberOrNull)(["bolletteAl"])(remoteValues),
    consegnato: (0, _object.getPathNumberToDateOrNull)(["consegnato"])(remoteValues),
    restituito: (0, _object.getPathNumberToDateOrNull)(["restituito"])(remoteValues),
    note: (0, _object.getPathStringOrEmptyString)(["note"])(remoteValues)
  };
  return tipoBollettario;
};

exports.fromApiTipoBollettario = fromApiTipoBollettario;

const toApiTipoBollettario = values => {
  const tipoBollettario = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    ...(values.anno ? {
      anno: values.anno
    } : {}),
    ...(values.prossimaBolletta ? {
      prossimaBolletta: values.prossimaBolletta
    } : {}),
    tipoAccertamentoId: (0, _object.getPathStringOrEmptyString)(["tipoAccertamento", "aggId"])(values),
    numero: (0, _object.getPathNumberOrNull)(["numero"])(values),
    bolletteDal: (0, _object.getPathNumberOrNull)(["bolletteDal"])(values),
    bolletteAl: (0, _object.getPathNumberOrNull)(["bolletteAl"])(values),
    consegnato: (0, _object.getPathDateToNumberOrNull)(["consegnato"])(values),
    restituito: (0, _object.getPathDateToNumberOrNull)(["restituito"])(values),
    note: (0, _object.getPathStringOrEmptyString)(["note"])(values)
  };
  return tipoBollettario;
};

exports.toApiTipoBollettario = toApiTipoBollettario;
const tipoBollettarioValidationSchema = Yup.object().shape({
  tipoAccertamento: Yup.object().required(_utils.requiredMessage).nullable(),
  numero: Yup.number().min(0, "Questo campo non può avere un valore negativo").required(_utils.requiredMessage),
  bolletteDal: Yup.number().min(0, "Questo campo non può avere un valore negativo").required(_utils.requiredMessage),
  bolletteAl: Yup.number().min(0, "Questo campo non può avere un valore negativo").required(_utils.requiredMessage),
  consegnato: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').nullable().typeError('Data non valida'),
  restituito: Yup.date().nullable().typeError('Data non valida')
});
exports.tipoBollettarioValidationSchema = tipoBollettarioValidationSchema;