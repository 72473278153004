"use strict";

var _NuovoVerbaleRoot = _interopRequireDefault(require("../verbaleNuovo/NuovoVerbaleRoot"));

var _AllegatiDigitaliNonAssociatiMasterDetail = _interopRequireDefault(require("./masterDetail/AllegatiDigitaliNonAssociatiMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.allegatiDigitali').provider('allegatiDigitaliRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'allegatiDigitaliNonAssociatiList',
    url: 'allegatiDigitaliNonAssociati?{page:int}&{limit:int}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    role: 'ROLE_ALLEGATO_DIGITALE_STAGING_RICERCA',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _AllegatiDigitaliNonAssociatiMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Allegati digitali non associati"
    }
  }, {
    name: 'allegatiDigitaliVerbalizza',
    role: 'ROLE_VERBALE_AGGIUNGI',
    url: 'allegatiDigitaliNonAssociati/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}/verbalizza',
    params: {
      daAllegatoDigitale: true,
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _NuovoVerbaleRoot.default
      }
    },
    resolve: {
      $label: () => "Verbalizza da allegato digitale"
    }
  }];
  routesProviderFactory.extend(this);
}