"use strict";

var _ContiCorrentiList = _interopRequireDefault(require("./ContiCorrentiList"));

var _ContoCorrenteEditRoot = _interopRequireDefault(require("./edit/ContoCorrenteEditRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('gestioneContiCorrenteTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsContiCorrente',
    url: 'tds/gestioneDelTerritorio/{comuneId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}/contiCorrenti',
    role: 'ROLE_CCP_RICERCA',
    params: {
      comuneId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ContiCorrentiList.default
      }
    },
    resolve: {
      $label: () => "Conti corrente"
    }
  }, {
    name: 'tdsContiCorrenteEdit',
    url: 'tds/gestioneDelTerritorio/{comuneId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}/contiCorrenti/{entityId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_CCP_MODIFICA',
    params: {
      entityId: null,
      comuneId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ContoCorrenteEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita conto corrente"
    }
  }];
  routesProviderFactory.extend(this);
}