"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.annullaVerbaleReactQuery = exports.toApiAnnullaVerbale = exports.annullaVerbaleEmptyForm = exports.annullaVerbaleValidationSchema = exports._annullaVerbaleDialogOpen = void 0;

var _reactQuery = require("react-query");

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _api = require("../../api");

var _utils = require("../../react/reactQuery/utils");

var _verbale = require("./verbale");

var Yup = _interopRequireWildcard(require("yup"));

var _utils2 = require("../../utils");

var _object = require("../../object.utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const _annullaVerbaleDialogOpen = (0, _recoil.atom)({
  key: "_annullaVerbaleDialogOpen",
  default: false
});

exports._annullaVerbaleDialogOpen = _annullaVerbaleDialogOpen;
const annullaVerbaleValidationSchema = Yup.object().shape({
  dataOraAnnullamento: Yup.date().max(_utils2.todayEndDay, 'Date future non ammesse').required(_utils2.requiredMessage).nullable().typeError('Data non valida'),
  motivazioneAnnullamento: Yup.string().required("Questo campo è obbligatorio")
});
exports.annullaVerbaleValidationSchema = annullaVerbaleValidationSchema;
const annullaVerbaleEmptyForm = {
  dataOraAnnullamento: new Date(),
  motivazioneAnnullamento: ''
};
exports.annullaVerbaleEmptyForm = annullaVerbaleEmptyForm;

const toApiAnnullaVerbale = values => {
  return {
    aggId: (0, _object.getPathNonEmptyStringOrNull)(["aggId"])(values),
    dataOraAnnulla: (0, _object.getPathDateToNumberOrNull)(["dataOraAnnullamento"])(values),
    motivazioneAnnullamento: (0, _object.getPathNonEmptyStringOrNull)(["motivazioneAnnullamento"])(values)
  };
};

exports.toApiAnnullaVerbale = toApiAnnullaVerbale;

const annullaVerbaleReactQuery = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Verbale annullato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(values => (0, _api.annullaVerbale)(toApiAnnullaVerbale(values)), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(_verbale.verbaliKeys.all())
  });
};

exports.annullaVerbaleReactQuery = annullaVerbaleReactQuery;