"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Visura', ['restmod', function (restmod) {
    return restmod.model('visura').mix({
      $extend: {
        Scope: {
          $config: {
            name: 'visure',
            baseEntityPath: 'visura/',
            api: {
              detail: {
                url: 'dettaglio/visura',
                method: 'POST'
              },
              aggiungi: {
                url: 'visure/aggiungi',
                method: 'POST',
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Credentials': true,
                  'Access-Control-Allow-Headers': 'Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
                  'Access-Control-Allow-Methods': '*'
                }
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'RicercaVeloce', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {},
        Resource: {},
        Collection: {},
        Record: {
          $aggiungiVisura: function (_params) {
            return this.$action(function () {
              var request = this.$buildRequest('aggiungi', false);
              request.data = Object.assign({}, _params);
              this.$dispatch('before-aggiungi-visura', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-aggiungi-visura', [_response]);
              }, function (_response) {
                this.$dispatch('after-aggiungi-visura-error', [_response]);
              });
            });
          }
        }
      }
    });
  }]);
})();