"use strict";

(function () {
  'use strict';

  angular.module('polcity.lotti').config(['$stateProvider', 'lottiRoutesProvider', 'AuthProvider', addRoutes]).run(['lottiRoutes', routesRun]);

  function addRoutes($stateProvider, lottiRoutesProvider, AuthProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }
    }, lottiRoutesProvider.list());
  }

  function routesRun(lottiRoutes) {
    lottiRoutes.subscribeUIEvents();
  }
})();