"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.insolutiRuoloTablecolumns = exports.fromApiRuolo = exports.fromApiRuoliQuickSearch = void 0;

var _object = require("../../object.utils");

var _DataGridColumnTypes = require("../../react/components/ApiRefGrid/DataGridColumnTypes");

var _utils = require("../../utils");

const fromApiRuoliQuickSearch = ruoli => {
  return ruoli.map(ruolo => ({ ...ruolo,
    dataCreazione: (0, _object.getPathNumberToDateOrNull)(["dataCreazione"])(ruolo),
    dataFlusso: (0, _object.getPathNumberToDateOrNull)(["dataFlusso"])(ruolo),
    dataInvio: (0, _object.getPathNumberToDateOrNull)(["dataInvio"])(ruolo)
  }));
};

exports.fromApiRuoliQuickSearch = fromApiRuoliQuickSearch;

const fromApiRuolo = ruolo => {
  return { ...(ruolo.aggId ? {
      aggId: ruolo.aggId
    } : {}),
    lottoId: (0, _object.getPathStringOrEmptyString)(["lottoId"])(ruolo),
    nome: (0, _object.getPathStringOrEmptyString)(["nome"])(ruolo),
    stato: (0, _object.getPathStringOrEmptyString)(["stato"])(ruolo),
    creatoDa: (0, _object.getPathStringOrEmptyString)(["creatoDa"])(ruolo),
    dataCreazione: (0, _object.getPathNumberToDateOrNull)(["dataCreazione"])(ruolo),
    dataFlusso: (0, _object.getPathNumberToDateOrNull)(["dataFlusso"])(ruolo),
    dataInvio: (0, _object.getPathNumberToDateOrNull)(["dataInvio"])(ruolo),
    insoluti: (0, _object.getPathArrayOrEmptyArray)(["insoluti"])(ruolo).map(insoluto => ({ ...insoluto,
      dataViolazione: (0, _object.getPathNumberToDateOrNull)(["dataViolazione"])(insoluto),
      dataNotificaTrasgressore: (0, _object.getPathNumberToDateOrNull)(["dataNotificaTrasgressore"])(insoluto),
      dataNotificaObbligato: (0, _object.getPathNumberToDateOrNull)(["dataNotificaObbligato"])(insoluto),
      dataTerminePagamento: (0, _object.getPathNumberToDateOrNull)(["dataTerminePagamento"])(insoluto)
    }))
  };
};

exports.fromApiRuolo = fromApiRuolo;
const insolutiRuoloTablecolumns = [{
  field: 'verbaleId',
  headerName: 'N° verbale',
  disableColumnMenu: true,
  flex: 1,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'dataViolazione',
  headerName: 'Data violazione',
  disableColumnMenu: true,
  flex: 1,
  valueGetter: ({
    value
  }) => value ? (0, _utils.formatDate)(value) : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'dataNotificaTrasgressore',
  headerName: 'Data notifica trasgressore',
  disableColumnMenu: true,
  flex: 1,
  valueGetter: ({
    value
  }) => value ? (0, _utils.formatDate)(value) : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'dataNotificaObbligato',
  headerName: 'Data notifica obbligato',
  disableColumnMenu: true,
  flex: 1,
  valueGetter: ({
    value
  }) => value ? (0, _utils.formatDate)(value) : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'dataTerminePagamento',
  headerName: 'Termine pagamento',
  disableColumnMenu: true,
  flex: 1,
  valueGetter: ({
    value
  }) => value ? (0, _utils.formatDate)(value) : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'importoSaldato',
  headerName: 'Importo pagato',
  disableColumnMenu: true,
  flex: 1,
  valueGetter: ({
    value
  }) => value ? (0, _utils.formatCurrency)(value) : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'importoInsoluto',
  headerName: 'Importo mancante',
  disableColumnMenu: true,
  flex: 1,
  valueGetter: ({
    value
  }) => value ? (0, _utils.formatCurrency)(value) : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'nomeCognomeSoggettoPerRuolo',
  headerName: 'Soggetto per ruolo',
  disableColumnMenu: true,
  flex: 1,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.insolutiRuoloTablecolumns = insolutiRuoloTablecolumns;