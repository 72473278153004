"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useArchivioArmaposteMasterDetail = exports._verbaleArchivioArmaposteFilters = exports.archivioArmaposteMasterDetailName = void 0;

var _recoil = require("recoil");

var _archivioArmaposte = require("./archivioArmaposte");

const archivioArmaposteMasterDetailName = "archivioArmaposte";
exports.archivioArmaposteMasterDetailName = archivioArmaposteMasterDetailName;

const _verbaleArchivioArmaposteFilters = (0, _recoil.atom)({
  key: "_verbaleArchivioArmaposteFilters",
  default: [{
    name: "idVerbale",
    label: "Numero verbale",
    default: null
  }, {
    name: "targa",
    label: "Targa",
    default: null
  }]
});

exports._verbaleArchivioArmaposteFilters = _verbaleArchivioArmaposteFilters;

const useArchivioArmaposteMasterDetail = ({
  activeFilters: activeFiltersProp,
  activeItemIdVerbale: activeItemIdVerbaleProp
} = {}) => {
  const availableFilters = (0, _recoil.useRecoilValue)(_verbaleArchivioArmaposteFilters);
  const {
    items,
    count,
    isLoading,
    activeFilters,
    addFilter,
    removeFilter,
    activeItemIdVerbale,
    setActiveItemIdVerbale
  } = (0, _archivioArmaposte.useRicercaVerbaliArchivioArmaposte)({
    activeItemIdVerbale: activeItemIdVerbaleProp,
    activeFilters: activeFiltersProp
  });
  return {
    items,
    count,
    isLoading,
    activeItemIdVerbale,
    setActiveItemIdVerbale: props => {
      setActiveItemIdVerbale(props);
    },
    availableFilters,
    activeFilters,
    addFilter: props => {
      activeFilters.length && removeFilter(props.name);
      addFilter(props);
    },
    removeFilter: props => {
      removeFilter(props);
    }
  };
};

exports.useArchivioArmaposteMasterDetail = useArchivioArmaposteMasterDetail;