"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.frazioneValidationSchema = exports.toApiFrazione = exports.fromApiFrazione = exports.frazioneEmptyForm = void 0;

var Yup = _interopRequireWildcard(require("yup"));

var _object = require("../object.utils");

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const frazioneEmptyForm = {
  codiceVeloce: "",
  nome: [],
  descrizione: "",
  cap: ""
};
exports.frazioneEmptyForm = frazioneEmptyForm;

const fromApiFrazione = remoteFrazione => {
  const frazione = { ...(remoteFrazione.aggId ? {
      aggId: remoteFrazione.aggId
    } : {}),
    // ID DEL COMUNE
    ...(remoteFrazione.frazioneId ? {
      frazioneId: remoteFrazione.frazioneId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(remoteFrazione),
    nome: (0, _object.getPathArrayOrEmptyArray)(["nome"])(remoteFrazione),
    nome_transId: (0, _object.getPathStringOrEmptyString)(["nome_transId"])(remoteFrazione),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(remoteFrazione),
    cap: (0, _object.getPathStringOrEmptyString)(["cap"])(remoteFrazione)
  };
  return frazione;
};

exports.fromApiFrazione = fromApiFrazione;

const toApiFrazione = values => {
  const frazione = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    // ID DEL COMUNE
    ...(values.frazioneId ? {
      frazioneId: values.frazioneId
    } : {}),
    ...(values.nome_transId ? {
      nome_transId: values.nome_transId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(values),
    nome: (0, _object.getPathArrayOrEmptyArray)(["nome"])(values),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(values),
    cap: (0, _object.getPathStringOrEmptyString)(["cap"])(values)
  };
  return frazione;
};

exports.toApiFrazione = toApiFrazione;
const frazioneValidationSchema = Yup.object().shape({
  codiceVeloce: Yup.string().required(_utils.requiredMessage),
  nome: Yup.array().ensure().min(1, _utils.requiredMessage).of(Yup.object().shape({
    traduzione: Yup.string().required(_utils.requiredMessage),
    languageTag: Yup.string().required(_utils.requiredMessage)
  })),
  descrizione: Yup.string().required(_utils.requiredMessage),
  cap: Yup.string().required(_utils.requiredMessage)
});
exports.frazioneValidationSchema = frazioneValidationSchema;