"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.leggeValidationSchema = exports.toApiLegge = exports.fromApiLegge = exports.leggeEmptyForm = void 0;

var Yup = _interopRequireWildcard(require("yup"));

var _object = require("../object.utils");

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const leggeEmptyForm = {
  codiceLegge: "",
  descrizione: "",
  ambito: ""
};
exports.leggeEmptyForm = leggeEmptyForm;

const fromApiLegge = remoteLegge => {
  const legge = { ...(remoteLegge.aggId ? {
      aggId: remoteLegge.aggId
    } : {}),
    codiceLegge: (0, _object.getPathStringOrEmptyString)(["codiceLegge"])(remoteLegge),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(remoteLegge),
    ambito: (0, _object.getPathStringOrEmptyString)(["ambito"])(remoteLegge)
  };
  return legge;
};

exports.fromApiLegge = fromApiLegge;

const toApiLegge = values => {
  const legge = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    codiceLegge: (0, _object.getPathNonEmptyStringOrNull)(["codiceLegge"])(values),
    descrizione: (0, _object.getPathNonEmptyStringOrNull)(["descrizione"])(values),
    ambito: (0, _object.getPathNonEmptyStringOrNull)(["ambito"])(values)
  };
  return legge;
};

exports.toApiLegge = toApiLegge;
const leggeValidationSchema = Yup.object().shape({
  codiceLegge: Yup.string().required(_utils.requiredMessage),
  descrizione: Yup.string().required(_utils.requiredMessage),
  ambito: Yup.string().required(_utils.requiredMessage)
});
exports.leggeValidationSchema = leggeValidationSchema;