"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('DecurtazionePunti', ['restmod', function (restmod) {
    return restmod.model('decurtazionePunti').mix({
      $extend: {
        Scope: {
          $config: {
            name: 'decurtazioni',
            baseEntityPath: 'decurtazione-punti/',
            api: {
              list: {
                url: 'ricerca/decurtazione-punti',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/decurtazione-punti',
                method: 'POST'
              },
              destroy: {
                url: 'comando/annulla',
                method: 'POST'
              },
              edit: {
                url: 'comando/modifica',
                method: 'POST'
              },
              esporta: {
                url: 'comando/esportazione-file-punti',
                method: 'POST',
                responseType: 'arraybuffer'
              },
              storna: {
                url: 'comando/esportazione-file-storno-punti',
                method: 'POST',
                responseType: 'arraybuffer'
              },
              assegnaDocumento: {
                url: 'comando/assegna-documento',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl("list");
            };

            return collection;
          }
        },
        Resource: {
          $esporta: function (_params) {
            if (this.$isCollection && !_params) {
              this.$dispatch('before-esporta-wrong-parameters-exception');
              throw 'Wrong parameters exception';
            }

            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('esporta');

              if (this.$isCollection) {
                request.data = Object.assign({}, {
                  aggIds: _params
                });
              } else {
                request.data = Object.assign({}, {
                  aggIds: [this.$pk]
                });
              }

              this.$dispatch('before-esporta', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-esporta', [_response]);
              }, function (_response) {
                this.$dispatch('after-esporta-error', [_response]);
              });
            });
          },
          $storna: function (_params) {
            if (this.$isCollection && !_params) {
              this.$dispatch('before-storna-wrong-parameters-exception');
              throw 'Wrong parameters exception';
            }

            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('storna');

              if (this.$isCollection) {
                request.data = Object.assign({}, {
                  aggIds: _params
                });
              } else {
                request.data = Object.assign({}, {
                  aggIds: [this.$pk]
                });
              }

              this.$dispatch('before-storna', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-storna', [_response]);
              }, function (_response) {
                this.$dispatch('after-storna-error', [_response]);
              });
            });
          }
        },
        Record: {
          $assegnaDocumento: function (documentoId) {
            return this.$action(function () {
              var request = this.$buildRequest('assegnaDocumento');
              request.data = Object.assign({}, {
                aggId: this.$pk,
                documentoId
              });
              this.$dispatch('before-assegna-documento', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-assegna-documento', [_response]);
              }, function (_response) {
                this.$dispatch('after-assegna-documento-error', [_response]);
              });
            });
          },
          //Override della defaul $destroy
          $destroy: function () {
            return this.$action(function () {
              if (this.aggId) {
                var request = this.$buildRequest('destroy', true);
                request.data = {
                  aggId: this.aggId
                };
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) this.$scope.$remove(this);
              }
            });
          }
        }
      }
    });
  }]);
})();