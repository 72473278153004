"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schema = void 0;

var _yup = require("yup");

var _yupLocales = require("yup-locales");

const required = "Campo obbligatorio";
const today = new Date();
(0, _yup.setLocale)(_yupLocales.it);
const schema = (0, _yup.object)().shape({
  data: (0, _yup.date)().nullable().max(today, `Data max ${today.toLocaleDateString()}`).required(required),
  dataRegistrazione: (0, _yup.date)().nullable().max(today, `Data max ${today.toLocaleDateString()}`).required(required),
  scrittureContabili: (0, _yup.array)().of((0, _yup.object)().shape({
    conto: (0, _yup.string)().required(required),
    descrizione: (0, _yup.string)().required(required),
    dareAvere: (0, _yup.string)(),
    importo: (0, _yup.number)().positive("Numeri positivi").required(required)
  })).min(2),
  totaleDare: (0, _yup.number)(),
  totaleAvere: (0, _yup.number)().oneOf([(0, _yup.ref)('totaleDare')], "Il totale DARE è diverso dal totale AVERE")
});
exports.schema = schema;