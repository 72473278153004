"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tipologieRimozioniListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

var _enums = require("../../../state/enums");

const tipologieRimozioniListColumns = [{
  field: 'codiceVeloce',
  headerName: 'Codice veloce',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'competenza',
  headerName: 'Competenza',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaCompetenza") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.tipologieRimozioniListColumns = tipologieRimozioniListColumns;