"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateRimozione = exports.rimozioneFormValidationSchema = exports.rimozioneEmptyForm = exports.toApiRimozione = exports.fromApiRimozione = void 0;

var _object = require("../../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _tryCatch = _interopRequireDefault(require("crocks/Result/tryCatch"));

var _either = _interopRequireDefault(require("crocks/pointfree/either"));

var _composeB = _interopRequireDefault(require("crocks/combinators/composeB"));

var _utils = require("../../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const fromApiRimozione = remoteRimozione => {
  const rimozione = { ...(remoteRimozione.aggId ? {
      aggId: remoteRimozione.aggId
    } : {}),
    "datiVerbale": (0, _object.getPathStringOrNull)(["datiVerbale", "verbaleId"])(remoteRimozione) ? {
      "verbaleAggId": (0, _object.getPathNonEmptyStringOrNull)(["datiVerbale", "verbaleAggId"])(remoteRimozione),
      "verbaleEtichettaRegistro": (0, _object.getPathNonEmptyStringOrNull)(["datiVerbale", "verbaleEtichettaRegistro"])(remoteRimozione),
      "verbaleId": (0, _object.getPathNonEmptyStringOrNull)(["datiVerbale", "verbaleId"])(remoteRimozione)
    } : null,
    "datiLuogoAccertamento": {
      "civico": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoCivico"])(remoteRimozione),
      "kilometro": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoKilometro"])(remoteRimozione),
      "comuneId": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoComuneId"])(remoteRimozione),
      "comune": (0, _object.getPathStringOrNull)(["datiLuogoAccertamento", "luogoAccertamentoComuneId"])(remoteRimozione) ? {
        "aggId": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoComuneId"])(remoteRimozione),
        "nome": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoComuneNome"])(remoteRimozione),
        "nomeTr": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoComuneNome"])(remoteRimozione)
      } : null,
      "stradaId": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoStradaId"])(remoteRimozione),
      "strada": (0, _object.getPathStringOrNull)(["datiLuogoAccertamento", "luogoAccertamentoStradaId"])(remoteRimozione) ? {
        "entityId": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoStradaId"])(remoteRimozione),
        "nome": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoStradaNome"])(remoteRimozione),
        "toponomastica": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoStradaToponomastica"])(remoteRimozione)
      } : null,
      "relazioneStrada": {
        "tipoRelazioneStradaTipo": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoTipoRelazioneStradaTipo"])(remoteRimozione),
        "tipoRelazioneStradaCivico": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoTipoRelazioneStradaCivico"])(remoteRimozione),
        "tipoRelazioneDescrizioneFronte": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoTipoRelazioneDescrizioneFronte"])(remoteRimozione),
        "tipoRelazioneStradaStradaId": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoTipoRelazioneStradaStradaId"])(remoteRimozione),
        "tipoRelazioneStradaStrada": (0, _object.getPathStringOrNull)(["datiLuogoAccertamento", "luogoAccertamentoTipoRelazioneStradaStradaId"])(remoteRimozione) ? {
          "entityId": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoTipoRelazioneStradaStradaId"])(remoteRimozione),
          "nome": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoTipoRelazioneStradaNome"])(remoteRimozione),
          "toponomastica": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoTipoRelazioneStradaToponomastica"])(remoteRimozione),
          "tipologia": (0, _object.getPathStringOrEmptyString)(["datiLuogoAccertamento", "luogoAccertamentoTipoRelazioneStradaTipologia"])(remoteRimozione)
        } : null
      }
    },
    "datiApplicazione": {
      "motivazioneMancataApplicazioneId": (0, _object.getPathStringOrEmptyString)(["datiApplicazione", "motivazioneMancataApplicazioneId"])(remoteRimozione),
      "motivazioneMancataApplicazione": (0, _object.getPathStringOrNull)(["datiApplicazione", "motivazioneMancataApplicazioneId"])(remoteRimozione) ? {
        "aggId": (0, _object.getPathStringOrEmptyString)(["datiApplicazione", "motivazioneMancataApplicazioneId"])(remoteRimozione),
        "nome": (0, _object.getPathStringOrEmptyString)(["datiApplicazione", "motivazioneMancataApplicazioneNome"])(remoteRimozione),
        "descrizioneStampa": (0, _object.getPathStringOrEmptyString)(["datiApplicazione", "motivazioneMancataApplicazioneDescrizioneStampa"])(remoteRimozione)
      } : null,
      "motivazioneMancataApplicazioneLibera": (0, _object.getPathStringOrEmptyString)(["datiApplicazione", "motivazioneMancataApplicazioneLibera"])(remoteRimozione),
      "applicabile": (0, _object.getPathBooleanOrFalse)(["datiApplicazione", "applicabile"])(remoteRimozione)
    },
    "datiRimozione": {
      "motivazione": (0, _object.getPathStringOrEmptyString)(["datiApplicazione", "motivazione"])(remoteRimozione),
      "rimozioneDataOra": (0, _object.getPathNumberToDateOrNull)(["datiRimozione", "rimozioneDataOra"])(remoteRimozione),
      "rimozioneEffettuata": (0, _object.getPathBooleanOrFalse)(["datiRimozione", "rimozioneEffettuata"])(remoteRimozione),
      "rimozioneNomeCustode": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneNomeCustode"])(remoteRimozione),
      "rimozioneLuogoCustodia": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneLuogoCustodia"])(remoteRimozione),
      "rimozioneComunicatoA": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneComunicatoA"])(remoteRimozione),
      "rimozioneFurto": (0, _object.getPathBooleanOrFalse)(["datiRimozione", "rimozioneFurto"])(remoteRimozione),
      "rimozioneAccertataIl": (0, _object.getPathNumberToDateOrNull)(["datiRimozione", "rimozioneAccertataIl"])(remoteRimozione),
      "rimozioneImporto": (0, _object.getPathNumberOrEmptyString)(["datiRimozione", "rimozioneImporto"])(remoteRimozione),
      "rimozioneAnnotazioni": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneAnnotazioni"])(remoteRimozione),
      "rimozioneDanniCausati": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneDanniCausati"])(remoteRimozione),
      "rimozioneCarroAttrezziId": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneCarroAttrezziId"])(remoteRimozione),
      "rimozioneCarroAttrezzi": (0, _object.getPathStringOrNull)(["datiRimozione", "rimozioneCarroAttrezziId"])(remoteRimozione) ? {
        "aggId": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneCarroAttrezziId"])(remoteRimozione),
        "codiceVeloce": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneCarroAttrezziCodiceVeloce"])(remoteRimozione),
        "descrizione": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneCarroAttrezziDescrizione"])(remoteRimozione)
      } : null,
      "rimozioneDepositoId": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneDepositoId"])(remoteRimozione),
      "rimozioneDeposito": (0, _object.getPathStringOrNull)(["datiRimozione", "rimozioneDepositoId"])(remoteRimozione) ? {
        "aggId": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneDepositoId"])(remoteRimozione),
        "codiceVeloce": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneDepositoCodiceVeloce"])(remoteRimozione),
        "descrizione": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneDepositoDecrizione"])(remoteRimozione),
        "indirizzo": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "rimozioneDepositoIndirizzo"])(remoteRimozione)
      } : null,
      "descrizione": (0, _object.getPathStringOrEmptyString)(["datiRimozione", "descrizione"])(remoteRimozione)
    },
    "datiVeicolo": {
      "veicoloTipo": (0, _object.getPathStringOrNull)(["datiVeicolo", "veicoloTipoId"])(remoteRimozione) ? {
        "aggId": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloTipoId"])(remoteRimozione),
        "descrizione": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloTipoDescrizione"])(remoteRimozione)
      } : null,
      "veicoloStato": (0, _object.getPathStringOrNull)(["datiVeicolo", "veicoloStatoId"])(remoteRimozione) ? {
        "aggId": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloStatoId"])(remoteRimozione),
        "nome": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloStatoNome"])(remoteRimozione),
        "siglaInternazionale": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloStatoSiglaInternazionale"])(remoteRimozione)
      } : null,
      "veicoloTarga": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloTarga"])(remoteRimozione),
      "veicoloMarca": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloMarca"])(remoteRimozione),
      "veicoloModello": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloModello"])(remoteRimozione),
      "veicoloPortata": (0, _object.getPathNumberOrEmptyString)(["datiVeicolo", "veicoloPortata"])(remoteRimozione),
      "veicoloMassa": (0, _object.getPathNumberOrEmptyString)(["datiVeicolo", "veicoloMassa"])(remoteRimozione),
      "veicoloNrTelaio": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloNrTelaio"])(remoteRimozione),
      "veicoloTipoCode": (0, _object.getPathStringOrEmptyString)(["datiVeicolo", "veicoloTipoCode"])(remoteRimozione)
    }
  };
  return rimozione;
};

exports.fromApiRimozione = fromApiRimozione;

const toApiRimozione = values => {
  let rimozione = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    "verbaleAggId": (0, _object.getPathStringOrEmptyString)(["datiVerbale", "verbaleAggId"])(values),
    "veicoloTipoId": (0, _object.getPathNonEmptyStringOrNull)(["datiVeicolo", "veicoloTipo", "aggId"])(values),
    "veicoloStatoId": (0, _object.getPathNonEmptyStringOrNull)(["datiVeicolo", "veicoloStato", "aggId"])(values),
    "veicoloTarga": (0, _object.getPathNonEmptyStringOrNull)(["datiVeicolo", "veicoloTarga"])(values),
    "veicoloMarca": (0, _object.getPathNonEmptyStringOrNull)(["datiVeicolo", "veicoloMarca"])(values),
    "veicoloModello": (0, _object.getPathNonEmptyStringOrNull)(["datiVeicolo", "veicoloModello"])(values),
    "veicoloPortata": (0, _object.getPathNumberOrNull)(["datiVeicolo", "veicoloPortata"])(values),
    "veicoloMassa": (0, _object.getPathNumberOrNull)(["datiVeicolo", "veicoloMassa"])(values),
    "veicoloNrTelaio": (0, _object.getPathNonEmptyStringOrNull)(["datiVeicolo", "veicoloNrTelaio"])(values),
    "luogoAccertamentoCivico": (0, _object.getPathNonEmptyStringOrNull)(["datiLuogoAccertamento", "civico"])(values),
    "luogoAccertamentoKilometro": (0, _object.getPathNonEmptyStringOrNull)(["datiLuogoAccertamento", "kilometro"])(values),
    "luogoAccertamentoComuneId": (0, _object.getPathNonEmptyStringOrNull)(["datiLuogoAccertamento", "comune", "aggId"])(values),
    "luogoAccertamentoStradaId": (0, _object.getPathNonEmptyStringOrNull)(["datiLuogoAccertamento", "strada", "entityId"])(values),
    "luogoAccertamentoTipoRelazioneStradaTipo": (0, _object.getPathNonEmptyStringOrNull)(["datiLuogoAccertamento", "relazioneStrada", "tipoRelazioneStradaTipo"])(values),
    "luogoAccertamentoTipoRelazioneStradaStradaId": (0, _object.getPathNonEmptyStringOrNull)(["datiLuogoAccertamento", "relazioneStrada", "tipoRelazioneStradaStrada", "entityId"])(values),
    "luogoAccertamentoTipoRelazioneDescrizioneFronte": (0, _object.getPathNonEmptyStringOrNull)(["datiLuogoAccertamento", "relazioneStrada", "tipoRelazioneDescrizioneFronte"])(values),
    "luogoAccertamentoTipoRelazioneStradaCivico": (0, _object.getPathNonEmptyStringOrNull)(["datiLuogoAccertamento", "relazioneStrada", "tipoRelazioneStradaCivico"])(values),
    "motivazione": (0, _object.getPathNonEmptyStringOrNull)(["datiRimozione", "motivazione"])(values),
    "rimozioneEffettuata": (0, _object.getPathBooleanOrFalse)(["datiRimozione", "rimozioneEffettuata"])(values),
    "rimozioneDataOra": (0, _object.getPathDateToNumberOrNull)(["datiRimozione", "rimozioneDataOra"])(values),
    "rimozioneAccertataIl": (0, _object.getPathDateToNumberOrNull)(["datiRimozione", "rimozioneAccertataIl"])(values),
    "rimozioneCarroAttrezziId": (0, _object.getPathNonEmptyStringOrNull)(["datiRimozione", "rimozioneCarroAttrezzi", "aggId"])(values),
    "rimozioneDepositoId": (0, _object.getPathNonEmptyStringOrNull)(["datiRimozione", "rimozioneDeposito", "aggId"])(values),
    "rimozioneNomeCustode": (0, _object.getPathNonEmptyStringOrNull)(["datiRimozione", "rimozioneNomeCustode"])(values),
    "rimozioneLuogoCustodia": (0, _object.getPathNonEmptyStringOrNull)(["datiRimozione", "rimozioneLuogoCustodia"])(values),
    "rimozioneFurto": (0, _object.getPathBooleanOrFalse)(["datiRimozione", "rimozioneFurto"])(values),
    "rimozioneImporto": (0, _object.getPathNumberOrNull)(["datiRimozione", "rimozioneImporto"])(values),
    "rimozioneAnnotazioni": (0, _object.getPathNonEmptyStringOrNull)(["datiRimozione", "rimozioneAnnotazioni"])(values),
    "rimozioneDanniCausati": (0, _object.getPathNonEmptyStringOrNull)(["datiRimozione", "rimozioneDanniCausati"])(values),
    "descrizione": (0, _object.getPathNonEmptyStringOrNull)(["datiRimozione", "descrizione"])(values)
  };
  return rimozione;
};

exports.toApiRimozione = toApiRimozione;
const rimozioneEmptyForm = {
  aggId: null,
  datiVerbale: {
    verbaleAggId: null,
    verbaleEtichettaRegistro: "",
    verbaleId: ""
  },
  datiLuogoAccertamento: {
    civico: "",
    kilometro: "",
    kilometroAdj: "",
    comuneId: "",
    comune: null,
    stradaId: "",
    strada: null,
    relazioneStrada: {
      tipoRelazioneStradaTipo: "",
      tipoRelazioneStradaCivico: "",
      tipoRelazioneStradaStradaId: "",
      tipoRelazioneStradaStrada: null,
      tipoRelazioneDescrizioneFronte: ""
    }
  },
  datiApplicazione: {
    motivazioneMancataApplicazioneId: "",
    motivazioneMancataApplicazione: null,
    motivazioneMancataApplicazioneLibera: "",
    motivazione: "",
    applicabile: false
  },
  datiRimozione: {
    rimozioneDataOra: null,
    rimozioneEffettuata: true,
    rimozioneNomeCustode: "",
    rimozioneLuogoCustodia: "",
    rimozioneComunicatoA: "",
    rimozioneFurto: false,
    rimozioneAccertataIl: null,
    rimozioneImporto: "",
    rimozioneAnnotazioni: "",
    rimozioneDanniCausati: "",
    rimozioneCarroAttrezziId: "",
    rimozioneCarroAttrezzi: null,
    rimozioneDepositoId: "",
    rimozioneDeposito: null,
    descrizione: ""
  },
  datiVeicolo: {
    veicoloTipo: null,
    veicoloStato: null,
    veicoloTarga: "",
    veicoloMarca: "",
    veicoloModello: "",
    veicoloPortata: "",
    veicoloMassa: "",
    veicoloNrTelaio: "",
    veicoloTipoCode: "",
    test: ""
  }
};
exports.rimozioneEmptyForm = rimozioneEmptyForm;
const rimozioneFormValidationSchema = Yup.object().shape({
  datiVeicolo: Yup.object().shape({
    veicoloTipo: Yup.object().required('Questo campo è obbligatorio').nullable(),
    veicoloStato: Yup.object().required('Questo campo è obbligatorio').nullable(),
    veicoloTarga: Yup.string().required('Questo campo è obbligatorio')
  }),
  datiLuogoAccertamento: Yup.object().shape({
    comune: Yup.object().required('Questo campo è obbligatorio').nullable(),
    strada: Yup.object().required('Questo campo è obbligatorio').nullable(),
    relazioneStrada: Yup.object().shape({
      tipoRelazioneStradaTipo: Yup.string(),
      tipoRelazioneDescrizioneFronte: Yup.string().when("tipoRelazioneStradaTipo", {
        is: value => value && (value === 'fronte' || value === 'corrispondenza'),
        then: Yup.string().required('Questo campo è obbligatorio'),
        otherwise: Yup.string()
      }),
      tipoRelazioneStradaStrada: Yup.object().when("tipoRelazioneStradaTipo", {
        is: value => value && (value === 'intersezione' || value === 'prossimita'),
        then: Yup.object().required('Questo campo è obbligatorio').nullable(),
        otherwise: Yup.object().nullable()
      })
    })
  }),
  datiRimozione: Yup.object().shape({
    rimozioneCarroAttrezzi: Yup.object().required('Questo campo è obbligatorio').nullable(),
    rimozioneEffettuata: Yup.boolean(),
    rimozioneDataOra: Yup.date().when("rimozioneEffettuata", {
      is: value => value === true,
      then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required('Questo campo è obbligatorio').nullable().typeError('Data non valida'),
      otherwise: Yup.date().nullable().typeError('Data non valida')
    }),
    rimozioneAccertataIl: Yup.date().when("rimozioneEffettuata", {
      is: value => value === true,
      then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required('Questo campo è obbligatorio').nullable().typeError('Data non valida'),
      otherwise: Yup.date().nullable().typeError('Data non valida')
    })
  })
});
exports.rimozioneFormValidationSchema = rimozioneFormValidationSchema;
const rimozioneFieldsNameLabelMap = {
  rimozioneCarroAttrezzi: "Carro attrezzi",
  rimozioneDataOra: "Data rimozione",
  rimozioneAccertataIl: "Data accertamento",
  obbligato: "Obbligato"
};
const rimozioneVerbaleValidationSchema = Yup.object().shape({
  rimozioneCarroAttrezzi: Yup.object().required(_utils.requiredMessage).nullable(),
  rimozioneDataOra: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required('Questo campo è obbligatorio').nullable().typeError('Data non valida'),
  rimozioneAccertataIl: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required('Questo campo è obbligatorio').nullable().typeError('Data non valida'),
  obbligato: Yup.object().required(_utils.requiredMessage).nullable()
});

const mapValidationSuccess = () => ({
  isValid: true,
  errors: []
});

const mapValidationFailure = exception => ({
  isValid: false,
  errors: exception.inner.reduce((acc, val) => [...acc, rimozioneFieldsNameLabelMap[val.path]], [])
}); // validateSync ritorna un'eccezione nel caso fallisca la validazione


const validateRimozioneWithYup = values => rimozioneVerbaleValidationSchema.validateSync(values, {
  abortEarly: false
}); // Ritorna un Result ( https://crocks.dev/docs/crocks/Result.html )


const tryValidateRimozione = (0, _tryCatch.default)(validateRimozioneWithYup); // either prende un Result vuole due funzioni, una che mappi il lato sinistro ( Error ) e uno che mappi il lato destro ( Ok ) ed estrae i valori dal wrapper

const extractResult = (0, _either.default)(mapValidationFailure, mapValidationSuccess); // VALIDAZIONE MANUALE DEL FERMO SEQUESTRO FATTA NEL TAB DEL VERBALE

const validateRimozione = (0, _composeB.default)(extractResult, tryValidateRimozione);
exports.validateRimozione = validateRimozione;