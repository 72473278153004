"use strict";

var _StazioneComandoRoot = _interopRequireDefault(require("./edit/StazioneComandoRoot"));

var _OrganigrammaRoot = _interopRequireDefault(require("./OrganigrammaRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('stazioniComandoTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsStazioniComandoTds',
    role: 'ROLE_STAZIONE_COMANDO_RICERCA',
    url: 'tds/stazioniComando',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _OrganigrammaRoot.default
      }
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('tds.stazioniComando.listaStazioniComando__bcLabel');
      }]
    }
  }, {
    name: 'tdsStazioniComandoTdsEdit',
    role: 'ROLE_STAZIONE_COMANDO_MODIFICA',
    url: 'tds/stazioniComando/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _StazioneComandoRoot.default
      }
    },
    resolve: {
      $label: ['$stateParams', function ($stateParams) {
        return $stateParams.aggId ? 'Modifica stazione comando' : 'Nuova stazione comando';
      }]
    }
  }];
  routesProviderFactory.extend(this);
}