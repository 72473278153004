/**
 * @mixin DirtyModel
 *
 * @description Adds the `$dirty` method to a model`s instances.
 */
'use strict';

angular.module('polcity.models').factory('DirtyModel', ['restmod', function (restmod) {
  //Flat object keys
  var flattenObject = function (ob) {
    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (ob[i] instanceof Object) {
        var flatObject = flattenObject(ob[i]);

        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;
          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }

    return toReturn;
  };

  return restmod.mixin(function () {
    this.on('after-feed', function (_original) {
      // store original information in a model's special property
      var $cmStatus = {};
      this.$each(function (_value, _key) {
        $cmStatus[_key] = angular.copy(_value);
      });
      this.$cmStatus = $cmStatus;
    })
    /**
     * @method $dirty
     * @memberof DirtyModel#
     *
     * @description Retrieves the model changes
     *
     * Property changes are determined using the strict equality operator.
     *
     * IDEA: allow changing the equality function per attribute.
     *
     * If given a property name, this method will return true if property has changed
     * or false if it has not.
     *
     * Called without arguments, this method will return a list of changed property names.
     *
     * _prop accetta la notazione puntata e il controllo è fatto in deep mode per gli oggetti
     *
     * @param {string} _prop Property to query
     * @return {boolean|array} Property state or array of changed properties
     */
    .define('$dirty', function (_prop) {
      var original = this.$cmStatus;

      if (_prop) {
        if (!original) return false;

        if (_.get(original, _prop) instanceof Object) {
          var flattenOriginal = flattenObject(_.get(original, _prop)),
              flattenToCheck = flattenObject(_.get(this, _prop));

          if (flattenOriginal) {
            for (key in flattenOriginal) {
              if (flattenToCheck[key] !== undefined && flattenToCheck[key] !== flattenOriginal[key]) {
                return true;
              }
            }
          }
        } else {
          return _.get(original, _prop) !== _.get(this, _prop);
        }
      } else {
        var changes = [],
            key,
            toCheck = this.$getModel(),
            flattenOriginal = flattenObject(original),
            flattenToCheck = flattenObject(toCheck);

        if (flattenOriginal) {
          for (key in flattenOriginal) {
            if (flattenToCheck[key] !== undefined && flattenToCheck[key] !== flattenOriginal[key]) {
              changes.push(key);
            }
          }
        }

        return changes;
      }
    })
    /**
     * @method $restore
     * @memberof DirtyModel#
     *
     * @description Restores the model's last fetched values.
     *
     * Usage:
     *
     * ```javascript
     * bike = Bike.$create({ brand: 'Trek' });
     * // later on...
     * bike.brand = 'Giant';
     * bike.$restore();
     *
     * console.log(bike.brand); // outputs 'Trek'
     * ```
     *
     * @param {string} _prop If provided, only _prop is restored
     * @return {Model} self
     */
    .define('$restore', function (_prop) {
      return this.$action(function () {
        var original = this.$cmStatus;

        if (_prop) {
          this[_prop] = original[_prop];
        } else {
          for (var key in original) {
            if (original.hasOwnProperty(key)) this[key] = original[key];
          }
        }
      });
    });
  });
}]);