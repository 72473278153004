"use strict";

(function () {
  'use strict';

  angular.module('polcity.filters').filter('renderOptional', function () {
    return function (input, customPlaceholder) {
      return input || customPlaceholder || '?';
    };
  });
})();