"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sanzioniAccessorieListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

var _enums = require("../../../state/enums");

const sanzioniAccessorieListColumns = [{
  field: 'codiceVeloce',
  headerName: 'Codice veloce',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'tipoCodice',
  headerName: 'Tipo',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaTipiCodice") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'decoratore',
  headerName: 'Decoratore',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaDecoratoriSanzioniAccessorie") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'motivazioneMancataApplicazione',
  headerName: 'Motivazione mancata applicazione',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? value.mmaNome : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.sanzioniAccessorieListColumns = sanzioniAccessorieListColumns;