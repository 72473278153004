"use strict";

/**
 * Created by MN 25/01/2019
 */
(function () {
  'use strict';

  angular.module('polcity.acquisizioneDati').component('rilevazioneRowDetail', {
    templateUrl: 'acquisizioneDati/rilevazioni/rilevazioneRowDetail.component.html',
    controller: ['$scope', 'Command', '$http', 'rolesGuardService', RilevazioneRowDetailController],
    controllerAs: 'rilevazioneRowDetailController',
    bindings: {
      item: '<',
      expanded: '<'
    }
  });

  function RilevazioneRowDetailController($scope, Command, $http, rolesGuardService) {
    // TODO: agganciare la chiamata per scaricare le immagini della rilevazione
    var rilevazioneRowDetailController = this;
    $scope.detailData;
    $scope.immagini = [];
    $scope.sectionRoles = ['ROLE_RILEVAZIONE_DETTAGLIO', 'ROLE_GET_IMMAGINI_DA_VALIDARE'];
    $scope.$watch('rilevazioneRowDetailController.expanded', function (newValue, oldValue) {
      if (!rolesGuardService.userHasGroupRoles($scope.sectionRoles)) {
        return;
      }

      if (newValue[rilevazioneRowDetailController.item.aggId] && !$scope.detailData) {
        fetchDetailData({
          aggId: rilevazioneRowDetailController.item.aggId
        }).then(setDetailData);
        fetchImmagini(rilevazioneRowDetailController.item.aggId).then(setImmagini);
      }
    }, true);

    function fetchDetailData(params) {
      return Command.execute('@dettaglioRilevazione', params);
    }

    function fetchImmagini(params) {
      // return Command.execute('@immaginiRilevazione', params);
      return $http.get('bpmn/get-immagini-da-validare'.concat('/', params));
    }

    function setDetailData(remoteData) {
      delete remoteData.tracciatoRilevazione.aggId;
      $scope.detailData = remoteData.tracciatoRilevazione;
    }

    function setImmagini(remoteData) {
      $scope.immagini = remoteData.data;
    }
  }
})();