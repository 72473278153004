"use strict";

/**
 * Created by dan on 20/03/17.
 */
(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name osDateTimePicker
   * @module
   * @description
   *
   * Visualizza un campo data per la selezione della data ed un campo ora, se richiesto, per selezionare l'ora.<br>
   *     Il componente permette di visualizzare o solo la data, o solo l'ora o entrambi.<br>
   *
   * <h2>Attributi</h2>
   * E' possibile utilizzare i seguenti attributi per modificare il comportamento della direttiva.
   *
   * <table class="md-api-table">
   *     <thead>
   *         <tr>
   *             <th>Nome attributo</th>
   *             <th>Tipo</th>
   *             <th>Descrizione</th>
   *          </tr>
   *      </thead>
   *
   *      <tbody>
   *          <tr>
   *              <td>show-date</td>
   *              <td>boolean</td>
   *              <td>Indica se visualizzare o meno il campo date</td>
   *           </tr>
   *          <tr>
   *              <td>show-time</td>
   *              <td>boolean</td>
   *              <td>Indica se visualizzare o meno il campo time</td>
   *           </tr>
   *           <tr>
   *              <td>date-picker</td>
   *              <td>boolean</td>
   *              <td>Indica se visualizzare l'icon calendario ed abilitare il popup per la selezione della data</td>
   *           </tr>
   *           <tr>
   *              <td>time-picker</td>
   *              <td>boolean</td>
   *              <td>Indica se visualizzare l'icona dell'orologio ed abilitare il popup per la selezione dell'ora</td>
   *           </tr>
   *           <tr>
   *              <td>check-min-limit</td>
   *              <td>boolean</td>
   *              <td>Indica se deve essere controllato il limite inferiore della data</td>
   *           </tr>
   *           <tr>
   *              <td>check-max-limit</td>
   *              <td>boolean</td>
   *              <td>Indica se deve essere controllato il limite superiore della data</td>
   *           </tr>
   *           <tr>
   *              <td>ng-min</td>
   *              <td>object | reference</td>
   *              <td>Referenza con la proprietà che contiene la data di confronto per il limite inferiore</td>
   *           </tr>
   *           <tr>
   *              <td>ng-max</td>
   *              <td>object | reference</td>
   *              <td>Referenza con la proprietà che contiene la data di confronto per il limite superiore</td>
   *           </tr>
   *           <tr>
   *              <td>ng-change</td>
   *              <td>expression | reference</td>
   *              <td>Funzione da richiamare quando il valore di data o ora cambiano</td>
   *           </tr>
   *      </tbody>
   *  </table>
   *
   *
   * @usage
   *
   * <hljs lang="html">
   *  <os-date-time-picker
   *          properties="{name: 'dataInizioServizio', resourceEntity: 'profiles'}"
   *          ng-required="true" date-picker="true" time-picker="false" show-time="false"
   *          ng-model="vc.profilo.dataInizioServizio" form="form">
   *  </os-date-time-picker>
   * </hljs>
   *
   **/

  angular.module('osFramework').directive('osDateTimePicker', ['DEFAULT_DATE_FORMAT', '$timeout', '$mdpDatePicker', '$mdpTimePicker', osDateTimePickerDirective]);

  function osDateTimePickerDirective(DEFAULT_DATE_FORMAT, $timeout, $mdpDatePicker, $mdpTimePicker) {
    return {
      restrict: 'E',
      //@issue PMMXVI-246 esponga un unico ng-model + @issue PMMXVI-246 influenzi la
      // validità della form nel quale è contenuto
      require: ['ngModel'],
      templateUrl: 'osFramework/directives/osDateTimePicker/osDateTimePicker.directive.html',
      link: LinkDirective,
      scope: {
        ngDisabled: '=',
        ngRequired: '=',
        inline: '=',
        alignCenter: '=',
        properties: '=',
        checkMinLimit: '=',
        ngMin: '=',
        checkMaxLimit: '=',
        ngMax: '=',
        ngModel: '=',
        form: '='
      }
    };

    function dateFormattedForInputText(date) {
      return moment(date).format(DEFAULT_DATE_FORMAT);
    }

    function timeFormattedForInputText(date) {
      return moment(date).format('HH:mm');
    }

    function dateFormattedForErrorMessage(date, scope) {
      var format = '';

      if (scope.showDate) {
        format = DEFAULT_DATE_FORMAT;
      }

      if (scope.showDate && scope.showTime) {
        format += ' HH:mm';
      } else if (scope.showTime) {
        format = 'HH:mm';
      }

      return moment(date).format(format);
    }

    function parseDate(stringDate) {
      return moment(stringDate, DEFAULT_DATE_FORMAT);
    }

    function parseTime(stringTime) {
      return moment(stringTime, 'HH:mm');
    } // Ritorna true quando da errore


    function testMin(momentDateToCheck, momentDateToCheckAgainst) {
      return momentDateToCheck.isBefore(momentDateToCheckAgainst);
    }

    function testMax(momentDateToCheck, momentDateToCheckAgainst) {
      return momentDateToCheck.isAfter(momentDateToCheckAgainst);
    }

    function LinkDirective(scope, element, attrs, controllers) {
      var ngModelCtrl = R.head(controllers);

      if (R.has('required', attrs)) {
        scope.ngRequired = true;
      }

      scope.internals = {};

      if (scope.properties.flex) {
        scope.flex = scope.properties.flex;
      } //@issue PMMXVI-246 preveda la possibilità di non mostrare il date picker


      scope.datePicker = scope.properties && R.has('datePicker', scope.properties) ? scope.properties.datePicker : R.prop('datePicker', attrs) !== 'false';
      $timeout(function () {
        if (!scope.datePicker && element.find('md-input-container').hasClass('md-icon-left')) {
          element.find('md-input-container').removeClass('md-icon-left');
        }
      }); //@issue PMMXVI-246 preveda la possibilità di non mostrare il time picker

      scope.timePicker = scope.properties && R.has('timePicker', scope.properties) ? scope.properties.timePicker : R.prop('timePicker', attrs) !== 'false'; //@issue PMMXVI-246 preveda la possibilità di non mostrare completamente la sezione time

      scope.showTime = R.prop('showTime', attrs) === 'true'; //@issue PMMXVI-246 preveda la possibilità di non mostrare completamente la sezione data

      scope.showDate = R.prop('showDate', attrs) !== 'false';
      scope.showDatePicker = showDatePicker;
      scope.showTimePicker = showTimePicker;
      scope.updateNgModel = watcher;
      prepareModels(); //@issue PMMXVI-246 preveda un min-date

      if (scope.checkMinLimit) {
        var unwatchMinValidity = scope.$watch('ngMin', watchValidity(testMin, 'minDate'));
        element.on('$destroy', unwatchMinValidity);
      } //@issue PMMXVI-246 preveda un max-date


      if (scope.checkMaxLimit) {
        var unwatchMaxValidity = scope.$watch('ngMax', watchValidity(testMax, 'maxDate'));
        element.on('$destroy', unwatchMaxValidity);
      }

      var unwatchModel = scope.$watch('ngModel', function () {
        if (scope.ngModel) {
          scope.internals.date = dateFormattedForInputText(scope.ngModel);

          if (scope.showTime) {
            scope.internals.time = timeFormattedForInputText(scope.ngModel);
          }

          if (scope.checkMinLimit) {
            watchValidity(testMin, 'minDate')();
          }
        } else {
          scope.internals.time = null;
          scope.internals.date = null;
        }
      });
      element.on('$destroy', unwatchModel);

      function watchValidity(testFunction, validityIndex) {
        return function () {
          if (!ngModelCtrl.$viewValue) {
            return;
          }

          if (scope.showDate && !scope.form[scope.properties.name + '_date']) {
            return;
          }

          if (scope.showTime && !scope.form[scope.properties.name + '_time']) {
            return;
          }

          var momentCompareDate, momentModelDate;

          if (validityIndex === 'minDate' && scope.ngMin) {
            momentCompareDate = moment.unix(scope.ngMin / 1000);
          } else if (validityIndex === 'maxDate' && scope.ngMax) {
            momentCompareDate = moment.unix(scope.ngMax / 1000);
          }

          if (!momentCompareDate) {
            return;
          }

          if (angular.isNumber(ngModelCtrl.$viewValue)) {
            momentModelDate = moment.unix(ngModelCtrl.$viewValue / 1000);
          } else {
            momentModelDate = moment(ngModelCtrl.$viewValue);
          }

          if (momentCompareDate.isValid() && momentModelDate.isValid()) {
            var testResult = testFunction(momentModelDate, momentCompareDate); // just update scope formatted dates for error message when the message must be shown

            if (testResult) {
              if (validityIndex === 'minDate') {
                scope.ngMinDate = dateFormattedForErrorMessage(momentCompareDate, scope);
              } else {
                scope.ngMaxDate = dateFormattedForErrorMessage(momentCompareDate, scope);
              }
            }

            $timeout(function () {
              if (scope.showDate && scope.form[scope.properties.name + '_date']) {
                scope.form[scope.properties.name + '_date'].$setValidity(validityIndex, !testResult);
              }

              if (scope.showTime && scope.form[scope.properties.name + '_time']) {
                scope.form[scope.properties.name + '_time'].$setValidity(validityIndex, !testResult);
              }
            });
          }
        };
      } //@issue PMMXVI-246 trasformi il model da timestamp a date quando riceve un timestamp


      function prepareModels() {
        var receivedDate = new Date();

        if (angular.isNumber(scope.ngModel)) {
          receivedDate = new Date(scope.ngModel);
        } else if (angular.isDate(scope.ngModel)) {
          receivedDate = scope.ngModel;
        } else if (scope.ngModel === null || scope.ngModel === undefined) {
          ngModelCtrl.$render();
          return;
        }

        scope.internals.date = dateFormattedForInputText(receivedDate);

        if (scope.showTime) {
          scope.internals.time = timeFormattedForInputText(receivedDate);
        } //@issue PMMXVI-246 trasformi il model da date a timestamp quando ne viene richiesto il valore
        //GB:
        //ngModelCtrl.$setViewValue(receivedDate.getTime());
        //ngModelCtrl.$render();

      }

      function watcher() {
        scope.form[scope.properties.name + '_date'].$setValidity("invalidDate", true);

        if (scope.internals.date === '' || scope.internals.date === undefined) {
          ngModelCtrl.$setViewValue(null);
          return;
        }

        var date = parseDate(scope.internals.date);

        if (!date.isValid()) {
          scope.form[scope.properties.name + '_date'].$setValidity("invalidDate", false);
          return;
        }

        if (scope.checkMaxLimit) {
          var maxDate = parseDate(scope.ngMax);

          if (testMax(date, maxDate)) {
            ngModelCtrl.$setViewValue(maxDate);
            return;
          }
        }

        if (scope.showTime) {
          scope.form[scope.properties.name + '_time'].$setValidity("invalidTime", true);
          var time = parseTime(scope.internals.time);

          if (!time.isValid()) {
            scope.form[scope.properties.name + '_time'].$setValidity("invalidTime", false);
            return;
          }

          if (time.isValid()) {
            date.minutes(time.minutes());
            date.hours(time.hours());
          } // if inner date is not set default to time's date


          if (!scope.internals.date) {
            scope.internals.date = date;
          }
        }

        ngModelCtrl.$setViewValue(date.toDate().getTime());

        if (attrs.ngChange) {
          scope.$eval(attrs.ngChange);
        }
      }

      function showTimePicker(ev) {
        var time = scope.internals.time ? parseTime(scope.internals.time) : moment();
        $mdpTimePicker(time.toDate(), {
          targetEvent: ev
        }).then(function (selectedDate) {
          //@issue PMMXVI-246 auto-formatti l'ora in stile hh:mm durante l'input dal time picker
          scope.internals.time = timeFormattedForInputText(selectedDate);
        }).then(watcher);
      }

      function showDatePicker(ev) {
        // @issue PMMXVI-274
        if (scope.ngDisabled) {
          return;
        }

        var date = scope.internals.date ? parseDate(scope.internals.date) : moment();
        $mdpDatePicker(date.toDate(), {
          targetEvent: ev,
          minDate: scope.ngMin,
          maxDate: scope.ngMax
        }).then(function (selectedDate) {
          // @issue PMMXVI-246 auto-formatti la data in stile dd/mm/yyyy durante l'input da date picker
          scope.internals.date = dateFormattedForInputText(selectedDate);
        }).then(watcher);
      }
    }
  }
})();