"use strict";

(function () {
  'use strict';

  angular.module('polcity.fermoSequestro').config(['$stateProvider', 'fermoSequestroRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['fermoSequestroRoutes', routesRun]);

  function addRoutes($stateProvider, fermoSequestroRoutesProvider, $translatePartialLoader, AuthProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }
    }, fermoSequestroRoutesProvider.list());
    $translatePartialLoader.addPart('fermoSequestro');
  }

  function routesRun(fermoSequestroRoutes) {
    fermoSequestroRoutes.subscribeUIEvents();
  }
})();