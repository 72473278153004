"use strict";

/**
 * MN 29/10/2019
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaTipoAccertamento', [RicercaTipoAccertamento]);

  function RicercaTipoAccertamento() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaTipoAccertamento'],
      scope: {
        entity: '=?',
        label: '@?',
        onTipoAccertamentoChange: '<?',
        onEntityChange: '&?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'ricercaTipoAccertamentoCtrl',
      templateUrl: 'osFramework/input/ricercaTipoAccertamento/ricercaTipoAccertamento.directive.html',
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this;
      me.groupRoles = ['ROLE_TIPO_ACCERTAMENTO_RICERCA', 'ROLE_TIPO_ACCERTAMENTO_DETTAGLIO'];

      me.getItemText = function (accertamento) {
        var itemText;
        itemText = accertamento.codiceVeloce.toUpperCase().concat(' - ', accertamento.nome.toUpperCase());
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaTipoAccertamentoCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.tipoAccertamentoAggId = null;
          return;
        }

        scope.tipoAccertamentoAggId = viewValue;
      };

      scope.$watch("entity", function (newVal) {
        if (scope.onEntityChange) {
          scope.onEntityChange({
            entity: newVal
          });
        }
      });
      scope.$watch('tipoAccertamentoAggId', function (newVal) {
        if (!newVal) {
          newVal = '';
        }

        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();