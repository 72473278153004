"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _app = require("../../../app");

const useKeycloakAuth = role => _app.keycloakAuth.hasRealmRole(role);

var _default = useKeycloakAuth;
exports.default = _default;