"use strict";

/**
 * Created by MN 15/11/2018
 */
(function () {
  'use strict';

  angular.module('polcity.visure').config(['$stateProvider', 'visureRoutesProvider', addRoutes]).run(['visureRoutes', routesRun]);
  /**
   * @ngdoc type
   * @name setupRoutes
   * @module polcity.visure
   *
   * @param {$stateProvider}
   * @param {visureRoutesProvider}
   *
   * Injects the module routes
   *
   * @return {void}
   */

  function addRoutes($stateProvider, visureRoutesProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;
      $stateProvider.state(stateName, routeDefinition);
    }, visureRoutesProvider.list());
  }

  function routesRun(visureRoutes) {
    visureRoutes.subscribeUIEvents();
  }
})();