"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.motivazioneArchiviazioneAccertamentoValidationSchema = exports.toApiMotivazioneArchiviazioneAccertamento = exports.fromApiMotivazioneArchiviazioneAccertamento = exports.motivazioneArchiviazioneAccertamentoEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const motivazioneArchiviazioneAccertamentoEmptyForm = {
  codiceVeloce: "",
  descrizione: ""
};
exports.motivazioneArchiviazioneAccertamentoEmptyForm = motivazioneArchiviazioneAccertamentoEmptyForm;

const fromApiMotivazioneArchiviazioneAccertamento = remoteMotivazione => {
  const motivazione = { ...(remoteMotivazione.aggId ? {
      aggId: remoteMotivazione.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrNull)(["codiceVeloce"])(remoteMotivazione),
    descrizione: (0, _object.getPathStringOrNull)(["descrizione"])(remoteMotivazione)
  };
  return motivazione;
};

exports.fromApiMotivazioneArchiviazioneAccertamento = fromApiMotivazioneArchiviazioneAccertamento;

const toApiMotivazioneArchiviazioneAccertamento = values => {
  const motivazione = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrNull)(["codiceVeloce"])(values),
    descrizione: (0, _object.getPathStringOrNull)(["descrizione"])(values)
  };
  return motivazione;
};

exports.toApiMotivazioneArchiviazioneAccertamento = toApiMotivazioneArchiviazioneAccertamento;
const motivazioneArchiviazioneAccertamentoValidationSchema = Yup.object().shape({
  codiceVeloce: Yup.string().required(_utils.requiredMessage).nullable(),
  descrizione: Yup.string().required(_utils.requiredMessage).nullable()
});
exports.motivazioneArchiviazioneAccertamentoValidationSchema = motivazioneArchiviazioneAccertamentoValidationSchema;