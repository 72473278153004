"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getErrorText = exports.getError = exports.getTouched = exports.getHelperText = void 0;

var _object = require("../../../object.utils");

const getHelperText = (errors, fieldName) => (0, _object.getPathStringOrEmptyString)([...fieldName.split(".")])(errors);

exports.getHelperText = getHelperText;

const getTouched = (touched, fieldName) => (0, _object.getPathBooleanOrFalse)([...fieldName.split(".")])(touched);

exports.getTouched = getTouched;

const getError = (form, fieldName) => getTouched(form.touched, fieldName) && Boolean(getHelperText(form.errors, fieldName));

exports.getError = getError;

const getErrorText = (form, fieldName) => getTouched(form.touched, fieldName) && getHelperText(form.errors, fieldName);

exports.getErrorText = getErrorText;