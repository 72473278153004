"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.riferimentoComuneValidationSchema = exports.toApiRiferimentoComune = exports.fromApiRiferimentoComune = exports.riferimentoComuneEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const riferimentoComuneEmptyForm = {
  nomeRiferimentoComune: [],
  enteTerritoriale: null,
  stato: null,
  cap: '',
  codiceBelfiore: '',
  codiceISTAT: '',
  capoluogo: false
};
exports.riferimentoComuneEmptyForm = riferimentoComuneEmptyForm;

const fromApiRiferimentoComune = remoteRifCom => {
  const rifCom = { ...(remoteRifCom.riferimentoComuneId ? {
      riferimentoComuneId: remoteRifCom.riferimentoComuneId
    } : {}),
    nomeRiferimentoComune: (0, _object.getPathArrayOrEmptyArray)(["nomeRiferimentoComune"])(remoteRifCom),
    stato: (0, _object.getPathStringOrNull)(["statoId"])(remoteRifCom) ? {
      aggId: (0, _object.getPathStringOrEmptyString)(["stato", "aggId"])(remoteRifCom),
      nome: (0, _object.getPathStringOrEmptyString)(["stato", "nome"])(remoteRifCom)
    } : null,
    enteTerritoriale: (0, _object.getPathStringOrNull)(["enteTerritorialeId"])(remoteRifCom) ? {
      entityId: (0, _object.getPathStringOrEmptyString)(["enteTerritoriale", "entityId"])(remoteRifCom),
      nome: (0, _object.getPathStringOrEmptyString)(["enteTerritoriale", "nome"])(remoteRifCom)
    } : null,
    cap: (0, _object.getPathStringOrEmptyString)(["cap"])(remoteRifCom),
    codiceBelfiore: (0, _object.getPathStringOrEmptyString)(["codiceBelfiore"])(remoteRifCom),
    codiceISTAT: (0, _object.getPathStringOrEmptyString)(["codiceISTAT"])(remoteRifCom),
    capoluogo: (0, _object.getPathBooleanOrFalse)(["capoluogo"])(remoteRifCom),
    nomeRiferimentoComune_transId: (0, _object.getPathStringOrEmptyString)(["nomeRiferimentoComune_transId"])(remoteRifCom) // FIXME: eliminare

  };
  return rifCom;
};

exports.fromApiRiferimentoComune = fromApiRiferimentoComune;

const toApiRiferimentoComune = values => {
  const rifCom = { ...(values.riferimentoComuneId ? {
      riferimentoComuneId: values.riferimentoComuneId
    } : {}),
    dataInserimento: moment().valueOf(),
    enteTerritorialeId: (0, _object.getPathNonEmptyStringOrNull)(["enteTerritoriale", "entityId"])(values),
    nomeRiferimentoComune: (0, _object.getPathArrayOrNull)(["nomeRiferimentoComune"])(values),
    statoId: (0, _object.getPathNonEmptyStringOrNull)(["stato", "aggId"])(values),
    cap: (0, _object.getPathNonEmptyStringOrNull)(["cap"])(values),
    codiceBelfiore: (0, _object.getPathNonEmptyStringOrNull)(["codiceBelfiore"])(values),
    codiceISTAT: (0, _object.getPathNonEmptyStringOrNull)(["codiceISTAT"])(values),
    capoluogo: (0, _object.getPathBooleanOrFalse)(["capoluogo"])(values),
    nomeRiferimentoComune_transId: (0, _object.getPathStringOrEmptyString)(["nomeRiferimentoComune_transId"])(values) // FIXME: eliminare

  };
  return rifCom;
};

exports.toApiRiferimentoComune = toApiRiferimentoComune;
const riferimentoComuneValidationSchema = Yup.object().shape({
  nomeRiferimentoComune: Yup.array().ensure().min(1, _utils.requiredMessage).of(Yup.object().shape({
    traduzione: Yup.string().required(_utils.requiredMessage),
    languageTag: Yup.string().required(_utils.requiredMessage)
  })),
  enteTerritoriale: Yup.object().required(_utils.requiredMessage).nullable(),
  stato: Yup.object().required(_utils.requiredMessage).nullable(),
  cap: Yup.string().required(_utils.requiredMessage)
});
exports.riferimentoComuneValidationSchema = riferimentoComuneValidationSchema;