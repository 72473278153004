"use strict";

var _visuraAciUtils = require("../visuraAciUtils");

var _utils = require("../../utils");

angular.module('polcity.visure').component('visureListDetail', {
  bindings: {
    item: '<'
  },
  templateUrl: 'visure/detail/visureListDetail.component.html',
  controller: 'visureListDetailController',
  controllerAs: 'visureListDetailCtrl'
}).controller('visureListDetailController', ['$state', 'visuraResponseService', VisureListDetailController]);

function VisureListDetailController($state, visuraResponseService) {
  var me = this;
  let isVisuraAci = false;
  me.erroreComunicazione = null;
  me.erroreVisura = [];
  me.dataOraRisposta = '--';
  me.dati = {};
  me.requestProjected = null;
  me.responseProjected = null;
  me.visuraStatus;
  me.erroriVisuraAci = [];
  me.aciComunicazioneErrorMessage = null;

  me.$onInit = function () {
    me.requestProjected = me.item.requestProjected ? JSON.parse(me.item.requestProjected) : null;
    me.responseProjected = me.item.responseProjected ? JSON.parse(me.item.responseProjected) : null;

    if ((0, _visuraAciUtils.isSorgeteDatiACI)(me.requestProjected.sorgenteDati)) {
      isVisuraAci = true;
      me.esito = (0, _visuraAciUtils.getEsito)(me.responseProjected.dati.datiRisposta[0].esito);
      me.datiRichiesta = (0, _visuraAciUtils.getDatiRichiesta)(me.responseProjected.dati.datiRisposta[0].datiRichiesta);

      if ((0, _visuraAciUtils.isVisuraOnError)(me.responseProjected.dati.datiRisposta[0].esito.descrizioneEsito)) {
        me.erroriVisuraAci = (0, _visuraAciUtils.getEsitoInErroreMessage)(me.responseProjected.dati.datiRisposta[0].errore);
      } else if ((0, _visuraAciUtils.isVisuraOnComunicazioneError)(me.responseProjected.comunicazione)) {
        me.aciComunicazioneErrorMessage = me.responseProjected.erroreComunicazione;
      } else {
        me.dati = (0, _utils.traverseJson)(me.responseProjected.dati.datiRisposta[0].datiRecuperati[0], formatDateField);
      }
    }

    if (me.responseProjected && !(0, _visuraAciUtils.isSorgeteDatiACI)(me.requestProjected.sorgenteDati)) {
      me.dati = visuraResponseService.formatResponse(me.responseProjected);
    }

    if (me.dati && me.dati.errore && !(0, _visuraAciUtils.isSorgeteDatiACI)(me.requestProjected.sorgenteDati)) {
      if (typeof me.dati.errore === 'string') {
        me.erroreComunicazione = me.dati.errore;
      } else {
        me.erroreVisura = me.dati.errore;
      }
    }

    setVisuraStatus();
  };

  me.navigateToSuperDetail = function () {
    $state.go('navigation.visure.superDetail', {
      aggId: me.requestProjected.aggId,
      tipoOperazione: me.requestProjected.tipoOperazione,
      dati: me.dati,
      isVisuraAci: isVisuraAci
    });
  };

  function setVisuraStatus() {
    var status = 'visuraAttesaDatiBg',
        visuraStatusEl = $('#visuraStatusEl');

    if (R.keys(me.dati).length && !me.erroreVisura.length && !me.erroreComunicazione) {
      status = 'visuraOkBg';
    } else if (me.erroreVisura.length || me.erroreComunicazione) {
      status = 'visuraKoBg';
    }

    visuraStatusEl.addClass(status);
  }

  const formatDateField = (obj, key) => {
    return key.indexOf("data") > -1 ? obj[key] ? moment(obj[key]).format('LLL') : '--' : obj[key];
  };
}