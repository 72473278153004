"use strict";

/**
 * Created by dan on 13/01/17.
 */
(function () {
  'use strict';
  /**
   * @ngdoc type
   * @name modalPresenter
   * @module
   *
   * @description
   * Exposes a method to create a dialog, allows you to abstract the `$mdDialog`.
   */

  angular.module('polcity.services').service('modalPresenter', ['$mdDialog', '$translate', '$q', modalPresenter]);

  function modalPresenter($mdDialog, $translate, $q) {
    return {
      /**
       * @ngdoc method
       * @name modalPresenter#create
       *
       * @description
       * Will return a function to be called when you want to show a dialog.
       *
       * @param {string} controllerName
       * @param {string} controllerAs
       * @param {string} templateUrl the path to the template you want to be showed as modal
       * @param {Function} executeAfter a callback that will be called when the dialog gets dismissed
       * @param {object} locals locals to be passed to dialog controller. Ex `modalPresenter.create(..,..,..,.., {a: 'a', b: 'b'}) --> function controller(dep1, dep2, a, b) {...}`
       *
       * @returns {Function}
       */
      create: presentModal,
      warn: createWarn,
      info: createInfo
    };

    function presentModal(controllerName, controllerAs, templateUrl, executeOnSuccess, locals, executeOnReject) {
      return function (ev) {
        $mdDialog.show({
          controller: controllerName,
          templateUrl: templateUrl,
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: false,
          locals: locals,
          controllerAs: controllerAs,
          multiple: true
        }).then(executeOnSuccess).catch(executeOnReject || angular.noop);
      };
    }

    function createInfo(contentTranslations, ev) {
      return customThemeConfirm(contentTranslations, ev, 'infoTheme');
    }
    /**
     * @ngdoc method
     * @name modalPresente#createWarn
     * @param {array} contentTranslations The first element is the title, the second the content of the warning, the third the label of the action button
     */


    function createWarn(contentTranslations, ev) {
      return customThemeConfirm(contentTranslations, ev, 'warnTheme');
    }

    function customThemeConfirm(contentTranslations, ev, theme) {
      var deferredWarningDialog = $q.defer();
      contentTranslations.push('actions.undo__buttonText');
      $translate(contentTranslations).then(R.values).then(function (translations) {
        var confirm = $mdDialog.confirm({
          theme: theme,
          focusOnOpen: true,
          multiple: true
        }).title(translations[0]).htmlContent(translations[1]).targetEvent(ev).ok(translations[2]).cancel(translations[3]);
        $mdDialog.show(confirm).then(deferredWarningDialog.resolve).catch(deferredWarningDialog.reject);
      }).catch(deferredWarningDialog.reject);
      return deferredWarningDialog.promise;
    }

    ;
  }
})();