"use strict";

(function () {
  'use strict';

  angular.module('osTranslatable').filter('translatable', ['DEFAULT_LOCALE', function (DEFAULT_LOCALE) {
    function translate(input) {
      var defLocale, traduzione; //FIXME: rimuovere questa gestione correggendo i codici locale dei partial

      if (DEFAULT_LOCALE == "it") {
        defLocale = "it_IT";
      } else if (DEFAULT_LOCALE == "de") {
        defLocale = "de_IT";
      }

      if (!input) {
        return null;
      }

      if (input instanceof Array && input.length > 0) {
        traduzione = R.find(R.propEq("languageTag", defLocale))(input).traduzione || "--";
      } else {
        traduzione = input;
      }

      return traduzione;
    }

    return translate;
  }]);
})();