"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tipiBollettariListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

var _utils = require("../../../utils");

const tipiBollettariListColumns = [{
  field: 'tipoAccertamento',
  headerName: 'Tipo accertamento',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _utils.capitalize)(value.tipoAccertamentoNome) : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'numero',
  headerName: 'Numero',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'bolletteDal',
  headerName: 'Bollette dal',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'bolletteAl',
  headerName: 'Bollette al',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'consegnato',
  headerName: 'Consegnato il',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridDateColumn
}, {
  field: 'restituito',
  headerName: 'Restituito il',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridDateColumn
}];
exports.tipiBollettariListColumns = tipiBollettariListColumns;