"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toApiLotto = exports.fromApiLotto = exports.lottoEmptyForm = void 0;

var _object = require("../../object.utils");

const lottoEmptyForm = {
  aggId: null,
  tipoLotto: '',
  destinazione: '',
  lottoVuoto: false,
  annotazioni: '',
  statoLotto: '',
  numeroLotto: '',
  utenteCreatore: '',
  nomeLotto: '',
  dataCreazioneLotto: null,
  dataInoltro: null,
  dataSpedizione: null,
  preRuoloFilters: {
    rangeDataVerbale: {
      startDate: null,
      endDate: null
    },
    tipoAccertamento: null,
    statiVerbale: null
  },
  statisticheLotto: {
    numeroVerbaliSelezionatiPerLotto: '',
    numeroSpedizioniRicavateDaVerbaliSelezionati: '',
    numeroSpedizioniConEsito: '',
    numeroSpedizioniNotificate: ''
  }
};
exports.lottoEmptyForm = lottoEmptyForm;

const fromApiLotto = remoteLotto => {
  let lotto = { ...(remoteLotto.aggId ? {
      aggId: remoteLotto.aggId
    } : {}),
    tipoLotto: (0, _object.getPathStringOrEmptyString)(["tipoLotto"])(remoteLotto),
    destinazione: (0, _object.getPathStringOrEmptyString)(["destinazione"])(remoteLotto),
    lottoVuoto: (0, _object.getPathBooleanOrFalse)(["lottoVuoto"])(remoteLotto),
    annotazioni: (0, _object.getPathStringOrEmptyString)(["annotazioni"])(remoteLotto),
    statoLotto: (0, _object.getPathStringOrEmptyString)(["statoLotto"])(remoteLotto),
    numeroLotto: (0, _object.getPathStringOrEmptyString)(["numeroLotto"])(remoteLotto),
    utenteCreatore: (0, _object.getPathStringOrEmptyString)(["utenteCreatore"])(remoteLotto),
    nomeLotto: (0, _object.getPathStringOrEmptyString)(["nomeLotto"])(remoteLotto),
    dataCreazioneLotto: (0, _object.getPathNumberOrNull)(['dataCreazioneLotto'])(remoteLotto),
    dataInoltro: (0, _object.getPathNumberOrNull)(['dataInoltro'])(remoteLotto),
    dataSpedizione: (0, _object.getPathNumberOrNull)(['dataSpedizione'])(remoteLotto),
    statisticheLotto: (0, _object.getPathObjectOrEmptyObject)(['statisticheLotto'])(remoteLotto),
    aperturaPagoPaOK: (0, _object.getPathBooleanOrFalse)(['aperturaPagoPaOK'])(remoteLotto)
  };
  return lotto;
};

exports.fromApiLotto = fromApiLotto;

const toApiLotto = values => {
  let lotto = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    tipoLotto: (0, _object.getPathNonEmptyStringOrNull)(["tipoLotto"])(values),
    destinazione: (0, _object.getPathNonEmptyStringOrNull)(["destinazione"])(values),
    lottoVuoto: (0, _object.getPathBooleanOrFalse)(["lottoVuoto"])(values),
    annotazioni: (0, _object.getPathNonEmptyStringOrNull)(["annotazioni"])(values),
    ...(values.tipoLotto === 'lettera_preruolo' ? {
      preRuoloFilters: {
        rangeDataVerbale: {
          startDate: (0, _object.getPathNumberOrNull)(['preRuoloFilters', 'rangeDataVerbale', 'startDate'])(values),
          endDate: (0, _object.getPathNumberOrNull)(['preRuoloFilters', 'rangeDataVerbale', 'endDate'])(values)
        },
        tipoAccertamentoAggId: (0, _object.getPathStringOrNull)(['preRuoloFilters', 'tipoAccertamentoAggId'])(values),
        statiVerbale: (0, _object.getPathArrayOrNull)(['preRuoloFilters', 'statiVerbale'])(values)
      }
    } : {})
  };
  return lotto;
};

exports.toApiLotto = toApiLotto;