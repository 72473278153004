"use strict";

/**
 * Created by dan on 19/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.navigation').provider('navigationRoutes', [navigationRoutes]);

  function navigationRoutes() {
    var routes = [{
      isBase: true,
      isLogin: false,
      name: 'navigation',
      url: '/',
      templateUrl: 'navigation/navigation.view.html',
      controller: 'navigationController',
      controllerAs: 'navigation',
      abstract: true
    }];
    this.$get = angular.noop;
    this.list = getRoutes;

    function getRoutes() {
      return routes;
    }
  }
})();