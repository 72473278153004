"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.footerFromXml = exports.bodyFromXml = exports.headerFromXml = exports.footerToXml = exports.bodyToXml = exports.headerToXml = exports.xmlToFooter = exports.xmlToBody = exports.xmlToHeader = exports.variableToWysiwyg = void 0;
const delimiters = {
  START: '«',
  END: '»'
};
const tagnames = {
  HEADER: 'xmlHeader',
  BODY: 'xmlBody',
  FOOTER: 'xmlFooter'
};

const wrapWithDelimiters = value => `${delimiters.START}${value}${delimiters.END}`;

const variableToWysiwyg = ({
  value,
  type,
  conditional
}) => {
  let result = wrapWithDelimiters(value);

  if (type === "array") {
    result = `${wrapWithDelimiters(`CICLA ${value} CONTESTO`)}${wrapWithDelimiters('FINECICLA')}`;
  } else if (conditional) {
    result = `${wrapWithDelimiters(`SE ${value} ALLORA`)}${result}${wrapWithDelimiters('FINESE')}`;
  }

  return result;
};

exports.variableToWysiwyg = variableToWysiwyg;

const xmlToHeader = xml => xml ? getTagValueFromXml(tagnames.HEADER, xml) : "";

exports.xmlToHeader = xmlToHeader;

const xmlToBody = xml => xml ? getTagValueFromXml(tagnames.BODY, xml) : "";

exports.xmlToBody = xmlToBody;

const xmlToFooter = xml => xml ? getTagValueFromXml(tagnames.FOOTER, xml) : "";

exports.xmlToFooter = xmlToFooter;

const headerToXml = xml => xml ? setTagValueFromXml(tagnames.HEADER, xml) : "";

exports.headerToXml = headerToXml;

const bodyToXml = xml => xml ? setTagValueFromXml(tagnames.BODY, xml) : "";

exports.bodyToXml = bodyToXml;

const footerToXml = xml => xml ? setTagValueFromXml(tagnames.FOOTER, xml) : "";

exports.footerToXml = footerToXml;

const headerFromXml = xml => xml ? getTagFromXml(tagnames.HEADER, xml) : "";

exports.headerFromXml = headerFromXml;

const bodyFromXml = xml => xml ? getTagFromXml(tagnames.BODY, xml) : "";

exports.bodyFromXml = bodyFromXml;

const footerFromXml = xml => xml ? getTagFromXml(tagnames.FOOTER, xml) : ""; // Recupera tutto il tag da una stringa


exports.footerFromXml = footerFromXml;

const getTagFromXml = (tagName, xml) => xml.lastIndexOf(`<${tagName}>`) != -1 ? xml.substring(xml.lastIndexOf(`<${tagName}>`), xml.lastIndexOf(`</${tagName}>`) + tagName.length + 3) : ""; // Recupera il testo contenuto in un tag da una stringa


const getTagValueFromXml = (tagName, xml) => xml.lastIndexOf(`<${tagName}>`) != -1 ? xml.substring(xml.lastIndexOf(`<${tagName}>`) + tagName.length + 2, xml.lastIndexOf(`</${tagName}>`)) : "";

const setTagValueFromXml = (tagname, xml) => `<${tagname}>${xml}</${tagname}>`;