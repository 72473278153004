"use strict";

/**
 * Created by dan on 01/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity').run(['uiToastListener', '$rootScope', '$translate', listenUIEvents]);

  function listenUIEvents(uiToastListener, $rootScope, $translate) {
    uiToastListener.beginListening();
    $rootScope.$on('$translatePartialLoaderStructureChanged', function () {
      $translate.refresh();
    });
  }
})();