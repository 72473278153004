"use strict";

/**
 * Created by gb on 17/09/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.ricorso.ritentaRicorso').controller('RicorsoRitentaRicorsoController', ['$scope', '$state', '$stateParams', 'OSNotification', RicorsoRitentaRicorsoController]);

  function RicorsoRitentaRicorsoController($scope, $state, $stateParams, OSNotification) {
    var me = this;
    me.isSaving = false;
    me.ritenta = {};

    me.$onInit = function () {
      me.ricorso.$on("before-ritenta-ricorso", function (res) {
        me.isSaving = true;
      });
      me.ricorso.$on("after-ritenta-ricorso", function (res) {
        me.isSaving = false;
        notifyUser("success", "ricorso.ritentaRicorsoSuccess");
        me.goBack();
      });
      me.ricorso.$on("after-ritenta-ricorso-error", function (res) {
        me.isSaving = false;
        notifyUser("error", "ricorso.ritentaRicorsoError");
        me.goBack();
      });
    };

    me.onAnnulla = function () {
      me.goBack();
    };

    me.onSalva = function () {
      me.ricorso.$ritentaRicorso(me.ritenta);
    };

    me.goBack = function () {
      $state.go("^", {
        aggId: $stateParams.aggId
      });
    };

    function notifyUser(type, msg) {
      OSNotification[type]({
        translationIndex: msg
      });
    }

    ;
  }
})();