"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contatoreProtocolloValidationSchema = exports.toApiContatoreProtocollo = exports.fromApiContatoreProtocollo = exports.contatoreProtocolloEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const contatoreProtocolloEmptyForm = {
  tipoProtocollo: "",
  anno: "",
  prossimoNumero: ""
};
exports.contatoreProtocolloEmptyForm = contatoreProtocolloEmptyForm;

const fromApiContatoreProtocollo = values => {
  const contatore = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    tipoProtocollo: (0, _object.getPathStringOrEmptyString)(["tipoProtocollo"])(values),
    anno: (0, _object.getPathNumberOrEmptyString)(["anno"])(values),
    prossimoNumero: (0, _object.getPathNumberOrEmptyString)(["prossimoNumero"])(values)
  };
  return contatore;
};

exports.fromApiContatoreProtocollo = fromApiContatoreProtocollo;

const toApiContatoreProtocollo = values => {
  const contatore = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    tipoProtocollo: (0, _object.getPathStringOrEmptyString)(["tipoProtocollo"])(values),
    anno: (0, _object.getPathNumberOrEmptyString)(["anno"])(values),
    prossimoNumero: (0, _object.getPathNumberOrEmptyString)(["prossimoNumero"])(values)
  };
  return contatore;
};

exports.toApiContatoreProtocollo = toApiContatoreProtocollo;
const contatoreProtocolloValidationSchema = Yup.object().shape({
  tipoProtocollo: Yup.string().required(_utils.requiredMessage),
  anno: Yup.number().min((0, _moment.default)().year(), "Inserire una data valida").required(_utils.requiredMessage),
  prossimoNumero: Yup.number().min(0, "Questo campo deve essere maggiore di zero").required(_utils.requiredMessage)
});
exports.contatoreProtocolloValidationSchema = contatoreProtocolloValidationSchema;