"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleFailure = exports.handleModificaFermoSequestroSuccess = exports.handleAnnullaRestituzioneVeicoloFermoSequestroSuccess = exports.handleRestituzioneVeicoloFermoSequestroSuccess = exports.handleCancellaFermoSequestroSuccess = exports.restituzioneFermoSequestroValidationSchema = exports.fermoSequestroValidationSchema = exports.restituzioneFermoSequestroEmptyForm = exports.fSEmptyForm = exports.toApiRestituzioneVeicoloFermoSequestro = exports.toApiFermoSequestro = exports.fromApiFemoSequestro = void 0;

var _object = require("../../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const fromApiFemoSequestro = remoteFermoSequestro => {
  const fermoSequestro = { ...(remoteFermoSequestro.aggId ? {
      aggId: remoteFermoSequestro.aggId
    } : {}),
    verbaleId: (0, _object.getPathStringOrEmptyString)(["verbaleId"])(remoteFermoSequestro),
    numeroVerbale: (0, _object.getPathStringOrEmptyString)(["numeroVerbale"])(remoteFermoSequestro),
    numeroRegistro: (0, _object.getPathStringOrEmptyString)(["numeroRegistro"])(remoteFermoSequestro),
    numeroProtocollo: (0, _object.getPathStringOrEmptyString)(["numeroProtocollo"])(remoteFermoSequestro),
    tipo: (0, _object.getPathStringOrEmptyString)(["tipo"])(remoteFermoSequestro),
    stato: (0, _object.getPathStringOrEmptyString)(["stato"])(remoteFermoSequestro),
    dataOperazione: (0, _object.getPathNumberToDateOrNull)(["dataOperazione"])(remoteFermoSequestro),
    dataScadenza: (0, _object.getPathNumberToDateOrNull)(["dataScadenza"])(remoteFermoSequestro),
    targa: (0, _object.getPathStringOrEmptyString)(["targa"])(remoteFermoSequestro),
    veicoloKm: (0, _object.getPathNumberOrEmptyString)(["veicoloKm"])(remoteFermoSequestro),
    veicoloStato: (0, _object.getPathStringOrEmptyString)(["veicoloStato"])(remoteFermoSequestro),
    veicoloOggettiRinvenuti: (0, _object.getPathStringOrEmptyString)(["veicoloOggettiRinvenuti"])(remoteFermoSequestro),
    trasportoACuraDi: (0, _object.getPathStringOrEmptyString)(["trasportoACuraDi"])(remoteFermoSequestro),
    trasportoEventualiDanni: (0, _object.getPathStringOrEmptyString)(["trasportoEventualiDanni"])(remoteFermoSequestro),
    affidatario: (0, _object.getPathStringOrEmptyString)(["affidatario"])(remoteFermoSequestro),
    custode: (0, _object.getPathStringOrEmptyString)(["custode"])(remoteFermoSequestro),
    luogoCustodia: (0, _object.getPathStringOrEmptyString)(["luogoCustodia"])(remoteFermoSequestro),
    deposito: (0, _object.getPathObjectOrNull)(["deposito"])(remoteFermoSequestro),
    restituzione: (0, _object.getPathObjectOrNull)(["restituzione"])(remoteFermoSequestro)
  };
  return fermoSequestro;
};

exports.fromApiFemoSequestro = fromApiFemoSequestro;

const toApiFermoSequestro = values => {
  const fermoSequestro = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    dataOperazione: (0, _object.getPathDateToNumberOrNull)(["dataOperazione"])(values),
    targa: (0, _object.getPathStringOrEmptyString)(["targa"])(values),
    veicoloKm: (0, _object.getPathNumberOrNull)(["veicoloKm"])(values),
    veicoloStato: (0, _object.getPathStringOrEmptyString)(["veicoloStato"])(values),
    veicoloOggettiRinvenuti: (0, _object.getPathStringOrEmptyString)(["veicoloOggettiRinvenuti"])(values),
    affidatario: (0, _object.getPathStringOrEmptyString)(["affidatario"])(values),
    trasportoACuraDi: (0, _object.getPathStringOrEmptyString)(["trasportoACuraDi"])(values),
    trasportoEventualiDanni: (0, _object.getPathStringOrEmptyString)(["trasportoEventualiDanni"])(values),
    luogoCustodia: (0, _object.getPathStringOrEmptyString)(["luogoCustodia"])(values),
    dataScadenza: (0, _object.getPathDateToNumberOrNull)(["dataScadenza"])(values),
    custode: (0, _object.getPathStringOrNull)(["custode"])(values),
    depositoId: (0, _object.getPathStringOrNull)(["deposito", "aggId"])(values),
    intestatarioVeicoloId: (0, _object.getPathStringOrNull)(["intestatarioVeicolo", "aggId"])(values)
  };
  return fermoSequestro;
};

exports.toApiFermoSequestro = toApiFermoSequestro;

const toApiRestituzioneVeicoloFermoSequestro = values => {
  const restituzioneVeicoloFermoSequestro = {
    aggId: (0, _object.getPathStringOrNull)(["aggId"])(values),
    dataRestituzione: (0, _object.getPathDateToNumberOrNull)(["dataRestituzione"])(values),
    decretoData: (0, _object.getPathDateToNumberOrNull)(["decretoData"])(values),
    // SOLO SE SEQUESTRO
    decretoNumero: (0, _object.getPathStringOrNull)(["decretoNumero"])(values),
    // SOLO SE SEQUESTRO
    decretoSoggetto: (0, _object.getPathStringOrNull)(["decretoSoggetto"])(values),
    // SOLO SE SEQUESTRO
    consegnatarioNome: (0, _object.getPathStringOrNull)(["consegnatarioNome"])(values),
    consegnatarioLuogoNascita: (0, _object.getPathStringOrNull)(["consegnatarioLuogoNascita"])(values),
    consegnatarioProvinciaNascita: (0, _object.getPathStringOrNull)(["consegnatarioProvinciaNascita"])(values),
    consegnatarioDataNascita: (0, _object.getPathDateToNumberOrNull)(["consegnatarioDataNascita"])(values),
    consegnatarioIndirizzoResidenza: (0, _object.getPathStringOrNull)(["consegnatarioIndirizzoResidenza"])(values),
    consegnatarioLuogoResidenza: (0, _object.getPathStringOrNull)(["consegnatarioLuogoResidenza"])(values),
    consegnatarioProvinciaResidenza: (0, _object.getPathStringOrNull)(["consegnatarioProvinciaResidenza"])(values),
    consegnatarioCapResidenza: (0, _object.getPathStringOrNull)(["consegnatarioCapResidenza"])(values)
  };
  return restituzioneVeicoloFermoSequestro;
};

exports.toApiRestituzioneVeicoloFermoSequestro = toApiRestituzioneVeicoloFermoSequestro;
const fSEmptyForm = {
  dataOperazione: null,
  dataScadenza: null,
  intestatario: null,
  targa: "",
  veicoloKm: "",
  veicoloStato: "",
  veicoloOggettiRinvenuti: "",
  affidatario: "",
  trasportoACuraDi: "",
  trasportoEventualiDanni: "",
  custode: "",
  luogoCustodia: "",
  deposito: null
};
exports.fSEmptyForm = fSEmptyForm;
const restituzioneFermoSequestroEmptyForm = {
  dataRestituzione: null,
  agente: null,
  decretoData: null,
  // SOLO SE SEQUESTRO
  decretoNumero: "",
  // SOLO SE SEQUESTRO
  decretoSoggetto: "",
  // SOLO SE SEQUESTRO
  consegnatarioNome: "",
  consegnatarioLuogoNascita: "",
  consegnatarioProvinciaNascita: "",
  consegnatarioDataNascita: null,
  consegnatarioIndirizzoResidenza: "",
  consegnatarioLuogoResidenza: "",
  consegnatarioProvinciaResidenza: "",
  consegnatarioCapResidenza: ""
};
exports.restituzioneFermoSequestroEmptyForm = restituzioneFermoSequestroEmptyForm;
const fermoSequestroValidationSchema = Yup.object().shape({
  dataRestituzione: Yup.number().required(_utils.requiredMessage).nullable(),
  agente: Yup.object().required(_utils.requiredMessage).nullable(),
  consegnatarioNome: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioLuogoNascita: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioProvinciaNascita: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioDataNascita: Yup.number().required(_utils.requiredMessage).nullable(),
  consegnatarioIndirizzoResidenza: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioLuogoResidenza: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioProvinciaResidenza: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioCapResidenza: Yup.string().required(_utils.requiredMessage).nullable()
});
exports.fermoSequestroValidationSchema = fermoSequestroValidationSchema;
const restituzioneFermoSequestroValidationSchema = Yup.object().shape({
  dataRestituzione: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  consegnatarioNome: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioLuogoNascita: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioProvinciaNascita: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioDataNascita: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  consegnatarioIndirizzoResidenza: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioLuogoResidenza: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioProvinciaResidenza: Yup.string().required(_utils.requiredMessage).nullable(),
  consegnatarioCapResidenza: Yup.string().required(_utils.requiredMessage).nullable(),
  decretoData: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').nullable().typeError('Data non valida')
});
exports.restituzioneFermoSequestroValidationSchema = restituzioneFermoSequestroValidationSchema;

const handleCancellaFermoSequestroSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: "Fermo sequestro cancellato",
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleCancellaFermoSequestroSuccess = handleCancellaFermoSequestroSuccess;

const handleRestituzioneVeicoloFermoSequestroSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: "Restituzione salvata",
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleRestituzioneVeicoloFermoSequestroSuccess = handleRestituzioneVeicoloFermoSequestroSuccess;

const handleAnnullaRestituzioneVeicoloFermoSequestroSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: "Restituzione annullata",
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleAnnullaRestituzioneVeicoloFermoSequestroSuccess = handleAnnullaRestituzioneVeicoloFermoSequestroSuccess;

const handleModificaFermoSequestroSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: "Fermo sequestro modificato",
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleModificaFermoSequestroSuccess = handleModificaFermoSequestroSuccess;

const handleFailure = messenger => ({
  response,
  onFailure
}) => {
  messenger({
    type: 'warning',
    message: `Attenzione! ${response.message}`,
    severity: 'warning'
  });

  if (onFailure) {
    onFailure(response);
  }
};

exports.handleFailure = handleFailure;