"use strict";

var _MarcheModelliVeicoliEditRoot = _interopRequireDefault(require("./edit/MarcheModelliVeicoliEditRoot"));

var _MarcheModelliList = _interopRequireDefault(require("./MarcheModelliList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(function () {
  'use strict';

  angular.module('polcity.tds').provider('marcheModelliVeicoliTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

  function routeProvider(routesProviderFactory) {
    this.routes = [{
      name: 'tdsMarcheModelliVeicoli',
      url: 'tds/marcheModelliVeicoli',
      role: 'ROLE_MARCA_MODELLO_VEICOLO_RICERCA',
      breadcrumbs: {
        isBase: false,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: _MarcheModelliList.default
        }
      },
      resolve: {
        $label: () => "Lista marche e modelli veicoli"
      }
    }, {
      name: 'tdsMarcheModelliVeicoliEdit',
      url: 'tds/marcheModelliVeicoli/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
      //uuid regex
      role: 'ROLE_MARCA_MODELLO_VEICOLO_MODIFICA',
      params: {
        aggId: null
      },
      breadcrumbs: {
        isBase: false,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: _MarcheModelliVeicoliEditRoot.default
        }
      },
      resolve: {
        $label: () => "Edita marca modello veicolo"
      }
    }];
    routesProviderFactory.extend(this);
  }
})();