"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contatoriProtocolloListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const contatoriProtocolloListColumns = [{
  field: 'tipoProtocollo',
  headerName: 'Tipo',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'anno',
  headerName: 'Anno',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'prossimoNumero',
  headerName: 'Prossimo numero',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.contatoriProtocolloListColumns = contatoriProtocolloListColumns;