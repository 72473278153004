"use strict";

/**
 * Created by MN 20/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('CondizioneLuceListController', ['$translate', '$tdsApiService', '$tdsGlobalBehaviors', CondizioneLuceListController]);

  function CondizioneLuceListController($translate, $tdsApiService, $tdsGlobalBehaviors) {
    var condizioneLuceListCtrl = this,
        searchCommand = '@ricercaCondizioniLuce',
        deleteCommand = '@eliminaCondizioneLuce',
        editRoute = 'navigation.condizioneLuceTdsEdit',
        entity = 'condizione di luce',
        editRole = 'ROLE_INDICE_VALORE',
        deleteRole = 'ROLE_INDICE_VALORE',
        editCallback = function (item) {
      $tdsGlobalBehaviors.redirectTo(editRoute, {
        aggId: item.aggId,
        item: item
      });
    },
        deleteCallback = function (items) {
      var el = items.ngSelectedRowModel ? items.ngSelectedRowModel : items;
      el = el instanceof Array ? el : [el];
      $tdsApiService.deleteCommand(deleteCommand, el).then(function () {
        loadElementi();
      });
    },
        defConfig = $tdsGlobalBehaviors.getDefaultConf(editRoute, entity); //Mergio le configurazioni di default nel controller


    _.merge(condizioneLuceListCtrl, defConfig);

    condizioneLuceListCtrl.addRole = 'ROLE_INDICE_VALORE';
    /**
     * @property
     * Configurazione delle colonne della tabella
     * @type {*[]}
     */

    condizioneLuceListCtrl.column = [{
      text: 'Descrizione',
      dataIndex: 'descrizione'
    }, {
      text: 'Data inizio validita',
      dataIndex: 'dataInizioVal',
      renderer: function (item) {
        return $tdsGlobalBehaviors.formatDate(item.dataInizioVal);
      }
    }, {
      text: 'Data fine validita',
      dataIndex: 'dataFineVal',
      renderer: function (item) {
        return $tdsGlobalBehaviors.formatDate(item.dataFineVal);
      }
    }, {
      text: 'Codice ISTAT',
      dataIndex: 'codiceIstat'
    }];
    /**
     * @property
     * Query params
     * @type {{limit: number, page: number, order: string}}
     */

    condizioneLuceListCtrl.query = {
      l: 20,
      p: 1
    };
    /**
     * @property
     * Configurazione delle action column di una riga della tabella
     * @type {*[]}
     */

    condizioneLuceListCtrl.actionColumn = $tdsGlobalBehaviors.getActionColumnDefaultConfig(editRole, deleteRole, editCallback, deleteCallback);
    /**
     * @property
     * Confgirazione delle bulk action della tabella
     * @type {*[]}
     */

    condizioneLuceListCtrl.bulkActions = $tdsGlobalBehaviors.getBulkActionsDefaultConfig(deleteRole, deleteCallback);
    /**
     * Operazioni eseguite in instanza del componente
     */

    condizioneLuceListCtrl.$onInit = function () {
      condizioneLuceListCtrl.progress = loadElementi();
    };
    /**
     * @property
     * Handler da eseguire al cambio pagina della tabella
     * @param page
     */


    condizioneLuceListCtrl.onPageChange = function (query) {
      var params = {
        l: query.limit ? query.limit : condizioneLuceListCtrl.query.limit,
        p: query.page ? query.page : condizioneLuceListCtrl.query.page
      };
      condizioneLuceListCtrl.progress = loadElementi(params);
    };
    /**
     * @property
     * Handler da eseguire al cambio dell'ordinamento di una colonna
     * @param page
     */


    condizioneLuceListCtrl.onOrderChange = function (order) {
      var params = {
        l: condizioneLuceListCtrl.query.limit,
        p: condizioneLuceListCtrl.query.page,
        o: order
      };
      condizioneLuceListCtrl.progress = loadElementi(params);
    };
    /****************
     * Utils
     ****************/


    function loadElementi(prms) {
      var params = prms ? prms : condizioneLuceListCtrl.query;
      return $tdsApiService.executeCommand(searchCommand, params).then(function (result) {
        setElementi(result);
      });
    }
    /**
     * Imposta tutti gli elementi necessari per popolare la tabella
     * @param elementi
     * @returns {*}
     */


    function setElementi(elementi) {
      condizioneLuceListCtrl.elementi = elementi.resultsDTO.results;
      condizioneLuceListCtrl.query.page = elementi.searchForm.p;
      condizioneLuceListCtrl.query.limit = elementi.searchForm.l;
      condizioneLuceListCtrl.totalEl = elementi.resultsDTO.count;
    }
  }
})();