"use strict";

(function () {
  'use strict';

  angular.module('osTranslatable').directive('translatable', ['$translatable', directiveFn]);
  /**
   * La direttiva ha lo scopo di creare un componente di input (textarea o text) per gestire le diverse localizzazioni dell'applicazione.
   * La maschera di mostra come un tab panel dove ogni panel rappresenta una lingua traducibile.
   * E' inoltre presente un ulteriore tab dove è possibile aggiungere dinamicamente altre lingue che non facciano parte del set di default
   *
   * La direttiva accetta quattro parametri:
   * 1) ngModel: sul quale fare il binding delle input area
   * 2) inputType: discrimina se usare una textarea o un normale campo di input type="text"
   * 3) required: se true le traduzioni facenti parte dei default locale sono mandatorie rispetto al salvataggio
   * 4) readonly (opzionale): se true l'input di testo diventa di sola lettura. Se non viene passato il parametro è false di default
   *
   * La direttiva fa uso di alcune proprietà interne dell'ngModelController. Per approfondimenti si veda:
   * - https://docs.angularjs.org/api/ng/type/ngModel.NgModelController
   * - http://radify.io/blog/understanding-ngmodelcontroller-by-example-part-1/
   */

  function directiveFn($translatable) {
    return {
      restrict: 'E',
      templateUrl: 'osFramework/osTranslatable/translatable.directive.html',
      link: postLink,
      require: ['ngModel'],
      controller: ['$scope', '$translatable', function (scope, $translatable) {
        var me = this,
            defLocales = [];
        /**
         * @property
         * Lista di tutte le lingue disponibili nell'applicazione.
         * Vengono configurate nell'opportuna tabella di supporto
         * @type {Array}
         */

        scope.availableLanguages = [];
        /**
         * @property
         * Oggetto contenente un'entry per lingua con la relativa traduzione.
         * Viene costruito nell'ngModelController a partire dai dati recuperati dall'ngModel
         */

        scope.traduzioni; //Recupero tutte le lingue disponibili

        $translatable.getAvailableLanguages().then(function (availableLanguages) {
          scope.availableLanguages = availableLanguages;
        }); //Recupero tutti i locales disponibili (default)

        $translatable.getAvailableLocales().then(function (availableLocales) {
          //TODO: verificare come sia possibile avere più locales configurati e attivi nello stesso momento
          var localesTab = R.map(function (locale) {
            return {
              title: locale + "*",
              languageTag: locale
            };
          }, availableLocales[0].lingueAttive.split(",")); //Mi serve successivamente per i campi required

          defLocales = availableLocales[0].lingueAttive.split(","); //Faccio il merge tra i tab delle traduzioni già presenti e i tab di default del locale
          //E' possibile infatti che le traduzioni vengano caricate prima dei default locale. Questo porta alla costruzione dei relativi
          //tabs nell'ngModelController. Per questo se i default locale vengono risolti dopo devo effettuare un merge per evitare doppioni

          scope.tabs = _.unionWith(localesTab, scope.tabs, function (obj1, obj2) {
            if (obj1.languageTag == obj2.languageTag) {
              return true;
            }

            return false;
          });
        });
        /**
         * Filtro da applicare alla lista delle lingue dsponibili
         * Elimino dalla lista quegli elementi per cui è già visualizzato il tab della traduzione
         * @param language: singolo item della lista (lingua esistente nelle tds)
         * @returns {boolean}
         */

        scope.dropAlreadyDiplayedTabs = function (language) {
          var result = true;

          if (R.find(R.propEq('languageTag', language.languageTag))(scope.tabs)) {
            result = false;
          }

          return result;
        }; //Se la direttiva translatable è required setto la medesima proprietà solo sulle form che fanno
        //parte dei default locale, non sulle traduzioni aggiunte in un secondo momento.


        scope.isLanguageTagRequired = function (languageTag) {
          return R.contains(languageTag, defLocales);
        };
        /**
         * L'handler si occupa di aggiungere un nuovo tab per le traduzioni a
         * seguito del click su un item della lista delle lingue disponibili
         * @param language
         */


        scope.addTranslationFor = function (language) {
          scope.tabs.push({
            title: language,
            languageTag: language
          });
        };
      }],
      scope: {
        inputType: '@',
        required: '=',
        label: '@',
        readonly: '<?',
        onTraduzioniChange: '<?'
      }
    };

    function postLink(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0]; //Operazioni eseguire al render della vista (sul change di $viewValue)
      //Elaboro le informazioni prodotte dai formatters per utilizzarle per costruire la vista

      ngModelCtrl.$render = function () {
        //proprietà sul quale viene fatto il bind dei campi di input
        scope.traduzioni = ngModelCtrl.$viewValue.traduzioni; //Faccio il merge tra i tab presenti e quelli creati a seguito delle traduzioni in ingresso dall'ngModel
        //E' necessario il merge perchè non possiamo sapere se verranno recuperate prima le traduzioni dei degaul locale

        scope.tabs = _.unionWith(scope.tabs, ngModelCtrl.$viewValue.tabs, function (obj1, obj2) {
          if (obj1.languageTag == obj2.languageTag) {
            return true;
          }

          return false;
        });
      }; //Pipeline functions to convert the modelValue to the viewValue
      //i formatter convertono il modelValue nel viewValue. Qui è possibile elabolare le informazioni dell'ngModel
      //per renderle utilizzabili dalla vista. In questo caso mi costruisco 2 proprietà:
      //1) traduzioni: come oggetto contenente le traduzioni recuperate dall'ngModel
      //2) tabs: array di tab (uno per traduzione)


      ngModelCtrl.$formatters.push(function (traduzioniModel) {
        var tabs = [],
            traduzioni = {};

        if (traduzioniModel) {
          R.map(function (traduzione) {
            tabs.push({
              title: traduzione.languageTag,
              languageTag: traduzione.languageTag
            });
            traduzioni[traduzione.languageTag] = traduzione.traduzione;
          }, traduzioniModel);
        }

        return {
          tabs: tabs,
          traduzioni: traduzioni
        };
      }); //convert view value to model value
      //Quando viene invocata la $setViewValue vengono eseguiti i parsers. Qui elaboriamo le informazioni inserite dall'utente
      //per scriverle dentro all'ngModel.

      ngModelCtrl.$parsers.push(function (viewValue) {
        var traduzioni = [];

        if (viewValue.traduzioni) {
          traduzioni = Object.keys(viewValue.traduzioni).map(key => ({
            languageTag: key,
            traduzione: viewValue.traduzioni[key].toUpperCase() || ""
          }));
        }

        scope.onTraduzioniChange && scope.onTraduzioniChange(traduzioni);
        return traduzioni;
      }); //Listen scope values change and update $viewValue
      //Quando l'utente interagisce con i campi di input sincronizzo l'ngModel

      scope.$watch('traduzioni', function () {
        ngModelCtrl.$setViewValue({
          traduzioni: scope.traduzioni
        });
      }, true);
    }
  }
})();