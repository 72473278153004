"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHideSpinner = void 0;

var _makeStyles = _interopRequireDefault(require("@material-ui/core/styles/makeStyles"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const useHideSpinner = (0, _makeStyles.default)({
  // stili per rimuovere le frecce per incrementare e decrementare dal campo input type number
  root: {
    "& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& [type=number]": {
      "-moz-appearance": "textfield"
    }
  }
});
exports.useHideSpinner = useHideSpinner;