"use strict";

/**
 * Created by gb on 15/10/19.
 */
(function () {
  'use strict';

  angular.module('polcity.documenti').service('documentiService', [documentiService]);

  function documentiService() {
    return {
      getDocumentoModalConfig: getDocumentoModalConfig
    };

    function getDocumentoModalConfig(config) {
      var confirmable = {
        templateUrl: 'osFramework/documenti/modal/documentoModal.component.html',
        controllerAs: 'DocumentoModalCtrl',
        controller: 'documentoModalController',
        multiple: true,
        clickOutsideToClose: true,
        bindToController: true,
        locals: {
          documento: {}
        }
      };
      return R.merge(confirmable, config);
    }
  }
})();