"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFromRight = void 0;

var _makeStyles = _interopRequireDefault(require("@material-ui/core/styles/makeStyles"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const array = [...Array(250).keys()];
const animationsDelay = array.reduce((acc, val, index) => ({ ...acc,
  [`&:nth-child(${index})`]: {
    animationDelay: `${Math.random().toFixed(2)}s`
  }
}), {});
const fromRight = {
  root: {
    opacity: 0,
    transform: 'translateX(50px)',
    animation: '0.3s fadeInRight forwards',
    ...animationsDelay
  }
};
const useFromRight = (0, _makeStyles.default)(theme => fromRight);
exports.useFromRight = useFromRight;