"use strict";

(function () {
  'use strict';

  angular.module('osAllegati').controller('osAllegatoFormController', ['$scope', 'Command', '$mdDialog', 'OSNotification', 'osEvents', 'aggId', 'aggregateCanonicalName', 'attachmentId', osAllegatoFormController]);

  function osAllegatoFormController($scope, Command, $mdDialog, OSNotification, osEvents, aggId, aggregateCanonicalName, attachmentId) {
    var me = this,
        allegato;
    /********************************
     * Controller Properties
     ********************************/

    /**
     * @property
     * ngModel della select box proiezioni
     */

    me.selectedProjection;
    /**
     * @property
     * ngModel della select box contenutoAllegato
     */

    me.selectedType;
    /**
     * @property
     * ngModel della textarea note
     */

    me.note;
    /**
     * @property
     * ngModel della input file
     */

    me.file = []; //*******************************

    /**
     * GB 02/07/2018
     * Handler scatenato al change della select box del tipo di proiezione
     * @type {loadContenutoAllegato}
     */

    me.onSelectedProjectionChange = loadContenutoAllegato;
    /**
     * GB 02/07/2018
     * Handler scatenato alla pressione del bottone salva
     */

    me.save = attachmentId !== -1 ? update : create;
    /**
     * GB 02/07/2018
     * Handler di chiusura della modale (call then)
     */

    me.hide = hideModal;
    /**
     * GB 02/07/2018
     * Handler di chiusura della modale (call catch)
     * @type {cancel}
     */

    me.cancel = cancel;
    /**
     * GB 03/07/2018
     * Handler che gestisce l'attivazione del bottone submit della form
     */

    me.checkDisabled = checkDisabled;

    me.$onInit = function () {
      fetchItems(aggId, aggregateCanonicalName).then(setItems).then(caricaAllegato);
      $scope.$watch('allegato', function (newAllegato, oldAllegato) {
        if (newAllegato) {
          setSelectedProjection(R.find(R.propEq('itemId', newAllegato.rifAggId))(me.items));
          setSelectedType({
            aggId: newAllegato.contenutoAllegatoId
          });
          setNote(newAllegato.note);
          me.file.push({
            lfFileName: newAllegato.nomeFileOriginale
          }); //Sarebbe il caso di fare il bindig sull'ngModel della select di projection (non va in automatico)

          fetchContenutoAllegato(getSelectedProjection().projectionClass).then(setTypes);
        }
      });
    };

    const getTipoContenuto = aggId => Array.isArray(me.types) && me.types.find(type => type.aggId === aggId);

    const hasTipoContenuto = aggId => aggId && Boolean(getTipoContenuto(aggId));

    me.getTipoContenutoData = () => {
      var _me$selectedType;

      const selectedAggId = (_me$selectedType = me.selectedType) === null || _me$selectedType === void 0 ? void 0 : _me$selectedType.aggId;
      return hasTipoContenuto(selectedAggId) ? getTipoContenuto(selectedAggId) : '';
    };
    /**
     * GB 03/07/2018
     * Il metodo si occupa di fare il fetch dell'allegato (se esiste) e di aggiungerlo allo scope
     */


    function caricaAllegato() {
      if (attachmentId !== -1) {
        fetchAllegato(attachmentId).then(function (allegato) {
          $scope.allegato = allegato;
        });
      }
    }
    /**
     * GB 02/07/2018
     * Handler di creazione allegato
     * FIXME: Multipart Mime part documento exceeds max filesize, qual è la dim massima??
     */


    function create() {
      var fd = new FormData();
      fd.append('documento', getFile());
      fd.append('json', JSON.stringify({
        note: getNote(),
        contenutoAllegatoId: getSelectedType().aggId,
        rifAggId: getSelectedProjection().itemId,
        nomeFileOriginale: getFileName()
      }));
      createAllegato(fd).then(hideModal);
    }

    function update() {
      var fd = new FormData(),
          json = {},
          file = getFile();
      json.aggId = $scope.allegato.aggId;
      json.note = getNote();
      json.contenutoAllegatoId = getSelectedType().aggId;
      json.rifAggId = getSelectedProjection().itemId; //Se non ricarico un nuovo file manco l'attuale allegato.nomeFileOriginale

      json.nomeFileOriginale = file ? getFileName() : $scope.allegato.nomeFileOriginale;
      fd.append('documento', file ? file : null);
      fd.append('json', JSON.stringify(json));
      updateAllegato(fd).then(hideModal);
    }

    function loadContenutoAllegato() {
      setTypes([]);
      setSelectedType(null);
      fetchContenutoAllegato(getSelectedProjection().projectionClass).then(setTypes);
    }
    /***************************************
     * Utils
     ***************************************/


    function checkDisabled(allegatoForm) {
      return allegatoForm.$invalid || !me.file.length;
    }

    function fetchItems(aggId, aggregateCanonicalName) {
      return Command.execute('@loadItems', {
        aggId: aggId,
        aggregateCanonicalName: aggregateCanonicalName
      });
    }

    function fetchContenutoAllegato(tipoAllegato) {
      return Command.execute('@contenutoAllegato', {
        tipoOggettoAllegato: tipoAllegato
      });
    }

    function fetchAllegato(aggId) {
      return Command.execute('@detailAllegato', {
        aggId: aggId
      });
    }

    function createAllegato(formData) {
      return Command.executeRaw('@creaAllegato', formData, {
        'Content-Type': undefined
      }); //this do the magic of multipart
    }

    function updateAllegato(formData) {
      return Command.executeRaw('@updateAllegato', formData, {
        'Content-Type': undefined
      }); //this do the magic of multipart
    }

    function getNote() {
      return me.note;
    }

    function setNote(note) {
      me.note = note;
    }

    function setItems(items) {
      me.items = items;
    }

    function getSelectedProjection() {
      return me.selectedProjection;
    }

    function setSelectedProjection(selectedProjection) {
      me.selectedProjection = selectedProjection;
    }

    function getSelectedType() {
      return me.selectedType;
    }

    function setSelectedType(type) {
      me.selectedType = type;
    }

    function setTypes(types) {
      me.types = types;
    }

    function getFile() {
      return me.file[0].lfFile;
    }

    function getFileName() {
      return me.file[0].lfFileName;
    }

    function hideModal() {
      $mdDialog.hide(aggId);
    }

    function cancel() {
      $mdDialog.cancel(aggId);
    } //**************************************

  }
})();