"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAllegatiDigitaliNonAssociatiMaterDetail = exports._availableSorters = exports._availableFilters = exports.allegatiDigitaliNonAssociatiMasterDetailName = void 0;

var _recoil = require("recoil");

var _quickSearch = require("../generics/quickSearch");

var _api = require("../../api");

var _masterDetail = require("../generics/masterDetail");

var _allegatiNonAssociati = require("./allegatiNonAssociati");

var _react = require("react");

const allegatiDigitaliNonAssociatiMasterDetailName = "allegatiDigitali";
exports.allegatiDigitaliNonAssociatiMasterDetailName = allegatiDigitaliNonAssociatiMasterDetailName;

const _availableFilters = (0, _recoil.atom)({
  key: '_availableFiltersAllegatiDigitaliNonAssociati',
  default: [{
    name: 'dataOraCaricamento',
    label: 'Data acquisizione',
    value: {
      startDate: null,
      endDate: null
    }
  }]
});

exports._availableFilters = _availableFilters;

const _availableSorters = (0, _recoil.atom)({
  key: '_availableSortersAllegatiDigitaliNonAssociati',
  default: [{
    name: 'dataOraCaricamento',
    label: 'Data acquisizione',
    direction: null
  }]
});

exports._availableSorters = _availableSorters;
const useAllegatiDigitaliNonAssociatiQuickSearch = (0, _quickSearch.useQuickSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    order,
    dataOraCaricamento
  }) => (0, _api.ricercaAllegatiDigitaliNonAssociati)({
    page,
    limit,
    order,
    dataOraCaricamento: dataOraCaricamento && dataOraCaricamento.startDate && dataOraCaricamento.endDate && [dataOraCaricamento.startDate, dataOraCaricamento.endDate]
  })
});

const useAllegatiDigitaliNonAssociatiMaterDetail = props => {
  const {
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  } = props;
  const availableSorters = (0, _recoil.useRecoilValue)(_availableSorters);
  const availableFilters = (0, _recoil.useRecoilValue)(_availableFilters);
  const {
    open,
    selectionActive,
    activeItem,
    setState,
    reset: resetState
  } = (0, _masterDetail.useMasterDetailState)({
    name,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  });
  const {
    page,
    limit,
    items,
    isLoading,
    count,
    activeFilters,
    activeSorters,
    activeSortersCount,
    changePage,
    changeLimit,
    addFilter,
    removeFilter,
    setActiveSorters,
    refresh
  } = useAllegatiDigitaliNonAssociatiQuickSearch({
    resourceKeys: _allegatiNonAssociati.allegatiDigitaliNonAssociatiKeys,
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp
  });

  const resetMasterDetail = () => {
    resetState();
    refresh(_allegatiNonAssociati.allegatiDigitaliNonAssociatiKeys.quickSearch());
  };

  (0, _react.useEffect)(() => {
    window.$transitions.onExit({
      exiting: `navigation.allegatiDigitaliVerbalizza`
    }, (_transition, _state) => {
      resetMasterDetail();
    });
  });
  return {
    page,
    limit,
    isLoading: isLoading,
    items: items,
    count: count,
    availableFilters,
    activeFilters,
    changePage,
    changeLimit,
    addFilter: props => {
      activeFilters.length && removeFilter(props.name);
      addFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    removeFilter: props => {
      removeFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    availableSorters,
    activeSorters,
    activeSortersCount,
    setSorters: setActiveSorters,
    refresh: refresh,
    //Master detail props
    open,
    selectionActive,
    activeItem,
    setState,
    resetMasterDetail
  };
};

exports.useAllegatiDigitaliNonAssociatiMaterDetail = useAllegatiDigitaliNonAssociatiMaterDetail;