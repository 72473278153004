"use strict";

/**
 * Created by MN 02/09/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').config(['$stateProvider', 'osContabilitaRoutesProvider', addRoutes]).run(['osContabilitaRoutes', routesRun]);

  function addRoutes($stateProvider, osContabilitaRoutesProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;
      $stateProvider.state(stateName, routeDefinition);
    }, osContabilitaRoutesProvider.list());
  }

  function routesRun(osContabilitaRoutes) {
    osContabilitaRoutes.subscribeUIEvents();
  }
})();