"use strict";

(function () {
  'use strict';

  angular.module('osDate').provider('osDateUtils', [osDateUtils]).config(['osDateUtilsProvider', function (osDateUtils) {
    osDateUtils.setFormat('DD/MM/YYYY');
  }]).directive('uiDateMask', ['osDateUtils', '$compile', '$timeout', osDatePicker]);

  function osDateUtils() {
    var format;
    this.setFormat = setFormat;
    this.getFormat = getFormat;

    this.$get = function () {
      return {
        getFormat: getFormat,
        setFormat: setFormat
      };
    };

    function getFormat() {
      return format || 'dd/mm/yyyy';
    }

    function setFormat(newFormat) {
      format = newFormat;
    }
  }
  /**
   * Handles an ngModel valorized with a timestamp and shows a formatted date
   * Thanks to uiDateMask the user gets a mask on the input
   * Adds a button that shows a calendar on click and keeps in sync the value of the calendar with the ngmodel
   *
   * @param osDateUtils
   * @param $compile
   * @param $timeout
   * @return {{restrict: string, priority: number, compile: compile, scope: {osMaxDate: string, osMinDate: string}, require: string}}
   */


  function osDatePicker(osDateUtils, $compile, $timeout) {
    return {
      restrict: 'A',
      priority: -1,
      // run this first so we can add the format to the uiDateMask attribute
      compile: function (element, attrs) {
        attrs.$set('uiDateMask', osDateUtils.getFormat());
        return postLink;
      },
      scope: {
        osMaxDate: '=',
        osMinDate: '='
      },
      require: 'ngModel'
    };

    function postLink(scope, element, attrs, ngModel) {
      scope.showCalendar = false;
      $timeout(function () {
        // formatters etc get processed in reverse order and this directive gets compiled
        // before the overridden one, so we add validators formatters etc etc in the next digest
        ngModel.$formatters.push(formatTimestamp);
        ngModel.$parsers.push(dateToTimestamp);
        ngModel.$validators.osMaxDate = validateMaxDate;
        ngModel.$validators.osMinDate = validateMinDate;
        ngModel.$render = ngModelCalendarSync;
      });

      scope.showPicker = function () {
        scope.showCalendar = !scope.showCalendar;
      };

      scope.calendarToNgModel = calendarToNgModel;
      element.wrap('<div class="os-date-picker"></div>');
      element.addClass('os-date-picker__input'); // add calendar button toggler

      element.parent().append($compile('<md-button md-prevent-menu-close="true" class="os-date-picker__calendarToggler md-icon-button" ng-click="showPicker()"><md-icon>event</md-icon></md-button>')(scope)); // add calendar

      element.parent().append($compile('<div class="os-date-picker__calendar"><md-datepicker ng-model="calendarDate" md-min-date="calendarMinDate" md-max-date="calendarMaxDate" md-is-open="showCalendar" ng-change="calendarToNgModel(calendarDate)"></md-datepicker></div>')(scope)); // watch max and min date limit changes

      scope.$watch('osMaxDate', updateMaxDate);
      scope.$watch('osMinDate', updateMinDate);
      /**
       *
       * @param Date calendarDate
       */

      function calendarToNgModel(calendarDate) {
        ngModel.$setViewValue(dateToDateString(calendarDate));
        element.val(dateToDateString(calendarDate));
      }

      function ngModelCalendarSync() {
        var value = ngModel.$isEmpty(ngModel.$viewValue) ? '' : ngModel.$viewValue;

        if (element.val() !== value) {
          element.val(value);
        }

        console.log('rendering', value);
      }
      /**
       *
       * @param value a timestamp
       * @return {boolean}
       */


      function validateMaxDate(value) {
        if (!scope.osMaxDate) {
          return true;
        }

        if (!angular.isNumber(value)) {
          return false;
        }

        var maxDateTimestamp = angular.copy(scope.osMaxDate);

        if (angular.isDate(maxDateTimestamp)) {
          maxDateTimestamp = maxDateTimestamp.getTime();
        }

        return value <= maxDateTimestamp;
      }
      /**
       *
       * @param value a timestamp
       * @return {boolean}
       */


      function validateMinDate(value) {
        if (!scope.osMinDate) {
          return true;
        }

        if (!angular.isNumber(value)) {
          return false;
        }

        var minDateTimestamp = angular.copy(scope.osMinDate);

        if (angular.isDate(minDateTimestamp)) {
          minDateTimestamp = minDateTimestamp.getTime();
        }

        return value >= minDateTimestamp;
      }
      /**
       * update max for the calendar and trigger validation
       */


      function updateMaxDate() {
        var maxDate = angular.copy(scope.osMaxDate);

        if (angular.isNumber(maxDate)) {
          maxDate = new Date(maxDate);
        }

        scope.calendarMaxDate = maxDate;
        ngModel.$validate();
      }
      /**
       * update min for the calendar and trigger validation
       */


      function updateMinDate() {
        var minDate = angular.copy(scope.osMinDate);

        if (angular.isNumber(minDate)) {
          minDate = new Date(minDate);
        }

        scope.calendarMinDate = minDate;
        ngModel.$validate();
      }
      /**
       *
       * @param value a date
       * @return a timestamp
       */


      function dateToTimestamp(value) {
        console.log('parsing', value);

        if (value && angular.isDate(value)) {
          scope.calendarDate = moment(value).toDate();
          return value.getTime();
        }

        return value;
      }
      /**
       *
       * @param value a timestamp
       * @return a formatted date string
       */


      function formatTimestamp(value) {
        console.log('formatting', value);

        if (value && angular.isNumber(value)) {
          if (scope.calendarDate && scope.calendarDate.getTime() !== value) {
            scope.calendarDate = getDate(value);
          }

          return timestampToDateString(value);
        }

        if (value === undefined) {
          // ngModel was cleared, clear also the calendar
          scope.calendarDate = undefined;
        }

        return value;
      }
      /**
       * helper function to transform a timestamp to a formatted date string
       * @param timestamp: a timestamp
       */


      function timestampToDateString(timestamp) {
        return moment(timestamp).format(osDateUtils.getFormat());
      }
      /**
       * helper function to transform a timestamp to a formatted date string
       * @param Date date: a timestamp
       */


      function dateToDateString(date) {
        return timestampToDateString(date);
      }
      /**
       * helper function to transform a timestamp into a date
       * @param timestamp
       * @return {Date|*}
       */


      function getDate(timestamp) {
        return moment(timestamp).toDate();
      }
    }
  }
})();