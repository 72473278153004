"use strict";

/**
 * Created by MN 10/12/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').controller('BilancioController', ['$scope', 'Bilancio', '$state', BilancioController]);

  function BilancioController($scope, Bilancio, $state) {
    var me = this;
    me.bilancio = Bilancio.$build();
    me.contoEconomico = {};
    me.statoPatrimoniale = {};
    me.isMenuOpen = false;
    me.showSelectYear = false;
    me.showCompareBilanci = false;
    me.bilancioYear = me.maxYearSelectable = moment().year();
    me.yearsToCompare = [];
    me.bilancio.$on('befor-get-bilancio', function (_res) {
      me.isLoadingBilancio = true;
    });
    me.bilancio.$on('after-get-bilancio', function (_res) {
      me.isLoadingBilancio = false;
      me.contoEconomico = _res.data.contoEconomico;
      me.statoPatrimoniale = _res.data.statoPatrimoniale;
    });
    me.bilancio.$on('after-get-bilancio-error', function (_res) {
      me.isLoadingBilancio = false;
    });

    me.$onInit = function () {
      var params = {
        anno: moment().year().toString()
      };
      me.bilancio.$getBilancio(params);
    };
    /**
     * Handlers
     */


    me.onToggleChangeBilancioYear = function () {
      toggleSelezionaAnno();
    };

    me.onToggleCompareBilanci = function () {
      toggleCompareBilanci();
    };

    me.onSelectedBilancioYear = function () {
      toggleSelezionaAnno();
      var params = {
        anno: me.bilancioYear.toString()
      };
      me.bilancio.$getBilancio(params);
    };

    me.onSelectedYearsToCompare = function () {
      toggleSelezionaAnno();
      $state.go('navigation.osContabilita.compareBilanci', {
        yearsToCompare: me.yearsToCompare
      });
    };
    /**
     * Utils
     */


    function toggleSelezionaAnno() {
      me.showSelectYear = !me.showSelectYear;
    }

    function toggleCompareBilanci() {
      me.showCompareBilanci = !me.showCompareBilanci;
    }
  }
})();