"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contenutiAllegatiListColumns = void 0;

var _react = _interopRequireDefault(require("react"));

var _enums = require("../../../state/enums");

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const contenutiAllegatiListColumns = [{
  field: 'descrizioneContenuto',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'tipoContenutoAllegato',
  headerName: 'Contenuto',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaTipiContenutoAllegato") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'tipoOggettoAllegato',
  headerName: 'Oggetto',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? value.replace('eu.opensoftware.entities.', '') : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'tipoDisponibilitaEnum',
  headerName: 'Disponibilità',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaDisponibilitaAllegato") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.contenutiAllegatiListColumns = contenutiAllegatiListColumns;