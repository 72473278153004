"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleDownloadAllegatoSuccess = exports.ensurePdf = void 0;

var _utils = require("../../utils");

const ensurePdf = (data, isPdf) => {
  let src;

  if (isPdf) {
    src = window.URL.createObjectURL((0, _utils.byteArrayToBlob)(data, "application/pdf"));
  } else {
    const arrayBuffer = new Uint8Array(data);
    src = (0, _utils.transformImageToPdf)(arrayBuffer);
  }

  return src;
};

exports.ensurePdf = ensurePdf;

const handleDownloadAllegatoSuccess = ({
  response
}) => {
  const fileType = (0, _utils.getFileType)(response.headers["content-type"]);
  const fileName = (0, _utils.getFileNameFromResponseHeaders)(response.headers) || 'File scaricato';
  return (0, _utils.downloadFile)(response.data, fileType, fileName);
};

exports.handleDownloadAllegatoSuccess = handleDownloadAllegatoSuccess;