"use strict";

/**
 * Created by MN 11/09/2019
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('stopClickPropagation', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        element.bind('click', function (e) {
          e.stopPropagation();
        });
      }
    };
  });
})();