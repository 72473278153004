"use strict";

/**
 * Created by gbelli on 24/09/19.
 */
(function () {
  'use strict';

  angular.module('polcity.decurtazioniPunti').config(['$stateProvider', 'decurtazioniPuntiRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['decurtazioniPuntiRoutes', routesRun]);

  function addRoutes($stateProvider, decurtazioniPuntiRoutesProvider, $translatePartialLoader, AuthProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }
    }, decurtazioniPuntiRoutesProvider.list());
    $translatePartialLoader.addPart('decurtazioniPunti');
  }

  function routesRun(decurtazioniPuntiRoutes) {
    decurtazioniPuntiRoutes.subscribeUIEvents();
  }
})();