"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPathDaterOrNull = exports.getPathDateToNumberOrNull = exports.getPathNumberToDateOrNull = exports.getPathObjectOrNull = exports.getPathObjectOrEmptyObject = exports.getPathNonEmptyArrayOrNull = exports.getPathArrayOrNull = exports.getPathArrayOrEmptyArray = exports.getPathBooleanOrTrue = exports.getPathBooleanOrFalse = exports.getPathNumberOrEmptyString = exports.getPathNumberOrNull = exports.getPathNonEmptyStringOrFalse = exports.getPathNonEmptyStringOrNull = exports.getPathStringOrFalse = exports.getPathStringOrNull = exports.getPathStringOrEmptyString = exports.extractPathArrayOrEmptyArray = exports.extractPathNonEmptyStringOrNull = exports.extractPathStringOrNull = exports.extractPathBoolOrFalse = exports.extractPathBoolOrNull = exports.extractPathObjectOrNull = exports.extractPathNumberOrEmptyString = exports.extractPathStringOrEmptyString = exports.extractPathPredOrEmptyArray = exports.extractPathPredOrFalse = exports.extractPathPredOrNull = exports.extractPathPredOrEmptyString = exports.extractPropNumberToDateOrNull = exports.extractPropDateToNumberOrNull = exports.extractPropArrayOrNull = exports.extractPropNonEmptyStringOrEmptyString = exports.extractPropNonEmptyStringOrNull = exports.extractPropNumberOrNull = exports.extractPropNumberOrEmptyString = exports.extractPropStringOrNull = exports.extractPropStringOrEmptyString = exports.extractPropBooleanOrFalse = exports.extractPropBooleanOrNull = exports.extractPropObjectOrNull = exports.extractPropPredOrFalse = exports.extractPropPredOrEmptyString = exports.extractPropPredOrNull = exports.isDate = exports.isNull = void 0;

var _isString = _interopRequireDefault(require("crocks/predicates/isString"));

var _isNumber = _interopRequireDefault(require("crocks/predicates/isNumber"));

var _isBoolean = _interopRequireDefault(require("crocks/predicates/isBoolean"));

var _isObject = _interopRequireDefault(require("crocks/predicates/isObject"));

var _safe = _interopRequireDefault(require("crocks/Maybe/safe"));

var _chain = _interopRequireDefault(require("crocks/pointfree/chain"));

var _getProp = _interopRequireDefault(require("crocks/Maybe/getProp"));

var _getPath = _interopRequireDefault(require("crocks/Maybe/getPath"));

var _compose = _interopRequireDefault(require("crocks/helpers/compose"));

var _either = _interopRequireDefault(require("crocks/pointfree/either"));

var _coalesce = _interopRequireDefault(require("crocks/pointfree/coalesce"));

var _constant = _interopRequireDefault(require("crocks/combinators/constant"));

var _and = _interopRequireDefault(require("crocks/logic/and"));

var _identity = _interopRequireDefault(require("crocks/combinators/identity"));

var _isArray = _interopRequireDefault(require("crocks/predicates/isArray"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const wrap = key => val => ({
  [key]: val
});

const getKey = (path, key) => key || path[path.length - 1];

const isNotEmptyString = x => x != "";

const isNotEmptyArray = x => !!x.length;

const isNull = x => x === null;

exports.isNull = isNull;

const isDate = date => date instanceof Date; // it returns an object


exports.isDate = isDate;

const extractPropPredOr = def => (pred, transformRight = _identity.default) => key => (0, _compose.default)((0, _either.default)(wrap(key), wrap(key)), (0, _coalesce.default)((0, _constant.default)(def), //coalesce needs a function so we use constant to swallow the input and return a def value
transformRight), (0, _chain.default)((0, _safe.default)(pred)), (0, _getProp.default)(key)); // it returns an object


const extractPathPredOr = def => pred => (path, key) => (0, _compose.default)((0, _either.default)(wrap(getKey(path, key)), wrap(getKey(path, key))), (0, _coalesce.default)((0, _constant.default)(def), //coalesce needs a function so we use constant to swallow the input and return a def value
_identity.default), (0, _chain.default)((0, _safe.default)(pred)), (0, _getPath.default)(path)); // it returns a property


const getPathPredOr = def => (pred, transformRight = _identity.default) => path => (0, _compose.default)((0, _either.default)(_identity.default, _identity.default), (0, _coalesce.default)((0, _constant.default)(def), //coalesce needs a function so we use constant to swallow the input and return a def value
transformRight), (0, _chain.default)((0, _safe.default)(pred)), (0, _getPath.default)(path));

const getPathPredOrNull = getPathPredOr(null);
const getPathPredOrEmptyString = getPathPredOr("");
const getPathPredOrFalse = getPathPredOr(false);
const getPathPredOrTrue = getPathPredOr(true);
const getPathPredOrEmptyArray = getPathPredOr([]);
const getPathPredOrEmptyObject = getPathPredOr({}); // extract prop
// extract means that it will extract the desired property from the source object and return a new object with only the property selected

const extractPropPredOrNull = extractPropPredOr(null);
exports.extractPropPredOrNull = extractPropPredOrNull;
const extractPropPredOrEmptyString = extractPropPredOr("");
exports.extractPropPredOrEmptyString = extractPropPredOrEmptyString;
const extractPropPredOrFalse = extractPropPredOr(false);
exports.extractPropPredOrFalse = extractPropPredOrFalse;
const extractPropObjectOrNull = extractPropPredOrNull(_isObject.default);
exports.extractPropObjectOrNull = extractPropObjectOrNull;
const extractPropBooleanOrNull = extractPropPredOrNull(_isBoolean.default);
exports.extractPropBooleanOrNull = extractPropBooleanOrNull;
const extractPropBooleanOrFalse = extractPropPredOrFalse(_isBoolean.default);
exports.extractPropBooleanOrFalse = extractPropBooleanOrFalse;
const extractPropStringOrEmptyString = extractPropPredOrEmptyString(_isString.default);
exports.extractPropStringOrEmptyString = extractPropStringOrEmptyString;
const extractPropStringOrNull = extractPropPredOrNull(_isString.default);
exports.extractPropStringOrNull = extractPropStringOrNull;
const extractPropNumberOrEmptyString = extractPropPredOrEmptyString(_isNumber.default);
exports.extractPropNumberOrEmptyString = extractPropNumberOrEmptyString;
const extractPropNumberOrNull = extractPropPredOrNull(_isNumber.default);
exports.extractPropNumberOrNull = extractPropNumberOrNull;
const extractPropNonEmptyStringOrNull = extractPropPredOrNull((0, _and.default)(_isString.default, isNotEmptyString));
exports.extractPropNonEmptyStringOrNull = extractPropNonEmptyStringOrNull;
const extractPropNonEmptyStringOrEmptyString = extractPropPredOrEmptyString((0, _and.default)(_isString.default, isNotEmptyString));
exports.extractPropNonEmptyStringOrEmptyString = extractPropNonEmptyStringOrEmptyString;
const extractPropArrayOrNull = extractPropPredOrNull(_isArray.default);
exports.extractPropArrayOrNull = extractPropArrayOrNull;
const extractPropDateToNumberOrNull = extractPropPredOrNull(isDate, date => date.getTime());
exports.extractPropDateToNumberOrNull = extractPropDateToNumberOrNull;
const extractPropNumberToDateOrNull = extractPropPredOrNull(_isNumber.default, timestamp => new Date(timestamp)); // extract path
// extract means that it will extract the desired path from the source object and return a new object with only the property selected

exports.extractPropNumberToDateOrNull = extractPropNumberToDateOrNull;
const extractPathPredOrEmptyString = extractPathPredOr("");
exports.extractPathPredOrEmptyString = extractPathPredOrEmptyString;
const extractPathPredOrNull = extractPathPredOr(null);
exports.extractPathPredOrNull = extractPathPredOrNull;
const extractPathPredOrFalse = extractPathPredOr(false);
exports.extractPathPredOrFalse = extractPathPredOrFalse;
const extractPathPredOrEmptyArray = extractPathPredOr([]);
exports.extractPathPredOrEmptyArray = extractPathPredOrEmptyArray;
const extractPathStringOrEmptyString = extractPathPredOrEmptyString(_isString.default);
exports.extractPathStringOrEmptyString = extractPathStringOrEmptyString;
const extractPathNumberOrEmptyString = extractPathPredOrEmptyString(_isNumber.default);
exports.extractPathNumberOrEmptyString = extractPathNumberOrEmptyString;
const extractPathObjectOrNull = extractPathPredOrNull(_isObject.default);
exports.extractPathObjectOrNull = extractPathObjectOrNull;
const extractPathBoolOrNull = extractPathPredOrNull(_isBoolean.default);
exports.extractPathBoolOrNull = extractPathBoolOrNull;
const extractPathBoolOrFalse = extractPathPredOrFalse(_isBoolean.default);
exports.extractPathBoolOrFalse = extractPathBoolOrFalse;
const extractPathStringOrNull = extractPathPredOrNull(_isString.default);
exports.extractPathStringOrNull = extractPathStringOrNull;
const extractPathNonEmptyStringOrNull = extractPathPredOrNull((0, _and.default)(_isString.default, isNotEmptyString));
exports.extractPathNonEmptyStringOrNull = extractPathNonEmptyStringOrNull;
const extractPathArrayOrEmptyArray = extractPathPredOrEmptyArray(_isArray.default); // get path

exports.extractPathArrayOrEmptyArray = extractPathArrayOrEmptyArray;
const getPathStringOrEmptyString = getPathPredOrEmptyString(_isString.default);
exports.getPathStringOrEmptyString = getPathStringOrEmptyString;
const getPathStringOrNull = getPathPredOrNull(_isString.default);
exports.getPathStringOrNull = getPathStringOrNull;
const getPathStringOrFalse = getPathPredOrFalse(_isString.default);
exports.getPathStringOrFalse = getPathStringOrFalse;
const getPathNonEmptyStringOrNull = getPathPredOrNull((0, _and.default)(_isString.default, isNotEmptyString));
exports.getPathNonEmptyStringOrNull = getPathNonEmptyStringOrNull;
const getPathNonEmptyStringOrFalse = getPathPredOrFalse((0, _and.default)(_isString.default, isNotEmptyString));
exports.getPathNonEmptyStringOrFalse = getPathNonEmptyStringOrFalse;
const getPathNumberOrNull = getPathPredOrNull(_isNumber.default);
exports.getPathNumberOrNull = getPathNumberOrNull;
const getPathNumberOrEmptyString = getPathPredOrEmptyString(_isNumber.default);
exports.getPathNumberOrEmptyString = getPathNumberOrEmptyString;
const getPathBooleanOrFalse = getPathPredOrFalse(_isBoolean.default);
exports.getPathBooleanOrFalse = getPathBooleanOrFalse;
const getPathBooleanOrTrue = getPathPredOrTrue(_isBoolean.default);
exports.getPathBooleanOrTrue = getPathBooleanOrTrue;
const getPathArrayOrEmptyArray = getPathPredOrEmptyArray(_isArray.default);
exports.getPathArrayOrEmptyArray = getPathArrayOrEmptyArray;
const getPathArrayOrNull = getPathPredOrNull(_isArray.default);
exports.getPathArrayOrNull = getPathArrayOrNull;
const getPathNonEmptyArrayOrNull = getPathPredOrNull((0, _and.default)(_isArray.default, isNotEmptyArray));
exports.getPathNonEmptyArrayOrNull = getPathNonEmptyArrayOrNull;
const getPathObjectOrEmptyObject = getPathPredOrEmptyObject(_isObject.default);
exports.getPathObjectOrEmptyObject = getPathObjectOrEmptyObject;
const getPathObjectOrNull = getPathPredOrNull(_isObject.default);
exports.getPathObjectOrNull = getPathObjectOrNull;
const getPathNumberToDateOrNull = getPathPredOrNull(_isNumber.default, timestamp => new Date(timestamp));
exports.getPathNumberToDateOrNull = getPathNumberToDateOrNull;
const getPathDateToNumberOrNull = getPathPredOrNull(isDate, date => date.getTime());
exports.getPathDateToNumberOrNull = getPathDateToNumberOrNull;
const getPathDaterOrNull = getPathPredOrNull(isDate);
exports.getPathDaterOrNull = getPathDaterOrNull;