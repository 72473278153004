"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SanzioniToCommandMapping = exports.decoratori = void 0;

var _utils = require("../../state/verbale/utils");

const fermoSequestro = {
  commandName: "InizializzaDecoratoreSanzioneAccessorieFermoSequestroCommand",
  ..._utils.fermoSequestroEmptyForm
};
const rimozione = {
  commandName: "InizializzaDecoratoreSanzioneAccessorieRimozioneCommand",
  ..._utils.rimozioneEmptyForm
};
const velocita = {
  commandName: "InizializzaDecoratoreInfrazioneVelocitaCommand",
  ..._utils.velocitaEmptyForm
};
const rosso = {
  commandName: "InizializzaDecoratoreInfrazioneRossoCommand",
  applicabile: true,
  motivazioneMancataApplicazioneId: "",
  motivazioneMancataApplicazioneLibera: "",
  speseSviluppo: false,
  rilevatoreId: null,
  rilevatore: null,
  direzioneId: null,
  direzione: null,
  numeroFotogramma: "",
  distanza: ""
};
const ztl = {
  commandName: "InizializzaDecoratoreInfrazioneZtlCommand",
  applicabile: true,
  motivazioneMancataApplicazioneId: "",
  motivazioneMancataApplicazioneLibera: "",
  speseSviluppo: false,
  rilevatoreId: null,
  rilevatore: null,
  direzioneId: null,
  direzione: null,
  numeroFotogramma: "",
  distanza: ""
};
const senzaDocumenti = {
  commandName: "InizializzaDecoratoreInfrazioneSenzaDocumentiCommand"
};
const generico = {
  commandName: "InizializzaDecoratoreGenericoSanzioneAccessorieCommand",
  applicabile: true,
  motivazioneMancataApplicazioneId: "",
  motivazioneMancataApplicazioneLibera: ""
};
const decoratori = {
  infrazione_rosso: rosso,
  infrazione_velocita: velocita,
  infrazione_ztl: ztl,
  infrazione_senza_documenti: senzaDocumenti,
  sanz_acc_fermo_sequestro: fermoSequestro,
  sanz_acc_rimozione: rimozione,
  generico: generico
};
exports.decoratori = decoratori;
const SanzioniToCommandMapping = {
  fermoSequestro: "InizializzaDecoratoreSanzioneAccessorieFermoSequestroCommand",
  rimozione: "InizializzaDecoratoreSanzioneAccessorieRimozioneCommand",
  senzaDocumenti: "InizializzaDecoratoreInfrazioneSenzaDocumentiCommand",
  ztl: "InizializzaDecoratoreInfrazioneZtlCommand",
  velocita: "InizializzaDecoratoreInfrazioneVelocitaCommand",
  rosso: "InizializzaDecoratoreInfrazioneRossoCommand"
};
exports.SanzioniToCommandMapping = SanzioniToCommandMapping;