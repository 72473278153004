"use strict";

/**
 * Created by stefano on 13/04/17.
 */
(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name polcity.ricorsi
   *
   * @requires polcity.resources
   * @requires polcity.widgets
   * @requires pascalprecht.translate
   */

  angular.module('polcity.ricorsi', ['pascalprecht.translate']);
})();