"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _app = require("../../../app");

const useUsername = () => _app.keycloakAuth.tokenParsed.preferred_username;

var _default = useUsername;
exports.default = _default;