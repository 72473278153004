"use strict";

(function () {
  'use strict';
  /**
   * Dona la possiblità ad un Model di essere seguito
   */

  angular.module('polcity.models').factory('Followable', ['restmod', function (restmod) {
    var API_FOLLOW = {
      url: 'segui',
      method: 'POST'
    },
        API_UNFOLLOW = {
      url: 'smetti',
      method: 'POST'
    };
    return restmod.mixin({
      $extend: {
        Scope: {
          //Costruttore
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);
            collection.$isFollowable = true;
            angular.extend(this.$config.api, {
              follow: API_FOLLOW,
              unfollow: API_UNFOLLOW
            });
            return collection;
          }
        }
      }
    }, {
      $extend: {
        Record: {
          $follow: function () {
            return this.$action(function () {
              var request = this.$buildRequest("follow", false);
              request.data = Object.assign({}, {
                rifAggId: this.$pk,
                rifEntityId: this.$pk,
                tipoOggettoSeguito: this.$aggregateCanonicalName
              });
              this.$dispatch('before-follow', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(this.$getResponseData(_response));
                this.$dispatch('after-follow', [_response]);
              }, function (_response) {
                this.$dispatch('after-follow-error', [_response]);
              });
            });
          },
          $unfollow: function () {
            return this.$action(function () {
              var request = this.$buildRequest("unfollow", false);
              request.data = Object.assign({}, {
                rifAggId: this.$pk,
                rifEntityId: this.$pk,
                tipoOggettoSeguito: this.$aggregateCanonicalName
              });
              this.$dispatch('before-unfollow', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(this.$getResponseData(_response));
                this.$dispatch('after-unfollow', [_response]);
              }, function (_response) {
                this.$dispatch('after-unfollow-error', [_response]);
              });
            });
          },
          $toggleFollow: function () {
            var params = {
              rifAggId: this.aggId,
              rifEntityId: this.aggId,
              tipoOggettoSeguito: this.$aggregateCanonicalName
            },
                follows = !!this.preferito;
            return follows ? this.$unfollow(params) : this.$follow(params);
          }
        }
      }
    });
  }]);
})();