"use strict";

/**
 * Created by dan on 10/04/17.
 */
(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name Command
   * @module polcity.api
   *
   * @description
   * Provides a mean to execute commands
   */

  angular.module('polcity.api').service('Command', ['CommandsList', 'api', '$rootScope', '$q', 'AcceptHeaders', 'FileSaver', Command]);

  function Command(CommandsList, api, $rootScope, $q, AcceptHeaders, FileSaver) {
    var hasTransformResponse = R.has('transformResponse'),
        hasTransformRequest = R.has('transformRequest');
    return {
      execute: execute,
      executeRaw: executeRaw,
      download: download,
      transformUrlCompatibility: transformUrlCompatibility,
      addResponseFilter: addResponseFilter
    };
    /**
     * Add a callback that will executed .then() the commands data gets retrieved, useful to apply logics
     * on remote data given contextual data. Ex "hide a street if my model already has that street", usage example
     * inside verbaliFromController
     * @param {String} forCommand a command name
     * @param {Function} responseFilter callback that will be called with the response from the server. These callbacks get called in fifo order
     */

    function addResponseFilter(forCommand, responseFilter) {
      var commandRequest = CommandsList.retrieve(forCommand);

      if (!commandRequest) {
        throw new ReferenceError(forCommand);
      }

      if (!commandRequest.responseFilters) {
        commandRequest.responseFilters = [];
      }

      commandRequest.responseFilters.push(responseFilter);
    }
    /**
     * @ngdoc method
     * @name Command#download
     *
     * @description
     * Esegue il donwload di un file e ne ritorna una Promise con la response del server.<br>
     * E' possibile modificare il comportamento del download tramite il `commandOptions` che può avere
     * le seguenti propriertà:
     * <hljs lang="js">
     * commandOptions = {
         *      headers: { Accept: 'application/pdf', CustomHeader: 'myValue'},
         *      filename: 'download-report-name.pdf'
         * }
     * </hljs>
     *
     * Di default vengono applicate le seguenti regole:
     * - Se non viene passato un headers.Accept viene applicato 'application/pdf'
     * - Per la risoluzione del filename viene data precendenza alla proprietà `filename` passata
     * nella `commandOptions`, poi viene cercato dentro header `content-disposition` della risposta
     * altrimenti viene applicato il nome `undefined`
     * - Se non viene passato nessun `responseType` dal comando, viene applicato in automatico `arraybuffer`
     *
     * @usage
     * <hljs lang="js">
     *     Command.download('awesomeCommand', {pi: Math.PI});
     * </hljs>
     *
     * @param {string} commandName the name of the command
     * @param {object} commandData data to pass over the remote apis
     * @param {object} commandOptions the options of the request
     *
     * @returns {Promise}
     */


    function download(commandName, commandData, commandOptions) {
      commandOptions = commandOptions || {};
      var headers = commandOptions.headers || {};

      if (!headers.Accept) {
        headers.Accept = AcceptHeaders.pdf;
      }

      var request = formatRequest(commandName, commandData, headers);

      if (!request.responseType) {
        request.responseType = 'arraybuffer';
      }

      return sendRequest(request).then(function (response) {
        $rootScope.$broadcast(commandName, response);
        return response;
      }).then(function (response) {
        var filename = computeFilename(commandOptions, response),
            data = new Blob([response.data], {
          type: response.headers('content-type')
        });
        FileSaver.saveAs(data, filename);
        return response;
      });
    }

    function computeFilename(commandOptions, response) {
      var filename = commandOptions.filename;

      if (!filename) {
        filename = JSON.parse(R.last((response.headers('content-disposition') || '').split('='))) ? JSON.parse(R.last((response.headers('content-disposition') || '').split('='))) : R.last((response.headers('content-disposition') || '').split('='));
      }

      if (!filename && commandOptions.filenameFallback) {
        filename = commandOptions.filenameFallback;
      }

      if (!filename) {
        console.warn('Unable to compute download file name. Please add Content-Type response header or commandOptions.filename prop');
        filename = 'undefined';
      }

      return filename;
    }
    /**
     * @ngdoc method
     * @name Command#execute
     *
     * @description
     * Recupera il comando, esegue la chiamata e ne estrare l'oggetto <b>data</b> dalla
     * riposta.<br />
     * Al termine fatto un broadcast di `commandName` con la risposta e tornata anche una
     * promise.
     *
     * @usage
     * <hljs lang="js">
     *     Command.execute('awesomeCommand', {pi: Math.PI});
     * </hljs>
     *
     * @param {string} commandName the name of the command
     * @param {object} commandData data to pass over the remote apis
     * @param {object} commandHeaders headers to pass over the remote apis
     *
     * @returns {Promise}
     */


    function execute(commandName, commandData, commandHeaders) {
      return executeRaw(commandName, commandData, commandHeaders).then(R.prop('data')).then(function (response) {
        var commandRequest = CommandsList.retrieve(commandName);

        if (commandRequest.responseFilters) {
          R.forEach(function (responseFilter) {
            response = responseFilter(response);
          }, commandRequest.responseFilters);
        }

        return response;
      }).then(function (response) {
        $rootScope.$broadcast(commandName, response);
        return response;
      });
    }

    function executeRaw(commandName, commandData, commandHeaders, commandOptions) {
      try {
        return sendRequest(formatRequest(commandName, commandData, commandHeaders, commandOptions));
      } catch (ex) {
        return $q.reject(ex);
      }
    }

    function sendRequest(request) {
      try {
        return api.perform(request);
      } catch (ex) {
        return $q.reject(ex);
      }
    }

    function formatRequest(commandName, commandData, commandHeaders, commandOptions) {
      var commandRequest = CommandsList.retrieve(commandName);

      if (!commandRequest) {
        throw new ReferenceError(commandName);
      }

      var request = commandRequestToRequest(commandRequest);
      request = setResponseType(request, commandRequest);
      request = replaceParamsInUrl(request, commandRequest, commandData);
      var dataProp = commandRequest.method === 'GET' ? 'params' : 'data';
      request = addCommandData(request, dataProp, commandData);
      request = addRequestParams(request, commandRequest, dataProp);
      request = addResponseTransformer(request, commandRequest, commandHeaders);
      request = addRequestTransformer(request, commandRequest);
      request = addRequestParamSerializer(request, commandRequest);
      request = addRequestOptions(request, commandOptions);

      if (commandHeaders) {
        request.headers = commandHeaders;
      }

      return request;
    }

    function addRequestOptions(request, commandOptions) {
      for (var opt in commandOptions) {
        request[opt] = commandOptions[opt];
      }

      return request;
    }

    function addCommandData(request, dataProp, commandData) {
      //if (commandData && R.keys(commandData).length > 0) {
      request[dataProp] = commandData; //}

      return request;
    }

    function commandRequestToRequest(commandRequest) {
      return {
        url: commandRequest.url,
        method: commandRequest.method || 'GET'
      };
    }

    function addRequestParams(request, commandRequest, dataProp) {
      if (commandRequest[dataProp]) {
        request[dataProp] = R.merge(request[dataProp], commandRequest[dataProp]);
      }

      return request;
    }

    function setResponseType(request, commandRequest) {
      if (commandRequest.responseType) {
        request.responseType = commandRequest.responseType;
      }

      return request;
    }

    function addRequestTransformer(request, commandRequest) {
      if (hasTransformRequest(commandRequest)) {
        request.transformRequest = commandRequest.transformRequest;
      }

      return request;
    }

    function addRequestParamSerializer(request, commandRequest) {
      if (commandRequest.paramSerializer) {
        request.paramSerializer = commandRequest.paramSerializer;
      }

      return request;
    }

    function addResponseTransformer(request, commandRequest, commandHeaders) {
      if (hasTransformResponse(commandRequest)) {
        if (commandHeaders && commandHeaders.responseType && commandHeaders.responseType === 'arraybuffer') {
          request.transformResponse = commandRequest.transformResponse;
        } else {
          request.transformResponse = R.pipe(angular.fromJson, commandRequest.transformResponse);
        }
      }

      return request;
    }

    function replaceParamsInUrl(request, commandRequest, commandData) {
      if (commandRequest.replaceParams) {
        var objKeys = R.keys(commandData);
        request.url = transformUrlCompatibility(commandRequest.url, commandData);
        R.forEach(replaceInUrl, objKeys);
      }

      return request;

      function replaceInUrl(key) {
        if (request.url.indexOf(':' + key) > -1) {
          request.url = request.url.replace(':' + key, commandData[key]);
          commandData = R.omit([key], commandData);
        }
      }
    } //From RestService


    function transformUrlCompatibility(url, item) {
      if (item) {
        return url.replace(/({[\w]+})/g, function (path) {
          var propName = path.replace(/[{}]/g, '');

          if (item.hasOwnProperty(propName)) {
            return item[propName];
          }
        });
      } else {
        return url;
      }
    }
  }
})();