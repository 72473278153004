"use strict";

(function () {
  'use strict';

  angular.module('polcity.dashboard').controller('WidgetCalendar', ['$scope', 'api', '$state', 'CALENDAR_DEFAULTS', Calendar]);

  function Calendar($scope, api, $state, CALENDAR_DEFAULTS) {
    var me = this;
    CALENDAR_DEFAULTS.locale = "it";
    /**
     * Opzioni di configurazione del fullCalendar
     */

    $scope.calendarOptions = {
      /*eventClick: function(calEvent, jsEvent, view) {
          var verbaleAggId = calEvent.aggId;
           $state.go("navigation.verbalidetail", {aggId: verbaleAggId});
      },*/
      eventClick: function (info) {
        alert('Event: ' + info.event.title);
        alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
        alert('View: ' + info.view.type); // change the border color just for fun

        info.el.style.borderColor = 'red';
      }
    };
    /**
     * Lista di event series del fullCalendar
     */

    $scope.eventSources = [];

    me.$onInit = function () {
      fetchVerbaliPericolo().then(function (verbali) {
        var eventSourcer = generateEventSources(verbali.resultsWrapper.values);
        $('div.fc').fullCalendar('addEventSource', {
          events: eventSourcer.dangerEvents,
          color: 'red',
          textColor: 'black'
        });
        $('div.fc').fullCalendar('addEventSource', {
          events: eventSourcer.warningEvents,
          color: 'yellow',
          textColor: 'black'
        });
        $('div.fc').fullCalendar('addEventSource', {
          events: eventSourcer.greenEvents,
          color: 'green',
          textColor: '#fff'
        });
      });
    };

    function fetchVerbaliPericolo() {
      return api.execute({
        method: 'GET',
        url: 'dashboard/ricerca/verbali-pericolo'
      });
    }

    ;

    function generateEventSources(verbali) {
      var dangerEvents = [],
          warningEvents = [],
          greenEvents = [];
      R.map(function (verbale) {
        var dataScadenza = moment(verbale.dataOraVerbale).add(90, 'days'),
            event = {
          title: verbale.idVerbale,
          start: moment(verbale.dataOraVerbale).add(90, 'days'),
          allDay: true,
          aggId: verbale.aggId
        };

        if (dataScadenza.isBefore(moment()) || dataScadenza.isBetween(moment(), moment().add(10, 'days'))) {
          dangerEvents.push(event);
        } else if (dataScadenza.isBetween(moment().add(11, 'days'), moment().add(20, 'days'))) {
          warningEvents.push(event);
        } else {
          greenEvents.push(event);
        }
      }, verbali);
      return {
        dangerEvents: dangerEvents,
        warningEvents: warningEvents,
        greenEvents: greenEvents
      };
    }
  }
})();