"use strict";

/**
 * GB 10/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaComune', [RicercaComune]);

  function RicercaComune() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaComune'],
      scope: {
        entity: '=?',
        label: '@?',
        onComuneChange: '<?',
        isRequired: '<?',
        name: '@?',
        afterInsert: '<?'
      },
      controller: ['$scope', '$filter', '$mdDialog', 'Command', '$controller', controllerFn],
      controllerAs: 'ricercaComuneCtrl',
      templateUrl: 'osFramework/input/ricercaComune/ricercaComune.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter, $mdDialog, Command, $controller) {
      var me = this;
      me.groupRoles = ['ROLE_COMUNE_RICERCA', 'ROLE_COMUNE_DETTAGLIO'];

      me.getItemText = function (comune) {
        var itemText;
        itemText = $filter('translatable')(comune.nome);
        return itemText;
      };

      me.nuovoComune = function () {
        $mdDialog.show({
          templateUrl: 'osFramework/input/ricercaComune/modal/insertEditComune.modal.html',
          fullscreen: false,
          escapeToClose: true,
          controller: 'GestioneDelTerritorioFormController',
          controllerAs: 'gestioneDelTerritorioFormCtrl',
          bindToController: true,
          focusOnOpen: true,
          locals: {
            hide: $mdDialog.cancel,
            save: $mdDialog.hide
          }
        }).then(function (comune) {
          return salvaComune(comune);
        }).then(function (comune) {
          if (scope.afterInsert) {
            scope.afterInsert(comune);
          }
        }).catch(function (e) {
          console.log(e);
        });

        function salvaComune(comune) {
          return Command.execute('@salvaComuneGestioneDelTerritorio', comune);
        }
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaComuneCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.comuneAggId = null;
          return;
        }

        scope.comuneAggId = viewValue;
      };

      scope.$watch('comuneAggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();