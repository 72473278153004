"use strict";

/**
 * MN 13/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaCodiceLegge', [RicercaCodiceLegge]);

  function RicercaCodiceLegge() {
    return {
      restrict: 'E',
      require: ['ngModel'],
      scope: {
        entity: '=?',
        label: '@?',
        onRicercaCodiceLeggeChange: '<?',
        isRequired: '<?',
        isReadonly: '<?',
        name: '@?'
      },
      controller: ['$scope', '$filter', controllerFn],
      controllerAs: 'ricercaCodiceLeggeCtrl',
      templateUrl: 'osFramework/input/ricercaCodiceLegge/ricercaCodiceLegge.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter) {
      var me = this;
      me.groupRoles = ['ROLE_LEGGE_RICERCA', 'ROLE_LEGGE_DETTAGLIO'];

      me.getItemText = function (legge) {
        var itemText;
        itemText = legge.codiceLegge.concat(' - ', legge.descrizione);
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.aggId = null;
          return;
        }

        scope.aggId = viewValue;
      };

      scope.$watch('aggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();