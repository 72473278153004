"use strict";

/**
 * MN 03/09/2019
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaEntitaPagabili', [RicercaEntitaPagabili]);

  function RicercaEntitaPagabili() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaEntitaPagabili'],
      scope: {
        entity: '=?',
        label: '@?',
        onSelectedEntity: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'ricercaEntitaPagabiliCtrl',
      templateUrl: 'osFramework/input/ricercaEntitaPagabili/ricercaEntitaPagabili.directive.html',
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this;
      me.groupRoles = ['ROLE_PAYABLE_ITEM_RICERCA_TESTO_LIBERO'];

      me.getItemText = function (payable) {
        var itemText;
        itemText = payable.payableLabel.toUpperCase();
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaEntitaPagabiliCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.payId = null;
          return;
        }

        scope.payId = viewValue;
      };

      scope.$watch('payId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();