"use strict";

/**
 * Created by MN 18/07/2018
 */
(function () {
  'use strict';

  angular.module('polcity.soggetti', []).config(['$translatePartialLoaderProvider', function ($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('soggetti');
  }]);
})();