"use strict";

/**
 * Created by MN 11/07/2019
 */
(function () {
  'use strict';

  angular.module('polcity.acquisizioneDati').controller('ListaRilevazioniModalController', ['$scope', 'Command', '$mdDialog', '$state', 'modalPresenter', 'OSNotification', 'rilevazioni', 'showBulkActions', 'showRedirectToVerbaleBtn', 'additionalColumn', listaRilevazioniCtrl]);

  function listaRilevazioniCtrl($scope, Command, $mdDialog, $state, modalPresenter, OSNotification, rilevazioni, showBulkActions, showRedirectToVerbaleBtn, additionalColumn) {
    var me = this;
    $scope.rilevazioni = rilevazioni;
    $scope.rilevazioniColumnTable = [{
      text: 'Creazione rilevazione',
      dataIndex: 'dataCreazioneRilevazione',
      renderer: function (item) {
        return moment(item.dataCreazioneRilevazione).format('LL');
      }
    }, {
      text: 'Data rilevazione',
      dataIndex: 'dataRilevazioneInfrazione',
      sortable: true,
      renderer: function (item) {
        return item.dataRilevazioneInfrazione ? moment(item.dataRilevazioneInfrazione).format('LL - hh:mm:ss') : '--';
      }
    }, {
      text: 'Tipo rilevatore',
      dataIndex: 'tipoRilevatore',
      sortable: true,
      renderer: function (item) {
        return item.tipoRilevatore;
      }
    }, {
      text: 'Matricola rilevatore',
      dataIndex: 'matricolaRilevatore',
      renderer: function (item) {
        return item.matricolaRilevatore;
      }
    }, {
      text: 'Marca / Modello rilevatore',
      dataIndex: 'marcaModelloRilevatore',
      renderer: function (item) {
        return item.marcaModelloRilevatore;
      }
    }, {
      text: 'Descrizione rilevatore',
      dataIndex: 'marcaModelloRilevatore',
      renderer: function (item) {
        return item.descrizioneRilevatore;
      }
    }
    /*,
    {
       text     : 'Nome file originale importato',
       dataIndex: 'nomeFileOriginaleImportato'
    },
    {
       text     : 'Nome cartella allegati',
       dataIndex: 'nomeCartellaAllegati'
    }*/
    ];
    $scope.enableBulkActions = showBulkActions;
    $scope.enableRowSelect = !!$scope.enableBulkActions;
    $scope.rilevazioniColumnTable = additionalColumn ? R.concat(additionalColumn, $scope.rilevazioniColumnTable) : $scope.rilevazioniColumnTable;
    $scope.rilevazioni.$on('before-fetch-many', function (res) {
      $scope.isLoadingList = true;
    });
    $scope.rilevazioni.$on('after-fetch-many', function (res) {
      $scope.isLoadingList = false;
      $scope.page = $scope.rilevazioni.$metadata.search.page;
      $scope.limit = $scope.rilevazioni.$metadata.search.limit;
      $scope.total = $scope.rilevazioni.$metadata.total;
    });
    $scope.rilevazioni.$on('after-fetch-many-error', function (res) {
      $scope.isLoadingList = false;
    });
    $scope.rilevazioni.$on('after-destroy', function () {
      $scope.rilevazioni.$ricercaVeloce();
    });
    /**
     * osTable
     */

    /**
     * @property
     * Handler da eseguire al cambio dell'ordinamento di una colonna
     * @param order
     */

    $scope.onOrderChange = function (order) {
      let orderSplitted = order.split(','),
          orderObj = {
        key: orderSplitted[0],
        direction: orderSplitted[1]
      };
      $scope.rilevazioni.$addSorter(orderObj);
    };

    $scope.onPageChange = function (params) {
      $scope.page = params.page;
      $scope.limit = params.limit;
      $scope.rilevazioni.$changePage($scope.page, $scope.limit);
    };

    $scope.rilevazioniTableActionColumn = showRedirectToVerbaleBtn ? [{
      type: 'navigation',
      icon: {
        iconName: 'arrow_forward'
      },
      tooltip: 'Vai al verbale',
      role: 'ROLE_VERBALE_DETTAGLIO',
      callback: function (rilevazione) {
        navigateToVerbale(rilevazione);
      }
    }] : [{
      type: 'delete',
      icon: {
        iconName: 'delete'
      },
      tooltip: 'Elimina rilevazione',
      role: 'ROLE_RILEVAZIONE_CANCELLA',
      callback: function (rilevazione) {
        deleteRilevazione(rilevazione);
      }
    }];
    $scope.rilevazioniTableBulkActions = [{
      type: 'inzializza',
      icon: {
        iconName: 'build'
      },
      tooltip: 'Inzializza rilevazione',
      role: 'ROLE_VERBALIZZAZIONE_START_PROCESS',
      callback: function (rilevazioni) {
        inizializzaProcessoVerbalizzazione(rilevazioni);
      }
    }];

    function inizializzaProcessoVerbalizzazione(rilevazioni) {
      var aggIds = [],
          rilevazioniPerStartProcess = [],
          rilevazioniPerREStartProcess = [];
      rilevazioniPerStartProcess = rilevazioni.ngSelectedRowModel.filter(rilevazione => rilevazione.milestone === 'rilevazione_acquisita');
      rilevazioniPerREStartProcess = rilevazioni.ngSelectedRowModel.filter(rilevazione => rilevazione.milestone === 'fallito_start_processo');

      if (rilevazioniPerStartProcess.length) {
        aggIds = rilevazioniPerStartProcess.map(ril => {
          return ril.aggId;
        });
        rilevazioni.data.$inizializzaProcessoVerbalizzazione(aggIds);
      } else if (rilevazioniPerREStartProcess.length) {
        aggIds = rilevazioniPerREStartProcess.map(ril => {
          return ril.aggId;
        });
        rilevazioni.data.$reInizializzaProcessoVerbalizzazione(aggIds);
      }

      R.forEach(function (item) {
        item.$removeFromList();
      }, rilevazioni.ngSelectedRowModel);
      rilevazioni.ngSelectedRowModel = [];
      closeDialog();
    }

    function deleteRilevazione(rilevazione) {
      var rilevazioneId = [rilevazione.aggId];
      askUserConfirm('warn', 'acquisizioneDati.rilevazioni.cancellaRilevazioneModal__title', 'acquisizioneDati.rilevazioni.cancellaRilevazioneModal__body', 'acquisizioneDati.rilevazioni.cancellaRilevazioneModal__confirm_button').then(deleteRilevazioneApiCall.bind(me, rilevazione)).then(afterDeletion).catch(function (err) {
        if (err) {
          notifyUser('warn', 'acquisizioneDati.rilevazioni.cancellaRilevazioneError__notification');
        }
      });
    }
    /**
     * Apis
     */


    function deleteRilevazioneApiCall(rilevazione) {
      rilevazione.$destroy();
    }
    /**
     * Utils
     */


    function askUserConfirm(type, title, body, confirmBtn) {
      return modalPresenter[type]([title, body, confirmBtn, 'acquisizioneDati.rilevazioni.cancellaRilevazioneModal__undo_button']);
    }

    function notifyUser(type, msg) {
      return OSNotification[type]({
        translationIndex: msg
      });
    }

    function afterDeletion() {
      notifyUser('success', 'acquisizioneDati.rilevazioni.cancellaRilevazioneSuccess__notification');
    }

    $scope.closeDialog = closeDialog;

    function closeDialog() {
      $scope.page = 1;
      $scope.limit = 10;
      $mdDialog.hide();
    }

    function navigateToVerbale(rilevazione) {
      closeDialog();
      $state.go('navigation.verbalidetail', {
        aggId: rilevazione.aggId
      });
    }
  }
})();