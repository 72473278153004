"use strict";

(function () {
  'use strict';

  angular.module('polcity.dashboard').controller('WidgetProcedimentoSanzionatorio', ['$scope', 'api', '$state', WidgetProcedimentoSanzionatorio]);

  function WidgetProcedimentoSanzionatorio($scope, api, $state) {
    var me = this;
    /**
     * Lista dei verbali seguiti
     */

    me.data;

    me.onListItemClick = function (verbale) {
      var verbaleAggId = verbale.rifAggId;
      $state.go("navigation.verbalidetail", {
        aggId: verbaleAggId
      });
    };

    fetchDashboardData().then(function (result) {
      setDashboardData(result);
    });
    /**************************
     * Setters
     ***************************/

    function setDashboardData(data) {
      me.data = data;
    }
    /**************************
     * Fetchers & Apis
     **************************/


    function fetchDashboardData() {
      return api.execute({
        method: 'POST',
        url: 'dashboard',
        data: {
          anno: '2019'
        }
      });
    }

    ;
  }
})();