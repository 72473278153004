"use strict";

var _jquery = _interopRequireDefault(require("jquery"));

var _Map = _interopRequireDefault(require("ol/Map"));

var _View = _interopRequireDefault(require("ol/View"));

var _proj = require("ol/proj");

var _map = require("./map");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('osMapField').directive('latLonField', [latLonFieldDirective]);

function latLonFieldDirective() {
  return {
    restrict: 'E',
    scope: {
      lat: '=',
      lon: '='
    },
    templateUrl: 'osFramework/osMapField/osMapField.directive.html',
    link: postLink
  };

  function postLink(scope, element) {
    var map = new _Map.default({
      layers: [_map.rasterLayer, _map.vectorLayer],
      target: (0, _jquery.default)('.map')[0],
      view: new _View.default({
        center: (0, _proj.fromLonLat)([scope.lon, scope.lat]),
        zoom: 16
      })
    });
    scope.$watchGroup(['lat', 'lon'], ([lat, lon]) => {
      (0, _map.removeAllMarkers)(_map.vectorSource);
      (0, _map.addMarker)(_map.vectorSource, lon, lat); //re-center the map

      map.getView().animate({
        center: (0, _proj.fromLonLat)([lon, lat]),
        duration: 2000
      });
    });
  }
}