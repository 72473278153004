"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stazioneComandoValidationSchema = void 0;

var Yup = _interopRequireWildcard(require("yup"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const stazioneComandoValidationSchema = Yup.object().shape({
  codiceUnivoco: Yup.string().required("Questo campo è obbligatorio"),
  riferimentoComune: Yup.object().required("Questo campo è obbligatorio").nullable(),
  indirizzo: Yup.string().required("Questo campo è obbligatorio"),
  descrizione: Yup.string().required("Questo campo è obbligatorio"),
  idEnteAccertatore: Yup.string().length(6, "Questo campo deve essere di 6 caratteri").required("Questo campo è obbligatorio"),
  codiceSana: Yup.string().required("Questo campo è obbligatorio")
});
exports.stazioneComandoValidationSchema = stazioneComandoValidationSchema;