"use strict";

/**
 * Created by MN 01/08/2018
 */
(function () {
  'use strict';

  angular.module('polcity.acquisizioneDati', ['osCollectionCarousel']).config(['$translatePartialLoaderProvider', function ($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('acquisizioneDati');
  }]);
})();