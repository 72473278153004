"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dettaglioTipoAccertamento = exports.ricercaTipiAccertamento = void 0;
const ricercaTipiAccertamento = {
  "search": {
    "page": 1,
    "order": "aggId,asc",
    "limit": 1000,
    "aggId": null,
    "codiceVeloce": null,
    "nome": null,
    "descrizione": null,
    "annotazioneObbligatoria": null,
    "tipoVerbale": null,
    "modelloStampaId": null,
    "motivazioneMancataContestazioneId": null,
    "registroSeparato": null,
    "storedList": null,
    "excludeDb": false,
    "explodeId": false
  },
  "resultsWrapper": {
    "values": [{
      "aggId": "9143eaaa-5d99-ed90-6b54-14f609ede7e0",
      "codiceVeloce": "t",
      "nome": "test",
      "descrizione": "test",
      "tempoRilevazione": 0,
      "annotazioneObbligatoria": false,
      "tipoVerbale": "preavviso_cds",
      "contatoreVerbaleUfficio": null,
      modelloStampa: {
        aggId: "bb7d8fec-84b8-4237-ae30-99e92645ba6a",
        codice: "ART_80",
        descrizione: "Modello di stampa per Art.80"
      },
      motivazioneMancataContestazione: {
        aggId: "595a333b-cc32-4cbc-b75d-d6917e7b5936",
        codiceVeloce: "007",
        descrizione: "Agente impegnato in servizio scuole",
        descrizioneStampa: [{
          languageTag: "it_IT",
          traduzione: "Agente impegnato in servizio scuole"
        }],
        descrizioneStampa_transId: "595a333b-cc32-4cbc-b75d-d6917e7b5936"
      }
    }, {
      "aggId": "a851dfdf-4e6d-6b9c-6be6-8a5ce68c5879",
      "codiceVeloce": "p",
      "nome": "preavviso",
      "descrizione": "preavviso",
      "tempoRilevazione": 0,
      "annotazioneObbligatoria": true,
      "tipoVerbale": "preavviso_cds",
      "contatoreVerbaleUfficio": null,
      "modelloStampa": {
        aggId: "65f67482-77e5-45e2-aba6-2d3f1d99eed1",
        codice: "ART_193",
        descrizione: "Modello di stampa per Art.193"
      },
      "motivazioneMancataContestazione": {
        aggId: "8c177347-17d8-4925-a12c-d044a0c13a02",
        codiceVeloce: "001",
        descrizione: "Impossibilità di raggiungere il veicolo",
        descrizioneStampa: [{
          languageTag: "it_IT",
          traduzione: "Impossibilità di raggiungere il veicolo"
        }],
        descrizioneStampa_transId: "8c177347-17d8-4925-a12c-d044a0c13a02"
      }
    }],
    "count": 2,
    "links": []
  },
  "filters": []
};
exports.ricercaTipiAccertamento = ricercaTipiAccertamento;
const dettaglioTipoAccertamento = {
  "aggId": "9143eaaa-5d99-ed90-6b54-14f609ede7e0",
  "codiceVeloce": "t",
  "nome": "test",
  "descrizione": "test",
  "tempoRilevazione": 0,
  "annotazioneObbligatoria": false,
  "tipoVerbale": "preavviso_cds",
  "contatoreVerbaleUfficio": null,
  modelloStampa: {
    aggId: "bb7d8fec-84b8-4237-ae30-99e92645ba6a",
    codice: "ART_80",
    descrizione: "Modello di stampa per Art.80"
  },
  motivazioneMancataContestazione: {
    aggId: "595a333b-cc32-4cbc-b75d-d6917e7b5936",
    codiceVeloce: "007",
    descrizione: "Agente impegnato in servizio scuole",
    descrizioneStampa: [{
      languageTag: "it_IT",
      traduzione: "Agente impegnato in servizio scuole"
    }],
    descrizioneStampa_transId: "595a333b-cc32-4cbc-b75d-d6917e7b5936"
  }
};
exports.dettaglioTipoAccertamento = dettaglioTipoAccertamento;