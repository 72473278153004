"use strict";

(function () {
  'use strict';

  angular.module('polcity.dashboard').controller('WidgetVerbaliSeguiti', ['$scope', 'api', '$state', WidgetVerbaliSeguiti]);

  function WidgetVerbaliSeguiti($scope, api, $state) {
    var me = this;
    /**
     * Lista dei verbali seguiti
     */

    me.verbali = [];

    me.onListItemClick = function (verbale) {
      var verbaleAggId = verbale.rifAggId;
      $state.go("navigation.verbalidetail", {
        aggId: verbaleAggId
      });
    };

    fetchVerbaliSeguiti().then(function (result) {
      setVerbali(result.resultsWrapper.values);
    });
    /**************************
     * Setters
     ***************************/

    function setVerbali(verbali) {
      me.verbali = verbali;
    }
    /**************************
     * Fetchers & Apis
     **************************/


    function fetchVerbaliSeguiti() {
      return api.execute({
        method: 'GET',
        url: 'dashboard/ricerca/verbali-seguiti'
      });
    }

    ;
  }
})();