"use strict";

/**
 * Created by dan on 10/01/17.
 */
(function () {
  'use strict';

  angular.module('polcity.dashboard').config(['$stateProvider', '$urlRouterProvider', 'dashboardRoutesProvider', 'AuthProvider', routesConfig]).run(['dashboardRoutes', routesRun]);

  function routesConfig($stateProvider, $urlRouterProvider, dashboardRoutesProvider, AuthProvider) {
    var redirectUrl = '';
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (routeDefinition.isHome) {
        redirectUrl = routeDefinition.url;
      }

      if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }
    }, dashboardRoutesProvider.list());

    if (redirectUrl) {
      $urlRouterProvider.otherwise('/' + redirectUrl);
    }
  }

  function routesRun(dashboardRoutes) {
    dashboardRoutes.subscribeUIEvents();
  }
})();