"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _formik = require("formik");

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const LottoEffects = () => {
  const {
    values,
    touched,
    setValues,
    setFieldValue,
    setFieldTouched,
    isSubmitting
  } = (0, _formik.useFormikContext)();
  touched.tipoLotto = touched.tipoLotto || {}; // Quando cambia il tipo lotto se è uguale a lettera_preruolo il lotto vuoto deve essere settato a false

  (0, _react.useEffect)(() => {
    if (touched.tipoLotto && values.tipoLotto === 'lettera_preruolo' && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        lottoVuoto: false
      }));
    } else if (touched.tipoLotto && values.tipoLotto !== 'lettera_preruolo' && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        preRuoloFilters: {
          rangeDataVerbale: {
            startDate: null,
            endDate: null
          },
          tipoAccertamento: null,
          statiVerbale: null
        }
      }));
      delete touched.preRuoloFilters;
    }
  }, [values.tipoLotto]);
  return null;
};

var _default = LottoEffects;
exports.default = _default;