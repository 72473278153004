"use strict";

angular.module('polcity.ricorsi').component('ricorsiList', {
  templateUrl: 'ricorsi/ricorsi.list.component.html',
  controller: 'RicorsiListController',
  controllerAs: 'RicorsiListController'
}).controller('RicorsiListController', ['$scope', 'Ricorso', '$mdDialog', 'Command', '$stateParams', RicorsiListController]);

function RicorsiListController($scope, Ricorso, $mdDialog, Command, $stateParams) {
  var me = this;
  /**
   * Confiurazioni iniziali della collection Ricorso
   */

  $scope.ricorsi = Ricorso.$collection({
    page: 1,
    limit: 10
  });
  $scope.ricorsi.$on('before-fetch-many', function (res) {
    $scope.isLoadingList = true;
  });
  $scope.ricorsi.$on('after-fetch-many', function (res) {
    $scope.isLoadingList = false;
    $scope.page = $scope.ricorsi.$metadata.search.page;
    $scope.limit = $scope.ricorsi.$metadata.search.limit;
    $scope.total = $scope.ricorsi.$metadata.total;
    $scope.filters = [{
      name: 'numeroProtocollo',
      label: 'Numero contatore protocollo',
      type: 'number'
    }, {
      name: 'dataRicorso',
      label: 'Data ricoso',
      type: 'dateRange'
    }, {
      name: 'statoRicorso',
      label: 'Stato ricorso',
      type: 'enum',
      enumType: 'statiRicorso'
    }, {
      name: 'idVerbali',
      queryProp: 'aggId',
      label: 'Verbale',
      type: 'ricercaLiberaVerbali'
    }];
    $scope.activeFilters = $scope.ricorsi.$getActiveFilters();
    $scope.activeSorters = $scope.ricorsi.$getActiveSorters();
    $scope.ricorsi.$saveState();
  });
  $scope.ricorsi.$on('after-fetch-many-error', function (res) {
    $scope.isLoadingList = false; //TODO: do something with errors
  });

  $scope.onImportaSana = function ($event) {
    $mdDialog.show({
      controller: ['OSNotification', function (OSNotification) {
        this.onClose = function () {
          $mdDialog.hide();
        };

        this.inviaTracciatoSana = function (file) {
          var fd = new FormData(),
              fileToUpload = file[0].lfFile;
          fd.append('tracciato', fileToUpload);
          Command.executeRaw('@caricaTracciatoSana', fd, {
            'Content-Type': undefined
          }) //this do the magic of multipart
          .then(function () {
            OSNotification['success']({
              translationIndex: 'ricorsi.uploadSanaSuccess'
            });
            $mdDialog.hide();
            $scope.ricorsi.$refresh();
          }).catch(function (err) {
            OSNotification['error']({
              translationIndex: 'ricorsi.uploadSanaError'
            });
          });
        };
      }],
      controllerAs: 'ImportSanaCtrl',
      template: '' + '<md-dialog style="width: 500px; height: 400px">' + '<md-toolbar class="mdToolbar" layout="row">' + '    <div class="md-toolbar-tools" >' + '        <h2 flex><translate>ricorsi.caricaTracciatoSana</translate></h2>' + '    </div>' + '</md-toolbar>' + '<md-dialog-content class="md-padding" flex>' + '<div class="mb20"><translate>ricorsi.selezionaTracciatoSana</translate></div>' + '<lf-ng-md-file-input lf-files="files" lf-placeholder="{{\'ricorsi.tracciato\' | translate}}" lf-browse-label="Seleziona" lf-remove-label="Rimuovi"> </lf-ng-md-file-input>' + '</md-dialog-content>' + '<md-dialog-actions layout="row">' + '      <span flex></span>' + '      <md-button ng-click="ImportSanaCtrl.onClose()"><translate>ricorsi.annulla</translata></md-button>' + '      <md-button class="md-primary md-raised" ng-click="ImportSanaCtrl.inviaTracciatoSana(files)"><translate>ricorsi.carica</translata></md-button>' + '</md-dialog-actions>' + '</md-dialog>',
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      fullscreen: false
    }).then(function (answer) {}, function () {});
  };

  me.$onInit = function () {
    var state,
        params = {},
        selectedItem = null; //Ripristino lo stato precedente

    if ($stateParams.reloadPreviousState) {
      state = $scope.ricorsi.$getState();
      params = state.search || {};
      selectedItem = state.selectedItem || null;
      $scope.ricorsi.$initFilter(state.filters, state.elasticFilters);
    }

    $scope.ricorsi.$fetch(params).$then(function () {
      if (selectedItem) {
        $scope.selectedRicorso = Ricorso.$new(selectedItem.aggId);
      }
    });
  };
  /*******************************
   * osList configuration
   *******************************/


  $scope.elasticSearch = false;
  $scope.sorters = {
    numeroProtocollo: {
      showEmptyOption: true,
      direction: null
    },
    dataRicorso: {
      showEmptyOption: true,
      direction: null
    },
    statoRicorso: {
      showEmptyOption: true,
      direction: null
    },
    dataPresentazione: {
      showEmptyOption: false,
      direction: null
    }
  };
  $scope.enableFilters = true; //Handlers

  $scope.onChildTap = function (params) {
    $scope.ricorsi.$saveState();
  };

  $scope.onRemoveItemSelection = function (params) {
    $scope.ricorsi.$saveState();
  };

  $scope.onSortAdd = function (params) {};

  $scope.onSortRemove = function (params) {};

  $scope.onPageChange = function (params) {};

  $scope.onFilterAdd = function (params) {};

  $scope.onFilterRemove = function (params) {};

  $scope.onFilterReset = function () {};

  $scope.onToggleFollow = function () {};
}