"use strict";

var _RegistrazioniContabili = _interopRequireDefault(require("./RegistrazioniContabili"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.registrazioniContabili').provider('registrazioniContabiliRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'registrazioniContabili',
    abstract: true,
    url: 'registrazioniContabili?{dataDa:int}&{dataA:int}&{page:int}&{limit:int}',
    params: {
      dataDa: null,
      dataA: null
    },
    templateUrl: 'registrazioniContabili/registrazioniContabili.html'
  }, {
    name: 'registrazioniContabili.list',
    url: '',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _RegistrazioniContabili.default
      }
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('registrazioniContabili.registrazioniContabili__bcLabel');
      }]
    }
  }];
  routesProviderFactory.extend(this);
}