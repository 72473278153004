"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rasterLayer = exports.vectorLayer = exports.vectorSource = exports.removeAllMarkers = exports.removeMarker = exports.addMarker = void 0;

require("ol/ol.css");

var _Feature = _interopRequireDefault(require("ol/Feature"));

var _Point = _interopRequireDefault(require("ol/geom/Point"));

var _Vector = _interopRequireDefault(require("ol/source/Vector"));

var _style = require("ol/style");

var _layer = require("ol/layer");

var _proj = require("ol/proj");

var _OSM = _interopRequireDefault(require("ol/source/OSM"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const createMarker = (lon, lat) => {
  const feature = new _Feature.default({
    geometry: new _Point.default((0, _proj.fromLonLat)([lon, lat]))
  });
  feature.setStyle(new _style.Style({
    image: new _style.Icon({
      crossOrigin: 'anonymous',
      scale: 0.2,
      src: 'https://openlayers.org/en/latest/examples/data/bigdot.png'
    })
  }));
  return feature;
};

const addMarker = (source, lon, lat) => {
  source.addFeature(createMarker(lon, lat));
};

exports.addMarker = addMarker;

const removeMarker = (source, marker) => {
  source.removeFeature(marker);
};

exports.removeMarker = removeMarker;

const removeAllMarkers = source => {
  source.clear();
};

exports.removeAllMarkers = removeAllMarkers;
const vectorSource = new _Vector.default({//Empty source
  //features: [london],
});
exports.vectorSource = vectorSource;
const vectorLayer = new _layer.Vector({
  source: vectorSource
});
exports.vectorLayer = vectorLayer;
const rasterLayer = new _layer.Tile({
  source: new _OSM.default()
});
exports.rasterLayer = rasterLayer;