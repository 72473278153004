"use strict";

/**
 * GB 10/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaStato', ['$mdDialog', 'Command', RicercaStato]);

  function RicercaStato($mdDialog, Command) {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaStato'],
      scope: {
        entity: '=?',
        label: '@?',
        onStatoChange: '<?',
        isRequired: '<?',
        name: '@?',
        afterInsert: '<?',
        inceptionMode: '<?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'ricercaStatoCtrl',
      templateUrl: 'osFramework/input/ricercaStato/ricercaStato.directive.html',
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this;
      me.groupRoles = ['ROLE_STATO_RICERCA', 'ROLE_STATO_DETTAGLIO'];

      me.getItemText = function (stato) {
        var itemText;
        itemText = stato.nome.toUpperCase();
        return itemText;
      };

      me.nuovaNazione = function () {
        $mdDialog.show({
          templateUrl: 'osFramework/input/ricercaStato/modal/insertEditStato.modal.html',
          fullscreen: false,
          escapeToClose: true,
          controller: 'NazioniFormController',
          controllerAs: 'nazioniFormCtrl',
          bindToController: true,
          focusOnOpen: true,
          multiple: true,
          locals: {
            hide: $mdDialog.cancel,
            save: $mdDialog.hide,
            inceptionMode: scope.inceptionMode
          }
        }).then(function (nazione) {
          return salvaNazione(nazione);
        }).then(function (nazione) {
          if (scope.afterInsert) {
            scope.afterInsert(nazione);
          }
        }).catch(function (e) {
          console.log(e);
        });

        function salvaNazione(nazione) {
          return Command.execute('@salvaNazione', nazione);
        }
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaStatoCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.statoAggId = null;
          return;
        }

        scope.statoAggId = viewValue;
      };

      scope.$watch('statoAggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();