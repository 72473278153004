"use strict";

angular.module('polcity.scartatiDaRuoli').config(['$stateProvider', 'scartatiDaRuoliRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['scartatiDaRuoliRoutes', routesRun]);

function addRoutes($stateProvider, scartatiDaRuoliRoutesProvider, $translatePartialLoader, AuthProvider) {
  R.forEach(function (routeDefinition) {
    var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

    if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
      $stateProvider.state(stateName, routeDefinition);
    }
  }, scartatiDaRuoliRoutesProvider.list());
  $translatePartialLoader.addPart('scartatiDaRuoli');
}

function routesRun(scartatiDaRuoliRoutes) {
  scartatiDaRuoliRoutes.subscribeUIEvents();
}