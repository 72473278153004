"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deserializeEl = exports.deserialize = exports.serialize = void 0;

var _escapeHtml = _interopRequireDefault(require("escape-html"));

var _slateHyperscript = require("slate-hyperscript");

var _slate = require("slate");

var _utils = require("../../../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const ELEMENT_TAGS = {
  BLOCKQUOTE: () => ({
    type: 'block-quote'
  }),
  H1: () => ({
    type: 'heading-one'
  }),
  H2: () => ({
    type: 'heading-two'
  }),
  H3: () => ({
    type: 'heading-three'
  }),
  H4: () => ({
    type: 'heading-four'
  }),
  H5: () => ({
    type: 'heading-five'
  }),
  H6: () => ({
    type: 'heading-six'
  }),
  IMG: el => ({
    type: 'image',
    url: el.getAttribute('src')
  }),
  LI: () => ({
    type: 'list-item'
  }),
  OL: () => ({
    type: 'numbered-list'
  }),
  P: () => ({
    type: 'paragraph'
  }),
  PRE: () => ({
    type: 'code'
  }),
  UL: () => ({
    type: 'bulleted-list'
  })
};
const TEXT_TAGS = {
  CODE: () => ({
    code: true
  }),
  DEL: () => ({
    strikethrough: true
  }),
  EM: () => ({
    italic: true
  }),
  I: () => ({
    italic: true
  }),
  S: () => ({
    strikethrough: true
  }),
  STRONG: () => ({
    bold: true
  }),
  U: () => ({
    underlined: true
  })
}; // Define a serializing function that takes a value and returns a string.

const serialize = nodes => {
  return nodes.reduce((acc, node) => `${acc}${serializeEl(node)}`, "");
};

exports.serialize = serialize;

const serializeEl = node => {
  if (_slate.Text.isText(node)) {
    let text = (0, _escapeHtml.default)(node.text);

    if (node.bold) {
      text = `<strong>${text}</strong>`;
    }

    if (node.code) {
      text = `<code>${text}</code>`;
    }

    if (node.italic) {
      text = `<em>${text}</em>`;
    }

    if (node.underlined) {
      text = `<u>${text}</u>`;
    }

    return text;
  }

  const children = node.children.map(n => serializeEl(n)).join('');
  const hasStyle = node.style && Object.keys(node.style).length;
  const nodeStyle = node.style && Object.keys(node.style).reduce((acc, style) => [...acc, `${[(0, _utils.fromCamelsToDashes)(style)]}: ${node.style[style]}`], []).join(";");

  switch (node.type) {
    case 'block-quote':
      return `<blockquote style="border-left: 2px solid #ddd; padding-left: 10px; color: #aaa; font-style: italic; ${nodeStyle}">${children}</blockquote>`;

    case 'paragraph':
      return `<p ${hasStyle ? `style="${nodeStyle}"` : ''}>${children}</p>`;

    case 'heading-one':
      return `<h1 ${hasStyle ? `style="${nodeStyle}"` : ''}>${children}</h1>`;

    case 'heading-two':
      return `<h2 ${hasStyle ? `style="${nodeStyle}"` : ''}>${children}</h2>`;

    case 'numbered-list':
      return `<ol ${hasStyle ? `style="${nodeStyle}"` : ''}>${children}</ol>`;

    case 'bulleted-list':
      return `<ul style="list-style-type: disc; padding-inline-start: 40px; ${nodeStyle}">${children}</ul>`;

    case 'list-item':
      return `<li ${hasStyle ? `style="${nodeStyle}"` : ''}>${children}</li>`;

    default:
      return children;
  }
};

const deserialize = html => {
  const document = new DOMParser().parseFromString(html, 'text/html');
  return deserializeEl(document.body);
};

exports.deserialize = deserialize;

const deserializeEl = el => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === 'BR') {
    return '\n';
  }

  const {
    nodeName
  } = el;
  let parent = el;

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0];
  }

  const children = Array.from(parent.childNodes).map(deserializeEl).flat();

  if (el.nodeName === 'BODY') {
    return (0, _slateHyperscript.jsx)('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const element = ELEMENT_TAGS[nodeName](el);
    const styleAttrs = Object.keys(el.style).filter(key => isNaN(key)).reduce((acc, key) => el.style[key] ? { ...acc,
      [key]: el.style[key]
    } : acc, {});
    return (0, _slateHyperscript.jsx)('element', { ...element,
      style: styleAttrs
    }, !children.length ? [{
      text: ""
    }] : children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map(child => (0, _slateHyperscript.jsx)('text', attrs, child));
  }

  return children;
};

exports.deserializeEl = deserializeEl;