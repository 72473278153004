"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('RicercaVeloceNonPaginata', ['restmod', 'RMCommonApi', function (restmod) {
    var API_RICERCA_VELOCE_NON_PAGINATA = 'no-pag/ricerca-veloce';
    return restmod.mixin({
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);
            collection.$isRicercaVeloce = true;
            return collection;
          }
        },
        Collection: {
          $ricercaVeloceNonPaginata: function (_params) {
            var me = this,
                params;

            this.$url = function () {
              return this.$getBaseEntityPath() + API_RICERCA_VELOCE_NON_PAGINATA;
            };

            params = R.path('$metadata', 'search', this) ? angular.extend(this.$metadata.search, _params) : _params; //Usiamo la stessa collection per mantenere il riferimento
            //this.$clear(); //Pulizia dei vecchi dati

            return this.$refresh(params);
          },
          $getElasticFilters: function () {
            return this.$metadata.filters;
          }
        }
      }
    });
  }]);
})();