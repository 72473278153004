"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useModelloStampa = exports.modelliStampaKeys = void 0;

var _utils = require("../../utils");

var _modelliStampaMasterDetail = require("./modelliStampaMasterDetail");

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _api = require("../../api");

var _utils2 = require("../../react/reactQuery/utils");

var _utils3 = require("./utils");

const modelliStampaKeys = {
  all: () => [_modelliStampaMasterDetail.modelliStampaMasterDetailName],
  detail: aggId => [...modelliStampaKeys.all(), "detail", ...(0, _utils.insertIf)(aggId, {
    aggId
  })],
  quickSearch: params => [...modelliStampaKeys.all(), "quickSearch", ...(0, _utils.insertIf)(params, params)]
};
exports.modelliStampaKeys = modelliStampaKeys;

const useModelloStampa = ({
  aggId,
  onSuccess,
  onFailure
}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data: remoteModelloStampa,
    isFetching: isFetchingModelloStampa,
    isLoading: isLoadingModelloStampa
  } = (0, _reactQuery.useQuery)(modelliStampaKeys.detail(aggId), ({
    queryKey
  }) => (0, _api.dettaglioModelloStampa)({
    aggId: queryKey[2].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId),
    onSuccess: response => (0, _utils2.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    select: response => response.error ? response : (0, _utils3.fromApiModelloStampa)(response)
  });
  const {
    data: remoteFunzione,
    isFetching: isFetchingFunzione,
    isLoading: isLoadingFunzione
  } = (0, _reactQuery.useQuery)([...modelliStampaKeys.detail(aggId), `funzione`, remoteModelloStampa.funzioneStampaId], ({
    queryKey
  }) => (0, _api.dettaglioFunzioneStampa)({
    aggId: remoteModelloStampa.funzioneStampaId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(remoteModelloStampa) && Boolean(remoteModelloStampa.funzioneStampaId),
    onSuccess: response => (0, _utils2.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    select: response => {
      if (response.error) return response;
      return { ...response,
        moduloStampa: response.moduloStampa == "sanctions" ? "procedimento sanzionatorio" : response.moduloStampa
      };
    }
  });
  const modelloStampaExploded = { ...remoteModelloStampa,
    funzione: remoteFunzione || null
  };
  return {
    modelloStampa: modelloStampaExploded,
    isFetching: isFetchingModelloStampa || isLoadingModelloStampa || isFetchingFunzione || isLoadingFunzione
  };
};

exports.useModelloStampa = useModelloStampa;