"use strict";

/**
 * Created by MN 16/05/2019
 */
(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name hasRoleFor
   * @module osFramework
   *
   * @param has-role-for
   * @param role -> il ruolo che deve avere l'elemento per poter essere visualizzato
   *
   * @description
   * Direttiva che permette di sottoporre all'analisi dei ruoli e permessi dell'utente loggato un elemento HTML
   *     permettendone la visualizzazione oppure, nel caso in cui l'utente non ne abbia il permesso, nascondendolo.
   *
   * @usage
   * <hljs language="html">
   *     <md-button
   *          has-role-for
   *          role="ROLE_ANAGRAFICA_MODIFICA">
   *          <md-icon>edit</md-icon>
   *          <md-tooltip md-direction="up">
   *               <translate>soggetti.dettaglio.modifica__tooltip</translate>
   *          </md-tooltip>
   *     </md-button>
   * </hljs>
   */

  angular.module('osFramework').directive('hasRoleFor', ['rolesGuardService', 'ROLES_ACTIONS', '$compile', HasRolesForDirective]);

  function HasRolesForDirective(rolesGuardService, ROLES_ACTIONS, $compile) {
    return {
      restrict: 'A',
      replace: false,
      link: linkFunction
    };

    function linkFunction(scope, element, attrs) {
      var role = attrs.role,
          elementToApplyAttribute = element[0];

      if (role.indexOf('[') > -1) {
        try {
          role = JSON.parse(attrs.role);
        } catch (e) {
          console.log('has role for directive error: ', e);
        }
      } else {
        role = [role];
      }

      if (!role || role === 'notApplyRoleManagement') {
        return;
      }

      if (!rolesGuardService.userHasGroupRoles(role)) {
        addNgIfAttributeToHtml();
      }
      /**
       * AGGIUNGE ATTRIBUTO NG-IF="FALSE" ALL'ELEMENTO A CUI E' APPLICATA LA DIRETTIVA E RICOMPILA L'HTML
       */


      function addNgIfAttributeToHtml() {
        elementToApplyAttribute.setAttribute('ng-if', 'false');
        element.parent().append($compile(elementToApplyAttribute)(scope));
      }
    }
  }
})();