"use strict";

(function () {
  'use strict';

  angular.module('polcity.acquisizioneDati').provider('acquisizioneDatiRoutes', ['routesProviderFactoryProvider', routeProvider]);

  function routeProvider(routesProviderFactory) {
    this.routes = [{
      name: 'acquisizioneDati',
      abstract: true,
      url: '',
      templateUrl: 'acquisizioneDati/acquisizioneDati.html'
    }, {
      name: 'acquisizioneDati.rilevazioni',
      role: 'ROLE_RILEVAZIONE_RICERCA_VELOCE',
      url: 'rilevazioni',
      breadcrumbs: {
        isBase: true,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: 'rilevazioniList'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return $translate('acquisizioneDati.rilevazioni.acquisizioneRilevazioni__bcLabel');
        }]
      }
    }, {
      name: 'acquisizioneDati.validazioneRilevazioni',
      roles: ['ROLE_BPMN_ACCETTA_VALIDAZIONE', 'ROLE_VERBALIZZAZIONE_RIGETTA_VALIDAZIONE'],
      url: 'rilevazioni/validazione-rilevazioni',
      params: {
        milestone: null,
        rilevazioniDaValidare: null
      },
      breadcrumbs: {
        isBase: false,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: 'rilevazioniValidazioneImmagini'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return $translate('acquisizioneDati.validazioneRilevazioni.validazioneRilevazioni__bcLabel');
        }]
      }
    }, {
      name: 'acquisizioneDati.validazioneFinaleRilevazioni',
      roles: ['ROLE_VERBALIZZAZIONE_VERBALIZZA_RILEVAZIONE', 'ROLE_VERBALIZZAZIONE_RIGETTA_VALIDAZIONE_FINALE'],
      url: 'rilevazioni/validazione-finale-rilevazioni',
      params: {
        milestone: null,
        rilevazioniDaValidare: null
      },
      breadcrumbs: {
        isBase: false,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: 'rilevazioniValidazioneImmagini'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return $translate('acquisizioneDati.validazioneRilevazioni.validazioneFinaleRilevazioni__bcLabel');
        }]
      }
    }, {
      name: 'acquisizioneDati.versamenti',
      role: 'ROLE_VERSAMENTO_RICERCA_VELOCE',
      url: 'versamenti',
      params: {
        // chiamare con questo parametro impostato con uno dei seguenti valori per selezionare un
        // determinato tab values: versamento_acquisito - in_attesa_validazione -
        // versamento_non_attribuito_resgistrato - versamento_attribuito
        activeTab: null
      },
      breadcrumbs: {
        isBase: true,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: 'versamentiAcquisizioneDati'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return $translate('acquisizioneDati.versamenti.acquisizioneVersamenti__bcLabel');
        }]
      }
    }, {
      name: 'acquisizioneDati.validazioneVersamenti',
      role: 'ROLE_PAGAMENTO_ATTRIBUZIONE_MANUALE',
      url: 'versamenti/validazione-versamenti',
      params: {
        milestone: null,
        versamentiDaValidare: null
      },
      breadcrumbs: {
        isBase: false,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: 'versamentiValidazioneImmagini'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return $translate('acquisizioneDati.validazioneVersamenti.validazioneVersamenti__bcLabel');
        }]
      }
    }];
    routesProviderFactory.extend(this);
  }
})();