"use strict";

var _CarroattrezziList = _interopRequireDefault(require("./CarroattrezziList"));

var _CarroAttrezziEditRoot = _interopRequireDefault(require("./edit/CarroAttrezziEditRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('carroAttrezziTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsCarroAttrezzi',
    url: 'tds/carroAttrezzi',
    role: 'ROLE_CARRO_ATTREZZI_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _CarroattrezziList.default
      }
    },
    resolve: {
      $label: () => "Carroattrezzi"
    }
  }, {
    name: 'tdsCarroAttrezziEdit',
    url: 'tds/carroAttrezzi/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_CARRO_ATTREZZI_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _CarroAttrezziEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita carroattrezzi"
    }
  }];
  routesProviderFactory.extend(this);
}