"use strict";

var _react = _interopRequireDefault(require("react"));

var _reactDom = _interopRequireDefault(require("react-dom"));

var _FabButtonWithTooltip = _interopRequireDefault(require("../../react/molecules/buttons/FabButtonWithTooltip"));

var _HelpOutline = _interopRequireDefault(require("@material-ui/icons/HelpOutline"));

var _Box = _interopRequireDefault(require("@material-ui/core/Box"));

var _styles = require("@material-ui/core/styles");

var _theme = _interopRequireDefault(require("../../theme/theme"));

var _TutorialLinkList = _interopRequireDefault(require("../../react/molecules/buttons/ActionButton/TutorialLinkList"));

var _LooksOne = _interopRequireDefault(require("@material-ui/icons/LooksOne"));

var _utils = require("../../utils");

var _linkTutorials = require("../../linkTutorials.constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('osTableComponent').component('osTable', {
  templateUrl: 'osFramework/osTable/osTable.component.html',
  controller: ['$state', '$scope', '$injector', '$transclude', OsTableComponent],
  controllerAs: 'osTableCtrl',
  bindings: {
    column: '<',
    readOnly: '<?',
    actionColumn: '<?',
    enableSearch: '<?',
    enableActions: '<?',
    bulkActions: '<?',
    enableBulkActions: '<?',
    enableInsert: '<?',
    enableInfo: '<?',
    tutorialList: '<?',
    newEntityRoute: '<?',
    entity: '<',
    data: '<',
    mdEnableRowSelect: '<?',
    multiple: '<?',
    ngSelectedRowModel: '=?',
    mdAutoSelect: '<?',
    mdProgress: '<',
    showHeader: '<?',
    mdSelectId: '<',
    mdCurrentPage: '<',
    mdPageLimit: '<',
    enablePagination: '<?',
    mdLimitOptions: '<',
    mdTotal: '<',
    enableRowWidget: '<?',
    //plugins            : '<?',
    mdPageSelect: '<',
    mdPageBoundaryLinks: '<',
    searchLabel: '<',
    addRole: '<?',
    performSearch: '&',
    onPageChange: '&',
    onOrderChange: '&',
    onRowExpandClick: '&'
  },
  transclude: {
    'rowWidget': '?rowWidget'
  }
});

function OsTableComponent($state, $scope, $injector, $transclude) {
  var vm = this;
  /**
   * Variabile ad uso interno che gestisce il toggle sul rowwidget
   * @type {Array}
   */

  $scope.expand = {};
  /***********************
   * Table configuration
   ***********************/

  /**
   * @config
   * Table column configurator
   * [{
   * text: 'column name, ***
   * dataIndex: 'property name model reference', ***
   * orderby: 'sort options'
   * renderer: transform data function,
   * icon: {iconName: 'nome icona'}
   * }]
   */

  vm.column = [];
  /**
   * Flag per disabilitare TUTTE le interazioni con la tabella
   * @type {boolean}
   */

  vm.readOnly = false;
  /**
   * @config
   * Row Action configurator
   * [
   * {
   *   type: 'delete',
   *   icon: {
   *     iconName: 'delete'
   *   },
   *   tooltip: 'Elimina spedizione',
   *   callback: function(item){
   *     deleteSpedizioni(vc.aggId, [item]);
   *   }
   * }
   * ]
   * {array}
   */

  vm.actionColumn = [];
  /**
   * @config
   * Abilita | disabilita le action sulle righe
   * {boolean}
   */

  vm.enableActions = false;
  /**
   * @config
   * Model instance to iterate in
   */

  vm.data = [];
  /**
   * @config
   * Enable row selection.
   */

  vm.mdEnableRowSelect = false;
  /**
   * @config
   * Allow multiple selection. When enabled a master toggle will be prepended to the last row of table header.
   */

  vm.multiple = false;
  /**
   * @config
   * The table will display a loading indicator until all promises are resolved or rejected.
   * {promise, promise<Array>}
   */

  vm.mdProgress;
  /**
   * @config
   * Elenco di azioni massive possibili
   * {array}
   */

  vm.bulkActions = [];
  /**
   * @config
   * Abilita le azioni massive sulla selezione multipla delle righe
   * {boolean}
   */

  vm.enableBulkActions = false;
  /*********************
   * Row configuration
   *********************/

  /**
   * @config
   * Select a row by clicking anywhere in the row.
   * [expression]
   */

  vm.mdAutoSelect = false;
  /**
   * @config
   * A unique identifier for the selected item. The identifier must be a property of the item.
   * {number, string}
   */

  vm.mdSelectId;
  /************************
   * AppHeader configuration
   ************************/

  /**
   * @config
   * Hide | Show table header
   */

  vm.showHeader = true;
  /*************************
   * Paging configuration
   *************************/

  /**
   * @config
   * A row limit.
   * {Integer}
   */

  vm.mdPageLimit = 20;
  /**
   * Boolean configurator to hide/show pagination
   * @type {boolean}
   */

  vm.enablePagination = true;
  /**
   * @config
   * Row limit options
   * {array}
   * The md-limit-options attribute supports integers or objects with the properties label and value. The latter
   *     is convenient for when you want to use language to give meaning to individual options
   */

  vm.mdPageLimitOptions = [5, 10, 20, 50, 100, 250, 500];
  /**
   * @config
   * Display a select dropdown for the page number
   * [expression]
   */

  vm.mdPageSelect = true;
  /**
   * @config
   * Displays first and last page navigation links
   * [expression]
   */

  vm.mdPageBoundaryLinks = true;
  /**
   * @config
   * Numero totale di elementi della tabella
   * @type {number}
   */

  vm.mdTotal;
  /**
   * @config
   * Page number. Pages are not zero indexed. The directive assumes the first page is one.
   * {integer}
   */

  vm.mdCurrentPage = 1;
  /**
   * Change the pagination label
   * {of, page, rowsPerPage}
   * {object}
   */

  vm.mdPageLabel;
  /**********************
   * Local properties
   **********************/

  /**
   * @property
   * A variable to bind selected items to.
   * La variabile è in two-way binding opzionale nel caso in cui non si volesse passare dalle bulk-actions (o per
   *     dati in ingresso)
   */

  vm.ngSelectedRowModel = [];
  /**
   * @property
   * A variable to bind the sort order to.
   * {string}
   */

  vm.mdOrder;
  /**
   * @property
   * Total number of items.
   * {integer}
   */

  vm.mdPageTotal;
  /**
   * Abilita la possibilità di espandere il body della riga con un widget passato nei plugin
   * @type {boolean}
   */

  vm.enableRowWidget = false;
  /**
   * Elenco di plugin associati alla tabella
   * rowwidget: consente di esplodere il body della riga con una direttiva a piacimento. E' possibile passare uno
   * scope e le proprietà da agganciare alla direttiva. Nello scope si trova inoltre il record della riga.
   * Attenzione che lo scope passato come argomento di trova nel $parent della direttiva widget ed è condiviso.
   *
   * es:
   * rowwidget:{
   *       directive: 'dati-aggiuntivi-verbale',
   *       ngModel: '$parent.verbale.decoratori',
   *       scope: $scope,
   *       bind:{
   *           "dati-aggiuntivi": "record.decoratori"
   *       }
   *   }
   * @type {{}}
   */

  /**
   * @property
   * A callback function for when the order changes. The callback will receive the new order.
   * {function}
   *
   * Il parametro order ha un segno "-" davanti se l'ordinamento è discendente
   */

  vm.mdOnReorder = function (orderKey) {
    var order;
    vm.ngSelectedRowModel = [];

    if (orderKey.charAt(0) === '-') {
      order = orderKey.substr(1, orderKey.length) + ',desc';
    } else {
      order = orderKey + ',asc';
    }

    vm.onOrderChange({
      order: order
    });
  };
  /**
   * @property
   * A callback function for when an item is selected. The item will be passed as an argument to the callback.
   * {function}
   */


  vm.mdRowOnSelect = function (item) {};

  $scope.onRowExpand = function (item, index) {
    $scope.expand[item.aggId] = !$scope.expand[item.aggId];

    if (vm.onRowExpandClick) {
      vm.onRowExpandClick({
        item: item,
        index: index
      });
    }
  };
  /**
   * @property
   * A callback function for when the page or limit changes. The page is passed as the first argument and the
   *     limit is passed as the second argument.
   * {function}
   */


  vm.mdPageOnPaginate = function (page, limit) {
    vm.onPageChange({
      page: page,
      limit: limit
    });
  };

  vm.$onInit = function () {
    setReadOnly(vm.readOnly);

    _loadTableTranslations();
  };
  /**
   * @property
   * A callback function to handle the search in table
   * {function}
   */


  vm.onPerformSearch = function (searchText) {
    vm.performSearch(searchText);
  };

  vm.$onChanges = function (changesObj) {
    /*if( changesObj.mdTotal.currentValue < changesObj.mdTotal.previousValue ) {
        vm.ngSelectedRowModel = [];
    }*/
    console.info('changes', changesObj);
  };

  vm.checkRoles = function (_roles) {
    console.log(_roles);
  };
  /**
   * Carica le traduzioni e le assegno alla vista
   * @returns {*}
   * @private
   */


  function _loadTableTranslations() {
    return $injector.get('$translate')(['labels.rowsPerPage__text', 'labels.of__text']).then(translationsToLabels);

    function translationsToLabels(translations) {
      vm.mdPageLabel = {};
      vm.mdPageLabel.rowsPerPage = translations['labels.rowsPerPage__text'];
      vm.mdPageLabel.of = translations['labels.of__text'];
    }
  }

  function setReadOnly(readOnly) {
    if (readOnly) {
      vm.enableActions = false;
      vm.mdEnableRowSelect = false;
      vm.multiple = false;
      vm.enableBulkActions = false;
    }
  }
}