"use strict";

/**
 * MN 13/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaFrazione', ['$mdDialog', 'Command', RicercaFrazione]);

  function RicercaFrazione($mdDialog, Command) {
    return {
      restrict: 'E',
      require: ['ngModel'],
      scope: {
        entity: '=?',
        label: '@?',
        onFrazioneChange: '<?',
        isRequired: '<?',
        name: '@?',
        inceptionMode: '<?',
        comuneId: '<?',
        afterInsert: '<?'
      },
      controller: ['$scope', '$filter', controllerFn],
      controllerAs: 'ricercaFrazioneCtrl',
      templateUrl: 'osFramework/input/ricercaFrazione/ricercaFrazione.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter) {
      var me = this;
      me.groupRoles = ['ROLE_FRAZIONE_RICERCA', 'ROLE_FRAZIONE_DETTAGLIO'];
      me.additionalQueryParams = {
        aggId: scope.comuneId
      };

      me.getItemText = function (frazione) {
        var itemText;
        itemText = $filter('translatable')(frazione.nome);
        return itemText;
      };

      me.nuovaFrazione = function () {
        $mdDialog.show({
          templateUrl: 'osFramework/input/ricercaFrazione/modal/insertEditFrazione.modal.html',
          fullscreen: false,
          escapeToClose: true,
          controller: 'FrazioniFormController',
          controllerAs: 'frazioniFormCtrl',
          bindToController: true,
          focusOnOpen: true,
          multiple: true,
          locals: {
            hide: $mdDialog.cancel,
            save: $mdDialog.hide,
            inceptionMode: scope.inceptionMode
          }
        }).then(function (frazione) {
          frazione.aggId = scope.comuneId;
          return salvaFrazione(frazione);
        }).then(function (frazione) {
          if (scope.afterInsert) {
            scope.afterInsert(frazione);
          }
        }).catch(function (e) {
          console.log(e);
        });

        function salvaFrazione(frazione) {
          return Command.execute('@salvaFrazione', frazione);
        }
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.frazioneId = null;
          return;
        }

        scope.frazioneId = viewValue;
      };

      scope.$watch('frazioneId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();