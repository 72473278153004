"use strict";

var _EnteTerritorialeEditRoot = _interopRequireDefault(require("./edit/EnteTerritorialeEditRoot"));

var _EntiTerritorialiList = _interopRequireDefault(require("./EntiTerritorialiList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('enteTerritorialeTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsEntiTerritoriali',
    url: 'tds/enteTerritoriale',
    role: 'ROLE_ENTE_TERRITORIALE_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _EntiTerritorialiList.default
      }
    },
    resolve: {
      $label: () => "Enti territoriali"
    }
  }, {
    name: 'tdsEntiTerritorialiEdit',
    url: 'tds/enteTerritoriale/{entityId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_ENTE_TERRITORIALE_MODIFICA',
    params: {
      entityId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _EnteTerritorialeEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita ente territoriale"
    }
  }];
  routesProviderFactory.extend(this);
}