"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMainMenu = void 0;

var _react = _interopRequireDefault(require("react"));

var _app = require("../../../app");

var _icons = require("@material-ui/icons");

var _roles = require("../../../roles.costants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const procedimentoSanzionatorio = {
  panelId: "procedimentoSanzionatorio",
  SectionIcon: _icons.DirectionsCar,
  sectionTitle: "Procedimento sanzionatorio",
  elements: [{
    role: _roles.roleVerbaleRicercaVeloce,
    name: 'Verbali',
    route: 'navigation.verbaliList'
  }, {
    role: _roles.roleRicorsoRicerca,
    name: 'Ricorsi',
    route: 'navigation.ricorsi.list'
  }, {
    role: _roles.roleLottoFastmailRicerca,
    name: 'Lotti',
    route: 'navigation.lottiList'
  }, {
    role: _roles.roleFermoSequestroRicerca,
    name: 'Fermi e sequestri',
    route: 'navigation.fermoSequestroList'
  }, {
    role: _roles.roleDecurtazionePuntiRicerca,
    name: 'Decurtazione punti',
    route: 'navigation.decurtazioniList'
  }, {
    role: _roles.roleRimozioneRicerca,
    name: 'Rimozioni',
    route: 'navigation.rimozioniList'
  }, {
    role: 'dontSearchForRole',
    name: 'Presentazione documenti',
    route: 'navigation.presentazioneDocumentiList'
  }, {
    role: "dontSearchForRole",
    name: 'Scadenzario',
    route: 'navigation.scadenzario'
  }]
};
const leggiArticoliNormative = {
  panelId: "leggiArticoliNormative",
  SectionIcon: _icons.LocalLibrary,
  sectionTitle: "Leggi, articoli e normative",
  elements: [{
    role: _roles.roleLeggeRicerca,
    name: 'Leggi',
    route: 'navigation.tdsLeggi'
  }, {
    role: _roles.roleArticoloCDSRicerca,
    name: 'Articoli e normative',
    route: 'navigation.articoliNormativeList'
  }]
};
const anagrafica = {
  panelId: "anagrafica",
  SectionIcon: _icons.RecentActors,
  sectionTitle: "Anagrafica",
  elements: [{
    role: _roles.roleAnagraficaRicerca,
    name: 'Soggetti',
    route: 'navigation.soggettiList'
  }]
};
const acquisizioneDati = {
  panelId: "acquisizioneDati",
  SectionIcon: _icons.AddAPhoto,
  sectionTitle: "Acquisizione dati",
  elements: [{
    role: _roles.roleRilevazioneRicercaVeloce,
    name: 'Rilevazioni',
    route: 'navigation.acquisizioneDati.rilevazioni'
  }, {
    role: _roles.roleVersamentoRicercaVeloce,
    name: 'Versamenti',
    route: 'navigation.acquisizioneDati.versamenti'
  }, {
    role: _roles.roleAllegatoDigitaleStagingRicerca,
    name: 'Allegati non associati',
    route: 'navigation.allegatiDigitaliNonAssociatiList'
  }, {
    role: _roles.roleAcquisizioneEsitiRicerca,
    name: 'Esiti spedizioni',
    route: 'navigation.esitiSpedizioni.list'
  }]
};
const contabilita = {
  panelId: "contabilita",
  SectionIcon: _icons.AccountBalance,
  sectionTitle: "Contabilità",
  elements: [// {role: roleContabilitaRicercaVeloce, name: 'Armonizzazione contabile', route: 'navigation.osContabilita.armonizzazioneContabile'},
  {
    role: _roles.roleContabilitaRicercaVeloce,
    name: 'Registrazioni Contabili',
    route: 'navigation.registrazioniContabili.list'
  }, {
    role: _roles.rolePagamentoCassa,
    name: 'Cassa',
    route: 'navigation.cassa'
  }, {
    role: _roles.roleGeneraBilancio,
    name: 'Bilancio',
    route: 'navigation.osContabilita.bilancio'
  }, {
    role: _roles.roleRuoliRicerca,
    name: "Ruoli",
    route: 'navigation.ruoli'
  }, {
    role: _roles.roleInsolutiRicerca,
    name: 'Insoluti',
    route: 'navigation.insolutiList'
  }]
};
const serviziVari = {
  panelId: "serviziVari",
  SectionIcon: _icons.EmojiObjects,
  sectionTitle: "Servizi",
  elements: [{
    role: _roles.roleAgenteVisuraRicerca,
    name: 'Tabella agenti visure',
    route: 'navigation.tdsAgentiVisure'
  }, {
    role: _roles.roleVisuraRicercaVeloce,
    name: 'Visure',
    route: 'navigation.visure.list'
  }]
};
const impostazioni = {
  panelId: "impostazioni",
  SectionIcon: _icons.Settings,
  sectionTitle: "Impostazioni",
  elements: [{
    role: _roles.roleModelloStampaRicerca,
    name: 'Modelli di stampa',
    route: 'navigation.modelliStampalist'
  }, {
    role: 'dontSearchForRole',
    name: 'Tabelle di supporto',
    route: 'navigation.tds'
  }, {
    role: _roles.roleSezioniValoriGlobali,
    name: 'Valori globali',
    route: 'navigation.valoriGlobaliList'
  }]
};

const useMainMenu = () => {
  const userRoleBasedItems = (items = []) => items.filter(item => item.role !== 'dontSearchForRole' ? _app.keycloakAuth.hasRealmRole(item.role) : true); // FIXME: rimuovere questa cagata del dontSearchForRole


  const procSanz = { ...procedimentoSanzionatorio,
    elements: userRoleBasedItems(procedimentoSanzionatorio.elements)
  };
  const legArtNorma = { ...leggiArticoliNormative,
    elements: userRoleBasedItems(leggiArticoliNormative.elements)
  };
  const anag = { ...anagrafica,
    elements: userRoleBasedItems(anagrafica.elements)
  };
  const acquiDati = { ...acquisizioneDati,
    elements: userRoleBasedItems(acquisizioneDati.elements)
  };
  const cont = { ...contabilita,
    elements: userRoleBasedItems(contabilita.elements)
  };
  const serVari = { ...serviziVari,
    elements: userRoleBasedItems(serviziVari.elements)
  };
  const imp = { ...impostazioni,
    elements: userRoleBasedItems(impostazioni.elements)
  };
  return [procSanz, legArtNorma, anag, acquiDati, cont, serVari, imp];
};

exports.useMainMenu = useMainMenu;