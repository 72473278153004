"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tdsOrderDeserializer = exports.tdsOrderSerializer = void 0;

const tdsOrderSerializer = order => {
  const res = order.reduce((acc, value, index) => {
    acc = acc.concat(value.field, ",", value.sort).concat(index == order.length - 1 ? "" : ":");
    return acc;
  }, "");
  return res;
};

exports.tdsOrderSerializer = tdsOrderSerializer;

const tdsOrderDeserializer = order => {
  const splittedOrder = order.split(":");
  const res = splittedOrder.reduce((acc, value, index) => {
    acc = [...acc, {
      field: value.split(",")[0],
      sort: value.split(",")[1]
    }];
    return acc;
  }, []);
  return res;
};

exports.tdsOrderDeserializer = tdsOrderDeserializer;