"use strict";

/**
 * Created by MN 31/07/2019
 */
(function () {
  'use strict';

  angular.module('polcity.acquisizioneDati').component('uploadRilevazione', {
    templateUrl: 'acquisizioneDati/rilevazioni/uploadRilevazione/uploadRilevazione.component.html',
    controller: ['$scope', '$rootScope', 'Command', 'OSNotification', 'rolesGuardService', UploadRilevazioneController]
  });

  function UploadRilevazioneController($scope, $rootScope, Command, OSNotification, rolesGuardService) {
    var me = this;
    $scope.tracciatoToUpload = {};
    $scope.tipiRilevatore = [];
    $scope.contenutiAllegato = [];
    $scope.sectionRoles = ['ROLE_VERBALIZZAZIONE_ACQUISISCI', 'ROLE_RILEVATORE_INFRAZIONI_RICERCA', 'ROLE_VERBALIZZAZIONE_ACQUISISCI'];

    me.$onInit = function () {
      if (!rolesGuardService.userHasGroupRoles($scope.sectionRoles)) {
        return;
      }

      fetchTipiRilevatore().then(setTipiRilevatore);
    };
    /**
     * Apis
     * @returns {*}
     */


    function fetchTipiRilevatore() {
      return Command.execute('@ricercaRilevatoriInfrazioni');
    }

    function uploadRilevazione(formdata) {
      return Command.executeRaw('@creaRilevazione', formdata, {
        'Content-Type': undefined
      });
    }

    function setTipiRilevatore(remoteRilevatori) {
      $scope.tipiRilevatore = remoteRilevatori.resultsWrapper.values;
    }
    /*******************************
     * upload file configuration
     *******************************/


    $scope.uploadRilevazione = function (rilevazione, file) {
      var fd = new FormData(),
          fileToUpload = file[0].lfFile,
          rilevazioneToUpload = JSON.stringify(rilevazione);
      fd.append('tracciato', fileToUpload);
      fd.append('json', rilevazioneToUpload);
      uploadRilevazione(fd).then(afterUpload).catch(function (err) {
        if (err) {
          notifyUser('warn', 'acquisizioneDati.rilevazioni.uploadTracciatoError__notification');
        }
      });
    };

    function afterUpload() {
      notifyUser('success', 'acquisizioneDati.rilevazioni.uploadTracciatoSuccess__notification');
      $scope.tracciatoToUpload = {};
      $scope.osAllegatoFormController.file = [];
      $scope.uploadTracciatoForm.$setUntouched(); // $state.go('navigation.dashboard');

      $rootScope.$broadcast('upload-new-rilevazioni-file');
    }
    /**
     * Utils
     */


    function notifyUser(type, msg) {
      OSNotification[type]({
        translationIndex: msg
      });
    }
  }
})();