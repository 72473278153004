"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localizzazioniListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const localizzazioniListColumns = [{
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'lingueAttive',
  headerName: 'Lingue attive',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'idLocale',
  headerName: 'Id locale',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'valuta',
  headerName: 'Valuta',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'defaultLocalizzazione',
  headerName: 'Attiva',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? 'SI' : 'NO',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.localizzazioniListColumns = localizzazioniListColumns;