"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.marcheModelliVeicoliListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const marcheModelliVeicoliListColumns = [{
  field: 'marca',
  headerName: 'Marca',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'modello',
  headerName: 'Modello',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'anno',
  headerName: 'Anno',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.marcheModelliVeicoliListColumns = marcheModelliVeicoliListColumns;