"use strict";

var _AgenteVisuraEditRoot = _interopRequireDefault(require("./tabellaAgenti/edit/AgenteVisuraEditRoot"));

var _AgenteVisuraDownloadRoot = _interopRequireDefault(require("./tabellaAgenti/download/AgenteVisuraDownloadRoot"));

var _AgentiVisureList = _interopRequireDefault(require("./tabellaAgenti/AgentiVisureList"));

var _roles = require("../roles.costants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.visure').provider('visureRoutes', ['routesProviderFactoryProvider', visureRoutes]);

function visureRoutes(routesProviderFactory) {
  this.routes = [{
    name: 'visure',
    abstract: true,
    url: 'visure',
    templateUrl: 'visure/visure.html'
  }, {
    name: 'tdsAgentiVisure',
    url: 'visure/tabellaAgenti',
    role: _roles.roleAgenteVisuraRicerca,
    params: {
      reloadPreviousState: false
    },
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _AgentiVisureList.default
      }
    },
    resolve: {
      $label: () => "Agenti visure"
    }
  }, {
    name: 'tdsAgentiVisureEdit',
    url: 'visure/tabellaAgenti/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'notApplyRoleManagement',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _AgenteVisuraEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita agente visura"
    }
  }, {
    name: 'tdsAgentiVisureDownload',
    url: 'visure/downloadAgente/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'notApplyRoleManagement',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _AgenteVisuraDownloadRoot.default
      }
    },
    resolve: {
      $label: () => "Download agente visura"
    }
  }, {
    name: 'visure.list',
    url: '',
    params: {
      reloadPreviousState: false
    },
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: 'visureList'
      }
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('visureM.visure__bcLabel');
      }]
    }
  }, {
    name: 'visure.superDetail',
    url: '/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    params: {
      dati: null,
      tipoOperazione: null,
      isVisuraAci: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: 'visureSuperDetail'
      }
    },
    resolve: {
      $label: ['$stateParams', function ($stateParams) {
        var label = $stateParams.tipoOperazione ? $stateParams.tipoOperazione.replace(/_/g, ' ') : '';
        return 'Dettaglio'.concat(' ', label);
      }]
    }
  }];
  routesProviderFactory.extend(this);
}