"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Ricorso', ['restmod', function (restmod) {
    return restmod.model('ricorso').mix({
      $extend: {
        Scope: {
          $config: {
            name: 'ricorsi',
            baseEntityPath: 'ricorso/',
            api: {
              list: {
                url: 'ricerca/ricorsi',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/ricorso',
                method: 'POST'
              },
              destroy: {
                url: 'comando/cancella-ricorso',
                method: 'POST'
              },
              save: {
                url: 'comando/aggiungi-ricorso',
                method: 'POST'
              },
              edit: {
                url: 'comando/modifica-ricorso',
                method: 'POST'
              },
              trasmetti: {
                url: 'comando/ricorso-trasmesso',
                method: 'POST'
              },
              annullaTrasmissione: {
                url: 'comando/annulla-ricorso-trasmesso',
                method: 'POST'
              },
              cronologiaStati: {
                url: 'cronologia-stati',
                method: 'GET'
              },
              prossimiStatiValidi: {
                url: 'prossimi-stati',
                method: 'GET'
              },
              esitoRicorso: {
                url: 'comando/esito-ricorso',
                method: 'POST'
              },
              annullaEsito: {
                url: 'comando/annulla-esito-ricorso',
                method: 'POST'
              },
              ritentaRicorso: {
                url: 'comando/ritenta-ricorso',
                method: 'POST'
              },
              generaFascicolo: {
                url: 'genera-fascicolo',
                method: 'POST',
                responseType: 'arraybuffer'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl("list");
            };

            return collection;
          }
        },
        Record: {
          $trasmetti: function (_params) {
            return this.$action(function () {
              var request = this.$buildRequest('trasmetti');
              request.data = Object.assign({}, {
                aggId: this.$pk
              }, _params);
              this.$dispatch('before-trasmetti', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-trasmetti', [_response]);
              }, function (_response) {
                this.$dispatch('after-trasmetti-error', [_response]);
              });
            });
          },
          $annullaTrasmissione: function () {
            return this.$action(function () {
              var request = this.$buildRequest('annullaTrasmissione');
              request.data = Object.assign({}, {
                aggId: this.$pk
              });
              this.$dispatch('before-annulla-trasmissione', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-annulla-trasmissione', [_response]);
              }, function (_response) {
                this.$dispatch('after-annulla-trasmissione-error', [_response]);
              });
            });
          },

          $getCronologiaStati(_params) {
            return this.$action(function () {
              var request = this.$buildRequest('cronologiaStati');
              request.params = Object.assign({}, {
                aggId: this.$pk
              }, _params);
              this.$dispatch('before-cronologia-stati', [request]);
              this.$send(request, function (_response) {
                this["$cronologiaStati"] = _response.data;
                this["$lastState"] = _response.data.length ? _response.data[_response.data.length - 1].statoRicorso : null;
                this.$dispatch('after-cronologia-stati', [_response]);
              }, function (_response) {
                this.$dispatch('after-cronologia-stati-error', [_response]);
              });
            });
          },

          $getProssimiStatiValidi(_params) {
            return this.$action(function () {
              var request = this.$buildRequest('prossimiStatiValidi');
              request.params = Object.assign({}, {
                aggId: this.$pk
              }, _params);
              this.$dispatch('before-prossimi-stati-validi', [request]);
              this.$send(request, function (_response) {
                this["$prossimiStatiValidi"] = _response.data;
                this.$dispatch('after-prossimi-stati-validi', [_response]);
              }, function (_response) {
                this.$dispatch('after-prossimi-stati-validi-error', [_response]);
              });
            });
          },

          $esitoRicorso(_params) {
            return this.$action(function () {
              var request = this.$buildRequest('esitoRicorso');
              request.data = Object.assign({}, _params);
              this.$dispatch('before-esito-ricorso', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-esito-ricorso', [_response]);
              }, function (_response) {
                this.$dispatch('after-esito-ricorso-error', [_response]);
              });
            });
          },

          $annullaEsitoRicorso() {
            return this.$action(function () {
              var request = this.$buildRequest('annullaEsito');
              request.data = Object.assign({}, {
                aggId: this.$pk
              });
              this.$dispatch('before-annulla-esito-ricorso', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-annulla-esito-ricorso', [_response]);
              }, function (_response) {
                this.$dispatch('after-annulla-esito-ricorso-error', [_response]);
              });
            });
          },

          $ritentaRicorso(_params) {
            return this.$action(function () {
              var request = this.$buildRequest('ritentaRicorso');
              request.data = Object.assign({}, {
                aggId: this.$pk
              }, _params);
              this.$dispatch('before-ritenta-ricorso', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-ritenta-ricorso', [_response]);
              }, function (_response) {
                this.$dispatch('after-ritenta-ricorso-error', [_response]);
              });
            });
          },

          $generaFascicolo(_params) {
            return this.$action(function () {
              var request = this.$buildRequest('generaFascicolo');
              request.data = Object.assign({}, {
                ricorsoAggId: this.$pk
              }, _params);
              this.$dispatch('before-genera-fascicolo', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-genera-fascicolo', [_response]);
              }, function (_response) {
                this.$dispatch('after-genera-fascicolo-error', [_response]);
              });
            });
          },

          //Override della defaul $destroy
          $destroy: function () {
            return this.$action(function () {
              if (this.aggId) {
                var request = this.$buildRequest('destroy', true);
                request.data = {
                  aggId: this.aggId
                };
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$unwrap(this.$getResponseData(_response));
                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) this.$scope.$remove(this);
              }
            });
          }
        }
      }
    });
  }]);
})();