"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.linguaValidationSchema = exports.fromApiLingua = exports.toApiLingua = exports.linguaEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const linguaEmptyForm = {
  languageTag: '',
  descrizione: '',
  attiva: false
};
exports.linguaEmptyForm = linguaEmptyForm;

const toApiLingua = values => {
  const result = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    ...(values.entityId ? {
      entityId: values.entityId
    } : {}),
    languageTag: (0, _object.getPathStringOrEmptyString)(["languageTag"])(values),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(values),
    attiva: (0, _object.getPathBooleanOrFalse)(["attiva"])(values)
  };
  return result;
};

exports.toApiLingua = toApiLingua;

const fromApiLingua = values => {
  const result = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    ...(values.entityId ? {
      entityId: values.entityId
    } : {}),
    languageTag: (0, _object.getPathStringOrEmptyString)(["languageTag"])(values),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(values),
    attiva: (0, _object.getPathBooleanOrFalse)(["attiva"])(values)
  };
  return result;
};

exports.fromApiLingua = fromApiLingua;
const linguaValidationSchema = Yup.object().shape({
  languageTag: Yup.string().matches(/^[a-z]{2}_[A-Z]{2}$/, "Formato non corretto. Esempio formato corretto: xx_XX").required(_utils.requiredMessage).nullable(),
  descrizione: Yup.string().required(_utils.requiredMessage).nullable(),
  attiva: Yup.boolean().required(_utils.requiredMessage).nullable()
});
exports.linguaValidationSchema = linguaValidationSchema;