"use strict";

/**
 * Created by Dan
 * Refactored by GB on 19/05/2018 (part1)
 */
(function () {
  'use strict';

  angular.module('osListComponent').component('osList', {
    templateUrl: 'osFramework/osList/osList.component.html',
    controller: ['Command', '$element', '$state', '$scope', '$translate', '$window', '$transclude', 'lastInsertedElementsService', OsListComponent],
    controllerAs: 'vm',
    bindings: {
      collection: '<',
      selectedItem: '<?',
      page: '<?',
      limit: '<?',
      total: '<?',
      sortable: '<?',
      sorters: '<?',
      filters: '<?',
      showLastInsertedItemBtn: '<?',
      tools: '<?',
      activeFilters: '<?',
      activeSorters: '<?',
      enableFreeSearch: '<?',
      enableMultipleActions: '<?',
      enableFilters: '<?',
      isLoadingList: '<?',
      freeSearchEntityModel: '<?',
      isLoadingDetail: '<?',
      //Handlers
      onChildTap: '&?',
      onSortAdd: '&?',
      onSortRemove: '&?',
      onPageChange: '&?',
      onFilterAdd: '&?',
      onFilterRemove: '&?',
      onFilterReset: '&?',
      onToggleFollow: '&?',
      onRemoveItemSelection: '&?'
    },
    transclude: {
      listItem: 'osListItem',
      detail: 'osListDetail',
      multipleActions: '?osListMultipleActions'
    }
  }).directive('osListItemTransclude', function () {
    /*
    (http://teropa.info/blog/2015/06/09/transclusion.html)
    If you want to pass "item" to the transcluded markup, using ng-transclude won't work because
    it won't use the scope of your menu directive. "item" just won't be present in the transclusion.
    Instead of using ng-transclude you can make your own little transclusion directive that puts item on that transclusion scope
    */
    return {
      link: function (scope, element, attrs, ctrl, transclude) {
        transclude(scope.$new(), function (transEl, transScope) {
          transScope.item = scope.item;
          element.append(transEl);
        }, null, 'listItem');
      }
    };
  });

  function OsListComponent(Command, $element, $state, $scope, $translate, $window, $transclude, lastInsertedElementsService) {
    var vm = this;
    vm.primaryKey = 'aggId';
    /**
     * @config
     * Abilita | Disabilita le azioni multiple sulla lista
     * @type {boolean}
     */

    vm.enableMultipleActions = false;
    /**
     * @config
     * Abilita / Disabilita il pannello dei filtri veloci
     * @type {boolean}
     */

    vm.enableQuickFilters = false;
    /**
     * Nasconde / Mostra i tools di osList all'apertura / chiusura della free search
     * @type {boolean}
     */

    vm.openFreeSearchField = false;
    /***********************
     * Internal properties
     ***********************/

    $scope.activeSortersCount = 0;
    /**
     * @property
     * view labels
     * @type {{}}
     */

    $scope.labels = {};
    /**
     * @config
     * true | false a seconda che il pannello dei sorters sia aperto | chiuso
     * @type {boolean}
     */

    $scope.sortersIsCollapsed = true;
    /**
     * @config
     * Abilita / disabilità le multiple actions
     * @type {boolean}
     */

    vm.multipleSelectionMode = false;
    /**
     * @config
     * Discrimina l'apertura | chiusura del pannello laterale dei filtri
     * @type {boolean}
     */

    $scope.quickFilterIsCollapsed = true;
    /**
     * @property
     * Elenco di item selezionati in modalità multipleSelection
     * @type {Array}
     */

    vm.selectedItems = [];

    vm.$onInit = function () {
      //Define some useful behaviour
      vm.collection.$on('after-fetch-many', function (res) {
        var index = -1; //Update sorters count

        $scope.activeSortersCount = vm.collection.$getActiveSortersCount(); //Aggiorniamo i sorter con i metadata della risposta alla fetch

        vm.sorters = R.mergeDeepRight(vm.sorters, vm.collection.$getActiveSorters());
        vm.selectedItem = null;
        /*if(vm.selectedItem){
            index = vm.collection.$indexOf(function(item){
                if(item.$pk === vm.selectedItem.$pk){
                    return true;
                }
            });
        }
         if(index == -1){
            removeItemSelection();
            $('.os-list__List')
                .animate({
                    scrollTop: 0
                }, 800);
        }else{
            scrollListTo(vm.selectedItem);
        }*/
      }); //Quando si elimina un elemento dalla lista resettiamo il dettaglio

      vm.collection.$on('after-remove', function () {
        //Erase previous detail structure
        $('os-list-detail').empty();
        vm.item = null;
      });

      _loadPaginationLabels().then(setPaginationLabel);
    };

    vm.onListItemClick = select;

    function select(item) {
      if (vm.multipleSelectionMode) {
        toggleItemSelection(item);
      } else {
        vm.selectedItem = item;
      }
    }

    $scope.$watch(() => vm.selectedItem, function (newValue, oldValue) {
      if (newValue) {
        var item = buildDetail(newValue);
        item.then(res => {
          vm.item = res;
          vm.collection.$setSelectedItem(vm.item);
          $scope.$emit('osList:childTap', vm.item);

          if (vm.onChildTap) {
            vm.onChildTap({
              osList: vm,
              item: vm.item
            });
          }

          scrollListTo(vm.item);
        });
      } else if (newValue == null && oldValue == undefined) {//Siamo al primo caricamento
        //noop
      } else if (newValue == null && oldValue) {
        //cambio pagina, filtri, ordinamento
        vm.collection.$setSelectedItem(null);
        removeItemSelection();
        scrollToTop();
      }
    });

    function buildDetail(item) {
      vm.isLoadingDetail = true; //Erase previous detail structure
      //FIXME: il controller non viene distrutto, stiamo solo togliendo l'html

      $("div[transclude-id='detail']").empty();
      var promise = item.$fetch().$asPromise();
      promise.then(function (res) {
        compileItemDetail(res);
      }).finally(function () {
        vm.isLoadingDetail = false;
      });
      return promise;
    }

    ;
    /*******************************************
     * Sorters handlers
     *******************************************/

    /**
     * Apre chiude il pannello laterale per l'ordinamento
     */

    vm.toggleSorters = function () {
      $scope.sortersIsCollapsed = !$scope.sortersIsCollapsed;

      if (!$scope.sortersIsCollapsed) {
        $scope.quickFilterIsCollapsed = true;
      }
    };
    /**
     * Handler richiamato alla selezione di un ordinamento
     * @param sorter {object} {key, label, direction}
     */


    vm.sorterAddHandler = function (sorter) {
      vm.collection.$addSorter(sorter);

      if (vm.onSortAdd) {
        vm.onSortAdd({
          key: sorter.key,
          label: sorter.label,
          direction: sorter.direction
        });
      }

      $scope.$emit('osList:sortAdd', {
        key: sorter.key,
        label: sorter.label,
        direction: sorter.direction
      });
    };
    /**
     * Handler richiamato dalla direttiva sorters quando viene
     * rimosso un paramtro di ordinamento
     * @param sorterKey
     */


    vm.sorterRemoveHandler = function (sorterKey) {
      vm.collection.$removeSorter(sorterKey);

      if (vm.onSortRemove) {
        vm.onSortRemove({
          key: sorterKey
        });
      }

      $scope.$emit('osList:sortRemove', {
        key: sorterKey
      });
    };
    /**************************************
     * List pagination handlers
     **************************************/

    /**
     * Handler della toolbar di paginazione.
     * Si occupa di aggiornare lo stato dei parametri page e limit
     * @param page
     * @param limit
     */


    vm.onPaginate = onListPageChange;

    function onListPageChange(page, limit) {
      vm.collection.$changePage(page, limit);

      if (vm.onPageChange) {
        vm.onPageChange({
          page: page,
          limit: limit
        });
      }

      $scope.$emit('osList:paginate', {
        page: page,
        limit: limit
      });
    }
    /**
     * Handler eseguito all'aggiunta di un quickFilter
     * @param filter
     */


    vm.quickFilterAddHandler = function (filter) {
      vm.collection.$addFilter(filter);

      if (vm.onFilterAdd) {
        vm.onFilterAdd({
          filter: filter
        });
      }

      $scope.$emit('osList:filterAdd', {
        filter: filter
      });
    };
    /**
     * Handler eseguito alla rimozione di un quickFilter
     * @param key
     */


    vm.quickFilterRemoveHandler = function (filterKey) {
      vm.collection.$removeFilter(filterKey);

      if (vm.onFilterRemove) {
        vm.onFilterRemove({
          key: filterKey
        });
      }

      $scope.$emit('osList:filterRemove', {
        key: filterKey
      });
    };
    /**
     * Handler eseguito al reset dei quickFilter
     * @param filter
     */


    vm.quickFilterResetHandler = function () {
      vm.collection.$resetFilters();

      if (vm.onFilterReset) {
        vm.onFilterReset();
      }

      $scope.$emit('osList:filterReset');
    };
    /****************************
     * Toggle follow
     ****************************/


    vm.toggleFollow = function (item) {
      var promise = item.$toggleFollow();
      promise.$then(function (res) {
        item.preferito = !item.preferito;

        if (vm.onToggleFollow) {
          vm.onToggleFollow({
            item: item
          });
        }

        $scope.$emit('osList:toggleFollow', {
          item: item
        });
      });
    };
    /****************************
     * Last inserted item
     ****************************/


    vm.isLastInsertedItemBtnDisabled = function () {
      var isDisabled = false;

      if (!lastInsertedElementsService.retrieveLastInsertedElement(vm.collection.$config.name).length || lastInsertedElementsService.retrieveLastInsertedElement(vm.collection.$config.name).length === {}) {
        isDisabled = true;
      }

      return isDisabled;
    };

    vm.onShowLastInsertedItem = function () {
      var lastItem = lastInsertedElementsService.retrieveLastInsertedElement(vm.collection.$config.name)[0];
      vm.collection.$addAggIdFilter({
        aggId: lastItem.aggId
      }).$then(function () {
        vm.selectedItem = vm.collection.$new(lastItem.aggId);
      });
    };

    vm.onReloadList = function () {
      vm.selectedItem = null;
      vm.collection.$removeAggIdFilter();
    };
    /****************************
     * Free search event listener
     ****************************/


    $scope.$on('toggle-free-search', function (event, _freeSearchIsOpen) {
      vm.freeSearchIsOpen = _freeSearchIsOpen;

      if (vm.freeSearchIsOpen) {
        $scope.quickFilterIsCollapsed = true;
        $scope.sortersIsCollapsed = true;
        toggleMultipleSelection('f');
      }
    });
    /*****************************
     * Utils
     *****************************/

    function _loadPaginationLabels() {
      return $translate(['labels.page__text', 'labels.rowsPerPage__text', 'labels.of__text']);
    }

    function setPaginationLabel(translations) {
      $scope.labels.page = translations['labels.page__text'];
      $scope.labels.rowsPerPage = translations['labels.rowsPerPage__text'];
      $scope.labels.of = translations['labels.of__text'];
    }

    function removeItemSelection() {
      //Erase previous detail structure
      $('os-list-detail').empty();
      vm.item = null;
      $scope.$emit('osList:removeItemSelection');

      if (vm.onRemoveItemSelection) {
        vm.onRemoveItemSelection({
          osList: vm
        });
      }
    }

    function compileItemDetail(item) {
      $transclude($scope.$new(), function (transEl, transScope) {
        transScope.item = item; //$element.find("div[transclude-id='detail']")

        $('div[transclude-id="detail"]').append(transEl);
      }, null, 'detail');
    }

    function compileMultipleActions(collection) {
      $transclude($scope.$new(), function (transEl, transScope) {
        transScope.collection = collection;
        $('div[transclude-id="multipleActions"]').append(transEl);
      }, null, 'multipleActions');
    }
    /**
     * Ablilita la modalità di selezione multipla degli item
     */


    vm.toggleMultipleSelection = toggleMultipleSelection;

    function toggleMultipleSelection(_flag) {
      if (_flag) {
        vm.multipleSelectionMode = _flag === 't' ? vm.multipleSelectionMode = true : vm.multipleSelectionMode = false;
      } else {
        vm.multipleSelectionMode = !vm.multipleSelectionMode;
      }

      if (!vm.multipleSelectionMode) {
        //Erase previous multiple actions structure
        $('.multipleActions').empty();
      } else {
        compileMultipleActions(vm.collection);
      }

      vm.collection.$resetSelectedItems();
      vm.selectedItems = [];
    }
    /**
     * Se la selezione multipla è attivata, marca tutti gli elementi della lista
     */


    vm.toggleAllItemsSelection = function () {
      if (vm.selectAllItemsClicked) {
        vm.selectAllItemsClicked = false;
        vm.collection.$resetSelectedItems();
        vm.selectedItems = [];
      } else {
        vm.selectAllItemsClicked = true;
        vm.collection.$selectAll();
        vm.selectedItems = vm.collection.$getSelectedItems();
      }
    };
    /**
     * Aggiunge | rimuove dai selectedItems un singolo item dopo il click
     * @param item
     * @returns {Array}
     */


    function toggleItemSelection(item) {
      var itemIndex = R.findIndex(R.propEq(vm.primaryKey, item[vm.primaryKey]), vm.selectedItems);

      if (itemIndex > -1) {
        vm.selectedItems.splice(itemIndex, 1);
        vm.collection.$removeSelectedItem(item);
      } else {
        vm.collection.$addSelectedItem(item);
        vm.selectedItems.push(item);
      }

      return vm.selectedItems;
    }

    vm.toggleQuickFilter = toggleQuickFilter;

    function toggleQuickFilter() {
      $scope.quickFilterIsCollapsed = !$scope.quickFilterIsCollapsed;

      if (!$scope.quickFilterIsCollapsed) {
        $scope.sortersIsCollapsed = true;
      }
    }
    /**
     * OsList reset
     * Reset generico, un po' brutale, si potrebbe implementare qualcosa
     * di più raffinato
     */


    $scope.$on('osList::reset', function () {
      vm.items = [];
      vm.item = null;
      vm.selectedItems = [];
      vm.collection.$removeAggIdFilter();
    });
    /**
     * GB 30/05/2018
     * Il metodo porta lo scroll della vista ad uno specifico item
     * @param item
     */

    function scrollListTo(item) {
      var selectedElement, topPos;

      if (!item) {
        return;
      }

      selectedElement = $('.item--' + item[vm.primaryKey])[0];

      if (!selectedElement) {
        return;
      }

      topPos = selectedElement.offsetTop - 60;
      $('.os-list__List').animate({
        scrollTop: topPos
      }, 1200);
    }

    function scrollToTop() {
      $('.os-list__List').animate({
        scrollTop: 0
      }, 800);
    }
  }
})();