"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contoCorrenteValidationSchema = exports.toApiContoCorrente = exports.fromApiContoCorrente = exports.contoCorrenteEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const contoCorrenteEmptyForm = {
  intestazione: "",
  numero: "",
  utilizzo: ""
};
exports.contoCorrenteEmptyForm = contoCorrenteEmptyForm;

const fromApiContoCorrente = remoteCC => {
  const cc = { ...(remoteCC.aggId ? {
      aggId: remoteCC.aggId
    } : {}),
    // ID DEL COMUNE
    ...(remoteCC.entityId ? {
      entityId: remoteCC.entityId
    } : {}),
    intestazione: (0, _object.getPathStringOrEmptyString)(["intestazione"])(remoteCC),
    numero: (0, _object.getPathStringOrEmptyString)(["numero"])(remoteCC),
    utilizzo: (0, _object.getPathStringOrEmptyString)(["utilizzo"])(remoteCC)
  };
  return cc;
};

exports.fromApiContoCorrente = fromApiContoCorrente;

const toApiContoCorrente = values => {
  const cc = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    // ID DEL COMUNE
    ...(values.entityId ? {
      entityId: values.entityId
    } : {}),
    intestazione: (0, _object.getPathStringOrEmptyString)(["intestazione"])(values),
    numero: (0, _object.getPathStringOrEmptyString)(["numero"])(values),
    utilizzo: (0, _object.getPathStringOrEmptyString)(["utilizzo"])(values)
  };
  return cc;
};

exports.toApiContoCorrente = toApiContoCorrente;
const contoCorrenteValidationSchema = Yup.object().shape({
  intestazione: Yup.string().required(_utils.requiredMessage),
  numero: Yup.string().required(_utils.requiredMessage),
  utilizzo: Yup.string().required(_utils.requiredMessage)
});
exports.contoCorrenteValidationSchema = contoCorrenteValidationSchema;