"use strict";

/**
 * Created by MN 19/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').provider('indiciValoriTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

  function routeProvider(routesProviderFactory) {
    this.routes = [// LISTA INDICI VALORI

      /*{
          isBase      : false,
          isLogin     : false,
          name        : 'indiciValoriTds',
          url         : 'tds/indiciValori',
          templateUrl : 'tds/tabelle/indiciValori/indiciValori.list.html',
          controller  : 'IndiciValoriListController',
          controllerAs: 'indiciValoriListCtrl',
          sectionScope: 'tds.indiciValori.indiciValoriTds__text',
      },
       // NATURA INCIDENTE
      {
          isBase      : false,
          isLogin     : false,
          name        : 'naturaIncidenteTds',
          url         : 'tds/indiciValori/naturaIncidente',
          templateUrl : 'tds/tabelle/indiciValori/naturaIncidente/naturaIncidente.list.html',
          controller  : 'NaturaIncidenteListController',
          controllerAs: 'naturaIncidenteListCtrl'
      },
      {
          isBase      : false,
          isLogin     : false,
          name        : 'naturaIncidenteTdsEdit',
          url         : 'tds/indiciValori/naturaIncidente/{aggId}',
          params      : {aggId: null, item: null},
          templateUrl : 'tds/tabelle/indiciValori/naturaIncidente/edit/naturaIncidente.form.html',
          controller  : 'NaturaIncidenteFormController',
          controllerAs: 'naturaIncidenteFormCtrl'
      },
       // TIPO STRADA
      {
          isBase      : false,
          isLogin     : false,
          name        : 'tipoStradaTds',
          url         : 'tds/indiciValori/tipoStrada',
          templateUrl : 'tds/tabelle/indiciValori/tipoStrada/tipoStrada.list.html',
          controller  : 'TipoStradaListController',
          controllerAs: 'tipoStradaListCtrl'
      },
      {
          isBase      : false,
          isLogin     : false,
          name        : 'tipoStradaTdsEdit',
          url         : 'tds/indiciValori/tipoStrada/{aggId}',
          params      : {aggId: null, item: null},
          templateUrl : 'tds/tabelle/indiciValori/tipoStrada/edit/tipoStrada.form.html',
          controller  : 'TipoStradaFormController',
          controllerAs: 'tipoStradaFormCtrl'
      },
       // PARTICOLARITA STRADA
      {
          isBase      : false,
          isLogin     : false,
          name        : 'particolaritaStradaTds',
          url         : 'tds/indiciValori/particolaritaStrada',
          templateUrl : 'tds/tabelle/indiciValori/particolaritaStrada/particolaritaStrada.list.html',
          controller  : 'ParticolaritaStradaListController',
          controllerAs: 'particolaritaStradaListCtrl'
      },
      {
          isBase      : false,
          isLogin     : false,
          name        : 'particolaritaStradaTdsEdit',
          url         : 'tds/indiciValori/particolaritaStrada/{aggId}',
          params      : {aggId: null, item: null},
          templateUrl : 'tds/tabelle/indiciValori/particolaritaStrada/edit/particolaritaStrada.form.html',
          controller  : 'ParticolaritaStradaFormController',
          controllerAs: 'particolaritaStradaFormCtrl'
      },
       // PAVIMENTAZIONE STRADA
      {
          isBase      : false,
          isLogin     : false,
          name        : 'pavimentazioneStradaTds',
          url         : 'tds/indiciValori/pavimentazioneStrada',
          templateUrl : 'tds/tabelle/indiciValori/pavimentazioneStrada/pavimentazioneStrada.list.html',
          controller  : 'PavimentazioneStradaListController',
          controllerAs: 'pavimentazioneStradaListCtrl'
      },
      {
          isBase      : false,
          isLogin     : false,
          name        : 'pavimentazioneStradaTdsEdit',
          url         : 'tds/indiciValori/pavimentazioneStrada/{aggId}',
          params      : {aggId: null, item: null},
          templateUrl : 'tds/tabelle/indiciValori/pavimentazioneStrada/edit/pavimentazioneStrada.form.html',
          controller  : 'PavimentazioneStradaFormController',
          controllerAs: 'pavimentazioneStradaFormCtrl'
      },
       // CONDIZIONI DI LUCE
      {
          isBase      : false,
          isLogin     : false,
          name        : 'condizioneLuceTds',
          url         : 'tds/indiciValori/condizioneLuce',
          templateUrl : 'tds/tabelle/indiciValori/condizioneLuce/condizioneLuce.list.html',
          controller  : 'CondizioneLuceListController',
          controllerAs: 'condizioneLuceListCtrl'
      },
      {
          isBase      : false,
          isLogin     : false,
          name        : 'condizioneLuceTdsEdit',
          url         : 'tds/indiciValori/condizioneLuce/{aggId}',
          params      : {aggId: null, item: null},
          templateUrl : 'tds/tabelle/indiciValori/condizioneLuce/edit/condizioneLuce.form.html',
          controller  : 'CondizioneLuceFormController',
          controllerAs: 'condizioneLuceFormCtrl'
      },
       // TIPO VEICOLO ISTAT
      {
          isBase      : false,
          isLogin     : false,
          name        : 'tipoVeicoloIstatTds',
          url         : 'tds/indiciValori/tipoVeicoloIstat',
          templateUrl : 'tds/tabelle/indiciValori/tipoVeicoloIstat/tipoVeicoloIstat.list.html',
          controller  : 'TipoVeicoloIstatListController',
          controllerAs: 'tipoVeicoloIstatListCtrl'
      },
      {
          isBase      : false,
          isLogin     : false,
          name        : 'tipoVeicoloIstatTdsEdit',
          url         : 'tds/indiciValori/tipoVeicoloIstat/{aggId}',
          params      : {aggId: null, item: null},
          templateUrl : 'tds/tabelle/indiciValori/tipoVeicoloIstat/edit/tipoVeicoloIstat.form.html',
          controller  : 'TipoVeicoloIstatFormController',
          controllerAs: 'tipoVeicoloIstatFormCtrl'
      },
       // CARRO ATTREZZI RIMOZIONE
      {
          isBase      : false,
          isLogin     : false,
          name        : 'carroAttrezziRimozioneTds',
          url         : 'tds/indiciValori/carroAttrezziRimozione',
          templateUrl : 'tds/tabelle/indiciValori/carroAttrezziRimozione/carroAttrezziRimozione.list.html',
          controller  : 'CarroAttrezziRimozioneListController',
          controllerAs: 'carroAttrezziRimozioneListCtrl'
      },
      {
          isBase      : false,
          isLogin     : false,
          name        : 'carroAttrezziRimozioneTdsEdit',
          url         : 'tds/indiciValori/carroAttrezziRimozione/{aggId}',
          params      : {aggId: null, item: null},
          templateUrl : 'tds/tabelle/indiciValori/carroAttrezziRimozione/edit/carroAttrezziRimozione.form.html',
          controller  : 'CarroAttrezziRimozioneFormController',
          controllerAs: 'carroAttrezziRimozioneFormCtrl'
      },
       // DEPOSITERIE
      {
          isBase      : false,
          isLogin     : false,
          name        : 'depositerieTds',
          url         : 'tds/indiciValori/depositerie',
          templateUrl : 'tds/tabelle/indiciValori/depositerie/depositerie.list.html',
          controller  : 'DepositerieListController',
          controllerAs: 'depositerieListCtrl'
      },
      {
          isBase      : false,
          isLogin     : false,
          name        : 'depositerieTdsEdit',
          url         : 'tds/indiciValori/depositerie/{aggId}',
          params      : {aggId: null, item: null},
          templateUrl : 'tds/tabelle/indiciValori/depositerie/edit/depositerie.form.html',
          controller  : 'DepositerieFormController',
          controllerAs: 'depositerieFormCtrl'
      }*/
    ];
    routesProviderFactory.extend(this);
  }
})();