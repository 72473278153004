"use strict";

angular.module('osFramework').directive('ricercaEnteSuperioreProvincia', [RicercaEnteSuperioreProvincia]);

function RicercaEnteSuperioreProvincia() {
  return {
    restrict: 'E',
    require: ['ngModel', 'ricercaEnteSuperioreProvincia'],
    scope: {
      entity: '=?',
      label: '@?',
      onEnteSuperioreRicercaChange: '<?',
      isRequired: '<?',
      name: '@?'
    },
    controller: ['$scope', controllerFn],
    controllerAs: 'ricercaEnteSuperioreProvinciaCtrl',
    templateUrl: 'osFramework/input/ricercaEnteSuperioreProvincia/ricercaEnteSuperioreProvincia.directive.html',
    link: linkFn
  };

  function controllerFn(scope) {
    var me = this;
    me.groupRoles = ['ROLE_ENTE_TERRITORIALE_RICERCA', 'ROLE_ENTE_TERRITORIALE_DETTAGLIO'];

    me.getItemText = function (ente) {
      var itemText;
      itemText = ente.tipoEnteTerritoriale + ' - ' + ente.nome;
      return itemText;
    };
  }

  function linkFn(scope, element, attrs, controllers) {
    var ngModelCtrl = controllers[0];

    ngModelCtrl.$render = function () {
      var viewValue = ngModelCtrl.$viewValue;

      if (!viewValue) {
        scope.aggId = null;
        return;
      }

      scope.aggId = viewValue;
    };

    scope.$watch('aggId', function (newVal) {
      ngModelCtrl.$setViewValue(newVal);
    });
  }
}