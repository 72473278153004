"use strict";

/**
 * Created by MN 16/12/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').controller('ContoEconomicoCompareBilanciController', ['$scope', ContoEconomicoCompareBilanciController]);

  function ContoEconomicoCompareBilanciController($scope) {
    var me = this;
    $scope.data = {
      'contoEconomico': {
        'valoreProduzione': {
          'valore': [{
            'anno': 2018,
            'valore': 123.20
          }, {
            'anno': 2019,
            'valore': 456.12
          }],
          'ricaviVendite': {
            'etichettaVoceBilancio': 'Ricavi dalle vendite e delle prestazioni',
            'breadcrumbBilancioCC': 'A) Valore della produzione → 1)',
            'valore': [{
              'anno': 2018,
              'valore': 789.20
            }, {
              'anno': 2019,
              'valore': 101.12
            }],
            'composizioneDaPianoConti': [{
              'etichettaConto': 'Permessi ztl',
              'descrizioneConto': 'Valore dei permessi ztl emessi',
              'valoreContoEnum': 'PERMESSI_ZTL',
              'valore': [{
                'anno': 2018,
                'valore': 12.20
              }, {
                'anno': 2019,
                'valore': 13.12
              }]
            }, {
              'etichettaConto': 'Verbali emessi',
              'descrizioneConto': 'Valore dei verbali emessi',
              'valoreContoEnum': 'VERBALI_EMESSI',
              'valore': [{
                'anno': 2018,
                'valore': 14.20
              }, {
                'anno': 2019,
                'valore': 15.12
              }]
            }]
          },
          'altriRicaviProventi': {
            'etichettaVoceBilancio': 'Altri ricavi e proventi',
            'breadcrumbBilancioCC': 'A) Valore della produzione → 5)',
            'valore': [{
              'anno': 2018,
              'valore': 16.20
            }, {
              'anno': 2019,
              'valore': 17.12
            }],
            'composizioneDaPianoConti': [{
              'etichettaConto': 'Rimborsi spese arvisura',
              'descrizioneConto': 'Rimborsi per spese di visura',
              'valoreContoEnum': 'RIMBORSI_SPESE_VISURA',
              'valore': [{
                'anno': 2018,
                'valore': 18.20
              }, {
                'anno': 2019,
                'valore': 19.12
              }]
            }, {
              'etichettaConto': 'Rimborsi spese notirica',
              'descrizioneConto': 'Rimborsi per spese di notifica',
              'valoreContoEnum': 'RIMBORSI_SPESE_NOTIFICA',
              'valore': [{
                'anno': 2018,
                'valore': 20.20
              }, {
                'anno': 2019,
                'valore': 21.12
              }]
            }, {
              'etichettaConto': 'Eccedenze',
              'descrizioneConto': 'Eccedenze derivate da versamenti superiori al valore del verbale da saldare',
              'valoreContoEnum': 'ECCEDENZE',
              'valore': [{
                'anno': 2018,
                'valore': 22.20
              }, {
                'anno': 2019,
                'valore': 23.12
              }]
            }, {
              'etichettaConto': 'Rimborsi spese processuali',
              'descrizioneConto': 'Rimborsi per spese di processuali',
              'valoreContoEnum': 'RIMBORSI_SPESE_PROCESSUALI',
              'valore': [{
                'anno': 2018,
                'valore': 24.20
              }, {
                'anno': 2019,
                'valore': 25.12
              }]
            }, {
              'etichettaConto': 'Preavvisi emessi',
              'descrizioneConto': 'Valore dei preavvisi emessi',
              'valoreContoEnum': 'PREAVVISI_EMESSI',
              'valore': [{
                'anno': 2018,
                'valore': 26.20
              }, {
                'anno': 2019,
                'valore': 27.12
              }]
            }, {
              'etichettaConto': 'Rimborsi spese generiche',
              'descrizioneConto': 'Rimborsi per spese generiche',
              'valoreContoEnum': 'RIMBORSI_SPESE_GENERICHE',
              'valore': [{
                'anno': 2018,
                'valore': 28.20
              }, {
                'anno': 2019,
                'valore': 29.12
              }]
            }]
          }
        },
        'costiProduzione': {
          'valore': [{
            'anno': 2018,
            'valore': 30.20
          }, {
            'anno': 2019,
            'valore': 31.12
          }],
          'oneriDiversi': {
            'etichettaVoceBilancio': 'Oneri diversi',
            'breadcrumbBilancioCC': 'B) Costi della produzione → 10)',
            'valore': [{
              'anno': 2018,
              'valore': 32.20
            }, {
              'anno': 2019,
              'valore': 33.12
            }],
            'composizioneDaPianoConti': [{
              'etichettaConto': 'Perdita crediti',
              'descrizioneConto': 'Crediti perduti in caso di inesigibilta',
              'valoreContoEnum': 'PERDITA_CREDITI',
              'valore': [{
                'anno': 2018,
                'valore': 34.20
              }, {
                'anno': 2019,
                'valore': 35.12
              }]
            }]
          },
          'costiServizi': {
            'etichettaVoceBilancio': 'Costi servizi',
            'breadcrumbBilancioCC': 'B) Costi della produzione → 7)',
            'valore': [{
              'anno': 2018,
              'valore': 36.20
            }, {
              'anno': 2019,
              'valore': 37.12
            }],
            'composizioneDaPianoConti': [{
              'etichettaConto': 'Spese generiche',
              'descrizioneConto': 'Spese sostenuto per spese generiche',
              'valoreContoEnum': 'SPESE_GENERICHE',
              'valore': [{
                'anno': 2018,
                'valore': 38.20
              }, {
                'anno': 2019,
                'valore': 39.12
              }]
            }, {
              'etichettaConto': 'Spese visura',
              'descrizioneConto': 'Spese sostenuto per le visure',
              'valoreContoEnum': 'SPESE_VISURA',
              'valore': [{
                'anno': 2018,
                'valore': 40.20
              }, {
                'anno': 2019,
                'valore': 41.12
              }]
            }, {
              'etichettaConto': 'Spese processuali',
              'descrizioneConto': 'Spese sostenuto per processi',
              'valoreContoEnum': 'SPESE_PROCESSUALI',
              'valore': [{
                'anno': 2018,
                'valore': 42.20
              }, {
                'anno': 2019,
                'valore': 43.12
              }]
            }, {
              'etichettaConto': 'Spese spedizione notifica',
              'descrizioneConto': 'Spese sostenuto per la spedizione e notifica',
              'valoreContoEnum': 'SPESE_SPEDIZIONE_NOTIFICA',
              'valore': [{
                'anno': 2018,
                'valore': 44.20
              }, {
                'anno': 2019,
                'valore': 45.12
              }]
            }]
          },
          'accantonamentiRischi': {
            'etichettaVoceBilancio': 'Accantonamenti per rischi',
            'breadcrumbBilancioCC': 'B) Costi della produzione → 12)',
            'valore': [{
              'anno': 2018,
              'valore': 46.20
            }, {
              'anno': 2019,
              'valore': 47.100000
            }],
            'composizioneDaPianoConti': [{
              'etichettaConto': 'Fondo svalutazione',
              'descrizioneConto': 'Rivalutazione da minimo edittale a scontato',
              'valoreContoEnum': 'FONDO_SVALUTAZIONE',
              'valore': [{
                'anno': 2018,
                'valore': 48.20
              }, {
                'anno': 2019,
                'valore': 49.100000
              }]
            }]
          }
        },
        'etichettaNodoBilancio': 'Conto economico'
      }
    };

    me.$onInit = function () {};

    $scope.nestedSectionIsOpen = false;
    $scope.expandedRowKey = null;

    $scope.toggleNestedSection = function (_key) {
      if (!me.expandedRowKey) {
        openNestedSection(_key);
      } else if (me.expandedRowKey === _key) {
        closeNestedSection();
      } else if (me.expandedRowKey !== _key && me.nestedSectionIsOpen) {
        me.expandedRowKey = _key;
      }
    };
    /**
     * Methods implementation
     */


    function closeNestedSection() {
      $scope.expandedRowKey = null;
      $scope.nestedSectionIsOpen = false;
    }

    function openNestedSection(_key) {
      $scope.expandedRowKey = _key;
      $scope.nestedSectionIsOpen = true;
    }
  }
})();