"use strict";

/**
 * Created by MN 12/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds', ['polcity.api', //'ngMaterial',
  'pascalprecht.translate' //'md.data.table'
  ]).config(['$translatePartialLoaderProvider', function ($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('tds');
  }]);
})();