"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.evtToProgress = exports.evtToPosizione = void 0;

var _utils = require("../../utils");

const evtToPosizione = evt => {
  const data = JSON.parse(evt.data);
  const riferimentoImporto = data.riferimentoBE.riferimentoImportoIUV;
  const spedizioneId = data.riferimentoBE.spedizioneAggId;
  const esito = data.esitoApertura.esito;
  const iuv = esito === "OK" ? data.esitoApertura.identificativoPosizione : null;
  const errori = esito === "OK" ? null : data.esitoApertura.erroriDiValidazione.map(err => err.descrizione).join(", ");
  const posizione = {
    spedizioneId,
    [`iuv${(0, _utils.capitalize)(riferimentoImporto)}`]: esito === "OK" ? iuv : null,
    [`errore${(0, _utils.capitalize)(riferimentoImporto)}`]: esito === "OK" ? null : errori
  };
  return posizione;
};

exports.evtToPosizione = evtToPosizione;

const evtToProgress = evt => {
  const data = JSON.parse(evt.data);
  const {
    numSuTotale,
    totaleLotto
  } = data.riferimentoBE;
  const perc = numSuTotale / totaleLotto ? Math.round(numSuTotale / totaleLotto * 100) : 100;
  return {
    receivedMessages: numSuTotale,
    expectedMessages: totaleLotto,
    isLoadingIuv: numSuTotale != totaleLotto,
    perc
  };
};

exports.evtToProgress = evtToProgress;