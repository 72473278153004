"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.entiListColumns = void 0;

var _enums = require("../../../state/enums");

var _utils = require("../../../utils");

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const entiListColumns = [{
  field: 'codiceVeloce',
  headerName: 'Codice veloce',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'tipoEnte',
  headerName: 'Tipo ente',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaTipiEnte") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'ragioneSociale',
  headerName: 'Ragione sociale',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'nomeStato',
  headerName: 'Stato',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'enteTerritoriale',
  headerName: 'Ente territoriale',
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  valueGetter: ({
    row
  }) => row ? (0, _utils.capitalize)(row.tipoEnteTerritoriale).concat(" ", row.nomeEnteTerritoriale) : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.entiListColumns = entiListColumns;