"use strict";

var _ArchivioArmaposteMasterDetail = _interopRequireDefault(require("./masterDetail/ArchivioArmaposteMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.archivioArmaposte').provider('archivioArmaposteRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'archivioArmaposteList',
    role: '',
    url: 'archivioArmaposte?{activeFilters:string}&{activeItemIdVerbale:string}',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ArchivioArmaposteMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Archivio armaposte 1.0"
    }
  }];
  routesProviderFactory.extend(this);
}