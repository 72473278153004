"use strict";

/**
 * MN 13/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaRiferimentoComune', [RicercaRiferimentoComune]);

  function RicercaRiferimentoComune() {
    return {
      restrict: 'E',
      require: ['ngModel'],
      scope: {
        entity: '=?',
        label: '@?',
        onRiferimentoComuneChange: '<?',
        isRequired: '<?',
        name: '@?',
        inceptionMode: '<?',
        afterInsert: '<?'
      },
      controller: ['$scope', '$mdDialog', 'Command', controllerFn],
      controllerAs: 'ricercaRiferimentoComuneCtrl',
      templateUrl: 'osFramework/input/ricercaRiferimentoComune/ricercaRiferimentoComune.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $mdDialog, Command) {
      var me = this;
      me.groupRoles = ['ROLE_RIFERIMENTO_COMUNE_RICERCA', 'ROLE_RIFERIMENTO_COMUNE_DETTAGLIO'];

      me.getItemText = function (comune) {
        var itemText;
        itemText = comune.nomeCompleto;
        return itemText;
      };

      me.nuovoRifComune = function () {
        var self = this,
            confirmable = $mdDialog.show({
          templateUrl: 'osFramework/input/ricercaRiferimentoComune/modal/insertEditRiferimentoComune.modal.html',
          fullscreen: false,
          escapeToClose: true,
          controller: 'ComuniFormController',
          controllerAs: 'comuniFormCtrl',
          bindToController: true,
          focusOnOpen: true,
          multiple: true,
          locals: {
            hide: $mdDialog.cancel,
            save: $mdDialog.hide,
            inceptionMode: scope.inceptionMode
          }
        }).then(function (comune) {
          return salvaComune(comune);
        }).then(function (comune) {
          if (scope.afterInsert) {
            scope.afterInsert(comune);
          }
        }).catch(function (e) {
          console.log(e);
        });

        function salvaComune(comune) {
          comune.capoluogo = comune.capoluogo ? comune.capoluogo : false;
          return Command.execute('@salvaComune', comune);
        }
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.riferimentoComuneId = null;
          return;
        }

        scope.riferimentoComuneId = viewValue;
      };

      scope.$watch('riferimentoComuneId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();