"use strict";

var _RimozioneEditRoot = _interopRequireDefault(require("./edit/RimozioneEditRoot"));

var _RimozioniMasterDetail = _interopRequireDefault(require("./masterDetail/RimozioniMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.rimozioni').provider('rimozioniRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'rimozioniList',
    role: 'ROLE_RIMOZIONE_RICERCA',
    url: 'rimozioni?{page:int}&{limit:int}&{query:string}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _RimozioniMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Rimozioni"
    }
  }, {
    name: 'rimozioneEdit',
    role: 'ROLE_RIMOZIONE_MODIFICA',
    url: 'rimozioni/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _RimozioneEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita rimozione"
    }
  }];
  routesProviderFactory.extend(this);
}