"use strict";

/**
 * created by gbelli on 08/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaTipoProprieta', [RicercaTipoProprieta]);

  function RicercaTipoProprieta() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaTipoProprieta'],
      scope: {
        entity: '=?',
        label: '@?',
        onTipoProprietaChange: '<?',
        isRequired: '<?',
        name: '@?',
        language: '@?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'ricercaTipoProprietaCtrl',
      templateUrl: 'osFramework/input/ricercaTipoProprieta/ricercaTipoProprieta.directive.html',
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this;
      me.groupRoles = ['ROLE_TIPO_PROPRIETA_RICERCA', 'ROLE_TIPO_PROPRIETA_DETTAGLIO'];
      scope.language = scope.language || "it_IT";

      me.getItemText = function (tipoProprieta) {
        var itemText;
        itemText = R.find(R.propEq('languageTag', scope.language))(tipoProprieta.descrizione).traduzione.toUpperCase();
        return itemText;
      };
    }

    ;

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaTipoProprietaCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.tipoProprietaAggId = null;
          return;
        }

        scope.tipoProprietaAggId = viewValue;
      };

      scope.$watch("tipoProprietaAggId", function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();