"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Soggetto', ['restmod', function (restmod) {
    return restmod.model('soggetto').mix({//documenti: {hasMany: 'Documento'},
      //veicoli  : {hasMany: 'VeicoloForm'},
    }, {
      $extend: {
        Scope: {
          $config: {
            name: 'soggetti',
            baseEntityPath: 'anagrafica/',
            api: {
              list: {
                url: 'ricerca/anagrafiche',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/anagrafica',
                method: 'POST'
              },
              destroy: {
                url: 'comando/cancella-anagrafica',
                method: 'POST'
              },
              save: {
                url: 'comando/aggiungi-anagrafica',
                method: 'POST'
              },
              edit: {
                url: 'comando/modifica-anagrafica',
                method: 'POST'
              },
              creaDocumento: {
                url: 'documento-anagrafica/comando/aggiungi-documento-anagrafica',
                method: 'POST'
              },
              modificaDocumento: {
                url: 'documento-anagrafica/comando/modifica-documento-anagrafica',
                method: 'POST'
              }
            }
          }
        }
      }
    }, // 'RicercaVeloce',
    'RicercaTestoLibero', 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl("list");
            };

            return collection;
          }
        },
        Record: {
          $creaDocumento: function (_params) {
            return this.$action(function () {
              var request = this.$buildRequest('creaDocumento', false);
              request.data = Object.assign({}, {
                aggId: this.$pk
              }, _params);
              this.$dispatch('before-crea-documento', [request]);
              this.$send(request, function (_response) {
                this.documenti = this.documenti ? R.concat(this.documenti, [_response.data]) : [_response.data];
                this.$dispatch('after-crea-documento', [_response]);
              }, function (_response) {
                this.$dispatch('after-crea-documento-error', [_response]);
              });
            });
          },
          $modificaDocumento: function (_params) {
            return this.$action(function () {
              var request = this.$buildRequest('modificaDocumento', false);
              request.data = Object.assign({}, _params);
              this.$dispatch('before-modifica-documento', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-modifica-documento', [_response]);
              }, function (_response) {
                this.$dispatch('after-modifica-documento-error', [_response]);
              });
            });
          },
          //Override della defaul $destroy
          $destroy: function () {
            return this.$action(function () {
              if (this.$pk) {
                var request = this.$buildRequest('destroy', true);
                request.data = {
                  aggId: this.$pk
                };
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$unwrap(this.$getResponseData(_response));
                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) {
                  this.$scope.$remove(this);
                }
              }
            });
          }
        }
      }
    });
  }]);
})();