"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.leggiListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

var _enums = require("../../../state/enums");

const leggiListColumns = [{
  field: 'codiceLegge',
  headerName: 'Codice legge',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'ambito',
  headerName: 'Ambito',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaTipiCodice") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.leggiListColumns = leggiListColumns;