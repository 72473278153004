"use strict";

/**
 * Created by MN 04/12/2019
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaModelloStampa', [RicercaModelloStampa]);

  function RicercaModelloStampa() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaModelloStampa'],
      scope: {
        entity: '=?',
        label: '@?',
        onModelloStampaChange: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'ricercaModelloStampaCtrl',
      templateUrl: 'osFramework/input/ricercaModelloStampa/ricercaModelloStampa.directive.html',
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this;
      me.groupRoles = ['ROLE_MODELLO_STAMPA_RICERCA', 'ROLE_MODELLO_STAMPA_DETTAGLIO'];

      me.getItemText = function (modello) {
        var itemText;
        itemText = modello.codice.toUpperCase().concat(' - ', modello.descrizione.toUpperCase());
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaModelloStampaCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.aggId = null;
          return;
        }

        scope.aggId = viewValue;
      };

      scope.$watch('aggId', function (newVal) {
        if (!newVal) {
          newVal = '';
        }

        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();