"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromApiModelloStampa = void 0;

var _object = require("../../object.utils");

const fromApiModelloStampa = remoteModelloStampa => {
  return { ...(remoteModelloStampa.aggId ? {
      aggId: remoteModelloStampa.aggId
    } : {}),
    codice: (0, _object.getPathStringOrEmptyString)(["codice"])(remoteModelloStampa),
    sottoCodiceVeloce: (0, _object.getPathStringOrEmptyString)(["sottoCodiceVeloce"])(remoteModelloStampa),
    languageTag: (0, _object.getPathStringOrEmptyString)(["languageTag"])(remoteModelloStampa),
    modulo: (0, _object.getPathStringOrEmptyString)(["modulo"])(remoteModelloStampa),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(remoteModelloStampa),
    funzioneStampaId: (0, _object.getPathStringOrEmptyString)(["funzione"])(remoteModelloStampa),
    descrizioneFunzione: (0, _object.getPathStringOrEmptyString)(["descrizioneFunzione"])(remoteModelloStampa),
    projectionClass: (0, _object.getPathStringOrEmptyString)(["projectionClass"])(remoteModelloStampa),
    inUso: (0, _object.getPathBooleanOrFalse)(["inUso"])(remoteModelloStampa)
  };
};

exports.fromApiModelloStampa = fromApiModelloStampa;