"use strict";

/**
 * MN 30/06/2020
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaCarroAttrezzi', [RicercaCarroAttrezzi]);

  function RicercaCarroAttrezzi() {
    return {
      restrict: 'E',
      require: ['ngModel'],
      scope: {
        entity: '=?',
        label: '@?',
        onCarroAttrezziChange: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', '$filter', controllerFn],
      controllerAs: 'ricercaCarroAttrezziCtrl',
      templateUrl: 'osFramework/input/ricercaCarroAttrezzi/ricercaCarroAttrezzi.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter) {
      var me = this;
      me.groupRoles = ['ROLE_CARRO_ATTREZZI_RICERCA', 'ROLE_CARRO_ATTREZZI_DETTAGLIO'];

      me.getItemText = function (carro) {
        var itemText;
        itemText = carro.descrizione;
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.aggId = null;
          return;
        }

        scope.aggId = viewValue;
      };

      scope.$watch('aggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();