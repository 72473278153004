"use strict";

var _object = require("../object.utils");

/**
 * Created by MN il 28/05/2018
 * Refactor by MN il 08/11/2018
 * Refactored by gbelli on 17/09/2019
 * Componente per gestire il dettaglio del ricorso
 */
(function () {
  'use strict';

  angular.module('polcity.ricorsi').component('ricorsiListDetail', {
    bindings: {
      item: '<'
    },
    templateUrl: 'ricorsi/ricorsi.listDetail.component.html',
    controller: ['OSNotification', 'modalPresenter', 'Command', RicorsiListDetailController],
    controllerAs: 'RicorsiListDetailCtrl'
  });

  function RicorsiListDetailController(OSNotification, modalPresenter, Command) {
    var me = this;
    me.tipoParere = "";
    /**
     * Operazioni eseguite in istanza del componente
     */

    me.$onInit = function () {
      fetchControdeduzione().then(setControdeduzione);
    };

    const fetchControdeduzione = () => Command.execute("@ricorsoGetControdeduzioni", {
      aggId: me.item.aggId
    });

    const setControdeduzione = res => {
      const controdeduzioni = (0, _object.getPathArrayOrEmptyArray)(["resultsWrapper", "values"])(res);
      me.tipoParere = controdeduzioni[controdeduzioni.length - 1].tipoParere || "";
    };

    me.isDeleting = false;
    /**
     * Cancella un ricorso, fa vedere una notifica e scatena un evento per aggiornare la lista
     * @param ricorso
     */

    me.deleteRicorso = function (ricorso) {
      modalPresenter.warn(['ricorsi.cancellazione.delete__title', 'ricorsi.cancellazione.delete__body', 'ricorsi.cancellazione.deleteConfirm__button', 'ricorsi.cancellazione.undo__buttonText']).then(function () {
        me.isDeleting = true;
        ricorso.$destroy().$asPromise().then(function () {
          OSNotification.success({
            translationIndex: 'ricorsi.cancellazione.deleteSuccess__notification'
          });
        }).finally(function () {
          me.isDeleting = false;
        });
      });
    };
    /**
     * OSTABLE SETTINGS
     *
     * Nomi colonne tabella
     * @type {*[]}
     */


    me.column = [{
      text: 'Numero verbale',
      dataIndex: 'idVerbale'
    }, {
      text: 'Numero protocollo',
      dataIndex: 'numeroProtocollo'
    }, {
      text: 'Targa',
      dataIndex: 'targa'
    }, {
      text: 'Data / ora verbale',
      dataIndex: 'dataOraVerbale',
      renderer: function (item) {
        return moment(item.dataOraVerbale).format('LLL');
      }
    }, {
      text: 'Stato verbale',
      dataIndex: 'statoVerbale'
    }];
  }
})();