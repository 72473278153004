"use strict";

(function () {
  'use strict';

  angular.module('polcity.soggetti').config(['$stateProvider', 'soggettiRoutesProvider', 'AuthProvider', addRoutes]).run(['soggettiRoutes', routesRun]);

  function addRoutes($stateProvider, soggettiRoutesProvider, AuthProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }
    }, soggettiRoutesProvider.list());
  }

  function routesRun(soggettiRoutes) {
    soggettiRoutes.subscribeUIEvents();
  }
})();