"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VerbaleAllegati = void 0;

var _index = require("angular2react/index");

var _lazyInjector = require("../../lazyInjector");

var _utils = require("../../utils");

const AllegatiCfg = {
  templateUrl: 'verbale/allegati/allegati.view.html',
  controllerAs: 'AllegatiVerbaleController',
  controller: 'AllegatiVerbaleController',
  bindings: {}
};
angular.module('polcity.verbali').component('alleGati', AllegatiCfg).controller('AllegatiVerbaleController', ['$scope', AllegatiVerbaleController]);

function AllegatiVerbaleController(scope) {
  var me = this;

  me.$onInit = () => {};

  me.$onChanges = ({}) => {};
}

const VerbaleAllegati = (0, _index.angular2react)('alleGati', AllegatiCfg, _lazyInjector.lazyInjector.$injector);
exports.VerbaleAllegati = VerbaleAllegati;