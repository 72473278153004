"use strict";

(function () {
  'use strict';

  angular.module('osListComponent').component('freeSearch', {
    templateUrl: 'osFramework/osList/freeSearch/freeSearch.component.html',
    controller: ['api', '$scope', FreeSearchController],
    bindings: {
      freeSearchEntityModel: '<',
      freeSearchQuery: '<'
    }
  });

  function FreeSearchController(api, $scope) {
    var vm = this,
        preQueryParams;
    /**
     * GB 09/06/2019
     * Metodo invocato al submit della form di freeSearch
     * @type {onFormSubmit}
     */

    vm.onFormSubmit = onFormSubmit;
    /**
     * Variabile di stato per il testo della freeSearch
     * @type {string}
     */

    vm.freeSearchQuery = $scope.$parent.freeSearchQuery;
    /**
     * Flag discriminante apertura/chiusura campo di ricerca
     * @type {boolean}
     */

    vm.openFreeSearchField = false;
    /**
     * Operazioni di inizializzazione
     *
     * 1) Se all'inizializzazione ho già in ingresso una query la eseguo
     */

    vm.$onInit = function () {
      if (vm.freeSearchQuery) {
        vm.onFormSubmit(vm.freeSearchQuery);
      }
    };
    /**
     * Handlers
     */


    vm.toggleFreeSearchField = function () {
      vm.openFreeSearchField = vm.freeSearchEntityModel.$isRicercaTestoLiberoActive = !vm.openFreeSearchField;
      $scope.$emit('toggle-free-search', vm.openFreeSearchField);

      if (!vm.openFreeSearchField && vm.freeSearchQuery) {
        clearFreeSearch();
      }
    };
    /**
     * GB 09/06/2018
     * Metodo invocato al submit della form.
     * Chiamiamo l'endpoint della ricerca libera e gestionamo il risultato
     * @param queryString
     */


    function onFormSubmit(queryString) {
      if (queryString) {
        preQueryParams = vm.freeSearchEntityModel.$metadata.search;

        if (preQueryParams.aggId) {
          preQueryParams.aggId = null;
        }

        var queryParams = {
          query: queryString,
          order: preQueryParams.order,
          limit: preQueryParams.limit || 10,
          page: 1
        };
        vm.freeSearchEntityModel.$ricercaTestoLibero(queryParams).$then(function (res) {
          //keep focus after search
          const freeSearchInput = document.getElementById('freeSearchInputElement');
          freeSearchInput && freeSearchInput.focus();
        }, function (errors) {
          //Ripristino lo stato precedente
          queryParams = preQueryParams;

          if (vm.freeSearchEntityModel.$isRicercaVeloce) {
            vm.freeSearchEntityModel.$ricercaVeloce(queryParams);
          } else if (vm.freeSearchEntityModel.$isRicercaTestoLibero) {
            vm.freeSearchEntityModel.$ricercaTestoLibero(queryParams);
          } else {
            vm.freeSearchEntityModel.$fetch(queryParams);
          }
        });
      } else {
        clearFreeSearch();
      }

      vm.freeSearchQuery = queryString;
    }
    /**
     * Metodo invocato alla chiusura della free search o alla cancellazione della queryString
     */


    function clearFreeSearch() {
      if (vm.freeSearchEntityModel.$isRicercaVeloce) {
        vm.freeSearchEntityModel.$ricercaVeloce(preQueryParams);
      } else {
        // var request = vm.freeSearchEntityModel.$buildRequest('list', true);
        vm.freeSearchEntityModel.$fetch(preQueryParams
        /*, request*/
        );
      }

      vm.freeSearchQuery = '';
      preQueryParams = null;
    }
  }
})();