"use strict";

/**
 * Created by gb on 15/10/19.
 */
(function () {
  'use strict';

  angular.module('polcity.documenti').service('documentiApiService', ['Command', documentiApiService]);

  function documentiApiService(Command) {
    return {};
  }
})();