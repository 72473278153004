"use strict";

/**
 * Created by MN 12/02/2020
 */
(function () {
  'use strict';

  angular.module('polcity.widgetTypes').component('numberWidgetComponent', {
    templateUrl: 'dashboard/widgetTypes/number/number.widget.component.html',
    controller: 'NumberWidgetComponentController',
    controllerAs: '$ctrl',
    bindings: {
      numberWidgetData: '<'
    }
  }).controller('NumberWidgetComponentController', ['$scope', NumberWidgetComponentController]);

  function NumberWidgetComponentController($scope) {
    var me = this;
    me.noDataAvaiable = false;
    me.selectableColors = [{
      label: 'bianco',
      value: '#FFFFFF'
    }, {
      label: 'nero',
      value: '#000000'
    }, {
      label: 'rosso',
      value: '#F44336'
    }, {
      label: 'verde',
      value: '#4CAF50'
    }, {
      label: 'giallo',
      value: '#ffeb3b'
    }, {
      label: 'arancione',
      value: '#ff5722'
    }, {
      label: 'blu',
      value: '#2196f3'
    }];
    me.bgSelectableColors = [{
      label: 'bianco',
      value: '#FFFFFF'
    }, {
      label: 'grigio',
      value: '#d3d3d3'
    }, {
      label: 'rosso',
      value: '#f08080'
    }, {
      label: 'verde',
      value: '#90ee90'
    }, {
      label: 'giallo',
      value: '#ffff009c'
    }, {
      label: 'arancione',
      value: '#ffa07a'
    }, {
      label: 'blu',
      value: '#add8e6'
    }];
    $scope.$watch(() => me.numberWidgetData.data, function (newVal, oldVal) {
      onInit();
    }, true);
    me.$onInit = onInit;

    function onInit() {
      if (!me.numberWidgetData) {
        return;
      }

      if (!me.numberWidgetData.data || !me.numberWidgetData.data.length) {
        me.noDataAvaiable = true;
      }
    }
    /**
     * Handlers
     */


    me.onRemoveWidget = function (_widget) {
      $scope.$emit('remove-widget', _widget);
    };

    me.onSelectColor = function (_color) {
      me.numberWidgetData.numberColor = _color;
    };

    me.onSelectBgColor = function (_color) {
      me.numberWidgetData.numberBgColor = _color;
    };
  }
})();