"use strict";

/**
 * Created by MN 17/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('ComuniFormController', ['$state', '$stateParams', 'Command', '$tdsApiService', '$tdsGlobalBehaviors', ComuniFormController]);

  function ComuniFormController($state, $stateParams, Command, $tdsApiService, $tdsGlobalBehaviors) {
    var comuniFormCtrl = this,
        dettaglioCommand = '@dettaglioComuneRif',
        modificaCommand = '@modificaComune',
        salvaCommand = '@salvaComune',
        redirectTo = 'navigation.tdsComuni';
    /**
     * Discriminante per capire se sono in edit
     * @type {boolean}
     */

    comuniFormCtrl.editMode = false;
    comuniFormCtrl.inceptionMode = $state.$current.name !== 'navigation.tdsRiferimentiComuniEdit';
    /**
     * @Property
     * Configuro l'aggId a zero leggendolo dagli $stateParams nel caso sia in inserimento
     * oppure lo recupero dall'oggetto nel caso in cui sia in edit
     */

    comuniFormCtrl.riferimentoComuneId = $stateParams.riferimentoComuneId;
    /**
     * @Property
     * Configuro l'oggetto recuperandolo dagli $stateParams nel caso in cui sia in edit
     * oppure assegnando un oggetto vuoto nel caso in cui sia in inserimento
     */

    comuniFormCtrl.item = {};
    /**
     * Handler onInit function
     * @type {onInit}
     */

    comuniFormCtrl.$onInit = onInit;
    /**
     * Operazioni eseguite in istanza del controller
     */

    function onInit() {
      // SE NEGLI STATE PARAMS HO L'AGGID POSSO ESSERE IN EDIT OPPURE AVER RICARICATO LA PAGINA
      if (comuniFormCtrl.riferimentoComuneId) {
        comuniFormCtrl.editMode = true;
        var params = {
          riferimentoComuneId: comuniFormCtrl.riferimentoComuneId
        };
        $tdsApiService.executeCommand(dettaglioCommand, params).then(function (res) {
          comuniFormCtrl.item = res;
          comuniFormCtrl.dataInserimentoForView = moment(comuniFormCtrl.item.dataInserimento).format('LL');
        }).catch(function () {
          handleItemLoadError();
        });
      } else {
        comuniFormCtrl.editMode = false;
      }
    }
    /**
     * Handler su ricerca stati
     * @param query
     * @returns {*}
     */


    comuniFormCtrl.queryApi = function (query) {
      var data = {};

      if (query) {
        data.nome = query;
      }

      return recuperaStati(data);
    };
    /**
     * Handler su ricerca enti territoriali
     * @param query
     * @returns {*}
     */


    comuniFormCtrl.queryEntiTerritoriali = function (query) {
      var data = {};

      if (query) {
        data.nome = query;
      }

      return recuperaEntiTerritoriali(data);
    };

    comuniFormCtrl.afterNewEnteTerritorialeInsert = function (_data) {
      console.log(_data);
      comuniFormCtrl.item.enteTerritorialeId = _data.entityId;
    };
    /**
     * Recupera i comuni
     */


    function recuperaStati(params) {
      return Command.execute('@ricercaNazioni', params).then(function (res) {
        return res.resultsWrapper.values;
      });
    }
    /**
     * Recupera i comuni
     */


    function recuperaEntiTerritoriali(params) {
      return Command.execute('@ricercaEntiTerritoriali', params).then(function (res) {
        return res.resultsWrapper.values;
      });
    }
    /**
     * @property
     * Handler da eseguire al click sul pulsante del salvataggio
     * @param item
     */


    comuniFormCtrl.onSaveComuneButtonClick = function (item) {
      var command = comuniFormCtrl.editMode ? modificaCommand : salvaCommand;
      item.capoluogo = item.capoluogo ? item.capoluogo : false;
      $tdsApiService.salvaElementoAndRedirect(command, item, comuniFormCtrl.editMode, redirectTo);
    };
    /****************
     * Utils
     ***************/


    function handleItemLoadError() {
      //Notifica errore
      $tdsGlobalBehaviors.notifica('error', 'tds.elementNotFount__notification'); //Go back

      $tdsGlobalBehaviors.redirectTo(redirectTo);
    }

    comuniFormCtrl.formatEnteTerritorialeText = function (item) {
      return item.tipoEnteTerritoriale + ' ' + item.nome;
    };
  }
})();