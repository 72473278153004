"use strict";

/**
 * Created by GB on 15/10/2019
 */
(function () {
  'use strict';

  angular.module('polcity.documenti').controller('documentoFormController', ['$scope', documentoFormController]);

  function documentoFormController($scope) {
    var me = this;
    me.documento = {
      tipoDocumento: 'patente'
    };
    $scope.$watch(() => me.documento.tipoDocumento, resetDocumentoValues);
    $scope.$watch(() => me.documento, function (newValue, oldValue) {
      if (me.onDocumentoChange) {
        me.onDocumentoChange({
          documento: newValue
        });
      }
    }, true);
    $scope.$watch(() => me.form.$valid, function (newValue) {
      if (me.onValidityChange) {
        me.onValidityChange({
          valid: newValue
        });
      }
    });
    /**
     * Al cambio del tipo di documento si occupa di resettare tutti i campi e di impostare come tipoDocumento,
     * il nuovo tipoDocumento selezionato
     * @param tipoDocumento
     */

    function resetDocumentoValues(tipoDocumento) {
      me.documento = {
        tipoDocumento: tipoDocumento || "patente"
      };
    }
  }
})();