"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Rilevazione', ['restmod', function (restmod) {
    return restmod.model('rilevazione').mix({
      $extend: {
        Scope: {
          $config: {
            baseEntityPath: 'rilevazione/',
            api: {
              rilevazioneCollectionIds: {
                url: 'ricerca/rilevazioni-id',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/rilevazione',
                method: 'POST'
              },
              destroy: {
                url: 'comando/cancella',
                method: 'POST'
              },
              acquisizioneTracciato: {
                url: 'verbalizzazione/fase_1',
                method: 'POST'
              },
              immaginiRilevazione: {
                url: 'bpmn/get-immagini-da-validare/',
                method: 'GET'
              },
              inizializzaProcessoVerbalizzazione: {
                url: 'bpmn/start-process',
                method: 'POST'
              },
              reInizializzaProcessoVerbalizzazione: {
                url: 'bpmn/restart-process',
                method: 'POST'
              },
              accettaValidazione: {
                url: 'bpmn/valida',
                method: 'POST'
              },
              rigettaValidazione: {
                url: 'bpmn/rigetta-validazione',
                method: 'POST'
              },
              verbalizzaValidazione: {
                url: 'verbalizza',
                method: 'POST'
              },
              rigettaValidazioneFinale: {
                url: 'bpmn/rigetta-validazione-finale',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'RicercaVeloce', 'RicercaTestoLibero', 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'OsImmaginiEntita', {
      $extend: {
        Scope: {},
        Resource: {
          $inizializzaProcessoVerbalizzazione: function (_aggIds) {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('inizializzaProcessoVerbalizzazione', false);

              if (!this.$isCollection && this.length) {
                this.$dispatch('before-inizializza-processo-verbalizzazione-wrong-parameters-exception');
                throw 'Wrong parameters exception';
              }

              if (_aggIds) {
                request.data = angular.copy(_aggIds);
              } else {
                request.data = angular.copy(this.$collectionIds);
              }

              this.$dispatch('before-inizializza-processo-verbalizzazione', [request]);
              this.$send(request, function (_response) {
                // this.$unwrap(_response);
                this.$dispatch('after-inizializza-processo-verbalizzazione', [_response]);
              }, function (_response) {
                this.$dispatch('after-inizializza-processo-verbalizzazione-error', [_response]);
              });
            });
          },
          $reInizializzaProcessoVerbalizzazione: function (_aggIds) {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('reInizializzaProcessoVerbalizzazione', false);

              if (!this.$isCollection && this.length) {
                this.$dispatch('before-re-inizializza-processo-verbalizzazione-wrong-parameters-exception');
                throw 'Wrong parameters exception';
              }

              if (_aggIds) {
                request.data = angular.copy(_aggIds);
              } else {
                request.data = angular.copy(this.$collectionIds);
              }

              this.$dispatch('before-re-inizializza-processo-verbalizzazione', [request]);
              this.$send(request, function (_response) {
                // this.$unwrap(_response);
                this.$dispatch('after-re-inizializza-processo-verbalizzazione', [_response]);
              }, function (_response) {
                this.$dispatch('after-re-inizializza-processo-verbalizzazione-error', [_response]);
              });
            });
          }
        },
        Collection: {
          $getCollectionIds: function (_params) {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('rilevazioneCollectionIds');
              request.params = Object.assign({}, {
                milestone: _params.milestone
              }, _params);
              this.$dispatch('before-get-collection-ids', [request]);
              this.$send(request, function (_response) {
                this['$collectionIds'] = _response.data.resultsWrapper.values;
                this.$dispatch('after-get-collection-ids', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-get-collection-ids-error', [_response]);
              });
            });
          }
        },
        Record: {
          $getImmaginiVersamento: function () {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('immaginiRilevazione', false);
              request.url = request.url.concat(this.$pk);
              this.$dispatch('before-get-immagini-rilevazione', [request]);
              this.$send(request, function (_response) {
                this['$immagini'] = _response.data;
                this.$dispatch('after-get-immagini-rilevazione', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-get-immagini-rilevazione-error', [_response]);
              });
            });
          },
          $accettaValidazione: function (_tracciatoLavorabile) {
            return this.$action(function () {
              var request = this.$buildRequest('accettaValidazione', false);
              request.data = Object.assign({}, {
                aggId: this.$pk,
                tracciatoLavorabile: _tracciatoLavorabile
              });
              this.$dispatch('before-accetta-validazione', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-accetta-validazione', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-accetta-validazione-error', [_response]);
              });
            });
          },
          $rigettaValidazione: function (_causale, _tracciatoLavorabile, item) {
            return this.$action(function () {
              var request = this.$buildRequest('rigettaValidazione', false);
              request.data = Object.assign({}, {
                aggId: this.$pk,
                causale: _causale,
                tracciatoLavorabile: _tracciatoLavorabile
              });
              this.$dispatch('before-rigetta-validazione', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response);
                this.$dispatch('after-rigetta-validazione', [_response]);
              }, function (_response) {
                this.$dispatch('after-rigetta-validazione-error', [_response]);
              });
            });
          },
          $verbalizzaValidazione: function (_coreVerbale) {
            return this.$action(function () {
              var request = this.$buildRequest('verbalizzaValidazione');
              request.data = Object.assign({}, _coreVerbale);
              this.$dispatch('before-verbalizza-validazione', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-verbalizza-validazione', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-verbalizza-validazione-error', [_response]);
              });
            });
          },
          $rigettaValidazioneFinale: function (_causale, _tracciatoLavorabile) {
            return this.$action(function () {
              var request = this.$buildRequest('rigettaValidazioneFinale', false);
              request.data = Object.assign({}, {
                aggId: this.$pk,
                causale: _causale,
                tracciatoLavorabile: _tracciatoLavorabile
              });
              this.$dispatch('before-rigetta-validazione-finale', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response);
                this.$dispatch('after-rigetta-validazione-finale', [_response]);
              }, function (_response) {
                this.$dispatch('after-rigetta-validazione-finale-error', [_response]);
              });
            });
          },
          //Override della defaul $destroy
          $destroy: function () {
            return this.$action(function () {
              if (this.$pk) {
                var request = this.$buildRequest('destroy');
                request.data = {
                  aggId: this.$pk
                };
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$unwrap(this.$getResponseData(_response));
                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) {
                  this.$scope.$remove(this);
                }
              }
            });
          },
          $removeFromList: function () {
            return this.$action(function () {
              this.$scope.$remove(this);
            });
          }
        }
      }
    });
  }]);
})();