"use strict";

/**
 * Created by MN 10/07/2020
 * resolve https://jira.opensoftware.it/browse/POLQA-66
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('restrictedTo', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attr, ngModelCtrl) {
        var restriction = attr.restrictedTo,
            lettersRegExp = /[^a-zA-Z\s]*/g,
            numbersRegExp = /[^0-9\s]*/g;

        function fromUser(_input) {
          var transformedInput = '';

          if (!_input) {
            return _input;
          }

          switch (restriction) {
            case 'letters':
              transformedInput = _input.replace(lettersRegExp, '');
              break;

            case 'numbers':
              transformedInput = _input.replace(numbersRegExp, '');
              break;

            default:
              transformedInput = _input;
          }

          if (transformedInput !== _input) {
            ngModelCtrl.$setViewValue(transformedInput);
            ngModelCtrl.$render();
          }

          return transformedInput;
        }

        ngModelCtrl.$parsers.push(fromUser);
      }
    };
  });
})();