"use strict";

/**
 * Created by dan on 10/01/17.
 */
(function () {
  'use strict';

  angular.module('polcity.navigation').config(['$stateProvider', 'navigationRoutesProvider', '$translatePartialLoaderProvider', routesConfig]).run(['$rootScope', handleSectionScope]);

  function routesConfig($stateProvider, navigationRoutesProvider, $translatePartialLoader) {
    $translatePartialLoader.addPart('navigation');
    var routes = R.unnest([navigationRoutesProvider.list()]);
    var redirectUrl = '';
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (routeDefinition.isLogin) {
        redirectUrl = routeDefinition.name;
      }

      $stateProvider.state(stateName, {
        url: routeDefinition.url,
        templateUrl: routeDefinition.templateUrl,
        controller: routeDefinition.controller,
        controllerAs: routeDefinition.controllerAs,
        breadcrumbs: routeDefinition.breadcrumbs ? routeDefinition.breadcrumbs : [],
        abstract: routeDefinition.abstract
      });
    }, routes);
  }

  function handleSectionScope($rootScope) {
    $rootScope.$on('$stateChangeStart', storeFromState);
    $rootScope.$on('$stateChangeSuccess', showStateSectionScope);

    function storeFromState() {
      $rootScope.hasHistory = history.length > 0;
    }

    function showStateSectionScope(ev, toState) {
      $rootScope.sectionScope = toState.sectionScope;
    }
  }
})();