"use strict";

/**
 * Created by macbookpro15tb on 01/09/17.
 * Refactores by GB on:
 * - 15/06/2018
 * - 17/06/2018
 * - 20/06/2018
 */
(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name entityToPdf
   * @restrict E
   *
   * @param {string@} printMode Required, stabilisce la modalità di stampa.
   * @param {string@} dominio: Required, nome del dominio di apparteneneza (Incidente, Verbale, etc..)
   * @param {boolean<} iconMode Optional, stabilisce se il bottone della direttiva debba visualizzare il testo o solo l'icona
   * @param {string@} aggregateCanonicalName Requires, L'aggregateCanonicalName del/degli elemento/i per cui si vuole effettuare la stampa
   * @param {string@} label Required, Il nome della proprietà dell'elemento da visualizzare nella prima colonna della tabella
   * @param {object<} item Optional, L'elemento da stampare
   * @param {string@} aggId Optional, nome della proprietà all'interno di item che identifica l'aggId
   * @param {array<} items Optional, Array di elementi da stampare
   * @param {string@} buttonClass Optiona, Classi css aggiuntive da applicare al bottone
   *
   * @description
   * Tramite la direttiva è possibile stampare qualunque entità o proiezione all'interno dell'applicazione.
   * Sono possibili 3 modalità differenti:
   * * onTheFly: ad esempio per le ricevute. Non viene visualizzata nessuna modale ma la richiesta di stampa viene inoltrata direttamente al server passando l'aggId dell'item
   * * onlyModels: ad esempio per i verbali. Viene visualizzata una modale nella quale è possibile selezionare solamente i modelli di stampa
   * * full: ad esempio per gli incidenti. Viene visualizzata una modale dove per ogni proiezione dell'entità è possibile selezionare i modelli di stampa
   *
   * ATTENZIONE!!!
   * E' necessario che il modulo che utilizza la direttiva implmeneti il Comando @stampa<Dominio> (es. @stampaIncidente) configurando l'url
   * da richiamare e il metodo da utilizzare
   *
   * @usage
   *
   * <entity-to-pdf
   *      class="button-bold"
   *      dominio="Incidente"
   *      print-mode="onlyModels"
   *      icon-mode="true"
   *      aggregate-canonical-name="eu.opensoftware.verbale.ao.Verbale"
   *      label="aggId"
   *      item="item">
   * </entity-to-pdf>
   *
   *
    *
   */

  angular.module('polcity.entityToPdf').component('entityToPdf', {
    templateUrl: 'osFramework/entityToPdf/entityToPdf.directive.html',
    controller: ['api', '$scope', '$translate', '$mdDialog', EntityToPdfController],
    controllerAs: 'vm',
    bindings: {
      dominio: '@',
      items: '<',
      item: '<',
      itemAggIdProperty: '@',
      label: '@',
      aggregateCanonicalName: '@',
      printMode: '@',
      iconMode: '<',
      buttonClass: '@'
    }
  });
  /**
   * Directive controller
   */

  function EntityToPdfController(api, $scope, $translate, $mdDialog) {
    var vm = this;
    /**
     * @property
     * Nome del dominio delle entità che si vuole maneggiare
     */

    vm.dominio;
    /**
     * @property
     * Array di elementi da stampare
     * @type {Array}
     */

    vm.items;
    /**
     * @property
     * Item da stampare
     * @type {null}
     */

    vm.item;
    /**
     * @property
     * Nome della proprietà da visualizzare come label nella prima colonna della tabella
     * @type {null}
     */

    vm.label;
    /**
     * @property
     * AggregateCanonicalName della risorsa che si vuole stampare
     * @type {undefined}
     */

    vm.aggregateCanonicalName;
    /**
     * @property
     * Può avere uno dei seguenti valori: "onTheFly", "printModels", "full"
     * onTheFly: il caso d'uso sono le ricevute di stampa, devo poter cliccare sul bottone di stampa ed inviare direttamente una pu con solo aggId
     * onlyModels: il caso d'uso sono i verbali, devo poter selezionare per ogni verbale solamente i modelli di stampa. L'entity è tutta la proiezione per default
     * full: il caso d'uso sono gli incidenti, devo poter selezionare le singole entity e per ognuna di esse i modelli di stampa
     * @type {undefined}
     */

    vm.printMode;
    /**
     * @property
     * Discrimina se il bottone debba visualizzare solo il testo o anche l'icona
     * @type {boolean}
     */

    vm.iconMode;
    /**
     * @property
     * Classi da applicare al bottone
     * @type {string}
     */

    vm.buttonClass;
    /**
     * @property {local}
     * Gestisce la visualizzazione del bottone GENERAPDF e del LODING gif
     */

    vm.pdfGenerationInProgress;
    /**
     * GB 20/06/2018
     * Handler eseguito alla pressione del bottone "STAMPA"
     */

    vm.stampa = stampa;
    /**
     * nome della proprietà all'interno di item che identifica l'aggId
     * default: aggId
     * @type {string}
     */

    vm.itemAggIdProperty;
    /**
     * Operazioni di inizializzazione del controller
     */

    vm.$onInit = function () {
      vm.itemAggIdProperty = vm.itemAggIdProperty || 'aggId';
      vm.items = vm.items || [];
      vm.iconMode = vm.iconMode || false;
      vm.buttonClass = vm.buttonClass || '';
    };
    /**
     * GB 20/06/2018
     * Handler sul click del bottone STAMPA
     * Chiama la stampa direttamente in caso di modalità "onTheFly" oppure apre la modale
     * @param $event
     */


    function stampa($event) {
      var listItems,
          printMode = vm.printMode;

      if (vm.item) {
        vm.items = [vm.item];
      }

      console.log(vm.items); //Corsia preferenziale per la stampa al volo

      if (printMode === 'onTheFly') {
        var printUnit;
        printUnit = preparePrintUnit(vm.item, printMode); //Call api

        generatePdf(printUnit);
        return;
      }

      listItems = prepareItems(vm.items, vm.label, printMode);
      openDialog($event, listItems);
    }
    /**
     * GB 20/06/2018
     * Il metodo prepara una lista di items da visualizzare nella modale
     * @param items: array di item da stampare
     * @param itemKey: nome della proprietà da visualizzare nella prima colonna della tabella
     * @param printMode: modalità di stampa
     * @returns {Array}
     */


    function prepareItems(items, itemKey) {
      var result = []; // Per ogni item creiamo un oggetto con le proprietà che ci servono e la sua printUnit

      R.map(function (item) {
        var listItem = {
          label: typeof itemKey === 'function' ? itemKey(item) : item[itemKey],
          //etichetta
          entities: [],
          //Array di entity disponibili
          modelliStampa: [],
          //Array di modelli di stampa disponibili
          printUnit: preparePrintUnit(item, vm.printMode) //PrintUnit

        };
        result.push(listItem);
      }, items);
      return result;
    }
    /**
     * GB 20/06/2018
     * Il medoto in base al parametro printMode prepara una printUnit
     * @param item
     * @param printMode
     * @returns {{aggId: *}|*}
     */


    function preparePrintUnit(item, printMode) {
      var result;
      result = {
        aggId: item[vm.itemAggIdProperty]
      };

      if (printMode === 'onlyModels') {
        result.modelliStampaId = item.modelliStampaId || [];
      }

      if (printMode === 'full') {
        result.modelliStampaId = item.modelliStampaId || [];
        result.entity = null;
      }

      return result;
    }
    /**
     * GB 20/06/2018
     * Il metodo apre la modale per gestire le operazioni di stampa
     * @param ev
     * @param items
     */


    function openDialog(ev, items) {
      $mdDialog.show({
        controller: 'entityToPdfDialogController',
        templateUrl: 'osFramework/entityToPdf/entityToPdf.dialog.html',
        controllerAs: 'vm',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: true,
        bindToController: true,
        locals: {
          dominio: vm.dominio,
          items: items,
          printMode: vm.printMode,
          aggregateCanonicalName: vm.aggregateCanonicalName
        }
      });
    }
  }
})();