"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useArticoliNormativeMasterDetail = exports._availableSorters = exports._availableFilters = exports.articoloNormativaMasterDetailName = void 0;

var _recoil = require("recoil");

var _react = require("react");

var _masterDetail = require("../generics/masterDetail");

var _quickSearch = require("../generics/quickSearch");

var _api = require("../../api");

var _freeSearch = require("../generics/freeSearch");

var _articoliNormative = require("./articoliNormative");

const articoloNormativaMasterDetailName = "articoliNormative";
exports.articoloNormativaMasterDetailName = articoloNormativaMasterDetailName;

const _availableFilters = (0, _recoil.atom)({
  key: '_availableFiltersArticoliNormative',
  default: [{
    name: 'ambito',
    label: 'Ambito',
    value: null
  }, {
    name: 'natura',
    label: 'natura',
    value: null
  }, {
    name: 'codiceVeloce',
    label: 'Codice veloce',
    value: null
  }, {
    name: 'sottocaso',
    label: 'Sottocaso',
    value: null
  }, {
    name: 'isAClone',
    label: 'Duplicato',
    value: false
  }]
});

exports._availableFilters = _availableFilters;

const _availableSorters = (0, _recoil.atom)({
  key: '_availableSortersArticoliNormative',
  default: [{
    name: 'codiceLibreria',
    label: 'Codice libreria',
    direction: null
  }, {
    name: 'codiceVeloce',
    label: 'Codice veloce',
    direction: null
  }, {
    name: 'numArticolo',
    label: 'Numero articolo',
    direction: null
  }, {
    name: 'inVigoreDal',
    label: 'In vigore dal',
    direction: null
  }, {
    name: 'inVigoreAl',
    label: 'In vigore al',
    direction: null
  }]
});

exports._availableSorters = _availableSorters;
const useArticoliNormativeQuickSearch = (0, _quickSearch.useQuickSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    order,
    ambito,
    natura,
    codiceVeloce,
    sottocaso,
    isAClone
  }) => (0, _api.ricercaArticoliNormative)({
    page,
    limit,
    order: order ? order : "codiceVeloce,asc",
    ambito: ambito === null || ambito === void 0 ? void 0 : ambito.alias,
    natura: natura === null || natura === void 0 ? void 0 : natura.alias,
    codiceVeloce,
    sottocaso,
    isAClone
  })
});
const useArticoliNormativeFreeSearchReactQuery = (0, _freeSearch.useFreeSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    query
  }) => (0, _api.ricercaInfrazioniTestoLibero)({
    page,
    limit,
    query
  })
});

const useArticoliNormativeMasterDetail = props => {
  const {
    name,
    page: pageProp,
    limit: limitProp,
    query: queryProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  } = props || {};
  const availableSorters = (0, _recoil.useRecoilValue)(_availableSorters);
  const availableFilters = (0, _recoil.useRecoilValue)(_availableFilters);
  const minLengthQuery = 2;
  const freeSearchActive = (0, _react.useCallback)(_query => _query && _query.length >= minLengthQuery, [minLengthQuery]);
  const {
    open,
    selectionActive,
    activeItem,
    setState,
    reset: resetState
  } = (0, _masterDetail.useMasterDetailState)({
    name,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  });
  const {
    page,
    limit,
    items,
    isLoading,
    count,
    activeFilters,
    activeSorters,
    activeSortersCount,
    changePage,
    changeLimit,
    addFilter,
    removeFilter,
    setActiveSorters,
    refresh,
    reset
  } = useArticoliNormativeQuickSearch({
    resourceKeys: _articoliNormative.articoloNormativaKeys,
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp
  });
  const {
    items: fsItems,
    count: fsCount,
    isLoading: fsIsLoading,
    query,
    changeLimit: fsChangeLimit,
    changeQuery,
    refresh: fsRefresh
  } = useArticoliNormativeFreeSearchReactQuery({
    resourceKeys: _articoliNormative.articoloNormativaKeys,
    name,
    page: pageProp,
    limit: limitProp,
    query: queryProp,
    minLength: 2
  });

  const resetMasterDetail = () => {
    reset();
    resetState();
    fsRefresh();
  };

  return {
    page,
    limit,
    isLoading: freeSearchActive(query) ? fsIsLoading : isLoading,
    query,
    items: freeSearchActive(query) ? fsItems : items,
    count: freeSearchActive(query) ? fsCount : count,
    availableFilters,
    activeFilters,
    changePage,
    changeLimit,
    addFilter: filterToAdd => {
      activeFilters.length && removeFilter(filterToAdd.name);
      addFilter(filterToAdd);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    removeFilter: filterToRemove => {
      removeFilter(filterToRemove);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    availableSorters,
    activeSorters,
    activeSortersCount: query ? 0 : activeSortersCount,
    setSorters: setActiveSorters,
    refresh: freeSearchActive(query) ? fsRefresh : refresh,
    changeFreeTextSearch: (value, _reason) => {
      fsChangeLimit(20);
      changeQuery(value);
    },
    minLengthQuery,
    //Master detail props
    open,
    selectionActive,
    activeItem,
    setState,
    resetMasterDetail
  };
};

exports.useArticoliNormativeMasterDetail = useArticoliNormativeMasterDetail;