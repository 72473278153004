"use strict";

(function () {
  'use strict';
  /**
   * Dona la possiblità ad un Model di essere seguito
   */

  angular.module('polcity.models').factory('Pageable', ['restmod', function (restmod) {
    return restmod.mixin({
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);
            collection.$isPageable = true;
            return collection;
          }
        },
        Collection: {
          $changePage: function (page, limit) {
            var search = R.path(['$metadata', 'search'], this);
            search.page = page ? page : 1;
            search.limit = limit ? limit : 10;
            /*if( this.$params && this.$params.page === page && this.$params.limit === limit ) {
                return Promise.resolve();
            }*/
            //Usiamo la stessa collection per mantenere il riferimento

            this.$clear(); //Pulizia dei vecchi dati

            if (this.$isRicercaVeloce) {
              return this.$ricercaVeloce(search);
            } else if (this.$isRicercaTestoLibero && this.$isRicercaTestoLiberoActive) {
              return this.$ricercaTestoLibero(search);
            } else {
              return this.$fetch(search);
            }
          }
        }
      }
    });
  }]);
})();