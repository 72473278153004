"use strict";

angular.module('polcity.services').factory('articoloCdsType', [ArticoloCdsType]);

function ArticoloCdsType() {
  let type, isReadOnly;
  return {
    getType: getType
  };

  function getType(_articolo) {
    if (!_articolo.isAClone && !_articolo.natura) {
      type = 'NUOVO_INSERIMENTO';
      isReadOnly = false;
    } else if (!_articolo.isAClone && _articolo.natura === "LIBRERIA") {
      type = 'LIBRERIA';
      isReadOnly = true;
    } else if (_articolo.isAClone && _articolo.natura === "LIBRERIA") {
      type = 'LIBRERIA_CLONE';
      isReadOnly = false;
    } else if (!_articolo.isAClone && _articolo.natura === "ADDENDUM") {
      type = 'MANUALE';
      isReadOnly = false;
    } else if (_articolo.isAClone && _articolo.natura === "ADDENDUM") {
      type = 'MANUALE_CLONE';
      isReadOnly = false;
    }

    return {
      type,
      isReadOnly
    };
  }
}