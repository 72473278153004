"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.insolutoDettaglioFakeData = exports.insolutiListaStatiFakeData = exports.insolutiRicercaFakeData = void 0;
const insolutiRicercaFakeData = {
  "filters": [{
    "name": "targa",
    "label": "Targa",
    "count": 10,
    "values": [{
      "value": "FR324TA",
      "count": 10
    }, {
      "value": "FR324TA",
      "count": 10
    }]
  }, {
    "name": "targa",
    "label": "Targa",
    "count": 10,
    "values": [{
      "value": "FR324TA",
      "count": 10
    }, {
      "value": "FR324TA",
      "count": 10
    }]
  }],
  "resultsWrapper": {
    "count": 2,
    "values": [{
      "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
      "verbaleAggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
      "verbaleId": "15443/V/2022",
      "stato": "INSOLUTO",
      "importoInsoluto": 343.32,
      "targa": "FA432TA",
      "nomeCognomeTrasgressore": "Mario Sereno",
      "nomeCognomeObbligato": "Giovanni Livolsi"
    }, {
      "aggId": "b58c4074-1e00-4ffd-afe8-da3177c12346",
      "verbaleAggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
      "verbaleId": "15443/V/2022",
      "stato": "INSOLUTO",
      "importoInsoluto": 100.32,
      "targa": "FA432TA",
      "nomeCognomeTrasgressore": "Mario Sereno",
      "nomeCognomeObbligato": "Giovanni Livolsi"
    }]
  },
  "search": {
    "semestreAnno": "1/2022",
    "importoInsolutoDa": 100,
    "importoInsolutoA": 200,
    "nomeCognomeObbligato": "Francesco Finizio",
    "targa": "FA344YY",
    "stato": "INSOLUTO",
    "attiva": true,
    "excludedDb": true,
    "explodeId": true,
    "limit": 10,
    "order": "importoInsoluto,asc:dataViolazione,asc",
    "page": 1
  }
};
exports.insolutiRicercaFakeData = insolutiRicercaFakeData;
const insolutiListaStatiFakeData = [{
  alias: "INSOLUTO",
  label: "INSOLUTO"
}, {
  alias: "TOLLERATO",
  label: "TOLLERATO"
}, {
  alias: "SCARTATO",
  label: "SCARTATO"
}];
exports.insolutiListaStatiFakeData = insolutiListaStatiFakeData;
const insolutoDettaglioFakeData = {
  "aggId": "b58c4074-1e00-4ffd-afe8-da3177c12346",
  "numeroVerbale": "15443/V/2022",
  "numeroRegistro": "12/2022",
  "stato": "insoluto",
  "dataViolazione": 1634076500000,
  "dataDecorrenzaTermini": 1634076500000,
  "dataElaborazione": 1634076500000,
  "dataProssimaElaborazione": 1634076500000,
  "luogoElevazione": "Via Antonio Zichicchi, Montagnana (PD)",
  "soggettoArruolabile": "TRASGRESSORE",
  "missingSoggettoArruolabile": true,
  "missingCf": true,
  "missingPiva": true,
  "accertatori": [{
    "identificativo": "1343234 MM - V.Brig. Pierluigi Collina"
  }, {
    "identificativo": "1343234 MM - V.Brig. Pierluigi Collina"
  }],
  "veicoloSanzionato": "Autoveicolo IT-Italia FA234TA Fiat Panda.",
  "soggetti": [{
    "anagraficaAggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
    "nominativoSoggetto": "Mario Rossi",
    "posizione": "T",
    "dataInoltroLotto": 1634076500000,
    "dataSpedizioneLotto": 1634076500000,
    "dataNotifica": 1634076500000,
    "esitoNotifica": "consegnato",
    "lottoAggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
    "lottoProtocollo": "1/2022"
  }, {
    "anagraficaAggId": "b58c4074-1e00-4ffd-afe8-da3177ce9888",
    "nominativoSoggetto": "Mario Rossi",
    "posizione": "T",
    "dataInoltroLotto": 1634076500000,
    "dataSpedizioneLotto": 1634076500000,
    "dataNotifica": 1634076500000,
    "esitoNotifica": "consegnato",
    "lottoAggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
    "lottoProtocollo": "1/2022"
  }]
};
exports.insolutoDettaglioFakeData = insolutoDettaglioFakeData;