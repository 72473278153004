"use strict";

/**
 * Created by MN on 13/02/2020
 */
(function () {
  'use strict';

  angular.module('polcity.services').factory('dashboardDataService', ['Command', DashboardData]);

  function DashboardData(Command) {
    return {
      // WIDGETS
      fetchVerbaliSeguiti: fetchVerbaliSeguiti,
      fetchStatiVerbali: fetchStatiVerbali,
      fetchTipiAccertamento: fetchTipiAccertamento,
      fetchLotti: fetchLotti,
      fetchTipiRilevatori: fetchTipiRilevatori,
      fetchMilestoneRilevazioni: fetchMilestoneRilevazioni,
      // CALENDAR
      fetchCalendarUserEvents: fetchCalendarUserEvents,
      createCalendarUserEvent: createCalendarUserEvent,
      editCalendarUserEvent: editCalendarUserEvent,
      deleteCalendarUserEvent: deleteCalendarUserEvent,
      // fetchCalendarApplicationEvents: fetchCalendarApplicationEvents,
      parseEvents: parseEvents,
      parseApplicationEvents: parseApplicationEvents
    };
    /**
     * Widgets
     */

    function fetchVerbaliSeguiti() {
      return Command.execute('@dashboardRicercaVerbaliSeguiti');
    }

    function fetchStatiVerbali() {
      return Command.execute('@dashboardRicercaStatiVerbali');
    }

    function fetchTipiAccertamento() {
      return Command.execute('@dashboardRicercaTipiAccertamento');
    }

    function fetchLotti() {
      return Command.execute('@dashboardRicercaLotti');
    }

    function fetchTipiRilevatori() {
      return Command.execute('@dashboardRicercaTipiRilevatore');
    }

    function fetchMilestoneRilevazioni() {
      return Command.execute('@dashboardRicercaMilestoneRilevazioni');
    }
    /**
     * Calendar
     */


    function fetchCalendarUserEvents() {
      return Command.execute('@dashboardRicercaUserEvents');
    }

    function createCalendarUserEvent(_event) {
      return Command.execute('@dashboardCreaUserEvent', _event);
    }

    function editCalendarUserEvent(_event) {
      return Command.execute('@dashboardModificaUserEvent', _event);
    }

    function deleteCalendarUserEvent(_eventAggId) {
      return Command.execute('@dashboardEliminaUserEvent', {
        aggId: _eventAggId
      });
    } // function fetchCalendarApplicationEvents() {
    //     return Command.execute('@ricercaEventiApplicazione');
    // }


    function parseEvents(events) {
      //A causa di una gestione differente del flag editable in FC5
      return events.map(event => event.editable ? (event.canEdit = true, event) : event);
    }

    function parseApplicationEvents(events) {
      return events.map(event => Object.assign(event, {
        type: 'applicationEvent',
        editable: false,
        eventStartEditable: false,
        eventDurationEditable: false,
        allDay: true,
        title: event.idVerbale,
        start: event.dataOraScadenzaVerbale,
        end: event.dataOraScadenzaVerbale
      }, getEventStatusTitleColor(event)));
    }

    function getEventStatusTitleColor(event) {
      if (moment(event.dataOraScadenzaVerbale).isBefore(moment().startOf('day'))) {
        //eventi scaduti
        return {
          status: 'appEventScaduto',
          color: 'red',
          title: `Verbale ${event.idVerbale} scaduto`
        };
      } else if (moment(event.dataOraScadenzaVerbale).isBetween(moment().startOf('day'), moment().startOf('day').add(10, 'days'))) {
        // appEvent in scadenza
        return {
          status: 'appEventInScadenza',
          color: 'orange',
          title: `Verbale ${event.idVerbale} in scadenza`
        };
      } else if (moment(event.dataOraScadenzaVerbale).isSameOrAfter(moment().startOf('day').add(10, 'days'))) {
        // appEvent da spedire
        return {
          status: 'appEventOk',
          color: 'green',
          title: `Verbale ${event.idVerbale} nei termini`
        };
      }
    }
  }
})();