"use strict";

/**
 * Created by MN 25/01/2019
 */
(function () {
  'use strict';

  angular.module('polcity.acquisizioneDati').config(['$stateProvider', 'acquisizioneDatiRoutesProvider', 'AuthProvider', addRoutes]).run(['acquisizioneDatiRoutes', routesRun]);

  function addRoutes($stateProvider, acquisizioneDatiRoutesProvider, AuthProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (!routeDefinition.role && !routeDefinition.roles) {
        $stateProvider.state(stateName, routeDefinition);
      }

      if (routeDefinition.role && AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }

      if (routeDefinition.roles && routeDefinition.roles.length) {
        for (var r of routeDefinition.roles) {
          if (!AuthProvider.hasRole(r)) {
            return;
          }
        }

        $stateProvider.state(stateName, routeDefinition);
      }
    }, acquisizioneDatiRoutesProvider.list());
  }

  function routesRun(acquisizioneDatiRoutes) {
    acquisizioneDatiRoutes.subscribeUIEvents();
  }
})();