"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JsonInspectorStyles = void 0;

var _react = _interopRequireDefault(require("react"));

var _styles = require("@material-ui/core/styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const JsonInspectorStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    height: '100%',
    '& .json-inspector__toolbar': {},
    '& .json-inspector__search': {
      width: '100%',
      margin: '0 10px 10px 0',
      padding: theme.spacing(1)
    },
    '& .json-inspector__selection': {
      font: '14px/1.4 Consolas, monospace'
    },
    '& .json-inspector__leaf': {
      paddingLeft: theme.spacing(4)
    },
    '& .json-inspector__line': {
      display: 'block',
      position: 'relative',
      cursor: 'default',
      marginTop: theme.spacing(2)
    },
    '& .json-inspector__line:hover:after': {
      background: theme.palette.grey[900]
    },
    '& .json-inspector__flatpath, .json-inspector__radio': {
      display: 'none'
    },
    '& .json-inspector__leaf_composite > .json-inspector__line': {
      cursor: 'pointer'
    },
    '& .json-inspector__value': {
      marginLeft: theme.spacing(4),
      fontWeight: 'bolder'
    },
    '& .json-inspector__value_helper, .json-inspector__value_null, .json-inspector__not-found': {
      color: theme.palette.grey[400]
    },
    '& .json-inspector__value_string, .json-inspector__value_boolean, .json-inspector__value_number': {
      color: "#2196f3"
    }
    /*
    '& .json-inspector__line:after': {
        content: '',
        position: 'absolute',
        top: 0,
        left: '-200px',
        right: '-50px',
        bottom: 0,
        zIndex: '-1',
        pointerEvents: 'none'
    },
    '& .json-inspector__value_boolean': {
        color: '#75b5aa'
    },
    '& .json-inspector__value_number': {
        color: '#d28445'
    },
    '& .json-inspector__hl': {
        background: '#ff0',
        boxShadow: '0 -1px 0 2px #ff0',
        borderRadius: '2px'
    },
    '& .json-inspector__show-original': {
        display: 'inline-block',
        padding: '0 6px',
        color: '#666',
        cursor: 'pointer'
    },
    '& .json-inspector__show-original:hover': {
        color: '#111'
    },
    '& .json-inspector__show-original:before': {
        content: '⥂'
    },
    '& .json-inspector__show-original:hover:after': {
        content: ' expand'
    }*/

  }
}));
exports.JsonInspectorStyles = JsonInspectorStyles;