"use strict";

/**
 * Created by MN on 20/07/2020
 */
(function () {
  'use strict';

  angular.module('polcity.services').factory('visuraResponseService', [VisuraResponseService]);

  function VisuraResponseService() {
    var responseVisura = {},
        datiVisura = {},
        normalizedData = {};
    return {
      formatResponse: formatResponse
    };

    function formatResponse(_response, _section) {
      // se non ho la _response non posso procedere
      if (!_response) {
        console.error(`Error, can't format the response because the response is missing`);
        return;
      }

      normalizedData = {};
      responseVisura = Object.assign({}, _response);
      datiVisura = responseVisura.dati;

      if (responseHasErrors()) {
        return normalizedData;
      } else {
        filterOperationType();
        return _section ? normalizedData[_section] : normalizedData;
      }
    }
    /**
     * Utils
     */


    function responseHasErrors() {
      var hasErrors = false;

      if (!datiVisura && responseVisura.comunicazione === 'KO') {
        hasErrors = true;
        normalizedData.errore = responseVisura.erroreComunicazione;
      } else if (datiVisura && datiVisura.errore && datiVisura.errore.length) {
        hasErrors = true;
        normalizedData.errore = formatVisuraErrors();
      }

      return hasErrors;
    }
    /**
     * Methods
     */


    function formatVisuraErrors() {
      var result = [];

      for (var i = 0; i < datiVisura.errore[0].codiceErrore.length; i++) {
        var obj = {};
        obj.key = datiVisura.errore[0].codiceErrore[i];
        obj.value = datiVisura.errore[0].descrizioneErrore[i];
        result.push(obj);
      }

      return result;
    }

    function filterOperationType() {
      switch (responseVisura.tipoOperazione) {
        case 'VISURA_TARGA':
          datiVisura = R.path(['dettaglioAutoveicoloComproprietariTrasferimentiResOutput', 'datiAutoveicoloComproprietariTrasferimentiRes'], datiVisura);
          normalizeVisuraTargaData();
          break;

        case 'VISURA_PATENTE':
          datiVisura = R.path(['dettaglioPatenteBaseOutput'], datiVisura);
          normalizeVisuraPatenteData();
          break;

        case 'VISURA_COPERTURA_ASSICURATIVA_SCADENZA_REVISIONE':
          datiVisura = R.path(['verificaCoperturaAssicurativaScadenzaRevisioneOutput'], datiVisura);
          normalizeVisuraCopAssScadRevData();
          break;

        case 'VISURA_PROPRIETARIO':
          datiVisura = R.path(['dettaglioPersonaFisicaVeicoliOutput'], datiVisura);
          normalizeVisuraProprietarioData();
          break;

        case 'VISURA_CARTA_CIRCOLAZIONE':
          normalizeVisuraCartaCircolazioneData();
          break;

        case 'VISURA_INFRAZIONI_PATENTE':
          datiVisura = R.path(['listaInfrazioniPatenteItalianaOutput'], datiVisura);
          normalizeVisuraInfrazioniPatenteData();
          break;

        case 'VISURA_DETTAGLIO_PATENTE':
          datiVisura = R.path(['dettaglioPatenteAdattamentiEOstativiOutput'], datiVisura);
          normalizeVisuraPatenteData();
          break;

        case 'VISURA_OMOLOGAZIONE_VEICOLO':
          datiVisura = R.path(['dettaglioOmologazioneBaseOutput'], datiVisura);
          normalizeVisuraOmologazioneVeicoloData();
          break;

        default:
      }
    }

    function normalizeVisuraTargaData() {
      normalizedData.datiProprietario = formatDatiProprietario(datiVisura.datiProprietario);
      normalizedData.datiAbilitazioniConseguite = formatData(datiVisura.datiAbilitazioniConseguite);
      normalizedData.datiVeicolo = formatData(datiVisura.datiVeicolo);
      normalizedData.datiUltimaRevisione = formatData(datiVisura.datiUltimaRevisione);
      normalizedData.datiCartaCircolazione = formatData(datiVisura.datiCartaCircolazione);
      normalizedData.datiIscrizioneRegistroStorico = formatData(datiVisura.datiIscrizioneRegistroStorico);
      normalizedData.datiIntegrazionePneumatici = formatData(datiVisura.datiIntegrazionePneumatici);
      normalizedData.datiGancioTraino = formatData(datiVisura.datiGancioTraino);
      normalizedData.datiImpiantoGPL = formatData(datiVisura.datiImpiantoGPL);
      normalizedData.datiImpiantoMetano = formatData(datiVisura.datiImpiantoMetano);
      normalizedData.datiImpiantoFAP = formatData(datiVisura.datiImpiantoFAP);
      normalizedData.datiAggiuntiviTecnici = formatData(datiVisura.datiAggiuntiviTecnici);
      normalizedData.listaMessaggi = datiVisura.listaMessaggi.value.messaggio;
      normalizedData.noteVeicolo = datiVisura.noteVeicolo;
      normalizedData.listaComproprietariLocatari = datiVisura.listaComproprietariLocatari;
      normalizedData.listaTrasferimentoResidenza = datiVisura.listaTrasferimentoResidenza;
      normalizedData.listaCronologiaIntestazioneProvvisoria = datiVisura.listaCronologiaIntestazioneProvvisoria;
      normalizedData.listaCronologiaDatiAnagrafici = datiVisura.listaCronologiaDatiAnagrafici;
    }

    function normalizeVisuraPatenteData() {
      var data = {
        personaFisica: Object.assign({}, {
          anagrafica: datiVisura.anagrafica,
          residenza: datiVisura.datiResidenza
        })
      };
      normalizedData.datiProprietario = formatDatiProprietario(data);
      normalizedData.datiPatente = formatDatiPatente(datiVisura.datiPatente);
      normalizedData.cronologiaPatenti = datiVisura.listaCronologiaPatenti ? formatDatiCronologiaPatenti(datiVisura.listaCronologiaPatenti.value.patentePrecedente) : null;
    }

    function normalizeVisuraCopAssScadRevData() {
      normalizedData = formatData(datiVisura);
    }

    function normalizeVisuraProprietarioData() {
      var data = {
        personaFisica: Object.assign({}, {
          anagrafica: datiVisura.anagrafica,
          residenza: datiVisura.datiResidenza
        })
      };
      normalizedData.datiProprietario = formatDatiProprietarioPersonaFisica(data);
      normalizedData.listaVeicoliPosseduti = formatDatiListaVeicoliPosseduti(datiVisura.listaVeicoliPosseduti);
      normalizedData.listaAutorizzazioni = datiVisura.listaAutorizzazioni;
      normalizedData.listaCronologiaIntestazioneProvvisoria = datiVisura.listaCronologiaIntestazioneProvvisoria;
    }

    function normalizeVisuraCartaCircolazioneData() {
      normalizedData.datiProprietario = formatDatiProprietario(datiVisura.datiProprietario);
      normalizedData.datiBaseVeicolo = formatData(datiVisura.datiBaseVeicolo);
      normalizedData.datiAmministrativiVeicolo = datiVisura.datiAmministrativiVeicolo;
      normalizedData.datiLicenzaVeicolo = formatData(datiVisura.datiLicenzaVeicolo);
      normalizedData.datiUltimaRevisione = formatData(datiVisura.datiUltimaRevisione);
      normalizedData.datiTecniciVeicolo = formatData(datiVisura.datiTecniciVeicolo);
      normalizedData.datiImpiantoGPL = formatData(datiVisura.datiImpiantoGPL);
      normalizedData.datiCambioSerbatoio = datiVisura.datiCambioSerbatoio.capacitaNuovoSerbatoioInLitri;
      normalizedData.datiImpiantoMetano = formatData(datiVisura.datiImpiantoMetano);
      normalizedData.datiAutobus = formatData(datiVisura.datiAutobus);
      normalizedData.datiGancioTraino = formatData(datiVisura.datiGancioTraino);
      normalizedData.datiCarrelloAppendice = formatData(datiVisura.datiCarrelloAppendice);
      normalizedData.datiFiltroAntiparticolato = formatData(datiVisura.datiFiltroAntiparticolato);
      normalizedData.datiIntegrazionePneumatici = datiVisura.datiIntegrazionePneumatici;
      normalizedData.righeDescrittive = datiVisura.righeDescrittive;
      normalizedData.listaComproprietariLocatari = formatListaComproprietariLocatari(datiVisura.listaComproprietariLocatari.comproprietariLocatari);
    }

    function normalizeVisuraInfrazioniPatenteData() {
      normalizedData.listaInfrazioni = formatData(datiVisura.listaInfrazioni.value.infrazione);
    }

    function normalizeVisuraOmologazioneVeicoloData() {
      normalizedData.datiIdentificativiOmologazione = formatData(datiVisura.datiIdentificativiOmologazione);
      normalizedData.datiIdentificativiVeicoloOmologazione = formatData(datiVisura.datiIdentificativiVeicoloOmologazione);
      normalizedData.caratteristicheTecnicheOmologazione = formatData(datiVisura.caratteristicheTecnicheOmologazione);
      normalizedData.massaDimensioniOmologazione = formatData(datiVisura.massaDimensioniOmologazione);
      normalizedData.datiInquinamentoOmologazione = formatData(datiVisura.datiInquinamentoOmologazione);
      normalizedData.righeDescrittiveOmologazione = datiVisura.righeDescrittiveOmologazione;
    }

    function formatDatiProprietario(_data) {
      if (_data.personaFisica) {
        return formatDatiProprietarioPersonaFisica(_data);
      } else {
        return formatDatiProprietarioFiguraGiuridica(_data);
      }
    }

    function formatData(_data) {
      if (!_data) {
        return {};
      }

      for (var [key, value] of Object.entries(_data)) {
        if (!value) {
          _data[key] = '--';
        }

        if (key === 'dataprimaAbilitazione' || key === 'dataInizioProprieta' || key === 'dataUltimaRevisione' || key === 'dataEmissione' || key === 'dataEmissione' || key === 'dataRichiesta' || key === 'dataInstallazioneGancioTraino' || key === 'dataCollaudoImpiantoGPL' || key === 'dataScadenzaAssicurazione' || key === 'dataDecorrenzaAssicurazione' || key === 'dataSospensioneAssicurazione' || key === 'dataInizioProprieta' || key === 'dataEmissioneCartaCircolazione' || key === 'dataInstallazioneImpiantoMetano' || key === 'dataCollaudoImpiantoMetano' || key === 'dataDisinstallazioneImpiantoMetano' || key === 'dataOmologazione' || key === 'dataOmologazioneEuropea') {
          _data[key] = moment(_data[key]).format('LL');
        }
      }

      return _data;
    }

    function formatDatiProprietarioPersonaFisica(_data) {
      var anagrafica = _data.personaFisica.anagrafica,
          residenza = _data.personaFisica.residenza;

      for (var [key, value] of Object.entries(anagrafica)) {
        if (!value) {
          _data[key] = '--';
        }

        if (key === 'dataNascita') {
          anagrafica.dataNascita = moment(value).format('LL');
        }

        if (key === 'luogoNascita') {
          if (value.luogoItaliano) {
            anagrafica.luogoNascita = value.luogoItaliano.siglaProvincia + ' - ' + value.luogoItaliano.descrizioneComune;
          } else {
            anagrafica.luogoNascita = value.luogoEstero.codiceInternazionaleEstero + ' - ' + value.luogoEstero.localitaEstera;
          }
        }
      }

      for (var [keyR, valueR] of Object.entries(residenza)) {
        if (!valueR) {
          _data[keyR] = '--';
        }
      }

      return R.mergeWith(R.concat, anagrafica, residenza);
    }

    function formatDatiProprietarioFiguraGiuridica(_data) {
      var datiFiguraGiuridica = _data.personaGiuridica;

      for (var [key, value] of Object.entries(datiFiguraGiuridica)) {
        if (!value) {
          _data[key] = '--';
        }

        if (key === 'sedeItaliana') {
          datiFiguraGiuridica.sede = value.descrizioneComune + ' - ' + value.siglaProvincia;
          delete datiFiguraGiuridica[key];
        }
      }

      return datiFiguraGiuridica;
    }

    function formatDatiPatente(_data) {
      var datiPatente = _data;

      for (var [key, value] of Object.entries(_data)) {
        if (!value) {
          _data[key] = '--';
        }

        if (key === 'saldoPuntiPatente' || key === 'motivoRichiesta' || key === 'dataEmissione' || key === 'dataScadenza' || key === 'infoAbilitazioneMoto' || key === 'indicazioniTagliandoCEE' || key === 'codAntifalsificazioneRinnovoValidita' || key === 'codAntifalsificazioneTrasferimentoResidenza') {
          if (key === 'dataEmissione' || key === 'dataScadenza') {
            value = moment(_data[key]).format('LL');
          }

          datiPatente.patenteAttuale[key] = value;
          delete datiPatente[key];
        }

        if (key === 'listaAbilitazioniPatenteAttuale') {
          datiPatente[key] = _data.listaAbilitazioniPatenteAttuale.value.abilitazionePatente;
        }

        if (key === 'dateScadenzaCqc') {
          datiPatente[key] = _data.dateScadenzaCqc.value;
        }

        if (key === 'listaPrescrizioniPatenteAttuale') {
          datiPatente[key] = _data.listaPrescrizioniPatenteAttuale.value.prescrizione;
        }

        if (key === 'listaMessaggi') {
          datiPatente[key] = _data.listaMessaggi.value.messaggio;
        }
      }

      return datiPatente;
    }

    function formatDatiCronologiaPatenti(_data) {
      var datiPatentePrecedente = _data,
          result = [];

      for (var patPrec of datiPatentePrecedente) {
        var obj = {
          datiPrincipali: {}
        };

        for (var [key, value] of Object.entries(patPrec)) {
          if (!value && key !== 'pdfAnteprimaPatentePrecedente') {
            obj.datiPrincipali[key] = '--';
          }

          if (key === 'numeroPatentePrecedente') {
            obj.datiPrincipali['numeroPatente'] = value.numeroPatente;
          }

          if (key === 'dataEmissione' || key === 'dataScadenza' || key === 'dataRilascio' || key === 'cambioAutomatico' || key === 'abilitazionePosseduta' || key === 'abilitazioneEdataAbilitazione' || key === 'motivo' || key === 'trasportoProfessionale') {
            if (key === 'dataEmissione' || key === 'dataScadenza' || key === 'dataRilascio') {
              obj.datiPrincipali[key] = moment(patPrec[key]).format('LL');
            } else {
              obj.datiPrincipali[key] = value;
            }
          }

          if (key === 'listaAbilitazioniPatentePrecedente') {
            obj[key] = patPrec.listaAbilitazioniPatentePrecedente.value.abilitazione;
          }

          if (key === 'listaPrescrizioniPatentePrecedente') {
            obj[key] = patPrec.listaPrescrizioniPatentePrecedente.value.prescrizione;
          }
        }

        result.push(obj);
      }

      return result;
    }

    function formatDatiListaVeicoliPosseduti(_data) {
      var veicoliPosseduti = _data;

      for (var [key, value] of Object.entries(_data)) {
        if (key === 'elencoAutoveicoli' || key === 'elencoMotoveicoli' || key === 'elencoRimorchi' || key === 'elencoMacchineAgricole' || key === 'elencoCiclomotoriImmatricolati' || key === 'elencoCiclomotoriContrassegno' || key === 'elencoMacchineOperatrici') {
          veicoliPosseduti[key] = value.length ? value[0].veicoloPosseduto : [];
        }
      }

      return veicoliPosseduti;
    }

    function formatListaComproprietariLocatari(_data) {
      var listaComproprietariLocatari = [];

      if (!_data.length) {
        return [];
      }

      for (var i = 0; i < _data.length; i++) {
        var obj = {};
        obj['rapporto'] = _data[i].rapporto;
        obj['datiProprietario'] = formatDatiProprietario(_data[i].comproprietariLocatari);
        listaComproprietariLocatari.push(obj);
      }

      return listaComproprietariLocatari;
    }
  }
})();