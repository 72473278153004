"use strict";

/**
 * Created by gb on 15/09/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.ricorso.esito').component('ricorsoEsito', {
    templateUrl: 'ricorso/esito/ricorso.esito.component.html',
    controller: 'RicorsoEsitoController',
    controllerAs: 'RicorsoEsitoCtrl',
    bindings: {
      ricorso: '<'
    }
  });
})();