"use strict";

(function () {
  'use strict';

  angular.module('osCollectionCarousel').component('osCollectionCarousel', {
    templateUrl: 'osFramework/osCollectionCarousel/osCollectionCarousel.component.html',
    controller: 'osCollectionCarouselController',
    controllerAs: 'osCollectionCarouselCtrl',
    bindings: {
      collection: '<',
      activeIndex: '<?',
      onActiveItemChange: '&?',
      onActiveIndexChange: '&?'
    }
  });
})();