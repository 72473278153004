"use strict";

/**
 * Created by MN 14/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').service('$tdsGlobalBehaviors', ['$state', 'OSNotification', 'modalPresenter', 'EnumService', TdsGlobalBehaviors]);

  function TdsGlobalBehaviors($state, OSNotification, modalPresenter, EnumService) {
    return {
      notifica: notifica,
      askConfirmation: askConfirmation,
      redirectTo: redirectTo,
      getDefaultConf: getDefaultConf,
      getActionColumnDefaultConfig: getActionColumnDefaultConfig,
      getBulkActionsDefaultConfig: getBulkActionsDefaultConfig,
      updateQueryParams: updateQueryParams,
      formatDate: formatDate,
      resolveEnum: resolveEnum,
      filterEnum: filterEnum
    };

    function resolveEnum(_enumType) {
      return EnumService.getEnum(_enumType);
    }

    function filterEnum(_obj, _key) {
      var result = _obj.filter(t => t.alias === _key);

      return result.length ? result[0].label : '-';
    }

    function redirectTo(route, prms) {
      var params = prms || {};
      $state.go(route, params);
    }

    function notifica(type, msg) {
      OSNotification[type]({
        translationIndex: msg
      });
    }

    function askConfirmation(type, titolo, testo, bottoneConferma, bottoneAnnulla) {
      return modalPresenter[type]([titolo, testo, bottoneConferma, bottoneAnnulla]);
    }

    function getDefaultConf(newEntityRoute, entity) {
      var config = {};
      /**
       * @config
       * Riferimento alla rotta per inserire un nuovo elemento
       */

      config.newEntityRoute = newEntityRoute;
      /**
       * @config
       * Nome dell'entità che si sta inserendo
       */

      config.entity = entity;
      /**
       * @config
       * Con questo parametro viene mostrato l'header della tabella
       */

      config.showHeader = true;
      /**
       * @config
       * Con questo parametro vengono disabilitate tutte le
       * interazioni con la tabella
       */

      config.readOnly = false;
      /**
       * @config
       * Abilita la selezione delle righe della tabella
       */

      config.mdRowSelect = true;
      /**
       * @config
       * Abilita la selezione multipla delle righe della tabella
       */

      config.multiple = true;
      /**
       * @config
       * Abilita la ricerca
       */

      config.enableSearch = true;
      /**
       * @config
       * Abilita le azioni sulle righe della tabella
       */

      config.enableActions = true;
      /**
       * @config
       * Abilita il pulsante per aggiungere un nuovo elemento
       */

      config.enableInsert = true;
      /**
       * @config
       * Abilita le operazioni massive sulla tabella
       */

      config.enableBulkActions = true;
      /**
       * @property
       * Lista degli elementi da visualizzare
       * @type {Array}
       */

      config.elementi = [];
      /**
       * @property
       * Promise che viene fornita alla tabella per la gestione del loading indicator
       */

      config.progress = false;
      /**
       * @property
       * Query params
       * @type {{limit: number, page: number, order: string}}
       */

      config.query = {
        limit: 20,
        page: 1
      };
      /**
       * @property
       * Numero totale di elementi
       * @type {number}
       */

      config.totalEl = 0;
      /********************
       * Utils functions
       ********************/

      /**
       * L'handler viene eseguito nel contesto (scope) del controller quindi this è il controller stesso
       * @param elementi
       */

      config.setElementi = function (result) {
        this.elementi = result.resultsWrapper.values;
        this.query.page = result.search.page;
        this.query.limit = result.search.limit;
        this.totalEl = result.resultsWrapper.count;
      };

      return config;
    }

    function getActionColumnDefaultConfig(editRole, deleteRole, editCallback, deleteCallback) {
      var config;
      config = [{
        type: 'edit',
        icon: {
          iconName: 'edit'
        },
        tooltip: 'Modifica',
        role: editRole,
        callback: editCallback
      }, {
        type: 'delete',
        icon: {
          iconName: 'delete'
        },
        tooltip: 'Elimina',
        role: deleteRole,
        callback: deleteCallback
      }];
      return config;
    }

    function getBulkActionsDefaultConfig(deleteRole, deleteCallback) {
      var config;
      config = [{
        type: 'delete',
        icon: {
          iconName: 'delete'
        },
        tooltip: 'Elimina elementi selezionati',
        role: deleteRole,
        callback: deleteCallback
      }];
      return config;
    }

    function updateQueryParams(ctrlQuery, newParam) {
      var params = {};
      params = R.merge(ctrlQuery, newParam);
      return params;
    }

    function formatDate(date) {
      if (!date) {
        return '--';
      } else {
        return moment(date).format('LL');
      }
    }
  }
})();