"use strict";

/**
 * Created by MN 31/07/2019
 */
(function () {
  'use strict';

  angular.module('polcity.acquisizioneDati').component('uploadVersamento', {
    templateUrl: 'acquisizioneDati/versamenti/uploadVersamento/uploadVersamento.component.html',
    controller: ['$scope', '$rootScope', 'Command', 'OSNotification', 'rolesGuardService', UploadVersamentoController]
  });

  function UploadVersamentoController($scope, $rootScope, Command, OSNotification, rolesGuardService) {
    var me = this,
        uploadRoles = ['ROLE_ACUISIZIONE_BOLLETTINI_REPORT', 'ROLE_ACQUISIZIONE_FLUSSI_BANCOPOSTA_TXT'];
    $scope.tracciatoToUpload = {};
    $scope.tipoTracciatoDaAcquisire;

    me.$onInit = function () {};
    /**
     * Apis
     * @returns {*}
     */


    function uploadBancopostaTxt(formdata) {
      return Command.executeRaw('@acquisisciBancopostaTxt', formdata, {
        'Content-Type': undefined
      });
    }

    function uploadBollettinoReport(formdata) {
      return Command.executeRaw('@acquisisciBollettinoReport', formdata, {
        'Content-Type': undefined
      });
    }

    function uploadBollettinoBancoposteSepa(formdata) {
      return Command.executeRaw('@acquisisciBollettinoBancoposteSepa', formdata, {
        'Content-Type': undefined
      });
    }
    /*******************************
     * upload file configuration
     *******************************/


    $scope.checkIfCanUploadFile = function () {
      var result = uploadRoles;

      if (uploadRoles.filter(role => rolesGuardService.userHasRole(role)).length === 1) {
        result = uploadRoles.filter(role => rolesGuardService.userHasRole(role))[0];
      }

      return result;
    };

    $scope.onUploadTracciatoVersamento = function (tracciatoVersamento, file) {
      var fd = new FormData(),
          fileToUpload = file[0].lfFile,
          tracciatoToUpload = JSON.stringify(tracciatoVersamento);
      fd.append('flusso', fileToUpload);
      fd.append('json', tracciatoToUpload);

      if ($scope.tipoTracciatoDaAcquisire === 'bollettino_txt') {
        uploadBancopostaTxt(fd).then(afterUpload).catch(function (err) {
          if (err) {
            notifyUser('warn', 'acquisizioneDati.versamenti.uploadTracciatoError__notification');
          }
        });
      } else if ($scope.tipoTracciatoDaAcquisire === 'bollettino_report') {
        uploadBollettinoReport(fd).then(afterUpload).catch(function (err) {
          if (err) {
            notifyUser('warn', 'acquisizioneDati.versamenti.uploadTracciatoError__notification');
          }
        });
      } else if ($scope.tipoTracciatoDaAcquisire === 'bancoposte_sepa') {
        uploadBollettinoBancoposteSepa(fd).then(afterUpload).catch(function (err) {
          if (err) {
            notifyUser('warn', 'acquisizioneDati.versamenti.uploadTracciatoError__notification');
          }
        });
      }
    };

    function afterUpload() {
      notifyUser('success', 'acquisizioneDati.versamenti.uploadTracciatoSuccess__notification');
      $scope.tracciatoToUpload = {};
      $scope.osAllegatoFormController.file = [];
      $scope.uploadTracciatoForm.$setUntouched(); // $state.go('navigation.dashboard');

      $rootScope.$broadcast('upload-new-versamenti-file');
    }
    /**
     * Utils
     */


    function notifyUser(type, msg) {
      OSNotification[type]({
        translationIndex: msg
      });
    }
  }
})();