"use strict";

var _PresentazioneDocumentiMasterDetail = _interopRequireDefault(require("./masterDetail/PresentazioneDocumentiMasterDetail"));

var _VerbalizzazionePresentazioneDocumentiRoot = _interopRequireDefault(require("./masterDetail/detail/verbalizzazione/VerbalizzazionePresentazioneDocumentiRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.presentazioneDocumenti').provider('presentazioneDocumentiRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'presentazioneDocumentiList',
    role: '',
    url: 'presentazioneDocumenti?{page:int}&{limit:int}&{query:string}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _PresentazioneDocumentiMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Presentazione documenti"
    }
  }, {
    name: 'verbalizzaPresentazioneDocumenti',
    role: '',
    url: 'presentazioneDocumenti/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _VerbalizzazionePresentazioneDocumentiRoot.default
      }
    },
    resolve: {
      $label: () => "Verbalizzazione"
    }
  }];
  routesProviderFactory.extend(this);
}