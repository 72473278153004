"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.funzioniStampaListColumns = void 0;

var _enums = require("../../../state/enums");

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const funzioniStampaListColumns = [{
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'moduloStampa',
  headerName: 'Modulo stampa',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'funzioneModelloStampa',
  headerName: 'Funzione modello stampa',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'listaEntita',
  headerName: 'Entità',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? value.replace('eu.opensoftware.entities.', '') : "--",
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'visibilita',
  headerName: 'Visibilità',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaVisibilitaFunzioneStampa") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'contenutoAllegato',
  headerName: 'Contenuto allegato',
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  valueGetter: ({
    value
  }) => value ? value.descrizioneContenuto : "--",
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.funzioniStampaListColumns = funzioniStampaListColumns;