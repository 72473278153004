"use strict";

/**
 * GB 25/10/2018
 */
(function () {
  'use strict';
  /**
   * La direttiva è un componente generico da utilizzare ogni qual volta ci sia bisogno di
   * una ricerca testuale su un endpoint dalla quale si debba selezionare un risultato.
   *
   * Accetta in ingresso un aggId e/o un oggetto di tipo Entità e restituisce l'aggId selezionato dalla ricerca
   * (nel caso venga effettuata)
   *
   * Altri parametri
   * label: etichetta della form di input (opzionale)
   * queryProperty: nome della proprietà sulla quale filtrare la ricerca
   * searchCommand: nome comando per la ricerca
   * detailCommand: nome comando per il dettaglio (opzionale)
   * getItemText: metodo o funzione che restituisce la stringa da visualizzare come placeholder dopo la selezione
   * (opzionale) onEntityChange: handler da eseguire alla selezione di una entità (opzionale) additionalQueryParams:
   * parametri aggiuntivi da aggiungere alla richiesta
   */

  angular.module('osRicercaEntita').directive('osRicercaEntita', ['Command', 'rolesGuardService', osRicercaEntita]);

  function osRicercaEntita(Command, rolesGuardService) {
    return {
      restrict: 'E',
      require: ['ngModel', 'osRicercaEntita'],
      scope: {
        label: '@inputLabel',
        groupRoles: '<?',
        addRole: '@?',
        queryProperty: '@?',
        entity: '=?',
        //Two way bind in modo che la modifica si propaghi da sola
        searchCommand: '@',
        //Comando di ricerca
        detailCommand: '@?',
        //Comando di dettaglios
        detailIdProp: '@?',
        //L'aggId di riferimenti entitità potrebbe non essere nella proprietà
        // aggId dell'oggetto
        getItemText: '<?',
        //Metodo per generare l'item-text
        onEntityChange: '<?',
        //Metodo da richiamare in caso di selezione entità
        mdMenuClass: '@?',
        //Class wrapper per item-template
        customClass: '<?',
        isRequired: '<?',
        isReadonly: '<?',
        minLength: '<?',
        name: '@?',
        onNewEntityClick: '<?',
        additionalQueryParams: '<?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'osRicercaEntitaCtrl',
      //templateUrl: 'osFramework/osRicercaEntita/osRicercaEntita.directive.html',
      template: templateFunction,
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this,
          detailIdProp = scope.detailIdProp ? scope.detailIdProp : 'aggId',
          searchText = {},
          promise,
          shouldReload;
      scope.currSearchText = null; //se cambiano gli additionalQueryParams devo forzare un ricaricamento

      scope.$watch(() => scope.additionalQueryParams, function (additionalQueryParams, oldValue) {
        if (additionalQueryParams != oldValue) {
          shouldReload = true;
        }
      });
      me.loadEntity = loadEntity;

      function loadEntity(aggId) {
        var params = {};
        params[detailIdProp] = aggId;
        promise = fetchEntity(scope.detailCommand, params).then(setEntity);
        return promise;
      }

      function setEntity(entity) {
        scope.entity = entity;
        return entity;
      }

      function fetchEntity(command, params) {
        return Command.execute(command, params);
      }

      me.queryEntity = queryEntity;

      function queryEntity(searchText) {
        var params = scope.additionalQueryParams || {},
            shouldLoad; //Pulizia

        delete params[scope.queryProperty];

        if (searchText !== '') {
          if (scope.queryProperty) {
            params[scope.queryProperty] = searchText;
          }
        }

        if (searchText != scope.currSearchText) {
          scope.currSearchText = searchText;
          shouldLoad = true;
        }

        if (shouldLoad || shouldReload) {
          promise = fetchEntity(scope.searchCommand, params);
        } //promise = fetchEntity(scope.searchCommand, params);


        return promise.then(function (res) {
          return res instanceof Array ? res : res.resultsWrapper.values;
        });
      }

      me.getItemText = scope.getItemText || function () {
        return '';
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          osRicercaEntitaCtrl = controllers[1],
          detailIdProp = scope.detailIdProp ? scope.detailIdProp : 'aggId',
          groupRoles = scope.groupRoles || [];
      scope.minLength = scope.minLength ? scope.minLength : 0;
      scope.queryProperty = 'query'; //Ricerca testo libero

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue; // CONTROLLO CHE SUI RUOLI NECESSARI PER FAR FUNZIONARE LA RICERCA

        if (!rolesGuardService.userHasGroupRoles(groupRoles)) {
          return;
        }

        if (!viewValue) {
          scope.selectedItem = null;
          scope.currSearchText = null;
          return;
        }

        if (!scope.entity || scope.entity[detailIdProp] != viewValue) {
          if (scope.detailCommand) {
            osRicercaEntitaCtrl.loadEntity(viewValue).then(function (entity) {
              scope.selectedItem = entity;

              if (scope.onEntityChange) {
                scope.onEntityChange(entity);
              }
            });
          }
        } else {
          scope.selectedItem = scope.entity;
        }
      };

      scope.onLocalSelectedItemChange = function (selectedItem) {
        scope.currSearchText = null; //Sulla selezione di un elemento resettiamo il parametro che tiene traccia
        // della ricerca testuale

        if (!selectedItem) {
          scope.entity = null;
          ngModelCtrl.$setViewValue(null);
        } else {
          //Ho un aggId, verifico di avere anche l'entity altrimenti devo recuperarla dal be
          if (selectedItem[detailIdProp] != ngModelCtrl.$viewValue) {
            if (scope.detailCommand) {
              osRicercaEntitaCtrl.loadEntity(selectedItem[detailIdProp]).then(function (entity) {
                ngModelCtrl.$setViewValue(selectedItem[detailIdProp]);

                if (scope.onEntityChange) {
                  scope.onEntityChange(entity);
                }
              });
            } else {
              ngModelCtrl.$setViewValue(selectedItem[detailIdProp]);

              if (scope.onEntityChange) {
                scope.onEntityChange(selectedItem);
              }
            }
          }
        }
      };

      scope.creaNuovaEntita = function ($event) {
        $event.preventDefault();

        if (scope.onNewEntityClick) {
          //FIXME: questa gestione per far scomparire il virtual-repeat è una porcheria (così come il
          // componente).
          $('md-virtual-repeat-container.md-not-found').addClass('ng-hide');
          scope.onNewEntityClick();
          $('.md-scroll-mask').remove();
        } //scope.onNewEntityClick();

      };
    } //Non è possibile usare transclude perchè a sua volta mdAutocomplete ricompila e sostituisce l'html prima
    //che venga eseguito il codice di questa direttiva (di fatto rescrivendo il nostro html). Dobbiamo farlo a mano.


    function templateFunction(elem, attr) {
      function getItemTemplate() {
        var defTemplate = '<ng-include src="osRicercaEntita/itemTemplate/defaultItemTemplate.html"></ng-include>';
        return elem.find('os-ricerca-entita-item-template').html() || defTemplate;
      }

      ;

      function getNotFound() {
        var defTemplate = '<div layout=\'column\'>' + '            <span class=\'noMatchInList\' translate translate-values="{textToMatch: bySearchText}">form.errors.noMatchInList</span>' + '            <md-button class="md-raised md-primary" has-role-for role="{{addRole}}" ng-if="onNewEntityClick" ng-mousedown="creaNuovaEntita($event )">' + '                <translate>' + '                    CREA NUOVO' + '                </translate>' + '            </md-button>' + '        </div>';
        return elem.find('not-found').html() || defTemplate;
      }

      ;

      function getContent() {
        var defContent = '';
        return elem.find('os-ricerca-entita-content').html() || defContent;
      }

      return '<div layout="column" class="customClass" has-role-for role="{{groupRoles}}">' + '<md-autocomplete' + '        flex' + '        ng-required="isRequired"' + '        ng-readonly="isReadonly"' + '        name="{{name}}"' + '        md-require-match="true"' + '        md-selected-item="selectedItem"' + '        md-search-text="bySearchText"' + '        md-selected-item-change="onLocalSelectedItemChange(selectedItem)"' + '        md-items="item in osRicercaEntitaCtrl.queryEntity(bySearchText)"' + '        md-item-text="osRicercaEntitaCtrl.getItemText(item)"' + '        md-clear-button="!isReadonly"' + '        md-min-length="minLength"' + '        md-delay="500"' + '        md-no-cache="true"' + '        md-menu-class="{{mdMenuClass}}"' + '        md-floating-label="{{label ? label : (\'osRicercaEntita.noLabelProvided__label\' | translate)}}">' + '    <md-item-template>' + getItemTemplate() + '    </md-item-template>' + '    <md-not-found>' + getNotFound() + '    </md-not-found>' + '</md-autocomplete>' + '</div>' + '<md-content>' + getContent() + '</md-content>';
    }
  }
})();