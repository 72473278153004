"use strict";

(function () {
  'use strict';

  angular.module('ngInputCurrency').service('ngInputCurrencyService', ['$locale', function ($locale) {
    this.toFloat = function (str) {
      if (angular.isNumber(str)) {
        return parseFloat(str, 10);
      }

      if (!angular.isString(str)) {
        throw new TypeError('ngInputCurrencyService.toFloat expects argument to be a String, but was given ' + str);
      }

      str = str.replace(new RegExp(this.stringToRegExp($locale.NUMBER_FORMATS.GROUP_SEP), 'g'), '').replace(new RegExp(this.stringToRegExp($locale.NUMBER_FORMATS.CURRENCY_SYM), 'g'), '').replace(new RegExp(this.stringToRegExp($locale.NUMBER_FORMATS.DECIMAL_SEP), 'g'), '.');
      return parseFloat(str, 10);
    };

    this.stringToRegExp = function (str, opt) {
      return str.replace(/\./g, '\\.').replace(/\[/g, '\\[').replace(/\]/g, '\\]').replace(/\,/g, '\\,').replace(/\|/g, '\\|').replace(/\)/g, '\\)').replace(/\(/g, '\\(').replace(/\^/g, '\\^').replace(/\$/g, '\\$').replace(/\_/g, '\\_').replace(/\?/g, '\\?').replace(/\-/g, '\\-');
    };

    this.isValid = function (val) {
      return angular.isNumber(val) && !isNaN(val);
    };

    this.preformatValue = function (val) {
      if (!angular.isString(val)) {
        return val;
      }

      val = val.replace($locale.NUMBER_FORMATS.CURRENCY_SYM, '');
      var groupRegex = new RegExp(this.stringToRegExp($locale.NUMBER_FORMATS.GROUP_SEP), 'g'),
          decimalRegex = new RegExp(this.stringToRegExp($locale.NUMBER_FORMATS.DECIMAL_SEP), 'g');
      var groupMatch = val.match(groupRegex),
          decimalMatch = val.match(decimalRegex);

      if (groupMatch && groupMatch.length == 1 && (!decimalMatch || decimalMatch.length === 0)) {
        return val.replace(groupRegex, '.');
      }

      if (decimalMatch && decimalMatch.length == 1 && (!groupMatch || groupMatch.length === 0)) {
        return val.replace(decimalRegex, '.');
      }

      return val;
    };
  }]);
})();