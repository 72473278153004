"use strict";

/**
 * Created by gbelli on 08/11/2019
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaMotivoMancataContestazione', [RicercaMotivoMancataContestazione]);

  function RicercaMotivoMancataContestazione() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaMotivoMancataContestazione'],
      scope: {
        entity: '=?',
        label: '@?',
        onMotivoMancataContestazioneChange: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'ricercaMotivoMancataContestazioneCtrl',
      templateUrl: 'osFramework/input/ricercaMotivoMancataContestazione/ricercaMotivoMancataContestazione.directive.html',
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this;
      me.groupRoles = ['ROLE_MOTIVAZIONE_MANCATA_CONTESTAZIONE_RICERCA', 'ROLE_MOTIVAZIONE_MANCATA_CONTESTAZIONE_DETTAGLIO'];

      me.getItemText = function (motivoMancataContestazione) {
        var itemText;
        itemText = motivoMancataContestazione.codiceVeloce.toUpperCase().concat(' - ', motivoMancataContestazione.descrizione.toUpperCase());
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaMotivoMancataContestazioneCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.motivoMancataContestazioneAggId = null;
          return;
        }

        scope.motivoMancataContestazioneAggId = viewValue;
      };

      scope.$watch('tipoAccertamentoAggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();