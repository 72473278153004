"use strict";

var _ModelliStampaMasterDetail = _interopRequireDefault(require("./masterDetail/ModelliStampaMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.modelliStampa').provider('modelliStampaRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'modelliStampalist',
    role: 'ROLE_MODELLO_STAMPA_RICERCA',
    url: 'modelliStampa?{page:int}&{limit:int}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    params: {
      reloadPreviousState: false
    },
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ModelliStampaMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Modelli di stampa"
    }
  }
  /*
  https://jira.opensoftware.it/browse/POLQA-672
  {
      name       : 'modelliStampa.detail',
      url        : '/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}', //uuid regex
      role       : 'ROLE_MODELLO_STAMPA_MODIFICA',
      params     : {
          subView: null,
          aggId  : null
      },
      breadcrumbs: {
          isBase             : false,
          reloadPreviousState: true
      },
      views      : {
          $default: {
              component: ModelloStampaForm
          }
      },
      resolve    : {
          $label       :
              [
                  function(  ) {
                      return "Modifica modello di stampa"
                      // return modelloStampa.codice.concat(' - ', modelloStampa.descrizioneFunzione);
                  }
              ]
      }
  },
  {
      name       : 'modelliStampa.new',
      url        : '/nuovo',
      role       : 'ROLE_MODELLO_STAMPA_AGGIUNGI',
      breadcrumbs: {
          isBase             : false,
          reloadPreviousState: true
      },
      views      : {
          $default: {
              component: ModelloStampaForm
          }
      },
      resolve    : {
          $isNew: [() => true],
          $label: [
              '$translate',
              function( $translate ) {
                  return $translate('modelliStampa.nuovoModelloDiStampa__bcLabel');
              }
          ]
      }
  }*/
  ];
  routesProviderFactory.extend(this);
}