"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.documentoSoggettoValidationSchema = exports.documentoEmptyForm = exports.toApiDocumento = exports.fromApiDocumento = void 0;

var _object = require("../../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const fromApiDocumento = remoteDocumento => {
  let documento = { ...(remoteDocumento.aggId ? {
      aggId: remoteDocumento.aggId
    } : {}),
    ...(remoteDocumento.entityId ? {
      entityId: remoteDocumento.entityId
    } : {}),
    numeroDocumento: (0, _object.getPathNonEmptyStringOrNull)(["numeroDocumento"])(remoteDocumento),
    tipoDocumento: (0, _object.getPathNonEmptyStringOrNull)(["tipoDocumento"])(remoteDocumento),
    dataRilascio: (0, _object.getPathNumberToDateOrNull)(["dataRilascio"])(remoteDocumento),
    dataScadenza: (0, _object.getPathNumberToDateOrNull)(["dataScadenza"])(remoteDocumento),
    categorie: (0, _object.getPathArrayOrEmptyArray)(["categorie"])(remoteDocumento),
    enteRilascio: (0, _object.getPathStringOrNull)(["enteRilascioId"])(remoteDocumento) ? {
      aggId: (0, _object.getPathStringOrEmptyString)(["enteRilascioId"])(remoteDocumento),
      ragioneSociale: (0, _object.getPathStringOrEmptyString)(["enteRilascio", "ragioneSociale"])(remoteDocumento)
    } : null,
    stato: (0, _object.getPathStringOrNull)(["statoId"])(remoteDocumento) ? {
      aggId: (0, _object.getPathStringOrEmptyString)(["stato", "aggId"])(remoteDocumento),
      nome: (0, _object.getPathStringOrEmptyString)(["stato", "nome"])(remoteDocumento)
    } : null,
    specificheCQC: (0, _object.getPathNonEmptyStringOrNull)(["specificheCQC"])(remoteDocumento),
    specificheCAP: (0, _object.getPathNonEmptyStringOrNull)(["specificheCAP"])(remoteDocumento),
    descrizione: (0, _object.getPathNonEmptyStringOrNull)(["descrizione"])(remoteDocumento)
  };
  return documento;
};

exports.fromApiDocumento = fromApiDocumento;

const toApiDocumento = values => {
  const documento = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    ...(values.entityId ? {
      entityId: values.entityId
    } : {}),
    numeroDocumento: (0, _object.getPathNonEmptyStringOrNull)(["numeroDocumento"])(values),
    tipoDocumento: (0, _object.getPathNonEmptyStringOrNull)(["tipoDocumento"])(values),
    categorie: (0, _object.getPathArrayOrEmptyArray)(["categorie"])(values),
    dataRilascio: (0, _object.getPathDateToNumberOrNull)(["dataRilascio"])(values),
    dataScadenza: (0, _object.getPathDateToNumberOrNull)(["dataScadenza"])(values),
    enteRilascioId: (0, _object.getPathNonEmptyStringOrNull)(["enteRilascio", "aggId"])(values),
    statoId: (0, _object.getPathNonEmptyStringOrNull)(["stato", "aggId"])(values),
    specificheCQC: (0, _object.getPathNonEmptyStringOrNull)(["specificheCQC"])(values),
    specificheCAP: (0, _object.getPathNonEmptyStringOrNull)(["specificheCAP"])(values),
    descrizione: (0, _object.getPathNonEmptyStringOrNull)(["descrizione"])(values)
  };
  return documento;
};

exports.toApiDocumento = toApiDocumento;
const documentoEmptyForm = {
  numeroDocumento: "",
  tipoDocumento: "",
  dataRilascio: null,
  categorie: [],
  enteRilascio: null,
  specificheCQC: "",
  specificheCAP: "",
  dataScadenza: null,
  descrizione: "",
  stato: null
};
exports.documentoEmptyForm = documentoEmptyForm;
const documentoSoggettoValidationSchema = Yup.object().shape({
  tipoDocumento: Yup.string().required(_utils.requiredMessage),
  enteRilascio: Yup.object().required(_utils.requiredMessage).nullable(),
  numeroDocumento: Yup.string().required(_utils.requiredMessage),
  dataRilascio: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  dataScadenza: Yup.date().required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  categorie: Yup.array().when("tipoDocumento", {
    is: value => value && (value === 'patente' || value === 'patente_estera'),
    then: Yup.array().min(1, _utils.requiredMessage),
    otherwise: Yup.array().nullable()
  }),
  stato: Yup.object().when("tipoDocumento", {
    is: value => value && value === 'patente_estera',
    then: Yup.object().required(_utils.requiredMessage).nullable(),
    otherwise: Yup.object().nullable()
  }),
  specificheCQC: Yup.string().when("tipoDocumento", {
    is: value => value && value === 'cqc',
    then: Yup.string().required(_utils.requiredMessage).nullable(),
    otherwise: Yup.string().nullable()
  }),
  specificheCAP: Yup.string().when("tipoDocumento", {
    is: value => value && value === 'cap',
    then: Yup.string().required(_utils.requiredMessage).nullable(),
    otherwise: Yup.string().nullable()
  })
});
exports.documentoSoggettoValidationSchema = documentoSoggettoValidationSchema;