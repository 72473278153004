"use strict";

/**
 * GB 10/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaDepositoFermiSequestri', [RicercaDepositoFermiSequestri]);

  function RicercaDepositoFermiSequestri() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaDepositoFermiSequestri'],
      scope: {
        entity: '=?',
        label: '@?',
        onDepositoChange: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'ricercaDepositoCtrl',
      templateUrl: 'osFramework/input/ricercaDepositoFermiSequestri/ricercaDepositoFermiSequestri.directive.html',
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this;
      me.groupRoles = ['ROLE_DEPOSITO_FERMI_SEQUESTRI_RICERCA', 'ROLE_DEPOSITO_FERMI_SEQUESTRI_DETTAGLIO'];

      me.getItemText = function (deposito) {
        var itemText;
        itemText = deposito.descrizione[0].traduzione.toUpperCase();
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaDepositoCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.depositoAggId = null;
          return;
        }

        scope.depositoAggId = viewValue;
      };

      scope.$watch('depositoAggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();