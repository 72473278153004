"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.longalert = void 0;
const longalert = {
  "search": {
    "page": 1,
    "order": "alertTime,desc",
    "limit": 1000,
    "storedList": null,
    "excludeDb": false,
    "explodeId": false
  },
  "resultsWrapper": {
    "values": [{
      "alertId": "eb285518-7d31-4195-b71c-54bcc570b241",
      "rifAggId": "cef50ffc-3958-4f4e-90f5-9c388fe9633a",
      "alertTime": 1632929992560,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "8ea84ad1-6e0e-41fe-92cd-f60fb60f0fe0",
      "rifAggId": "936e3c7f-6c9b-497f-b269-a9ab6019e395",
      "alertTime": 1632929969942,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "e3bfd657-329f-433c-a59d-16ef3fd5858d",
      "rifAggId": "69037195-e5df-418d-9b5e-c857225db160",
      "alertTime": 1632929958036,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "d6e1666c-6716-4664-9814-2b1447526cd3",
      "rifAggId": "83dcd0a4-b1a3-4efb-8170-3c60b47af70b",
      "alertTime": 1632929946037,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "b7c1f310-e78c-4998-9a83-6b0ff4ac2020",
      "rifAggId": "e2ae82d6-f694-4af8-a195-4c187c36d80e",
      "alertTime": 1632929938469,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "ff0b79a1-7dff-4f29-9dab-a1ecbabc9494",
      "rifAggId": "d848ad7e-3155-44fe-a4e6-4aabdee358e1",
      "alertTime": 1632929925266,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "eba8f184-7d0e-4fc3-891d-c89d21249bb7",
      "rifAggId": "c6128020-6e32-4068-9545-842325379ea6",
      "alertTime": 1632929796370,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "79d55140-2a91-e86a-10dc-3dd6d8da2eb3",
      "rifAggId": "624a18c5-ff21-a681-84cb-f8089e323eca",
      "alertTime": 1632925152492,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10346' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "40ce3451-a5c9-62d6-c066-8f0a07adc3ad",
      "rifAggId": "624a18c5-ff21-a681-84cb-f8089e323eca",
      "alertTime": 1632925019363,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10346' completata",
      "alertType": "ok"
    }, {
      "alertId": "ac58c0b1-086f-4185-9edb-dbc7d168e8c8",
      "rifAggId": "624a18c5-ff21-a681-84cb-f8089e323eca",
      "alertTime": 1632924914339,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '160/2021'",
      "alertType": "ok"
    }, {
      "alertId": "476d3c18-a08d-4bad-8512-c1aedfc346d4",
      "rifAggId": "073a2a0e-ab82-4d2f-a58d-76707837f999",
      "alertTime": 1632891602969,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "78636c85-1d72-41a7-e5ea-f5a785481a3d",
      "rifAggId": "24c425bc-565d-032b-73b7-5fa8284902c6",
      "alertTime": 1632839130016,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA000AUO_10392' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "dc9c9f4f-b329-4d1f-a117-144cd0dadefe",
      "rifAggId": "24c425bc-565d-032b-73b7-5fa8284902c6",
      "alertTime": 1632836413235,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '159/2021'contenente 14 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "5503ed35-6852-fc64-7e78-13f66602882c",
      "rifAggId": "2bd1fc94-a2c1-24b9-842e-8b5dd2e8e592",
      "alertTime": 1632813726352,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10480' completata",
      "alertType": "ok"
    }, {
      "alertId": "11149ba2-17a1-4911-8d1a-b5aef6f9932a",
      "rifAggId": "2bd1fc94-a2c1-24b9-842e-8b5dd2e8e592",
      "alertTime": 1632813647754,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '158/2021'",
      "alertType": "ok"
    }, {
      "alertId": "114af3cf-fc2c-4a9b-8e57-758cedfd1cb9",
      "rifAggId": "587344af-a44b-4788-872d-cd910ef44ac7",
      "alertTime": 1632805202748,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "74b65bbb-1ff0-4c23-aac4-3337d0be7620",
      "rifAggId": "78185ba1-6182-47af-b38d-2f577ebb6d70",
      "alertTime": 1632718803260,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "96367670-26d6-4a8c-90c8-07806c49032a",
      "rifAggId": "a006cf8f-4b89-40fd-afb9-625d9bfb3515",
      "alertTime": 1632632404374,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "e436cd78-2ce6-4bc1-969c-ab07f57cf6cf",
      "rifAggId": "f5e84658-ee32-40f0-b6c4-d1fb63b523ad",
      "alertTime": 1632546003311,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "1ae4b2e0-1659-417c-a8d8-935eb8217af1",
      "rifAggId": "993b9616-bd1c-44c4-ba48-f30a71d4ccb8",
      "alertTime": 1632480673244,
      "tipoAggregato": "bollettino_txt",
      "messaggio": "È completata l'acquisizione di 49 rilevazioni di tipo bollettino_txt",
      "alertType": "ok"
    }, {
      "alertId": "d5c1b2d8-1212-43dd-8d97-0f0bf623eda9",
      "rifAggId": "3aaaa3b6-7dcc-4739-92af-9bf73a9d6198",
      "alertTime": 1632480578785,
      "tipoAggregato": "bollettino_txt",
      "messaggio": "È completata l'acquisizione di 52 rilevazioni di tipo bollettino_txt",
      "alertType": "ok"
    }, {
      "alertId": "e6b58241-280a-45ed-a555-96036bf627f4",
      "rifAggId": "470d7fc5-5b85-4b02-b505-9e06853155e1",
      "alertTime": 1632459603047,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "959f6415-6c90-4fa4-8982-2a2fecb4a220",
      "rifAggId": "ae3c67ab-38bb-7f72-8f0e-f63cb0928aca",
      "alertTime": 1632410150013,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 126/2021.",
      "alertType": "ok"
    }, {
      "alertId": "a1b6b597-adba-47b9-ad78-544a03f464dd",
      "rifAggId": "16eeec50-3f82-800c-9b00-5306ac3798b6",
      "alertTime": 1632410144066,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 129/2021.",
      "alertType": "ok"
    }, {
      "alertId": "b2b5deaa-e695-4c8a-8e66-6946ae80ab8e",
      "rifAggId": "7aaa545e-0c69-72de-cbaa-7b61ef4cea11",
      "alertTime": 1632410108225,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 153/2021.",
      "alertType": "ok"
    }, {
      "alertId": "6d8460cb-95b4-48eb-8823-dd4fcd7ae323",
      "rifAggId": "1d1e67f3-ce07-4fb8-84da-abcf32d739af",
      "alertTime": 1632373202238,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "cb776deb-45bb-4291-a7b4-9ab82507016e",
      "rifAggId": "a6dd5e0a-8a84-45a0-bb2f-16952f4908e6",
      "alertTime": 1632286803424,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "7c144575-d35b-424a-bb13-2b84b3c51032",
      "rifAggId": "368e4fd4-f403-08aa-d8e4-285df167ad58",
      "alertTime": 1632210559470,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 157/2021.",
      "alertType": "ok"
    }, {
      "alertId": "c5a4805c-b984-4bb5-bb87-5e93c5cbcc77",
      "rifAggId": "368e4fd4-f403-08aa-d8e4-285df167ad58",
      "alertTime": 1632210464917,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '157/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "602c7b5a-d97b-4eed-b741-42b7115ef863",
      "rifAggId": "5786816a-885b-4417-b968-89e78edf3653",
      "alertTime": 1632200404734,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "22a849c1-f85e-4a42-9a75-b86865d20b82",
      "rifAggId": "b76ebd45-b9b5-49ed-913d-6dc43607888e",
      "alertTime": 1632200404650,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "59081c4d-bd04-4540-a548-58e8cf6b3c36",
      "rifAggId": "c6421e2a-1181-43e7-bdd1-79848f5157d2",
      "alertTime": 1632200404644,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "75490538-118a-118f-a3ad-f00b37ab887b",
      "rifAggId": "9b486b0c-591d-26cf-57bf-2dc6e6b04cc2",
      "alertTime": 1632142405628,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA000ZUO_10353' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "cdc6a86f-9236-470b-a8b0-7e834bf8febd",
      "rifAggId": "9b486b0c-591d-26cf-57bf-2dc6e6b04cc2",
      "alertTime": 1632141032461,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '156/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "59d7f906-2b82-4aab-befa-69e9e887e65b",
      "rifAggId": "4bfbcdd5-990f-4453-8be0-b614f8e03417",
      "alertTime": 1632114449002,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "7d33a1e9-0f72-465f-a9fa-a11bcd3102e3",
      "rifAggId": "dd042a9b-1656-4e16-8296-ac4213bc2c30",
      "alertTime": 1632114449000,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "fa74ed53-8f79-49d9-b573-22d416e1d85d",
      "rifAggId": "5bbbb2df-3072-422a-9089-0d2f9652bb04",
      "alertTime": 1632114448998,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "b92667a6-92bb-4c80-b102-0ce9e3bc413a",
      "rifAggId": "8a5f8d92-4c4e-9a62-f3ad-536fa3a790db",
      "alertTime": 1632062640442,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 155/2021.",
      "alertType": "ok"
    }, {
      "alertId": "db6d75eb-2086-40b6-a76c-0a4c4995c5f2",
      "rifAggId": "8a5f8d92-4c4e-9a62-f3ad-536fa3a790db",
      "alertTime": 1632061849388,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '155/2021'",
      "alertType": "ok"
    }, {
      "alertId": "640313c9-675e-47fc-f35b-e12644b234c3",
      "rifAggId": "e920d210-539c-5b9a-ac91-1afd504a49c6",
      "alertTime": 1632061664861,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0005UO_10488' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "57e55eb1-a064-11a9-40be-4983e374cbe8",
      "rifAggId": "e920d210-539c-5b9a-ac91-1afd504a49c6",
      "alertTime": 1632061643459,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione delle spedizioni al lotto non possibile: Non e' stato possibile associare le spedizioni al Lotto. Nessuna spedizione disponibile trovata con i parametri impostati",
      "alertType": "error"
    }, {
      "alertId": "cfc2c422-91cf-b8e8-5b03-e737dcdcb1aa",
      "rifAggId": "e920d210-539c-5b9a-ac91-1afd504a49c6",
      "alertTime": 1632061631288,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0005UO_10488' completata",
      "alertType": "ok"
    }, {
      "alertId": "985a805c-4c7f-40c6-b19a-c8f4e3ea47b7",
      "rifAggId": "e920d210-539c-5b9a-ac91-1afd504a49c6",
      "alertTime": 1632061609892,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '154/2021'",
      "alertType": "ok"
    }, {
      "alertId": "0728d00a-f3a0-4aca-a676-5597a1b72a71",
      "rifAggId": "c486547b-4a52-4313-89e8-d29bab764191",
      "alertTime": 1632027604718,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "38b8079a-81c9-4ef0-8d4c-dd582de821af",
      "rifAggId": "f54e9902-5585-4800-b6c2-b256956ee36e",
      "alertTime": 1632027604701,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "7cd69526-e117-47dc-afa0-64534dfa87be",
      "rifAggId": "b20b3f61-77fc-4453-ad50-4d7caf82e318",
      "alertTime": 1632027604699,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "df6a191d-17b4-4bb5-b929-ae46d599d85e",
      "rifAggId": "7aaa545e-0c69-72de-cbaa-7b61ef4cea11",
      "alertTime": 1631996942791,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '153/2021'",
      "alertType": "ok"
    }, {
      "alertId": "4d81cb2d-60dd-4b4e-8020-b09098522193",
      "rifAggId": "78111b1f-4fb7-2395-3a75-68343d9fea44",
      "alertTime": 1631996925169,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 152/2021.",
      "alertType": "ok"
    }, {
      "alertId": "ed65ebbb-db33-435f-ab8b-1e05fafd5f1e",
      "rifAggId": "78111b1f-4fb7-2395-3a75-68343d9fea44",
      "alertTime": 1631996816715,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '152/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "7aec95f9-6f7b-4ad6-b548-67210f2e1ed6",
      "rifAggId": "38c64ee7-f19b-412b-83a9-4b1d9f170ae8",
      "alertTime": 1631941204804,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "28a53335-0fe6-48a8-88e2-05e52b975699",
      "rifAggId": "a6490257-e298-461a-bf1b-69709b8ab721",
      "alertTime": 1631941204676,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "bd9221a1-0b31-4779-b751-3e341ff784dc",
      "rifAggId": "cae6f1b5-5ce4-4b82-9709-3b7a79811630",
      "alertTime": 1631941204469,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "74614015-45a3-163f-5058-e229a8d4856b",
      "rifAggId": "14c14dbe-b6d2-7b89-d0f5-77860c5cd47f",
      "alertTime": 1631883012643,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0006UO_10427' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "dd02fd3f-49ec-eb57-93e2-dd094c608c2d",
      "rifAggId": "14c14dbe-b6d2-7b89-d0f5-77860c5cd47f",
      "alertTime": 1631882955888,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10427' completata",
      "alertType": "ok"
    }, {
      "alertId": "549b121e-b1d1-4ff8-a444-f1ee28ce04b5",
      "rifAggId": "14c14dbe-b6d2-7b89-d0f5-77860c5cd47f",
      "alertTime": 1631882670982,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '151/2021'",
      "alertType": "ok"
    }, {
      "alertId": "6adfefde-2d4f-4b2a-aaf2-89eb1add1bd7",
      "rifAggId": "7ca3bd3f-af68-444c-8f2f-0d1725272ce6",
      "alertTime": 1631873371273,
      "tipoAggregato": "sana",
      "messaggio": "È fallita la lavorazione del file per una rilevazione del tipo sana perché error in opening zip file",
      "alertType": "error"
    }, {
      "alertId": "7dfb0bcc-d5d9-4b62-97fd-9bfd5e4eba38",
      "rifAggId": "a0081827-1214-46fa-9ae2-054016e8b2f1",
      "alertTime": 1631854803878,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "ed99e3dc-ae02-482f-8a38-82164486f153",
      "rifAggId": "0b32b48d-0858-47b1-bc30-02fb60cdd445",
      "alertTime": 1631854803873,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "2187018f-ea16-41b4-8ef2-03dcde1d739f",
      "rifAggId": "b43a06c6-da5d-4286-be23-01e5b0bb09a4",
      "alertTime": 1631854803870,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "55199a7b-eb4e-a7f5-bcd3-423a42631307",
      "rifAggId": "996b61ee-48da-04e1-3fd3-eea411383bb1",
      "alertTime": 1631787949629,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA000AUO_10352' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "d0f16d7a-03ca-46a4-8962-72211a36d28d",
      "rifAggId": "996b61ee-48da-04e1-3fd3-eea411383bb1",
      "alertTime": 1631787754672,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '150/2021'contenente 1 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "5788f84e-9dfd-4cd5-b79b-3d573cf56f87",
      "rifAggId": "50d2435c-731a-4b79-a9ea-de06dbdaa8a7",
      "alertTime": 1631768404739,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "5cfd2409-33f1-4ef8-8a50-1be9a3852224",
      "rifAggId": "e501ce58-3a04-48be-9644-298751bafef7",
      "alertTime": 1631768404719,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "594b58a6-2b22-46f3-af3d-67b8384803f6",
      "rifAggId": "0107ead3-340a-40fe-955e-c58e48587e59",
      "alertTime": 1631768404716,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "f98c8cb4-9f5d-2917-b2d6-0d3089916d6a",
      "rifAggId": "d3e6886d-c783-0dbe-6adb-dd451261655d",
      "alertTime": 1631693341987,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10431' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "f457cad3-64c4-6f5f-eab2-a9656589679c",
      "rifAggId": "d3e6886d-c783-0dbe-6adb-dd451261655d",
      "alertTime": 1631691580436,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10431' completata",
      "alertType": "ok"
    }, {
      "alertId": "15c74264-be39-420a-82c8-e8aad1ad3d65",
      "rifAggId": "c6220c14-9bfb-4a79-b383-9c4b7af174e5",
      "alertTime": 1631682004358,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "600a7ffd-7330-49bd-9e04-3ead6a27dc39",
      "rifAggId": "a4a89010-e777-4ea8-8e4a-c57a09f5566b",
      "alertTime": 1631682004277,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "2a31fa90-5cb0-4d2c-a40d-b07f9a6db40d",
      "rifAggId": "47f52750-b013-44a2-82e3-53ada0b92119",
      "alertTime": 1631682002924,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "e283c965-bd86-48bd-ac80-dfb804ca7865",
      "rifAggId": "f8998c84-150a-4de4-5326-c090beff6a1b",
      "alertTime": 1631642919272,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 149/2021.",
      "alertType": "ok"
    }, {
      "alertId": "6f713c21-7409-4662-bd7a-5e1bb4b2e592",
      "rifAggId": "f8998c84-150a-4de4-5326-c090beff6a1b",
      "alertTime": 1631642791511,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '149/2021'",
      "alertType": "ok"
    }, {
      "alertId": "afc626dd-51c1-b5eb-f52d-9678b59a9256",
      "rifAggId": "7b2720c9-40fd-0647-c716-fb95ecc73ddc",
      "alertTime": 1631642552451,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0004UO_10783' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "3257a84c-a083-39b2-db9b-93ec022b3242",
      "rifAggId": "7b2720c9-40fd-0647-c716-fb95ecc73ddc",
      "alertTime": 1631642506643,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0004UO_10783' completata",
      "alertType": "ok"
    }, {
      "alertId": "31ab682a-c42f-41e8-b946-005b983a4558",
      "rifAggId": "7b2720c9-40fd-0647-c716-fb95ecc73ddc",
      "alertTime": 1631642436548,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '148/2021'",
      "alertType": "ok"
    }, {
      "alertId": "5cd82b65-c29c-4f4b-bf47-70f9029eb49e",
      "rifAggId": "d3e6886d-c783-0dbe-6adb-dd451261655d",
      "alertTime": 1631641467566,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '147/2021'",
      "alertType": "ok"
    }, {
      "alertId": "03a8a5bc-fa6a-4635-bba6-b2985a843f1b",
      "rifAggId": "d9ef4aca-d8fd-4f41-8d7a-7c0a7841c944",
      "alertTime": 1631595606022,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "2d8ce13d-428c-4693-a5f2-eab098c3c422",
      "rifAggId": "e03ff912-6a44-487e-acf2-0d37dfb140f0",
      "alertTime": 1631595606008,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "884b6a44-7c3c-4eb4-8483-e047477bd904",
      "rifAggId": "1256cd1f-9582-4925-b33c-a07f77d88659",
      "alertTime": 1631595603076,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "85891ffd-6ea2-2864-1f46-8f268181f815",
      "rifAggId": "6441e383-a9d2-4d6d-a3ac-b1edb4beb91e",
      "alertTime": 1631543307952,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0005UO_10427' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "b0882dfd-6aa7-a4d3-d1f3-91b9cd36ad6f",
      "rifAggId": "6441e383-a9d2-4d6d-a3ac-b1edb4beb91e",
      "alertTime": 1631543269524,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0005UO_10427' completata",
      "alertType": "ok"
    }, {
      "alertId": "ff0508c5-c986-47b5-979b-d1b9b6f98339",
      "rifAggId": "6441e383-a9d2-4d6d-a3ac-b1edb4beb91e",
      "alertTime": 1631543148650,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '146/2021'",
      "alertType": "ok"
    }, {
      "alertId": "df0f7180-7a21-6b3f-33b4-b629162f12cb",
      "rifAggId": "c78965c7-9060-1dfb-dabb-2fab6f906148",
      "alertTime": 1631542542207,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "2f5f6eda-55cc-1af5-9456-870dbb48cf95",
      "rifAggId": "3d83c712-a612-b9ca-d704-77bed32cd09d",
      "alertTime": 1631530545971,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0007UO_10487' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "0bf2a602-36d0-0568-5982-1ca95d29a431",
      "rifAggId": "3d83c712-a612-b9ca-d704-77bed32cd09d",
      "alertTime": 1631530511522,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0007UO_10487' completata",
      "alertType": "ok"
    }, {
      "alertId": "fbffe216-a64f-47a7-819f-6102984189f3",
      "rifAggId": "3d83c712-a612-b9ca-d704-77bed32cd09d",
      "alertTime": 1631530465066,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '145/2021'",
      "alertType": "ok"
    }, {
      "alertId": "6e44a1e1-21f6-e4b0-87aa-01555485a9ce",
      "rifAggId": "caf24cea-7a7a-26ce-eebd-7aaacd3dfaf1",
      "alertTime": 1631530404986,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "5b3bc147-877e-9112-6cb1-ac3df96ff617",
      "rifAggId": "13bcfbf7-1bda-f2ff-08a2-03cbd5943586",
      "alertTime": 1631529351218,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "0b049f2e-0f39-47a6-8e59-c9ed33791a99",
      "rifAggId": "80b289a2-ca54-48c3-b7e1-764fd5a5ebdf",
      "alertTime": 1631509204974,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "2729c179-d615-4d2f-b7ab-4413d4c7f81e",
      "rifAggId": "37e8af11-834b-49fa-97d2-cb40000b5d9b",
      "alertTime": 1631509204860,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "34c65db4-6aab-4031-b1db-84ba59d8eecd",
      "rifAggId": "0cfb5a5c-57ae-4e9e-b8d9-2caee7dcc219",
      "alertTime": 1631509204851,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "966f85bc-683f-4471-80fe-41e143cdd234",
      "rifAggId": "6b0fcd48-ec82-4969-a85f-99e14ac44590",
      "alertTime": 1631422809189,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "b0b0ae82-cd74-4e1c-bdf0-716dd5ef69e9",
      "rifAggId": "f37cb28e-887a-45dd-b1ec-e784bafbb3a6",
      "alertTime": 1631422807886,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "8550c9cf-13a0-44f1-af13-a554e3026edc",
      "rifAggId": "e2a3b14e-6ef3-4504-90d8-9d6d3ff13302",
      "alertTime": 1631422807769,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "44804513-2d3c-08c0-b71d-ffd76200902b",
      "rifAggId": "998723b3-49e4-a1d6-0eba-0c46265e96ea",
      "alertTime": 1631356878330,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0009UO_10392' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "8ce0d213-c9ac-4617-928c-d73d4b72a533",
      "rifAggId": "998723b3-49e4-a1d6-0eba-0c46265e96ea",
      "alertTime": 1631351791581,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '144/2021'contenente 16 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "7c81f163-871e-4058-b4f6-e808df70adef",
      "rifAggId": "3ba995fe-5de1-4554-a1b1-0b3ca4956802",
      "alertTime": 1631336404839,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "86353d0e-5b1e-47c8-9b54-b0cf3f543fa8",
      "rifAggId": "ef0b80a5-df44-4785-9738-ebb5d9fb34ba",
      "alertTime": 1631336404826,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "a5b71376-5143-4ef4-838d-20c2b844de33",
      "rifAggId": "d0f065d1-e98f-484f-8ea4-f21070ff5fbd",
      "alertTime": 1631336404716,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "08883f6b-0d52-4174-a5b9-771c6e7a4945",
      "rifAggId": "80de1b55-5b97-4e82-8d72-6f8f6b24a20a",
      "alertTime": 1631250004908,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:5",
      "alertType": "ok"
    }, {
      "alertId": "621730a0-0075-4bee-9c4f-91da25a4ee2a",
      "rifAggId": "84b2e4d9-c813-426b-9b40-2a2b9693ae6f",
      "alertTime": 1631250004711,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:5",
      "alertType": "ok"
    }, {
      "alertId": "5c969532-0096-4795-b342-a592baaf19ab",
      "rifAggId": "20df8f70-4f73-4bfb-a5d4-7386399e9cc5",
      "alertTime": 1631250004697,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:5",
      "alertType": "ok"
    }, {
      "alertId": "612bd494-3ab7-4468-ac02-b9dfaee8341c",
      "rifAggId": "d55cac7a-3845-47a2-b00a-90f40c080e3e",
      "alertTime": 1631189391511,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 143/2021.",
      "alertType": "ok"
    }, {
      "alertId": "83474a03-7734-4fa1-b8ee-84d1bb80de2c",
      "rifAggId": "d55cac7a-3845-47a2-b00a-90f40c080e3e",
      "alertTime": 1631189298948,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '143/2021'",
      "alertType": "ok"
    }, {
      "alertId": "5b6584cf-4fa1-7879-f391-bdb44549ba06",
      "rifAggId": "3291d1e0-e2db-e587-499c-3197c0ee0c65",
      "alertTime": 1631189074366,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "20a3e620-8240-4ae2-869d-a1e1e6407253",
      "rifAggId": "7ed25336-59be-27b6-c058-54f9d2e2e93b",
      "alertTime": 1631188803019,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 142/2021.",
      "alertType": "ok"
    }, {
      "alertId": "4dac44d0-7fd8-4b32-808d-b261e13a6d44",
      "rifAggId": "7ed25336-59be-27b6-c058-54f9d2e2e93b",
      "alertTime": 1631187940609,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '142/2021'",
      "alertType": "ok"
    }, {
      "alertId": "eef37a39-b911-5ef7-12d6-7dd2365239c8",
      "rifAggId": "e072a49b-c818-69c8-573d-e95a9867c831",
      "alertTime": 1631187717962,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "926b7bd2-3415-3ffc-152b-35ed18432193",
      "rifAggId": "15b62160-d99d-b1a7-f22c-e5744a967797",
      "alertTime": 1631187598806,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "5e3579fd-3512-64cd-14e3-1bf9d26dae7b",
      "rifAggId": "25dbcc0e-23ed-fd96-75c4-44d293d650f2",
      "alertTime": 1631182559959,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto 25dbcc0e-23ed-fd96-75c4-44d293d650f2 fallita. Errore: L'utente non e' abilitato alla creazione del Lotto",
      "alertType": "error"
    }, {
      "alertId": "7551233c-4a70-4bf8-964d-1d52bd1f8a26",
      "rifAggId": "b03538f5-6a0a-4068-a25e-3c95eaa217c9",
      "alertTime": 1631163604701,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "734cede6-699e-4bf2-a97d-c08891d62446",
      "rifAggId": "157ea3d4-b41d-4811-a0d0-750779367e9a",
      "alertTime": 1631163604476,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "90627044-668d-4f53-aa4a-526de13afd6a",
      "rifAggId": "8dc48b29-4fa1-4e90-a6f5-40ce08131220",
      "alertTime": 1631163604377,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "810d4bd1-81bc-4ddf-9242-3a20ca4062a6",
      "rifAggId": "fd1ee79d-2b38-441a-8a99-24225efa986b",
      "alertTime": 1631115475661,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "66469ac4-e0bd-4993-8ab7-060726c8a5d1",
      "rifAggId": "e338ba33-d83f-4f43-8008-06a8abc424bc",
      "alertTime": 1631115353797,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "903dcd04-abfb-40b5-ac89-3c6ea89659e8",
      "rifAggId": "df23d856-c7fe-4fdc-a84a-55777ca036c9",
      "alertTime": 1631115238963,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "62984ad0-db7e-44a0-868b-b77bc0d3c386",
      "rifAggId": "2ee67780-fce0-49f2-9bad-b634b510ea91",
      "alertTime": 1631115130618,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "37b977bd-2db5-4a66-94b2-85ead6460f29",
      "rifAggId": "2a77d242-11e6-4263-a1cd-02785bc26fa7",
      "alertTime": 1631115069122,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "0c6bb1ca-56bd-430a-9790-9d32f83c7bae",
      "rifAggId": "f42dc40a-0054-40de-b9de-4dc1e857dcf2",
      "alertTime": 1631115020484,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "d9d8963f-55ac-43f6-bfad-1e4ce8caa51e",
      "rifAggId": "5218a614-0847-4cb0-bcf1-817feb145dfe",
      "alertTime": 1631114975172,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "3dda8df6-aa6f-4b99-9a5b-e78b8166d1fc",
      "rifAggId": "626777d8-588f-4344-901c-d784bc40ba6b",
      "alertTime": 1631114259190,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "18eab999-edd2-4121-9be6-f03b642e9726",
      "rifAggId": "c1a5b33d-1e44-432c-af11-de3ea8598cc6",
      "alertTime": 1631114215304,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "d7f8a3d9-d659-4cce-baec-019bc2952b60",
      "rifAggId": "edbd23cb-9b4f-42cd-8cf1-848d52d5b795",
      "alertTime": 1631114170635,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "3ae6d9e8-e395-459f-828b-5396c8cf2f41",
      "rifAggId": "f8ca5cb7-91a3-4065-b2ce-fe375bfda136",
      "alertTime": 1631114103194,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "c14a67a5-2e40-43b2-8281-e6fe5dde38ce",
      "rifAggId": "a7e095be-7ff5-403f-946b-eed4b677f877",
      "alertTime": 1631114029275,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "bd985cfe-69fb-41fd-ad57-c5e2050ec3df",
      "rifAggId": "e3c9b203-cefe-45b7-6046-b64badd4d4ad",
      "alertTime": 1631110737165,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 140/2021.",
      "alertType": "ok"
    }, {
      "alertId": "0bd50eed-13e7-40f9-88e8-bf843a320ed7",
      "rifAggId": "7ec35855-0e54-45d7-bb4f-a3b87f9dac27",
      "alertTime": 1631107960874,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "10a009b2-9a25-68cc-886e-d4037f4298f0",
      "rifAggId": "e299cae2-1495-1366-fc59-612c1bdf7f0e",
      "alertTime": 1631105827131,
      "tipoAggregato": "LOTTO",
      "messaggio": "Cancellazione del lotto e299cae2-1495-1366-fc59-612c1bdf7f0e fallita. Errore: null",
      "alertType": "error"
    }, {
      "alertId": "03102ba1-9193-4cad-8b73-935a952f7455",
      "rifAggId": "e299cae2-1495-1366-fc59-612c1bdf7f0e",
      "alertTime": 1631105827038,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 141/2021.",
      "alertType": "ok"
    }, {
      "alertId": "e1ab6521-fbd5-2073-0432-c212c2a5655f",
      "rifAggId": "e299cae2-1495-1366-fc59-612c1bdf7f0e",
      "alertTime": 1631105687744,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto e299cae2-1495-1366-fc59-612c1bdf7f0e fallita. Errore: null",
      "alertType": "error"
    }, {
      "alertId": "a7f09a7e-ba2f-4379-9457-7a3b3b3eb038",
      "rifAggId": "e299cae2-1495-1366-fc59-612c1bdf7f0e",
      "alertTime": 1631105686088,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '141/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "1248387f-cbed-4ee3-a831-910da612ad84",
      "rifAggId": "9255b810-7f3a-4234-b6db-5e4021aa223f",
      "alertTime": 1631097711077,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "9909bee2-fac0-dcb0-a0af-7a0f877f564a",
      "rifAggId": "8829a252-e942-7337-a8ca-72bac72ed1cc",
      "alertTime": 1631083976032,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "2cefbeb2-3403-432d-8d8a-ad6d2241f0b4",
      "rifAggId": "2cfa217d-77b5-4f6a-8281-14f7d001f9f0",
      "alertTime": 1631077208605,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:23",
      "alertType": "ok"
    }, {
      "alertId": "3ec53825-b315-4e1a-94cd-621d39cf42db",
      "rifAggId": "17265eb8-54b5-498b-91a2-edc06742a0b6",
      "alertTime": 1631077208225,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:23",
      "alertType": "ok"
    }, {
      "alertId": "cef45165-fc14-4b80-80d8-05caeb613bd7",
      "rifAggId": "c6473d51-ff33-4b23-a9aa-4db15dfd5cf3",
      "alertTime": 1631077207957,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:23",
      "alertType": "ok"
    }, {
      "alertId": "7412d709-f457-18f5-8f43-38dc4589bc68",
      "rifAggId": "47ac2756-6b00-1ff6-c2dd-e92c9d45911d",
      "alertTime": 1631015850901,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0005UO_10347' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "534389bc-6d66-c51b-46cb-04dcf12bf63e",
      "rifAggId": "e3c9b203-cefe-45b7-6046-b64badd4d4ad",
      "alertTime": 1631008038481,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10454' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "99ecb949-8f02-43c3-aa95-5ebb2cac9237",
      "rifAggId": "e3c9b203-cefe-45b7-6046-b64badd4d4ad",
      "alertTime": 1631007653113,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '140/2021'contenente 4 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "7e6fb429-0519-ade0-829b-7fd743b80794",
      "rifAggId": "f0738d34-e10d-d424-b6ea-ae5e380fd330",
      "alertTime": 1631005382881,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "03c476db-b866-42e0-8902-3ecdf3c80d0a",
      "rifAggId": "47ac2756-6b00-1ff6-c2dd-e92c9d45911d",
      "alertTime": 1631003635912,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '139/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "9abc6ddb-4c40-6d09-e726-6436ff2ef972",
      "rifAggId": "e17b1217-6230-0028-5bdd-e68606dc4c46",
      "alertTime": 1631002111874,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto e17b1217-6230-0028-5bdd-e68606dc4c46 fallita. Errore: Tipo lotto:'prenotifica' non supportato",
      "alertType": "error"
    }, {
      "alertId": "b41272de-dce7-1942-bbe6-fec934900937",
      "rifAggId": "5f4c9702-3b5a-1806-2c1b-eb040768b8f4",
      "alertTime": 1631000752252,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto 5f4c9702-3b5a-1806-2c1b-eb040768b8f4 fallita. Errore: Tipo lotto:'prenotifica' non supportato",
      "alertType": "error"
    }, {
      "alertId": "a01b14c3-bf40-407a-8f5d-472c0cfa791c",
      "rifAggId": "120eecdf-ee62-4735-820f-87c50d4f14ea",
      "alertTime": 1630990810236,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "0951ec31-350e-4759-a738-8323444a3e0a",
      "rifAggId": "cecb3e1f-225b-4bcd-b93f-03f1e7288117",
      "alertTime": 1630990809757,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "a09e6f5e-8a44-42f1-b5cc-61094d97eb4e",
      "rifAggId": "02c3f1ad-4513-4976-898a-196ca4c07bbb",
      "alertTime": 1630990809574,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "27fba3d0-cb2c-463b-bb8d-456598ae1828",
      "rifAggId": "afc9d691-88e7-4904-0c1c-ce467e3fe410",
      "alertTime": 1630914346130,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 138/2021.",
      "alertType": "ok"
    }, {
      "alertId": "055b3903-3332-464d-90c1-522b3e945bf4",
      "rifAggId": "afc9d691-88e7-4904-0c1c-ce467e3fe410",
      "alertTime": 1630913590995,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '138/2021'",
      "alertType": "ok"
    }, {
      "alertId": "5bbbe43b-465a-fc20-7eb1-0c1343619688",
      "rifAggId": "ae679b58-bd1b-0257-c4aa-fc9bed3bd08a",
      "alertTime": 1630911028336,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "8f1b6bfc-3d19-404e-9c0b-b3bdd0d8d38a",
      "rifAggId": "776acfcd-04b1-4c2f-b2bd-af5c426ea9bb",
      "alertTime": 1630904407993,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "709b6731-5ea6-431b-9b5f-3c2e04371797",
      "rifAggId": "8f5a6282-8bd7-4009-977c-71b10a86ea50",
      "alertTime": 1630904407626,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "7c123b1d-e23e-4b99-b006-98623e747844",
      "rifAggId": "2bd36db3-aeda-4268-8876-8d213b319ba8",
      "alertTime": 1630904407373,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "c1f6309d-7224-4a1f-8ded-6b465adfa95a",
      "rifAggId": "2c56692f-3f39-4f43-b003-216087758807",
      "alertTime": 1630818011440,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "70bdbc57-1913-4855-86c4-35e07ffdb269",
      "rifAggId": "31e1400e-540f-4b7e-a0a0-27869e09de40",
      "alertTime": 1630818011363,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "790a12c0-b396-4c88-a996-fdf0d4ae04c9",
      "rifAggId": "589f3513-a160-4de7-9942-42794a061e91",
      "alertTime": 1630818011025,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "e36647ce-917f-4bf1-88d7-2e7cc07798bb",
      "rifAggId": "1fcbf5cb-1940-40a2-8bf6-7ffdcbc5cdf3",
      "alertTime": 1630731610310,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "89091a91-f761-4f25-a79c-f80cc67e36ee",
      "rifAggId": "9295cc0b-9edb-461e-9cc4-0ae9bab6a1a6",
      "alertTime": 1630731610032,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "01a8c59d-cb88-4c8c-856c-646de1a261bf",
      "rifAggId": "2b712ae5-b2c8-4e97-8f64-f0accc8d4229",
      "alertTime": 1630731609955,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "989116e0-1566-4c59-a570-ba56161d0136",
      "rifAggId": "06a2b7f9-9842-c1b9-dd45-0584ae3a96aa",
      "alertTime": 1630671888699,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 82/2021.",
      "alertType": "ok"
    }, {
      "alertId": "e3858b99-e242-4900-84c5-ce8bcba1130e",
      "rifAggId": "b20f77d4-41f4-4090-aed6-6080b62d2773",
      "alertTime": 1630645208646,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "5ed56c2a-8b9c-45b7-b420-bcd835e17f64",
      "rifAggId": "49f38a94-f2cb-4e32-8e0c-b3bcdb0cb415",
      "alertTime": 1630645208608,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "220fac3a-e1b5-4164-be99-4e0762d7d75f",
      "rifAggId": "a828fa27-edf7-4975-aef7-332e752c0589",
      "alertTime": 1630645208351,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "e1f6f565-e648-4f95-a515-f81c22eeda32",
      "rifAggId": "8a0de4d0-b680-9a60-377b-541154f3b765",
      "alertTime": 1630581053481,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 120/2021.",
      "alertType": "ok"
    }, {
      "alertId": "34a469c7-75e7-43a4-8b58-ff05e94b5783",
      "rifAggId": "6fe475fe-aed0-937c-526b-d2350f58ed65",
      "alertTime": 1630580467864,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 135/2021.",
      "alertType": "ok"
    }, {
      "alertId": "a6429c72-d5a7-4d26-afd2-ee1a81cc8021",
      "rifAggId": "4de49d8a-1778-4cdc-bb6e-4ee391722148",
      "alertTime": 1630558807467,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "e9335df2-10e3-41af-a903-d6969170485a",
      "rifAggId": "bd297d7e-f204-4129-adf2-4ff7cb7bd83e",
      "alertTime": 1630558807296,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "62f69f58-8988-70be-6f98-d75c73934537",
      "rifAggId": "e85cba20-e392-aaa3-a09a-9c9d723ff6b9",
      "alertTime": 1630502864868,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10395' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "80eb4ff4-9879-48e7-a119-9fd3a782005d",
      "rifAggId": "e820c557-b542-2d3e-6302-af1fb35438ab",
      "alertTime": 1630499057647,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 137/2021.",
      "alertType": "ok"
    }, {
      "alertId": "05016087-f2bc-4c87-8f6a-3aebbd130a17",
      "rifAggId": "e820c557-b542-2d3e-6302-af1fb35438ab",
      "alertTime": 1630498670016,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '137/2021'",
      "alertType": "ok"
    }, {
      "alertId": "05e44290-201c-246f-01c5-4305400083f3",
      "rifAggId": "1b920f73-1b68-92c3-35f2-93d9283ee125",
      "alertTime": 1630498557869,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "5fc94c6f-936a-efb1-e284-9985a7987106",
      "rifAggId": "47d63210-a5a5-aff2-2dcf-fc5fc3a7b9e9",
      "alertTime": 1630498464990,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "3d636769-b31a-449e-a1e8-d341a9b0bb1c",
      "rifAggId": "4e5b4182-5ba3-458e-8013-990f23cce147",
      "alertTime": 1630472408253,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "7c490398-4af1-4115-b22a-9cbdbc222ab3",
      "rifAggId": "ab74762e-17ba-4e34-b284-e2ec86cd9f18",
      "alertTime": 1630472407750,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "4506e18a-e3f1-453f-8eab-6a1707bc6aa6",
      "rifAggId": "a4533d2d-6f63-433a-a5ae-0c960674f9fc",
      "alertTime": 1630472407699,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "ff1d540e-7b44-455b-a804-209785e75a9b",
      "rifAggId": "e85cba20-e392-aaa3-a09a-9c9d723ff6b9",
      "alertTime": 1630409296468,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '136/2021'contenente 1 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "e6e3f9f5-33e8-44fb-a50a-c2d15aea79ba",
      "rifAggId": "9f9b42a0-b4e3-4108-9e87-120996c8185e",
      "alertTime": 1630386008302,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "c7ee6362-c6f1-4e56-b970-229d33a61b6a",
      "rifAggId": "8f7d34a2-1a84-4572-ab6b-11f8b6a0fa3c",
      "alertTime": 1630386008072,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "0e6244b9-338d-4458-ab16-572c0db772b7",
      "rifAggId": "1aada7a9-b820-446c-bb4e-d1b8d6249869",
      "alertTime": 1630386007551,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "f3dd80f9-148b-4523-af40-9f1410390a01",
      "rifAggId": "b9111bcc-7151-44f1-bc90-ca6f37b4026e",
      "alertTime": 1630299608278,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "58460a24-878d-4a0d-b33f-3c66173f35ff",
      "rifAggId": "ab3dfa7f-05ef-4551-98be-c204055f057f",
      "alertTime": 1630299608230,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "57124a2e-f8ce-4ba8-819b-4e2b08b329d7",
      "rifAggId": "03ddf363-c9cd-43a7-aa1e-7d409543244c",
      "alertTime": 1630299608170,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "3d25a6eb-945a-4906-a324-36df5e9ae035",
      "rifAggId": "c89794f1-6eff-4dd1-a627-36d0c5d8aaf6",
      "alertTime": 1630213208149,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "b7effec3-6dc1-4437-b5be-2209d31939b8",
      "rifAggId": "2c6d89c7-0204-46d3-b166-28ba97908ec1",
      "alertTime": 1630213208132,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "9eb3ccf4-1737-449e-add9-4a3207f977f1",
      "rifAggId": "a3290f4e-b025-4b33-84d9-a477b512a81f",
      "alertTime": 1630213208121,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "ac824b91-fef2-474f-9177-d0227a7c7002",
      "rifAggId": "6fe475fe-aed0-937c-526b-d2350f58ed65",
      "alertTime": 1630147013297,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '135/2021'",
      "alertType": "ok"
    }, {
      "alertId": "869d9629-b68d-ec4d-812e-f3895927ddc5",
      "rifAggId": "251a5c7a-80c6-9cb8-d0da-c42bbb67328f",
      "alertTime": 1630131857043,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA000CUO_10393' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "042e34ed-05db-5d07-66a9-60e80eb27113",
      "rifAggId": "251a5c7a-80c6-9cb8-d0da-c42bbb67328f",
      "alertTime": 1630131826004,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA000CUO_10393' completata",
      "alertType": "ok"
    }, {
      "alertId": "e0f23158-7500-44f3-b5b4-2b84362fcdf0",
      "rifAggId": "251a5c7a-80c6-9cb8-d0da-c42bbb67328f",
      "alertTime": 1630131618519,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '134/2021'",
      "alertType": "ok"
    }, {
      "alertId": "4302285b-54b9-5d60-9e47-f08d1b56c499",
      "rifAggId": "6d4b8969-9d67-06dc-0bfa-023ae24779cd",
      "alertTime": 1630131605829,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "c08b9f75-1c56-d07b-b091-882b11148a85",
      "rifAggId": "5216a1bc-a2e5-ae21-f307-6fab278eb916",
      "alertTime": 1630131571334,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "7d8848c7-ad4b-6e1b-996f-afe01e6d25fc",
      "rifAggId": "da5a9337-37a2-3602-e4fb-3fd926e5b12f",
      "alertTime": 1630130589514,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "f46dfa28-f771-4496-b072-8d6d111744b9",
      "rifAggId": "96ed4fe5-38c0-43b1-8e53-19a0a2cdb3cf",
      "alertTime": 1630126810372,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "12adee74-34c8-4180-a850-a9cdc47e1a8a",
      "rifAggId": "bc0748cd-fd59-48cf-935b-a0fdca024ac3",
      "alertTime": 1630126808081,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "376bbe76-eec7-4881-b403-d307f030523b",
      "rifAggId": "1d53bd05-d6a1-45d8-842c-78cadbc0f896",
      "alertTime": 1630126808000,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "d0fa43f9-f10b-4033-8d7b-548f077702a2",
      "rifAggId": "6148199b-a7f9-5a6b-d9e7-3c8e4a2c6a41",
      "alertTime": 1630076578714,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 132/2021.",
      "alertType": "ok"
    }, {
      "alertId": "d4d498bd-aefd-4d13-a91c-e1a79fbcf7f3",
      "rifAggId": "fc500137-4164-4e48-a353-dfe436ae07e6",
      "alertTime": 1630040407692,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "e477b5fd-29eb-4281-9adf-5d504e294505",
      "rifAggId": "bbb6b8bd-ea22-48c6-8b27-23b0ea60a79e",
      "alertTime": 1630040407533,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "d0aacf47-b5f6-4ff2-8b9d-5826885aa812",
      "rifAggId": "4c58d309-8bc4-47e7-af68-7d54b05e9b66",
      "alertTime": 1630040407400,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "d6de6c93-af8d-b03c-ff46-dde7606eb18e",
      "rifAggId": "097904ca-42f1-589d-2a4b-49ccceb3ef23",
      "alertTime": 1630029066099,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10455' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "f317e9ea-3b3d-c44c-7d61-0bc6e54bb342",
      "rifAggId": "097904ca-42f1-589d-2a4b-49ccceb3ef23",
      "alertTime": 1630028710619,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10455' completata",
      "alertType": "ok"
    }, {
      "alertId": "58753f12-3aa6-4921-a929-03764a4d0fea",
      "rifAggId": "097904ca-42f1-589d-2a4b-49ccceb3ef23",
      "alertTime": 1630028410428,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '133/2021'",
      "alertType": "ok"
    }, {
      "alertId": "85ef6ea5-2f65-4e2e-8bb6-514914ec23ef",
      "rifAggId": "f66b629e-7a9f-44d3-90de-1d68dafd1ea4",
      "alertTime": 1629954006597,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "95785e26-db2d-4be3-8a02-9d60300c6286",
      "rifAggId": "d3bf2baa-25e5-434b-a6a8-c4c93d9f8187",
      "alertTime": 1629954006471,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "b6daa9ca-652f-440c-84f4-928900e5d6b0",
      "rifAggId": "15ccde5a-6b60-43f2-b6ee-bf7c5ec89492",
      "alertTime": 1629954006407,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "35bfcb79-0e7e-4b3c-9acf-ad86b1714147",
      "rifAggId": "6148199b-a7f9-5a6b-d9e7-3c8e4a2c6a41",
      "alertTime": 1629889790072,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '132/2021'",
      "alertType": "ok"
    }, {
      "alertId": "f40b8f08-e05e-0fe5-bdc1-c5644941a345",
      "rifAggId": "c8e67fc1-4a8b-b8c1-7569-ad2f001b2a0e",
      "alertTime": 1629889731320,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "b82f4eb7-72f9-74f8-a3b8-b5c40c01d872",
      "rifAggId": "333b3243-0c69-14a8-59a4-c731946839b1",
      "alertTime": 1629889650781,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "2ca1cd2f-1e03-2e0a-e831-d545f6b095ca",
      "rifAggId": "14a17d41-3cde-b569-bcd1-e1b150f5fdef",
      "alertTime": 1629888591026,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10450' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "04fec50f-fd02-098d-bf99-96c58f842a79",
      "rifAggId": "14a17d41-3cde-b569-bcd1-e1b150f5fdef",
      "alertTime": 1629888466692,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10450' completata",
      "alertType": "ok"
    }, {
      "alertId": "1ef1d7c2-0092-4d8e-ba11-f2a6a9d4ae26",
      "rifAggId": "14a17d41-3cde-b569-bcd1-e1b150f5fdef",
      "alertTime": 1629888350687,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '131/2021'",
      "alertType": "ok"
    }, {
      "alertId": "3658b435-d4f3-4ed9-99c2-7f39a75b1f0d",
      "rifAggId": "a7f004b6-36e2-4c56-8982-05c1eb467c03",
      "alertTime": 1629867608368,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "b07b344b-2957-46e4-8e9e-13cde5576146",
      "rifAggId": "866ae398-36be-4970-8978-684d0d28a1d5",
      "alertTime": 1629867606039,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "16041e49-4583-4e2d-9d5e-fc3a1a7add90",
      "rifAggId": "511fe91a-02b7-4885-878d-8ba86d13b07d",
      "alertTime": 1629867605828,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:18",
      "alertType": "ok"
    }, {
      "alertId": "aa7a554b-151e-41a0-9851-77fb3ca11b7d",
      "rifAggId": "40caf733-ee94-4ac3-8045-99a6844d2cee",
      "alertTime": 1629781207372,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:17",
      "alertType": "ok"
    }, {
      "alertId": "d577452d-c318-457b-a1ed-c4a83875b540",
      "rifAggId": "37d5b321-78fb-4823-a943-ff6b4ffb6e01",
      "alertTime": 1629781207327,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:17",
      "alertType": "ok"
    }, {
      "alertId": "9b0574fc-fc03-4ee5-9760-54a27b92a96f",
      "rifAggId": "a05b7a5c-25fb-4d3c-bd0a-775e190ece63",
      "alertTime": 1629781207291,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:17",
      "alertType": "ok"
    }, {
      "alertId": "3c65d209-9f5c-40b7-a905-e5cc2eae8a9d",
      "rifAggId": "6ad1a329-d1cc-b578-1228-83b98facedac",
      "alertTime": 1629727937497,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 130/2021.",
      "alertType": "ok"
    }, {
      "alertId": "7cd48699-8a3c-4cde-b898-51a0ff9e5127",
      "rifAggId": "6ad1a329-d1cc-b578-1228-83b98facedac",
      "alertTime": 1629727903246,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '130/2021'",
      "alertType": "ok"
    }, {
      "alertId": "d3882852-efff-a713-975f-5d5d2dabcc79",
      "rifAggId": "b6bd2b88-a93f-3e8e-2f05-2f61d0a4da4d",
      "alertTime": 1629727876237,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "0b7b9728-a600-96a4-02db-8f73824a97ef",
      "rifAggId": "16eeec50-3f82-800c-9b00-5306ac3798b6",
      "alertTime": 1629709546340,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10379' completata",
      "alertType": "ok"
    }, {
      "alertId": "9e00e69b-6abd-497f-999d-d299993d244f",
      "rifAggId": "16eeec50-3f82-800c-9b00-5306ac3798b6",
      "alertTime": 1629709509770,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '129/2021'",
      "alertType": "ok"
    }, {
      "alertId": "6547249d-aafa-0b2d-e12c-51b109bb9953",
      "rifAggId": "685c6e96-d88c-c981-5356-d30573fee8ef",
      "alertTime": 1629708422036,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0005UO_10379' completata",
      "alertType": "ok"
    }, {
      "alertId": "5fc3d602-8ce3-454f-bc03-f556117d5b73",
      "rifAggId": "685c6e96-d88c-c981-5356-d30573fee8ef",
      "alertTime": 1629708271741,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '128/2021'",
      "alertType": "ok"
    }, {
      "alertId": "ac492b80-c69d-4a50-ae16-435add91ff9d",
      "rifAggId": "a02797e8-6e24-1b91-52b4-721bcde5de61",
      "alertTime": 1629708008153,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 127/2021.",
      "alertType": "ok"
    }, {
      "alertId": "29fc7551-9b21-d59c-bcfe-3c1520bc1ba7",
      "rifAggId": "ae3c67ab-38bb-7f72-8f0e-f63cb0928aca",
      "alertTime": 1629707915382,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0003UO_10379' completata",
      "alertType": "ok"
    }, {
      "alertId": "1b8bb7f5-9ece-4b5f-8564-16422c5cb263",
      "rifAggId": "a02797e8-6e24-1b91-52b4-721bcde5de61",
      "alertTime": 1629707740757,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '127/2021'",
      "alertType": "ok"
    }, {
      "alertId": "a8741a29-6c1f-4892-b583-0e03e4d9f6b4",
      "rifAggId": "ae3c67ab-38bb-7f72-8f0e-f63cb0928aca",
      "alertTime": 1629707710327,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '126/2021'",
      "alertType": "ok"
    }, {
      "alertId": "8fab3fd3-edf4-4183-9876-b619aac9806d",
      "rifAggId": "d650533a-a88f-4012-90ab-fb2d3122d5bb",
      "alertTime": 1629695091307,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "0877aad0-e1ba-49ff-88c0-904dafdad50b",
      "rifAggId": "5b4712fa-98b3-445f-a4af-16d22b37a885",
      "alertTime": 1629695091143,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "95822ce8-9568-4459-9924-442c1a15f2f9",
      "rifAggId": "9a23e483-bb96-4ab2-883b-1ae75eb9fbaa",
      "alertTime": 1629695090985,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "310eb6d3-7c7e-4eb9-8c67-7413e30c3265",
      "rifAggId": "6875aa46-49f2-4fef-9ec6-5d46e9634e9e",
      "alertTime": 1629608407286,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "18a12ecd-f9f0-41a7-9cd2-22640591b555",
      "rifAggId": "66810a22-8b55-442e-a12a-07b1103fd12b",
      "alertTime": 1629608407127,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "14d79673-1d91-48e2-a91f-291b59684b22",
      "rifAggId": "e067aa6f-de94-41da-b55b-51833fe1309f",
      "alertTime": 1629608406889,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "5106e4a8-c02c-aa1e-a5ca-32e3396c067a",
      "rifAggId": "0ad6f5c1-ba05-7eca-266f-a9d72cd307ef",
      "alertTime": 1629591344395,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0004UO_10488' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "5ba048e0-3c6a-785e-a2fd-6cdb1c451ffd",
      "rifAggId": "0ad6f5c1-ba05-7eca-266f-a9d72cd307ef",
      "alertTime": 1629591312399,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0004UO_10488' completata",
      "alertType": "ok"
    }, {
      "alertId": "d4a2c6af-adf2-4f3c-ae93-7daf0e37f54e",
      "rifAggId": "0ad6f5c1-ba05-7eca-266f-a9d72cd307ef",
      "alertTime": 1629591289792,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '125/2021'",
      "alertType": "ok"
    }, {
      "alertId": "51c5c6c5-b24b-43a1-ed86-e2f49bdd9db0",
      "rifAggId": "afc26147-985d-b43f-3c5e-8c8f1783e68a",
      "alertTime": 1629538435646,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0009UO_10399' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "3ae17243-1401-4982-b0b0-225d4c464bb5",
      "rifAggId": "701d3387-b5b3-44e6-917c-0b14928589da",
      "alertTime": 1629522007412,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "f7ccda01-5dcb-4acd-9022-7f1255184b20",
      "rifAggId": "02dc6e5a-013f-4792-918b-208d048a40a3",
      "alertTime": 1629522007348,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "0e9d9021-8d18-49ce-b990-36c7cdfce2b2",
      "rifAggId": "dc8b723e-1311-47fb-a4ec-ecbacf155c67",
      "alertTime": 1629522007195,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "f1d18d4f-c6c3-dade-2fc5-f0f4a471b65d",
      "rifAggId": "5c62e64e-3885-0996-a34a-3b9d8ae39650",
      "alertTime": 1629471338666,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10430' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "2403759b-8ead-f2f0-307e-2182ac1f6ec0",
      "rifAggId": "5c62e64e-3885-0996-a34a-3b9d8ae39650",
      "alertTime": 1629470718930,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione delle spedizioni al lotto non possibile: Non e' stato possibile associare le spedizioni al Lotto. Nessuna spedizione disponibile trovata con i parametri impostati",
      "alertType": "error"
    }, {
      "alertId": "80e2072a-1ba5-2d55-2c36-2612c149af13",
      "rifAggId": "5c62e64e-3885-0996-a34a-3b9d8ae39650",
      "alertTime": 1629470654767,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0003UO_10430' completata",
      "alertType": "ok"
    }, {
      "alertId": "6c0d75e7-c598-4bb1-8188-9acff500fde0",
      "rifAggId": "5c62e64e-3885-0996-a34a-3b9d8ae39650",
      "alertTime": 1629470580800,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '124/2021'",
      "alertType": "ok"
    }, {
      "alertId": "cbb7adc1-18d3-42ca-a1b8-79185c7abac6",
      "rifAggId": "2ac2b635-3b51-4766-adf2-6b8ad355ef17",
      "alertTime": 1629435607029,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "a1310a04-432a-4326-811a-19f24cf7b00b",
      "rifAggId": "fa558686-f2ce-411d-b563-2fefc536a192",
      "alertTime": 1629435606795,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "fc640d69-00d6-4b7b-806c-2e77ccfac366",
      "rifAggId": "dfd40910-5ca0-4edf-9890-908dcebf34b6",
      "alertTime": 1629435606386,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:15",
      "alertType": "ok"
    }, {
      "alertId": "0496acec-aae2-4b9d-8ad6-c66aafb77667",
      "rifAggId": "c1e12e02-4159-4188-b3bd-ae02ce048781",
      "alertTime": 1629378289204,
      "tipoAggregato": "bollettino_txt",
      "messaggio": "È completata l'acquisizione di 222 rilevazioni di tipo bollettino_txt",
      "alertType": "ok"
    }, {
      "alertId": "f697cd75-b9ad-5859-61d9-6bd15a1bfece",
      "rifAggId": "afc26147-985d-b43f-3c5e-8c8f1783e68a",
      "alertTime": 1629360226685,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0009UO_10399' completata",
      "alertType": "ok"
    }, {
      "alertId": "300faae0-c1db-4f85-8971-6a3703025b2f",
      "rifAggId": "606e53d7-2a2f-43b5-89ef-c4ed52e8ea1d",
      "alertTime": 1629349205365,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "2fc30eed-d350-4705-9000-e75cae2275dc",
      "rifAggId": "d7b3ee6a-e602-4816-bc0c-ae6606fa2b8b",
      "alertTime": 1629349205030,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "14d1e75a-884e-42c4-baf9-a2baed6bfd9c",
      "rifAggId": "f70e0d1f-e365-496c-9bbc-5651693335a2",
      "alertTime": 1629349204902,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:6",
      "alertType": "ok"
    }, {
      "alertId": "fac885f0-b28a-cc07-88fb-4ce077630024",
      "rifAggId": "6792a086-d0c9-7cb8-ae36-d3b2518c5cf3",
      "alertTime": 1629302649319,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0006UO_10441' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "a45d5ca7-fb14-438f-a4e8-62f4f2a706a9",
      "rifAggId": "022def4b-be24-02df-748b-58f3f81d11c1",
      "alertTime": 1629294738915,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 123/2021.",
      "alertType": "ok"
    }, {
      "alertId": "3da883f1-3612-fd26-5d73-dc9906bd4fb8",
      "rifAggId": "3c1f6033-1577-fdcb-4a22-d7828315ae97",
      "alertTime": 1629294711492,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0005UO_10409' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "6ca97a05-0ed1-45cc-8a1d-d4d4a0f60681",
      "rifAggId": "022def4b-be24-02df-748b-58f3f81d11c1",
      "alertTime": 1629294621910,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '123/2021'",
      "alertType": "ok"
    }, {
      "alertId": "7708a1e6-f831-472d-b8ba-86bc212e4988",
      "rifAggId": "3c1f6033-1577-fdcb-4a22-d7828315ae97",
      "alertTime": 1629294603277,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '122/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "7702030e-93ea-d759-7bfe-22537b3c420f",
      "rifAggId": "bb8271b8-1c5b-6e6d-15da-5900559c792b",
      "alertTime": 1629272696925,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10345' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "adb430c7-c5cf-4147-8376-f3be480e0714",
      "rifAggId": "bb8271b8-1c5b-6e6d-15da-5900559c792b",
      "alertTime": 1629272569591,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '121/2021'contenente 1 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "bab801d7-8007-42d8-b9ef-631d71afcb76",
      "rifAggId": "a66ca38d-644c-4b25-8879-2a8c7e03281a",
      "alertTime": 1629262804051,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "8d381fc5-330d-4201-8a4f-dd845ae0b39d",
      "rifAggId": "3d917a9c-8d92-490b-b62d-9e553d1723d0",
      "alertTime": 1629262804029,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "b260f828-4c5b-4087-8b82-93b192733206",
      "rifAggId": "3ff2334e-e420-4b7a-a2b2-3cb5804f6ca1",
      "alertTime": 1629262803362,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "39cf97e9-8e3c-4a93-8976-60fc2f090d6b",
      "rifAggId": "8a0de4d0-b680-9a60-377b-541154f3b765",
      "alertTime": 1629208121445,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '120/2021'",
      "alertType": "ok"
    }, {
      "alertId": "990c08fa-0507-aa0e-7c49-c25738a776a6",
      "rifAggId": "281d3721-da5c-02ff-df03-45c9ebdb9732",
      "alertTime": 1629207886385,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "ad533992-51cc-0324-b6e2-4c0316103e35",
      "rifAggId": "6792a086-d0c9-7cb8-ae36-d3b2518c5cf3",
      "alertTime": 1629205230441,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10441' completata",
      "alertType": "ok"
    }, {
      "alertId": "20cb382f-7d9f-f61f-7a87-e44db9ddbe55",
      "rifAggId": "6792a086-d0c9-7cb8-ae36-d3b2518c5cf3",
      "alertTime": 1629205218362,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10441' completata",
      "alertType": "ok"
    }, {
      "alertId": "a2a6850c-8a1f-478c-58a9-ea7a55bec669",
      "rifAggId": "6792a086-d0c9-7cb8-ae36-d3b2518c5cf3",
      "alertTime": 1629205030009,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10441' completata",
      "alertType": "ok"
    }, {
      "alertId": "04b5ac7a-4cde-ea2f-5954-988e29be5498",
      "rifAggId": "6792a086-d0c9-7cb8-ae36-d3b2518c5cf3",
      "alertTime": 1629205021422,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10441' completata",
      "alertType": "ok"
    }, {
      "alertId": "29b7b9c2-0aa1-4e20-8d2e-8ebc9dd9ae02",
      "rifAggId": "6792a086-d0c9-7cb8-ae36-d3b2518c5cf3",
      "alertTime": 1629204074529,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '119/2021'",
      "alertType": "ok"
    }, {
      "alertId": "c0edd159-093a-48e3-9e5d-0fa0f5572870",
      "rifAggId": "ee5b902d-ebb0-b8f6-6da3-4d3d7745328c",
      "alertTime": 1629203754141,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 118/2021.",
      "alertType": "ok"
    }, {
      "alertId": "32af21aa-8d20-4096-bb92-022a474ca87e",
      "rifAggId": "ee5b902d-ebb0-b8f6-6da3-4d3d7745328c",
      "alertTime": 1629203678261,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '118/2021'",
      "alertType": "ok"
    }, {
      "alertId": "285cad35-ef40-4fd1-8f53-c3c4268c107f",
      "rifAggId": "7b73a372-f5af-f093-66d7-6f44cdda601a",
      "alertTime": 1629202425162,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 110/2021.",
      "alertType": "ok"
    }, {
      "alertId": "33c88658-6093-4c0d-9942-b5fe0bf130ba",
      "rifAggId": "afc26147-985d-b43f-3c5e-8c8f1783e68a",
      "alertTime": 1629197944695,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '117/2021'",
      "alertType": "ok"
    }, {
      "alertId": "0df9b415-f02a-4a52-bcd9-335a3a5a5b52",
      "rifAggId": "b878e99a-caa0-4cb1-ba51-5c6fa2741534",
      "alertTime": 1629176435492,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "17f60a64-6cc0-49da-a9e6-1b992dce9255",
      "rifAggId": "0e9a4612-8ea3-4d96-be5d-31cc5010bd67",
      "alertTime": 1629176435433,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "098f0a56-dfc7-4e00-a522-ce7b71f8302b",
      "rifAggId": "62fbba80-24e1-4dff-ade8-7911b9568f96",
      "alertTime": 1629176435432,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "a9e1486d-b05c-a57d-f5e3-73c2f899571d",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1629122689856,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "2e4e6282-87f3-48b7-990b-8c1c42bb7da6",
      "rifAggId": "88ccf9df-cf06-4f6e-8afd-6c72fd4a270f",
      "alertTime": 1629090003367,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "d77a5b18-e4ed-4464-a128-13fa880de1b9",
      "rifAggId": "b096d784-e7c7-4ab1-8f9a-62ee0a2cfcaa",
      "alertTime": 1629090003342,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "25a26097-b9c9-4eea-a27c-2856e6d54a99",
      "rifAggId": "d8881176-b619-41eb-9711-93d56937f89d",
      "alertTime": 1629090003329,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "5bbe354e-a033-4b7a-84a1-b7852045c581",
      "rifAggId": "8b48e797-edfe-c4d8-2533-58a3c39ba770",
      "alertTime": 1629044486780,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 116/2021.",
      "alertType": "ok"
    }, {
      "alertId": "6fe3c056-609d-43d6-aa80-e0adf91f7735",
      "rifAggId": "8b48e797-edfe-c4d8-2533-58a3c39ba770",
      "alertTime": 1629044470620,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '116/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "403e5b19-6217-4fcb-9cde-721d8f08546e",
      "rifAggId": "4de7d671-7a6e-1284-70ed-7115a34d02fc",
      "alertTime": 1629044450612,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 115/2021.",
      "alertType": "ok"
    }, {
      "alertId": "426d3b62-234e-45c7-b158-1d0cd08e1135",
      "rifAggId": "4de7d671-7a6e-1284-70ed-7115a34d02fc",
      "alertTime": 1629044380602,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '115/2021'",
      "alertType": "ok"
    }, {
      "alertId": "f89b0c88-5695-0d22-4b66-1d3f720d09ea",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1629005486466,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "95a020ce-099d-fcaf-a033-2d72ef28d18f",
      "rifAggId": "3cffb1f7-b986-3c74-7c81-c1d4be54dcef",
      "alertTime": 1629005367539,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10783' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "f1f6eea8-1e38-2385-20b4-7abb2447bb2f",
      "rifAggId": "3cffb1f7-b986-3c74-7c81-c1d4be54dcef",
      "alertTime": 1629005248944,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0003UO_10783' completata",
      "alertType": "ok"
    }, {
      "alertId": "f8e401d1-e86e-8072-bb13-fdc671d0d759",
      "rifAggId": "3cffb1f7-b986-3c74-7c81-c1d4be54dcef",
      "alertTime": 1629004882209,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0003UO_10783' completata",
      "alertType": "ok"
    }, {
      "alertId": "bdb1ad12-9947-ccac-85b7-4d6785238c06",
      "rifAggId": "3cffb1f7-b986-3c74-7c81-c1d4be54dcef",
      "alertTime": 1629004853784,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0003UO_10783' completata",
      "alertType": "ok"
    }, {
      "alertId": "1c1d1119-1288-4e6c-bb8a-5aa28ee67758",
      "rifAggId": "3cffb1f7-b986-3c74-7c81-c1d4be54dcef",
      "alertTime": 1629004775087,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '114/2021'",
      "alertType": "ok"
    }, {
      "alertId": "f619e552-3371-478b-a057-db1ccf492e77",
      "rifAggId": "15d046e0-7a15-468f-84d5-94897b841834",
      "alertTime": 1629003635856,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "4cf865b6-40e9-4e4a-b39f-8183b489774f",
      "rifAggId": "5dec66f9-bd8a-4792-b098-215fac059cd0",
      "alertTime": 1629003635772,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "e9e06272-76f7-42f4-8ca8-b5819c7f92dc",
      "rifAggId": "dd795d56-90f8-462d-9616-3c89453331b5",
      "alertTime": 1629003635745,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "04dac6ce-26ac-4038-bc05-33d51b6d0d49",
      "rifAggId": "d24821bc-5a38-4c15-b91e-bb4049d45f37",
      "alertTime": 1628917203406,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "14598824-4d23-4133-9a6b-b32e20fb9fb4",
      "rifAggId": "85b8c594-96a0-4305-87d2-b67699a64260",
      "alertTime": 1628917203387,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "f60cc7f1-2ff6-47f0-a140-022c9feb7112",
      "rifAggId": "817ea540-4c66-4b05-b9c9-c6f926d599ca",
      "alertTime": 1628917203269,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "515a4259-5441-467d-864e-a69e61529116",
      "rifAggId": "1ef2e7de-b18a-4dbd-b0c4-b19c4e3511e3",
      "alertTime": 1628830803654,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "8e3408a8-8d3e-4113-908c-9097599d9386",
      "rifAggId": "9c98a697-0f36-4897-9f06-6defcac7a98b",
      "alertTime": 1628830803637,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "57d72093-f285-47a4-8380-21bafe707b7e",
      "rifAggId": "a1b3724e-c408-42f5-afe7-db25b05d64b6",
      "alertTime": 1628830803632,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "157f633e-39f7-59a8-bb33-ecd1fa445c1e",
      "rifAggId": "b99b2311-d2e9-55b0-8ad6-40a8054ec57c",
      "alertTime": 1628779254889,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "7b0f77b1-80f7-450c-933c-5808bc65ea4c",
      "rifAggId": "3969b675-8a27-4482-ac22-07b0454a9720",
      "alertTime": 1628744403480,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "30cfe926-0fd9-4b81-86a6-0e0b7ebc44c3",
      "rifAggId": "ae9f9e96-a2eb-4321-9feb-63da30fb25cc",
      "alertTime": 1628744403475,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "4542c8bb-013f-4071-b636-1b17fbe8b21c",
      "rifAggId": "2c1670ea-547b-440d-9637-f272512acd90",
      "alertTime": 1628744402977,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "a75012be-ba7d-81c8-20f0-7b0c6de0fca9",
      "rifAggId": "adccfa4d-eaf8-7540-28bf-626fadd45b7f",
      "alertTime": 1628665336230,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10394' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "35f6db3e-665e-4372-965b-d0ec5cd73fcb",
      "rifAggId": "adccfa4d-eaf8-7540-28bf-626fadd45b7f",
      "alertTime": 1628665167224,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '113/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "7a2a0a93-f917-4277-a8f7-a2f370c26932",
      "rifAggId": "0837ae5e-cceb-4726-a19c-da5bc18b5c5d",
      "alertTime": 1628658003542,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "550fca85-8793-4fdb-b2e3-27a438436a40",
      "rifAggId": "89791b39-da0c-4745-a80a-63c2841b6bf0",
      "alertTime": 1628658003476,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "3d97866e-b74f-4cf0-a2d7-9012006279ff",
      "rifAggId": "772dbc31-20c8-4e5e-9a3c-1097740fc042",
      "alertTime": 1628658003475,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "2315f2d7-8543-0c9f-84ac-2584f29f7e39",
      "rifAggId": "89f52af0-e2f9-7ffb-49f9-6fe351836cc6",
      "alertTime": 1628607760564,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "10fe0faa-d2c8-4901-a055-3a4aaf32bc96",
      "rifAggId": "aaf55539-dfd8-4c1b-bebf-91f894d3851f",
      "alertTime": 1628571603303,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "0c292e8b-3f6a-44e7-a36c-dfec1f4d4be4",
      "rifAggId": "31ae1eaa-ba94-46ea-a5e1-acf353f9e134",
      "alertTime": 1628571603219,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "8538434c-106b-4a49-9f85-f2b3f27d643d",
      "rifAggId": "b5f9cf26-1f97-41d4-b297-0c57cf11c675",
      "alertTime": 1628571603172,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "d3b20c2a-e5f3-4944-a625-4088ba47da40",
      "rifAggId": "aa01811a-daea-b487-3c93-288fdac459cc",
      "alertTime": 1628559376242,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 112/2021.",
      "alertType": "ok"
    }, {
      "alertId": "f3575ad3-cefb-430a-b12c-d1c86de9e75b",
      "rifAggId": "aa01811a-daea-b487-3c93-288fdac459cc",
      "alertTime": 1628559258682,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '112/2021'",
      "alertType": "ok"
    }, {
      "alertId": "cc34034e-d7ab-4bf5-9b93-630157d22fd6",
      "rifAggId": "b0384fe2-c46f-9448-a646-d07555f9030a",
      "alertTime": 1628558835477,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 111/2021.",
      "alertType": "ok"
    }, {
      "alertId": "4a5029cb-07b3-41f6-8157-14c5f236051b",
      "rifAggId": "b0384fe2-c46f-9448-a646-d07555f9030a",
      "alertTime": 1628558725347,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '111/2021'",
      "alertType": "ok"
    }, {
      "alertId": "47f38eaa-d767-212b-4fa3-6a70e8d17b0f",
      "rifAggId": "7b73a372-f5af-f093-66d7-6f44cdda601a",
      "alertTime": 1628514067667,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0005UO_10441' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "06bb7979-4caa-b185-3409-3a714449ca4f",
      "rifAggId": "7b73a372-f5af-f093-66d7-6f44cdda601a",
      "alertTime": 1628513919041,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0005UO_10441' completata",
      "alertType": "ok"
    }, {
      "alertId": "76299681-cc98-4d11-9828-dcdf4119b25b",
      "rifAggId": "7b73a372-f5af-f093-66d7-6f44cdda601a",
      "alertTime": 1628513853438,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '110/2021'",
      "alertType": "ok"
    }, {
      "alertId": "48f4413d-5f79-4a1c-a41d-6193141b74bb",
      "rifAggId": "4002e918-e6af-13a5-04ad-bc502121195a",
      "alertTime": 1628513793476,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 94/2021.",
      "alertType": "ok"
    }, {
      "alertId": "21e68ef5-1d43-4194-aa5a-48555bf9108a",
      "rifAggId": "37b155c2-d9b4-beea-f8ed-0f0c67f7a6a5",
      "alertTime": 1628513777700,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 98/2021.",
      "alertType": "ok"
    }, {
      "alertId": "f3948c29-6eb8-40d4-9bca-24e1b4ed106c",
      "rifAggId": "43bbfd87-42b4-4e79-9fec-3857f7d21da5",
      "alertTime": 1628485203503,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "ff569af0-757b-4cd0-a5c9-c9d30947c220",
      "rifAggId": "aac53520-c3ee-4823-89da-522830596a95",
      "alertTime": 1628485203436,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "84c81b70-957d-4dbf-9c36-36dc1ce24bdf",
      "rifAggId": "0053c1d3-426e-4afc-abc8-b591efec6952",
      "alertTime": 1628485203403,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "2084d63f-f0b0-42d4-b6c5-b5047af6e578",
      "rifAggId": "24481964-c975-e84e-29a8-5cb8f99830ba",
      "alertTime": 1628430322729,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 109/2021.",
      "alertType": "ok"
    }, {
      "alertId": "fb9ed996-61f7-4a3c-a466-ffa33b718081",
      "rifAggId": "24481964-c975-e84e-29a8-5cb8f99830ba",
      "alertTime": 1628430195582,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '109/2021'",
      "alertType": "ok"
    }, {
      "alertId": "8f17bc56-6c8a-fc88-290c-8ca971249fef",
      "rifAggId": "5aa01a8a-936e-535e-4293-246628686106",
      "alertTime": 1628430111467,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "715bd430-4b6f-2da2-a104-0160ab87be4a",
      "rifAggId": "864922e7-0094-d692-d328-64970e3b38b8",
      "alertTime": 1628429898341,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "75e52ae5-759d-119f-e692-2c3c73cac902",
      "rifAggId": "9882c6d4-5bc0-df45-1032-c607f7048c13",
      "alertTime": 1628429852634,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "9392b5e3-84ba-4f87-b7a2-b9380f4854e5",
      "rifAggId": "f3590bf8-030f-6135-f806-c13fe4e95e62",
      "alertTime": 1628429770283,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "ff642b6a-a68a-4bcc-a4bb-339786631fe2",
      "rifAggId": "6debc507-434a-d4b3-0854-6b468b86fce0",
      "alertTime": 1628422415226,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 107/2021.",
      "alertType": "ok"
    }, {
      "alertId": "85527294-6b44-9528-a079-e5be72e30ac7",
      "rifAggId": "aa376cc2-52aa-4e4d-bcbf-cfc8219dd7fe",
      "alertTime": 1628410125148,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10478' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "6fe70318-3c7d-46a3-6077-699791beb740",
      "rifAggId": "aa376cc2-52aa-4e4d-bcbf-cfc8219dd7fe",
      "alertTime": 1628409974508,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0003UO_10478' completata",
      "alertType": "ok"
    }, {
      "alertId": "ab5d641f-d47b-4de1-9d3a-d01bb3743c1e",
      "rifAggId": "aa376cc2-52aa-4e4d-bcbf-cfc8219dd7fe",
      "alertTime": 1628409592519,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '108/2021'",
      "alertType": "ok"
    }, {
      "alertId": "c5b8419a-6d3a-5371-b878-cb387269cf6c",
      "rifAggId": "4197a38f-cece-a76d-868b-547692036354",
      "alertTime": 1628409565964,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "2c63832b-e07d-0cf3-9015-a04589e9a805",
      "rifAggId": "0d7942a9-d531-5a2d-4f85-beab0374145f",
      "alertTime": 1628409173032,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "3de85384-cbd8-43d1-93ee-481787584142",
      "rifAggId": "6debc507-434a-d4b3-0854-6b468b86fce0",
      "alertTime": 1628408647716,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '107/2021'",
      "alertType": "ok"
    }, {
      "alertId": "3c7887d9-dde0-46db-a3bd-94282fcf0535",
      "rifAggId": "13bbe9a2-a786-313e-59bd-051a4c78e777",
      "alertTime": 1628408632975,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 106/2021.",
      "alertType": "ok"
    }, {
      "alertId": "d1a47fd1-deb9-4831-9ab2-cd61c946831c",
      "rifAggId": "13bbe9a2-a786-313e-59bd-051a4c78e777",
      "alertTime": 1628408567065,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '106/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "702587fb-ff63-4cf9-8d3e-aa699f669345",
      "rifAggId": "f1d06285-c262-5840-e37c-a66a63a75075",
      "alertTime": 1628408553303,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 105/2021.",
      "alertType": "ok"
    }, {
      "alertId": "47ede23f-fdb8-435f-8f97-0cfcfcb13a5a",
      "rifAggId": "f1d06285-c262-5840-e37c-a66a63a75075",
      "alertTime": 1628408028013,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '105/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "2e613c86-523a-4c2b-912e-8e3a534e7191",
      "rifAggId": "bb113c28-29bf-7a21-13bd-f6a91ac0a202",
      "alertTime": 1628407832871,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 104/2021.",
      "alertType": "ok"
    }, {
      "alertId": "477cafd1-a6be-49a7-819e-7d251c2f30b0",
      "rifAggId": "bb113c28-29bf-7a21-13bd-f6a91ac0a202",
      "alertTime": 1628407771614,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '104/2021'",
      "alertType": "ok"
    }, {
      "alertId": "65db26bd-b4ed-439c-bb51-201037423b29",
      "rifAggId": "0a9090bf-3de2-4bbf-98f7-43ecc3fbdc97",
      "alertTime": 1628398803109,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "aa5a6d62-41a7-4417-8391-b09947088d5e",
      "rifAggId": "e4b6ee8b-e01e-4352-a596-4ed0580e9c06",
      "alertTime": 1628398803026,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "47bb7aec-eb6f-4ebd-b314-4e5ab35d4ed5",
      "rifAggId": "26085ddc-7db4-4811-ab53-b888d56cef99",
      "alertTime": 1628398802995,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "e568b2aa-3794-7a8b-5e31-8d4840f4912e",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1628358183139,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "e843dd0e-85ca-a69e-29c4-5ec80a0b67bc",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1628357830627,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "369fb55b-07d7-6608-3ebc-0d474772ec17",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1628355388139,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "90d6c1ce-d883-9e83-045e-1e3c48715f58",
      "rifAggId": "c23cafb0-3d77-ad9b-5235-37c18ae31bf1",
      "alertTime": 1628353963798,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "4108890e-d58f-4e99-b203-68278f53f222",
      "rifAggId": "cec8b5f4-ea3f-399a-07bf-c5068576cdc5",
      "alertTime": 1628326962953,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 103/2021.",
      "alertType": "ok"
    }, {
      "alertId": "6e383ed3-de78-462b-8972-f109327b1ff4",
      "rifAggId": "cec8b5f4-ea3f-399a-07bf-c5068576cdc5",
      "alertTime": 1628326933162,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '103/2021'",
      "alertType": "ok"
    }, {
      "alertId": "ea36f96c-e0f8-05bd-536d-1ba0f19321b5",
      "rifAggId": "4e791f99-e54c-869e-ea8a-63af539145bd",
      "alertTime": 1628326885092,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "4ddbde5d-92c1-470b-8906-702304f5744e",
      "rifAggId": "50b2e937-110f-4dea-99db-6bebcefa7b01",
      "alertTime": 1628312403883,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "aab1b53b-a707-4805-a7d7-a2821ab82594",
      "rifAggId": "50ee3f76-b474-42e6-8aaf-b8ac8bb4f6f2",
      "alertTime": 1628312403860,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "f83013ed-58c1-4f64-862c-69399b3b99e6",
      "rifAggId": "943b0152-1fc8-42aa-97f9-42631056c185",
      "alertTime": 1628312403692,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "11e6d818-70db-d77e-5220-4590b74657d6",
      "rifAggId": "af13b990-6f09-f48e-573c-afd06bf4bce1",
      "alertTime": 1628270127036,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10451' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "33b8e568-aa17-3670-6a25-e1b4ef1e9972",
      "rifAggId": "af13b990-6f09-f48e-573c-afd06bf4bce1",
      "alertTime": 1628269441129,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10451' completata",
      "alertType": "ok"
    }, {
      "alertId": "95ccb43b-f370-5bc8-d654-6d767ce7fa2d",
      "rifAggId": "af13b990-6f09-f48e-573c-afd06bf4bce1",
      "alertTime": 1628269401858,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10451' completata",
      "alertType": "ok"
    }, {
      "alertId": "d4a1601a-2a80-8964-1076-803bf73723e7",
      "rifAggId": "af13b990-6f09-f48e-573c-afd06bf4bce1",
      "alertTime": 1628269385321,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10451' completata",
      "alertType": "ok"
    }, {
      "alertId": "de5542c2-1481-1850-30cb-468f6e69a30c",
      "rifAggId": "af13b990-6f09-f48e-573c-afd06bf4bce1",
      "alertTime": 1628269373226,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10451' completata",
      "alertType": "ok"
    }, {
      "alertId": "0ce7eeb8-cd4a-4580-80b0-8e0a278f439e",
      "rifAggId": "af13b990-6f09-f48e-573c-afd06bf4bce1",
      "alertTime": 1628269273896,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '102/2021'",
      "alertType": "ok"
    }, {
      "alertId": "d72f319e-33f4-9fdb-15f3-8062b759eda8",
      "rifAggId": "4f9e28b0-c397-3bba-29d6-813f79d6a093",
      "alertTime": 1628256806771,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0004UO_10410' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "a7b7f3ba-85f2-5ce9-cf7b-0ef31456f363",
      "rifAggId": "4f9e28b0-c397-3bba-29d6-813f79d6a093",
      "alertTime": 1628256765106,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0004UO_10410' completata",
      "alertType": "ok"
    }, {
      "alertId": "353b5a24-618a-6c83-1721-9951bea8ed83",
      "rifAggId": "4f9e28b0-c397-3bba-29d6-813f79d6a093",
      "alertTime": 1628256755983,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0004UO_10410' completata",
      "alertType": "ok"
    }, {
      "alertId": "8eec94de-2d36-4e96-9c7a-f5d3a941f0cd",
      "rifAggId": "1735bbaa-7f22-1b70-4f39-0dcc05988569",
      "alertTime": 1628256731670,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 101/2021.",
      "alertType": "ok"
    }, {
      "alertId": "71f08139-8789-47ed-bce0-6ae1c6b51645",
      "rifAggId": "1735bbaa-7f22-1b70-4f39-0dcc05988569",
      "alertTime": 1628256100379,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '101/2021'contenente 16 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "47dcf43c-7bda-4767-bdfd-5cf66a5cf9f1",
      "rifAggId": "4f9e28b0-c397-3bba-29d6-813f79d6a093",
      "alertTime": 1628255571148,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '100/2021'",
      "alertType": "ok"
    }, {
      "alertId": "2bb85ff2-e752-4502-a0b1-99b8e7b6a557",
      "rifAggId": "98d88fda-c41a-bfdf-410b-7dc1136a88b1",
      "alertTime": 1628244861040,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 99/2021.",
      "alertType": "ok"
    }, {
      "alertId": "5bf16e04-5595-4619-81ad-7771a5a426e7",
      "rifAggId": "98d88fda-c41a-bfdf-410b-7dc1136a88b1",
      "alertTime": 1628244717233,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '99/2021'contenente 6 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "7fa06cf7-1362-4094-847f-60f975c742e3",
      "rifAggId": "37b155c2-d9b4-beea-f8ed-0f0c67f7a6a5",
      "alertTime": 1628231901714,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '98/2021'",
      "alertType": "ok"
    }, {
      "alertId": "68e37129-6a90-4c01-b928-d7e7d8fe74ff",
      "rifAggId": "055f6d64-8e3d-4f64-9e4b-e319f4f32627",
      "alertTime": 1628226005068,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "117c0f75-fb40-4345-be2f-129857d69379",
      "rifAggId": "56a4c9dc-8db1-4042-b6ff-e68bb365c246",
      "alertTime": 1628226005042,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "bbea22e8-eeaf-4d3d-baa1-f80cae1db052",
      "rifAggId": "9bce8dc2-e6cd-49d0-991f-24e0fa708bbc",
      "alertTime": 1628226004928,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "4e1f91d1-7515-2e45-0beb-7ce2baea473c",
      "rifAggId": "7ba5136c-f58b-288a-5651-7a850c074c79",
      "alertTime": 1628168498612,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "4a2d5a97-66fd-01a7-3307-cdf9543131ab",
      "rifAggId": "5924855b-c4d2-7ee7-2cb6-1dffbbbf74c3",
      "alertTime": 1628162073679,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "efd4bd98-f81f-edae-e848-eb8212f0df4f",
      "rifAggId": "28bc382c-1503-99bf-4092-48f05e46960c",
      "alertTime": 1628162060786,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "dc342d9c-e41d-446a-9bfe-1c5c08b2043d",
      "rifAggId": "efcdedfe-9830-d3da-889f-2cc268d30cd6",
      "alertTime": 1628160539754,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 97/2021.",
      "alertType": "ok"
    }, {
      "alertId": "68b58bd3-e8c7-4279-9252-f7794904e62b",
      "rifAggId": "efcdedfe-9830-d3da-889f-2cc268d30cd6",
      "alertTime": 1628160528503,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '97/2021'",
      "alertType": "ok"
    }, {
      "alertId": "2dbb591f-5f8e-e98e-bbd9-26f53fa1768f",
      "rifAggId": "19f222d0-4438-5d53-04f2-492213c63d53",
      "alertTime": 1628160482526,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "9eb62587-ef0d-4edd-b246-aded5d870ea2",
      "rifAggId": "9f7ba19b-ddbb-a12f-3345-66642a69711c",
      "alertTime": 1628160404005,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 96/2021.",
      "alertType": "ok"
    }, {
      "alertId": "d4a7491e-5a71-4e34-bfd6-1d9de5c00992",
      "rifAggId": "9f7ba19b-ddbb-a12f-3345-66642a69711c",
      "alertTime": 1628160379492,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '96/2021'",
      "alertType": "ok"
    }, {
      "alertId": "97c07b7b-4c72-82ac-4964-43985c2e080f",
      "rifAggId": "33996496-6b16-1fa0-20ff-096f62707efc",
      "alertTime": 1628160278389,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "4678c0f8-c9c6-44d8-82ea-6c0e6fa0a4bf",
      "rifAggId": "dc16cdad-7a1e-408d-ad11-edc97aec8970",
      "alertTime": 1628139605788,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "5b85c1e8-aa42-408e-96ee-198505460e07",
      "rifAggId": "fb527d77-d328-48a4-87b8-ea71242cb08a",
      "alertTime": 1628139605684,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "12b789ea-05ff-4ed8-8c80-3dac0609b0b7",
      "rifAggId": "a4b622cc-d2bb-4b1a-829e-5a796e9c2057",
      "alertTime": 1628139600796,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "8ad1d86c-6834-a63f-92d3-6e090600e983",
      "rifAggId": "f4c21d50-f7e8-47eb-6923-5a8ca108ef07",
      "alertTime": 1628088645248,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "3dc3144e-fec7-4f32-bfa7-d02c960a4b18",
      "rifAggId": "3db555c6-3b13-4e83-bb10-e92ae2b83e12",
      "alertTime": 1628053203787,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "19e9692b-f132-4ade-957a-a9629934a1e4",
      "rifAggId": "4a9f8528-94cb-4dea-83da-0c0735fc1711",
      "alertTime": 1628053202765,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "789225a2-ecb7-446c-af81-d22a8ae49143",
      "rifAggId": "ace4663b-0d1a-432b-8c8f-cb405a7246e7",
      "alertTime": 1628053202659,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "7a7ac172-7d28-415f-877d-2d83deab6a9e",
      "rifAggId": "479d7d98-2df8-4d01-af89-733fef8b9010",
      "alertTime": 1627966803679,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "7ec6d61d-f7dc-458c-b0c5-04416e741282",
      "rifAggId": "e7cf1124-9a47-4851-9433-6943c5765aab",
      "alertTime": 1627966803616,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "772e368d-646a-405d-bd5c-4a57e6d1be46",
      "rifAggId": "73084d58-590c-49ac-a7d7-0662e9da938a",
      "alertTime": 1627966803134,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "d717dd48-81cd-44cf-9d34-b92bddc5a3a9",
      "rifAggId": "56a969bc-2b07-4db1-84e1-ea85ae4d75ea",
      "alertTime": 1627880560576,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "bb0c0bbf-ffc1-4416-b285-45181f3fce39",
      "rifAggId": "e3813b1f-4c01-4d9c-a810-38e47a036fa8",
      "alertTime": 1627880559989,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "1e44c296-278f-48f8-b05e-48239b415038",
      "rifAggId": "2aab034b-22a9-4055-bfcc-1bd3467943da",
      "alertTime": 1627880559987,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "bac8225e-5e49-4296-95c6-436c7d2c34d7",
      "rifAggId": "cc4a625f-e559-612f-31ff-41fff49e08cf",
      "alertTime": 1627876344040,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 95/2021.",
      "alertType": "ok"
    }, {
      "alertId": "f945ee69-1afe-4a2a-a1d7-9205278ece57",
      "rifAggId": "cc4a625f-e559-612f-31ff-41fff49e08cf",
      "alertTime": 1627876301957,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '95/2021'",
      "alertType": "ok"
    }, {
      "alertId": "82ff436c-23ec-443e-9a79-ca8e7dccdbce",
      "rifAggId": "4002e918-e6af-13a5-04ad-bc502121195a",
      "alertTime": 1627809914818,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '94/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "6b0050a0-4c9d-aef2-737a-97fe0b9e1c1f",
      "rifAggId": "4512eca0-68b5-399f-8831-da3aad398c97",
      "alertTime": 1627808867508,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0004UO_10350' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "1c6b9162-f2f7-137c-0f02-9e6513e07c83",
      "rifAggId": "4512eca0-68b5-399f-8831-da3aad398c97",
      "alertTime": 1627808640382,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0004UO_10350' completata",
      "alertType": "ok"
    }, {
      "alertId": "8bdebbd7-3a9a-47f2-8c0d-a3c01a7495b9",
      "rifAggId": "4512eca0-68b5-399f-8831-da3aad398c97",
      "alertTime": 1627808558737,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '93/2021'",
      "alertType": "ok"
    }, {
      "alertId": "c6de3587-4a85-d4ba-e871-25aaa32e0bbd",
      "rifAggId": "a716920d-5874-36da-e92e-d7bf4340f004",
      "alertTime": 1627807708806,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "921139c8-dc3f-5f05-40bb-d2e0e05328f0",
      "rifAggId": "b9e7a466-403f-e8b1-5fdc-0193d556a989",
      "alertTime": 1627807657482,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "36e83ac8-76c3-b438-cdda-7802ff6b77fb",
      "rifAggId": "8258e3f2-d1b5-46cb-1334-68dee662185d",
      "alertTime": 1627807613478,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "00831ac8-a9e4-1206-4c5e-dfe90bf9d365",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1627798999527,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "ebc8c71e-61ad-5b9a-f9c7-04632c070e98",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1627798805660,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "3a848c55-e6d2-468b-97d2-5f1e17565d96",
      "rifAggId": "5089e213-5c71-4fbc-9a0d-f45849886b60",
      "alertTime": 1627794243460,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "6ada4427-3ce2-4630-b450-0b2c71996ab8",
      "rifAggId": "f4b85d9d-72af-4a3d-baeb-6953a4e33c4e",
      "alertTime": 1627794243444,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "2e45ae6f-921c-443a-aae2-3679ee2dfde2",
      "rifAggId": "079c60c9-46e9-463a-ae44-db6465ab9504",
      "alertTime": 1627794241950,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "d5132f52-a1ac-4cff-bd32-03089a625ece",
      "rifAggId": "27c19a22-6f71-e63c-01f1-099742f4ffa6",
      "alertTime": 1627769374460,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 92/2021.",
      "alertType": "ok"
    }, {
      "alertId": "22acb10f-700f-4327-9a9d-31865455f61e",
      "rifAggId": "27c19a22-6f71-e63c-01f1-099742f4ffa6",
      "alertTime": 1627769240549,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '92/2021'",
      "alertType": "ok"
    }, {
      "alertId": "c35e5018-2998-11cf-b407-217427a5d8cc",
      "rifAggId": "7bcdb03c-512b-d366-9b7f-b1aee92593a1",
      "alertTime": 1627769095533,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "e9bfa42c-8267-132e-bd54-201cd927fba5",
      "rifAggId": "9c5a24d3-ebb7-79f3-7be7-a2a1c8e80014",
      "alertTime": 1627742095356,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0006UO_10364' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "bc38aaf0-310e-dc39-ad13-c60b3fdea90c",
      "rifAggId": "9c5a24d3-ebb7-79f3-7be7-a2a1c8e80014",
      "alertTime": 1627742040553,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10364' completata",
      "alertType": "ok"
    }, {
      "alertId": "feb650c8-32f2-4b46-97fb-c3ef0549237a",
      "rifAggId": "9c5a24d3-ebb7-79f3-7be7-a2a1c8e80014",
      "alertTime": 1627741975877,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '91/2021'",
      "alertType": "ok"
    }, {
      "alertId": "40e4a335-6c3e-6171-21cf-777115ccef68",
      "rifAggId": "4903b725-aa1c-13f4-0282-5b8180b9ba2d",
      "alertTime": 1627741589854,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "95138aea-bf7a-33dd-8798-0e210b5e2d8b",
      "rifAggId": "1f5efdd8-222a-fce0-01ff-f55c8aed0ad2",
      "alertTime": 1627741019990,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "b38064b1-3b4d-0b57-1448-07de0a01d01a",
      "rifAggId": "a32a7831-e88b-620e-50ff-ad6acdaa59bc",
      "alertTime": 1627734311338,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "8c36ba70-7a11-11b5-9744-145c81d71c16",
      "rifAggId": "6360fa9c-4b4d-616d-e26e-0e863434c764",
      "alertTime": 1627734215624,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "d94a188e-604f-4557-a006-ededbcef6c4f",
      "rifAggId": "324fe543-ae21-e365-1e05-05945b00f55a",
      "alertTime": 1627734169086,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 90/2021.",
      "alertType": "ok"
    }, {
      "alertId": "27f378ff-213c-df1a-c427-3ad4a332d6a5",
      "rifAggId": "324fe543-ae21-e365-1e05-05945b00f55a",
      "alertTime": 1627734116092,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0003UO_10487' completata",
      "alertType": "ok"
    }, {
      "alertId": "d5c25c80-8a71-4fe9-995a-0b1cd02b1208",
      "rifAggId": "324fe543-ae21-e365-1e05-05945b00f55a",
      "alertTime": 1627734056821,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '90/2021'",
      "alertType": "ok"
    }, {
      "alertId": "c532e2f4-da7d-4a1d-896a-ff62d0bfcb61",
      "rifAggId": "74d24477-918e-475c-8d1e-36e2fc3051a9",
      "alertTime": 1627707603808,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "973e1c17-e8d5-494f-96f1-d422db161319",
      "rifAggId": "52115912-7a48-45a4-bad5-9063fea1d3f2",
      "alertTime": 1627707602676,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "28e700e1-b6e7-4ac3-b28d-0497b78d4a85",
      "rifAggId": "150f5317-ac13-4180-852b-21442a6ed7c7",
      "alertTime": 1627707602649,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "5dab21af-ab20-41d2-a7bb-80df46657c6d",
      "rifAggId": "d28b04a7-2210-d080-5deb-8030f6dbe7b2",
      "alertTime": 1627643993909,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 89/2021.",
      "alertType": "ok"
    }, {
      "alertId": "d09453e7-9643-419b-8f83-0e43c3362f54",
      "rifAggId": "d28b04a7-2210-d080-5deb-8030f6dbe7b2",
      "alertTime": 1627643774347,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '89/2021'contenente 1 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "283e004e-defe-4cc8-9031-bf5030c84802",
      "rifAggId": "959a70ff-9013-42b8-90d3-c04070b9d08d",
      "alertTime": 1627634079326,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "cd80b3ff-a7c0-4516-9944-d0c60fad162b",
      "rifAggId": "a715d8d1-a1b0-4761-8e9d-2a6906d6770b",
      "alertTime": 1627633755575,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "f3c70d4f-db8f-4518-8c2c-fc4a3f129af6",
      "rifAggId": "bcf1b219-69ee-4d8d-9588-e78a22b4b190",
      "alertTime": 1627632892762,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "503ac563-4c26-4432-8f80-8236b9e83caf",
      "rifAggId": "11b4fd72-714c-45b6-b172-91aa91ee9aa8",
      "alertTime": 1627632754245,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "50827259-9da7-497b-b1d8-09ee2fd35e5e",
      "rifAggId": "4184b969-815d-4668-8bf6-53658291536c",
      "alertTime": 1627632652360,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "87d1f8bd-4271-7891-077f-907cfac6a6c9",
      "rifAggId": "de77ec73-07c4-a51d-7e26-423c4a757d64",
      "alertTime": 1627632163061,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10379' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "ca7cbcc0-d678-cf08-7658-3b77a67fa96d",
      "rifAggId": "de77ec73-07c4-a51d-7e26-423c4a757d64",
      "alertTime": 1627631928334,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10379' completata",
      "alertType": "ok"
    }, {
      "alertId": "c5b45b33-5e6e-7b66-d7f5-4f8f30eaf80f",
      "rifAggId": "de77ec73-07c4-a51d-7e26-423c4a757d64",
      "alertTime": 1627631911358,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10379' completata",
      "alertType": "ok"
    }, {
      "alertId": "c2c10aa9-c29b-4317-a258-49b058ee3ed5",
      "rifAggId": "de77ec73-07c4-a51d-7e26-423c4a757d64",
      "alertTime": 1627631829448,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '88/2021'",
      "alertType": "ok"
    }, {
      "alertId": "09ef7dbd-c0da-4178-9c2e-84c93e022da5",
      "rifAggId": "cfa182e9-a36f-49cb-91e9-92e826a3b394",
      "alertTime": 1627631791983,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "46a15fcd-61de-4981-a36e-57948a27a3fd",
      "rifAggId": "b61e5fcb-1dad-42a9-92bc-14b61972dba7",
      "alertTime": 1627631641985,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "bc72a1bd-dec8-4e91-970a-85528d29b5ba",
      "rifAggId": "1d01ecb0-f084-4a0d-b185-585c33db1cd8",
      "alertTime": 1627631548610,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "1763b5dd-8e7d-452b-a63e-33b219856359",
      "rifAggId": "fbd47bf8-b199-4bfa-a1b8-d8d06d8205ad",
      "alertTime": 1627621206466,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "60256b27-85f8-4feb-b46b-41c1feb0fd9c",
      "rifAggId": "df0a72a2-13c1-4935-9ce8-a374c79f9345",
      "alertTime": 1627621206392,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "6ef70629-61ad-42b5-8750-07277b87163a",
      "rifAggId": "92e06a21-0ed6-4376-83fc-803bbe609b9f",
      "alertTime": 1627621206390,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "1fa48f27-fee1-49e4-b472-3a27980cf266",
      "rifAggId": "07bab511-8fcf-421b-875f-da3f1d9a687d",
      "alertTime": 1627572391241,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "7fff9223-948d-4df1-ba12-53dfaeaf98f3",
      "rifAggId": "b9d62b37-f9a7-45c7-a952-421f266a286c",
      "alertTime": 1627572283846,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "95cc2ef1-4ef8-4d13-a3f5-d4f31ea74016",
      "rifAggId": "ccd9fa70-2b32-46d2-b59b-3eaae397cd07",
      "alertTime": 1627572206316,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "b31cef53-8c39-4d16-9cf4-cdb6c252c055",
      "rifAggId": "4c2e7a5a-b15b-4d4d-89a4-7dec4fa5e3f4",
      "alertTime": 1627571337838,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "0934cb76-159b-47f5-9c5d-9f285aab7b9f",
      "rifAggId": "8ff210fd-5ffd-46ec-a807-734304e4ab00",
      "alertTime": 1627567118350,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "681d5ba9-2408-4c85-a179-916e430dc2d4",
      "rifAggId": "ad240105-bdba-4837-9e5a-6809ecc564b7",
      "alertTime": 1627566383869,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:1 Flussi in errore:0",
      "alertType": "ok"
    }, {
      "alertId": "20e07f8a-ddd2-4aa4-9b84-b7c5e5daa894",
      "rifAggId": "c2df05d0-b68b-878e-048f-be149594018b",
      "alertTime": 1627562537254,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 83/2021.",
      "alertType": "ok"
    }, {
      "alertId": "f1194c79-0858-4553-a5e2-483b02dad521",
      "rifAggId": "a5e18bff-34e7-f3e2-702f-92a159270bcd",
      "alertTime": 1627562362451,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 76/2021.",
      "alertType": "ok"
    }, {
      "alertId": "1250985a-7c99-4a91-89e9-9115814da9e1",
      "rifAggId": "ddd6acb7-c9b7-4296-ecf6-582d4df09057",
      "alertTime": 1627562352622,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 72/2021.",
      "alertType": "ok"
    }, {
      "alertId": "0e103805-3047-47a1-8af1-4bd54bd09915",
      "rifAggId": "de2277aa-f1cc-6591-d26e-1de2f3de30f6",
      "alertTime": 1627561793365,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 56/2021.",
      "alertType": "ok"
    }, {
      "alertId": "bae5d7cf-f68c-46e3-ada4-12d2003e8117",
      "rifAggId": "0002b670-9e4c-8c4f-caad-de3673761718",
      "alertTime": 1627558217088,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 87/2021.",
      "alertType": "ok"
    }, {
      "alertId": "8906be9e-5d69-4153-bd73-e86f7368219e",
      "rifAggId": "0002b670-9e4c-8c4f-caad-de3673761718",
      "alertTime": 1627557895814,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '87/2021'",
      "alertType": "ok"
    }, {
      "alertId": "43b28a23-ff92-2b62-51a5-303989e638a8",
      "rifAggId": "2e0c666e-fd3d-d8ab-cb4a-a226861bd1ed",
      "alertTime": 1627557622001,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10394' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "dfaa20a7-045a-4918-9fc7-ed954195f938",
      "rifAggId": "2e0c666e-fd3d-d8ab-cb4a-a226861bd1ed",
      "alertTime": 1627557388914,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '86/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "6d5e6c8b-5543-d258-feae-38419bd5a5bd",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1627556112302,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "b410037e-217e-7864-c7de-e8aba79fc03a",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1627555525666,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "0225425c-ee75-ed56-ff6b-6936c602e47d",
      "rifAggId": "b5fa4198-a9a2-ae7d-9201-273737b6829e",
      "alertTime": 1627552911954,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10430' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "f4a95438-9d68-40e5-b2a2-e1acfa629b11",
      "rifAggId": "728da19b-c5e1-41ce-acc1-9cc05b12d1d2",
      "alertTime": 1627534806783,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "bffc7d9d-3131-49e9-8a9f-751368e18d3d",
      "rifAggId": "2f07eaae-038c-46e9-a733-f758bdaae03e",
      "alertTime": 1627534805030,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "0b4d5029-89d0-46fc-85bd-3009f56a9847",
      "rifAggId": "7f69617c-1c64-4b58-957a-e129739272e5",
      "alertTime": 1627534804938,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "55254f9d-c552-a77e-ca08-721491ff245d",
      "rifAggId": "98e7df7b-b64c-e902-fe8b-d872ab083637",
      "alertTime": 1627485680803,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10413' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "6e7c0f34-2239-45f3-89eb-b735c8fa6d0e",
      "rifAggId": "d290e02a-5f28-dde0-5e51-43cd2513f20e",
      "alertTime": 1627477971719,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 85/2021.",
      "alertType": "ok"
    }, {
      "alertId": "cc1fb28f-f1e9-41bd-8f7f-cacb1f6c96e6",
      "rifAggId": "d290e02a-5f28-dde0-5e51-43cd2513f20e",
      "alertTime": 1627477018597,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '85/2021'",
      "alertType": "ok"
    }, {
      "alertId": "e4f1f6ae-e7f1-87e6-afc0-e3a2b2aee58b",
      "rifAggId": "10c54df5-5dec-5fb7-9a55-d55569e7dc7e",
      "alertTime": 1627473602988,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "07c8572d-4bbf-e20f-c374-19cb66d9e927",
      "rifAggId": "5c8c1cae-5f52-ef22-edf3-be2a29b3dc71",
      "alertTime": 1627473513958,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "db1a7e58-fd0e-4801-89cf-4e6075963322",
      "rifAggId": "b00c2769-ac21-a885-06d6-51e6fa8dbf0b",
      "alertTime": 1627467880238,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 44/2021.",
      "alertType": "ok"
    }, {
      "alertId": "e8b05ffd-979c-68ce-1bbb-32f9e7fd9973",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1627465025805,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10783' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "b93469a1-73a3-538a-964b-4101fc3b8df4",
      "rifAggId": "d9f85afc-2598-3610-cd47-a92b1f4392b1",
      "alertTime": 1627462205664,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10441' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "769c2508-f1c5-43b3-9fb7-cf30710510a6",
      "rifAggId": "81ad05f5-5a19-4f5d-991a-1fc7c17fab95",
      "alertTime": 1627448406379,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "a53671ef-f39b-469c-b8ad-7b6f05061414",
      "rifAggId": "2f012819-6c99-42e9-b6e8-c67d756c9062",
      "alertTime": 1627448406156,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "2f178a4f-df05-4eae-b39e-c8fceb86ad50",
      "rifAggId": "73f10e22-49b3-4729-aacf-77eb4f6b9a22",
      "alertTime": 1627448405960,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "8f6a7eef-f8cc-b583-7daa-41a054889cce",
      "rifAggId": "d9f85afc-2598-3610-cd47-a92b1f4392b1",
      "alertTime": 1627392105478,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10441' fallita. Errore:Il lotto 'd9f85afc-2598-3610-cd47-a92b1f4392b1' ha  più di un allegato digitale 'lotto_archivio' associato",
      "alertType": "error"
    }, {
      "alertId": "0d658f13-4703-42de-b2d4-3a2af1de6efe",
      "rifAggId": "1b76d015-1eff-b275-0a68-da8f104bbb8d",
      "alertTime": 1627389479898,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 84/2021.",
      "alertType": "ok"
    }, {
      "alertId": "a7201863-9604-49d1-9e02-924e6e2a1894",
      "rifAggId": "1b76d015-1eff-b275-0a68-da8f104bbb8d",
      "alertTime": 1627389298782,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '84/2021'",
      "alertType": "ok"
    }, {
      "alertId": "6a3c4ea5-710c-896b-f47a-dc98e20296e4",
      "rifAggId": "c2df05d0-b68b-878e-048f-be149594018b",
      "alertTime": 1627386718297,
      "tipoAggregato": "LOTTO",
      "messaggio": "Preparazione dell'archivio da inviare per il lotto 'ARMA0008UO_10392' e' fallita. Errore:eu.opensoftware.verbale.exceptions.postalizzazione.CalcoloImportiVerbaleException: DatiPagamento associati al verbaleId f7933369-4d13-4aa3-9757-ea778f58102b non trovati",
      "alertType": "error"
    }, {
      "alertId": "078c4e27-0673-e521-9c0d-3c4c43308df5",
      "rifAggId": "c2df05d0-b68b-878e-048f-be149594018b",
      "alertTime": 1627386695990,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0008UO_10392' completata",
      "alertType": "ok"
    }, {
      "alertId": "80cda604-27f6-4419-8324-0ca4fa398aa6",
      "rifAggId": "c2df05d0-b68b-878e-048f-be149594018b",
      "alertTime": 1627386609129,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '83/2021'",
      "alertType": "ok"
    }, {
      "alertId": "70db6a36-9eb1-baea-e2f7-632b1c4c5452",
      "rifAggId": "d12e5ae7-41e9-ff90-2d4a-d70c5f27f50f",
      "alertTime": 1627386164484,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "9c37babb-653f-f615-4501-934a2d6373df",
      "rifAggId": "06a2b7f9-9842-c1b9-dd45-0584ae3a96aa",
      "alertTime": 1627386112491,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0007UO_10392' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "228db162-09cd-155f-92ea-2c616dc1cd37",
      "rifAggId": "06a2b7f9-9842-c1b9-dd45-0584ae3a96aa",
      "alertTime": 1627372275546,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0007UO_10392' fallita. Errore:Connect to gateway.maingate.it:8111 [gateway.maingate.it/194.243.107.3] failed: Connection timed out (Connection timed out)",
      "alertType": "error"
    }, {
      "alertId": "8c7a5cbc-8b7d-404a-93ce-289819dd15e8",
      "rifAggId": "a07d72e6-7735-483e-b19a-e4c4357e6e4b",
      "alertTime": 1627368973398,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "c4a0206c-a8f1-46f3-bf79-d3340b71218a",
      "rifAggId": "2d323e8b-7a0f-48c8-90fa-e1969c2d9f95",
      "alertTime": 1627367547719,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "aa12b61e-e0cf-4757-b6fe-762831554ca9",
      "rifAggId": "06a2b7f9-9842-c1b9-dd45-0584ae3a96aa",
      "alertTime": 1627367227956,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '82/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "dd06fc08-670d-4655-9ece-e8c0ddbc1e9d",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627367217711,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 81/2021.",
      "alertType": "ok"
    }, {
      "alertId": "fba7e317-f2f9-b9d8-576c-b4da47018842",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627367083821,
      "tipoAggregato": "LOTTO",
      "messaggio": "Preparazione dell'archivio da inviare per il lotto 'ARMA0006UO_10392' e' fallita. Errore:eu.opensoftware.verbale.exceptions.postalizzazione.CalcoloImportiVerbaleException: DatiPagamento associati al verbaleId f7933369-4d13-4aa3-9757-ea778f58102b non trovati",
      "alertType": "error"
    }, {
      "alertId": "985b0ac4-4a6a-4d42-840b-232fe93b9a64",
      "rifAggId": "4ce91f54-0033-4fb1-9d35-b0fead938087",
      "alertTime": 1627362004697,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "2ff5de89-8885-7d28-076a-dda50b25cc36",
      "rifAggId": "98e7df7b-b64c-e902-fe8b-d872ab083637",
      "alertTime": 1627310721691,
      "tipoAggregato": "LOTTO",
      "messaggio": "Preparazione dell'archivio da inviare per il lotto 'ARMA0001UO_10413' e' fallita. Errore:eu.opensoftware.verbale.exceptions.postalizzazione.CalcoloImportiVerbaleException: DatiPagamento associati al verbaleId b34444be-ab10-40ff-8844-1e00e4e28955 non trovati",
      "alertType": "error"
    }, {
      "alertId": "58f6d647-9c80-228e-d397-571cde6921ba",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627307578026,
      "tipoAggregato": "LOTTO",
      "messaggio": "Preparazione dell'archivio da inviare per il lotto 'ARMA0006UO_10392' e' fallita. Errore:eu.opensoftware.verbale.exceptions.postalizzazione.CalcoloImportiVerbaleException: DatiPagamento associati al verbaleId f7933369-4d13-4aa3-9757-ea778f58102b non trovati",
      "alertType": "error"
    }, {
      "alertId": "538a4d32-a06a-7213-f2ad-0ca25cef6b79",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627307151030,
      "tipoAggregato": "LOTTO",
      "messaggio": "Preparazione dell'archivio da inviare per il lotto 'ARMA0006UO_10392' e' fallita. Errore:eu.opensoftware.verbale.exceptions.postalizzazione.CalcoloImportiVerbaleException: DatiPagamento associati al verbaleId f7933369-4d13-4aa3-9757-ea778f58102b non trovati",
      "alertType": "error"
    }, {
      "alertId": "46627d91-d7cb-477b-b925-faae2f541fad",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627307027290,
      "tipoAggregato": "LOTTO",
      "messaggio": "Preparazione dell'archivio da inviare per il lotto 'ARMA0006UO_10392' e' fallita. Errore:eu.opensoftware.verbale.exceptions.postalizzazione.CalcoloImportiVerbaleException: DatiPagamento associati al verbaleId f7933369-4d13-4aa3-9757-ea778f58102b non trovati",
      "alertType": "error"
    }, {
      "alertId": "5bea98b6-6177-0ac3-888e-fa1571dd5035",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627307022700,
      "tipoAggregato": "LOTTO",
      "messaggio": "Preparazione dell'archivio da inviare per il lotto 'ARMA0006UO_10392' e' fallita. Errore:eu.opensoftware.verbale.exceptions.postalizzazione.CalcoloImportiVerbaleException: DatiPagamento associati al verbaleId f7933369-4d13-4aa3-9757-ea778f58102b non trovati",
      "alertType": "error"
    }, {
      "alertId": "018bb0d0-0f2e-dd10-6c85-a78310640289",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627307008344,
      "tipoAggregato": "LOTTO",
      "messaggio": "Preparazione dell'archivio da inviare per il lotto 'ARMA0006UO_10392' e' fallita. Errore:eu.opensoftware.verbale.exceptions.postalizzazione.CalcoloImportiVerbaleException: DatiPagamento associati al verbaleId f7933369-4d13-4aa3-9757-ea778f58102b non trovati",
      "alertType": "error"
    }, {
      "alertId": "436571af-9779-de68-da8e-7024dff1cbb6",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627306125538,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10392' completata",
      "alertType": "ok"
    }, {
      "alertId": "c57ad3ad-ef45-dcf2-d41f-3fc08b6b607e",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627305773861,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10392' completata",
      "alertType": "ok"
    }, {
      "alertId": "35c8feb6-93ed-1d05-e70f-47463662fc49",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627305760971,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10392' completata",
      "alertType": "ok"
    }, {
      "alertId": "0547cced-705e-d903-8ef1-bef34bc616ee",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627305479215,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione delle spedizioni al lotto non possibile: Non e' stato possibile associare le spedizioni al Lotto. Nessuna spedizione disponibile trovata con i parametri impostati",
      "alertType": "error"
    }, {
      "alertId": "3f24ea51-3411-9268-fc8e-533623f4073b",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627305472550,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10392' completata",
      "alertType": "ok"
    }, {
      "alertId": "8d0f8c63-7b25-9ddc-e871-4a49f6cdd886",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627305468056,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10392' completata",
      "alertType": "ok"
    }, {
      "alertId": "1ca99bb4-f14d-fa8d-1795-92242ecb80bf",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627305458663,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0006UO_10392' completata",
      "alertType": "ok"
    }, {
      "alertId": "303395a1-4737-4376-8d1e-481966b6eb4a",
      "rifAggId": "cc05c900-bde4-8381-14a8-ee2f994305ce",
      "alertTime": 1627305317928,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '81/2021'",
      "alertType": "ok"
    }, {
      "alertId": "d875b25c-e06a-40ad-98cf-1acc5c175af1",
      "rifAggId": "401a2999-a841-152b-207a-96c57027f2e9",
      "alertTime": 1627296088066,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 48/2021.",
      "alertType": "ok"
    }, {
      "alertId": "9edc21e1-9f4e-4a9e-8210-9f5297f36c22",
      "rifAggId": "c17d6f33-cda9-4d0b-831a-1080e3e084a3",
      "alertTime": 1627284246736,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "4d53352b-aec1-4646-82da-3b3867431a71",
      "rifAggId": "31fbf12a-5346-48a0-bb7f-4d41c287812b",
      "alertTime": 1627284244189,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "f695b432-358d-eab8-f48a-43dd9a2f3d7c",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1627280109004,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10783' completata",
      "alertType": "ok"
    }, {
      "alertId": "27e640f2-3de0-4c6b-92a0-41e83b485ae7",
      "rifAggId": "993b744d-b412-4afc-a05f-501315458aa6",
      "alertTime": 1627275604873,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "c6d1bf62-43e7-921a-807a-cc7a120105fe",
      "rifAggId": "4ee1741b-397b-6f1d-ca6c-f0e6e8704e92",
      "alertTime": 1627206781339,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "a4b707e2-6afa-f971-3cac-fba2ba077810",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1627206730312,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione delle spedizioni al lotto non possibile: Non e' stato possibile associare le spedizioni al Lotto. Nessuna spedizione disponibile trovata con i parametri impostati",
      "alertType": "error"
    }, {
      "alertId": "c5b528ce-26e8-fdd7-a1c7-08c977b8e9c8",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1627206713216,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10783' completata",
      "alertType": "ok"
    }, {
      "alertId": "6805eb59-6e6c-4aca-9431-078d151aaf9e",
      "rifAggId": "545289d1-76af-333d-f534-f2d9161d7e68",
      "alertTime": 1627206610343,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '80/2021'",
      "alertType": "ok"
    }, {
      "alertId": "2cb58cfd-e42f-4a3d-bb32-a9d6ed1f6f66",
      "rifAggId": "e353e1b5-70b2-3a07-fc6c-8e9864aa36dc",
      "alertTime": 1627206469406,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 79/2021.",
      "alertType": "ok"
    }, {
      "alertId": "dff832e2-34f1-9698-2aab-865a4a8d262f",
      "rifAggId": "e353e1b5-70b2-3a07-fc6c-8e9864aa36dc",
      "alertTime": 1627205617173,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10783' completata",
      "alertType": "ok"
    }, {
      "alertId": "cb180707-f76f-4902-9481-fd343b298c5d",
      "rifAggId": "e353e1b5-70b2-3a07-fc6c-8e9864aa36dc",
      "alertTime": 1627205395882,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '79/2021'",
      "alertType": "ok"
    }, {
      "alertId": "6ac99f65-7dfb-7155-6ee7-8a006d6b6fdc",
      "rifAggId": "14da09f0-015e-07c6-3130-d72b7616f431",
      "alertTime": 1627202074886,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "e6d06f70-f025-411e-88ba-de400d2f185c",
      "rifAggId": "8825fd78-8949-4958-9d52-a73b9d8bc980",
      "alertTime": 1627197830251,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "bf39cf14-f416-4ba8-8ae0-35452ea0a7a0",
      "rifAggId": "808d32c2-83f9-427e-8e01-088fa99aa39f",
      "alertTime": 1627197829983,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "fa6dcdd9-8e8f-4b45-8f1d-49c4cb6a5d8b",
      "rifAggId": "685cae76-4d59-4d71-8866-aab293e995b2",
      "alertTime": 1627189203751,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "f5a2bde4-e08a-429b-83dc-de3881059346",
      "rifAggId": "0e0d501d-4d69-4aa3-a7e5-9637c4aee9c7",
      "alertTime": 1627130229686,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 78/2021.",
      "alertType": "ok"
    }, {
      "alertId": "ae3c3240-48f8-486e-a331-cad299df80a8",
      "rifAggId": "0e0d501d-4d69-4aa3-a7e5-9637c4aee9c7",
      "alertTime": 1627130048526,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '78/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "4ecacb11-f675-4a59-9d1b-c66c5431eb88",
      "rifAggId": "3f2f4aee-f0e4-61c8-b39f-bc247968990a",
      "alertTime": 1627130030009,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 77/2021.",
      "alertType": "ok"
    }, {
      "alertId": "487d8b2f-f168-427f-b607-b40d5f7e82a0",
      "rifAggId": "3f2f4aee-f0e4-61c8-b39f-bc247968990a",
      "alertTime": 1627129877421,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '77/2021'",
      "alertType": "ok"
    }, {
      "alertId": "d69baf68-0df1-40e3-9b4d-5da54eaf5d0d",
      "rifAggId": "a5e18bff-34e7-f3e2-702f-92a159270bcd",
      "alertTime": 1627129873086,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '76/2021'",
      "alertType": "ok"
    }, {
      "alertId": "4ef06cf3-eb6f-4144-8384-2327475f6054",
      "rifAggId": "14301cac-6557-4b57-b0b4-d9cdc532515e",
      "alertTime": 1627111587035,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "c3fa77cf-0072-44f7-82cf-f4d65cf36b7a",
      "rifAggId": "e97ff428-2195-41b4-8aa8-6107bfd0d8b9",
      "alertTime": 1627111575396,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "d42981b3-7054-4773-92d6-ff5f4cec6506",
      "rifAggId": "1887046c-345b-44d1-948a-34d2500cb2a2",
      "alertTime": 1627102804912,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:12",
      "alertType": "ok"
    }, {
      "alertId": "efa8b50b-232e-414a-b664-aaa84ebb0588",
      "rifAggId": "246b0d93-c2d8-4446-8c9f-84251c056cff",
      "alertTime": 1627023865983,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "196cc3ee-5c13-4826-87d0-511ee831001f",
      "rifAggId": "60310f40-2a90-4de4-a9e1-ceabc64315b9",
      "alertTime": 1627023761252,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "d3ab8862-bd1e-46a0-9925-68a1fb8bd658",
      "rifAggId": "0004d4bd-9a89-4910-99e2-9d0b2b0e3d0e",
      "alertTime": 1627016404285,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "08e27e0e-3bfd-4d5c-b3a8-4c313e6fa66d",
      "rifAggId": "653aa40c-2674-1f4e-6c57-80bf69f1b908",
      "alertTime": 1626965235096,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 75/2021.",
      "alertType": "ok"
    }, {
      "alertId": "2ab6d444-8bdb-47d8-a919-6243b606d75c",
      "rifAggId": "653aa40c-2674-1f4e-6c57-80bf69f1b908",
      "alertTime": 1626965102501,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '75/2021'",
      "alertType": "ok"
    }, {
      "alertId": "50272633-1139-4781-a80d-3d2b1659c325",
      "rifAggId": "a783a6ab-99e0-ca29-9596-c15c0c6d5420",
      "alertTime": 1626963998639,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 69/2021.",
      "alertType": "ok"
    }, {
      "alertId": "4f0bf6a5-c558-3841-2424-453c4e51735a",
      "rifAggId": "a78ac60c-b604-2850-a3a8-9175f121a717",
      "alertTime": 1626939144324,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "4df203a8-23b6-47bb-a215-f5afe2efa468",
      "rifAggId": "26263939-1026-49cd-85ea-5ba7ecd6c7e2",
      "alertTime": 1626939060805,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "93585d25-a1c6-4be7-8d41-03290f2c846e",
      "rifAggId": "b0eb6ae9-5b09-7d36-1f3a-39421f345490",
      "alertTime": 1626938513774,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 74/2021.",
      "alertType": "ok"
    }, {
      "alertId": "17e8f6b0-c3fb-4ad6-a1c1-652c6082bf15",
      "rifAggId": "b0eb6ae9-5b09-7d36-1f3a-39421f345490",
      "alertTime": 1626938484996,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '74/2021'",
      "alertType": "ok"
    }, {
      "alertId": "93f5af11-b324-4bba-b5f2-a1fe7c79d5f6",
      "rifAggId": "5c926581-3afe-4737-ae0f-ee96612b0749",
      "alertTime": 1626930005958,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "1f3da768-6509-4f95-a604-84179607fb17",
      "rifAggId": "0af502d3-8871-42c7-893f-063c972db7e1",
      "alertTime": 1626930005935,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "427f1f8b-4d93-4119-2c6e-6e2b4241a177",
      "rifAggId": "a504a3e8-e63c-456e-9411-4b60d19c4906",
      "alertTime": 1626882233140,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0007UO_10352' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "d6f59dd8-beb0-704b-5517-79e3235ed19c",
      "rifAggId": "a504a3e8-e63c-456e-9411-4b60d19c4906",
      "alertTime": 1626882055520,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0007UO_10352' fallita. Errore:Il lotto 'a504a3e8-e63c-456e-9411-4b60d19c4906' ha  più di un allegato digitale 'lotto_archivio' associato",
      "alertType": "error"
    }, {
      "alertId": "d86fce76-20eb-48c7-b6c5-313b6b452299",
      "rifAggId": "e98f596c-1584-afae-e054-93f672380d23",
      "alertTime": 1626878499845,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 73/2021.",
      "alertType": "ok"
    }, {
      "alertId": "d3dca6c3-1c29-42dc-aa03-e3a87bb6bd8c",
      "rifAggId": "e98f596c-1584-afae-e054-93f672380d23",
      "alertTime": 1626878382326,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '73/2021'",
      "alertType": "ok"
    }, {
      "alertId": "288763dd-0b12-4ed0-957f-e1cf98760ad2",
      "rifAggId": "5566ce33-1262-aa61-649f-2429a1c7bc93",
      "alertTime": 1626877890251,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 66/2021.",
      "alertType": "ok"
    }, {
      "alertId": "b2016bba-f178-811a-9798-caaaa8cef216",
      "rifAggId": "48b2c9ca-59ee-195a-fe4a-5eb0d7ad5bf6",
      "alertTime": 1626875728692,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10392' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "0fd11097-d5dc-4f2a-a953-139460bad3f3",
      "rifAggId": "79ae9700-6c59-a775-6e00-3282e35af3ca",
      "alertTime": 1626873980147,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 63/2021.",
      "alertType": "ok"
    }, {
      "alertId": "f4554a0b-15e0-4ebf-8595-df785021ef8e",
      "rifAggId": "a8fbcc48-2acf-0b39-39cf-cbf3e1ee242c",
      "alertTime": 1626873746800,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 52/2021.",
      "alertType": "ok"
    }, {
      "alertId": "18a07ca5-3073-4557-9424-fd64d6618124",
      "rifAggId": "7179cdd6-1473-4f4f-97f1-a4c74d2a1ff9",
      "alertTime": 1626871704590,
      "tipoAggregato": "bollettino_txt",
      "messaggio": "È completata l'acquisizione di 191 rilevazioni di tipo bollettino_txt",
      "alertType": "ok"
    }, {
      "alertId": "0633760c-b535-403b-8346-2f4d263428c7",
      "rifAggId": "ddd6acb7-c9b7-4296-ecf6-582d4df09057",
      "alertTime": 1626854897779,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '72/2021'",
      "alertType": "ok"
    }, {
      "alertId": "d66d0dab-53db-410c-840c-a4c5ca418a85",
      "rifAggId": "e68ae5a5-bf76-453b-72db-489b2830c687",
      "alertTime": 1626854798366,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 71/2021.",
      "alertType": "ok"
    }, {
      "alertId": "443b5ff9-1f69-48ad-8990-4985d6c0faf6",
      "rifAggId": "e68ae5a5-bf76-453b-72db-489b2830c687",
      "alertTime": 1626854609508,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '71/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "7587cc74-3399-49d2-b382-9fdf70a9ae2b",
      "rifAggId": "aef12c8d-3660-4b92-a312-d06ad8b213a2",
      "alertTime": 1626848476370,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "96e74a2f-6e2d-4cb2-aeff-b699a2c9d6b5",
      "rifAggId": "3b87f6e4-a98c-41aa-9fe6-64e31acd3955",
      "alertTime": 1626843605614,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "1a37b7c6-4766-417b-858d-b398ec5f8ded",
      "rifAggId": "2f3b7a56-bbd7-43c5-a235-bef15cfc603c",
      "alertTime": 1626843604409,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "f9e369e2-c4f5-412e-b6a3-ba2485800694",
      "rifAggId": "9001a11e-a2df-4ffb-b75b-2a509b5efb51",
      "alertTime": 1626765842727,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "82e007fb-1054-4784-8fe4-72755f26dd5b",
      "rifAggId": "bd2350ff-05a8-9df4-0f36-1fd4b318f59b",
      "alertTime": 1626763542754,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 70/2021.",
      "alertType": "ok"
    }, {
      "alertId": "1676353b-7f24-499d-8eb8-ef6c38de90c3",
      "rifAggId": "bd2350ff-05a8-9df4-0f36-1fd4b318f59b",
      "alertTime": 1626763335429,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '70/2021'",
      "alertType": "ok"
    }, {
      "alertId": "070847ca-15e2-ce70-9099-2cde28b5cf64",
      "rifAggId": "a783a6ab-99e0-ca29-9596-c15c0c6d5420",
      "alertTime": 1626761347073,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10488' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "fc8e2320-f881-fb8c-54f3-4bdecb4790b5",
      "rifAggId": "a783a6ab-99e0-ca29-9596-c15c0c6d5420",
      "alertTime": 1626761287916,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0003UO_10488' completata",
      "alertType": "ok"
    }, {
      "alertId": "ce8b2cc0-068b-463b-ac17-13cda3fc0dda",
      "rifAggId": "a783a6ab-99e0-ca29-9596-c15c0c6d5420",
      "alertTime": 1626761214580,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '69/2021'",
      "alertType": "ok"
    }, {
      "alertId": "bca06e41-7bf1-40b8-9c7e-bc5bf086d4ff",
      "rifAggId": "624d5549-ea2f-9227-8440-e5084fb6a7fd",
      "alertTime": 1626761190007,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 62/2021.",
      "alertType": "ok"
    }, {
      "alertId": "4f9498ea-97ca-2aa4-3d3d-7435a96c5e7b",
      "rifAggId": "624d5549-ea2f-9227-8440-e5084fb6a7fd",
      "alertTime": 1626761148289,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10488' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "860f4de6-4f30-4468-8bbc-53819f5c0043",
      "rifAggId": "6d68aba1-fd4c-4686-850c-ace6084a5e36",
      "alertTime": 1626757205495,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "8a8d05a7-2432-4e86-bfca-23409cc04509",
      "rifAggId": "fb72db17-95ed-4449-ba93-a6de306f656b",
      "alertTime": 1626757205452,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "2b8329a3-c40d-4797-899a-eb231821747a",
      "rifAggId": "e1df86c4-9849-4bee-889d-22e94db421da",
      "alertTime": 1626679443129,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "2ed30b66-7f05-4b55-860c-8fa3d83fc396",
      "rifAggId": "9109bd22-02a0-4ba3-aa72-9e90c5948a8b",
      "alertTime": 1626670805264,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "d5ff0a07-79f1-4a0a-b19d-a61de5e243fe",
      "rifAggId": "89f03838-6e9b-420e-8916-4784c1747266",
      "alertTime": 1626670805220,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "d868df35-72b4-457c-bf54-e5fcf8841fb6",
      "rifAggId": "56b6f35d-3c4d-434b-ae9e-fa780acfb9ef",
      "alertTime": 1626593047843,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "7bbd6c71-6f3f-4e57-8a80-4f88af77fb9e",
      "rifAggId": "d2a325cd-c27a-489d-80b9-31de90342836",
      "alertTime": 1626584436274,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "ce776c44-a0a7-40d1-b229-bbe3ad31a233",
      "rifAggId": "6345c033-6853-492a-853a-5a8527803d44",
      "alertTime": 1626584434192,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "d537a371-ef4b-4e2e-8152-df975e2fe567",
      "rifAggId": "4d0f23de-7be9-4e3e-a68d-f6f81c0ab688",
      "alertTime": 1626506613908,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "e7fbee8f-9741-4515-a379-34265c9d8b39",
      "rifAggId": "019f7770-9607-4be0-bf4a-c5e72c8818ca",
      "alertTime": 1626498005538,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "d924ec43-e8cf-45b1-9507-5c52a8c8b964",
      "rifAggId": "0a46cbed-fa90-44f2-9fb4-03b8d057f41e",
      "alertTime": 1626498005152,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:8",
      "alertType": "ok"
    }, {
      "alertId": "514d48c7-41a3-43c2-83ea-64b3ccc54043",
      "rifAggId": "301d4bc3-18b6-488d-84c0-d044df3c50d0",
      "alertTime": 1626420493142,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:7",
      "alertType": "ok"
    }, {
      "alertId": "65fdcf88-0248-4e29-9773-205cca4e5759",
      "rifAggId": "501826cb-7b2d-48ce-941f-b0a40bb1f1ae",
      "alertTime": 1626411825589,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:7",
      "alertType": "ok"
    }, {
      "alertId": "4cc62930-1c52-4039-a7ef-fcc0393f47e6",
      "rifAggId": "53e8701f-8fe4-4ef6-b189-055e26eeee62",
      "alertTime": 1626411825587,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:7",
      "alertType": "ok"
    }, {
      "alertId": "56b35abf-41d3-4b78-8544-b5349a76668f",
      "rifAggId": "0e66fa77-7610-4433-95cf-d2888d0281ed",
      "alertTime": 1626331609939,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:7",
      "alertType": "ok"
    }, {
      "alertId": "d7746211-5bcc-4256-bc09-69fd40ba014f",
      "rifAggId": "b687db30-5774-40a5-b42b-a917bf7643a1",
      "alertTime": 1626325205764,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:7",
      "alertType": "ok"
    }, {
      "alertId": "dfc87284-07ab-47dd-9c30-3a318f6c915d",
      "rifAggId": "f582b168-ed86-4d2e-b158-7ddfcbed0fa1",
      "alertTime": 1626325205289,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:7",
      "alertType": "ok"
    }, {
      "alertId": "b22c6bbf-c0cc-4dd0-93bf-893e9270a7fa",
      "rifAggId": "21702ce7-9dc6-4645-ba4a-8c1f2189d4a5",
      "alertTime": 1626238803638,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "d0b0d16d-9c1d-4c63-a82d-fdceb55d0e98",
      "rifAggId": "524e79e1-3903-41fa-ab35-e10bd7f5c27d",
      "alertTime": 1626238803519,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "c5b3474f-f924-464c-af33-0ea14b7c7fef",
      "rifAggId": "b46607af-7b4d-4569-9d62-30765f859406",
      "alertTime": 1626238803466,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "ea928695-fc42-cfe9-fec0-0d69db8f6632",
      "rifAggId": "43d635d1-0dc2-8e06-f190-8dbc27100cda",
      "alertTime": 1626161838845,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10379' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "ba17a165-465f-f630-807e-8dd93a335092",
      "rifAggId": "43d635d1-0dc2-8e06-f190-8dbc27100cda",
      "alertTime": 1626161771775,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10379' completata",
      "alertType": "ok"
    }, {
      "alertId": "a42972b2-b94b-f73c-f66f-ef3bfb74064d",
      "rifAggId": "43d635d1-0dc2-8e06-f190-8dbc27100cda",
      "alertTime": 1626160934811,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10379' completata",
      "alertType": "ok"
    }, {
      "alertId": "17aac658-e687-45a4-891e-0cac78ba008d",
      "rifAggId": "3485374b-e58d-4028-a678-eb912698b4c7",
      "alertTime": 1626152405665,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "2a6b0a86-eb38-48c0-80d9-011d0b409248",
      "rifAggId": "eefaa790-9a24-4251-9848-94adf3cf1a72",
      "alertTime": 1626152405594,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "5d4bc5e0-7278-43b1-81ef-31ba156c43f6",
      "rifAggId": "e16a64a7-b11c-4f41-82f9-75faa128c113",
      "alertTime": 1626152405522,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "c995dd90-451d-46f4-a843-683507bc04d7",
      "rifAggId": "43d635d1-0dc2-8e06-f190-8dbc27100cda",
      "alertTime": 1626091957255,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '68/2021'",
      "alertType": "ok"
    }, {
      "alertId": "b6e32435-ea97-49ed-9e7e-ce4fbd554633",
      "rifAggId": "dbfb93c7-28ec-0b53-8938-2346245796a8",
      "alertTime": 1626078476133,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 67/2021.",
      "alertType": "ok"
    }, {
      "alertId": "c61e8a69-8c6d-4b98-acbd-7952a9b913b7",
      "rifAggId": "dbfb93c7-28ec-0b53-8938-2346245796a8",
      "alertTime": 1626078404746,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '67/2021'",
      "alertType": "ok"
    }, {
      "alertId": "d69fb651-bf86-b542-c7ba-f03d01313d99",
      "rifAggId": "c92d921d-968c-460e-3828-e8b555ae3d7b",
      "alertTime": 1626078392364,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "a8deedd5-6a51-4fc2-973c-52192a41f055",
      "rifAggId": "745accf4-95f9-4bc4-aa6e-aab5530c8f28",
      "alertTime": 1626066005362,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "4f06592a-c04a-4c99-9984-1f37ecf36ae8",
      "rifAggId": "972cde08-9a98-49d2-b7a1-bd603f01663e",
      "alertTime": 1626066005184,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "e647c93a-bb2c-473c-b78b-1e287082de50",
      "rifAggId": "44217dfb-33dc-4184-b5ae-53d0234efdff",
      "alertTime": 1626066005146,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "66ebb3a8-7c2b-d1c0-f5e2-090634b673b7",
      "rifAggId": "5566ce33-1262-aa61-649f-2429a1c7bc93",
      "alertTime": 1626021876748,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0008UO_10352' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "1453c5a8-8703-c371-09fc-4a9516af7793",
      "rifAggId": "5566ce33-1262-aa61-649f-2429a1c7bc93",
      "alertTime": 1626021748756,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0008UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "9c19eef7-8251-3e43-be9f-c706e3f9a2da",
      "rifAggId": "5566ce33-1262-aa61-649f-2429a1c7bc93",
      "alertTime": 1626021744600,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0008UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "54ad03cd-df78-419e-a6f5-90e8079602c7",
      "rifAggId": "5566ce33-1262-aa61-649f-2429a1c7bc93",
      "alertTime": 1626021697907,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '66/2021'",
      "alertType": "ok"
    }, {
      "alertId": "bed3267d-7d25-22b9-329a-ac4548dc1009",
      "rifAggId": "6e42fc5f-93ee-c5df-257f-9b4f8bb000a4",
      "alertTime": 1626021672709,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "2f6724af-ef8d-4ef2-8f10-32c9ce05c17f",
      "rifAggId": "b79b2822-a7f0-4360-b9db-af2808bf4471",
      "alertTime": 1625979605930,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "617e3a61-0ef0-4924-b65d-f59025271010",
      "rifAggId": "fa477dd5-5d02-475b-a00a-aaa43f302784",
      "alertTime": 1625979605902,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "f4fb55cb-a705-488a-9a68-ca39855480a1",
      "rifAggId": "c822b356-558d-409c-bf5c-e095e0d54251",
      "alertTime": 1625979605894,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "b43abe7c-d9e7-4faf-be88-9323245b77d9",
      "rifAggId": "9321daa2-a9ee-4f07-bb92-dfef24b287fb",
      "alertTime": 1625893205662,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "d0888b0b-0ebe-41f1-9a88-ca4450b01cba",
      "rifAggId": "7611e420-53d7-4a72-ac7a-783a85144720",
      "alertTime": 1625893205374,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "6cf66c8c-a71e-45ef-a9f4-b0dd666e9128",
      "rifAggId": "1e24d1d5-f976-4da2-b977-a9295ccad33e",
      "alertTime": 1625893205369,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "61b2a0a5-93fd-19ec-5bae-b5add74f441f",
      "rifAggId": "088b51fc-61c8-63d4-42cb-43cf81882f9d",
      "alertTime": 1625825117771,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10430' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "14f9f7ec-1079-aaad-f589-1bdd6f4d5728",
      "rifAggId": "088b51fc-61c8-63d4-42cb-43cf81882f9d",
      "alertTime": 1625825056731,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10430' completata",
      "alertType": "ok"
    }, {
      "alertId": "522865a1-13cd-490c-b75f-ed4afb53c10e",
      "rifAggId": "088b51fc-61c8-63d4-42cb-43cf81882f9d",
      "alertTime": 1625824782882,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '65/2021'",
      "alertType": "ok"
    }, {
      "alertId": "fce852f9-2e3a-b65b-7ef4-d21728375d82",
      "rifAggId": "b5fa4198-a9a2-ae7d-9201-273737b6829e",
      "alertTime": 1625819765740,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10430' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "77b9ee44-d9a5-3202-12ac-b91a3400a855",
      "rifAggId": "b5fa4198-a9a2-ae7d-9201-273737b6829e",
      "alertTime": 1625819616875,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10430' completata",
      "alertType": "ok"
    }, {
      "alertId": "0c022ad9-0db6-34c8-725b-e67cde0b7ec4",
      "rifAggId": "b5fa4198-a9a2-ae7d-9201-273737b6829e",
      "alertTime": 1625818930573,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10430' completata",
      "alertType": "ok"
    }, {
      "alertId": "b16ed609-bdc9-4ac2-830f-980f0b21e88d",
      "rifAggId": "b5fa4198-a9a2-ae7d-9201-273737b6829e",
      "alertTime": 1625818617268,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '64/2021'",
      "alertType": "ok"
    }, {
      "alertId": "e6a7e99e-f849-4d0f-9920-48692d0d570b",
      "rifAggId": "4c089df5-93cc-43f4-b814-bba283df4412",
      "alertTime": 1625815445824,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "c962916d-5ccc-44eb-a640-f166739f3ee4",
      "rifAggId": "889195a6-bda1-4493-8d06-d6bd3acdd597",
      "alertTime": 1625806805701,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "1b090e82-1587-4526-b693-bb29a323ac02",
      "rifAggId": "f9027b9a-31c7-45ed-be8f-dbeef782d31c",
      "alertTime": 1625806805632,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:4",
      "alertType": "ok"
    }, {
      "alertId": "15f3d142-7851-4f17-a653-2f969d086bde",
      "rifAggId": "6f2d4651-cee6-4b97-b838-77fa9c660da9",
      "alertTime": 1625729017235,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "ad695740-a745-4384-b84b-d6e199d8d99a",
      "rifAggId": "d5860dd1-11bb-4f0c-8f3f-37149c3e77cb",
      "alertTime": 1625720404031,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "8c42734f-1a19-41e8-9693-d85896d47b3e",
      "rifAggId": "1ff0f0de-a2ae-46ff-b291-c01531e694c7",
      "alertTime": 1625720403538,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "5313ce36-5b24-958d-5e18-a4b48c0cdef1",
      "rifAggId": "79ae9700-6c59-a775-6e00-3282e35af3ca",
      "alertTime": 1625695882808,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10395' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "e1fccd32-c01f-40aa-8efd-aaace44bb6dd",
      "rifAggId": "79ae9700-6c59-a775-6e00-3282e35af3ca",
      "alertTime": 1625695817353,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '63/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "f38e3d69-c856-468a-8097-115497a82167",
      "rifAggId": "34f8cd8c-5b6a-43b0-a6b3-888a00b91860",
      "alertTime": 1625670214372,
      "tipoAggregato": "bollettino_txt",
      "messaggio": "È completata l'acquisizione di 46 rilevazioni di tipo bollettino_txt",
      "alertType": "ok"
    }, {
      "alertId": "54b5d49a-3c36-4960-8cb5-7edc9a2bfb4e",
      "rifAggId": "90993bc6-3180-4f4b-ad9c-7a62e7dd68dc",
      "alertTime": 1625670090389,
      "tipoAggregato": "bollettino_txt",
      "messaggio": "È fallita la lavorazione del file per una rilevazione del tipo bollettino_txt perché eu.opensoftware.exceptions.MalformedFlatFileException: Errore alcune linee del file sono più lunghe del previsto.",
      "alertType": "error"
    }, {
      "alertId": "a5380500-a16c-41f8-aba1-cfb60c981bdd",
      "rifAggId": "6fa6abf3-a958-4e7a-9fef-0575bbffe078",
      "alertTime": 1625642874852,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "15f8fce8-7553-45e8-ae23-65e5f972cc21",
      "rifAggId": "624d5549-ea2f-9227-8440-e5084fb6a7fd",
      "alertTime": 1625640702750,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '62/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "f0354468-a50a-4bcd-8562-beae21860ff3",
      "rifAggId": "a5adc48b-a99c-48c8-90ba-7d78a1be65c0",
      "alertTime": 1625634004356,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "735db294-93f0-4d5b-9aca-1ac8c1865aae",
      "rifAggId": "cbbda400-5cc9-48f1-8fd9-518168e270be",
      "alertTime": 1625634002406,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "b9c37c98-28f6-4b83-a0d7-2ef949dac743",
      "rifAggId": "1ab50109-12c5-4e7a-9209-049c14701777",
      "alertTime": 1625554138767,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "dad9aab8-aee4-45d3-a579-baa446fbf51e",
      "rifAggId": "49ab4698-b0c4-4ecd-a4d5-aa74eac4eef7",
      "alertTime": 1625547603328,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "dec09a3f-dfa6-460d-b14b-ac903f634aae",
      "rifAggId": "2a9679c3-ad79-4e39-907c-03e4caa8dd2d",
      "alertTime": 1625547603205,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "fd95a84b-8beb-4036-b1a0-431e44afeb9e",
      "rifAggId": "96997746-5303-1b13-e474-5436c0de78e6",
      "alertTime": 1625474557071,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '61/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "b64a12b3-aa19-4188-a886-8e1aa7d3300f",
      "rifAggId": "287711bf-6d2a-41a4-96ac-815eee91f03e",
      "alertTime": 1625469788281,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "e0126a51-7c9e-44c2-ac11-133e89303aab",
      "rifAggId": "11473113-7a45-42b6-acc7-fda539945abb",
      "alertTime": 1625461203262,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "8773bfa2-7217-4908-8207-6b347285074e",
      "rifAggId": "bd621e09-9516-4539-b0a7-97d2cbcd2a8b",
      "alertTime": 1625461203166,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "e77e9379-7e71-1ca4-dc18-143c5ac24a41",
      "rifAggId": "1e0e1831-000f-0c4b-9582-9f1b45d42db8",
      "alertTime": 1625405890980,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0005UO_10413' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "40c850b9-b3d2-4124-9707-8950b772436f",
      "rifAggId": "1e0e1831-000f-0c4b-9582-9f1b45d42db8",
      "alertTime": 1625405790613,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '60/2021'contenente 47 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "e8c9e654-685d-4468-884f-20e81ab41c73",
      "rifAggId": "3aa67e40-8e2b-4687-99c1-8ccb558b4a1c",
      "alertTime": 1625383950648,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "666a5b2e-4316-4b71-b7e5-0688b9f6b7b9",
      "rifAggId": "4eeb235a-45bf-47ed-b012-a74f269e12b1",
      "alertTime": 1625374803577,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "90f728e1-8c6f-4d66-b3e6-e1aa36e45f90",
      "rifAggId": "769bfb6c-d26b-4349-a418-30e2f3b7b898",
      "alertTime": 1625374803136,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "fb2045eb-d8c4-4bee-9144-801b4130c44c",
      "rifAggId": "c06df1f2-982a-4517-99b0-caea517276cb",
      "alertTime": 1625292465655,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "c0fdcd06-6f17-4a91-8e22-665f4103b31b",
      "rifAggId": "4c8214c2-f672-47e9-92dd-4edaf50af5ef",
      "alertTime": 1625288403966,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "fbeaa5e5-0e62-4d16-b433-82fb28836a00",
      "rifAggId": "5deb5dce-f2b2-4c9f-b577-76b061bf1d74",
      "alertTime": 1625288402731,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "6492a759-521b-4ca6-8b48-d1cec41329ab",
      "rifAggId": "7cf4e7cd-ada1-9a32-7dd0-72f8510720b8",
      "alertTime": 1625238273489,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 59/2021.",
      "alertType": "ok"
    }, {
      "alertId": "21a4e433-01d7-4a04-8104-5847b7ecabd2",
      "rifAggId": "0f5146f6-da57-b85b-8601-df92acbf1252",
      "alertTime": 1625238243385,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 57/2021.",
      "alertType": "ok"
    }, {
      "alertId": "95ceb84f-1f29-f32e-6928-af018f8bbf13",
      "rifAggId": "d0ca87b2-e466-01e7-e024-a720dc9cb32a",
      "alertTime": 1625238221120,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10413' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "e02db9f2-da47-4471-a150-6231aa0b1c49",
      "rifAggId": "7cf4e7cd-ada1-9a32-7dd0-72f8510720b8",
      "alertTime": 1625237361767,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '59/2021'",
      "alertType": "ok"
    }, {
      "alertId": "147c1cb4-1cea-415a-a636-1290de7c56be",
      "rifAggId": "d0ca87b2-e466-01e7-e024-a720dc9cb32a",
      "alertTime": 1625237024508,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '58/2021'contenente 48 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "65667c72-1181-42e0-8200-e5ec206065cf",
      "rifAggId": "0f5146f6-da57-b85b-8601-df92acbf1252",
      "alertTime": 1625236837201,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '57/2021'",
      "alertType": "ok"
    }, {
      "alertId": "d772aedc-fb17-4315-a709-98aeb0e40777",
      "rifAggId": "e76a4e52-8f69-4161-96ea-0448ffae64cb",
      "alertTime": 1625202005808,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "abce297d-1f07-45b9-a669-1c267401119b",
      "rifAggId": "5e3c2131-7287-425e-8097-793a21167295",
      "alertTime": 1625202004427,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "88f61a39-64a9-4905-a9fe-33071c0a5e09",
      "rifAggId": "a6d675d7-a081-475d-8106-3dd2a9e5c137",
      "alertTime": 1625202004406,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "cec7270e-0fbd-4560-8533-526ead923764",
      "rifAggId": "20d36d8f-ad26-4bb9-8088-6dfafb77d31b",
      "alertTime": 1625115605654,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "0ab9af7b-3775-486d-bd05-19e885304ca5",
      "rifAggId": "0528cf56-22f8-44b8-941e-b83ec959cf6e",
      "alertTime": 1625115605262,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "30f6672e-ae48-4409-9b80-2fb6c5fd24a0",
      "rifAggId": "ed10d7e6-3212-42c8-b444-dbfe7506d473",
      "alertTime": 1625115605236,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "d9f07126-d798-45a8-b158-45e058c7e04f",
      "rifAggId": "de2277aa-f1cc-6591-d26e-1de2f3de30f6",
      "alertTime": 1625049002210,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '56/2021'contenente 1 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "8b76a0c5-66f5-4f2a-bf2d-5a33fa6e746d",
      "rifAggId": "968fd73d-91a0-4e6d-999d-e8d5e85ab4ec",
      "alertTime": 1625029205316,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "421becc2-df43-4f2f-ac12-abb74465e1ba",
      "rifAggId": "acab7287-cbb3-494c-bd86-a3e396e07706",
      "alertTime": 1625029205223,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "cb1a19d2-52e8-4a98-9acb-25ce77ca40f9",
      "rifAggId": "54efaa0a-bd91-4301-ac15-a7bf7756bf4a",
      "alertTime": 1625029205195,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:3",
      "alertType": "ok"
    }, {
      "alertId": "9c6e9aa6-f140-de58-ebe2-99aa751eb6d0",
      "rifAggId": "ea77b3a5-becd-5c4c-789a-2f182afc80da",
      "alertTime": 1624957933241,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10350' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "553fa4fc-8305-4a37-8888-bd31f2884b24",
      "rifAggId": "ea77b3a5-becd-5c4c-789a-2f182afc80da",
      "alertTime": 1624957876034,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '55/2021'contenente 1 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "259ae297-e444-4f27-8bf1-53165c810d91",
      "rifAggId": "c0f7814c-e6bd-256a-89d5-5c6b203fc27a",
      "alertTime": 1624957066477,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 54/2021.",
      "alertType": "ok"
    }, {
      "alertId": "99ac6057-2d85-4f0f-881c-59c7c4679ac5",
      "rifAggId": "c0f7814c-e6bd-256a-89d5-5c6b203fc27a",
      "alertTime": 1624957019217,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '54/2021'contenente 1 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "ba82609a-2edc-44d3-889b-4e5d499ad22d",
      "rifAggId": "a6b7c614-70a2-178c-2dce-e5b5012e7364",
      "alertTime": 1624955387165,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 53/2021.",
      "alertType": "ok"
    }, {
      "alertId": "b8068b58-9ed8-4c86-8826-97d43e059465",
      "rifAggId": "a6b7c614-70a2-178c-2dce-e5b5012e7364",
      "alertTime": 1624954670667,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '53/2021'",
      "alertType": "ok"
    }, {
      "alertId": "de0cb0a1-5550-bd17-ba78-8397a06c4d04",
      "rifAggId": "1fbec00e-b398-51fc-1a9a-32ab1f3107d3",
      "alertTime": 1624954593919,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "d0bbeedf-492d-402b-98a0-1c5edab68093",
      "rifAggId": "e018fef2-b463-4354-9410-96e9a5bb16fa",
      "alertTime": 1624942805206,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "7a64ad3a-7249-4b68-be67-d30957e67493",
      "rifAggId": "2a47345a-191e-4dd4-945c-eabcb2ef5b62",
      "alertTime": 1624942805180,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "0396e422-1ec0-43d7-bf9e-ac2214975820",
      "rifAggId": "3156ad3c-a0ff-442a-b6c3-81c449aba0ed",
      "alertTime": 1624942804899,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "eb6aa654-126a-296a-26fc-8a3208dafa4c",
      "rifAggId": "a8fbcc48-2acf-0b39-39cf-cbf3e1ee242c",
      "alertTime": 1624889944001,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0005UO_10364' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "453e57ac-d044-4827-b0ef-cd65687b5859",
      "rifAggId": "a8fbcc48-2acf-0b39-39cf-cbf3e1ee242c",
      "alertTime": 1624889776569,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '52/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "545e7bec-b483-0c10-ff60-f1e7ff7ffc83",
      "rifAggId": "98e7df7b-b64c-e902-fe8b-d872ab083637",
      "alertTime": 1624868778177,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10413' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "f88e225b-2b79-4a14-8354-b85ca072393c",
      "rifAggId": "98e7df7b-b64c-e902-fe8b-d872ab083637",
      "alertTime": 1624864371002,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '51/2021'contenente 91 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "61d3f309-602e-4a3b-a2b3-d9e1ba94aa2a",
      "rifAggId": "692a5010-2164-4bef-a078-e79904344a22",
      "alertTime": 1624856405022,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "7d4b0fef-e7c3-44a4-af59-fbcbc027e195",
      "rifAggId": "be66ec84-54ac-4ad5-83ee-4f20101eaf31",
      "alertTime": 1624856404951,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "896866ff-0212-4c15-86d2-b4b262e8d581",
      "rifAggId": "bf1aae8f-abcd-4872-89ab-10b51e249f66",
      "alertTime": 1624856404603,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "d6f8d7cb-3a6f-4330-9543-192e71890dd6",
      "rifAggId": "2d849e34-3965-4263-b127-27f600e2bcd1",
      "alertTime": 1624770005648,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "90a826d3-0bf6-4be7-9527-6772dc3a2370",
      "rifAggId": "d750c14e-c251-453b-a33b-6b9cfd3130e1",
      "alertTime": 1624770005394,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "66d2afae-5cd4-45d7-8f36-d4b6cb059004",
      "rifAggId": "b4f228a8-96fc-4dea-8022-0927a252dde7",
      "alertTime": 1624770005319,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "36a26ce8-5b02-d113-9d7a-6fd7d4b5d0d9",
      "rifAggId": "a504a3e8-e63c-456e-9411-4b60d19c4906",
      "alertTime": 1624707741108,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0007UO_10352' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "e41cc91a-1041-60db-e458-104574ee4d2a",
      "rifAggId": "a504a3e8-e63c-456e-9411-4b60d19c4906",
      "alertTime": 1624707687415,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0007UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "41db835b-1ec7-4568-a1be-bdd0761eee1f",
      "rifAggId": "ae3a61f8-0d2e-e175-4f8c-3fc25f962e83",
      "alertTime": 1624707669734,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 49/2021.",
      "alertType": "ok"
    }, {
      "alertId": "611b7f0e-df9d-4ec4-8508-7a7c2d835a4c",
      "rifAggId": "a504a3e8-e63c-456e-9411-4b60d19c4906",
      "alertTime": 1624707619007,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '50/2021'",
      "alertType": "ok"
    }, {
      "alertId": "f1e57892-6247-eb70-7531-152215273263",
      "rifAggId": "ae3a61f8-0d2e-e175-4f8c-3fc25f962e83",
      "alertTime": 1624707581406,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0006UO_10352' fallita. Errore:Il lotto 'ae3a61f8-0d2e-e175-4f8c-3fc25f962e83' ha  più di un allegato digitale 'lotto_archivio' associato",
      "alertType": "error"
    }, {
      "alertId": "66657600-67c5-0a48-d312-29ff4fe1d2b4",
      "rifAggId": "ae3a61f8-0d2e-e175-4f8c-3fc25f962e83",
      "alertTime": 1624707510135,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0006UO_10352' fallita. Errore:Il lotto 'ae3a61f8-0d2e-e175-4f8c-3fc25f962e83' ha  più di un allegato digitale 'lotto_archivio' associato",
      "alertType": "error"
    }, {
      "alertId": "f2af383b-f10b-6478-fa27-b843fa41009a",
      "rifAggId": "ae3a61f8-0d2e-e175-4f8c-3fc25f962e83",
      "alertTime": 1624707491566,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0006UO_10352' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "e2be6298-2fb6-4c3f-9f8f-28ca6b88581e",
      "rifAggId": "ae3a61f8-0d2e-e175-4f8c-3fc25f962e83",
      "alertTime": 1624707421996,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '49/2021'contenente 6 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "40dec0bb-cc15-4a45-80de-8bf10847632f",
      "rifAggId": "35d22343-77e4-40aa-8da3-aa4bb700354e",
      "alertTime": 1624683605346,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "f064482a-1668-4a4c-b566-c570111a3154",
      "rifAggId": "0a030b00-584a-4473-9d1d-0279de261edc",
      "alertTime": 1624683605335,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "c20e4a8b-7737-4a71-936d-6b3ab72db401",
      "rifAggId": "d6544307-60ce-45b7-aaf0-d688b1d71bfc",
      "alertTime": 1624683605323,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "be1797a6-d2c7-edbe-dd38-9cb5d5a3f199",
      "rifAggId": "401a2999-a841-152b-207a-96c57027f2e9",
      "alertTime": 1624620797513,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10427' completata",
      "alertType": "ok"
    }, {
      "alertId": "596590e4-6880-47ce-bf8d-093780192d38",
      "rifAggId": "401a2999-a841-152b-207a-96c57027f2e9",
      "alertTime": 1624620723558,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '48/2021'",
      "alertType": "ok"
    }, {
      "alertId": "ef5b956c-2ea5-44e2-9f3f-41267926b803",
      "rifAggId": "ad71d24c-527f-2af4-9bbd-f130b0e66abb",
      "alertTime": 1624619592651,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 47/2021.",
      "alertType": "ok"
    }, {
      "alertId": "c1c8199a-cb71-e725-3e6d-6fa67b4cb5c9",
      "rifAggId": "ad71d24c-527f-2af4-9bbd-f130b0e66abb",
      "alertTime": 1624617883537,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10427' completata",
      "alertType": "ok"
    }, {
      "alertId": "c25687b1-b591-451b-b941-c3e732bd5257",
      "rifAggId": "ad71d24c-527f-2af4-9bbd-f130b0e66abb",
      "alertTime": 1624617632383,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '47/2021'",
      "alertType": "ok"
    }, {
      "alertId": "2cda7033-a081-8af2-7fcc-d2b35209d5f4",
      "rifAggId": "9ae398fb-c07c-8b90-6643-a45821ff61d1",
      "alertTime": 1624617467693,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto 9ae398fb-c07c-8b90-6643-a45821ff61d1 fallita. Errore: Tipo lotto:'messo' non supportato",
      "alertType": "error"
    }, {
      "alertId": "a0ab7e86-2df8-a338-e1a1-cd74a9fcf760",
      "rifAggId": "646c8069-a8a8-5cc6-3dbb-288397b49192",
      "alertTime": 1624609549596,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0005UO_10392' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "c263fb7d-4d6c-1bf8-41d9-d596b1d75e84",
      "rifAggId": "646c8069-a8a8-5cc6-3dbb-288397b49192",
      "alertTime": 1624609392737,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0005UO_10392' completata",
      "alertType": "ok"
    }, {
      "alertId": "162466a8-a6e5-4958-bd10-c8e89ef5eeef",
      "rifAggId": "646c8069-a8a8-5cc6-3dbb-288397b49192",
      "alertTime": 1624604872726,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '46/2021'",
      "alertType": "ok"
    }, {
      "alertId": "101ccead-5cc9-4539-a72e-5498b9f49287",
      "rifAggId": "6b914b67-9ad8-fe6f-5c95-8dd0281db31e",
      "alertTime": 1624604857329,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 45/2021.",
      "alertType": "ok"
    }, {
      "alertId": "f75d8c07-e6e8-4b6d-af70-bd4d82d34508",
      "rifAggId": "6b914b67-9ad8-fe6f-5c95-8dd0281db31e",
      "alertTime": 1624603225138,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '45/2021'contenente 15 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "efa34327-d773-4305-9010-600d674d3502",
      "rifAggId": "55ab8235-2cf5-489e-a4cd-67b92767f0b7",
      "alertTime": 1624597204961,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "90c275c4-dce2-4be5-9aa3-ae74c0d3b006",
      "rifAggId": "c3dca8ea-d25f-4f71-a1f5-0305347efcfe",
      "alertTime": 1624597204955,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "f918e04e-3637-454e-8d8b-bae81a5f99f9",
      "rifAggId": "813c30a0-4bde-4a45-a277-c40e19e8d823",
      "alertTime": 1624597204923,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "0566c920-ceb3-4a4d-9cdc-622cfc29a9a9",
      "rifAggId": "a7b86412-1363-4568-bf8c-af7c47f3f700",
      "alertTime": 1624510805923,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "c492dcc8-051f-447e-8f63-dba5f67e67ca",
      "rifAggId": "2a3c55b5-c3f7-4a19-8f70-07bc671b79ea",
      "alertTime": 1624510805532,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "d12f4d5a-b93b-4799-9a5d-4089c9fd2229",
      "rifAggId": "dd8626f7-6cf5-49f4-a31e-8505cc731e59",
      "alertTime": 1624510805523,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "aa7b17c4-97ed-0ee9-fe06-7a01f96b8a3c",
      "rifAggId": "b00c2769-ac21-a885-06d6-51e6fa8dbf0b",
      "alertTime": 1624444519960,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA000AUO_10393' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "a7883b75-555f-49d3-a618-b5352fd3fb1c",
      "rifAggId": "b00c2769-ac21-a885-06d6-51e6fa8dbf0b",
      "alertTime": 1624444473135,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '44/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "724cb905-b92c-4d9c-a6a6-79375c2263f6",
      "rifAggId": "2aa9547b-0bb3-43f4-8414-adb881b90b6a",
      "alertTime": 1624424403751,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "8554d848-27b9-498f-837a-1313063e1d9f",
      "rifAggId": "3ae70143-1845-4ccc-a902-512856237877",
      "alertTime": 1624424403662,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "2be34802-e001-4a06-ade5-d453625c0648",
      "rifAggId": "aa5e6d9a-eead-40f5-a7c1-6ace16992954",
      "alertTime": 1624424403008,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "6f0a1841-b3d9-06f4-147d-08bc2feac7e3",
      "rifAggId": "9aa75a07-297a-e705-2ea6-8d31ba3daebf",
      "alertTime": 1624374070745,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10396' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "e2a5e0c6-8cfe-68da-835b-9cbc339d5562",
      "rifAggId": "d9f85afc-2598-3610-cd47-a92b1f4392b1",
      "alertTime": 1624357267637,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10441' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "b2d06dd5-eb64-0aa6-11cd-10c9fb4cca2a",
      "rifAggId": "d9f85afc-2598-3610-cd47-a92b1f4392b1",
      "alertTime": 1624347187611,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10441' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "e148b41d-c4c5-e623-b655-aba08560297b",
      "rifAggId": "48b2c9ca-59ee-195a-fe4a-5eb0d7ad5bf6",
      "alertTime": 1624347151867,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10392' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "7a2ac1ac-9855-47c7-8c96-9e8e955a8c06",
      "rifAggId": "0b3d9e3a-2300-2037-ec20-6795b21713cf",
      "alertTime": 1624342187450,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 43/2021.",
      "alertType": "ok"
    }, {
      "alertId": "8a547e4e-6406-486b-8829-cb227de08acb",
      "rifAggId": "0b3d9e3a-2300-2037-ec20-6795b21713cf",
      "alertTime": 1624342021214,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '43/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "a9ba38d5-f03a-4092-bf56-94202232f34a",
      "rifAggId": "16003bdb-7771-409c-9aaf-17b24857ebae",
      "alertTime": 1624338004471,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "713a7b33-7280-4882-88b1-fe3484829c5c",
      "rifAggId": "b94d84ac-1a2b-4b83-a7cb-f698918a8b7c",
      "alertTime": 1624338004433,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "b4b7c231-bd2b-4486-b5f4-cc7f6e2a88d2",
      "rifAggId": "4a440b6d-9bdf-4a33-a886-a6ed5fb02226",
      "alertTime": 1624338004067,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "4a8cfbb8-f7cd-4900-9fce-99c6cf984489",
      "rifAggId": "4d3e596b-bf05-6686-5222-9ef2723851c6",
      "alertTime": 1624290146602,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 41/2021.",
      "alertType": "ok"
    }, {
      "alertId": "78e1e5ff-76c5-4284-9317-b4694df981e2",
      "rifAggId": "4279fec6-4591-eb4f-b4c6-1606e950de06",
      "alertTime": 1624289930254,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 35/2021.",
      "alertType": "ok"
    }, {
      "alertId": "9f597c9d-5d20-46d8-9583-0485681e42fe",
      "rifAggId": "abecdd6f-71bf-aca9-62eb-67ef6bf3e71b",
      "alertTime": 1624287763848,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 15/2021.",
      "alertType": "ok"
    }, {
      "alertId": "bc0083dc-3a4e-4efa-89fc-c2badc7f230d",
      "rifAggId": "fee7f1be-745c-a655-1be4-524c23bd4ac3",
      "alertTime": 1624278382976,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 38/2021.",
      "alertType": "ok"
    }, {
      "alertId": "625aadde-f026-38b7-6216-4819347f476a",
      "rifAggId": "e5becbd6-f8c7-ee10-ee81-015aff6b7ee6",
      "alertTime": 1624278248486,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0007UO_10393' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "83c2fc00-317e-a328-e73c-721a867c2f81",
      "rifAggId": "4279fec6-4591-eb4f-b4c6-1606e950de06",
      "alertTime": 1624278149838,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10460' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "f3adf930-7f9a-fb04-638e-b4fe543ba118",
      "rifAggId": "d9f85afc-2598-3610-cd47-a92b1f4392b1",
      "alertTime": 1624278035400,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10441' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "48022e5c-edc4-7afb-c6f1-ff08e28600e6",
      "rifAggId": "abecdd6f-71bf-aca9-62eb-67ef6bf3e71b",
      "alertTime": 1624277806178,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10488' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "1f211ee7-827d-463f-b2d6-99dc2a4a3f13",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1624277618439,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 13/2021.",
      "alertType": "ok"
    }, {
      "alertId": "c80ca632-1a7d-129a-2618-ea43a62a732a",
      "rifAggId": "e5becbd6-f8c7-ee10-ee81-015aff6b7ee6",
      "alertTime": 1624269479690,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0007UO_10393' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'File already sent'",
      "alertType": "error"
    }, {
      "alertId": "e5f5f351-722d-4aa5-b92e-ec9a18796deb",
      "rifAggId": "0828e277-4494-8d67-92c0-bd35497d16fa",
      "alertTime": 1624266240817,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 37/2021.",
      "alertType": "ok"
    }, {
      "alertId": "8c8ef6b6-4e7f-42b5-8091-351031fd0c9b",
      "rifAggId": "cad83271-b65d-46d8-b7a1-c9610b37978d",
      "alertTime": 1624251603728,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "b559d089-bb65-4322-b600-1144d43c4241",
      "rifAggId": "8344daff-4398-4691-83d6-2fbe3711aae8",
      "alertTime": 1624251603619,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "eee45c54-5580-4277-9dd9-ed0c5a2ae1c4",
      "rifAggId": "5062973f-3dfe-4109-98f2-a258ad610d32",
      "alertTime": 1624251602712,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "05ec3971-91f8-4be7-80b9-769f080c99b7",
      "rifAggId": "8238eed6-dc6b-9ab8-e69a-8271e433c56b",
      "alertTime": 1624183879551,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 40/2021.",
      "alertType": "ok"
    }, {
      "alertId": "202a6f55-86b1-44f6-a37c-c019b530634b",
      "rifAggId": "caa46fe8-ffa4-4f26-bce9-a37e078c6cb9",
      "alertTime": 1624165206065,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "daa6e275-aece-4de6-b711-8b3fd41d2eb2",
      "rifAggId": "44bcb9cd-1efe-4352-b973-49190bf48671",
      "alertTime": 1624165206041,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "c246fddd-b6a7-4a64-8c45-946c8742264b",
      "rifAggId": "ad1ac5e8-a85a-4d12-aa1c-2c7195dbffdd",
      "alertTime": 1624165206030,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "0ca7e44f-feff-4bd9-b43f-28a30d54b2c1",
      "rifAggId": "0f9d0613-1359-47d7-9076-3cc55e37f0d2",
      "alertTime": 1624078804051,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "ccaadedb-feff-4a96-8d94-ae06267ad4d1",
      "rifAggId": "434fa7ce-0b9b-47c9-a560-ecf5ddb48010",
      "alertTime": 1624078804032,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "34f89f45-9290-47d5-82e3-07fea0a8ae10",
      "rifAggId": "59859ab1-92cb-4b29-b116-bf9668c84747",
      "alertTime": 1624078803839,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "0b460277-4961-a659-770e-59db3d057e0f",
      "rifAggId": "9aa75a07-297a-e705-2ea6-8d31ba3daebf",
      "alertTime": 1624017264285,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10396' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "1ca9912a-dfa4-586a-103c-223d239f5ac1",
      "rifAggId": "9aa75a07-297a-e705-2ea6-8d31ba3daebf",
      "alertTime": 1624017198194,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10396' completata",
      "alertType": "ok"
    }, {
      "alertId": "50b1da82-1d66-4c8f-a5e8-e881ff582384",
      "rifAggId": "9aa75a07-297a-e705-2ea6-8d31ba3daebf",
      "alertTime": 1624017101193,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '42/2021'",
      "alertType": "ok"
    }, {
      "alertId": "3e141cd4-88d4-4a54-9943-912f123f80f1",
      "rifAggId": "4d3e596b-bf05-6686-5222-9ef2723851c6",
      "alertTime": 1624004742275,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '41/2021'",
      "alertType": "ok"
    }, {
      "alertId": "74cd9919-48f1-4e2e-9106-d798339eef1d",
      "rifAggId": "a8087b1f-1cae-496c-a40b-718f4aff8ebe",
      "alertTime": 1623992404720,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "70599b62-88df-4539-a609-16ed4e62f731",
      "rifAggId": "ddc8d5f1-a4d3-43f5-9503-9dc7703290e8",
      "alertTime": 1623992404682,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "033e6dee-bd11-4fd8-b16b-d9916e867804",
      "rifAggId": "ad72ff83-fcae-4980-9167-5d72caa6cc40",
      "alertTime": 1623992404444,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "e6e2e21a-1675-4cda-b767-c7736de7c557",
      "rifAggId": "8238eed6-dc6b-9ab8-e69a-8271e433c56b",
      "alertTime": 1623953684543,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '40/2021'",
      "alertType": "ok"
    }, {
      "alertId": "5bcfd2ed-9fec-47e8-af24-d6bc34833c2b",
      "rifAggId": "0ca330f8-599e-55bc-98f0-a51ea9b019a6",
      "alertTime": 1623953664220,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 39/2021.",
      "alertType": "ok"
    }, {
      "alertId": "6d62d0c5-93d2-4520-bce9-77b773ad2283",
      "rifAggId": "0ca330f8-599e-55bc-98f0-a51ea9b019a6",
      "alertTime": 1623953592023,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '39/2021'",
      "alertType": "ok"
    }, {
      "alertId": "890aedca-3f19-dc3c-4a63-c885afc519a9",
      "rifAggId": "1493dd96-8aa2-28a1-19cb-e19adea4d328",
      "alertTime": 1623953566284,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "4a109bbf-e0a6-4436-85c4-e05d1bd023ff",
      "rifAggId": "98996cce-e371-4216-8d4c-065a244deb77",
      "alertTime": 1623906005023,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "b1f6226e-c6c5-455f-9fd0-cfda3cf47c55",
      "rifAggId": "06172117-09f3-43d6-9b78-cb318d50c023",
      "alertTime": 1623906005010,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "a05ad04e-3801-4fe4-afc6-1d165c8d905b",
      "rifAggId": "e27618bf-fce8-433c-858f-1d5a68080028",
      "alertTime": 1623906004743,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "28804d86-b132-4a5f-95bd-d8b0598012be",
      "rifAggId": "0e375988-facd-f0cd-960a-dfb1ba462449",
      "alertTime": 1623842838620,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 34/2021.",
      "alertType": "ok"
    }, {
      "alertId": "9d88bfda-9149-4de2-aaf2-4506c81cabfd",
      "rifAggId": "fee7f1be-745c-a655-1be4-524c23bd4ac3",
      "alertTime": 1623827514823,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '38/2021'",
      "alertType": "ok"
    }, {
      "alertId": "baebb8a3-eff4-40f2-9e5e-c243b09f2986",
      "rifAggId": "a9768c42-d2c7-4d47-a94c-849d571e1bea",
      "alertTime": 1623819604595,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "bded3aba-ec15-43bb-94b0-c64af9612850",
      "rifAggId": "3f3676dc-13f9-47e3-a2b0-76fa09ecf27e",
      "alertTime": 1623819604593,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "ba09ab77-ada9-4130-ab68-7db200edf79f",
      "rifAggId": "355ee3ef-9778-4128-a1a5-087bbbac74a1",
      "alertTime": 1623819603992,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "b76c4714-5b62-e07b-9c9c-4eaf1787577a",
      "rifAggId": "e5becbd6-f8c7-ee10-ee81-015aff6b7ee6",
      "alertTime": 1623739355802,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0007UO_10393' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "aa3170b8-f8c2-9de5-e347-b5156e8f655a",
      "rifAggId": "0828e277-4494-8d67-92c0-bd35497d16fa",
      "alertTime": 1623739267409,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0008UO_10393' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "0aa8f654-ddc5-4b40-97a2-d6d840f7420f",
      "rifAggId": "0828e277-4494-8d67-92c0-bd35497d16fa",
      "alertTime": 1623737874745,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '37/2021'",
      "alertType": "ok"
    }, {
      "alertId": "f1b6731a-f3fa-40fa-bc1c-b620a8396199",
      "rifAggId": "e5becbd6-f8c7-ee10-ee81-015aff6b7ee6",
      "alertTime": 1623737834418,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '36/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "289ab58c-1eff-44ae-906e-20e2c3e16a3c",
      "rifAggId": "c1f2e624-fc0f-4e94-9a38-95615072c72c",
      "alertTime": 1623733204389,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "beb2229c-27e5-4063-bfcd-097f9d9856b3",
      "rifAggId": "f96c0545-6044-49c3-a2c7-32d44604b03c",
      "alertTime": 1623733204388,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "ea99e13f-cbe0-4407-9ae8-b52ee828fa96",
      "rifAggId": "d8611320-f053-4ae4-ab5c-61162d708c2b",
      "alertTime": 1623733204325,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "3be75573-34e7-c70d-45c6-7610631ca543",
      "rifAggId": "4279fec6-4591-eb4f-b4c6-1606e950de06",
      "alertTime": 1623725147229,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10460' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "a3376a4a-6d48-4918-ad78-f790543a4a6f",
      "rifAggId": "4279fec6-4591-eb4f-b4c6-1606e950de06",
      "alertTime": 1623725079884,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '35/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "2011660a-7a3f-44ce-adbf-1fce2b9a0e81",
      "rifAggId": "737f7e64-c700-4768-b24b-370880cf330c",
      "alertTime": 1623646804284,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "92eda3ff-38d2-4026-97a3-0c190db0e4b8",
      "rifAggId": "4fa39303-6bf3-42dc-8044-35fb8f6671eb",
      "alertTime": 1623646804282,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "db46f1a7-1569-4f7d-b813-480e44d9da1b",
      "rifAggId": "da8d08b5-3f88-4c71-8d9d-5422f47d8bc9",
      "alertTime": 1623646804267,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "fae15c18-d98b-452d-af33-2111597e5618",
      "rifAggId": "0e375988-facd-f0cd-960a-dfb1ba462449",
      "alertTime": 1623600409374,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '34/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "63868182-7384-4e5b-9f19-7a34b957e152",
      "rifAggId": "bff7a71d-65b1-4fc4-9642-2790964ac721",
      "alertTime": 1623560668263,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "f71eafae-81ee-4a40-9ae6-8514610672cf",
      "rifAggId": "c8cd87d0-e87d-40ed-a932-ba0b448ee30f",
      "alertTime": 1623560667798,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "02bca289-2bdb-4bae-9e71-de9422768a0f",
      "rifAggId": "31007ef8-bb7e-4135-b6b1-4f56babdef3e",
      "alertTime": 1623560515925,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "dfc252c7-2ceb-46e2-8908-5a65e3cd893b",
      "rifAggId": "1b1b3857-5277-431e-94d4-f9136145ecfd",
      "alertTime": 1623474005805,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "008648a5-be5d-4f27-9907-b4dbdb2eb8a8",
      "rifAggId": "46d3e74d-3113-411d-a974-e3cd9ccbbfd0",
      "alertTime": 1623474005793,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "25702354-7533-49f5-8899-0b908cda2460",
      "rifAggId": "f50784ac-2661-43bf-86c1-d0b82e8f10cb",
      "alertTime": 1623474005694,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "1646b1cf-2ed2-93cf-1097-43927df9a41a",
      "rifAggId": "b17676c8-f5bc-4667-df95-b93ebf1ff6e7",
      "alertTime": 1623395434323,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "263e942d-646d-4dca-b85f-4ac16499142e",
      "rifAggId": "451ca1b4-0be4-42ea-82db-39509d63c98a",
      "alertTime": 1623387604479,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "9a3f4afb-25c8-4fb2-8936-5b12f9f49af5",
      "rifAggId": "415865f6-5255-47c6-a4ac-644086b6afc8",
      "alertTime": 1623387604417,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "c4e7e27e-5321-412f-aba3-dbb51a7b90c8",
      "rifAggId": "feea5262-73ad-480e-b264-b095d137f755",
      "alertTime": 1623387604150,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "b5c95d5e-51e0-97cd-0334-8bf067d2f852",
      "rifAggId": "48b2c9ca-59ee-195a-fe4a-5eb0d7ad5bf6",
      "alertTime": 1623338612153,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10392' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "bdce8d8f-1b61-034d-8196-e74a2aa070e5",
      "rifAggId": "48b2c9ca-59ee-195a-fe4a-5eb0d7ad5bf6",
      "alertTime": 1623337145412,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10392' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "0b5da50d-0cfe-fae0-45f2-537bd4eed161",
      "rifAggId": "48b2c9ca-59ee-195a-fe4a-5eb0d7ad5bf6",
      "alertTime": 1623332793494,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10392' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "cb072015-f23b-48d0-9fc1-417f1ee24ef6",
      "rifAggId": "ab51d9de-df91-6316-8295-4c6c67b755d0",
      "alertTime": 1623319798119,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 33/2021.",
      "alertType": "ok"
    }, {
      "alertId": "d5242805-4a4c-4019-8418-7e6f595244fe",
      "rifAggId": "ab51d9de-df91-6316-8295-4c6c67b755d0",
      "alertTime": 1623319732064,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '33/2021'contenente 1 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "356cef06-2938-46a8-a117-e5ace5dc19ce",
      "rifAggId": "4fb718e1-c310-c93b-d478-dcf0e8e20e4c",
      "alertTime": 1623319713868,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 32/2021.",
      "alertType": "ok"
    }, {
      "alertId": "6d6b3ba6-51cf-4a30-9631-f4b17346181e",
      "rifAggId": "4fb718e1-c310-c93b-d478-dcf0e8e20e4c",
      "alertTime": 1623319488315,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '32/2021'",
      "alertType": "ok"
    }, {
      "alertId": "0b3ef034-9c90-4abc-9f81-e21ee1c415a9",
      "rifAggId": "8b49d60f-707c-26bd-b48f-87edc948d7a6",
      "alertTime": 1623319397738,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 26/2021.",
      "alertType": "ok"
    }, {
      "alertId": "4e5bc609-5fc7-448f-bc7a-292e623a0bf2",
      "rifAggId": "2d87d81c-39b2-3222-dbaa-760723ac088b",
      "alertTime": 1623317096979,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 31/2021.",
      "alertType": "ok"
    }, {
      "alertId": "9b93089f-3364-4a8c-bdde-e4b9a46b4af6",
      "rifAggId": "2d87d81c-39b2-3222-dbaa-760723ac088b",
      "alertTime": 1623316035454,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '31/2021'contenente 1 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "0ca6e6de-c2cc-48bc-9146-f557815acd5a",
      "rifAggId": "c96ae129-db04-4c3e-8f5d-ed4f370c555e",
      "alertTime": 1623301204597,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "1092c333-2539-468c-9d67-555ae2d2757f",
      "rifAggId": "fb665b3d-6cf6-44d9-85e0-ab95ee3decb7",
      "alertTime": 1623301204562,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "62003b5e-4737-4566-bc36-0e96c18ac1c5",
      "rifAggId": "9bab5694-9c5f-407a-82e1-f4e9d361cb1e",
      "alertTime": 1623301204207,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "f424e647-9bf2-4884-9e9d-f96aa0f4d284",
      "rifAggId": "bcb4a144-cde2-4648-89d2-37721ac7b958",
      "alertTime": 1623214804183,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "20534e1e-2566-4391-93e1-7b8f17c6c338",
      "rifAggId": "0a07dcd2-cefe-4408-8af8-70fc1f53e930",
      "alertTime": 1623214804044,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "46078d7f-b264-4d96-b8a3-2fecf64097c0",
      "rifAggId": "1d616cc0-553c-4239-995a-8bf239144cfe",
      "alertTime": 1623214803944,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "2be2a71e-bbf4-4157-d769-bbfc7e9a6f8d",
      "rifAggId": "ca3bd09b-fb42-0a6d-1566-b87f0e60966b",
      "alertTime": 1623169152183,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10395' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "224b75a0-ff82-c97a-a5ff-06c2444597a9",
      "rifAggId": "d9f85afc-2598-3610-cd47-a92b1f4392b1",
      "alertTime": 1623169057136,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10441' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "905f3e20-8082-439c-ad17-76ff46953ac0",
      "rifAggId": "d9f85afc-2598-3610-cd47-a92b1f4392b1",
      "alertTime": 1623168680161,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '30/2021'contenente 32 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "7b326290-077b-4068-b26c-4a11e5005cde",
      "rifAggId": "ca3bd09b-fb42-0a6d-1566-b87f0e60966b",
      "alertTime": 1623167090788,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '29/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "6083ad5e-b0aa-1d7f-4653-9f66b4ffbb2c",
      "rifAggId": "c636fef8-9398-8f9d-f86c-05a2e167ed77",
      "alertTime": 1623166916172,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "b4b66091-e6f3-45ea-8bdd-20ddbb800240",
      "rifAggId": "57c51128-5040-41d1-81e4-3c01b5972864",
      "alertTime": 1623128404508,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "c415e8ac-492a-4813-a0a7-ae0461a5d303",
      "rifAggId": "118a230d-bb59-4b94-98da-02ce494c3e7f",
      "alertTime": 1623128404443,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "e29a1696-5aa7-4c5e-bf7c-9026e2a68445",
      "rifAggId": "92bea791-0efe-4af8-8b02-1815aa20d023",
      "alertTime": 1623128404411,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "1f3fe687-7b26-481e-ab86-741d889e1835",
      "rifAggId": "9f101522-b388-453c-964c-fdb2322c6ab2",
      "alertTime": 1623042004159,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "46af0805-21a6-417a-b625-0b415e2e345b",
      "rifAggId": "2da5f532-0891-4395-a458-e482abc04cbb",
      "alertTime": 1623042004151,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "9ea9a279-efbb-465e-b152-1ce3c7f903f4",
      "rifAggId": "cccbe946-e2b1-4546-bb08-ba0259f940e4",
      "alertTime": 1623042004144,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "8951f6c6-a893-78c4-c7db-5228a7984312",
      "rifAggId": "d41db9c4-497b-8450-9099-876754b08295",
      "alertTime": 1622965848358,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0003UO_10347' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "7c6b548e-c9e5-4f07-8c29-1df0e10fd379",
      "rifAggId": "d41db9c4-497b-8450-9099-876754b08295",
      "alertTime": 1622965767769,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '28/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "d82ede8d-71f1-46ca-9812-e3427bd0efa5",
      "rifAggId": "23616594-8bb5-198f-c62b-40a054419ab3",
      "alertTime": 1622965711332,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 27/2021.",
      "alertType": "ok"
    }, {
      "alertId": "b79d45f7-2e39-4c2b-9b86-ddbb6afccc54",
      "rifAggId": "23616594-8bb5-198f-c62b-40a054419ab3",
      "alertTime": 1622965375463,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '27/2021'",
      "alertType": "ok"
    }, {
      "alertId": "fbf57d09-e6e9-4e49-8e92-855d028574c2",
      "rifAggId": "9a8bf03f-1b65-4bfe-9b22-1c07885688ef",
      "alertTime": 1622955606247,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "9bee2daa-9deb-4359-ab3b-fccf333847f0",
      "rifAggId": "291d4314-c73e-45c2-ad9f-5242370e37f4",
      "alertTime": 1622955606163,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "f80ffb2f-2c52-4521-8945-665d0d2ddbbc",
      "rifAggId": "5cdac9e9-fde0-439c-8e71-ef61631d8a6c",
      "alertTime": 1622955605966,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "bff12d57-abea-4901-a8eb-ef45b9901612",
      "rifAggId": "d270341f-b77c-42f9-b5b7-e49b6d6e2b0f",
      "alertTime": 1622869204388,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "c5f9e4f5-75f7-484a-a157-08fd4bc6212b",
      "rifAggId": "da2f4bd7-a416-456c-bab0-504759b54bd0",
      "alertTime": 1622869204325,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "a6acf0cf-7d6c-4dbb-92ad-aaf8f96d5e5b",
      "rifAggId": "d3efaec9-ab95-4e4d-85e4-0970e5225971",
      "alertTime": 1622869204063,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "885d838c-7ebf-40ab-8b65-d655509ab4de",
      "rifAggId": "8b49d60f-707c-26bd-b48f-87edc948d7a6",
      "alertTime": 1622863933956,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '26/2021'",
      "alertType": "ok"
    }, {
      "alertId": "d456897d-c301-be71-b127-1d57a6745a00",
      "rifAggId": "d5ee26bc-d82f-e12c-b1d3-c9f93fec486f",
      "alertTime": 1622820329611,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "2c2b3451-9679-4001-9eb7-7cf362fb6465",
      "rifAggId": "417182ec-0250-0ec5-56ac-d37459333c98",
      "alertTime": 1622820214401,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 25/2021.",
      "alertType": "ok"
    }, {
      "alertId": "93138421-6b69-49fc-9f34-d969428b44d1",
      "rifAggId": "417182ec-0250-0ec5-56ac-d37459333c98",
      "alertTime": 1622820100479,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '25/2021'",
      "alertType": "ok"
    }, {
      "alertId": "6cbd9865-9688-5d35-89ab-a7e291bb4192",
      "rifAggId": "ad37e1ef-c265-71ff-3508-9186237a641d",
      "alertTime": 1622820037823,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "02c61f6b-75b6-486a-b0ee-125ef7fd4c48",
      "rifAggId": "505de2c5-b674-d44b-1594-e04ffd711fb5",
      "alertTime": 1622808773480,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 23/2021.",
      "alertType": "ok"
    }, {
      "alertId": "373cd694-85be-4ce1-b045-ab24f84010d3",
      "rifAggId": "bf56acc3-f7ec-1d0c-4b2d-ca783e3e78e6",
      "alertTime": 1622808133770,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 24/2021.",
      "alertType": "ok"
    }, {
      "alertId": "17ee0948-2c87-404d-be12-de3c0cdfdc39",
      "rifAggId": "bf56acc3-f7ec-1d0c-4b2d-ca783e3e78e6",
      "alertTime": 1622808075206,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '24/2021'contenente 2 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "d1e795bb-c85b-4228-a6fc-5dcf79437f3a",
      "rifAggId": "505de2c5-b674-d44b-1594-e04ffd711fb5",
      "alertTime": 1622806018121,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '23/2021'",
      "alertType": "ok"
    }, {
      "alertId": "104e33a9-fa0c-c4cd-75dd-8a748a70ce34",
      "rifAggId": "48b2c9ca-59ee-195a-fe4a-5eb0d7ad5bf6",
      "alertTime": 1622801682824,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10392' fallita. Errore:Il lotto '48b2c9ca-59ee-195a-fe4a-5eb0d7ad5bf6' ha  più di un allegato digitale 'lotto_archivio' associato",
      "alertType": "error"
    }, {
      "alertId": "2f53351c-b6f1-4f2a-b3ce-56f03c9e3643",
      "rifAggId": "48b2c9ca-59ee-195a-fe4a-5eb0d7ad5bf6",
      "alertTime": 1622801663646,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10392' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "11a935c3-3cf4-44f5-b9ba-3d86656b5621",
      "rifAggId": "48b2c9ca-59ee-195a-fe4a-5eb0d7ad5bf6",
      "alertTime": 1622795736776,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '22/2021'contenente 27 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "f783dcc4-0fe1-49fb-aadb-40454641bb2c",
      "rifAggId": "5d34d052-f3e2-7e72-701c-309665513361",
      "alertTime": 1622795579012,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 21/2021.",
      "alertType": "ok"
    }, {
      "alertId": "15a92c72-6989-4fc4-bd1b-12ff58a97032",
      "rifAggId": "5d34d052-f3e2-7e72-701c-309665513361",
      "alertTime": 1622794285378,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '21/2021'contenente 28 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "7fb9dd5a-5824-46de-b517-9dc5c6cb1f64",
      "rifAggId": "8b267c22-75d2-4888-be77-51b0b54b30a1",
      "alertTime": 1622782803568,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "0ff97f9e-3863-4046-ac54-161f7771c0c4",
      "rifAggId": "f78f060f-4a19-4d87-afbd-619304250274",
      "alertTime": 1622782803551,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "5049e123-7ffa-4604-9ef2-786e01434820",
      "rifAggId": "65c9d18d-ab69-43bd-bc1c-025f3f1d4b88",
      "alertTime": 1622782803435,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "bd9f5048-0602-c589-96d7-11e08c8f4612",
      "rifAggId": "dea17615-5a64-b253-57a1-0f0956643c5b",
      "alertTime": 1622774009771,
      "tipoAggregato": "LOTTO",
      "messaggio": "Cancellazione del lotto dea17615-5a64-b253-57a1-0f0956643c5b fallita. Errore: Aggregate with identifier [dea17615-5a64-b253-57a1-0f0956643c5b] not found. It has been deleted.",
      "alertType": "error"
    }, {
      "alertId": "1cbd7ddb-5257-4047-920b-aad3e47971c3",
      "rifAggId": "dea17615-5a64-b253-57a1-0f0956643c5b",
      "alertTime": 1622774002329,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 20/2021.",
      "alertType": "ok"
    }, {
      "alertId": "d6a917c7-0465-49f7-99dd-d7783113b34b",
      "rifAggId": "dea17615-5a64-b253-57a1-0f0956643c5b",
      "alertTime": 1622773858505,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '20/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "26e3dec3-c6c3-4624-b1b7-98d85eaba913",
      "rifAggId": "741ac3ca-9986-894f-dbea-c417f6d53c25",
      "alertTime": 1622729423132,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 19/2021.",
      "alertType": "ok"
    }, {
      "alertId": "5c98d35c-94d0-4551-94d3-63dfdf3607e9",
      "rifAggId": "741ac3ca-9986-894f-dbea-c417f6d53c25",
      "alertTime": 1622729256879,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '19/2021'",
      "alertType": "ok"
    }, {
      "alertId": "4089d785-7e80-495f-84c2-5f08ab53ef33",
      "rifAggId": "5cdecd62-5a05-867c-4047-678e80c089b7",
      "alertTime": 1622713883969,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 18/2021.",
      "alertType": "ok"
    }, {
      "alertId": "99e0ded1-44f1-3d30-c0b3-dbff3d349fab",
      "rifAggId": "5cdecd62-5a05-867c-4047-678e80c089b7",
      "alertTime": 1622713636409,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0004UO_10409' completata",
      "alertType": "ok"
    }, {
      "alertId": "28ec50f4-3647-4201-b8d7-5ee252fef889",
      "rifAggId": "5cdecd62-5a05-867c-4047-678e80c089b7",
      "alertTime": 1622713550091,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '18/2021'",
      "alertType": "ok"
    }, {
      "alertId": "67726b66-2bcc-2a65-de7f-a66a775c3b42",
      "rifAggId": "abecdd6f-71bf-aca9-62eb-67ef6bf3e71b",
      "alertTime": 1622713350184,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10488' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "438f914b-7079-4921-9b32-303e14d9c8e4",
      "rifAggId": "e8ac0346-7267-8c6d-6c6e-206a07a42760",
      "alertTime": 1622710603715,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 17/2021.",
      "alertType": "ok"
    }, {
      "alertId": "9db5d7e2-1a11-4cab-83d5-9211dd4db38f",
      "rifAggId": "e8ac0346-7267-8c6d-6c6e-206a07a42760",
      "alertTime": 1622710534819,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '17/2021'",
      "alertType": "ok"
    }, {
      "alertId": "ea521b91-b4d3-cf7f-a8af-b5920f62eb8a",
      "rifAggId": "a821b16c-e979-882a-b5e0-19bc734525cf",
      "alertTime": 1622710363819,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "a2a32b04-81c1-45c3-a738-cd474f7dc404",
      "rifAggId": "5c608bf1-6e4c-8ee6-7403-5a7825084376",
      "alertTime": 1622710337034,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 16/2021.",
      "alertType": "ok"
    }, {
      "alertId": "9dfbcbda-fd2d-46aa-b587-0718d715e034",
      "rifAggId": "5c608bf1-6e4c-8ee6-7403-5a7825084376",
      "alertTime": 1622710172753,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '16/2021'",
      "alertType": "ok"
    }, {
      "alertId": "a50ac8e7-b7e4-68c4-478a-735d940a8a64",
      "rifAggId": "e972ac03-7ab0-89f7-b610-fd3b9a8af51c",
      "alertTime": 1622710070052,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "a120465f-feca-00e2-e5ee-f3ca1b6dbcfb",
      "rifAggId": "abecdd6f-71bf-aca9-62eb-67ef6bf3e71b",
      "alertTime": 1622708668101,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0001UO_10488' completata",
      "alertType": "ok"
    }, {
      "alertId": "866a4057-c2ec-4f9a-814c-ee98a5ccfce3",
      "rifAggId": "abecdd6f-71bf-aca9-62eb-67ef6bf3e71b",
      "alertTime": 1622706064302,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '15/2021'",
      "alertType": "ok"
    }, {
      "alertId": "add6c15d-a5c0-f266-e580-55bac7ac90aa",
      "rifAggId": "649dd052-73d4-9d15-c6ca-d1b6cc08bbfc",
      "alertTime": 1622705958309,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto 649dd052-73d4-9d15-c6ca-d1b6cc08bbfc fallita. Errore: Lotto non corretto. La destinazione non rispetta il Tipo di Lotto impostato",
      "alertType": "error"
    }, {
      "alertId": "b1f53af4-0c82-4b9f-bf2b-3157636e030c",
      "rifAggId": "b30c298f-d900-4096-91ac-24904409fd67",
      "alertTime": 1622696403400,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "4953576d-d643-42c0-a007-e94dd4eb4cb4",
      "rifAggId": "c707cf0b-b7c3-4db2-a206-4ebd90b31bb6",
      "alertTime": 1622696403368,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "a5a0782c-6a0a-405b-b11d-e42a7f903be3",
      "rifAggId": "6514f21f-50d7-4737-b522-ab0a41e16bbb",
      "alertTime": 1622696403217,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "acf6daca-fce1-41fa-a1b5-f28949590852",
      "rifAggId": "18b85c8c-6632-8c80-a492-8047a731398a",
      "alertTime": 1622655250659,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 14/2021.",
      "alertType": "ok"
    }, {
      "alertId": "9855c434-459c-47e3-bd78-5ecd5014e88d",
      "rifAggId": "18b85c8c-6632-8c80-a492-8047a731398a",
      "alertTime": 1622655181693,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '14/2021'",
      "alertType": "ok"
    }, {
      "alertId": "04344f9d-8cfa-2318-9caa-dbe8aab6db88",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654501190,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0002UO_10352' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "fd01c39f-5cdf-1ba9-8fb2-2f14abb54c4c",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654433838,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "c159be92-7d20-5010-8597-56e1d380c5b6",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654431900,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "bc594b10-9723-c646-a767-93dc47fff6d9",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654429614,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione delle spedizioni al lotto non possibile: Non e' stato possibile associare le spedizioni al Lotto. Nessuna spedizione disponibile trovata con i parametri impostati",
      "alertType": "error"
    }, {
      "alertId": "d2b2835d-1676-21a3-a402-ab998bdc0f39",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654427941,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "e35f56ea-66a3-e84b-2bc7-74ec75344dc5",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654425687,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "98fcb956-4bb4-7f59-0c6a-9859591ee63b",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654423389,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione delle spedizioni al lotto non possibile: Non e' stato possibile associare le spedizioni al Lotto. Nessuna spedizione disponibile trovata con i parametri impostati",
      "alertType": "error"
    }, {
      "alertId": "82000a51-2c1b-9a9a-dde9-5752ac4d3215",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654420362,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione delle spedizioni al lotto non possibile: Non e' stato possibile associare le spedizioni al Lotto. Nessuna spedizione disponibile trovata con i parametri impostati",
      "alertType": "error"
    }, {
      "alertId": "0c9c1337-12de-cfaa-04a3-68669777e8c2",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654420231,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "2744a5ac-3cf5-f2c0-ec06-4defed988e7d",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654415859,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "0194215e-c217-5c8f-9e99-cb32b8aa698d",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654411691,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "9e6cbcdc-5d65-aabb-ea40-c7ea1d70a45d",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654409186,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "7d3545fe-315b-718f-a43a-30fcf3ecfcb6",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654404985,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "2166ed11-da70-7412-f07f-38b8e05c3d97",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654402157,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "53f1cfc1-da91-df56-9153-0508cab523ea",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654397992,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "e00be9b1-6dab-abe4-2d7f-1badf59d1be9",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654394229,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "c28aa021-951f-7860-dc34-70ec5b4a0f6e",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654390918,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'ARMA0002UO_10352' completata",
      "alertType": "ok"
    }, {
      "alertId": "5571705b-fec7-4b4a-8915-c9548debf12c",
      "rifAggId": "259a262d-deff-5775-5ef1-4c1f6db746dc",
      "alertTime": 1622654363561,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 12/2021.",
      "alertType": "ok"
    }, {
      "alertId": "15135b21-fb29-a4ed-4fe0-eac8ebb04897",
      "rifAggId": "259a262d-deff-5775-5ef1-4c1f6db746dc",
      "alertTime": 1622654345356,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10352' fallita. Errore:Il lotto '259a262d-deff-5775-5ef1-4c1f6db746dc' ha  più di un allegato digitale 'lotto_archivio' associato",
      "alertType": "error"
    }, {
      "alertId": "c4c908d8-63cf-ee16-2497-6fe7c1f63fca",
      "rifAggId": "e1f78bf0-7ff6-8b3b-1952-86e4d5447920",
      "alertTime": 1622654310858,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "f768b7ab-d9db-d443-882c-f256bbefde25",
      "rifAggId": "259a262d-deff-5775-5ef1-4c1f6db746dc",
      "alertTime": 1622654109841,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10352' fallita. Errore:Il lotto '259a262d-deff-5775-5ef1-4c1f6db746dc' ha  più di un allegato digitale 'lotto_archivio' associato",
      "alertType": "error"
    }, {
      "alertId": "036415f2-e0e3-43e3-81d4-b65bfeb1388e",
      "rifAggId": "24cdab97-88dc-0b97-fd17-43ddfcba7a46",
      "alertTime": 1622654011562,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '13/2021'",
      "alertType": "ok"
    }, {
      "alertId": "88932c06-55e1-cac0-ef80-9c8f6f866cff",
      "rifAggId": "259a262d-deff-5775-5ef1-4c1f6db746dc",
      "alertTime": 1622653916680,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10352' fallita. Errore:Il lotto '259a262d-deff-5775-5ef1-4c1f6db746dc' ha  più di un allegato digitale 'lotto_archivio' associato",
      "alertType": "error"
    }, {
      "alertId": "77797793-5642-2775-90a1-2d63c378b30d",
      "rifAggId": "259a262d-deff-5775-5ef1-4c1f6db746dc",
      "alertTime": 1622653717622,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10352' fallita. Errore:Il lotto '259a262d-deff-5775-5ef1-4c1f6db746dc' ha  più di un allegato digitale 'lotto_archivio' associato",
      "alertType": "error"
    }, {
      "alertId": "7d31b6b7-451d-95e3-6b1b-74df3471ad66",
      "rifAggId": "259a262d-deff-5775-5ef1-4c1f6db746dc",
      "alertTime": 1622653706106,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'ARMA0001UO_10352' terminato.",
      "alertType": "ok"
    }, {
      "alertId": "715699b6-e8b5-411f-ba97-0e9be8ab75cd",
      "rifAggId": "259a262d-deff-5775-5ef1-4c1f6db746dc",
      "alertTime": 1622653502363,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '12/2021'contenente 13 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "90c01a36-7120-4be1-8c4a-ad54ec33ea7a",
      "rifAggId": "625738dd-e0a0-487c-a58a-d409f3cd53f2",
      "alertTime": 1622610004766,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "bd2824f9-dea4-41da-94d5-fcdb3d3683d9",
      "rifAggId": "e66f3f50-717b-4330-a0a1-c895557e64bd",
      "alertTime": 1622610004761,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "c31de52f-069d-4faa-a150-fb7a31fc65eb",
      "rifAggId": "58d74fe7-6e56-428a-8807-9735ec0f2730",
      "alertTime": 1622610004756,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione. Nessun flusso presente da elaborare",
      "alertType": "warning"
    }, {
      "alertId": "638bb80c-cbe5-b040-c05e-d70e6db1bf7d",
      "rifAggId": "23cfdd02-bd7f-f9ae-9c9e-5221c926c6e6",
      "alertTime": 1622562240768,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto 23cfdd02-bd7f-f9ae-9c9e-5221c926c6e6 fallita. Errore: Tipo lotto:'visura' non supportato",
      "alertType": "error"
    }, {
      "alertId": "fe7614fc-d871-470f-baba-13e45aac858f",
      "rifAggId": "6057a467-eebd-4621-b994-646f5505fb0a",
      "alertTime": 1622523603482,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "7789025e-d2fd-44b9-8a65-c34c5a23ea52",
      "rifAggId": "49d49b7e-ef53-48a5-9a6e-65d71f6364da",
      "alertTime": 1622523600594,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "92b836b1-538a-46ac-a1f9-00e1344e115d",
      "rifAggId": "5e5bfa57-fb29-4d8b-a903-e233073fe0b2",
      "alertTime": 1622523600582,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "6346babe-bf66-478a-96b1-96aa1ad934dc",
      "rifAggId": "8cb001eb-e4cd-44f1-9124-07049d6c1fa4",
      "alertTime": 1622437200613,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "7d358054-cd28-4e33-8437-07653ddc8ca0",
      "rifAggId": "a3936855-40b1-4060-982a-7acb29a7f4ce",
      "alertTime": 1622437200602,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "c41b55eb-ebfe-4886-bd78-48c8ef096e07",
      "rifAggId": "49952c70-343e-4209-ad09-270bb7dc0539",
      "alertTime": 1622437200583,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "f0ccd79f-0098-9499-778b-223b74bcc085",
      "rifAggId": "8b22e5a3-1eff-3581-4ebc-8297f4fc249f",
      "alertTime": 1622369455362,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "c7fa0866-b0ee-b9fe-8ebe-b79aae079715",
      "rifAggId": "c54ac9f3-74b0-3865-b19a-576a91021a4c",
      "alertTime": 1622369127984,
      "tipoAggregato": "LOTTO",
      "messaggio": "Creazione del lotto non possibile: Non e' possibile creare il nuovo Lotto. Non ci sono verbali pronti per essere spediti ai rispettivi soggetti",
      "alertType": "error"
    }, {
      "alertId": "f6afa2d6-a0bc-4a67-82d4-73f89eceb8ea",
      "rifAggId": "52dfc534-327a-42e8-955d-49646738e22a",
      "alertTime": 1622350800702,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "74e75f8e-9931-464a-9442-36c08c16d0b3",
      "rifAggId": "05ad95e9-c5ac-49e5-8485-d4201ffe2596",
      "alertTime": 1622350800607,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "f6b1b58d-c14e-4170-bcd3-4d3b497f4702",
      "rifAggId": "13393612-9a19-4cb9-bd1c-e7a621f4bbd5",
      "alertTime": 1622350800581,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "676975ff-f6b3-4576-b9ad-83bed79bc2b8",
      "rifAggId": "4d1e758e-bdc8-46ec-ad1c-69e29ce1d320",
      "alertTime": 1622264400554,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "3713b6ef-c4b5-422a-9ccc-041a34ead7a2",
      "rifAggId": "7036dde0-2837-4a4f-9152-471aee91e596",
      "alertTime": 1622264400544,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "95570968-e320-413c-b6c0-4cf858d56823",
      "rifAggId": "cc20a304-a3c8-4605-a2d6-07845db30c6a",
      "alertTime": 1622264400534,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "0919d244-ef04-418e-9c73-03add385af9e",
      "rifAggId": "ef7bfaeb-2a6c-42dd-ac4f-5a74121f5b8d",
      "alertTime": 1622178000806,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "b3809714-cad6-4581-8e49-a8fdaac3b3e1",
      "rifAggId": "43d792be-4418-4e6d-91c4-06f2be0efec0",
      "alertTime": 1622178000733,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "f58fafda-241c-4b01-9464-2ac6e38f3dcd",
      "rifAggId": "6de9d53b-2b52-40a0-8c0f-3452c93631ae",
      "alertTime": 1622178000709,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "c70d8baa-e017-42a4-b383-93c8c8e86fb4",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1622101202270,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 11/2021.",
      "alertType": "ok"
    }, {
      "alertId": "bedafde5-6c09-423e-bdef-9a22b2b89d61",
      "rifAggId": "02ffed8a-3125-4295-b11e-63360cf678d5",
      "alertTime": 1622091601018,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "888caaf2-dffa-4745-abc2-6cffa4bbea26",
      "rifAggId": "91485f60-7fb3-4ae5-a8d7-9a256da7570f",
      "alertTime": 1622091600994,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "7a815afa-d98c-46de-94ab-3f85f86de25c",
      "rifAggId": "daa1ec3d-3751-4f03-bc88-7c5f34abd578",
      "alertTime": 1622091600666,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "586c66e1-dce3-4604-b619-c94dee7331c6",
      "rifAggId": "491e1855-3ad1-49b3-9a4d-6981b1f4391d",
      "alertTime": 1622005200624,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "870bf6d9-5931-4951-a9a7-9d9150afc375",
      "rifAggId": "3e5e3bed-f09f-4348-997a-cb1576e22546",
      "alertTime": 1622005200608,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "9c91962d-5a5c-4b9f-b078-c551316a0355",
      "rifAggId": "a7081207-d578-4b10-8d34-85a65f8656b4",
      "alertTime": 1622005200602,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "2d438d11-b848-285b-d077-0a6156f18af8",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1621948934914,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'F2410001UO_10408' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'Invalid file name'",
      "alertType": "error"
    }, {
      "alertId": "86192eec-bfad-b3d9-5ae2-33d49dd88673",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1621948857570,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'F2410001UO_10408' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'Invalid file name'",
      "alertType": "error"
    }, {
      "alertId": "9b4534e9-85bf-8fb7-75bd-5f71efadcaeb",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1621948828587,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'F2410001UO_10408' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'Invalid file name'",
      "alertType": "error"
    }, {
      "alertId": "30509013-866f-f4a5-b755-6e8764d24d4f",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1621948577026,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'F2410001UO_10408' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'Invalid file name'",
      "alertType": "error"
    }, {
      "alertId": "95cc47e5-ee27-4259-dc34-42419ff542d6",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1621948523653,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'F2410001UO_10408' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'Invalid file name'",
      "alertType": "error"
    }, {
      "alertId": "4fb3e218-3ba1-1e67-9fa1-ce290bc21ad4",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1621948484168,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'F2410001UO_10408' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'Invalid file name'",
      "alertType": "error"
    }, {
      "alertId": "759c42d9-d35e-f4c0-9d9d-19ad040d38de",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1621948368763,
      "tipoAggregato": "LOTTO",
      "messaggio": "Inoltro del lotto 'F2410001UO_10408' fallita. Errore:Esito della chiamata a RPOnline non positivo. Esito: '-1' Descrizione:'Invalid file name'",
      "alertType": "error"
    }, {
      "alertId": "fa23c041-337a-d143-643f-2515dd366016",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1621948197252,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'F2410001UO_10408' completata",
      "alertType": "ok"
    }, {
      "alertId": "6b0665ea-a6b1-4b1c-857c-57b6fea40ac0",
      "rifAggId": "a15db6fa-9d79-14c9-0b47-3b78a42d1cc4",
      "alertTime": 1621948049633,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '11/2021'",
      "alertType": "ok"
    }, {
      "alertId": "29302cd0-841b-4e60-8c13-55b57fd4a979",
      "rifAggId": "a1d2f641-602f-4bbc-8775-786e9387971b",
      "alertTime": 1621918803418,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "58fe0d25-333c-473d-85f0-97d193790acc",
      "rifAggId": "ed084e79-b1da-4d23-bba1-641ba631dda1",
      "alertTime": 1621918800474,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "04d2587d-5cc0-42c5-8ffa-b737e898b648",
      "rifAggId": "ba7c5362-a502-4d69-a1a3-bb27348d20ca",
      "alertTime": 1621918800368,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "e5d8901c-9112-4e33-8ffe-4e82c53f8d06",
      "rifAggId": "de9d36d9-0010-49d1-90b7-e077c501becb",
      "alertTime": 1621832400581,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "ab6c534f-2113-4244-8903-1b6d4f901559",
      "rifAggId": "d6ad4495-7216-45be-ac53-896917ea71f2",
      "alertTime": 1621832400573,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "2b95aa97-9259-4d61-b1c1-86c166ded8fa",
      "rifAggId": "fb6c68cf-b78c-45ed-9a6d-5f683b6162f4",
      "alertTime": 1621832400558,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "2c4b3080-64e1-4f69-8e18-0e715586d2fa",
      "rifAggId": "8ea5f067-115d-42b9-8b06-947bb3d0dfa5",
      "alertTime": 1621746000645,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "ead86cd0-48d9-4319-8b45-09578392767d",
      "rifAggId": "45113d37-9c80-48fb-9f1b-dd3a8b5c76de",
      "alertTime": 1621746000632,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "3999c58b-b470-47e0-8c46-85d6a70db2bc",
      "rifAggId": "ef51156b-556c-446d-9fbf-f90ae3071556",
      "alertTime": 1621746000531,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "3617d2eb-bb99-4ae9-aee6-18f08eab459b",
      "rifAggId": "e7be7dc0-53fb-4d8c-9fe4-f80cd00be0fe",
      "alertTime": 1621659600526,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "b415d3ed-5a0c-4d3b-929c-64d2a6b0e470",
      "rifAggId": "400d9e6f-c683-4ffd-8536-d69bab13fd63",
      "alertTime": 1621659600487,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "2367230a-5281-4f42-b471-8f98462608ef",
      "rifAggId": "da1e9a8f-3d8a-4d7a-aba8-7dbe6d38705e",
      "alertTime": 1621659600460,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "91a28494-3aee-4f0e-bd89-a37b2f544591",
      "rifAggId": "3a320fc3-2e67-4cc1-b606-a49ebca74a27",
      "alertTime": 1621573200779,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "20c6cde0-88c8-4f27-a69f-3335439c1368",
      "rifAggId": "f0131dd1-a4b7-4152-87cd-31ab1c0f6c7a",
      "alertTime": 1621573200648,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "b69b1cbe-abd6-444c-8759-df47ad2d38f5",
      "rifAggId": "721a04a1-1ea6-4fe0-8f7f-8b8077b973dd",
      "alertTime": 1621573200643,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "1dbbe0a5-6d66-47a0-91db-6229eba538e7",
      "rifAggId": "5b9cc79c-3a4e-43a7-84f3-0f1cdab0ee99",
      "alertTime": 1621486800506,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "c53ccb03-ef6b-4c95-9f5d-0700b1973529",
      "rifAggId": "107cacb5-03f4-4680-8fbf-4eff08110cd4",
      "alertTime": 1621486800498,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "4c88ebb5-75a9-4e13-9323-a3de57af4bbc",
      "rifAggId": "2218cdae-e007-4433-bffd-a6265972ed9f",
      "alertTime": 1621486800478,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "06559947-facd-4754-8835-2fd02042c7f2",
      "rifAggId": "39a081bc-1a63-48fc-9dbc-84513fbe1d79",
      "alertTime": 1621400400620,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "76becfee-18b6-4ebe-8c43-abf9686a1b12",
      "rifAggId": "99cdb9e3-f3ca-49e5-aed2-80c2e6c6d000",
      "alertTime": 1621400400616,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "7648bb99-dc55-463e-8c8e-126b1ac9e722",
      "rifAggId": "98e7abc4-c94e-4a94-8f4e-7c0910853ae7",
      "alertTime": 1621400400606,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "90a9020d-288b-41af-a432-6353b78e6655",
      "rifAggId": "e0aed553-20a3-4ba1-b207-92a7519a1d06",
      "alertTime": 1621314000790,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "b0c025e1-73c2-4ccf-a4d8-036318e0768f",
      "rifAggId": "7cdebee0-6878-40ae-ad92-78601c942908",
      "alertTime": 1621314000526,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "163a1891-1042-42c7-899d-cc98b917b434",
      "rifAggId": "62938a61-44eb-4286-b88e-69d7b3da1c10",
      "alertTime": 1621314000490,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "bbf15366-6d58-49ed-af58-ba5c4abf9293",
      "rifAggId": "d654146b-dee3-4544-95c0-f97336a3fee9",
      "alertTime": 1621227600887,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "83ebff37-19a6-4b6b-a75b-45ecfed021a5",
      "rifAggId": "ac2c6b67-0f78-4740-817a-010968606e7e",
      "alertTime": 1621227600681,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "a1c359d3-61ab-478d-9619-7b6d0da43747",
      "rifAggId": "1a7ec0ff-aca9-475f-bcbb-3f54dabf2a55",
      "alertTime": 1621227600665,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "9a3fe910-f1e3-4f88-85cd-f7432a3c9d01",
      "rifAggId": "3bb89cec-f340-4b27-affb-0499d4e550de",
      "alertTime": 1621141203396,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "7d1680dc-35b8-4f40-8794-118073d7edb1",
      "rifAggId": "5f0df5f4-4f1e-4832-96ad-1a8d611fb5e5",
      "alertTime": 1621141200543,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "07fafaa2-1864-4911-970f-cf364dad6d40",
      "rifAggId": "39d656e5-4c15-405d-9c1f-8f9901df2653",
      "alertTime": 1621141200466,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "0eb920dd-e8f1-4037-8940-4c250fb35d04",
      "rifAggId": "c0d5958e-6d5f-e8fc-1f2b-6d328a500726",
      "alertTime": 1621075052366,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 10/2021.",
      "alertType": "ok"
    }, {
      "alertId": "458a6b1f-8228-486b-b416-775f90890c58",
      "rifAggId": "58295224-c43d-2c75-7768-38ec5c533b2c",
      "alertTime": 1621074997797,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 9/2021.",
      "alertType": "ok"
    }, {
      "alertId": "a812f56c-3d64-46ac-b9cb-219411590bcd",
      "rifAggId": "c0d5958e-6d5f-e8fc-1f2b-6d328a500726",
      "alertTime": 1621074856019,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '10/2021'contenente 3 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "0fc5b623-2218-4122-975e-61fb588cd0fd",
      "rifAggId": "4154ce29-290a-fbf0-cdcb-c00d7dfa79b2",
      "alertTime": 1621074838347,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 8/2021.",
      "alertType": "ok"
    }, {
      "alertId": "4761d97b-a64a-4546-92df-53f5e492948e",
      "rifAggId": "58295224-c43d-2c75-7768-38ec5c533b2c",
      "alertTime": 1621074780013,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '9/2021'contenente 163 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "d7b9bce1-3921-5acd-7f19-2ebf65e61bb1",
      "rifAggId": "4154ce29-290a-fbf0-cdcb-c00d7dfa79b2",
      "alertTime": 1621073161960,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'F2410008' completata",
      "alertType": "ok"
    }, {
      "alertId": "dc3160d0-5884-f682-a23a-8a6763766458",
      "rifAggId": "4154ce29-290a-fbf0-cdcb-c00d7dfa79b2",
      "alertTime": 1621073141316,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione delle spedizioni al lotto non possibile: Errore durante l'estrazione delle Spedizioni. Una o più spedizioni non valide. Non e' stato possibile completare l'operazione",
      "alertType": "error"
    }, {
      "alertId": "6b4313fb-173d-a1f5-7ece-c63ddad96bd0",
      "rifAggId": "4154ce29-290a-fbf0-cdcb-c00d7dfa79b2",
      "alertTime": 1621073131087,
      "tipoAggregato": "LOTTO",
      "messaggio": "Assegnazione spedizioni al lotto 'F2410008' completata",
      "alertType": "ok"
    }, {
      "alertId": "7425b1be-b635-4167-96f7-1149ad1e738d",
      "rifAggId": "4154ce29-290a-fbf0-cdcb-c00d7dfa79b2",
      "alertTime": 1621072872124,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '8/2021'",
      "alertType": "ok"
    }, {
      "alertId": "512a323e-fa0a-4a27-87e8-e175f6d2ce00",
      "rifAggId": "4890f1ca-dc81-4322-9cda-57c8c8e31de1",
      "alertTime": 1621054800571,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "301c4389-ad1b-457b-8723-52b97e13f269",
      "rifAggId": "0b87e9b2-b076-44c4-9577-89f3974cbd04",
      "alertTime": 1621054800557,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "c1658795-ea3c-44a1-9dd5-349d94ff1da2",
      "rifAggId": "dea9c1ac-64e5-46ce-a94b-0aabc0db1d94",
      "alertTime": 1621054800426,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "869af9c3-bbed-4103-ae30-5190e8cefd65",
      "rifAggId": "8fdffdfa-a372-47f0-8fc1-baa7c6a85086",
      "alertTime": 1620968403543,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "54cc0f54-01bb-4bc5-b413-17ba46dd090c",
      "rifAggId": "b957633f-aa5e-416b-bb6b-bb0d143be111",
      "alertTime": 1620968400565,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "763e66ca-320b-452e-b394-b7e93f20e284",
      "rifAggId": "825164f3-97a5-4119-939a-380fba573759",
      "alertTime": 1620968400507,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "60bfb0f6-afe6-4284-bb10-5c32669cbe93",
      "rifAggId": "17f303dc-27aa-429f-8073-9cd54aa8d137",
      "alertTime": 1620882000466,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "f0b1d119-352f-4c8c-8c6a-42a630a704a5",
      "rifAggId": "f33864ae-e45b-4ec5-b384-feaef364f37b",
      "alertTime": 1620882000453,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "94795c1d-3be1-4cd4-b023-781850676fc6",
      "rifAggId": "6c82bf96-988f-494e-aa24-f226efaec921",
      "alertTime": 1620882000435,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "27494f94-1098-46c1-b222-02b508aadf5a",
      "rifAggId": "df0cb101-2d61-49d1-9cd1-699f2c704d11",
      "alertTime": 1620795600947,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "e81272d5-e6bb-4844-87d9-086527420c0d",
      "rifAggId": "751c3447-1f01-4963-8d5e-b2c206d0f61c",
      "alertTime": 1620795600933,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "2c121cbe-7855-4d9a-bcd9-e4cb765bbf29",
      "rifAggId": "4fc6356a-1d8e-4245-a3d3-21607f775781",
      "alertTime": 1620795600797,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "8f6e33bd-e23c-4d7f-a86a-e8d605c96cf1",
      "rifAggId": "26903498-e923-4ee6-af4f-464dd960ef6e",
      "alertTime": 1620709200629,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "7cd9a5e2-0053-41ee-af29-381d3edac1d9",
      "rifAggId": "1bd25cf2-65b6-4f2b-8fc4-e672c9b36543",
      "alertTime": 1620709200625,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "6133fa34-5a27-496e-be56-0b2ae963d0bc",
      "rifAggId": "75ef6708-b2ef-48ec-ae11-510364c0456c",
      "alertTime": 1620709200605,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "90f1c825-4562-46f4-aaf4-ed8cddcbe1ac",
      "rifAggId": "e3937b4b-243e-524b-e749-e13f9646c489",
      "alertTime": 1620636811904,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 7/2021.",
      "alertType": "ok"
    }, {
      "alertId": "e1b84822-3db0-4d35-a8df-70dd9aa7133e",
      "rifAggId": "e3937b4b-243e-524b-e749-e13f9646c489",
      "alertTime": 1620636049980,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la creazione del lotto '7/2021'contenente 116 spedizioni",
      "alertType": "ok"
    }, {
      "alertId": "a0dcfbcf-0f69-40f8-b8a2-afbcecc2c4dc",
      "rifAggId": "66fb4644-0d31-4d22-8d79-52c5d32c56c2",
      "alertTime": 1620622800629,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "8f5f26d7-d4c6-45af-933d-3a39811710ea",
      "rifAggId": "16188472-075c-4ab7-b550-074ca1106859",
      "alertTime": 1620622800532,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "b39d31bd-4241-4771-b7cb-68fef0042624",
      "rifAggId": "c6cafa11-fe60-483b-9a22-feef883a141a",
      "alertTime": 1620622800501,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "78b3510e-4227-4b8b-a2a8-4d81092a6c88",
      "rifAggId": "897ddc89-3390-4414-a7fd-bfcfba688eda",
      "alertTime": 1620536400712,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "ce1b5824-8563-4730-88a6-04815c2657bc",
      "rifAggId": "4cb34b02-0166-418c-a119-02ac19bebb2d",
      "alertTime": 1620536400620,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "0de3681a-9534-471a-94fb-d1400520176c",
      "rifAggId": "1673cbcc-de38-4af0-a8b0-ff8fddbfd318",
      "alertTime": 1620536400602,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "30918065-cdb3-4657-9b6f-c66861abf2f1",
      "rifAggId": "1f2a7bf1-f183-4e4e-b26f-af360ce450c2",
      "alertTime": 1620450000627,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "d5b2eb9c-f9d9-4fb8-aaa4-4dd11209477d",
      "rifAggId": "dda658b1-fb40-4560-92cc-8f1e4a9c430e",
      "alertTime": 1620450000509,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "7f6b2e0b-0ee4-4728-b8e6-70a49877065f",
      "rifAggId": "207fd19c-f001-42fc-9772-8700c88b9653",
      "alertTime": 1620450000502,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "708574dd-a6ce-49ec-8a28-9716aec3e0a6",
      "rifAggId": "ea33701c-870b-4a4b-a166-e9b39000ffa7",
      "alertTime": 1620363600967,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "8a41f615-5a28-4c40-911b-7cb0680fb430",
      "rifAggId": "ae8bcdb0-c9c1-405e-b8ec-4f6165c13df7",
      "alertTime": 1620363600854,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "1fc6b213-5e0a-4ab8-b366-af94b216a056",
      "rifAggId": "92fcd00b-46ba-4064-8ce2-2dd2a6b7f1a1",
      "alertTime": 1620363600615,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "12ac7e5e-89d9-4c96-a54e-fe5edd6def9d",
      "rifAggId": "02ecc429-4932-46ea-b6e7-a8e618b6dbef",
      "alertTime": 1620277200992,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "d5068b81-90a8-485c-b4d1-734570620494",
      "rifAggId": "ebb98ec4-0661-4b14-8ae3-ce78a272cfa2",
      "alertTime": 1620277200908,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "bf268450-828e-471f-84d1-ec3f934d8869",
      "rifAggId": "e028100b-b343-41c7-b372-6cb2d9437f1b",
      "alertTime": 1620277200764,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "dec4cf3d-57d1-49df-a1bf-036261b7b032",
      "rifAggId": "459bab88-0103-46f6-a534-5e3f26719b57",
      "alertTime": 1620190800635,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "777a9061-0bc4-4731-b2be-53fd83aed168",
      "rifAggId": "aa385e24-8658-439f-85d1-08ac17c360a8",
      "alertTime": 1620190800610,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "ef85a3b8-f84c-4bf9-ab55-2f9f09ead79a",
      "rifAggId": "a6bb10cb-e190-475e-9864-6ebc1039afee",
      "alertTime": 1620190800596,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "db5a0b4c-78a8-4f8e-8c03-8a89b748636a",
      "rifAggId": "451ad770-dbaf-46c3-ae25-a5efb9d83223",
      "alertTime": 1620104400825,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "0cdbf9cf-bbec-419e-90cd-dcd92ace1253",
      "rifAggId": "38cb0116-ed85-4825-81aa-3c8095d20241",
      "alertTime": 1620104400803,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "1b8bcd56-d22d-470e-8a89-787847bd85c2",
      "rifAggId": "b9533099-4e9f-4f46-ac58-e7fd37510a4f",
      "alertTime": 1620104400774,
      "tipoAggregato": "LOTTO",
      "messaggio": "Terminata elaborazione dei flussi di spedizione.Flussi OK:0 Flussi in errore:2",
      "alertType": "ok"
    }, {
      "alertId": "9e6cffb7-757b-4132-ac01-da6785cb7c1b",
      "rifAggId": "b6fbb9d4-3f3b-1396-d9c0-faa8c66bdd1f",
      "alertTime": 1620034867256,
      "tipoAggregato": "LOTTO",
      "messaggio": "Completata la cancellazione del lotto 6/2021.",
      "alertType": "ok"
    }],
    "count": 1034,
    "links": []
  },
  "filters": []
};
exports.longalert = longalert;