"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Payable', ['restmod', function (restmod) {
    return restmod.model('payable').mix({
      $extend: {
        Scope: {
          $config: {
            baseEntityPath: 'payable/',
            api: {
              detail: {
                url: 'dettaglio/payable',
                method: 'POST'
              },
              pagamentoCassa: {
                url: 'pagamento-cassa',
                method: 'POST'
              },
              forzaPagamentoCassa: {
                url: 'forza-pagamento-cassa',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'RicercaVeloce', 'RicercaTestoLibero', 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {//Constructor

          /*$collection: function( _params, _scope ) {
              var collection = this.$super(_params, _scope);
              collection.$url = function() {
                  return this.$getBaseEntityPath() + this.$getUrl('list');
              };
              return collection;
          }*/
        },
        Record: {
          $getImporti: function () {
            var me = this,
                request = {
              url: this.priceSupplierUrl,
              method: 'GET'
            };
            this.$dispatch('before-paga-tramite-cassa', [request]);
            this.$send(request, function (_response) {
              this.$dispatch('after-paga-tramite-cassa', [_response.data]);
            }, function (_response) {
              this.$dispatch('after-paga-tramite-cassa-error', [_response]);
            });
          },
          $pagaTramiteCassa: function (_payableData) {
            var me = this,
                request = this.$buildRequest('pagamentoCassa');
            request.data = Object.assign({}, _payableData);
            this.$dispatch('before-paga-tramite-cassa', [request]);
            this.$send(request, function (_response) {
              this.$dispatch('after-paga-tramite-cassa', [_response.data]);
            }, function (_response) {
              this.$dispatch('after-paga-tramite-cassa-error', [_response]);
            });
          },
          $forzaPagamentoCassa: function (_payableData) {
            var me = this,
                request = this.$buildRequest('forzaPagamentoCassa');
            request.data = Object.assign({}, _payableData);
            this.$dispatch('before-forza-pagamento-cassa', [request]);
            this.$send(request, function (_response) {
              this.$dispatch('after-forza-pagamento-cassa', [_response.data]);
            }, function (_response) {
              this.$dispatch('after-forza-pagamento-cassa-error', [_response]);
            });
          }
        }
      }
    });
  }]);
})();