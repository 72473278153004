"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('ModelloStampa', ['restmod', function (restmod) {
    return restmod.model('modelloStampa').mix({
      $extend: {
        Scope: {
          $config: {
            name: 'modelliStampa',
            baseEntityPath: 'modello-stampa/',
            api: {
              list: {
                url: 'ricerca/modelli-stampa',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/modello-stampa',
                method: 'POST'
              },
              destroy: {
                url: 'comando/cancella-modello-stampa',
                method: 'POST'
              },
              save: {
                url: 'comando/aggiungi-modello-stampa',
                method: 'POST'
              },
              edit: {
                url: 'comando/modifica-modello-stampa',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl('list');
            };

            return collection;
          }
        },
        Record: {
          //Override della defaul $destroy
          $destroy: function () {
            return this.$action(function () {
              if (this.$pk) {
                var request = this.$buildRequest('destroy', true);
                request.data = {
                  aggId: this.$pk
                };
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$unwrap(this.$getResponseData(_response));
                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) {
                  this.$scope.$remove(this);
                }
              }
            });
          }
        }
      }
    });
  }]);
})();