"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLastSavedElement = exports._lastSavedElementState = void 0;

var _recoil = require("recoil");

var _object = require("../object.utils");

var _localStorageEffect = _interopRequireDefault(require("./generics/effects/localStorageEffect"));

var _messages = require("./global/messages");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const localStorageName = "last-saved-elements";

const _lastSavedElementState = (0, _recoil.atomFamily)({
  key: "_lastSavedElementState",
  default: {},
  effects_UNSTABLE: ({
    section
  }) => [(0, _localStorageEffect.default)(localStorageName, newValue => {
    const savedObj = JSON.parse(localStorage.getItem(localStorageName));
    const updateValue = {
      [`${section}`]: {
        aggId: newValue.aggId
      }
    };
    return { ...savedObj,
      ...updateValue
    };
  })]
});

exports._lastSavedElementState = _lastSavedElementState;

const useLastSavedElement = ({
  section
}) => {
  const setLastSavedElement = (0, _recoil.useSetRecoilState)(_lastSavedElementState({
    section: section
  }));
  return {
    updateLastSavedElement: (0, _recoil.useRecoilCallback)(({
      set
    }) => ({
      aggId,
      item
    }) => {
      setLastSavedElement(state => ({ ...state,
        aggId,
        item
      }));
    }),
    getLastSavedElement: section => (0, _object.getPathStringOrNull)([section, "aggId"])(JSON.parse(localStorage.getItem(localStorageName))),
    getLastSavedElementAndRedirect: (0, _recoil.useRecoilCallback)(({
      set
    }) => ({
      section,
      redirectRoute
    }) => {
      const lastElementAggId = (0, _object.getPathStringOrNull)([section, "aggId"])(JSON.parse(localStorage.getItem(localStorageName)));
      lastElementAggId ? window.$state.go(redirectRoute, {
        aggId: lastElementAggId
      }) : set(_messages.messagesStack, (0, _messages.pushMessage)({
        type: 'info',
        message: "Impossibile recuperare l'ultimo elemento salvato",
        severity: 'info'
      }));
    })
  };
};

exports.useLastSavedElement = useLastSavedElement;