"use strict";

var _VerbaleRoot = _interopRequireDefault(require("../verbale/VerbaleRoot"));

var _VerbaliMasterDetail = _interopRequireDefault(require("./masterdetail/VerbaliMasterDetail"));

var _NuovoVerbaleRoot = _interopRequireDefault(require("../verbaleNuovo/NuovoVerbaleRoot"));

var _RicorsoEditRoot = _interopRequireDefault(require("../ricorso/edit/RicorsoEditRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.verbali').provider('verbaliRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'verbaliList',
    url: 'verbali?{page:int}&{limit:int}&{query:string}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    role: 'ROLE_VERBALE_RICERCA_VELOCE',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('verbali.verbaliList__bcLabel');
      }]
    },
    views: {
      $default: {
        component: _VerbaliMasterDetail.default
      }
    }
  }, {
    name: 'verbalidetail',
    role: 'ROLE_VERBALE_MODIFICA',
    url: 'verbali/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    params: {
      subView: null,
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _VerbaleRoot.default
      }
    },
    resolve: [{
      token: '$label',
      resolveFn: () => "Dettaglio verbale"
    }]
  }, {
    name: 'verbalidetailallegati',
    url: '/allegati',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        templateUrl: 'verbali/verbale.allegati.view.html'
      }
    },
    resolve: {
      $label: ['verbale', function (verbale) {
        return verbale.verbaleId.toUpperCase();
      }]
    }
  }, {
    name: 'verbalinew',
    role: 'ROLE_VERBALE_AGGIUNGI',
    url: 'verbali/nuovo',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    params: {
      nuovo: true
    },
    resolve: {
      $label: ['$translate', function ($translate) {
        return $translate('verbali.nuovoVerbaliList__bcLabel');
      }]
    },
    views: {
      $default: {
        component: _NuovoVerbaleRoot.default
      }
    }
  }, {
    name: 'ricorso.new',
    url: 'verbali/nuovo-ricorso',
    role: 'ROLE_RICORSO_MODIFICA',
    params: {
      aggId: {
        squash: true,
        value: null
      },
      ricorso: null,
      verbali: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: false
    },
    views: {
      $default: {
        component: _RicorsoEditRoot.default
      }
    },
    resolve: {
      $label: ['$stateParams', function ($stateParams) {
        return "Crea ricorso";
      }]
    }
  }];
  routesProviderFactory.extend(this);
}