"use strict";

var _FunzioniStampaEditRoot = _interopRequireDefault(require("./edit/FunzioniStampaEditRoot"));

var _FunzioniStampaList = _interopRequireDefault(require("./FunzioniStampaList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('funzioniStampaTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsFunzioniStampa',
    url: 'tds/funzioniStampa',
    role: 'ROLE_FUNZIONE_STAMPA_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _FunzioniStampaList.default
      }
    },
    resolve: {
      $label: () => "Funzioni di stampa"
    }
  }, {
    name: 'tdsFunzioniStampaEdit',
    url: 'tds/funzioniStampa/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_FUNZIONE_STAMPA_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _FunzioniStampaEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita funzione di stampa"
    }
  }];
  routesProviderFactory.extend(this);
}