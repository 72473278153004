"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.carroattrezziValidationSchema = exports.fromApiCarroattrezzi = exports.toApiCarroattrezzi = exports.carroattrezziEmptyForm = void 0;

var _object = require("../../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const requiredMsg = "Questo campo è obbligatorio";
const carroattrezziEmptyForm = {
  codiceVeloce: '',
  descrizione: ''
};
exports.carroattrezziEmptyForm = carroattrezziEmptyForm;

const toApiCarroattrezzi = values => {
  let carroattrezzi = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(['codiceVeloce'])(values),
    descrizione: (0, _object.getPathStringOrEmptyString)(['descrizione'])(values)
  };
  return carroattrezzi;
};

exports.toApiCarroattrezzi = toApiCarroattrezzi;

const fromApiCarroattrezzi = remoteCarroattrezzi => {
  let carroAttrezzi = { ...(remoteCarroattrezzi.aggId ? {
      aggId: remoteCarroattrezzi.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(['codiceVeloce'])(remoteCarroattrezzi),
    descrizione: (0, _object.getPathStringOrEmptyString)(['descrizione'])(remoteCarroattrezzi)
  };
  return carroAttrezzi;
};

exports.fromApiCarroattrezzi = fromApiCarroattrezzi;
const carroattrezziValidationSchema = Yup.object().shape({
  codiceVeloce: Yup.string().required(requiredMsg),
  descrizione: Yup.string().required(requiredMsg)
});
exports.carroattrezziValidationSchema = carroattrezziValidationSchema;