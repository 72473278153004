"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExpandedIds = exports.getStazioneComandoId = exports.filterTree = exports.findNode = exports.defaultMatcher = void 0;

const defaultMatcher = (filterText, node) => {
  const text = filterText.toLowerCase();
  return node.userObject.codiceUnivoco.toLowerCase().includes(text) || node.userObject.descrizione.toLowerCase().includes(text) || node.userObject.nomeComune.toLowerCase().includes(text) || node.userObject.indirizzo.toLowerCase().includes(text);
};

exports.defaultMatcher = defaultMatcher;

const findNode = (node, filter, matcher) => {
  return matcher(filter, node) || // i match
  node.children && // or i have decendents and one of them match
  node.children.length && !!node.children.find(child => findNode(child, filter, matcher));
};

exports.findNode = findNode;

const filterTree = (node, filter, matcher = defaultMatcher) => {
  // If im an exact match then all my children get to stay
  if (matcher(filter, node) || !node.children) {
    return node;
  } // If not then only keep the ones that match or have matching descendants


  const filtered = node.children.filter(child => findNode(child, filter, matcher)).map(child => filterTree(child, filter, matcher));
  return Object.assign({}, node, {
    children: filtered
  });
};

exports.filterTree = filterTree;

const getStazioneComandoId = userObject => userObject.stazioneComandoId;

exports.getStazioneComandoId = getStazioneComandoId;

const getExpandedIds = node => {
  if (!node.children) return [];
  const ids = node.children.reduce((acc, val) => {
    return [...acc, getStazioneComandoId(val.userObject), ...(Array.isArray(val.children) ? getExpandedIds(val) : [])];
  }, []);
  return ids;
};

exports.getExpandedIds = getExpandedIds;