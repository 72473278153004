"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.presentazioneDocumentiValidationSchema = exports.fromApiPresentazioneDocumento = exports.mapDocumentoFields = exports.getNuovoDocumentoDaPresentareEmptyForm = exports.handleAggiungiModificaSuccess = exports.handleVerbalizzaPresentazioneDocumentoSuccess = exports.handleCancellaSuccess = exports.handleFailure = exports.handleForzaModificaTipoDocumentoSuccess = exports.useVerbalizzaPresentazioneDocumento = exports.useForzaModificaTipoDocumento = exports.useModificaDocumentoDaPresentare = exports.useAggiungiDocumentoDaPresentare = exports.useCancellaDocumento = exports.usePresentazioneDocumento = exports._documentoDeletable = exports._documentoEditable = exports._documentoToDelete = exports._documentoToEdit = exports.pdKeys = void 0;

var _api = require("../../api");

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _object = require("../../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

var _reactQuery = require("react-query");

var _utils2 = require("../../react/reactQuery/utils");

var _presentazioneDocumentiMasterDetail = require("./presentazioneDocumentiMasterDetail");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const pdKeys = {
  all: () => [_presentazioneDocumentiMasterDetail.presentazioneDocumentiMasterDetailName],
  detail: aggId => [...pdKeys.all(), "detail", ...(0, _utils.insertIf)(aggId, {
    aggId
  })],
  quickSearch: params => [...pdKeys.all(), "quickSearch", ...(0, _utils.insertIf)(params, params)]
};
exports.pdKeys = pdKeys;

const _documentoToEdit = (0, _recoil.atom)({
  key: "_documentoToEdit",
  default: undefined
});

exports._documentoToEdit = _documentoToEdit;

const _documentoToDelete = (0, _recoil.atom)({
  key: "_documentoToDelete",
  default: undefined
});

exports._documentoToDelete = _documentoToDelete;

const _documentoEditable = (0, _recoil.selector)({
  key: "_documentoEditable",
  get: ({
    get
  }) => {
    const documentoToEdit = get(_documentoToEdit);
    return documentoToEdit ? mapDocumentoFields(documentoToEdit) : undefined;
  },
  set: ({
    reset
  }) => {
    reset(_documentoToEdit);
  }
});

exports._documentoEditable = _documentoEditable;

const _documentoDeletable = (0, _recoil.selector)({
  key: "_documentoDeletable",
  get: ({
    get
  }) => {
    const documentoToDelete = get(_documentoToDelete);
    const documentoDeletable = documentoToDelete ? {
      aggId: documentoToDelete.aggId,
      docId: documentoToDelete.docId,
      violazioneId: documentoToDelete.riferimentoArticolo.aggId,
      tipoDocumento: documentoToDelete.tipoDocumento
    } : undefined;
    return documentoDeletable;
  },
  set: ({
    reset
  }) => {
    reset(_documentoToDelete);
  }
});

exports._documentoDeletable = _documentoDeletable;

const usePresentazioneDocumento = aggId => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(pdKeys.detail(aggId), ({
    queryKey
  }) => (0, _api.dettaglioPresentazioneDocumento)({
    aggId: queryKey[2].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    // MI SERVE PER POTER VISUALIZZARE LO SKELETON PRESENTE DENTRO IL SUSPENSE IN PRESENTAZIONEDOCUMENTIMASTERDETAIL.JSX
    enabled: Boolean(aggId),
    onSuccess: (0, _utils2.handleSuccess)(messenger),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    select: response => fromApiPresentazioneDocumento(response)
  });
  return {
    item: data ? data : {
      daPresentare: [],
      verbalizzabili: []
    },
    isFetching: isFetching || isLoading
  };
};

exports.usePresentazioneDocumento = usePresentazioneDocumento;

const useCancellaDocumento = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(documentoDaCancellare => (0, _api.cancellaDocumentoPresDoc)(documentoDaCancellare), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleCancellaSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => {
      queryClient.invalidateQueries(pdKeys.all());
    }
  });
};

exports.useCancellaDocumento = useCancellaDocumento;

const useAggiungiDocumentoDaPresentare = ({
  onSuccess,
  onFailure
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(documentoDaPresentare => (0, _api.aggiungiDocumentoDaPresentare)(toApiDocumentoDaPresentare(documentoDaPresentare)), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleAggiungiModificaSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => {
      queryClient.invalidateQueries(pdKeys.all());
    }
  });
};

exports.useAggiungiDocumentoDaPresentare = useAggiungiDocumentoDaPresentare;

const useModificaDocumentoDaPresentare = ({
  onSuccess,
  onFailure
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(documentoDaPresentare => (0, _api.modificaDocumentoDaPresentare)(toApiDocumentoDaPresentare(documentoDaPresentare)), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleAggiungiModificaSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => {
      queryClient.invalidateQueries(pdKeys.all());
    }
  });
};

exports.useModificaDocumentoDaPresentare = useModificaDocumentoDaPresentare;

const useForzaModificaTipoDocumento = ({
  aggId,
  docId,
  onSuccess,
  onFailure,
  onError
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setDocumentoToEdit = (0, _recoil.useSetRecoilState)(_documentoToEdit);
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(({
    aggId,
    docId,
    tipoDocumento,
    categorie,
    articoloId,
    motivazione
  }) => (0, _api.forzaModificaTipoDocumentoPresDoc)({
    aggId,
    docId,
    tipoDocumento,
    categorie,
    articoloId,
    motivazione
  }), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleForzaModificaTipoDocumentoSuccess(messenger)({
      response,
      setDocumentoToEdit,
      aggId,
      docId,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => {
      queryClient.invalidateQueries(pdKeys.detail());
    }
  });
};

exports.useForzaModificaTipoDocumento = useForzaModificaTipoDocumento;

const useVerbalizzaPresentazioneDocumento = ({
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(({
    aggId,
    docId
  }) => (0, _api.verbalizzaDocumentoPresDoc)({
    aggId,
    docId
  }), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response
    }) : handleVerbalizzaPresentazioneDocumentoSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => {
      queryClient.invalidateQueries(pdKeys.detail());
    }
  });
};

exports.useVerbalizzaPresentazioneDocumento = useVerbalizzaPresentazioneDocumento;

const handleForzaModificaTipoDocumentoSuccess = messenger => ({
  response,
  setDocumentoToEdit,
  aggId,
  docId,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: 'Documento salvato',
    severity: 'success'
  });
  const doc = response.daPresentare.find(doc => doc.docId == docId);

  if (!doc) {
    messenger({
      type: 'error',
      message: "Impossibile completare l'operazione",
      severity: 'error'
    });
    return;
  } else {
    setDocumentoToEdit({ ...doc,
      aggId
    });
  }

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleForzaModificaTipoDocumentoSuccess = handleForzaModificaTipoDocumentoSuccess;

const handleFailure = messenger => ({
  response,
  onFailure
}) => {
  messenger({
    type: 'warning',
    message: `Attenzione! ${response.message}`,
    severity: 'warning'
  });

  if (onFailure) {
    onFailure(response);
  }
};

exports.handleFailure = handleFailure;

const handleCancellaSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: "Documento cancellato",
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleCancellaSuccess = handleCancellaSuccess;

const handleVerbalizzaPresentazioneDocumentoSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: 'Documento verbalizzato',
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleVerbalizzaPresentazioneDocumentoSuccess = handleVerbalizzaPresentazioneDocumentoSuccess;

const handleAggiungiModificaSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: 'Documento salvato',
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleAggiungiModificaSuccess = handleAggiungiModificaSuccess;

const getNuovoDocumentoDaPresentareEmptyForm = aggId => ({ ...nuovoDocumentoDaPresentareEmptyForm,
  aggId: aggId
});

exports.getNuovoDocumentoDaPresentareEmptyForm = getNuovoDocumentoDaPresentareEmptyForm;

const mapDocumentoFields = documento => {
  const doc = { ...(documento ? {
      aggId: documento.aggId
    } : {}),
    ...(documento ? {
      docId: documento.docId
    } : {}),
    soggetto: (0, _object.getPathObjectOrNull)(["soggetto"])(documento),
    dataPresentazione: (0, _object.getPathNumberToDateOrNull)(["dataPresentazione"])(documento),
    luogoPresentazione: (0, _object.getPathStringOrEmptyString)(["luogoPresentazione"])(documento),
    esitoPresentazione: (0, _object.getPathStringOrEmptyString)(["esitoPresentazione"])(documento),
    tipoDocumento: (0, _object.getPathStringOrNull)(["tipoDocumentoForzato"])(documento) ? (0, _object.getPathStringOrEmptyString)(["tipoDocumentoForzato"])(documento) : (0, _object.getPathStringOrEmptyString)(["tipoDocumento"])(documento),
    enteRilascio: (0, _object.getPathObjectOrNull)(["enteRilascio"])(documento),
    numeroDocumento: (0, _object.getPathStringOrEmptyString)(["numeroDocumento"])(documento),
    dataRilascio: (0, _object.getPathNumberToDateOrNull)(["dataRilascio"])(documento),
    dataScadenza: (0, _object.getPathNumberToDateOrNull)(["dataScadenza"])(documento),
    categorie: (0, _object.getPathArrayOrNull)(["categoriaDocumentoForzato"])(documento) ? (0, _object.getPathArrayOrEmptyArray)(["categoriaDocumentoForzato"])(documento) : (0, _object.getPathArrayOrEmptyArray)(["categoria"])(documento),
    riferimentoArticolo: (0, _object.getPathObjectOrNull)(["riferimentoArticoloForzato"])(documento) ? (0, _object.getPathObjectOrNull)(["riferimentoArticoloForzato"])(documento) : (0, _object.getPathObjectOrNull)(["riferimentoArticolo"])(documento)
  };
  return doc;
};

exports.mapDocumentoFields = mapDocumentoFields;
const nuovoDocumentoDaPresentareEmptyForm = {
  soggetto: null,
  tipoSoggetto: "",
  dataPresentazione: null,
  luogoPresentazione: "",
  esitoPresentazione: "",
  riferimentoArticolo: null,
  tipoDocumento: "",
  enteRilascio: null,
  numeroDocumento: "",
  dataRilascio: null,
  dataScadenza: null,
  categorie: []
};

const toApiDocumentoDaPresentare = values => {
  const documento = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    ...(values.docId ? {
      docId: values.docId
    } : {}),
    soggettoId: (0, _object.getPathStringOrEmptyString)(["soggetto", "aggId"])(values),
    tipoSoggetto: (0, _object.getPathStringOrEmptyString)(["soggetto", "tipoSoggetto"])(values),
    violazioneId: (0, _object.getPathStringOrEmptyString)(["riferimentoArticolo", "aggId"])(values),
    dataPresentazione: (0, _object.getPathDateToNumberOrNull)(["dataPresentazione"])(values),
    luogoPresentazione: (0, _object.getPathStringOrEmptyString)(["luogoPresentazione"])(values),
    esitoPresentazione: (0, _object.getPathStringOrEmptyString)(["esitoPresentazione"])(values),
    tipoDocumento: (0, _object.getPathStringOrEmptyString)(["tipoDocumento"])(values),
    numeroDocumento: (0, _object.getPathStringOrNull)(["numeroDocumento"])(values),
    enteRilascioId: (0, _object.getPathStringOrNull)(["enteRilascio", "aggId"])(values),
    dataRilascio: (0, _object.getPathDateToNumberOrNull)(["dataRilascio"])(values),
    dataScadenza: (0, _object.getPathDateToNumberOrNull)(["dataScadenza"])(values),
    categoriaDocumento: (0, _object.getPathArrayOrEmptyArray)(["categorie"])(values),
    categoriaDocumentoForzato: (0, _object.getPathStringOrNull)(["categoriaDocumentoForzato"])(values)
  };
  return documento;
};

const fromApiPresentazioneDocumento = values => {
  const {
    trasgressore,
    obbligato,
    ...rest
  } = values;
  return { ...rest,
    soggetti: [...(0, _utils.insertIf)(trasgressore === null || trasgressore === void 0 ? void 0 : trasgressore.aggId, { ...trasgressore,
      posizione: values.obbligatoUgualeTrasgressore ? "T + O" : "T",
      tipoSoggetto: "trasgressore"
    }), ...(0, _utils.insertIf)(obbligato === null || obbligato === void 0 ? void 0 : obbligato.aggId, { ...obbligato,
      posizione: "OB",
      tipoSoggetto: "obbligato"
    })]
  };
};

exports.fromApiPresentazioneDocumento = fromApiPresentazioneDocumento;

const presentazioneDocumentiValidationSchema = withViolazione => Yup.object().shape({
  soggetto: Yup.object().required(_utils.requiredMessage).nullable(),
  dataPresentazione: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
  luogoPresentazione: Yup.string().required(_utils.requiredMessage),
  esitoPresentazione: Yup.string().required(_utils.requiredMessage).nullable(),
  riferimentoArticolo: withViolazione && Yup.object().required(_utils.requiredMessage).nullable(),
  tipoDocumento: Yup.string().required(_utils.requiredMessage),
  enteRilascio: Yup.object().when('esitoPresentazione', {
    is: value => value && value != 'NON_PRESENTATO' && value != 'ATTESA',
    then: Yup.object().required(_utils.requiredMessage).nullable(),
    otherwise: Yup.object().nullable()
  }),
  numeroDocumento: Yup.string().when('esitoPresentazione', {
    is: value => value && value != 'NON_PRESENTATO' && value != 'ATTESA',
    then: Yup.string().required(_utils.requiredMessage),
    otherwise: Yup.string()
  }),
  dataRilascio: Yup.date().when('esitoPresentazione', {
    is: value => value && value != 'NON_PRESENTATO' && value != 'ATTESA',
    then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
    otherwise: Yup.date().nullable()
  }),
  dataScadenza: Yup.date().when(["tipoDocumento", "esitoPresentazione"], {
    is: (tipoDocumento, esitoPresentazione) => {
      return tipoDocumento != 'documento_unico_proprieta' && tipoDocumento != 'documento_circolazione' && esitoPresentazione != "NON_PRESENTATO" && esitoPresentazione != 'ATTESA';
    },
    then: Yup.date().required(_utils.requiredMessage).nullable().typeError('Data non valida'),
    otherwise: Yup.date().nullable()
  }),
  categorie: Yup.array().when(["tipoDocumento", "esitoPresentazione"], {
    is: (tipoDocumento, esitoPresentazione) => (tipoDocumento === 'patente' || tipoDocumento === 'patente_estera') && esitoPresentazione != "NON_PRESENTATO" && esitoPresentazione != 'ATTESA',
    then: Yup.array().min(1, _utils.requiredMessage),
    otherwise: Yup.array().nullable()
  }),
  stato: Yup.object().when(["tipoDocumento", "esitoPresentazione"], {
    is: (tipoDocumento, esitoPresentazione) => tipoDocumento === 'patente_estera' && esitoPresentazione != "NON_PRESENTATO" && esitoPresentazione != 'ATTESA',
    then: Yup.object().required(_utils.requiredMessage).nullable(),
    otherwise: Yup.object().nullable()
  }),
  specificheCQC: Yup.string().when(["tipoDocumento", "esitoPresentazione"], {
    is: (tipoDocumento, esitoPresentazione) => tipoDocumento === 'cqc' && esitoPresentazione != "NON_PRESENTATO" && esitoPresentazione != 'ATTESA',
    then: Yup.string().required(_utils.requiredMessage).nullable(),
    otherwise: Yup.string().nullable()
  }),
  specificheCAP: Yup.string().when(["tipoDocumento", "esitoPresentazione"], {
    is: (tipoDocumento, esitoPresentazione) => tipoDocumento === 'cap' && esitoPresentazione != "NON_PRESENTATO" && esitoPresentazione != 'ATTESA',
    then: Yup.string().required(_utils.requiredMessage).nullable(),
    otherwise: Yup.string().nullable()
  })
});

exports.presentazioneDocumentiValidationSchema = presentazioneDocumentiValidationSchema;