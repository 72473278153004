"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('OsImmaginiEntita', ['restmod', function (restmod) {
    var API_IMMAGINI_ENTITA = 'bpmn/get-immagini-da-validare/';
    var METHOD_IMMAGINI_ENTITA = 'GET';
    return restmod.mixin({
      $extend: {
        Scope: {},
        Collection: {},
        Record: {
          $immagineEntita: function () {
            return this.$action(function () {
              var me = this,
                  request = {
                url: API_IMMAGINI_ENTITA.concat(this.$pk),
                method: METHOD_IMMAGINI_ENTITA
              };
              this.$dispatch('before-get-immagini-entita', [request]);
              this.$send(request, function (_response) {
                this["$immagini"] = _response.data;
                this.$dispatch('after-get-immagini-entita', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-get-immagini-entita-error', [_response]);
              });
            });
          }
        }
      }
    });
  }]);
})();