"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _formik = require("formik");

var _react = require("react");

var _utils = require("../../../../state/documenti/utils");

const DocumentoSoggettoEffects = () => {
  const {
    values,
    touched,
    resetForm,
    isSubmitting
  } = (0, _formik.useFormikContext)();
  (0, _react.useEffect)(() => {
    if (touched.tipoDocumento && !isSubmitting) {
      resetForm({
        values: Object.assign({}, _utils.documentoEmptyForm, {
          tipoDocumento: values.tipoDocumento
        })
      });
    }
  }, [values.tipoDocumento]);
  return null;
};

var _default = DocumentoSoggettoEffects;
exports.default = _default;