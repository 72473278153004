"use strict";

var _LeggeEditRoot = _interopRequireDefault(require("./edit/LeggeEditRoot"));

var _LeggiList = _interopRequireDefault(require("./LeggiList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('leggiTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsLeggi',
    url: 'tds/leggi',
    role: 'ROLE_LEGGE_RICERCA',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _LeggiList.default
      }
    },
    resolve: {
      $label: () => "Leggi"
    }
  }, {
    name: 'tdsLeggiEdit',
    url: 'tds/leggi/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_LEGGE_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _LeggeEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita legge"
    }
  }];
  routesProviderFactory.extend(this);
}