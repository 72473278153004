"use strict";

var _Scadenzario = _interopRequireDefault(require("./Scadenzario"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.scadenzario').provider('scadenzarioRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'scadenzario',
    url: 'scadenzario?{page:int}&{limit:int}&{dataInizio:int}&{dataFine:int}&{tipoScadenza:string}',
    role: 'ROLE_VERBALE_RICERCA_VELOCE',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    resolve: {
      $label: () => "Scadenzario"
    },
    views: {
      $default: {
        component: _Scadenzario.default
      }
    }
  }];
  routesProviderFactory.extend(this);
}