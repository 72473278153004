"use strict";

/**
 * Created by dan on 10/04/17.
 */
(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name CommandsList
   * @module polcity.api
   *
   * @description
   * Holds the request definitions for the available commands
   *
   * If you want to add a command, just inject the service and call the dedicated method:
   *
   * @usage
   * <hljs lang="js">
   *     ....
   *     .service('MyService', ['CommandsList', MyServiceDefinition]);
   *
   *     function MyServiceDefinition(CommandsList) {
   *          .....
   *
   *          CommandsList.add({
   *               '@dettaglioPerBollettaAnno': {
   *                   method: 'POST',
   *                   url   : 'bollettario/dettaglio-per-bolletta-anno/bollettario'
   *               }
   *           });
   *     }
   *
   * </hljs>
   */

  angular.module('polcity.api').provider('CommandsList', [CommandsList]);

  function CommandsList() {
    var commandsMap = {};

    this.$get = function () {
      return {
        retrieve: pickCommand,
        add: addCommand
      };
    };
    /**
     * @ngdoc method
     * @name CommandsList#retrieve
     *
     * @description
     * Retrieves the request object from the commands list.
     *
     * @usage
     * Can be used like this:
     * <hljs lang="js">
     *     $http(CommandsList.get('awesomeCommand'));
     * </hljs>
     *
     * @param {string} commandName
     * @returns {!Object} the request description object
     */


    function pickCommand(cmdName) {
      return R.prop(cmdName, commandsMap);
    }
    /**
     * @ngdoc method
     * @name CommandsList#add
     *
     * @description
     * Adds a request command object to the available commands.
     * The object should comply to the accepted request object of the `$http` angular resource.
     *
     * @usage
     * Can be used like this:
     * <hljs lang="js">
     *     CommandsList.add({awesomeCommand: {
     *      url: 'http://my.remote.org',
     *      transformResponse: function() {...},
     *      transformRequest: function() {....},
     *     })
     * </hljs>
     *
     * @param {object} cmd `{'@cmdName': config}` Command object with one key that will be the commandName and one value that contains a configuration object with these supported properties:
     *  - `method` - `{string}`:  HTTP method (e.g. 'GET', 'POST', etc)
     *  - `url` - `{string|TrustedObject}`: Absolute or relative URL of the resource that is being requested; or an object created by a call to `$sce.trustAsResourceUrl(url)`.
     *  - `transformRequest` - `{function(data, headersGetter)|Array.<function(data, headersGetter)>}`: transform function or an array of such functions. The transform function takes the http request body and headers and returns its transformed (typically serialized) version. See Overriding the Default Transformations
     *  - `transformResponse` - `{function(data, headersGetter, status)|Array.<function(data, headersGetter, status)>}`: transform function or an array of such functions. The transform function takes the http response body, headers and status and returns its transformed (typically deserialized) version. See Overriding the Default Transformations
     *  - `cache` - `{boolean|Object}`:  A boolean value or object created with `$cacheFactory to enable or disable caching of the HTTP response. See `$http Caching` for more information.
     *  - `timeout` - `{number|Promise}`:  timeout in milliseconds, or promise that should abort the request when resolved.
     *  - `responseType` - `{string}`:  see [XMLHttpRequest.responseType](https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest#xmlhttprequest-responsetype).
     *
     */


    function addCommand(cmd) {
      angular.extend(commandsMap, cmd);
    }
  }
})();