"use strict";

/**
 * Created by dan on 12/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.navigation').service('breadcrumbs', ['$rootScope', breadcrumbs]);

  function breadcrumbs($rootScope) {
    var service = this,
        items;
    service.path = path;
    service.setPath = setPath;
    service.events = {
      needsRefresh: 'breadcrumbs were updated'
    };
    return service;

    function path() {
      return items;
    }

    function setPath(elements) {
      items = elements;
      $rootScope.$broadcast(service.events.needsRefresh);
      $rootScope.$emit(service.events.needsRefresh);
    }
  }
})();