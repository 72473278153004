"use strict";

/**
 * Created by GB on 15/10/2019
 */
(function () {
  'use strict';

  angular.module('polcity.documenti', []).config(['$translatePartialLoaderProvider', addTranslations]);

  function addTranslations($translatePartialLoader) {
    $translatePartialLoader.addPart('osFramework/documenti');
  }
})();