"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGestioniSupplementariFromInfrazioni = void 0;

var _contants = require("./contants");

var _object = require("../../object.utils");

var _utils = require("../../utils");

const getGestioniSupplementariFromInfrazioni = infrazioni => {
  return infrazioni.reduce((acc, val) => {
    const hasInfrazioneRosso = (0, _object.getPathBooleanOrFalse)(["decoratori", "decoratoriIds", "infrazione_rosso"])(val);
    const hasInfrazioneVelocita = (0, _object.getPathBooleanOrFalse)(["decoratori", "decoratoriIds", "infrazione_velocita"])(val);
    const hasInfrazioneSenzaDocumenti = (0, _object.getPathBooleanOrFalse)(["decoratori", "decoratoriIds", "infrazione_senza_documenti"])(val);
    const hasInfrazioneZtl = (0, _object.getPathBooleanOrFalse)(["decoratori", "decoratoriIds", "infrazione_ztl"])(val);
    const {
      hasFermoSequestro,
      hasRimozione,
      generico
    } = val.sanzioniAccessorie.reduce((acc2, val2) => {
      return {
        hasFermoSequestro: acc2.hasFermoSequestro || val2.decoratore === "sanz_acc_fermo_sequestro",
        hasRimozione: acc2.hasRimozione || val2.decoratore === "sanz_acc_rimozione",
        generico: [...acc2.generico, ...(0, _utils.insertIf)(val2.decoratore === "sanz_acc_generica", val2)]
      };
    }, {
      hasFermoSequestro: false,
      hasRimozione: false,
      generico: []
    });
    return {
      ztl: acc.ztl || hasInfrazioneZtl,
      rosso: acc.rosso || hasInfrazioneRosso,
      senzaDocumenti: acc.senzaDocumenti || hasInfrazioneSenzaDocumenti,
      velocita: acc.velocita || hasInfrazioneVelocita,
      fermoSequestro: acc.fermoSequestro || hasFermoSequestro,
      rimozione: acc.rimozione || hasRimozione,
      generico: (0, _utils.unique)([...acc.generico, ...generico], (a, b) => a.codiceVeloce === b.codiceVeloce)
    };
  }, {
    ztl: false,
    rosso: false,
    senzaDocumenti: false,
    velocita: false,
    fermoSequestro: false,
    rimozione: false,
    generico: []
  });
};

exports.getGestioniSupplementariFromInfrazioni = getGestioniSupplementariFromInfrazioni;