"use strict";

/**
 * Created by dan on 02/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.api').service('exceptionHandlerInjector', ['$q', 'apiException', '$rootScope', 'uiEvents', checkForException]);

  function checkForException($q, apiException, $rootScope, uiEvents) {
    return {
      response: checkExceptionInSuccess,
      responseError: propagateException
    };

    function checkExceptionInSuccess(response) {
      if (R.path(['data', 'exception'], response) !== undefined) {
        $rootScope.$broadcast(uiEvents.showToast, {
          isSuccess: false,
          message: searchForErrorMessage(response)
        });
        $rootScope.$broadcast(uiEvents.stopProgress);
        return $q.reject();
      }

      return response;
    }

    function propagateException(rejection) {
      $rootScope.$broadcast(uiEvents.stopProgress);
      $rootScope.$broadcast(uiEvents.showToast, {
        isSuccess: false,
        message: searchForErrorMessage(rejection)
      });

      if (rejection.status === 404) {
        return $q.reject(new apiException.notFoundException(rejection.data));
      }

      if (rejection.status === 401 || R.path(['data', 'message'])(rejection) && R.path(['data', 'message'])(rejection).indexOf('401') > -1) {
        $rootScope.$broadcast(uiEvents.notAuthenticated);
        return $q.reject(new apiException.unauthorizedException(rejection.data));
      }

      return $q.reject(rejection);
    }

    function searchForErrorMessage(response) {
      var message = '';

      if (R.path(['data', 'exception'], response) !== undefined) {
        message = R.path(['data', 'message'], response);

        if (!message) {
          message = R.path(['data', 'exception'], response) + ' in ' + R.pathOr('', ['config', 'url'], response);
        }

        return message;
      }

      if (R.path(['data', 'errorDTOs'], response) !== undefined) {
        var readErrorFromDTO = R.prop('message');

        if (R.isArrayLike(R.path(['data', 'errorDTOs'], response))) {
          message = R.join('<br>', R.map(readErrorFromDTO, R.path(['data', 'errorDTOs'], response)));
        } else {
          message = readErrorFromDTO(R.path(['data', 'errorDTOs'], response));
        }

        return message;
      }

      if (R.path(['message'], response)) {
        return R.path(['message'], response);
      }

      if (R.path(['data', 'errors'], response)) {
        return response.data.errors[0].message;
      }

      if (response.status === 404 && response.data) {
        return response.data.status + ' - ' + response.data.path;
      }

      return response.status + ' - ' + response.statusText;
    }
  }
})();