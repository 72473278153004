"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userJoinGivenGroup = exports.useKeycloakGroups = void 0;

var _app = require("../../../app");

var _object = require("../../../object.utils");

const useKeycloakGroups = () => (0, _object.getPathArrayOrEmptyArray)(["tokenParsed", "gruppi_utente"])(_app.keycloakAuth);

exports.useKeycloakGroups = useKeycloakGroups;

const userJoinGivenGroup = group => {
  const gruppiUtente = useKeycloakGroups() || [];
  return gruppiUtente.some(gruppo => gruppo.includes(group));
};

exports.userJoinGivenGroup = userJoinGivenGroup;