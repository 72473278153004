"use strict";

var _TipologiaRimozioneEditRoot = _interopRequireDefault(require("./edit/TipologiaRimozioneEditRoot"));

var _TipologieRimozioniList = _interopRequireDefault(require("./TipologieRimozioniList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('tipologieRimozioniTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsTipologieRimozioni',
    url: 'tds/tipologieRimozioni',
    role: 'ROLE_TIPOLOGIA_RIMOZIONE_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _TipologieRimozioniList.default
      }
    },
    resolve: {
      $label: () => "Tipologie rimozioni"
    }
  }, {
    name: 'tdsTipologieRimozioniEdit',
    url: 'tds/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_TIPOLOGIA_RIMOZIONE_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _TipologiaRimozioneEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita tipologia rimozione"
    }
  }];
  routesProviderFactory.extend(this);
}