"use strict";

/**
 * Created by dan on 22/08/16.
 *
 * This interceptors will disable links, inputs, buttons etc while the app will be doing background http requests
 */
(function () {
  'use strict';

  angular.module('polcity.interceptors').factory('disableUI', ['$q', disableUI]);

  function disableUI($q) {
    var elementsSelector = 'input,button,a,[ng-click],select,textarea',
        disabledClass = 'disabled-during-http-request',
        unresolvedRequests = 0;
    return {
      request: R.pipe(incrementRequests, _disableUI),
      response: R.pipe(decrementRequests, _enableUI),
      responseError: R.pipe(reject, decrementRequests, _enableUI),
      requestError: R.pipe(reject, decrementRequests, _enableUI)
    };

    function reject(error) {
      return $q.reject(error);
    }

    function incrementRequests(config) {
      ++unresolvedRequests;
      return arguments[0];
    }

    function decrementRequests() {
      --unresolvedRequests;

      if (unresolvedRequests < 0) {
        unresolvedRequests = 0;
      }

      return arguments[0];
    }

    function _enableUI() {
      if (unresolvedRequests !== 0) {
        return arguments[0];
      }

      $('.' + disabledClass).removeAttr('disabled').removeClass('disabled').removeClass(disabledClass);
      $('body').removeClass('is-loading');
      return arguments[0];
    }

    function _disableUI(config) {
      if (config.disableUI == false) {
        delete config.disableUI;
        return config;
      }

      if (unresolvedRequests > 1) {
        return arguments[0];
      }

      $(elementsSelector).each(function () {
        var element = $(this); //disable only elements that are not already disabled

        if (isNotDisabled(element)) {
          element.addClass(disabledClass).addClass('disabled').attr('disabled', 'disabled');
        }
      });
      $('body').addClass('is-loading');
      return arguments[0];
    }

    function isNotDisabled(element) {
      return element.prop('disabled') === false && !element.attr('ng-disabled') && !element.hasClass('disabled');
    }
  }
})();