"use strict";

/**
 * Created by MN 01/08/2018
 */
(function () {
  'use strict';

  angular.module('polcity.lotti', ['polcity.api', //'ngMaterial',
  'pascalprecht.translate', //'md.data.table',
  'osTableComponent']).config(['$translatePartialLoaderProvider', function ($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('lotti');
  }]);
})();