"use strict";

angular.module('polcity.ruoli').config(['$stateProvider', 'ruoliRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['ruoliRoutes', routesRun]);

function addRoutes($stateProvider, ruoliRoutesProvider, $translatePartialLoader, AuthProvider) {
  R.forEach(function (routeDefinition) {
    var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

    if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
      $stateProvider.state(stateName, routeDefinition);
    }
  }, ruoliRoutesProvider.list());
}

function routesRun(ruoliRoutes) {
  ruoliRoutes.subscribeUIEvents();
}