"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleCancellaDocumentoSoggetto = exports.useCancellaDocumentoSoggettoReactQuery = exports.useModificaDocumentoSoggettoReactQuery = exports.useCreaDocumentoSoggettoReactQuery = exports.useDocumentoSoggettoReactQuery = exports.useDocumentiSoggettoReactQuery = exports.documentoSoggettoEditModalOpen = exports.documentoToEdit = exports.documentiSoggettoKeys = exports.documentiSoggettoName = void 0;

var _recoil = require("recoil");

var _api = require("../../api");

var _utils = require("./utils");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _utils2 = require("../../utils");

var _utils3 = require("../../react/reactQuery/utils");

const documentiSoggettoName = "documentiSoggetto";
exports.documentiSoggettoName = documentiSoggettoName;
const documentiSoggettoKeys = {
  all: () => [documentiSoggettoName],
  detail: ({
    aggId,
    entityId
  }) => [...documentiSoggettoKeys.all(), "detail", ...(0, _utils2.insertIf)(aggId, {
    aggId
  }), ...(0, _utils2.insertIf)(entityId, {
    entityId
  })],
  quickSearch: params => [...documentiSoggettoKeys.all(), "quickSearch", ...(0, _utils2.insertIf)(params, params)]
};
exports.documentiSoggettoKeys = documentiSoggettoKeys;
const documentoToEdit = (0, _recoil.atomFamily)({
  key: "documentoToEdit",
  default: undefined
});
exports.documentoToEdit = documentoToEdit;
const documentoSoggettoEditModalOpen = (0, _recoil.atom)({
  key: "documentoSoggettoEditModalOpen",
  default: false
});
exports.documentoSoggettoEditModalOpen = documentoSoggettoEditModalOpen;

const useDocumentiSoggettoReactQuery = soggettoId => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const queryClient = (0, _reactQuery.useQueryClient)();
  const {
    isLoading,
    data,
    isFetching
  } = (0, _reactQuery.useQuery)(documentiSoggettoKeys.quickSearch({
    aggId: soggettoId
  }), ({
    queryKey
  }) => {
    const query = {
      aggId: queryKey[2].aggId
    };
    return (0, _api.ricercaDocumentiAnagrafica)({
      query
    });
  }, {
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(soggettoId),
    suspense: true,
    onSuccess: response => {
      response.map(item => queryClient.setQueryData([...documentiSoggettoKeys.detail({
        aggId: item.aggId,
        entityId: item.entityId
      })], item));
      (0, _utils3.handleSuccess)(messenger)(response);
    },
    onError: response => (0, _utils3.handleError)(messenger)(response),
    select: response => response.error ? response : (response === null || response === void 0 ? void 0 : response.resultsWrapper.values) || []
  });
  return {
    isLoading: isLoading || isFetching,
    documenti: data
  };
};

exports.useDocumentiSoggettoReactQuery = useDocumentiSoggettoReactQuery;

const useDocumentoSoggettoReactQuery = (aggId, entityId) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data: item,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(documentiSoggettoKeys.detail({
    aggId,
    entityId
  }), ({
    queryKey
  }) => (0, _api.dettaglioDocumento)({
    aggId: queryKey[2].aggId,
    entityId: queryKey[3].entityId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId) && Boolean(entityId),
    onSuccess: (0, _utils3.handleSuccess)(messenger),
    onError: response => (0, _utils3.handleError)(messenger)(response),
    select: data => (0, _utils.fromApiDocumento)(data)
  });
  return {
    item: item,
    isFetching: isFetching || isLoading
  };
};

exports.useDocumentoSoggettoReactQuery = useDocumentoSoggettoReactQuery;

const useCreaDocumentoSoggettoReactQuery = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Documento salvato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(documento => (0, _api.creaDocumento)((0, _utils.toApiDocumento)(documento)), {
    onSuccess: response => (0, _utils3.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils3.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(documentiSoggettoKeys.all())
  });
};

exports.useCreaDocumentoSoggettoReactQuery = useCreaDocumentoSoggettoReactQuery;

const useModificaDocumentoSoggettoReactQuery = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Documento salvato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(documento => (0, _api.modificaDocumento)((0, _utils.toApiDocumento)(documento)), {
    onSuccess: response => (0, _utils3.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils3.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(documentiSoggettoKeys.all())
  });
};

exports.useModificaDocumentoSoggettoReactQuery = useModificaDocumentoSoggettoReactQuery;

const useCancellaDocumentoSoggettoReactQuery = ({
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(({
    aggId,
    entityId
  }) => (0, _api.cancellaDocumento)({
    aggId,
    entityId
  }), {
    onSuccess: response => {
      handleCancellaDocumentoSoggetto(messenger)(response);
      onSuccess(response);
    },
    onError: response => (0, _utils3.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(documentiSoggettoKeys.all())
  });
};

exports.useCancellaDocumentoSoggettoReactQuery = useCancellaDocumentoSoggettoReactQuery;

const handleCancellaDocumentoSoggetto = messenger => response => {
  (0, _utils3.handleSuccess)(messenger)(response);
  if (!response.error) messenger({
    type: 'success',
    message: "Documento cancellato",
    severity: 'success'
  });
};

exports.handleCancellaDocumentoSoggetto = handleCancellaDocumentoSoggetto;