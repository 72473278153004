"use strict";

/**
 * Created by MN 26/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('LuoghiRicoveroInfortunatiFormController', ['$stateParams', 'Command', '$tdsApiService', '$tdsGlobalBehaviors', LuoghiRicoveroInfortunatiFormController]);

  function LuoghiRicoveroInfortunatiFormController($stateParams, Command, $tdsApiService, $tdsGlobalBehaviors) {
    var luoghiRicoveroInfortunatiFormCtrl = this,
        dettaglioCommand = '@dettaglioLuogoRicoveroInfortunati',
        modificaCommand = '@modificaLuogoRicoveroInfortunati',
        salvaCommand = '@salvaLuogoRicoveroInfortunati',
        redirectTo = 'navigation.luoghiRicoveroInfortunatiTds';
    /**
     * Discriminante per capire se sono in edit
     * @type {boolean}
     */

    luoghiRicoveroInfortunatiFormCtrl.editMode = false;
    /**
     * @Property
     * Configuro l'aggId a zero leggendolo dagli $stateParams nel caso sia in inserimento
     * oppure lo recupero dall'oggetto nel caso in cui sia in edit
     */

    luoghiRicoveroInfortunatiFormCtrl.aggId = $stateParams.aggId;
    /**
     * @Property
     * Configuro l'oggetto recuperandolo dagli $stateParams nel caso in cui sia in edit
     * oppure assegnando un oggetto vuoto nel caso in cui sia in inserimento
     */

    luoghiRicoveroInfortunatiFormCtrl.item = {};
    /**
     * Handler onInit function
     * @type {onInit}
     */

    luoghiRicoveroInfortunatiFormCtrl.$onInit = onInit;
    /**
     * Operazioni eseguite in istanza del controller
     */

    function onInit() {
      // SE NEGLI STATE PARAMS HO L'AGGID POSSO ESSERE IN EDIT OPPURE AVER RICARICATO LA PAGINA
      if (luoghiRicoveroInfortunatiFormCtrl.aggId) {
        luoghiRicoveroInfortunatiFormCtrl.editMode = true;
        var params = {
          aggId: luoghiRicoveroInfortunatiFormCtrl.aggId
        };
        $tdsApiService.executeCommand(dettaglioCommand, params).then(function (res) {
          luoghiRicoveroInfortunatiFormCtrl.item = res;
        }).catch(function () {
          handleItemLoadError();
        });
      } else {
        luoghiRicoveroInfortunatiFormCtrl.editMode = false;
      }
    }
    /**
     * Handler ricerca comuni
     * @param query
     * @returns {*}
     */


    luoghiRicoveroInfortunatiFormCtrl.queryApi = function (query) {
      var data = {};

      if (query) {
        data.nome = query;
      }

      return recuperaComuni(data);
    };
    /**
     * Recupera i comuni
     */


    function recuperaComuni(params) {
      return Command.execute('@ricercaComuni', params).then(function (res) {
        return res.resultsWrapper.values;
      });
    }
    /**
     * @property
     * Handler da eseguire al click sul pulsante del salvataggio
     * @param item
     */


    luoghiRicoveroInfortunatiFormCtrl.onSaveRicInfButtonClick = function (item) {
      var command = luoghiRicoveroInfortunatiFormCtrl.editMode ? modificaCommand : salvaCommand;
      $tdsApiService.salvaElementoAndRedirect(command, item, luoghiRicoveroInfortunatiFormCtrl.editMode, redirectTo);
    };
    /****************
     * Utils
     ***************/


    function handleItemLoadError() {
      //Notifica errore
      $tdsGlobalBehaviors.notifica('error', 'tds.elementNotFount__notification'); //Go back

      $tdsGlobalBehaviors.redirectTo(redirectTo);
    }
  }
})();