"use strict";

/**
 * MN 13/11/2018
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaContenutoAllegato', [RicercaContenutoAllegato]);

  function RicercaContenutoAllegato() {
    return {
      restrict: 'E',
      require: ['ngModel'],
      scope: {
        entity: '=?',
        label: '@?',
        onRicercaFunzioneStampaChange: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', '$filter', controllerFn],
      controllerAs: 'ricercaContenutoAllegatoCtrl',
      templateUrl: 'osFramework/input/ricercaContenutoAllegato/ricercaContenutoAllegato.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter) {
      var me = this;
      me.groupRoles = ['ROLE_CONTENUTO_ALLEGATO_RICERCA', 'ROLE_CONTENUTO_ALLEGATO_DETTAGLIO'];

      me.getItemText = function (contenutoAllegato) {
        var itemText;
        itemText = contenutoAllegato.descrizioneContenuto;
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.aggId = null;
          return;
        }

        scope.aggId = viewValue;
      };

      scope.$watch('aggId', function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();