"use strict";

(function () {
  'use strict';
  /**
   * Created by GB on 03/07/2018
   *
   * @ngdoc component
   * @name os-allegati
   * @module osAllegati
   * @description
   *
   * Il componente gestisce gli allegati dell'applicazione.
   * E' possibile associarlo ad una qualunque delle entità presenti (verbali, ricorsi, incodenti...)
   * Consente di
   * * caricare un allegato (img, pdf, video)
   * * modificare un allegato
   * * scaricare un allegato
   * * visualizzare la preview di un allegato
   * * eliminare un allegato
   *
   * NB: per funzionare, al momento, è necessario definire nel file commands le api per il dettaglio
   *     (@detail<entità>)
   * e per la lista allegati (@allegati<entità>)
   *
   * @usage
   *
   * <os-allegati
   *  module="incidenti"
   *  aggregate-canonical-name="eu.opensoftware.incidente.ao.Incidente"
   *  navigation-item-property="$numeroIncidente"
   *  navigation-item-state="verbaleEdit"
   *  enable-preview="true"
   *  enable-download="true"
   *  enable-delete="true"
   *  enable-edit="true"
   *  enable-create="true"
   *  item-command="@detailIncidente"
   *  allegati-command="@allegatiIncidente">
   * </os-allegati>
   *
   * @param {string} module Nome del modulo di riferimento (incidenti, verbali, ricorsi).
   * @param {string} aggregateCanonicalName Aggregate canonical name dell'entità di riferimento.
   * @param {string} navigationItemProperty Proprietà all'interno dell'entità da visualizzare nella navigation bar
   * @param {string} navigationItemState lo stato in cui riportare l'applicazione quando l'utente clicca sulla
   *     navigationItemProperty
   * @param {boolean} enablePreview abilita la preview dell'allegato
   * @param {boolean} enableDownload abilita il download dell'allegato
   * @param {boolean} enableDelete abilita la cancellazione dell'allegato
   * @param {boolean} enableEdit abilita la modifica dell'allegato
   * @param {enableCreate} enableEdit la creazione di un allegato
   * @param {string} itemCommand nome del command per caricare il dettaglio dell'entità
   * @param {string} allegatiCommand nome del command per la lista allegati dell'entità
   **/

  angular.module('osAllegati').component('osAllegati', {
    controller: ['$scope', '$state', '$stateParams', 'Command', 'FileSaver', '$sce', 'modalPresenter', 'OSNotification', '$mdDialog', osAllegatiComponentComponentController],
    controllerAs: 'osAllegatiController',
    templateUrl: 'osFramework/osAllegati/osAllegati.component.html',
    bindings: {
      module: '@',
      aggregateCanonicalName: '@',
      navigationItemProperty: '@',
      navigationItemState: '@',
      enablePreview: '<',
      enableDownload: '<',
      enableDelete: '<',
      enableEdit: '<',
      enableCreate: '<',
      itemCommand: '@',
      allegatiCommand: '@',
      selectable: '<?',
      onSelectedItemsChange: '&?'
    }
  });

  function osAllegatiComponentComponentController($scope, $state, $stateParams, Command, FileSaver, $sce, modalPresenter, OSNotification, $mdDialog) {
    var me = this; //osAllegatiController

    /******************************************
     * Controller properties
     ******************************************/

    /**
     * @property
     * Lista degli allegati
     * @type {Array}
     */

    me.allegati = [];
    /**
     * @property
     * Oggetto di gestione della modale di preview:
     * * previewFile: fileUrl di cui visualizzare la preview (discrimina anche la visualizzazione della modale)
     * * previewTitle: title della maschera di preview
     * * fileExtensione: estensione del file da visualizzare. Discrimina quale tag HTML usare per la visualizzazione
     * @type {{previewFile: string, previewTitle: string, fileExtension: string}}
     */

    me.preview = {
      previewFile: false,
      previewTitle: null,
      fileExtension: null
    };
    /**
     * @property
     * Handler scatenato alla pressione del bottone preview
     * @type {function}
     */

    me.presentPreviewModal = presentPreviewModal;
    /**
     * @property
     * Handler scatenato alla pressione del bottone crea
     * @type {function}
     */

    me.presentCreateModal = presentCreateModal;
    /**
     * @property
     * Handler scatenato alla pressione del bottone edit
     * @type {function}
     */

    me.presentEditModal = presentEditModal;
    /**
     * @property
     * Handler scatenato alla pressione del bottone download
     * @type {function}
     */

    me.downloadAllegato = downloadAllegato;
    /**
     * @property
     * Handler scatenato alla pressione del bottone cancella
     * @type {function}
     */

    me.confirmAndDelete = confirmAndDelete;
    /**
     * @property
     * Handler scatenato alla pressione del bottone di chiusura della preview del file
     * @type {function}
     */

    me.closePreview = closePreview;
    /**
     * @config
     * true | false a seconda che si voglia abilitare o meno la selezione degli allegati
     */

    me.selectable = false;
    /**
     * @config
     * Il medoto da chiamare alla selezione di un item
     */

    me.selectedItems = {};

    me.disablePreviewBtn = estensioneFile => ['doc', 'docx', 'txt'].some(e => e == estensioneFile.toLowerCase()); //*****************************************

    /******************************************
     * Local properties
     ******************************************/


    var
    /**
     * @private
     * Lista delle estensioni di file immagine supportate
     * @type {string[]}
     */
    imageExtensions = ['jpg', 'jpeg', 'png', 'gif'],

    /**
     * @private
     * Lista delle estensioni di file pdf supportate
     * @type {string[]}
     */
    pdfExtension = ['pdf'],

    /**
     * @private
     * Insieme delle estensioni di file supportate
     * @type {string[]}
     */
    supportedFormats = R.concat(imageExtensions, pdfExtension); //isSupported         = function(extension){return R.contains(extension, supportedFormats)}
    //*****************************************

    /******************************************
     * Scope properties
     ******************************************/

    /**
     * @protected
     * Dettaglio entità a cui sono associati gli allegati
     */

    $scope.item = $stateParams.item;
    /**
     * @protected
     * Valore della proprietà da utilizzare nella navigation bar
     */

    $scope.navigationItemProperty = $scope.item ? $scope.item[me.navigationItemProperty] : '?';
    /**
     * @protected
     * AggId dell'entità a cui sono associati gli allegati
     */

    $scope.aggId = $stateParams.aggId; //*****************************************

    /**
     * Operazioni di inizializzazione del componente
     * Aggiunge alcune variabili allo scope, carica l'item se non già presente e gli allegati
     */

    me.$onInit = function () {
      $scope.module = me.module;
      $scope.navigationItemState = me.navigationItemState;

      if (!me.item) {
        fetchItem($scope.aggId).then(function (item) {
          $scope.item = item;
          $scope.navigationItemProperty = item[me.navigationItemProperty];
        });
      }

      loadAllegati();
    };
    /**
     * Il metodo fa il fetch degli allegati e setta la relativa proprietà del controller
     */


    function loadAllegati() {
      setAllegati([]);
      fetchAllegati($scope.aggId).then(function (allegati) {
        setAllegati(parseAllegatiResponse(allegati));
      });
    }
    /***************************************
     * Preview & download handlers
     ***************************************/

    /**
     * Il metodo fa il fetch dell'allegato e valizza le proprietà della preview per visualizzare il file
     * @param allegato
     */


    function presentPreviewModal($event, allegato) {
      fetchAllegato(allegato).then(function (response) {
        var file = getFileFromResponse(response),
            fileName = allegato.nomeFileOriginale;
        setPreview({
          previewFile: $sce.trustAsResourceUrl(file.fileURL),
          previewTitle: fileName,
          fileExtension: allegato.estensione ? allegato.estensione.toLowerCase() : "jpg"
        });
      }).then(function () {
        showPreviewModal($event);
      });
    }

    function showPreviewModal(ev) {
      $mdDialog.show({
        controller: function () {
          /**
           * @porperty
           * true|false a seconda che l'estensione passata come argomento appartenga al gruppo pdf
           * @type {function}
           */
          this.isPdf = function (extension) {
            return R.contains(extension, pdfExtension);
          };
          /**
           * @porperty
           * true|false a seconda che l'estensione passata come argomento appartenga al gruppo immagini
           * @type {function}
           */


          this.isImage = function (extension) {
            return R.contains(extension, imageExtensions);
          };
        },
        templateUrl: 'osFramework/osAllegati/modal/previewModal.component.html',
        parent: angular.element(document.body),
        bindToController: true,
        targetEvent: ev,
        clickOutsideToClose: true,
        fullscreen: false,
        controllerAs: 'previewModalCtrl',
        escapeToClose: true,
        locals: {
          preview: me.preview,
          hide: $mdDialog.hide
        }
      });
    }
    /**
     * Il metodo da il fetch dell'allegato e lo salva su disco
     * @param allegato
     */


    function downloadAllegato(allegato) {
      fetchAllegato(allegato).then(function (response) {
        var file = getFileFromResponse(response);
        FileSaver.saveAs(file.blob, allegato.nomeFileOriginale);
      });
    }
    /**
     * Il metodo elabora l'array bi dyte restituito dal server per costruire un Blob
     * @param response
     */


    function getFileFromResponse(response) {
      var file = {};
      file.blob = new Blob([response.data], {
        type: response.headers('content-type')
      });
      file.fileURL = URL.createObjectURL(file.blob);
      return file;
    }
    /**
     * Il medoto azzera le proprietà della preview (chiusura)
     */


    function closePreview() {
      setPreview({});
    } //**************************************

    /***************************************
     * Delete handler
     ***************************************/

    /**
     * Apre la modale di conferma cancellazione
     * In caso affermativo cancella l'allegato
     * @param $event
     * @param allegato
     */


    function askUserConfirm(type, title, body, confirmBtn) {
      return modalPresenter[type]([title, body, confirmBtn, 'acquisizioneDati.rilevazioni.cancellaRilevazioneModal__undo_button']);
    }

    function confirmAndDelete($event, allegato) {
      askUserConfirm('warn', 'warnings.irreversibleAction', 'allegati.confirmRemoval__text', 'actions.delete__buttonText').then(deleteAllegato.bind(me, allegato.aggId)).then(notify('allegati.itemWasDeleted__text', 'success')).then(loadAllegati).catch(function (err) {
        notify('allegati.errorWhileFetchingResults__text', 'error');
      });
    } //**************************************

    /***************************************
     * Save and Edit modals handlers
     ***************************************/

    /**
     * Handler dell'operazione di edit
     */


    function presentEditModal(ev, allegato, item) {
      presentSaveModal(ev, allegato, item);
    }
    /**
     * Handler dell'operazione di creazione
     */


    function presentCreateModal(ev) {
      presentSaveModal(ev);
    }
    /**
     * Il medoto apre la modale configurata a seconda che si sia in modifica o creazione
     */


    function presentSaveModal(ev, allegato, item) {
      var locals = {
        aggId: $scope.aggId,
        aggregateCanonicalName: me.aggregateCanonicalName,
        attachmentId: allegato ? allegato.aggId : -1
      };
      $mdDialog.show({
        controller: 'osAllegatoFormController',
        templateUrl: 'osFramework/osAllegati/modal/osAllegatoForm.component.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        fullscreen: false,
        controllerAs: 'osAllegatoFormController',
        locals: locals
      }).then(loadAllegati).catch(notify('actions.canceledOperation__notification', 'info'));
    }
    /***********************************
     * Gestione multiselect
     ***********************************/


    me.onSelectionChange = function () {
      var allegati = Object.keys(me.selectedItems).reduce((acc, key) => {
        if (me.selectedItems[key]) {
          acc.push(key);
        }

        return acc;
      }, []);

      if (me.onSelectedItemsChange) {
        me.onSelectedItemsChange({
          allegati
        });
      }
    }; //**************************************

    /******************************************
     * Utils
     ******************************************/


    function fetchAllegato(allegato) {
      //TODO: farsi restituire l'id del file dall'aggregate explorer
      return Command.executeRaw('@allegatoDownload', {
        aggId: allegato.aggId
      }, null, {
        responseType: 'arraybuffer'
      });
    }

    function deleteAllegato(aggId) {
      Command.execute('@cancellaAllegato', {
        aggId: aggId
      });
    }

    function fetchItem(aggId) {
      return Command.execute(me.itemCommand, {
        aggId: aggId
      });
    }

    function fetchAllegati(aggId) {
      return Command.execute(me.allegatiCommand, {
        aggId: aggId
      });
    }

    function setAllegati(allegati) {
      me.allegati = allegati;
    }

    function setPreview(preview) {
      me.preview = preview;
    }
    /**
     * Metodo di parsing della risposta al fetchAllegati per preparare i dati da dare alla vista
     * @param data
     */


    function parseAllegatiResponse(data) {
      var result = {};
      result.totalAttachments = 0;
      R.map(function (projection) {
        result[projection.projectionClass] = {
          label: projection.frontendLabel,
          items: [],
          totalAttachments: 0,
          expanded: true
        };
        R.map(function (item) {
          if (item.attaches.length > 0) {
            result[projection.projectionClass].items.push(item);
            result[projection.projectionClass].totalAttachments += item.attaches.length;
          }
        }, projection.items);
        result.totalAttachments += result[projection.projectionClass].totalAttachments;

        if (result[projection.projectionClass].totalAttachments === 0) {
          delete result[projection.projectionClass];
        }
      }, data);
      return result;
    }

    function notify(labelTranslationIndex, type) {
      var notificationData = {
        translationIndex: labelTranslationIndex
      };
      return function () {
        switch (type) {
          case 'info':
            OSNotification.info(notificationData);
            break;

          case 'success':
            OSNotification.success(notificationData);
            break;

          case 'error':
            OSNotification.error(notificationData);
            break;
        }
      };
    } //*****************************************

  }
})();