"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _withStyles = _interopRequireDefault(require("@material-ui/core/styles/withStyles"));

var _core = require("@material-ui/core");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const TextFieldUppercase = (0, _withStyles.default)(theme => ({
  root: {
    "& .MuiInputBase-input": {
      textTransform: "uppercase"
    }
  }
}))(_core.TextField);
var _default = TextFieldUppercase;
exports.default = _default;