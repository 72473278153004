"use strict";

/**
 * Created by GB on 15/10/2019
 */
(function () {
  'use strict';

  angular.module('polcity.documenti').controller('documentoModalController', ['$mdDialog', documentoModalController]);

  function documentoModalController($mdDialog) {
    var me = this;
    me.isValid = false;

    me.onDocumentoChange = function (params) {
      me.documento = params.documento;
    };

    me.onValidityChange = function (params) {
      me.isValid = params.valid;
    };

    me.hide = function () {
      $mdDialog.cancel();
    };

    me.save = function (documento) {
      $mdDialog.hide(documento);
    };
  }
})();