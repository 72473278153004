"use strict";

/**
 * MN 14/07/2020
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaInfrazioneText', [RicercaInfrazioneText]);

  function RicercaInfrazioneText() {
    return {
      restrict: 'E',

      /*require     : ['ngModel'],*/
      scope: {
        entity: '=?',
        label: '@?',
        onInfrazioneChange: '<?',
        isReadonly: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', '$filter', controllerFn],
      controllerAs: 'ricercaInfrazioneTextCtrl',
      templateUrl: 'osFramework/input/ricercaInfrazioneText/ricercaInfrazioneText.directive.html',
      link: linkFn
    };

    function controllerFn(scope, $filter) {
      var me = this;
      me.groupRoles = ['notApplyRoleManagement', 'notApplyRoleManagement'];

      me.getItemText = function (violazione) {
        var itemText;
        itemText = violazione.codiceLibreria + ' - ' + violazione.titolo;
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {}
  }
})();