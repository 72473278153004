"use strict";

angular.module('polcity.allegatiDigitali').config(['$stateProvider', 'allegatiDigitaliRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['allegatiDigitaliRoutes', routesRun]);

function addRoutes($stateProvider, allegatiDigitaliRoutesProvider, $translatePartialLoader, AuthProvider) {
  R.forEach(function (routeDefinition) {
    var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

    if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
      $stateProvider.state(stateName, routeDefinition);
    }
  }, allegatiDigitaliRoutesProvider.list());
  $translatePartialLoader.addPart('allegatiDigitali');
}

function routesRun(allegatiDigitaliRoutes) {
  allegatiDigitaliRoutes.subscribeUIEvents();
}