"use strict";

/**
 * Created by gb on 30/10/19.
 * Multiple actions
 */
(function () {
  'use strict';

  angular.module('polcity.ricorsi').component('ricorsiMultipleActions', {
    bindings: {
      collection: '<'
    },
    templateUrl: 'ricorsi/multipleActions/ricorsi.multipleActions.component.html',
    controller: 'RicorsiMultipleActionsController',
    controllerAs: 'RicorsiMultipleActionsCtrl'
  });
})();