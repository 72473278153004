"use strict";

(function () {
  'use strict';
  /**
   * Dona la possiblità ad un Model di essere stampabile
   */

  angular.module('polcity.models').factory('Printable', ['restmod', function (restmod) {
    var API_PRINT = {
      url: 'stampa',
      method: 'POST',
      responseType: 'arraybuffer'
    };
    return restmod.mixin({
      $extend: {
        Scope: {
          //Costruttore
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);
            collection.$isPrintable = true;
            angular.extend(this.$config.api, {
              print: API_PRINT
            });
            return collection;
          }
        }
      }
    }, {
      $extend: {
        Resource: {
          $print: function () {
            return this.$action(function (_params) {
              var request = this.$buildRequest("print");
              request.data = Object.assign({}, _params);
              this.$dispatch('before-print', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-print', [_response]);
              }, function (_response) {
                this.$dispatch('after-print-error', [_response]);
              });
            });
          }
        }
      }
    });
  }]);
})();