"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _utils = require("../../../../utils");

var _formik = require("formik");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const DocumentoEffects = () => {
  const {
    values,
    setValues,
    touched,
    setFieldTouched,
    isSubmitting
  } = (0, _formik.useFormikContext)();
  (0, _react.useEffect)(() => {
    if (touched.tipoDocumento && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        numeroDocumento: "",
        dataRilascio: null,
        categorie: [],
        enteRilascio: null,
        specificheCQC: "",
        specificheCAP: "",
        dataScadenza: null,
        descrizione: "",
        stato: null
      }));
    }

    setFieldTouched("tipoDocumento", false);
  }, [touched.tipoDocumento]);
  return null;
};

var _default = DocumentoEffects;
exports.default = _default;