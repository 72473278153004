"use strict";

/**
 * Created by gb on 11/09/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.ricorso').component('ricorso', {
    templateUrl: 'ricorso/ricorso.component.html',
    controller: 'RicorsoController',
    controllerAs: 'RicorsoCtrl',
    bindings: {
      ricorso: '<'
    }
  });
})();