"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDecurtazioniPuntiMasterDetail = exports._availableSorters = exports._availableFilters = exports.decurtazioniPuntiMasterDetailName = void 0;

var _recoil = require("recoil");

var _masterDetail = require("../generics/masterDetail");

var _quickSearch = require("../generics/quickSearch");

var _api = require("../../api");

var _decurtazioniPunti = require("./decurtazioniPunti");

const decurtazioniPuntiMasterDetailName = "decurtazioniPunti";
exports.decurtazioniPuntiMasterDetailName = decurtazioniPuntiMasterDetailName;

const _availableFilters = (0, _recoil.atom)({
  key: '_availableFiltersDecurtazioniPutni',
  default: [{
    name: 'stato',
    label: 'Stato',
    value: null
  }, {
    name: 'tipoAccertamento',
    label: 'Tipo accertamento',
    value: null
  }, {
    name: 'verbale',
    label: 'Verbale',
    value: null
  }, {
    name: 'dataVerbale',
    label: 'Data verbale',
    value: {
      startDate: null,
      endDate: null
    }
  }, {
    name: 'dataDefinizione',
    label: 'Data definizione',
    value: {
      startDate: null,
      endDate: null
    }
  }]
});

exports._availableFilters = _availableFilters;

const _availableSorters = (0, _recoil.atom)({
  key: '_availableSortersDecurtazioniPunti',
  default: [{
    name: 'dataVerbale',
    label: 'Data verbale',
    direction: null
  }, {
    name: 'dataUltimaModifica',
    label: 'Data ultima modifica',
    direction: null
  }, {
    name: 'tipoAccertamentoCodiceVeloce',
    label: 'Tipo accertamento',
    direction: null
  }, {
    name: 'numeroVerbaleNumero',
    label: 'Numero verbale',
    direction: null
  }, {
    name: 'numeroVerbaleAnno',
    label: 'Anno numero verbale',
    direction: null
  }]
});

exports._availableSorters = _availableSorters;
const useDecurtazioniPuntiQuickSearch = (0, _quickSearch.useQuickSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    order,
    stato,
    tipoAccertamento,
    verbale,
    dataVerbale,
    dataDefinizione
  }) => (0, _api.ricercaDecurtazioniPunti)({
    page,
    limit,
    order,
    stato: stato === null || stato === void 0 ? void 0 : stato.alias,
    tipoAccertamentoId: tipoAccertamento === null || tipoAccertamento === void 0 ? void 0 : tipoAccertamento.aggId,
    numeroVerbale: verbale === null || verbale === void 0 ? void 0 : verbale.idVerbale,
    dataVerbale: dataVerbale && dataVerbale.startDate && dataVerbale.endDate && [dataVerbale.startDate, dataVerbale.endDate],
    dataDefinizione: dataDefinizione && dataDefinizione.startDate && dataDefinizione.endDate && [dataDefinizione.startDate, dataDefinizione.endDate]
  })
});

const useDecurtazioniPuntiMasterDetail = props => {
  const {
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  } = props || {};
  const availableSorters = (0, _recoil.useRecoilValue)(_availableSorters);
  const availableFilters = (0, _recoil.useRecoilValue)(_availableFilters);
  const {
    open,
    selectionActive,
    activeItem,
    setState,
    reset: resetState
  } = (0, _masterDetail.useMasterDetailState)({
    name,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  });
  const {
    page,
    limit,
    items,
    isLoading,
    count,
    filters,
    activeFilters,
    activeSorters,
    activeSortersCount,
    changePage,
    changeLimit,
    addFilter,
    removeFilter,
    setActiveSorters,
    refresh,
    reset
  } = useDecurtazioniPuntiQuickSearch({
    resourceKeys: _decurtazioniPunti.decurtazioniPuntiKeys,
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp
  });

  const resetMasterDetail = () => {
    reset();
    resetState();
  };

  return {
    page,
    limit,
    isLoading: isLoading,
    items: items,
    count: count,
    availableFilters,
    activeFilters,
    changePage,
    changeLimit,
    addFilter: props => {
      activeFilters.length && removeFilter(props.name);
      addFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    removeFilter: props => {
      removeFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    availableSorters,
    activeSorters,
    activeSortersCount,
    setSorters: setActiveSorters,
    refresh: refresh,
    //Master detail props
    open,
    selectionActive,
    activeItem,
    setState,
    resetMasterDetail
  };
};

exports.useDecurtazioniPuntiMasterDetail = useDecurtazioniPuntiMasterDetail;