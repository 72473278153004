"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useModelliStampaMasterDetail = exports._availableSorters = exports._availableFilters = exports.modelliStampaMasterDetailName = void 0;

var _recoil = require("recoil");

var _quickSearch = require("../generics/quickSearch");

var _api = require("../../api");

var _masterDetail = require("../generics/masterDetail");

var _modelliStampa = require("./modelliStampa");

const modelliStampaMasterDetailName = "modelliStampa";
exports.modelliStampaMasterDetailName = modelliStampaMasterDetailName;

const _availableFilters = (0, _recoil.atom)({
  key: '_availableFiltersModelliStampa',
  default: [{
    name: 'codice',
    label: 'Codice',
    value: null
  }, {
    name: 'descrizione',
    label: 'Descrizione',
    value: null
  }]
});

exports._availableFilters = _availableFilters;

const _availableSorters = (0, _recoil.atom)({
  key: '_availableSortersModelliStampa',
  default: [{
    name: 'codice',
    label: 'Codice',
    direction: null
  }, {
    name: 'descrizione',
    label: 'Descrizione',
    direction: null
  }]
});

exports._availableSorters = _availableSorters;
const useModelliStampaQuickSearch = (0, _quickSearch.useQuickSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    order,
    codice,
    descrizione
  }) => (0, _api.ricercaModelliStampa)({
    page,
    limit,
    order,
    codice,
    descrizione
  })
});

const useModelliStampaMasterDetail = props => {
  const {
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  } = props || {};
  const availableSorters = (0, _recoil.useRecoilValue)(_availableSorters);
  const availableFilters = (0, _recoil.useRecoilValue)(_availableFilters);
  const {
    open,
    selectionActive,
    activeItem,
    setState,
    reset: resetState
  } = (0, _masterDetail.useMasterDetailState)({
    name,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  });
  const {
    page,
    limit,
    items,
    isLoading,
    count,
    activeFilters,
    activeSorters,
    activeSortersCount,
    changePage,
    changeLimit,
    addFilter,
    removeFilter,
    setActiveSorters,
    refresh,
    reset
  } = useModelliStampaQuickSearch({
    resourceKeys: _modelliStampa.modelliStampaKeys,
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp
  });

  const resetMasterDetail = () => {
    reset();
    resetState();
  };

  return {
    page,
    limit,
    isLoading: isLoading,
    items: items,
    count: count,
    availableFilters,
    activeFilters,
    changePage,
    changeLimit,
    addFilter: props => {
      activeFilters.length && removeFilter(props.name);
      addFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    removeFilter: props => {
      removeFilter(props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    availableSorters,
    activeSorters,
    activeSortersCount,
    setSorters: setActiveSorters,
    refresh: refresh,
    //Master detail props
    open,
    selectionActive,
    activeItem,
    setState,
    resetMasterDetail
  };
};

exports.useModelliStampaMasterDetail = useModelliStampaMasterDetail;