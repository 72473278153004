"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('RicercaVeloce', ['restmod', 'RMCommonApi', function (restmod) {
    var API_RICERCA_VELOCE = 'ricerca-veloce';
    return restmod.mixin({
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);
            collection.$isRicercaVeloce = true;
            return collection;
          }
        },
        Collection: {
          $ricercaVeloce: function (_params) {
            var me = this,
                params,
                request;
            /*this.$url = function(){
               return this.$getBaseEntityPath() + API_RICERCA_VELOCE;
            };
            params = R.path('$metadata', 'search', this) ? angular.extend(this.$metadata.search, _params) : _params;*/

            request = {
              url: this.$getBaseEntityPath() + API_RICERCA_VELOCE,
              method: 'GET'
            };
            params = Object.assign({}, this.$params, _params); //Usiamo la stessa collection per mantenere il riferimento

            this.$clear(); //Pulizia dei vecchi dati

            return this.$fetch(params, request);
          },
          $getElasticFilters: function () {
            return this.$metadata.filters;
          }
        }
      }
    }, function () {
      /**
       * OS RicercaVeloce implementation
       */

      /*this.define('Scope.$ricercaVeloce', function(_params) {
          var me = this;
          return this.$collection(_params, {
              $urlFor: function() {
                  return me.$url() + '/ricerca-veloce';
              }
          }).$fetch();
       })*/
    });
  }]);
})();