"use strict";

var _FrazioniList = _interopRequireDefault(require("./FrazioniList"));

var _FrazioneEditRoot = _interopRequireDefault(require("./edit/FrazioneEditRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('frazioniTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsFrazioni',
    url: 'tds/gestioneDelTerritorio/{comuneId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}/frazioni',
    role: 'ROLE_FRAZIONE_RICERCA',
    params: {
      comuneId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _FrazioniList.default
      }
    },
    resolve: {
      $label: () => "Frazioni"
    }
  }, {
    name: 'tdsFrazioniEdit',
    url: 'tds/gestioneDelTerritorio/{comuneId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}/frazioni/{frazioneId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_FRAZIONE_MODIFICA',
    params: {
      frazioneId: null,
      comuneId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _FrazioneEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita frazione"
    }
  }];
  routesProviderFactory.extend(this);
}