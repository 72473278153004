"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.frazioniListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const frazioniListColumns = [{
  field: 'codiceVeloce',
  headerName: 'Codice veloce',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'nome',
  headerName: 'Nome',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'cap',
  headerName: 'CAP',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.frazioniListColumns = frazioniListColumns;