"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Bilancio', ['restmod', function (restmod) {
    return restmod.model('bilancio').mix({
      $extend: {
        Scope: {
          $config: {
            baseEntityPath: 'bilancio',
            api: {}
          }
        }
      }
    }, {
      $extend: {
        Scope: {},
        Resource: {
          $getBilancio: function (_params) {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest();
              request.params = Object.assign({}, {
                anno: _params.anno
              }, _params);
              this.$dispatch('before-get-bilancio', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-get-bilancio', [_response]);
              }, function (_response) {
                this.$dispatch('after-get-bilancio-error', [_response]);
              });
            });
          }
        },
        Collection: {},
        Record: {}
      }
    });
  }]);
})();