"use strict";

(function () {
  'use strict';
  /**
   * Dona la possiblità ad un Model di essere seguito
   */

  angular.module('polcity.models').factory('Sortable', ['restmod', function (restmod) {
    return restmod.mixin({
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);
            collection.$isSortable = true;
            return collection;
          }
        },
        Collection: {
          /**
           * Add sorter to collection search params
           * @param sorter (es {key, direction})
           */
          $addSorter: function (sorter) {
            var search = R.path(['$metadata', 'search'], this),
                order = search.order || '',
                regexp = new RegExp(sorter.key + ',(asc|desc)', 'g');

            if (order === '') {
              order = sorter.key + ',' + sorter.direction;
            } else if (!order.includes(sorter.key)) {
              order += ':' + sorter.key + ',' + sorter.direction;
            } else {
              order = order.replace(regexp, sorter.key + ',' + sorter.direction);
            }

            search.order = order;
            search.page = 1; //Ripartiamo dall'inizio
            //FIXME: la chiamata alla api di fetch non dovrebbe state qui!!! capra!!!

            return this.$isRicercaVeloce ? this.$ricercaVeloce(search) : this.$refresh(search);
          },

          /**
           * Add sorter from collection search params
           * @param sorter (es {key, direction})
           */
          $removeSorter: function (sorterKey) {
            var search = R.path(['$metadata', 'search'], this) || '',
                activeOrders = R.path(['order'], search),
                sorters = activeOrders ? activeOrders.split(':') : [],
                newOrder = [];
            newOrder = sorters.filter(sort => !sort.includes(sorterKey));
            newOrder = newOrder.length ? newOrder.join(':') : null;
            search.order = newOrder;
            search.page = 1; //Ripartiamo dall'inizio
            //FIXME: la chiamata alla api di fetch non dovrebbe state qui!!! capra!!!

            return this.$isRicercaVeloce ? this.$ricercaVeloce(search) : this.$refresh(search);
          },
          $getActiveSortersCount: function () {
            var search = R.path(['$metadata', 'search'], this) || '',
                orders = R.path(['order'], search),
                sorters = orders ? orders.split(':') : [];
            return sorters.length;
          },
          $getActiveSorters: function () {
            var search = R.path(['$metadata', 'search'], this) || '',
                orders = R.path(['order'], search),
                sorters = orders ? orders.split(':') : [],
                result = {},
                split;
            sorters.forEach(function (sorter) {
              split = sorter.split(',');
              result[split[0]] = {
                direction: split[1]
              };
            });
            return result;
          }
        }
      }
    });
  }]);
})();