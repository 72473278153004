"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleCancellaVeicoloSoggetto = exports.handleSalvaVeicoloSoggetto = exports.useCancellaVeicoloSoggettoReactQuery = exports.useModificaVeicoloSoggettoReactQuery = exports.useCreaVeicoloSoggettoReactQuery = exports.useVeicoloSoggettoReactQuery = exports.useVeicoliSoggettoReactQuery = exports.veicoliSoggettoKeys = exports.veicoliSoggettoName = exports.veicoloSoggettoEditModalOpen = void 0;

var _recoil = require("recoil");

var _api = require("../../api");

var _react = require("react");

var _utils = require("./utils");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _utils2 = require("../../react/reactQuery/utils");

var _utils3 = require("../../utils");

const veicoloSoggettoEditModalOpen = (0, _recoil.atom)({
  key: "veicoloSoggettoEditModalOpen",
  default: false
});
exports.veicoloSoggettoEditModalOpen = veicoloSoggettoEditModalOpen;
const veicoliSoggettoName = "veicoliSoggetto";
exports.veicoliSoggettoName = veicoliSoggettoName;
const veicoliSoggettoKeys = {
  all: () => [veicoliSoggettoName],
  detail: ({
    aggId,
    entityId
  }) => [...veicoliSoggettoKeys.all(), "detail", ...(0, _utils3.insertIf)(aggId, {
    aggId
  }), ...(0, _utils3.insertIf)(entityId, {
    entityId
  })],
  quickSearch: params => [...veicoliSoggettoKeys.all(), "quickSearch", ...(0, _utils3.insertIf)(params, params)]
};
exports.veicoliSoggettoKeys = veicoliSoggettoKeys;

const useVeicoliSoggettoReactQuery = soggettoId => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const queryClient = (0, _reactQuery.useQueryClient)();
  const {
    isLoading,
    data,
    isFetching
  } = (0, _reactQuery.useQuery)(veicoliSoggettoKeys.quickSearch({
    aggId: soggettoId
  }), ({
    queryKey
  }) => {
    const query = {
      aggId: queryKey[2].aggId
    };
    return (0, _api.ricercaVeicoliAnagrafica)({
      query
    });
  }, {
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(soggettoId),
    suspense: true,
    onSuccess: response => {
      response.map(item => queryClient.setQueryData([...veicoliSoggettoKeys.detail({
        aggId: item.aggId,
        entityId: item.entityId
      })], item));
      (0, _utils2.handleSuccess)(messenger)(response);
    },
    onError: (0, _utils2.handleError)(messenger),
    select: response => response.error ? response : (response === null || response === void 0 ? void 0 : response.resultsWrapper.values) || []
  });
  return {
    isLoading: isLoading || isFetching,
    veicoli: data
  };
};

exports.useVeicoliSoggettoReactQuery = useVeicoliSoggettoReactQuery;

const useVeicoloSoggettoReactQuery = (aggId, entityId) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data: item,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(veicoliSoggettoKeys.detail({
    aggId,
    entityId
  }), ({
    queryKey
  }) => (0, _api.dettaglioVeicolo)({
    aggId: queryKey[2].aggId,
    entityId: queryKey[3].entityId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId) && Boolean(entityId),
    onSuccess: (0, _utils2.handleSuccess)(messenger),
    onError: response => (0, _utils2.handleError)(messenger)(response)
  });
  const {
    data: remoteTipoVeicolo,
    isFetching: isFetchingTipoVeicolo,
    isLoading: isLoadingTipoVeicolo
  } = (0, _reactQuery.useQuery)([...veicoliSoggettoKeys.detail({
    aggId,
    entityId
  }), `tipoVeicolo`, {
    tipoVeicoloId: item === null || item === void 0 ? void 0 : item.tipoVeicoloId
  }], ({
    queryKey
  }) => (0, _api.dettaglioTipoVeicolo)({
    aggId: queryKey[5].tipoVeicoloId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(item) && Boolean(item.tipoVeicoloId),
    onSuccess: (0, _utils2.handleSuccess)(messenger),
    onError: response => (0, _utils2.handleError)(messenger)(response)
  });
  const fromApiVeicoloSoggetto = (0, _react.useMemo)(() => (0, _utils.fromApiVeicolo)(item), [item]);
  const veicoloExploded = { ...fromApiVeicoloSoggetto,
    tipoVeicolo: remoteTipoVeicolo
  };
  return {
    item: veicoloExploded,
    isFetching: isFetching || isLoading || isFetchingTipoVeicolo || isLoadingTipoVeicolo
  };
};

exports.useVeicoloSoggettoReactQuery = useVeicoloSoggettoReactQuery;

const useCreaVeicoloSoggettoReactQuery = ({
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(documento => (0, _api.creaVeicolo)((0, _utils.toApiVeicolo)(documento)), {
    onSuccess: response => {
      handleSalvaVeicoloSoggetto(messenger)(response);
      onSuccess(response);
    },
    onError: (0, _utils2.handleError)(messenger),
    onSettled: () => queryClient.invalidateQueries(veicoliSoggettoKeys.all())
  });
};

exports.useCreaVeicoloSoggettoReactQuery = useCreaVeicoloSoggettoReactQuery;

const useModificaVeicoloSoggettoReactQuery = ({
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(documento => (0, _api.modificaVeicolo)((0, _utils.toApiVeicolo)(documento)), {
    onSuccess: response => {
      handleSalvaVeicoloSoggetto(messenger)(response);
      onSuccess(response);
    },
    onError: (0, _utils2.handleError)(messenger),
    onSettled: () => queryClient.invalidateQueries(veicoliSoggettoKeys.all())
  });
};

exports.useModificaVeicoloSoggettoReactQuery = useModificaVeicoloSoggettoReactQuery;

const useCancellaVeicoloSoggettoReactQuery = ({
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(({
    aggId,
    entityId
  }) => (0, _api.cancellaVeicolo)({
    aggId,
    entityId
  }), {
    onSuccess: response => {
      handleCancellaVeicoloSoggetto(messenger)(response);
      Boolean(onSuccess) && onSuccess(response);
    },
    onError: (0, _utils2.handleError)(messenger),
    onSettled: () => queryClient.invalidateQueries(veicoliSoggettoKeys.all())
  });
};

exports.useCancellaVeicoloSoggettoReactQuery = useCancellaVeicoloSoggettoReactQuery;

const handleSalvaVeicoloSoggetto = messenger => response => {
  (0, _utils2.handleSuccess)(messenger)(response);
  if (!response.error) messenger({
    type: 'success',
    message: "Veicolo salvato",
    severity: 'success'
  });
};

exports.handleSalvaVeicoloSoggetto = handleSalvaVeicoloSoggetto;

const handleCancellaVeicoloSoggetto = messenger => response => {
  (0, _utils2.handleSuccess)(messenger)(response);
  if (!response.error) messenger({
    type: 'success',
    message: "Veicolo cancellato",
    severity: 'success'
  });
};

exports.handleCancellaVeicoloSoggetto = handleCancellaVeicoloSoggetto;