"use strict";

/**
 * Created by MN 21/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('EnteTerritorialeFormController', ['$stateParams', 'Command', '$tdsApiService', '$tdsGlobalBehaviors', '$state', EnteTerritorialeFormController]);

  function EnteTerritorialeFormController($stateParams, Command, $tdsApiService, $tdsGlobalBehaviors, $state) {
    var enteTerritorialeFormCtrl = this,
        dettaglioCommand = '@dettaglioEnteTerritoriale',
        modificaCommand = '@modificaEnteTerritoriale',
        salvaCommand = '@salvaEnteTerritoriale',
        redirectTo = 'navigation.tdsEntiTerritoriali';
    enteTerritorialeFormCtrl.inceptionMode = $state.$current.name !== 'navigation.tdsEntiTerritorialiEdit';
    /**
     * Discriminante per capire se sono in edit
     * @type {boolean}
     */

    enteTerritorialeFormCtrl.editMode = false;
    /**
     * @Property
     * Configuro l'aggId a zero leggendolo dagli $stateParams nel caso sia in inserimento
     * oppure lo recupero dall'oggetto nel caso in cui sia in edit
     */

    enteTerritorialeFormCtrl.aggId = $state.includes('navigation.tdsEntiTerritorialiEdit') ? $stateParams.entityId : null;
    /**
     * @Property
     * Configuro l'oggetto recuperandolo dagli $stateParams nel caso in cui sia in edit
     * oppure assegnando un oggetto vuoto nel caso in cui sia in inserimento
     */

    enteTerritorialeFormCtrl.item = {};
    /**
     * Handler onInit function
     * @type {onInit}
     */

    enteTerritorialeFormCtrl.$onInit = onInit;
    /**
     * Oggetto condiviso con la vista
     * @type {Array}
     */

    enteTerritorialeFormCtrl.enti = [];
    /**
     * Operazioni eseguite in istanza del controller
     */

    function onInit() {
      // SE NEGLI STATE PARAMS HO L'AGGID POSSO ESSERE IN EDIT OPPURE AVER RICARICATO LA PAGINA
      if (enteTerritorialeFormCtrl.aggId) {
        enteTerritorialeFormCtrl.editMode = true;
        var params = {
          entityId: enteTerritorialeFormCtrl.aggId
        };
        $tdsApiService.executeCommand(dettaglioCommand, params).then(function (res) {
          enteTerritorialeFormCtrl.item = res;
        }).catch(function () {
          handleItemLoadError();
        });
      } else {
        enteTerritorialeFormCtrl.editMode = false;
      }

      recuperaEntiTerritoriali().then(setEntiTerritoriali);
    }

    enteTerritorialeFormCtrl.queryApi = function (query) {
      var data = {};

      if (query) {
        data.nome = query;
      }

      return recuperaStati(data);
    };

    enteTerritorialeFormCtrl.afterInsertStato = function (_stato) {
      enteTerritorialeFormCtrl.item.aggId = _stato.aggId;
    };
    /**
     * Recupera i comuni
     */


    function recuperaStati(params) {
      return Command.execute('@ricercaNazioni', params).then(function (res) {
        return res.resultsWrapper.values;
      });
    }
    /**
     * Chiamata rest per recuperare gli enti territoriali
     * @returns {*}
     */


    function recuperaEntiTerritoriali() {
      return Command.execute('@recuperaEntiTerritorialiEnum');
    }
    /**
     * Setter variabile enteTerritoriale
     * @param enti
     */


    function setEntiTerritoriali(enti) {
      enteTerritorialeFormCtrl.enti = enti;
    }
    /**
     * @property
     * Handler da eseguire al click sul pulsante del salvataggio
     * @param item
     */


    enteTerritorialeFormCtrl.onSaveEnteTerritorialeButtonClick = function (item) {
      var command = enteTerritorialeFormCtrl.editMode ? modificaCommand : salvaCommand;
      $tdsApiService.salvaElementoAndRedirect(command, item, enteTerritorialeFormCtrl.editMode, redirectTo);
    };
    /****************
     * Utils
     ***************/


    function handleItemLoadError() {
      //Notifica errore
      $tdsGlobalBehaviors.notifica('error', 'tds.elementNotFount__notification'); //Go back

      $tdsGlobalBehaviors.redirectTo(redirectTo);
    }
  }
})();