"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('RicercaTestoLibero', ['restmod', 'RMCommonApi', function (restmod) {
    var API_RICERCA_TESTO_LIBERO = 'ricerca-testo-libero';
    return restmod.mixin({
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);
            collection.$isRicercaTestoLibero = true;
            return collection;
          },
          $pippo: {
            API_RICERCA_TESTO_LIBERO: 'ricerca-testo-libero'
          }
        },
        Collection: {
          $ricercaTestoLibero: function (_params) {
            var me = this,
                params,
                request = {};
            /*this.$url = function() {
                return this.$getBaseEntityPath() + API_RICERCA_TESTO_LIBERO;
            };
             params = R.path('$metadata', 'search', this) ? angular.extend(this.$metadata.search, _params) : _params;*/

            request = {
              url: this.$getBaseEntityPath() + API_RICERCA_TESTO_LIBERO,
              method: 'GET'
            };
            params = Object.assign({}, this.$params, _params); //Usiamo la stessa collection per mantenere il riferimento

            this.$clear(); //Pulizia dei vecchi dati

            return this.$fetch(params, request);
          }
        }
      }
    });
  }]);
})();