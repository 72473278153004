"use strict";

/**
 * Created by MN 14/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('GestioneDelTerritorioFormController', ['$state', '$stateParams', 'Command', '$tdsApiService', '$tdsGlobalBehaviors', GestioneDelTerritorioFormController]);

  function GestioneDelTerritorioFormController($state, $stateParams, Command, $tdsApiService, $tdsGlobalBehaviors) {
    var gestioneDelTerritorioFormCtrl = this,
        dettaglioCommand = '@dettaglioComuneGestioneDelTerritorio',
        modificaCommand = '@modificaComuneGestioneDelTerritorio',
        salvaCommand = '@salvaComuneGestioneDelTerritorio',
        redirectTo = 'navigation.tdsGestioneDelTerritorio';
    /**
     * Discriminante per capire se sono in edit
     * @type {boolean}
     */

    gestioneDelTerritorioFormCtrl.editMode = false;
    /**
     * @Property
     * Configuro l'aggId a zero leggendolo dagli $stateParams nel caso sia in inserimento
     * oppure lo recupero dall'oggetto nel caso in cui sia in edit
     */

    gestioneDelTerritorioFormCtrl.aggId = $state.includes('navigation.tdsGestioneDelTerritorioEdit') ? $stateParams.aggId : null;
    /**
     * @Property
     * Configuro l'oggetto recuperandolo dagli $stateParams nel caso in cui sia in edit
     * oppure assegnando un oggetto vuoto nel caso in cui sia in inserimento
     */

    gestioneDelTerritorioFormCtrl.item = {};
    /**
     * Handler onInit function
     * @type {onInit}
     */

    gestioneDelTerritorioFormCtrl.$onInit = onInit;
    /**
     * Operazioni eseguite in istanza del componente
     */

    function onInit() {
      // SE NEGLI STATE PARAMS HO L'AGGID POSSO ESSERE IN EDIT OPPURE AVER RICARICATO LA PAGINA
      if (gestioneDelTerritorioFormCtrl.aggId) {
        gestioneDelTerritorioFormCtrl.editMode = true;
        var params = {
          aggId: gestioneDelTerritorioFormCtrl.aggId
        };
        $tdsApiService.executeCommand(dettaglioCommand, params).then(function (res) {
          gestioneDelTerritorioFormCtrl.item = res;
          gestioneDelTerritorioFormCtrl.item.enteTerritoriale = gestioneDelTerritorioFormCtrl.item.enteTerritorialeId ? gestioneDelTerritorioFormCtrl.item.enteTerritoriale : '';
        }).catch(function () {
          handleItemLoadError();
        });
      } else {
        gestioneDelTerritorioFormCtrl.editMode = false;
      }
    }

    gestioneDelTerritorioFormCtrl.afterInsertNuovoEnteTerritoriale = function (enteTerritoriale) {
      gestioneDelTerritorioFormCtrl.item.enteTerritorialeId = enteTerritoriale.entityId;
    };
    /**
     * Handler sulla ricerca di un ente territoriale
     * @param query
     * @returns {*}
     */


    gestioneDelTerritorioFormCtrl.queryEntiTerritoriali = function (query) {
      var data = {};

      if (query) {
        data.nome = query;
      }

      return recuperaEntiTerritoriali(data);
    };
    /**
     * Recupera i comuni
     */


    function recuperaEntiTerritoriali(params) {
      return Command.execute('@ricercaEntiTerritoriali', params).then(function (res) {
        return res.resultsWrapper.values;
      });
    }

    gestioneDelTerritorioFormCtrl.enteTerritorialeFormatName = function (item) {
      return item.tipoEnteTerritoriale + ' ' + item.nome;
    };
    /**
     * @property
     * Handler da eseguire al click sul pulsante del salvataggio
     * @param item
     */


    gestioneDelTerritorioFormCtrl.onSaveGestioneDelTerritorioButtonClick = function (item) {
      var command = gestioneDelTerritorioFormCtrl.editMode ? modificaCommand : salvaCommand;
      item = R.dissoc('enteTerritoriale', item);
      $tdsApiService.salvaElementoAndRedirect(command, item, gestioneDelTerritorioFormCtrl.editMode, redirectTo);
    };
    /****************
     * Utils
     ***************/


    function handleItemLoadError() {
      //Notifica errore
      $tdsGlobalBehaviors.notifica('error', 'tds.elementNotFount__notification'); //Go back

      $tdsGlobalBehaviors.redirectTo(redirectTo);
    }
  }
})();