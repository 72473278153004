"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVGChanged = exports.getValoriGlobaliTipoUUID = exports.flattenObjectValoriGlobali = void 0;

const flattenObjectValoriGlobali = obj => Object.keys(obj).reduce((acc, key) => {
  if (Boolean(obj[key]) && obj[key] instanceof Object && !Boolean(obj[key].tipo)) {
    return { ...acc,
      ...flattenObjectValoriGlobali(obj[key])
    };
  }

  acc = { ...acc,
    ...obj
  };
  return acc;
}, {});

exports.flattenObjectValoriGlobali = flattenObjectValoriGlobali;

const getValoriGlobaliTipoUUID = obj => Object.keys(obj).reduce((acc, key) => {
  if (obj[key].tipo == "UUID" && Boolean(obj[key].valore)) {
    acc = [...acc, {
      key: key,
      ...obj[key]
    }];
  }

  return acc;
}, []);

exports.getValoriGlobaliTipoUUID = getValoriGlobaliTipoUUID;

const getVGChanged = (alias, initialValues, finalValues) => {
  const changes = Object.keys(initialValues).reduce((acc, key) => {
    if (initialValues[key] != finalValues[key]) {
      acc = { ...acc,
        [`${key}`]: {
          valore: finalValues[key]
        }
      };
    }

    return acc;
  }, {});
  return {
    [`${alias}`]: { ...changes
    }
  };
};

exports.getVGChanged = getVGChanged;