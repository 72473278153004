"use strict";

/**
 * Created by dan on 21/04/17.
 */
(function () {
  'use strict';

  angular.module('polcity.verbali').factory('VerbaliCommands', ['CommandsList', '$filter', '$httpParamSerializer', '$translate', VerbaliCommands]);

  function VerbaliCommands(CommandsList, $filter, $httpParamSerializer, $translate) {
    return {
      publish: publish
    };

    function publish() {
      CommandsList.add({
        '@verbaliList': {
          method: 'GET',
          url: 'verbale/lista/verbali'
        },
        '@verbaliSearch': {
          method: 'GET',
          url: 'verbali/search/ricerca-dimensioni'
        },
        '@verbaliFilter': {
          method: 'POST',
          url: 'verbale/ricerca/verbali',
          transformResponse: toHumanList
        },

        /*'@liberaBolletta'                     : {
            method: 'POST',
            url   : 'bolletta/libera/bolletta'
        },*/
        '@importiInfrazione': {
          method: 'POST',
          url: 'importo/ricerca/importi-calcolati'
        },
        '@verbaleDetail': {
          method: 'POST',
          url: 'verbale/dettaglio/verbale'
        },
        // '@showLogs'                           : {
        //     method       : 'GET',
        //     url          : 'aggregato/cronologia?aggregateCanonicalName=eu.opensoftware.verbale.ao.Verbale',
        //     replaceParams: ['aggId']
        // },

        /*'@followVerbale'                      : {
            method: 'POST',
            url   : 'segui'
        },
        '@unfollowVerbale'                    : {
            method: 'POST',
            url   : 'smetti'
        },*/

        /*'@getMotivazioniMancataContestazione' : {
            method: 'GET',
            url   : 'motivazione-mancata-contestazione/lista/motivazioni-mancata-contestazione'
        },*/
        // commands passed into a os-async-dropdown
        '@verbaleGetComune': {
          method: 'POST',
          url: 'comune/dettaglio/comune',
          transformRequest: function (request) {
            return angular.toJson({
              aggId: request
            });
          }
        },

        /*'@verbaleGetStrada'                   : {
            method          : 'POST',
            url             : 'strada/dettaglio/strada',
            transformRequest: function( request ) {
                return angular.toJson({entityId: request});
            }
        },*/

        /*'@verbaleRicercaComune'               : {
            method           : 'POST',
            url              : 'comune/ricerca/comuni',
            transformRequest : function( request ) {
                return angular.toJson({filtro: {nome: request}});
            },
            transformResponse: function( res ) {
                return res.risultato;
            }
        },*/

        /* '@verbaleRicercaStrada'               : {
             method           : 'POST',
             url              : 'strada/ricerca/strade',
             transformRequest : function( request ) {
                 if( R.is(String, request) ) {
                     return angular.toJson({filtro: {nome: request}});
                 }
                  var newRequest = {
                     filtro: {
                         nome : request.query,
                         aggId: [request.aggId]
                     }
                 };
                  return angular.toJson(newRequest);
              },
             transformResponse: function( res ) {
                 return res.risultato;
             }
         },*/

        /*'@verbaleRicercaStradaLocalita'       : {
            method           : 'POST',
            url              : 'strada/ricerca/strade',
            transformRequest : function( request ) {
                if( R.is(String, request) ) {
                    return angular.toJson({filtro: {nome: request}});
                }
                 var newRequest = {
                    filtro: {
                        nome : request.query,
                        aggId: [request.aggId]
                    }
                };
                 return angular.toJson(newRequest);
             },
            transformResponse: function( res ) {
                return res.risultato;
            }
        },*/

        /*'@verbaleRicercaTipiVeicolo'          : {
            method           : 'GET',
            url              : 'tipo-veicolo/ricerca/tipi-veicolo',
            paramSerializer  : function( request ) {
                if( request ) {
                    request = (hasNumber(request) ? {codiceVeloce: request} : {descrizione: request});
                }
                return $httpParamSerializer(request);
            },
            transformResponse: function( res ) {
                return res.resultsWrapper.values;
            }
        },*/

        /*'@verbaleDettaglioTipoVeicolo'        : {
            method          : 'POST',
            url             : 'tipo-veicolo/dettaglio/tipo-veicolo',
            transformRequest: function( request ) {
                return angular.toJson({aggId: request});
            }
        },*/

        /*'@verbaleRicercaStatoVeicolo'         : {
            method           : 'GET',
            url              : 'stato/ricerca/stati',
            paramSerializer  : function( request ) {
                if( request ) {
                    request = {nome: request};
                }
                return $httpParamSerializer(request);
            },
            transformResponse: function( res ) {
                return res.resultsWrapper.values;
            }
        },
        '@verbaleDettaglioStatoVeicolo'       : {
            method          : 'POST',
            url             : 'stato/dettaglio/stato',
            transformRequest: function( request ) {
                return angular.toJson({aggId: request});
            }
        },*/
        '@creaVerbale': {
          method: 'POST',
          url: 'verbale/comando/aggiungi-verbale'
        },
        '@modificaVerbale': {
          method: 'POST',
          url: 'verbale/comando/modifica-verbale'
        },

        /*'@cancellaVerbale'                    : {
            method          : 'POST',
            url             : 'verbale/comando/cancella-verbale',
            transformRequest: function( request ) {
                return angular.toJson({aggId: request.aggId});
            }
        },
        '@cancellazioneMultiplaVerbali'       : {
            method          : 'POST',
            url             : 'verbale/comando/cancella-verbale',
            transformRequest: function( request ) {
                return angular.toJson({aggId: request.aggId});
            }
        },*/

        /*'@documentiPerAnagrafica'             : {
            method           : 'GET',
            url              : 'documento-anagrafica/ricerca/documenti-anagrafica',
            transformResponse: function( response ) {
                return response.resultsWrapper.values;
            }
        },*/
        '@getSanzioniAccessorieByCodiceVeloce': {
          method: 'GET',
          url: 'sanzione-accessoria/ricerca/sanzioni-accessorie' //transformRequest: function( request ) {
          //    return angular.toJson({codiceVeloce: request});
          //}

        },
        '@getDatiAggiuntiviForRilevatore': {
          method: 'POST',
          url: 'relazione-articoli-decoratori/ricerca/relazioni-articoli-decoratori'
        },
        '@addDocument': {
          method: 'POST',
          url: 'documento-anagrafica/comando/aggiungi-documento-anagrafica'
        },
        '@fetchMotivazioniMancataApplicazione': {
          method: 'GET',
          url: 'motivazione-mancata-applicazione/lista/motivazioni-mancata-applicazione',
          transformResponse: function (response) {
            $translate('verbali.motivazioneMancataApplicazioneLibera__text').then(function (traduzione) {
              // L'aggId di motivazioneMancataApplicazioneLibera non può essere uguale a -1 altrimenti genera errori a backend.
              // E' stato deciso di mettere un aggId come impostato
              var motivazioneMancataApplicazioneLibera = {
                aggId: '00000000-0000-0000-0000-000000000000',
                codiceVeloce: 'ML',
                descrizioneStampa: 'Motivazione mancata applicazione libera',
                descrizioneStampa_transId: null,
                nome: 'Motivazione mancata applicazione libera',
                titolo: traduzione
              };
              response.push(motivazioneMancataApplicazioneLibera);
            });
            return response;
          }
        }
      });
    }

    function hasNumber(myString) {
      return /\d/.test(myString);
    }

    function toHumanList(data) {
      if (R.has('risultato', data)) {
        data.risultato.forEach(toHuman);
      }

      return data;
    }

    function toHuman(verbale) {
      verbale.$dataOraVerbale = $filter('date')(verbale.dataOraVerbale, 'dd/MM/yyyy HH:mm');
      verbale.$numeroTipoAccertamentoAnno = R.props(['numero', 'tipoAccertamento', 'annoVerbale'], verbale).join('/');
      return verbale;
    }
  }
})();