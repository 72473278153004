"use strict";

/**
 * Created by MN 12/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('FrazioniFormController', ['$stateParams', 'Command', '$tdsApiService', '$tdsGlobalBehaviors', FrazioniFormController]);

  function FrazioniFormController($stateParams, Command, $tdsApiService, $tdsGlobalBehaviors) {
    var frazioniFormCtrl = this,
        dettaglioCommand = '@dettaglioFrazione',
        modificaCommand = '@modificaFrazione',
        salvaCommand = '@salvaFrazione',
        redirectTo = 'navigation.tdsFrazioni',
        comuneId = $stateParams.comuneId;
    frazioniFormCtrl.comuneId = comuneId;
    /**
     * Discriminante per capire se sono in edit
     * @type {boolean}
     */

    frazioniFormCtrl.editMode = false;
    /**
     * @Property
     * Configuro l'aggId a zero leggendolo dagli $stateParams nel caso sia in inserimento
     * oppure lo recupero dall'oggetto nel caso in cui sia in edit
     */

    frazioniFormCtrl.frazioneId = $stateParams.frazioneId;
    /**
     * @Property
     * Configuro l'oggetto recuperandolo dagli $stateParams nel caso in cui sia in edit
     * oppure assegnando un oggetto vuoto nel caso in cui sia in inserimento
     */

    frazioniFormCtrl.item = {};
    /**
     * Handler onInit function
     * @type {onInit}
     */

    frazioniFormCtrl.$onInit = onInit;
    /**
     * Operazioni eseguite in istanza del controller
     */

    function onInit() {
      // SE NEGLI STATE PARAMS HO L'AGGID POSSO ESSERE IN EDIT OPPURE AVER RICARICATO LA PAGINA
      if (frazioniFormCtrl.frazioneId) {
        frazioniFormCtrl.editMode = true;
        var params = {
          frazioneId: frazioniFormCtrl.frazioneId
        };
        $tdsApiService.executeCommand(dettaglioCommand, params).then(function (res) {
          frazioniFormCtrl.item = res;
        }).catch(function () {
          handleItemLoadError();
        });
      } else {
        frazioniFormCtrl.editMode = false;
      }
    }
    /**
     * @property
     * Handler da eseguire al click sul pulsante del salvataggio
     * @param item
     */


    frazioniFormCtrl.onSaveFrazioneButtonClick = function (item) {
      var command = frazioniFormCtrl.editMode ? modificaCommand : salvaCommand;
      item.aggId = comuneId;
      return Command.execute(command, item).then(function () {
        $tdsGlobalBehaviors.notifica('success', frazioniFormCtrl.editMode ? 'tds.modificaSuccess__notification' : 'tds.inserimentoSuccess__notification');
      }).then(function () {
        $tdsGlobalBehaviors.redirectTo(redirectTo, {
          comuneId: comuneId
        });
      }).catch(function (err) {
        $tdsGlobalBehaviors.notifica('error', 'tds.inserimentoError__notification');
      });
    };
    /****************
     * Utils
     ***************/


    function handleItemLoadError() {
      //Notifica errore
      $tdsGlobalBehaviors.notifica('error', 'tds.elementNotFount__notification'); //Go back

      $tdsGlobalBehaviors.redirectTo(redirectTo);
    }
  }
})();