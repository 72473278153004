"use strict";

/**
 * Created by MN 18/09/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('armonizzazioneContabileList', {
    templateUrl: 'osContabilita/armonizzazioneContabile/armonizzazioneContabileList.component.html',
    controller: 'armonizzazioneContabileListController'
  }).controller('armonizzazioneContabileListController', ['$scope', 'ScritturaContabile', '$state', '$stateParams', ArmonizzazioneContabileListController]);

  function ArmonizzazioneContabileListController($scope, ScritturaContabile, $state, $stateParams) {
    var me = this;
    $scope.scritturaContabile = ScritturaContabile.$collection({
      page: 1,
      limit: 10
    });
    $scope.scritturaContabile.$on('before-fetch-many', function (res) {
      $scope.isLoadingList = true;
    });
    $scope.scritturaContabile.$on('after-fetch-many', function (res) {
      $scope.isLoadingList = false;
      $scope.page = $scope.scritturaContabile.$metadata.search.page;
      $scope.limit = $scope.scritturaContabile.$metadata.search.limit;
      $scope.total = $scope.scritturaContabile.$metadata.total;
      $scope.filters = $scope.scritturaContabile.$metadata.filters;
      $scope.activeFilters = $scope.scritturaContabile.$getActiveFilters();
      $scope.activeSorters = $scope.scritturaContabile.$getActiveSorters();
      $scope.scritturaContabile.$saveState();
    });
    $scope.scritturaContabile.$on('after-fetch-many-error', function (res) {
      $scope.isLoadingList = false; //TODO: do something with errors
    });
    $scope.enableFilters = true;
    $scope.scritturaContabileTools = [{
      icon: 'pie_chart',
      tooltip: 'Vista grafici',
      callBack: function () {
        $scope.scritturaContabile.$resetFilters();
        $state.go('navigation.osContabilita.armonizzazioneContabileChartsView');
      }
    }];
    $scope.sorters = {
      data: {
        showEmptyOption: true,
        direction: null
      },
      dateRegistrazione: {
        showEmptyOption: true,
        direction: null
      },
      importo: {
        showEmptyOption: true,
        direction: null
      }
    };

    me.$onInit = function () {
      var state,
          params = {},
          selectedItem = null; //Ripristino lo stato precedente

      if ($stateParams.reloadPreviousState) {
        state = $scope.scritturaContabile.$getState();
        params = state.search || {};
        selectedItem = state.selectedItem || null;
        $scope.scritturaContabile.$initFilter(state.filters, state.elasticFilters);
      }

      $scope.scritturaContabile.$ricercaVeloce().$then(function () {
        if (selectedItem) {
          $scope.selectedScritturaContabile = ScritturaContabile.$new(selectedItem.aggId);
        }
      });
    }; //Handlers


    $scope.onChildTap = function (params) {
      $scope.scritturaContabile.$saveState();
    };

    $scope.onSortAdd = function (params) {};

    $scope.onRemoveItemSelection = function (params) {
      $scope.scritturaContabile.$saveState();
    };

    $scope.onSortRemove = function (params) {};

    $scope.onPageChange = function (params) {};

    $scope.onFilterAdd = function (params) {};

    $scope.onFilterRemove = function (params) {};

    $scope.onFilterReset = function () {};

    $scope.onToggleFollow = function () {};
  }
})();