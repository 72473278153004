"use strict";

(function () {
  'use strict';

  angular.module('osListComponent').directive('osListDetail', bellaFunzione);

  function bellaFunzione() {
    return {
      restrict: 'E',
      require: '^osList',
      template: '<div ng-transclude style="height: 100%"></div>',
      transclude: true,
      link: postLink
    };

    function postLink(scope, element, attrs, controllers, transclude) {
      scope.osList = controllers;
    }
  }
})();