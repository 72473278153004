"use strict";

angular.module('polcity.rimozioni').config(['$stateProvider', 'rimozioniRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['rimozioniRoutes', routesRun]);

function addRoutes($stateProvider, rimozioniRoutesProvider, $translatePartialLoader, AuthProvider) {
  R.forEach(function (routeDefinition) {
    var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

    if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
      $stateProvider.state(stateName, routeDefinition);
    }
  }, rimozioniRoutesProvider.list());
  $translatePartialLoader.addPart('rimozioni');
}

function routesRun(rimozioniRoutes) {
  rimozioniRoutes.subscribeUIEvents();
}