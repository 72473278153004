"use strict";

/**
 * Created by dan on 27/01/17.
 */
(function () {
  'use strict';

  angular.module('polcity.navigation').directive('closeMenuIfActive', ['$state', 'uiEvents', closeMenuOnActiveSectionClick]);

  function closeMenuOnActiveSectionClick($state, uiEvents) {
    return {
      restrict: 'A',
      link: linkDirective
    };

    function linkDirective(scope, element, attrs) {
      element.on('click', preventDefaultIfIsCurrentState);

      function preventDefaultIfIsCurrentState(ev) {
        if ($state.current.name === attrs.uiSref) {
          ev.preventDefault();
          scope.$emit(uiEvents.hideLayers);
        }
      }
    }
  }
})();