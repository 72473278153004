"use strict";

/**
 * Created by MN 09/01/2019
 */
(function () {
  'use strict';

  angular.module('polcity.modelliStampa', []).config(['$translatePartialLoaderProvider', function ($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('modelliStampa');
  }]);
})();