"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.visuraRicercaVeloceData = void 0;
const visuraRicercaVeloceData = {
  "search": {
    "page": 1,
    "order": null,
    "limit": 10,
    "codiceNomeIndice": "elastic.index.visure",
    "noLimit": false,
    "tipoOperazione": null,
    "targa": null,
    "nome": null,
    "cognome": null,
    "rifAggId": null,
    "dataOraRichiesta": null,
    "dataOraRisposta": null,
    "excludeDb": true,
    "storedList": null,
    "explodeId": false
  },
  "resultsWrapper": {
    "values": [{
      "highlights": {},
      "aggId": "5d472ba8-789f-4e51-b827-ee5658fc0347",
      "rifAggId": "b3001360-ddd2-4157-9c0e-e31464330cf2",
      "tipoOperazione": "VISURA_TARGA",
      "dataOraRichiesta": 1605092570104,
      "dataOraRisposta": 1605092575594,
      "statusVisura": "VERDE_SUCCESSO"
    }, {
      "highlights": {},
      "aggId": "275fcfbd-43fb-4290-9b4f-ea96e8c21969",
      "rifAggId": "a380792b-b9a7-4777-8696-f98f0cd48992",
      "tipoOperazione": "VISURA_TARGA",
      "dataOraRichiesta": 1605092737877,
      "dataOraRisposta": 1605092739923,
      "statusVisura": "VERDE_SUCCESSO" //GIALLO_ATTESA

    }],
    "count": 2,
    "links": []
  },
  "filters": []
};
exports.visuraRicercaVeloceData = visuraRicercaVeloceData;