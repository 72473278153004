"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.relazioniNomeSessoListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

var _enums = require("../../../state/enums");

const relazioniNomeSessoListColumns = [{
  field: 'nome',
  headerName: 'Nome',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'sesso',
  headerName: 'Sesso',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _enums.useEnumResolver)(value, "listaSessi") : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.relazioniNomeSessoListColumns = relazioniNomeSessoListColumns;