"use strict";

/**
 * Created by MN on 07/10/2019
 */
(function () {
  'use strict';

  angular.module('osImageCarousel').filter('trustThisUrl', ['$sce', function ($sce) {
    return function (val) {
      return $sce.trustAsResourceUrl(val);
    };
  }]);
})();