"use strict";

/**
 * Created by dan on 01/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity').config(['$translateProvider', 'DEFAULT_LOCALE', 'DEFAULT_DATE_FORMAT', '$mdDateLocaleProvider', '$translatePartialLoaderProvider', routesConfig]);

  function routesConfig($translateProvider, DEFAULT_LOCALE, DEFAULT_DATE_FORMAT, $mdDateLocaleProvider, $translatePartialLoader) {
    $translateProvider.registerAvailableLanguageKeys(['de', 'it'], {
      'de_*': 'de',
      'de': 'de',
      'it_*': 'it',
      'it': 'it'
    });
    $translateProvider.useLoader('$translatePartialLoader', {
      urlTemplate: '{part}/i18n/{lang}.json'
    });
    $translateProvider.preferredLanguage(DEFAULT_LOCALE);
    $translateProvider.useSanitizeValueStrategy('sanitize');
    $mdDateLocaleProvider.formatDate = osDateFormatter;

    function osDateFormatter(date) {
      if (!date) {
        date = new Date();
      }

      return moment(date).format(DEFAULT_DATE_FORMAT);
    }
  }
})();