"use strict";

angular.module('polcity.archivioArmaposte').config(['$stateProvider', 'archivioArmaposteRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['archivioArmaposteRoutes', routesRun]);

function addRoutes($stateProvider, archivioArmaposteRoutesProvider, $translatePartialLoader, AuthProvider) {
  R.forEach(function (routeDefinition) {
    const stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

    if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
      $stateProvider.state(stateName, routeDefinition);
    }
  }, archivioArmaposteRoutesProvider.list());
}

function routesRun(archivioArmaposteRoutes) {
  archivioArmaposteRoutes.subscribeUIEvents();
}