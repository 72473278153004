"use strict";

/**
 * Created by gb on 30/09/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.ricorsi').controller('RicorsiMultipleActionsController', ['$scope', '$state', 'OSNotification', 'modalPresenter', RicorsiMultipleActionsController]);

  function RicorsiMultipleActionsController($scope, $state, OSNotification, modalPresenter) {
    var me = this;
    me.collection;
    me.selectedItems;

    me.$onInit = function () {};

    me.onEliminaRicorsi = function () {
      var selectedItems = me.collection.$getSelectedItemsCollection(),
          promises = [];
      warnUserBeforeDelete().then(function () {
        selectedItems.forEach(function (item) {
          promises.push(item.$destroy().$asPromise());
        });
        Promise.all(promises).then(function () {
          me.collection.$resetSelectedItems();
          notifyUser("success", "ricorsi.cancellazione.deleteSuccessMultiple__notification");
        }).catch(function () {}).finally(function () {});
      });
    };

    function warnUserBeforeDelete() {
      return modalPresenter.warn(['ricorsi.cancellazione.delete__title', 'ricorsi.cancellazione.deleteMultiple__body', 'ricorsi.cancellazione.deleteConfirm__button', 'ricorsi.cancellazione.undo__buttonText']);
    }

    ;

    function notifyUser(type, msg) {
      OSNotification[type]({
        translationIndex: msg
      });
    }

    ;
  }
})();