"use strict";

var _utils = require("./utils");

angular.module('polcity.acquisizioneDati').component('versamentiAcquisizioneDati', {
  templateUrl: 'acquisizioneDati/versamenti/versamentiAcquisizioneDati.component.html',
  controller: 'versamentiAcquisizioneDatiController'
}).controller('versamentiAcquisizioneDatiController', ['$scope', 'Versamento', '$timeout', 'OSNotification', '$mdDialog', 'modalPresenter', '$state', '$stateParams', '$translate', '$mdSidenav', VersamentiAcquisizioneDatiController]);

function VersamentiAcquisizioneDatiController($scope, Versamento, $timeout, OSNotification, $mdDialog, modalPresenter, $state, $stateParams, $translate) {
  var me = this;
  $scope.filterPanelIsOpen = false;
  $scope.filterIsPresent = false;
  $scope.activeTab = 0;
  $scope.isLoadingList = false;
  /**
   * Confiurazioni iniziali della collection rilevazioni
   */

  $scope.versamenti = Versamento.$collection({
    page: 1,
    limit: 10
  });
  $scope.versamenti.$on('before-fetch-many', function (res) {
    $scope.isLoadingList = true;
  });
  $scope.versamenti.$on('after-fetch-many', function (res) {
    $scope.isLoadingList = false;
    $scope.page = $scope.versamenti.$metadata.search.page;
    $scope.limit = $scope.versamenti.$metadata.search.limit;
    $scope.total = $scope.versamenti.$metadata.total;
    $scope.filters = $scope.versamenti.$metadata.filters;
  });
  $scope.versamenti.$on('after-fetch-many-error', function (res) {
    $scope.isLoadingList = false;
  });
  $scope.versamenti.$on('after-inizializza-processo-attribuzione-versamenti', function (res) {
    notifyUser('success', 'acquisizioneDati.versamenti.startInizializzazione__success');
    loadCollection({
      milestone: 'versamento_acquisito'
    });
  });
  $scope.versamenti.$on('after-attribuzione-automatica-massiva', function (res) {
    notifyUser('success', 'acquisizioneDati.versamenti.attribuzioneAutomaticaMassivaSuccess__notification');
    loadCollection({
      milestone: 'versamento_non_attribuito_resgistrato'
    });
  });
  $scope.versamenti.$on('after-attribuzione-automatica-massiva-error', function (res) {
    notifyUser('success', 'acquisizioneDati.versamenti.attribuzioneAutomaticaMassivaError__notification');
  });
  $scope.$on('upload-new-versamenti-file', function () {
    $timeout(function () {
      loadCollection({
        milestone: 'versamento_acquisito'
      });
    }, 1000);
  });
  $scope.versamenti.$on('after-get-collection-ids', function (res) {
    startProcessConfirmation();
  });

  me.$onInit = function () {
    $scope.filters = [];
    $scope.activeFilters = [];

    _loadPaginationLabels().then(setPaginationLabel);

    setActiveTab($stateParams.activeTab);
  };
  /**
   * Filtri temporali
   */


  $scope.firstDateFilterLabel = 'Data creazione';
  $scope.firstDateFilterKey = 'dataCreazioneByRange';

  $scope.addTimeRangeFilter = function (filterData) {
    addFilter({
      name: filterData.selectionType,
      value: filterData.timeRange
    });
  };
  /**
   * Handlers
   */


  $scope.onTabClick = function (prm) {
    $scope.filterPanelIsOpen = false;
    loadCollection(prm);
  };

  $scope.onStartProcessClick = function (item) {
    if (item) {
      // SE HO UN SOLO ITEM STARTO IL PROCESSO SOLO PER IL SINGOLO ITEM
      startProcessConfirmation(item);
    } else {
      // SE NON HO L'ITEM STARTO IL PROCESSO PER TUTTI I VERSAMENTI
      $scope.versamenti.$getCollectionIds({
        milestone: 'versamento_acquisito'
      });
    }
  };

  $scope.onFilterListClick = function () {
    toggleFilterPanel();
  };

  $scope.onRemoveFilterClick = function () {
    $scope.versamenti.$resetFilters();
    toggleAddFilter();
  };

  $scope.onValidazioneClick = function (item, milestone) {
    var itemsDaValidare = item ? [item] : $scope.versamenti;
    $state.go('navigation.acquisizioneDati.validazioneVersamenti', {
      milestone: milestone,
      versamentiDaValidare: itemsDaValidare
    });
  };

  $scope.onAttribuisciVersamentiClick = function () {
    askUserConfirmation('info', 'acquisizioneDati.versamenti.attribuzioneAutomaticaMassivaVersamenti__title', 'acquisizioneDati.versamenti.attribuzioneAutomaticaMassivaVersamenti__body', 'acquisizioneDati.versamenti.attribuzioneAutomaticaMassivaVersamentiConfirm__button').then(attribuzioneAutomaticaMassiva.bind(me)).catch(function (err) {
      if (err !== undefined) {
        notifyUser('warn', 'acquisizioneDati.versamenti.versamentoEliminatoError__notification');
      }
    });
  };

  $scope.onShowImmaginiVersamentoClick = function (versamento) {
    retrieveImmaginiVersamento(versamento).$asPromise().then(showImmaginiVersamentoModal).catch(function (err) {
      notifyUser('info', 'acquisizioneDati.versamenti.immaginiNonTrovate__notification');
    });
  };

  $scope.getItemFromString = function (tipoBollettino, tracciato) {
    if (!tracciato) {
      return;
    }

    const tracciatoFromString = JSON.parse(tracciato);
    let result = tracciatoFromString;

    switch (tipoBollettino) {
      case "bollettino_txt":
        result = (0, _utils.formatBollettinoTxtDetail)(tracciatoFromString);
        break;

      case "bollettino_report":
        result = (0, _utils.formatBollettinoReportDetail)(tracciatoFromString);
        break;

      case "bollettino_sepa_bp":
        result = (0, _utils.formatBollettinoSepaBpDetail)(tracciatoFromString);
        break;

      case "api_automatica_posta":
        result = (0, _utils.formatApiAutomaticaPostaDetail)(tracciatoFromString);
        break;
    }

    return result;
  };
  /**
   * Apis
   */


  function loadCollection(prm) {
    $scope.versamenti.$ricercaVeloce(prm);
  }

  function startProcess(_data) {
    if (_data) {
      _data.$inizializzaProcessoAttribuzioneVersamenti();
    } else {
      $scope.versamenti.$inizializzaProcessoAttribuzioneVersamenti($scope.versamenti.$collectionIds);
    }
  }

  function retrieveImmaginiVersamento(versamento) {
    return versamento.$getImmaginiVersamento();
  }

  function attribuzioneAutomaticaMassiva() {
    $scope.versamenti.$attribuzioneAutomaticaMassiva();
  }
  /**
   * Setters
   * Viene usato solo per settare direttamente active tab se dichiarato negli $stateParams
   */


  function setActiveTab(activeTab) {
    switch (activeTab) {
      case 'versamento_acquisito':
        $scope.activeTab = 0;
        break;

      case 'in_attesa_validazione':
        $scope.activeTab = 1;
        break;

      case 'versamento_non_attribuito_resgistrato':
        $scope.activeTab = 2;
        break;

      case 'versamento_attribuito':
        $scope.activeTab = 3;
        break;

      default:
        $scope.activeTab = 0;
    }
  }
  /**
   * Utils
   */


  function addFilter(filtro) {
    $scope.versamenti.$addFilter(filtro);
    toggleAddFilter();
    toggleFilterPanel();
  }

  function toggleAddFilter() {
    $scope.filterIsPresent = !$scope.filterIsPresent;
  }

  function toggleFilterPanel() {
    $scope.filterPanelIsOpen = !$scope.filterPanelIsOpen;
  }

  function startProcessConfirmation(_data) {
    var titleText, bodyText;

    if (!_data) {
      titleText = 'acquisizioneDati.versamenti.startProcessAttribuzione__title';
      bodyText = 'acquisizioneDati.versamenti.startProcessAttribuzione__body';
    } else {
      titleText = 'acquisizioneDati.versamenti.startProcessAttribuzioneUnVersamento__title';
      bodyText = 'acquisizioneDati.versamenti.startProcessAttribuzioneUnVersamento__button';
    }

    askUserConfirmation('info', titleText, bodyText, 'acquisizioneDati.versamenti.startProcessAttribuzione__button').then(startProcess.bind(me, _data)).catch(function (err) {
      if (err !== undefined) {
        notifyUser('error', 'acquisizioneDati.versamenti.startInizializzazione__error');
      }
    });
  }

  function showImmaginiVersamentoModal(versamento) {
    $mdDialog.show({
      controller: 'immaginiVersamentoModalController',
      controllerAs: '$ctrl',
      templateUrl: 'acquisizioneDati/versamenti/immaginiVersamentoModal/immaginiVersamentoModal.component.html',
      parent: angular.element(document.body),
      bindToController: true,
      clickOutsideToClose: true,
      locals: {
        immagini: versamento.$immagini || []
      }
    });
  }

  function askUserConfirmation(type, title, body, confirmBtn) {
    return modalPresenter[type]([title, body, confirmBtn, 'acquisizioneDati.versamenti.undo__buttonText']);
  }

  function notifyUser(type, msg) {
    return OSNotification[type]({
      translationIndex: msg
    });
  }
  /**
   * Paginazione
   */


  $scope.labels = {};

  $scope.onPageChange = function (page, limit) {
    $scope.versamenti.$changePage(page, limit);
  };

  function _loadPaginationLabels() {
    return $translate(['labels.page__text', 'labels.rowsPerPage__text', 'labels.of__text']);
  }

  function setPaginationLabel(translations) {
    $scope.labels.page = translations['labels.page__text'];
    $scope.labels.rowsPerPage = translations['labels.rowsPerPage__text'];
    $scope.labels.of = translations['labels.of__text'];
  }
}