"use strict";

var _TdsListRoot = _interopRequireDefault(require("./listaTabelle/TdsListRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('tdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tds',
    url: 'tds',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: false
    },
    views: {
      $default: {
        component: _TdsListRoot.default
      }
    },
    resolve: {
      $label: () => "Tabelle di supporto"
    }
  }];
  routesProviderFactory.extend(this);
}