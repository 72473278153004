"use strict";

/**
 * Created by MN on 31/01/2020
 */
(function () {
  'use strict';

  angular.module('polcity.services').factory('storageService', [StorageService]);

  function StorageService() {
    return {
      storeItem: storeItem,
      deleteItem: deleteItem,
      getData: getData
    };
    /**
     * Immagazzina un elemento nel local/session storage
     *
     * @param _storeObj
     * @param _objKey
     * @param _objData
     * @param _dataReplacer
     * @param _dataSpace
     */

    function storeItem(_storeObj, _objKey, _objData, _dataReplacer, _dataSpace) {
      // se non ho la chiave dell'oggetto ed i dati da immagazzinare non posso procedere
      if (!_storeObj || !_objKey || !_objData) {
        console.error(`Error, can't store object because some data is missing`);
        return;
      } // controllo sul tipo di dato di key che deve essere una stringa


      if (_objKey && typeof _objKey !== 'string') {
        console.error(`Error, key must to be a string but got ${typeof _objKey}`);
        return;
      }

      var stringifyData = stringifyFn(_objData, _dataReplacer, _dataSpace);
      dataToStorage(_storeObj, _objKey, stringifyData);
    }
    /**
     * Data una chiave recupera quell'elemento dal local/session storage
     *
     * @param _storeObj
     * @param _itemKey
     */


    function getData(_storeObj, _itemKey) {
      var result;

      if (!_storeObj || !_itemKey) {
        console.error(`Error, can't retrieve item because some data is missing`);
        return;
      }

      if (typeof _itemKey !== 'string') {
        console.error(`Error, can't retrieve item because itemKey must to be a string but got ${typeof _itemKey}`);
        return;
      }

      result = getItemFromStorage(_storeObj, _itemKey);
      return JSON.parse(result);
      /*try {
          return JSON.parse(result);
      } catch (e) {
          console.log(e)
      }*/
    }
    /**
     * Data la chiave, elimina quell'elemento dal local/session storage
     *
     * @param _storeObj
     * @param _itemKey
     */


    function deleteItem(_storeObj, _itemKey) {
      if (!_storeObj || !_itemKey) {
        console.error(`Error, can't remove item because some data is missing`);
        return;
      }

      if (typeof _itemKey !== 'string') {
        console.error(`Error, can't remove item because itemKey must to be a string but got ${typeof _itemKey}`);
        return;
      }

      try {
        _storeObj.removeItem(_itemKey);
      } catch (e) {
        handleError(e);
      }
    }
    /**
     * Trasforma un oggetto in stringa tramite la funzione JSON.stringify()
     *
     * @param _data
     * @param _dataReplacer
     * @param _dataSpace
     * @returns {string}
     */


    function stringifyFn(_data, _dataReplacer, _dataSpace) {
      var stringifyData = {}; // _objReplacer deve essere una funzione o un array di string e number

      if (_dataReplacer && (typeof _dataReplacer !== 'function' || typeof _dataReplacer !== 'object')) {
        console.error(`Error, replacer must be a funcion or an array of string and numbers`);
        return;
      } // _objSpace deve essere string o number


      if (_dataSpace && (typeof _dataSpace !== 'string' || typeof _dataSpace !== 'number')) {
        console.error(`Error, _objSpace must be a string or a number but got ${typeof _dataSpace}: ${_dataSpace}`);
        return;
      }

      try {
        stringifyData = JSON.stringify(_data, _dataReplacer, _dataSpace);
      } catch (e) {}

      return stringifyData;
    }
    /**
     * Immagazzina l'oggetto desiderato nel local/session storage
     *
     * @param _storeObj
     * @param _objKey
     * @param _data
     * @param _onStoreError
     */


    function dataToStorage(_storeObj, _objKey, _data) {
      try {
        _storeObj.setItem(_objKey, _data);
      } catch (e) {
        handleError(e);
      }
    }
    /**
     * Recupera l'item dal local/session storage
     *
     * @param _storeObj
     * @param _itemKey
     * @returns {string}
     */


    function getItemFromStorage(_storeObj, _itemKey) {
      try {
        return _storeObj.getItem(_itemKey);
      } catch (e) {
        handleError(e, _itemKey);
      }
    }
    /**
     * Handler degli errori
     * @param _error
     * @param _itemKey
     */


    function handleError(_error, _itemKey) {
      console.error(`Can't procede because ${_error}`);
      deleteItem(_itemKey);
    }
  }
})();