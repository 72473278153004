"use strict";

/**
 * Created by dan on 05/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.verbali').config(['$stateProvider', 'verbaliRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['verbaliRoutes', routesRun]);

  function addRoutes($stateProvider, verbaliRoutesProvider, $translatePartialLoader, AuthProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name;

      if (!routeDefinition.role || AuthProvider.hasRole(routeDefinition.role)) {
        $stateProvider.state(stateName, routeDefinition);
      }
    }, verbaliRoutesProvider.list());
    $translatePartialLoader.addPart('verbali');
  }

  function routesRun(verbaliRoutes) {
    verbaliRoutes.subscribeUIEvents();
  }
})();