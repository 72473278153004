"use strict";

(function () {
  'use strict';

  angular.module('polcity.navigation').filter('menuItemsFilter', menuItemsFilter);

  function menuItemsFilter() {
    return filter;

    function filter(items, stringToMatch) {
      if (!stringToMatch) {
        return items;
      }

      return R.filter(function (item) {
        var toLower = R.compose(R.toLower, R.prop);
        return toLower('scope', item).indexOf(stringToMatch) > -1 || toLower('label', item).indexOf(stringToMatch) > -1;
      }, items);
    }
  }
})();