"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requiredFieldsVerbaleLabelMap = exports.extractBolletta = exports.velocitaEmptyForm = exports.rimozioneEmptyForm = exports.fermoSequestroEmptyForm = exports.verbaleEmptyForm = exports.extractRossoNonApplicato = exports.extractRossoApplicata = exports.extractRossoToApi = exports.extractVelocitaToApi = exports.extractFermoSequestroToApi = exports.extractFermoSequestroApplicato = exports.extractFermoSequestroNonApplicata = exports.extractRimozioneToApi = exports.extractDecoratoreNonApplicato = exports.extractDecoratoreGenerico = exports.toApi = exports.fromApi = void 0;

var _utils = require("../../utils");

var _mreduceMap = _interopRequireDefault(require("crocks/helpers/mreduceMap"));

var _Assign = _interopRequireDefault(require("crocks/Assign"));

var _applyTo = _interopRequireDefault(require("crocks/combinators/applyTo"));

var _object = require("../../object.utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const fromApi = remoteVerbale => {
  var verbale = { ...(remoteVerbale.aggId ? {
      aggId: remoteVerbale.aggId
    } : {}),
    numero: (0, _object.getPathNumberOrEmptyString)(["dominio", "numero"])(remoteVerbale),
    numeroProtocollo: (0, _object.getPathStringOrEmptyString)(["dominio", "numeroProtocollo"])(remoteVerbale),
    annoVerbale: (0, _object.getPathNumberOrEmptyString)(["dominio", "annoVerbale"])(remoteVerbale),
    tipoAccertamento: null,
    tipoAccertamentoId: (0, _object.getPathStringOrEmptyString)(["dominio", "tipoAccertamentoId"])(remoteVerbale),
    dataOraVerbale: (0, _object.getPathNumberToDateOrNull)(["dataOraVerbale"])(remoteVerbale),
    dataOraVerbalizzazione: (0, _object.getPathNumberToDateOrNull)(["dataOraVerbalizzazione"])(remoteVerbale),
    dataOraDecorrenza: (0, _object.getPathNumberToDateOrNull)(["dataOraDecorrenza"])(remoteVerbale),
    giorniUtili: (0, _object.getPathNumberOrEmptyString)(["dominio", "giorniUtili"])(remoteVerbale),
    luogoAccertamento: {
      luogoAccertamentoComune: null,
      luogoAccertamentoComuneId: (0, _object.getPathStringOrEmptyString)(["dominio", "luogoAccertamento", "luogoAccertamentoComuneId"])(remoteVerbale),
      luogoAccertamentoStrada: null,
      luogoAccertamentoStradaId: (0, _object.getPathStringOrEmptyString)(["dominio", "luogoAccertamento", "luogoAccertamentoStradaId"])(remoteVerbale),
      luogoAccertamentoCivico: (0, _object.getPathStringOrEmptyString)(["dominio", "luogoAccertamento", "luogoAccertamentoCivico"])(remoteVerbale),
      luogoAccertamentoKilometro: (0, _object.getPathStringOrEmptyString)(["dominio", "luogoAccertamento", "luogoAccertamentoKilometro"])(remoteVerbale),
      lat: (0, _object.getPathNumberOrNull)(["luogoAccertamentoDto", "lat"])(remoteVerbale),
      lon: (0, _object.getPathNumberOrNull)(["luogoAccertamentoDto", "lon"])(remoteVerbale)
    },
    tipoRelazioneStrada: {
      luogoAccertamentoTipoRelazioneStradaTipo: (0, _object.getPathStringOrEmptyString)(["dominio", "luogoAccertamento", "tipoRelazioneStrada", "luogoAccertamentoTipoRelazioneStradaTipo"])(remoteVerbale),
      luogoAccertamentoTipoRelazioneStradaStrada: null,
      luogoAccertamentoTipoRelazioneStradaStradaId: (0, _object.getPathStringOrEmptyString)(["dominio", "luogoAccertamento", "tipoRelazioneStrada", "luogoAccertamentoTipoRelazioneStradaStradaId"])(remoteVerbale),
      luogoAccertamentoTipoRelazioneStradaCivico: (0, _object.getPathStringOrEmptyString)(["dominio", "luogoAccertamento", "tipoRelazioneStrada", "luogoAccertamentoTipoRelazioneStradaCivico"])(remoteVerbale),
      luogoAccertamentoTipoRelazioneDescrizioneFronte: (0, _object.getPathStringOrEmptyString)(["dominio", "luogoAccertamento", "tipoRelazioneStrada", "luogoAccertamentoTipoRelazioneDescrizioneFronte"])(remoteVerbale)
    },
    pedone: (0, _object.getPathBooleanOrFalse)(["dominio", "pedone"])(remoteVerbale),
    veicolo: {
      veicoloTipo: null,
      veicoloTipoId: (0, _object.getPathStringOrNull)(["dominio", "veicolo", "veicoloTipo"])(remoteVerbale),
      veicoloTarga: (0, _object.getPathStringOrEmptyString)(["dominio", "veicolo", "veicoloTarga"])(remoteVerbale),
      veicoloStato: null,
      veicoloStatoId: (0, _object.getPathStringOrEmptyString)(["dominio", "veicolo", "veicoloStatoId"])(remoteVerbale),
      veicoloMarca: (0, _object.getPathStringOrEmptyString)(["dominio", "veicolo", "veicoloMarca"])(remoteVerbale),
      veicoloModello: (0, _object.getPathStringOrEmptyString)(["dominio", "veicolo", "veicoloModello"])(remoteVerbale),
      veicoloPortata: (0, _object.getPathNumberOrEmptyString)(["dominio", "veicolo", "veicoloPortata"])(remoteVerbale),
      veicoloMassa: (0, _object.getPathNumberOrEmptyString)(["dominio", "veicolo", "veicoloMassa"])(remoteVerbale),
      veicoloNrTelaio: (0, _object.getPathStringOrEmptyString)(["dominio", "veicolo", "veicoloNrTelaio"])(remoteVerbale)
    },
    rimorchio: {
      veicoloRimorchioPresente: (0, _object.getPathBooleanOrFalse)(["dominio", "veicolo", "rimorchio", "veicoloRimorchioPresente"])(remoteVerbale),
      veicoloRimorchioMarca: (0, _object.getPathStringOrEmptyString)(["dominio", "veicolo", "rimorchio", "veicoloRimorchioMarca"])(remoteVerbale),
      veicoloRimorchioMassa: (0, _object.getPathNumberOrEmptyString)(["dominio", "veicolo", "rimorchio", "veicoloRimorchioMassa"])(remoteVerbale),
      veicoloRimorchioModello: (0, _object.getPathStringOrEmptyString)(["dominio", "veicolo", "rimorchio", "veicoloRimorchioModello"])(remoteVerbale),
      veicoloRimorchioNrTelaio: (0, _object.getPathStringOrEmptyString)(["dominio", "veicolo", "rimorchio", "veicoloRimorchioNrTelaio"])(remoteVerbale),
      veicoloRimorchioPortata: (0, _object.getPathNumberOrEmptyString)(["dominio", "veicolo", "rimorchio", "veicoloRimorchioPortata"])(remoteVerbale),
      veicoloRimorchioTarga: (0, _object.getPathStringOrEmptyString)(["dominio", "veicolo", "rimorchio", "veicoloRimorchioTarga"])(remoteVerbale)
    },
    motivazioneMancataContestazione: null,
    motivazioneMancataContestazioneId: (0, _object.getPathStringOrEmptyString)(["dominio", "motivazioneMancataContestazioneId"])(remoteVerbale),
    motivazioneMancataContestazioneDescrizioneStampa: (0, _object.getPathArrayOrEmptyArray)(["dominio", "motivazioneMancataContestazioneDescrizioneStampa"])(remoteVerbale),
    modelloStampa: [],
    modelloStampaId: (0, _object.getPathArrayOrEmptyArray)(["dominio", "modelloStampaId"])(remoteVerbale),
    noteUsoInterno: (0, _object.getPathStringOrEmptyString)(["dominio", "noteUsoInterno"])(remoteVerbale),
    infrazioni: (0, _object.getPathArrayOrEmptyArray)(["infrazioni"])(remoteVerbale),
    sanzioni: {
      fermoSequestro: remoteVerbale.dominio.sanzioni.fermoSequestro ? {
        commandName: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "commandName"])(remoteVerbale),
        applicabile: (0, _object.getPathBooleanOrFalse)(["dominio", "sanzioni", "fermoSequestro", "applicabile"])(remoteVerbale),
        affidatario: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "affidatario"])(remoteVerbale),
        custode: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "custode"])(remoteVerbale),
        dataOperazione: (0, _object.getPathNumberToDateOrNull)(["dominio", "sanzioni", "fermoSequestro", "dataOperazione"])(remoteVerbale),
        dataScadenza: (0, _object.getPathNumberToDateOrNull)(["dominio", "sanzioni", "fermoSequestro", "dataScadenza"])(remoteVerbale),
        deposito: null,
        depositoId: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "depositoId"])(remoteVerbale),
        descrizione: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "descrizione"])(remoteVerbale),
        luogoCustodia: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "luogoCustodia"])(remoteVerbale),
        tipo: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "tipo"])(remoteVerbale),
        trasportoACuraDi: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "trasportoACuraDi"])(remoteVerbale),
        trasportoEventualiDanni: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "trasportoEventualiDanni"])(remoteVerbale),
        veicoloKm: (0, _object.getPathNumberOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "veicoloKm"])(remoteVerbale),
        veicoloOggettiRinvenuti: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "veicoloOggettiRinvenuti"])(remoteVerbale),
        veicoloStato: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "veicoloStato"])(remoteVerbale),
        motivazioneMancataApplicazioneId: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "motivazioneMancataApplicazioneId"])(remoteVerbale),
        motivazioneMancataApplicazioneLibera: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "fermoSequestro", "motivazioneMancataApplicazioneLibera"])(remoteVerbale)
      } : null,
      rimozione: remoteVerbale.dominio.sanzioni.rimozione ? {
        commandName: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "commandName"])(remoteVerbale),
        applicabile: (0, _object.getPathBooleanOrFalse)(["dominio", "sanzioni", "rimozione", "applicabile"])(remoteVerbale),
        motivazione: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "motivazione"])(remoteVerbale),
        rimozioneEffettuata: (0, _object.getPathBooleanOrFalse)(["dominio", "sanzioni", "rimozione", "rimozioneEffettuata"])(remoteVerbale),
        rimozioneDataOra: (0, _object.getPathNumberToDateOrNull)(["dominio", "sanzioni", "rimozione", "rimozioneDataOra"])(remoteVerbale),
        rimozioneAccertataIl: (0, _object.getPathNumberToDateOrNull)(["dominio", "sanzioni", "rimozione", "rimozioneAccertataIl"])(remoteVerbale),
        rimozioneCarroAttrezzi: null,
        rimozioneCarroAttrezziId: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "rimozioneCarroAttrezziId"])(remoteVerbale),
        rimozioneNomeCustode: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "rimozioneNomeCustode"])(remoteVerbale),
        rimozioneDeposito: null,
        rimozioneDepositoId: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "rimozioneDepositoId"])(remoteVerbale),
        rimozioneLuogoCustodia: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "rimozioneLuogoCustodia"])(remoteVerbale),
        rimozioneFurto: (0, _object.getPathBooleanOrFalse)(["dominio", "sanzioni", "rimozione", "rimozioneFurto"])(remoteVerbale),
        rimozioneImporto: (0, _object.getPathNumberOrEmptyString)(["dominio", "sanzioni", "rimozione", "rimozioneImporto"])(remoteVerbale),
        rimozioneAnnotazioni: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "rimozioneAnnotazioni"])(remoteVerbale),
        rimozioneDanniCausati: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "rimozioneDanniCausati"])(remoteVerbale),
        descrizione: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "descrizione"])(remoteVerbale),
        motivazioneMancataApplicazioneId: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "motivazioneMancataApplicazioneId"])(remoteVerbale),
        motivazioneMancataApplicazioneLibera: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rimozione", "motivazioneMancataApplicazioneLibera"])(remoteVerbale)
      } : null,
      velocita: remoteVerbale.dominio.sanzioni.velocita ? {
        commandName: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "velocita", "commandName"])(remoteVerbale),
        direzione: null,
        direzioneId: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "velocita", "direzioneId"])(remoteVerbale),
        numeroFotogramma: (0, _object.getPathNumberOrEmptyString)(["dominio", "sanzioni", "velocita", "numeroFotogramma"])(remoteVerbale),
        rilevatore: null,
        rilevatoreId: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "velocita", "rilevatoreId"])(remoteVerbale),
        speseSviluppo: (0, _object.getPathBooleanOrFalse)(["dominio", "sanzioni", "velocita", "speseSviluppo"])(remoteVerbale),
        velocitaAccertata: (0, _object.getPathNumberOrEmptyString)(["dominio", "sanzioni", "velocita", "velocitaAccertata"])(remoteVerbale),
        velocitaConsentita: (0, _object.getPathNumberOrEmptyString)(["dominio", "sanzioni", "velocita", "velocitaConsentita"])(remoteVerbale),
        velocitaDistanza: (0, _object.getPathNumberOrEmptyString)(["dominio", "sanzioni", "velocita", "velocitaDistanza"])(remoteVerbale),
        velocitaSuperata: (0, _object.getPathNumberOrEmptyString)(["dominio", "sanzioni", "velocita", "velocitaSuperata"])(remoteVerbale)
      } : null,
      rosso: remoteVerbale.dominio.sanzioni.rosso ? {
        commandName: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rosso", "commandName"])(remoteVerbale),
        speseSviluppo: (0, _object.getPathBooleanOrFalse)(["dominio", "sanzioni", "rosso", "speseSviluppo"])(remoteVerbale),
        rilevatore: null,
        rilevatoreId: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rosso", "rilevatoreId"])(remoteVerbale),
        direzione: null,
        direzioneId: (0, _object.getPathStringOrEmptyString)(["dominio", "sanzioni", "rosso", "direzioneId"])(remoteVerbale),
        numeroFotogramma: (0, _object.getPathNumberOrEmptyString)(["dominio", "sanzioni", "rosso", "numeroFotogramma"])(remoteVerbale),
        distanza: (0, _object.getPathNumberOrEmptyString)(["dominio", "sanzioni", "rosso", "distanza"])(remoteVerbale)
      } : null,
      ztl: remoteVerbale.dominio.sanzioni.ztl ? remoteVerbale.dominio.sanzioni.ztl : null,
      senzaDocumenti: (0, _object.getPathObjectOrNull)(["dominio", "sanzioni", "senzaDocumenti"])(remoteVerbale),
      generico: (0, _object.getPathArrayOrEmptyArray)(["dominio", "sanzioni", "generico"])(remoteVerbale)
    },
    trasgressore: {
      trasgressore: null,
      trasgressoreTipo: (0, _object.getPathStringOrEmptyString)(["dominio", "trasgressoreTipo"])(remoteVerbale),
      trasgressoreAggId: (0, _object.getPathStringOrEmptyString)(["dominio", "trasgressore", "trasgressoreAggId"])(remoteVerbale),
      trasgressoreDataNotifica: (0, _object.getPathNumberToDateOrNull)(["dominio", "trasgressoreDataNotifica"])(remoteVerbale),
      trasgressoreNeoPatentato: (0, _object.getPathBooleanOrFalse)(["dominio", "trasgressore", "trasgressoreNeoPatentato"])(remoteVerbale),
      trasgressoreObbligatoInSolido: (0, _object.getPathBooleanOrFalse)(["dominio", "trasgressore", "trasgressoreObbligatoInSolido"])(remoteVerbale)
    },
    documentiPresentati: (0, _object.getPathArrayOrEmptyArray)(["dominio", "documentiPresentati"])(remoteVerbale).map(d => ({ ...d,
      dataPresentazione: (0, _object.getPathNumberToDateOrNull)(["dataPresentazione"])(d)
    })),
    decurtazionePuntiApplicataDocumentoId: (0, _object.getPathArrayOrEmptyArray)(["dominio", "decurtazionePuntiApplicataDocumentoId"])(remoteVerbale),
    dichiarazioniTrasgressore: (0, _object.getPathStringOrEmptyString)(["dominio", "dichiarazioniTrasgressore"])(remoteVerbale),
    obbligatoUgualeTrasgressore: (0, _object.getPathBooleanOrTrue)(["dominio", "obbligatoUgualeTrasgressore"])(remoteVerbale),
    obbligato: {
      obbligato: null,
      obbligatoAggId: (0, _object.getPathStringOrEmptyString)(["dominio", "obbligato", "obbligatoAggId"])(remoteVerbale),
      obbligatoTipo: (0, _object.getPathStringOrEmptyString)(["dominio", "obbligatoTipo"])(remoteVerbale),
      obbligatoDataNotifica: (0, _object.getPathNumberToDateOrNull)(["dominio", "obbligatoDataNotifica"])(remoteVerbale)
    },
    spedizioni: [],
    fermoSequestroId: (0, _object.getPathStringOrEmptyString)(["dominio", "fermoSequestroId"])(remoteVerbale),
    rimozioneId: (0, _object.getPathStringOrEmptyString)(["dominio", "rimozioneId"])(remoteVerbale),
    storicoTrasgressori: (0, _object.getPathArrayOrEmptyArray)(["storicoTrasgressori"])(remoteVerbale).map(item => item.trasgressoreAggId),
    storicoObbligati: (0, _object.getPathArrayOrEmptyArray)(["storicoObbligati"])(remoteVerbale).map(item => item.obbligatoAggId),
    statoVerbale: (0, _object.getPathStringOrEmptyString)(["statoVerbale"])(remoteVerbale),
    verbale126BisDTO: remoteVerbale.verbale126BisDTO,
    //TODO: valutare al refactor del verbale
    soggettiList: remoteVerbale.soggettiList,
    //TODO: valutare al refactor del verbale
    verbaleId: remoteVerbale.verbaleId,
    //TODO: valutare al refactor del verbale
    stazioneComando: remoteVerbale.stazioneComando,
    preferito: remoteVerbale.preferito,
    riferimentoDocumentoVerbalizzato: (0, _object.getPathObjectOrNull)(["riferimentoDocumentoVerbalizzato"])(remoteVerbale)
  };
  return verbale;
};

exports.fromApi = fromApi;

const toApi = values => {
  let verbale = {
    type: "InizializzaVerbaleAManoDaUICommand",
    ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    numero: values.numero,
    tipoAccertamentoId: values.tipoAccertamento.aggId,
    dataOraVerbale: (0, _object.getPathDateToNumberOrNull)(["dataOraVerbale"])(values),
    dataOraVerbalizzazione: (0, _object.getPathDateToNumberOrNull)(["dataOraVerbalizzazione"])(values),
    dataOraDecorrenza: (0, _object.getPathDateToNumberOrNull)(["dataOraDecorrenza"])(values),
    luogoAccertamentoComuneId: (0, _object.getPathStringOrNull)(["luogoAccertamento", "luogoAccertamentoComune", "aggId"])(values),
    luogoAccertamentoStradaId: (0, _object.getPathStringOrNull)(["luogoAccertamento", "luogoAccertamentoStrada", "entityId"])(values),
    luogoAccertamentoCivico: (0, _object.getPathNonEmptyStringOrNull)(["luogoAccertamento", "luogoAccertamentoCivico"])(values),
    luogoAccertamentoKilometro: (0, _object.getPathNonEmptyStringOrNull)(["luogoAccertamento", "luogoAccertamentoKilometro"])(values),
    luogoAccertamentoTipoRelazioneStradaTipo: (0, _object.getPathNonEmptyStringOrNull)(["tipoRelazioneStrada", "luogoAccertamentoTipoRelazioneStradaTipo"])(values),
    luogoAccertamentoTipoRelazioneStradaStradaId: (0, _object.getPathNonEmptyStringOrNull)(["tipoRelazioneStrada", "luogoAccertamentoTipoRelazioneStradaStrada", "entityId"])(values),
    luogoAccertamentoTipoRelazioneStradaCivico: (0, _object.getPathNonEmptyStringOrNull)(["tipoRelazioneStrada", "luogoAccertamentoTipoRelazioneStradaCivico"])(values),
    luogoAccertamentoTipoRelazioneDescrizioneFronte: (0, _object.getPathNonEmptyStringOrNull)(["tipoRelazioneStrada", "luogoAccertamentoTipoRelazioneDescrizioneFronte"])(values),
    pedone: values.pedone,
    veicoloTipo: (0, _object.getPathNonEmptyStringOrNull)(["veicolo", "veicoloTipo", "aggId"])(values),
    veicoloTarga: (0, _object.getPathNonEmptyStringOrNull)(["veicolo", "veicoloTarga"])(values),
    veicoloStatoId: (0, _object.getPathNonEmptyStringOrNull)(["veicolo", "veicoloStato", "aggId"])(values),
    veicoloMarca: (0, _object.getPathNonEmptyStringOrNull)(["veicolo", "veicoloMarca"])(values),
    veicoloModello: (0, _object.getPathNonEmptyStringOrNull)(["veicolo", "veicoloModello"])(values),
    veicoloPortata: (0, _object.getPathNumberOrNull)(["veicolo", "veicoloPortata"])(values),
    veicoloMassa: (0, _object.getPathNumberOrNull)(["veicolo", "veicoloMassa"])(values),
    veicoloNrTelaio: (0, _object.getPathNonEmptyStringOrNull)(["veicolo", "veicoloNrTelaio"])(values),
    veicoloRimorchioPresente: (0, _object.getPathBooleanOrFalse)(["rimorchio", "veicoloRimorchioPresente"])(values),
    veicoloRimorchioMarca: (0, _object.getPathNonEmptyStringOrNull)(["rimorchio", "veicoloRimorchioMarca"])(values),
    veicoloRimorchioMassa: (0, _object.getPathNumberOrNull)(["rimorchio", "veicoloRimorchioMassa"])(values),
    veicoloRimorchioModello: (0, _object.getPathNonEmptyStringOrNull)(["rimorchio", "veicoloRimorchioModello"])(values),
    veicoloRimorchioNrTelaio: (0, _object.getPathNonEmptyStringOrNull)(["rimorchio", "veicoloRimorchioNrTelaio"])(values),
    veicoloRimorchioPortata: (0, _object.getPathNumberOrNull)(["rimorchio", "veicoloRimorchioPortata"])(values),
    veicoloRimorchioTarga: (0, _object.getPathNonEmptyStringOrNull)(["rimorchio", "veicoloRimorchioTarga"])(values),
    trasgressoreTipo: (0, _object.getPathNonEmptyStringOrNull)(["trasgressore", "trasgressoreTipo"])(values),
    trasgressoreAggId: (0, _object.getPathNonEmptyStringOrNull)(["trasgressore", "trasgressore", "aggId"])(values),
    trasgressoreNeoPatentato: (0, _object.getPathBooleanOrFalse)(["trasgressore", "trasgressoreNeoPatentato"])(values),
    trasgressoreObbligatoInSolido: (0, _object.getPathBooleanOrFalse)(["trasgressore", "trasgressoreObbligatoInSolido"])(values),
    trasgressoreDataNotifica: (0, _object.getPathDateToNumberOrNull)(["trasgressore", "trasgressoreDataNotifica"])(values),
    documentiPresentati: (0, _object.getPathArrayOrEmptyArray)(["documentiPresentati"])(values).map(d => ({ ...d,
      dataPresentazione: (0, _object.getPathDateToNumberOrNull)(["dataPresentazione"])(d)
    })),
    decurtazionePuntiApplicataDocumentoId: (0, _object.getPathArrayOrEmptyArray)(["decurtazionePuntiApplicataDocumentoId"])(values),
    dichiarazioniTrasgressore: (0, _object.getPathNonEmptyStringOrNull)(["dichiarazioniTrasgressore"])(values),
    obbligatoUgualeTrasgressore: (0, _object.getPathBooleanOrTrue)(["obbligatoUgualeTrasgressore"])(values),
    obbligatoAggId: (0, _object.getPathNonEmptyStringOrNull)(["obbligato", "obbligato", "aggId"])(values),
    obbligatoTipo: (0, _object.getPathNonEmptyStringOrNull)(["obbligato", "obbligatoTipo"])(values),
    obbligatoDataNotifica: (0, _object.getPathDateToNumberOrNull)(["obbligato", "obbligatoDataNotifica"])(values),
    infrazioni: values.infrazioni.map(infrazione => ({ ...infrazione,
      articoloId: infrazione.aggId,
      sanzioniAccessorie: Array.isArray(infrazione.sanzioniAccessorie) ? infrazione.sanzioniAccessorie.map(s => s.codiceVeloce) : []
    })),
    motivazioneMancataContestazioneId: (0, _object.getPathNonEmptyStringOrNull)(["motivazioneMancataContestazione", "aggId"])(values),
    motivazioneMancataContestazioneDescrizioneStampa: (0, _object.getPathArrayOrNull)(["motivazioneMancataContestazioneDescrizioneStampa"])(values),
    noteUsoInterno: (0, _object.getPathNonEmptyStringOrNull)(["noteUsoInterno"])(values),
    modelloStampaId: (0, _object.getPathArrayOrEmptyArray)(["modelloStampa"])(values).map(item => item.aggId),
    ...(values.allegatoNonAssociatoId ? {
      allegatoNonAssociatoId: values.allegatoNonAssociatoId
    } : {}),
    tipologia: "GESTIONE_DATI"
  };
  const sanzioni = [values.sanzioni.rimozione ? extractRimozioneToApi(values.sanzioni.rimozione) : null, values.sanzioni.fermoSequestro ? extractFermoSequestroToApi(values.sanzioni.fermoSequestro) : null, values.sanzioni.velocita ? extractVelocitaToApi(values.sanzioni.velocita) : null, values.sanzioni.rosso ? extractRossoToApi(values.sanzioni.rosso) : null, ...values.sanzioni.generico.map(g => extractDecoratoreGenerico(g))];
  verbale = (0, _utils.removeNulls)(verbale);
  return creaVerbale(sanzioni, verbale);
};

exports.toApi = toApi;

const creaVerbale = (sanzioni, verbale) => {
  const nonEmptySanzioni = sanzioni.filter(sanzione => sanzione != null);

  if (nonEmptySanzioni.length === 0) {
    return {
      inner: verbale
    };
  } else {
    return {
      inner: {
        imNext: true,
        type: nonEmptySanzioni[0].commandName,
        ...(0, _utils.removeNulls)(nonEmptySanzioni[0]),
        ...creaVerbale(nonEmptySanzioni.slice(1), verbale)
      }
    };
  }
};

const extractDecoratoreGenerico = sanzione => (0, _mreduceMap.default)(_Assign.default, (0, _applyTo.default)(sanzione), [(0, _object.extractPropStringOrEmptyString)('commandName'), (0, _object.extractPropBooleanOrFalse)('applicabile'), (0, _object.extractPropNonEmptyStringOrNull)('descrizione'), (0, _object.extractPropNonEmptyStringOrNull)('codiceVeloce'), (0, _object.extractPropNonEmptyStringOrNull)('motivazioneMancataApplicazioneId'), (0, _object.extractPropNonEmptyStringOrNull)('motivazioneMancataApplicazioneLibera')]); // RIMOZIONE


exports.extractDecoratoreGenerico = extractDecoratoreGenerico;

const extractDecoratoreNonApplicato = rimozione => (0, _mreduceMap.default)(_Assign.default, (0, _applyTo.default)(rimozione), [(0, _object.extractPropStringOrEmptyString)('commandName'), (0, _object.extractPropBooleanOrFalse)('applicabile'), (0, _object.extractPropNonEmptyStringOrNull)('motivazioneMancataApplicazioneId'), (0, _object.extractPropNonEmptyStringOrNull)('motivazioneMancataApplicazioneLibera')]);

exports.extractDecoratoreNonApplicato = extractDecoratoreNonApplicato;

const extractRimozioneApplicata = rimozione => (0, _mreduceMap.default)(_Assign.default, (0, _applyTo.default)(rimozione), [(0, _object.extractPropStringOrEmptyString)('commandName'), (0, _object.extractPropBooleanOrFalse)('applicabile'), (0, _object.extractPropNonEmptyStringOrNull)('motivazione'), (0, _object.extractPropBooleanOrFalse)('rimozioneEffettuata'), (0, _object.extractPropDateToNumberOrNull)('rimozioneDataOra'), (0, _object.extractPropDateToNumberOrNull)('rimozioneAccertataIl'), (0, _object.extractPathNonEmptyStringOrNull)(['rimozioneCarroAttrezzi', 'aggId'], 'rimozioneCarroAttrezziId'), (0, _object.extractPropNonEmptyStringOrNull)('rimozioneNomeCustode'), (0, _object.extractPathNonEmptyStringOrNull)(['rimozioneDeposito', 'aggId'], 'rimozioneDepositoId'), (0, _object.extractPropNonEmptyStringOrNull)('rimozioneLuogoCustodia'), (0, _object.extractPropBooleanOrFalse)('rimozioneFurto'), (0, _object.extractPropNumberOrNull)('rimozioneImporto'), (0, _object.extractPropNonEmptyStringOrNull)('rimozioneAnnotazioni'), (0, _object.extractPropNonEmptyStringOrNull)('rimozioneDanniCausati'), (0, _object.extractPropNonEmptyStringOrNull)('descrizione')]);

const extractRimozioneToApi = rimozione => !!rimozione.applicabile ? extractRimozioneApplicata(rimozione) : extractDecoratoreNonApplicato(rimozione); // FERMO E SEQUESTRO


exports.extractRimozioneToApi = extractRimozioneToApi;

const extractFermoSequestroNonApplicata = fermoSequestro => (0, _mreduceMap.default)(_Assign.default, (0, _applyTo.default)(fermoSequestro), [(0, _object.extractPropStringOrEmptyString)('commandName'), (0, _object.extractPropBooleanOrFalse)('applicabile'), (0, _object.extractPropNonEmptyStringOrNull)('motivazioneMancataApplicazioneId'), (0, _object.extractPropNonEmptyStringOrNull)('motivazioneMancataApplicazioneLibera')]);

exports.extractFermoSequestroNonApplicata = extractFermoSequestroNonApplicata;

const extractFermoSequestroApplicato = fermoSequestro => (0, _mreduceMap.default)(_Assign.default, (0, _applyTo.default)(fermoSequestro), [(0, _object.extractPropStringOrEmptyString)('commandName'), (0, _object.extractPropBooleanOrFalse)('applicabile'), (0, _object.extractPropNonEmptyStringOrNull)('affidatario'), (0, _object.extractPropNonEmptyStringOrNull)('custode'), (0, _object.extractPropDateToNumberOrNull)('dataOperazione'), (0, _object.extractPropDateToNumberOrNull)('dataScadenza'), (0, _object.extractPathNonEmptyStringOrNull)(['deposito', 'aggId'], 'depositoId'), (0, _object.extractPropNonEmptyStringOrNull)('descrizione'), (0, _object.extractPropNonEmptyStringOrNull)('luogoCustodia'), (0, _object.extractPropNonEmptyStringOrNull)('tipo'), (0, _object.extractPropNonEmptyStringOrNull)('trasportoACuraDi'), (0, _object.extractPropNonEmptyStringOrNull)('trasportoEventualiDanni'), (0, _object.extractPropNumberOrNull)('veicoloKm'), (0, _object.extractPropNonEmptyStringOrNull)('veicoloOggettiRinvenuti'), (0, _object.extractPropNonEmptyStringOrNull)('veicoloStato')]);

exports.extractFermoSequestroApplicato = extractFermoSequestroApplicato;

const extractFermoSequestroToApi = fermoSequestro => !!fermoSequestro.applicabile ? extractFermoSequestroApplicato(fermoSequestro) : extractFermoSequestroNonApplicata(fermoSequestro); // VELOCITA


exports.extractFermoSequestroToApi = extractFermoSequestroToApi;

const extractVelocitaToApi = velocita => (0, _mreduceMap.default)(_Assign.default, (0, _applyTo.default)(velocita), [(0, _object.extractPropStringOrEmptyString)('commandName'), (0, _object.extractPathNonEmptyStringOrNull)(['direzione', 'aggId'], 'direzioneId'), (0, _object.extractPropNumberOrNull)('numeroFotogramma'), (0, _object.extractPropNonEmptyStringOrNull)('rilevatoreId'), (0, _object.extractPropBooleanOrFalse)('speseSviluppo'), (0, _object.extractPropNumberOrNull)('velocitaAccertata'), (0, _object.extractPropNumberOrNull)('velocitaConsentita'), (0, _object.extractPropNumberOrNull)('velocitaDistanza'), (0, _object.extractPropNumberOrNull)('velocitaSuperata')]); // ROSSO


exports.extractVelocitaToApi = extractVelocitaToApi;

const extractRossoToApi = rosso => !!rosso.applicabile ? extractRossoApplicata(rosso) : extractRossoNonApplicato(rosso);

exports.extractRossoToApi = extractRossoToApi;

const extractRossoApplicata = rosso => (0, _mreduceMap.default)(_Assign.default, (0, _applyTo.default)(rosso), [(0, _object.extractPropStringOrEmptyString)('commandName'), (0, _object.extractPathNonEmptyStringOrNull)(['direzione', 'aggId'], 'direzioneId'), (0, _object.extractPropNumberOrNull)('numeroFotogramma'), (0, _object.extractPropNumberOrNull)('distanza'), (0, _object.extractPropNonEmptyStringOrNull)('rilevatoreId'), (0, _object.extractPropBooleanOrFalse)('speseSviluppo')]);

exports.extractRossoApplicata = extractRossoApplicata;

const extractRossoNonApplicato = rosso => (0, _mreduceMap.default)(_Assign.default, (0, _applyTo.default)(rosso), [(0, _object.extractPropStringOrEmptyString)('commandName'), (0, _object.extractPropBooleanOrFalse)('applicabile'), (0, _object.extractPropNonEmptyStringOrNull)('motivazioneMancataApplicazioneId'), (0, _object.extractPropNonEmptyStringOrNull)('motivazioneMancataApplicazioneLibera')]);

exports.extractRossoNonApplicato = extractRossoNonApplicato;
const verbaleEmptyForm = {
  aggId: null,
  numero: '',
  statoVerbale: '',
  tipoAccertamento: null,
  tipoAccertamentoId: '',
  dataOraVerbale: null,
  dataOraVerbalizzazione: null,
  dataOraDecorrenza: null,
  dataOraVerbaleEqualsDataOraViolazione: true,
  luogoAccertamento: {
    luogoAccertamentoComune: null,
    luogoAccertamentoComuneId: '',
    luogoAccertamentoStrada: null,
    luogoAccertamentoStradaId: '',
    luogoAccertamentoCivico: '',
    luogoAccertamentoKilometro: ''
  },
  tipoRelazioneStrada: {
    luogoAccertamentoTipoRelazioneStradaTipo: '',
    luogoAccertamentoTipoRelazioneStradaStrada: null,
    luogoAccertamentoTipoRelazioneStradaStradaId: '',
    luogoAccertamentoTipoRelazioneStradaCivico: '',
    luogoAccertamentoTipoRelazioneDescrizioneFronte: ''
  },
  pedone: false,
  veicolo: {
    veicoloTipo: null,
    veicoloTipoId: '',
    veicoloTarga: '',
    veicoloStato: null,
    veicoloStatoId: '',
    veicoloMarca: '',
    veicoloModello: '',
    veicoloPortata: '',
    veicoloMassa: '',
    veicoloNrTelaio: ''
  },
  rimorchio: {
    veicoloRimorchioPresente: false,
    veicoloRimorchioMarca: '',
    veicoloRimorchioMassa: '',
    veicoloRimorchioModello: '',
    veicoloRimorchioNrTelaio: '',
    veicoloRimorchioPortata: '',
    veicoloRimorchioTarga: ''
  },
  motivoMancataContestazioneId: '',
  motivazioneMancataContestazioneDescrizioneStampa: [],
  modelloStampa: [],
  infrazioni: [],
  sanzioni: {
    fermoSequestro: null,
    rimozione: null,
    rosso: null,
    senzaDocumenti: null,
    velocita: null,
    ztl: null,
    generico: []
  },
  trasgressore: {
    trasgressore: null,
    trasgressoreTipo: '',
    trasgressoreAggId: '',
    trasgressoreNeoPatentato: false,
    trasgressoreObbligatoInSolido: true,
    notificatoTrasgressore: true,
    trasgressoreDataNotifica: ''
  },
  documentiPresentati: [],
  decurtazionePuntiApplicataDocumentoId: [],
  dichiarazioniTrasgressore: '',
  obbligatoUgualeTrasgressore: false,
  obbligato: {
    obbligato: null,
    obbligatoAggId: '',
    obbligatoTipo: '',
    notificatoObbligato: true,
    obbligatoDataNotifica: ''
  },
  spedizioni: []
};
exports.verbaleEmptyForm = verbaleEmptyForm;
const fermoSequestroEmptyForm = {
  applicabile: false,
  affidatario: "",
  custode: "",
  dataOperazione: null,
  dataScadenza: null,
  deposito: null,
  depositoId: "",
  descrizione: "",
  luogoCustodia: "",
  tipo: "",
  trasportoACuraDi: "",
  trasportoEventualiDanni: "",
  veicoloKm: "",
  veicoloOggettiRinvenuti: "",
  veicoloStato: "",
  motivazioneMancataApplicazioneId: "",
  motivazioneMancataApplicazioneLibera: ""
};
exports.fermoSequestroEmptyForm = fermoSequestroEmptyForm;
const rimozioneEmptyForm = {
  applicabile: false,
  motivazione: "",
  rimozioneEffettuata: true,
  rimozioneDataOra: null,
  rimozioneCarroAttrezzi: null,
  rimozioneCarroAttrezziId: "",
  rimozioneNomeCustode: "",
  rimozioneDeposito: null,
  rimozioneDepositoId: "",
  rimozioneLuogoCustodia: "",
  rimozioneFurto: false,
  rimozioneImporto: "",
  rimozioneAnnotazioni: "",
  rimozioneDanniCausati: "",
  descrizione: "",
  motivazioneMancataApplicazioneId: "",
  motivazioneMancataApplicazioneLibera: ""
};
exports.rimozioneEmptyForm = rimozioneEmptyForm;
const velocitaEmptyForm = {
  direzione: null,
  direzioneId: "",
  numeroFotogramma: "",
  rilevatore: null,
  rilevatoreId: "",
  speseSviluppo: "",
  velocitaAccertata: "",
  velocitaConsentita: "",
  velocitaDistanza: "",
  velocitaSuperata: ""
};
exports.velocitaEmptyForm = velocitaEmptyForm;

const extractBolletta = values => (0, _mreduceMap.default)(_Assign.default, (0, _applyTo.default)(values), [(0, _object.extractPropNumberOrNull)('numero'), (0, _object.extractPathNonEmptyStringOrNull)(["tipoAccertamento", "aggId"], "tipoAccertamentoAggId"), (0, _object.extractPropDateToNumberOrNull)('dataOraVerbale'), (0, _object.extractPathNonEmptyStringOrNull)(["luogoAccertamento", "luogoAccertamentoComune", "aggId"], "comuneAggId"), (0, _object.extractPropNonEmptyStringOrNull)('aggId')]);

exports.extractBolletta = extractBolletta;
const requiredFieldsVerbaleLabelMap = {
  bollettaIsFree: "Bolletta",
  numero: "Numero verbale",
  tipoAccertamento: "Tipo accertamento",
  dataOraVerbale: "Data ora violazione",
  dataOraVerbalizzazione: "Data ora verbalizzazione",
  luogoAccertamentoComune: "Comune",
  luogoAccertamentoStrada: "Strada",
  luogoAccertamentoTipoRelazioneStradaStrada: "Strada tipo relazione strada",
  luogoAccertamentoTipoRelazioneDescrizioneFronte: "Descrizione tipo relazione strada",
  trasgressoreDataNotifica: "Data notifica trasgressore",
  trasgressoreTipo: "Tipo trasgressore",
  veicoloTipo: "Tipo veicolo",
  veicoloStato: "Stato veicolo",
  veicoloTarga: "Targa veicolo",
  veicoloRimorchioTarga: "Targa rimorchio",
  obbligatoTipo: "Tipo obbligato",
  obbligatoDataNotifica: "Data notifica obbligato",
  infrazioni: "Infrazioni",
  modelloStampa: "Modello di stampa",
  dataPresentazione: "Data presentazione documento"
};
exports.requiredFieldsVerbaleLabelMap = requiredFieldsVerbaleLabelMap;