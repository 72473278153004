"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _styles = require("@material-ui/core/styles");

var _Error = _interopRequireDefault(require("@material-ui/icons/Error"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const ErrorIcon = (0, _styles.withStyles)(({
  palette
}) => ({
  root: {
    color: palette.error.main
  }
}))(_Error.default);
var _default = ErrorIcon;
exports.default = _default;