"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.motivazioniMancataApplicazioneListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const motivazioniMancataApplicazioneListColumns = [{
  field: 'codiceVeloce',
  headerName: 'Codice veloce',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'nome',
  headerName: 'Nome',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'descrizioneStampa',
  headerName: 'Descrizione di stampa',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.motivazioniMancataApplicazioneListColumns = motivazioniMancataApplicazioneListColumns;