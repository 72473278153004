"use strict";

var _SoggettoEditRoot = _interopRequireDefault(require("./edit/SoggettoEditRoot"));

var _SoggettiMasterDetail = _interopRequireDefault(require("./masterDetail/SoggettiMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.soggetti').provider('soggettiRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'soggettiList',
    url: 'soggetti?{page:int}&{limit:int}&{query:string}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    params: {
      reloadPreviousState: false,
      open: false
    },
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    role: 'ROLE_ANAGRAFICA_RICERCA',
    views: {
      $default: {
        component: _SoggettiMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Soggetti"
    }
  }, {
    name: 'soggettiEdit',
    url: 'soggetti/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_ANAGRAFICA_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _SoggettoEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita soggetto"
    }
  }];
  routesProviderFactory.extend(this);
}