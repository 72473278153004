"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('ValoreGlobale', ['restmod', function (restmod) {
    return restmod.model('valoreGlobale').mix({
      $extend: {
        Scope: {
          $config: {
            baseEntityPath: 'valori-globali/',
            api: {
              listaSezioni: {
                url: 'globale/lista/sezioni-valori-globali',
                method: 'GET'
              },
              dettaglioSezione: {
                url: 'leggi',
                method: 'GET'
              },
              aggiorna: {
                url: 'comando/aggiorna',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', {
      $extend: {
        Scope: {},
        Resource: {
          $getListaSezioni: function () {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('listaSezioni', false);
              this.$dispatch('before-get-lista-sezioni', [request]);
              this.$send(request, function (_response) {
                // this['$sections'] = _response.data;
                this.$dispatch('after-get-lista-sezioni', [_response]);
              }, function (_response) {
                this.$dispatch('after-get-lista-sezioni-error', [_response]);
              });
            });
          },
          $getDettaglioSezione: function (_params) {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('dettaglioSezione', true);
              request.params = Object.assign({}, {
                sezione: _params.sezione
              }, _params);
              this.$dispatch('before-get-dettaglio-sezione', [request]);
              this.$send(request, function (_response) {
                if (R.keys(this.$cmStatus).length) {
                  var keys = this.$getModelKeys(),
                      me = this;
                  R.forEach(function (key) {
                    delete me[key];
                  }, keys);
                }

                this.$unwrap(_response.data);
                this.$dispatch('after-get-dettaglio-sezione', [_response]);
              }, function (_response) {
                this.$dispatch('after-get-dettaglio-sezione-error', [_response]);
              });
            });
          },
          $aggiornaValoreGlobale: function (_params) {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('aggiorna', true);
              request.data = Object.assign({}, _params);
              this.$dispatch('before-aggiorna-valore-globale', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-aggiorna-valore-globale', [_response]);
              }, function (_response) {
                this.$dispatch('after-aggiorna-valore-globale-error', [_response]);
              });
            });
          }
        },
        Collection: {},
        Record: {}
      }
    });
  }]);
})();