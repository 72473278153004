"use strict";

var _utils = require("../../../utils");

angular.module('polcity.acquisizioneDati').component('immaginiVersamentoModal', {
  templateUrl: 'acquisizioneDati/versamenti/immaginiVersamentoModal/immaginiVersamentoModal.component.html',
  controller: 'immaginiVersamentoModalController'
}).controller('immaginiVersamentoModalController', ['$scope', '$mdDialog', ImmaginiVersamentoModalController]);

function ImmaginiVersamentoModalController($scope, $mdDialog) {
  var me = this;

  me.$onInit = function () {};

  $scope.closeImmaginiVersamentoDialog = function () {
    $mdDialog.hide();
  };
}