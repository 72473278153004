"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.motivazioneMancataApplicazioneValidationSchema = exports.toApiMotivazioneMancataApplicazione = exports.fromApiMotivazioneMancataApplicazione = exports.motivazioneMancataApplicazioneEmptyForm = exports.listaMotivazioniMancataApplicazione = void 0;

var _recoil = require("recoil");

var _api = require("../api");

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

const listaMotivazioniMancataApplicazione = (0, _recoil.selector)({
  key: "listaMotivazioniMancataApplicazione",
  get: function () {
    var _ref = _asyncToGenerator(function* () {
      const response = yield (0, _api.listaMotivazioniMancataApplicazione)();

      if (response.error) {
        throw response.error;
      }

      return response;
    });

    return function get() {
      return _ref.apply(this, arguments);
    };
  }()
});
exports.listaMotivazioniMancataApplicazione = listaMotivazioniMancataApplicazione;
const motivazioneMancataApplicazioneEmptyForm = {
  codiceVeloce: "",
  nome: "",
  descrizioneStampa: []
};
exports.motivazioneMancataApplicazioneEmptyForm = motivazioneMancataApplicazioneEmptyForm;

const fromApiMotivazioneMancataApplicazione = remoteSanzione => {
  const sanzione = { ...(remoteSanzione.aggId ? {
      aggId: remoteSanzione.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(remoteSanzione),
    nome: (0, _object.getPathStringOrEmptyString)(["nome"])(remoteSanzione),
    descrizioneStampa: (0, _object.getPathArrayOrEmptyArray)(["descrizioneStampa"])(remoteSanzione),
    descrizioneStampa_transId: (0, _object.getPathStringOrEmptyString)(["descrizioneStampa_transId"])(remoteSanzione)
  };
  return sanzione;
};

exports.fromApiMotivazioneMancataApplicazione = fromApiMotivazioneMancataApplicazione;

const toApiMotivazioneMancataApplicazione = values => {
  const sanzione = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(values),
    nome: (0, _object.getPathStringOrEmptyString)(["nome"])(values),
    descrizioneStampa: (0, _object.getPathArrayOrEmptyArray)(["descrizioneStampa"])(values),
    descrizioneStampa_transId: (0, _object.getPathStringOrEmptyString)(["descrizioneStampa_transId"])(values)
  };
  return sanzione;
};

exports.toApiMotivazioneMancataApplicazione = toApiMotivazioneMancataApplicazione;
const motivazioneMancataApplicazioneValidationSchema = Yup.object().shape({
  codiceVeloce: Yup.string().required(_utils.requiredMessage),
  nome: Yup.string().required(_utils.requiredMessage),
  descrizioneStampa: Yup.array().ensure().min(1, _utils.requiredMessage).of(Yup.object().shape({
    traduzione: Yup.string().required(_utils.requiredMessage),
    languageTag: Yup.string().required(_utils.requiredMessage)
  }))
});
exports.motivazioneMancataApplicazioneValidationSchema = motivazioneMancataApplicazioneValidationSchema;