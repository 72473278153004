"use strict";

/**
 * Created by dan on 13/01/17.
 */
(function () {
  'use strict';

  angular.module('polcity.dashboard').controller('QuickLinkDialogController', ['$mdDialog', 'uiEvents', '$scope', '$timeout', 'navigationKnownRoutes', //'localStorageQuickLinks',
  'activeLinks', QuickLinkDialogController]);

  function QuickLinkDialogController($mdDialog, uiEvents, $scope, $timeout, navigationKnownRoutes,
  /* localStorageQuickLinks, */
  activeLinks) {
    var quickLinks = this,
        activeLinks = activeLinks,
        //JSON.parse(localStorage.getItem(localStorageQuickLinks)) || {},
    uniqueProperty = 'label';
    quickLinks.undo = undo;
    quickLinks.submit = submit;
    quickLinks.toggle = toggle;
    quickLinks.active = active;
    quickLinks.requestInProgress = true;
    quickLinks.items = [
      /*{
          icon: 'notes',
          label: 'Verbali',
          state: 'verbali',
          params: {}
      },
      {
          icon: 'playlist_add',
          label: 'Nuovo Verbale',
          state: 'verbali-new',
          params: {}
      },
      {
          icon: 'account_balance',
          label: 'Ricorsi',
          state: 'ricorsi',
          params: {}
      },
      {
          icon: 'email',
          label: 'Lotti',
          state: 'lotti',
          params: {}
      },
      {
          icon: 'add',
          label: 'ciao',
          state: 'lotto',
          params: {aggId: 0}
      }*/
    ];
    navigationKnownRoutes.list().then(showQuickLinks, null, R.pipe(showQuickLinks, hideLoader)).finally(hideLoader);
    $scope.$emit(uiEvents.publishRoutes);

    function showQuickLinks(navigationQuickLinks) {
      quickLinks.items = navigationQuickLinks;
    }

    function toggle(item) {
      if (active(item)) {
        activeLinks = R.omit([item[uniqueProperty]], activeLinks);
      } else {
        activeLinks[item[uniqueProperty]] = item;
      }

      persistActiveLinks();
    }

    function active(item) {
      return R.has(item[uniqueProperty])(activeLinks);
    }

    function undo() {
      $mdDialog.cancel();
    }

    function submit() {
      quickLinks.requestInProgress = true;
      persistActiveLinks();
      $mdDialog.hide();
    }

    function hideLoader() {
      quickLinks.requestInProgress = false;
    }

    function persistActiveLinks() {//localStorage.setItem(localStorageQuickLinks, JSON.stringify(activeLinks));
    }
  }
})();