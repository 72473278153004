"use strict";

(function () {
  'use strict';

  angular.module('osTableComponent').directive('widgetcolumn', [directiveFn]);

  function directiveFn() {
    return {
      restrict: 'E',
      template: '',
      link: postLink,
      //require: ['ngModel'],
      controller: ['$scope', '$element', '$compile', function (scope, element, $compile) {
        var me = this,
            config = scope.cellwidget,
            //nuovo scope non isolato con parent quello passato come argomento
        directiveScope = scope.$new(false, scope.cellwidget.scope),
            directive,
            properties = []; //Directive name / type

        directive = scope.cellwidget.directive; //Directive Params

        for (var param in scope.cellwidget.bind) {
          properties.push(param + '="' + scope.cellwidget.bind[param] + '"');
        } //Add current record to injected scope


        directiveScope.record = scope.record; //properties.push('record="' + scope.record +'"');

        var el = $compile("<" + directive + " " + properties.join(" ") + "\"></" + directive + ">")(directiveScope);
        element.append(el);
      }],
      scope: {
        cellwidget: '=',
        record: '='
      }
    };

    function postLink(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0];
    }
  }
})();