"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLastInsertedElement = void 0;
angular.module('polcity.services').factory('lastInsertedElementsService', [LastInsertedElementsService]);
let lastInsertedElements = [];

const updateLastInsertedElement = _element => {
  // Se non ho un elemento da inserire esco
  if (!_element) {
    console.error('Provide a valid element to insert');
    return;
  } // Se l'array con gli ultimi elementi è vuoto o
  // se non ho una secion come quella dell'elemento che voglio inserire lo inserisco direttamente


  if (!lastInsertedElements.length || !lastInsertedElements.filter(elem => elem.section === _element.section).length) {
    lastInsertedElements.push(_element);
  } else {
    // Vado a sostituire l'elemento presente con il nuovo elemento
    lastInsertedElements.splice(R.findIndex(R.propEq('section', _element.section))(lastInsertedElements), 1);
    lastInsertedElements.push(_element);
  }
};

exports.updateLastInsertedElement = updateLastInsertedElement;

function LastInsertedElementsService() {
  return {
    updateLastInsertedElement: updateLastInsertedElement,
    retrieveLastInsertedElement: retrieveLastInsertedElement,
    deleteLastInsertedElement: deleteLastInsertedElement
  };

  function retrieveLastInsertedElement(_section) {
    var lastInsertedElement = {};

    if (!_section) {
      console.error('Provide a valid section');
      return;
    }

    if (!lastInsertedElements.length || !lastInsertedElements.filter(elem => elem.section === _section).length) {
      lastInsertedElement = {};
    } else {
      lastInsertedElement = lastInsertedElements.filter(elem => elem.section === _section);
    }

    return lastInsertedElement;
  }

  function deleteLastInsertedElement(_aggId) {
    // Se non ho un elemento da eliminare esco
    if (!_aggId) {
      console.error('Provide a valid aggId to delete');
      return;
    }

    if (!lastInsertedElements.filter(elem => elem.aggId === _aggId).length) {
      return;
    } else {
      lastInsertedElements.splice(R.findIndex(R.propEq('aggId', _aggId))(lastInsertedElements), 1);
    }
  }
}