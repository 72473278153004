"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDownloadAllegatoVerbaleArchivioArmaposte = exports.useVerbaleArchivioArmaposte = exports.useRicercaVerbaliArchivioArmaposte = exports.archivioArmaposteKeys = exports._archivioArmaposteState = void 0;

var _utils = require("../../utils");

var _reactQuery = require("react-query");

var _api = require("../../api");

var _utils2 = require("../../react/reactQuery/utils");

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _utils3 = require("./utils");

var _archivioArmaposteMasterDetail = require("./archivioArmaposteMasterDetail");

var _react = require("react");

const defaultArchivioArmaposteState = {
  activeItemIdVerbale: "",
  activeFilters: []
};

const _archivioArmaposteState = (0, _recoil.atomFamily)({
  key: '_archivioArmaposteState',
  default: ({
    name,
    params
  }) => {
    return { ...defaultArchivioArmaposteState,
      ...params
    };
  }
});

exports._archivioArmaposteState = _archivioArmaposteState;
const archivioArmaposteKeys = {
  all: () => [_archivioArmaposteMasterDetail.archivioArmaposteMasterDetailName],
  detail: idVerbale => [...archivioArmaposteKeys.all(), "detail", ...(0, _utils.insertIf)(idVerbale, {
    idVerbale
  })],
  quickSearch: params => [...archivioArmaposteKeys.all(), "quickSearch", ...(0, _utils.insertIf)(params, params)]
};
exports.archivioArmaposteKeys = archivioArmaposteKeys;

const useRicercaVerbaliArchivioArmaposte = ({
  activeItemIdVerbale: activeItemIdVerbaleProp,
  activeFilters: activeFiltersProp
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const [{
    activeItemIdVerbale,
    activeFilters
  }, setState] = (0, _recoil.useRecoilState)(_archivioArmaposteState({
    activeItemIdVerbaleProp,
    activeFiltersProp
  }));
  const {
    data,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(archivioArmaposteKeys.quickSearch({
    activeFilters
  }), ({
    queryKey
  }) => {
    const {
      activeFilters
    } = queryKey[2];
    const filters = activeFilters.length ? activeFilters.reduce((acc, f) => ({ ...acc,
      [f.name]: f.value
    }), {}) : null;
    return (0, _api.ricercaArchivioArmaposte)({
      idVerbale: filters.idVerbale,
      targa: filters.targa
    });
  }, {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    enabled: Boolean(activeFilters.length),
    onSuccess: response => (0, _utils2.handleOnSuccessQuery)(messenger)({
      response
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response)
  });
  (0, _react.useEffect)(() => {
    window.$transitions.onExit({
      exiting: "navigation.archivioArmaposteList"
    }, (transition, state) => {
      setState(defaultArchivioArmaposteState);
      queryClient.removeQueries(archivioArmaposteKeys.all());
    });
  });
  return {
    items: Boolean(data) && !data.error ? (0, _utils3.fromApiRicercaVerbaiArchivioArmaposte)(data.elenco) : [],
    count: Boolean(data) && !data.error ? data.count : 0,
    isLoading: isFetching || isLoading,
    activeFilters,
    addFilter: ({
      name,
      label,
      value
    }) => setState(state => ({ ...state,
      activeFilters: [...state.activeFilters, {
        name,
        label,
        value
      }],
      activeItemIdVerbale: ""
    })),
    removeFilter: name => setState(state => ({ ...state,
      activeFilters: state.activeFilters.filter(f => f.name != name),
      activeItemIdVerbale: ""
    })),
    activeItemIdVerbale,
    setActiveItemIdVerbale: activeItemIdVerbale => setState(state => ({ ...state,
      activeItemIdVerbale: activeItemIdVerbale
    }))
  };
};

exports.useRicercaVerbaliArchivioArmaposte = useRicercaVerbaliArchivioArmaposte;

const useVerbaleArchivioArmaposte = idVerbale => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(archivioArmaposteKeys.detail(idVerbale), ({
    queryKey
  }) => (0, _api.dettaglioArchivioArmaposte)(queryKey[2].idVerbale), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(idVerbale),
    onSuccess: response => (0, _utils2.handleOnSuccessQuery)(messenger)({
      response
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    select: response => response.error ? response : (0, _utils3.fromApiVerbaleArchivioArmaposte)(response)
  });
  return {
    verbale: data,
    isLoading: isFetching || isLoading
  };
};

exports.useVerbaleArchivioArmaposte = useVerbaleArchivioArmaposte;

const useDownloadAllegatoVerbaleArchivioArmaposte = ({
  onFailure,
  onSuccess
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Allegato scaricato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(idAllegato => (0, _api.downloadAllegatoVerbaleArchivioArmaposte)(idAllegato), {
    onSuccess: response => (0, _utils2.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response)
  });
};

exports.useDownloadAllegatoVerbaleArchivioArmaposte = useDownloadAllegatoVerbaleArchivioArmaposte;