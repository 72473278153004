"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gestioneDelTerritorioListColumns = void 0;

var _react = _interopRequireDefault(require("react"));

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

var _utils = require("../../../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const gestioneDelTerritorioListColumns = [{
  field: 'codiceVeloce',
  headerName: 'Codice veloce',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'nome',
  headerName: 'Nome',
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'enteTerritoriale',
  headerName: 'Ente territoriale',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _utils.capitalize)(value.tipoEnteTerritoriale) + ' ' + value.nome : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'codiceBelfiore',
  headerName: 'Codice belfiore',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'codiceSANA',
  headerName: 'Codice SANA',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'codiceISTAT',
  headerName: 'Codice istat',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'codiceFlussoCNC',
  headerName: 'Codice flusso CNC',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.gestioneDelTerritorioListColumns = gestioneDelTerritorioListColumns;