"use strict";

/**
 * Created by MN 12/02/2020
 */
(function () {
  'use strict';

  angular.module('polcity.widgetTypes').component('listWidgetComponent', {
    templateUrl: 'dashboard/widgetTypes/list/list.widget.component.html',
    controller: 'ListWidgetComponentController',
    controllerAs: '$ctrl',
    bindings: {
      listWidgetData: '<'
    }
  }).controller('ListWidgetComponentController', ['$scope', ListWidgetComponentController]);

  function ListWidgetComponentController($scope) {
    var me = this;
    me.noDataAvaiable = false;
    $scope.$watch(() => me.listWidgetData.data, function (newVal, oldVal) {
      if (newVal) {
        onInit();
      }
    }, true);
    me.$onInit = onInit;

    function onInit() {
      if (!me.listWidgetData) {
        return;
      }

      if (!me.numberWidgetData || !me.numberWidgetData.data || !me.numberWidgetData.data.length) {
        me.noDataAvaiable = true;
      }
    }
    /**
     * Handlers
     */


    me.onRemoveWidget = function (_widget) {
      $scope.$emit('remove-widget', _widget);
    };
  }
})();