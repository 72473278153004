"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.soggettoEmptyForm = exports.toApiSoggetto = exports.fromApiSoggetto = void 0;

var _object = require("../../object.utils");

const fromApiSoggetto = remoteSoggetto => {
  const soggetto = { ...(remoteSoggetto.aggId ? {
      aggId: remoteSoggetto.aggId
    } : {}),
    tipoAnagrafica: (0, _object.getPathStringOrEmptyString)(["tipoAnagrafica"])(remoteSoggetto),
    cfPi: (0, _object.getPathStringOrEmptyString)(["cfPi"])(remoteSoggetto),
    nome: (0, _object.getPathStringOrEmptyString)(["nome"])(remoteSoggetto),
    cognome: (0, _object.getPathStringOrEmptyString)(["cognome"])(remoteSoggetto),
    dataNascita: (0, _object.getPathNumberToDateOrNull)(["dataNascita"])(remoteSoggetto),
    comuneNascita: (0, _object.getPathStringOrNull)(["comuneNascita", "comuneId"])(remoteSoggetto) ? {
      riferimentoComuneId: (0, _object.getPathStringOrEmptyString)(["comuneNascita", "comuneId"])(remoteSoggetto),
      nomeCompleto: (0, _object.getPathStringOrEmptyString)(["comuneNascita", "nomeCompleto"])(remoteSoggetto),
      nomeRiferimentoComune: (0, _object.getPathStringOrEmptyString)(["comuneNascita", "nome"])(remoteSoggetto),
      stato: {
        nome: (0, _object.getPathStringOrEmptyString)(["comuneNascita", "nomeStato"])(remoteSoggetto)
      },
      enteTerritoriale: {
        siglaEnteTerritoriale: (0, _object.getPathStringOrEmptyString)(["comuneNascita", "enteTerritoriale", "sigla"])(remoteSoggetto)
      }
    } : null,
    sesso: (0, _object.getPathStringOrEmptyString)(["sesso"])(remoteSoggetto),
    indirizzoResidenza: (0, _object.getPathStringOrEmptyString)(["indirizzoResidenza"])(remoteSoggetto),
    civicoResidenza: (0, _object.getPathStringOrEmptyString)(["civicoResidenza"])(remoteSoggetto),
    comuneResidenza: (0, _object.getPathStringOrNull)(["comuneResidenza", "comuneId"])(remoteSoggetto) ? {
      riferimentoComuneId: (0, _object.getPathStringOrEmptyString)(["comuneResidenza", "comuneId"])(remoteSoggetto),
      nomeRiferimentoComune: (0, _object.getPathStringOrEmptyString)(["comuneResidenza", "nomeCompleto"])(remoteSoggetto),
      nomeStato: (0, _object.getPathStringOrEmptyString)(["comuneResidenza", "nomeStato"])(remoteSoggetto),
      stato: {
        nome: (0, _object.getPathStringOrEmptyString)(["comuneResidenza", "nomeStato"])(remoteSoggetto)
      }
    } : null,
    capResidenza: (0, _object.getPathStringOrEmptyString)(["capResidenza"])(remoteSoggetto),
    indirizzoDomicilio: (0, _object.getPathStringOrEmptyString)(["indirizzoDomicilio"])(remoteSoggetto),
    civicoDomicilio: (0, _object.getPathStringOrEmptyString)(["civicoDomicilio"])(remoteSoggetto),
    comuneDomicilio: (0, _object.getPathStringOrNull)(["comuneDomicilio", "comuneId"])(remoteSoggetto) ? {
      riferimentoComuneId: (0, _object.getPathStringOrEmptyString)(["comuneDomicilio", "comuneId"])(remoteSoggetto),
      nomeRiferimentoComune: (0, _object.getPathStringOrEmptyString)(["comuneDomicilio", "nomeCompleto"])(remoteSoggetto)
    } : null,
    capDomicilio: (0, _object.getPathStringOrEmptyString)(["capDomicilio"])(remoteSoggetto),
    telefono: (0, _object.getPathStringOrEmptyString)(["telefono"])(remoteSoggetto),
    email: (0, _object.getPathStringOrEmptyString)(["email"])(remoteSoggetto),
    pec: (0, _object.getPathStringOrEmptyString)(["pec"])(remoteSoggetto),
    ragioneSociale: (0, _object.getPathStringOrEmptyString)(["ragioneSociale"])(remoteSoggetto),
    rappresentanteLegale: (0, _object.getPathStringOrEmptyString)(["rappresentanteLegale"])(remoteSoggetto),
    indirizzoSedeLegale: (0, _object.getPathStringOrEmptyString)(["indirizzoSedeLegale"])(remoteSoggetto),
    civicoSedeLegale: (0, _object.getPathStringOrEmptyString)(["civicoSedeLegale"])(remoteSoggetto),
    capSedelegale: (0, _object.getPathStringOrEmptyString)(["capSedelegale"])(remoteSoggetto),
    comuneSedeLegale: (0, _object.getPathStringOrNull)(["comuneSedeLegale", "comuneId"])(remoteSoggetto) ? {
      riferimentoComuneId: (0, _object.getPathStringOrEmptyString)(["comuneSedeLegale", "comuneId"])(remoteSoggetto),
      nomeRiferimentoComune: (0, _object.getPathStringOrEmptyString)(["comuneSedeLegale", "nomeCompleto"])(remoteSoggetto)
    } : null,
    documenti: (0, _object.getPathArrayOrNull)(["documenti"])(remoteSoggetto),
    veicoli: (0, _object.getPathArrayOrNull)(["veicoli"])(remoteSoggetto)
  };
  return soggetto;
};

exports.fromApiSoggetto = fromApiSoggetto;

const toApiSoggetto = values => {
  let soggetto = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    tipoAnagrafica: (0, _object.getPathNonEmptyStringOrNull)(["tipoAnagrafica"])(values),
    nome: (0, _object.getPathNonEmptyStringOrNull)(["nome"])(values),
    cognome: (0, _object.getPathNonEmptyStringOrNull)(["cognome"])(values),
    sesso: (0, _object.getPathNonEmptyStringOrNull)(["sesso"])(values),
    dataNascita: (0, _object.getPathDateToNumberOrNull)(["dataNascita"])(values),
    indirizzoResidenza: (0, _object.getPathNonEmptyStringOrNull)(["indirizzoResidenza"])(values),
    civicoResidenza: (0, _object.getPathNonEmptyStringOrNull)(["civicoResidenza"])(values),
    capResidenza: (0, _object.getPathNonEmptyStringOrNull)(["capResidenza"])(values),
    indirizzoDomicilio: (0, _object.getPathNonEmptyStringOrNull)(["indirizzoDomicilio"])(values),
    civicoDomicilio: (0, _object.getPathNonEmptyStringOrNull)(["civicoDomicilio"])(values),
    capDomicilio: (0, _object.getPathNonEmptyStringOrNull)(["capDomicilio"])(values),
    indirizzoSedeLegale: (0, _object.getPathNonEmptyStringOrNull)(["indirizzoSedeLegale"])(values),
    civicoSedeLegale: (0, _object.getPathNonEmptyStringOrNull)(["civicoSedeLegale"])(values),
    capSedelegale: (0, _object.getPathNonEmptyStringOrNull)(["capSedelegale"])(values),
    ragioneSociale: (0, _object.getPathNonEmptyStringOrNull)(["ragioneSociale"])(values),
    rappresentanteLegale: (0, _object.getPathNonEmptyStringOrNull)(["rappresentanteLegale"])(values),
    telefono: (0, _object.getPathNonEmptyStringOrNull)(["telefono"])(values),
    cfPi: (0, _object.getPathNonEmptyStringOrNull)(["cfPi"])(values),
    email: (0, _object.getPathNonEmptyStringOrNull)(["email"])(values),
    pec: (0, _object.getPathNonEmptyStringOrNull)(["pec"])(values),
    comuneNascitaId: (0, _object.getPathNonEmptyStringOrNull)(["comuneNascita", "riferimentoComuneId"])(values),
    comuneResidenzaId: (0, _object.getPathNonEmptyStringOrNull)(["comuneResidenza", "riferimentoComuneId"])(values),
    comuneDomicilioId: (0, _object.getPathNonEmptyStringOrNull)(["comuneDomicilio", "riferimentoComuneId"])(values),
    comuneSedeLegaleId: (0, _object.getPathNonEmptyStringOrNull)(["comuneSedeLegale", "riferimentoComuneId"])(values)
  };
  return soggetto;
};

exports.toApiSoggetto = toApiSoggetto;
const soggettoEmptyForm = {
  aggId: null,
  tipoAnagrafica: 'persona_fisica',
  nome: '',
  cognome: '',
  sesso: 'm',
  dataNascita: null,
  indirizzoResidenza: '',
  civicoResidenza: '',
  capResidenza: '',
  indirizzoDomicilio: '',
  civicoDomicilio: '',
  capDomicilio: '',
  indirizzoSedeLegale: '',
  civicoSedeLegale: '',
  capSedelegale: '',
  ragioneSociale: '',
  rappresentanteLegale: '',
  telefono: '',
  cfPi: '',
  email: '',
  pec: '',
  comuneNascita: null,
  comuneResidenza: null,
  comuneDomicilio: null,
  comuneSedeLegale: null
};
exports.soggettoEmptyForm = soggettoEmptyForm;