"use strict";

var _LottiMasterDetail = _interopRequireDefault(require("./masterDetail/LottiMasterDetail"));

var _LottoEditRoot = _interopRequireDefault(require("./edit/LottoEditRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.lotti').provider('lottiRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'lottiList',
    role: 'ROLE_LOTTO_FASTMAIL_RICERCA',
    url: 'lotti?{page:int}&{limit:int}&{query:string}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _LottiMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Lotti"
    }
  }, {
    name: 'lottiEdit',
    url: 'lotti/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_LOTTO_MODIFICA',
    params: {
      aggId: null,
      subView: null,
      lottoVuoto: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _LottoEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita lotto"
    }
  }];
  routesProviderFactory.extend(this);
}