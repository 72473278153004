"use strict";

/**
 * Created by dan on 12/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.services').service('uiToastListener', ['uiEvents', 'Notification', '$rootScope', '$translate', 'NotificationTypes', uiToastListener]).filter('to_trusted', ['$sce', function ($sce) {
    return function (text) {
      return $sce.trustAsHtml(text);
    };
  }]);

  function uiToastListener(uiEvents, Notification, $rootScope, $translate, NotificationTypes) {
    window.addEventListener('show-custom-notification-event', function (_event) {
      showToast(_event, _event.detail);
    });
    window.addEventListener('show-custom-translated-notification-event', function (_event) {
      showNotificationFromTranslation(_event, _event.detail);
    });
    var listener = this;
    listener.beginListening = beginListening;
    return listener;

    function beginListening() {
      $rootScope.$on(uiEvents.showToast, showToast);
      $rootScope.$on(uiEvents.showNotificationFromTranslation, showNotificationFromTranslation);
    }

    function showNotificationFromTranslation($event, toastData) {
      var promise = toastData.translationParam ? $translate(toastData.translationIndex, toastData.translationParam) : $translate(toastData.translationIndex);
      promise.then(function (translation) {
        toastData.message = translation;
        showToast($event, toastData);
      }).catch(function () {
        console.error('while translating message for notification');
        console.error(arguments);
      });
    }

    function showToast($event, toastData) {
      var templateUrl = '';

      switch (toastData.type) {
        case NotificationTypes.success:
          templateUrl = 'shared/services/toast.success.view.html';
          break;

        case NotificationTypes.info:
          templateUrl = 'shared/services/toast.info.view.html';
          break;

        case NotificationTypes.loading:
          templateUrl = 'shared/services/toast.dataIsLoading.view.html';
          break;

        default:
          templateUrl = 'shared/services/toast.failure.view.html';
      }

      Notification.primary({
        message: toastData.message + '',
        templateUrl: templateUrl,
        positionY: 'top',
        positionX: 'center',
        delay: toastData.duration || 5000
      });
    }
  }
})();