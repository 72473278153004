"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.routes = void 0;

var _tdsNameCostant = require("../../tds/tabelle/tdsNameCostant");

const routes = {
  [`${_tdsNameCostant.nazioniTdsName}`]: "tdsNazioniEdit",
  [`${_tdsNameCostant.carroattrezziTdsName}`]: "tdsCarroAttrezziEdit",
  [`${_tdsNameCostant.riferimentiComuniTdsName}`]: "tdsRiferimentiComuniEdit",
  [`${_tdsNameCostant.contatoriProtocolloTdsName}`]: "tdsContatoriProtocolloEdit",
  [`${_tdsNameCostant.contenutiAllegatiTdsName}`]: "tdsContenutiAllegatiEdit",
  [`${_tdsNameCostant.depositiFermiSequestriTdsName}`]: "tdsDepositiFermiSequestriEdit",
  [`${_tdsNameCostant.entiTdsName}`]: "tdsEntiEdit",
  [`${_tdsNameCostant.entiTerritorialiTdsName}`]: "tdsEntiTerritorialiEdit",
  [`${_tdsNameCostant.funzioniStampaTdsName}`]: "tdsFunzioniStampaEdit",
  [`${_tdsNameCostant.gestioneDelTerritorioTdsName}`]: "tdsGestioneDelTerritorioEdit",
  [`${_tdsNameCostant.frazioniTdsName}`]: "tdsFrazioniEdit",
  [`${_tdsNameCostant.gestioneStradeTdsName}`]: "tdsGestioneStradeEdit",
  [`${_tdsNameCostant.gestioneContiCorrentiTdsName}`]: "tdsContiCorrenteEdit",
  [`${_tdsNameCostant.lingueTdsName}`]: "tdsLingueEdit",
  [`${_tdsNameCostant.localizzazioniTdsName}`]: "tdsLocalizzazioniEdit",
  [`${_tdsNameCostant.marcheModelliVeicoliTdsName}`]: "tdsMarcheModelliVeicoliEdit",
  [`${_tdsNameCostant.tipologieRimozioniTdsName}`]: "tdsTipologieRimozioniEdit",
  [`${_tdsNameCostant.attivitaProduttiveTdsName}`]: "tdsAttivitaProduttiveEdit",
  [`${_tdsNameCostant.direzioneMarciaVeicoliTdsName}`]: "tdsDirezioniMarciaVeicoliEdit",
  [`${_tdsNameCostant.motivazioniArchiviazioniAccertamentiTdsName}`]: "tdsMotivazioniArchiviazioniAccertamentiEdit",
  [`${_tdsNameCostant.motivazioniMancataApplicazioneTdsName}`]: "tdsMotivazioniMancataApplicazioneEdit",
  [`${_tdsNameCostant.motivazioniMancataContestazioneTdsName}`]: "tdsMotivazioniMancataContestazioneEdit",
  [`${_tdsNameCostant.relazioniNomeSessoTdsName}`]: "tdsRelazioniNomeSessoEdit",
  [`${_tdsNameCostant.rilevatoriInfrazioniTdsName}`]: "tdsRilevatoriInfrazioniEdit",
  [`${_tdsNameCostant.sanzioniAccessorieTdsName}`]: "tdsSanzioniAccessorieEdit",
  [`${_tdsNameCostant.tipiAccertamentoTdsName}`]: "tdsTipiAccertamentoEdit",
  [`${_tdsNameCostant.tipiBollettariTdsName}`]: "tdsTipiBollettariEdit",
  [`${_tdsNameCostant.tipiProprietaTdsName}`]: "tdsTipiProprietaEdit",
  [`${_tdsNameCostant.tipiVeicoloTdsName}`]: "tdsTipiVeicoloEdit",
  [`${_tdsNameCostant.leggiTdsName}`]: "tdsLeggiEdit",
  [`${_tdsNameCostant.agentiVisureTdsName}`]: "tdsAgentiVisureEdit"
};
exports.routes = routes;