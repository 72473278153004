"use strict";

/**
 * Created by gb 28/10/2019
 */
(function () {
  'use strict';

  angular.module('osLoadMask').directive('osLoadMask', ['$compile', function ($compile) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var progressCircular;
        scope.$on('$destroy', function () {
          if (progressCircular) {
            progressCircular.remove();
          }
        });
        scope.$watch(attrs.osLoadMask, function (value) {
          if (value) {
            progressCircular = angular.element(`<div style="background:white;opacity: 0.4;position: absolute; height: ${$(element).height()}px; width: ${$(element).width()}px; top: ${$(element).offset().top}px; left:${$(element).offset().left}px; z-index: 998;"></div>
                                         <div
                                            layout
                                            layout-align="center center"
                                            style="position: absolute; height: ${$(element).height()}px; width: ${$(element).width()}px; top: ${$(element).offset().top}px; left:${$(element).offset().left}px; z-index: 999;">
                                                <md-progress-circular style="width: 50px; height: 50px;" md-mode="indeterminate"></md-progress-circular>
                                         </div>`);
            progressCircular = $compile(progressCircular)(scope.$new());
            $(progressCircular).appendTo(document.body);
          } else {
            if (progressCircular) {
              progressCircular.remove();
            }
          }
        });
      }
    };
  }]);
})();