"use strict";

var _TipoProprietaEditRoot = _interopRequireDefault(require("./edit/TipoProprietaEditRoot"));

var _TipiProprietaList = _interopRequireDefault(require("./TipiProprietaList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('tipiProprietaTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsTipiProprieta',
    url: 'tds/tipiProprieta',
    role: 'ROLE_TIPO_PROPRIETA_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _TipiProprietaList.default
      }
    },
    resolve: {
      $label: () => "Tipi di proprietà"
    }
  }, {
    name: 'tdsTipiProprietaEdit',
    url: 'tds/tipiProprieta/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_TIPO_PROPRIETA_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _TipoProprietaEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita tipo di proprietà"
    }
  }];
  routesProviderFactory.extend(this);
}