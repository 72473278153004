"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var _locale = require("@material-ui/core/locale");

const azure = "#2196f3";
const osAzure = "#03a9f4"; //"#44b4f4" // '#8cb4e1';

const orange = "#FFAB40";
let theme = (0, _styles.createMuiTheme)({
  spacing: 5,
  palette: {
    type: 'light',
    primary: {
      main: osAzure,
      light: (0, _styles.lighten)(osAzure, 0.2),
      contrastText: "#fff"
    },
    secondary: {
      main: orange
    },
    tertiary: {
      main: azure
    }
  },
  toolbar: {
    maxHeight: 45
  },
  typography: {
    fontSize: 13,
    fontFamily: ['Roboto Mono', 'Helvetica Neue', 'sans-serif'].join(',')
  }
}, _locale.itIT);
theme = (0, _styles.responsiveFontSizes)(theme);
var _default = theme;
exports.default = _default;