"use strict";

var _MotivazioniArchiviazioneAccertamentiList = _interopRequireDefault(require("./MotivazioniArchiviazioneAccertamentiList"));

var _MotivazioneArchiviazioneAccertamentoEditRoot = _interopRequireDefault(require("./edit/MotivazioneArchiviazioneAccertamentoEditRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('motivazioneArchiviazioneAccertamentiTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsMotivazioniArchiviazioniAccertamenti',
    url: 'tds/motivazioneArchiviazioneAccertamenti',
    role: 'ROLE_MOTIVAZIONE_ARCHIVIAZIONE_ACCERTAMENTI_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _MotivazioniArchiviazioneAccertamentiList.default
      }
    },
    resolve: {
      $label: () => "Motivazioni archiviazioni accertamenti"
    }
  }, {
    name: 'tdsMotivazioniArchiviazioniAccertamentiEdit',
    url: 'tds/motivazioneArchiviazioneAccertamenti/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_MOTIVAZIONE_ARCHIVIAZIONE_ACCERTAMENTI_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _MotivazioneArchiviazioneAccertamentoEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita motivazione archiviazione accertamento"
    }
  }];
  routesProviderFactory.extend(this);
}