"use strict";

/**
 * Created by gb on 14/09/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.ricorso.controdeduzione').component('ricorsoControdeduzione', {
    templateUrl: 'ricorso/controdeduzione/ricorso.controdeduzione.component.html',
    controller: 'RicorsoControdeduzioneController',
    controllerAs: 'RicorsoControdeduzioneCtrl',
    bindings: {
      ricorso: '<',
      controdeduzione: '<'
    }
  });
})();