"use strict";

var _core = require("@fullcalendar/core");

var _daygrid = _interopRequireDefault(require("@fullcalendar/daygrid"));

var _timegrid = _interopRequireDefault(require("@fullcalendar/timegrid"));

var _interaction = _interopRequireDefault(require("@fullcalendar/interaction"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// for selectable
const augmentEvent = event => {};

angular.module('polcity.widgetTypes').component('calendarWidgetComponent', {
  templateUrl: 'dashboard/widgetTypes/calendar/calendar.widget.component.html',
  controller: 'CalendarWidgetComponentController',
  controllerAs: '$ctrl',
  bindings: {
    calendarWidgetData: '<'
  }
}).controller('CalendarWidgetComponentController', ['$scope', 'dashboardDataService', '$mdDialog', 'OSNotification', '$state', CalendarWidgetComponentController]);

function CalendarWidgetComponentController($scope, dashboardDataService, $mdDialog, OSNotification, $state) {
  var me = this,
      calendar;

  me.$onInit = function () {
    var osCalendar = document.getElementById('osCalendar');
    calendar = new _core.Calendar(osCalendar, {
      locale: 'it',
      contentHeight: 700,
      plugins: [_daygrid.default, _interaction.default, _timegrid.default],
      headerToolbar: {
        left: 'dayGridMonth,timeGridWeek,timeGridDay, today',
        center: 'title',
        right: 'prevYear, prev, next, nextYear'
      },
      buttonText: {
        today: 'oggi',
        month: 'mese',
        week: 'settimana',
        day: 'giorno'
      },
      eventSources: [{
        id: 'userEventsSource',
        events: (info, successCallback, failureCallback) => successCallback(me.calendarWidgetData.userEvents || [])
      }, {
        id: 'applicationEventsSource',
        events: (info, successCallback, failureCallback) => successCallback(me.calendarWidgetData.applicationEvents || [])
      }],
      selectable: true,
      select: function (startDate, endDate, jsEvent, view) {
        var newEvent = {
          type: 'userEvent',
          editable: true,
          eventStartEditable: true,
          eventDurationEditable: false,
          allDay: true,
          eventCreationDate: moment(),
          isEdit: false,
          start: startDate,
          end: endDate
        };
        showEditEventDialog(newEvent, jsEvent, view).then(function (res) {
          var newEvent = Object.assign({}, res);
          createEvent(newEvent);
        }).catch(function (e) {
          console.log('err', e);
        });
      },
      eventDidMount: function ({
        event,
        el
      }) {
        var icon;

        if (event.extendedProps.type === 'userEvent' && event.backgroundColor === '#2196f3') {
          icon = '<i class="material-icons mr5" style="font-size: 15px;">deck</i>';
        } else if (event.extendedProps.type === 'userEvent' && event.backgroundColor === '#00bcd4') {
          icon = '<i class="material-icons mr5" style="font-size: 15px;">work</i>';
        } else if (event.extendedProps.type === 'applicationEvent') {
          icon = '<i class="material-icons" style="font-size: 15px; width: 7%;">laptop_chrome</i>';
        }

        $(el).find('.fc-event-title').before(icon);
        $(el).find('.fc-event-title-container').css("display", "flex").css("align-items", "center");
      },
      eventClick: function ({
        event,
        jsEvent,
        view
      }) {
        if (event.extendedProps.type === 'applicationEvent' && !event.startEditable) {
          // showEventsListDialog(event.toPlainObject({collapseExtendedProps: true}), jsEvent);
          $state.go('navigation.verbalidetail', {
            aggId: event.extendedProps.aggId
          });
        } else if (event.extendedProps.type === 'userEvent' && event.startEditable) {
          showEditEventDialog(event.toPlainObject({
            collapseExtendedProps: true
          }), jsEvent, view).then(updateEvent).catch(function (err) {
            if (err.reason && err.reason == "delete") {
              deleteEvent(err.event.aggId, err.event);
            }
          });
        }
      },
      eventDrop: function ({
        event,
        oldEvent
      }) {
        updateEvent(event.toPlainObject({
          collapseExtendedProps: true
        }));
      },
      editable: true,
      eventResize: function ({
        event,
        oldEvent
      }) {
        updateEvent(event.toPlainObject({
          collapseExtendedProps: true
        }));
      },
      viewClassNames: function () {// called whenever the view changes
        // console.log("viewClassNames");
        //Aggiorno il testo dei bottoni "anno precedente"
        // let y = moment(calendar.getDate()).year();
        // $('.fc-prevYear-button').html('').text(y - 1);
        // $('.fc-nextYear-button').html('').text(y + 1);
      },
      dayMaxEvents: 2,
      moreLinkText: numEv => `+ ${numEv} verbali`
    });
    calendar.render();
  };
  /**
   * Watchers
   */


  $scope.$watch(() => me.calendarWidgetData, (newVal, oldVal) => {
    //Ricarico le event sources
    calendar.getEventSources().map(source => source.refetch());
  }, true);

  function updateEvent(_event) {
    _event.editable = _event.canEdit;
    dashboardDataService.editCalendarUserEvent(_event).then(dashboardDataService.fetchCalendarUserEvents).then(res => dashboardDataService.parseEvents(res.resultsWrapper.values)).then(events => {
      me.calendarWidgetData.userEvents = events;
      OSNotification.success({
        translationIndex: 'widgetTypes.calendar.eventEdited__notification'
      });
    }).catch(function (_err) {
      OSNotification.warn({
        translationIndex: 'widgetTypes.calendar.eventError__notification'
      });
    });
  }

  function createEvent(_newEvent) {
    dashboardDataService.createCalendarUserEvent(_newEvent).then(dashboardDataService.fetchCalendarUserEvents).then(res => dashboardDataService.parseEvents(res.resultsWrapper.values)).then(events => {
      me.calendarWidgetData.userEvents = events;
      OSNotification.success({
        translationIndex: 'widgetTypes.calendar.eventCreated__notification'
      });
    }).catch(function (_err) {
      OSNotification.warn({
        translationIndex: 'widgetTypes.calendar.eventError__notification'
      });
    });
  }

  function deleteEvent(_aggId, _updatedEvent) {
    dashboardDataService.deleteCalendarUserEvent(_aggId).then(dashboardDataService.fetchCalendarUserEvents).then(res => dashboardDataService.parseEvents(res.resultsWrapper.values)).then(function (events) {
      //calendar.removeEvents(_updatedEvent._id);
      me.calendarWidgetData.userEvents = events;
      OSNotification.success({
        translationIndex: 'widgetTypes.calendar.eventDeleted__notification'
      });
    }).catch(function (_err) {
      OSNotification.warn({
        translationIndex: 'widgetTypes.calendar.eventError__notification'
      });
    });
  }

  function showEventsListDialog(_event, jsEvent) {
    var events = me.calendarWidgetData.applicationEvents.filter(event => event.status === _event.status && moment(event.dataOraScadenzaVerbale).startOf('day').isSame(moment(_event.dataOraScadenzaVerbale).startOf('day')));
    return $mdDialog.show({
      templateUrl: 'dashboard/widgetTypes/calendar/eventsList/calendarEventsList.dialog.html',
      controller: 'calendarEventsListController',
      controllerAs: '$ctrl',
      parent: angular.element(document.body),
      targetEvent: jsEvent,
      locals: {
        events: events
      },
      clickOutsideToClose: true,
      multiple: false
    }).then(function (res) {}).catch(function (_err) {
      console.log(_err);
    });
  }

  function showEditEventDialog(_newEvent, _jsEvent, _view, isEdit) {
    return $mdDialog.show({
      templateUrl: 'dashboard/widgetTypes/calendar/editEventModal/addCalendarEvent.dialog.html',
      controller: 'addCalendarEventController',
      controllerAs: '$ctrl',
      parent: angular.element(document.body),
      targetEvent: _jsEvent,
      locals: {
        event: _newEvent,
        view: _view,
        isEdit
      },
      clickOutsideToClose: true,
      multiple: false
    });
  }
}