"use strict";

var _ComuneList = _interopRequireDefault(require("./ComuneList"));

var _GestioneDelTerritorioComuneEditRoot = _interopRequireDefault(require("./edit/GestioneDelTerritorioComuneEditRoot"));

var _ComuneSubTablesList = _interopRequireDefault(require("./subTableList/ComuneSubTablesList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('gestioneDelTerritorioTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsGestioneDelTerritorio',
    url: 'tds/gestioneDelTerritorio',
    role: 'ROLE_COMUNE_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ComuneList.default
      }
    },
    resolve: {
      $label: () => "Comuni"
    }
  }, {
    name: 'tdsGestioneDelTerritorioSubTableList',
    url: 'tds/gestioneDelTerritorio/{comuneId}',
    role: 'ROLE_COMUNE_RICERCA',
    params: {
      comuneId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ComuneSubTablesList.default
      }
    },
    resolve: {
      comune: ['$stateParams', 'Command', function ($stateParams, Command) {
        return Command.execute('@ricercaComuniGestioneDelTerritorio', {
          aggId: $stateParams.comuneId
        }).then(res => {
          return res.resultsWrapper.values[0];
        });
      }],
      $label: ['comune', function (comune) {
        return comune.codiceVeloce.concat(' - ', comune.nome[0].traduzione);
      }]
    }
  }, {
    name: 'tdsGestioneDelTerritorioEdit',
    url: 'tds/gestioneDelTerritorio/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_COMUNE_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _GestioneDelTerritorioComuneEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita comune"
    }
  }];
  routesProviderFactory.extend(this);
}