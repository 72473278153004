"use strict";

(function () {
  'use strict'; //Direttiva a scope "condiviso" col parent

  angular.module('osAllegati').directive('osAllegatiNavigation', [osAllegatiNavigation]);

  function osAllegatiNavigation() {
    return {
      restrict: 'E',
      controller: ['$scope', '$state', osAllegatiNavigationController],
      controllerAs: 'osAllegatiNavigationController',
      templateUrl: 'osFramework/osAllegati/navigation/osAllegatiNavigation.component.html',
      scope: true,
      link: function (scope) {}
    };

    function osAllegatiNavigationController($scope, $state) {
      var me = this;
      me.moduleRef = "navigation." + $scope.module;
      me.moduleRefText = $scope.module + ".list__titleText";
      me.allegatiTabTitleText = $scope.module + ".allegati__tabTitle";

      me.onNavEntityClick = function () {
        $state.go("navigation." + $scope.navigationItemState, {
          aggId: $scope.aggId
        });
      };
    }
  }
})();