"use strict";

/**
 * Created by MN on 18/11/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.valoriGlobali').config(['$stateProvider', 'valoriGlobaliRoutesProvider', '$translatePartialLoaderProvider', 'AuthProvider', addRoutes]).run(['valoriGlobaliRoutes', routesRun]);

  function addRoutes($stateProvider, valoriGlobaliRoutesProvider, $translatePartialLoader, AuthProvider) {
    R.forEach(function (routeDefinition) {
      var stateName = routeDefinition.isBase ? routeDefinition.name : 'navigation.' + routeDefinition.name; // TODO: scommentare quando ci saranno i ruoli

      /*if( !routeDefinition.role || AuthProvider.hasRole(routeDefinition.role) ) {
          $stateProvider
              .state(stateName, routeDefinition);
      }*/
      // TODO: eliminare quando ci saranno i ruoli

      $stateProvider.state(stateName, routeDefinition);
    }, valoriGlobaliRoutesProvider.list());
    $translatePartialLoader.addPart('valoriGlobali');
  }

  function routesRun(valoriGlobaliRoutes) {
    valoriGlobaliRoutes.subscribeUIEvents();
  }
})();