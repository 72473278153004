"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.entiTerritorialiListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const entiTerritorialiListColumns = [{
  field: 'sigla',
  headerName: 'Sigla',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'nome',
  headerName: 'Nome',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'tipoEnteTerritoriale',
  headerName: 'Tipo ente territoriale',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'stato',
  headerName: 'Stato',
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  valueGetter: ({
    value
  }) => value ? value.nome : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'enteSuperioreTipoEnte',
  headerName: 'Ente superiore',
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  valueGetter: ({
    row
  }) => row.enteSuperioreTipoEnte ? row.enteSuperioreTipoEnte.concat(' - ', row.enteSuperioreNome) : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.entiTerritorialiListColumns = entiTerritorialiListColumns;