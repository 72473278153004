"use strict";

(function () {
  'use strict';

  angular.module('osListComponent').filter('keylength', function () {
    return function (input) {
      if (!angular.isObject(input)) {
        throw Error("Usage of non-objects with keylength filter!!");
      }

      return Object.keys(input).length;
    };
  }).filter('hasItems', function () {
    return function (input) {
      if (!angular.isObject(input)) {
        throw Error("Usage of non-objects with keylength filter!!");
      }

      return Object.keys(input).length > 0;
    };
  });
})();