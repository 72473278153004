"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('ScritturaContabile', ['restmod', function (restmod) {
    return restmod.model('scritturaContabile').mix({
      $extend: {
        Scope: {
          $config: {
            name: 'scrittureContabili',
            baseEntityPath: 'contabilita/',
            api: {
              ricercaVeloceNoPag: {
                url: 'no-pag/ricerca-veloce',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/contabilita',
                method: 'POST'
              },
              ricercaRegistrazione: {
                url: 'ricerca-registrazione',
                method: 'GET'
              }
            }
          }
        }
      }
    }, 'RicercaVeloce', 'RicercaVeloceNonPaginata', 'RicercaTestoLibero', 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {},
        Collection: {
          $ricercaVeloceNoPag: function (_params) {
            return this.$action(function () {
              var request = this.$buildRequest('ricercaVeloceNoPag');
              R.forEach(function (filtro) {
                var key = filtro.name,
                    filter = {};
                filter[key] = filtro.value;
                request.params = Object.assign({}, filter);
              }, _params);
              this.$dispatch('before-ricerca-veloce-noPag', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-ricerca-veloce-noPag', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-ricerca-veloce-noPag-error', [_response]);
              });
            });
          }
        },
        Record: {
          $getRegistrazione: function (_params) {
            return this.$action(function () {
              var request = this.$buildRequest('ricercaRegistrazione');
              request.params = Object.assign({}, {
                registrazioneContabileId: this.registrazioneContabileId,
                noLimit: true
              }, _params);
              this.$dispatch('before-get-registrazione-contabile', [request]);
              this.$send(request, function (_response) {
                this['$registrazione'] = _response.data.resultsWrapper.values;
                this.$dispatch('after-get-registrazione-contabile', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-get-registrazione-contabile-error', [_response]);
              });
            });
          }
        }
      }
    });
  }]);
})();