"use strict";

/**
 * Created by dan on 10/01/17.
 * Refactored by GB on 21/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.dashboard').controller('WidgetQuickLinks', ['$scope', '$state', 'modalPresenter', QuickLinks]);

  function QuickLinks($scope, $state, modalPresenter) {
    var me = this,
        localStorageQuickLinks = "polcity.quickLinks";
    /**
     * In caso di essezione sulla lettura o il parsing dei dati provenienti dal localStorage
     * @type {boolean}
     */

    me.quickLinksLoadError = false;
    /**
     * Elenco dei link veloci memorizzati dall'utente
     * @type {Array}
     */

    me.quickLinks = [];
    me.isEmpty = R.isEmpty;

    me.$onInit = function () {
      readQuickLinks();
    };

    me.showQuickLinkDialog = showQuickLinkDialog;

    function showQuickLinkDialog() {
      modalPresenter.create('QuickLinkDialogController', 'quickLinks', 'dashboard/widget/quickLinks/quickLink.dialog.html', readQuickLinks, {
        activeLinks: me.quickLinks
      })();
    }

    ;

    function readQuickLinks() {
      try {
        me.quickLinks = JSON.parse(localStorage.getItem(localStorageQuickLinks));
      } catch (e) {
        me.quickLinksLoadError = true;
        me.quickLinks = [];
      }
    }
  }
})();