"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._redirectUserBasedOnAttributeOnFirstConnectionState = exports.keycloakUserAttributes = exports.currentAgenteVisura = exports.userName = exports.locale = exports.language = void 0;

var _recoil = require("recoil");

var _app = require("../../app");

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

const language = (0, _recoil.atom)({
  key: 'language',
  default: 'it_IT'
});
exports.language = language;
const locale = (0, _recoil.atom)({
  key: 'locale',
  default: 'it'
});
exports.locale = locale;
const userName = (0, _recoil.atom)({
  key: 'userName',
  default: null
});
exports.userName = userName;
const currentAgenteVisura = (0, _recoil.atomFamily)({
  key: 'currentAgenteVisura',
  default: null
});
exports.currentAgenteVisura = currentAgenteVisura;
const keycloakUserAttributesQuery = (0, _recoil.selector)({
  key: '_keycloakUserAttributesQuery',
  get: function () {
    var _ref = _asyncToGenerator(function* ({
      get: _get
    }) {
      const profile = yield _app.keycloakAuth.loadUserProfile();
      return profile.attributes;
    });

    return function get(_x) {
      return _ref.apply(this, arguments);
    };
  }()
});
const keycloakUserAttributes = (0, _recoil.atom)({
  key: '_keycloakUserAttributes',
  default: keycloakUserAttributesQuery
});
exports.keycloakUserAttributes = keycloakUserAttributes;

const _redirectUserBasedOnAttributeOnFirstConnectionState = (0, _recoil.atom)({
  key: "_redirectUserBasedOnAttributeOnFirstConnectionState",
  default: true
});

exports._redirectUserBasedOnAttributeOnFirstConnectionState = _redirectUserBasedOnAttributeOnFirstConnectionState;