"use strict";

/**
 * Created by dan on 06/12/16.
 */
(function () {
  'use strict';

  function GeneralException(sMessage) {
    this.name = 'GeneralException';
    this.message = sMessage;
    this.stack = new Error().stack;
  }

  GeneralException.prototype = Object.create(Error.prototype);
  GeneralException.prototype.constructor = GeneralException;

  function NotFoundException(sMessage) {
    this.name = '404 NotFoundException';
    this.message = sMessage;
    this.stack = new Error().stack;
  }

  NotFoundException.prototype = Object.create(Error.prototype);
  NotFoundException.prototype.constructor = NotFoundException;

  function UnauthorizedException(sMessage) {
    this.name = '401 UnauthorizedException';
    this.message = sMessage;
    this.stack = new Error().stack;
  }

  UnauthorizedException.prototype = Object.create(Error.prototype);
  UnauthorizedException.prototype.constructor = UnauthorizedException;
  angular.module('polcity.api').service('apiException', [apiException]);

  function apiException() {
    var exceptions = {};
    exceptions.generalException = GeneralException;
    exceptions.notFoundException = NotFoundException;
    exceptions.unauthorizedException = UnauthorizedException;
    return exceptions;
  }
})();