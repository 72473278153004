"use strict";

(function () {
  'use strict';

  angular.module('osFramework').directive('mdModal', ['$compile', '$timeout', directiveFn]);

  function directiveFn($compile, $timeout) {
    return {
      restrict: 'E',
      link: postLink,
      scope: {
        openFlag: '=',
        onClose: '&',
        beforeClose: '&'
      }
    };

    function postLink(scope, element) {
      var body = angular.element(document.body);
      element.append($compile('<md-button class="md-icon-button md-modal__close" ng-click="innerClose($event)"><md-icon>close</md-icon></md-button>')(scope));
      body.append(element); // body.on('keyup', closeOnEscape);

      scope.openFlag = angular.isUndefined(scope.openFlag) ? false : scope.openFlag;
      var stopWatchingOpenFlag = scope.$watch('openFlag', onOpenFlagChange);
      scope.close = close;
      scope.innerClose = innerClose;
      scope.$on('$destroy', stopWatchingOpenFlag);
      scope.$on('$destroy', stopListeningOnEscape);

      function closeOnEscape(ev) {
        if (ev.keyCode === 27) {
          scope.innerClose(ev);
        }
      }

      function innerClose(ev) {
        if (!scope.beforeClose) {
          close(ev);
        } else {
          var canClose = scope.beforeClose(ev);

          if (R.is(Boolean, canClose) && canClose) {
            close(ev);
          } else if (canClose && canClose.then) {
            canClose.then(function () {
              close(ev);
            });
          } else if (canClose === undefined) {
            close(ev);
          }
        }
      }

      function stopListeningOnEscape() {
        body.off('keyup', closeOnEscape);
      }

      function close($ev) {
        $ev.preventDefault();
        scope.openFlag = false;

        if (scope.onClose) {
          // if( scope.$parents.tabs ) {
          //     console.log('ti elimini tutto')
          // } else {
          //     scope.onClose();
          // }
          scope.onClose();
        }
      }

      function onOpenFlagChange(newVal) {
        if (newVal) {
          element.addClass('md-modal--visible');
          body.addClass('md-modal--shown'); // the modal will be shown
          // the material autocomplete dropdowns have a z-index way higher than the modal
          // so we need to hide them if they're visible, otherwise they will be visible over
          // the modal

          $timeout(function () {
            // select all material autocompletes
            var autocompletes = $('md-autocomplete');
            angular.forEach(autocompletes, function ($mdAutocomplete) {
              // try to get the full angular element with angular, and ask for its controller
              try {//var $ctrl = angular.element($mdAutocomplete).controller('mdAutocomplete');
                // force hidden to true
                //$ctrl.hidden = true;
              } catch (ex) {
                console.warn('while trying to get an mdAutocomplete controller', ex, $mdAutocomplete);
              }
            }); // also give focus to the first element of the modal

            /*var firstInput = $('input').first();
            if (firstInput) {
                firstInput.focus();
            }*/
          });
        } else {
          element.removeClass('md-modal--visible');
          body.removeClass('md-modal--shown');
        }
      }
    }
  }
})();