"use strict";

(function () {
  'use strict';

  angular.module('polcity.ricorsi').provider('ricorsiRoutes', ['routesProviderFactoryProvider', routeProvider]);

  function routeProvider(routesProviderFactory) {
    this.routes = [{
      name: 'ricorsi',
      abstract: true,
      url: 'ricorsi',
      templateUrl: 'ricorsi/ricorsi.html'
    }, {
      name: 'ricorsi.list',
      url: '',
      role: 'ROLE_RICORSO_RICERCA',
      params: {
        reloadPreviousState: false
      },
      breadcrumbs: {
        isBase: true,
        reloadPreviousState: true
      },
      views: {
        $default: {
          component: 'ricorsiList'
        }
      },
      resolve: {
        $label: ['$translate', function ($translate) {
          return $translate('ricorsi.ricorsiList__bcLabel');
        }]
      }
    }];
    routesProviderFactory.extend(this);
  }
})();