"use strict";

/**
 * Created by MN 23/09/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('armonizzazioneContabileCharts', {
    templateUrl: 'osContabilita/armonizzazioneContabileChartsView/armonizzazioneContabileCharts/armonizzazioneContabileCharts.component.html',
    controller: 'armonizzazioneContabileCharts',
    bindings: {
      chartData: '<',
      onChartClick: '&'
    }
  }).controller('armonizzazioneContabileCharts', ['$scope', '$mdDialog', '$compile', ArmonizzazioneContabileCharts]);

  function ArmonizzazioneContabileCharts($scope, $mdDialog, $compile) {
    var me = this;
    $scope.$on('reset-importi-range', function () {
      $scope.importoRange.minValue = 0;
      $scope.importoRange.maxValue = 500;
    });

    me.$onInit = function () {};

    me.$onChanges = function (changeObj) {
      if (me.chartData.length) {
        setChartData(me.chartData);
      }
    };

    $scope.onImportiFilterClick = function () {
      addImportiFilter();
    };

    $scope.contoChartData = {
      options: {
        filterKey: 'conto',
        legend: {
          display: true,
          position: 'left'
        }
      },
      callBack: onChartClick
    };
    $scope.dareAvereChartData = {
      options: {
        filterKey: 'dareAvere',
        legend: {
          display: false,
          position: 'left'
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      },
      callBack: onChartClick
    };
    $scope.importoRange = {
      minValue: 0,
      maxValue: 500,
      options: {
        floor: 0,
        ceil: 500,
        step: 1,
        precision: 1,
        noSwitching: true,
        translate: function (value) {
          return '€' + value;
        }
      }
    };
    $scope.dataVersamentoChartData = {
      options: {
        filterKey: 'contabilitaDataRange',
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      } // callBack: onChartClick

    };
    $scope.dataRegistrazioneChartData = {
      options: {
        filterKey: 'contabilitaDataRegistrazioneRange',
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      } // callBack: onChartClick

    };

    $scope.openCanvasModal = function (canvas, elementId) {
      var chart = angular.element(document.querySelector('#'.concat(canvas)));
      $mdDialog.show({
        controller: 'chartModalController',
        controllerAs: '$ctrl',
        templateUrl: 'osContabilita/armonizzazioneContabileChartsView/armonizzazioneContabileCharts/chartModal/chartModal.component.html',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          item: chart
        }
      }).finally(function () {
        var card = document.getElementById(elementId);
        chart.detach();
        card.append(chart[0]);
      });
    };

    function setChartData(data) {
      var a = [{
        '2018': [{
          'value': '01/2018',
          'count': 30
        }, {
          'value': '02/2018',
          'count': 26
        }, {
          'value': '03/2018',
          'count': 12
        }, {
          'value': '04/2018',
          'count': 1
        }]
      }, {
        '2019': [{
          'value': '01/2019',
          'count': 44
        }, {
          'value': '02/2019',
          'count': 59
        }, {
          'value': '04/2019',
          'count': 4
        }, {
          'value': '04/2019',
          'count': 23
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }, {
        '2020': [{
          'value': '01/2020',
          'count': 112
        }, {
          'value': '02/2020',
          'count': 99
        }, {
          'value': '03/2020',
          'count': 32
        }, {
          'value': '04/2020',
          'count': 58
        }]
      }];

      for (var i = 0; i < data.length; i++) {
        if (data[i].name === 'conto') {
          setChartDataForConto(data[i].values);
        } else if (data[i].name === 'dareAvere') {
          setChartDataForDareAvere(data[i].values);
        } else if (data[i].name === 'contabilitaDataRange') {
          setChartDataForDataVersamento(data[i].values);
        } else if (data[i].name === 'contabilitaDataRegistrazioneRange') {
          setChartDataForDataRegistrazioneAvere(data[i].values);
        }
      }
    }

    function setChartDataForConto(data) {
      var contoChartlabel = [],
          contoChartValues = [];
      R.forEach(function (o) {
        contoChartlabel.push(o.value);
        contoChartValues.push(o.count);
      }, data);
      $scope.contoChartData = R.merge($scope.contoChartData, {
        labels: contoChartlabel,
        data: contoChartValues
      });
      return $scope.contoChartData;
    }

    function setChartDataForDareAvere(data) {
      var dareAvereChartlabel = [],
          dareAvereChartValues = [];
      R.forEach(function (o) {
        dareAvereChartlabel.push(o.value);
        dareAvereChartValues.push(o.count);
      }, data);
      $scope.dareAvereChartData = R.merge($scope.dareAvereChartData, {
        labels: dareAvereChartlabel,
        data: dareAvereChartValues
      });
      return $scope.dareAvereChartData;
    }

    function setChartDataForDataVersamento(data) {
      var dateChartlabel = [];
      var dateChartValues = [];
      R.forEach(function (o) {
        dateChartlabel.push(o.value);
        dateChartValues.push(o.count);
      }, data);
      $scope.dataVersamentoChartData = R.merge($scope.dataVersamentoChartData, {
        labels: dateChartlabel,
        data: dateChartValues
      });
      return $scope.dataVersamentoChartData;
    }

    function setChartDataForDataRegistrazioneAvere(data) {
      var dataRegistrazioneChartlabel = [];
      var dataRegistrazioneChartValues = [];
      R.forEach(function (o) {
        dataRegistrazioneChartlabel.push(o.value);
        dataRegistrazioneChartValues.push(o.count);
      }, data);
      $scope.dataRegistrazioneChartData = R.merge($scope.dataRegistrazioneChartData, {
        labels: dataRegistrazioneChartlabel,
        data: dataRegistrazioneChartValues
      });
      return $scope.dataRegistrazioneChartData;
    } // CON STRUTTURA DATI ELASTIC SEARCH RAGGRUPPATA

    /*function setChartDataForDataRegistrazioneAvere( data ) {
        var dataRegistrazioneChartlabel     = [
                'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
            ], dataRegistrazioneChartValues = [],
            dataRegistrazioneChartSeries    = [];
         // recupero le serie
        R.forEach(function( o ) {
                dataRegistrazioneChartSeries.push(R.keys(o));
            }, data
        );
         // recupero i valori e creo struttura per il grafico [ [1,2,3], [4,5,6], [7,8,9] ] (deve per forza essere fatta così)
        for( var k = 0; k < data.length; k++ ) {
            var values = data[k], array = [];
            values = values[R.keys(values)];
             for( var j = 0; j < values.length; j++ ) {
                array.push(values[j].count);
            }
             dataRegistrazioneChartValues.push(array);
        }
         $scope.dataRegistrazioneChartData = R.merge($scope.dataRegistrazioneChartData, {
            series: dataRegistrazioneChartSeries,
            labels: dataRegistrazioneChartlabel,
            data  : dataRegistrazioneChartValues
        });
         return $scope.dataRegistrazioneChartData;
    }*/


    function onChartClick(element, evt) {
      var filtro = {};

      if (!element.length) {
        return;
      } // selezionando un valore dal grafico devo ricostruire il filtro da passare al model per aggiungerlo alla lista


      filtro.name = element[0]._chart.options.filterKey;
      filtro.value = element[0]._model.label || element[0]._scale.pointLabels[element[0]._index];
      me.onChartClick({
        filter: filtro
      });
    }

    function addImportiFilter() {
      var filtro = {};
      filtro.name = 'importoRange';
      filtro.value = [$scope.importoRange.minValue, $scope.importoRange.maxValue];
      me.onChartClick({
        filter: filtro
      });
    }
  }
})();