"use strict";

/**
 * Created by MN 18/09/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('armonizzazioneContabileListDetail', {
    templateUrl: 'osContabilita/armonizzazioneContabile/armonizzazioneContabileListDetail.component.html',
    controller: 'armonizzazioneContabileListDetailController',
    bindings: {
      item: '<'
    }
  }).controller('armonizzazioneContabileListDetailController', ['$scope', '$filter', ArmonizzazioneContabileListDetailController]);

  function ArmonizzazioneContabileListDetailController($scope, $filter) {
    var me = this;
    me.registrazioneContabile = [];

    me.$onInit = function () {
      getRegistrazioneContabile();
    };

    function getRegistrazioneContabile() {
      me.item.$getRegistrazione();
    }
    /**
     * @property
     * Configurazione delle colonne della tabella
     * @type {*[]}
     */


    me.column = [{
      text: 'Data versamento',
      dataIndex: 'data',
      renderer: function (item) {
        return moment(item.data).format('LL');
      }
    }, {
      text: 'Data registrazione',
      dataIndex: 'dateRegistrazione',
      renderer: function (item) {
        return moment(item.dateRegistrazione).format('LL');
      }
    }, {
      text: 'Conto',
      dataIndex: 'conto'
    }, {
      text: 'Descrizione',
      dataIndex: 'descrizione'
    }, {
      text: 'Dare',
      dataIndex: 'dareAvere',
      renderer: function (item) {
        return item.dareAvere === 'D' ? $filter('currency')(item.importo) : '';
      }
    }, {
      text: 'Avere',
      dataIndex: 'dareAvere',
      renderer: function (item) {
        return item.dareAvere === 'A' ? $filter('currency')(item.importo) : '';
      }
    }];
  }
})();