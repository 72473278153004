"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleUploadFilePuntiSuccess = exports.handleVerbalizzaDecurtazioniSuccess = exports.handleDownloadFileSuccess = exports.handleStornaFileSuccess = exports.handleEsportaFileSuccess = exports.handleAssegnaDocumentoSuccess = exports.handleFailure = exports.useUploadFilePunti = exports.useVerbalizzaDecurtazioni = exports.useDownloadFile = exports.useStornaFile = exports.useEsportaFile = exports.useAssegnaDocumento = exports.useDecurtazionePunti = exports.fromApiDecurtazionePunti = exports.assegnaDocumentoModalOpen = exports.decurtazioniPuntiKeys = void 0;

var _api = require("../../api");

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _utils = require("../../utils");

var _decurtazioniPuntiMasterDetail = require("./decurtazioniPuntiMasterDetail");

var _reactQuery = require("react-query");

var _utils2 = require("../../react/reactQuery/utils");

var _object = require("../../object.utils");

const decurtazioniPuntiKeys = {
  all: () => [_decurtazioniPuntiMasterDetail.decurtazioniPuntiMasterDetailName],
  detail: aggId => [...decurtazioniPuntiKeys.all(), "detail", ...(0, _utils.insertIf)(aggId, {
    aggId
  })],
  quickSearch: params => [...decurtazioniPuntiKeys.all(), "quickSearch", ...(0, _utils.insertIf)(params, params)]
};
exports.decurtazioniPuntiKeys = decurtazioniPuntiKeys;
const assegnaDocumentoModalOpen = (0, _recoil.atom)({
  key: 'assegnaDocumentoModalOpen',
  default: false
});
exports.assegnaDocumentoModalOpen = assegnaDocumentoModalOpen;

const fromApiDecurtazionePunti = remoteDecurtazione => {
  const {
    trasgressore,
    obbligato
  } = remoteDecurtazione;
  const aggIdTrasgressore = (0, _object.getPathStringOrFalse)(["trasgressore", "aggId"])(remoteDecurtazione);
  const aggIdObbligato = (0, _object.getPathStringOrFalse)(["obbligato", "aggId"])(remoteDecurtazione);
  return { ...remoteDecurtazione,
    soggetti: [...(0, _utils.insertIf)(aggIdTrasgressore && aggIdTrasgressore != aggIdObbligato, { ...trasgressore,
      posizione: "T",
      tipoSoggetto: "trasgressore",
      dataNotifica: remoteDecurtazione.dataNotificaTrasgressore
    }), ...(0, _utils.insertIf)(aggIdObbligato && aggIdTrasgressore != aggIdObbligato, { ...obbligato,
      posizione: "OB",
      tipoSoggetto: "obbligato",
      dataNotifica: remoteDecurtazione.dataNotificaObbligato
    }), ...(0, _utils.insertIf)(aggIdObbligato && aggIdTrasgressore && aggIdObbligato == aggIdTrasgressore, { ...trasgressore,
      posizione: "T + O",
      tipoSoggetto: "trasgressore",
      dataNotifica: remoteDecurtazione.dataNotificaTrasgressore
    })],
    missingTrasgressore: !Boolean(remoteDecurtazione.trasgressore),
    missingDataNotificaTrasgressore: !Boolean(remoteDecurtazione.dataNotificaTrasgressore)
  };
};

exports.fromApiDecurtazionePunti = fromApiDecurtazionePunti;

const useDecurtazionePunti = aggId => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data: item,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(decurtazioniPuntiKeys.detail(aggId), ({
    queryKey
  }) => (0, _api.dettaglioDecurtazionePunti)({
    aggId: queryKey[2].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId),
    onSuccess: (0, _utils2.handleSuccess)(messenger),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    select: data => fromApiDecurtazionePunti(data)
  });
  return {
    item,
    isFetching: isFetching || isLoading
  };
};

exports.useDecurtazionePunti = useDecurtazionePunti;

const useAssegnaDocumento = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(({
    aggId,
    dataPresentazioneDocumento,
    documentoId
  }) => (0, _api.assegnaDocumentoPerDecurtazionePunti)({
    aggId,
    dataPresentazioneDocumento: dataPresentazioneDocumento.getTime(),
    documentoId
  }), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleAssegnaDocumentoSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(decurtazioniPuntiKeys.all())
  });
};

exports.useAssegnaDocumento = useAssegnaDocumento;

const useEsportaFile = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(esporabiliIds => (0, _api.creaFileDecurtazioniPunti)({
    aggIds: esporabiliIds
  }), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleEsportaFileSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(decurtazioniPuntiKeys.all())
  });
};

exports.useEsportaFile = useEsportaFile;

const useStornaFile = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(esporabiliIds => (0, _api.stornaDecurtazioni)({
    aggIds: esporabiliIds
  }), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleStornaFileSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(decurtazioniPuntiKeys.all())
  });
};

exports.useStornaFile = useStornaFile;

const useDownloadFile = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(aggIdAllegato => (0, _api.downloadFileDecurtazioniPunti)({
    allegatoAggId: aggIdAllegato
  }), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleDownloadFileSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(decurtazioniPuntiKeys.all())
  });
};

exports.useDownloadFile = useDownloadFile;

const useVerbalizzaDecurtazioni = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(formData => (0, _api.verbalizza126Bis)(formData), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleVerbalizzaDecurtazioniSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(decurtazioniPuntiKeys.all())
  });
};

exports.useVerbalizzaDecurtazioni = useVerbalizzaDecurtazioni;

const useUploadFilePunti = ({
  onFailure,
  onSuccess
}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  return (0, _reactQuery.useMutation)(formData => (0, _api.uploadFilePunti)(formData), {
    onSuccess: response => response.error ? handleFailure(messenger)({
      response,
      onFailure
    }) : handleUploadFilePuntiSuccess(messenger)({
      response,
      onSuccess
    }),
    onError: response => (0, _utils2.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(decurtazioniPuntiKeys.all())
  });
};

exports.useUploadFilePunti = useUploadFilePunti;

const handleFailure = messenger => ({
  response,
  onFailure
}) => {
  messenger({
    type: 'warning',
    message: `Attenzione! ${response.message}`,
    severity: 'warning'
  });

  if (onFailure) {
    onFailure(response);
  }
};

exports.handleFailure = handleFailure;

const handleAssegnaDocumentoSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: "Documento assegnato",
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleAssegnaDocumentoSuccess = handleAssegnaDocumentoSuccess;

const handleEsportaFileSuccess = messenger => ({
  response,
  onSuccess
}) => {
  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleEsportaFileSuccess = handleEsportaFileSuccess;

const handleStornaFileSuccess = messenger => ({
  response,
  onSuccess
}) => {
  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleStornaFileSuccess = handleStornaFileSuccess;

const handleDownloadFileSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: "File scaricato",
    severity: 'success'
  });
  const fileName = (0, _utils.getFileNameFromResponseHeaders)(response.headers) || 'File scaricato';
  (0, _utils.downloadFile)(response.data, 'application/octet-stream', fileName);

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleDownloadFileSuccess = handleDownloadFileSuccess;

const handleVerbalizzaDecurtazioniSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: "Operazione completata. Verrai notificato ad operazione conclusa",
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleVerbalizzaDecurtazioniSuccess = handleVerbalizzaDecurtazioniSuccess;

const handleUploadFilePuntiSuccess = messenger => ({
  response,
  onSuccess
}) => {
  messenger({
    type: 'success',
    message: "File importato",
    severity: 'success'
  });

  if (onSuccess) {
    onSuccess(response);
  }
};

exports.handleUploadFilePuntiSuccess = handleUploadFilePuntiSuccess;