"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var _utils = require("./utils");

var _react = require("react");

var _utils2 = require("../../../utils");

const useTreeSearch = props => {
  const {
    tree: treeProp
  } = props;
  const [tree, setTree] = (0, _react.useState)();
  const [searchText, setSearchText] = (0, _react.useState)("");
  const [expandedNodeIds, setExpandedNodeIds] = (0, _react.useState)([]);
  const [filteredTree, setFilteredTree] = (0, _react.useState)({});
  (0, _react.useEffect)(() => {
    if (!(0, _utils2.deepEqual)(tree, treeProp)) {
      setTree(treeProp);
      setFilteredTree(treeProp);
      setExpandedNodeIds([(0, _utils.getStazioneComandoId)(treeProp.userObject)]);
    }
  }, [treeProp]);

  const onSearchChange = text => {
    setSearchText(text);

    if (!text) {
      setFilteredTree(tree);
      setExpandedNodeIds([tree.userObject.stazioneComandoId]); // resetIdsExpandFilter()

      return;
    }

    const filtered = (0, _utils.filterTree)(tree, text);
    const expandedIds = filtered.children ? [...(0, _utils.getExpandedIds)(filtered), (0, _utils.getStazioneComandoId)(filtered.userObject)] : (0, _utils.getStazioneComandoId)(filtered.userObject);
    setFilteredTree(filtered);
    setExpandedNodeIds(expandedIds);
  };

  return {
    getNodeProps: () => ({
      onToggle: (e, nodeIds) => {
        setExpandedNodeIds(nodeIds);
      }
    }),
    getSearchProps: (0, _react.useCallback)(() => ({
      value: searchText,
      onChange: e => onSearchChange(e.target.value)
    }), [searchText, onSearchChange]),
    tree: filteredTree,
    expandedNodeIds
  };
};

var _default = useTreeSearch;
exports.default = _default;