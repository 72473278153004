"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCancellaInsolutoDaRuolo = exports.useCancellaRuolo = exports.useInviaRuolo = exports.useEsportaRuolo = exports.useGeneraFlussoRuolo = exports.useCreaRuolo = exports.useRuolo = exports._insolutoDaRimuovereDalRuolo = exports._rimuoviInsolutoDaRuoloDialogOpen = exports._inserisciRuoloDialogOpen = exports.ruoliKeys = void 0;

var _api = require("../../api");

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _utils = require("../../react/reactQuery/utils");

var _utils2 = require("../../utils");

var _ruoliMasterDetail = require("./ruoliMasterDetail");

var _utils3 = require("./utils");

const ruoliKeys = {
  all: () => [_ruoliMasterDetail.ruoliMasterDetailName],
  detail: aggId => [...ruoliKeys.all(), "detail", ...(0, _utils2.insertIf)(aggId, {
    aggId
  })],
  quickSearch: params => [...ruoliKeys.all(), "quickSearch", ...(0, _utils2.insertIf)(params, params)]
};
exports.ruoliKeys = ruoliKeys;

const _inserisciRuoloDialogOpen = (0, _recoil.atom)({
  key: "_inserisciRuoloDialogOpen",
  default: false
});

exports._inserisciRuoloDialogOpen = _inserisciRuoloDialogOpen;

const _rimuoviInsolutoDaRuoloDialogOpen = (0, _recoil.atom)({
  key: "_rimuoviInsolutoDaRuoloDialogOpen",
  default: false
});

exports._rimuoviInsolutoDaRuoloDialogOpen = _rimuoviInsolutoDaRuoloDialogOpen;

const _insolutoDaRimuovereDalRuolo = (0, _recoil.atom)({
  key: "_insolutoDaRimuovereDalRuolo",
  default: false
});

exports._insolutoDaRimuovereDalRuolo = _insolutoDaRimuovereDalRuolo;

const useRuolo = aggId => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(ruoliKeys.detail(aggId), ({
    queryKey
  }) => (0, _api.dettaglioRuolo)({
    aggId: queryKey[2].aggId
  }), {
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    suspense: true,
    enabled: Boolean(aggId),
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: data => (0, _utils3.fromApiRuolo)(data)
  });
  return {
    ruolo: data,
    isLoading: isFetching || isLoading
  };
};

exports.useRuolo = useRuolo;

const useCreaRuolo = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Ruolo creato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(semestreAnno => (0, _api.creaRuolo)(semestreAnno), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(ruoliKeys.all())
  });
};

exports.useCreaRuolo = useCreaRuolo;

const useGeneraFlussoRuolo = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Flusso generato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.generaFlussoRuolo)({
    aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(ruoliKeys.all())
  });
};

exports.useGeneraFlussoRuolo = useGeneraFlussoRuolo;

const useEsportaRuolo = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Ruolo esportato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.esportaRuolo)({
    aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(ruoliKeys.all())
  });
};

exports.useEsportaRuolo = useEsportaRuolo;

const useInviaRuolo = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Ruolo inviato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.inviaRuolo)({
    aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(ruoliKeys.all())
  });
};

exports.useInviaRuolo = useInviaRuolo;

const useCancellaRuolo = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Ruolo cancellato",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.cancellaRuolo)({
    aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(ruoliKeys.all())
  });
};

exports.useCancellaRuolo = useCancellaRuolo;

const useCancellaInsolutoDaRuolo = ({
  onSuccess,
  onFailure
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Insoluto cancellato dal ruolo",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(aggId => (0, _api.cancellaInsolutoDaRuolo)({
    aggId
  }), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(ruoliKeys.all())
  });
};

exports.useCancellaInsolutoDaRuolo = useCancellaInsolutoDaRuolo;