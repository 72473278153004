"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.agentiVisureTdsName = exports.leggiTdsName = exports.tipiVeicoloTdsName = exports.tipiProprietaTdsName = exports.tipiBollettariTdsName = exports.tipiAccertamentoTdsName = exports.sanzioniAccessorieTdsName = exports.rilevatoriInfrazioniTdsName = exports.relazioniNomeSessoTdsName = exports.motivazioniMancataContestazioneTdsName = exports.motivazioniMancataApplicazioneTdsName = exports.motivazioniArchiviazioniAccertamentiTdsName = exports.direzioneMarciaVeicoliTdsName = exports.attivitaProduttiveTdsName = exports.tipologieRimozioniTdsName = exports.marcheModelliVeicoliTdsName = exports.localizzazioniTdsName = exports.lingueTdsName = exports.gestioneContiCorrentiTdsName = exports.gestioneStradeTdsName = exports.frazioniTdsName = exports.gestioneDelTerritorioTdsName = exports.funzioniStampaTdsName = exports.entiTerritorialiTdsName = exports.entiTdsName = exports.depositiFermiSequestriTdsName = exports.contenutiAllegatiTdsName = exports.contatoriProtocolloTdsName = exports.riferimentiComuniTdsName = exports.carroattrezziTdsName = exports.nazioniTdsName = void 0;
const nazioniTdsName = "Nazioni";
exports.nazioniTdsName = nazioniTdsName;
const carroattrezziTdsName = "CarroAttrezzi";
exports.carroattrezziTdsName = carroattrezziTdsName;
const riferimentiComuniTdsName = "RiferimentiComuni";
exports.riferimentiComuniTdsName = riferimentiComuniTdsName;
const contatoriProtocolloTdsName = "ContatoriProtocollo";
exports.contatoriProtocolloTdsName = contatoriProtocolloTdsName;
const contenutiAllegatiTdsName = "ContenutiAllegati";
exports.contenutiAllegatiTdsName = contenutiAllegatiTdsName;
const depositiFermiSequestriTdsName = "DepositiFermiSequestri";
exports.depositiFermiSequestriTdsName = depositiFermiSequestriTdsName;
const entiTdsName = "Enti";
exports.entiTdsName = entiTdsName;
const entiTerritorialiTdsName = "EntiTerritoriali";
exports.entiTerritorialiTdsName = entiTerritorialiTdsName;
const funzioniStampaTdsName = "FunzioniStampa";
exports.funzioniStampaTdsName = funzioniStampaTdsName;
const gestioneDelTerritorioTdsName = "GestioneDelTerritorio";
exports.gestioneDelTerritorioTdsName = gestioneDelTerritorioTdsName;
const frazioniTdsName = "Frazioni";
exports.frazioniTdsName = frazioniTdsName;
const gestioneStradeTdsName = "GestioneStrade";
exports.gestioneStradeTdsName = gestioneStradeTdsName;
const gestioneContiCorrentiTdsName = "ContiCorrente";
exports.gestioneContiCorrentiTdsName = gestioneContiCorrentiTdsName;
const lingueTdsName = "Lingue";
exports.lingueTdsName = lingueTdsName;
const localizzazioniTdsName = "Localizzazioni";
exports.localizzazioniTdsName = localizzazioniTdsName;
const marcheModelliVeicoliTdsName = "MarcheModelliVeicoli";
exports.marcheModelliVeicoliTdsName = marcheModelliVeicoliTdsName;
const tipologieRimozioniTdsName = "TipologieRimozioni";
exports.tipologieRimozioniTdsName = tipologieRimozioniTdsName;
const attivitaProduttiveTdsName = "AttivitaProduttive";
exports.attivitaProduttiveTdsName = attivitaProduttiveTdsName;
const direzioneMarciaVeicoliTdsName = "DirezioniMarciaVeicoli";
exports.direzioneMarciaVeicoliTdsName = direzioneMarciaVeicoliTdsName;
const motivazioniArchiviazioniAccertamentiTdsName = "MotivazioniArchiviazioniAccertamenti";
exports.motivazioniArchiviazioniAccertamentiTdsName = motivazioniArchiviazioniAccertamentiTdsName;
const motivazioniMancataApplicazioneTdsName = "MotivazioniMancataApplicazione";
exports.motivazioniMancataApplicazioneTdsName = motivazioniMancataApplicazioneTdsName;
const motivazioniMancataContestazioneTdsName = "MotivazioniMancataContestazione";
exports.motivazioniMancataContestazioneTdsName = motivazioniMancataContestazioneTdsName;
const relazioniNomeSessoTdsName = "RelazioniNomeSesso";
exports.relazioniNomeSessoTdsName = relazioniNomeSessoTdsName;
const rilevatoriInfrazioniTdsName = "RilevatoriInfrazioni";
exports.rilevatoriInfrazioniTdsName = rilevatoriInfrazioniTdsName;
const sanzioniAccessorieTdsName = "SanzioniAccessorie";
exports.sanzioniAccessorieTdsName = sanzioniAccessorieTdsName;
const tipiAccertamentoTdsName = "TipiAccertamento";
exports.tipiAccertamentoTdsName = tipiAccertamentoTdsName;
const tipiBollettariTdsName = "TipiBollettari";
exports.tipiBollettariTdsName = tipiBollettariTdsName;
const tipiProprietaTdsName = "TipiProprieta";
exports.tipiProprietaTdsName = tipiProprietaTdsName;
const tipiVeicoloTdsName = "TipiVeicolo";
exports.tipiVeicoloTdsName = tipiVeicoloTdsName;
const leggiTdsName = "Leggi";
exports.leggiTdsName = leggiTdsName;
const agentiVisureTdsName = "AgentiVisure";
exports.agentiVisureTdsName = agentiVisureTdsName;