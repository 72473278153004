"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAggiornaValoriGlobali = exports.useResolveValoriGlobaliTipoUUID = exports.useValoreGlobale = exports.useValoriGlobaliMasterDetail = exports._valoriGlobaliMasterDetailState = exports.valoriGlobaliKeys = exports.valoriGlobaliMasterDetailName = void 0;

var _recoil = require("recoil");

var _messages = require("../global/messages");

var _reactQuery = require("react-query");

var _api = require("../../api");

var _utils = require("../../react/reactQuery/utils");

var _utils2 = require("../../utils");

var _react = require("react");

var _utils3 = require("./utils");

var _constants = require("./constants");

const valoriGlobaliMasterDetailName = "valoriGlobali";
exports.valoriGlobaliMasterDetailName = valoriGlobaliMasterDetailName;
const valoriGlobaliKeys = {
  all: () => [valoriGlobaliMasterDetailName],
  sezioni: params => [...valoriGlobaliKeys.all(), "sezioni", ...(0, _utils2.insertIf)(params, params)],
  detail: alias => [...valoriGlobaliKeys.all(), "detail", ...(0, _utils2.insertIf)(alias, {
    alias
  })]
};
exports.valoriGlobaliKeys = valoriGlobaliKeys;
const defaultValoriGlobaliMasterDetailState = {
  sectionNameFilter: "",
  activeSection: null
};

const _valoriGlobaliMasterDetailState = (0, _recoil.atom)({
  key: "_valoriGlobaliMasterDetailState",
  default: defaultValoriGlobaliMasterDetailState
});

exports._valoriGlobaliMasterDetailState = _valoriGlobaliMasterDetailState;

const useValoriGlobaliMasterDetail = ({
  onSuccess,
  onFailure
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const [{
    sectionNameFilter,
    activeSection
  }, setState] = (0, _recoil.useRecoilState)(_valoriGlobaliMasterDetailState);
  const {
    data,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(valoriGlobaliKeys.sezioni(), ({
    queryKey
  }) => (0, _api.valoriGlobali)(), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    // 5 minutes
    onSuccess: response => (0, _utils.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response)
  });
  const valoriGlobaliFiltered = (0, _react.useMemo)(() => {
    if (sectionNameFilter) {
      return data.filter(section => section.alias.includes(sectionNameFilter));
    }

    return data ? data : [];
  }, [data, sectionNameFilter]);
  return {
    valoriGlobali: valoriGlobaliFiltered,
    isLoading: isFetching || isLoading,
    sectionNameFilter,
    activeSection: activeSection,
    onSectionClick: section => setState(state => ({ ...state,
      activeSection: section
    })),
    onFilterAdd: filterValue => setState(state => ({ ...state,
      sectionNameFilter: filterValue
    }))
  };
};

exports.useValoriGlobaliMasterDetail = useValoriGlobaliMasterDetail;

const useValoreGlobale = ({
  alias,
  onSuccess,
  onFailure
} = {}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const {
    data,
    isFetching,
    isLoading
  } = (0, _reactQuery.useQuery)(valoriGlobaliKeys.detail(alias), ({
    queryKey
  }) => (0, _api.valoriGlobaliLeggi)(queryKey[2].alias), {
    enabled: Boolean(alias),
    suspense: true,
    onSuccess: response => (0, _utils.handleOnSuccessQuery)(messenger)({
      response,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: response => response.error ? response : (0, _utils3.flattenObjectValoriGlobali)(response) || {}
  });
  return {
    valori: Boolean(data) ? data : {},
    isLoading: isFetching || isLoading
  };
};

exports.useValoreGlobale = useValoreGlobale;

const useResolveValoriGlobaliTipoUUID = valori => {
  const valoriGlobaliDiTipoUUID = (0, _utils3.getValoriGlobaliTipoUUID)(valori);
  const UUIDResponses = (0, _reactQuery.useQueries)(valoriGlobaliDiTipoUUID.map(valoreGlobaleUUID => {
    return {
      queryKey: [`valoriGlobaliDiTipoUUIDKeys`, {
        key: valoreGlobaleUUID.key,
        valore: valoreGlobaleUUID.valore
      }],
      queryFn: ({
        queryKey
      }) => {
        if (_constants.ARTICOLO_CDS_CASES.includes(valoreGlobaleUUID.key)) {
          return (0, _api.dettaglioArticoloNormativa)({
            aggId: queryKey[1].valore
          });
        }

        if (_constants.NAZIONE_CASES.includes(valoreGlobaleUUID.key)) {
          return (0, _api.dettaglioStato)({
            aggId: valoreGlobaleUUID.valore
          });
        }

        if (_constants.COMUNEGDT_CASES.includes(valoreGlobaleUUID.key)) {
          return (0, _api.dettaglioComune)({
            aggId: valoreGlobaleUUID.valore
          });
        }

        if (_constants.STRADA_CASES.includes(valoreGlobaleUUID.key)) {
          return (0, _api.dettaglioStrada)({
            entityId: valoreGlobaleUUID.valore
          });
        }

        if (_constants.CONTENUTO_ALLEGATO_CASES.includes(valoreGlobaleUUID.key)) {
          return (0, _api.dettaglioContenutoAllegato)({
            aggId: valoreGlobaleUUID.valore
          });
        }

        if (_constants.STAZIONE_COMANDO_CASES.includes(valoreGlobaleUUID.key)) {
          return (0, _api.dettaglioStazioneComando)({
            aggId: valoreGlobaleUUID.valore
          });
        }

        if (_constants.MODELLO_STAMPA_CASES.includes(valoreGlobaleUUID.key)) {
          return (0, _api.dettaglioModelloStampa)({
            aggId: valoreGlobaleUUID.valore
          });
        }

        if (_constants.MOTIVAZIONE_MANCATA_APPLICAZIONE_CASES.includes(valoreGlobaleUUID.key)) {
          return (0, _api.dettaglioMotivazioneMancataApplicazione)({
            aggId: valoreGlobaleUUID.valore
          });
        }

        if (_constants.TIPO_ACCERTAMENTO_CASES.includes(valoreGlobaleUUID.key)) {
          return (0, _api.dettaglioTipoAccertamento)({
            aggId: valoreGlobaleUUID.valore
          });
        }
      },
      staleTime: 1000 * 60 * 5,
      suspense: false,
      // https://github.com/TanStack/query/issues/1523
      enabled: Boolean(valoriGlobaliDiTipoUUID) && Boolean(valoriGlobaliDiTipoUUID.length),
      select: response => {
        if (response.error) {
          return response;
        }

        return {
          [`${valoreGlobaleUUID.key}`]: {
            valore: response
          }
        };
      }
    };
  }));
  const successUUIDResponses = UUIDResponses.filter(r => r.status === "success").filter(({
    data
  }) => Boolean(data) && !data.error).map(({
    data
  }) => data).reduce((acc, value) => {
    const key = Object.keys(value)[0];
    acc = { ...acc,
      [`${key}`]: value[key]
    };
    return acc;
  }, {});
  return { ...valori,
    ...successUUIDResponses
  };
};

exports.useResolveValoriGlobaliTipoUUID = useResolveValoriGlobaliTipoUUID;

const useAggiornaValoriGlobali = ({
  onFailure,
  onSuccess
} = {}) => {
  const queryClient = (0, _reactQuery.useQueryClient)();
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const message = {
    type: 'success',
    message: "Valori globali aggiornati",
    severity: 'success'
  };
  return (0, _reactQuery.useMutation)(valoriGlobali => (0, _api.aggiornaValoriGlobali)(valoriGlobali), {
    onSuccess: response => (0, _utils.handleOnSuccessMutation)(messenger)({
      response,
      message,
      onSuccess,
      onFailure
    }),
    onError: response => (0, _utils.handleError)(messenger)(response),
    onSettled: () => queryClient.invalidateQueries(valoriGlobaliKeys.detail())
  });
};

exports.useAggiornaValoriGlobali = useAggiornaValoriGlobali;