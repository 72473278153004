"use strict";

var _api = require("./api");

var _visura_ricercaVeloce = require("./mockdata/visura_ricerca-veloce");

var _ricerca_agenteVeicoli = require("./mockdata/anagraficaVeicolo/ricerca_agente-veicoli");

var _valoriGlobali_leggi = require("./mockdata/valori-globali_leggi");

var _tipiAccertamento = require("./mockdata/tipiAccertamento");

var _verbale_ricercaVeloce = require("./mockdata/verbale_ricerca-veloce");

var _alert = require("./mockdata/alert");

var _presentazione_documenti_mock_api = require("./mockdata/presentazione_documenti_mock_api");

var _organigramma_dettaglio = require("./mockdata/organigramma_dettaglio");

var _ruoli_fake_data = require("./mockdata/ruoli_fake_data");

var _insoluti_mock_api = require("./mockdata/insoluti_mock_api");

var _archivio_armaposte = require("./mockdata/archivio_armaposte");

const MockAdapter = require("axios-mock-adapter"); // This sets the mock adapter on the default instance


const mock = new MockAdapter(_api.instance, {
  onNoMatch: "passthrough",
  delayResponse: 2000
});
const mock2 = new MockAdapter(_api.instance2, {
  onNoMatch: "passthrough",
  delayResponse: 2000
});
/*mock.onPost(
    "http://localhost:8081/api/VerbaliAP1/Ricerca",
    {idVerbale: 1}
).reply(
    400
);*/

/*mock.onPost(
    "http://localhost:8081/api/VerbaliAP1/Ricerca",
).reply(
    200,
    archivioArmaposteRicercaFakeData
);

mock.onGet("http://localhost:8081/api/VerbaliAP1/Dettaglio/1/V/2021").reply(
    200,
    archivioArmaposteDettaglio3
);

mock.onGet("http://localhost:8081/api/VerbaliAP1/Dettaglio/2/V/2021").reply(
    400
);
mock.onGet("http://localhost:8081/api/VerbaliAP1/Allegato/pdf123").reply(
    200,
    archivioArmaposteAllegatoFakeDataPdf
);*/
// mock.onGet("http://localhost:8081/api/ruoli/ricerca-veloce").reply(200, ruoliRicercaVeloceFakeData);
// mock.onGet("http://localhost:8081/api/lotto/ruoli/dettaglio/lotto").reply(200, ruoliDettaglioFakeData);
// mock.onGet("http://localhost:8081/api/ruolo/lista/stati").reply(200, statiRuoliFakeData);
// mock.onGet("https://test.polcity.it/api_remote/insoluto/ricerca-veloce").reply(200, insolutiRicercaFakeData);
// mock.onGet("https://test.polcity.it/api_remote/insoluto/lista/stati").reply(200, insolutiListaStatiFakeData);
// mock.onPost("https://test.polcity.it/api_remote/insoluto/dettaglio/insoluto").reply(200, insolutoDettaglioFakeData);
//
// mock.onGet("http://armatest.polcity.it/api_remote/insoluto/ricerca-veloce").reply(200, insolutiRicercaFakeData);
// mock.onGet("http://armatest.polcity.it/api_remote/insoluto/lista/stati").reply(200, insolutiListaStatiFakeData);
// mock.onPost("http://armatest.polcity.it/api_remote/insoluto/dettaglio/insoluto").reply(200, insolutoDettaglioFakeData);
//
// mock.onGet("http://localhost:8081/api/insoluto/ricerca-veloce").reply(200, insolutiRicercaFakeData);
// mock.onGet("http://localhost:8081/api/insoluto/lista/stati").reply(200, insolutiListaStatiFakeData);
// mock.onPost("http://localhost:8081/api/insoluto/dettaglio/insoluto").reply(200, insolutoDettaglioFakeData);
// mock.onGet("http://localhost:8080/api_remote/organigramma/dettaglio/organigramma").reply(200, dettaglioOrganigramma);
// mock.onGet("http://localhost:8080/api_remote/presentazione-documenti/ricerca/presentazione-documenti") .reply(200, ricercaPresentazioneDocumentiMock);
// mock.onPost("http://localhost:8080/api_remote/presentazione-documenti/dettaglio/presentazione-documenti").reply(200, dettaglioPresentazioneDocumentoMock);
// mock.onPost("http://localhost:8080/api_remote/presentazione-documenti/forza/documento").reply(200, {
//     "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
//     "statoPresentazione": "da_presentare",
//     "verbaleId": "fa75645f-e9b7-4ef3-90e4-7b40453c9aba",
//     "numeroVerbale": "1/V/2021",
//     "dataViolazioneVerbale": 1634076000000,
//     "violazioni": [
//         {
//             "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9aba",
//             "codiceLibreria": "180/001.A",
//             "titolo": "Mancanza del documento di circolazione\n",
//             "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
//         },
//         {
//             "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9aba",
//             "codiceLibreria": "180/001.A",
//             "titolo": "Mancanza del documento di circolazione\n",
//             "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
//         }
//     ],
//     "trasgressore": {
//         "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
//         "tipoAnagrafica": "persona_fisica",
//         "nome": "Mario",
//         "cognome": "Rossi",
//         "ragioneSociale": "Open Software srl"
//     },
//     "obbligato": {
//         "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9aba",
//         "tipoAnagrafica": "persona_fisica",
//         "nome": "Mario",
//         "cognome": "Rossi",
//         "ragioneSociale": "Open Software srl"
//     },
//     "daPresentare": [
//         {
//             "docId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
//             "soggetto": {
//                 "tipoSoggetto": "obbligato",
//                 "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9aba",
//                 "tipoAnagrafica": "persona_fisica",
//                 "nome": "Mario",
//                 "cognome": "Rossi",
//                 "ragioneSociale": "Open Software srl"
//             },
//             "isDefault": true,
//             "tipoDocumento": "certificato_circolazione",
//             "tipoDocumentoForzato": "CFP",
//             "enteRilascio": {
//                 "aggId": "47eef37d-8bb5-4449-ad93-e8900817b30c",
//                 "tipoEnte": "QUESTURA",
//                 "ragioneSociale": "Questura di Treviso"
//             },
//             "numeroDocumento": "abrt34234o12345qwerf",
//             "dataRilascio": 1634076000000,
//             "dataScadenza": 1634086000000,
//             "categoria": "BE",
//             "categoriaDocumentoForzato": "BCDE",
//             "dataPresentazione": 1634086000000,
//             "luogoPresentazione": "Bastia Rovolon",
//             "esitoPresentazione": "OK",
//             "riferimentoArticolo": {
//                 "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9aba",
//                 "codiceLibreria": "180/001.A",
//                 "titolo": "Mancanza del documento di circolazione\n",
//                 "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
//             },
//             "riferimentoArticoloForzato": {
//                 "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9aba",
//                 "codiceLibreria": "180/001.A",
//                 "titolo": "Mancanza del documento di circolazione\n",
//                 "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
//             }
//         }
//     ]
// });
// mock.onPost("http://localhost:8080/api_remote/presentazione-documenti/modifica/documento").reply(200, {aggId: "ciao"});
// mock.onGet("http://webm.docker.it/api_remote/alert/ricerca/alert").reply(200, longalert);
// mock.onGet(`http://webm.docker.it/api_remote/verbale/ricerca-testo-libero`).reply(200, verbale_ricercaVeloce);
// mock.onGet(`http://webm.docker.it/api_remote/tipo-accertamento/ricerca/tipi-accertamento`).reply(200, ricercaTipiAccertamento);
// mock.onPost(`http://webm.docker.it/api_remote/tipo-accertamento/dettaglio/tipo-accertamento`, {aggId: "9143eaaa-5d99-ed90-6b54-14f609ede7e0"}).reply(200, dettaglioTipoAccertamento);
// mock.onPost(`http://webm.docker.it/api_remote/verbale/comando/modifica-verbale`)
//     .reply(401, {"errors": [
//             {
//                 "key": "eu.opensoftware.exceptions.ModificaVerbalePropietarioException",
//                 "message": "L'utente non è il propietario del verbale"
//             }
//         ]});
//
// mock.onPost(`http://webm.docker.it/api_remote/verbale/comando/aggiungi-verbale`)
//     .reply(401, {"errors": [
//             {
//                 "key": "eu.opensoftware.exceptions.ModificaVerbalePropietarioException",
//                 "message": "L'utente non è il propietario del verbale"
//             }
//         ]});
// mock.onGet(`http://webm.localhost/api_remote/visura/ricerca-veloce`).reply(200, visuraRicercaVeloceData);
//
// mock.onGet(`http://webm.localhost/api_remote/anagrafica-veicolo/ricerca/agente-veicoli`).reply(200, anagraficaVeicoloRicercaAgenteVeicoliData);
//
// mock.onGet(`http://webm.localhost/api_remote/valori-globali/leggi`, {params: {sezione: 'tempiVigore'}}).reply(200, valoriGlobaliLeggiData);
// mock.onGet(`http://webm.localhost/api_remote/comune/ricerca/comuni`).reply(200, {
//     "search": {
//         "page": 1,
//         "order": "aggId,asc",
//         "limit": 1000,
//         "aggId": null,
//         "codiceVeloce": null,
//         "nome": null,
//         "codiceSANA": null,
//         "codiceFlussoCNC": null,
//         "storedList": null,
//         "excludeDb": false,
//         "explodeId": false
//     }, "resultsWrapper": {
//         "values": [
//             {
//             "aggId": "481b7674-e988-4ef4-9b39-0eb9d5a7cf08",
//             "codiceVeloce": "JES",
//             "nome": [{"languageTag": "it_IT", "traduzione": "Jesolo"}],
//             "enteTerritorialeId": "c06e8437-6433-4dbf-a629-373f27c82433",
//             "codiceSANA": "C388",
//             "codiceISTAT": "027019",
//             "codiceFlussoCNC": "C388",
//             "codiceBelfiore": "C388",
//             "nome_transId": "bfd6fc42-8648-488d-bb64-55ce7e695197",
//             "enteTerritoriale": {
//                 "entityId": "c06e8437-6433-4dbf-a629-373f27c82433",
//                 "nome": "Venezia",
//                 "siglaEnteTerritoriale": "VE",
//                 "tipoEnteTerritoriale": "PROVINCIA"
//             }
//         },
//             {
//             "aggId": "96dad7f9-791c-4e52-8a3f-605887af9a57",
//             "codiceVeloce": "SPR",
//             "nome": [{"languageTag": "it_IT", "traduzione": "Spresiano"}],
//             "enteTerritorialeId": "7c56fedf-2c22-4863-9f8f-aa247cfcabb7",
//             "codiceSANA": "I927",
//             "codiceISTAT": "026082",
//             "codiceFlussoCNC": "I927",
//             "codiceBelfiore": "I927",
//             "nome_transId": "e263dfdb-abf8-4744-9ae1-83720ba5ed67",
//             "enteTerritoriale": {
//                 "entityId": "7c56fedf-2c22-4863-9f8f-aa247cfcabb7",
//                 "nome": "Treviso",
//                 "siglaEnteTerritoriale": "TV",
//                 "tipoEnteTerritoriale": "PROVINCIA"
//             }
//         }, {
//             "aggId": "c7f68214-31f8-4165-91b3-66833c3d28bf",
//             "codiceVeloce": "CIT",
//             "nome": [{"languageTag": "it_IT", "traduzione": "Cittadella"}],
//             "enteTerritorialeId": "24360818-c7df-4a72-8a1e-9ce87c9e1ddc",
//             "codiceSANA": "C743",
//             "codiceISTAT": "028032",
//             "codiceFlussoCNC": "C743",
//             "codiceBelfiore": "C743",
//             "nome_transId": "9ea8bcf4-f017-481f-9666-53e1a2d919f1",
//             "enteTerritoriale": {
//                 "entityId": "24360818-c7df-4a72-8a1e-9ce87c9e1ddc",
//                 "nome": "Padova",
//                 "siglaEnteTerritoriale": "PD",
//                 "tipoEnteTerritoriale": "PROVINCIA"
//             }
//         }], "count": 3, "links": []
//     }, "filters": []
// })