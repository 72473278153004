"use strict";

var _InsolutiMasterDetail = _interopRequireDefault(require("./masterDetail/InsolutiMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.insoluti').provider('insolutiRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'insolutiList',
    role: 'ROLE_INSOLUTI_RICERCA',
    url: 'insoluti?{page:int}&{limit:int}&{query:string}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{selectionActive:bool}&{activeItem:string}',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _InsolutiMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Insoluti"
    }
  }];
  routesProviderFactory.extend(this);
}