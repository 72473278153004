"use strict";

/**
 * Created by MN 26/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').controller('LuoghiRicoveroInfortunatiListController', ['$tdsApiService', '$tdsGlobalBehaviors', LuoghiRicoveroInfortunatiListController]);

  function LuoghiRicoveroInfortunatiListController($tdsApiService, $tdsGlobalBehaviors) {
    var luoghiRicoveroInfortunatiListCtrl = this,
        searchParamName = 'descrizione',
        searchCommand = '@ricercaLuoghiRicoveroInfortunati',
        deleteCommand = '@eliminaLuogoRicoveroInfortunati',
        editRoute = 'navigation.luoghiRicoveroInfortunatiTdsEdit',
        entity = 'luogo ricovero infortunato',
        editRole = 'ROLE_RICOVERO_INFORTUNATI_MODIFICA',
        deleteRole = 'ROLE_RICOVERO_INFORTUNATI_CANCELLA',
        editCallback = function (item) {
      $tdsGlobalBehaviors.redirectTo(editRoute, {
        aggId: item.aggId
      });
    },
        deleteCallback = function (items) {
      $tdsApiService.deleteCommand(deleteCommand, items).then(function () {
        loadElementi();
      }).then(function () {
        items.ngSelectedRowModel = [];
      });
    },
        defConfig = $tdsGlobalBehaviors.getDefaultConf(editRoute, entity);
    /**
     * Indica la label da stampare nel placeholder della input box del campo di ricerca
     * @type {string}
     */


    luoghiRicoveroInfortunatiListCtrl.searchLabel = 'descrizione';
    luoghiRicoveroInfortunatiListCtrl.addRole = 'ROLE_RICOVERO_INFORTUNATI_AGGIUNGI'; //Mergio le configurazioni di default nel controller

    _.merge(luoghiRicoveroInfortunatiListCtrl, defConfig);
    /**
     * @property
     * Configurazione delle colonne della tabella
     * @type {*[]}
     */


    luoghiRicoveroInfortunatiListCtrl.column = [{
      text: 'Codice veloce',
      dataIndex: 'codiceVeloce',
      sortable: true
    }, {
      text: 'Descrizione',
      dataIndex: 'descrizione',
      sortable: true
    }, {
      text: 'Comune',
      dataIndex: 'comune',
      sortable: true,
      renderer: function (item) {
        if (!item.comuneId) {
          return '--';
        }

        return item.comune.nomeCompleto;
      }
    }, {
      text: 'Indirizzo',
      dataIndex: 'indirizzo',
      sortable: true,
      renderer: function (item) {
        if (!item.indirizzo) {
          return '--';
        }

        return item.indirizzo + ' - ' + item.civico;
      }
    }];
    /**
     * @property
     * Configurazione delle action column di una riga della tabella
     * @type {*[]}
     */

    luoghiRicoveroInfortunatiListCtrl.actionColumn = $tdsGlobalBehaviors.getActionColumnDefaultConfig(editRole, deleteRole, editCallback, deleteCallback);
    /**
     * @property
     * Confgirazione delle bulk action della tabella
     * @type {*[]}
     */

    luoghiRicoveroInfortunatiListCtrl.bulkActions = $tdsGlobalBehaviors.getBulkActionsDefaultConfig(deleteRole, deleteCallback);
    /**
     * Operazioni eseguite in instanza del componente
     */

    luoghiRicoveroInfortunatiListCtrl.$onInit = function () {
      luoghiRicoveroInfortunatiListCtrl.progress = loadElementi();
    };
    /**
     * @property
     * Handler da eseguire al cambio pagina della tabella
     * @param query
     */


    luoghiRicoveroInfortunatiListCtrl.onPageChange = function (query) {
      var params = $tdsGlobalBehaviors.updateQueryParams(luoghiRicoveroInfortunatiListCtrl.query, {
        limit: query.limit,
        page: query.page
      });
      luoghiRicoveroInfortunatiListCtrl.progress = loadElementi(params);
    };
    /**
     * @property
     * Handler da eseguire al cambio dell'ordinamento di una colonna
     * @param order
     */


    luoghiRicoveroInfortunatiListCtrl.onOrderChange = function (order) {
      var params = $tdsGlobalBehaviors.updateQueryParams(luoghiRicoveroInfortunatiListCtrl.query, {
        order: order
      });
      luoghiRicoveroInfortunatiListCtrl.progress = loadElementi(params);
    };
    /**
     * @property
     * Handler da eseguire quando si effettua una ricerca
     * @param searchText
     */


    luoghiRicoveroInfortunatiListCtrl.searchIt = function (searchText) {
      var thingToSearch = {};
      thingToSearch[searchParamName] = searchText.searchText;

      if (thingToSearch[searchParamName].length > 0) {
        luoghiRicoveroInfortunatiListCtrl.query = $tdsGlobalBehaviors.updateQueryParams(luoghiRicoveroInfortunatiListCtrl.query, thingToSearch);
      } else {
        delete luoghiRicoveroInfortunatiListCtrl.query[searchParamName];
      }

      loadElementi(luoghiRicoveroInfortunatiListCtrl.query);
    };
    /****************
     * Utils
     ****************/


    function loadElementi(prms) {
      var params = prms ? prms : luoghiRicoveroInfortunatiListCtrl.query;
      return $tdsApiService.executeCommand(searchCommand, params).then(function (result) {
        luoghiRicoveroInfortunatiListCtrl.setElementi(result);
      });
    }
  }
})();