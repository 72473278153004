"use strict";

/**
 * Created by macbookpro15tb on 08/09/17.
 *
 * refactored by gbelli on 13/09/2019
 */
(function () {
  'use strict';
  /**
   * @todo improvement handle refreshOn?
   *
   * @ngdoc directive
   * @module
   * @restrict E
   * @name osRicercaEnum
   *
   * @param {string} placeholder
   * @param {string} command the name of the command to execute to populate the suggestions list, optional
   * @param {string} url the url to be used to populate the suggestions list, optional
   * @param {string} method the http method to use with the provided url. Defaults to GET
   * @param {string} name the name of the input, will be used to show ngMessage errors
   * @param {array} pathInResponse the path inside the remote api response where to search for the data to populate the list. Ex. ['data', 'risultato']
   * @param {displayValue} if provided, will pick this prop for each object inside the suggestions list as item label and to give the ngModel a value
   * @param {expression} ngRequired true, false or an expression
   * @param {expression} ngDisabled true, false or an expression
   * @param {object} form the form that contains the directive
   *
   * @example
   * <hljs lang="html">
   * </hljs>
   */

  angular.module('osFramework').directive('osRicercaEnum', ['EnumService', directive]);

  function directive(EnumService) {
    return {
      restrict: 'E',
      require: 'ngModel',
      link: linkFn,
      controllerAs: 'vm',
      templateUrl: 'osFramework/input/osRicercaEnum/osRicercaEnum.view.html',
      scope: {
        type: '@',
        placeholder: '@',
        pathInResponse: '=',
        displayValue: '@',
        ngRequired: '=',
        ngDisabled: '=',
        ngReadonly: '=',
        name: '@',
        form: '='
      }
    };

    function linkFn(scope, element, attrs, ngModelCtrl) {
      scope.$on('osList::quickFilterReset', function () {
        scope.searchText = '';
      }); // Directive API

      scope.searchTextChange = searchTextChange;
      scope.selectedItemChange = selectedItemChange;
      scope.getSuggestions = getSuggestions; /////////////////////////
      // this will get called in case of ng-init on the ngModel, in case the ngModel gets a value from a remoteApi, etc etc

      ngModelCtrl.$render = handleExternalChanges; /////////////////////////
      // Methods implementation

      function handleExternalChanges() {
        if (ngModelCtrl.$viewValue && !scope.selectedItem) {
          EnumService.getEnumItem(scope.type, ngModelCtrl.$viewValue).then(function (item) {
            scope.selectedItem = item;
          });
        }
        /*if( scope.searchText !== ngModelCtrl.$viewValue ) {
            scope.searchText = ngModelCtrl.$viewValue;
        }*/

      } // after this, a filterCache gets triggered by the ngMaterial autocomplete directive


      function searchTextChange(searchedText) {
        ngModelCtrl.$setViewValue(searchedText);
      }

      function selectedItemChange(item) {
        if (!item) {
          ngModelCtrl.$setViewValue(null);
          return;
        }

        ngModelCtrl.$setViewValue(item.alias);
      }
      /**
       * @return {Promise} the promise with the list of values
       */


      function getSuggestions(filter) {
        return EnumService.getEnum(scope.type, filter);
      }
    }
  }
})();