"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.marcaModelloValidationSchema = exports.toApiMarcaModelloVeicolo = exports.fromApiMarcaModelloVeicolo = exports.marcaModelloEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const marcaModelloEmptyForm = {
  marca: "",
  modello: "",
  anno: ""
};
exports.marcaModelloEmptyForm = marcaModelloEmptyForm;

const fromApiMarcaModelloVeicolo = values => {
  const result = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    marca: (0, _object.getPathStringOrEmptyString)(["marca"])(values),
    modello: (0, _object.getPathStringOrEmptyString)(["modello"])(values),
    anno: (0, _object.getPathNumberOrEmptyString)(["anno"])(values)
  };
  return result;
};

exports.fromApiMarcaModelloVeicolo = fromApiMarcaModelloVeicolo;

const toApiMarcaModelloVeicolo = values => {
  const result = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    marca: (0, _object.getPathStringOrEmptyString)(["marca"])(values),
    modello: (0, _object.getPathStringOrEmptyString)(["modello"])(values),
    anno: (0, _object.getPathNumberOrEmptyString)(["anno"])(values)
  };
  return result;
};

exports.toApiMarcaModelloVeicolo = toApiMarcaModelloVeicolo;
const marcaModelloValidationSchema = Yup.object().shape({
  marca: Yup.string().required(_utils.requiredMessage),
  modello: Yup.string().required(_utils.requiredMessage)
});
exports.marcaModelloValidationSchema = marcaModelloValidationSchema;