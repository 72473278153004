"use strict";

/**
 * Created by dan on 02/12/16.
 */
(function () {
  'use strict';
  /**
   * @ngdoc module
   * @name polcity.api
   *
   * @requires polcity.exceptions
   * @requires polcity.ui
   *
   * @description
   * Provides for http requests / responses error interceptors and a overall wrapper on <code>$http</code> angular service
   *
   */

  angular.module('polcity.api', [//'base64',
  'polcity.exceptions', 'ngFileSaver']).constant('AcceptHeaders', {
    pdf: 'application/pdf',
    excel: 'application/vnd.ms-excel'
  }) //.constant('BASE_URL', location.href.indexOf('localhost') > -1 ? 'http://win-p2016-test.polcity.it/resources/'  : [location.protocol, '//', location.host, '/resources'].join(''))
  .constant('ELEMENTS_PER_PAGE', 10);
})();