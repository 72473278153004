"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anagraficaVeicoloRicercaAgenteVeicoliData = void 0;
const anagraficaVeicoloRicercaAgenteVeicoliData = {
  "search": {
    "page": 1,
    "order": "targa,desc:inizioProprieta,desc:tipoVeicoloTipoMezzo,desc",
    "limit": 1000,
    "targa": "FA234TT",
    "inizioProprieta": null,
    "tipoVeicoloTipoMezzo": "AUTOVEICOLO",
    "storedList": null,
    "excludeDb": false,
    "explodeId": false
  },
  "resultsWrapper": {
    "values": [{
      "aggId": "e3eba218-18d9-424a-baa4-6f0b65f7dc0c",
      "toUpdate": false,
      "dataOraUltimaVisura": 1605779915084,
      "anagrafica": {
        "anagraficaId": "7ca18787-96b1-4fe6-9aac-ee54d70e6a32",
        "tipoAnagrafica": "persona_fisica",
        "nome": "ROBERTA",
        "cognome": "ZAMPARINI",
        "sesso": "f",
        "dataNascita": -132105600000,
        "comuneNascitaId": "658da86c-7d3c-4544-ba1e-d8c55d9ec2db",
        "comuneNascita": {
          "comuneId": "658da86c-7d3c-4544-ba1e-d8c55d9ec2db",
          "nome": "Terni",
          "enteTerritorialeId": "b3b96413-dc18-4589-9dfe-184704bb3fea",
          "nomeEnteTerritoriale": "Terni",
          "tipoEnteTerritoriale": "PROVINCIA",
          "statoId": "7556459a-93be-4fd8-868c-23316bfc2fc4",
          "siglaStato": "IT",
          "nomeStato": "Italia",
          "nomeCompleto": "Terni (Terni) Italia"
        },
        "comuneResidenzaId": "658da86c-7d3c-4544-ba1e-d8c55d9ec2db",
        "comuneResidenza": {
          "comuneId": "658da86c-7d3c-4544-ba1e-d8c55d9ec2db",
          "nome": "Terni",
          "enteTerritorialeId": "b3b96413-dc18-4589-9dfe-184704bb3fea",
          "nomeEnteTerritoriale": "Terni",
          "tipoEnteTerritoriale": "PROVINCIA",
          "statoId": "7556459a-93be-4fd8-868c-23316bfc2fc4",
          "siglaStato": "IT",
          "nomeStato": "Italia",
          "nomeCompleto": "Terni (Terni) Italia"
        },
        "indirizzoResidenza": " VIA MACINAROTTA,3/B",
        "civicoResidenza": null,
        "comuneDomicilioId": "658da86c-7d3c-4544-ba1e-d8c55d9ec2db",
        "comuneDomicilio": {
          "comuneId": "658da86c-7d3c-4544-ba1e-d8c55d9ec2db",
          "nome": "Terni",
          "enteTerritorialeId": "b3b96413-dc18-4589-9dfe-184704bb3fea",
          "nomeEnteTerritoriale": "Terni",
          "tipoEnteTerritoriale": "PROVINCIA",
          "statoId": "7556459a-93be-4fd8-868c-23316bfc2fc4",
          "siglaStato": "IT",
          "nomeStato": "Italia",
          "nomeCompleto": "Terni (Terni) Italia"
        },
        "indirizzoDomicilio": " VIA MACINAROTTA,3/B",
        "civicoDomicilio": null,
        "comuneSedeLegaleId": null,
        "comuneSedeLegale": {
          "comuneId": null,
          "nome": null,
          "enteTerritorialeId": null,
          "nomeEnteTerritoriale": null,
          "tipoEnteTerritoriale": null,
          "statoId": null,
          "siglaStato": null,
          "nomeStato": null,
          "nomeCompleto": "null (null) null"
        },
        "indirizzoSedeLegale": null,
        "civicoSedeLegale": null,
        "ragioneSociale": null,
        "rappresentanteLegale": null,
        "telefono": null,
        "cfPi": "ZMPRRT65R65L117C",
        "email": null,
        "pec": null
      },
      "veicolo": {
        "veicoloId": "7556459a-93be-4fd8-868c-23316bfc2fc4",
        "tipoVeicoloId": null,
        "tipoVeicolo": {
          "tipoVeicoloId": null,
          "codiceVeloce": "001",
          "tipoMezzo": "AUTOVEICOLO",
          "classificazioneMotorizzazione": null,
          "descrizione": "Autoveicolo"
        },
        "targa": "FA234TT",
        "marca": "SUZUKI",
        "modello": "JIMNY (5MT B43V FJ)",
        "colore": null,
        "statoId": null,
        "inizioProprieta": 1553644800000,
        "fineProprieta": null,
        "dataOraUltimaVisura": 1605779915084,
        "scadenzaAssicurazione": null,
        "ultimaRevisione": 1574899200000
      }
    }],
    "count": 1,
    "links": []
  },
  "filters": []
};
exports.anagraficaVeicoloRicercaAgenteVeicoliData = anagraficaVeicoloRicercaAgenteVeicoliData;