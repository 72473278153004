"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tabelleDiSupporto = void 0;

var _roles = require("../../roles.costants");

const tabelleDiSupporto = [{
  section: "Tabelle generali",
  tabelle: [{
    section: "Tabelle generali",
    name: "Stati",
    listRole: _roles.roleStatoRicerca,
    listRoute: "navigation.tdsNazioni"
  }, {
    section: "Tabelle generali",
    name: "Gestione del territorio",
    listRole: _roles.roleComuneRicerca,
    listRoute: "navigation.tdsGestioneDelTerritorio"
  }, {
    section: "Tabelle generali",
    name: "Comuni",
    listRole: _roles.roleRiferimentoComuneRicerca,
    listRoute: "navigation.tdsRiferimentiComuni"
  }, {
    section: "Tabelle generali",
    name: "Tipologie rimozioni",
    listRole: _roles.roleTipologiaRimozioneRicerca,
    listRoute: "navigation.tdsTipologieRimozioni"
  }, {
    section: "Tabelle generali",
    name: "Marche e modelli veicoli",
    listRole: _roles.roleMarcaModelloVeicoloRicerca,
    listRoute: "navigation.tdsMarcheModelliVeicoli"
  }, {
    section: "Tabelle generali",
    name: "Depositi fermi e sequestri",
    listRole: _roles.roleDepositoFermiSequestriRicerca,
    listRoute: "navigation.tdsDepositiFermiSequestri"
  }, {
    section: "Tabelle generali",
    name: "Contatori protocollo",
    listRole: _roles.roleContatoreProtocolloRicerca,
    listRoute: "navigation.tdsContatoriProtocollo"
  }, {
    section: "Tabelle generali",
    name: "Contenuti degli allegati",
    listRole: _roles.roleContenutoAllegatoRicerca,
    listRoute: "navigation.tdsContenutiAllegati"
  }, {
    section: "Tabelle generali",
    name: "Funzioni di stampa",
    listRole: _roles.roleFunzioneStampaRicerca,
    listRoute: "navigation.tdsFunzioniStampa"
  }, {
    section: "Tabelle generali",
    name: "Lingue",
    listRole: _roles.roleLinguaRicerca,
    listRoute: "navigation.tdsLingue"
  }, {
    section: "Tabelle generali",
    name: "Localizzazione",
    listRole: _roles.roleLocalizzazioneRicerca,
    listRoute: "navigation.tdsLocalizzazioni"
  }, {
    section: "Tabelle generali",
    name: "Enti territoriali",
    listRole: _roles.roleEnteTerritorialeRicerca,
    listRoute: "navigation.tdsEntiTerritoriali"
  }, {
    section: "Tabelle generali",
    name: "Enti",
    listRole: _roles.roleEnteRicerca,
    listRoute: "navigation.tdsEnti"
  }, {
    section: "Tabelle generali",
    name: "Carroattrezzi",
    listRole: _roles.roleCarroAttrezziRicerca,
    listRoute: "navigation.tdsCarroAttrezzi"
  }, {
    section: "Tabelle generali",
    name: "Stazioni comando",
    listRole: _roles.roleStazioneComandoRicerca,
    listRoute: "navigation.tdsStazioniComandoTds"
  }]
}, {
  section: "Procedimento sanzionatorio",
  tabelle: [{
    section: "Procedimento sanzionatorio",
    name: "Tipi di veicolo",
    listRole: _roles.roleTipoVeicoloRicerca,
    listRoute: "navigation.tdsTipiVeicolo"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Tipi di proprietà",
    listRole: _roles.roleTipoProprietaRicerca,
    listRoute: "navigation.tdsTipiProprieta"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Tipi di bollettario",
    listRole: _roles.roleBollettarioRicerca,
    listRoute: "navigation.tdsTipiBollettari"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Tipi di accertamento",
    listRole: _roles.roleTipoAccertamentoRicerca,
    listRoute: "navigation.tdsTipiAccertamento"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Direzioni di marcia dei veicoli",
    listRole: _roles.roleDirezioneMarciaVeicoliRicerca,
    listRoute: "navigation.tdsDirezioniMarciaVeicoli"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Motivazioni archiviazione accertamento",
    listRole: _roles.roleMotivazioneArchiviazioneAccertamentiRicerca,
    listRoute: "navigation.tdsMotivazioniArchiviazioniAccertamenti"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Relazioni nome sesso",
    listRole: _roles.roleRelazioneNomeSessoRicerca,
    listRoute: "navigation.tdsRelazioniNomeSesso"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Attività produttive",
    listRole: _roles.roleAttivitaProduttivaRicerca,
    listRoute: "navigation.tdsAttivitaProduttive"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Motivazioni mancata contestazione",
    listRole: _roles.roleMotivazioneMancataContestazioneRicerca,
    listRoute: "navigation.tdsMotivazioniMancataContestazione"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Sanzioni accessorie",
    listRole: _roles.roleSanzioneAccessoriaRicerca,
    listRoute: "navigation.tdsSanzioniAccessorie"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Rilevatori di infrazione",
    listRole: _roles.roleRilevatoreInfrazioniRicerca,
    listRoute: "navigation.tdsRilevatoriInfrazioni"
  }, {
    section: "Procedimento sanzionatorio",
    name: "Motivazioni mancata applicazione",
    listRole: _roles.roleMotivazioneMancataApplicazioneRicerca,
    listRoute: "navigation.tdsMotivazioniMancataApplicazione"
  }]
}];
exports.tabelleDiSupporto = tabelleDiSupporto;