"use strict";

/**
 * Created by dan on 16/03/17.
 */
(function () {
  'use strict';
  /**
   * @ngdoc service
   * @module polcity.cache
   * @name AliasToApi
   *
   * @description
   * Registers for a new index in cache a new url to be fetched
   *
   * @usage
   * <hljs language="js">
   *     //basic
   *     AliasToApi.add('tipi.statiRicorso', 'ricorso/lista/stati-ricorso');
   *
   *     //advanced
   *     AliasToApi.add('tipi.statiRicorso', {url: 'ricorso/lista/stati-ricorso', id: 'aggId', label: 'name'});
   * </hljs>
   *
   */

  angular.module('polcity.factories').factory('AliasToApi', [AliasToApi]);

  function AliasToApi() {
    var aliasToApiMap = {};
    return {
      add: add,
      getApiUrl: getApiUrl
    };
    /**
     * @ngdoc method
     * @name AliasToApi#add
     * @param {string} alias the cache index where to store the url response. Must contain a dot ('.'). Ex: veicoli.tipo
     * @param {object} props an object describing the request to be made to fetch the data to store inside the cache
     *  - `url` - `{string}` the url to fetch
     *  - `id` - `{string}` the name of the key used to retrieve object from cache, defaults to `'alias'`
     *  - `label` - `{string}` the name of the property that will be fetched from the object retrieved from cache and put in view. Defaults to `'label'`
     */

    function add(alias, props) {
      if (alias.indexOf('.') === -1) {
        throw Error('invalid alias format');
      }

      if (R.is(String, props)) {
        props = {
          'url': props
        };
      } else if (R.is(Object, props)) {
        // o contiene una tra url id e label o "si rompe"
        var verifyProps = R.pick(['url', 'id', 'label'], props);

        if (!verifyProps.url) {
          throw Error('unsupported props format - missing url');
        }
      } else {
        // si rompe
        throw Error('unsupported props format');
      }

      if (!isValidUrl(props.url)) {
        throw Error('unsupported props format - invalid url');
      }

      aliasToApiMap[alias] = props;
      return true;
    }

    function isValidUrl(url) {
      return url.indexOf('/') > -1;
    }

    function getApiUrl(alias) {
      return aliasToApiMap[alias];
    }
  }
})();