"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.valoriGlobaliLeggiData = void 0;
const valoriGlobaliLeggiData = {
  "ggPagamentoMisuraRidotta": {
    "valore": 60,
    "tipo": "Integer"
  },
  "ggTrasmissioneRicorso": {
    "valore": 30,
    "tipo": "Integer"
  },
  "ggPagamentoIngiunzione": {
    "valore": 30,
    "tipo": "Integer"
  },
  "ggTrasmissionePuntiPatente": {
    "valore": 30,
    "tipo": "Integer"
  },
  "ggTolleranzaTrasmissionePuntiPatente": {
    "valore": 0,
    "tipo": "Integer"
  },
  "ggPresentazioneRicorso": {
    "valore": 60,
    "tipo": "Integer"
  },
  "valoriPresentazioneDocumenti": {
    "ggPresentazioneDocumenti": {
      "valore": 10,
      "tipo": "Integer"
    },
    "infrazioneAssicurazione16_30": {
      "valore": "00000000-0000-03af-0000-0000000003af",
      "tipo": "UUID"
    },
    "infrazioneAssicurazioneScaduta": {
      "valore": "00000000-0000-03af-0000-0000000003af",
      "tipo": "UUID"
    },
    "revisione": {
      "valore": "00000000-0000-00d3-0000-0000000000d3",
      "tipo": "UUID"
    },
    "revisioneRecidiva": {
      "valore": "00000000-0000-00d5-0000-0000000000d5",
      "tipo": "UUID"
    },
    "esposizioneTargaProva": {
      "valore": "00000000-0000-011a-0000-00000000011a",
      "tipo": "UUID"
    }
  },
  "ggInvioSegnalazioniPatente": {
    "valore": 80,
    "tipo": "Integer"
  },
  "ggCalcoloDataScadenzaDati": {
    "valore": 2,
    "tipo": "Integer"
  }
};
exports.valoriGlobaliLeggiData = valoriGlobaliLeggiData;