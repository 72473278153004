"use strict";

var _LingueList = _interopRequireDefault(require("./LingueList"));

var _LinguaEditRoot = _interopRequireDefault(require("./edit/LinguaEditRoot"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.tds').provider('lingueTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'tdsLingue',
    url: 'tds/lingue',
    role: 'ROLE_LINGUA_RICERCA',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _LingueList.default
      }
    },
    resolve: {
      $label: () => "Lingue"
    }
  }, {
    name: 'tdsLingueEdit',
    url: 'tds/lingue/{entityId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_LINGUA_MODIFICA',
    params: {
      entityId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _LinguaEditRoot.default
      }
    },
    resolve: {
      $label: () => "Edita lingua"
    }
  }];
  routesProviderFactory.extend(this);
}