"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tipologiaRimozioneValidationSchema = exports.toApiTipologiaRimozione = exports.fromApiTipologiaRimozione = exports.tipologiaRimozioneEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const tipologiaRimozioneEmptyForm = {
  codiceVeloce: "",
  competenza: "",
  descrizione: [],
  dislocazione: ""
};
exports.tipologiaRimozioneEmptyForm = tipologiaRimozioneEmptyForm;

const fromApiTipologiaRimozione = values => {
  const result = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(values),
    competenza: (0, _object.getPathStringOrEmptyString)(["competenza"])(values),
    descrizione: (0, _object.getPathArrayOrEmptyArray)(["descrizione"])(values),
    descrizione_transId: (0, _object.getPathStringOrEmptyString)(["descrizione_transId"])(values)
  };
  return result;
};

exports.fromApiTipologiaRimozione = fromApiTipologiaRimozione;

const toApiTipologiaRimozione = values => {
  const result = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    codiceVeloce: (0, _object.getPathStringOrEmptyString)(["codiceVeloce"])(values),
    competenza: (0, _object.getPathStringOrEmptyString)(["competenza"])(values),
    descrizione: (0, _object.getPathArrayOrEmptyArray)(["descrizione"])(values),
    descrizione_transId: (0, _object.getPathStringOrEmptyString)(["descrizione_transId"])(values)
  };
  return result;
};

exports.toApiTipologiaRimozione = toApiTipologiaRimozione;
const tipologiaRimozioneValidationSchema = Yup.object().shape({
  codiceVeloce: Yup.string().required(_utils.requiredMessage),
  competenza: Yup.string().required(_utils.requiredMessage),
  descrizione: Yup.array().ensure().min(1, _utils.requiredMessage).of(Yup.object().shape({
    traduzione: Yup.string().required(_utils.requiredMessage),
    languageTag: Yup.string().required(_utils.requiredMessage)
  }))
});
exports.tipologiaRimozioneValidationSchema = tipologiaRimozioneValidationSchema;