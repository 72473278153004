"use strict";

/**
 * Created by MN 12/02/2020
 */
(function () {
  'use strict';

  angular.module('polcity.widgetTypes').component('chartWidgetComponent', {
    templateUrl: 'dashboard/widgetTypes/chart/chart.widget.component.html',
    controller: 'ChartWidgetComponentController',
    controllerAs: '$ctrl',
    bindings: {
      chartWidgetData: '<'
    }
  }).controller('ChartWidgetComponentController', ['$scope', ChartWidgetComponentController]);

  function ChartWidgetComponentController($scope) {
    var me = this;
    me.noDataAvaiable = false;
    me.selectableCharts = [{
      type: 'pie',
      label: 'Torta'
    }, {
      type: 'bar',
      label: 'Istogramma'
    }, {
      type: 'radar',
      label: 'Radar'
    }, {
      type: 'polarArea',
      label: 'Polar'
    }];
    $scope.$watch(() => me.chartWidgetData.data, function (newVal, oldVal) {
      onInit();
    }, true);
    me.$onInit = onInit;

    function onInit() {
      if (!me.chartWidgetData) {
        return;
      }

      if (me.chartWidgetData.data && me.chartWidgetData.data.length) {
        manageDataResponse();
      } else {
        me.noDataAvaiable = true;
      }
    }
    /**
     * Watchers
     */


    $scope.$watch(() => me.chartWidgetData.chartType, function (newVal) {
      if (newVal === 'bar') {
        setBarChartOptions();
      } else if (newVal === 'pie' || newVal === 'polarArea') {
        setPieChartOptions();
      } else if (newVal === 'radar') {
        setRadarChartOptions();
      }
    });
    /**
     * Handlers
     */

    me.onOpenSettingsMenu = function ($mdMenu) {
      $mdMenu.open();
    };

    me.onSelectChart = function (_chart) {
      setChartType(_chart);
    };

    me.onRemoveWidget = function (_widget) {
      $scope.$emit('remove-widget', _widget);
    };
    /**
     * Methods implementation
     */


    function manageDataResponse() {
      var labels = [],
          values = [];
      R.forEach(function (o) {
        labels.push(o.value);
        values.push(o.count);
      }, me.chartWidgetData.data);
      me.chartWidgetData.labels = labels;
      me.chartWidgetData.values = values;
    }
    /**
     * Setters
     */


    function setChartType(_type) {
      me.chartWidgetData.chartType = _type;
    }

    function setBarChartOptions() {
      me.chartWidgetData.options = {
        responsive: true,
        legend: {
          display: false
        },
        tooltips: {
          titleFontSize: 20,
          bodyFontSize: 20
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontSize: 15
            }
          }]
        }
      };
    }

    function setPieChartOptions() {
      me.chartWidgetData.options = {
        responsive: true,
        legend: {
          display: true,
          position: 'left',
          labels: {
            fontSize: 15
          }
        },
        tooltips: {
          titleFontSize: 20,
          bodyFontSize: 20
        },
        scales: false
      };
    }

    function setRadarChartOptions() {
      me.chartWidgetData.options = {
        legend: {
          display: false
        },
        tooltips: {
          titleFontSize: 20,
          bodyFontSize: 20
        }
      };
    }
  }
})();