"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tutorialsMap = exports.decurtazionePuntiTutorialList = exports.presentazioneDocumentiTutorialList = exports.visureTutorialList = exports.verbalizzazioneAllegatoNonAssociatoTutorialList = exports.tdsTutorialList = exports.cassaTutorialList = exports.verbaliTutorialList = exports.dashboardTutorialList = void 0;

var _linkTutorials = require("../../linkTutorials.constants");

const dashboardTutorialList = [{
  label: 'Tutorial dashboard',
  tutorialUrl: _linkTutorials.dashboardTutorialNew
}];
exports.dashboardTutorialList = dashboardTutorialList;
const verbaliTutorialList = [{
  label: 'Tutorial verbale',
  tutorialUrl: _linkTutorials.verbaleTutorialNew
}];
exports.verbaliTutorialList = verbaliTutorialList;
const cassaTutorialList = [{
  label: 'Tutorial cassa',
  tutorialUrl: _linkTutorials.cassaTutorialNew
}];
exports.cassaTutorialList = cassaTutorialList;
const tdsTutorialList = [{
  label: 'Tutorial tabelle di supporto',
  tutorialUrl: _linkTutorials.tabelleSupportoTutorialNew
}];
exports.tdsTutorialList = tdsTutorialList;
const verbalizzazioneAllegatoNonAssociatoTutorialList = [{
  label: 'Tutorial verbalizzazione allegato non associato',
  tutorialUrl: _linkTutorials.verbalizzazioneAllegatoNonAssociatoTutorialNew
}];
exports.verbalizzazioneAllegatoNonAssociatoTutorialList = verbalizzazioneAllegatoNonAssociatoTutorialList;
const visureTutorialList = [{
  label: 'Tutorial visure',
  tutorialUrl: _linkTutorials.visureTutorialNew
}];
exports.visureTutorialList = visureTutorialList;
const presentazioneDocumentiTutorialList = [{
  label: 'Tutorial presentazione documenti',
  tutorialUrl: _linkTutorials.presentazioneDocumentiTutorialNew
}];
exports.presentazioneDocumentiTutorialList = presentazioneDocumentiTutorialList;
const decurtazionePuntiTutorialList = [{
  label: 'Tutorial decurtazione punti',
  tutorialUrl: _linkTutorials.decurtazionePuntiTutorialNew
}];
exports.decurtazionePuntiTutorialList = decurtazionePuntiTutorialList;
const tutorialsMap = {
  dashboard: dashboardTutorialList,
  verbali: verbaliTutorialList,
  cassa: cassaTutorialList,
  tds: tdsTutorialList,
  allegatiDigitaliNonAssociati: verbalizzazioneAllegatoNonAssociatoTutorialList,
  visure: visureTutorialList,
  presentazioneDocumenti: presentazioneDocumentiTutorialList,
  decurtazioni: decurtazionePuntiTutorialList
};
exports.tutorialsMap = tutorialsMap;