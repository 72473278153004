"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scrollAndFocusElementIntoView = exports.getMimeTypeFromBase64String = exports.getFileType = exports.useHash = exports.formatDate = exports.formatDateTime = exports.todayStartDate = exports.todayEndDay = exports.isImportoInVigore = exports.insertValueOrReplacer = exports.removeDuplicatesFromArray = exports.logInfo = exports.logError = exports.fileExtensionToMimeTypeMap = exports.trakingNuneroRaccomandataPosteItaliane = exports.defaultTsDateTimeFormat = exports.defaultTsDateFormat = exports.defaultDateTimeFormat = exports.defaultDateFormat = exports.beautifyResidenzaDomicilioSedeLegale = exports.getNominativoFromAnagrafica = exports.isEllipsisActive = exports.sortArrOfObjAlphabeticallyByKey = exports.generaCodiceFiscale = exports.requiredMessage = exports.unique = exports.replaceUnderscore = exports.removeFromObj = exports.insertAt = exports.removeAt = exports.replaceAt = exports.insertIf = exports.formatCurrency = exports.getFileNameFromResponseHeaders = exports.sentenceFromCamelCaseString = exports.locationHashRegularExp = exports.cfRegExp = exports.pivaRegExp = exports.capitalize = exports.transformImageToPdf = exports.downloadFile = exports.downloadBlob = exports.byteArrayToBlob = exports.traverseJson = exports.removeFalse = exports.removeNulls = exports.dispatchCustomEvent = exports.createCustomNotificationEvent = exports.parseRtf = exports.throwErr = exports.isValidAggId = exports.debouncePromise = exports.timeout = exports.openNewWindowTab = exports.fromCamelsToDashes = exports.appendMerge = exports.overwriteMerge = exports.deepmerge = exports.leftMerge = exports.deepEqual = void 0;

var _d3Format = require("d3-format");

var _jspdf = _interopRequireDefault(require("jspdf"));

var _codiceFiscaleJs = _interopRequireDefault(require("codice-fiscale-js"));

var _react = _interopRequireWildcard(require("react"));

var _endOfDay = _interopRequireDefault(require("date-fns/endOfDay"));

var _startOfDay = _interopRequireDefault(require("date-fns/startOfDay"));

var _format = _interopRequireDefault(require("date-fns/format"));

var _fileType = _interopRequireDefault(require("file-type"));

var _seamlessScrollPolyfill = require("seamless-scroll-polyfill");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const validate = require('uuid-validate');

const rtfToHTML = require('@iarna/rtf-to-html');

const deepEqual = require('deep-equal');

exports.deepEqual = deepEqual;
const DEBUG = window._env_.DEBUG;
const IMPORTO_INVIGORE_RIGHT_EXTREME = window._env_.IMPORTO_INVIGORE_RIGHT_EXTREME;
const trakingHost = window._env_.TRACKING_NUMERO_RACCOMANDATA_POSTEITALIANE;

const leftMerge = (a, b) => Object.keys(a).reduce((r, key) => ({ ...r,
  [key]: (key in b ? b : a)[key]
}), {});

exports.leftMerge = leftMerge;

const deepmerge = require("deepmerge");

exports.deepmerge = deepmerge;

const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

exports.overwriteMerge = overwriteMerge;

const appendMerge = (destinationArray, sourceArray) => [...destinationArray, ...sourceArray];

exports.appendMerge = appendMerge;

const fromCamelsToDashes = str => str.replace(/[A-Z]/g, m => "-" + m.toLowerCase());

exports.fromCamelsToDashes = fromCamelsToDashes;

const openNewWindowTab = (url, target, features) => window.open(url, target, features);

exports.openNewWindowTab = openNewWindowTab;

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

exports.timeout = timeout;

const debouncePromise = require('debounce-promise');

exports.debouncePromise = debouncePromise;

const isValidAggId = aggId => validate(aggId); // In attesa di poter usare throw nel contesto di una espressione
// https://github.com/tc39/proposal-throw-expressions


exports.isValidAggId = isValidAggId;

const throwErr = msg => {
  throw new Error(msg);
};

exports.throwErr = throwErr;

const parseRtf = rtfString => new Promise((resolve, reject) => {
  rtfToHTML.fromString(rtfString, {
    template: (doc, defaults, content) => {
      return content;
    }
  }, (err, html) => {
    if (err) reject(err);
    resolve(html);
  });
});

exports.parseRtf = parseRtf;

const createCustomNotificationEvent = eventData => new CustomEvent('show-custom-notification-event', {
  detail: eventData
});

exports.createCustomNotificationEvent = createCustomNotificationEvent;

const dispatchCustomEvent = event => window.dispatchEvent(event);

exports.dispatchCustomEvent = dispatchCustomEvent;

const removeNulls = obj => Object.keys(obj).reduce((acc, value) => obj[value] == null ? acc : { ...acc,
  [value]: obj[value]
}, {});

exports.removeNulls = removeNulls;

const removeFalse = obj => Object.keys(obj).reduce((acc, value) => !Boolean(obj[value]) ? acc : { ...acc,
  [value]: obj[value]
}, {});

exports.removeFalse = removeFalse;

const traverseJson = (obj, parseLeaf) => Object.keys(obj).reduce((acc, key) => {
  return obj[key] instanceof Object ? Array.isArray(obj[key]) ? { ...acc,
    [key]: obj[key].map(item => item instanceof Object ? traverseJson(item, parseLeaf) : item)
  } : { ...acc,
    [key]: traverseJson(obj[key], parseLeaf)
  } : { ...acc,
    [key]: parseLeaf(obj, key)
  };
}, {});

exports.traverseJson = traverseJson;

const byteArrayToBlob = (byte, type) => new Blob([byte], {
  type: type || "application/pdf"
});

exports.byteArrayToBlob = byteArrayToBlob;

const downloadBlob = (reportName, blob) => {
  let link = document.createElement('a');
  const fileName = reportName;
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

exports.downloadBlob = downloadBlob;

const downloadFile = (data, type, fileName) => {
  const blobFile = byteArrayToBlob(data, type);
  downloadBlob(fileName, blobFile);
};

exports.downloadFile = downloadFile;

const transformImageToPdf = imageData => {
  const doc = new _jspdf.default();
  doc.deletePage(1);
  doc.addPage();
  doc.addImage(imageData, 'JPEG', 0, 0, 210, 297);
  const pdfURL = doc.output("bloburl");
  return pdfURL;
};

exports.transformImageToPdf = transformImageToPdf;

const capitalize = str => {
  if (!str) return "";
  const [head, ...tail] = str;
  return `${head.toUpperCase()}${tail.join("").toLowerCase()}`;
};

exports.capitalize = capitalize;
const pivaRegExp = /^[0-7]{1}\d{6}(0{2}[1-9]{1}|0{1}[1-9]{1}\d{1}|100|120|121|888|999)\d{1}$/i;
exports.pivaRegExp = pivaRegExp;
const cfRegExp = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
exports.cfRegExp = cfRegExp;
const locationHashRegularExp = /^(#\/)(.*?)(?:[\/|?]|$)/i;
exports.locationHashRegularExp = locationHashRegularExp;
const sentenceFromCamelCaseString = /^([a-z0-9])([A-Z])/g;
exports.sentenceFromCamelCaseString = sentenceFromCamelCaseString;

const getFileNameFromResponseHeaders = headers => headers['content-disposition'].split('filename=')[1].replace(/['"]/g, '') || '';

exports.getFileNameFromResponseHeaders = getFileNameFromResponseHeaders;
const locale = (0, _d3Format.formatLocale)({
  decimal: ",",
  thousands: ".",
  grouping: [3],
  currency: ["", " €"]
});
const formatCurrency = locale.format("$.2f"); //conditionally insert into array

exports.formatCurrency = formatCurrency;

const insertIf = (cond, ...items) => cond ? items : []; // Replace an item in array without mutation


exports.insertIf = insertIf;

const replaceAt = (array, index, value) => {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}; // Remove an item from array without mutation


exports.replaceAt = replaceAt;

const removeAt = (array, index) => array.filter((item, i) => i != index); // Insert in array without mutation


exports.removeAt = removeAt;

const insertAt = (arr, index, newItem) => [// part of the array before the specified index
...arr.slice(0, index), // inserted item
newItem, // part of the array after the specified index
...arr.slice(index)];

exports.insertAt = insertAt;

const removeFromObj = (obj, propName) => Object.keys(obj).reduce((acc, key) => key !== propName ? { ...acc,
  [key]: obj[key]
} : acc, {});

exports.removeFromObj = removeFromObj;

const replaceUnderscore = (str = '') => str.replaceAll('_', ' '); // Rende unici gli elementi di un array in base alla compareFn


exports.replaceUnderscore = replaceUnderscore;

const unique = (arr, compareFn) => arr.reduce((acc, val) => {
  const alreadyPresent = acc.find(item => compareFn(item, val));
  return [...acc, ...insertIf(!Boolean(alreadyPresent), val)];
}, []);

exports.unique = unique;
const requiredMessage = "Questo campo è obbligatorio";
exports.requiredMessage = requiredMessage;

const generaCodiceFiscale = data => {
  let cf;

  try {
    cf = new _codiceFiscaleJs.default(data);
  } catch (e) {
    cf = '';
  }

  return cf;
};

exports.generaCodiceFiscale = generaCodiceFiscale;

const sortArrOfObjAlphabeticallyByKey = (arr, key) => arr.sort((a, b) => a[key].localeCompare(b[key]));

exports.sortArrOfObjAlphabeticallyByKey = sortArrOfObjAlphabeticallyByKey;

const isEllipsisActive = element => Boolean(element.offsetWidth < element.scrollWidth);

exports.isEllipsisActive = isEllipsisActive;

const getNominativoFromAnagrafica = anagrafica => anagrafica.tipoAnagrafica == "persona_fisica" ? `${capitalize(anagrafica.nome)}, ${capitalize(anagrafica.cognome)}` : `${capitalize(anagrafica.ragioneSociale)}`;

exports.getNominativoFromAnagrafica = getNominativoFromAnagrafica;

const beautifyResidenzaDomicilioSedeLegale = (indirizzo, civico, cap, comune) => `${capitalize(indirizzo)} ${Boolean(civico) ? civico.concat(",") : ""} ${Boolean(cap) ? cap : ""} ${comune.nomeRiferimentoComune}`;

exports.beautifyResidenzaDomicilioSedeLegale = beautifyResidenzaDomicilioSedeLegale;
const defaultDateFormat = "DD/MM/YYYY";
exports.defaultDateFormat = defaultDateFormat;
const defaultDateTimeFormat = "DD/MM/YYYY [ore] HH:mm";
exports.defaultDateTimeFormat = defaultDateTimeFormat;
const defaultTsDateFormat = "dd/MM/y";
exports.defaultTsDateFormat = defaultTsDateFormat;
const defaultTsDateTimeFormat = "dd/MM/y 'ore' HH:mm";
exports.defaultTsDateTimeFormat = defaultTsDateTimeFormat;

const trakingNuneroRaccomandataPosteItaliane = nr => `${trakingHost}/${nr}`;

exports.trakingNuneroRaccomandataPosteItaliane = trakingNuneroRaccomandataPosteItaliane;
const fileExtensionToMimeTypeMap = {
  gif: "image/gif",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  jpe: "image/jpeg",
  png: "image/png",
  svg: "image/svg+xml",
  tiff: "image/tiff",
  tif: "image/tiff",
  bmp: "image/bmp",
  pdf: "application/pdf"
};
exports.fileExtensionToMimeTypeMap = fileExtensionToMimeTypeMap;

const log = severity => message => {
  if (DEBUG) {
    if (severity === "error") {
      console.error(message);
      return;
    }

    console.log(message);
  }
};

const logError = log("error");
exports.logError = logError;
const logInfo = log("info");
exports.logInfo = logInfo;

const removeDuplicatesFromArray = items => [...new Set(items)];

exports.removeDuplicatesFromArray = removeDuplicatesFromArray;

const insertValueOrReplacer = (value, replacer = "--") => value ? value : replacer; // O facciamo il parseInt della property che arriva dal .env (stringa) o non usiamo l'uguaglianza stretta ( === )


exports.insertValueOrReplacer = insertValueOrReplacer;

const isImportoInVigore = inVigoreAl => inVigoreAl == IMPORTO_INVIGORE_RIGHT_EXTREME;

exports.isImportoInVigore = isImportoInVigore;
const todayEndDay = (0, _endOfDay.default)(new Date());
exports.todayEndDay = todayEndDay;
const todayStartDate = (0, _startOfDay.default)(new Date());
exports.todayStartDate = todayStartDate;

const formatDateTime = date => (0, _format.default)(date, defaultTsDateTimeFormat);

exports.formatDateTime = formatDateTime;

const formatDate = date => (0, _format.default)(date, defaultTsDateFormat);

exports.formatDate = formatDate;

const useHash = () => {
  const [hash, setHash] = (0, _react.useState)(() => window.location.hash);
  const hashChangeHandler = (0, _react.useCallback)(() => {
    setHash(window.location.hash);
  }, []);
  (0, _react.useEffect)(() => {
    window.addEventListener('transitionchange', hashChangeHandler);
    return () => {
      window.removeEventListener('transitionchange', hashChangeHandler);
    };
  }, []);
  return hash;
};

exports.useHash = useHash;

const getFileType = contentType => {
  let fileType = '';

  if (contentType.indexOf("pdf") != -1) {
    fileType = 'application/pdf';
  } else if (contentType.indexOf("png") != -1) {
    fileType = 'image/png';
  } else if (contentType.indexOf("jpeg") != -1) {
    fileType = 'image/jpeg';
  }

  return fileType;
};

exports.getFileType = getFileType;

const getMimeTypeFromBase64String = base64Data => {
  const ft = (0, _fileType.default)(Buffer.from(base64Data, "base64"));
  return Boolean(ft) ? ft.mime : "application/octet-stream";
};

exports.getMimeTypeFromBase64String = getMimeTypeFromBase64String;

const scrollAndFocusElementIntoView = id => {
  if (!Boolean(id)) return;
  const el = document.getElementById(id);

  if (Boolean(el)) {
    (0, _seamlessScrollPolyfill.scrollIntoView)(el, {
      behavior: "smooth",
      block: "center",
      inline: "center"
    });
    el.focus({
      preventScroll: true
    });
  }
};

exports.scrollAndFocusElementIntoView = scrollAndFocusElementIntoView;