"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verbale_ricercaVeloce = void 0;
const verbale_ricercaVeloce = {
  "search": {
    "page": 1,
    "order": null,
    "limit": 1000,
    "codiceNomeIndice": "elastic.index.verbali",
    "noLimit": false,
    "query": "15",
    "storedList": ["idVerbale", "numeroRegistro", "dataOraVerbale", "targa", "statoVerbale", "luogoAccertamento", "preferito", "obbligato", "trasgressore", "quarto_campo"],
    "excludeDb": true,
    "explodeId": false
  },
  "resultsWrapper": {
    "values": [{
      "highlights": {},
      "aggId": "2ecbacaa-dad5-41f5-942f-c4738eedb993",
      "idVerbale": "153346536/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630003080000,
      "targa": "VI033279",
      "luogoAccertamento": "VIA GRANZE ",
      "numeroRegistro": "1268/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027892021110039",
      "trasgressore": "FIN LUCA",
      "obbligato": "NNNN 28315 AZIENDA AGRICOLA"
    }, {
      "highlights": {},
      "aggId": "793ac4e6-b336-48f7-bb51-41a2a9b6fb80",
      "idVerbale": "153346439/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630000500000,
      "targa": "VI033279",
      "luogoAccertamento": "VIA GRANZE ",
      "numeroRegistro": "1269/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027902021110022",
      "trasgressore": "FIN LUCA",
      "obbligato": "NNNN 28315 AZIENDA AGRICOLA"
    }, {
      "highlights": {},
      "aggId": "ede526e8-8d74-42fb-886f-ef84a6ab71b1",
      "idVerbale": "153352733/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624611300000,
      "targa": "MD1592",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "621/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015672021110074",
      "trasgressore": "SCIORTINO GIUSEPPE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c7aa3afb-4eaa-44b4-93be-968a4bca0884",
      "idVerbale": "153461535/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629271200000,
      "targa": "DE815FF",
      "luogoAccertamento": "VIA ROMA - SP15 VIA CALMANA ",
      "numeroRegistro": "1157/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016022021110037",
      "trasgressore": "SAGGIORATO GIANCARLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a3789d89-e34b-46d8-9d55-b256921719b5",
      "idVerbale": "153326237/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620461700000,
      "targa": "EA158BZ",
      "luogoAccertamento": "VIA FILIPPI ",
      "numeroRegistro": "144/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017102021110061",
      "trasgressore": "AMBROSI CRISTIAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "18ebc772-b7d2-4e05-abc2-05acdf855c9b",
      "idVerbale": "152616737/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624893300000,
      "targa": "CP415ZM",
      "luogoAccertamento": "VIA TRIESTE ",
      "numeroRegistro": "648/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006352021110015",
      "trasgressore": "NICOLETTI ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "60b4ee3f-66d8-4af1-be35-a98f1c22cc17",
      "idVerbale": "153326139/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620461100000,
      "targa": "EX158BZ",
      "luogoAccertamento": "VIA FILIPPI ",
      "numeroRegistro": "177/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "AMBROSI LUCA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2a86809d-b203-4df8-abbf-e6a541ccb740",
      "idVerbale": "152801732/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1626525900000,
      "targa": "ab415dr",
      "luogoAccertamento": "CORSO GARIBALDI CORSO GARIBALDI ",
      "numeroRegistro": "861/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "mimali ilirjan",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1cf5660f-81cd-461c-9701-49490a27ced7",
      "idVerbale": "152728439/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1620343200000,
      "targa": "GB015VM",
      "luogoAccertamento": "VIA CORSICA ",
      "numeroRegistro": "91/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007792021110078",
      "trasgressore": "FRACASSO THOMAS",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "987ca0ba-39f1-4db8-a89c-f266da10af12",
      "idVerbale": "153322133/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1620590700000,
      "targa": "FP115KZ",
      "luogoAccertamento": "VIA DELLE INDUSTRIE ",
      "numeroRegistro": "133/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017072021110019",
      "trasgressore": "BENEDETTI MATTIA",
      "obbligato": "BENEDETTI GIUSEPPE"
    }, {
      "highlights": {},
      "aggId": "5be585dc-c9a4-4da9-85d3-1bc5884e6103",
      "idVerbale": "152537332/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1625015100000,
      "targa": "ez715cn",
      "luogoAccertamento": "via castellana via castellana ",
      "numeroRegistro": "665/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009542021110079",
      "trasgressore": "gazzola luciano",
      "obbligato": "SRL LEO"
    }, {
      "highlights": {},
      "aggId": "5f7273c8-5294-417a-bd5a-94194a4d4998",
      "idVerbale": "152865833/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1619788020000,
      "targa": "BL415RL",
      "luogoAccertamento": "Via Antoniana ",
      "numeroRegistro": "80/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010152021110065",
      "trasgressore": "IANCU MARINELA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b57f6432-288a-4d60-86af-4130297c3f0a",
      "idVerbale": "152506035/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626174900000,
      "targa": "AE415WB",
      "luogoAccertamento": "via Desman Via Desman ",
      "numeroRegistro": "828/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009552021110062",
      "trasgressore": "Resenterra Rita",
      "obbligato": "LEROSE ROSARIO"
    }, {
      "highlights": {},
      "aggId": "b65530b9-00cc-4dc2-aa02-eff639171f7c",
      "idVerbale": "152616835/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625443232000,
      "targa": "fa155lg",
      "luogoAccertamento": "307 via del santo ",
      "numeroRegistro": "710/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005452021110057",
      "trasgressore": "cristea florin gabriel",
      "obbligato": "cristea dumitru"
    }, {
      "highlights": {},
      "aggId": "8f893f5c-ac95-44c2-bfa6-11fbeb4828de",
      "idVerbale": "153337430/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628170500000,
      "targa": "fa159gn",
      "luogoAccertamento": "VIA STATALE ",
      "numeroRegistro": "1032/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002102021110079",
      "trasgressore": "canazza daniele",
      "obbligato": "MERLIN BARBARA"
    }, {
      "highlights": {},
      "aggId": "28402fa8-c5b1-485e-802c-90a8e8cb28f7",
      "idVerbale": "152676734/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623237300000,
      "targa": "FV151TV",
      "luogoAccertamento": "VIA MUTTIRONA - SP94 VIA MUTTIRONA - SP94 ",
      "numeroRegistro": "424/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005052021110086",
      "trasgressore": "BOSCOLO LUCA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "45f87331-2ba0-4406-bd3a-2b540bbd121f",
      "idVerbale": "195315730/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628073300000,
      "targa": "dz155df",
      "luogoAccertamento": "via  vallegia ",
      "numeroRegistro": "1428/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "barison roberto",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8df78149-8034-4a36-91c6-895e7fcc7a1c",
      "idVerbale": "153360038/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631706900000,
      "targa": "BV615GT",
      "luogoAccertamento": "VIA PIETRO APOSTOLI ",
      "numeroRegistro": "1501/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015942021110080",
      "trasgressore": null,
      "obbligato": "RADULOVIC MARINKOVIC LIDIA"
    }, {
      "highlights": {},
      "aggId": "e8ee4b65-1d6a-491e-8993-ccef8228ce43",
      "idVerbale": "153359932/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631706000000,
      "targa": "BV615GT",
      "luogoAccertamento": "VIA PIETRO APOSTOLI ",
      "numeroRegistro": "1502/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003402021110008",
      "trasgressore": null,
      "obbligato": "RADULOVIC MARINKOVIC LIDIA"
    }, {
      "highlights": {},
      "aggId": "3a51714e-e850-4e1e-a136-1059d8af9a8e",
      "idVerbale": "153341933/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632236400000,
      "targa": "AW115CR",
      "luogoAccertamento": "VIA NAZIONALE ",
      "numeroRegistro": "1550/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "COCIRLA IULIAN",
      "obbligato": "CALMIC SVETLANA"
    }, {
      "highlights": {},
      "aggId": "4357dd2d-1fa2-42a6-8247-a1fe69051b49",
      "idVerbale": "153462632/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632416160000,
      "targa": "FH215HY",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "1578/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012362021110028",
      "trasgressore": "CONVENTO FABRIZIO",
      "obbligato": "AUTOTRASPORTI CONVENTO DI CONVENTO FABRIZIO SNC"
    }, {
      "highlights": {},
      "aggId": "b8a86d97-57d2-45b2-896a-0ed6a7c27555",
      "idVerbale": "153364034/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632579955000,
      "targa": "DK155XA",
      "luogoAccertamento": "via Circonvallazione ",
      "numeroRegistro": "1591/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GIACOMINI CHIARA",
      "obbligato": "CAMON LISA"
    }, {
      "highlights": {},
      "aggId": "02ebb231-6fbf-4139-bb33-9db6bfcba35f",
      "idVerbale": "152730532/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626041100000,
      "targa": "PDB15460",
      "luogoAccertamento": "PIAZZA DEL VICARIATO CIVILE CAMISANO VICENTINO PIAZZA DEL VICARIATO CIVILE  ",
      "numeroRegistro": "805/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006682021110012",
      "trasgressore": "TONELLO PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a3fb6be5-92d7-48f0-b666-17fa4ee1e128",
      "idVerbale": "152730337/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626041100000,
      "targa": "PDB15460",
      "luogoAccertamento": "PIAZZA DEL VICARIATO CIVILE CAMISANO VICENTINO PIAZZA DEL VICARIATO CIVILE  ",
      "numeroRegistro": "804/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006722021110037",
      "trasgressore": "TONELLO PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e5a81123-3b8b-43fd-98e2-2bb20e3958fb",
      "idVerbale": "152730435/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626041100000,
      "targa": "PDB15460",
      "luogoAccertamento": "PIAZZA DEL VICARIATO CIVILE CAMISANO VICENTINO PIAZZA DEL VICARIATO CIVILE  ",
      "numeroRegistro": "803/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006702021110071",
      "trasgressore": "TONELLO PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e94bd1af-1225-4fb0-8821-2e2489bbf777",
      "idVerbale": "152730131/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626041117000,
      "targa": "PDB15460",
      "luogoAccertamento": "PIAZZA DEL VICARIATO CIVILE CAMISANO VICENTINO PIAZZA DEL VICARIATO CIVILE  ",
      "numeroRegistro": "801/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006692021110088",
      "trasgressore": "TONELLO PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "84aa4318-1170-4be2-80d7-d1f9b3ca6812",
      "idVerbale": "152730239/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626041120000,
      "targa": "PDB15460",
      "luogoAccertamento": "PIAZZA DEL VICARIATO CIVILE CAMISANO VICENTINO PIAZZA DEL VICARIATO CIVILE  ",
      "numeroRegistro": "802/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006712021110054",
      "trasgressore": "TONELLO PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3b46ca14-4366-48e9-bcd9-20387892b3bb",
      "idVerbale": "246507935/V/2021",
      "statoVerbale": "archiviato",
      "dataOraVerbale": 1623227400000,
      "targa": "EV154HW",
      "luogoAccertamento": "Via Sorio Via Sorio ",
      "numeroRegistro": "439/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004342021110084",
      "trasgressore": "peruzzi francesco",
      "obbligato": "PERUZZI MARCO"
    }, {
      "highlights": {},
      "aggId": "2dd49a97-125a-4780-b91f-d6fb4e14c65f",
      "idVerbale": "195314839/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622619900000,
      "targa": "ER156XZ",
      "luogoAccertamento": "VIA  LIBERO BENEDETTI ",
      "numeroRegistro": "350/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010962021110083",
      "trasgressore": "MONTRESOR ARTURO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e6718cc7-10dd-416b-8340-6b2137fbc6c7",
      "idVerbale": "195188235/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627117500000,
      "targa": "el154ha",
      "luogoAccertamento": "VIA BATTAGLIA VIA BATTAGLIA SS16 ",
      "numeroRegistro": "930/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012022021110048",
      "trasgressore": "sguazzabia giorgio",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "fc0c5901-a880-432e-9f15-14fb57a7f3d5",
      "idVerbale": "936756724/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631089200000,
      "targa": "dn151sc",
      "luogoAccertamento": "SS 309 Romea ",
      "numeroRegistro": "1391/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "936756724",
      "trasgressore": "Bleau Constantin Traian",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5e2d243c-9406-4d7f-a4a1-9481f0c7b4bd",
      "idVerbale": "750709022/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624657202000,
      "targa": "DE115HC",
      "luogoAccertamento": "via regia ",
      "numeroRegistro": "623/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010722021110026",
      "trasgressore": "HOSSEN MOHAMMED ANWAR",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "aea202cd-378f-4f72-bea2-a40383499ac1",
      "idVerbale": "936785827/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623132900000,
      "targa": "cm154ln",
      "luogoAccertamento": "via via don A Scapin ",
      "numeroRegistro": "418/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003132021110002",
      "trasgressore": "EDDAKNI RACHID",
      "obbligato": "KHALID EDDAKNI"
    }, {
      "highlights": {},
      "aggId": "52cedee6-9fa1-4904-be53-8ccd8134de27",
      "idVerbale": "750708924/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624657240000,
      "targa": "DE115HC",
      "luogoAccertamento": "via regia ",
      "numeroRegistro": "622/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010402021110012",
      "trasgressore": "HOSSEN MOHAMMED ANWAR",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5935b14b-f195-4b78-a5e2-d492d2f3a915",
      "idVerbale": "153440130/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631518080000,
      "targa": "fn661be",
      "luogoAccertamento": "Via Delle Industrie Via Delle Industrie ",
      "numeroRegistro": "1445/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008172021110083",
      "trasgressore": "SARTORATO LEONARDO",
      "obbligato": "CHELAB S.R.L."
    }, {
      "highlights": {},
      "aggId": "6da694c1-3fbb-4c00-bd02-0275d019107a",
      "idVerbale": "152593538/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623335415000,
      "targa": "fh116ge",
      "luogoAccertamento": "via via sp 34 ",
      "numeroRegistro": "442/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007722021110011",
      "trasgressore": "mason armando",
      "obbligato": "PLISSE'SPA"
    }, {
      "highlights": {},
      "aggId": "3ffb03ef-9a57-479b-90f5-b989ac207f6d",
      "idVerbale": "152717539/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622309400000,
      "targa": "DW499EA",
      "luogoAccertamento": "SS53 Via Postumia SS53 Via Postumia SS53 Via Postumia",
      "numeroRegistro": "326/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009032021110016",
      "trasgressore": "PIGATO ENRICO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "510b8f4b-b926-4072-ad4a-5ab5adeaf1ab",
      "idVerbale": "152615738/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621780739000,
      "targa": "fk206bb",
      "luogoAccertamento": "via piave ",
      "numeroRegistro": "260/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005492021110082",
      "trasgressore": "AMBESI IMPIOMBATO MASSIMO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f061b6d0-c6c0-49a1-927e-5b4dcdd091a5",
      "idVerbale": "153321535/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619685000000,
      "targa": "DV291KW",
      "luogoAccertamento": "VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "26/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012522021110035",
      "trasgressore": "GREGGIO FLAVIO",
      "obbligato": "CHINELLO PAOLA"
    }, {
      "highlights": {},
      "aggId": "7d5d16d7-e6c6-4266-8e20-fd3d44d0b0d3",
      "idVerbale": "152615435/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1619527857000,
      "targa": "ed809vf",
      "luogoAccertamento": "Sant'antonio ",
      "numeroRegistro": "12/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005522021110031",
      "trasgressore": "Zaramella Riccardo",
      "obbligato": "Giacomazzi Cecilia"
    }, {
      "highlights": {},
      "aggId": "f8b60b46-9396-4d2f-ab45-8eb12c4c5440",
      "idVerbale": "152615630/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1619991010000,
      "targa": "dg945dd",
      "luogoAccertamento": "MonteGrappa ",
      "numeroRegistro": "60/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005502021110065",
      "trasgressore": "Cukusic Elvis",
      "obbligato": "Ardeleanu Roman"
    }, {
      "highlights": {},
      "aggId": "0620f8a2-79cd-43bd-99a3-c00145078097",
      "idVerbale": "153351538/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624101300000,
      "targa": "DM603RY",
      "luogoAccertamento": "VIA MALACARNE ",
      "numeroRegistro": "555/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015832021110081",
      "trasgressore": "QOULAL EL HASSAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0fd933bd-2651-471c-a435-7a545c68398f",
      "idVerbale": "152615532/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1619989242000,
      "targa": "DG945dd",
      "luogoAccertamento": "MonteGrappa ",
      "numeroRegistro": "54/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005512021110048",
      "trasgressore": "Cukusic Elvis",
      "obbligato": "Ardeleanu Roman"
    }, {
      "highlights": {},
      "aggId": "49ffaf71-2cde-4f93-b64e-42ebb193e0a1",
      "idVerbale": "153331533/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1623174146000,
      "targa": "ct102tc",
      "luogoAccertamento": "VIA ROMA VIA ROMA ",
      "numeroRegistro": "414/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013082021110013",
      "trasgressore": "EL GUERRAOUI RADOUANE",
      "obbligato": "LAKRAOUI KHADIJA"
    }, {
      "highlights": {},
      "aggId": "be1fba7f-f062-4706-9d89-f7913ca8dedd",
      "idVerbale": "152711535/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628607000000,
      "targa": "dk363ds",
      "luogoAccertamento": "Via Riviera Berica Via Riviera Berica Via Riviera Berica",
      "numeroRegistro": "1096/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006862021110078",
      "trasgressore": "BORDIGNON ALBERTI Luca",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d7cec37e-cf1c-44d4-bfef-8ab4c19c485d",
      "idVerbale": "152731531/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1629847800000,
      "targa": "FK998CT",
      "luogoAccertamento": "VIA VALSUGANA VIA VALSUGANA ",
      "numeroRegistro": "1237/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006562021110030",
      "trasgressore": "MENGATO DIEGO",
      "obbligato": "ROSIN ORIANA LUISELLA"
    }, {
      "highlights": {},
      "aggId": "4d305997-4f07-4f1a-a370-503462b895d7",
      "idVerbale": "153341531/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1630092600000,
      "targa": "DB365WP",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "1279/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "IZEJROSKI ARGJENT",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "462e0b5d-66b9-410f-afb2-3be5687d1b04",
      "idVerbale": "152991536/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631027400000,
      "targa": "FB658JR",
      "luogoAccertamento": "via Bibano via Bibano ",
      "numeroRegistro": "1385/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MONNI Andrea",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1337af6d-ef13-4a0e-bb66-a312496128dd",
      "idVerbale": "152615836/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622069100000,
      "targa": "X8VL96",
      "luogoAccertamento": "PIAZZA CASTELLO ",
      "numeroRegistro": "420/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005482021110006",
      "trasgressore": "BRESSAN RICCARDO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a8269d73-fc02-4512-8f92-6a0ba09e0a3d",
      "idVerbale": "152801536/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1626461700000,
      "targa": "CF065JK",
      "luogoAccertamento": "Corso Stati Uniti Corso Stati Uniti ",
      "numeroRegistro": "855/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019002021110086",
      "trasgressore": "lakhchine hamza",
      "obbligato": "LAKHCHINE EL KBIR"
    }, {
      "highlights": {},
      "aggId": "95bdd4af-4ad8-4b95-98da-462ac7c15d2d",
      "idVerbale": "152615934/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1622380005000,
      "targa": "BV382DD",
      "luogoAccertamento": "VIA BASSA II ",
      "numeroRegistro": "342/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005472021110023",
      "trasgressore": "TERRIN LUCA",
      "obbligato": "RAFFAEL MONICA"
    }, {
      "highlights": {},
      "aggId": "0467093a-ea68-4abb-be10-b574ec957ae4",
      "idVerbale": "152715032/U/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1624395480000,
      "targa": "x584wg",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "610/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009202021110006",
      "trasgressore": null,
      "obbligato": "Denis IPPINO"
    }, {
      "highlights": {},
      "aggId": "a6811f3e-2ddb-4f2c-8c6b-7f2738214877",
      "idVerbale": "1615339/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631111400000,
      "targa": "CP386MW",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "1398/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GRAUR VALENTINA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3997ca74-db86-4c98-a9a1-e1bcdcf0f192",
      "idVerbale": "153323230/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1619650380000,
      "targa": "ET11KZV",
      "luogoAccertamento": "via Martiri della liberta' ",
      "numeroRegistro": "22/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012512021110052",
      "trasgressore": "CHIODARELLI NICOLO'",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9c112ede-5ca1-4e17-a1cb-43beb8dc031e",
      "idVerbale": "153325639/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1620091200000,
      "targa": "B106WXN",
      "luogoAccertamento": "NAZIONALE ",
      "numeroRegistro": "75/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017512021110015",
      "trasgressore": "POGGI ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "defdc216-7a39-402e-b42e-709300db4628",
      "idVerbale": "152792430/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620333300000,
      "targa": "ef058kx",
      "luogoAccertamento": "VIA FRANCESCO RISMONDO ",
      "numeroRegistro": "93/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004552021110006",
      "trasgressore": "CASAP IGOR",
      "obbligato": "jivita inna"
    }, {
      "highlights": {},
      "aggId": "e36484ca-dace-4eaa-a245-5ce2b348c1f0",
      "idVerbale": "153326834/V/2021",
      "statoVerbale": "attesa_dati",
      "dataOraVerbale": 1620525600000,
      "targa": "DZ651AP",
      "luogoAccertamento": "XX SETTEMBRE ",
      "numeroRegistro": "117/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9c3c3812-5521-44f8-96ec-beb4ae126f50",
      "idVerbale": "152792537/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620334800000,
      "targa": "ef058kx",
      "luogoAccertamento": "VIA FRANCESCO RISMONDO ",
      "numeroRegistro": "94/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004392021110092",
      "trasgressore": "CASAP IGOR",
      "obbligato": "jivita inna"
    }, {
      "highlights": {},
      "aggId": "5b2af05f-0de6-4645-a8b7-e163f8ec17ab",
      "idVerbale": "153325933/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1620401940000,
      "targa": "8AN2659",
      "luogoAccertamento": "SP 8 DEI BERSAGLIERI ",
      "numeroRegistro": "102/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017122021110027",
      "trasgressore": "barbin elisa",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e76963ca-ba8e-4d93-bd3b-18c9d07d5e85",
      "idVerbale": "152777037/V/2021",
      "statoVerbale": "attesa_dati",
      "dataOraVerbale": 1620668700000,
      "targa": "DA485JV",
      "luogoAccertamento": "Via San Marco ",
      "numeroRegistro": "140/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004582021110048",
      "trasgressore": "broidic martino",
      "obbligato": "ZORZATO MASSIMO"
    }, {
      "highlights": {},
      "aggId": "5a5f4d7c-4d59-4d0d-81e4-41b71e917fb7",
      "idVerbale": "153326031/V/2021",
      "statoVerbale": "attesa_dati",
      "dataOraVerbale": 1620456720000,
      "targa": "DQ706NC",
      "luogoAccertamento": "VIA PALUGANA NORD ",
      "numeroRegistro": "145/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017112021110044",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "71535d80-3bdf-4837-978e-9bf15c17f56d",
      "idVerbale": "153327236/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1620896400000,
      "targa": "EG190ZV",
      "luogoAccertamento": "VIA VITTORIO EMANUELE II ",
      "numeroRegistro": "166/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014582021110067",
      "trasgressore": "PAVAN DENIS",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4de4f152-7c6f-4e1a-847c-e42500a235fe",
      "idVerbale": "153383736/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1620316320000,
      "targa": "DG653KE",
      "luogoAccertamento": "LUPPIA SAN ZENO ",
      "numeroRegistro": "136/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012652021110000",
      "trasgressore": "LEONARDI GIUSEPPE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2bfeca33-3884-49e1-b783-a513f606d065",
      "idVerbale": "152593235/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1620257747000,
      "targa": "DA385EW",
      "luogoAccertamento": "via roma ",
      "numeroRegistro": "84/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "ROSSETTO SARA",
      "obbligato": "GATTO LUISA"
    }, {
      "highlights": {},
      "aggId": "9d514afe-6b0b-4eff-be54-193a91b9d2d5",
      "idVerbale": "152777135/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620669609000,
      "targa": "DA485JV",
      "luogoAccertamento": "Via San Marco ",
      "numeroRegistro": "141/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004582021110048",
      "trasgressore": "broidic martino",
      "obbligato": "ZORZATO MASSIMO"
    }, {
      "highlights": {},
      "aggId": "c1d592a9-5913-4c43-8676-07b62e894bcc",
      "idVerbale": "152676137/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620261300000,
      "targa": "CZ445RT",
      "luogoAccertamento": "Via MUTTIRONA ",
      "numeroRegistro": "86/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "falconi antonio",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9dfad9e1-4709-4a18-8107-6ad15cda8fc0",
      "idVerbale": "152717138/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1620297900000,
      "targa": "em529WD",
      "luogoAccertamento": "Viale Europa ",
      "numeroRegistro": "87/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009052021110075",
      "trasgressore": "FORASACCO NADIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ec699bf3-ca7d-4920-8127-ad7436c35382",
      "idVerbale": "153322437/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1621148100000,
      "targa": "PB496CP",
      "luogoAccertamento": "VIA CONSOLAZIONI ",
      "numeroRegistro": "195/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014632021110075",
      "trasgressore": "GALLO EMANUELE",
      "obbligato": "MELTRA S.R.O."
    }, {
      "highlights": {},
      "aggId": "7a914985-5a62-4eb6-889a-32a275b61b9d",
      "idVerbale": "153322534/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1621149000000,
      "targa": "PB46CP",
      "luogoAccertamento": "VIA CONSOLAZIONI ",
      "numeroRegistro": "194/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014572021110084",
      "trasgressore": "GALLO EMANUELE",
      "obbligato": "MELTRA S.R.O."
    }, {
      "highlights": {},
      "aggId": "b868bb22-adb0-48ad-9ea9-f9be577db75f",
      "idVerbale": "152717236/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1621350000000,
      "targa": "fw480nz",
      "luogoAccertamento": "SP94 Via Contarina Nuova ",
      "numeroRegistro": "210/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009042021110092",
      "trasgressore": "MAKSIMOVIC DIMITRIJE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7c7c1475-655f-4716-95bd-f6901c95492a",
      "idVerbale": "153262331/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621381200000,
      "targa": "EF981JC",
      "luogoAccertamento": "VIA G. CALLIDO ",
      "numeroRegistro": "215/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019972021110018",
      "trasgressore": "GIACOMELLA KEVIN ALFREDO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1fc615a2-399a-443b-b732-bcbba55b07cc",
      "idVerbale": "153262233/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621326900000,
      "targa": "X8SS42",
      "luogoAccertamento": "VIA ROMA EST ",
      "numeroRegistro": "214/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019952021110052",
      "trasgressore": "DE NEGRI STEFANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ce759983-252b-4a0e-8b1f-9bbf33eb6e42",
      "idVerbale": "153262135/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621326900000,
      "targa": "X8SS42",
      "luogoAccertamento": "VIA ROMA EST ",
      "numeroRegistro": "213/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019962021110035",
      "trasgressore": "DE NEGRI STEFANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a7440425-c35b-4a1f-a9a2-ef9a0e2d2754",
      "idVerbale": "153383932/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621493700000,
      "targa": "bw548lr",
      "luogoAccertamento": "Via San benedetto ",
      "numeroRegistro": "231/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012642021110017",
      "trasgressore": "Salkanovic Walter",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0c4240ff-540a-4266-a182-6add7fca7c9d",
      "idVerbale": "153332630/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621602000000,
      "targa": "FC860SW",
      "luogoAccertamento": "PADANA INFERIORE VIA LOC.TORRE ",
      "numeroRegistro": "237/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014732021110091",
      "trasgressore": "MANFRIN SIMONE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "385f6b1e-b5b8-451d-9209-f82e861e2872",
      "idVerbale": "153319931/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621541880000,
      "targa": "FE536538",
      "luogoAccertamento": "VIA NAZIONALE ",
      "numeroRegistro": "233/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014742021110074",
      "trasgressore": "CANELLA PARIDE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ff4f0f59-682c-41f4-9bcc-e5db40d0be70",
      "idVerbale": "153320037/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621542300000,
      "targa": "FE536538",
      "luogoAccertamento": "VIA NAZIONALE ",
      "numeroRegistro": "232/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006020002021110060",
      "trasgressore": "CANELLA PARIDE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9abc6fde-2e9f-4d69-b724-f1c682f5d67d",
      "idVerbale": "152717432/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621551600000,
      "targa": "aj784bz",
      "luogoAccertamento": "Via Risorgimento Via Risorgimento Via Risorgimento",
      "numeroRegistro": "240/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MAURIZIO ANZILIERO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "823b1cc3-84b0-4a5b-8c7d-22b9d82623be",
      "idVerbale": "152717334/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621552500000,
      "targa": "aj784bz",
      "luogoAccertamento": "Via Risorgimento Via Risorgimento Via Risorgimento",
      "numeroRegistro": "239/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MAURIZIO ANZILIERO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8c0dc9e1-849f-44b3-9331-072a956ec0fd",
      "idVerbale": "152576931/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1621858800000,
      "targa": "ga277lg",
      "luogoAccertamento": "italia via firenze via firenze",
      "numeroRegistro": "261/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "agostini francesco",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e1121ae8-8ec5-42a7-832c-0fdcaefec7ec",
      "idVerbale": "153493038/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621093980000,
      "targa": "DF694GY",
      "luogoAccertamento": "VIA IV NOVEMBRE VIA IV NOVEMBRE",
      "numeroRegistro": "193/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012232021110063",
      "trasgressore": "FETTAH RACHID",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cc7f63e3-2815-4bed-98c8-e1daa12bd264",
      "idVerbale": "152717030/V/2021",
      "statoVerbale": "attesa_dati",
      "dataOraVerbale": 1619836200000,
      "targa": "GE596CF",
      "luogoAccertamento": "Via Marconi VIA MARCONI  via marconi ",
      "numeroRegistro": "40/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009062021110058",
      "trasgressore": "ALBERTO VANZETTO",
      "obbligato": "SMURFIT KAPPA ITALIA SPA"
    }, {
      "highlights": {},
      "aggId": "c7af7a5c-b94d-413e-a792-ade250edfe56",
      "idVerbale": "153323132/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1619528400000,
      "targa": "P0577KP",
      "luogoAccertamento": "VIA VIGO DI TORRE ",
      "numeroRegistro": "264/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012472021110027",
      "trasgressore": "IVANOV STANGHO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b3eff3ae-8a11-4793-b475-d9701fcf808a",
      "idVerbale": "152989736/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1619451059000,
      "targa": "EC759ZL",
      "luogoAccertamento": "via Cavour ",
      "numeroRegistro": "1/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010742021110085",
      "trasgressore": "ADELBURG LEWIS",
      "obbligato": "L'AUTOMOBILE"
    }, {
      "highlights": {},
      "aggId": "ee665b33-724a-4093-8b2b-864a1d0c4804",
      "idVerbale": "152990332/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1620840324000,
      "targa": "ED040CY",
      "luogoAccertamento": "VIALE PO ",
      "numeroRegistro": "157/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003882021110029",
      "trasgressore": "CAMERAN MARCO",
      "obbligato": "CAMERAN LORIS"
    }, {
      "highlights": {},
      "aggId": "afc34bc5-3d6c-4577-97ba-98f8219bf2cd",
      "idVerbale": "152989834/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1619452503000,
      "targa": "EC759ZL",
      "luogoAccertamento": "via Cavour ",
      "numeroRegistro": "3/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010732021110009",
      "trasgressore": "ADELBURG LEWIS",
      "obbligato": "L'AUTOMOBILE"
    }, {
      "highlights": {},
      "aggId": "21180297-f1f9-44b9-842f-4757595626fc",
      "idVerbale": "152989932/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1619453880000,
      "targa": "EC759ZL",
      "luogoAccertamento": "via Cavour ",
      "numeroRegistro": "5/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010752021110068",
      "trasgressore": "ADELBURG LEWIS",
      "obbligato": "L'AUTOMOBILE"
    }, {
      "highlights": {},
      "aggId": "90227163-b773-4941-a080-4500f9321379",
      "idVerbale": "152547937/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621928400000,
      "targa": "CC014FW",
      "luogoAccertamento": "Via  Della Pieve ",
      "numeroRegistro": "277/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005682021110038",
      "trasgressore": "VLASIE VALENTIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4fdd249d-36fd-47ee-9d77-8596c3fa8f1e",
      "idVerbale": "152548035/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621928100000,
      "targa": "CC014FW",
      "luogoAccertamento": "Via  Della Pieve ",
      "numeroRegistro": "276/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006062021110043",
      "trasgressore": "VLASIE VALENTIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "20a0ba31-3d5c-4c52-b1f1-2db0f319bc4b",
      "idVerbale": "152555730/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622026800000,
      "targa": "b222btp",
      "luogoAccertamento": "308 SR 308 ",
      "numeroRegistro": "295/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005542021110090",
      "trasgressore": "NICOLAE VILSON RAFAEL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "73b0d570-3bf3-487b-8a58-0bd9feb6e7b8",
      "idVerbale": "153323034/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619467800000,
      "targa": "WU5411C",
      "luogoAccertamento": "NAZIONALE ",
      "numeroRegistro": "27/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012442021110078",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3afa5ac6-89a0-445c-8ecd-c53bc416f806",
      "idVerbale": "153459833/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622014620000,
      "targa": "EL114PJ",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "287/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012302021110037",
      "trasgressore": "GUSELLA STEFANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "82a8a740-cb1c-4ae6-9536-af75d965f978",
      "idVerbale": "153328930/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622213700000,
      "targa": "EH160TW",
      "luogoAccertamento": "VIA UMBERTO I° ",
      "numeroRegistro": "314/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016952021110037",
      "trasgressore": "ZAGO NICOLA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "275bffed-212a-4074-b2a2-77d01f09677e",
      "idVerbale": "152676636/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622337300000,
      "targa": "em952vv",
      "luogoAccertamento": "SP94 Via MUTTIRONA ",
      "numeroRegistro": "321/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CECOVINI SABRINA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5dd932e5-59f4-4421-869c-2f146f785c43",
      "idVerbale": "152712632/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622320200000,
      "targa": "EW745DY",
      "luogoAccertamento": "Via Don Milani Via Don Milani Via Don Milani",
      "numeroRegistro": "324/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009022021110033",
      "trasgressore": null,
      "obbligato": "JANI ANDREI GIGI"
    }, {
      "highlights": {},
      "aggId": "bb30afc8-454f-489e-8c3a-6cfd79b7af65",
      "idVerbale": "152536636/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1619436300000,
      "targa": "cv607jy",
      "luogoAccertamento": "via regina elena ",
      "numeroRegistro": "2/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005612021110064",
      "trasgressore": "Biasio Fernando",
      "obbligato": "Barbieri Luigina"
    }, {
      "highlights": {},
      "aggId": "3ee8100f-811e-4c4b-9584-45611de0e18b",
      "idVerbale": "152576833/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1620556200000,
      "targa": "fl736ky",
      "luogoAccertamento": "via firenze via firenze",
      "numeroRegistro": "119/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007632021110071",
      "trasgressore": "BAZZON DENIS MARIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e1331c0b-982f-4f02-a387-c77170ae7c3d",
      "idVerbale": "152990038/V/2021",
      "statoVerbale": "lotto_creato",
      "dataOraVerbale": 1619771040000,
      "targa": "fm929bv",
      "luogoAccertamento": "PIAZZA SALVO D'ACQUISTO 1 PIAZZA SALVO D'ACQUISTO 1 ",
      "numeroRegistro": "30/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": null,
      "obbligato": "ARVAL SERVICE LEASE ITALIA"
    }, {
      "highlights": {},
      "aggId": "b1453e7f-b525-4251-8a71-4dd0b9b298be",
      "idVerbale": "152576735/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1620028800000,
      "targa": "fl736ky",
      "luogoAccertamento": "via firenze via firenze",
      "numeroRegistro": "59/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007642021110054",
      "trasgressore": "BAZZON DENIS MARIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c1c97788-cca7-48c7-8015-e72a22c333c7",
      "idVerbale": "152676235/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1620262200000,
      "targa": "CZ445RT",
      "luogoAccertamento": "Via MUTTIRONA ",
      "numeroRegistro": "85/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "falconi antonio",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "025380b2-fc57-4d75-b2c2-f3743e3e71e9",
      "idVerbale": "152609638/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619634000000,
      "targa": "ax782mm",
      "luogoAccertamento": "via grion ",
      "numeroRegistro": "29/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005322021110092",
      "trasgressore": "marino bonato",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d88ac3e9-fafe-4983-8fc6-cd129ebc1ea6",
      "idVerbale": "152550334/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621135800000,
      "targa": "dx835nk",
      "luogoAccertamento": "Strada provinciale brentana ",
      "numeroRegistro": "190/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005412021110032",
      "trasgressore": "carlo masato",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0dde52c7-dfcb-4126-9206-90824cc4f979",
      "idVerbale": "152555231/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619790900000,
      "targa": "en724rp",
      "luogoAccertamento": "via kennedy 17 ",
      "numeroRegistro": "65/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006032021110001",
      "trasgressore": "ioniche constantin stefan",
      "obbligato": "Muraru Ana Cristina"
    }, {
      "highlights": {},
      "aggId": "4a65b770-154e-46eb-808d-8d26e53b9b3b",
      "idVerbale": "153325737/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620321600000,
      "targa": "DM706AV",
      "luogoAccertamento": "VIA CHIESA MOTTA ",
      "numeroRegistro": "101/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017142021110086",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "79910df8-d809-437b-be33-b47af74db11e",
      "idVerbale": "153322035/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620585000000,
      "targa": "EX308XJ",
      "luogoAccertamento": "VIA CALDEVIGO ",
      "numeroRegistro": "132/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017082021110002",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "dd5aac18-4ed6-423f-84ff-30f7238d8fda",
      "idVerbale": "153261939/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621317600000,
      "targa": "AS692AM",
      "luogoAccertamento": "MURE OVEST ",
      "numeroRegistro": "211/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014682021110083",
      "trasgressore": "FRISON VITTORIO",
      "obbligato": "FRISON ELENA"
    }, {
      "highlights": {},
      "aggId": "ef27773d-32cf-4875-bacc-304df6fc8eb5",
      "idVerbale": "153262037/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621317600000,
      "targa": "AS692AM",
      "luogoAccertamento": "MURE OVEST ",
      "numeroRegistro": "212/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014692021110066",
      "trasgressore": "FRISON VITTORIO",
      "obbligato": "FRISON ELENA"
    }, {
      "highlights": {},
      "aggId": "7359f629-7264-40a6-8da0-eb56a919505a",
      "idVerbale": "152547839/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620919740000,
      "targa": "db352rt",
      "luogoAccertamento": "san donato ",
      "numeroRegistro": "172/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005662021110072",
      "trasgressore": "miotti agostino",
      "obbligato": "mussaldi giovanna maria"
    }, {
      "highlights": {},
      "aggId": "0865fbda-b279-49ce-962f-1d2a8692b5ac",
      "idVerbale": "153327030/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620830400000,
      "targa": "DG735TT",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "159/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014602021110033",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ceb32340-02a2-449b-a738-3dfd502b6805",
      "idVerbale": "152728732/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620602400000,
      "targa": "eb730lm",
      "luogoAccertamento": "via vittorio veneto ",
      "numeroRegistro": "221/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007762021110036",
      "trasgressore": "GABRIEL MORETTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2e1994f7-fbfc-4b19-8dea-ff4ada8a2f66",
      "idVerbale": "152547731/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619759700000,
      "targa": "dx790px",
      "luogoAccertamento": "via bonora ",
      "numeroRegistro": "55/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005672021110055",
      "trasgressore": "rosso silvia maria",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "73df5556-dee2-4d25-a853-2002531f3475",
      "idVerbale": "152990136/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619853604000,
      "targa": "ep245mj",
      "luogoAccertamento": "via della Provvidenza ",
      "numeroRegistro": "43/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010612021110027",
      "trasgressore": "LAME FRENK",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "813b6c45-ed31-448b-9982-1e8ab318e949",
      "idVerbale": "152555133/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619790600000,
      "targa": "en724rp",
      "luogoAccertamento": "via kennedy 17 ",
      "numeroRegistro": "66/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006042021110077",
      "trasgressore": "ioniche constantin stefan",
      "obbligato": "Muraru Ana Cristina"
    }, {
      "highlights": {},
      "aggId": "f719e47a-ec5b-4e53-a69a-91435a0fed6c",
      "idVerbale": "153321937/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620162300000,
      "targa": "DK313FF",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "78/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017502021110032",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ba34ea62-7fa5-44af-ba37-b5c7b6cc479f",
      "idVerbale": "153294930/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620507900000,
      "targa": "FL279PG",
      "luogoAccertamento": "VIA LUPPIA ALBERI ",
      "numeroRegistro": "116/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017092021110078",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "950568a4-bc1b-41fc-9998-c90788455b78",
      "idVerbale": "153492832/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620457500000,
      "targa": "EX619ND",
      "luogoAccertamento": "VIA ROMA VIA ROMA ",
      "numeroRegistro": "109/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012192021110038",
      "trasgressore": "TANEVSKI DRAZENA",
      "obbligato": "CASTELLO MORENO"
    }, {
      "highlights": {},
      "aggId": "3f4c263c-6997-44ae-a48a-cc7263f01ee8",
      "idVerbale": "153328734/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620603420000,
      "targa": "ak223nw",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "134/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017062021110036",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f7d22687-6f1e-4bb0-9571-7d41b2a2af54",
      "idVerbale": "153328832/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621468800000,
      "targa": "CH401XP",
      "luogoAccertamento": "VIA PAVAGLIONE TORNE ",
      "numeroRegistro": "225/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019992021110077",
      "trasgressore": "CEKA JORGO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f5b41338-59a0-43a3-b2cb-ec301606e330",
      "idVerbale": "152875831/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1620246600000,
      "targa": "3a56577",
      "luogoAccertamento": "via veronese 1 comando stazione cc ",
      "numeroRegistro": "143/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010012021110024",
      "trasgressore": "giuliana alex",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "326d246b-a8a4-452c-8b56-3fd95e454b6f",
      "idVerbale": "153331034/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622390400000,
      "targa": "BW983VP",
      "luogoAccertamento": "via Circonvallazione ",
      "numeroRegistro": "337/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016982021110079",
      "trasgressore": "CIOBANITA IONEL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cd082e05-937b-4875-b2a6-1383e4e6ca54",
      "idVerbale": "153330936/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622390100000,
      "targa": "BW983VP",
      "luogoAccertamento": "VIA CIRCONVALLAZIONE ",
      "numeroRegistro": "336/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "CIOBANITA IONEL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1dddc17c-c437-472b-baf5-6be718c56a65",
      "idVerbale": "152712730/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622469300000,
      "targa": "PD20385",
      "luogoAccertamento": "Via Cesare Cantù Via Cesare Cantù Via Cesare Cantù",
      "numeroRegistro": "333/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009012021110050",
      "trasgressore": "Cristofani Massimo",
      "obbligato": "Cristofani Società Agricola S. S. "
    }, {
      "highlights": {},
      "aggId": "f3438a0c-727b-4db9-8fe6-a91b7d204774",
      "idVerbale": "152550236/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620928200000,
      "targa": "el755sz",
      "luogoAccertamento": "via garibaldi ",
      "numeroRegistro": "171/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005422021110015",
      "trasgressore": "beltramello stefano",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2dcc0596-afd2-4b77-8e09-59ef8797fc3f",
      "idVerbale": "152577039/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621323600000,
      "targa": "cy673mc",
      "luogoAccertamento": "marconi via marconi via marconi",
      "numeroRegistro": "207/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007622021110088",
      "trasgressore": "innocenti manuel",
      "obbligato": "innocenti loris"
    }, {
      "highlights": {},
      "aggId": "bb36afad-5f5c-458c-aca7-e29d67aa5965",
      "idVerbale": "152713034/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622580000000,
      "targa": "CB641KA",
      "luogoAccertamento": "SS53 Via Postumia SS53 Via Postumia SS53 Via Postumia",
      "numeroRegistro": "346/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008992021110084",
      "trasgressore": "BATTISTUTTI CATIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d0875a5e-b3a9-4bfb-84ac-7e2cfed5798d",
      "idVerbale": "152713132/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622580600000,
      "targa": "CB641KA",
      "luogoAccertamento": "SS53 Via Postumia SS53 Via Postumia SS53 Via Postumia",
      "numeroRegistro": "347/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008982021110008",
      "trasgressore": "BATTISTUTTI CATIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "389a47f2-372d-48dd-bfb8-77e8f4275da6",
      "idVerbale": "152728830/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1620602400000,
      "targa": "eb730lm",
      "luogoAccertamento": "via vittorio veneto ",
      "numeroRegistro": "222/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007172021110016",
      "trasgressore": "GABRIEl MORETTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "767febc1-5bcf-455a-90cf-b88aaeff7318",
      "idVerbale": "152728938/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1620602400000,
      "targa": "eb730lm",
      "luogoAccertamento": "via vittorio veneto ",
      "numeroRegistro": "223/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GABRIEl MORETTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3795d07f-ff1d-4e0b-96a3-a0ffc697b107",
      "idVerbale": "153335236/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622616900000,
      "targa": "AL492TY",
      "luogoAccertamento": "VIA ARZERE ",
      "numeroRegistro": "351/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017012021110028",
      "trasgressore": "JALOULI EL HASSANE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "da50d6bc-3ee7-4fbd-9b43-62cb28b78648",
      "idVerbale": "152792332/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1620035400000,
      "targa": "en006wv",
      "luogoAccertamento": "Via forcellini ",
      "numeroRegistro": "77/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004542021110023",
      "trasgressore": "bolzonella laura",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "40126518-7797-4829-8bd4-b0bea1f63f6e",
      "idVerbale": "152782539/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1619780100000,
      "targa": "DY639RN",
      "luogoAccertamento": "VIA SELVA ",
      "numeroRegistro": "39/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004472021110049",
      "trasgressore": "CAMARA FOTOUMATA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b999eb44-99cc-40f5-bb8b-e4fc62394415",
      "idVerbale": "153337635/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622785200000,
      "targa": "EZ604TT",
      "luogoAccertamento": "PADANA INFERIORE VIA ROMA EST ",
      "numeroRegistro": "369/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017022021110011",
      "trasgressore": "ZENATO LORENZO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b71ee9bf-63a1-4cdf-8922-5bdf7aaac168",
      "idVerbale": "937158824/U/2021",
      "statoVerbale": "archiviato",
      "dataOraVerbale": 1621071000000,
      "targa": "FN245EF",
      "luogoAccertamento": "VESCOVANA VIA BASSA 4 COMANDO STAZIONE CC",
      "numeroRegistro": "349/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012102021110005",
      "trasgressore": "CRUCEANU DAN",
      "obbligato": "NUNZIATA TRASPORTI SCRAL"
    }, {
      "highlights": {},
      "aggId": "caff0586-73ec-4b58-bbbd-8eda901afef0",
      "idVerbale": "153331230/V/2020",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1591338600000,
      "targa": "EG616DN",
      "luogoAccertamento": "PIAZZA DONATORI DI SANGUE ",
      "numeroRegistro": "1/2020",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013022021110022",
      "trasgressore": "TILA BEDRI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ac7bcc3b-43ae-436f-9f75-dcd578ef3393",
      "idVerbale": "153331338/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622880000000,
      "targa": "BA707HT",
      "luogoAccertamento": "PRà VIA PRà ",
      "numeroRegistro": "391/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013032021110005",
      "trasgressore": "VIGATO ROBERTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "148d939e-7219-46b4-ae8e-551c81171bf5",
      "idVerbale": "153330534/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1622011920000,
      "targa": "CG993GA",
      "luogoAccertamento": "VIA MAGGIORE ",
      "numeroRegistro": "289/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016932021110071",
      "trasgressore": "FERRARI EMANUELE",
      "obbligato": "MTE MONSELICE TRASPORTI ECOLOGICI SRL"
    }, {
      "highlights": {},
      "aggId": "a6a61b24-258e-4e08-b212-b041def15a8e",
      "idVerbale": "152990733/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1623013500000,
      "targa": "cz226ef",
      "luogoAccertamento": "via Parini  strada comunale ",
      "numeroRegistro": "396/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CARPANESE ALAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "08b810ca-2757-440a-a1f6-ac286551fb59",
      "idVerbale": "153335334/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622989200000,
      "targa": "CL703AE",
      "luogoAccertamento": "VIA ADIGE INFERIORE ",
      "numeroRegistro": "398/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013052021110064",
      "trasgressore": "COVALI CONSTANTIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "944667bf-ecbb-4b0d-bb34-f48f9d4c5474",
      "idVerbale": "152555838/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621980900000,
      "targa": "b222btp",
      "luogoAccertamento": "308 SR 308 ",
      "numeroRegistro": "291/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "NICOLAE VILSON RAFAEL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ac421826-688b-4092-b7db-2fc262aae16f",
      "idVerbale": "152537038/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623285600000,
      "targa": "da826zn",
      "luogoAccertamento": "via via meucci ",
      "numeroRegistro": "440/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "simion stefan",
      "obbligato": "gavrilita oana"
    }, {
      "highlights": {},
      "aggId": "400f3460-c27b-4790-b1f9-2dc4ad7c61c0",
      "idVerbale": "152536832/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620508200000,
      "targa": "ej869hb",
      "luogoAccertamento": "via rettilineo ",
      "numeroRegistro": "170/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005602021110081",
      "trasgressore": "facci renato",
      "obbligato": "furlan loreta"
    }, {
      "highlights": {},
      "aggId": "a017cdca-29ba-4294-bdbd-3616dcf30bd2",
      "idVerbale": "152564132/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1620108300000,
      "targa": "ew500bl",
      "luogoAccertamento": "Viale Venezia viale venezia ",
      "numeroRegistro": "72/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009522021110020",
      "trasgressore": "BURBELLO GIULIANO",
      "obbligato": "BERTI ANNALISA"
    }, {
      "highlights": {},
      "aggId": "cbe2db00-a19f-493d-8e9f-eefac5b6da92",
      "idVerbale": "815942020/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621240829000,
      "targa": "FZ757GT",
      "luogoAccertamento": "S.P.3 ",
      "numeroRegistro": "201/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006001122021110071",
      "trasgressore": "MUSCIOLA' GIANVITO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0c98dea9-7c14-4bb6-be31-86beb5064946",
      "idVerbale": "152609736/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620065700000,
      "targa": "DF033GV",
      "luogoAccertamento": "via Montello II Tronco ",
      "numeroRegistro": "70/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005312021110016",
      "trasgressore": "SEMENZATO Nicola",
      "obbligato": "LAURA TONOLO"
    }, {
      "highlights": {},
      "aggId": "f2a92ae1-ccdd-4390-95b7-ce4c462c8c53",
      "idVerbale": "153327432/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1621201800000,
      "targa": "CT585DD",
      "luogoAccertamento": "VIA CANEVEDO ",
      "numeroRegistro": "198/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014652021110041",
      "trasgressore": "NESSI MARCO",
      "obbligato": "RONCOLATO ITARIA"
    }, {
      "highlights": {},
      "aggId": "d665bbb1-456e-4a48-a860-f42c120521fe",
      "idVerbale": "152553331/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619612400000,
      "targa": "eh799gm",
      "luogoAccertamento": "corso giovanni stevanato ",
      "numeroRegistro": "21/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005582021110022",
      "trasgressore": "erik marzari",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ba3d32ec-339e-4404-ab99-d46e53824640",
      "idVerbale": "152550432/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621620645000,
      "targa": "FA334LG",
      "luogoAccertamento": "via borgo padova via borgo padova via borgo padova",
      "numeroRegistro": "242/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005682021110038",
      "trasgressore": "BAGGIO LUCA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4e88741a-33c2-42c2-aa7c-561dc27449e3",
      "idVerbale": "152616130/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623479400000,
      "targa": "EK186ZP",
      "luogoAccertamento": "via piave ",
      "numeroRegistro": "458/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006412021110006",
      "trasgressore": "visentini pietro",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6fa035c8-9670-479d-b8df-3857794add97",
      "idVerbale": "936731528/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1622035817000,
      "targa": "KPLK398",
      "luogoAccertamento": "VIA  MURE ",
      "numeroRegistro": "365/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000052021110030",
      "trasgressore": "PETRACIN DEVIS",
      "obbligato": "SRL REVENTON CAR"
    }, {
      "highlights": {},
      "aggId": "b2a777e2-bc5b-4954-9f83-1c7fda1bb63a",
      "idVerbale": "153350931/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623736800000,
      "targa": "AJ369CA",
      "luogoAccertamento": "STRADA MOROSINA ",
      "numeroRegistro": "483/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015922021110021",
      "trasgressore": "ROTARU ROMAN",
      "obbligato": "DULGHER GHEORGHE"
    }, {
      "highlights": {},
      "aggId": "1e0ec413-acf9-4869-9a87-5756b1a7c2bc",
      "idVerbale": "152538830/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623694200000,
      "targa": "CL884VA",
      "luogoAccertamento": "Via Albare Via ALBARE ",
      "numeroRegistro": "479/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009802021110009",
      "trasgressore": "GJELOSHI GRISEL",
      "obbligato": "LAMON SABRINA"
    }, {
      "highlights": {},
      "aggId": "102a6c71-fc67-4db9-a96f-f8b51b78ca39",
      "idVerbale": "152616434/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623740400000,
      "targa": null,
      "luogoAccertamento": "via piave ",
      "numeroRegistro": "482/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006382021110057",
      "trasgressore": "HEMUKA CHIADIADI KINGSLEY",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "44728bbb-acb6-4dc6-87e3-af72692566c6",
      "idVerbale": "153351137/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623811800000,
      "targa": "CV286KP",
      "luogoAccertamento": "VIA VENETO VIA VENETO ",
      "numeroRegistro": "498/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015892021110072",
      "trasgressore": "ESPOSITO LUIGI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "78f180e8-0649-4d8f-b618-1630a7b6bc38",
      "idVerbale": "153404535/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623826212000,
      "targa": "cd404eg",
      "luogoAccertamento": "via adige via adige via adige",
      "numeroRegistro": "496/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002532021110092",
      "trasgressore": "corsato mariangela",
      "obbligato": "saoncella valerio"
    }, {
      "highlights": {},
      "aggId": "addf7c1e-5280-47a6-9d27-453fa1247244",
      "idVerbale": "153351039/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623810600000,
      "targa": "CV286KP",
      "luogoAccertamento": "VIA VENETO VIA VENETO ",
      "numeroRegistro": "497/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015902021110055",
      "trasgressore": "ESPOSITO LUIGI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "312f6bae-1756-44c6-ba46-7e0de96c4b3d",
      "idVerbale": "153330133/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621155900000,
      "targa": "DG442JZ",
      "luogoAccertamento": "VIA VITTORIO ",
      "numeroRegistro": "495/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014642021110058",
      "trasgressore": "ZEN ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "dfe5d00e-74c3-47e8-9606-d3aae2a90e7d",
      "idVerbale": "152727831/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1619402437000,
      "targa": "SPROVVISTO",
      "luogoAccertamento": "via galileo galilei ",
      "numeroRegistro": "45/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007852021110069",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6c3c866d-058c-4a1a-bc7f-758d96eeb769",
      "idVerbale": "152728037/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1619404200000,
      "targa": "0",
      "luogoAccertamento": "via galileo galilei ",
      "numeroRegistro": "96/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007832021110010",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2c0e6616-cc8f-4dd3-983c-7663d0dff8a3",
      "idVerbale": "152727939/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1619403300000,
      "targa": "0",
      "luogoAccertamento": "via galileo galilei ",
      "numeroRegistro": "92/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007842021110086",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4eb29cac-3ff1-41ca-b96a-7355644440e8",
      "idVerbale": "153329038/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623840900000,
      "targa": "DP248DL",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "502/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015862021110030",
      "trasgressore": "RESENTE ADRIANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "86ba8110-3100-4a0a-8152-20151c06fc07",
      "idVerbale": "152990939/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623888600000,
      "targa": "fs181fa",
      "luogoAccertamento": "PIAZZA SALVO D'ACQUISTO 1 PIAZZA SALVO D'ACQUISTO 1 ",
      "numeroRegistro": "503/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MARENGO ANDREA",
      "obbligato": "MARENGO GIONNI"
    }, {
      "highlights": {},
      "aggId": "c7f9bbb7-b229-4e80-a635-e33d8aa18068",
      "idVerbale": "152714239/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623927000000,
      "targa": "dx292eb",
      "luogoAccertamento": "Piazza Donatori di Sangue Piazza Donatori di Sangue Piazza Donatori di Sangue",
      "numeroRegistro": "515/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009282021110056",
      "trasgressore": "Fabjan HYKA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6d26f05c-58c0-4be3-92e9-0d540648f47f",
      "idVerbale": "152714131/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1623855000000,
      "targa": "EM117EK",
      "luogoAccertamento": "Via Giuseppe Mazzini Via Giuseppe Mazzini Via Giuseppe Mazzini",
      "numeroRegistro": "501/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MANUEL FICARRA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "332c6208-4b5c-44dd-866c-5930d9b9f307",
      "idVerbale": "152800430/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623931800000,
      "targa": "EA87365",
      "luogoAccertamento": "via trieste via trieste ",
      "numeroRegistro": "524/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019042021110018",
      "trasgressore": "MARCHELLE SANDRA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "29a68d2e-5787-4728-8ed8-a591d6bdaf86",
      "idVerbale": "152729232/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1623000480000,
      "targa": "CV30010",
      "luogoAccertamento": "via dei contarini via dei contarini ",
      "numeroRegistro": "520/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007142021110067",
      "trasgressore": "GUSUILA ARTUR",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a0d6bf94-d684-4aff-ab79-abcb3e5c918c",
      "idVerbale": "152519030/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623805200000,
      "targa": "dm098rz",
      "luogoAccertamento": "via roma via roma ",
      "numeroRegistro": "539/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005642021110013",
      "trasgressore": "milanko slobodan",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7b5b2817-cced-4b29-8164-a700a8c3f62e",
      "idVerbale": "152519138/U/2021",
      "statoVerbale": "attesa_dati",
      "dataOraVerbale": 1623808800000,
      "targa": "dm098rz",
      "luogoAccertamento": "via Kennedy 17 via kennedy 17 ",
      "numeroRegistro": "538/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f5697e56-43d3-4ca6-bd2d-aab34365fb32",
      "idVerbale": "153351930/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624110600000,
      "targa": "DH016CK",
      "luogoAccertamento": "VIA GIOVANNI XXIII ",
      "numeroRegistro": "556/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015802021110039",
      "trasgressore": "DINIC VEROLJUB",
      "obbligato": "DINIC SLADANA"
    }, {
      "highlights": {},
      "aggId": "c864d823-a4ec-4738-8280-894f913a9430",
      "idVerbale": "152713338/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623612000000,
      "targa": "ev788fl",
      "luogoAccertamento": "Via Giuseppe Mazzini Via Giuseppe Mazzini Via Giuseppe Mazzini",
      "numeroRegistro": "553/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Thomas FATTORI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f6bb99e4-23d7-467e-ab98-5bd85e0dbcdb",
      "idVerbale": "153351734/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624108200000,
      "targa": "DH016CK",
      "luogoAccertamento": "VIA GIOVANNI XXIII ",
      "numeroRegistro": "557/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015812021110022",
      "trasgressore": "DINIC VEROLJUB",
      "obbligato": "DINIC SLADANA"
    }, {
      "highlights": {},
      "aggId": "f57802c0-37a4-4691-8574-dbf1a604eb37",
      "idVerbale": "153351832/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624108200000,
      "targa": "DH016CK",
      "luogoAccertamento": "VIA GIOVANNI XXIII ",
      "numeroRegistro": "558/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015772021110090",
      "trasgressore": "DINIC VEROLJUB",
      "obbligato": "DINIC SLADANA"
    }, {
      "highlights": {},
      "aggId": "48de5a50-32f2-4d1e-b60b-e8165c929d19",
      "idVerbale": "152728331/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1619891580000,
      "targa": "DP431GA",
      "luogoAccertamento": "via dei del dente ",
      "numeroRegistro": "47/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007802021110061",
      "trasgressore": "FABRIZIO ADRIANO DE BARTOLO",
      "obbligato": "LOMBARDI MARIANNA"
    }, {
      "highlights": {},
      "aggId": "adddaa29-746f-4b17-8965-7d3b0593e8f2",
      "idVerbale": "152800537/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624074000000,
      "targa": "EA314YG",
      "luogoAccertamento": "VIA MONTE NERO VIA MONTE NERO ",
      "numeroRegistro": "565/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019052021110001",
      "trasgressore": "GUIDOLIN ANDREA",
      "obbligato": "COMARELLA NOEMI"
    }, {
      "highlights": {},
      "aggId": "7725f2f9-321e-4516-9254-8d17d831ab87",
      "idVerbale": "153329234/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624222200000,
      "targa": "AJ547BR",
      "luogoAccertamento": "VIA STATALE ",
      "numeroRegistro": "564/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "PANIN SILVIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "baae5ef1-73df-412b-86d3-615e8c45c62c",
      "idVerbale": "152729134/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1621816207000,
      "targa": "EV364RV",
      "luogoAccertamento": "Via Venezia Via Venezia ",
      "numeroRegistro": "259/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007152021110050",
      "trasgressore": "GOIA ALEXANDRU VALENTIN",
      "obbligato": "ATAMANIUC ANA"
    }, {
      "highlights": {},
      "aggId": "70ef467c-459b-46aa-96c7-619c9dfc6e98",
      "idVerbale": "153352038/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624233780000,
      "targa": "FE102MA",
      "luogoAccertamento": "VIA GAMBINA ",
      "numeroRegistro": "570/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015752021110031",
      "trasgressore": "VEZZU' ANDREA",
      "obbligato": "TURETTA LORENA"
    }, {
      "highlights": {},
      "aggId": "d452de87-c69a-481c-a46b-b7c179bba93a",
      "idVerbale": "152713739/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623846300000,
      "targa": "EW745DY",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "548/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009322021110081",
      "trasgressore": null,
      "obbligato": "JANI ANDREI GIGI"
    }, {
      "highlights": {},
      "aggId": "46cf539c-304e-44b4-b9ca-471547f5414e",
      "idVerbale": "152713837/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623846600000,
      "targa": "EW745DY",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "551/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009312021110005",
      "trasgressore": null,
      "obbligato": "JANI ANDREI GIGI"
    }, {
      "highlights": {},
      "aggId": "b32f7ca6-ca94-41db-b50e-1e2636673dbf",
      "idVerbale": "152713436/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623843000000,
      "targa": "EW745DY",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "545/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009352021110030",
      "trasgressore": null,
      "obbligato": "JANI ANDREI GIGI"
    }, {
      "highlights": {},
      "aggId": "76e43bed-a726-4c32-a652-cae05110dfdc",
      "idVerbale": "152714033/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623844800000,
      "targa": "EW745DY",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "546/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009292021110039",
      "trasgressore": null,
      "obbligato": "JANI ANDREI GIGI"
    }, {
      "highlights": {},
      "aggId": "c82e7be1-7256-4d42-9907-df92fcfb0cc9",
      "idVerbale": "152713935/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623846900000,
      "targa": "EW745DY",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "550/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009302021110022",
      "trasgressore": null,
      "obbligato": "JANI ANDREI GIGI"
    }, {
      "highlights": {},
      "aggId": "a013f5ac-e5cf-42c4-9870-09e6b117b78d",
      "idVerbale": "152713631/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623845520000,
      "targa": "EW745DY",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "547/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009332021110064",
      "trasgressore": null,
      "obbligato": "JANI ANDREI GIGI"
    }, {
      "highlights": {},
      "aggId": "d4036e56-7338-4707-a892-a9881f7a7ff2",
      "idVerbale": "153324033/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624370700000,
      "targa": "BV314ZA",
      "luogoAccertamento": "VIA PRA' ",
      "numeroRegistro": "586/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015652021110015",
      "trasgressore": "ALLEGRI LUIGI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d129b17f-a690-4e1b-836d-dc55adf3302d",
      "idVerbale": "152714630/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624533600000,
      "targa": "x584wg",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "605/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009242021110031",
      "trasgressore": null,
      "obbligato": "Denis IPPINO"
    }, {
      "highlights": {},
      "aggId": "ab64d3a1-0c9b-4dad-8316-d64b60ada4d2",
      "idVerbale": "152577538/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624505125000,
      "targa": "EV717SV",
      "luogoAccertamento": "VIA MAGLIO VIA MAGLIO ",
      "numeroRegistro": "602/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "PALLARO FILIPPO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1078e869-0ac9-438d-8180-9c4e773afeb0",
      "idVerbale": "153352234/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624473600000,
      "targa": "CT395YS",
      "luogoAccertamento": "VIA  CESARE BATTISTI ",
      "numeroRegistro": "613/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "BARATTO MARCO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f448544a-74e2-45ff-b21c-9d5f90a32cae",
      "idVerbale": "152840432/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624030020000,
      "targa": "EJ272HV",
      "luogoAccertamento": "VIA PERARELLO ",
      "numeroRegistro": "533/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004042021110036",
      "trasgressore": "DE BASTIANI GIAMPAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "05e1b7f4-cc5a-4cc4-bad2-ef7ae6c679dc",
      "idVerbale": "152840334/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624030020000,
      "targa": "EJ272HV",
      "luogoAccertamento": "VIA PERARELLO ",
      "numeroRegistro": "532/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004022021110070",
      "trasgressore": "DE BASTIANI GIAMPAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c7f0e4f6-4780-4c44-be0a-3ba6dee148da",
      "idVerbale": "152710135/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624395360000,
      "targa": "X584WG",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "617/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": null,
      "obbligato": "Denis IPPINO"
    }, {
      "highlights": {},
      "aggId": "2aed97d2-892e-45a6-95c5-46180da25cfa",
      "idVerbale": "152840138/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623066900000,
      "targa": "ET989HT",
      "luogoAccertamento": "Via Roma ",
      "numeroRegistro": "405/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004102021110027",
      "trasgressore": "FERRINI LUCIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9d6d44ec-6732-44cf-a392-f791d9f654b9",
      "idVerbale": "152710634/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624752000000,
      "targa": "CE205WG",
      "luogoAccertamento": "SS53 Via Dante SS53 Via Dante SS53 Via Dante",
      "numeroRegistro": "630/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "NICOLIN Alessio",
      "obbligato": "DE ROSSI Elena"
    }, {
      "highlights": {},
      "aggId": "031a8e2d-e037-4850-9f85-3a89e2a70491",
      "idVerbale": "152710536/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624755000000,
      "targa": "14/09/2019",
      "luogoAccertamento": "SS53 Via Dante SS53 Via Dante SS53 Via Dante",
      "numeroRegistro": "631/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009172021110057",
      "trasgressore": "NICOLIN Giuliano",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "fea66e56-1a16-4ef2-be57-20247295702a",
      "idVerbale": "153353233/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624754100000,
      "targa": "BM230YM",
      "luogoAccertamento": "VIA XXVIII APRILE ",
      "numeroRegistro": "632/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015642021110032",
      "trasgressore": "TIVERON IVAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "88880379-0285-4bd8-a8cb-bfc5da73a26a",
      "idVerbale": "153329733/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624836900000,
      "targa": "EZ557DS",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "637/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015522021110050",
      "trasgressore": "CORTESI LAKSHIMI",
      "obbligato": "FRASSON MICHELE"
    }, {
      "highlights": {},
      "aggId": "341cb4b5-559b-4356-a6ca-009086383349",
      "idVerbale": "152593431/U/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623119400000,
      "targa": "CF202LL",
      "luogoAccertamento": "VIA  BOSCALTO SR 307 - VIA BOSCALTO DI RESANA ",
      "numeroRegistro": "634/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GALATEANU CRISTIAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "65e47a8c-7ae8-4749-aa5f-b54c77204ca0",
      "idVerbale": "153333130/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624903380000,
      "targa": "CJ994YP",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "650/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015592021110024",
      "trasgressore": "HUDOROVIC MARINA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "eed06fd8-2da1-4fdd-8e87-670f7e5b1da0",
      "idVerbale": "153333238/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624903800000,
      "targa": "CJ994YP",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "651/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016322021110085",
      "trasgressore": "HUDOROVIC MARINA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "91b58c76-8c41-4271-92a1-b8add2caebf0",
      "idVerbale": "153352939/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624665000000,
      "targa": "BV387CZ",
      "luogoAccertamento": "VIA PRINCIPE UMBERTO ",
      "numeroRegistro": "656/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "MENEGOTTO LUCA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6f7e9291-fd50-4cd1-98fc-04a7bb255527",
      "idVerbale": "152710331/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1624692780000,
      "targa": "x584wg",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "619/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Denis IPPINO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d4137d4a-acd7-4fa4-b7db-99b0b814208c",
      "idVerbale": "153333737/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625104800000,
      "targa": "DM881AT",
      "luogoAccertamento": "VIA GRANDE ",
      "numeroRegistro": "672/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "FIORAVANTI MARTINA",
      "obbligato": "LANZETTA CARMELA"
    }, {
      "highlights": {},
      "aggId": "51228ba9-d30c-490d-b716-4cfbf5a6d2d8",
      "idVerbale": "153329831/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625083500000,
      "targa": "AN437FX",
      "luogoAccertamento": "VIA NAZIONALE ",
      "numeroRegistro": "677/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016292021110043",
      "trasgressore": "BORDON ATTILIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "dd8ab4b2-efb6-4712-a106-cff9bfe5c369",
      "idVerbale": "152710732/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1625158500000,
      "targa": "BV34726",
      "luogoAccertamento": "Via Marconi VIA MARCONI  via marconi ",
      "numeroRegistro": "682/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "ZACCHIA CHRISTIAN",
      "obbligato": "ZACCHIA CLAUDIO"
    }, {
      "highlights": {},
      "aggId": "b944e3d8-008e-4097-8da5-306f21d2206f",
      "idVerbale": "153330035/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625140200000,
      "targa": "FT160LD",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "680/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008132021110058",
      "trasgressore": "RUSU GEORGE CATALIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c2beab87-00a4-4e69-a5aa-0b059d375e8a",
      "idVerbale": "152710830/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625165400000,
      "targa": "VR129ED",
      "luogoAccertamento": "SS53 Via Marconi SS53 Via Marconi SS53 Via Marconi",
      "numeroRegistro": "683/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STAMENKOVIC Sladan",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1d3a362e-4d40-4daa-9062-62bd8c43cc2e",
      "idVerbale": "153333639/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625035020000,
      "targa": "J56A022",
      "luogoAccertamento": "VIA PASQUALE LONGO ",
      "numeroRegistro": "686/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "DINIC HUSEIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "36af2229-9292-439e-a269-2d9c47caf9e8",
      "idVerbale": "152637634/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625245590000,
      "targa": "GDRSAAB",
      "luogoAccertamento": "Via Carbonara ",
      "numeroRegistro": "691/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005252021110025",
      "trasgressore": "Saab Roberto",
      "obbligato": "saab snc di saab & C"
    }, {
      "highlights": {},
      "aggId": "6a6a13f3-e4bc-46a4-a002-e3e15dbd70a2",
      "idVerbale": "153340337/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625267400000,
      "targa": "FW579SA",
      "luogoAccertamento": "VIA PRINCIPE AMEDEO ",
      "numeroRegistro": "696/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015602021110007",
      "trasgressore": "LISSANDRIN EDOARDO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "55c09b65-66ac-444e-b68a-012fb5510522",
      "idVerbale": "153327334/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1621164000000,
      "targa": "DD507GT",
      "luogoAccertamento": "VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "199/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GEORGESCU ALEXANDRU CATALIN",
      "obbligato": "MOISA DAVID MIHAEL"
    }, {
      "highlights": {},
      "aggId": "2aff4f17-0742-41aa-ac22-a2a0c2ca810a",
      "idVerbale": "153327539/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1621257000000,
      "targa": "FC238MJ",
      "luogoAccertamento": "VIA RIVIERA ",
      "numeroRegistro": "204/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "TONIN RENATO",
      "obbligato": "CENTRO SERVIZI FUNEBRI SRL"
    }, {
      "highlights": {},
      "aggId": "44509111-5659-44e0-a3ed-f4a3c62e63a7",
      "idVerbale": "153332738/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1621732740000,
      "targa": "CT273YS",
      "luogoAccertamento": "VIA XXVIII APRILE ",
      "numeroRegistro": "252/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016912021110012",
      "trasgressore": "BONFIO VERONESE LORETTA",
      "obbligato": "SLANZI SEVERINO"
    }, {
      "highlights": {},
      "aggId": "37ebf525-84d5-4ab1-b3cb-7dfa231fc417",
      "idVerbale": "153337939/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1625386200000,
      "targa": "CW455SY",
      "luogoAccertamento": "VIA MASSIMO D'AZEGLIO ",
      "numeroRegistro": "706/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015562021110075",
      "trasgressore": "BERMUCA ELMAZ",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4db0f5c5-a022-483a-8100-e280bd51cc5a",
      "idVerbale": "153350138/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623366600000,
      "targa": "FY575WP",
      "luogoAccertamento": "VIA GUIDO NEGRI ",
      "numeroRegistro": "447/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "BOLSI PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3cecee0a-63ef-4106-97b5-e45ac8b61fba",
      "idVerbale": "153351636/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1624108200000,
      "targa": "DH016CK",
      "luogoAccertamento": "VIA GIOVANNI XXIII ",
      "numeroRegistro": "559/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015822021110005",
      "trasgressore": "DINIC VEROLJUB",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a18f5404-c2a8-44c8-8c39-ef31f3ab8323",
      "idVerbale": "153323935/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1624060800000,
      "targa": "FL050KM",
      "luogoAccertamento": "SR 10 VIA CHIESA MOTTA ",
      "numeroRegistro": "544/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015842021110064",
      "trasgressore": "PELLEGRINI LUCA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1e6a87ae-419b-4dfb-93a6-387ac5400ce6",
      "idVerbale": "153325531/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620013800000,
      "targa": "CW024XL",
      "luogoAccertamento": "VIALE TRE MARTIRI ",
      "numeroRegistro": "83/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012222021110080",
      "trasgressore": "JADAL REDOUANE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cf7fd302-604b-496a-abf5-ee90163f2db7",
      "idVerbale": "153321731/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1619914242000,
      "targa": "bn355ph",
      "luogoAccertamento": "VIA G.B. FERRARI ",
      "numeroRegistro": "51/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012182021110055",
      "trasgressore": "ABBAS TASSAWAR",
      "obbligato": "AHMEDZAI SHARIF"
    }, {
      "highlights": {},
      "aggId": "67e487d7-2244-4c9b-98e7-b38d87aa0dc8",
      "idVerbale": "153321839/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1619914247297,
      "targa": "BN355PH",
      "luogoAccertamento": "VIA G.B. FERRARI ",
      "numeroRegistro": "52/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012202021110021",
      "trasgressore": "ABBAS TASSAWAR",
      "obbligato": "AHMEDZAI SHARIF"
    }, {
      "highlights": {},
      "aggId": "1e3d4dc0-b401-4d09-81ac-83400109e3b7",
      "idVerbale": "153323338/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1622042100000,
      "targa": "CP172ZP",
      "luogoAccertamento": "VIA NAZIONALE ",
      "numeroRegistro": "297/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "QUAGLIO FABRIZIO",
      "obbligato": "PADOAN DANIELE"
    }, {
      "highlights": {},
      "aggId": "5a3aa03f-60f1-40a9-9859-ed2030ed4b92",
      "idVerbale": "153323436/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1622756280000,
      "targa": "FA576PR",
      "luogoAccertamento": "VIA TORNE ",
      "numeroRegistro": "370/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "MASSIGNAN ROBERTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a61f4210-c905-4547-a05a-619a12d2ee30",
      "idVerbale": "153323533/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1622757120000,
      "targa": "BW968LS",
      "luogoAccertamento": "VIA PAVAGLIONE TORNE VIA PAVAGLIONE TORNE ",
      "numeroRegistro": "377/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013032021110005",
      "trasgressore": "PASCHINA DANIELA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c165b320-7c85-405d-bee9-74d7ea69491b",
      "idVerbale": "153326530/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620763500000,
      "targa": "DV202CB",
      "luogoAccertamento": "VIA DOSSI ",
      "numeroRegistro": "149/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014452021110009",
      "trasgressore": "LIZIERO MATTEO",
      "obbligato": "ROSSIGNOLO SANTINA"
    }, {
      "highlights": {},
      "aggId": "bf80a752-0c3d-410a-a04d-8024f88126a5",
      "idVerbale": "153326638/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620763500000,
      "targa": "DV202CB",
      "luogoAccertamento": "VIA DOSSI ",
      "numeroRegistro": "150/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014442021110026",
      "trasgressore": "LIZIERO MATTEO",
      "obbligato": "ROSSIGNOLO SANTINA"
    }, {
      "highlights": {},
      "aggId": "0d179c9b-0b52-4059-bc41-3ff480347474",
      "idVerbale": "153326932/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620819900000,
      "targa": "EK053GD",
      "luogoAccertamento": "VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "158/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014612021110016",
      "trasgressore": "BAU' ANTONIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0c2de781-c004-4577-ac9e-28083baa35a2",
      "idVerbale": "153304830/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621889100000,
      "targa": "FV293MD",
      "luogoAccertamento": "VIA XX SETTEMBRE ",
      "numeroRegistro": "284/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016922021110088",
      "trasgressore": "BRIGATO MATTEO",
      "obbligato": "VECCHIATO LUISELLA"
    }, {
      "highlights": {},
      "aggId": "744bd251-7ff6-4fc0-82ba-a23991f7076f",
      "idVerbale": "153333835/V/2020",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1593866400000,
      "targa": "EA881DC",
      "luogoAccertamento": "SP 247 RIVIERA VIA RIVIERA ",
      "numeroRegistro": "2/2020",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "RIZZI ALEX",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b1bcfdf6-3274-40ba-91d0-aba1b57a928f",
      "idVerbale": "153404731/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1625420100000,
      "targa": "DD113VH",
      "luogoAccertamento": "via ROMA ",
      "numeroRegistro": "712/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002552021110058",
      "trasgressore": "SEGANTIN MARINO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2ac89cc1-df2f-48cb-ae33-6af0fa8feb2f",
      "idVerbale": "152537537/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625442600000,
      "targa": "bk892rr",
      "luogoAccertamento": "via dell'industria ",
      "numeroRegistro": "715/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "fiorese lorenzo",
      "obbligato": "baldo daniela"
    }, {
      "highlights": {},
      "aggId": "5a645a78-646a-4225-9966-fc700f24849d",
      "idVerbale": "153329537/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1624404000000,
      "targa": "NRMA706",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "590/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "DE BATTISTI FRANCO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "fcc98ab9-695a-4da5-849a-bd972a0bee0b",
      "idVerbale": "152537430/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625392800000,
      "targa": "DF737WM",
      "luogoAccertamento": "via Kennedy 17 via kennedy 17 ",
      "numeroRegistro": "717/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": null,
      "obbligato": "Nonni Moustapha "
    }, {
      "highlights": {},
      "aggId": "96b9cc19-18e4-4200-9ee4-673ab8350560",
      "idVerbale": "152844535/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620295740000,
      "targa": "GE737RA",
      "luogoAccertamento": "Via Terraglione ",
      "numeroRegistro": "88/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003992021110028",
      "trasgressore": "RENZO PIOTTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "beb31f90-8c13-453a-9c3a-568219f7e194",
      "idVerbale": "152844731/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621006800000,
      "targa": "cv090fp",
      "luogoAccertamento": "Via Terraglione Via Terraglione ",
      "numeroRegistro": "186/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004052021110019",
      "trasgressore": "GRIGGIO MASSIMILIANO",
      "obbligato": "SRL WEEK S.R.L.S."
    }, {
      "highlights": {},
      "aggId": "2db083c3-432f-4eb1-a5ea-459ef59e03d2",
      "idVerbale": "152844633/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620664800000,
      "targa": "ff966th",
      "luogoAccertamento": "Via Terraglione ",
      "numeroRegistro": "135/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004012021110087",
      "trasgressore": "RANZATO DAVIDE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e76dadff-6715-4b9a-83e8-d47b7b72a110",
      "idVerbale": "153326335/V/2021",
      "statoVerbale": "ricorso",
      "dataOraVerbale": 1620764100000,
      "targa": "DV202CB",
      "luogoAccertamento": "VIA DOSSI ",
      "numeroRegistro": "147/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017052021110053",
      "trasgressore": "LIZIERO MATTEO",
      "obbligato": "ROSSIGNOLO SANTINA"
    }, {
      "highlights": {},
      "aggId": "c5644b36-9084-443f-b355-8f4e6861f68c",
      "idVerbale": "153326433/V/2021",
      "statoVerbale": "ricorso",
      "dataOraVerbale": 1620753000000,
      "targa": "DV202CB",
      "luogoAccertamento": "VIA DOSSI ",
      "numeroRegistro": "148/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017042021110070",
      "trasgressore": "LIZIERO MATTEO",
      "obbligato": "ROSSIGNOLO SANTINA"
    }, {
      "highlights": {},
      "aggId": "32650b8c-fd57-4588-9426-c89acd7c0946",
      "idVerbale": "152676538/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621828200000,
      "targa": "DK190JE",
      "luogoAccertamento": "Via Carturo Via Carturo ",
      "numeroRegistro": "255/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "DOS SANTOS TORRES DA SILVA DIEGO LUIS",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "003fc635-443b-489e-b0f2-4c0282324c7b",
      "idVerbale": "152548437/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622920680000,
      "targa": "EZ013CZ",
      "luogoAccertamento": "viale Della Stazione ",
      "numeroRegistro": "386/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006082021110009",
      "trasgressore": "JUSUFI ANES",
      "obbligato": "JUSUFI ERSAT"
    }, {
      "highlights": {},
      "aggId": "266cb019-24c5-4d9f-bef9-2b00891906b8",
      "idVerbale": "152732932/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622535900000,
      "targa": "dk081wy",
      "luogoAccertamento": "via Kennedy 17 via kennedy 17 ",
      "numeroRegistro": "338/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009152021110091",
      "trasgressore": "ALLA MANJOLA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8fc48793-5c3f-4fb6-956c-396e5ae90d93",
      "idVerbale": "153493635/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625645730000,
      "targa": "CT467DC",
      "luogoAccertamento": "via Busatte ",
      "numeroRegistro": "737/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012342021110062",
      "trasgressore": "FIKRI KHADIJA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e50db9df-dbd3-49ad-b731-640cd57ea2c0",
      "idVerbale": "153493430/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625638800000,
      "targa": "CT467DC",
      "luogoAccertamento": "via Busatte ",
      "numeroRegistro": "736/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012322021110003",
      "trasgressore": "FIKRI KHADIJA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "41493d22-9282-4d7a-bf29-6962cc8ed3f1",
      "idVerbale": "153460135/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622172300000,
      "targa": "DL219GH",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "307/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012352021110045",
      "trasgressore": "GUSELLA DANIELE",
      "obbligato": "EUROSFUSI SRL"
    }, {
      "highlights": {},
      "aggId": "e801f158-ccd1-48d3-9dfc-89a8f6bf6110",
      "idVerbale": "153460037/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622102700000,
      "targa": "BS507T",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "303/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012332021110079",
      "trasgressore": "FURIN ERNESTO",
      "obbligato": "SAS ROSSINI ANDREA & C."
    }, {
      "highlights": {},
      "aggId": "c335302b-9e2e-4ad3-af4c-ea3bb16642dc",
      "idVerbale": "152556034/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621965900000,
      "targa": "CM016CZ",
      "luogoAccertamento": "Via Facca SP. 47 Via Facca ",
      "numeroRegistro": "316/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005552021110073",
      "trasgressore": "SALVADOR CARLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ff2875a8-aa33-4643-be68-20c3a4e61538",
      "idVerbale": "152577137/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1622139300000,
      "targa": "ey745ry",
      "luogoAccertamento": "marconi via marconi via marconi",
      "numeroRegistro": "306/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007602021110029",
      "trasgressore": "lucon matteo",
      "obbligato": "antoniol federica"
    }, {
      "highlights": {},
      "aggId": "dc86a382-0dba-4ab2-a4fb-c20484e6baef",
      "idVerbale": "153459931/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622102100000,
      "targa": "BS507T",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "302/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012312021110020",
      "trasgressore": "FURIN ERNESTO",
      "obbligato": "SAS ROSSINI ABDREA E C."
    }, {
      "highlights": {},
      "aggId": "b1a343a2-1e94-4942-8870-5f32d0a333f7",
      "idVerbale": "195141534/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622019000000,
      "targa": "df069tj",
      "luogoAccertamento": "via Milano 1 uffici stazioni carabinieri stazione carabinieri",
      "numeroRegistro": "286/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008402021110064",
      "trasgressore": "fattore elisa",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "52aced2e-bed9-4583-b5d3-47390137208c",
      "idVerbale": "152609932/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622924700000,
      "targa": "dj619rz",
      "luogoAccertamento": "VIA DE GASPERI ",
      "numeroRegistro": "381/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005332021110075",
      "trasgressore": "KAMRUL HASAN SARKER",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "addf7090-a795-4e44-aa7a-75393e4e8df9",
      "idVerbale": "152536930/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622555100000,
      "targa": "AE297L",
      "luogoAccertamento": "Strada regionale 53 Postumia Strada regionale 53 Postumia ",
      "numeroRegistro": "341/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006112021110051",
      "trasgressore": "BATTOCCHIO Mariano",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "35aa8974-1678-401f-9f77-04c6d3bef615",
      "idVerbale": "152593333/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622742300000,
      "targa": "BT411BV",
      "luogoAccertamento": "corso corso giovanni stevanato ",
      "numeroRegistro": "359/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007742021110070",
      "trasgressore": "BAGGIO ANDREA",
      "obbligato": "TODESCO MORENA"
    }, {
      "highlights": {},
      "aggId": "1b22f6a0-036d-446a-ac28-c70280fb0a56",
      "idVerbale": "152990430/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622728500000,
      "targa": "ET911HT",
      "luogoAccertamento": "via Rossi via A. Rossi ",
      "numeroRegistro": "357/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003892021110012",
      "trasgressore": "MOROSINOTTO GIAMPIERO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "869d162a-4dd0-406f-bef2-996889859d36",
      "idVerbale": "152845035/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622753400000,
      "targa": "FG642KX",
      "luogoAccertamento": "Via Roma ",
      "numeroRegistro": "366/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004072021110078",
      "trasgressore": "GARDIN NICOLA",
      "obbligato": "GARDIN MAURO"
    }, {
      "highlights": {},
      "aggId": "5f7a7233-df0b-4ec0-b8ed-21f57cf45a10",
      "idVerbale": "152593832/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1625503500000,
      "targa": "by482fp",
      "luogoAccertamento": "via  Marconi ",
      "numeroRegistro": "724/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "TROMBETTA ALEX",
      "obbligato": "TONIN AUGUSTA"
    }, {
      "highlights": {},
      "aggId": "3b30f708-fb4d-47be-bb99-354bf4a45907",
      "idVerbale": "152610038/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622964600000,
      "targa": "en018lt",
      "luogoAccertamento": "via cosma via cosma ",
      "numeroRegistro": "390/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005342021110058",
      "trasgressore": "giordano teresa",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "484a6ffb-6ca8-46a3-8b4a-651a96b423eb",
      "idVerbale": "152990537/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622731547000,
      "targa": "ZA281SR",
      "luogoAccertamento": "via Rossi via A. Rossi ",
      "numeroRegistro": "358/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003902021110088",
      "trasgressore": "MANEA LUCIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "91305909-2c01-46e9-8d20-5b7c8643a55b",
      "idVerbale": "153334031/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625778900000,
      "targa": "CB956XT",
      "luogoAccertamento": "VIA VALLESINA ",
      "numeroRegistro": "761/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "MESCHINI JACKELYNE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0c2a45ce-f897-4d74-96d9-c0c5088f57cc",
      "idVerbale": "153338135/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625820900000,
      "targa": "FS992GY",
      "luogoAccertamento": "VIA CESARE BATTISTI ",
      "numeroRegistro": "764/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015572021110058",
      "trasgressore": "DALLA MUTTA GUERRINO",
      "obbligato": "RINALDO PATRIZIA"
    }, {
      "highlights": {},
      "aggId": "e5062fe9-a860-417e-97cc-22f58fff087f",
      "idVerbale": "153384432/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625878800000,
      "targa": "DK392ZA",
      "luogoAccertamento": "VIA LUPPIA SAN ZENO ",
      "numeroRegistro": "768/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000822021110023",
      "trasgressore": "GAFFO MATTIA",
      "obbligato": "ROSANNA LA ZIA"
    }, {
      "highlights": {},
      "aggId": "fbf29720-ba59-4493-9e8f-e8d96cd1bffe",
      "idVerbale": "153384539/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625882700000,
      "targa": "CW372CZ",
      "luogoAccertamento": "via BOSCHETTI ",
      "numeroRegistro": "794/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000552021110017",
      "trasgressore": "ORTOLAN MARY",
      "obbligato": "ORTOLAN TIZIANO"
    }, {
      "highlights": {},
      "aggId": "2da2d7f9-4e07-4784-b033-cd4b9f0238ba",
      "idVerbale": "152822130/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626007500000,
      "targa": "ce398wk",
      "luogoAccertamento": "CORSO 13 GIUGNO CORSO 13 GIUGNO ",
      "numeroRegistro": "795/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004532021110040",
      "trasgressore": "makonga kayembe abel",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ee283784-61fe-4fc2-9db1-5ec058ed62e8",
      "idVerbale": "153334433/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625968020000,
      "targa": "EH223GV",
      "luogoAccertamento": "VIA XXVIII APRILE ",
      "numeroRegistro": "798/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "DE ROMA RUGGIERO",
      "obbligato": "TOGNELLA VANNA"
    }, {
      "highlights": {},
      "aggId": "481bcb47-cbae-45e2-b103-54a5fef0d1b0",
      "idVerbale": "152616032/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1622381448000,
      "targa": "BV382DD",
      "luogoAccertamento": "VIA BASSA II ",
      "numeroRegistro": "343/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005462021110040",
      "trasgressore": "TERRIN LUCA",
      "obbligato": "RAFFAEL MONICA"
    }, {
      "highlights": {},
      "aggId": "f921ad18-cf21-4686-8393-a75920052586",
      "idVerbale": "153334530/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625968560000,
      "targa": "EZ071NA",
      "luogoAccertamento": "VIA XXVIII APRILE ",
      "numeroRegistro": "808/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "MARETTO ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e36521ed-e637-470c-bd6b-db0b0d2c09a0",
      "idVerbale": "153334139/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1625842320000,
      "targa": "BZ72MOK",
      "luogoAccertamento": "VIA ROMA VIA ROMA ",
      "numeroRegistro": "769/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "MOCANU DAN DUMITRU",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3d6cc300-ca89-43da-9b5b-8658b7ea1fce",
      "idVerbale": "153338233/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626105900000,
      "targa": "DC398FY",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "813/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015452021110076",
      "trasgressore": "CARETTA STEFANO",
      "obbligato": "Società Estense Servizi Ambientali spa"
    }, {
      "highlights": {},
      "aggId": "ebdfbffb-ab29-435c-b7e2-fed8ced3740d",
      "idVerbale": "152553634/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626163500000,
      "targa": "gc782tn",
      "luogoAccertamento": "via via cristoforo colombo ",
      "numeroRegistro": "814/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009902021110025",
      "trasgressore": "michelon simone",
      "obbligato": "avis budget italia spa fleet co saa"
    }, {
      "highlights": {},
      "aggId": "7a23574e-632b-451a-ad96-97e26e6e06a1",
      "idVerbale": "152616933/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1625765400000,
      "targa": "87F1S",
      "luogoAccertamento": "VIA PAVESE  VIA PAVESE ",
      "numeroRegistro": "751/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006342021110032",
      "trasgressore": "FANTINATO MAURO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d724d012-539f-4a6d-bf1a-fc3c620267dc",
      "idVerbale": "153324532/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1626057420000,
      "targa": "WG3272F",
      "luogoAccertamento": "SP 247 RIVIERA VIA RIVIERA ",
      "numeroRegistro": "809/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015472021110042",
      "trasgressore": "CAVALLARO ADRIANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "87e5ba0d-4537-479b-8fc2-610ce6ef0f82",
      "idVerbale": "152801135/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625737200000,
      "targa": "dl951hh",
      "luogoAccertamento": "VIA LONGHIN VIA LONGHIN ",
      "numeroRegistro": "796/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "wei abiao",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "591327d9-d332-4092-bb48-033e52bb8421",
      "idVerbale": "153334834/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626331080000,
      "targa": "DA303ZA",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "834/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "GOLDIN GIOVANNI",
      "obbligato": "GOLDIN GIANCARLO"
    }, {
      "highlights": {},
      "aggId": "a1ae1fa5-a30b-481c-b053-e681d3a8cd93",
      "idVerbale": "153324738/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626353100000,
      "targa": "BK783SM",
      "luogoAccertamento": "VIA CALDEVIGO VIA CALDEVIGO ",
      "numeroRegistro": "837/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015392021110085",
      "trasgressore": "ASSALLE ABDELLATIF",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "232c453e-248d-412c-957a-bf311fe9ae74",
      "idVerbale": "153324836/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626353700000,
      "targa": "BK783SM",
      "luogoAccertamento": "VIA CALDEVIGO VIA CALDEVIGO ",
      "numeroRegistro": "839/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "ASSALLE ABDELLATIF",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "bd4befc9-5077-42e9-946b-8c4e72ef7a89",
      "idVerbale": "152609834/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1620542100000,
      "targa": "CC567FX",
      "luogoAccertamento": "via Massanzago ",
      "numeroRegistro": "120/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "RIZZO ERICA",
      "obbligato": "RIZZO FLAVIO"
    }, {
      "highlights": {},
      "aggId": "1cf68c71-13f0-4964-ae38-bfc594817c44",
      "idVerbale": "152875939/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624537800000,
      "targa": "fz900bl",
      "luogoAccertamento": "via veronese 1 comando stazione cc ",
      "numeroRegistro": "880/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "rampazzo giacomo",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "baab07c9-4c19-4344-8294-cfaaca9d3711",
      "idVerbale": "152876037/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624537800000,
      "targa": "fz900bl",
      "luogoAccertamento": "via veronese 1 comando stazione cc ",
      "numeroRegistro": "879/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "rampazzo giacomo",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "bfe9a5b1-f7ae-4dc3-8871-a5a33a04e605",
      "idVerbale": "153493831/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626710400000,
      "targa": "dn758ah",
      "luogoAccertamento": "via  mazzini ",
      "numeroRegistro": "885/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012412021110036",
      "trasgressore": "broidic sidney",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c2923253-b3f7-4452-abb1-2d2c54cda413",
      "idVerbale": "153493733/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626703200000,
      "targa": "dn758ah",
      "luogoAccertamento": "via  mazzini ",
      "numeroRegistro": "884/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012382021110087",
      "trasgressore": "broidic sidney",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7bda83cf-10f7-49ac-a95e-d649b6fe85db",
      "idVerbale": "152865931/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619882700000,
      "targa": "BZ767FF",
      "luogoAccertamento": "VIA MATTEOTTI ",
      "numeroRegistro": "114/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010162021110048",
      "trasgressore": "POPA GEORGE IULIAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3cec0ca2-81f2-4145-983e-8e1661993fd5",
      "idVerbale": "152550637/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622743048279,
      "targa": "et716hd",
      "luogoAccertamento": "sp22 sp22 ",
      "numeroRegistro": "360/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009712021110069",
      "trasgressore": "padalino stefano",
      "obbligato": "padalino gerardo\\"
    }, {
      "highlights": {},
      "aggId": "4a98edfd-fd43-4b3a-bf8b-050faba23b31",
      "idVerbale": "152550539/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622516734000,
      "targa": "fv766vf",
      "luogoAccertamento": "via sr307 ",
      "numeroRegistro": "334/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006222021110050",
      "trasgressore": "gabriele ucci",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "00dea4af-947e-427f-bef9-898462f56af1",
      "idVerbale": "152676333/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620419400000,
      "targa": "ck608pk",
      "luogoAccertamento": "VIA ALFIERI ",
      "numeroRegistro": "103/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005092021110018",
      "trasgressore": "CELLETTI MATTEO",
      "obbligato": "BETTINARDI  CHIARA"
    }, {
      "highlights": {},
      "aggId": "61771062-3086-41bf-ad3d-e4901de9cf0d",
      "idVerbale": "152676431/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620419700000,
      "targa": "ck608pk",
      "luogoAccertamento": "VIA ALFIERI VIA ALFIERI ",
      "numeroRegistro": "104/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005082021110035",
      "trasgressore": "CELLETTI MATTEO",
      "obbligato": "BETTINARDI  CHIARA"
    }, {
      "highlights": {},
      "aggId": "817e5e85-8baf-494a-bbe2-86612455178a",
      "idVerbale": "152728536/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620410700000,
      "targa": "EC642KY",
      "luogoAccertamento": "VIA CORSICA ",
      "numeroRegistro": "106/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007782021110002",
      "trasgressore": "ZEJADIN BAJRAMI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d2c1cc2f-9b14-4b99-9321-138a6c872972",
      "idVerbale": "153439730/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620734416000,
      "targa": "dj857hr",
      "luogoAccertamento": "roma presso Uffici in intestazione",
      "numeroRegistro": "153/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000902021110073",
      "trasgressore": "alfonsi thomas",
      "obbligato": "alfonsi cristiano"
    }, {
      "highlights": {},
      "aggId": "7d4e65dc-fb47-4088-a29b-2a0f488faf11",
      "idVerbale": "152728634/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620602400000,
      "targa": "eb730lm",
      "luogoAccertamento": "via vittorio veneto ",
      "numeroRegistro": "220/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007772021110019",
      "trasgressore": "GABRIEL MORETTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5bc1366a-a476-4f4d-8049-b60b963fb0c3",
      "idVerbale": "152733539/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624381619000,
      "targa": "GD438NC",
      "luogoAccertamento": "VIA ROSSANO ",
      "numeroRegistro": "596/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009092021110007",
      "trasgressore": "CHEN BIN",
      "obbligato": "WANG QINWEI"
    }, {
      "highlights": {},
      "aggId": "bf13be28-76b2-47d5-809e-0afeaf7b570e",
      "idVerbale": "152800939/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625596800000,
      "targa": "bv781da",
      "luogoAccertamento": "Brusegana via dei colli ",
      "numeroRegistro": "738/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019072021110060",
      "trasgressore": "perez obando sebastian",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d0e487b7-fdc2-474c-8b96-54274b4f8850",
      "idVerbale": "152801938/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1626795000000,
      "targa": "PA025XL",
      "luogoAccertamento": "VIA CABRINI VIA CABRINI ",
      "numeroRegistro": "897/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "RADULJ MARJAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b9405fb7-17d1-4218-a70a-379f7f6bbc66",
      "idVerbale": "152537234/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624945500000,
      "targa": "cv173fm",
      "luogoAccertamento": "via roma ",
      "numeroRegistro": "653/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009762021110077",
      "trasgressore": "bernardo nicola",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3ef35eb2-561d-4a24-b7cf-4b27a851a16b",
      "idVerbale": "152733236/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622556000000,
      "targa": "AT368WK",
      "luogoAccertamento": "via Kennedy 17 via kennedy 17 ",
      "numeroRegistro": "456/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009112021110066",
      "trasgressore": "BUCCELLI GENNARO",
      "obbligato": "VANACORE MARIA ROSARIA"
    }, {
      "highlights": {},
      "aggId": "2d4174d8-5694-4186-ab9e-46275b0dbc82",
      "idVerbale": "152712936/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622579400000,
      "targa": "CB641KA",
      "luogoAccertamento": "SS53 Via Postumia SS53 Via Postumia SS53 Via Postumia",
      "numeroRegistro": "345/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009532021110003",
      "trasgressore": "BATTISTUTTI CATIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3073b155-0b66-4c74-a177-8e2e9556d0ee",
      "idVerbale": "152840236/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1623488880000,
      "targa": "fb237zb",
      "luogoAccertamento": "Via Terraglione Via Terraglione ",
      "numeroRegistro": "459/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004112021110010",
      "trasgressore": "CRISTIANO PAGNUCCO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3cea37dd-8fef-47b1-845f-08f1a6b0ca76",
      "idVerbale": "153319735/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1619876100000,
      "targa": "EC058XA",
      "luogoAccertamento": "VIA BORGOFURO ",
      "numeroRegistro": "67/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012132021110047",
      "trasgressore": null,
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "02ffea96-44ec-477d-9cd5-8cd0379290c2",
      "idVerbale": "152728135/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1619404823000,
      "targa": "ASSENTE",
      "luogoAccertamento": "via galileo galilei ",
      "numeroRegistro": "9/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007822021110027",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "37e970db-475f-4095-86a2-2d559b8e36de",
      "idVerbale": "152728233/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1619405400000,
      "targa": "0",
      "luogoAccertamento": "via galileo galilei ",
      "numeroRegistro": "95/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007812021110044",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b59eb322-493c-4b8e-bdb7-83375e1ae8c5",
      "idVerbale": "152577431/V/2021",
      "statoVerbale": "annullato",
      "dataOraVerbale": 1624189841000,
      "targa": "EZ237VE",
      "luogoAccertamento": "Viale Europa Viale Europa ",
      "numeroRegistro": "554/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007582021110063",
      "trasgressore": "YASSIR MAROUANI",
      "obbligato": "S.R.L. EDILMASSETTI"
    }, {
      "highlights": {},
      "aggId": "73ee3c20-799c-443f-901a-a379e8495655",
      "idVerbale": "152545733/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1624063140000,
      "targa": "et032dw",
      "luogoAccertamento": "via via san francesco ",
      "numeroRegistro": "537/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009612021110053",
      "trasgressore": "Pinaffo Thomas",
      "obbligato": "centenaro annamaria"
    }, {
      "highlights": {},
      "aggId": "4cf2bb5d-57ed-44b7-85b2-968e118232fe",
      "idVerbale": "152729036/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1621782049000,
      "targa": "EB730LM",
      "luogoAccertamento": "COMANDO STAZIONE CARABINIERI VIA DEI CONTARINI 21 COMANDO STAZIONE CARABINIERI ",
      "numeroRegistro": "253/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007162021110033",
      "trasgressore": "GABRIEL MORETTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d832355b-16f3-4697-8a0a-1fe736ecbaff",
      "idVerbale": "152800831/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624077000000,
      "targa": "EA314YG",
      "luogoAccertamento": "Via Sorio Via Sorio ",
      "numeroRegistro": "563/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "GUIDOLIN ANDREA",
      "obbligato": "COMARELLA NOEMI"
    }, {
      "highlights": {},
      "aggId": "e5888adb-efef-4911-93d8-8717e7627daa",
      "idVerbale": "152730630/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626078649000,
      "targa": "fnrr405",
      "luogoAccertamento": "via dei contarini via dei contarini ",
      "numeroRegistro": "799/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006672021110029",
      "trasgressore": "BOLLER WOLFGANG HELMUT",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d2532536-a91c-4e6f-85f0-ab10fe93e73a",
      "idVerbale": "152729330/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623995100000,
      "targa": "0",
      "luogoAccertamento": "VIA G. FALCONE via g. falcone ",
      "numeroRegistro": "579/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007652021110037",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6f0e0d6f-09b2-4104-b4db-c2c644624b60",
      "idVerbale": "152729438/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623995100000,
      "targa": "0",
      "luogoAccertamento": "VIA G. FALCONE via g. falcone ",
      "numeroRegistro": "580/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009372021110089",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "686341a7-3482-4e54-896a-ece02226a2fa",
      "idVerbale": "152729731/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623995100000,
      "targa": "0",
      "luogoAccertamento": "VIA G. FALCONE via g. falcone ",
      "numeroRegistro": "583/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009642021110002",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9151422d-a149-4497-a900-c847fc5e174f",
      "idVerbale": "152729535/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623995100000,
      "targa": "0",
      "luogoAccertamento": "VIA G. FALCONE via g. falcone ",
      "numeroRegistro": "581/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009662021110061",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7743b324-e849-4eb7-9164-bb196852c753",
      "idVerbale": "195315231/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625244300000,
      "targa": "em078bs",
      "luogoAccertamento": "via via porto ",
      "numeroRegistro": "697/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006011022021110074",
      "trasgressore": "LUNARDI LINO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "787925bd-2ad8-4806-84bb-1fdde36565af",
      "idVerbale": "152802036/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626966600000,
      "targa": "BY365ZY",
      "luogoAccertamento": "VIA GRASSI VIA GRASSI ",
      "numeroRegistro": "908/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019382021110091",
      "trasgressore": "MASUD HOSSAIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "18bb7c35-e534-4d76-a480-20e352f4f734",
      "idVerbale": "195315035/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1623221700000,
      "targa": "BF249BE",
      "luogoAccertamento": "via  aganoor ",
      "numeroRegistro": "434/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006011002021110015",
      "trasgressore": "BOVO WALTER",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "53eee8b2-5269-4c66-8b1e-de039352fd6b",
      "idVerbale": "153333336/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624976100000,
      "targa": "ct489rf",
      "luogoAccertamento": "via via vallancon nord ",
      "numeroRegistro": "659/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016302021110026",
      "trasgressore": "mazziero lorenzo",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5a292f33-ff73-42cf-a5e0-d962e57ea246",
      "idVerbale": "153332033/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1623247200000,
      "targa": "DH520JV",
      "luogoAccertamento": "VIA CHIESA DI PONSO ",
      "numeroRegistro": "429/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014152021110054",
      "trasgressore": "BATTISTINI CLAUDIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ef5eed7d-1035-4759-938c-4679afeac970",
      "idVerbale": "152577636/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1624507200000,
      "targa": "ev717sv",
      "luogoAccertamento": "VIA MAGLIO VIA MAGLIO ",
      "numeroRegistro": "603/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007562021110004",
      "trasgressore": "PALLARO FILIPPO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "35ac9a60-73ab-4396-b5fc-8e4c3b5bfc66",
      "idVerbale": "152710938/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626573000000,
      "targa": "eb504ns",
      "luogoAccertamento": "Via Boschi Via BOSCHI Via boschi",
      "numeroRegistro": "913/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Brogliati Davide",
      "obbligato": "Bizzotto Lisetta"
    }, {
      "highlights": {},
      "aggId": "ad9f443f-b67e-4d37-84b1-9a03811ab528",
      "idVerbale": "153459539/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1620201900000,
      "targa": "CN460NS",
      "luogoAccertamento": "S.P. 91 MOCENIGA ",
      "numeroRegistro": "82/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012242021110046",
      "trasgressore": "TOSETTO GIANCARLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5555ad77-9920-4dc3-92a4-9b5d404142af",
      "idVerbale": "153343432/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1627266049000,
      "targa": "EL726GW",
      "luogoAccertamento": "VIA ALBERE VIA ALBERE ",
      "numeroRegistro": "940/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "PORCELLI EMIL VINCENZO",
      "obbligato": "PIOVAN MADDALENA"
    }, {
      "highlights": {},
      "aggId": "9b63ab10-b358-4676-80fa-f42ea0202a82",
      "idVerbale": "153336538/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627221900000,
      "targa": "dj920hr",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "937/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GASPARETTO VALERIO",
      "obbligato": "MASIERO SANDRA"
    }, {
      "highlights": {},
      "aggId": "5665aa9f-8f00-4b41-bc58-4e4f5e1124aa",
      "idVerbale": "153336636/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627224000000,
      "targa": "BS600TH",
      "luogoAccertamento": "VIA MARCONI VIA MARCONI ",
      "numeroRegistro": "938/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "SACCHETTO AURORA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a2dbae0d-f9e1-4af4-a6ff-810a9de91eff",
      "idVerbale": "153342736/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626631991000,
      "targa": "FG648AC",
      "luogoAccertamento": "VIA GUIDO NEGRI VIA GUIDO NEGRI ",
      "numeroRegistro": "893/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "HASANI MAZLUM",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3ada159e-c85a-4484-a6db-3c875c915a21",
      "idVerbale": "152809636/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627226100000,
      "targa": "ca980dl",
      "luogoAccertamento": "VIA DELL'IPPODROMO VIA DELL'IPPODROMO ",
      "numeroRegistro": "941/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019312021110024",
      "trasgressore": "ferdinaid roberts",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "21e65171-daf8-4792-ba2e-bd29d5c80cf1",
      "idVerbale": "153336734/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1627279800000,
      "targa": "GE040KW",
      "luogoAccertamento": "VIA PRA' ",
      "numeroRegistro": "947/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GIULIATO LUCA",
      "obbligato": "MASIERO GIOVANNA"
    }, {
      "highlights": {},
      "aggId": "b30118f4-15bb-446a-8a64-756b690846b2",
      "idVerbale": "153385039/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627160700000,
      "targa": "FX026ZF",
      "luogoAccertamento": "VIA SPALATO VIA SPALATO ",
      "numeroRegistro": "945/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015322021110018",
      "trasgressore": "QOZMAN JAMAL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "de80c908-5f6e-4105-b45c-35ceb826b8a8",
      "idVerbale": "152866137/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1620486900000,
      "targa": "fa290za",
      "luogoAccertamento": "Piazza San Bonaventura Piazza San Bonaventura ",
      "numeroRegistro": "118/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "DIAMANTIS KONSTANTINOS",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6a9edb23-99ae-47d7-8e3a-e89bd9965413",
      "idVerbale": "152866039/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1620486900000,
      "targa": "fa290za",
      "luogoAccertamento": "Piazza San Bonaventura Piazza San Bonaventura ",
      "numeroRegistro": "113/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010172021110031",
      "trasgressore": "DIAMANTIS KONSTANTINOS",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "962ff0c0-e589-45c1-bfa7-73d1fc9e8719",
      "idVerbale": "153335735/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623337800000,
      "targa": "fd126at",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "449/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019932021110086",
      "trasgressore": "MERLIN ANDREA",
      "obbligato": "MILAN MICHELA"
    }, {
      "highlights": {},
      "aggId": "d0615451-8d60-47e2-90c8-b9eeeae3ac93",
      "idVerbale": "153321633/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1619914481840,
      "targa": "BN355PH",
      "luogoAccertamento": "VIA G.B. FERRARI ",
      "numeroRegistro": "50/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012172021110072",
      "trasgressore": "ABBAS TASSAWAR",
      "obbligato": "AHMEDZAI SHARIF"
    }, {
      "highlights": {},
      "aggId": "a1d872dd-e607-44b6-833d-eaaf0f657634",
      "idVerbale": "153325835/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1620323400000,
      "targa": "EN123CE",
      "luogoAccertamento": "VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "100/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017132021110010",
      "trasgressore": "HASSE ASHRAF",
      "obbligato": "HASSE HASSAN"
    }, {
      "highlights": {},
      "aggId": "a57075c9-df47-421f-9937-6c3c3076238c",
      "idVerbale": "153335539/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1623108600000,
      "targa": "fn482tp",
      "luogoAccertamento": "SR 10 VIA CHIESA MOTTA ",
      "numeroRegistro": "450/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013062021110047",
      "trasgressore": "CREMA DANIEL",
      "obbligato": "SALVATORI ELENA"
    }, {
      "highlights": {},
      "aggId": "e9aa853f-2002-47c3-9d0d-55d48b009c0e",
      "idVerbale": "152617530/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627328730000,
      "targa": "bk814pw",
      "luogoAccertamento": "via Filipetto ",
      "numeroRegistro": "948/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005292021110050",
      "trasgressore": "CHANNA YASSINE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c0ba986f-0eec-4f6f-880b-563a51bd62bb",
      "idVerbale": "152610332/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626451800000,
      "targa": "ve935269",
      "luogoAccertamento": "via Marconi via Marconi ",
      "numeroRegistro": "852/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007472021110064",
      "trasgressore": null,
      "obbligato": "artuso ivano"
    }, {
      "highlights": {},
      "aggId": "39aae4b8-55d9-4584-803f-7e5fa047d518",
      "idVerbale": "153330730/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1622317200000,
      "targa": "ER278EN",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "329/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017032021110087",
      "trasgressore": "POZZATO GIACOMO",
      "obbligato": "POZZATO GIULIANO"
    }, {
      "highlights": {},
      "aggId": "559b147a-3929-4a8e-aeaf-9c0e6334ee71",
      "idVerbale": "153331436/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1622937900000,
      "targa": "FM795KM",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "394/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013032021110005",
      "trasgressore": "DESTRO VITTORIO",
      "obbligato": "BERTIN ELISABETTA"
    }, {
      "highlights": {},
      "aggId": "63ae372e-f730-4419-b91f-ccd4d38e8930",
      "idVerbale": "153335138/V/2021",
      "statoVerbale": "notificato_trsgressore",
      "dataOraVerbale": 1621634100000,
      "targa": "EZ805CN",
      "luogoAccertamento": "VIA Cà MORI ",
      "numeroRegistro": "244/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014712021110032",
      "trasgressore": "BABETTO GIULIA",
      "obbligato": "GATTOLIN RAFFAELLA"
    }, {
      "highlights": {},
      "aggId": "85517866-f79d-4cbe-ba11-1dfc3de38010",
      "idVerbale": "153330437/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1621638600000,
      "targa": "EC697KZ",
      "luogoAccertamento": "VIA ATHESTE ",
      "numeroRegistro": "248/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014702021110049",
      "trasgressore": "MANZIN ENRICO",
      "obbligato": "BRUSIANI ELENA"
    }, {
      "highlights": {},
      "aggId": "4fc35d6e-ec6b-4e28-8a00-dd91a9291adb",
      "idVerbale": "152553732/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627318500000,
      "targa": "bb772ps",
      "luogoAccertamento": "via san donato ",
      "numeroRegistro": "951/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009892021110042",
      "trasgressore": "pettenuzzo alessandro",
      "obbligato": "scalco costantina giuliana"
    }, {
      "highlights": {},
      "aggId": "abcfba5a-e5a6-4e18-a992-41c705b24452",
      "idVerbale": "153332934/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623762600000,
      "targa": "FP555KZ",
      "luogoAccertamento": "VIA BANZE ",
      "numeroRegistro": "540/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015912021110038",
      "trasgressore": "MENEGHINI GRAZIANO",
      "obbligato": "M.G. NUTRITITON SRL"
    }, {
      "highlights": {},
      "aggId": "803b9b32-e9a8-49b7-888a-9be780753cbf",
      "idVerbale": "153384931/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1627159500000,
      "targa": "EH288FB",
      "luogoAccertamento": "VIA SPALATO VIA SPALATO ",
      "numeroRegistro": "944/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015312021110035",
      "trasgressore": "HU LILIANG",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "72dae6c4-e84d-4388-bd08-cdce535b3f8a",
      "idVerbale": "153340934/V/2021",
      "statoVerbale": "attesa_dati",
      "dataOraVerbale": 1627248600000,
      "targa": "CM647DA",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "952/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "TASINATO PIERANTONIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "97c52f81-adde-4536-a485-0b7220207679",
      "idVerbale": "153343539/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1627500060000,
      "targa": "CE185TM",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "958/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "LISI MIRI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "09e7084f-942a-4ee3-bafa-5ba4ff0ef25e",
      "idVerbale": "152802535/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627457100000,
      "targa": "ed34211",
      "luogoAccertamento": "VIA VENEZIA VIA VENEZIA ",
      "numeroRegistro": "959/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Michelon Moreno",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8d5978c9-3772-40f7-8aca-d89ba4b7eeb3",
      "idVerbale": "153343334/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627196405000,
      "targa": "CF947HL",
      "luogoAccertamento": "VIA PILASTRO VIA PILASTRO ",
      "numeroRegistro": "936/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": null,
      "obbligato": "RIOLO SRL"
    }, {
      "highlights": {},
      "aggId": "ff279ec5-cf57-4357-92db-300c4986181f",
      "idVerbale": "152733735/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1627390240000,
      "targa": "FT628MJ",
      "luogoAccertamento": "via Kennedy 17 via kennedy 17 ",
      "numeroRegistro": "960/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009072021110041",
      "trasgressore": "TIMIS GINA MIRELA",
      "obbligato": "TIMIS IOAN"
    }, {
      "highlights": {},
      "aggId": "4410c901-de3e-45ff-8039-2d8948a9c684",
      "idVerbale": "152801439/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627528800000,
      "targa": "CG309SG",
      "luogoAccertamento": "VIA VIA FRANCESCO RISMONDO ",
      "numeroRegistro": "961/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019732021110054",
      "trasgressore": "nuredini ervin",
      "obbligato": "NUREDINI MADJANA"
    }, {
      "highlights": {},
      "aggId": "10684b59-0b7b-4094-8b7c-49bc928ab810",
      "idVerbale": "937158824/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1621071000000,
      "targa": "FN245EF",
      "luogoAccertamento": "VESCOVANA VIA BASSA 4 COMANDO STAZIONE CC",
      "numeroRegistro": "188/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012102021110005",
      "trasgressore": "CRUCEANU DAN",
      "obbligato": "NUNZIATA TRASPORTI SCRAL"
    }, {
      "highlights": {},
      "aggId": "121ed953-deb4-4538-b19f-d30728901ed8",
      "idVerbale": "152714532/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1624337100000,
      "targa": "fw272pp",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "593/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009252021110014",
      "trasgressore": null,
      "obbligato": "BAZELLI SHPRESA"
    }, {
      "highlights": {},
      "aggId": "92f0fa70-18da-45b1-beff-42a8af7b0c21",
      "idVerbale": "153322231/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1620776400000,
      "targa": "BG306HH",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "707/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014462021110085",
      "trasgressore": null,
      "obbligato": "RIZZI RENZO"
    }, {
      "highlights": {},
      "aggId": "e2e15ef8-2313-442e-a53c-400be6e86297",
      "idVerbale": "153322339/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1620777000000,
      "targa": "BG306HH",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "708/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014622021110092",
      "trasgressore": null,
      "obbligato": "RIZZI RENZO"
    }, {
      "highlights": {},
      "aggId": "b7d1e60e-a186-44ed-a44e-dbc27f4c054e",
      "idVerbale": "153336832/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627583700000,
      "targa": "cw302da",
      "luogoAccertamento": "via cima da conegliano ",
      "numeroRegistro": "963/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "RONCOLATO DIEGO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "819b7cff-4731-4f8a-a019-5a418c4d0abd",
      "idVerbale": "153337038/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627593300000,
      "targa": "CK963RT",
      "luogoAccertamento": "VIA VOLTADILANA ",
      "numeroRegistro": "965/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "LABRAHIMI MOHAMED",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6aa0759a-0db1-4dd6-8195-341457b6de58",
      "idVerbale": "153336930/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627591200000,
      "targa": "DJ931CW",
      "luogoAccertamento": "VIA DEL COMMERCIO ",
      "numeroRegistro": "964/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CRIVELLIN DEMIS",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f7d23d2c-d9d0-44f2-a5af-fc420b71b3d0",
      "idVerbale": "152991037/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627578015000,
      "targa": "fb567dg",
      "luogoAccertamento": "gioberti via Gioberti ",
      "numeroRegistro": "962/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "BONORA FILIPPO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cf774976-4879-4615-8139-3824aa2393c5",
      "idVerbale": "153340131/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1625200200000,
      "targa": "BY657GY",
      "luogoAccertamento": "VIA STAZIE BRAGADINE VIA STAZIE BRAGADINE ",
      "numeroRegistro": "688/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008142021110041",
      "trasgressore": "ERRAJIFI HICHAM",
      "obbligato": "TAVIAN FEDERICA"
    }, {
      "highlights": {},
      "aggId": "489516a6-384b-4774-86a2-6710613d5d97",
      "idVerbale": "153340239/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1625199900000,
      "targa": "BY657GY",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "687/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016272021110077",
      "trasgressore": "ERRAJIFI HICHAM",
      "obbligato": "TAVIAN FEDERICA"
    }, {
      "highlights": {},
      "aggId": "8234a5cf-0813-4df3-b84f-c83faaccf924",
      "idVerbale": "153343735/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1627626300000,
      "targa": "BM242LX",
      "luogoAccertamento": "VIA MEGGIARO VIA MEGGIARO ",
      "numeroRegistro": "968/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "SILVAN ALBERTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2696a5e5-8ee7-4c9f-81be-2e7e8c9bfd5b",
      "idVerbale": "153343833/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1627690200000,
      "targa": "CV351PX",
      "luogoAccertamento": "VIA LUPPIA VIA LUPPIA ",
      "numeroRegistro": "974/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "TUSHE XHAFER",
      "obbligato": "TUSHE TAULANT"
    }, {
      "highlights": {},
      "aggId": "455224d1-2355-4f58-95da-a24890204a7f",
      "idVerbale": "153343931/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1627696449000,
      "targa": "FS556JS",
      "luogoAccertamento": "VIA TORNE VIA TORNE ",
      "numeroRegistro": "975/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CAVALLINI NICOLA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "df095d32-e8bd-4177-88b7-60ced756bcca",
      "idVerbale": "152578038/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627765500000,
      "targa": "CS084GA",
      "luogoAccertamento": "marconi via marconi via marconi",
      "numeroRegistro": "985/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "KOCI KEJIDI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "dd03c6c3-2ee5-4c09-b34c-da1ac3901515",
      "idVerbale": "152578136/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627767600000,
      "targa": "CS084GA",
      "luogoAccertamento": "marconi via marconi via marconi",
      "numeroRegistro": "986/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "KOCI KEJIDI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2a1896a5-3bc3-468e-b09b-e0c5400f7c98",
      "idVerbale": "152677136/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1623926520000,
      "targa": "CX751DG",
      "luogoAccertamento": "VIA SOLFERINO VIA SOLFERINO VIA SOLFERINO",
      "numeroRegistro": "518/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GIURIATO MASSIMO",
      "obbligato": "DAL SASSO DANIELA"
    }, {
      "highlights": {},
      "aggId": "12bbceb1-c9d3-4499-9ace-d32d3623685f",
      "idVerbale": "152677038/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1623925860000,
      "targa": "CX751DG",
      "luogoAccertamento": "VIA SOLFERINO VIA SOLFERINO VIA SOLFERINO",
      "numeroRegistro": "517/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GIURIATO MASSIMO",
      "obbligato": "DAL SASSO DANIELA"
    }, {
      "highlights": {},
      "aggId": "bc0319d3-6596-4ef3-af5e-9439aeb7428a",
      "idVerbale": "152676930/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1623925320000,
      "targa": "CX751DG",
      "luogoAccertamento": "VIA SOLFERINO VIA SOLFERINO VIA SOLFERINO",
      "numeroRegistro": "516/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GIURIATO MASSIMO",
      "obbligato": "DAL SASSO DANIELA"
    }, {
      "highlights": {},
      "aggId": "6ff2007d-676a-4165-b0fb-aad4b2892978",
      "idVerbale": "152990831/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623502800000,
      "targa": null,
      "luogoAccertamento": "PIAZZA SALVO D'ACQUISTO 1 PIAZZA SALVO D'ACQUISTO 1 ",
      "numeroRegistro": "461/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003942021110020",
      "trasgressore": "CIUPERCA VLADISLAV",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2f39d662-30fa-46c3-944d-cb042dd66e73",
      "idVerbale": "153326736/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1620753600000,
      "targa": "DV",
      "luogoAccertamento": "VIA DOSSI ",
      "numeroRegistro": "151/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "LIZIERO MATTEO",
      "obbligato": "ROSSIGNOLO SANTINA"
    }, {
      "highlights": {},
      "aggId": "d8fe1139-9264-43bf-b5cc-138d0e02009c",
      "idVerbale": "152555632/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1620460800000,
      "targa": "AM974VH",
      "luogoAccertamento": "via kennedy 17 ",
      "numeroRegistro": "108/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006022021110018",
      "trasgressore": "SAMB MOUSSA",
      "obbligato": "STEPHEN MICHAEL"
    }, {
      "highlights": {},
      "aggId": "8e964f51-4a03-42f0-a489-0ff66902b73a",
      "idVerbale": "153337234/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627805400000,
      "targa": "EF911VS",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1007/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002062021110054",
      "trasgressore": "USURELU TUDOR",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6ca0221b-783f-438c-9477-265e99d34770",
      "idVerbale": "153460439/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623896728000,
      "targa": "AS289WJ",
      "luogoAccertamento": "VIA DELL'ARTIGIANO ",
      "numeroRegistro": "510/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016112021110070",
      "trasgressore": null,
      "obbligato": "BURINATO SARA"
    }, {
      "highlights": {},
      "aggId": "96f9d781-408d-4e8b-accf-5714fbc8d4b8",
      "idVerbale": "153337136/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627716060000,
      "targa": "DG855VL",
      "luogoAccertamento": "VIA SANTA CROCE ",
      "numeroRegistro": "1006/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002052021110071",
      "trasgressore": "DE LISA GIUSEPPE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0c792ab1-f179-4c1d-84aa-df2cfec3d660",
      "idVerbale": "152730738/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627952400000,
      "targa": "FL894GL",
      "luogoAccertamento": "VIA GIOVANNI TONELLO 27 VIA GIOVANNI TONELLO 27 ",
      "numeroRegistro": "1005/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152730738",
      "trasgressore": "DAVIDE BARUZZO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b1630298-2a02-4912-8c2d-0e3fdc6f5d16",
      "idVerbale": "153492930/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1620464700000,
      "targa": "DZ164AP",
      "luogoAccertamento": "VIA CERESARA ",
      "numeroRegistro": "110/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012212021110004",
      "trasgressore": "DE CECCO ANTONIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1575f203-98eb-4e49-965b-063d54e2679c",
      "idVerbale": "152889835/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628064000000,
      "targa": "dg436jz",
      "luogoAccertamento": "sp 13 sp13 ",
      "numeroRegistro": "1024/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010072021110015",
      "trasgressore": "avram ana maria",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "269911b7-3cac-4c3c-8c68-a551a4abfd3e",
      "idVerbale": "152729633/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623995100000,
      "targa": "0",
      "luogoAccertamento": "VIA G. FALCONE via g. falcone ",
      "numeroRegistro": "582/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009652021110078",
      "trasgressore": "ALEXANDRU ANTONIO MERAUTA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a017aa35-16eb-4d73-aab1-174140fed8e0",
      "idVerbale": "152553135/V/2021",
      "statoVerbale": "lotto_creato",
      "dataOraVerbale": 1619467200000,
      "targa": "DP702KE",
      "luogoAccertamento": "Via guido rossa ",
      "numeroRegistro": "6/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006162021110059",
      "trasgressore": "Casarotto Matteo",
      "obbligato": "Biasio Patrizia Giuseppina"
    }, {
      "highlights": {},
      "aggId": "328065c9-ee29-4062-87c1-3a4a4ad7a242",
      "idVerbale": "152553233/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1619467200000,
      "targa": "EK357WL",
      "luogoAccertamento": "Via guido rossa ",
      "numeroRegistro": "7/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006152021110076",
      "trasgressore": "TREVISAN DEVIS",
      "obbligato": "TREVISAN MASSIMO"
    }, {
      "highlights": {},
      "aggId": "56687e78-b4c1-4591-bffd-c75e79979ab2",
      "idVerbale": "152553536/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1620486000000,
      "targa": "at418nk",
      "luogoAccertamento": "via borgo padova via borgo padova",
      "numeroRegistro": "111/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005572021110039",
      "trasgressore": "costa marco",
      "obbligato": "parolin maria angela"
    }, {
      "highlights": {},
      "aggId": "4b98a9c9-7797-4b60-8061-3c642ce49759",
      "idVerbale": "152529939/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1620377100000,
      "targa": "Fk903GS",
      "luogoAccertamento": "Cittadella via Bellinghiera ",
      "numeroRegistro": "99/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006072021110026",
      "trasgressore": "Dan Nicoleta Oana",
      "obbligato": "V-Service s.r.l."
    }, {
      "highlights": {},
      "aggId": "654493d3-e06f-455f-a9fa-51e6a3d70bfd",
      "idVerbale": "152553938/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628199000000,
      "targa": "fa696rj",
      "luogoAccertamento": "via via cristoforo colombo ",
      "numeroRegistro": "1039/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009582021110011",
      "trasgressore": "meneghetti nicola",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "241de318-cd3c-4b10-8109-34ff19f20137",
      "idVerbale": "153335432/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623044100000,
      "targa": "AT491MR",
      "luogoAccertamento": "VIA Roma Ovest ",
      "numeroRegistro": "1038/2021",
      "preferito": true,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "BIGUOTTO RAFFAELE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "07c59d31-5d7c-4fb9-b3bd-9489896c30f2",
      "idVerbale": "152518834/U/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1622865600000,
      "targa": "ev538aa",
      "luogoAccertamento": "via Kennedy 17 via kennedy 17 ",
      "numeroRegistro": "374/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "paolo andretta",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ed999ec0-45e2-4b57-995c-21241c98eed1",
      "idVerbale": "152518932/U/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1622866500000,
      "targa": "ev538aa",
      "luogoAccertamento": "via Kennedy 17 via kennedy 17 ",
      "numeroRegistro": "375/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "paolo andretta",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "19c49932-bd5d-4483-8bf4-c8a5ad93c8bc",
      "idVerbale": "152548133/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1622116200000,
      "targa": "FE834MB",
      "luogoAccertamento": "VIA DESMAN ",
      "numeroRegistro": "317/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006052021110060",
      "trasgressore": "GOBBI GIORDANO",
      "obbligato": "CANTON GIOVANNI DI CANTON LUCIO E C SNC"
    }, {
      "highlights": {},
      "aggId": "294e5106-7ebe-409b-b182-6eeebb0bd624",
      "idVerbale": "152548339/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1622118600000,
      "targa": "CW079RC",
      "luogoAccertamento": "VIA AURELIA ",
      "numeroRegistro": "318/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "STOIKO JEZON",
      "obbligato": "LEVACOVICH STEFANIA"
    }, {
      "highlights": {},
      "aggId": "a63f9ae0-6f05-4a57-a619-e2dc18734516",
      "idVerbale": "152553439/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1619739600000,
      "targa": "ex934yd",
      "luogoAccertamento": "Via valsugana ",
      "numeroRegistro": "34/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Sgarbossa Jacopo",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a68343b8-1deb-49fe-a9f1-278809c36a58",
      "idVerbale": "152555339/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1619791200000,
      "targa": "en724rp",
      "luogoAccertamento": "via kennedy 17 ",
      "numeroRegistro": "56/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "ioniche constantin stefan",
      "obbligato": "Muraru Ana Cristina"
    }, {
      "highlights": {},
      "aggId": "ef02efe2-8935-41b6-aa07-b77be3d19e5d",
      "idVerbale": "152555936/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1622224800000,
      "targa": "CM016CZ",
      "luogoAccertamento": "Via Facca SP. 47 Via Facca ",
      "numeroRegistro": "315/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006092021110085",
      "trasgressore": "SALVADOR CARLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b581fae3-30c0-4288-9e5f-40e82d93458f",
      "idVerbale": "153355437/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628300400000,
      "targa": "EL773FV",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1047/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "RENNA ROSARIO",
      "obbligato": "CARUSO CONCETTA"
    }, {
      "highlights": {},
      "aggId": "2f938ecc-9ff2-4031-9ae4-9875757ad72f",
      "idVerbale": "153353536/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628381100000,
      "targa": "DK313FF",
      "luogoAccertamento": "VIA GUIDO NEGRI ",
      "numeroRegistro": "1056/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003542021110049",
      "trasgressore": "TREVISAN FILIPPO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "feb11b1c-797f-4cd5-87d0-b4ce1bcadec0",
      "idVerbale": "153385431/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628021400000,
      "targa": "FJ020ZV",
      "luogoAccertamento": "VIA PASQUALE LONGO ",
      "numeroRegistro": "1017/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015242021110061",
      "trasgressore": "PERUFFO LUCA",
      "obbligato": "PELOSO MASSIMILIANO"
    }, {
      "highlights": {},
      "aggId": "22afe969-0da5-4e11-b413-7493dcc04aaa",
      "idVerbale": "152779936/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1628449200000,
      "targa": "BW548LR",
      "luogoAccertamento": "PRIMA STRADA PRIMA STRADA ",
      "numeroRegistro": "1066/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": null,
      "obbligato": "Salkanovic Walter"
    }, {
      "highlights": {},
      "aggId": "875e7421-e1a6-4e5d-8b27-fccb50684dc1",
      "idVerbale": "153345332/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628413080000,
      "targa": "CD222GC",
      "luogoAccertamento": "VIA ARZERE ",
      "numeroRegistro": "1068/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000782021110091",
      "trasgressore": "AIT EL FAKIRA ABDELJALIL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "947c82cc-6567-4586-a9f5-2cd380c9cb4a",
      "idVerbale": "152638937/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628455800000,
      "targa": "cn938vm",
      "luogoAccertamento": "Sr 307 via Antoniana via Antoniana ",
      "numeroRegistro": "1069/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005122021110060",
      "trasgressore": "andrea graziani",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1e1e957b-3bfd-4a14-a0f1-27d8b944620e",
      "idVerbale": "153405231/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628445600000,
      "targa": "bz098yk",
      "luogoAccertamento": "via madonnetta ",
      "numeroRegistro": "1083/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002492021110067",
      "trasgressore": "chahyd ouadia",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "956969a3-beca-4659-8c44-3fa3e41dd8a3",
      "idVerbale": "152711232/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628534400000,
      "targa": "gc626ty",
      "luogoAccertamento": "SS53 - VIA POSTUMIA SS53 - VIA POSTUMIA ",
      "numeroRegistro": "1081/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006872021110061",
      "trasgressore": "PACI ALESSANDRO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "44f152ed-60e2-4620-859c-b8e2ef195e55",
      "idVerbale": "153353634/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628615722000,
      "targa": "cm867xt",
      "luogoAccertamento": "via vallesana ",
      "numeroRegistro": "1090/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003522021110083",
      "trasgressore": "GAINO MIRKO",
      "obbligato": "BROGGIATO CRISTIANO"
    }, {
      "highlights": {},
      "aggId": "79e04a6e-6b56-4c1f-a431-2a8ef214d3d7",
      "idVerbale": "152711330/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628384400000,
      "targa": "ea061zr",
      "luogoAccertamento": "SS53 Via Dante SS53 Via Dante SS53 Via Dante",
      "numeroRegistro": "1093/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "RAVAROTTO ELEONORA",
      "obbligato": "RAVAROTTO STEFANO"
    }, {
      "highlights": {},
      "aggId": "a42ff6ad-4a2d-416b-b823-67636014b7ab",
      "idVerbale": "153460732/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1625116200000,
      "targa": "GB928MG",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "674/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016172021110061",
      "trasgressore": "BASSAN PAOLO",
      "obbligato": "EUROSFUSI SRL"
    }, {
      "highlights": {},
      "aggId": "e665b64f-e663-4279-bb52-2960ab60b768",
      "idVerbale": "152711438/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628637000000,
      "targa": "paxt1b1",
      "luogoAccertamento": "via copernico via copernico ",
      "numeroRegistro": "1091/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152711438",
      "trasgressore": "battocchio dario",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "47d09312-36dc-4568-a8e6-5006cb193206",
      "idVerbale": "153355936/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628721300000,
      "targa": "cp108fc",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "1101/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MELEGA ANATOLIE",
      "obbligato": "OXENTI ANGELA"
    }, {
      "highlights": {},
      "aggId": "e9b9ffdb-ca85-4d2d-af5a-a97a33334634",
      "idVerbale": "153385538/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628688060000,
      "targa": "EK440HD",
      "luogoAccertamento": "via Via Saoncella - sp.90 ",
      "numeroRegistro": "1100/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015232021110078",
      "trasgressore": "COLOMBI MAURIZIO",
      "obbligato": "COLOMBI MARINO"
    }, {
      "highlights": {},
      "aggId": "3d0fa6b6-f82e-470e-814a-39c3feadbdcd",
      "idVerbale": "152876634/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628683200000,
      "targa": "ep64873",
      "luogoAccertamento": "via veronese 1 comando stazione cc ",
      "numeroRegistro": "1099/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010082021110091",
      "trasgressore": "diego pavan dalla torre",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c1d065ea-37da-4590-9041-97ea2d0b5e5f",
      "idVerbale": "152729839/U/2021",
      "statoVerbale": "archiviato",
      "dataOraVerbale": 1624705253000,
      "targa": "X2TKDC",
      "luogoAccertamento": "COMANDO STAZIONE CARABINIERI VIA DEI CONTARINI 21 COMANDO STAZIONE CARABINIERI ",
      "numeroRegistro": "624/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006742021110003",
      "trasgressore": "GAMBA ALEX",
      "obbligato": "LAZZARIN PATRIZIA"
    }, {
      "highlights": {},
      "aggId": "c619be97-d003-4dd5-9080-e0226d325085",
      "idVerbale": "153345430/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628769360000,
      "targa": "AW410CY",
      "luogoAccertamento": "VIA RIVA DI FIUME ",
      "numeroRegistro": "1108/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022102021110024",
      "trasgressore": "DURIC GOJKO",
      "obbligato": "RADULOVIC MOZULA"
    }, {
      "highlights": {},
      "aggId": "947e7a5e-f039-4df7-8ad9-8943daab0755",
      "idVerbale": "152876732/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628684400000,
      "targa": "ep64873",
      "luogoAccertamento": "via veronese 1 comando stazione cc ",
      "numeroRegistro": "1105/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010102021110057",
      "trasgressore": "diego pavan dalla torre",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "195fdd5d-c51e-4e18-adfd-59f39015e080",
      "idVerbale": "152876938/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628686800000,
      "targa": "ep64873",
      "luogoAccertamento": "via veronese 1 comando stazione cc ",
      "numeroRegistro": "1107/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010122021110023",
      "trasgressore": "diego pavan dalla torre",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1bb7d24f-5305-4191-9654-59a7ca08370d",
      "idVerbale": "153345537/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628780580000,
      "targa": "CR302NY",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "1109/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027772021110057",
      "trasgressore": "HOXHAJ KLODJAN",
      "obbligato": "AJAZI KELVIN"
    }, {
      "highlights": {},
      "aggId": "383d8a8a-8973-41b2-bf92-a42171548abd",
      "idVerbale": "152876830/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628685600000,
      "targa": "ep64873",
      "luogoAccertamento": "via veronese 1 comando stazione cc ",
      "numeroRegistro": "1106/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010112021110040",
      "trasgressore": "diego pavan dalla torre",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e7469714-8e52-47e7-ab51-f4da01bff850",
      "idVerbale": "153385333/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1627973100000,
      "targa": "BD454WX",
      "luogoAccertamento": "VIA VIA LONGO PASQUALE ",
      "numeroRegistro": "1021/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015222021110002",
      "trasgressore": "REZMIVES IONUT MARIN",
      "obbligato": "MAHRACH NOUR EDDINE"
    }, {
      "highlights": {},
      "aggId": "ec0058f5-927a-4647-8deb-67069e8e2bc1",
      "idVerbale": "152506133/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628764500000,
      "targa": "av363yh",
      "luogoAccertamento": "via sen. g. cittadella ",
      "numeroRegistro": "1119/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008022021110059",
      "trasgressore": "cherubin roberto",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "dcf4fea5-f984-48b7-a7fe-7b00273296fe",
      "idVerbale": "152617638/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628897400000,
      "targa": "el032yt",
      "luogoAccertamento": "via roma  ",
      "numeroRegistro": "1125/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005282021110067",
      "trasgressore": "Oancea Alexandru Daniel",
      "obbligato": "De Marchi Dorino"
    }, {
      "highlights": {},
      "aggId": "844f049b-cbcb-4eb3-9821-b2566969a2dd",
      "idVerbale": "153353938/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628819400000,
      "targa": "FS000WB",
      "luogoAccertamento": "VIA DESERTO ",
      "numeroRegistro": "1130/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "BROCCO LUCA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "07b114fe-720f-4f2f-aed7-56f30d970e57",
      "idVerbale": "153461232/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629022320000,
      "targa": "CL916VC",
      "luogoAccertamento": "VIA ROMA - SP15 VIA CALMANA ",
      "numeroRegistro": "1135/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016042021110003",
      "trasgressore": "FAVAZZA ADRIANO",
      "obbligato": "MICHELIN CARLA"
    }, {
      "highlights": {},
      "aggId": "a1eed451-d964-4c15-8614-1403cc44bb8c",
      "idVerbale": "153461134/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629010800000,
      "targa": "fe306es",
      "luogoAccertamento": "VIA VITTORIO ",
      "numeroRegistro": "1133/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016062021110062",
      "trasgressore": "currò stefania",
      "obbligato": "currò salvatore"
    }, {
      "highlights": {},
      "aggId": "6074d97c-fa02-4165-b157-c8acf14f7273",
      "idVerbale": "153354036/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629055320000,
      "targa": "EX077NL",
      "luogoAccertamento": "VIA GUIDO NEGRI ",
      "numeroRegistro": "1141/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "DE SANTI FEDERICO",
      "obbligato": "PERTEGATO MARIA LUISA"
    }, {
      "highlights": {},
      "aggId": "e894a365-7676-4c17-bbfe-688febdf06c4",
      "idVerbale": "153345939/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629136200000,
      "targa": "CX028CH",
      "luogoAccertamento": "VIA STAZIONE ",
      "numeroRegistro": "1143/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027702021110083",
      "trasgressore": "TAHIRI ABDELFATTAH",
      "obbligato": "TAHIRI EL HABIB"
    }, {
      "highlights": {},
      "aggId": "bc207913-b1d4-442e-ae37-59e7b48b373b",
      "idVerbale": "152730836/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629132022000,
      "targa": "GD200MW",
      "luogoAccertamento": "VIALE SILVESTRO CAMERINI VIA SILVESTRO CAMERINI PIAZZOLA SUL BRENTA ",
      "numeroRegistro": "1145/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006632021110004",
      "trasgressore": "BISELLO PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a39e0bd3-bf33-43e8-bcdb-ecab62798a84",
      "idVerbale": "152876135/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626220800000,
      "targa": "fs831ct",
      "luogoAccertamento": "piazza cristoforo colombo 1 policlinico di abano terme ",
      "numeroRegistro": "878/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010042021110066",
      "trasgressore": "cristiano andrea lilla",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "06e3bf85-eba3-4595-b3fc-46396604f082",
      "idVerbale": "152730934/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1629206400000,
      "targa": "dy123kc",
      "luogoAccertamento": "COMANDO STAZIONE CARABINIERI VIA DEI CONTARINI 21 COMANDO STAZIONE CARABINIERI ",
      "numeroRegistro": "1146/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006622021110021",
      "trasgressore": null,
      "obbligato": "ROSET SERGIU"
    }, {
      "highlights": {},
      "aggId": "59e9aada-19d0-4e06-a64a-9a000303ff47",
      "idVerbale": "153345831/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628919900000,
      "targa": "FD900TC",
      "luogoAccertamento": "VIA SCHIAVIN ",
      "numeroRegistro": "1149/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "BORINAGA JULLIAN DARYL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "79241fbd-f095-418e-ac04-77e5951aeead",
      "idVerbale": "152876331/U/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1628424000000,
      "targa": "em820bv",
      "luogoAccertamento": "via veronese 1 comando stazione cc ",
      "numeroRegistro": "1059/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010092021110074",
      "trasgressore": "turcan nicolae",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a50869c1-bd3a-4f0b-8921-64ef9b4c6f23",
      "idVerbale": "153345733/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628903400000,
      "targa": "DA455YW",
      "luogoAccertamento": "VIA PEAGNOLA ",
      "numeroRegistro": "1148/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027742021110015",
      "trasgressore": "GUSELLA ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b4b009d2-236a-4ac2-a44d-1aa026832520",
      "idVerbale": "153345635/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628898900000,
      "targa": "EX389KK",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "1147/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027752021110091",
      "trasgressore": "BOSCARO MANUEL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "142104e5-00a0-4f07-a6b8-4886a9b6f21a",
      "idVerbale": "153461438/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629271020000,
      "targa": "DE302RM",
      "luogoAccertamento": "VIA ROMA - SP15 VIA CALMANA ",
      "numeroRegistro": "1156/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016032021110020",
      "trasgressore": "FRANCHIN MARTINO",
      "obbligato": "PASQUALOTTO CARLA"
    }, {
      "highlights": {},
      "aggId": "686637ba-42c5-4a6d-bdde-d1043efcf965",
      "idVerbale": "153461330/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629269220000,
      "targa": "GC896BT",
      "luogoAccertamento": "VIA ROMA - SP15 VIA CALMANA ",
      "numeroRegistro": "1154/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016052021110079",
      "trasgressore": "LOLO MARILENA",
      "obbligato": "MARCHETTI ILLO"
    }, {
      "highlights": {},
      "aggId": "717531a1-3387-4808-8648-ed3556f0cd57",
      "idVerbale": "153384030/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1621788600000,
      "targa": "DF217LN",
      "luogoAccertamento": "VIA LUPPIA ALBERI ",
      "numeroRegistro": "256/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012082021110039",
      "trasgressore": "SAQIL MOULAY ABDELHADI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "23295402-30c8-4e69-aefa-874eea0e7cd7",
      "idVerbale": "153384236/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1622361360000,
      "targa": "ch090tt",
      "luogoAccertamento": "via Roma ",
      "numeroRegistro": "330/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000832021110006",
      "trasgressore": null,
      "obbligato": "GIRONDA MARIA CRISTIA"
    }, {
      "highlights": {},
      "aggId": "9dc9f67c-cfae-4930-9a4c-bd42b609ede8",
      "idVerbale": "153346135/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629276900000,
      "targa": "CY419VR",
      "luogoAccertamento": "VIA IV NOVEMBRE VIA IV NOVEMBRE VIA IV NOVEMBRE",
      "numeroRegistro": "1168/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027382021110069",
      "trasgressore": "BUSSOLIN LINDA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3c875884-27f6-4304-8466-c730ca0ecad4",
      "idVerbale": "153383638/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1619886600000,
      "targa": "CS217GE",
      "luogoAccertamento": "STAZIE BRAGADINE ",
      "numeroRegistro": "57/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012662021110076",
      "trasgressore": "ROMMANY OMAR",
      "obbligato": "ZORZATO MASSIMO"
    }, {
      "highlights": {},
      "aggId": "e07bbf3e-0f3b-407a-aeca-a1a62f24ef31",
      "idVerbale": "153334638/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626324900000,
      "targa": "DE518WL",
      "luogoAccertamento": "VIA MASSIMO D'AZEGLIO ",
      "numeroRegistro": "832/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015442021110000",
      "trasgressore": "STURARO MATTEO",
      "obbligato": "MIOLA MONICA"
    }, {
      "highlights": {},
      "aggId": "0978ee6a-0bb1-466e-9156-2bddb6215b22",
      "idVerbale": "152801331/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626458400000,
      "targa": "fm076kl",
      "luogoAccertamento": "VIA GUIZZA VIA GUIZZA ",
      "numeroRegistro": "854/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019012021110069",
      "trasgressore": "salvo' sandro",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c78c7ecf-b76d-4449-9c78-951e6929ad11",
      "idVerbale": "153324239/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1625984760000,
      "targa": "EM622ZP",
      "luogoAccertamento": "VIA GAMBINA ",
      "numeroRegistro": "780/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015482021110025",
      "trasgressore": "ZANNATO DAMIANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "35f22b36-8a87-4aa7-b670-33811725e379",
      "idVerbale": "153404839/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1625926200000,
      "targa": "CD657SN",
      "luogoAccertamento": "via adige via adige via adige",
      "numeroRegistro": "775/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002562021110041",
      "trasgressore": "mazzali simone",
      "obbligato": "CESTARI SANDRA"
    }, {
      "highlights": {},
      "aggId": "21be0bb4-ea80-4645-b27c-126c6a54d413",
      "idVerbale": "153493332/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1623253804000,
      "targa": "EV788RT",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "460/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012292021110054",
      "trasgressore": "TORCELLI BIAGIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "156f8d43-c96b-4549-92f6-3239951b2944",
      "idVerbale": "153385235/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1627972200000,
      "targa": "BD454WX",
      "luogoAccertamento": "VIA PASQUALE LONGO ",
      "numeroRegistro": "1022/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015252021110044",
      "trasgressore": "REZMIVES IONUT MARIN",
      "obbligato": "MAHRACH NOUR EDDINE"
    }, {
      "highlights": {},
      "aggId": "6cc9ccf1-8e10-4bdb-8d5c-d585e826d21f",
      "idVerbale": "153324435/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625994540000,
      "targa": "DS511HV",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "782/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015532021110033",
      "trasgressore": "POZZATO MATTEO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4da76001-ac77-4e67-9fa9-da868239b672",
      "idVerbale": "153405035/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628008200000,
      "targa": "AV254ZA",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "1045/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002502021110050",
      "trasgressore": "rossi luigi",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "96ff4594-c18e-4033-9c29-92e5eb2c5bfa",
      "idVerbale": "152617335/U/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1626337840000,
      "targa": "GC872BS",
      "luogoAccertamento": "via piave ",
      "numeroRegistro": "859/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005432021110091",
      "trasgressore": "bussolaro matteo",
      "obbligato": "Vedovato Diana"
    }, {
      "highlights": {},
      "aggId": "bf8ebaac-1501-4cd9-8a58-38ccd030f8f7",
      "idVerbale": "153344235/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628431200000,
      "targa": "BT755R",
      "luogoAccertamento": "VIA CORTONA ",
      "numeroRegistro": "1065/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015952021110063",
      "trasgressore": "PAVAN MICHELE",
      "obbligato": "AZIENDA AGRICOLA DESTRO ALBERTO"
    }, {
      "highlights": {},
      "aggId": "11e9747a-91d4-4307-8cf1-8ab7d3015410",
      "idVerbale": "153461036/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625824020000,
      "targa": "CL793SP",
      "luogoAccertamento": "SP 76  BRESEGANA ",
      "numeroRegistro": "760/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016072021110045",
      "trasgressore": "TOFFANIN ALESSANDRO",
      "obbligato": "CASTAGNA SCAVI DI CASTAGNA TIZIANO ED ALESSANDRO SNC"
    }, {
      "highlights": {},
      "aggId": "55f57ed7-0cd6-4ffd-b058-57ba4a521736",
      "idVerbale": "152889737/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626861600000,
      "targa": "ea23694",
      "luogoAccertamento": "via veronese 1 comando stazione cc ",
      "numeroRegistro": "911/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010062021110032",
      "trasgressore": "de marchi domenico",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8ca50960-1e59-4c62-8570-f6a3d201dd63",
      "idVerbale": "152617433/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626975000000,
      "targa": "DN779RL",
      "luogoAccertamento": "via  guizze alte ",
      "numeroRegistro": "910/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005302021110033",
      "trasgressore": "salvalaggio marco",
      "obbligato": "MACCHION MARA"
    }, {
      "highlights": {},
      "aggId": "19ffadb0-e5c5-4207-a018-bac1d72245a0",
      "idVerbale": "153344137/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628430600000,
      "targa": "BT755R",
      "luogoAccertamento": "VIA CORTONA ",
      "numeroRegistro": "1067/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022412021110055",
      "trasgressore": "PAVAN MICHELE",
      "obbligato": "AZIENDA AGRICOLA DESTRO ALBERTO"
    }, {
      "highlights": {},
      "aggId": "3565c786-a664-4431-980f-062afcaaba93",
      "idVerbale": "153344333/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628431800000,
      "targa": "BT755R",
      "luogoAccertamento": "VIA CORTONA ",
      "numeroRegistro": "1063/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022422021110038",
      "trasgressore": "PAVAN MICHELE",
      "obbligato": "AZIENDA AGRICOLA DESTRO ALBERTO"
    }, {
      "highlights": {},
      "aggId": "bc9e10a9-aeaa-4e31-9785-66a023cca04f",
      "idVerbale": "152530035/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625819100000,
      "targa": "dz359an",
      "luogoAccertamento": "via via Bellinghiera ",
      "numeroRegistro": "762/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008032021110042",
      "trasgressore": "bizzotto andrea",
      "obbligato": "bizzotto sergio"
    }, {
      "highlights": {},
      "aggId": "36508c6a-423d-4922-90cf-ab2ecc531233",
      "idVerbale": "152577734/U/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1625673643000,
      "targa": "dk317dl",
      "luogoAccertamento": "italia via firenze via firenze",
      "numeroRegistro": "746/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007552021110021",
      "trasgressore": "PETTENUZZO FILIPPO",
      "obbligato": "PETTENUZZO MARIO"
    }, {
      "highlights": {},
      "aggId": "95b3266f-7b80-4529-b99c-cbdffaac1d9a",
      "idVerbale": "152712838/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1622571300000,
      "targa": "de931le",
      "luogoAccertamento": "SS53 Via Postumia SS53 Via Postumia SS53 Via Postumia",
      "numeroRegistro": "344/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009002021110067",
      "trasgressore": "FLORIANA CARDILLO",
      "obbligato": "TINA WALTER"
    }, {
      "highlights": {},
      "aggId": "aa0e1670-d30d-4c67-8ca3-0e0ca3077379",
      "idVerbale": "152577235/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1622898611000,
      "targa": "EM826TD",
      "luogoAccertamento": "Via Via Camposampiero ",
      "numeroRegistro": "392/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007612021110012",
      "trasgressore": "Salvador Mattia",
      "obbligato": "Salvador Giacomo"
    }, {
      "highlights": {},
      "aggId": "550c9dc0-3d7b-4f68-bde3-34cfceaf5029",
      "idVerbale": "152733432/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1624141200000,
      "targa": "bt217bz",
      "luogoAccertamento": "via dante ",
      "numeroRegistro": "576/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009102021110083",
      "trasgressore": "STEPHEN MICHAEL",
      "obbligato": "SAMB MOUSSA"
    }, {
      "highlights": {},
      "aggId": "0740937b-d8da-4496-8770-cde57c996fec",
      "idVerbale": "937081522/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1624992600000,
      "targa": "CS787PG",
      "luogoAccertamento": "VIA PREVITALI ",
      "numeroRegistro": "662/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006011552021110010",
      "trasgressore": "MARIANI CLAUDIO",
      "obbligato": "SE.VEN SRL"
    }, {
      "highlights": {},
      "aggId": "f809a7f8-d00e-449e-9ec7-8970d149e0a9",
      "idVerbale": "153332131/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623252000000,
      "targa": "CB588WC",
      "luogoAccertamento": "VIA VENETO VIA VENETO ",
      "numeroRegistro": "430/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014162021110037",
      "trasgressore": "SARTORELLO SIMONE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "978b5be1-1647-4725-858a-016d0b6b77cf",
      "idVerbale": "153332239/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623253920000,
      "targa": "CV286KP",
      "luogoAccertamento": "VIA VENETO VIA VENETO ",
      "numeroRegistro": "427/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014172021110020",
      "trasgressore": "ESPOSITO LUIGI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2ce1e636-9687-4018-978e-f2e82f44eeb0",
      "idVerbale": "153350334/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623372900000,
      "targa": "EM286CK",
      "luogoAccertamento": "VIA CALDEVIGO VIA CALDEVIGO ",
      "numeroRegistro": "446/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016342021110051",
      "trasgressore": "FRIOLO MIRCO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "bf25c1d3-236d-4a27-a14e-6ed41e639d5c",
      "idVerbale": "152577333/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622911351000,
      "targa": "ES750FR",
      "luogoAccertamento": "Via Castellana SR53 Via Castellana SR53 ",
      "numeroRegistro": "393/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007592021110046",
      "trasgressore": "Morano Pasquale",
      "obbligato": "Miazzi Valentina"
    }, {
      "highlights": {},
      "aggId": "2bca0f17-c5c2-4e5e-9a9f-39b21e87b301",
      "idVerbale": "153331739/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623185446000,
      "targa": "GB894BG",
      "luogoAccertamento": "VIA SPALATO VIA SPALATO ",
      "numeroRegistro": "416/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013102021110072",
      "trasgressore": "MSATFI NOREDDINE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1f868b68-e8ff-4da8-bd28-6f9053ebb7f5",
      "idVerbale": "153332337/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1623255360000,
      "targa": "CV286KP",
      "luogoAccertamento": "VIA VENETO VIA VENETO ",
      "numeroRegistro": "428/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019902021110044",
      "trasgressore": "ESPOSITO LUIGI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "71f4d789-cb8c-47fa-8b9c-8c7e09227d10",
      "idVerbale": "153342834/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1626638458000,
      "targa": "CL522RV",
      "luogoAccertamento": "VIA BOSCHETTI VIA BOSCHETTI ",
      "numeroRegistro": "868/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002302021110018",
      "trasgressore": "MEGGIORIN DENIS",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6fef9d5d-90d2-4035-95cf-320bb26e5717",
      "idVerbale": "153338331/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1626618600000,
      "targa": "FK922AJ",
      "luogoAccertamento": "VIA CASETTE SALTARE VIA CASETTE SALTARE LOCALITA DESERTO DI ESTE ",
      "numeroRegistro": "870/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002292021110035",
      "trasgressore": "CAPUZZO KARIS",
      "obbligato": "GARBIN GIOVANNA"
    }, {
      "highlights": {},
      "aggId": "2d4de0e8-d418-44ea-ab64-2af479645312",
      "idVerbale": "153338439/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1626620700000,
      "targa": "FK922AJ",
      "luogoAccertamento": "VIA CASETTE SALTARE VIA CASETTE SALTARE LOCALITA DESERTO DI ESTE ",
      "numeroRegistro": "872/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015332021110001",
      "trasgressore": "CAPUZZO KARIS",
      "obbligato": "GARBIN GIOVANNA"
    }, {
      "highlights": {},
      "aggId": "f3b6b9f1-8fbc-4f5e-b3ba-03d8af02c0e5",
      "idVerbale": "195315133/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624536300000,
      "targa": "DJ486LT",
      "luogoAccertamento": "via vallegia via ",
      "numeroRegistro": "899/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006018212021110034",
      "trasgressore": "PEAGNO ROSSELLA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e2213691-adc6-43ee-8d92-b6443c73aede",
      "idVerbale": "153335931/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626912300000,
      "targa": "GC754BT",
      "luogoAccertamento": "VIA ASSISI ",
      "numeroRegistro": "901/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002362021110009",
      "trasgressore": "KAMENICA DARIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5a6d0852-c28c-474a-b9e2-5190f7062a27",
      "idVerbale": "153336235/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626913200000,
      "targa": "GC754BT",
      "luogoAccertamento": "VIA ASSISI ",
      "numeroRegistro": "904/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002392021110051",
      "trasgressore": "KAMENICA DARIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e1bfb516-c40f-4467-9439-d53303eb99b6",
      "idVerbale": "153338536/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626740100000,
      "targa": "bw748ss",
      "luogoAccertamento": "Este (PD) via Rovigo via rovigo ",
      "numeroRegistro": "907/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002332021110060",
      "trasgressore": "CANEVAROLO DARIO",
      "obbligato": "CANEVAROLO GIAMPAOLO"
    }, {
      "highlights": {},
      "aggId": "81be9c93-6685-4595-814b-19f32e941b2d",
      "idVerbale": "153336333/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627149780000,
      "targa": "DW952DF",
      "luogoAccertamento": "VIA PRINCIPE AMEDEO ",
      "numeroRegistro": "927/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002142021110011",
      "trasgressore": "DROSTICI SIMONE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3420e859-8549-41b2-a44f-7bf20863cd3f",
      "idVerbale": "152802330/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627089300000,
      "targa": "eg904ac",
      "luogoAccertamento": "VIA CHIESANUOVA VIA CHIESANUOVA ",
      "numeroRegistro": "922/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019542021110005",
      "trasgressore": "LEGDANI AYOUB",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f1a48fe4-0e33-43f8-a7c6-f1f82ab0cf74",
      "idVerbale": "152802438/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627092900000,
      "targa": "fe851wt",
      "luogoAccertamento": "Via Ca' Rossa ",
      "numeroRegistro": "923/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019532021110022",
      "trasgressore": "boschetto fabio",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ddbc7242-c60f-4668-a80f-305e75f473b0",
      "idVerbale": "153383834/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1621149600000,
      "targa": "ag96vla",
      "luogoAccertamento": "via Circonvallazione ",
      "numeroRegistro": "191/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "VIOREL CONDURACHI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cce8db86-5187-4ca4-992d-0cf90f78045a",
      "idVerbale": "153385636/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629532200000,
      "targa": "DZ355NE",
      "luogoAccertamento": "Via San benedetto ",
      "numeroRegistro": "1189/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022132021110066",
      "trasgressore": "BRESSAN LORENZO",
      "obbligato": "TAMBURIN DANIELA"
    }, {
      "highlights": {},
      "aggId": "85abb5ed-a3fb-402d-8574-eb56e4250a36",
      "idVerbale": "152556436/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1629496200000,
      "targa": "DE748HB",
      "luogoAccertamento": "VIA SANTA MARIA VIA SANTA MARIA ",
      "numeroRegistro": "1187/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "RACHID AHMED",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "fc67e371-f1f4-44f1-b2c0-77ecd5d73cd9",
      "idVerbale": "152550833/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629585900000,
      "targa": "dp857kc",
      "luogoAccertamento": "via via cristoforo colombo ",
      "numeroRegistro": "1195/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009832021110051",
      "trasgressore": "bacchin alberto",
      "obbligato": "gastronomia veneta sas di Cazzaro Vittorina"
    }, {
      "highlights": {},
      "aggId": "670bf2e5-1a7e-40ff-ba0b-7261a319eb8a",
      "idVerbale": "153385930/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629624600000,
      "targa": "CZ719CC",
      "luogoAccertamento": "Via San benedetto ",
      "numeroRegistro": "1208/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014982021110038",
      "trasgressore": "MUZAFFAR KAMRAN",
      "obbligato": "BASIT ABDUL"
    }, {
      "highlights": {},
      "aggId": "870c406e-e7df-4ec4-8d74-893fa8cb3663",
      "idVerbale": "152556533/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1629688500000,
      "targa": "ch196wv",
      "luogoAccertamento": "via aurelia via aurelia ",
      "numeroRegistro": "1205/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007002021110026",
      "trasgressore": "hajdau vasilica alin",
      "obbligato": "dielibas ovidiu"
    }, {
      "highlights": {},
      "aggId": "da7a4c97-cb67-4838-8a21-5a09614b60a7",
      "idVerbale": "153385734/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1629616920000,
      "targa": "KPGZ543",
      "luogoAccertamento": "VIA CIRCONVALLAZIONE VIA CIRCONVALLAZIONE ",
      "numeroRegistro": "1207/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015002021110004",
      "trasgressore": "ZOCCA GIULIANA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b4b795c0-3831-4b58-b245-db43b6403bb7",
      "idVerbale": "152506231/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629574200000,
      "targa": "00000",
      "luogoAccertamento": "via aurelia via aurelia ",
      "numeroRegistro": "1209/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "SECK ABDOU LAHAT",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6ee76aee-4386-46e6-8996-ec2ac4741d7d",
      "idVerbale": "152593930/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1629242400000,
      "targa": "DT181EJ",
      "luogoAccertamento": "viale della vittoria  presso stazione carabinieri di piombino dese",
      "numeroRegistro": "1201/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007682021110079",
      "trasgressore": "HANKI ADIL",
      "obbligato": "ESSADIKI NADIA"
    }, {
      "highlights": {},
      "aggId": "caa65fce-68f1-4d0a-9673-8f413e2baaf3",
      "idVerbale": "153354438/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1629555300000,
      "targa": "EH863HZ",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "1192/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003512021110007",
      "trasgressore": "CHITORAGA VITALIE",
      "obbligato": "SIRBU VLADIMIR"
    }, {
      "highlights": {},
      "aggId": "6bc69435-c7a3-4ac2-8633-5cabf2e53009",
      "idVerbale": "153356837/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629589800000,
      "targa": "CV407SW",
      "luogoAccertamento": "VIA VIA ARGINE RESTARA ",
      "numeroRegistro": "1194/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027292021110036",
      "trasgressore": "GREGGIO MATTIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "77f9223a-d601-4dd6-9fac-c477c76e070a",
      "idVerbale": "153346233/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629578400000,
      "targa": "bm809mk",
      "luogoAccertamento": "VIA CAPONTE ",
      "numeroRegistro": "1193/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027302021110019",
      "trasgressore": "Beta Aron",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b19ef512-99dd-4e47-9000-eae6833f101c",
      "idVerbale": "153354330/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629553500000,
      "targa": "EP993TL",
      "luogoAccertamento": "VIA GIOVANNI XXIII ",
      "numeroRegistro": "1191/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003492021110041",
      "trasgressore": "SCARPARO RICCARDO",
      "obbligato": "CESAROTTO DARIO"
    }, {
      "highlights": {},
      "aggId": "1e68267c-8d74-4691-bd1d-159c3df6c62f",
      "idVerbale": "153354232/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629491721000,
      "targa": "ck680al",
      "luogoAccertamento": "via bosco crosara ",
      "numeroRegistro": "1186/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027322021110078",
      "trasgressore": "bellettato davide",
      "obbligato": "schivo ludovica"
    }, {
      "highlights": {},
      "aggId": "817a322c-3e13-4b74-b919-5e66d338c0b2",
      "idVerbale": "153356533/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1629460200000,
      "targa": "DD539VD",
      "luogoAccertamento": "VIA VIA PETRARCA ",
      "numeroRegistro": "1182/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027342021110044",
      "trasgressore": "ANTONIAZZI PIETRO",
      "obbligato": "ANTONIAZZI CHIARA"
    }, {
      "highlights": {},
      "aggId": "84adc6da-1234-4088-99d5-4e0a62736e98",
      "idVerbale": "153344832/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629513636000,
      "targa": "EM255KF",
      "luogoAccertamento": "VIA BANZE VIA BANZE (SP89) ",
      "numeroRegistro": "1188/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027312021110002",
      "trasgressore": "VETTORELLO FRANCESCO",
      "obbligato": "FACCO KETI"
    }, {
      "highlights": {},
      "aggId": "d55f91e3-5deb-4e5b-a606-805cc67e812d",
      "idVerbale": "153356631/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629472800000,
      "targa": "CY91971",
      "luogoAccertamento": "via via versori ",
      "numeroRegistro": "1183/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027332021110061",
      "trasgressore": "TINCANI EDOARDO",
      "obbligato": "ZAMPIERI IRENE"
    }, {
      "highlights": {},
      "aggId": "e4c51e71-52c0-44c3-adc0-5f02877a0358",
      "idVerbale": "153356436/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629454658000,
      "targa": "ev00825",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "1178/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022992021110092",
      "trasgressore": "masiero damiano",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "285261a2-62ca-4c07-bd1c-19c5cb07791b",
      "idVerbale": "153346331/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629699240000,
      "targa": "AV035YY",
      "luogoAccertamento": "VIA VIA DELL'INDUSTRIA ",
      "numeroRegistro": "1213/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MARCHETTO MARCELLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "eb7ffde3-715f-49fb-ae4c-74ca103bc7e2",
      "idVerbale": "153356230/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629404400000,
      "targa": "FL161CP",
      "luogoAccertamento": "VIA DESERTO ",
      "numeroRegistro": "1174/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027362021110010",
      "trasgressore": "MICIGLIANI FILIPPO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "95f7f2d9-ae59-4442-bb6c-00ffeb9887d6",
      "idVerbale": "153356338/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629410700000,
      "targa": "GD640ND",
      "luogoAccertamento": "VIA VIA MOLINI ",
      "numeroRegistro": "1175/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027352021110027",
      "trasgressore": "BENEDUSI MICHELE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "fb78ca72-8fbe-4efe-9eb4-99bc8b94e224",
      "idVerbale": "152711633/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629296400000,
      "targa": "CK125MM",
      "luogoAccertamento": "Via Palladio Via Palladio",
      "numeroRegistro": "1171/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006802021110087",
      "trasgressore": null,
      "obbligato": "CARPENTERIE ITALIANE E SERVIZI INDUSTRIALI S.r.l."
    }, {
      "highlights": {},
      "aggId": "b715ffd1-a4cb-4d9c-bc57-ab48918ff7f2",
      "idVerbale": "153341130/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629297349000,
      "targa": "cx812nh",
      "luogoAccertamento": "via badia via badia ",
      "numeroRegistro": "1165/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027372021110086",
      "trasgressore": "hu xiaofang",
      "obbligato": "hu chang xix"
    }, {
      "highlights": {},
      "aggId": "b7aab9dd-df09-49d4-8186-839b914ba6e5",
      "idVerbale": "153343637/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627571009000,
      "targa": "DF092GX",
      "luogoAccertamento": "VIA MEGGIARO VIA MEGGIARO ",
      "numeroRegistro": "1166/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003482021110058",
      "trasgressore": "BUGA MIHAIL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "86e2a03b-2d20-41f3-b82d-c9d35995d2c0",
      "idVerbale": "153341032/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629233113000,
      "targa": "eg241ll",
      "luogoAccertamento": "Via Nuova Via Nuova ",
      "numeroRegistro": "1150/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027682021110024",
      "trasgressore": "casella luca",
      "obbligato": "CASELLA FABIEN"
    }, {
      "highlights": {},
      "aggId": "04716b8f-731d-4327-a667-c4a7e491afb6",
      "idVerbale": "153354134/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629114900000,
      "targa": "AF499MH",
      "luogoAccertamento": "VIA CALDEVIGO VIA CALDEVIGO ",
      "numeroRegistro": "1140/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027712021110066",
      "trasgressore": "MARCHETTO SALVATORE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "aacf15fa-44f7-422c-ac03-6593dfe0d668",
      "idVerbale": "153344538/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628800079000,
      "targa": "et297yv",
      "luogoAccertamento": "Via San Pio Decimo Sant'elena (PD) Via San Pio Decimo SANT'ELENA (PD) VIA SAN PIO DECIMO",
      "numeroRegistro": "1126/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027762021110074",
      "trasgressore": "Tamiazzo Pierluigi",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "458e4e42-d2d8-4c74-a5f7-5d03e91fdc76",
      "idVerbale": "153344734/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629080171000,
      "targa": "FF956VP",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "1137/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027722021110049",
      "trasgressore": "BERTO RICCARDO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ba6fdb6d-6bd0-44f6-9941-d7e3488eb401",
      "idVerbale": "152840735/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628879400000,
      "targa": "em785da",
      "luogoAccertamento": "via villabozza ",
      "numeroRegistro": "1127/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004142021110052",
      "trasgressore": "MARTINO PILOTTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "160f80ca-870b-460e-8656-1f0201f4d5a4",
      "idVerbale": "153356132/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628961900000,
      "targa": "DY12XD",
      "luogoAccertamento": "S.R. S.R. 10 VAR ",
      "numeroRegistro": "1131/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027732021110032",
      "trasgressore": "SORRAJ REDJAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f225cb50-efd5-42f4-85ac-1b23f4d0e839",
      "idVerbale": "153385832/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629623220000,
      "targa": "BB453WZ",
      "luogoAccertamento": "VIA VIA ROSA ",
      "numeroRegistro": "1216/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014992021110021",
      "trasgressore": "JAMAD HAMID",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ce634cf6-8f8c-4235-9939-d9b920162460",
      "idVerbale": "153353830/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628686834000,
      "targa": "ER823EY",
      "luogoAccertamento": "via bosco alto ",
      "numeroRegistro": "1111/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003532021110066",
      "trasgressore": "MAMBRIN ALBERTO",
      "obbligato": "CH&F BERTOLINI SRL"
    }, {
      "highlights": {},
      "aggId": "e4dc410e-49f6-4c72-9b66-5c4e031bd3b6",
      "idVerbale": "152556132/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1628336400000,
      "targa": "ac216lv",
      "luogoAccertamento": "via roma via roma ",
      "numeroRegistro": "1070/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009922021110084",
      "trasgressore": "padure alexandru",
      "obbligato": "CEREDNIC ALIONA"
    }, {
      "highlights": {},
      "aggId": "dc097b61-388c-4b06-b875-c156787436e6",
      "idVerbale": "153355730/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1628598000000,
      "targa": "BX362PZ",
      "luogoAccertamento": "VIA VIA ROVERE ",
      "numeroRegistro": "1086/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022462021110063",
      "trasgressore": "SCIANNA FRANCESCO",
      "obbligato": "SCIANNA EMMA"
    }, {
      "highlights": {},
      "aggId": "b1f630e3-2841-4ad6-8352-fbf587ec509a",
      "idVerbale": "153355838/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1628598300000,
      "targa": "BX362PZ",
      "luogoAccertamento": "VIA VIA ROVERE ",
      "numeroRegistro": "1087/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022472021110046",
      "trasgressore": "SCIANNA FRANCESCO",
      "obbligato": "SCIANNA EMMA"
    }, {
      "highlights": {},
      "aggId": "a06d45b7-64f3-415a-b76b-da78fffa984d",
      "idVerbale": "152556230/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628424480000,
      "targa": "DD549VT",
      "luogoAccertamento": "VIA PROVINCIALE SP 47 VIA PROVINCIALE ",
      "numeroRegistro": "1071/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009912021110008",
      "trasgressore": "COCCO LUIGI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7d41f826-cc68-4467-9346-659f0a09e67b",
      "idVerbale": "153355632/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1628597700000,
      "targa": "BX362PZ",
      "luogoAccertamento": "VIA VIA ROVERE ",
      "numeroRegistro": "1085/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022452021110080",
      "trasgressore": "SCIANNA FRANCESCO",
      "obbligato": "SCIANNA EMMA"
    }, {
      "highlights": {},
      "aggId": "2596948b-d081-4996-8688-275bee478ba8",
      "idVerbale": "152991135/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1628443832000,
      "targa": "GC660BR",
      "luogoAccertamento": "11 via della Provvidenza ",
      "numeroRegistro": "1060/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004002021110011",
      "trasgressore": "MOROZ STANISLAV",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ba3e24da-922b-4334-8710-93da8df81704",
      "idVerbale": "153355534/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628541900000,
      "targa": "BJ598DX",
      "luogoAccertamento": "VIA NAZIONALE ",
      "numeroRegistro": "1082/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022432021110021",
      "trasgressore": "GREGORIN MORENO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c165cba9-7ba4-4124-9f33-0e048b1e8002",
      "idVerbale": "153356034/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628724900000,
      "targa": "DR559BG",
      "luogoAccertamento": "VIA VIA MOTTA ",
      "numeroRegistro": "1102/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022092021110041",
      "trasgressore": "CIMMINO ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "acc81446-e3ed-42c1-8153-680c5a0d9c48",
      "idVerbale": "153355231/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628310600000,
      "targa": "EL773FV",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1043/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000702021110041",
      "trasgressore": "RENNA ROSARIO",
      "obbligato": "CARUSO CONCETTA"
    }, {
      "highlights": {},
      "aggId": "757b0445-0420-4b7f-b63d-773ad0882203",
      "idVerbale": "153355339/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1628311500000,
      "targa": "EL773FV",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1044/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000572021110076",
      "trasgressore": "RENNA ROSARIO",
      "obbligato": "CARUSO CONCETTA"
    }, {
      "highlights": {},
      "aggId": "e074c1b8-fb79-402e-a598-9bae9a251cdf",
      "idVerbale": "152638633/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627641900000,
      "targa": "cl124ac",
      "luogoAccertamento": "VIA HERMAGOR ",
      "numeroRegistro": "980/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005152021110009",
      "trasgressore": "HUDOROVIC Haron",
      "obbligato": "TRUZZI Venusia"
    }, {
      "highlights": {},
      "aggId": "b2c76ce1-ec9f-46e7-86f2-000af4c94a7a",
      "idVerbale": "152638535/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627641900000,
      "targa": "cl124ac",
      "luogoAccertamento": "VIA HERMAGOR ",
      "numeroRegistro": "981/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005162021110085",
      "trasgressore": "HUDOROVIC Haron",
      "obbligato": "TRUZZI Venusia"
    }, {
      "highlights": {},
      "aggId": "b3595745-607e-4852-adba-f1a69000ff6f",
      "idVerbale": "152638438/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627641900000,
      "targa": "cl124ac",
      "luogoAccertamento": "VIA HERMAGOR ",
      "numeroRegistro": "982/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005172021110068",
      "trasgressore": "HUDOROVIC Haron",
      "obbligato": "TRUZZI Venusia"
    }, {
      "highlights": {},
      "aggId": "c98f769a-5ff7-45e0-ba23-14902f639c7f",
      "idVerbale": "152638731/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627907100000,
      "targa": "cl124ac",
      "luogoAccertamento": "VIA HERMAGOR ",
      "numeroRegistro": "1004/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005142021110026",
      "trasgressore": "TRUZZI Venusia",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8c51ef07-6ab4-4e3f-9a8e-1ed837f1aa16",
      "idVerbale": "153353439/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1627573200000,
      "targa": "CM144DC",
      "luogoAccertamento": "via Matteotti ",
      "numeroRegistro": "1041/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003552021110032",
      "trasgressore": null,
      "obbligato": "STURARO LAURO"
    }, {
      "highlights": {},
      "aggId": "24c38330-1e69-460d-aa4e-c89db67b4a0b",
      "idVerbale": "153337332/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1628101200000,
      "targa": "CP08ODA",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1025/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002082021110020",
      "trasgressore": "BOBU IONUT",
      "obbligato": "CALDARARU LAURENTIU"
    }, {
      "highlights": {},
      "aggId": "14c3f76e-2e87-4482-81cb-3484002d5d30",
      "idVerbale": "152990635/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622898900000,
      "targa": "DK19367",
      "luogoAccertamento": "COMUNALE COMUNALE ",
      "numeroRegistro": "389/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006003912021110071",
      "trasgressore": "CIUPERCA VLADISLAV",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "75548f2a-dead-4164-b4aa-44ed55718364",
      "idVerbale": "153333434/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624976580000,
      "targa": "ct489rf",
      "luogoAccertamento": "via via vallancon nord ",
      "numeroRegistro": "660/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016332021110068",
      "trasgressore": "zanconato dino",
      "obbligato": "mazziero lorenzo"
    }, {
      "highlights": {},
      "aggId": "0c577dc9-05ff-4f24-b1f0-016eecc6081a",
      "idVerbale": "152677332/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1628946000000,
      "targa": "BH326Z",
      "luogoAccertamento": "Piazza IV Novrembre Piazza IV Novembre ",
      "numeroRegistro": "1217/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "SGAMBARO Mario",
      "obbligato": "SOC. AGR. SGAMBARO SS"
    }, {
      "highlights": {},
      "aggId": "870b9517-f78b-4550-a237-f2c65f7c3a38",
      "idVerbale": "152677430/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1628946600000,
      "targa": "BH326Z",
      "luogoAccertamento": "Piazza IV Novrembre Piazza IV Novembre ",
      "numeroRegistro": "1218/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "SGAMBARO Mario",
      "obbligato": "SOC. AGR. SGAMBARO SS"
    }, {
      "highlights": {},
      "aggId": "ed5e23b6-3fa5-4560-94c0-a62e83b548c1",
      "idVerbale": "152731032/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629699919000,
      "targa": "GL13BWF",
      "luogoAccertamento": "VIA ALCIDE DE GASPERI CURTAROLO VIA ALCIDE DE GASPERI ",
      "numeroRegistro": "1220/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006612021110038",
      "trasgressore": "ROMASCU VASILE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2ab8e9ed-2815-42b7-8579-9f0c30b23d31",
      "idVerbale": "152610537/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629762300000,
      "targa": "FG782TJ",
      "luogoAccertamento": "VIA VIA ROMA DI MASSANZAGO ",
      "numeroRegistro": "1221/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007902021110077",
      "trasgressore": "TERRIN ANDREA",
      "obbligato": "BROCCO MATTIA"
    }, {
      "highlights": {},
      "aggId": "bb55f632-5b74-48fe-9ba8-97e21c16d4e6",
      "idVerbale": "152610635/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629762300000,
      "targa": "FG782TJ",
      "luogoAccertamento": "VIA VIA ROMA DI MASSANZAGO ",
      "numeroRegistro": "1226/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "TERRIN ANDREA",
      "obbligato": "BROCCO MATTIA"
    }, {
      "highlights": {},
      "aggId": "5860d7cc-9694-4273-8f2a-f46a8e3341ca",
      "idVerbale": "152731238/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629762504000,
      "targa": "DX585AT",
      "luogoAccertamento": "VIA DALMAZIA VIA DALMAZIA CURTAROLO ",
      "numeroRegistro": "1223/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152731238",
      "trasgressore": "GRIGGIO VITTORIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "accb412f-217d-4f85-b619-941ab310c9aa",
      "idVerbale": "152731130/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629762511288,
      "targa": "DX585AT",
      "luogoAccertamento": "VIA DALMAZIA VIA DALMAZIA CURTAROLO ",
      "numeroRegistro": "1222/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152731130",
      "trasgressore": "GRIGGIO VITTORIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ad234a9d-c667-4130-a793-7e3cba7e5285",
      "idVerbale": "152731336/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629762480000,
      "targa": "DX585AT",
      "luogoAccertamento": "VIA DALMAZIA VIA DALMAZIA CURTAROLO ",
      "numeroRegistro": "1224/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152731336",
      "trasgressore": "GRIGGIO VITTORIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cf15a564-ee40-4983-bde9-1c5f76537a52",
      "idVerbale": "152610430/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1627126500000,
      "targa": "CN921NB",
      "luogoAccertamento": "VIA VIA ROMA DI MASSANZAGO ",
      "numeroRegistro": "935/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007462021110081",
      "trasgressore": "PAQARIZI ARTAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "04c35d53-3f18-4b1a-8dd2-7a6f725a084f",
      "idVerbale": "152731434/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629762480000,
      "targa": "DX585AT",
      "luogoAccertamento": "VIA DALMAZIA VIA DALMAZIA CURTAROLO ",
      "numeroRegistro": "1225/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152731434",
      "trasgressore": "GRIGGIO VITTORIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e56da873-360a-4042-875b-2124cb0ccb9d",
      "idVerbale": "153341238/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629792620000,
      "targa": "bk602nx",
      "luogoAccertamento": "VIA ALBERE VIA ALBERE ",
      "numeroRegistro": "1231/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "PANFILO CRISTIAN",
      "obbligato": "SOCIETA' ESTENSE SERVIZI AMBIENTALI SPA"
    }, {
      "highlights": {},
      "aggId": "26c59cf7-85c6-47ec-97aa-0a8ac11c2731",
      "idVerbale": "152594038/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1629742500000,
      "targa": "BV967AE",
      "luogoAccertamento": "VIA DEI BUSI ",
      "numeroRegistro": "1232/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "BALASO MICHELA",
      "obbligato": "FABBIAN YVONE"
    }, {
      "highlights": {},
      "aggId": "20fd7464-dd99-476b-89f4-60e0382e78c6",
      "idVerbale": "153356935/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629819000000,
      "targa": null,
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1233/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "FERRARI EMANUELA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b4b73221-fb1e-46e9-988a-210236555271",
      "idVerbale": "152594136/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629855300000,
      "targa": "DZ553RG",
      "luogoAccertamento": "STRADA REGIONALE 307 STRADA REGIONALE 307 KM 20,200 ",
      "numeroRegistro": "1236/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MICHIELETTO SIMONE",
      "obbligato": "DE ROSA ANTONELLA"
    }, {
      "highlights": {},
      "aggId": "654bc52a-6d57-4172-be9b-450664a1f195",
      "idVerbale": "153341336/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629852352000,
      "targa": "DZ939AL",
      "luogoAccertamento": "VIA CALDEVIGO VIA CALDEVIGO ",
      "numeroRegistro": "1238/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STOJKO KRISTINA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a4bfc2b3-629f-4462-888d-b840fbf995c3",
      "idVerbale": "153341434/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629859832000,
      "targa": "X7MY4Z",
      "luogoAccertamento": "VIA CALDEVIGO VIA CALDEVIGO ",
      "numeroRegistro": "1239/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "BONATO STEFANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1ae656f7-8cff-4f8b-90d3-72adf12f49a1",
      "idVerbale": "153357033/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629892500000,
      "targa": "FN983BH",
      "luogoAccertamento": "SR 10 VAR PADANA INFERIORE ",
      "numeroRegistro": "1244/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CHIRIL GHEORGHE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "98b0d9fb-f6c7-4a9c-a029-a4e30c51e615",
      "idVerbale": "153354535/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629924900000,
      "targa": "FF097DG",
      "luogoAccertamento": "VIA 24 MAGGIO ",
      "numeroRegistro": "1246/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "RIZZATO LORENZO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c618b7f5-0689-4c6c-ad04-1a121c7e1537",
      "idVerbale": "152840833/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629909900000,
      "targa": "dd348nl",
      "luogoAccertamento": "via  roma ",
      "numeroRegistro": "1250/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004152021110035",
      "trasgressore": "tatiana streltov",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a67224e8-fe57-475b-a8b5-f49d2755c74f",
      "idVerbale": "152731639/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629917100000,
      "targa": "bv666dn",
      "luogoAccertamento": "VIA VALSUGANA VIA VALSUGANA ",
      "numeroRegistro": "1251/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006652021110063",
      "trasgressore": "PIUBELLO DAVIDE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "582cd260-09a4-4e15-8082-aa67cb80d92c",
      "idVerbale": "153354633/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629981639000,
      "targa": "FH189GA",
      "luogoAccertamento": "VIA MAGGIORE ",
      "numeroRegistro": "1258/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GOMAN KEVIN",
      "obbligato": "BOGDAN ALIDA PERLA"
    }, {
      "highlights": {},
      "aggId": "1ffdc727-b7b1-4551-aa96-45f1efde0199",
      "idVerbale": "153354731/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629982207000,
      "targa": "FH189GA",
      "luogoAccertamento": "VIA MAGGIORE ",
      "numeroRegistro": "1259/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GOMAN KEVIN",
      "obbligato": "GOMAN SHANA"
    }, {
      "highlights": {},
      "aggId": "4cc8777f-35f8-4988-a338-07f460cd6c62",
      "idVerbale": "153354839/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1629992155000,
      "targa": "ET255YF",
      "luogoAccertamento": "VIA ROMA VIA ROMA ",
      "numeroRegistro": "1257/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "ZANELLA FRANCESCO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ce5a8686-48a0-4ed4-9013-122da5484ca5",
      "idVerbale": "153357131/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1630018500000,
      "targa": "FS099DB",
      "luogoAccertamento": "VIA VIA PAPA GIOVANNI XXIII ",
      "numeroRegistro": "1265/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "PEDRAZZOLI DAVIDE",
      "obbligato": "WUERT S.R.L."
    }, {
      "highlights": {},
      "aggId": "20de7c75-368a-4492-9294-c8d4f5ef7ce0",
      "idVerbale": "153357239/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630033800000,
      "targa": "FR934HK",
      "luogoAccertamento": "VIA PRINCIPE UMBERTO ",
      "numeroRegistro": "1266/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CECCON RITA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9b98dce8-ea48-478f-90b0-1463fe77ca78",
      "idVerbale": "152731737/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630055700000,
      "targa": "DP112DN",
      "luogoAccertamento": "VIA RISARETTA VIA RISARETTA ",
      "numeroRegistro": "1270/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006642021110080",
      "trasgressore": "MARCHETTI BERTILLA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "659292c4-64e5-4cfa-a373-78844666ecfd",
      "idVerbale": "152506437/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630075200000,
      "targa": "DM666BG",
      "luogoAccertamento": "Via Guizze Via Guizze ",
      "numeroRegistro": "1275/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "PESCE SEBASTIANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4ef9208c-0623-4c7e-853c-b7127f6ea1a2",
      "idVerbale": "153405339/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630248900000,
      "targa": "CS382HM",
      "luogoAccertamento": "VIA SANTA CROCE ",
      "numeroRegistro": "1284/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002482021110084",
      "trasgressore": "GLUKHOVATA INNA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ec3cfe7b-ed3b-4fd7-b190-fb38625da2cc",
      "idVerbale": "152617834/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630147500000,
      "targa": "FR409hp",
      "luogoAccertamento": "Via b.go padova ",
      "numeroRegistro": "1278/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005262021110008",
      "trasgressore": "Pastò Elena",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5fa15887-5dc5-457b-a42a-b692cd3a3884",
      "idVerbale": "152548838/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626946200000,
      "targa": "CC014FW",
      "luogoAccertamento": "via Ampezzon  ",
      "numeroRegistro": "1289/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009852021110017",
      "trasgressore": "VLASIE VALENTIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e639ca78-774f-4363-be73-2d217f4e09e8",
      "idVerbale": "152548936/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1626946200000,
      "targa": "CC014FW",
      "luogoAccertamento": "via Ampezzon  ",
      "numeroRegistro": "1290/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008052021110008",
      "trasgressore": "VLASIE VALENTIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b97b5cc7-cd1d-48ea-a837-2c03a23537cf",
      "idVerbale": "153346634/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630278600000,
      "targa": "DX551ZB",
      "luogoAccertamento": "SR 10 VAR PADANA INFERIORE ",
      "numeroRegistro": "1294/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027852021110014",
      "trasgressore": "DE BATTISTI MATTIA",
      "obbligato": "BRIANZA LAURA"
    }, {
      "highlights": {},
      "aggId": "0b833c1f-c486-448a-b068-78a68d0902dd",
      "idVerbale": "153346732/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630287000000,
      "targa": "FP396ZF",
      "luogoAccertamento": "VIA ALTIERO ",
      "numeroRegistro": "1296/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027832021110048",
      "trasgressore": "DORDA KLINSI",
      "obbligato": "DORDA FIQIRI"
    }, {
      "highlights": {},
      "aggId": "a22acdfb-ca23-4932-a2dd-e0dba52263a7",
      "idVerbale": "153346830/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630287600000,
      "targa": "fp386zf",
      "luogoAccertamento": "VIA ALTIERO ",
      "numeroRegistro": "1295/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027842021110031",
      "trasgressore": "DORDA KLINSI",
      "obbligato": "DORDA FIQIRI"
    }, {
      "highlights": {},
      "aggId": "dc46ba20-55e1-4228-8bd9-1d288d74f468",
      "idVerbale": "152991331/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630107608000,
      "targa": "ak329nj",
      "luogoAccertamento": "PIAZZA SALVO D'ACQUISTO 1 PIAZZA SALVO D'ACQUISTO 1 ",
      "numeroRegistro": "1297/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Leandro Davide",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cfd84ac7-12d2-43fe-b21d-b8e1e29e0673",
      "idVerbale": "152991439/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630108850000,
      "targa": "ak329nj",
      "luogoAccertamento": "PIAZZA SALVO D'ACQUISTO 1 PIAZZA SALVO D'ACQUISTO 1 ",
      "numeroRegistro": "1299/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Leandro Davide",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ff7e6798-32fe-4938-bad7-f14d64008599",
      "idVerbale": "152677537/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1629035700000,
      "targa": "DF311GX",
      "luogoAccertamento": "Piazza IV Novrembre Piazza IV Novembre ",
      "numeroRegistro": "1302/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": null,
      "obbligato": "ACHEAMPONG GERTRUDE GYAAMAH"
    }, {
      "highlights": {},
      "aggId": "cc79bfa3-aee0-4e7f-a5d8-46d808b7bb89",
      "idVerbale": "153354937/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1630411559000,
      "targa": "ED168ZZ",
      "luogoAccertamento": "VIA MAGGIORE ",
      "numeroRegistro": "1303/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "EDERLE DAVIDE",
      "obbligato": "MARCHIORO ANTONIO"
    }, {
      "highlights": {},
      "aggId": "2bef18b6-82a7-4176-aa19-a2cd51a9a65a",
      "idVerbale": "153355035/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630479905000,
      "targa": "ES491YS",
      "luogoAccertamento": "SR 10 VIA CHIESA MOTTA ",
      "numeroRegistro": "1307/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "BATTISTELLA DIEGO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e91595b1-f5c4-4e45-8375-092c00078b0e",
      "idVerbale": "153357630/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1630540227000,
      "targa": "ez826yz",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "1313/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "NIZZETTO DARIO",
      "obbligato": "SATTIN LORIS"
    }, {
      "highlights": {},
      "aggId": "f4ab729c-287a-4bd0-8bea-9d8a5324049b",
      "idVerbale": "153357738/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1630541152000,
      "targa": "ez826yz",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "1314/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "NIZZETTO DARIO",
      "obbligato": "SATTIN LORIS"
    }, {
      "highlights": {},
      "aggId": "b883415a-bdde-4d8b-9b26-3f5348f35f3d",
      "idVerbale": "152556631/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630534200000,
      "targa": "ew077hg",
      "luogoAccertamento": "via Kennedy 17 via kennedy 17 ",
      "numeroRegistro": "1315/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CHIHI Mohamed Amir",
      "obbligato": "GISLON GIADA"
    }, {
      "highlights": {},
      "aggId": "a77e1359-877a-47e9-897c-ff792073ea4d",
      "idVerbale": "152877036/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629507000000,
      "targa": "BR778VZ",
      "luogoAccertamento": "Via Roma Via roma ",
      "numeroRegistro": "1317/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006030302021110034",
      "trasgressore": "KIRILL GIOVANNI GESUATO ",
      "obbligato": "IRENE LAZZARIN"
    }, {
      "highlights": {},
      "aggId": "200fa450-fe81-44e1-8c82-a64ab5140e28",
      "idVerbale": "246515436/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1630483800000,
      "targa": "fj973ze",
      "luogoAccertamento": "VIA VIA FRANCESCO RISMONDO ",
      "numeroRegistro": "1321/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Della Schiva Lorenzo",
      "obbligato": "VRENT SRL"
    }, {
      "highlights": {},
      "aggId": "b2a36a45-caa7-4427-8606-40daabb9517c",
      "idVerbale": "246515132/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630415400000,
      "targa": "ec06741",
      "luogoAccertamento": "Via Tommaseo Via Tommaseo ",
      "numeroRegistro": "1318/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Armani Giulia",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2e1ad802-fd69-4069-87d3-037c917bd14d",
      "idVerbale": "246515338/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630482900000,
      "targa": "fj973ze",
      "luogoAccertamento": "VIA VIA FRANCESCO RISMONDO ",
      "numeroRegistro": "1322/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006031332021110050",
      "trasgressore": "Della Schiva Lorenzo",
      "obbligato": "VRENT SRL"
    }, {
      "highlights": {},
      "aggId": "560a59fa-ed58-44f4-a7ee-fcd6bb995dd5",
      "idVerbale": "246515230/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630477800000,
      "targa": "db897ae",
      "luogoAccertamento": "via milazzo via milazzo ",
      "numeroRegistro": "1324/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019842021110053",
      "trasgressore": "Parpajola Lorenzo",
      "obbligato": "PARPAJOLA BRUNO"
    }, {
      "highlights": {},
      "aggId": "c8b4d29e-45bb-44c2-9d2e-5e59ca88ea27",
      "idVerbale": "153345038/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630591200000,
      "targa": "CG768EY",
      "luogoAccertamento": "VIA gorgo ",
      "numeroRegistro": "1325/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027812021110082",
      "trasgressore": "STOIKO KATIUSCIA",
      "obbligato": "STOIKO GENNA"
    }, {
      "highlights": {},
      "aggId": "2deb6333-899b-4c49-9273-3203a5ab986c",
      "idVerbale": "153383433/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1619526900000,
      "targa": "DM932DB",
      "luogoAccertamento": "via brancaglia ",
      "numeroRegistro": "32/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012702021110008",
      "trasgressore": "ARONNE VINCENZO",
      "obbligato": "AFFRESCO SRL"
    }, {
      "highlights": {},
      "aggId": "f6a71f1b-83e5-4894-8705-300d562778f9",
      "idVerbale": "152617736/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629825000000,
      "targa": "PD118092",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "1234/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005272021110084",
      "trasgressore": "hysa roland",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ee58d0a4-c839-4d43-aff9-9d745430b0b3",
      "idVerbale": "153439838/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630595127000,
      "targa": "EF177DF",
      "luogoAccertamento": "Via Europa ",
      "numeroRegistro": "1328/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008152021110024",
      "trasgressore": "ENRICO BORDON",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "69bc8cb9-0066-45d3-83fa-d8d60a4c2b8b",
      "idVerbale": "153344930/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630590420000,
      "targa": "CG768EY",
      "luogoAccertamento": "VIA gorgo ",
      "numeroRegistro": "1326/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006027822021110065",
      "trasgressore": "STOIKO KATIUSCIA",
      "obbligato": "STOIKO GENNA"
    }, {
      "highlights": {},
      "aggId": "8a6c8198-b287-41db-8fdd-c7a845c2e0e7",
      "idVerbale": "153338634/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630606680000,
      "targa": "dy916le",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "1333/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006026552021110085",
      "trasgressore": "ASAD MUHAMMAD",
      "obbligato": "HUMYOUN NAILA"
    }, {
      "highlights": {},
      "aggId": "19097eb9-ea9f-4c52-b978-e9833ba3c2c7",
      "idVerbale": "153338732/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630671300000,
      "targa": "EG942LP",
      "luogoAccertamento": "VIA BANZE ",
      "numeroRegistro": "1336/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006026562021110068",
      "trasgressore": "VERGANESI ELISA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c6746686-5609-4d9e-a83f-03d94b41f7bd",
      "idVerbale": "152840931/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630672200000,
      "targa": "CY487GR",
      "luogoAccertamento": "VIA VIA VITTORIO VENETO ",
      "numeroRegistro": "1334/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "ANDONI IVAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b3aba8e0-1aeb-4b40-a02c-3fbc3f9606d8",
      "idVerbale": "152731835/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630670700000,
      "targa": "EY890JL",
      "luogoAccertamento": "COMANDO STAZIONE CARABINIERI VIA DEI CONTARINI 21 COMANDO STAZIONE CARABINIERI ",
      "numeroRegistro": "1332/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152731835",
      "trasgressore": "MORBIATO MARCO",
      "obbligato": "GALLIO MICHELA"
    }, {
      "highlights": {},
      "aggId": "a837fa5f-50d7-4854-ae56-4689cfad7c60",
      "idVerbale": "153357337/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630703700000,
      "targa": "DC103ZM",
      "luogoAccertamento": "VIA CALDEVIGO VIA CALDEVIGO ",
      "numeroRegistro": "1337/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "KASEMI ERION",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d621b633-bc2c-4d61-8577-725312511651",
      "idVerbale": "153338830/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630680120000,
      "targa": "ES994BV",
      "luogoAccertamento": "VIA ARZERE ",
      "numeroRegistro": "1335/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006026572021110051",
      "trasgressore": "DRAGO IVANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9fff9226-a87d-4af0-9d03-01f97e6d29a9",
      "idVerbale": "153358032/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1630717521000,
      "targa": "AFM105",
      "luogoAccertamento": "PIAZZA MAGGIORE PIAZZA MAGGIORE ",
      "numeroRegistro": "1342/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "KASRAOUI OMAR",
      "obbligato": "BOUGHANMI MONJI"
    }, {
      "highlights": {},
      "aggId": "bb8def88-53ca-490b-b72e-cbe454c75d00",
      "idVerbale": "153357934/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1630716641000,
      "targa": "AFM105",
      "luogoAccertamento": "PIAZZA MAGGIORE PIAZZA MAGGIORE ",
      "numeroRegistro": "1341/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "KASRAOUI OMAR",
      "obbligato": "BOUGHANMI MONJI"
    }, {
      "highlights": {},
      "aggId": "b5c9bf38-bc25-440e-b34c-a06c997a750f",
      "idVerbale": "152617932/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630663200000,
      "targa": "FF933WB",
      "luogoAccertamento": "via Borgo Trento Trieste ",
      "numeroRegistro": "1338/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006031792021110012",
      "trasgressore": "ARCANGELI tania",
      "obbligato": "I.F.B. STRODER"
    }, {
      "highlights": {},
      "aggId": "7dd55864-2895-4dbc-8c45-a2a85dd34a46",
      "idVerbale": "153358130/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1630718492000,
      "targa": "AFM105",
      "luogoAccertamento": "PIAZZA MAGGIORE PIAZZA MAGGIORE ",
      "numeroRegistro": "1340/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "KASRAOUI OMAR",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f824a579-9742-488c-9bf9-984cac9ab6e5",
      "idVerbale": "153357836/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630712718000,
      "targa": "DF454GV",
      "luogoAccertamento": "VIA BORGO ENIANO BORGO ENIANO ",
      "numeroRegistro": "1339/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GARBO NICOLO'",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "bccc5910-a9b9-495c-acc7-f3a25790e399",
      "idVerbale": "152841039/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630749720000,
      "targa": "dy009dt",
      "luogoAccertamento": "via via 1° Maggio ",
      "numeroRegistro": "1345/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "boschini veronica",
      "obbligato": "DALLA VIA fiorella"
    }, {
      "highlights": {},
      "aggId": "d5561418-a656-4353-a9d4-f513d0d8f385",
      "idVerbale": "152506534/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630626900000,
      "targa": "ea172yf",
      "luogoAccertamento": "via Montale via montale ",
      "numeroRegistro": "1344/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "Zuccherato Andrea",
      "obbligato": "Mella Paola"
    }, {
      "highlights": {},
      "aggId": "40580ccb-269a-48c4-9e3d-cdb0eb2f4bc8",
      "idVerbale": "152732031/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630756500000,
      "targa": "CN934CS",
      "luogoAccertamento": "via dei contarini via dei contarini ",
      "numeroRegistro": "1346/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152732031",
      "trasgressore": "MARAZZATO STEFANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ae9a1877-95a3-4bb3-b648-cc209334140b",
      "idVerbale": "153358238/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630775727000,
      "targa": "BZ759XJ",
      "luogoAccertamento": "VIA RONDEI ",
      "numeroRegistro": "1349/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "ES SABBAR RAHHAL",
      "obbligato": "REGHAY ABDELKADER"
    }, {
      "highlights": {},
      "aggId": "d5ad823c-5b6d-42bb-8b9f-c89c36f9d033",
      "idVerbale": "152867332/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630624140000,
      "targa": "cm307cf",
      "luogoAccertamento": "via cavour via cavour ",
      "numeroRegistro": "1353/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010272021110047",
      "trasgressore": null,
      "obbligato": "Basso Stefano"
    }, {
      "highlights": {},
      "aggId": "2acfa36c-067a-4251-9f0b-6ebb0f73d024",
      "idVerbale": "152867537/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630624140000,
      "targa": "cm307cf",
      "luogoAccertamento": "via cavour via cavour ",
      "numeroRegistro": "1354/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006029162021110019",
      "trasgressore": null,
      "obbligato": "basso stefano"
    }, {
      "highlights": {},
      "aggId": "c977ffc2-ed64-4581-922d-23bd02fd187b",
      "idVerbale": "152731933/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630742700000,
      "targa": "FY138EV",
      "luogoAccertamento": "via dei contarini via dei contarini ",
      "numeroRegistro": "1347/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152731933",
      "trasgressore": "DISARO' ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "966f22e0-805a-45b9-8353-006d2e0e151d",
      "idVerbale": "153358639/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630863733000,
      "targa": "BZ759XJ",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1360/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "hamdoun yassine",
      "obbligato": "reghay abdelkader"
    }, {
      "highlights": {},
      "aggId": "107a4d1d-19e6-4d7f-822d-041358a920f5",
      "idVerbale": "152867038/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1629934560000,
      "targa": "ae28845",
      "luogoAccertamento": "via  Marconi ",
      "numeroRegistro": "1249/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010282021110030",
      "trasgressore": "shima shpetim",
      "obbligato": "Tagi Trasporti Srl"
    }, {
      "highlights": {},
      "aggId": "b58ceac4-c1c1-4e9d-88d8-0ccda9b27d1f",
      "idVerbale": "152867430/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630624140000,
      "targa": "cm307cf",
      "luogoAccertamento": "via cavour via cavour ",
      "numeroRegistro": "1355/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006029152021110036",
      "trasgressore": null,
      "obbligato": "BASSO STEFANO"
    }, {
      "highlights": {},
      "aggId": "e0104328-8e0a-45e9-b9be-2328ada742be",
      "idVerbale": "153357435/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630886400000,
      "targa": "BM989RX",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1358/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "NARDI STEFANO",
      "obbligato": "GALEAZZO ROBERTO"
    }, {
      "highlights": {},
      "aggId": "323a6712-f1c4-44e2-97e9-38a290359712",
      "idVerbale": "153357532/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630887000000,
      "targa": "BM989RX",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1359/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "NARDI STEFANO",
      "obbligato": "GALEAZZO ROBERTO"
    }, {
      "highlights": {},
      "aggId": "69032279-22f3-4f59-ae12-e8a991f2cc6a",
      "idVerbale": "153358737/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630864963000,
      "targa": "BZ759XJ",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1361/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "reghay abdelkader",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "538effa1-832e-45b8-b2fa-cfb5f70bef38",
      "idVerbale": "152564533/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626453000000,
      "targa": "ch631yn",
      "luogoAccertamento": "via NOVA via nova ",
      "numeroRegistro": "848/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009482021110088",
      "trasgressore": null,
      "obbligato": "GEMIN Tiziano"
    }, {
      "highlights": {},
      "aggId": "2c08cf24-3075-4d7d-ace4-08bf9b5d9b77",
      "idVerbale": "152610234/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625918580000,
      "targa": "GE486KK",
      "luogoAccertamento": "via zingarelle via zingarelle ",
      "numeroRegistro": "772/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007482021110047",
      "trasgressore": "COLETTO LUCIANO",
      "obbligato": "FCA BANK"
    }, {
      "highlights": {},
      "aggId": "343cdddb-ec2d-46ef-860f-db9d43c3c2da",
      "idVerbale": "152564338/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625592900000,
      "targa": "ev111rw",
      "luogoAccertamento": "via sommavilla via sommavilla ",
      "numeroRegistro": "730/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009502021110054",
      "trasgressore": "caccaro fabio",
      "obbligato": "basso emanuela"
    }, {
      "highlights": {},
      "aggId": "de52ba78-f92e-4e6c-ada5-4ec2101ec6ee",
      "idVerbale": "153325032/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626782700000,
      "targa": "ed085dc",
      "luogoAccertamento": "via San fermo ",
      "numeroRegistro": "891/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002342021110043",
      "trasgressore": "BAUCE LAURA",
      "obbligato": "BARISON LUCA"
    }, {
      "highlights": {},
      "aggId": "ec7d1a2d-dc9b-4fe6-8c18-c6b0f7b27e88",
      "idVerbale": "195315534/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1627114800000,
      "targa": "dw113pl",
      "luogoAccertamento": "VIA ROMA VIA ROMA ",
      "numeroRegistro": "931/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016472021110016",
      "trasgressore": "zannini mirko",
      "obbligato": "forcato gioacchino"
    }, {
      "highlights": {},
      "aggId": "d2f9eb32-6d88-4a29-a7de-50bbd196b4a6",
      "idVerbale": "153343138/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627056900000,
      "targa": "fk201jd",
      "luogoAccertamento": "VIA STAZIE BRAGADINE VIA STAZIE BRAGADINE ",
      "numeroRegistro": "915/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002012021110046",
      "trasgressore": "MONTIN MASSIMILIANO",
      "obbligato": "SCARANELLO SIMONETTA"
    }, {
      "highlights": {},
      "aggId": "ee4f0b8c-077a-4497-805a-6da29e29dcdf",
      "idVerbale": "153330339/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621219800000,
      "targa": "fk318pr",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "1049/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014662021110024",
      "trasgressore": "su yifan",
      "obbligato": "wang shinan"
    }, {
      "highlights": {},
      "aggId": "89bd200e-546b-4494-99ff-918c920eda68",
      "idVerbale": "152802134/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627020300000,
      "targa": "af12344",
      "luogoAccertamento": "VIA VENEZIA VIA VENEZIA ",
      "numeroRegistro": "920/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019622021110055",
      "trasgressore": "Lorigiola Marco",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5bb425e4-3a6a-4ffc-a424-320a0eeb0e14",
      "idVerbale": "153358531/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630777228000,
      "targa": "BZ759XJ",
      "luogoAccertamento": "VIA RONDEI ",
      "numeroRegistro": "1352/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "HAMDOUN YASSINE",
      "obbligato": "reghay abdelkader"
    }, {
      "highlights": {},
      "aggId": "aefe5c65-bcca-409d-84aa-07f512dc1447",
      "idVerbale": "152556338/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1628690400000,
      "targa": "cf277yk",
      "luogoAccertamento": "via Pozzetto Piombino Dese via pozzetto ",
      "numeroRegistro": "1098/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007112021110025",
      "trasgressore": "marin elena",
      "obbligato": "riondato germano"
    }, {
      "highlights": {},
      "aggId": "3575a2e8-af2b-48ef-8592-1881009ffb7f",
      "idVerbale": "153355133/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1628295600000,
      "targa": "BZ383YC",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "1042/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002132021110028",
      "trasgressore": "BOTTARO RICCARDO",
      "obbligato": "MASIERO ELIO"
    }, {
      "highlights": {},
      "aggId": "1e4eab91-aae5-49d8-9999-368ba0fadabf",
      "idVerbale": "153358835/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630866620000,
      "targa": "BZ759XJ",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1362/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "hamdoun yassine",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "aa01b548-8da4-4c29-99a9-2fd77bd343ee",
      "idVerbale": "246515837/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1630846800000,
      "targa": "DE109ND",
      "luogoAccertamento": "VIA VIA FRANCESCO RISMONDO ",
      "numeroRegistro": "1364/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "MUNTEANU VALENTIN",
      "obbligato": "PALADE TUDOREL"
    }, {
      "highlights": {},
      "aggId": "befb7485-7b8f-42af-9e19-4c888faa884e",
      "idVerbale": "153358434/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630776629000,
      "targa": "BZ759XJ",
      "luogoAccertamento": "VIA RONDEI ",
      "numeroRegistro": "1351/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "HAMDOUN YASSINE",
      "obbligato": "REGHAY ABDELKADER"
    }, {
      "highlights": {},
      "aggId": "6910d418-deda-4650-b590-850fc951d365",
      "idVerbale": "153358336/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630776046000,
      "targa": "BZ759XJ",
      "luogoAccertamento": "VIA RONDEI ",
      "numeroRegistro": "1350/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "HAMDOUN YASSINE",
      "obbligato": "REGHAY ABDELKADER"
    }, {
      "highlights": {},
      "aggId": "e52f5ade-1503-4d57-a844-2e3acaa10483",
      "idVerbale": "195171537/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1630915991763,
      "targa": "X7WDXC",
      "luogoAccertamento": "VIA PERNUMIA ",
      "numeroRegistro": "1366/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "EL MOTTAKI MOHAMED",
      "obbligato": "EL MOTTAKI MOUSSA"
    }, {
      "highlights": {},
      "aggId": "3ec5bfe0-529c-4b51-90d7-16da1b79d500",
      "idVerbale": "246515739/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630793100000,
      "targa": "GC135BL",
      "luogoAccertamento": "Via Sorio Via Sorio ",
      "numeroRegistro": "1367/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006031302021110008",
      "trasgressore": "martinello matteo",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0df0ea1d-ebe1-4265-8346-73eca30199c0",
      "idVerbale": "246515533/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630779300000,
      "targa": "ae109wj",
      "luogoAccertamento": "VIA CHIESANUOVA VIA CHIESANUOVA ",
      "numeroRegistro": "1363/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006031342021110033",
      "trasgressore": "giorgi giacomo",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4841ec94-30a3-4178-819d-f8a4c8f8ecd5",
      "idVerbale": "152756737/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630759020000,
      "targa": "PDB5298P",
      "luogoAccertamento": "via trieste via trieste ",
      "numeroRegistro": "1365/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004592021110031",
      "trasgressore": "griggio filippo",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "16375c9a-9df4-4b9e-87fb-11c8195376c1",
      "idVerbale": "152711731/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630878000000,
      "targa": "DE981NC",
      "luogoAccertamento": "Via Marconi VIA MARCONI  via marconi ",
      "numeroRegistro": "1370/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006842021110019",
      "trasgressore": "Peretti Alessandro Maria Giampietro",
      "obbligato": "Peretti Silvano"
    }, {
      "highlights": {},
      "aggId": "dac6edbb-c946-4790-958c-079f0d8bdd76",
      "idVerbale": "246515631/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630787700000,
      "targa": "FC341RD",
      "luogoAccertamento": "via via del plebiscito 1866 ",
      "numeroRegistro": "1368/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006031312021110084",
      "trasgressore": "PRETATO RICCARDO",
      "obbligato": "PICELLO SUSI"
    }, {
      "highlights": {},
      "aggId": "44876b3d-7c5b-4c99-9d12-69db9f819863",
      "idVerbale": "153358933/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1630949700000,
      "targa": "X48SF4",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "1373/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CRISTOFOLI RICCARDO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7ca91eea-3f62-43c4-88b8-2fcb3139d96d",
      "idVerbale": "152867635/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1630975500000,
      "targa": "cx871dd",
      "luogoAccertamento": "Piazza San Bonaventura Piazza San Bonaventura ",
      "numeroRegistro": "1374/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006030622021110048",
      "trasgressore": null,
      "obbligato": "GRAJDIANU ALEXEI"
    }, {
      "highlights": {},
      "aggId": "d6f102b5-317f-4256-8743-1af0a3e5b8de",
      "idVerbale": "246515935/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630908600000,
      "targa": "BW266MK",
      "luogoAccertamento": "VIA BEMBO VIA BEMBO ",
      "numeroRegistro": "1369/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006031242021110017",
      "trasgressore": "PISTELLO CRISTINA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4dbe13f3-e475-4fbc-930b-bcb19665fd88",
      "idVerbale": "152506730/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630938900000,
      "targa": "dv204hy",
      "luogoAccertamento": "Via Roma Via roma ",
      "numeroRegistro": "1371/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008062021110084",
      "trasgressore": "Filippi Paolo",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3f37b047-46b2-4a19-9f1f-5022f0d3173e",
      "idVerbale": "153359139/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1630969227000,
      "targa": "bm195lw",
      "luogoAccertamento": "VIA BANZE ",
      "numeroRegistro": "1375/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "DE BATTISTI MASSIMO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "88a1dfce-7be6-438d-ba07-f857c8236fef",
      "idVerbale": "153359237/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1630972801000,
      "targa": "DR728BK",
      "luogoAccertamento": "VIA SALUTE ",
      "numeroRegistro": "1376/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "RIZZO HOLGUIN ELVIS THOMAS",
      "obbligato": "HOLGUIN PINELA DIANA ELENA"
    }, {
      "highlights": {},
      "aggId": "567e8867-34d5-49e1-a18a-e3c362a1fd1c",
      "idVerbale": "937159324/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629677719000,
      "targa": "CB454CY",
      "luogoAccertamento": "VIA CESARE BATTISTI ",
      "numeroRegistro": "1378/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CUSIN FEDERICO",
      "obbligato": "ALBION DARIO"
    }, {
      "highlights": {},
      "aggId": "2cc0c93a-af7d-4634-af78-b3515c117179",
      "idVerbale": "937159422/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630800900000,
      "targa": "CB454CY",
      "luogoAccertamento": "VIA CESARE BATTISTI ",
      "numeroRegistro": "1381/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CUSIN FEDERICO",
      "obbligato": "ALBION DARIO"
    }, {
      "highlights": {},
      "aggId": "9c88e967-0329-464f-a6db-be9448afc390",
      "idVerbale": "937159226/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630800900000,
      "targa": "CB454CY",
      "luogoAccertamento": "VIA CESARE BATTISTI ",
      "numeroRegistro": "1379/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CUSIN FEDERICO",
      "obbligato": "ALBION DARIO"
    }, {
      "highlights": {},
      "aggId": "32bf91ce-efd8-49a4-a355-656b4cd2b91e",
      "idVerbale": "153386038/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630665600000,
      "targa": "XA554KX",
      "luogoAccertamento": "viale viale dei tigli ",
      "numeroRegistro": "1377/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014972021110055",
      "trasgressore": "D'AMICO SALVATORE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7208d4e4-9404-4ffa-be60-01996f5136cf",
      "idVerbale": "937159020/U/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1623219600000,
      "targa": "de947nd",
      "luogoAccertamento": "VESCOVANA VIA BASSA 4 COMANDO STAZIONE CC",
      "numeroRegistro": "437/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "BORGHETTO FRANCESCO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0494c79f-83f0-425d-b110-28c3a62d7c46",
      "idVerbale": "152617031/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625765880000,
      "targa": "87F1S",
      "luogoAccertamento": "VIA PAVESE  VIA PAVESE ",
      "numeroRegistro": "752/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006332021110049",
      "trasgressore": "FANTINATO MAURO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0d95691b-fab3-4f59-b3b3-e4d4cf361410",
      "idVerbale": "153323631/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622832420000,
      "targa": "DS558AD",
      "luogoAccertamento": "VIA BRUNELLI ",
      "numeroRegistro": "376/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013012021110039",
      "trasgressore": "MASIERO MATTIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a9308059-2f69-4655-89c4-065eff1fa1d6",
      "idVerbale": "153359335/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1631026080000,
      "targa": "cz711pd",
      "luogoAccertamento": "via badia via badia ",
      "numeroRegistro": "1384/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "kozman mohammed",
      "obbligato": "QOZMAN JAMAL"
    }, {
      "highlights": {},
      "aggId": "efd2faad-352d-4fac-a3bb-e09bca5b7dc7",
      "idVerbale": "153461633/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631035440000,
      "targa": "FW616HH",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "1389/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012392021110070",
      "trasgressore": "ORTOLAN ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8513c2f6-51d1-4f33-9918-82b3d06c3e59",
      "idVerbale": "152617139/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625767200000,
      "targa": "87F1S",
      "luogoAccertamento": "VIA PAVESE  VIA PAVESE ",
      "numeroRegistro": "753/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006322021110066",
      "trasgressore": "FANTINATO MAURO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e7c12dc3-b4d2-4e9a-bf73-45343a7048ef",
      "idVerbale": "152990234/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620840335000,
      "targa": "ED040CY",
      "luogoAccertamento": "VIALE PO ",
      "numeroRegistro": "156/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010382021110046",
      "trasgressore": "CAMERAN MARCO",
      "obbligato": "CAMERAN LORIS"
    }, {
      "highlights": {},
      "aggId": "3110bfca-a3d7-46b8-b2bd-02f4015f521d",
      "idVerbale": "153340435/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626079500000,
      "targa": "EP533LX",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "797/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015462021110059",
      "trasgressore": "BREGGIè CAMILLA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "81fe520d-9eb6-4da4-8941-f438f5630f09",
      "idVerbale": "152840539/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625953680000,
      "targa": "bv427cx",
      "luogoAccertamento": "Via Terraglione Via Terraglione ",
      "numeroRegistro": "779/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004122021110086",
      "trasgressore": "dalan davide",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a57b9c6d-6e39-449b-beb2-f0194eaa45c1",
      "idVerbale": "153460331/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623889920000,
      "targa": "FX402DE",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "509/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016122021110053",
      "trasgressore": "DE BATTISTI CHRISTIAN",
      "obbligato": "AUTOTRASPORTI BALDUIN SRL"
    }, {
      "highlights": {},
      "aggId": "5e57bf34-b8f8-4d64-848d-3cfa348d046c",
      "idVerbale": "152577832/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625676000000,
      "targa": "dk317dl",
      "luogoAccertamento": "italia via firenze via firenze",
      "numeroRegistro": "747/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007542021110038",
      "trasgressore": "PETTENUZZO FILIPPO",
      "obbligato": "PETTENUZZO MARIO"
    }, {
      "highlights": {},
      "aggId": "804ec90d-f8f4-4f83-8cb6-e565a493c025",
      "idVerbale": "153460536/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624000200000,
      "targa": "dc042xj",
      "luogoAccertamento": "SP 76  BRESEGANA ",
      "numeroRegistro": "525/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016082021110028",
      "trasgressore": "nacu dumitru",
      "obbligato": "autotrasporti gabanelli srl"
    }, {
      "highlights": {},
      "aggId": "ecfe23ee-bc51-4281-84cf-5dcf232b2cff",
      "idVerbale": "153359530/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1631086980000,
      "targa": "ev908ab",
      "luogoAccertamento": "VIA STAZIE BRAGADINE VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1392/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "maron giuseppe",
      "obbligato": "pastorello marisa"
    }, {
      "highlights": {},
      "aggId": "e8f7fb2e-304e-444c-9dde-7963b6e72f73",
      "idVerbale": "152711839/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631092500000,
      "targa": "EA739MN",
      "luogoAccertamento": "VIA CERATO ",
      "numeroRegistro": "1393/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006832021110036",
      "trasgressore": "MOCELLIN STEFANO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "da358c66-b2d2-4c40-93aa-a32306d95000",
      "idVerbale": "153384334/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625813400000,
      "targa": "DL648YY",
      "luogoAccertamento": "VIA LOVARA ",
      "numeroRegistro": "755/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000622021110084",
      "trasgressore": "BOUCHREK KHADDOUJ",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "86e1c557-e186-4539-91d4-81be689424ac",
      "idVerbale": "152577930/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626051633000,
      "targa": "gb605ek",
      "luogoAccertamento": "via don g. carrara via don g. carrara ",
      "numeroRegistro": "789/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007532021110055",
      "trasgressore": "pettenuzzo antonio",
      "obbligato": "par srl"
    }, {
      "highlights": {},
      "aggId": "f54495c1-0d9e-4b1d-85a3-c9c51eedda66",
      "idVerbale": "153334335/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625960400000,
      "targa": "DT370EJ",
      "luogoAccertamento": "VIA GUIDO FERRO ",
      "numeroRegistro": "777/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015492021110008",
      "trasgressore": "VEGRO MANUEL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "d40aeaad-8d54-4c9f-8c4a-dd573d9dab5c",
      "idVerbale": "152506838/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630939200000,
      "targa": "fk265ct",
      "luogoAccertamento": "Via Roma Via roma ",
      "numeroRegistro": "1394/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007922021110043",
      "trasgressore": "Frasson Paola",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c7d6d341-af20-4e80-9e14-0997ceb7c546",
      "idVerbale": "152809538/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625715900000,
      "targa": "CY933HY",
      "luogoAccertamento": "VIA LONGHIN VIA LONGHIN ",
      "numeroRegistro": "791/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004522021110057",
      "trasgressore": "nzaji bonyi eugene",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3711c2c1-cb5c-444b-9c26-d3becaf4dbb3",
      "idVerbale": "152506936/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631006580000,
      "targa": null,
      "luogoAccertamento": "via piave ",
      "numeroRegistro": "1395/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "HEMUKA CHIADIADI KINGSLEY",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1f75cbb2-3ebd-450e-b53f-3f0911e1e781",
      "idVerbale": "153327138/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1620892140000,
      "targa": "BX362PZ",
      "luogoAccertamento": "VIA DESERTO ",
      "numeroRegistro": "167/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014592021110050",
      "trasgressore": "SCIANNA FRANCESCO",
      "obbligato": "SCIANNA EMMA"
    }, {
      "highlights": {},
      "aggId": "2aa528f9-5eb4-4436-833c-70cce98d7478",
      "idVerbale": "153331132/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1622515620000,
      "targa": "BW965VL",
      "luogoAccertamento": "VIA MEGGIARO ",
      "numeroRegistro": "335/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016992021110062",
      "trasgressore": null,
      "obbligato": "ARCURI FRANCESCO"
    }, {
      "highlights": {},
      "aggId": "3b74c3ad-509a-49ec-80ba-63faaea0a63a",
      "idVerbale": "153337733/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1623666900000,
      "targa": "BY872HC",
      "luogoAccertamento": "VIA STAZIE BRAGADINE VIA STAZIE BRAGADINE ",
      "numeroRegistro": "475/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016382021110076",
      "trasgressore": null,
      "obbligato": "AFYF ABDERRAHIM "
    }, {
      "highlights": {},
      "aggId": "a1d0706c-2767-410d-985a-f8f8b3c67f11",
      "idVerbale": "153337831/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1624384020000,
      "targa": "EF125HW",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "595/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015622021110066",
      "trasgressore": "ALBERTIN PAOLO",
      "obbligato": "ISOLI PATRIZIA"
    }, {
      "highlights": {},
      "aggId": "2d709cca-e3fc-4ad4-b598-99c34fd9436e",
      "idVerbale": "153295038/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1622235300000,
      "targa": "BJ580NY",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "313/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016962021110020",
      "trasgressore": "MARTINELLO MATTEO",
      "obbligato": "MARTINELLO MAURO"
    }, {
      "highlights": {},
      "aggId": "fc64c2a1-f1a4-4fe3-b8f3-4d2691f52d50",
      "idVerbale": "153262439/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1621384500000,
      "targa": "CF606FZ",
      "luogoAccertamento": "VIA GUGLIELMO MARCONI ",
      "numeroRegistro": "216/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019982021110001",
      "trasgressore": "HALILOVIC MEHMED",
      "obbligato": "BREBENEL FLORENTINA"
    }, {
      "highlights": {},
      "aggId": "89a9898a-f134-4e4c-bafc-b974337abf09",
      "idVerbale": "152506632/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630927200000,
      "targa": "fn246ys",
      "luogoAccertamento": "via borgo padova via borgo padova via borgo padova",
      "numeroRegistro": "1396/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008012021110076",
      "trasgressore": "ferronato claudio",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ff9d13ff-1165-4865-aaa0-55b541fd4a23",
      "idVerbale": "152840637/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625955000000,
      "targa": "FZ118XX",
      "luogoAccertamento": "Via Terraglione Via Terraglione ",
      "numeroRegistro": "800/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004132021110069",
      "trasgressore": "celi pietro",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ecc611e1-3ea7-4de5-96ed-5c02ff24ab6d",
      "idVerbale": "153338037/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625814360000,
      "targa": "eg661hw",
      "luogoAccertamento": "VIA CAVAIZZA ",
      "numeroRegistro": "765/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015542021110016",
      "trasgressore": "FRAMORANDO KEVIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "84ea078f-8792-40b8-b6f2-bb49af535723",
      "idVerbale": "153324337/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625993880000,
      "targa": "DS511HV",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "781/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015512021110067",
      "trasgressore": "POZZATO MATTEO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e2904744-0c20-4769-8819-92dd2d650db3",
      "idVerbale": "152637938/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625853000000,
      "targa": "FT998CD",
      "luogoAccertamento": "VIA HERMAGOR ",
      "numeroRegistro": "766/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005222021110076",
      "trasgressore": "Guion Alex",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0380dbbc-f5e5-43dc-89a9-9eb424aae0ac",
      "idVerbale": "153459637/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1621498980000,
      "targa": "CS112ZT",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "226/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012262021110012",
      "trasgressore": "CALLEGARI STEFANO",
      "obbligato": "VISENTIN FLAVIO E C. SNC"
    }, {
      "highlights": {},
      "aggId": "bf1921b2-7afd-4188-b9e5-07efb87abb52",
      "idVerbale": "153342638/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626392580000,
      "targa": "FC598JX",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "845/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015372021110026",
      "trasgressore": "SILVESTRIN NICHOLAS",
      "obbligato": "BOATO TANIA"
    }, {
      "highlights": {},
      "aggId": "3fe18cdf-0f2e-4348-912c-771113e30ecb",
      "idVerbale": "152616531/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624492800000,
      "targa": "er342yn",
      "luogoAccertamento": "via via marconi ",
      "numeroRegistro": "599/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006372021110074",
      "trasgressore": "Marin Carlo",
      "obbligato": "Disarò Loredana"
    }, {
      "highlights": {},
      "aggId": "e5ef112d-94ae-4344-815a-8631746c6dd3",
      "idVerbale": "152637830/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1625735100000,
      "targa": "DW868RM",
      "luogoAccertamento": "via rOMA ",
      "numeroRegistro": "749/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005232021110059",
      "trasgressore": "RAINHARD DEMIS",
      "obbligato": "rainhard michele"
    }, {
      "highlights": {},
      "aggId": "80c58c5f-7595-47f0-9415-fca81abb0d8e",
      "idVerbale": "152638036/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1625950202000,
      "targa": "EM672YK",
      "luogoAccertamento": "VIA CALTANA ",
      "numeroRegistro": "776/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005212021110000",
      "trasgressore": "KADRIC AMEL",
      "obbligato": "KADRIC MIRSAD"
    }, {
      "highlights": {},
      "aggId": "7c0cfaa4-acc8-4875-bcb2-ea1558c65791",
      "idVerbale": "152637735/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1625697000000,
      "targa": "x4KDFP",
      "luogoAccertamento": "Via  Dalla VECCHIA vinicio  ",
      "numeroRegistro": "773/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005242021110042",
      "trasgressore": "Lorioni Alessandro",
      "obbligato": "BOzza Lucia"
    }, {
      "highlights": {},
      "aggId": "57baeb3e-841d-4436-a480-167f9c648e08",
      "idVerbale": "152548231/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1622125800000,
      "targa": "CW079RC",
      "luogoAccertamento": "VIA AURELIA ",
      "numeroRegistro": "319/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005532021110014",
      "trasgressore": "STOIKO JEZON",
      "obbligato": "LEVACOVICH STEFANIA"
    }, {
      "highlights": {},
      "aggId": "4dd8b986-e046-4984-8474-21d5828c0cf8",
      "idVerbale": "153459735/V/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1621931100000,
      "targa": "EK020EG",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "270/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012282021110071",
      "trasgressore": "BOBIL IGOR",
      "obbligato": "GLOBAL LOGISTICA SRL"
    }, {
      "highlights": {},
      "aggId": "cfc9fdae-ea56-41ce-b4b3-02b838aa2c4f",
      "idVerbale": "152711036/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1627804200000,
      "targa": "EW745DY",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "989/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007132021110084",
      "trasgressore": "JANI ANDREI GIGI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "2f3d4675-64f8-4085-97bf-7303cbf38503",
      "idVerbale": "153359433/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1631077680000,
      "targa": "cl358sg",
      "luogoAccertamento": "VIA VIA PAPA GIOVANNI XXIII ",
      "numeroRegistro": "1390/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STOIKA Emilian",
      "obbligato": "cavazza jason"
    }, {
      "highlights": {},
      "aggId": "a32181da-dbb4-489b-94e4-75ad77159c22",
      "idVerbale": "153386136/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631130300000,
      "targa": "RK57TRX",
      "luogoAccertamento": "Via San benedetto ",
      "numeroRegistro": "1399/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014962021110072",
      "trasgressore": "O'BRIEN PATRICK",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c6531eb8-ab4c-4857-9a53-9ed3f0142a77",
      "idVerbale": "153324630/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626347820000,
      "targa": "dj231cv",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "838/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015402021110068",
      "trasgressore": "ZANDONA' FRANCA",
      "obbligato": "FURLANELLO FRANCESCO"
    }, {
      "highlights": {},
      "aggId": "7fb9c413-6ebf-41ba-ac3d-548e3ca8f723",
      "idVerbale": "152732139/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1631175300000,
      "targa": "CH983WF",
      "luogoAccertamento": "via dei contarini via dei contarini ",
      "numeroRegistro": "1404/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "152732139",
      "trasgressore": null,
      "obbligato": "FRATELLI DANIELE DI DANIELE GIANCARLO E DANIELE GIULIANO S.N.C."
    }, {
      "highlights": {},
      "aggId": "616833c4-1042-4510-8070-ca074ffebdde",
      "idVerbale": "152876233/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626385980000,
      "targa": "dw636er",
      "luogoAccertamento": "via dei colli via dei colli ",
      "numeroRegistro": "881/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010052021110049",
      "trasgressore": "loro nicola",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "15a0d700-e202-4912-a758-8460c65c3755",
      "idVerbale": "937159529/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631004600000,
      "targa": "dp703se",
      "luogoAccertamento": "via vescovana via roma ",
      "numeroRegistro": "1403/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": null,
      "obbligato": "blanca società"
    }, {
      "highlights": {},
      "aggId": "d1f16caf-dec3-4735-8ce6-789e24454176",
      "idVerbale": "153334736/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626330780000,
      "targa": "DA303ZA",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "833/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015432021110017",
      "trasgressore": "GOLDIN GIOVANNI",
      "obbligato": "GOLDIN GIANCARLO"
    }, {
      "highlights": {},
      "aggId": "7a9ed0ed-ace1-4a28-81e0-c4496f3830a5",
      "idVerbale": "153334932/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626331500000,
      "targa": "DA303ZA",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "835/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015422021110034",
      "trasgressore": "GOLDIN GIOVANNI",
      "obbligato": "GOLDIN GIANCARLO"
    }, {
      "highlights": {},
      "aggId": "b777c592-4a0b-4dcc-b0fa-e9d63a3c06ba",
      "idVerbale": "153340738/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626512111000,
      "targa": "bm113lp",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "858/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015282021110086",
      "trasgressore": "GASPARETTO LUCA",
      "obbligato": "PRETTO VIOLA"
    }, {
      "highlights": {},
      "aggId": "b9205a6c-f747-4360-9fbf-07d2578cc44a",
      "idVerbale": "152548632/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626508500000,
      "targa": "am861wb",
      "luogoAccertamento": "Viale dell'industria zona industriale",
      "numeroRegistro": "856/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009862021110000",
      "trasgressore": "MONTES DIAZ Maria de Jesus",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e4c2959d-cac8-407a-9178-9ed816abfe98",
      "idVerbale": "153335030/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626333000000,
      "targa": "DA303ZA",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "836/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015412021110051",
      "trasgressore": "GOLDIN GIANCARLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f19556d7-9cc0-459e-9ff6-acdeb089d02f",
      "idVerbale": "195315437/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626686400000,
      "targa": "ep079kr",
      "luogoAccertamento": "via vallegia via ",
      "numeroRegistro": "876/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006018222021110017",
      "trasgressore": "DANILO BELLOTTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f228e2b7-3f22-4911-b47e-10b8b6dae9de",
      "idVerbale": "153335833/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626713460000,
      "targa": "EK281VC",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "882/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002322021110077",
      "trasgressore": "TURRA PATRIZIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "55a06386-d40d-4124-a07e-d90379d2263f",
      "idVerbale": "153343030/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626833426000,
      "targa": "FM306KP",
      "luogoAccertamento": "VIA SAN GIOVANNI BOSCO SR10 VIA SAN GIOVANNI BOSCO ",
      "numeroRegistro": "894/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002352021110026",
      "trasgressore": "TACHRIMANTE ZOUHAIR",
      "obbligato": "Società Estense Servizi Ambientali spa"
    }, {
      "highlights": {},
      "aggId": "b1d6a6e5-a1f2-4f6c-822a-ecd683db23b6",
      "idVerbale": "153384637/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626615600000,
      "targa": "DD884HT",
      "luogoAccertamento": "VIA LUPPIA ALBERI ",
      "numeroRegistro": "866/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015302021110052",
      "trasgressore": "ABDELILAH ABOULAALA",
      "obbligato": "HAMZA MAATOUF"
    }, {
      "highlights": {},
      "aggId": "b7d66fce-7dcc-4a69-b67b-b2213f9bb02a",
      "idVerbale": "153342932/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626642120000,
      "targa": "DJ561CS",
      "luogoAccertamento": "VIA CIRCONVALLAZIONE VIA CIRCONVALLAZIONE ",
      "numeroRegistro": "869/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002312021110001",
      "trasgressore": "HAKIM NOUREDDINE",
      "obbligato": "MBAYE MATAR"
    }, {
      "highlights": {},
      "aggId": "337347da-d01b-4109-bec4-8c38a38764f2",
      "idVerbale": "153336039/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626912600000,
      "targa": "GC754BT",
      "luogoAccertamento": "VIA ASSISI ",
      "numeroRegistro": "902/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002372021110085",
      "trasgressore": "KAMENICA DARIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6da8c0a1-3e86-4ade-9384-9aef7a29b755",
      "idVerbale": "153461731/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631172000000,
      "targa": "DE787FH",
      "luogoAccertamento": "S.P. 100 VIA MEGGIORINA ",
      "numeroRegistro": "1405/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016202021110010",
      "trasgressore": "DORIO ALFREDO MARIA",
      "obbligato": "BETTICAR SRL"
    }, {
      "highlights": {},
      "aggId": "9e6349cd-99b7-43f7-a740-4f9e320ddfe5",
      "idVerbale": "152802232/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627020900000,
      "targa": "af12344",
      "luogoAccertamento": "VIA VENEZIA VIA VENEZIA ",
      "numeroRegistro": "919/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019632021110038",
      "trasgressore": "Lorigiola Marco",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "31492ebb-d04f-4dfd-b51b-8897f466b8d7",
      "idVerbale": "153336137/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626912900000,
      "targa": "GC754BT",
      "luogoAccertamento": "VIA ASSISI ",
      "numeroRegistro": "903/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002382021110068",
      "trasgressore": "KAMENICA DARIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "97fc8f83-3f87-416a-a515-da5926b1d6c1",
      "idVerbale": "153324934/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626477840000,
      "targa": "fd132av",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "857/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015352021110060",
      "trasgressore": "NICOLE' GIOIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "dbc43951-39c1-4c88-bc95-184b247e167c",
      "idVerbale": "152549034/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631179620000,
      "targa": "CF202LL",
      "luogoAccertamento": "VIA CECCARELLO 34 VIA CECCARELLO 34 ",
      "numeroRegistro": "1409/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GALATEANU CRISTIAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "bf91d118-3c3d-4483-96c8-d6b92bcb7032",
      "idVerbale": "153336431/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627155780000,
      "targa": "DY687DN",
      "luogoAccertamento": "VIA DEL COMMERCIO ",
      "numeroRegistro": "928/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002152021110087",
      "trasgressore": "BONFA' THOMAS",
      "obbligato": "BONFA' LUCA"
    }, {
      "highlights": {},
      "aggId": "c20048f1-14d8-487e-89c4-c2e289050019",
      "idVerbale": "153340836/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627031280000,
      "targa": "CR591RR",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "909/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002402021110034",
      "trasgressore": "ANDREOSE MASSIMO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "66f5c62b-cd35-456b-8f74-3796475b0de0",
      "idVerbale": "152638134/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626952520000,
      "targa": "BC382GF",
      "luogoAccertamento": "Piazzetta San Martino ",
      "numeroRegistro": "905/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005202021110017",
      "trasgressore": "RAINHARD Romildo Pio",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4604f05b-23a1-40fd-82c8-096b9ea2e6c0",
      "idVerbale": "153384735/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627156200000,
      "targa": "DC903MK",
      "luogoAccertamento": "VIA LUPPIA ALBERI ",
      "numeroRegistro": "942/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015292021110069",
      "trasgressore": "EL IDRISSI BOUAMRANI Mohamed",
      "obbligato": "BLIOJU IONELA"
    }, {
      "highlights": {},
      "aggId": "63b39b84-ce71-41bb-b225-6f2de3fdb832",
      "idVerbale": "153329430/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624288200000,
      "targa": "FT030CD",
      "luogoAccertamento": "SR 10 VAR PADANA INFERIORE ",
      "numeroRegistro": "573/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015732021110065",
      "trasgressore": "CLEMENTE PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3f5fc4c8-73af-4ee2-b85e-cc18c292740c",
      "idVerbale": "152638330/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627032300000,
      "targa": "fd788rx",
      "luogoAccertamento": "VIA ROMA  VIA ROMA  ",
      "numeroRegistro": "912/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005182021110051",
      "trasgressore": "BOSCHELLO FEDELE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cb28db68-fe7b-470a-a117-2bfe95b6fd7d",
      "idVerbale": "152553830/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627830900000,
      "targa": "fn497yk",
      "luogoAccertamento": "Via Facca SP. 47 Via Facca ",
      "numeroRegistro": "993/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009882021110059",
      "trasgressore": "bianco iginio",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a18c122d-e395-40bd-8a0f-cd8a9ac0f48f",
      "idVerbale": "152733334/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622556600000,
      "targa": "AT368WK",
      "luogoAccertamento": "via Kennedy 17 via kennedy 17 ",
      "numeroRegistro": "457/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009122021110049",
      "trasgressore": "BUCCELLI GENNARO",
      "obbligato": "VANACORE MARIA ROSARIA"
    }, {
      "highlights": {},
      "aggId": "25d239a7-91e8-4068-93be-8c90d750e1fd",
      "idVerbale": "153385137/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627971300000,
      "targa": "BD454WX",
      "luogoAccertamento": "VIA PASQUALE LONGO ",
      "numeroRegistro": "1023/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015262021110027",
      "trasgressore": "REZMIVES IONUT MARIN",
      "obbligato": "MAHRACH NOUR EDDINE"
    }, {
      "highlights": {},
      "aggId": "cbd24b0a-db0b-4d51-a156-43be081cb484",
      "idVerbale": "153345136/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627843020000,
      "targa": "VRB01463",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "1002/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002072021110037",
      "trasgressore": "GIACCHETTO MANUEL",
      "obbligato": "RINALDI MARIA TIZIANA"
    }, {
      "highlights": {},
      "aggId": "b3406293-b5a4-40a5-8489-be2aa80a822b",
      "idVerbale": "153353037/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624672500000,
      "targa": "FC900XX",
      "luogoAccertamento": "VIA CALDEVIGO VIA CALDEVIGO ",
      "numeroRegistro": "658/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015632021110049",
      "trasgressore": "padovani gianluca",
      "obbligato": "PADOVANI EDOARDO"
    }, {
      "highlights": {},
      "aggId": "569e4097-1aa0-48ed-9e68-342e8385688c",
      "idVerbale": "153330231/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621218600000,
      "targa": "fk318pr",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "1048/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014672021110007",
      "trasgressore": "su yifan",
      "obbligato": "wang shinan"
    }, {
      "highlights": {},
      "aggId": "b31ee3f2-74df-4d1d-8c6e-7c90c9d39c72",
      "idVerbale": "195315339/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626341400000,
      "targa": "fc700rb",
      "luogoAccertamento": "via vallegia via ",
      "numeroRegistro": "1008/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006011042021110040",
      "trasgressore": "BARALDO MATTIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "43aed8d6-7b8f-4ffa-8f7b-8349919b4619",
      "idVerbale": "153331631/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623180617000,
      "targa": "EV895AB",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "415/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013092021110089",
      "trasgressore": "OLIVIERO GIULIA",
      "obbligato": "BONIOLO CATERINA"
    }, {
      "highlights": {},
      "aggId": "9989bb4a-d736-47ef-af91-fb54d760396a",
      "idVerbale": "153346037/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629207600000,
      "targa": "AV641YL",
      "luogoAccertamento": "SP 247 RIVIERA VIA RIVIERA ",
      "numeroRegistro": "1415/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "RHABIRI ABDELAAZIZ",
      "obbligato": "RHABIRI ABDELHADI"
    }, {
      "highlights": {},
      "aggId": "8f43947c-8855-4497-b31a-48f24f9cebad",
      "idVerbale": "152800635/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624075800000,
      "targa": "EA314YG",
      "luogoAccertamento": "Via Sorio Via Sorio ",
      "numeroRegistro": "566/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019062021110077",
      "trasgressore": "GUIDOLIN ANDREA",
      "obbligato": "COMARELLA NOEMI"
    }, {
      "highlights": {},
      "aggId": "c72c9172-7b13-4ff9-842d-ab4f62ca915a",
      "idVerbale": "195315632/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627119000000,
      "targa": "ek243dx",
      "luogoAccertamento": "viale viale delle terme ",
      "numeroRegistro": "932/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016412021110025",
      "trasgressore": "BACCAGLINI ROBERTO",
      "obbligato": "BELTRAME MOIRA"
    }, {
      "highlights": {},
      "aggId": "2b0e7f5d-2ae5-430d-9cfc-758cfd7c2949",
      "idVerbale": "153359638/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631204700000,
      "targa": "FT031WJ",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1414/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "ANICULAESEI PETRE BOGDAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "98444171-c196-4b81-ade9-16cd61daa89f",
      "idVerbale": "153404937/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628178000000,
      "targa": "DZ857AL",
      "luogoAccertamento": "Via Nuova Via Nuova ",
      "numeroRegistro": "1036/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002522021110016",
      "trasgressore": "Barisic Luca",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8d3c112c-8ddd-40ed-a835-099e84d4d561",
      "idVerbale": "153344039/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628121000000,
      "targa": "FE975RA",
      "luogoAccertamento": "VIA BRUNELLI ",
      "numeroRegistro": "1033/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002092021110003",
      "trasgressore": "GREGGIO ANDREA",
      "obbligato": "GREGGIO ROBERTO"
    }, {
      "highlights": {},
      "aggId": "1842c28b-728a-4649-9cee-9b668425e3c2",
      "idVerbale": "152711134/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628010840000,
      "targa": "dc979wb",
      "luogoAccertamento": "SS53 Via Dante SS53 Via Dante SS53 Via Dante",
      "numeroRegistro": "1016/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007122021110008",
      "trasgressore": "Camilo Rosilene",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0b7f0596-4c45-4272-8ae1-4a2a39482556",
      "idVerbale": "153405133/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628008200000,
      "targa": "AV254ZA",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "1046/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002512021110033",
      "trasgressore": "rossi luigi",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a2a21093-ff2c-45a1-8ab6-35f5e3a31b9e",
      "idVerbale": "153337537/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628233800000,
      "targa": "DZ234AF",
      "luogoAccertamento": "VIA STAZIONE ",
      "numeroRegistro": "1040/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002112021110062",
      "trasgressore": "CASCIELLO ONOFRIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "586ebddf-1264-4366-8e2c-f4533bd39913",
      "idVerbale": "153344431/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628559000000,
      "targa": "CX382DE",
      "luogoAccertamento": "VIA ANTONIO DRESSENO ",
      "numeroRegistro": "1088/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006022442021110004",
      "trasgressore": "BASHURI EMANUELE",
      "obbligato": "BASHURI ARBEN"
    }, {
      "highlights": {},
      "aggId": "98344a3b-7d9c-48fe-9833-adc3c8cac7b6",
      "idVerbale": "153350539/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623616200000,
      "targa": "FP482LC",
      "luogoAccertamento": "VIA PRINCIPE UMBERTO ",
      "numeroRegistro": "471/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016362021110017",
      "trasgressore": "NICOLETTO WOLFGANG",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "aa1896b1-bcc2-45c0-93d2-92c5d96e7ae8",
      "idVerbale": "153345234/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1628282100000,
      "targa": "DA363ZE",
      "luogoAccertamento": "VIA STATALE ",
      "numeroRegistro": "1050/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002122021110045",
      "trasgressore": "BORASO FEDERICO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6a2532ba-e23c-4158-a896-df879c771e66",
      "idVerbale": "247861531/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631267100000,
      "targa": "DP636DM",
      "luogoAccertamento": "VIA ARZERINI VIA ARZERINI ",
      "numeroRegistro": "1421/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006001712021110091",
      "trasgressore": "MANIERO DIEGO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5882d816-c916-48bc-aead-668b78aecb21",
      "idVerbale": "153331837/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623187800000,
      "targa": "FX133CX",
      "luogoAccertamento": "VIA CAODALBERO VIA CAODALBERO ",
      "numeroRegistro": "417/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014132021110088",
      "trasgressore": "PARGOVA GUERGANA DIMITROVA",
      "obbligato": "SRL MP MOBILI SRL SEMPLIFICATA"
    }, {
      "highlights": {},
      "aggId": "12615bb0-dbb9-42ab-acbc-3f85679a1226",
      "idVerbale": "153360136/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1631197200000,
      "targa": "BP074DX",
      "luogoAccertamento": "VIA NAZIONALE ",
      "numeroRegistro": "1422/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002612021110049",
      "trasgressore": "GALATà GIUSEPPE",
      "obbligato": "ALFA TRASPORTI SRL"
    }, {
      "highlights": {},
      "aggId": "9f6e08bb-d66d-4deb-b915-fda1f002c681",
      "idVerbale": "153335637/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623111600000,
      "targa": "fd126at",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "451/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006013072021110030",
      "trasgressore": "MERLIN ANDREA",
      "obbligato": "MILAN MICHELA"
    }, {
      "highlights": {},
      "aggId": "3b28eadc-a1e6-4aeb-a2c9-b6346dfe7067",
      "idVerbale": "153350236/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623369840000,
      "targa": "CP658ZN",
      "luogoAccertamento": "VIA CAVOUR ",
      "numeroRegistro": "444/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019942021110069",
      "trasgressore": "FACCIOLO MANUEL",
      "obbligato": "G&B SRL"
    }, {
      "highlights": {},
      "aggId": "8c652734-c794-41cb-b441-08db39a8dd4e",
      "idVerbale": "247951532/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619852100000,
      "targa": "FK377MG",
      "luogoAccertamento": "Strada dei pescatori ",
      "numeroRegistro": "41/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006001932021110089",
      "trasgressore": "GIANMARCO MONETTI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b222fa31-814d-4e8d-89c5-24cf52e58334",
      "idVerbale": "153460233/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623307500000,
      "targa": "EY356VN",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "435/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016092021110011",
      "trasgressore": "ALDRIGO LUCA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "43f26ffc-0055-4d0b-90e6-a077930fd6c4",
      "idVerbale": "152889336/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623442500000,
      "targa": "er489xj",
      "luogoAccertamento": "via  perarolo ",
      "numeroRegistro": "467/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005002021110078",
      "trasgressore": "schiraldi chiara",
      "obbligato": "antonio schiraldi"
    }, {
      "highlights": {},
      "aggId": "5b912cac-0f42-4dd7-9018-aedd7690f367",
      "idVerbale": "153350432/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623615300000,
      "targa": "FL817AW",
      "luogoAccertamento": "VIA PRINCIPE UMBERTO ",
      "numeroRegistro": "470/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016352021110034",
      "trasgressore": "RIZZO NICOLA",
      "obbligato": "BERARDI LUCIA"
    }, {
      "highlights": {},
      "aggId": "feec923d-2456-44d4-88fe-05d101bcb785",
      "idVerbale": "153332532/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623254700000,
      "targa": "BR568BW",
      "luogoAccertamento": "VIA VENETO VIA VENETO ",
      "numeroRegistro": "432/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019912021110027",
      "trasgressore": "BELLUCO PRIMO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "5959db7d-8069-4bf7-aebb-9fd0c73d41cc",
      "idVerbale": "153323739/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623494100000,
      "targa": "FK203CN",
      "luogoAccertamento": "via via versori ",
      "numeroRegistro": "464/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016282021110060",
      "trasgressore": "MINGARDO PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9c5694bb-06fc-44d6-9247-4886d5aef833",
      "idVerbale": "153338938/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631103480000,
      "targa": "ET237HV",
      "luogoAccertamento": "SP 247 RIVIERA VIA RIVIERA ",
      "numeroRegistro": "1424/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000642021110050",
      "trasgressore": "BIANCO STEFANO",
      "obbligato": "ROUHAUT EDITH"
    }, {
      "highlights": {},
      "aggId": "87e3c6fd-9a31-4f2c-b25f-9f135adb6885",
      "idVerbale": "152639339/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631260200000,
      "targa": "BZ002NF",
      "luogoAccertamento": "Via Bassa I ",
      "numeroRegistro": "1423/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "SUKA Shpend",
      "obbligato": "KRASNIQ Ruzhdi"
    }, {
      "highlights": {},
      "aggId": "34607177-f854-48d9-9efe-825fec80cdea",
      "idVerbale": "152732237/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631323020000,
      "targa": "ca163bf",
      "luogoAccertamento": "Via Padova - Bassano Via Padova - Bassano ",
      "numeroRegistro": "1433/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006032002021110027",
      "trasgressore": "COSSENTINO MARIAGRAZIA",
      "obbligato": "ALBACAR SRL"
    }, {
      "highlights": {},
      "aggId": "3d103eae-5123-4895-a792-0457b5657184",
      "idVerbale": "153341639/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631305218000,
      "targa": "DC388CD",
      "luogoAccertamento": "VIA XXVIII APRILE ",
      "numeroRegistro": "1426/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "ait el fakira ahmed",
      "obbligato": "A. TEAM S.R.L.S."
    }, {
      "highlights": {},
      "aggId": "dd35f073-0211-4a33-8dbe-2f4bdb4af69e",
      "idVerbale": "152809431/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1622844000000,
      "targa": "BZ682YC",
      "luogoAccertamento": "VIA BRONZETTI VIA BRONZETTI ",
      "numeroRegistro": "371/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005952021110044",
      "trasgressore": "MUNARIN FRANCESCA",
      "obbligato": "MUNARIN ANGELO"
    }, {
      "highlights": {},
      "aggId": "caa3d315-d1e9-48bb-b06b-2ae43aa1be25",
      "idVerbale": "152610831/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631362740000,
      "targa": "bg057ma",
      "luogoAccertamento": "viale roma ",
      "numeroRegistro": "1435/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "de luca thomas",
      "obbligato": "de luca jessica"
    }, {
      "highlights": {},
      "aggId": "e3f2ca18-804f-4e90-9242-0d194d0e37e9",
      "idVerbale": "153439936/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1630962332830,
      "targa": "BE448F",
      "luogoAccertamento": "VIA ARZARELLO VIA ARZARELLO ",
      "numeroRegistro": "1372/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008162021110007",
      "trasgressore": "MICHAEL BELLARIA ",
      "obbligato": "MENEGHELLO  LORIS"
    }, {
      "highlights": {},
      "aggId": "2d312fdf-7200-4ec3-b4bd-30d3f84f85e6",
      "idVerbale": "153339134/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1620830100000,
      "targa": "EL433FV",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "1443/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MURARO LARA",
      "obbligato": "MURARO GIUSEPPE"
    }, {
      "highlights": {},
      "aggId": "dddb3fe9-d987-482f-9f9c-a7b2a47b01b1",
      "idVerbale": "152732335/U/2021",
      "statoVerbale": "spedito",
      "dataOraVerbale": 1631455200000,
      "targa": "pd120476",
      "luogoAccertamento": "via dei contarini via dei contarini ",
      "numeroRegistro": "1442/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006031992021110044",
      "trasgressore": null,
      "obbligato": "MARENDON FEDERICO PAOLO"
    }, {
      "highlights": {},
      "aggId": "6aa93176-1c5a-4d10-9ba1-23836a12626c",
      "idVerbale": "153405437/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631440224000,
      "targa": null,
      "luogoAccertamento": "via Roma via Roma ",
      "numeroRegistro": "1441/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002472021110008",
      "trasgressore": "HOXHA PETRIT",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6f17645a-09ab-4204-8933-9ca622ead0c3",
      "idVerbale": "152616336/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623569460000,
      "targa": "FX092SM",
      "luogoAccertamento": "Via Commerciale ",
      "numeroRegistro": "466/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006392021110040",
      "trasgressore": "CONTIERO DAVIDE",
      "obbligato": "Alphabet Italia Fleet Management Spa"
    }, {
      "highlights": {},
      "aggId": "6b15a0f7-f1e2-4453-875f-14bc9b9da123",
      "idVerbale": "152537136/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623521400000,
      "targa": "EH954FB",
      "luogoAccertamento": "via Maglio ",
      "numeroRegistro": "473/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006122021110034",
      "trasgressore": "Munari Alessio",
      "obbligato": "SAS M.A.BE PLAST DI BERTONCELLO BRUNA"
    }, {
      "highlights": {},
      "aggId": "e9b3794e-e002-4497-9c17-3c1d7ee1fd65",
      "idVerbale": "152616238/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623568200000,
      "targa": "FX092SM",
      "luogoAccertamento": "Via Commerciale ",
      "numeroRegistro": "465/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006402021110023",
      "trasgressore": "CONTIERO DAVIDE",
      "obbligato": "Alphabet Italia Fleet Management Spa"
    }, {
      "highlights": {},
      "aggId": "0243a77f-8345-4b16-80df-52bb5e5d5900",
      "idVerbale": "152889434/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623508800000,
      "targa": "em820bv",
      "luogoAccertamento": "via manzoni ",
      "numeroRegistro": "468/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010032021110083",
      "trasgressore": "turcan nicolae",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a236193e-baaa-413d-8602-8779f442adf4",
      "idVerbale": "153332435/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623254400000,
      "targa": "BR568BW",
      "luogoAccertamento": "VIA VENETO VIA VENETO ",
      "numeroRegistro": "431/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019922021110010",
      "trasgressore": "BELLUCO PRIMO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "bfd3a456-b7e6-4a9d-a6b2-09e4a703082e",
      "idVerbale": "152539036/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631467200000,
      "targa": "DY013BE",
      "luogoAccertamento": "VIA Morosini VIA MOROSINI",
      "numeroRegistro": "1444/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009782021110043",
      "trasgressore": "BOTTERO ALDINA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cd3857c0-4892-4f0f-9055-9e3793ae5fcb",
      "idVerbale": "153350637/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623620400000,
      "targa": "AM23423",
      "luogoAccertamento": "VIA CAPONTE ",
      "numeroRegistro": "472/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016372021110000",
      "trasgressore": "GASIUK VOLODYMYR",
      "obbligato": "HASYUK HALYNA"
    }, {
      "highlights": {},
      "aggId": "fd879fff-26da-403b-bc56-89329b61ffa6",
      "idVerbale": "152538938/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624123800000,
      "targa": "EK719NY",
      "luogoAccertamento": "VIA BASSA III VIA BASSA III ",
      "numeroRegistro": "541/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009792021110026",
      "trasgressore": "CARRARO LAURA",
      "obbligato": "CARRARO TIZIANO"
    }, {
      "highlights": {},
      "aggId": "74d4c65a-f347-4db8-89b4-4010004a2dd7",
      "idVerbale": "152733637/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624382425000,
      "targa": "ET092LK",
      "luogoAccertamento": "VIA ROSSANO ",
      "numeroRegistro": "597/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009082021110024",
      "trasgressore": "SGARBOSSA STEFANO",
      "obbligato": "ANTONELLO ANGELICA"
    }, {
      "highlights": {},
      "aggId": "72a28421-6d38-4fe7-98c0-92eed31ecd74",
      "idVerbale": "152564230/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624484700000,
      "targa": "x8c7wz",
      "luogoAccertamento": "via 25 APRILE ",
      "numeroRegistro": "598/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009512021110037",
      "trasgressore": "ALESSANDRO MASIN",
      "obbligato": "DIEGO MASIN"
    }, {
      "highlights": {},
      "aggId": "17320a71-1f5a-4c88-aedf-e715afce2674",
      "idVerbale": "152639133/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629985500000,
      "targa": "fh247vm",
      "luogoAccertamento": "VIA HERMAGOR ",
      "numeroRegistro": "1446/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "LOVATO Gianluca\\",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "01a79602-09b8-4020-b2fa-2a6bba4558e1",
      "idVerbale": "153319833/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1619876457000,
      "targa": "EC058XA",
      "luogoAccertamento": "VIA BORGOFURO ",
      "numeroRegistro": "68/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012152021110013",
      "trasgressore": "AMBROSI RICCARDO",
      "obbligato": "SINIGAGLIA FABIOLA"
    }, {
      "highlights": {},
      "aggId": "d3886361-5cd2-4b7a-bd03-93877ae9799e",
      "idVerbale": "153329635/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624785600000,
      "targa": "CR836KX",
      "luogoAccertamento": "VIA PADANA INFERIORE VIA PADANA INFERIORE SR10 ",
      "numeroRegistro": "635/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015502021110084",
      "trasgressore": "RETTONDINI LUCA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e72f41ea-4afe-4e4e-9e93-b1026904f3e8",
      "idVerbale": "152841235/U/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1631492100000,
      "targa": "ct323yv",
      "luogoAccertamento": "VIA VIA 4 NOVEMBRE ",
      "numeroRegistro": "1448/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": null,
      "obbligato": "GIUSEPPE BOTTARO"
    }, {
      "highlights": {},
      "aggId": "d33ce0ae-930f-4758-8fce-50da4cc4617b",
      "idVerbale": "152889531/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624553100000,
      "targa": "CV132SX",
      "luogoAccertamento": "VIA MARTIGNON ",
      "numeroRegistro": "625/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010022021110007",
      "trasgressore": "ZILI BUJAR",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c859afd8-2063-4899-9560-11b4fda07cbf",
      "idVerbale": "152556739/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629047700000,
      "targa": "EP658TY",
      "luogoAccertamento": "VIA MALCANTON SP 88 MASSANZAGO VIA MALCANTON ",
      "numeroRegistro": "1449/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "PANIZZON PIETRO",
      "obbligato": "CACCIN STEFANIA"
    }, {
      "highlights": {},
      "aggId": "ff4c7225-9752-46fd-86fb-f49bf3d59188",
      "idVerbale": "153359736/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1631576400000,
      "targa": "EK213NM",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "1453/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "BOTTARO PAOLO",
      "obbligato": "TREVISAN MARIA ENRICHETTA"
    }, {
      "highlights": {},
      "aggId": "e3732397-5797-476d-ac68-8d17d39734ed",
      "idVerbale": "152556837/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629047700000,
      "targa": "EP658TY",
      "luogoAccertamento": "VIA MALCANTON MASSANZAGO VIA MALCANTON ",
      "numeroRegistro": "1451/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "PANIZZON PIETRO",
      "obbligato": "CACCIN STEFANIA"
    }, {
      "highlights": {},
      "aggId": "aac4329e-768f-4e8f-afae-39690192b5bd",
      "idVerbale": "152550735/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625042100000,
      "targa": "fa206fk",
      "luogoAccertamento": "via Garibaldi  Garibaldi ",
      "numeroRegistro": "666/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009842021110034",
      "trasgressore": "colesso mauro",
      "obbligato": "PA. DE. GAS SRL"
    }, {
      "highlights": {},
      "aggId": "713396c5-35bc-4191-834b-2f214aeeba4c",
      "idVerbale": "152677234/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625088300000,
      "targa": "EM352WD",
      "luogoAccertamento": "VIA CORSE 71 VIA CORSE 71 ",
      "numeroRegistro": "668/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005812021110003",
      "trasgressore": "MAURIZIO MENEGATTI",
      "obbligato": "BADO DOMENICO"
    }, {
      "highlights": {},
      "aggId": "7e4e7a3f-cfea-43f0-9a09-3afada66da61",
      "idVerbale": "153461839/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631547420000,
      "targa": "DN342XH",
      "luogoAccertamento": "VIA CHIESA VIA CHIESA ",
      "numeroRegistro": "1452/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006026782021110066",
      "trasgressore": "MONTESIN PIERGIORGIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b6072df9-dc45-4d8f-b57e-2f3dd6174e5c",
      "idVerbale": "152866636/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625260200000,
      "targa": "CN701NK",
      "luogoAccertamento": "strada del santo strada del santo SR307 ",
      "numeroRegistro": "695/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010232021110022",
      "trasgressore": "CECCARELLO MARCO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f6f0e24d-5c3a-48c2-8ba0-99a1d3dfe7b9",
      "idVerbale": "152610136/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625247000000,
      "targa": "ec816pm",
      "luogoAccertamento": "10 via villanova ",
      "numeroRegistro": "689/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007492021110030",
      "trasgressore": "baldassa davide",
      "obbligato": "minuzzo donella"
    }, {
      "highlights": {},
      "aggId": "d9fc2d2c-135a-42e7-9d9e-436c35a4dd3f",
      "idVerbale": "152536734/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1620496020000,
      "targa": "fe467ma",
      "luogoAccertamento": "via Antoniana ",
      "numeroRegistro": "169/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005592021110005",
      "trasgressore": "Dorigo Davide",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e868abec-8c7f-4796-a4f3-519f33156009",
      "idVerbale": "153460938/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625119200000,
      "targa": "FH420HW",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "676/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016192021110027",
      "trasgressore": "GAMBIN CHIARA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "46a05edf-cb1c-4fe5-a602-2cb0fcdac8b1",
      "idVerbale": "815942128/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625176800000,
      "targa": "GE418KH",
      "luogoAccertamento": "VIA UMBERTO I ",
      "numeroRegistro": "705/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006001132021110054",
      "trasgressore": "CARRARO GIANANTONIO",
      "obbligato": "OCS COLD SRL"
    }, {
      "highlights": {},
      "aggId": "efd3371e-cf6f-4b87-9ab7-74126489f648",
      "idVerbale": "815942226/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625176800000,
      "targa": "GE418KH",
      "luogoAccertamento": "VIA UMBERTO I ",
      "numeroRegistro": "720/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006001142021110037",
      "trasgressore": "CARRARO GIANANTONIO",
      "obbligato": "OCS COLD SRL"
    }, {
      "highlights": {},
      "aggId": "10e8ca7c-7498-4b66-8a6c-1ec5acd0af82",
      "idVerbale": "815942529/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625176800000,
      "targa": "GE418KH",
      "luogoAccertamento": "VIA UMBERTO I ",
      "numeroRegistro": "723/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006001172021110079",
      "trasgressore": "CARRARO GIANANTONIO",
      "obbligato": "OCS COLD SRL"
    }, {
      "highlights": {},
      "aggId": "f61a9bc6-7d86-4b68-8648-41da53ed4619",
      "idVerbale": "815942422/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625176800000,
      "targa": "GE418KH",
      "luogoAccertamento": "VIA UMBERTO I ",
      "numeroRegistro": "722/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006001162021110003",
      "trasgressore": "CARRARO GIANANTONIO",
      "obbligato": "OCS COLD SRL"
    }, {
      "highlights": {},
      "aggId": "920da23a-2c28-4fa9-b0ba-62ee0eb4ec79",
      "idVerbale": "153470133/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625639100000,
      "targa": "CH753XB",
      "luogoAccertamento": "VIA  VIA ESTE ",
      "numeroRegistro": "732/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012712021110084",
      "trasgressore": "POSENATO ALFONSO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "41ae8dd3-6600-4f2d-a1bb-af4a3261b77e",
      "idVerbale": "153404633/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625419200000,
      "targa": "DD113VH",
      "luogoAccertamento": "via ROMA ",
      "numeroRegistro": "713/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002542021110075",
      "trasgressore": "SEGANTIN MARINO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "198b6de9-3f0d-4b77-be0d-95a659028891",
      "idVerbale": "153360234/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631625876000,
      "targa": "CL358SG",
      "luogoAccertamento": "VIA VIA PAPA GIOVANNI XXIII ",
      "numeroRegistro": "1468/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STOICA EMILIAN",
      "obbligato": "cavazza jason"
    }, {
      "highlights": {},
      "aggId": "ebaf17b8-5e7a-4bef-99fc-e7e7be10308f",
      "idVerbale": "152867136/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630224949000,
      "targa": "CN464PW",
      "luogoAccertamento": "via  roma ",
      "numeroRegistro": "1463/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MOUTALIB OMAR",
      "obbligato": "MAATOUFI BOUCHRA"
    }, {
      "highlights": {},
      "aggId": "8333709a-79cb-41d7-bd97-37e1b2d40254",
      "idVerbale": "153461937/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631628000000,
      "targa": "CW983CS",
      "luogoAccertamento": "PIAZZA EUROPA PIAZZA EUROPA ",
      "numeroRegistro": "1465/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016212021110086",
      "trasgressore": "FRACASSO LUIGINO",
      "obbligato": "MARCHIORI ETTORE"
    }, {
      "highlights": {},
      "aggId": "4f5233df-5ec3-4b73-aced-e60a8efd696b",
      "idVerbale": "152867234/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630224875000,
      "targa": "cN464PW",
      "luogoAccertamento": "via  roma ",
      "numeroRegistro": "1462/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MOUTALIB OMAR",
      "obbligato": "MAATOUFI BOUCHRA"
    }, {
      "highlights": {},
      "aggId": "8723902b-b680-4029-91d5-2dd091f0a0f0",
      "idVerbale": "153462035/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631628337049,
      "targa": "FA527RN",
      "luogoAccertamento": "PIAZZA EUROPA PIAZZA EUROPA ",
      "numeroRegistro": "1467/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016222021110069",
      "trasgressore": "ROSSATO CATIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "307f5c86-d757-4962-819d-d866551be21e",
      "idVerbale": "152578234/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631637600000,
      "targa": "EN548DF",
      "luogoAccertamento": "VIA ugo foscolo ",
      "numeroRegistro": "1469/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "PIRAN ALESSANDRA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "76e27e12-4058-4b10-926e-308b0558f915",
      "idVerbale": "153359834/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631641137000,
      "targa": "BW041SG",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "1471/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MUNTEANU IVAN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "fd04587a-f269-4afb-b908-0205395e2faa",
      "idVerbale": "152507034/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631293500000,
      "targa": "EJ866VD",
      "luogoAccertamento": "via Garibaldi  Garibaldi ",
      "numeroRegistro": "1475/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007932021110026",
      "trasgressore": "Ghebrea Adrian",
      "obbligato": "Marcon Renato"
    }, {
      "highlights": {},
      "aggId": "fcbdc7d4-2089-472a-a618-ec4986cb84f8",
      "idVerbale": "152545831/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624064520000,
      "targa": "et032dw",
      "luogoAccertamento": "via via san francesco ",
      "numeroRegistro": "534/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009602021110070",
      "trasgressore": "Pinaffo Thomas",
      "obbligato": "centenaro annamaria"
    }, {
      "highlights": {},
      "aggId": "06e509c9-3aad-48ce-989f-202f2e9eb3cb",
      "idVerbale": "152538732/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623258720000,
      "targa": "DL359GG",
      "luogoAccertamento": "VIA ORTIGARA VIA ORTIGARA ",
      "numeroRegistro": "433/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006102021110068",
      "trasgressore": "FAVARETTO ALBERTO",
      "obbligato": "ITALCORE SRL"
    }, {
      "highlights": {},
      "aggId": "e5c5bc98-5148-4c86-ad94-bcdcb1f5b299",
      "idVerbale": "152676832/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623529800000,
      "targa": "gc977bs",
      "luogoAccertamento": "Via Carturo Via Carturo ",
      "numeroRegistro": "462/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005042021110010",
      "trasgressore": "FACCO Riccardo",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ef72123b-0ca8-4ab2-a2e5-10ed83f39748",
      "idVerbale": "153343236/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627057543000,
      "targa": "fk201jd",
      "luogoAccertamento": "VIA STAZIE BRAGADINE VIA STAZIE BRAGADINE ",
      "numeroRegistro": "916/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002022021110029",
      "trasgressore": "MONTIN MASSIMILIANO",
      "obbligato": "SCARANELLO SIMONETTA"
    }, {
      "highlights": {},
      "aggId": "2f78dfb3-022d-4e1f-8fd7-d40aafa92fc9",
      "idVerbale": "152617237/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626311400000,
      "targa": "FZ491BN",
      "luogoAccertamento": "VIA BORGO PADOVA VIA BORGO PADOVA ",
      "numeroRegistro": "826/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005442021110074",
      "trasgressore": "FERRARA MASSIMO",
      "obbligato": "FANTON RENATA"
    }, {
      "highlights": {},
      "aggId": "e0a6ad28-ce25-4664-8b2f-5190287fd67b",
      "idVerbale": "152564436/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626451200000,
      "targa": "ch631yn",
      "luogoAccertamento": "via NOVA via nova ",
      "numeroRegistro": "847/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009492021110071",
      "trasgressore": "EMANUELA MICHELIN",
      "obbligato": "GEMIN Tiziano"
    }, {
      "highlights": {},
      "aggId": "08b2be7d-c64c-470d-9c43-23c1f96ad09c",
      "idVerbale": "152866832/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627117800000,
      "targa": "fz927xt",
      "luogoAccertamento": "strada del santo strada del santo SR307 ",
      "numeroRegistro": "926/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010262021110064",
      "trasgressore": "michele bortolato",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3c8c1bb4-ff80-4eaf-ba57-c4a57b77e612",
      "idVerbale": "153340630/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1626430256000,
      "targa": "dg878kf",
      "luogoAccertamento": "VIA STAZIE BRAGADINE VIA STAZIE BRAGADINE ",
      "numeroRegistro": "850/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015362021110043",
      "trasgressore": "mahmoud ayoub",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f0fb1765-9994-49cd-b18f-466c082dc68a",
      "idVerbale": "152730033/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1625268600000,
      "targa": "FL391AY",
      "luogoAccertamento": "VIA PROVINCIALE SP 47 VIA PROVINCIALE ",
      "numeroRegistro": "694/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006732021110020",
      "trasgressore": "CAROLLO MARTA",
      "obbligato": "BOLOGNESE ANDREA"
    }, {
      "highlights": {},
      "aggId": "a8421611-ed09-4736-86c9-5382af32ae3d",
      "idVerbale": "153331935/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623239100000,
      "targa": "BV923ZA",
      "luogoAccertamento": "VIA DESERTO ",
      "numeroRegistro": "426/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014142021110071",
      "trasgressore": "BERESTEAN CONSTANTIN",
      "obbligato": "ARCAN CLAUDIA"
    }, {
      "highlights": {},
      "aggId": "a9c10cc2-71a7-4d3a-a934-86c52960e127",
      "idVerbale": "152548534/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624542900000,
      "targa": "cr121be",
      "luogoAccertamento": "via  roma ",
      "numeroRegistro": "615/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009872021110076",
      "trasgressore": "Mboup Ousseynou",
      "obbligato": "MBOUP Momar"
    }, {
      "highlights": {},
      "aggId": "88a8c704-2e67-4674-b0d4-b02930d302af",
      "idVerbale": "152732433/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631731500000,
      "targa": "FF877NH",
      "luogoAccertamento": "COMANDO STAZIONE CARABINIERI VIA DEI CONTARINI 21 COMANDO STAZIONE CARABINIERI ",
      "numeroRegistro": "1479/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006031982021110061",
      "trasgressore": "CAMPESATO RENZO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c42d5303-f0c5-47ba-949e-a6a7552b8ddb",
      "idVerbale": "153384833/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1627160100000,
      "targa": "DC903MK",
      "luogoAccertamento": "VIA LUPPIA ALBERI ",
      "numeroRegistro": "943/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015272021110010",
      "trasgressore": "EL IDRISSI BOUAMRANI Mohamed",
      "obbligato": "BLIOJU IONELA"
    }, {
      "highlights": {},
      "aggId": "88eb57ce-e18b-44aa-997b-b230a2a4cc47",
      "idVerbale": "153360332/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631752500000,
      "targa": "EN910CA",
      "luogoAccertamento": "VIA DEGLI ESTENSI ",
      "numeroRegistro": "1482/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002002021110063",
      "trasgressore": "GARBIN DANIELE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e23ff6c5-be6a-4a83-a959-901f7c8affe4",
      "idVerbale": "153360430/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631753880000,
      "targa": "EN910CA",
      "luogoAccertamento": "VIA DEGLI ESTENSI ",
      "numeroRegistro": "1481/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "GARBIN DANIELE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "633f0f8a-74b5-4ec3-b953-557a6d13ee8e",
      "idVerbale": "153341835/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631805300000,
      "targa": "eh026tv",
      "luogoAccertamento": "VIA UMBERTO I° ",
      "numeroRegistro": "1484/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "viola renata",
      "obbligato": "cerato luigi innocente"
    }, {
      "highlights": {},
      "aggId": "53e425fd-d8b6-493f-80dc-ab04aa2347f2",
      "idVerbale": "153405534/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631791800000,
      "targa": "PD817022",
      "luogoAccertamento": "piazza giacomo matteotti ",
      "numeroRegistro": "1488/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006002462021110025",
      "trasgressore": "RECCA PIETRO",
      "obbligato": "DINCA VALI"
    }, {
      "highlights": {},
      "aggId": "de4c381d-3f5a-4b98-8d90-cb65a6fcedfa",
      "idVerbale": "152610939/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631794528000,
      "targa": "SPROVVISTO",
      "luogoAccertamento": "via a. de gasperi ",
      "numeroRegistro": "1490/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MARAZZATO GIOVANNI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "bd729f51-1cb9-4705-9bce-db0704169bd2",
      "idVerbale": "152611037/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631795288000,
      "targa": "SPROVVISTO",
      "luogoAccertamento": "via a. de gasperi ",
      "numeroRegistro": "1491/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MARAZZATO GIOVANNI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ffda0f4d-424b-4a6c-a0e9-19441430a086",
      "idVerbale": "153333531/V/2021",
      "statoVerbale": "ricorso",
      "dataOraVerbale": 1624981560000,
      "targa": "FL670LF",
      "luogoAccertamento": "SP 247 RIVIERA VIA RIVIERA ",
      "numeroRegistro": "1492/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016312021110009",
      "trasgressore": "BELLIN GIUSEPPE",
      "obbligato": "BELLIN COSTRUZIONI SRL"
    }, {
      "highlights": {},
      "aggId": "15513362-d18f-4e80-a260-2c8ae4686122",
      "idVerbale": "153340532/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1626383100000,
      "targa": "dz709pc",
      "luogoAccertamento": "VIA ROMA VIA ROMA ",
      "numeroRegistro": "840/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015382021110009",
      "trasgressore": "BERGAMASCO IVO",
      "obbligato": "BERGAMASCO GIANCARLO"
    }, {
      "highlights": {},
      "aggId": "b34444be-ab10-40ff-8844-1e00e4e28955",
      "idVerbale": "153262536/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1621628700000,
      "targa": "DA916ZB",
      "luogoAccertamento": "VIA ARZERE ",
      "numeroRegistro": "243/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014722021110015",
      "trasgressore": "BARBIERATO BARBARA",
      "obbligato": "MINELLE ALEX"
    }, {
      "highlights": {},
      "aggId": "d6e3aee3-b424-427f-9316-1389de4f3f34",
      "idVerbale": "152638839/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1628033100000,
      "targa": "ga104pg",
      "luogoAccertamento": "Via Bassa I ",
      "numeroRegistro": "1018/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005132021110043",
      "trasgressore": "LONGO Federico",
      "obbligato": "MENIN Vanna"
    }, {
      "highlights": {},
      "aggId": "6a50fc5f-b5ba-444f-8401-fe603002a345",
      "idVerbale": "153334237/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625892000000,
      "targa": "EH055TW",
      "luogoAccertamento": "VIA ALBERE ",
      "numeroRegistro": "774/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015552021110092",
      "trasgressore": "LISIERO MONICA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7892ad55-36a4-468f-a18b-7ad85f8877b5",
      "idVerbale": "153383530/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1619528400000,
      "targa": "DM932DB",
      "luogoAccertamento": "via brancaglia ",
      "numeroRegistro": "33/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012672021110059",
      "trasgressore": "ARONNE VINCENZO",
      "obbligato": "AFFRESCO SRL"
    }, {
      "highlights": {},
      "aggId": "6ae5491c-6ac0-43cc-b693-7fb166c55a41",
      "idVerbale": "153362633/V/2021",
      "statoVerbale": "pagamento_saldo_forzato",
      "dataOraVerbale": 1631885400000,
      "targa": "CR441LH",
      "luogoAccertamento": "via Via Saoncella - sp.90 ",
      "numeroRegistro": "1499/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "RINALDO FRANCESCO",
      "obbligato": "TONINELLO SRL"
    }, {
      "highlights": {},
      "aggId": "51e01063-4502-44fd-9426-eccc9aee3af7",
      "idVerbale": "152844839/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1621187100000,
      "targa": "EZ249DY",
      "luogoAccertamento": "Via MANZONI ",
      "numeroRegistro": "206/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006004032021110053",
      "trasgressore": "LUCA GARZARA",
      "obbligato": "FABIO FRANCINI"
    }, {
      "highlights": {},
      "aggId": "828eee65-cc0c-48e2-89c6-315cb97900db",
      "idVerbale": "153341737/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631732400000,
      "targa": "ct752yp",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "1500/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006001992021110080",
      "trasgressore": "MAGAROTTO TIZIANO",
      "obbligato": "EDILMILESI SRL"
    }, {
      "highlights": {},
      "aggId": "aba9cf67-e584-45bf-885d-ae605f42fb47",
      "idVerbale": "153362839/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1631956800000,
      "targa": "FS978EC",
      "luogoAccertamento": "SP48 SP48 VIA SANTA CROCE ",
      "numeroRegistro": "1509/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "ROSSI CARLO",
      "obbligato": "ING.AM.SRL"
    }, {
      "highlights": {},
      "aggId": "c681268f-198b-4dab-b6e6-01c91e4a3088",
      "idVerbale": "153360537/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631988327000,
      "targa": "CZ104LG",
      "luogoAccertamento": "VIA GUIDO NEGRI VIA GUIDO NEGRI ",
      "numeroRegistro": "1513/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "VOGLI BASHKIM",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0ad5577d-308b-403a-93ff-75bf8cd13b67",
      "idVerbale": "153362731/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1631955000000,
      "targa": "CD335GS",
      "luogoAccertamento": "SP48 SP48 VIA SANTA CROCE ",
      "numeroRegistro": "1508/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "NIZZETTO ANNA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8e28463a-dca8-4e03-ab67-4500922bdef4",
      "idVerbale": "153360733/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631998250000,
      "targa": "BZ371FG",
      "luogoAccertamento": "VIA TREPONTI VIA TREPONTI ",
      "numeroRegistro": "1512/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "FANTINATO ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "35ba6c4b-6cfa-4d63-b5da-f2f557bfbc7d",
      "idVerbale": "153360635/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631988947000,
      "targa": "CZ104LG",
      "luogoAccertamento": "VIA GUIDO NEGRI ",
      "numeroRegistro": "1514/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "VOGLI BASHKIM",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3bda1e74-6576-4415-a66d-bf8482dd8a38",
      "idVerbale": "152639035/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629985227000,
      "targa": "FH247VM",
      "luogoAccertamento": "VIA HERMAGOR ",
      "numeroRegistro": "1271/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "LOVATO Gianluca\\",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "6191d2d8-8e73-4227-8a8c-9b0b8de8bad5",
      "idVerbale": "195315936/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629531000000,
      "targa": "mancante",
      "luogoAccertamento": "via  vallegia ",
      "numeroRegistro": "1516/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "menin raffaele",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9eaad1e7-49d4-49a4-93d1-c9f3efd2023a",
      "idVerbale": "195315838/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629533400000,
      "targa": "mancante",
      "luogoAccertamento": "via  vallegia ",
      "numeroRegistro": "1515/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "menin raffaele",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a39a8770-faa3-42b5-a651-609d64b6b654",
      "idVerbale": "152639231/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1630074480000,
      "targa": "el390fz",
      "luogoAccertamento": "via Croce Ruzza ",
      "numeroRegistro": "1280/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MARGARINT Stepan",
      "obbligato": "ZANETTI TRASPORTI E NOLEGGI SRL"
    }, {
      "highlights": {},
      "aggId": "0bc65357-0588-4629-b394-7fb53bd10702",
      "idVerbale": "152618030/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631909447000,
      "targa": "BD751ZH",
      "luogoAccertamento": "VIA bonora ",
      "numeroRegistro": "1507/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006032092021110060",
      "trasgressore": "CARINATO ANDREA",
      "obbligato": "CUSINATO ANTONIETTA"
    }, {
      "highlights": {},
      "aggId": "23cfa33a-6564-46df-a30c-74512904f6c5",
      "idVerbale": "153360831/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632064838000,
      "targa": "CB318TS",
      "luogoAccertamento": "SR 10 VAR PADANA INFERIORE ",
      "numeroRegistro": "1521/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "BORGATO ALESSANDRO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "1eb5606b-d1c5-4544-b544-e20aeeca41cb",
      "idVerbale": "153362937/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1632074421000,
      "targa": "BJ311SD",
      "luogoAccertamento": "VIA ROBERT BADEN POWELL ",
      "numeroRegistro": "1523/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": null,
      "obbligato": "PASHAH HOSSEIN"
    }, {
      "highlights": {},
      "aggId": "3bf1430e-653e-4238-8fef-6eb3e7418587",
      "idVerbale": "153363133/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632088539000,
      "targa": "CL358SG",
      "luogoAccertamento": "VIA FRATELLI CERVI ",
      "numeroRegistro": "1525/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "cavazza jason",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "691f423b-7732-4aac-8449-7e348511a6fd",
      "idVerbale": "153363035/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632087912000,
      "targa": "CL358SG",
      "luogoAccertamento": "VIA FRATELLI CERVI ",
      "numeroRegistro": "1524/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "cavazza jason",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "36ad2f75-dbf9-419e-b01f-48c22e912d74",
      "idVerbale": "153462133/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632108900000,
      "targa": "PDB81047",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "1527/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016242021110035",
      "trasgressore": "BARON PAOLO",
      "obbligato": "TONIOLO VITTORINA"
    }, {
      "highlights": {},
      "aggId": "499be656-f4f3-471f-971c-c4fe0060aa8d",
      "idVerbale": "152557033/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631355000000,
      "targa": "BN566RV",
      "luogoAccertamento": "SP47 VIA BOLZANELLA VIA BOLZANELLA ",
      "numeroRegistro": "1533/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MARTINI GIACOMO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3c6c9911-cabd-47e0-8b4e-f342df61a3cb",
      "idVerbale": "152809734/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632054540000,
      "targa": "ek904jn",
      "luogoAccertamento": "via monte grappa via monte grappa ",
      "numeroRegistro": "1529/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019302021110041",
      "trasgressore": "bertin jacopo",
      "obbligato": "gastaldon simone"
    }, {
      "highlights": {},
      "aggId": "42e3db6c-f751-40ff-a0b3-cf4b3b19399e",
      "idVerbale": "152510538/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631358000000,
      "targa": "BN566RV",
      "luogoAccertamento": "SP47 VIA BOLZANELLA VIA BOLZANELLA ",
      "numeroRegistro": "1534/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MARTINI GIACOMO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "26e00abe-a09d-4bb8-ae8c-a3ac5973ca4b",
      "idVerbale": "153363231/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632088762000,
      "targa": "CL358SG",
      "luogoAccertamento": "VIA FRATELLI CERVI ",
      "numeroRegistro": "1526/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "cavazza jason",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8259bd03-6853-4c02-902b-4589cae2c8bd",
      "idVerbale": "152556935/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1629047700000,
      "targa": "EP658TY",
      "luogoAccertamento": "VIA MALCANTON MASSANZAGO VIA MALCANTON ",
      "numeroRegistro": "1537/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "PANIZZON PIETRO",
      "obbligato": "CACCIN STEFANIA"
    }, {
      "highlights": {},
      "aggId": "9352fe7e-a0f6-44cf-b33f-9a491a4e4abe",
      "idVerbale": "152507132/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631358900000,
      "targa": "BN566RV",
      "luogoAccertamento": "SP47 VIA BOLZANELLA VIA BOLZANELLA ",
      "numeroRegistro": "1535/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006007942021110009",
      "trasgressore": "MARTINI GIACOMO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a88ac105-40a5-46d5-bf70-4b000794cadc",
      "idVerbale": "153363339/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632141011000,
      "targa": "FS918DC",
      "luogoAccertamento": "VIA CALDEVIGO VIA CALDEVIGO ",
      "numeroRegistro": "1540/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "OLIVATO GIAMPAOLO",
      "obbligato": "EDILBIEMME SSRL"
    }, {
      "highlights": {},
      "aggId": "3a45d724-bc29-4bc7-8737-ff611f4093fd",
      "idVerbale": "152732530/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632058237000,
      "targa": "AJ288AZ",
      "luogoAccertamento": "VIA CAMPO SAN MARTINO VIA BRENTA ",
      "numeroRegistro": "1538/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006031972021110078",
      "trasgressore": null,
      "obbligato": "GOTTARDELLO NICOLA"
    }, {
      "highlights": {},
      "aggId": "f41439e6-3ae8-4dd5-909e-dcfdd866ee2e",
      "idVerbale": "152557131/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631363400000,
      "targa": "BN566RV",
      "luogoAccertamento": "SP47 VIA BOLZANELLA VIA BOLZANELLA ",
      "numeroRegistro": "1536/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "MARTINI GIACOMO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e80a12ec-0fde-4ac6-9104-a57ddbbac9df",
      "idVerbale": "153363437/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632170400000,
      "targa": "FJ638LN",
      "luogoAccertamento": "Via Pasquale Longo Via Pasquale Longo ",
      "numeroRegistro": "1542/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STECCO MARTINO",
      "obbligato": "MASIERO SPEDIZIONI S.R.L."
    }, {
      "highlights": {},
      "aggId": "8007cb5f-74a5-45cc-add8-1f84be3da32f",
      "idVerbale": "152991732/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632180642000,
      "targa": "er365lh",
      "luogoAccertamento": "PIAZZA SALVO D'ACQUISTO 1 PIAZZA SALVO D'ACQUISTO 1 ",
      "numeroRegistro": "1545/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "davide russo",
      "obbligato": "antonio russo"
    }, {
      "highlights": {},
      "aggId": "665e4857-7d49-47c8-8215-baa9f655087a",
      "idVerbale": "152809832/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632132660000,
      "targa": "EB56065",
      "luogoAccertamento": "via paoli via paoli ",
      "numeroRegistro": "1546/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019292021110058",
      "trasgressore": "hannou youssef",
      "obbligato": "BOUMDI IMANE"
    }, {
      "highlights": {},
      "aggId": "486c71f4-3a25-43c0-bc93-73763ae5eb9d",
      "idVerbale": "153363632/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632171000000,
      "targa": "FJ638LN",
      "luogoAccertamento": "Via Pasquale Longo Via Pasquale Longo ",
      "numeroRegistro": "1544/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STECCO MARTINO",
      "obbligato": "RSC TRASPORTI DI STECCO MARTINO & C. S.N.C."
    }, {
      "highlights": {},
      "aggId": "22d845e5-38f3-4613-975e-f75b719ebfba",
      "idVerbale": "153363534/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632170700000,
      "targa": "FJ638LN",
      "luogoAccertamento": "Via Pasquale Longo Via Pasquale Longo ",
      "numeroRegistro": "1543/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STECCO MARTINO",
      "obbligato": "MASIERO SPEDIZIONI S.R.L."
    }, {
      "highlights": {},
      "aggId": "77a2bef8-8b29-4087-abd3-97e0beff8515",
      "idVerbale": "153363730/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632229740000,
      "targa": "CL313VD",
      "luogoAccertamento": "VIA STRADA CARCERI ",
      "numeroRegistro": "1549/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "LIVIERO MASSIMO",
      "obbligato": "BIASIN ORIETTA"
    }, {
      "highlights": {},
      "aggId": "6d31e583-2239-426b-b7de-cb2afdc23d2c",
      "idVerbale": "153462339/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632231240000,
      "targa": "DX826BF",
      "luogoAccertamento": "SP 76 VIA  CHIESA DI PONSO ",
      "numeroRegistro": "1556/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006026792021110049",
      "trasgressore": "ANDREASI LUCIANO",
      "obbligato": "C. & B. TRASPORTI E SERVIZI SRL"
    }, {
      "highlights": {},
      "aggId": "b6f41b35-f87f-433c-9373-007b2e60aa64",
      "idVerbale": "153462231/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632230100000,
      "targa": "EB140VN",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "1553/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016182021110044",
      "trasgressore": "ROMITO PATRIZIA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e8508c96-8883-494c-ad4b-db1f31452a11",
      "idVerbale": "152611135/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632210120000,
      "targa": "FL989GE",
      "luogoAccertamento": "CORSO DEL POPOLO CORSO DEL POPOLO ",
      "numeroRegistro": "1561/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "LEANDRO DEVIS",
      "obbligato": "NEW D.W. S.R.L."
    }, {
      "highlights": {},
      "aggId": "4f5730ef-2bbf-4670-b33d-18011aeea4a8",
      "idVerbale": "153342031/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1632309600000,
      "targa": "x85g55",
      "luogoAccertamento": "SP247 via Martiri della Libertà via Martiri della liberta' ",
      "numeroRegistro": "1562/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "pozza michele",
      "obbligato": "pozza andrea"
    }, {
      "highlights": {},
      "aggId": "fa6117a9-068e-4776-aaf1-4018c88bbc02",
      "idVerbale": "153352537/V/2021",
      "statoVerbale": "ricorso",
      "dataOraVerbale": 1624537800000,
      "targa": "7648E",
      "luogoAccertamento": "VIA ALBERE ",
      "numeroRegistro": "647/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015692021110040",
      "trasgressore": "SPINALBELLI ANGELO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4e19efb5-d046-4980-b213-7b81f26918e6",
      "idVerbale": "153352430/V/2021",
      "statoVerbale": "ricorso",
      "dataOraVerbale": 1624536900000,
      "targa": "7618E",
      "luogoAccertamento": "VIA ALBERE ",
      "numeroRegistro": "646/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "SPINALBELLI ANGELO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "25212e65-04b9-49db-847b-df8c90608fe5",
      "idVerbale": "152549132/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632333000000,
      "targa": "FP663XS",
      "luogoAccertamento": "via Mottinello ",
      "numeroRegistro": "1565/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008002021110000",
      "trasgressore": "SIMEONI DAVIDE",
      "obbligato": "GASTALDON ALESSANDRA"
    }, {
      "highlights": {},
      "aggId": "1fbe2932-0dbc-488d-8996-c4d6a9abd6cf",
      "idVerbale": "153462437/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632331020000,
      "targa": "GB096BR",
      "luogoAccertamento": "Bresegana SP 76 ",
      "numeroRegistro": "1570/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "GIGLI PAOLO FRANCESCO",
      "obbligato": "BIANCO SERVICE SRL"
    }, {
      "highlights": {},
      "aggId": "2bd3d15b-ef81-4a77-b457-117e1ecc41c7",
      "idVerbale": "153342335/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1632380700000,
      "targa": "CY029JM",
      "luogoAccertamento": "SP48 SP48 VIA SANTA CROCE ",
      "numeroRegistro": "1571/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "TURETTA ERIKA",
      "obbligato": "SRL MDS GOMME"
    }, {
      "highlights": {},
      "aggId": "c2c16c0b-1f3a-4ffe-826e-7129e6ee7d00",
      "idVerbale": "152867733/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632355800000,
      "targa": "FT416CC",
      "luogoAccertamento": "Piazzale Castagnara Piazzale Castagnara ",
      "numeroRegistro": "1568/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MICHELA PANTANO",
      "obbligato": "AUTOSYSTEM SOCIETA' DI SRTVIZI SPA"
    }, {
      "highlights": {},
      "aggId": "338c9fc0-e685-49b2-9c97-97463f9ca566",
      "idVerbale": "153342237/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632344400000,
      "targa": "EW632GT",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "1567/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "COMUNIA CESARE",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "c14074c0-0140-404d-8241-2678eb9d2b84",
      "idVerbale": "153342139/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632342900000,
      "targa": "BR967WA",
      "luogoAccertamento": "VIA NAZIONALE ",
      "numeroRegistro": "1566/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "BEQIBAJ ELSID",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "e315d5fa-16b7-4905-b0c6-b02ee2d775fa",
      "idVerbale": "153462534/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632410220000,
      "targa": "BV104CN",
      "luogoAccertamento": "sp91 sp91 ",
      "numeroRegistro": "1577/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016162021110078",
      "trasgressore": "SARTORI THOMAS",
      "obbligato": "FM TECNOSALD SRL"
    }, {
      "highlights": {},
      "aggId": "65959849-1cd3-406b-bdfc-1a3d72777efa",
      "idVerbale": "937158922/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623219300000,
      "targa": "de947nd",
      "luogoAccertamento": "VESCOVANA VIA BASSA 4 COMANDO STAZIONE CC",
      "numeroRegistro": "436/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012112021110081",
      "trasgressore": "BORGHETTO FRANCESCO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "13cd39cf-fa6a-4641-8a08-705ab5dbd83f",
      "idVerbale": "153360939/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632420503000,
      "targa": "fg585pj",
      "luogoAccertamento": "PADANA INFERIORE VIA ROMA EST ",
      "numeroRegistro": "1575/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "CHIARELLO ANDREA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "3842460f-c86e-4198-a002-98ddd6554868",
      "idVerbale": "937159128/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1624297620000,
      "targa": "AW027BV",
      "luogoAccertamento": "Via Cavour VIA cAVOUR ",
      "numeroRegistro": "572/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "FERRARA UMBERTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7f7dd02c-e804-40de-b168-fdc4c2c18b7b",
      "idVerbale": "153361037/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632430375000,
      "targa": "cz059jr",
      "luogoAccertamento": "via nazionale SS16 Via nazionale ss16 ",
      "numeroRegistro": "1576/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "el bachiri mustapha",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8b14b68e-abb7-452b-9135-4425a2551620",
      "idVerbale": "152714337/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624206300000,
      "targa": "CW249DG",
      "luogoAccertamento": "Via Maglio Via Maglio Via Maglio",
      "numeroRegistro": "560/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009272021110073",
      "trasgressore": "STEFANO TEGNER",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "8f6bce86-0595-4281-8e94-ad124b2f767b",
      "idVerbale": "153333032/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624259700000,
      "targa": "CL783AH",
      "luogoAccertamento": "VIA SANTA CROCE ",
      "numeroRegistro": "584/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015722021110082",
      "trasgressore": "SPIANDORELLO MARCO",
      "obbligato": "RUGGERI EMANUELE"
    }, {
      "highlights": {},
      "aggId": "a8a569d6-ed50-498b-b971-24d13d4ad8a4",
      "idVerbale": "153323837/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1623998700000,
      "targa": "BV314ZA",
      "luogoAccertamento": "VIA GRANZE ",
      "numeroRegistro": "543/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015782021110073",
      "trasgressore": "ALLEGRI LINDA",
      "obbligato": "ALLEGRI LUIGI"
    }, {
      "highlights": {},
      "aggId": "15eb4b42-7870-4329-82f2-98512c433643",
      "idVerbale": "152714435/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624207800000,
      "targa": "CS740VZ",
      "luogoAccertamento": "Via Maglio Via Maglio Via Maglio",
      "numeroRegistro": "561/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009262021110090",
      "trasgressore": "ROBERT DANIEL FLOAREA",
      "obbligato": "MARIA FLOAREA"
    }, {
      "highlights": {},
      "aggId": "c0d1b3e6-448b-42dd-8bed-a2cec145a49c",
      "idVerbale": "152714836/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624395000000,
      "targa": "x584wg",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "607/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009222021110065",
      "trasgressore": null,
      "obbligato": "Denis IPPINO"
    }, {
      "highlights": {},
      "aggId": "84172271-07ab-4b3b-8035-eafc2be2a77a",
      "idVerbale": "152714738/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624534200000,
      "targa": "x584wg",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "606/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009232021110048",
      "trasgressore": null,
      "obbligato": "Denis IPPINO"
    }, {
      "highlights": {},
      "aggId": "502a2a35-a849-42b5-a026-e4d7ffee4bc9",
      "idVerbale": "153351235/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624038000000,
      "targa": "X2DC68",
      "luogoAccertamento": "VIA NUOVA ",
      "numeroRegistro": "536/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015792021110056",
      "trasgressore": "MATTIAZZO MARIO",
      "obbligato": "LAIN OLGA"
    }, {
      "highlights": {},
      "aggId": "1025c2e0-add9-439d-a2bf-0267837f0fc7",
      "idVerbale": "153329332/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624226100000,
      "targa": "BY657GY",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "575/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015762021110014",
      "trasgressore": "ERRAJIFI HICHAM",
      "obbligato": "TAVIAN FEDERICA"
    }, {
      "highlights": {},
      "aggId": "f7933369-4d13-4aa3-9757-ea778f58102b",
      "idVerbale": "152777331/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1623891000000,
      "targa": "ER018RT",
      "luogoAccertamento": "CORSO AUSTRALIA CORSO AUSTRALIA ",
      "numeroRegistro": "513/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005942021110061",
      "trasgressore": "ROSSI GIOVANNI",
      "obbligato": "ROSSI FERNANDO"
    }, {
      "highlights": {},
      "aggId": "fdc084c0-9858-4a77-9146-7f933f69a22f",
      "idVerbale": "153351333/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624042500000,
      "targa": "FJ142AL",
      "luogoAccertamento": "VIA TRESTO NORD ",
      "numeroRegistro": "535/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015852021110047",
      "trasgressore": "PAROLO ADRIANO",
      "obbligato": "COSTRUZIONI STRADALI MARTINI SILVESTRO SRL"
    }, {
      "highlights": {},
      "aggId": "2f2575ca-c839-4d9d-a50e-3b19ddbc2a2b",
      "idVerbale": "152777439/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1624324500000,
      "targa": "FE788SZ",
      "luogoAccertamento": "VIA CAFFI VIA CAFFI ",
      "numeroRegistro": "588/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005932021110078",
      "trasgressore": "CUNSOLO GIACINTO",
      "obbligato": "CUNSOLO VINCENZO"
    }, {
      "highlights": {},
      "aggId": "a0fa1e25-ec8a-475d-a737-c42ad4632ea0",
      "idVerbale": "152710233/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624395420000,
      "targa": "x584wg",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "611/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009192021110023",
      "trasgressore": null,
      "obbligato": "Denis IPPINO"
    }, {
      "highlights": {},
      "aggId": "7471fb46-410c-4c14-80f2-d6064b7d5550",
      "idVerbale": "153352136/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624238640000,
      "targa": "ER785FH",
      "luogoAccertamento": "VIA SCHIAVIN ",
      "numeroRegistro": "571/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015742021110048",
      "trasgressore": "GATTOLIN LEONARDO",
      "obbligato": "MUNARO RAFFAELLA"
    }, {
      "highlights": {},
      "aggId": "e1791077-f797-4dca-988d-502dcd4c7f37",
      "idVerbale": "152618138/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632264300000,
      "targa": "FP318BS",
      "luogoAccertamento": "via Borgo Trento Trieste ",
      "numeroRegistro": "1552/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006032082021110077",
      "trasgressore": "bellotto franco martino",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "eb05edad-cdb7-41d3-9f1e-a6ada5edfcd5",
      "idVerbale": "152616639/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1624494000000,
      "targa": "bj282nx",
      "luogoAccertamento": "via via marconi ",
      "numeroRegistro": "600/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006362021110091",
      "trasgressore": "Ivan Campagnolo",
      "obbligato": "Sonia Turrin"
    }, {
      "highlights": {},
      "aggId": "9fc13285-eb0a-4450-9600-bfa971fddda4",
      "idVerbale": "153352332/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624483800000,
      "targa": "EM201ZV",
      "luogoAccertamento": "VIA NAZIONALE ",
      "numeroRegistro": "612/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015712021110006",
      "trasgressore": "BRESSAN ANDREA",
      "obbligato": "GIRARDI NIVES"
    }, {
      "highlights": {},
      "aggId": "9f56113e-66cf-4d2a-85a5-3bd94c814c0c",
      "idVerbale": "153330838/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1622324100000,
      "targa": "FT274DP",
      "luogoAccertamento": "VIA MONTE GULA ",
      "numeroRegistro": "328/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016972021110003",
      "trasgressore": "GIORIO FRANCESCO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "b1a5728e-bdc1-41b8-b50b-fb163f0a5c8b",
      "idVerbale": "152713230/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623418800000,
      "targa": "DM832EK",
      "luogoAccertamento": "Via Degli Alpini Via Degli Alpini ",
      "numeroRegistro": "455/2021",
      "preferito": false,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009362021110013",
      "trasgressore": "Said TOUGUI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "0a42a985-67cc-4e8a-813c-094517489fcf",
      "idVerbale": "153332836/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1622407800000,
      "targa": "FS973SC",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "352/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006017002021110045",
      "trasgressore": "RANCAN MATTEO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "87854c2c-a1c1-4d68-a4a8-30a83ccf82e0",
      "idVerbale": "153350833/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623686700000,
      "targa": "BZ404YH",
      "luogoAccertamento": "VIA RIVIERA ",
      "numeroRegistro": "478/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015882021110089",
      "trasgressore": "GRAJDEANU ION",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "323d83c5-833c-48d7-b2eb-786a057ccbad",
      "idVerbale": "153493234/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623172550000,
      "targa": "EK466AT",
      "luogoAccertamento": "VIA GIUSEPPE GARIBALDI ",
      "numeroRegistro": "413/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012272021110088",
      "trasgressore": "DARIO ALESSANDRO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "54a46277-2654-4c5b-bbe2-5d9b59c728f3",
      "idVerbale": "153493136/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623065700000,
      "targa": "EZ087CP",
      "luogoAccertamento": "VIA DESERTO ",
      "numeroRegistro": "412/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006012252021110029",
      "trasgressore": "MATTIELLO LUCA",
      "obbligato": "MATTIELLO DIMER"
    }, {
      "highlights": {},
      "aggId": "84ad77b0-7a85-4637-a64c-39b9b32d535a",
      "idVerbale": "152714934/U/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624395600000,
      "targa": "x584wg",
      "luogoAccertamento": "Via Giuseppe Verdi Via Verdi ",
      "numeroRegistro": "608/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009212021110082",
      "trasgressore": null,
      "obbligato": "Denis IPPINO"
    }, {
      "highlights": {},
      "aggId": "b31d9b98-4ce7-4e98-a553-2540312f8614",
      "idVerbale": "152618236/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632426029000,
      "targa": "gc478yz",
      "luogoAccertamento": "via Borgo Trento Trieste ",
      "numeroRegistro": "1579/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006032072021110001",
      "trasgressore": "Marconato Andrea",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "15816243-70a4-4901-93f8-cabd9d2266d2",
      "idVerbale": "153353135/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624676820000,
      "targa": "DP117SB",
      "luogoAccertamento": "SP 247 RIVIERA VIA RIVIERA ",
      "numeroRegistro": "649/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015662021110091",
      "trasgressore": "PIRAS VINCENZO",
      "obbligato": "ZOIA ANNA MARIA"
    }, {
      "highlights": {},
      "aggId": "0cf9b4bc-c071-4114-af14-d878a187dcdb",
      "idVerbale": "153330632/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1622077500000,
      "targa": "ez273cp",
      "luogoAccertamento": "VIA NUOVA ",
      "numeroRegistro": "300/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016942021110054",
      "trasgressore": "barbei iurie",
      "obbligato": "HRENIUC IOSIF"
    }, {
      "highlights": {},
      "aggId": "56fa160d-ed40-418e-9ad2-5d9c0a7d0ec4",
      "idVerbale": "153352831/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624663800000,
      "targa": "ES305PT",
      "luogoAccertamento": "VIA PRINCIPE UMBERTO ",
      "numeroRegistro": "655/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015612021110083",
      "trasgressore": "FERRARETTO MARIKA",
      "obbligato": "FERRARETTO  MARIANO"
    }, {
      "highlights": {},
      "aggId": "9f0923d8-c4b3-4b14-87bb-f29cf0af06c7",
      "idVerbale": "153329939/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625088900000,
      "targa": "CX679DC",
      "luogoAccertamento": "VIA ATESTE ",
      "numeroRegistro": "678/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016262021110001",
      "trasgressore": "SILVAN MARCO",
      "obbligato": "BORTOLETTO NADIA"
    }, {
      "highlights": {},
      "aggId": "1584ca8b-eef6-44ad-8008-3bcbdbcdac67",
      "idVerbale": "246561536/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624860900000,
      "targa": "FK202AX",
      "luogoAccertamento": "Corso Stati Uniti Corso Stati Uniti ",
      "numeroRegistro": "641/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006018892021110087",
      "trasgressore": "penello laura",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "659f7ae0-222b-46c4-b436-56a14ce611c7",
      "idVerbale": "153460830/U/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1625116500000,
      "targa": "GB928MG",
      "luogoAccertamento": "VIA ROMA ",
      "numeroRegistro": "675/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016102021110087",
      "trasgressore": "BASSAN PAOLO",
      "obbligato": "EUROSFUSI SRL"
    }, {
      "highlights": {},
      "aggId": "5132f1de-1dd0-498c-969e-ad0123380d49",
      "idVerbale": "153350735/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623685800000,
      "targa": "BZ040YH",
      "luogoAccertamento": "VIA RIVIERA ",
      "numeroRegistro": "477/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015932021110004",
      "trasgressore": "LAZUR ION",
      "obbligato": "GRAJDEANU ION"
    }, {
      "highlights": {},
      "aggId": "751766bc-bc27-4702-8920-7aaa97dcac46",
      "idVerbale": "153353331/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1625127900000,
      "targa": "EW202LP",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "673/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008122021110075",
      "trasgressore": "BELLAN FABIO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "20d3bd0b-ccbc-4b2d-8dca-233c0fbd0b2e",
      "idVerbale": "152807530/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1622338800000,
      "targa": "DS561MJ",
      "luogoAccertamento": "VIA BAJARDI VIA BAJARDI ",
      "numeroRegistro": "327/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006018712021110021",
      "trasgressore": "ANYANWU DANIEL UDOAMAKA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7750ca96-0caf-44e3-8f15-c76ff8ec3a31",
      "idVerbale": "153324131/V/2021",
      "statoVerbale": "pagamento_eccedente",
      "dataOraVerbale": 1624497480000,
      "targa": "DF716FG",
      "luogoAccertamento": "ADRIATICA VIA ROMA ",
      "numeroRegistro": "618/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015702021110023",
      "trasgressore": "RITROVATO GIUSEPPE",
      "obbligato": "BOLDRIN GAIA MARIA"
    }, {
      "highlights": {},
      "aggId": "47834b00-a922-4ad9-86af-a390105ce42c",
      "idVerbale": "152800332/V/2021",
      "statoVerbale": "pagamento_parziale",
      "dataOraVerbale": 1623931200000,
      "targa": "EA87365",
      "luogoAccertamento": "via trieste via trieste ",
      "numeroRegistro": "523/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019032021110035",
      "trasgressore": "MARCHELLE SANDRA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "a0ed11a9-40f8-4531-acbe-fee8a475a41f",
      "idVerbale": "153352635/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1624543560000,
      "targa": "EV175FE",
      "luogoAccertamento": "via ragazzi della folgore ",
      "numeroRegistro": "633/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006015682021110057",
      "trasgressore": "MARANGHELLO KETTY",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "f30339fe-c6ae-4604-9eca-fa24e8640d92",
      "idVerbale": "246515034/V/2021",
      "statoVerbale": "pagato",
      "dataOraVerbale": 1623882000000,
      "targa": "X87JWW",
      "luogoAccertamento": "VIA ARIOSTO VIA ARIOSTO ",
      "numeroRegistro": "512/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019022021110052",
      "trasgressore": "AMIANTI RAFFAELE",
      "obbligato": "AMIANTI FLAVIO"
    }, {
      "highlights": {},
      "aggId": "82ebffd0-9308-4a0f-bccc-b4d65ad3d7d3",
      "idVerbale": "152639632/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632553200000,
      "targa": "CH905WT",
      "luogoAccertamento": "VIA PONTAROLA VIA PONTAROLA ",
      "numeroRegistro": "1584/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006262021110075",
      "trasgressore": "TREVISAN RICCARDO",
      "obbligato": "TREVISAN ANTONIO"
    }, {
      "highlights": {},
      "aggId": "074f5da3-b23c-46f2-b41c-50665a26c265",
      "idVerbale": "153363838/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632528300000,
      "targa": "BD639SL",
      "luogoAccertamento": "VIA PRA' ",
      "numeroRegistro": "1583/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "PICELLI MATTEO",
      "obbligato": "PICELLI STEFANO"
    }, {
      "highlights": {},
      "aggId": "7a9d9d4c-71ce-402e-ab2c-c512cb839246",
      "idVerbale": "153361135/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632552600000,
      "targa": "EW637WX",
      "luogoAccertamento": "SP 247 RIVIERA VIA RIVIERA ",
      "numeroRegistro": "1586/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "VIGATO MATTEO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "ad70ecf1-3261-40b7-8a81-7821e24508f1",
      "idVerbale": "153361233/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632560880000,
      "targa": "DG621VL",
      "luogoAccertamento": "via nazionale SS16 Via nazionale ss16 ",
      "numeroRegistro": "1587/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "EL QAZBOURI HICHAM",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9ac7b07b-a953-4c52-acb6-6b164ca6ccfb",
      "idVerbale": "153363936/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632568529000,
      "targa": "AY428KA",
      "luogoAccertamento": "via BELLE ",
      "numeroRegistro": "1590/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "VASCON LORENZO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "41404540-4d52-4ffe-9e1d-7298e70d0dd6",
      "idVerbale": "153361331/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632614160000,
      "targa": "ES634PW",
      "luogoAccertamento": "VIA AUGUSTEA VIA AUGUSTEA ",
      "numeroRegistro": "1597/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MORO FRANCESCA",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "9ae6b04a-9209-44a2-8602-1a9f303a6b92",
      "idVerbale": "153364132/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632642900000,
      "targa": "AY428KA",
      "luogoAccertamento": "via BELLE ",
      "numeroRegistro": "1602/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "VASCON LORENZO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "cdfb8ece-6289-4d81-ab0b-c35ef9212d25",
      "idVerbale": "152710439/V/2021",
      "statoVerbale": "ricorso",
      "dataOraVerbale": 1624751700000,
      "targa": "CE205WG",
      "luogoAccertamento": "SS53 Via Dante SS53 Via Dante SS53 Via Dante",
      "numeroRegistro": "629/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006009182021110040",
      "trasgressore": "NICOLIN Alessio",
      "obbligato": "DE ROSSI Elena"
    }, {
      "highlights": {},
      "aggId": "f9378354-8ae5-424d-a225-5e32403610d1",
      "idVerbale": "152889933/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631736000000,
      "targa": "ca307cf",
      "luogoAccertamento": "Brusegana via dei colli ",
      "numeroRegistro": "1497/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006010132021110006",
      "trasgressore": "Razvan Constantin STAICU",
      "obbligato": "staicu camelin"
    }, {
      "highlights": {},
      "aggId": "cfbee1f8-1504-46f3-89e4-4395c0cdc33a",
      "idVerbale": "152639437/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1630848600000,
      "targa": "FD218GB",
      "luogoAccertamento": "VIA HERMAGOR 44 VIA HERMAGOR 44 ",
      "numeroRegistro": "1603/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006006282021110041",
      "trasgressore": "AGOSTINI ALBERTO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "36d742a5-6ca4-4bf2-a5c1-328f3bc17ea6",
      "idVerbale": "153364230/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632699628000,
      "targa": "FE302EP",
      "luogoAccertamento": "VIA ROARO ",
      "numeroRegistro": "1607/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "VALARIN ANDREA",
      "obbligato": "VALARIN GIULIANA"
    }, {
      "highlights": {},
      "aggId": "0d3bf156-7205-461f-9646-f699152788ad",
      "idVerbale": "152594430/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631780756000,
      "targa": "CW29883",
      "luogoAccertamento": "viale della vittoria 23 viale della vittoria  ",
      "numeroRegistro": "1612/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "D'ATARDI SALVATORE",
      "obbligato": "D'ESTE ARIANNA"
    }, {
      "highlights": {},
      "aggId": "91fcdf19-4023-462d-a15e-ee06cea6da7b",
      "idVerbale": "152554036/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632722400000,
      "targa": "DS232LS",
      "luogoAccertamento": "via ca' zorzi ",
      "numeroRegistro": "1614/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006005562021110056",
      "trasgressore": "NAYARU SAMISONI",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "4302f16f-d669-4f9a-a078-02247d010602",
      "idVerbale": "153440238/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632717000000,
      "targa": "BK237SA",
      "luogoAccertamento": "Via Delle Industrie Via Delle Industrie ",
      "numeroRegistro": "1623/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006008182021110066",
      "trasgressore": "TATULESCU RAUL ANDREI",
      "obbligato": "TATULESCU IULIANA GINA"
    }, {
      "highlights": {},
      "aggId": "9112ea74-607d-41f3-805c-170f28f37351",
      "idVerbale": "152890137/U/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1631548852000,
      "targa": "NO",
      "luogoAccertamento": "via Scapacchiò Ovest ",
      "numeroRegistro": "1624/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "LIETA LEVORIN",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "541448b8-6fd4-4f0e-87c8-a5d20da10aa2",
      "idVerbale": "152801037/V/2021",
      "statoVerbale": "lotto_non_modificabile",
      "dataOraVerbale": 1625661600000,
      "targa": "fd165zw",
      "luogoAccertamento": "Via Ansuino da forli' ",
      "numeroRegistro": "748/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019082021110043",
      "trasgressore": "Vezzu' Simone",
      "obbligato": "L'unitaria Logistica Società Cooperativa"
    }, {
      "highlights": {},
      "aggId": "d40f1a32-1db8-4a24-8322-713479743dbc",
      "idVerbale": "152801830/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1626657000000,
      "targa": "DC006LM",
      "luogoAccertamento": "Via Tommaseo Via Tommaseo ",
      "numeroRegistro": "874/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019392021110074",
      "trasgressore": "dela cruz advincula maria virginia",
      "obbligato": "DELA CRUZ ROLAND NALITAN"
    }, {
      "highlights": {},
      "aggId": "832030c9-447c-4d9f-827c-2cd6c533f1f7",
      "idVerbale": "152777536/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1624760700000,
      "targa": "EJ49587",
      "luogoAccertamento": "VIA ZARA VIA ZARA ",
      "numeroRegistro": "638/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006019192021110042",
      "trasgressore": "VERNEAU CHRISTOPHE ANDRE ANTONY",
      "obbligato": "PIOVAN BARBARA"
    }, {
      "highlights": {},
      "aggId": "2f028485-0e00-4aeb-98b8-6009760265f8",
      "idVerbale": "152801233/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1626159300000,
      "targa": "by729jn",
      "luogoAccertamento": "via annibale da bassano via annibale da bassano ",
      "numeroRegistro": "815/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "guidi elena",
      "obbligato": "guidi franco"
    }, {
      "highlights": {},
      "aggId": "b16fe879-c797-4034-b674-2cf1cc806b83",
      "idVerbale": "152801634/V/2021",
      "statoVerbale": "notificato_trasgressore",
      "dataOraVerbale": 1626521700000,
      "targa": "cv612ss",
      "luogoAccertamento": "Via Falloppio ",
      "numeroRegistro": "860/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006014942021110013",
      "trasgressore": "obayagbona isoken",
      "obbligato": "tono arcangela"
    }, {
      "highlights": {},
      "aggId": "ef98b065-e19e-4b1f-874e-38757b730645",
      "idVerbale": "153364436/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632841200000,
      "targa": "DS898FJ",
      "luogoAccertamento": "VIA PALLADIO ",
      "numeroRegistro": "1629/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STEPICH DARIO",
      "obbligato": "COLOMBO GIANGIACOMO"
    }, {
      "highlights": {},
      "aggId": "bdfbe084-15ca-4ab4-a571-23026fdb2cb5",
      "idVerbale": "153364631/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632843600000,
      "targa": "DS898FJ",
      "luogoAccertamento": "VIA PALLADIO ",
      "numeroRegistro": "1631/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STEPICH DARIO",
      "obbligato": "COLOMBO GIANGIACOMO"
    }, {
      "highlights": {},
      "aggId": "b50130d4-6893-47d0-bb2d-5b9a66d5eb12",
      "idVerbale": "153364739/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632844200000,
      "targa": "DS898FJ",
      "luogoAccertamento": "VIA PALLADIO ",
      "numeroRegistro": "1632/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STEPICH DARIO",
      "obbligato": "COLOMBO GIANGIACOMO"
    }, {
      "highlights": {},
      "aggId": "0777c23e-60c2-481e-a408-c5f0e6248fd7",
      "idVerbale": "153364533/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632842400000,
      "targa": "DS898FJ",
      "luogoAccertamento": "VIA PALLADIO ",
      "numeroRegistro": "1630/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STEPICH DARIO",
      "obbligato": "COLOMBO GIANGIACOMO"
    }, {
      "highlights": {},
      "aggId": "f748dcc1-cf0d-4a4d-a786-fbe909452c45",
      "idVerbale": "153364837/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632843600000,
      "targa": "DS898FJ",
      "luogoAccertamento": "VIA PALLADIO ",
      "numeroRegistro": "1633/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STEPICH DARIO",
      "obbligato": "COLOMBO GIANGIACOMO"
    }, {
      "highlights": {},
      "aggId": "df94695f-d82e-425f-b2a7-90a52970eb3a",
      "idVerbale": "195181535/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632852240000,
      "targa": "fk203cn",
      "luogoAccertamento": "via Cristoforo colombo ",
      "numeroRegistro": "1636/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "MINGARDO PAOLO",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "09322aea-cff9-42f2-ae58-8c7d1eed9076",
      "idVerbale": "153364338/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1632839400000,
      "targa": "DS898FJ",
      "luogoAccertamento": "VIA PALLADIO ",
      "numeroRegistro": "1638/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "STEPICH DARIO",
      "obbligato": "COLOMBO GIANGIACOMO"
    }, {
      "highlights": {},
      "aggId": "cf926b63-06f9-4751-8692-cfedefbb2374",
      "idVerbale": "153364935/U/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1632848400000,
      "targa": "DS898FJ",
      "luogoAccertamento": "VIA STAZIE BRAGADINE ",
      "numeroRegistro": "1639/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "sconosciuto",
      "trasgressore": "VILLANI FRANCESCO",
      "obbligato": "COLOMBO GIANGIACOMO"
    }, {
      "highlights": {},
      "aggId": "72c8f323-787c-4df4-98fd-b6075ce39db2",
      "idVerbale": "1533291136/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1623977100000,
      "targa": "DS050VD",
      "luogoAccertamento": "VIA CONCETTO MARCHESI ",
      "numeroRegistro": "529/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "",
      "trasgressore": "FIORENTINI MICHAEL",
      "obbligato": null
    }, {
      "highlights": {},
      "aggId": "7ad308fa-2c72-479a-8b26-416c19ff9dd7",
      "idVerbale": "1533333531/V/2021",
      "statoVerbale": "pronto",
      "dataOraVerbale": 1624117560000,
      "targa": "FL670LF",
      "luogoAccertamento": "SP 247 RIVIERA VIA RIVIERA ",
      "numeroRegistro": "661/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006016312021110009",
      "trasgressore": "BELLIN GIUSEPPE",
      "obbligato": "BELLIN COSTRUZIONI SRL"
    }, {
      "highlights": {},
      "aggId": "934fb5b4-4286-46df-b4d7-3bedd5497a7d",
      "idVerbale": "1533784138/V/2021",
      "statoVerbale": "notificato",
      "dataOraVerbale": 1622207700000,
      "targa": "FH048DJ",
      "luogoAccertamento": "VIA LOVARA ",
      "numeroRegistro": "312/2021",
      "preferito": null,
      "ambito": null,
      "stazioneComandoId": null,
      "gruppoComandoId": null,
      "quarto_campo": "006000612021110008",
      "trasgressore": "SESTA FORTUNATO",
      "obbligato": null
    }],
    "count": 906,
    "links": []
  },
  "filters": []
};
exports.verbale_ricercaVeloce = verbale_ricercaVeloce;