"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFreeSearchReactQuery = exports._freeSearchState = void 0;

var _recoil = require("recoil");

var _react = require("react");

var _reactQuery = require("react-query");

var _quickSearch = require("./quickSearch");

var _utils = require("../../react/reactQuery/utils");

var _messages = require("../global/messages");

// Free search
const defaultFreeSearchState = {
  query: "",
  page: 1,
  limit: 10
};

const _freeSearchState = (0, _recoil.atomFamily)({
  key: '_freeSearchState',
  default: ({
    params
  }) => {
    return { ...defaultFreeSearchState,
      params
    };
  }
});

exports._freeSearchState = _freeSearchState;

const useFreeSearchReactQuery = ({
  apiCallback
}) => ({
  resourceKeys,
  name,
  page: pageProp,
  limit: limitProp,
  query: queryProp,
  minLength: minLengthProp
}) => {
  const setMessage = (0, _recoil.useSetRecoilState)(_messages.messagesStack);

  const messenger = message => setMessage((0, _messages.pushMessage)(message));

  const queryClient = (0, _reactQuery.useQueryClient)();
  const ns = (0, _quickSearch.getQsNs)(name);
  const [{
    page,
    limit,
    query
  }, setState] = (0, _recoil.useRecoilState)(_freeSearchState({
    name: ns,
    pageProp,
    limitProp,
    queryProp
  }));
  (0, _react.useEffect)(() => {
    window.$transitions.onExit({
      exiting: `navigation.${name}List`
    }, (_transition, _state) => {
      queryClient.removeQueries(resourceKeys.all());
    });
  });
  const {
    data,
    isLoading,
    isFetching
  } = (0, _reactQuery.useQuery)(resourceKeys.freeSearch({
    page,
    limit,
    query
  }), ({
    queryKey
  }) => {
    const keys = queryKey[2];
    return apiCallback({
      page: keys.page,
      limit: keys.limit,
      query: keys.query
    });
  }, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(query) && query.length >= minLengthProp,
    onSuccess: (0, _utils.handleSuccess)(messenger),
    onError: response => (0, _utils.handleError)(messenger)(response),
    select: response => response.error ? response : (response === null || response === void 0 ? void 0 : response.resultsWrapper) || []
  });
  return {
    items: data ? data.values : [],
    count: data ? data.count : 0,
    isLoading: isLoading || isFetching,
    page,
    limit,
    query,
    changePage: newPage => setState(state => ({ ...state,
      page: newPage
    })),
    changeLimit: newLimit => setState(state => ({ ...state,
      page: 1,
      limit: newLimit
    })),
    changeQuery: newQuery => setState(state => ({ ...state,
      page: 1,
      query: newQuery
    })),
    refresh: () => queryClient.invalidateQueries(resourceKeys.all()),
    reset: () => setState(state => ({ ...state,
      ...defaultFreeSearchState
    }))
  };
};

exports.useFreeSearchReactQuery = useFreeSearchReactQuery;