"use strict";

/**
 * Created by dan on 06/12/16.
 */
(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name api
   * @module polcity.api
   *
   * @description Wrapper on <code>$http</code> angular service.
   *
   */

  angular.module('polcity.api').service('api', ['$http', api]);

  function api($http) {
    return {
      perform: perform,
      // utility method to uniform Command and api APIs
      execute: uniformPerform
    };

    function uniformPerform(request) {
      return perform(request).then(R.prop('data'));
    }
    /**
     * @ngdoc method
     * @name api#perform
     *
     * @description
     * It's the only API exposed from the service, will call the <code>$http</code> service.
     *
     * @param {object} request Same object you would pass to <code>$http</code> service
     *
     * @return {promise}
     */


    function perform(request) {
      return $http(request);
    }
  }
})();