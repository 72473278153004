"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNewModelloStampa = exports.currentVariabiliDiStampaQueryState = exports.currentModelloStampaFormState = exports.currentModelloStampaTrigger = exports.currentModelloStampaAggIdState = void 0;

var _recoil = require("recoil");

const currentModelloStampaAggIdState = (0, _recoil.atom)({
  key: 'currentModelloStampaAggId',
  default: null
});
exports.currentModelloStampaAggIdState = currentModelloStampaAggIdState;
const currentModelloStampaTrigger = (0, _recoil.atom)({
  key: 'currentModelloStampaTrigger',
  default: 0
});
exports.currentModelloStampaTrigger = currentModelloStampaTrigger;
const currentModelloStampaFormState = (0, _recoil.atom)({
  key: 'currentModelloStampaForm',
  default: {
    languageTag: '',
    modulo: '',
    funzione: '',
    inUso: true,
    codice: '',
    sottoCodiceVeloce: '',
    descrizione: '',
    xml: '',
    rtf: ''
  }
});
exports.currentModelloStampaFormState = currentModelloStampaFormState;
const currentVariabiliDiStampaQueryState = (0, _recoil.atom)({
  key: 'currentVariabiliDiStampaQueryState',
  default: ''
});
exports.currentVariabiliDiStampaQueryState = currentVariabiliDiStampaQueryState;
const isNewModelloStampa = (0, _recoil.atom)({
  key: 'isNewModelloStampa',
  default: undefined
});
exports.isNewModelloStampa = isNewModelloStampa;