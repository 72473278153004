"use strict";

/**
 * Created by gb on 11/09/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.ricorso').controller('RicorsoController', ['Controdeduzione', '$scope', '$state', '$stateParams', 'Command', 'OSNotification', RicorsoController]);

  function RicorsoController(Controdeduzione, $scope, $state, $stateParams, Command, OSNotification) {
    var me = this;
    /**
     * Variabili globali
     * @type {RicorsiFormController}
     */

    me.aggId = $stateParams.aggId;
    me.presentatoA;
    me.tipoRicorso;
    me.modalitaPresentazione;
    me.isLoadingControdeduzioni = false;
    me.isLoadingCronologiaStati = false;
    me.controdeduzioni = Controdeduzione.$collection({
      page: 1,
      limit: 10
    });

    me.$onInit = function () {
      me.activeTab = $stateParams.subView; //Carico le controdeduzioni e aggiungo gli handler agli eventi

      me.controdeduzioni.$on("before-fetch-many", function (res) {
        me.isLoadingControdeduzioni = true;
      });
      me.controdeduzioni.$on("after-fetch-many", function (res) {
        me.isLoadingControdeduzioni = false;
      });
      me.controdeduzioni.$fetch({
        aggId: me.ricorso.aggId
      }); //Carico la cronologia degli stati e aggiungo gli handler agli eventi

      me.ricorso.$on("before-cronologia-stati", function (res) {
        me.isLoadingCronologiaStati = true;
      });
      me.ricorso.$on("after-cronologia-stati", function (res) {
        me.isLoadingCronologiaStati = false;
      });
      me.ricorso.$on("after-cronologia-stati-error", function (res) {
        me.isLoadingCronologiaStati = false;
      });
      $scope.$watch(() => me.ricorso.$resolved, (newValue, oldValue) => {
        //Chiamata ogni volta che viene caricato con successo il ricorso
        if (newValue === true) {
          me.ricorso.$getCronologiaStati(); //Chiama il be e aggiunge al ricorso la property $cronologiaStati 
        }
      });
    }; //Editabile solo in stato PRESENTATO


    me.isEditable = function () {
      return me.ricorso.$lastState === "PRESENTATO";
    }; //TRASMISSIBILE solo in stato PRESENTATO


    me.isTransmittable = function () {
      return me.ricorso.$lastState === "PRESENTATO";
    }; //Si può caricare un esito solo negli stati diversi da presentato


    me.canAddEsito = function () {
      return me.ricorso.$lastState != "PRESENTATO";
    }; //E' possibile annullare la trasmissione solo in stato TRASMETTO


    me.canUndoTrasmission = function () {
      return me.ricorso.$lastState == "TRASMESSO";
    }; //Un ricorso è "ritentabile" se:
    //1) è stato fatto al PREFETTO
    //2) si trova in stato PARZIALMENTE ACCOLTO o RESPINTO


    me.canRitentaRicorso = function () {
      var canRitentaRicorso = false;

      if (me.ricorso.ente.tipoEnte === "PREFETTURA") {
        if (me.ricorso.$lastState === "PARZIALMENTE_ACCOLTO" || me.ricorso.$lastState === "RESPINTO") {
          canRitentaRicorso = true;
        }
      }

      return canRitentaRicorso;
    }; //L'esito di ricorso è annullabile sono se non è in stato TRASMESSO o PRESENTATO


    me.isUndoable = function () {
      var lastState = me.ricorso.$lastState;
      return lastState != "PRESENTATO" && lastState != "TRASMESSO";
    }; //Gestione controdeduzioni


    me.onAggiungiControdeduzione = function () {
      $state.go(".controdeduzione", {
        ricorso: me.ricorso
      });
    };

    me.onModificaControdeduzione = function (controdeduzione) {
      $state.go(".controdeduzione", {
        ricorso: me.ricorso,
        controdeduzione: controdeduzione,
        controdeduzioneAggId: controdeduzione.controdeduzioneId
      });
    };

    me.onEliminaControdeduzione = function (controdeduzione) {
      eliminaControdeduzione(controdeduzione).$then(function (res) {
        notifyUser('success', 'ricorso.controdeduzioneDeleteSuccess');
      }, function (err) {
        notifyUser('error', 'ricorso.controdeduzioneDeleteError');
      });
    }; //END - Gestione controdeduzioni
    //Gestione esiti


    me.onAggiungiEsito = function () {
      $state.go(".esito", {
        ricorso: me.ricorso
      });
    };

    me.onAnnullaEsito = function () {
      annullaEsito(me.ricorso).$asPromise().then(function (res) {
        notifyUser('success', 'ricorso.ricorsoAnnullaEsitoSuccess');
        me.ricorso.$refresh();
      }).catch(function (err) {
        notifyUser('error', 'ricorso.ricorsoAnnullaEsitoError');
      }).finally(function () {});
    }; //END - Gestione esiti
    //Gestione trasmissione


    me.onTrasmettiRicorso = function () {
      trasmettiRicorso(me.ricorso).$then(function (res) {
        notifyUser('success', 'ricorso.ricorsoTrasmettiSuccess');
        me.ricorso.$refresh();
      }, function (err) {
        notifyUser('error', 'ricorso.ricorsoTrasmettiError');
      });
    };

    me.onAnnullaTrasmissione = function () {
      annullaTrasmissione(me.ricorso).$then(function (res) {
        notifyUser('success', 'ricorso.annullaTrasmissioneSuccess');
        me.ricorso.$refresh();
      }, function (err) {
        notifyUser('error', 'ricorso.annullaTrasmissioneError');
      });
    }; //END - Gestione trasmissione
    //Gestione fascicolo


    me.onGeneraFascicolo = function () {
      $state.go(".generaFascicolo", {
        ricorso: me.ricorso
      });
    }; //END - Gestione fascicolo
    //Gestione ritenta ricorso


    me.onRitentaRicorso = function () {
      $state.go(".ritentaRicorso", {
        ricorso: me.ricorso
      });
    }; //END - Gestione ritenta ricorso


    function notifyUser(type, msg) {
      OSNotification[type]({
        translationIndex: msg
      });
    }

    ; //Commands

    function annullaEsito(ricorso) {
      return ricorso.$annullaEsitoRicorso();
    }

    function trasmettiRicorso(ricorso) {
      return ricorso.$trasmetti();
    }

    ;

    function annullaTrasmissione(ricorso) {
      return ricorso.$annullaTrasmissione();
    }

    ;

    function eliminaControdeduzione(controdeduzione) {
      return controdeduzione.$destroy();
    }

    ;
  }
})();