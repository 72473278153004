"use strict";

/**
 * Created by MN 16/12/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('compareBilanci', {
    templateUrl: 'osContabilita/bilancio/compareBilanci/compareBilanci.component.html',
    controller: 'CompareBilanciController',
    controllerAs: 'compareBilanciCtrl'
  });
})();