"use strict";

/**
 * Created by stefano on 17/05/17.
 */
(function () {
  'use strict';
  /**
   * @ngdoc type
   * @name OSNotification
   * @module polcity.utils
   * @description
   *
   * Consente di visualizzare le notifiche in maniera molto semplice
   *
   * E' possibile visualizzare tre tipi di messaggio, chiamando le rispettive funzioni:
   * <ul>
   * <li>OSNotification.<b>success()</b></li>
   * <li>OSNotification.<b>warn()</b></li>
   * <li>OSNotification.<b>error()</b></li>
   * <li>OSNotification.<b>confirmDelete(props)</b></li>
   * </ul>
   *
   * E' necessario passare un oggetto per indicare le proprietà da visualizzare nella notifica; è possibile
   * indicare un messaggio diretto tramite la propriertà <i>message</i>
   *
   * <hljs lang="js">
   * OSNotification.success({
   *  message: 'Il mio messaggio'
   * });
   * </hljs>
   *
   * Altrimenti è possibile indicare la label che verrà tradotta in automatico prima di essere visualizzata nella
   * notifica.
   *
   * <hljs lang="js">
   * OSNotification.success({
   *  translationIndex: 'verbali.title__section',
   *  translateParam: {} // Opzionale, da usare in caso la traduzione necessiti di parametri
   * });
   * </hljs>
   *
   * <hljs lang="js">
   * OSNotification.confirmDelete({
   *  title: '',
   *  titleIndex: 'verbali.title__section',
   *  titleParams: {},
   *  message:'',
   *  messageIndex: 'verbali.title__section',
   *  messageParam: {}
   * }).then(ok, cancel);
   * </hljs>
   *
   * @usage
   *
   * <hljs lang="js">
   *  angular
   *  .module('__module__')
   *  .controller('__controller', ['OSNotification', __controller);
   *
   *      function __controller(OSNotification) {
   *          OSNotification.success({
   *              message: 'Funziona'
   *          });
   *      }
   *  ]);
   * </hljs>
   **/

  angular.module('polcity.services').constant('NotificationTypes', {
    success: 'success',
    info: 'info',
    loading: 'loading',
    warning: 'warning',
    error: 'error'
  }).factory('OSNotification', ['$rootScope', 'uiEvents', 'NotificationTypes', '$q', '$translate', OSNotification]);

  function OSNotification($rootScope, uiEvents, NotificationTypes, $q, $translate) {
    return {
      success: success,
      warn: warn,
      error: error,
      info: info //confirmDelete: confirmDelete

    }; /////////////////////////
    // Implementations

    function success(properties) {
      return _emitShow(R.merge(properties, {
        isSuccess: true,
        type: NotificationTypes.success
      }));
    }

    function info(properties) {
      return _emitShow(R.merge(properties, {
        isSuccess: true,
        type: NotificationTypes.info
      }));
    }

    function warn(properties) {
      return _emitShow(R.merge(properties, {
        isSuccess: true,
        type: NotificationTypes.warning
      }));
    }

    function error(properties) {
      return _emitShow(R.merge(properties, {
        isSuccess: false,
        type: NotificationTypes.error
      }));
    }

    function _emitShow(properties) {
      var event = R.has('translationIndex')(properties) ? uiEvents.showNotificationFromTranslation : uiEvents.showToast;
      $rootScope.$emit(event, properties);
    }
  }
})();