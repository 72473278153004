"use strict";

(function () {
  'use strict';

  angular.module('polcity.dashboard').controller('WidgetStatisticheLotti', ['$scope', 'api', '$state', WidgetStatisticheLotti]);

  function WidgetStatisticheLotti($scope, api, $state) {
    var me = this;

    me.controllaEsiti = function () {
      generaEsitiSpedizioniRandom().then(loadStatisticheLotto);
    };

    me.onChartClick = function (points, evt) {};

    $scope.statisticheOptions = {
      responsive: true,
      maintainAspectRatio: false
    };
    loadStatisticheLotto();
    /*********************
     * Utils
     *********************/

    function loadStatisticheLotto() {
      fetchStatisticheLotto().then(function (result) {
        var statistiche = elaboraStatisticheLotto(result);
        setStatistiche(statistiche);
      });
    }
    /**
     * Elabora le statistiche per creare il dataset da utilizzare
     * nel grafico
     */


    function elaboraStatisticheLotto(statistiche) {
      var result = {
        labels: [],
        series: ["Senza Esito", "Notificate", "Esito non notificato"],
        data: [[], [], []]
      };
      R.map(function (statistica) {
        result.labels.push("Lotto " + statistica.numeroLotto);
        result.data[0].push(statistica.numeroSpedizoniSenzaEsito);
        result.data[1].push(statistica.numeroSpedizioniNotificate);
        result.data[2].push(statistica.numeroSpedizioniEsitoNonNotificato);
      }, statistiche);
      return result;
    }

    ;
    /**********************
     * Setters
     **********************/

    /**
     * Valorizza le variabili dello scope che servono alla direttiva
     * per renderizzare il grafico
     * @param statistiche
     */

    function setStatistiche(statistiche) {
      $scope.statisticheLottiLabels = statistiche.labels;
      $scope.statisticheLottiSeries = statistiche.series;
      $scope.statisticheLottiData = statistiche.data;
    }

    ;
    /*************************
     * Fetchers & Apis
     *************************/

    function generaEsitiSpedizioniRandom() {
      return api.execute({
        method: 'POST',
        url: 'lotto/comando/demo-genera-esiti-random'
      });
    }
    /**
     * Recupera le statistiche dei lotti dal server
     */


    function fetchStatisticheLotto() {
      return api.execute({
        method: 'GET',
        url: 'dashboard/ricerca/spedizioni-per-lotto'
      });
    }

    ;
  }
})();