"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VerbaleMappa = void 0;

var _index = require("angular2react/index");

var _lazyInjector = require("../../lazyInjector");

var _utils = require("../../utils");

const MappaCfg = {
  templateUrl: 'verbale/datiPrincipali/mappa.view.html',
  controllerAs: 'MappaController',
  controller: 'MappaController',
  bindings: {
    open: '<',
    close: '<',
    lat: '<',
    lon: '<'
  }
};
angular.module('polcity.verbali').component('maPpa', MappaCfg).controller('MappaController', ['$scope', '$mdDialog', 'Command', MappaController]);

function MappaController(scope, $mdDialog, Command) {
  var me = this;

  me.$onInit = () => {};

  scope.onClose = () => {
    me.close();
  };

  me.$onChanges = ({
    open,
    lat,
    lon
  }) => {
    if (open) {
      scope.open = open.currentValue;
    }

    if (lat) {
      scope.lat = lat.currentValue;
    }

    if (lon) {
      scope.lon = lon.currentValue;
    }
  };

  scope.$watch('verbale', verbale => {}, true);
}

;
const VerbaleMappa = (0, _index.angular2react)('maPpa', MappaCfg, _lazyInjector.lazyInjector.$injector);
exports.VerbaleMappa = VerbaleMappa;