"use strict";

/**
 * Created by MN 16/05/2019
 */
(function () {
  'use strict';

  angular.module('osFramework').service('rolesGuardService', ['Auth', 'ROLES_ACTIONS', RolesGuardService]).constant('ROLES_ACTIONS', {
    lista: 'LISTA',
    ricerca: 'RICERCA',
    aggiungi: 'AGGIUNGI',
    dettaglio: 'DETTAGLIO',
    modifica: 'MODIFICA',
    cancella: 'CANCELLA'
  });

  function RolesGuardService(Auth, ROLES_ACTIONS) {
    var allUserRoles = retrieveUserRoles();
    return {
      getRoles: getRoles,
      userHasRole: userHasRole,
      userHasGroupRoles: userHasGroupRoles
    };

    function getRoles() {
      return allUserRoles;
    }

    function userHasRole(role) {
      if (role === 'notApplyRoleManagement') {
        return true;
      }

      return R.contains(role, allUserRoles);
    }

    function userHasGroupRoles(roles) {
      var result = true;

      for (var r of roles) {
        if (!userHasRole(r)) {
          result = false;
          break;
        } else {
          result = true;
        }
      }

      return result;
    }
    /**
     *********************************************************************************************************************
     ***********************************************  UTILS  *************************************************************
     *********************************************************************************************************************/

    /**
     * METODO CHE RECUPERA TUTTI I RUOLI DELL'UTENTE LOGGATO TRAMITE LE FUNZIONI DI KEYCLOAK
     */


    function retrieveUserRoles() {
      return Auth.getAuth().authz.tokenParsed.realm_access.roles;
    }
  }
})();