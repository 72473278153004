"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SoggettoValidationSchema = void 0;

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const SoggettoValidationSchema = Yup.object().shape({
  tipoAnagrafica: Yup.string().required('Questo campo è obbligatorio'),
  ragioneSociale: Yup.string().when('tipoAnagrafica', {
    is: value => value && value === 'figura_giuridica',
    then: Yup.string().required('Questo campo è obbligatorio'),
    otherwise: Yup.string()
  }),
  indirizzoSedeLegale: Yup.string().when('tipoAnagrafica', {
    is: value => value && value === 'figura_giuridica',
    then: Yup.string().required('Questo campo è obbligatorio'),
    otherwise: Yup.string()
  }),
  comuneSedeLegale: Yup.object().when('tipoAnagrafica', {
    is: value => value && value === 'figura_giuridica',
    then: Yup.object().required('Questo campo è obbligatorio').nullable(),
    otherwise: Yup.object().nullable()
  }),
  nome: Yup.string().when('tipoAnagrafica', {
    is: value => value && value === 'persona_fisica',
    then: Yup.string().required('Questo campo è obbligatorio'),
    otherwise: Yup.string()
  }),
  cognome: Yup.string().when('tipoAnagrafica', {
    is: value => value && value === 'persona_fisica',
    then: Yup.string().required('Questo campo è obbligatorio'),
    otherwise: Yup.string()
  }),
  dataNascita: Yup.date().when("tipoAnagrafica", {
    is: value => value && value === 'persona_fisica',
    then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(_utils.requiredMessage).nullable().typeError('Data non valida'),
    otherwise: Yup.date().nullable()
  }),
  comuneNascita: Yup.object().when('tipoAnagrafica', {
    is: value => value && value === 'persona_fisica',
    then: Yup.object().required('Questo campo è obbligatorio').nullable(),
    otherwise: Yup.object().nullable()
  }),
  indirizzoResidenza: Yup.string().when('tipoAnagrafica', {
    is: value => value && value === 'persona_fisica',
    then: Yup.string().required('Questo campo è obbligatorio'),
    otherwise: Yup.string()
  }),
  comuneResidenza: Yup.object().when('tipoAnagrafica', {
    is: value => value && value === 'persona_fisica',
    then: Yup.object().required('Questo campo è obbligatorio').nullable(),
    otherwise: Yup.object().nullable()
  }),
  cfPi: Yup.string().when("tipoAnagrafica", (tipoAnagrafica, schema) => {
    return tipoAnagrafica == "persona_fisica" ? schema.matches(_utils.cfRegExp, "Il campo sembra malformato") : schema.matches(_utils.pivaRegExp, "Il campo sembra malformato");
  })
});
exports.SoggettoValidationSchema = SoggettoValidationSchema;