"use strict";

/**
 * Created by MN 26/09/2018
 */
(function () {
  'use strict';

  angular.module('polcity.tds').provider('luoghiRicoveroInfortunatiTdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

  function routeProvider(routesProviderFactory) {
    this.routes = [
      /*{
          isBase      : false,
          isLogin     : false,
          name        : 'luoghiRicoveroInfortunatiTds',
          url         : 'tds/luoghiRicoveroInfortunati',
          role        : 'ROLE_RICOVERO_INFORTUNATI_RICERCA',
          templateUrl : 'tds/tabelle/luoghiRicoveroInfortunati/luoghiRicoveroInfortunati.list.html',
          controller  : 'LuoghiRicoveroInfortunatiListController',
          controllerAs: 'luoghiRicoveroInfortunatiListCtrl',
          sectionScope: 'tds.luoghiRicoveroInfortunati.luoghiRicoveroInfortunatiTds__text',
      },
      {
          isBase      : false,
          isLogin     : false,
          name        : 'luoghiRicoveroInfortunatiTdsEdit',
          url         : 'tds/luoghiRicoveroInfortunati/{aggId}',
          role        : 'ROLE_RICOVERO_INFORTUNATI_MODIFICA',
          params      : {aggId: null},
          templateUrl : 'tds/tabelle/luoghiRicoveroInfortunati/edit/luoghiRicoveroInfortunati.form.html',
          controller  : 'LuoghiRicoveroInfortunatiFormController',
          controllerAs: 'luoghiRicoveroInfortunatiFormCtrl',
          sectionScope: 'tds.luoghiRicoveroInfortunati.luoghiRicoveroInfortunatiTds__text',
      }*/
    ];
    routesProviderFactory.extend(this);
  }
})();