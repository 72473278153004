"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Lotto', ['restmod', function (restmod) {
    return restmod.model('lotto').mix({
      spedizioni: {
        hasMany: 'Spedizione'
      }
    }, {
      $extend: {
        Scope: {
          $config: {
            name: 'lotti',
            baseEntityPath: 'lotto/',
            api: {
              detail: {
                url: 'dettaglio/lotto',
                method: 'POST'
              },
              destroy: {
                url: 'comando/cancella',
                method: 'POST'
              },
              saveAutomatico: {
                url: 'comando/nuovo',
                method: 'POST'
              },
              saveManuale: {
                url: 'comando/nuovo-manuale',
                method: 'POST'
              },
              edit: {
                url: 'comando/modifica',
                method: 'POST'
              },
              forzaInvio: {
                url: 'comando/forza-invio-v2',
                method: 'POST'
              },
              forzaInvioSinPostel: {
                url: 'comando/forza-invio-sin-postel',
                method: 'POST'
              },
              marcaInvio: {
                url: 'comando/marca-invio',
                method: 'POST'
              },
              downloadArchivioSinPostel: {
                url: 'comando/download-archivio-sin-postel',
                method: 'GET'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'RicercaVeloce', 'Pageable', 'Filterable', 'Sortable', 'Selectable', {
      $extend: {
        Scope: {},
        Record: {
          //Override della defaul $destroy
          $destroy: function () {
            return this.$action(function () {
              if (this.aggId) {
                var request = this.$buildRequest('destroy', true);
                request.data = {
                  aggId: this.aggId
                };
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$unwrap(this.$getResponseData(_response));
                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) {
                  this.$scope.$remove(this);
                }
              }
            });
          },
          $save: function () {
            return this.$action(function () {
              var request = this.lottoVuoto ? this.$buildRequest('saveManuale') : this.$buildRequest('saveAutomatico');
              request.data = {
                destinazione: this.destinazione,
                tipoLotto: this.tipoLotto,
                annotazioni: this.annotazioni,
                lottoVuoto: this.lottoVuoto,
                spedizioni: this.spedizioni
              };
              this.$dispatch('before-save', [request]);
              this.$send(request, function (_response) {
                // this.$unwrap(_response.data);
                this.$dispatch('after-save', [_response]);
              }, function (_response) {
                this.$dispatch('after-save-error', [_response]);
              });
            });
          },
          $forzaInvio: function () {
            return this.$action(function () {
              var request = this.$buildRequest('forzaInvio');
              request.data = {
                aggId: this.$pk
              };
              this.$dispatch('before-forza-invio', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-forza-invio', [_response]);
              }, function (_response) {
                this.$dispatch('after-forza-invio-error', [_response]);
              });
            });
          },
          $forzaInvioSinPostel: function () {
            return this.$action(function () {
              var request = this.$buildRequest('forzaInvioSinPostel');
              request.data = {
                aggId: this.$pk
              };
              this.$dispatch('before-forza-invio-sin-postel', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-forza-invio-sin-postel', [_response]);
              }, function (_response) {
                this.$dispatch('after-forza-invio-sin-postel-error', [_response]);
              });
            });
          },
          $downloadArchivioSinPostel: function () {
            return this.$action(function () {
              var request = this.$buildRequest('downloadArchivioSinPostel');
              request.params = Object.assign({}, {
                aggId: this.$pk
              });
              this.$dispatch('before-download-archivio-sin-postel', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-download-archivio-sin-postel', [_response]);
              }, function (_response) {
                this.$dispatch('after-download-archivio-sin-postel-error', [_response]);
              });
            });
          },
          $marcaInvio: function () {
            return this.$action(function () {
              var request = this.$buildRequest('marcaInvio');
              request.data = {
                aggId: this.$pk
              };
              this.$dispatch('before-marca-invio', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-marca-invio', [_response]);
              }, function (_response) {
                this.$dispatch('after-marca-invio-error', [_response]);
              });
            });
          }
        }
      }
    });
  }]);
})();