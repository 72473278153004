"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dettaglioPresentazioneDocumentoMock = exports.ricercaPresentazioneDocumentiMock = void 0;
const ricercaPresentazioneDocumentiMock = {
  "resultsWrapper": {
    "count": 2,
    "values": [{
      "aggId": "9143eaaa-5d99-ed90-6b54-14f609ede7e0",
      "verbaleId": "verbale id",
      "numeroVerbale": "1/P/2021",
      "dataViolazioneVerbale": 47069237,
      "dataScadenza": -17670093,
      "tipoVeicolo": "QUADRICICLO",
      "targa": "AX12345",
      "statoPresentazione": "presentato",
      "numeroDocumentiDaPresentare": 1,
      "tipiDocumentoDaPresentare": ["patente"],
      "trasgressore": {
        "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
        "tipoAnagrafica": "persona_fisica",
        "nome": "Mario",
        "cognome": "Rossi",
        "ragioneSociale": "Open Software srl"
      },
      "obbligato": {
        "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9aba",
        "tipoAnagrafica": "figura_giuridica",
        "nome": "Mario",
        "cognome": "Rossi",
        "ragioneSociale": "Open Software srl"
      }
    }, {
      "aggId": "a851dfdf-4e6d-6b9c-6be6-8a5ce68c5879",
      "verbaleId": "verbale id",
      "numeroVerbale": "2/P/2021",
      "dataViolazioneVerbale": 50049391,
      "dataScadenza": 13758324,
      "tipoVeicolo": "AUTOVEICOLO",
      "targa": "AA000BB",
      "statoPresentazione": "verbalizzabile",
      "numeroDocumentiDaPresentare": 2,
      "tipiDocumentoDaPresentare": ["patente", "carta identità"],
      "trasgressore": {
        "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
        "tipoAnagrafica": "persona_fisica",
        "nome": "John",
        "cognome": "Pescanoce",
        "ragioneSociale": "Open Software srl"
      },
      "obbligato": {
        "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9456",
        "tipoAnagrafica": "persona_fisica",
        "nome": "Fritz",
        "cognome": "Banana"
      }
    }, {
      "aggId": "9143eaaa-5d99-ed90-6b54-14f609ede666",
      "verbaleId": "verbale id",
      "numeroVerbale": "3/P/2021",
      "dataViolazioneVerbale": 47069237,
      "dataScadenza": -17670093,
      "tipoVeicolo": "QUADRICICLO",
      "targa": "AX12345",
      "statoPresentazione": "presentato",
      "numeroDocumentiDaPresentare": 3,
      "tipiDocumentoDaPresentare": ["carta identità", "carta di circolazione", "certificato assicurazione"],
      "trasgressore": {
        "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
        "tipoAnagrafica": "persona_fisica",
        "nome": "Carlo",
        "cognome": "Cracco",
        "ragioneSociale": "Open Software srl"
      },
      "obbligato": {
        "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9123",
        "tipoAnagrafica": "figura_giuridica",
        "nome": "Mario",
        "cognome": "Rossi",
        "ragioneSociale": "Cracco srl"
      }
    }, {
      "aggId": "9143eaaa-5d99-ed90-6b54-14f609ede6g6",
      "verbaleId": "verbale id",
      "numeroVerbale": "4/P/2021",
      "dataViolazioneVerbale": 47069237,
      "dataScadenza": -17670093,
      "tipoVeicolo": "QUADRICICLO",
      "targa": "AX12345",
      "statoPresentazione": "verbalizzato",
      "numeroDocumentiDaPresentare": 7,
      "tipiDocumentoDaPresentare": ["patente", "carta identità", "carta di circolazione", "certificato assicurazione", "certificato assicurazione", "certificato assicurazione", "certificato assicurazione"],
      "obbligato": {
        "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
        "tipoAnagrafica": "persona_fisica",
        "nome": "Joe",
        "cognome": "Pescatore",
        "ragioneSociale": "Open Software srl"
      }
    }]
  },
  "search": {
    "aggId": "",
    "statoPresentazione": "",
    "numeroVerbale": "",
    "targa": "",
    "obbligato": "",
    "trasgressore": "",
    "attiva": true,
    "excludedDb": true,
    "explodeId": false,
    "limit": 20,
    "order": "",
    "page": 1
  }
};
exports.ricercaPresentazioneDocumentiMock = ricercaPresentazioneDocumentiMock;
const dettaglioPresentazioneDocumentoMock = {
  "aggId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
  "statoPresentazione": "verbalizzabile",
  "verbaleId": "fa75645f-e9b7-4ef3-90e4-7b40453c9111",
  "numeroVerbale": "1/V/2021",
  "dataViolazioneVerbale": 1634076000000,
  "violazioni": [{
    "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9sad",
    "codiceLibreria": "180/001.A",
    "titolo": "Mancanza del documento di circolazione\n",
    "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
  }, {
    "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453cfafa",
    "codiceLibreria": "180/001.A",
    "titolo": "Mancanza del documento di circolazione\n",
    "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
  }],
  "trasgressore": {
    "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9ced",
    "tipoAnagrafica": "persona_fisica",
    "nome": "Mario",
    "cognome": "Rossi"
  },
  "obbligato": {
    "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453crw23",
    "tipoAnagrafica": "figura_giuridica",
    "ragioneSociale": "Open Software srl"
  },
  "daPresentare": [{
    "docId": "b58c4074-1e00-4ffd-afe8-da3177ce92b6",
    "soggetto": {
      "tipoSoggetto": "trasgressore",
      "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9ced",
      "tipoAnagrafica": "persona_fisica",
      "nome": "Mario",
      "cognome": "Rossi"
    },
    "isDefault": true,
    "tipoDocumento": "patente",
    "tipoDocumentoForzato": "CFP",
    "enteRilascio": {
      "aggId": "47eef37d-8bb5-4449-ad93-e8900817b30c",
      "tipoEnte": "QUESTURA",
      "ragioneSociale": "Questura di Treviso"
    },
    "numeroDocumento": "abrt34234o12345qwerf",
    "dataRilascio": 1634076000000,
    "dataScadenza": 1634086000000,
    "categorie": ["CQC_P"],
    "categoriaDocumentoForzato": "BD",
    "dataPresentazione": 1634086000000,
    "luogoPresentazione": "Bastia Rovolon",
    "esitoPresentazione": "ok",
    "riferimentoArticolo": {
      "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9eee",
      "codiceLibreria": "180/001.A",
      "titolo": "Mancanza del documento di circolazione\n",
      "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
    },
    "riferimentoArticoloForzato": {
      "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9abb",
      "codiceLibreria": "180/001.A",
      "titolo": "Mancanza del documento di circolazione\n",
      "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
    }
  }, {
    "docId": null,
    "soggetto": {
      "tipoSoggetto": "obbligato",
      "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453crw23",
      "tipoAnagrafica": "figura_giuridica",
      "ragioneSociale": "Open Software srl"
    },
    "isDefault": true,
    "tipoDocumento": "patente",
    "tipoDocumentoForzato": null,
    "enteRilascio": null,
    "numeroDocumento": null,
    "dataRilascio": null,
    "dataScadenza": null,
    "categoria": null,
    "categoriaDocumentoForzato": null,
    "dataPresentazione": null,
    "luogoPresentazione": null,
    "esitoPresentazione": "mancanza_patente",
    "riferimentoArticolo": {
      "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9gre",
      "codiceLibreria": "180/001.A",
      "titolo": "Mancanza del documento di circolazione\n",
      "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
    },
    "riferimentoArticoloForzato": {
      "aggId": "fa75645f-e9b7-4ef3-90e4-7b40453c9nhy",
      "codiceLibreria": "180/001.A",
      "titolo": "Mancanza del documento di circolazione\n",
      "descrizione": "Per aver circolato con il veicolo non presentato alla revisione per l'anno ____\n"
    }
  }]
};
exports.dettaglioPresentazioneDocumentoMock = dettaglioPresentazioneDocumentoMock;