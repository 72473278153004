"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Versamento', ['restmod', function (restmod) {
    return restmod.model('versamento').mix({
      $extend: {
        Scope: {
          $config: {
            baseEntityPath: 'versamento/',
            api: {
              versamentoCollectionIds: {
                url: 'ricerca/versamenti-id',
                method: 'GET'
              },
              destroy: {
                url: 'comando/cancella-versamento',
                method: 'POST'
              },
              inizializzaProcessoVersamento: {
                url: 'bpmn/versamento/start-process',
                method: 'POST'
              },
              immaginiVersamento: {
                url: 'bpmn/get-immagini-da-validare/',
                method: 'GET'
              },
              tipiVersamento: {
                url: 'lista/tipi-versamento',
                method: 'GET'
              },
              metodiVersamento: {
                url: 'lista/metodi-versamento',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/versamento',
                method: 'POST'
              },
              bancopostaTxt: {
                url: 'bancoposta-txt',
                method: 'POST'
              },
              bollettinoReport: {
                url: 'bollettino-report',
                method: 'POST'
              },
              attribuzioneManuale: {
                url: 'attribuzione-manuale',
                method: 'POST'
              },
              attribuzioneAutomaticaMassiva: {
                url: 'attribuzione-automatica-massiva',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'RicercaVeloce', 'Pageable', 'Filterable', {
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl('list');
            };

            return collection;
          }
        },
        Resource: {
          $inizializzaProcessoAttribuzioneVersamenti: function (_params) {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('inizializzaProcessoVersamento', false);

              if (this.$isCollection && !_params) {
                this.$dispatch('before-inizializza-processo-attribuzione-versamenti-wrong-parameters-exception');
                throw 'Wrong parameters exception';
              }

              if (this.$isCollection) {
                request.data = angular.copy(_params);
              } else {
                request.data = [this.$pk];
              }

              this.$dispatch('before-inizializza-processo-attribuzione-versamenti', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-inizializza-processo-attribuzione-versamenti', [_response]);
              }, function (_response) {
                this.$dispatch('after-inizializza-processo-attribuzione-versamenti-error', [_response]);
              });
            });
          }
        },
        Collection: {
          $getCollectionIds: function (_params) {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('versamentoCollectionIds');
              request.params = Object.assign({}, {
                milestone: _params.milestone
              }, _params);
              this.$dispatch('before-get-collection-ids', [request]);
              this.$send(request, function (_response) {
                this['$collectionIds'] = _response.data.resultsWrapper.values;
                this.$dispatch('after-get-collection-ids', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-get-collection-ids-error', [_response]);
              });
            });
          },
          $attribuzioneAutomaticaMassiva: function () {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('attribuzioneAutomaticaMassiva');
              this.$dispatch('before-attribuzione-automatica-massiva', [request]);
              this.$send(request, function (_response) {
                this.$dispatch('after-attribuzione-automatica-massiva', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-attribuzione-automatica-massiva-error', [_response]);
              });
            });
          }
        },
        Record: {
          $getImmaginiVersamento: function () {
            return this.$action(function () {
              var me = this,
                  request = this.$buildRequest('immaginiVersamento', false);
              request.url = request.url.concat(this.$pk);
              this.$dispatch('before-get-immagini-versamento', [request]);
              this.$send(request, function (_response) {
                this['$immagini'] = _response.data;
                this.$dispatch('after-get-immagini-versamento', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-get-immagini-versamento-error', [_response]);
              });
            });
          },
          $getTipiVersamento: function (params) {
            var me = this,
                request = this.$buildRequest('tipiVersamento');
            this.$dispatch('before-get-tipi-versamento', [request]);
            this.$send(request, function (_response) {
              this['$tipiVersamento'] = _response.data;
              this.$dispatch('after-get-tipi-versamento', [_response.data]);
            }, function (_response) {
              this.$dispatch('after-get-tipi-versamento-error', [_response]);
            });
          },
          $getMetodiVersamento: function (params) {
            var me = this,
                request = this.$buildRequest('metodiVersamento');
            this.$dispatch('before-get-metodi-versamento', [request]);
            this.$send(request, function (_response) {
              this['$metodiVersamento'] = _response.data;
              this.$dispatch('after-get-metodi-versamento', [_response.data]);
            }, function (_response) {
              this.$dispatch('after-get-metodi-versamento-error', [_response]);
            });
          },
          $acquisisciBancopostaTxt: function (_versamento) {
            var me = this,
                request = this.$buildRequest('bancopostaTxt');
            request.data = Object.assign({}, _versamento);
            this.$dispatch('before-acquisici-bancoposta-txt', [request]);
            this.$send(request, function (_response) {
              this.$dispatch('after-acquisici-bancoposta-txt', [_response.data]);
            }, function (_response) {
              this.$dispatch('after-acquisici-bancoposta-txt-error', [_response]);
            });
          },
          $acquisisciBollettinoReport: function (_versamento) {
            var me = this,
                request = this.$buildRequest('bollettinoReport');
            request.data = Object.assign({}, _versamento);
            this.$dispatch('before-acquisici-bollettino-report', [request]);
            this.$send(request, function (_response) {
              this.$dispatch('after-acquisici-bollettino-report', [_response.data]);
            }, function (_response) {
              this.$dispatch('after-acquisici-bollettino-report-error', [_response]);
            });
          },
          $attribuzioneManualeVersamento: function (_versamento) {
            return this.$action(function () {
              var request = this.$buildRequest('attribuzioneManuale');
              request.data = Object.assign({}, _versamento);
              this.$dispatch('before-attribuzione-manuale-versamento', [request]);
              this.$send(request, function (_response) {
                /*if( this.$scope.$remove ) {
                    this.$scope.$remove(this);
                }*/
                this.$dispatch('after-attribuzione-manuale-versamento', [_response]);
              }, function (_response) {
                this.$dispatch('after-attribuzione-manuale-versamento-error', [_response]);
              });
            });
          },
          $delete: function (_params) {
            return this.$action(function () {
              var request = this.$buildRequest('destroy', true);
              request.data = Object.assign({}, _params);
              this.$dispatch('before-delete', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-delete', [_response]);
              }, function (_response) {
                this.$dispatch('after-delete-error', [_response]);
              });
            });
          }
        }
      }
    });
  }]);
})();