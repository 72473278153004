"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('OsSave', ['restmod', function (restmod) {
    var API_SAVE = {
      url: 'comando/nuovo',
      method: 'POST'
    },
        API_EDIT = {
      url: 'comando/modifica',
      method: 'POST'
    };
    return restmod.mixin({
      $extend: {
        Scope: {
          //Costruttore
          $new: function (_pk, _scope) {
            angular.extend(this.$config.api, {
              save: this.$config.api.save ? this.$config.api.save : API_SAVE,
              edit: this.$config.api.edit ? this.$config.api.edit : API_EDIT
            });
            return this.$super(_pk, _scope);
          }
        }
      }
    }, {
      $extend: {
        $save: function (_patch) {
          return this.$decorate({
            'before-save': function (_req) {
              var request = this.$isNew() ? this.$buildRequest('save') : this.$buildRequest('edit');
              _req.method = request.method;
              _req.url = request.url;
            }
          }, function () {
            if (!this.$isNew()) {
              _patch = _patch ? R.append('aggId', _patch) : ['aggId'];
            } // every method from same context called inside this function will be decorated.


            return this.$super(_patch);
          });
        }
      }
    });
  }]);
})();