"use strict";

/**
 * Created by MN 16/12/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('contoEconomicoCompareBilanci', {
    templateUrl: 'osContabilita/bilancio/compareBilanci/contoEconomicoCompareBilanci/contoEconomicoCompareBilanci.component.html',
    controller: 'ContoEconomicoCompareBilanciController',
    controllerAs: 'contoEconomicoCompareBilanciCtrl',
    bindings: {
      data: '<'
    }
  });
})();