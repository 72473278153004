"use strict";

/**
 * Created by gbelli on 20/06/18.
 * Controllore della modal window di stampa
 */
(function () {
  'use strict';

  angular.module('polcity.entityToPdf').controller('entityToPdfDialogController', ['$scope', '$mdDialog', '$timeout', '$injector', 'Command', EntityToPdfDialogControllerFn]);

  function EntityToPdfDialogControllerFn($scope, $mdDialog, $timeout, $injector, Command) {
    var vm = this;
    /**
     * @property
     * Array di item da visualizzare nella lista
     * @type {Array}
     */

    vm.items;
    /**
     * @property
     * Modalità di stampa
     */

    vm.printMode;
    /**
     * @property
     * AggregateCanonicalName della risorsa da stamapre
     */

    vm.aggregateCanonicalName;
    /**
     * @property
     * Dominio delle risorse che si vuole stampare
     */

    vm.dominio;
    /**
     * GB 15/06/2017
     * Modal close handler
     */

    vm.onCloseButtonClick = function () {
      $mdDialog.hide();
    };
    /**
     * GB 17/06/2019
     * Modal generatePdf button handler
     * @param printUnit
     */


    vm.generatePdf = function (items) {
      var printUnits = [];
      R.map(function (item) {
        printUnits.push(preparePrintUnit(item.printUnit));
      }, items);
      vm.pdfGenerationInProgress = true;
      Command.download("@stampa" + vm.dominio, printUnits, {
        //TODO: valutare in seguito
        filename: "stampa_" + vm.dominio + ".pdf"
      }).finally(function () {
        vm.pdfGenerationInProgress = false;
      });
    };
    /********************************
     * Local controller variables
     ********************************/

    /**
     * @property {local}
     * Proiezione di default nella collezione
     */


    vm.defaultProjectionClass;
    /**
     * GB 20/06/2018
     * Procedura di inizializzazione della modale
     * Mi carico la projectionClass di default a partire dall'aggregateCanonicalName e la applico a tutti gli elementi
     */

    vm.$onInit = function () {
      fetchDefaultEntity(vm.aggregateCanonicalName).then(function (result) {
        vm.defaultProjectionClass = result.projectionClass;
        applyEntities(vm.items, [result]);
        bulkSelectDefaultEntity(vm.items, result);
      });
      $scope.$watch('vm.defaultProjectionClass', function (newDefaultProjectionClass, oldDefaultProjectionClass) {
        if (newDefaultProjectionClass) {
          fetchPrintModels(newDefaultProjectionClass).then(function (result) {
            vm.modelliStampaBulkOptions = prepareModelliStampa(result.resultsWrapper.values);
            applyModelliStampaBulkOptionsToCollection(vm.items, newDefaultProjectionClass, vm.modelliStampaBulkOptions);
          });
        }
      });
    };
    /*****************************
     * Bulk actions handlers
     *****************************/

    /**
     * GB 20/06/2018
     *
     * Collezione di modelli di stampa da applicare alla defaultProjectionClass
     * @type {Array}
     */


    vm.modelliStampaBulkOptions = [];
    /**
     * GB 20/06/2018
     *
     * Handelr scatenato alla selezione di uno o più modelli di stampa da applicare a tutta la selezione
     *
     * Applica a tutti gli item che vogliono stampare la defaultProjection i modelli di stampa selezionali
     * @param modelliStampaBulkSelection
     */

    vm.onModelliStampaBulkSelectionChange = function (modelliStampaBulkSelection) {
      setBulkOptionSelectionToCollection(vm.items, vm.defaultProjectionClass, modelliStampaBulkSelection);
    }; // ----------

    /******************************
     * Entity handlers
     *****************************/

    /**
     * GB 20/06/2018
     * Handler scatenato al cambio di selezioni della proiezione da stampare
     * @param item
     * @param entity
     */


    vm.onEntityChange = function (item) {
      item.printUnit.modelliStampaId = [];
    };
    /**
     * GB 20/06/2017
     *
     * Handler scatenato all'apertura del menu delle entity sul singolo item
     * @param item
     * @returns {*}
     */


    vm.onEntityOpen = function (item) {
      item.entities = [];
      return fetchProjections(vm.aggregateCanonicalName, item.printUnit.aggId).then(function (result) {
        applyEntities([item], prepareEntities(result));
      });
    };
    /***************************
     * Print models handlers
     ***************************/

    /**
     * GB 20/06/2018
     *
     * Handler scatena alla pressione sulla selectbox dei modelli di stampa di un elemento
     * @param item
     * @returns {*}
     */


    vm.onPrintModelsOpen = function (item) {
      item.modelliStampa = [];
      return fetchPrintModels(item.printUnit.entity.projectionClass).then(function (results) {
        item.modelliStampa = prepareModelliStampa(results.resultsWrapper.values);
      });
    }; // ----------

    /***********************
     * Setters, Appliers
     ***********************/


    function applyEntities(items, entities) {
      R.map(function (item) {
        item.entities = entities;
      }, items);
    }

    function applyModelliStampaBulkOptionsToCollection(items, defaultProjectionClass, modelliStampa) {
      R.map(function (item) {
        if (item.printUnit.entity.projectionClass === defaultProjectionClass) {
          item.modelliStampa = modelliStampa;
        }
      }, items);
    }

    function setBulkOptionSelectionToCollection(items, defaultProjectionClass, modelliStampaBulkSelection) {
      R.map(function (item) {
        if (item.printUnit.entity.projectionClass === defaultProjectionClass) {
          item.printUnit.modelliStampaId = modelliStampaBulkSelection;
        }
      }, items);
    }

    function setModelliStampa(item, modelliStampa) {
      item.printUnit.modelliStampaId = modelliStampa;
    }
    /***************************
     * Utils
     ***************************/


    function preparePrintUnit(pu) {
      var printUnit = {};
      printUnit.aggId = pu.aggId;
      printUnit.modelliStampaId = pu.modelliStampaId;

      if (pu.entity.entityId) {
        printUnit.entityId = pu.entity.entityId;
      }

      if (pu.entity.projectionClass) {
        printUnit.projectionClass = pu.entity.projectionClass;
      }

      return printUnit;
    }
    /**
     * GB 21/06/2018
     *
     * Il medoto elabora e prepara un array di entità da associare ad un item
     * @param entities, array di entità (proiezioni)
     * @returns {Array}
     */


    function prepareEntities(entities) {
      var result = [];
      R.map(function (entity) {
        result.push({
          entityId: entity.itemId,
          projectionClass: entity.projectionClass,
          frontendLabel: entity.frontendLabel + " - " + entity.itemProjectionDescription
        });
      }, entities);
      return result;
    }
    /**
     * GB 21/06/2018
     *
     * Il metodo applica agli items la defaultEntity
     * @param items
     * @param defaultEntity
     */


    function bulkSelectDefaultEntity(items, defaultEntity) {
      R.map(function (item) {
        item.printUnit.entity = defaultEntity;
      }, items);
    }
    /**
     * GB 20/06/2018
     *
     * Elabora i modelli di stampa restituiti dal server raggruppandoli per funzione di stampa
     * @param modelliStampa
     */


    function prepareModelliStampa(modelliStampa) {
      var result = {};

      for (var i = 0; i < modelliStampa.length; i++) {
        if (!result[modelliStampa[i].descrizioneFunzione]) {
          result[modelliStampa[i].descrizioneFunzione] = [];
        }

        result[modelliStampa[i].descrizioneFunzione].push({
          frontendLabel: modelliStampa[i].codice + " - " + modelliStampa[i].descrizione,
          modelloId: modelliStampa[i].aggId
        });
      }

      return result;
    }
    /**
     * GB 20/06/2018
     * Chiede al server l'entity di default per un aggregateCanonicalName
     * @param aggregateCanonicalName
     * @returns {*}
     */


    function fetchDefaultEntity(aggregateCanonicalName) {
      return Command.execute($injector.get('FETCH_DEFAULT_ENTITIY_COMMAND'), {
        aggregateCanonicalName: aggregateCanonicalName
      });
    }
    /**
     * Chiede al server le entità disponibili per la stampa di un elemento
     * @param aggregateCanonicalName
     * @param aggId: aggId dell'elemento per cui si richiedono le proiezioni
     * @returns {*}
     */


    function fetchProjections(aggregateCanonicalName, aggId) {
      return Command.execute($injector.get('FETCH_ENTITIES_COMMAND'), {
        aggregateCanonicalName: aggregateCanonicalName,
        aggId: aggId
      });
    }
    /**
     * Chiede al server la lista dei modelli di stampa per una proiezione
     * @param projectionClass
     * @returns {*}
     */


    function fetchPrintModels(projectionClass) {
      return Command.execute($injector.get('FETCH_PRINT_TEMPLATES_COMMAND'), {
        projectionClass: projectionClass
      });
    } // ----------

  }
})();