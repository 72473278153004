"use strict";

//TODO: eliminare
(function () {
  'use strict';

  angular.module('polcity.verbali').component('verbaliQuickFilter', {
    templateUrl: 'verbali/quickFilter.component.html',
    controller: ['$scope', '$location', '$timeout', VerbaliQuickFilterComponent],
    bindings: {
      setFilters: '<',
      items: '<' // array with the list of quickfilters to show

    }
  });

  function VerbaliQuickFilterComponent($scope, $location, $timeout) {
    this.$onInit = R.pipe(setUpMaxDate, restoreFiltersFromUrl);
    this.$onChanges = R.pipe(setUpMaxDate, restoreFiltersFromUrl);
    this.showValues = showValues;
    this.hideValues = hideValues;
    this.addFilter = addFilter;
    this.removeFilter = removeFilter;
    this.addDateFilter = addDateFilter;
    this.hasFilters = hasFilters;
    this.clearFilters = clearFilters;
    this.activeFilters = {};
    var vm = this;

    function hasFilters() {
      return R.reject(R.isNil, R.values(R.omit(['page', 'limit', 'order', 'selectedItem'], vm.activeFilters))).length > 0;
    }

    function clearFilters() {
      R.mapObjIndexed(function (value, key) {
        vm.removeFilter(key, false);
      }, R.omit(['page', 'limit', 'order', 'selectedItem'], vm.activeFilters));
      $scope.$emit('osList::quickFilter', angular.copy(vm.activeFilters));
    }

    function restoreFiltersFromUrl() {
      $timeout(function () {
        var params = $location.search(); //var soggetti = R.has('trasgressore', params) ? params.trasgressore : null;
        //params = R.pick(R.map(R.prop('name'), vm.items), params);
        //if (soggetti) {
        //    params.soggetti = soggetti;
        //}

        R.mapObjIndexed(function (value, key) {
          if (key === 'dataOraVerbale') {
            vm.items = R.map(function (filter) {
              if (filter.name === key) {
                filter.minDate = new Date(parseInt(value[0]));
                filter.maxDate = new Date(parseInt(value[1]));
              }

              return filter;
            }, vm.items);
          }

          vm.activeFilters[key] = value;
        }, params);
        R.mapObjIndexed(function (value, key) {
          if (value) {
            disableFilterBtn(key, 0);
          }
        }, vm.activeFilters);
        $scope.$emit('osList::quickFilterPresence', R.reject(R.isNil, R.unnest(R.values(R.omit(['page', 'limit', 'order', 'selectedItem'], vm.activeFilters)))).length > 0);
      });
    }

    function addDateFilter(from, to) {
      addFilter([from, to], 'dataOraVerbale');
    }

    function addFilter(value, key, count) {
      vm.activeFilters[key] = vm.activeFilters[key] || {};
      vm.activeFilters[key] = value;
      var data = angular.copy(vm.activeFilters);

      if (key === 'dataOraVerbale') {
        $scope.dateFilterForm.$setPristine();
      }

      hideValues();
      disableFilterBtn(key, count);
      $scope.$emit('osList::quickFilter', data);
    }

    function disableFilterBtn(key, count) {
      vm.items = R.map(function (filter) {
        if (filter.name === key) {
          filter.lastCount = filter.count;
          filter.count = count;
          filter.disabled = true;
        }

        return filter;
      }, vm.items);
    }

    function enableFilterBtn(key) {
      vm.items = R.map(function (filter) {
        if (filter.name === key) {
          filter.disabled = false;
        }

        return filter;
      }, vm.items);
    }

    function showValues(key) {
      vm.valuesPanelVisible = true;
      vm.clickedFilter = key;
    }

    function hideValues() {
      vm.valuesPanelVisible = false;
    }

    function removeFilter(key) {
      vm.activeFilters[key] = null;
      var data = angular.copy(vm.activeFilters);

      if (arguments.length < 2) {
        $scope.$emit('osList::quickFilter', data);
      }

      if (key === 'dataOraVerbale') {
        setUpMaxDate();
      }

      enableFilterBtn(key);
    }

    function setUpMaxDate() {
      var key = 'dataOraVerbale';
      vm.items = R.map(function (filter) {
        if (filter.name === key) {
          var now = new Date();
          filter.max = now;
          filter.minDate = now;
          filter.maxDate = now;
        }

        return filter;
      }, vm.items);
    }
  }
})();