"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const SizebleTableCell = (0, _core.withStyles)(theme => ({
  root: ({
    width
  }) => ({
    width: width
  })
}))(_core.TableCell);
var _default = SizebleTableCell;
exports.default = _default;