"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gestioneContiCorrentiListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const gestioneContiCorrentiListColumns = [{
  field: 'intestazione',
  headerName: 'Intestazione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'numero',
  headerName: 'Numero',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'utilizzo',
  headerName: 'Utilizzo',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.gestioneContiCorrentiListColumns = gestioneContiCorrentiListColumns;