"use strict";

/**
 * Created by MN 31/07/2019
 */
(function () {
  'use strict';

  angular.module('polcity.acquisizioneDati').component('filtriTemporali', {
    templateUrl: 'acquisizioneDati/rilevazioni/filtriTemporali/filtriTemporali.component.html',
    controller: ['$scope', FiltriTemporaliController],
    bindings: {
      firstDateFilterKey: '<',
      firstDateFilterLabel: '<',
      secondDateFilterKey: '<',
      secondDateFilterLabel: '<',
      onAddTemporalFilter: '&'
    }
  });

  function FiltriTemporaliController($scope) {
    var me = this;
    /**
     * Event listeners
     */

    $scope.$on('reset-date-range', function () {
      setDefault();
    });

    me.$onInit = function () {
      setDefault();
    };
    /**
     * Handlers
     */


    me.onAddTimeFilter = function (timeRange) {
      switch (timeRange) {
        case 'today':
          calculateTimeRangeFor('today');
          break;

        case 'last7days':
          calculateTimeRangeFor('last7days');
          break;

        case 'last30days':
          calculateTimeRangeFor('last30days');
          break;

        case 'lastYear':
          calculateTimeRangeFor('lastYear');
          break;
      }
    };

    me.onAddSeletedTimeFilter = function () {
      var timeRange = [];

      if ($scope.selectedTimeRange.fromDate === $scope.selectedTimeRange.toDate) {
        $scope.selectedTimeRange.fromDate = moment($scope.selectedTimeRange.fromDate).startOf('day').toDate().getTime();
        $scope.selectedTimeRange.toDate = moment($scope.selectedTimeRange.toDate).endOf('day').toDate().getTime();
      }

      timeRange.push($scope.selectedTimeRange.fromDate);
      timeRange.push($scope.selectedTimeRange.toDate);
      me.onAddTemporalFilter({
        timeRange: timeRange,
        selectionType: $scope.selectedTimeRange.selectionType
      });
    };

    me.switchSelectionType = function (data) {
      if (data === true) {
        $scope.selectedTimeRange.selectionType = me.secondDateFilterKey;
      } else {
        $scope.selectedTimeRange.selectionType = me.firstDateFilterKey;
      }
    };
    /**
     * Utils
     */


    function calculateTimeRangeFor(time) {
      var timeRange = [];
      $scope.selectedTimeRange.toDate = moment();

      if (time === 'today') {
        $scope.selectedTimeRange.fromDate = moment().startOf('day');
      } else if (time === 'last7days') {
        $scope.selectedTimeRange.fromDate = moment($scope.selectedTimeRange.toDate).subtract(7, 'days');
      } else if (time === 'last30days') {
        $scope.selectedTimeRange.fromDate = moment($scope.selectedTimeRange.toDate).subtract(30, 'days');
      } else if (time === 'lastYear') {
        $scope.selectedTimeRange.fromDate = moment().startOf('year');
      }

      $scope.selectedTimeRange.fromDate = moment($scope.selectedTimeRange.fromDate).toDate().getTime();
      $scope.selectedTimeRange.toDate = moment($scope.selectedTimeRange.toDate).toDate().getTime();
      timeRange.push($scope.selectedTimeRange.fromDate);
      timeRange.push($scope.selectedTimeRange.toDate);
      me.onAddTemporalFilter({
        timeRange: timeRange,
        selectionType: $scope.selectedTimeRange.selectionType
      });
    }

    function setDefault() {
      $scope.currentDate = moment();
      $scope.selectionType = false;
      $scope.selectedTimeRange = {
        selectionType: me.firstDateFilterKey,
        fromDate: null,
        toDate: null
      };
    }
  }
})();