"use strict";

/**
 * GB 24/09/2019
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaVerbale', [RicercaVerbale]);

  function RicercaVerbale() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaVerbale'],
      scope: {
        entity: '=?',
        label: '@?',
        onVerbaleChange: '<?',
        onEntityChange: '&?',
        isRequired: '<?',
        name: '@?',
        minLength: '<?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'ricercaVerbaleCtrl',
      templateUrl: 'osFramework/input/ricercaVerbale/ricercaVerbale.directive.html',
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this;
      me.groupRoles = ['ROLE_VERBALE_RICERCA_TESTO_LIBERO'];
      me.additionalQueryParams = {
        limit: 20
      };

      me.getItemText = function (verbale) {
        var itemText,
            verbaleId = verbale.idVerbale || verbale.verbaleId; //FIXME: alcuni servizi rispondono in un modo altri in un altro

        itemText = verbaleId.toUpperCase();
        return itemText;
      };
    }

    ;

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaVerbaleCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.verbaleAggId = null;
          return;
        }

        scope.verbaleAggId = viewValue;
      };

      scope.$watch("entity", function (newVal) {
        if (scope.onEntityChange) {
          scope.onEntityChange({
            entity: newVal
          });
        }
      });
      scope.$watch("verbaleAggId", function (newVal) {
        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();