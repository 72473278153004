"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.veicoloValidationSchema = exports.veicoloEmptyForm = exports.toApiVeicolo = exports.fromApiVeicolo = void 0;

var _object = require("../../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const fromApiVeicolo = remoteVeicolo => ({ ...(remoteVeicolo !== null && remoteVeicolo !== void 0 && remoteVeicolo.aggId ? {
    aggId: remoteVeicolo.aggId
  } : {}),
  ...(remoteVeicolo !== null && remoteVeicolo !== void 0 && remoteVeicolo.entityId ? {
    entityId: remoteVeicolo.entityId
  } : {}),
  targa: (0, _object.getPathStringOrEmptyString)(["targa"])(remoteVeicolo),
  marca: (0, _object.getPathStringOrEmptyString)(["marca"])(remoteVeicolo),
  modello: (0, _object.getPathStringOrEmptyString)(["modello"])(remoteVeicolo),
  colore: (0, _object.getPathStringOrEmptyString)(["colore"])(remoteVeicolo),
  inizioProprieta: (0, _object.getPathNumberToDateOrNull)(["inizioProprieta"])(remoteVeicolo),
  fineProprieta: (0, _object.getPathNumberToDateOrNull)(["fineProprieta"])(remoteVeicolo),
  ultimaRevisione: (0, _object.getPathNumberToDateOrNull)(["ultimaRevisione"])(remoteVeicolo),
  scadenzaAssicurazione: (0, _object.getPathNumberToDateOrNull)(["scadenzaAssicurazione"])(remoteVeicolo)
});

exports.fromApiVeicolo = fromApiVeicolo;

const toApiVeicolo = values => {
  const veicolo = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    ...(values.entityId ? {
      entityId: values.entityId
    } : {}),
    tipoVeicolo: (0, _object.getPathObjectOrNull)(["tipoVeicolo"])(values),
    tipoVeicoloId: (0, _object.getPathNonEmptyStringOrNull)(["tipoVeicolo", "aggId"])(values),
    targa: (0, _object.getPathNonEmptyStringOrNull)(["targa"])(values),
    marca: (0, _object.getPathNonEmptyStringOrNull)(["marca"])(values),
    modello: (0, _object.getPathNonEmptyStringOrNull)(["modello"])(values),
    colore: (0, _object.getPathNonEmptyStringOrNull)(["colore"])(values),
    inizioProprieta: (0, _object.getPathDateToNumberOrNull)(["inizioProprieta"])(values),
    fineProprieta: (0, _object.getPathDateToNumberOrNull)(["fineProprieta"])(values),
    ultimaRevisione: (0, _object.getPathDateToNumberOrNull)(["ultimaRevisione"])(values),
    scadenzaAssicurazione: (0, _object.getPathDateToNumberOrNull)(["scadenzaAssicurazione"])(values)
  };
  return veicolo;
};

exports.toApiVeicolo = toApiVeicolo;
const veicoloEmptyForm = {
  tipoVeicolo: null,
  targa: '',
  marca: '',
  modello: '',
  colore: '',
  inizioProprieta: null,
  fineProprieta: null,
  ultimaRevisione: null,
  scadenzaAssicurazione: null
};
exports.veicoloEmptyForm = veicoloEmptyForm;
const veicoloValidationSchema = Yup.object().shape({
  tipoVeicolo: Yup.object().required(_utils.requiredMessage).nullable(),
  targa: Yup.string().when("tipoVeicolo", {
    is: value => value && value.nonTargato === false,
    then: Yup.string().required(_utils.requiredMessage).nullable()
  }),
  inizioProprieta: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').nullable().typeError('Data non valida'),
  fineProprieta: Yup.date().nullable().typeError('Data non valida'),
  ultimaRevisione: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').nullable().typeError('Data non valida'),
  scadenzaAssicurazione: Yup.date().nullable().typeError('Data non valida')
});
exports.veicoloValidationSchema = veicoloValidationSchema;