"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TIPO_ACCERTAMENTO_CASES = exports.MOTIVAZIONE_MANCATA_APPLICAZIONE_CASES = exports.MODELLO_STAMPA_CASES = exports.STAZIONE_COMANDO_CASES = exports.CONTENUTO_ALLEGATO_CASES = exports.STRADA_CASES = exports.COMUNEGDT_CASES = exports.NAZIONE_CASES = exports.ARTICOLO_CDS_CASES = void 0;
const ARTICOLO_CDS_CASES = ['articoloAssicurazione', 'esposizioneTargaProva', 'infrazioneAssicurazione16_30', 'infrazioneAssicurazioneScaduta', 'articolo', 'revisione', 'revisioneRecidiva', 'articoloRevisione', 'uuidArticoloCds126Bis', 'oltreSNormale', 'oltreSComma11', 'semaforicheComma3', 'semaforicheComma2', 'nonOltreDNormale', 'nonOltreDComma11', 'nonOltreDLimVeicolo', 'daDaQNormale', 'daDaQComma11', 'daDaQLimVeicolo', 'daQaSNormale', 'daQaSComma11', 'daQaSLimVeicolo', 'oltreSLimVeicolo', 'infrazioniSirio', 'infrazionePerTipoRilevazione'];
exports.ARTICOLO_CDS_CASES = ARTICOLO_CDS_CASES;
const NAZIONE_CASES = ["statoVeicolo"];
exports.NAZIONE_CASES = NAZIONE_CASES;
const COMUNEGDT_CASES = ["comune"];
exports.COMUNEGDT_CASES = COMUNEGDT_CASES;
const STRADA_CASES = ["strada"];
exports.STRADA_CASES = STRADA_CASES;
const CONTENUTO_ALLEGATO_CASES = ["tipoAllegatoDigitaleId"];
exports.CONTENUTO_ALLEGATO_CASES = CONTENUTO_ALLEGATO_CASES;
const STAZIONE_COMANDO_CASES = ["stazioneComandoId"];
exports.STAZIONE_COMANDO_CASES = STAZIONE_COMANDO_CASES;
const MODELLO_STAMPA_CASES = ['modelloStampaAccertamenti', 'gestioneVerbaliModelloStampaPrederinito', 'modelloStampaPredefinito', 'modelloStampaPredefinitito', 'uuidModelloStampa126Bis'];
exports.MODELLO_STAMPA_CASES = MODELLO_STAMPA_CASES;
const MOTIVAZIONE_MANCATA_APPLICAZIONE_CASES = ['gestioneVerbaliMotivazioneMancataApplicazione', 'motivazioneMancataApplicazione'];
exports.MOTIVAZIONE_MANCATA_APPLICAZIONE_CASES = MOTIVAZIONE_MANCATA_APPLICAZIONE_CASES;
const TIPO_ACCERTAMENTO_CASES = ['gestioneVerbaliTipoAccertamentoPredefinito', 'tipoAccertamentoPredefinito', 'tipoAccertamento'];
exports.TIPO_ACCERTAMENTO_CASES = TIPO_ACCERTAMENTO_CASES;