"use strict";

(function () {
  'use strict';

  angular.module('osAllegati').factory('osAllegatiCommands', ['CommandsList', osAllegatiCommands]);

  function osAllegatiCommands(CommandsList) {
    return {
      publish: publish
    };

    function publish() {
      CommandsList.add({
        '@detailIncidente': {
          method: 'GET',
          url: 'incidenti/:aggId?projection=incidenteProjection',
          replaceParams: true //transformResponse: R.pipe(angular.fromJson, transformDetail, stringNumbersToNumbers)

        },
        '@allegatiIncidente': {
          method: 'GET',
          url: 'aggregato/allegati?aggregateCanonicalName=eu.opensoftware.incidente.ao.Incidente'
        },
        '@detailVerbale': {
          method: 'POST',
          url: 'verbale/dettaglio/verbale',
          replaceParams: true //transformResponse: R.pipe(angular.fromJson, transformDetail, stringNumbersToNumbers)

        },
        '@allegatiVerbale': {
          method: 'GET',
          url: 'aggregato/allegati?aggregateCanonicalName=eu.opensoftware.verbale.ao.Verbale'
        },
        '@detailRicorso': {
          method: 'POST',
          url: 'ricorso/dettaglio/ricorso',
          replaceParams: true //transformResponse: R.pipe(angular.fromJson, transformDetail, stringNumbersToNumbers)

        },
        '@allegatiRicorso': {
          method: 'GET',
          url: 'aggregato/allegati?aggregateCanonicalName=eu.opensoftware.verbale.ao.Ricorso'
        },
        '@allegatoDownload': {
          method: 'GET',
          url: 'allegato-digitale/download/:aggId/inline',
          replaceParams: true
        },
        '@cancellaAllegato': {
          method: 'POST',
          url: 'allegato-digitale/comando/cancella-allegato-digitale'
        },
        '@loadItems': {
          method: 'GET',
          url: 'aggregato/items'
        },
        '@contenutoAllegato': {
          method: 'GET',
          url: 'contenuto-allegato/ricerca/contenuti-allegati',
          transformResponse: function (result) {
            return result.resultsWrapper.values;
          }
        },
        '@creaAllegato': {
          method: 'POST',
          url: 'allegato-digitale/comando/carica-allegato-digitale'
        },
        '@updateAllegato': {
          method: 'POST',
          url: 'allegato-digitale/comando/modifica-allegato-digitale'
        },
        '@detailAllegato': {
          method: 'POST',
          url: 'allegato-digitale/dettaglio/allegato-digitale'
        }
      });
    }
  }
})();