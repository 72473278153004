"use strict";

(function () {
  'use strict';

  angular.module('ngInputCurrency').directive('ngInputCurrency', ['$locale', '$filter', 'ngInputCurrencyService', '$timeout', function ($locale, $filter, util, $timeout) {
    var link = function ($scope, $element, $attrs, $ngModel) {
      var opts = {
        updateOn: 'blur enter',
        updateOnDefault: false
      };

      if ($ngModel.$options !== null && $ngModel.$options !== undefined) {
        opts = $ngModel.$options.createChild(opts);
      }

      $ngModel.$options = opts;
      var filter = $filter('currency');
      $ngModel.$formatters.push(function fromModelToView(value) {
        return filter(value);
      });
      $ngModel.$parsers.push(function (value) {
        value = util.preformatValue(value);
        var currency = util.toFloat(filter(value));

        if (util.isValid(currency)) {
          $ngModel.$setViewValue(filter(currency));
          $ngModel.$render();
          return currency;
        }
      });
      $element.on('blur', function () {
        if ($ngModel.$viewValue === $ngModel.$modelValue) {
          $element.val(filter($ngModel.$modelValue));
        }
      });
      $element.on('focus', function () {
        if (util.isValid($ngModel.$modelValue)) {
          $ngModel.$setViewValue($ngModel.$modelValue);
          $ngModel.$render();
        }
      });

      $ngModel.$validators.currency = function (modelValue) {
        return util.isValid(modelValue);
      };
    };

    return {
      'restrict': 'A',
      'require': 'ngModel',
      'link': link
    };
  }]);
})();