"use strict";

/**
 * Created by dan on 06/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.exceptions', []).service('exceptions', [exceptions]);

  function exceptions() {
    function IllegalArgument(sMessage) {
      this.name = 'IllegalArgument';
      this.message = 'IllegalArgument::' + sMessage;
      this.stack = new Error().stack;
    }

    IllegalArgument.prototype = Object.create(Error.prototype);
    IllegalArgument.prototype.constructor = IllegalArgument;

    function NotAuthorized(sMessage) {
      this.name = 'NotAuthorized';
      this.message = 'NotAuthorized::' + sMessage;
      this.stack = new Error().stack;
    }

    NotAuthorized.prototype = Object.create(Error.prototype);
    NotAuthorized.prototype.constructor = NotAuthorized;

    function NotImplemented(sMessage) {
      this.name = 'NotImplemented';
      this.message = 'NotImplemented::' + sMessage;
      this.stack = new Error().stack;
    }

    NotImplemented.prototype = Object.create(Error.prototype);
    NotImplemented.prototype.constructor = NotImplemented;

    function WrongArgumentsNumber(sMessage) {
      this.name = 'WrongArgumentsNumber';
      this.message = 'WrongArgumentsNumber::' + sMessage;
      this.stack = new Error().stack;
    }

    WrongArgumentsNumber.prototype = Object.create(Error.prototype);
    WrongArgumentsNumber.prototype.constructor = WrongArgumentsNumber;

    function WrongArgumentType(sMessage) {
      this.name = 'WrongArgumentType';
      this.message = 'WrongArgumentType::' + sMessage;
      this.stack = new Error().stack;
    }

    WrongArgumentType.prototype = Object.create(Error.prototype);
    WrongArgumentType.prototype.constructor = WrongArgumentType;
    return {
      WrongArgumentType: WrongArgumentType,
      WrongArgumentsNumber: WrongArgumentsNumber,
      NotAuthorized: NotAuthorized,
      IllegalArgument: IllegalArgument,
      NotImplemented: NotImplemented
    };
  }
})();