"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.insolutoSoggettoMancanteValidationSchema = exports.insolutoSoggettoMancanteEmptyForm = exports.toApiInsolutoCfPiMancante = exports.toApiInsolutoCompletaSoggetto = exports.fromApiInsoluto = void 0;

var _object = require("../../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const fromApiInsoluto = values => {
  return { ...values,
    dataViolazione: (0, _object.getPathNumberToDateOrNull)(["dataViolazione"])(values),
    dataDecorrenzaTermini: (0, _object.getPathNumberToDateOrNull)(["dataDecorrenzaTermini"])(values),
    dataElaborazione: (0, _object.getPathNumberToDateOrNull)(["dataElaborazione"])(values),
    dataProssimaElaborazione: (0, _object.getPathNumberToDateOrNull)(["dataProssimaElaborazione"])(values),
    soggetti: (0, _object.getPathArrayOrEmptyArray)(["soggetti"])(values).map(soggetto => ({ ...soggetto,
      dataInoltroLotto: (0, _object.getPathNumberToDateOrNull)(["dataInoltroLotto"])(soggetto),
      dataSpedizioneLotto: (0, _object.getPathNumberToDateOrNull)(["dataSpedizioneLotto"])(soggetto),
      dataNotifica: (0, _object.getPathNumberToDateOrNull)(["dataNotifica"])(soggetto)
    })),
    missingData: values.missingSoggettoArruolabile || values.missingCf || values.missingPiva,
    veicoloSanzionato: (0, _object.getPathStringOrEmptyString)(["veicoloSanzionato"])(values)
  };
};

exports.fromApiInsoluto = fromApiInsoluto;

const toApiInsolutoCompletaSoggetto = values => {
  return {
    insolutoAggId: (0, _object.getPathNonEmptyStringOrNull)(["insolutoAggId"])(values),
    soggettoRiferito: (0, _object.getPathNonEmptyStringOrNull)(["soggettoRiferito"])(values).charAt(0).toUpperCase(),
    anagraficaAggId: (0, _object.getPathNonEmptyStringOrNull)(["anagrafica", "aggId"])(values)
  };
};

exports.toApiInsolutoCompletaSoggetto = toApiInsolutoCompletaSoggetto;

const toApiInsolutoCfPiMancante = values => {
  return {
    insolutoAggId: (0, _object.getPathNonEmptyStringOrNull)(["insolutoAggId"])(values),
    soggettoRiferito: (0, _object.getPathNonEmptyStringOrNull)(["soggettoRiferito"])(values).charAt(0).toUpperCase(),
    dato: (0, _object.getPathNonEmptyStringOrNull)(["dato"])(values)
  };
};

exports.toApiInsolutoCfPiMancante = toApiInsolutoCfPiMancante;
const insolutoSoggettoMancanteEmptyForm = {
  insolutoAggId: "",
  soggettoRiferito: "",
  anagrafica: null
};
exports.insolutoSoggettoMancanteEmptyForm = insolutoSoggettoMancanteEmptyForm;
const insolutoSoggettoMancanteValidationSchema = Yup.object().shape({
  soggettoRiferito: Yup.string().required(_utils.requiredMessage).nullable(),
  anagrafica: Yup.object().required(_utils.requiredMessage).nullable()
});
exports.insolutoSoggettoMancanteValidationSchema = insolutoSoggettoMancanteValidationSchema;