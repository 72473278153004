"use strict";

/**
 * Created by GB on 15/10/2019
 *
 * Visualizza una form per editare un documento
 */
(function () {
  'use strict';

  angular.module('polcity.documenti').component('documentoForm', {
    templateUrl: 'osFramework/documenti/form/documentoForm.component.html',
    controller: 'documentoFormController',
    controllerAs: 'documentoFormCtrl',
    bindings: {
      documento: '<?',
      onDocumentoChange: '&?',
      onValidityChange: '&?'
    }
  });
})();