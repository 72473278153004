"use strict";

/**
 * Created by MN 10/12/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('contoEconomico', {
    templateUrl: 'osContabilita/bilancio/contoEconomico/contoEconomico.component.html',
    controller: 'ContoEconomicoController',
    controllerAs: 'contoEconomicoCtrl',
    bindings: {
      data: '<'
    }
  });
})();