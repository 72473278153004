"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.depositiFermiSequestriListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const depositiFermiSequestriListColumns = [{
  field: 'codiceVeloce',
  headerName: 'Codice veloce',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'comune',
  headerName: 'Comune',
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  valueGetter: ({
    value
  }) => value ? value.nomeCompleto : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'indirizzo',
  headerName: 'Indirizzo',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? value : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'civico',
  headerName: 'Civico',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? value : '--',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.depositiFermiSequestriListColumns = depositiFermiSequestriListColumns;