"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localizzazioneValidationSchema = exports.fromApiLocalizzazione = exports.toApiLocalizzazione = exports.localizzazioneEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const localizzazioneEmptyForm = {
  descrizione: "",
  lingueAttive: [],
  valuta: "",
  defaultLocalizzazione: false
};
exports.localizzazioneEmptyForm = localizzazioneEmptyForm;

const toApiLocalizzazione = values => {
  const localizzazione = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    ...(values.entityId ? {
      entityId: values.entityId
    } : {}),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(values),
    lingueAttive: (0, _object.getPathArrayOrEmptyArray)(["lingueAttive"])(values).map(x => x.languageTag).toString(),
    idLocale: (0, _object.getPathArrayOrEmptyArray)(["lingueAttive"])(values).map(x => x.languageTag).toString(),
    valuta: (0, _object.getPathStringOrEmptyString)(["valuta"])(values),
    defaultLocalizzazione: (0, _object.getPathBooleanOrFalse)(["defaultLocalizzazione"])(values)
  };
  return localizzazione;
};

exports.toApiLocalizzazione = toApiLocalizzazione;

const fromApiLocalizzazione = values => {
  const localizzazione = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    ...(values.entityId ? {
      entityId: values.entityId
    } : {}),
    descrizione: (0, _object.getPathStringOrEmptyString)(["descrizione"])(values),
    lingueAttive: (0, _object.getPathStringOrEmptyString)(["lingueAttive"])(values).split(',').map(languageTag => Object.assign({
      languageTag: languageTag
    })),
    valuta: (0, _object.getPathStringOrEmptyString)(["valuta"])(values),
    defaultLocalizzazione: (0, _object.getPathBooleanOrFalse)(["defaultLocalizzazione"])(values)
  };
  return localizzazione;
};

exports.fromApiLocalizzazione = fromApiLocalizzazione;
const localizzazioneValidationSchema = Yup.object().shape({
  descrizione: Yup.string().required(_utils.requiredMessage).nullable(),
  lingueAttive: Yup.array().ensure().min(1, _utils.requiredMessage).of(Yup.object()),
  valuta: Yup.string().required(_utils.requiredMessage).nullable(),
  defaultLocalizzazione: Yup.boolean().required(_utils.requiredMessage).nullable()
});
exports.localizzazioneValidationSchema = localizzazioneValidationSchema;