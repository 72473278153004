"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('Spedizione', ['restmod', function (restmod) {
    return restmod.model('spedizione').mix({
      $extend: {
        Scope: {
          $config: {
            baseEntityPath: 'spedizione/',
            api: {
              list: {
                url: 'ricerca/spedizioni',
                method: 'GET'
              },
              destroy: {
                url: 'lotto/comando/cancella-spedizioni',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', {
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl('list');
            };

            return collection;
          }
        },
        Record: {
          $getSpedizioneVerbale: function (_params) {
            return this.$action(function () {
              var request = this.$buildRequest('list');
              request.params = Object.assign({}, {
                verbaleId: _params.verbaleId,
                order: _params.order
              });
              this.$dispatch('before-get-spedizione-verbale', [request]);
              this.$send(request, function (_response) {
                this.$unwrap(_response.data);
                this.$dispatch('after-get-spedizione-verbale', [_response.data]);
              }, function (_response) {
                this.$dispatch('after-get-spedizione-verbale-error', [_response]);
              });
            });
          },
          //Override della defaul $destroy
          $destroy: function (_params) {
            return this.$action(function () {
              if (this.spedizioneId) {
                var request = this.$buildRequest('destroy', false);
                request.data = Object.assign({}, {
                  aggId: _params.aggId,
                  spedizioni: [_params.spedizioneId]
                });
                this.$dispatch('before-destroy', [request]).$send(request, function (_response) {
                  // remove from scope
                  if (this.$scope.$remove) {
                    this.$scope.$remove(this);
                  }

                  this.$unwrap(this.$getResponseData(_response));
                  this.$dispatch('after-destroy', [_response]);
                }, function (_response) {
                  this.$dispatch('after-destroy-error', [_response]);
                });
              } else {
                // If not yet bound, just remove from parent
                if (this.$scope.$remove) {
                  this.$scope.$remove(this);
                }
              }
            });
          }
        }
      }
    });
  }]);
})();