"use strict";

/**
 * Created by dan on 06/05/17.
 */
(function () {
  'use strict';

  angular.module('polcity.factories').provider('routesProviderFactory', [routesProviderFactory]);

  function routesProviderFactory() {
    this.$get = exposeExtend;
    this.extend = extend;

    function exposeExtend() {
      return {
        extend: extend
      };
    }

    function extend(obj) {
      obj.$get = ['$injector', 'uiEvents', 'navigationKnownRoutes', publishRoutes.bind(obj)];
      obj.list = getRoutes.bind(obj);
    }

    function publishRoutes($injector, uiEvents, navigationKnownRoutes) {
      var self = this;
      return {
        subscribeUIEvents: subscribeUIEvents
      };

      function subscribeUIEvents() {
        var rScope = $injector.get('$rootScope');
        rScope.$on(uiEvents.publishRoutes, function () {
          navigationKnownRoutes.push(self.routes);
        });
      }
    }

    function getRoutes() {
      return this.routes;
    }
  }
})();