"use strict";

/**
 * Created by dan on 01/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity').run(['$rootScope', 'uiEvents', 'breadcrumbs', listenRoutingEvents]);

  function listenRoutingEvents($rootScope, uiEvents, breadcrumbs) {
    R.forEach(function (listener) {
      $rootScope.$on('$stateChangeStart', listener);
    }, [hideLayers]);
    R.forEach(function (listener) {
      $rootScope.$on('$stateChangeSuccess', listener);
    }, [setPageNameClass, setBreadcrumbs]);
    R.forEach(function (listener) {
      $rootScope.$on('$stateNotFound', listener);
    }, [propagateErrorWithToast]);
    R.forEach(function (listener) {
      $rootScope.$on('$stateChangeError', listener);
    }, [propagateErrorWithToast]);

    function setPageNameClass(event, toState) {
      $rootScope.routeName = toState.name.replace('.', '-');
    }

    function hideLayers() {
      $rootScope.$broadcast(uiEvents.hideLayers);
      $rootScope.$emit(uiEvents.hideLayers);
    }

    function setBreadcrumbs(event, toState) {
      breadcrumbs.setPath(toState.breadcrumbs);
    }

    function propagateErrorWithToast(event, toState, toParams, fromState, fromParams, error) {
      if (!error) {
        error = event;
      }

      $rootScope.$emit(uiEvents.showToast, {
        isSuccess: false,
        message: error.name + ' ' + (error.message ? error.message : '')
      });
    }
  }
})();