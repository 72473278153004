"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setContext = exports.getVisuraTargaData = void 0;
const beHost = window._env_.BE_HOST;
const bePort = window._env_.BE_PORT;
const sorgenteVisuraTarga = window._env_.SORGENTE_VISURA_TARGA || "DTT";
const sorgenteDati = ["DTT"];
const metodoEffettuazioneVisura = "SERVIZI";
const indirizzoBackend = `${beHost}${bePort ? `:${bePort}` : ''}`;
const rifAggIdContestoLibera = "00000000-0000-0000-0000-000000000000";
const visuraTypes = {
  "VISURA_TARGA": "targa",
  "VISURA_PATENTE": "patente",
  "VISURA_PROPRIETARIO": "proprietario",
  "VISURA_CARTA_CIRCOLAZIONE": "cartaCircolazione",
  "VISURA_OMOLOGAZIONE_VEICOLO": "omologazioneVeicolo",
  "VISURA_DETTAGLIO_PATENTE": "dettaglioPatente",
  "VISURA_INFRAZIONI_PATENTE": "infrazioniPatente",
  "VISURA_COPERTURA_ASSICURATIVA_SCADENZA_REVISIONE": "coperturaAssScadenzaRev"
};
const additionalData = {
  "VISURA_TARGA": ["classificazioneMotorizzazione", "targa"],
  "VISURA_PATENTE": ["numeroPatente", "pdf", "pdfAnteprimaPatente"],
  "VISURA_PROPRIETARIO": ["codiceFiscale", "pdf"],
  "VISURA_CARTA_CIRCOLAZIONE": ["classificazioneMotorizzazione", "targa"],
  "VISURA_OMOLOGAZIONE_VEICOLO": ["codiceOmologazione"],
  "VISURA_DETTAGLIO_PATENTE": ["numeroPatente"],
  "VISURA_INFRAZIONI_PATENTE": ["numeroPatente"],
  "VISURA_COPERTURA_ASSICURATIVA_SCADENZA_REVISIONE": ["classificazioneMotorizzazione", "targa", "dataRiferimento"]
};
const base = {
  sorgenteDati,
  metodoEffettuazioneVisura,
  indirizzoBackend: indirizzoBackend,
  nomeCoda: "REQUEST_VENASCA"
};

const getVisuraTargaData = (sorgenteDati, classificazioneMotorizzazione, targa) => ({ ...base,
  sorgenteDati: [sorgenteDati],
  tipoOperazione: "VISURA_TARGA",
  classificazioneMotorizzazione,
  targa
});

exports.getVisuraTargaData = getVisuraTargaData;

const setContext = (visura, contestoVisura = "LIBERA", rifAggId, nomeCoda) => {
  if (!visura) throw "Missing argument exception (visura)";
  return { ...visura,
    contestoVisura,
    rifAggId: contestoVisura === "LIBERA" ? rifAggIdContestoLibera : rifAggId,
    nomeCoda: `REQUEST_${nomeCoda}`
  };
}; // const visura = Object.keys(visuraTypes).reduce((acc, visuraType) => {
//     acc[visuraTypes[visuraType]] = (    ) => {
//         ...base,
//
//     }
// }, {})
//
// const getVisuraTargaPayload = (classificazioneMotorizzazione, targa) => ({
//     ...base,
//     classificazioneMotorizzazione,
//     targa
// })
// //TARGA
// const payload = {
//     "sorgenteDati": ["DTT"],
//     "metodoEffettuazioneVisura":"SERVIZI",
//     "indirizzoBackend":"localhost:8080",
//
//     "tipoOperazione":"VISURA_TARGA",
//
//     "classificazioneMotorizzazione":"AUTOVEICOLO",
//     "targa":"TV805507",
//
//     "nomeCoda":"TEST_VENASCA", //da agenteVisura api
//     "contestoVisura":"LIBERA", // da contesto visura api
//     "rifAggId":"00000000-0000-0000-0000-000000000000", // dipende dal contesto
//
//
// }
// //PATENTE
// const data = {
//     "sorgenteDati": ["DTT"],
//     "metodoEffettuazioneVisura": "SERVIZI",
//
//     "tipoOperazione": "VISURA_PATENTE",
//     "dettaglioPatenteRequestType": {
//         "login": {},
//         "ambitoPatenteBase": {
//             "patente": {
//                 "numeroPatente": "TV5121705B"
//             }
//         },
//         "pdf": false,
//         "pdfAnteprimaPatente": false
//     },
//     "nomeCoda": "TEST_VENASCA",
//     "indirizzoBackend": "localhost:8080"
// }
// //PROPRIETARIO
// const prop = {
//     "sorgenteDati": ["DTT"],
//     "metodoEffettuazioneVisura":"SERVIZI",
//     "tipoOperazione":"VISURA_PROPRIETARIO",
//     "personaFisicaVeicoliRequestType": {
//         "login": {},
//         "ambitoPersonaFisicaVeicoli":{
//             "codiceFiscale":"FRLFBA82M01L407L"
//         },
//         "pdf":false
//     },
//     "nomeCoda":"TEST_VENASCA",
//     "indirizzoBackend":"localhost:8080"
// }
// //CARTA CIRCOLAZIONE
// const carta = {
//     "sorgenteDati": ["DTT"],
//     "metodoEffettuazioneVisura":"SERVIZI",
//     "tipoOperazione":"VISURA_CARTA_CIRCOLAZIONE",
//     "classificazioneMotorizzazione":"AUTOVEICOLO",
//     "targa":"FV379ED",
//     "nomeCoda":"TEST_VENASCA",
//     "indirizzoBackend":"localhost:8080"
// }
// //OMOLOGAZIONE VEICOLO
// const omog = {
//     "sorgenteDati": ["DTT"],
//     "metodoEffettuazioneVisura":"SERVIZI",
//     "tipoOperazione":"VISURA_OMOLOGAZIONE_VEICOLO",
//     "codiceOmologazione" : "VE18322CPACT/18",
//     "nomeCoda":"TEST_VENASCA",
//     "indirizzoBackend":"localhost:8080"
// }
// //DETTAGLIO PATENTE
// const dett_pat = {
//     "sorgenteDati": [
//         "DTT"
//     ],
//     "metodoEffettuazioneVisura": "SERVIZI",
//     "tipoOperazione": "VISURA_DETTAGLIO_PATENTE",
//     "numeroPatente": "TV5121705B",
//     "nomeCoda": "TEST_VENASCA",
//     "indirizzoBackend": "localhost:8080"
// }
// //INFRAZIONI PATENTE
// const infr = {
//     "sorgenteDati": [
//         "DTT"
//     ],
//     "metodoEffettuazioneVisura": "SERVIZI",
//     "tipoOperazione": "VISURA_INFRAZIONI_PATENTE",
//     "numeroPatente": "TV5121705B",
//     "nomeCoda": "TEST_VENASCA",
//     "indirizzoBackend": "localhost:8080"
// }
// // COP ASS SCAD REV
// const copass = {
//     "sorgenteDati": ["DTT"],
//     "metodoEffettuazioneVisura":"SERVIZI",
//     "tipoOperazione":"VISURA_COPERTURA_ASSICURATIVA_SCADENZA_REVISIONE",
//     "classificazioneMotorizzazione":"AUTOVEICOLO",
//     "targa":"FV379ED",
//     "dataRiferimento":"2020-07-16",
//     "nomeCoda":"TEST_VENASCA",
//     "indirizzoBackend":"localhost:8080"
// }


exports.setContext = setContext;