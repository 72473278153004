"use strict";

(function () {
  'use strict';

  angular.module('polcity.filters').filter('justExtension', function () {
    return function (input) {
      var ext = R.last(input.split('.'));
      return ext;
    };
  });
})();