"use strict";

/**
 * Created by gbelli on 13/09/2019.
 */
(function () {
  'use strict';

  angular.module('polcity.services').service('EnumService', ['Command', '$q', EnumService]);

  function EnumService(Command, $q) {
    var enums = {
      //promises
      //ricorso
      tipiRicorso: null,
      statiRicorso: null,
      modalitaPresentazioneRicorso: null,
      ricorsoPresentatoA: null,
      ricorsoTipiParere: null,
      sospensiveRicorso: null,
      //verbale
      statiVerbale: null,
      tipiTrasgressore: null,
      mezziNotifica: null,
      esitiSpedizione: null,
      posizioniRelative: null,
      //fermi sequestro
      tipiFermoSequestro: null,
      //versamenti
      tipiVersamento: null,
      metodiVersamento: null,
      tipiTracciatoVersamento: null,
      //documenti
      tipiDocumentoPersonale: null,
      categorieParente: null,
      spefificheCqc: null,
      //contabilita
      contiEconomici: null,
      //enum
      CalcoloVelocitaEnum: null,
      InviaRiceviPcEnum: null,
      TracciatoInvioEnum: null,
      TipoStampanteEnum: null,
      TipiBollettariAccertamentoEnum: null,
      NrRegistroAccertamentiEnum: null,
      PuntiPersoneFisicheEnum: null,
      ProgressivoRicorsiEnum: null,
      NomeAllegatiSanaEnum: null,
      ModalitaRipetizioneInserimentoEnum: null,
      DataRilevazioneVerbalizzazioneMassivaEnum: null,
      ObbligatorioNonObblitatorioEnum: null,
      ApplicazioneSpeseBolloEnum: null,
      ScontoInfrazioniEnum: null,
      MisuraRidottaScontoEnum: null,
      StampaFineTurnoEnum: null,
      InserimentoPagamentoEnum: null,
      EsportazioneInfrazioniEnum: null,
      StatoAcquisizioneZtlEnum: null,
      TipoVisualizzazioneFotoEnum: null,
      IterRilevazioneEnum: null,
      TipoSpeseDaImpostareEnum: null,
      TipiVerbale: null,
      TipiCodice: null,
      Competenze: null,
      TipiProtocolli: null,
      TipiDisponibilita: null,
      TipiVisibilita: null,
      TipiEnti: null,
      TipiMezzi: null,
      TipiMotori: null,
      Sessi: null,
      DecoratoriAccessorie: null,
      TipiAnagrafica: null,
      TipiOperazioniVisure: null,
      StatusVisura: null,
      SistemiOperativi: null,
      ViolazioneNatura: null,
      StatiFermoSequestro: null,
      TipiTrasportoACuraDi: null,
      ListaTipiAffidamento: null,
      ListaSorgentiVisura: null,
      ListaStatiDecurtazioniPunti: null,
      ListaPareriControdeduzione: null
    },
        enumCommandMap = {
      //ricorso
      tipiRicorso: '@recuperaTipiRicorso',
      statiRicorso: '@recuperaStatiRicorso',
      modalitaPresentazioneRicorso: '@recuperaModalitaPresentazioneRicorso',
      ricorsoPresentatoA: '@recuperaRicorsoPresentatoA',
      ricorsoTipiParere: '@recuperaRicorsoTipiPareri',
      sospensiveRicorso: '@recuperaSospensiveRicorso',
      //verbale
      statiVerbale: '@recuperaStatiVerbale',
      tipiTrasgressore: '@recuperaTipiTrasgressore',
      mezziNotifica: '@recuperaMezziNotifica',
      esitiSpedizione: '@recuperaEsitiSpedizione',
      posizioniRelative: '@recuperaPosizioniRelative',
      //fermi sequestro
      tipiFermoSequestro: '@recuperaTipiFermoSequestro',
      //versamenti
      tipiVersamento: '@tipiVersamento',
      metodiVersamento: '@metodiVersamento',
      tipiTracciatoVersamento: '@recuperaTipiTracciatoVersamento',
      //documenti
      tipiDocumentoPersonale: '@recuperaTipiDocumentoPersonale',
      categoriePatente: '@recuperaCategoriePatente',
      spefificheCqc: '@recuperaSpecificheCQC',
      //contabilita
      contiEconomici: '@recuperaContiEconomici',
      // enums
      CalcoloVelocitaEnum: '@calcoloVelocita',
      InviaRiceviPcEnum: '@inviaRiceviPc',
      TracciatoInvioEnum: '@tracciatoInvio',
      TipoStampanteEnum: '@tipoStampante',
      TipiBollettariAccertamentoEnum: '@tipiBollettariAccertamento',
      NrRegistroAccertamentiEnum: '@nrRegistroAccertamenti',
      PuntiPersoneFisicheEnum: '@puntiPersoneFisiche',
      ProgressivoRicorsiEnum: '@progressivoRicorsi',
      NomeAllegatiSanaEnum: '@nomeAllegatiSana',
      ModalitaRipetizioneInserimentoEnum: '@modalitaRipetizioneInserimento',
      DataRilevazioneVerbalizzazioneMassivaEnum: '@dataRilevazioneVerbalizzazioneMassiva',
      ObbligatorioNonObblitatorioEnum: '@obbligatorioNonObblitatorio',
      ApplicazioneSpeseBolloEnum: '@applicazioneSpeseBollo',
      ScontoInfrazioniEnum: '@scontoInfrazioni',
      MisuraRidottaScontoEnum: '@misuraRidottaSconto',
      StampaFineTurnoEnum: '@stampaFineTurno',
      InserimentoPagamentoEnum: '@inserimentoPagamento',
      EsportazioneInfrazioniEnum: '@esportazioneInfrazioni',
      StatoAcquisizioneZtlEnum: '@statoAcquisizioneZtl',
      TipoVisualizzazioneFotoEnum: '@tipoVisualizzazioneFoto',
      IterRilevazioneEnum: '@iterRilevazione',
      TipoSpeseDaImpostareEnum: '@tipoSpeseDaImpostare',
      TipiVerbale: '@recuperaTipiVerbale',
      TipiCodice: '@recuperaTipiCodice',
      Competenze: '@recuperaCompetenze',
      TipiProtocolli: '@recuperaTipiProtocollo',
      TipiDisponibilita: '@recuperaDisponibilitaAllegato',
      TipiVisibilita: '@recuperaVisibilitaFunzioneStampa',
      TipiEnti: '@recuperaTipiEnte',
      TipiMezzi: '@recuperaTipiMezzo',
      TipiMotori: '@recuperaTipiMotore',
      Sessi: '@recuperaSessi',
      DecoratoriAccessorie: '@recuperaDecoratoriAccessorie',
      TipiAnagrafica: '@recuperaTipiAnagrafica',
      TipiOperazioniVisure: '@tipiOperazioniVisure',
      StatusVisura: '@statusVisura',
      SistemiOperativi: '@sistemiOperativi',
      ViolazioneNatura: '@violazioneNatura',
      StatiFermoSequestro: '@statiFermoSequestro',
      TipiTrasportoACuraDi: '@trasportoACuraDi',
      ListaTipiAffidamento: '@listaTipiAffidamento',
      ListaSorgentiVisura: '@listaSorgentiVisure',
      ListaStatiDecurtazioniPunti: '@listaStatiDecurtazionePunti',
      ListaPareriControdeduzione: "@listaPareriControdeduzioni"
    };

    var filterResult = (filter = "") => result => {
      var filteredResult;

      if (!filter) {
        filteredResult = result;
      }

      filteredResult = result.filter(item => item.label.toLowerCase().includes(filter.toLowerCase()));
      return filteredResult;
    };

    function fetchEnum(type) {
      var enumPromise;

      if (!enumCommandMap[type]) {
        return $q.when({});
      }

      if (enums[type]) {
        enumPromise = enums[type];
      } else {
        enumPromise = enums[type] = Command.execute(enumCommandMap[type]);
      }

      return enumPromise;
    }

    return {
      getEnum: getEnum,
      getEnumItem: getEnumItemByAlias,
      getEnumLabel: getEnumLabelByAlias
    };

    function getEnum(type, filter) {
      return fetchEnum(type).then(filterResult(filter));
    }

    function getEnumItemByAlias(type, alias) {
      return fetchEnum(type).then(function (result) {
        return R.find(R.propEq('alias', alias))(result);
      });
    }

    function getEnumLabelByAlias(type, alias) {
      return getEnumItemByAlias(type, alias).then(function (item) {
        return item ? item.label : "-";
      });
    }
  }
})();