"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('OsApi', ['restmod', 'RMUtils', function (restmod, Utils) {
    return restmod.mixin({
      $config: {
        style: 'OsApi',
        // By setting the style variable the warning is disabled.
        primaryKey: 'aggId'
        /* other style related configuration */

      }
    }, function () {
      /**
       * Stragegie di interpretazione della risposta di os-backend
       * Per una collection:
       * 1) salva alcune info nei $metaData
       * 2) restituisce l'array di valori
       */
      this.define('Model.unpack', function (_resource, _raw) {
        var path = [],
            metadata;

        if (_resource.$isCollection) {
          path = ['resultsWrapper', 'values'];
          metadata = {
            total: _raw.resultsWrapper.count,
            search: _raw.search,
            filters: _raw.filters
          };
          _resource.$metadata = Object.assign({}, _resource.$metadata, metadata);
        }

        return R.path(path, _raw);
      });
    }, function () {
      /**
       * Metodo di utilità che costruisce un oggetto request.
       * @param api {string} nome servizio che si sta richiamando
       */
      this.define('Resource.$buildRequest', function (api, addBaseEntityPath = true) {
        var url = addBaseEntityPath ? this.$scope.$getBaseEntityPath() : '';
        var req = {
          url: api ? url + this.$scope.$getUrl(api) : url,
          method: api ? this.$scope.$getMethod(api) : 'GET',
          headers: api ? this.$scope.$getHeaders(api) : {},
          //To correctly send xhr request
          responseType: api ? this.$scope.$getResponseType(api) || 'json' : 'json'
        };
        return req;
      });
    }, function () {
      /**
       * Medoto di utilità che restituisce il payload della response
       */
      this.define('Record.$getResponseData', function (_response) {
        var data;

        switch (_response.status) {
          case 204:
            //No Content
            data = {};
            break;

          default:
            data = _response.data;
            break;
        }

        return data;
      });
    }, function () {
      /**
       * Alcuni metodi di utilità
       */
      this.define('Scope.$getUrl', function (api) {
        return this.$config.api[api].url;
      }).define('Scope.$getMethod', function (api) {
        return this.$config.api[api].method;
      }).define('Scope.$getHeaders', function (api) {
        return this.$config.api[api].headers;
      }).define('Scope.$getResponseType', function (api) {
        return this.$config.api[api].responseType;
      }).define('Scope.$getBaseEntityPath', function () {
        return this.$config.baseEntityPath;
      }).define('Record.$getModelKeys', function () {
        var modelKeys = [];

        for (var key in this) {
          if (this.hasOwnProperty(key) && key[0] !== '$') {
            modelKeys.push(key);
          }
        }

        return modelKeys;
      }).define('Record.$getModel', function () {
        var model = {},
            keys = this.$getModelKeys();

        for (var i = 0; i < keys.length; i++) {
          model[keys[i]] = this[keys[i]];
        }

        return model;
      });
    }, function () {
      //Salva nel localstorage lo stato di una collection
      //TODO: che sia il caso di fare un mixin "Stateful" (SI)
      this.define('Collection.$saveState', function () {
        var state = {
          search: this.$metadata.search,
          filters: this.$activeFilters.filters,
          elasticFilters: this.$activeFilters.elasticFilters,
          selectedItem: this.$selectedItem ? this.$selectedItem.$getModel() : null
        };
        localStorage.setItem(`pc-${this.$config.name}-list`, JSON.stringify(state));
      });
    }, function () {
      //Recupera dal localstorage lo stato di una collection
      //TODO: che sia il caso di fare un mixin "Stateful"
      this.define('Collection.$getState', function () {
        var state = JSON.parse(localStorage.getItem(`pc-${this.$config.name}-list`));
        return state;
      });
    }, function () {
      //Metodo per aggiungere più di un record alla volta ad una collection
      this.define('Collection.$bulkAdd', function (_objs) {
        var me = this;
        Utils.assert(_objs instanceof Array, 'Collection $bulkAdd expects parameter to be an Array');

        _objs.forEach(function (_obj) {
          Utils.assert(_obj.$type && _obj.$type === me.$type, 'Collection $bulkAdd expects record of the same $type');
        });

        return this.$action(function () {
          _objs.forEach(function (_obj) {
            me.push(_obj);
          });

          this.$dispatch('after-bulk-add', [_objs]);
        });
      });
    });
  }]);
})();