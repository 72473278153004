"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGruppoComandoId = exports.useStazioneComandoId = exports.default = exports.useIsSuperUser = void 0;

var _react = require("react");

var _app = require("../../../app");

var _recoil = require("recoil");

var _atoms = require("../../../state/global/atoms");

const useKeycloakUserAttributes = () => {
  const [attributes, setAttributes] = (0, _react.useState)([]);
  (0, _react.useEffect)(() => {
    _app.keycloakAuth.loadUserProfile().then(profile => {
      setAttributes(profile.attributes);
    }).catch(console.error);
  }, []);
  return attributes;
};

const useIsSuperUser = () => {
  const attributes = useKeycloakUserAttributes(); // La condizione è debole ma si basa su un accordo sull'uso degli attributi di keycloak. L'array super conterrà sempre solo un elemento "true"|"false" (stringa)

  const isSuperUser = Array.isArray(attributes.super) && attributes.super[0] === "true";
  return isSuperUser;
};

exports.useIsSuperUser = useIsSuperUser;
var _default = useKeycloakUserAttributes;
exports.default = _default;

const useStazioneComandoId = () => {
  const attributes = (0, _recoil.useRecoilValue)(_atoms.keycloakUserAttributes);
  const stazioneComandoId = attributes["stazione-comando"] ? attributes["stazione-comando"][0] : undefined;
  return stazioneComandoId;
};

exports.useStazioneComandoId = useStazioneComandoId;

const useGruppoComandoId = () => {
  const attributes = (0, _recoil.useRecoilValue)(_atoms.keycloakUserAttributes);
  return attributes["gruppo-comando"] ? attributes["gruppo-comando"][0] : undefined;
};

exports.useGruppoComandoId = useGruppoComandoId;