"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRimozioniMasterDetail = exports._availableSorters = exports._availableFilters = exports.rimozioniMasterDetailName = void 0;

var _recoil = require("recoil");

var _masterDetail = require("../generics/masterDetail");

var _quickSearch = require("../generics/quickSearch");

var _api = require("../../api");

var _rimozione = require("./rimozione");

const rimozioniMasterDetailName = "rimozioni";
exports.rimozioniMasterDetailName = rimozioniMasterDetailName;

const _availableFilters = (0, _recoil.atom)({
  key: '_availableFiltersRimozioni',
  default: [{
    name: 'verbale',
    label: 'Verbale',
    value: null
  }, {
    name: 'numeroRegistro',
    label: 'Numero registro',
    value: null
  }]
});

exports._availableFilters = _availableFilters;

const _availableSorters = (0, _recoil.atom)({
  key: '_availableSortersRimozioni',
  default: [{
    name: 'verbaleId',
    label: 'Verbale',
    direction: null
  }, {
    name: 'verbaleEtichettaRegistro',
    label: 'Numero registro',
    direction: null
  }]
});

exports._availableSorters = _availableSorters;
const useRimozioniQuickSearch = (0, _quickSearch.useQuickSearchReactQuery)({
  apiCallback: ({
    page,
    limit,
    order,
    verbale,
    numeroRegistro
  }) => (0, _api.ricercaRimozioni)({
    page,
    limit,
    order,
    verbaleId: verbale === null || verbale === void 0 ? void 0 : verbale.idVerbale,
    verbaleEtichettaRegistro: numeroRegistro
  })
});

const useRimozioniMasterDetail = props => {
  const {
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  } = props || {};
  const availableSorters = (0, _recoil.useRecoilValue)(_availableSorters);
  const availableFilters = (0, _recoil.useRecoilValue)(_availableFilters);
  const {
    open,
    selectionActive,
    activeItem,
    setState,
    reset: resetState
  } = (0, _masterDetail.useMasterDetailState)({
    name,
    open: openProp,
    activeItem: activeItemProp,
    selectionActive: selectionActiveProp
  });
  const {
    page,
    limit,
    items,
    isLoading,
    count,
    filters,
    activeFilters,
    activeSorters,
    activeSortersCount,
    changePage,
    changeLimit,
    addFilter,
    removeFilter,
    setActiveSorters,
    refresh,
    reset
  } = useRimozioniQuickSearch({
    resourceKeys: _rimozione.rimozioniKeys,
    name,
    page: pageProp,
    limit: limitProp,
    activeFilters: activeFiltersProp,
    activeSorters: activeSortersProp
  });

  const resetMasterDetail = () => {
    reset();
    resetState();
  };

  return {
    page,
    limit,
    isLoading: isLoading,
    items: items,
    count: count,
    availableFilters,
    activeFilters,
    changePage,
    changeLimit,
    addFilter: _props => {
      activeFilters.length && removeFilter(_props.name);
      addFilter(_props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    removeFilter: _props => {
      removeFilter(_props);
      setState({
        prop: 'activeItem',
        value: ""
      });
    },
    availableSorters,
    activeSorters,
    activeSortersCount,
    setSorters: setActiveSorters,
    refresh: refresh,
    //Master detail props
    open,
    selectionActive,
    activeItem,
    setState,
    resetMasterDetail
  };
};

exports.useRimozioniMasterDetail = useRimozioniMasterDetail;