"use strict";

(function () {
  'use strict';

  angular.module('polcity.filters').filter('enum', ['EnumService', function (EnumService) {
    var data = {},
        // DATA RECEIVED ASYNCHRONOUSLY AND CACHED HERE
    invoked = {};

    function getEnumLabel(input, type) {
      // REAL FILTER LOGIC
      var label = "-",
          item;

      if (data[type]) {
        if (item = R.find(R.propEq('alias', input))(data[type])) {
          label = item.label;
        }
      }

      return label;
    }

    function enumFilter(input, type) {
      if (!data[type]) {
        if (!invoked[type]) {
          invoked[type] = EnumService.getEnum(type).then(function (results) {
            data[type] = results;
          });
        }

        return "-"; // PLACEHOLDER WHILE LOADING, COULD BE EMPTY
      } else return getEnumLabel(input, type);
    }

    return enumFilter;
  }]);
})();