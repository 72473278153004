"use strict";

/**
 * Created by MN 16/07/2020
 */
(function () {
  'use strict';

  angular.module('polcity.visure').component('visureList', {
    templateUrl: 'visure/list/visureList.component.html',
    controller: 'visureListController',
    controllerAs: 'visureListCtrl'
  }).controller('visureListController', ['$scope', '$rootScope', '$stateParams', 'Visura', 'lastInsertedElementsService', '$timeout', VisureListController]);

  function VisureListController($scope, $rootScope, $stateParams, Visura, lastInsertedElementsService, $timeout) {
    var me = this;
    /**
     * Confiurazioni iniziali della collection Ricorso
     */

    $scope.visure = Visura.$collection({
      page: 1,
      limit: 10
    });
    $scope.visure.$on('before-fetch-many', function (res) {
      $scope.isLoadingList = true;
    });
    $scope.visure.$on('after-fetch-many', function (res) {
      $scope.isLoadingList = false;
      $scope.page = $scope.visure.$metadata.search.page;
      $scope.limit = $scope.visure.$metadata.search.limit;
      $scope.total = $scope.visure.$metadata.total;
      $scope.filters = [{
        name: 'tipoOperazione',
        label: 'Tipo operazione',
        type: 'enum',
        enumType: 'TipiOperazioniVisure'
      }, {
        name: 'targa',
        label: 'Targa',
        type: 'text',
        placeholder: 'es. AA000BB'
      }, {
        name: 'nome',
        label: 'Nome',
        type: 'text',
        placeholder: 'es. Luca'
      }, {
        name: 'cognome',
        label: 'Cognome',
        type: 'text',
        placeholder: 'es. Verdi'
      } // {name: 'dataOraRichiesta', label: 'Data ora richiesta', type: 'dateRange'},
      // {name: 'dataOraRisposta', label: 'Data ora risposta', type: 'dateRange'}
      ];
      $scope.activeFilters = $scope.visure.$getActiveFilters();
      $scope.activeSorters = $scope.visure.$getActiveSorters();
      $scope.visure.$saveState();
    });
    $scope.visure.$on('after-fetch-many-error', function (res) {
      $scope.isLoadingList = false; //TODO: do something with errors
    });
    $scope.$on('after-visura-added', function ($event, _res) {
      $scope.isLoadingData = true;
      lastInsertedElementsService.updateLastInsertedElement({
        section: $scope.visure.$scope.$config.name,
        aggId: _res.aggId
      });
      $timeout(function () {
        $scope.visure.$ricercaVeloce().$then(function () {
          $scope.isLoadingData = false;
        });
      }, 1000);
    }, true);

    me.$onInit = function () {
      var state,
          params = {},
          selectedItem = null; //Ripristino lo stato precedente

      if ($stateParams.reloadPreviousState) {
        state = $scope.visure.$getState();
        params = state.search || {};
        selectedItem = state.selectedItem || null;
      }

      $scope.visure.$ricercaVeloce(params).$then(function () {
        if (selectedItem) {
          $scope.selectedVisura = Visura.$new(selectedItem.aggId);
        }
      });
    };

    me.newVisuraBtnConf = {
      cssClass: 'md-fab md-accent os-save-fab',
      iconBtn: 'add'
    };
    $scope.sorters = {
      dataOraRichiesta: {
        showEmptyOption: true,
        direction: null
      },
      dataOraRisposta: {
        showEmptyOption: true,
        direction: null
      }
    }; //Handlers

    $scope.onChildTap = function (params) {
      $scope.visure.$saveState();
    };

    $scope.onSortAdd = function (params) {};

    $scope.onRemoveItemSelection = function (params) {
      $scope.visure.$saveState();
    };

    $scope.onSortRemove = function (params) {};

    $scope.onPageChange = function (params) {};

    $scope.onFilterAdd = function (params) {};

    $scope.onFilterRemove = function (params) {};

    $scope.onFilterReset = function () {};

    $scope.onToggleFollow = function () {};
  }
})();