"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.riferimentiComuniListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

var _utils = require("../../../utils");

const riferimentiComuniListColumns = [{
  field: 'nomeRiferimentoComune',
  headerName: 'Nome',
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'enteTerritoriale',
  headerName: 'Ente territoriale',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? (0, _utils.capitalize)(value.tipoEnteTerritoriale).concat(" ", value.nome) : '--',
  sortable: false,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'stato',
  headerName: 'Stato',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? value.nome : '--',
  sortable: false,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'cap',
  headerName: 'CAP',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'codiceISTAT',
  headerName: 'Codice istat',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'codiceBelfiore',
  headerName: 'Codice belfiore',
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'capoluogo',
  headerName: 'Capoluogo',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value ? "SI" : "NO",
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'dataInserimento',
  headerName: 'Data inserimento',
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  ..._DataGridColumnTypes.dataGridDateColumn
}];
exports.riferimentiComuniListColumns = riferimentiComuniListColumns;