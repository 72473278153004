"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.relazioneNomeSessoValidationSchema = exports.toApiRelazioneNomeSesso = exports.fromApiRelazioneNomeSesso = exports.relazioneNomeSessoEmptyForm = void 0;

var _object = require("../object.utils");

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const relazioneNomeSessoEmptyForm = {
  nome: "",
  sesso: ""
};
exports.relazioneNomeSessoEmptyForm = relazioneNomeSessoEmptyForm;

const fromApiRelazioneNomeSesso = remoteRelazione => {
  const relazione = { ...(remoteRelazione.aggId ? {
      aggId: remoteRelazione.aggId
    } : {}),
    nome: (0, _object.getPathStringOrNull)(["nome"])(remoteRelazione),
    sesso: (0, _object.getPathStringOrNull)(["sesso"])(remoteRelazione)
  };
  return relazione;
};

exports.fromApiRelazioneNomeSesso = fromApiRelazioneNomeSesso;

const toApiRelazioneNomeSesso = values => {
  const relazione = { ...(values.aggId ? {
      aggId: values.aggId
    } : {}),
    nome: (0, _object.getPathStringOrNull)(["nome"])(values),
    sesso: (0, _object.getPathStringOrNull)(["sesso"])(values)
  };
  return relazione;
};

exports.toApiRelazioneNomeSesso = toApiRelazioneNomeSesso;
const relazioneNomeSessoValidationSchema = Yup.object().shape({
  nome: Yup.string().required(_utils.requiredMessage).nullable(),
  sesso: Yup.string().required(_utils.requiredMessage).nullable()
});
exports.relazioneNomeSessoValidationSchema = relazioneNomeSessoValidationSchema;