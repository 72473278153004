"use strict";

(function () {
  'use strict';

  angular.module('polcity.models').factory('PresentazioneDocumento', ['restmod', function (restmod) {
    return restmod.model('presentazioneDocumento').mix({
      $extend: {
        Scope: {
          $config: {
            name: 'presentazioneDocumento',
            baseEntityPath: 'presentazione-documenti/',
            api: {
              list: {
                url: 'ricerca/presentazione-documenti',
                method: 'GET'
              },
              detail: {
                url: 'dettaglio/presentazione-documento',
                method: 'POST'
              }
            }
          }
        }
      }
    }, 'DirtyModel', 'Sortable', 'Pageable', 'Filterable', 'Selectable', {
      $extend: {
        Scope: {
          //Constructor
          $collection: function (_params, _scope) {
            var collection = this.$super(_params, _scope);

            collection.$url = function () {
              return this.$getBaseEntityPath() + this.$getUrl("list");
            };

            return collection;
          }
        },
        Resource: {},
        Record: {}
      }
    });
  }]);
})();