"use strict";

/**
 * Created by MN 07/08/2020
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('resolveEnum', ['$interpolate', 'EnumService', resolveEnumDirective]);

  function resolveEnumDirective($interpolate, EnumService) {
    return {
      restrict: 'A',
      link: linkFn
    };

    function linkFn(scope, elem, attrs) {
      var enumtype = attrs.resolveEnum,
          // TIPO DI ENUM DA RISOLVERE
      interpolateValue = $interpolate(elem.html(), true),
          enumAlias,
          // ALIAS
      enumLabel = '',
          // LABEL FINALE DA STAMPARE A VIDEO
      data = {}; // DATA RECEIVED ASYNCHRONOUSLY AND CACHED HERE
      // UTILIZZO $interpolate SERVICE PER OTTENERE IL VALORE DEL CAMPO

      scope.$watch(interpolateValue, function (newValue) {
        enumAlias = newValue ? newValue.trim().toLowerCase() : '--';
        retrieveEnumLabel();
      });

      function retrieveEnumLabel() {
        if (!data || !data[enumtype]) {
          getData();
        } else if (data && data[enumtype]) {
          getEnumLabel();
        }
      }

      function getData() {
        return EnumService.getEnum(enumtype).then(function (results) {
          data[enumtype] = results;
        }).catch(function (_err) {
          console.log(_err);
        }).finally(function () {
          getEnumLabel();
        });
      }

      function getEnumLabel() {
        var dataToLowerCase = data[enumtype].map(item => {
          return {
            alias: item.alias.toLowerCase(),
            label: item.label.toUpperCase()
          };
        }),
            item = R.find(R.propEq('alias', enumAlias))(dataToLowerCase);
        enumLabel = item.label;
        recompileHtml();
      }

      function recompileHtml() {
        elem.html(enumLabel);
      }
    }
  }
})();