"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SlateEditor", {
  enumerable: true,
  get: function () {
    return _SlateEditor.default;
  }
});
Object.defineProperty(exports, "serialize", {
  enumerable: true,
  get: function () {
    return _serializer.serialize;
  }
});
Object.defineProperty(exports, "deserialize", {
  enumerable: true,
  get: function () {
    return _serializer.deserialize;
  }
});

var _SlateEditor = _interopRequireDefault(require("./SlateEditor"));

var _serializer = require("./serializer");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }