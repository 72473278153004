"use strict";

(function () {
  'use strict';

  angular.module('polcity.verbali').controller('InfoContabiliVerbaleModalController', ['$filter', 'Command', '$mdDialog', 'OSNotification', InfoContabiliVerbaleModalController]);

  function InfoContabiliVerbaleModalController($filter, Command, $mdDialog, OSNotification) {
    var me = this;
    me.scrittureContabili = [];
    me.registrazioniContabili = {};
    me.column = [{
      text: 'Data versamento',
      dataIndex: 'data',
      renderer: function (item) {
        return moment(item.data).format('LL');
      }
    }, {
      text: 'Data registrazione',
      dataIndex: 'dateRegistrazione',
      renderer: function (item) {
        return moment(item.dateRegistrazione).format('LL');
      }
    }, {
      text: 'Conto',
      dataIndex: 'conto'
    }, {
      text: 'Descrizione',
      dataIndex: 'descrizione'
    }, {
      text: 'Dare',
      dataIndex: 'dareAvere',
      renderer: function (item) {
        return item.dareAvere === 'D' ? $filter('currency')(item.importo) : '';
      }
    }, {
      text: 'Avere',
      dataIndex: 'dareAvere',
      renderer: function (item) {
        return item.dareAvere === 'A' ? $filter('currency')(item.importo) : '';
      }
    }];

    me.$onInit = function () {
      fetchInfoContabili().then(setScrittureContabili).then(setRegistrazioniContabili).catch(function (_err) {
        OSNotification.warn({
          translationIndex: 'verbali.errorFetchingDatiContabili__notification'
        });
      });
    };

    me.$onChanges = function (changesObj) {};
    /**
     * Handlers
     */


    me.closeDialog = function () {
      $mdDialog.hide();
    };
    /**
     * Fetchers
     */


    function fetchInfoContabili() {
      return Command.execute('@contabilitaRicercaTestoLibero', {
        query: me.item.verbaleId,
        order: 'data,desc'
      });
    }
    /**
     * Setters
     */


    function setScrittureContabili(res) {
      me.scrittureContabili = res.resultsWrapper.values;
      me.total = res.resultsWrapper.count;
    }
    /**
     * Methods
     */


    function setRegistrazioniContabili() {
      // Una registrazione contabile è formata da una serie di scritture contabili che condividono lo stesso
      // registrazioneContabileId
      me.registrazioniContabili = R.groupBy(R.prop('registrazioneContabileId'), me.scrittureContabili);
    }
  }
})();