"use strict";

/**
 * Created by MN 09/10/2018
 */
(function () {
  'use strict';

  angular.module('polcity.appCommands', []).run(['appCommands', function (appCommands) {
    appCommands.publish();
  }]).factory('appCommands', ['CommandsList', 'URLS', appCommands]);

  function appCommands(CommandsList, URLS) {
    return {
      publish: publish
    };

    function publish() {
      CommandsList.add({
        // DASHBOARD
        '@dashboardRicercaVerbaliSeguiti': {
          method: URLS.dashboard.verbaliSeguiti.method,
          url: URLS.dashboard.verbaliSeguiti.url
        },
        '@dashboardRicercaStatiVerbali': {
          method: URLS.dashboard.statiVerbale.method,
          url: URLS.dashboard.statiVerbale.url
        },
        '@dashboardRicercaTipiAccertamento': {
          method: URLS.dashboard.tipiAccertamento.method,
          url: URLS.dashboard.tipiAccertamento.url
        },
        '@dashboardRicercaLotti': {
          method: URLS.dashboard.lotti.method,
          url: URLS.dashboard.lotti.url
        },
        '@dashboardRicercaTipiRilevatore': {
          method: URLS.dashboard.tipiRilevatore.method,
          url: URLS.dashboard.tipiRilevatore.url
        },
        '@dashboardRicercaMilestoneRilevazioni': {
          method: URLS.dashboard.milestoneRilevazioni.method,
          url: URLS.dashboard.milestoneRilevazioni.url
        },
        // '@ricercaEventiApplicazione': {
        //     method: URLS.dashboard.eventiApplicazione.method,
        //     url: URLS.dashboard.eventiApplicazione.url
        // },
        // USER EVENTS
        '@dashboardRicercaUserEvents': {
          method: URLS.dashboard.userEvents.ricerca.method,
          url: URLS.dashboard.userEvents.ricerca.url
        },
        '@dashboardCreaUserEvent': {
          method: URLS.dashboard.userEvents.crea.method,
          url: URLS.dashboard.userEvents.crea.url
        },
        '@dashboardModificaUserEvent': {
          method: URLS.dashboard.userEvents.modifica.method,
          url: URLS.dashboard.userEvents.modifica.url
        },
        '@dashboardEliminaUserEvent': {
          method: URLS.dashboard.userEvents.elimina.method,
          url: URLS.dashboard.userEvents.elimina.url
        },
        // ENTITA' PAGABILI
        '@ricercaEntitaPagabili': {
          method: URLS.entitaPagabili.ricerca.method,
          url: URLS.entitaPagabili.ricerca.url
        },
        // ARTICOLICDS
        '@ricercaViolazioniTestoLibero': {
          method: URLS.articoliCds.ricercaTestoLibero.method,
          url: URLS.articoliCds.ricercaTestoLibero.url
        },
        '@ricercaViolazione': {
          method: URLS.articoliCds.ricerca.method,
          url: URLS.articoliCds.ricerca.url
        },
        '@dettaglioViolazione': {
          method: URLS.articoliCds.dettaglio.method,
          url: URLS.articoliCds.dettaglio.url
        },
        // ACQUISIZIONE DATI -> RILEVAZIONI
        '@ricercaRilevazioni': {
          method: URLS.acquisizioneDati.rilevazioni.ricerca.method,
          url: URLS.acquisizioneDati.rilevazioni.ricerca.url
        },
        '@dettaglioRilevazione': {
          method: URLS.acquisizioneDati.rilevazioni.dettaglio.method,
          url: URLS.acquisizioneDati.rilevazioni.dettaglio.url
        },
        '@immaginiRilevazione': {
          method: URLS.acquisizioneDati.rilevazioni.immagini.method,
          url: URLS.acquisizioneDati.rilevazioni.immagini.url
        },
        '@creaRilevazione': {
          method: URLS.acquisizioneDati.rilevazioni.crea.method,
          url: URLS.acquisizioneDati.rilevazioni.crea.url
        },
        '@cancellaRilevazione': {
          method: URLS.acquisizioneDati.rilevazioni.cancella.method,
          url: URLS.acquisizioneDati.rilevazioni.cancella.url
        },
        '@ricercaTestoLiberoAcquisizioneDati': {
          method: URLS.acquisizioneDati.rilevazioni.ricercaTestoLibero.method,
          url: URLS.acquisizioneDati.rilevazioni.ricercaTestoLibero.url
        },
        // ACQUISIZIONE DATI -> VERSAMENTI
        '@acquisisciBancopostaTxt': {
          method: URLS.acquisizioneDati.versamenti.acquisizioneBancopostaTxt.method,
          url: URLS.acquisizioneDati.versamenti.acquisizioneBancopostaTxt.url
        },
        '@acquisisciBollettinoReport': {
          method: URLS.acquisizioneDati.versamenti.acquisizioneBollettinoReport.method,
          url: URLS.acquisizioneDati.versamenti.acquisizioneBollettinoReport.url
        },
        '@acquisisciBollettinoBancoposteSepa': {
          method: URLS.acquisizioneDati.versamenti.acquisizioneBollettinoBancoposteSepa.method,
          url: URLS.acquisizioneDati.versamenti.acquisizioneBollettinoBancoposteSepa.url
        },
        // VERBALE
        '@lockNumeroBolletta': {
          method: URLS.verbale.bolletta.method,
          url: URLS.verbale.bolletta.url
        },
        '@ricercaTestLiberoVerbali': {
          method: URLS.verbale.ricercaTestoLibero.method,
          url: URLS.verbale.ricercaTestoLibero.url
        },
        '@ricercaVeloceVerbali': {
          method: URLS.verbale.ricercaVeloce.method,
          url: URLS.verbale.ricercaVeloce.url
        },
        '@ricercaTestoLiberoVerbali': {
          method: URLS.verbale.ricercaTestoLibero.method,
          url: URLS.verbale.ricercaTestoLibero.url
        },
        '@dettaglioVerbale': {
          method: URLS.verbale.dettaglio.method,
          url: URLS.verbale.dettaglio.url
        },
        '@cancellaVebale': {
          method: URLS.verbale.cancella.method,
          url: URLS.verbale.cancella.url
        },
        '@followVerbale': {
          method: URLS.verbale.follow.method,
          url: URLS.verbale.follow.url
        },
        '@unfollowVerbale': {
          method: URLS.verbale.unfollow.method,
          url: URLS.verbale.unfollow.url
        },
        '@creaSpedizioneManuale': {
          method: URLS.spedizione.creaSpedizioneManuale.method,
          url: URLS.spedizione.creaSpedizioneManuale.url
        },
        '@creaEsitoManuale': {
          method: URLS.spedizione.creaEsitoManuale.method,
          url: URLS.spedizione.creaEsitoManuale.url
        },
        '@cancellaSpedizioneManuale': {
          method: URLS.spedizione.cancellaSpedizioneManuale.method,
          url: URLS.spedizione.cancellaSpedizioneManuale.url
        },
        '@stampaVerbale': {
          method: 'POST',
          url: 'verbale/stampa'
        },
        // RICORSI
        '@dettaglioRicorso': {
          method: URLS.ricorsi.dettaglio.method,
          url: URLS.ricorsi.dettaglio.url
        },
        '@allegatiRicorso': {
          method: 'GET',
          url: 'aggregato/allegati?aggregateCanonicalName=eu.opensoftware.verbale.ao.Ricorso'
        },
        '@caricaTracciatoSana': {
          method: URLS.ricorsi.caricaTracciatoSana.method,
          url: URLS.ricorsi.caricaTracciatoSana.url
        },
        '@ricorsoGeneraFascicolo': {
          method: URLS.ricorsi.generaFascicolo.method,
          url: URLS.ricorsi.generaFascicolo.url
        },
        '@ricorsoGetControdeduzioni': {
          method: URLS.ricorsi.controdeduzione.method,
          url: URLS.ricorsi.controdeduzione.url
        },
        //Decurtazione punti
        '@caricaFilePunti': {
          method: URLS.decurtazione.caricaFilePunti.method,
          url: URLS.decurtazione.caricaFilePunti.url
        },
        // VISURE
        '@visuraPersonaFisica': {
          method: URLS.visure.visuraPersonaFisica.method,
          url: URLS.visure.visuraPersonaFisica.url
        },
        '@visuraPersonaGiuridica': {
          method: URLS.visure.visuraFiguraGiuridica.method,
          url: URLS.visure.visuraFiguraGiuridica.url
        },
        '@visuraVeicoloGenerale': {
          method: URLS.visure.visuraVeicoloGenerale.method,
          url: URLS.visure.visuraVeicoloGenerale.url
        },
        '@visuraVeicoloParziale': {
          method: URLS.visure.visuraVeicoloParziale.method,
          url: URLS.visure.visuraVeicoloParziale.url
        },
        // AGENTI VISURE
        '@ricercaAgentiVisure': {
          method: URLS.agentiVisure.ricerca.method,
          url: URLS.agentiVisure.ricerca.url
        },
        '@dettaglioAgenteVisura': {
          method: URLS.agentiVisure.dettaglio.method,
          url: URLS.agentiVisure.dettaglio.url
        },
        '@creaAgenteVisura': {
          method: URLS.agentiVisure.crea.method,
          url: URLS.agentiVisure.crea.url
        },
        '@modificaAgenteVisura': {
          method: URLS.agentiVisure.modifica.method,
          url: URLS.agentiVisure.modifica.url
        },
        '@eliminaAgenteVisura': {
          method: URLS.agentiVisure.elimina.method,
          url: URLS.agentiVisure.elimina.url
        },
        '@downloadAgenteVisura': {
          method: URLS.agentiVisure.download.method,
          url: URLS.agentiVisure.download.url
        },
        '@downloadArchivioSinPostel': {
          method: URLS.lotto.downloadArchivioSinPostel.method,
          url: URLS.lotto.downloadArchivioSinPostel.url
        },
        '@ricercaViolazioni': {
          method: URLS.infrazioni.ricerca.method,
          url: URLS.infrazioni.ricerca.url
        },
        // CONTABILITA
        '@contabilitaRicercaTestoLibero': {
          method: URLS.contabilita.ricercaTestoLibero.method,
          url: URLS.contabilita.ricercaTestoLibero.url
        },
        '@contabilitaRicercaRegistrazione': {
          method: URLS.contabilita.ricercaRegistrazione.method,
          url: URLS.contabilita.ricercaRegistrazione.url
        },
        // VERSAMENTI
        '@tipiVersamento': {
          method: URLS.versamenti.tipiVersamento.method,
          url: URLS.versamenti.tipiVersamento.url
        },
        '@metodiVersamento': {
          method: URLS.versamenti.metodiVersamento.method,
          url: URLS.versamenti.metodiVersamento.url
        },
        // TABELLE DI SUPPORTO
        // NAZIONI
        '@ricercaNazioni': {
          method: URLS.tabelleDiSupporto.nazioni.ricerca.method,
          url: URLS.tabelleDiSupporto.nazioni.ricerca.url
        },
        '@dettaglioNazione': {
          method: URLS.tabelleDiSupporto.nazioni.dettaglio.method,
          url: URLS.tabelleDiSupporto.nazioni.dettaglio.url
        },
        '@salvaNazione': {
          method: URLS.tabelleDiSupporto.nazioni.crea.method,
          url: URLS.tabelleDiSupporto.nazioni.crea.url
        },
        '@modificaNazione': {
          method: URLS.tabelleDiSupporto.nazioni.modifica.method,
          url: URLS.tabelleDiSupporto.nazioni.modifica.url
        },
        '@eliminaNazione': {
          method: URLS.tabelleDiSupporto.nazioni.elimina.method,
          url: URLS.tabelleDiSupporto.nazioni.elimina.url
        },
        // GESTIONE DEL TERRITORIO
        '@ricercaComuniGestioneDelTerritorio': {
          method: URLS.tabelleDiSupporto.gestioneDelTerritorio.ricerca.method,
          url: URLS.tabelleDiSupporto.gestioneDelTerritorio.ricerca.url
        },
        '@dettaglioComuneGestioneDelTerritorio': {
          method: URLS.tabelleDiSupporto.gestioneDelTerritorio.dettaglio.method,
          url: URLS.tabelleDiSupporto.gestioneDelTerritorio.dettaglio.url
        },
        '@salvaComuneGestioneDelTerritorio': {
          method: URLS.tabelleDiSupporto.gestioneDelTerritorio.crea.method,
          url: URLS.tabelleDiSupporto.gestioneDelTerritorio.crea.url
        },
        '@modificaComuneGestioneDelTerritorio': {
          method: URLS.tabelleDiSupporto.gestioneDelTerritorio.modifica.method,
          url: URLS.tabelleDiSupporto.gestioneDelTerritorio.modifica.url
        },
        '@eliminaComuneGestioneDelTerritorio': {
          method: URLS.tabelleDiSupporto.gestioneDelTerritorio.elimina.method,
          url: URLS.tabelleDiSupporto.gestioneDelTerritorio.elimina.url
        },
        // FRAZIONI
        '@ricercaFrazioni': {
          method: URLS.tabelleDiSupporto.frazioni.ricerca.method,
          url: URLS.tabelleDiSupporto.frazioni.ricerca.url
        },
        '@dettaglioFrazione': {
          method: URLS.tabelleDiSupporto.frazioni.dettaglio.method,
          url: URLS.tabelleDiSupporto.frazioni.dettaglio.url
        },
        '@salvaFrazione': {
          method: URLS.tabelleDiSupporto.frazioni.crea.method,
          url: URLS.tabelleDiSupporto.frazioni.crea.url
        },
        '@modificaFrazione': {
          method: URLS.tabelleDiSupporto.frazioni.modifica.method,
          url: URLS.tabelleDiSupporto.frazioni.modifica.url
        },
        '@eliminaFrazione': {
          method: URLS.tabelleDiSupporto.frazioni.elimina.method,
          url: URLS.tabelleDiSupporto.frazioni.elimina.url
        },
        // STRADE
        '@ricercaStrade': {
          method: URLS.tabelleDiSupporto.strade.ricerca.method,
          url: URLS.tabelleDiSupporto.strade.ricerca.url
        },
        '@dettaglioStrada': {
          method: URLS.tabelleDiSupporto.strade.dettaglio.method,
          url: URLS.tabelleDiSupporto.strade.dettaglio.url
        },
        '@salvaStrada': {
          method: URLS.tabelleDiSupporto.strade.crea.method,
          url: URLS.tabelleDiSupporto.strade.crea.url
        },
        '@modificaStrada': {
          method: URLS.tabelleDiSupporto.strade.modifica.method,
          url: URLS.tabelleDiSupporto.strade.modifica.url
        },
        '@eliminaStrada': {
          method: URLS.tabelleDiSupporto.strade.elimina.method,
          url: URLS.tabelleDiSupporto.strade.elimina.url
        },
        // CONTI CORRENTE
        '@ricercaContiCorrente': {
          method: URLS.tabelleDiSupporto.contoCorrente.ricerca.method,
          url: URLS.tabelleDiSupporto.contoCorrente.ricerca.url
        },
        '@dettaglioContoCorrente': {
          method: URLS.tabelleDiSupporto.contoCorrente.dettaglio.method,
          url: URLS.tabelleDiSupporto.contoCorrente.dettaglio.url
        },
        '@salvaContoCorrente': {
          method: URLS.tabelleDiSupporto.contoCorrente.crea.method,
          url: URLS.tabelleDiSupporto.contoCorrente.crea.url
        },
        '@modificaContoCorrente': {
          method: URLS.tabelleDiSupporto.contoCorrente.modifica.method,
          url: URLS.tabelleDiSupporto.contoCorrente.modifica.url
        },
        '@eliminaContoCorrente': {
          method: URLS.tabelleDiSupporto.contoCorrente.elimina.method,
          url: URLS.tabelleDiSupporto.contoCorrente.elimina.url
        },
        // COMUNI
        '@ricercaComuni': {
          method: URLS.tabelleDiSupporto.comuni.ricerca.method,
          url: URLS.tabelleDiSupporto.comuni.ricerca.url
        },
        '@dettaglioComuneRif': {
          method: URLS.tabelleDiSupporto.comuni.dettaglio.method,
          url: URLS.tabelleDiSupporto.comuni.dettaglio.url
        },
        '@salvaComune': {
          method: URLS.tabelleDiSupporto.comuni.crea.method,
          url: URLS.tabelleDiSupporto.comuni.crea.url
        },
        '@modificaComune': {
          method: URLS.tabelleDiSupporto.comuni.modifica.method,
          url: URLS.tabelleDiSupporto.comuni.modifica.url
        },
        '@eliminaComune': {
          method: URLS.tabelleDiSupporto.comuni.elimina.method,
          url: URLS.tabelleDiSupporto.comuni.elimina.url
        },
        // TIPOLOGIE RIMOZIONI
        '@ricercaTipologieRimozioni': {
          method: URLS.tabelleDiSupporto.tipologieRimozioni.ricerca.method,
          url: URLS.tabelleDiSupporto.tipologieRimozioni.ricerca.url
        },
        '@dettaglioTipologiaRimozione': {
          method: URLS.tabelleDiSupporto.tipologieRimozioni.dettaglio.method,
          url: URLS.tabelleDiSupporto.tipologieRimozioni.dettaglio.url
        },
        '@salvaTipologiaRimozione': {
          method: URLS.tabelleDiSupporto.tipologieRimozioni.crea.method,
          url: URLS.tabelleDiSupporto.tipologieRimozioni.crea.url
        },
        '@modificaTipologiaRimozione': {
          method: URLS.tabelleDiSupporto.tipologieRimozioni.modifica.method,
          url: URLS.tabelleDiSupporto.tipologieRimozioni.modifica.url
        },
        '@eliminaTipologiaRimozione': {
          method: URLS.tabelleDiSupporto.tipologieRimozioni.elimina.method,
          url: URLS.tabelleDiSupporto.tipologieRimozioni.elimina.url
        },
        // MARCHE E MODELLI VEICOLI
        '@ricercaMarcheModelliVeicoli': {
          method: URLS.tabelleDiSupporto.marcheModelliVeicoli.ricerca.method,
          url: URLS.tabelleDiSupporto.marcheModelliVeicoli.ricerca.url
        },
        '@dettaglioMarcaModelloVeicolo': {
          method: URLS.tabelleDiSupporto.marcheModelliVeicoli.dettaglio.method,
          url: URLS.tabelleDiSupporto.marcheModelliVeicoli.dettaglio.url
        },
        '@salvaMarcaModelloVeicolo': {
          method: URLS.tabelleDiSupporto.marcheModelliVeicoli.crea.method,
          url: URLS.tabelleDiSupporto.marcheModelliVeicoli.crea.url
        },
        '@modificaMarcaModelloVeicolo': {
          method: URLS.tabelleDiSupporto.marcheModelliVeicoli.modifica.method,
          url: URLS.tabelleDiSupporto.marcheModelliVeicoli.modifica.url
        },
        '@eliminaMarcaModelloVeicolo': {
          method: URLS.tabelleDiSupporto.marcheModelliVeicoli.elimina.method,
          url: URLS.tabelleDiSupporto.marcheModelliVeicoli.elimina.url
        },
        // DEPOSITI FERMI E SEQUESTRI
        '@ricercaDepositiFermiSequestri': {
          method: URLS.tabelleDiSupporto.depositiFermiSequestri.ricerca.method,
          url: URLS.tabelleDiSupporto.depositiFermiSequestri.ricerca.url
        },
        '@dettaglioDepositoFermoSequestro': {
          method: URLS.tabelleDiSupporto.depositiFermiSequestri.dettaglio.method,
          url: URLS.tabelleDiSupporto.depositiFermiSequestri.dettaglio.url
        },
        '@salvaDepositoFermoSequestro': {
          method: URLS.tabelleDiSupporto.depositiFermiSequestri.crea.method,
          url: URLS.tabelleDiSupporto.depositiFermiSequestri.crea.url
        },
        '@modificaDepositoFermoSequestro': {
          method: URLS.tabelleDiSupporto.depositiFermiSequestri.modifica.method,
          url: URLS.tabelleDiSupporto.depositiFermiSequestri.modifica.url
        },
        '@eliminaDepositoFermoSequestro': {
          method: URLS.tabelleDiSupporto.depositiFermiSequestri.elimina.method,
          url: URLS.tabelleDiSupporto.depositiFermiSequestri.elimina.url
        },
        // CONTATORI PROTOCOLLO
        '@ricercaContatoriProtocollo': {
          method: URLS.tabelleDiSupporto.contatoriProtocollo.ricerca.method,
          url: URLS.tabelleDiSupporto.contatoriProtocollo.ricerca.url
        },
        '@dettaglioContatoreProtocollo': {
          method: URLS.tabelleDiSupporto.contatoriProtocollo.dettaglio.method,
          url: URLS.tabelleDiSupporto.contatoriProtocollo.dettaglio.url
        },
        '@salvaContatoreProtocollo': {
          method: URLS.tabelleDiSupporto.contatoriProtocollo.crea.method,
          url: URLS.tabelleDiSupporto.contatoriProtocollo.crea.url
        },
        '@modificaContatoreProtocollo': {
          method: URLS.tabelleDiSupporto.contatoriProtocollo.modifica.method,
          url: URLS.tabelleDiSupporto.contatoriProtocollo.modifica.url
        },
        '@eliminaContatoreProtocollo': {
          method: URLS.tabelleDiSupporto.contatoriProtocollo.elimina.method,
          url: URLS.tabelleDiSupporto.contatoriProtocollo.elimina.url
        },
        // INDICI VALORI - NATURA INCIDENTE
        '@ricercaNatureIncidenti': {
          method: URLS.tabelleDiSupporto.naturaIncidente.ricerca.method,
          url: URLS.tabelleDiSupporto.naturaIncidente.ricerca.url
        },
        '@salvaNaturaIncidente': {
          method: URLS.tabelleDiSupporto.naturaIncidente.crea.method,
          url: URLS.tabelleDiSupporto.naturaIncidente.crea.url
        },
        '@modificaNaturaIncidente': {
          method: URLS.tabelleDiSupporto.naturaIncidente.modifica.method,
          url: URLS.tabelleDiSupporto.naturaIncidente.modifica.url
        },
        '@eliminaNaturaIncidente': {
          method: URLS.tabelleDiSupporto.naturaIncidente.elimina.method,
          url: URLS.tabelleDiSupporto.naturaIncidente.elimina.url
        },
        // INDICI VALORI - TIPO STRADA
        '@ricercaTipiStrada': {
          method: URLS.tabelleDiSupporto.tipoStrada.ricerca.method,
          url: URLS.tabelleDiSupporto.tipoStrada.ricerca.url
        },
        '@salvaTipoStrada': {
          method: URLS.tabelleDiSupporto.tipoStrada.crea.method,
          url: URLS.tabelleDiSupporto.tipoStrada.crea.url
        },
        '@modificaTipoStrada': {
          method: URLS.tabelleDiSupporto.tipoStrada.modifica.method,
          url: URLS.tabelleDiSupporto.tipoStrada.modifica.url
        },
        '@eliminaTipoStrada': {
          method: URLS.tabelleDiSupporto.tipoStrada.elimina.method,
          url: URLS.tabelleDiSupporto.tipoStrada.elimina.url
        },
        // INDICI VALORI - PARTICOLARITA STRADA
        '@ricercaParticolaritaStrade': {
          method: URLS.tabelleDiSupporto.particolaritaStrada.ricerca.method,
          url: URLS.tabelleDiSupporto.particolaritaStrada.ricerca.url
        },
        '@salvaParticolaritaStrada': {
          method: URLS.tabelleDiSupporto.particolaritaStrada.crea.method,
          url: URLS.tabelleDiSupporto.particolaritaStrada.crea.url
        },
        '@modificaParticolaritaStrada': {
          method: URLS.tabelleDiSupporto.particolaritaStrada.modifica.method,
          url: URLS.tabelleDiSupporto.particolaritaStrada.modifica.url
        },
        '@eliminaParticolaritaStrada': {
          method: URLS.tabelleDiSupporto.particolaritaStrada.elimina.method,
          url: URLS.tabelleDiSupporto.particolaritaStrada.elimina.url
        },
        // INDICI VALORI - PAVIMENTAZIONE STRADA
        '@ricercaPavimentazioniStrade': {
          method: URLS.tabelleDiSupporto.pavimentazioneStrada.ricerca.method,
          url: URLS.tabelleDiSupporto.pavimentazioneStrada.ricerca.url
        },
        '@salvaPavimentazioneStrada': {
          method: URLS.tabelleDiSupporto.pavimentazioneStrada.crea.method,
          url: URLS.tabelleDiSupporto.pavimentazioneStrada.crea.url
        },
        '@modificaPavimentazioneStrada': {
          method: URLS.tabelleDiSupporto.pavimentazioneStrada.modifica.method,
          url: URLS.tabelleDiSupporto.pavimentazioneStrada.modifica.url
        },
        '@eliminaPavimentazioneStrada': {
          method: URLS.tabelleDiSupporto.pavimentazioneStrada.elimina.method,
          url: URLS.tabelleDiSupporto.pavimentazioneStrada.elimina.url
        },
        // INDICI VALORI - CONDIZIONI LUCE
        '@ricercaCondizioniLuce': {
          method: URLS.tabelleDiSupporto.condizioneLuce.ricerca.method,
          url: URLS.tabelleDiSupporto.condizioneLuce.ricerca.url
        },
        '@salvaCondizioneLuce': {
          method: URLS.tabelleDiSupporto.condizioneLuce.crea.method,
          url: URLS.tabelleDiSupporto.condizioneLuce.crea.url
        },
        '@modificaCondizioneLuce': {
          method: URLS.tabelleDiSupporto.condizioneLuce.modifica.method,
          url: URLS.tabelleDiSupporto.condizioneLuce.modifica.url
        },
        '@eliminaCondizioneLuce': {
          method: URLS.tabelleDiSupporto.condizioneLuce.elimina.method,
          url: URLS.tabelleDiSupporto.condizioneLuce.elimina.url
        },
        // INDICI VALORI - TIPO VEICOLO ISTAT
        '@ricercaTipiVeicoliIstat': {
          method: URLS.tabelleDiSupporto.tipoVeicoloIstat.ricerca.method,
          url: URLS.tabelleDiSupporto.tipoVeicoloIstat.ricerca.url
        },
        '@salvaTipoVeicoloIstat': {
          method: URLS.tabelleDiSupporto.tipoVeicoloIstat.crea.method,
          url: URLS.tabelleDiSupporto.tipoVeicoloIstat.crea.url
        },
        '@modificaTipoVeicoloIstat': {
          method: URLS.tabelleDiSupporto.tipoVeicoloIstat.modifica.method,
          url: URLS.tabelleDiSupporto.tipoVeicoloIstat.modifica.url
        },
        '@eliminaTipoVeicoloIstat': {
          method: URLS.tabelleDiSupporto.tipoVeicoloIstat.elimina.method,
          url: URLS.tabelleDiSupporto.tipoVeicoloIstat.elimina.url
        },
        // INDICI VALORI - CARRO ATTREZZI RIMOZIONE
        '@ricercaCarriAttrezziRimozione': {
          method: URLS.tabelleDiSupporto.carroAttrezziRimozione.ricerca.method,
          url: URLS.tabelleDiSupporto.carroAttrezziRimozione.ricerca.url
        },
        '@salvaCarroAttrezziRimozione': {
          method: URLS.tabelleDiSupporto.carroAttrezziRimozione.crea.method,
          url: URLS.tabelleDiSupporto.carroAttrezziRimozione.crea.url
        },
        '@modificaCarroAttrezziRimozione': {
          method: URLS.tabelleDiSupporto.carroAttrezziRimozione.modifica.method,
          url: URLS.tabelleDiSupporto.carroAttrezziRimozione.modifica.url
        },
        '@eliminaCarroAttrezziRimozione': {
          method: URLS.tabelleDiSupporto.carroAttrezziRimozione.elimina.method,
          url: URLS.tabelleDiSupporto.carroAttrezziRimozione.elimina.url
        },
        // INDICI VALORI - DEPOSITERIE
        '@ricercaDepositerie': {
          method: URLS.tabelleDiSupporto.depositerie.ricerca.method,
          url: URLS.tabelleDiSupporto.depositerie.ricerca.url
        },
        '@salvaDepositeria': {
          method: URLS.tabelleDiSupporto.depositerie.crea.method,
          url: URLS.tabelleDiSupporto.depositerie.crea.url
        },
        '@modificaDepositeria': {
          method: URLS.tabelleDiSupporto.depositerie.modifica.method,
          url: URLS.tabelleDiSupporto.depositerie.modifica.url
        },
        '@eliminaDepositeria': {
          method: URLS.tabelleDiSupporto.depositerie.elimina.method,
          url: URLS.tabelleDiSupporto.depositerie.elimina.url
        },
        // CONTENUTI DEGLI ALLEGATI
        '@ricercaContenutiAllegati': {
          method: URLS.tabelleDiSupporto.contenutiAllegati.ricerca.method,
          url: URLS.tabelleDiSupporto.contenutiAllegati.ricerca.url
        },
        '@dettaglioContenutoAllegato': {
          method: URLS.tabelleDiSupporto.contenutiAllegati.dettaglio.method,
          url: URLS.tabelleDiSupporto.contenutiAllegati.dettaglio.url
        },
        '@salvaContenutoAllegato': {
          method: URLS.tabelleDiSupporto.contenutiAllegati.crea.method,
          url: URLS.tabelleDiSupporto.contenutiAllegati.crea.url
        },
        '@modificaContenutoAllegato': {
          method: URLS.tabelleDiSupporto.contenutiAllegati.modifica.method,
          url: URLS.tabelleDiSupporto.contenutiAllegati.modifica.url
        },
        '@eliminaContenutoAllegato': {
          method: URLS.tabelleDiSupporto.contenutiAllegati.elimina.method,
          url: URLS.tabelleDiSupporto.contenutiAllegati.elimina.url
        },
        // FUNZIONI DI STAMPA
        '@ricercaFunzioniStampa': {
          method: URLS.tabelleDiSupporto.funzioniStampa.ricerca.method,
          url: URLS.tabelleDiSupporto.funzioniStampa.ricerca.url
        },
        '@dettaglioFunzioneStampa': {
          method: URLS.tabelleDiSupporto.funzioniStampa.dettaglio.method,
          url: URLS.tabelleDiSupporto.funzioniStampa.dettaglio.url
        },
        '@salvaFunzioneStampa': {
          method: URLS.tabelleDiSupporto.funzioniStampa.crea.method,
          url: URLS.tabelleDiSupporto.funzioniStampa.crea.url
        },
        '@modificaFunzioneStampa': {
          method: URLS.tabelleDiSupporto.funzioniStampa.modifica.method,
          url: URLS.tabelleDiSupporto.funzioniStampa.modifica.url
        },
        '@eliminaFunzioneStampa': {
          method: URLS.tabelleDiSupporto.funzioniStampa.elimina.method,
          url: URLS.tabelleDiSupporto.funzioniStampa.elimina.url
        },
        // LINGUE
        '@ricercaLingue': {
          method: URLS.tabelleDiSupporto.lingue.ricerca.method,
          url: URLS.tabelleDiSupporto.lingue.ricerca.url
        },
        '@dettaglioLingua': {
          method: URLS.tabelleDiSupporto.lingue.dettaglio.method,
          url: URLS.tabelleDiSupporto.lingue.dettaglio.url
        },
        '@salvaLingua': {
          method: URLS.tabelleDiSupporto.lingue.crea.method,
          url: URLS.tabelleDiSupporto.lingue.crea.url
        },
        '@modificaLingua': {
          method: URLS.tabelleDiSupporto.lingue.modifica.method,
          url: URLS.tabelleDiSupporto.lingue.modifica.url
        },
        '@eliminaLingua': {
          method: URLS.tabelleDiSupporto.lingue.elimina.method,
          url: URLS.tabelleDiSupporto.lingue.elimina.url
        },
        // LOCALIZZAZIONE
        '@ricercaLocalizzazioni': {
          method: URLS.tabelleDiSupporto.localizzazione.ricerca.method,
          url: URLS.tabelleDiSupporto.localizzazione.ricerca.url
        },
        '@dettaglioLocalizzazione': {
          method: URLS.tabelleDiSupporto.localizzazione.dettaglio.method,
          url: URLS.tabelleDiSupporto.localizzazione.dettaglio.url
        },
        '@salvaLocalizzazione': {
          method: URLS.tabelleDiSupporto.localizzazione.crea.method,
          url: URLS.tabelleDiSupporto.localizzazione.crea.url
        },
        '@modificaLocalizzazione': {
          method: URLS.tabelleDiSupporto.localizzazione.modifica.method,
          url: URLS.tabelleDiSupporto.localizzazione.modifica.url
        },
        '@eliminaLocalizzazione': {
          method: URLS.tabelleDiSupporto.localizzazione.elimina.method,
          url: URLS.tabelleDiSupporto.localizzazione.elimina.url
        },
        // ENTE TERRITORIALE
        '@ricercaEntiTerritoriali': {
          method: URLS.tabelleDiSupporto.enteTerritoriale.ricerca.method,
          url: URLS.tabelleDiSupporto.enteTerritoriale.ricerca.url
        },
        '@ricercaEnteSuperioreProvincia': {
          method: URLS.tabelleDiSupporto.enteSuperioreProvincia.ricerca.method,
          url: URLS.tabelleDiSupporto.enteSuperioreProvincia.ricerca.url
        },
        '@dettaglioEnteTerritoriale': {
          method: URLS.tabelleDiSupporto.enteTerritoriale.dettaglio.method,
          url: URLS.tabelleDiSupporto.enteTerritoriale.dettaglio.url
        },
        '@salvaEnteTerritoriale': {
          method: URLS.tabelleDiSupporto.enteTerritoriale.crea.method,
          url: URLS.tabelleDiSupporto.enteTerritoriale.crea.url
        },
        '@modificaEnteTerritoriale': {
          method: URLS.tabelleDiSupporto.enteTerritoriale.modifica.method,
          url: URLS.tabelleDiSupporto.enteTerritoriale.modifica.url
        },
        '@eliminaEnteTerritoriale': {
          method: URLS.tabelleDiSupporto.enteTerritoriale.elimina.method,
          url: URLS.tabelleDiSupporto.enteTerritoriale.elimina.url
        },
        // ENTE
        '@ricercaEnti': {
          method: URLS.tabelleDiSupporto.ente.ricerca.method,
          url: URLS.tabelleDiSupporto.ente.ricerca.url
        },
        '@dettaglioEnte': {
          method: URLS.tabelleDiSupporto.ente.dettaglio.method,
          url: URLS.tabelleDiSupporto.ente.dettaglio.url
        },
        '@salvaEnte': {
          method: URLS.tabelleDiSupporto.ente.crea.method,
          url: URLS.tabelleDiSupporto.ente.crea.url
        },
        '@modificaEnte': {
          method: URLS.tabelleDiSupporto.ente.modifica.method,
          url: URLS.tabelleDiSupporto.ente.modifica.url
        },
        '@eliminaEnte': {
          method: URLS.tabelleDiSupporto.ente.elimina.method,
          url: URLS.tabelleDiSupporto.ente.elimina.url
        },
        // CARRO ATTREZZI
        '@ricercaCarroattrezzi': {
          method: URLS.tabelleDiSupporto.carroAttrezzi.ricerca.method,
          url: URLS.tabelleDiSupporto.carroAttrezzi.ricerca.url
        },
        '@dettaglioCarroAttrezzi': {
          method: URLS.tabelleDiSupporto.carroAttrezzi.dettaglio.method,
          url: URLS.tabelleDiSupporto.carroAttrezzi.dettaglio.url
        },
        '@salvaCarroAttrezzi': {
          method: URLS.tabelleDiSupporto.carroAttrezzi.crea.method,
          url: URLS.tabelleDiSupporto.carroAttrezzi.crea.url
        },
        '@modificaCarroAttrezzi': {
          method: URLS.tabelleDiSupporto.carroAttrezzi.modifica.method,
          url: URLS.tabelleDiSupporto.carroAttrezzi.modifica.url
        },
        '@eliminaCarroAttrezzi': {
          method: URLS.tabelleDiSupporto.carroAttrezzi.elimina.method,
          url: URLS.tabelleDiSupporto.carroAttrezzi.elimina.url
        },
        // TIPI DI VEICOLO
        '@ricercaTipiVeicolo': {
          method: URLS.tabelleDiSupporto.tipoVeicolo.ricerca.method,
          url: URLS.tabelleDiSupporto.tipoVeicolo.ricerca.url
        },
        '@dettaglioTipoVeicolo': {
          method: URLS.tabelleDiSupporto.tipoVeicolo.dettaglio.method,
          url: URLS.tabelleDiSupporto.tipoVeicolo.dettaglio.url
        },
        '@salvaTipoVeicolo': {
          method: URLS.tabelleDiSupporto.tipoVeicolo.crea.method,
          url: URLS.tabelleDiSupporto.tipoVeicolo.crea.url
        },
        '@modificaTipoVeicolo': {
          method: URLS.tabelleDiSupporto.tipoVeicolo.modifica.method,
          url: URLS.tabelleDiSupporto.tipoVeicolo.modifica.url
        },
        '@eliminaTipoVeicolo': {
          method: URLS.tabelleDiSupporto.tipoVeicolo.elimina.method,
          url: URLS.tabelleDiSupporto.tipoVeicolo.elimina.url
        },
        // TIPI DI PROPRIETA'
        '@ricercaTipiProprieta': {
          method: URLS.tabelleDiSupporto.tipiProprieta.ricerca.method,
          url: URLS.tabelleDiSupporto.tipiProprieta.ricerca.url
        },
        '@dettaglioTipoProprieta': {
          method: URLS.tabelleDiSupporto.tipiProprieta.dettaglio.method,
          url: URLS.tabelleDiSupporto.tipiProprieta.dettaglio.url
        },
        '@salvaTipoProprieta': {
          method: URLS.tabelleDiSupporto.tipiProprieta.crea.method,
          url: URLS.tabelleDiSupporto.tipiProprieta.crea.url
        },
        '@modificaTipoProprieta': {
          method: URLS.tabelleDiSupporto.tipiProprieta.modifica.method,
          url: URLS.tabelleDiSupporto.tipiProprieta.modifica.url
        },
        '@eliminaTipoProprieta': {
          method: URLS.tabelleDiSupporto.tipiProprieta.elimina.method,
          url: URLS.tabelleDiSupporto.tipiProprieta.elimina.url
        },
        // TIPI DI BOLLETTARIO'
        '@ricercaTipiBollettario': {
          method: URLS.tabelleDiSupporto.tipoBollettario.ricerca.method,
          url: URLS.tabelleDiSupporto.tipoBollettario.ricerca.url
        },
        '@dettaglioTipoBollettario': {
          method: URLS.tabelleDiSupporto.tipoBollettario.dettaglio.method,
          url: URLS.tabelleDiSupporto.tipoBollettario.dettaglio.url
        },
        '@salvaTipoBollettario': {
          method: URLS.tabelleDiSupporto.tipoBollettario.crea.method,
          url: URLS.tabelleDiSupporto.tipoBollettario.crea.url
        },
        '@modificaTipoBollettario': {
          method: URLS.tabelleDiSupporto.tipoBollettario.modifica.method,
          url: URLS.tabelleDiSupporto.tipoBollettario.modifica.url
        },
        '@eliminaTipoBollettario': {
          method: URLS.tabelleDiSupporto.tipoBollettario.elimina.method,
          url: URLS.tabelleDiSupporto.tipoBollettario.elimina.url
        },
        // TIPI ACCERTAMENTO
        '@ricercaTipiAccertamento': {
          method: URLS.tabelleDiSupporto.tipoAccertamento.ricerca.method,
          url: URLS.tabelleDiSupporto.tipoAccertamento.ricerca.url
        },
        '@dettaglioTipoAccertamento': {
          method: URLS.tabelleDiSupporto.tipoAccertamento.dettaglio.method,
          url: URLS.tabelleDiSupporto.tipoAccertamento.dettaglio.url
        },
        '@salvaTipoAccertamento': {
          method: URLS.tabelleDiSupporto.tipoAccertamento.crea.method,
          url: URLS.tabelleDiSupporto.tipoAccertamento.crea.url
        },
        '@modificaTipoAccertamento': {
          method: URLS.tabelleDiSupporto.tipoAccertamento.modifica.method,
          url: URLS.tabelleDiSupporto.tipoAccertamento.modifica.url
        },
        '@eliminaTipoAccertamento': {
          method: URLS.tabelleDiSupporto.tipoAccertamento.elimina.method,
          url: URLS.tabelleDiSupporto.tipoAccertamento.elimina.url
        },
        // MOTIVAZIONI MANCATA CONTESTAZIONE
        '@ricercaMotivazioniMancataContestazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataContestazione.ricerca.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataContestazione.ricerca.url
        },
        '@dettaglioMotivazioneMancataContestazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataContestazione.dettaglio.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataContestazione.dettaglio.url
        },
        '@salvaMotivazioneMancataContestazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataContestazione.crea.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataContestazione.crea.url
        },
        '@modificaMotivazioneMancataContestazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataContestazione.modifica.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataContestazione.modifica.url
        },
        '@eliminaMotivazioneMancataContestazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataContestazione.elimina.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataContestazione.elimina.url
        },
        // DIREZIONE DI MARCIA DEI VEICOLI
        '@ricercaDirezioniMarcia': {
          method: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.ricerca.method,
          url: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.ricerca.url
        },
        '@dettaglioDirezioneMarcia': {
          method: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.dettaglio.method,
          url: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.dettaglio.url
        },
        '@salvaDirezioneMarcia': {
          method: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.crea.method,
          url: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.crea.url
        },
        '@modificaDirezioneMarcia': {
          method: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.modifica.method,
          url: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.modifica.url
        },
        '@eliminaDirezioneMarcia': {
          method: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.elimina.method,
          url: URLS.tabelleDiSupporto.direzioneMarciaVeicoli.elimina.url
        },
        // MOTIVAZIONE ARCHIVIAZIONE ACCERTAMENTI
        '@ricercaMotivazioniArchiviazioniAccertamenti': {
          method: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.ricerca.method,
          url: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.ricerca.url
        },
        '@dettaglioMotivazioneArchiviazioneAccertamenti': {
          method: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.dettaglio.method,
          url: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.dettaglio.url
        },
        '@salvaMotivazioneArchiviazioneAccertamenti': {
          method: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.crea.method,
          url: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.crea.url
        },
        '@modificaMotivazioneArchiviazioneAccertamenti': {
          method: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.modifica.method,
          url: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.modifica.url
        },
        '@eliminaMotivazioneArchiviazioneAccertamenti': {
          method: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.elimina.method,
          url: URLS.tabelleDiSupporto.motivazioneArchiviazioneAccertamenti.elimina.url
        },
        // RELAZIONE NOME SESSO
        '@ricercaRelazioniNomeSesso': {
          method: URLS.tabelleDiSupporto.relazioneNomeSesso.ricerca.method,
          url: URLS.tabelleDiSupporto.relazioneNomeSesso.ricerca.url
        },
        '@dettaglioRelazioneNomeSesso': {
          method: URLS.tabelleDiSupporto.relazioneNomeSesso.dettaglio.method,
          url: URLS.tabelleDiSupporto.relazioneNomeSesso.dettaglio.url
        },
        '@salvaRelazioneNomeSesso': {
          method: URLS.tabelleDiSupporto.relazioneNomeSesso.crea.method,
          url: URLS.tabelleDiSupporto.relazioneNomeSesso.crea.url
        },
        '@modificaRelazioneNomeSesso': {
          method: URLS.tabelleDiSupporto.relazioneNomeSesso.modifica.method,
          url: URLS.tabelleDiSupporto.relazioneNomeSesso.modifica.url
        },
        '@eliminaRelazioneNomeSesso': {
          method: URLS.tabelleDiSupporto.relazioneNomeSesso.elimina.method,
          url: URLS.tabelleDiSupporto.relazioneNomeSesso.elimina.url
        },
        // ATTIVITA' PRODUTTIVA
        '@ricercaAttivitaProduttive': {
          method: URLS.tabelleDiSupporto.attivitaProduttiva.ricerca.method,
          url: URLS.tabelleDiSupporto.attivitaProduttiva.ricerca.url
        },
        '@dettaglioAttivitaProduttiva': {
          method: URLS.tabelleDiSupporto.attivitaProduttiva.dettaglio.method,
          url: URLS.tabelleDiSupporto.attivitaProduttiva.dettaglio.url
        },
        '@salvaAttivitaProduttiva': {
          method: URLS.tabelleDiSupporto.attivitaProduttiva.crea.method,
          url: URLS.tabelleDiSupporto.attivitaProduttiva.crea.url
        },
        '@modificaAttivitaProduttiva': {
          method: URLS.tabelleDiSupporto.attivitaProduttiva.modifica.method,
          url: URLS.tabelleDiSupporto.attivitaProduttiva.modifica.url
        },
        '@eliminaAttivitaProduttiva': {
          method: URLS.tabelleDiSupporto.attivitaProduttiva.elimina.method,
          url: URLS.tabelleDiSupporto.attivitaProduttiva.elimina.url
        },
        // SANZIONI ACCESSORIE
        '@ricercaSanzioniAccessorie': {
          method: URLS.tabelleDiSupporto.sanzioniAccessorie.ricerca.method,
          url: URLS.tabelleDiSupporto.sanzioniAccessorie.ricerca.url
        },
        '@dettaglioSanzioneAccessoria': {
          method: URLS.tabelleDiSupporto.sanzioniAccessorie.dettaglio.method,
          url: URLS.tabelleDiSupporto.sanzioniAccessorie.dettaglio.url
        },
        '@salvaSanzioneAccessoria': {
          method: URLS.tabelleDiSupporto.sanzioniAccessorie.crea.method,
          url: URLS.tabelleDiSupporto.sanzioniAccessorie.crea.url
        },
        '@modificaSanzioneAccessoria': {
          method: URLS.tabelleDiSupporto.sanzioniAccessorie.modifica.method,
          url: URLS.tabelleDiSupporto.sanzioniAccessorie.modifica.url
        },
        '@eliminaSanzioneAccessoria': {
          method: URLS.tabelleDiSupporto.sanzioniAccessorie.elimina.method,
          url: URLS.tabelleDiSupporto.sanzioniAccessorie.elimina.url
        },
        // MOTIVAZIONE MANCATA APPLICAZIONE
        '@ricercaMotivazioniMancataApplicazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.ricerca.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.ricerca.url
        },
        '@dettaglioMotivazioneMancataApplicazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.dettaglio.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.dettaglio.url
        },
        '@salvaMotivazioneMancataApplicazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.crea.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.crea.url
        },
        '@modificaMotivazioneMancataApplicazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.modifica.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.modifica.url
        },
        '@eliminaMotivazioneMancataApplicazione': {
          method: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.elimina.method,
          url: URLS.tabelleDiSupporto.motivazioneMancataApplicazione.elimina.url
        },
        // LEGGI
        '@ricercaLeggi': {
          method: URLS.tabelleDiSupporto.leggi.ricerca.method,
          url: URLS.tabelleDiSupporto.leggi.ricerca.url
        },
        '@dettaglioLegge': {
          method: URLS.tabelleDiSupporto.leggi.dettaglio.method,
          url: URLS.tabelleDiSupporto.leggi.dettaglio.url
        },
        '@salvaLegge': {
          method: URLS.tabelleDiSupporto.leggi.crea.method,
          url: URLS.tabelleDiSupporto.leggi.crea.url
        },
        '@modificaLegge': {
          method: URLS.tabelleDiSupporto.leggi.modifica.method,
          url: URLS.tabelleDiSupporto.leggi.modifica.url
        },
        '@eliminaLegge': {
          method: URLS.tabelleDiSupporto.leggi.elimina.method,
          url: URLS.tabelleDiSupporto.leggi.elimina.url
        },
        // RILEVATORI DI INFRAZIONI
        '@ricercaRilevatoriInfrazioni': {
          method: URLS.tabelleDiSupporto.rilevatoriInfrazioni.ricerca.method,
          url: URLS.tabelleDiSupporto.rilevatoriInfrazioni.ricerca.url
        },
        '@dettaglioRilevatoreInfrazioni': {
          method: URLS.tabelleDiSupporto.rilevatoriInfrazioni.dettaglio.method,
          url: URLS.tabelleDiSupporto.rilevatoriInfrazioni.dettaglio.url
        },
        '@salvaRilevatoreInfrazioni': {
          method: URLS.tabelleDiSupporto.rilevatoriInfrazioni.crea.method,
          url: URLS.tabelleDiSupporto.rilevatoriInfrazioni.crea.url
        },
        '@modificaRilevatoreInfrazioni': {
          method: URLS.tabelleDiSupporto.rilevatoriInfrazioni.modifica.method,
          url: URLS.tabelleDiSupporto.rilevatoriInfrazioni.modifica.url
        },
        '@eliminaRilevatoreInfrazioni': {
          method: URLS.tabelleDiSupporto.rilevatoriInfrazioni.elimina.method,
          url: URLS.tabelleDiSupporto.rilevatoriInfrazioni.elimina.url
        },
        // LUOGHI RICOVERO INFORTUNATI
        '@ricercaLuoghiRicoveroInfortunati': {
          method: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.ricerca.method,
          url: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.ricerca.url
        },
        '@dettaglioLuogoRicoveroInfortunati': {
          method: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.dettaglio.method,
          url: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.dettaglio.url
        },
        '@salvaLuogoRicoveroInfortunati': {
          method: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.crea.method,
          url: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.crea.url
        },
        '@modificaLuogoRicoveroInfortunati': {
          method: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.modifica.method,
          url: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.modifica.url
        },
        '@eliminaLuogoRicoveroInfortunati': {
          method: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.elimina.method,
          url: URLS.tabelleDiSupporto.luoghiRicoveroInfortunati.elimina.url
        },
        // STAZIONI COMANDO
        '@ricercaStazioniComando': {
          method: URLS.tabelleDiSupporto.stazioniComando.ricerca.method,
          url: URLS.tabelleDiSupporto.stazioniComando.ricerca.url
        },
        '@dettaglioStazioneComando': {
          method: URLS.tabelleDiSupporto.stazioniComando.dettaglio.method,
          url: URLS.tabelleDiSupporto.stazioniComando.dettaglio.url
        },
        '@modificaStazioneComando': {
          method: URLS.tabelleDiSupporto.stazioniComando.modifica.method,
          url: URLS.tabelleDiSupporto.stazioniComando.modifica.url
        },
        '@creaStazioneComando': {
          method: URLS.tabelleDiSupporto.stazioniComando.crea.method,
          url: URLS.tabelleDiSupporto.stazioniComando.crea.url
        },
        '@eliminaStazioneComando': {
          method: URLS.tabelleDiSupporto.stazioniComando.elimina.method,
          url: URLS.tabelleDiSupporto.stazioniComando.elimina.url
        },
        // ANAGRAFICA
        '@ricercaTestoLiberoAnagrafica': {
          method: URLS.anagrafica.ricercaTestoLibero.method,
          url: URLS.anagrafica.ricercaTestoLibero.url
        },
        '@dettaglioAnagrafica': {
          method: URLS.anagrafica.dettaglio.method,
          url: URLS.anagrafica.dettaglio.url
        },
        '@salvaAnagrafica': {
          method: URLS.anagrafica.crea.method,
          url: URLS.anagrafica.crea.url
        },
        '@modificaAnagrafica': {
          method: URLS.anagrafica.modifica.method,
          url: URLS.anagrafica.modifica.url
        },
        // ANAGRAFICA DOCUMENTO
        '@ricercaDocumentiAnagrafica': {
          method: URLS.anagrafica.documento.ricerca.method,
          url: URLS.anagrafica.documento.ricerca.url
        },
        '@dettaglioDocumentiAnagrafica': {
          method: URLS.anagrafica.documento.dettaglio.method,
          url: URLS.anagrafica.documento.dettaglio.url
        },
        '@salvaDocumentiAnagrafica': {
          method: URLS.anagrafica.documento.crea.method,
          url: URLS.anagrafica.documento.crea.url
        },
        '@modificaDocumentiAnagrafica': {
          method: URLS.anagrafica.documento.modifica.method,
          url: URLS.anagrafica.documento.modifica.url
        },
        '@eliminaDocumentiAnagrafica': {
          method: URLS.anagrafica.documento.elimina.method,
          url: URLS.anagrafica.documento.elimina.url
        },
        // ANAGRAFICA VEICOLO
        '@ricercaVeicoliAnagrafica': {
          method: URLS.anagrafica.veicolo.ricerca.method,
          url: URLS.anagrafica.veicolo.ricerca.url
        },
        '@dettaglioVeicoloAnagrafica': {
          method: URLS.anagrafica.veicolo.dettaglio.method,
          url: URLS.anagrafica.veicolo.dettaglio.url
        },
        '@salvaVeicoloAnagrafica': {
          method: URLS.anagrafica.veicolo.crea.method,
          url: URLS.anagrafica.veicolo.crea.url
        },
        '@modificaVeicoloAnagrafica': {
          method: URLS.anagrafica.veicolo.modifica.method,
          url: URLS.anagrafica.veicolo.modifica.url
        },
        '@eliminaVeicoloAnagrafica': {
          method: URLS.anagrafica.veicolo.elimina.method,
          url: URLS.anagrafica.veicolo.elimina.url
        },
        // VALORI GLOBALI
        '@listaSezioniValoriGlobali': {
          method: 'GET',
          url: 'globale/lista/sezioni-valori-globali'
        },
        '@dettaglioSezioneValoreGlobale': {
          method: 'GET',
          url: 'valori-globali/leggi'
        },
        '@salvaValoreGlobale': {
          method: 'POST',
          url: 'valori-globali/comando/aggiorna'
        },
        // ENUMS
        '@recuperaEsitiSpedizione': {
          method: URLS.enums.esitiSpedizione.method,
          url: URLS.enums.esitiSpedizione.url
        },
        '@recuperaCompetenze': {
          method: URLS.enums.recuperaCompetenze.method,
          url: URLS.enums.recuperaCompetenze.url
        },
        '@recuperaTipiProtocollo': {
          method: URLS.enums.tipiProtocollo.method,
          url: URLS.enums.tipiProtocollo.url
        },
        '@recuperaTipologieStrade': {
          method: URLS.enums.tipologieStrade.method,
          url: URLS.enums.tipologieStrade.url
        },
        '@recuperaIndiciValori': {
          method: URLS.enums.indiciValori.method,
          url: URLS.enums.indiciValori.url
        },
        '@recuperaTipiAllegato': {
          method: URLS.enums.tipiAllegato.method,
          url: URLS.enums.tipiAllegato.url
        },
        '@recuperaDisponibilitaAllegato': {
          method: URLS.enums.disponibilitaAllegato.method,
          url: URLS.enums.disponibilitaAllegato.url
        },
        '@recuperaModuliStampa': {
          method: URLS.enums.moduliStampa.method,
          url: URLS.enums.moduliStampa.url
        },
        '@recuperaListaEntita': {
          method: URLS.enums.listaEntita.method,
          url: URLS.enums.listaEntita.url
        },
        '@recuperaVisibilitaFunzioneStampa': {
          method: URLS.enums.visibilitaFunzioneStampa.method,
          url: URLS.enums.visibilitaFunzioneStampa.url
        },
        '@recuperaValute': {
          method: URLS.enums.valute.method,
          url: URLS.enums.valute.url
        },
        '@recuperaEntiTerritorialiEnum': {
          method: URLS.enums.entiTerritoriali.method,
          url: URLS.enums.entiTerritoriali.url
        },
        '@recuperaTipiEnte': {
          method: URLS.enums.tipiEnte.method,
          url: URLS.enums.tipiEnte.url
        },
        '@recuperaTipiMezzo': {
          method: URLS.enums.tipiMezzo.method,
          url: URLS.enums.tipiMezzo.url
        },
        '@recuperaTipiMotore': {
          method: URLS.enums.tipiMotore.method,
          url: URLS.enums.tipiMotore.url
        },
        '@recuperaTipiVerbale': {
          method: URLS.enums.tipiVerbali.method,
          url: URLS.enums.tipiVerbali.url
        },
        '@recuperaSessi': {
          method: URLS.enums.sessi.method,
          url: URLS.enums.sessi.url
        },
        '@recuperaTipiCodice': {
          method: URLS.enums.tipiCodice.method,
          url: URLS.enums.tipiCodice.url
        },
        '@recuperaDecoratoriAccessorie': {
          method: URLS.enums.decoratoriAccessorie.method,
          url: URLS.enums.decoratoriAccessorie.url
        },
        '@recuperaTipiRilevatori': {
          method: URLS.enums.tipiRilevatori.method,
          url: URLS.enums.tipiRilevatori.url
        },
        '@recuperaModelliRilevatori': {
          method: URLS.enums.modelliRilevatori.method,
          url: URLS.enums.modelliRilevatori.url
        },
        '@recuperaPostazioniRilevatori': {
          method: URLS.enums.postazioniRilevatori.method,
          url: URLS.enums.postazioniRilevatori.url
        },
        '@recuperaTipiAccesso': {
          method: URLS.enums.tipiAccesso.method,
          url: URLS.enums.tipiAccesso.url
        },
        '@recuperaPosizioniRelative': {
          method: URLS.enums.posizioniRelative.method,
          url: URLS.enums.posizioniRelative.url
        },
        '@recuperaListaProiezioni': {
          method: URLS.enums.listaProiezioni.method,
          url: URLS.enums.listaProiezioni.url
        },
        '@recuperaModalitaRichieste': {
          method: URLS.enums.modalitaRichieste.method,
          url: URLS.enums.modalitaRichieste.url
        },
        '@recuperaRichiedentiIntervento': {
          method: URLS.enums.richiedentiIntervento.method,
          url: URLS.enums.richiedentiIntervento.url
        },
        '@recuperaTipiTraffico': {
          method: URLS.enums.tipiTraffico.method,
          url: URLS.enums.tipiTraffico.url
        },
        '@recuperaCondizioniMeteo': {
          method: URLS.enums.condizioniMeteo.method,
          url: URLS.enums.condizioniMeteo.url
        },
        '@recuperaTipiVisibilita': {
          method: URLS.enums.tipiVisibilita.method,
          url: URLS.enums.tipiVisibilita.url
        },
        '@recuperaFondiStradali': {
          method: URLS.enums.fondiStradali.method,
          url: URLS.enums.fondiStradali.url
        },
        '@recuperaStatiManutenzioneStrada': {
          method: URLS.enums.statiManutenzioneStrada.method,
          url: URLS.enums.statiManutenzioneStrada.url
        },
        '@recuperaTipiSegnaletica': {
          method: URLS.enums.tipiSegnaletica.method,
          url: URLS.enums.tipiSegnaletica.url
        },
        '@recuperaTipiAnagrafica': {
          method: URLS.enums.tipiAnagrafica.method,
          url: URLS.enums.tipiAnagrafica.url
        },
        '@recuperaListaCinture': {
          method: URLS.enums.listaCinture.method,
          url: URLS.enums.listaCinture.url
        },
        '@recuperaListaCasco': {
          method: URLS.enums.listaCasco.method,
          url: URLS.enums.listaCasco.url
        },
        '@recuperaTipiDocumentoPersonale': {
          method: URLS.enums.tipiDocumentoPersonale.method,
          url: URLS.enums.tipiDocumentoPersonale.url
        },
        '@recuperaCategoriePatente': {
          method: URLS.enums.tipiCategoriaPatente.method,
          url: URLS.enums.tipiCategoriaPatente.url
        },
        '@recuperaSpecificheCQC': {
          method: URLS.enums.listaSpecificheCQC.method,
          url: URLS.enums.listaSpecificheCQC.url
        },
        '@recuperaTipiRilevazione': {
          method: URLS.enums.tipiRilevazione.method,
          url: URLS.enums.tipiRilevazione.url
        },
        '@recuperaTipiRicorso': {
          method: URLS.enums.tipiRicorsi.method,
          url: URLS.enums.tipiRicorsi.url
        },
        '@recuperaModalitaPresentazioneRicorso': {
          method: URLS.enums.modalitaPresentazioneRicorso.method,
          url: URLS.enums.modalitaPresentazioneRicorso.url
        },
        '@recuperaRicorsoPresentatoA': {
          method: URLS.enums.ricorsoPresentatoA.method,
          url: URLS.enums.ricorsoPresentatoA.url
        },
        '@recuperaRicorsoTipiPareri': {
          method: URLS.enums.ricorsoTipiPareri.method,
          url: URLS.enums.ricorsoTipiPareri.url
        },
        '@recuperaStatiRicorso': {
          method: URLS.enums.statiRicorso.method,
          url: URLS.enums.statiRicorso.url
        },
        '@recuperaSospensiveRicorso': {
          method: URLS.enums.sospensiveRicorso.method,
          url: URLS.enums.sospensiveRicorso.url
        },
        '@recuperaTipiTrasgressore': {
          method: URLS.enums.tipiTrasgressore.method,
          url: URLS.enums.tipiTrasgressore.url
        },
        '@recuperaTipiNotifica': {
          method: URLS.enums.tipiNotifica.method,
          url: URLS.enums.tipiNotifica.url
        },
        '@recuperaMezziNotifica': {
          method: URLS.enums.mezziNotifica.method,
          url: URLS.enums.mezziNotifica.url
        },
        '@recuperaTipiProprieta': {
          method: URLS.enums.tipiProprieta.method,
          url: URLS.enums.tipiProprieta.url
        },
        '@recuperaStatiVerbale': {
          method: URLS.enums.statiVerbale.method,
          url: URLS.enums.statiVerbale.url
        },
        '@recuperaTipiRichiesteVisuraPf': {
          method: URLS.enums.tipiRichiesteVisuraPf.method,
          url: URLS.enums.tipiRichiesteVisuraPf.url
        },
        '@recuperaTipiRichiesteVisuraPg': {
          method: URLS.enums.tipiRichiesteVisuraPg.method,
          url: URLS.enums.tipiRichiesteVisuraPg.url
        },
        '@recuperaOriginiNascita': {
          method: URLS.enums.originiNascita.method,
          url: URLS.enums.originiNascita.url
        },
        '@recuperaTipiFermoSequestro': {
          method: URLS.enums.tipiFermoSequestro.method,
          url: URLS.enums.tipiFermoSequestro.url
        },
        '@recuperaTrasportoACuraDi': {
          method: URLS.enums.trasportoACuraDi.method,
          url: URLS.enums.trasportoACuraDi.url
        },
        '@recuperaTipiAffidamentoVeicolo': {
          method: URLS.enums.tipiAffidamentoVeicolo.method,
          url: URLS.enums.tipiAffidamentoVeicolo.url
        },
        '@recuperaDestinazioni': {
          method: URLS.enums.destinazioniLotto.method,
          url: URLS.enums.destinazioniLotto.url
        },
        '@recuperaTipiLotto': {
          method: URLS.enums.tipiLotto.method,
          url: URLS.enums.tipiLotto.url
        },
        '@recuperaTipiTracciatoVersamento': {
          method: URLS.enums.tipiTracciatoVersamento.method,
          url: URLS.enums.tipiTracciatoVersamento.url
        },
        '@recuperaContiEconomici': {
          method: URLS.enums.contiEconomici.method,
          url: URLS.enums.contiEconomici.url
        },
        // MODELLI DI STAMPA
        '@ricercaModelliStampa': {
          method: URLS.modelliStampa.ricerca.method,
          url: URLS.modelliStampa.ricerca.url
        },
        '@dettaglioModelloStampa': {
          method: URLS.modelliStampa.dettaglio.method,
          url: URLS.modelliStampa.dettaglio.url
        },
        '@calcoloVelocita': {
          method: URLS.enums.calcoloVelocita.method,
          url: URLS.enums.calcoloVelocita.url
        },
        '@inviaRiceviPc': {
          method: URLS.enums.inviaRiceviPc.method,
          url: URLS.enums.inviaRiceviPc.url
        },
        '@tracciatoInvio': {
          method: URLS.enums.tracciatoInvio.method,
          url: URLS.enums.tracciatoInvio.url
        },
        '@tipoStampante': {
          method: URLS.enums.tipoStampante.method,
          url: URLS.enums.tipoStampante.url
        },
        '@tipiBollettariAccertamento': {
          method: URLS.enums.tipiBollettariAccertamento.method,
          url: URLS.enums.tipiBollettariAccertamento.url
        },
        '@nrRegistroAccertamenti': {
          method: URLS.enums.nrRegistroAccertamenti.method,
          url: URLS.enums.nrRegistroAccertamenti.url
        },
        '@puntiPersoneFisiche': {
          method: URLS.enums.puntiPersoneFisiche.method,
          url: URLS.enums.puntiPersoneFisiche.url
        },
        '@progressivoRicorsi': {
          method: URLS.enums.progressivoRicorsi.method,
          url: URLS.enums.progressivoRicorsi.url
        },
        '@nomeAllegatiSana': {
          method: URLS.enums.nomeAllegatiSana.method,
          url: URLS.enums.nomeAllegatiSana.url
        },
        '@modalitaRipetizioneInserimento': {
          method: URLS.enums.modalitaRipetizioneInserimento.method,
          url: URLS.enums.modalitaRipetizioneInserimento.url
        },
        '@dataRilevazioneVerbalizzazioneMassiva': {
          method: URLS.enums.dataRilevazioneVerbalizzazioneMassiva.method,
          url: URLS.enums.dataRilevazioneVerbalizzazioneMassiva.url
        },
        '@obbligatorioNonObblitatorio': {
          method: URLS.enums.obbligatorioNonObblitatorio.method,
          url: URLS.enums.obbligatorioNonObblitatorio.url
        },
        '@applicazioneSpeseBollo': {
          method: URLS.enums.applicazioneSpeseBollo.method,
          url: URLS.enums.applicazioneSpeseBollo.url
        },
        '@scontoInfrazioni': {
          method: URLS.enums.scontoInfrazioni.method,
          url: URLS.enums.scontoInfrazioni.url
        },
        '@misuraRidottaSconto': {
          method: URLS.enums.misuraRidottaSconto.method,
          url: URLS.enums.misuraRidottaSconto.url
        },
        '@stampaFineTurno': {
          method: URLS.enums.stampaFineTurno.method,
          url: URLS.enums.stampaFineTurno.url
        },
        '@inserimentoPagamento': {
          method: URLS.enums.inserimentoPagamento.method,
          url: URLS.enums.inserimentoPagamento.url
        },
        '@esportazioneInfrazioni': {
          method: URLS.enums.esportazioneInfrazioni.method,
          url: URLS.enums.esportazioneInfrazioni.url
        },
        '@statoAcquisizioneZtl': {
          method: URLS.enums.statoAcquisizioneZtl.method,
          url: URLS.enums.statoAcquisizioneZtl.url
        },
        '@tipoVisualizzazioneFoto': {
          method: URLS.enums.tipoVisualizzazioneFoto.method,
          url: URLS.enums.tipoVisualizzazioneFoto.url
        },
        '@iterRilevazione': {
          method: URLS.enums.iterRilevazione.method,
          url: URLS.enums.iterRilevazione.url
        },
        '@tipoSpeseDaImpostare': {
          method: URLS.enums.tipoSpeseDaImpostare.method,
          url: URLS.enums.tipoSpeseDaImpostare.url
        },
        '@tipiOperazioniVisure': {
          method: URLS.enums.tipiOperazioniVisure.method,
          url: URLS.enums.tipiOperazioniVisure.url
        },
        '@statusVisura': {
          method: URLS.enums.statusVisura.method,
          url: URLS.enums.statusVisura.url
        },
        '@sistemiOperativi': {
          method: URLS.enums.sistemiOperativi.method,
          url: URLS.enums.sistemiOperativi.url
        },
        '@violazioneNatura': {
          method: URLS.enums.violazioneNatura.method,
          url: URLS.enums.violazioneNatura.url
        },
        '@statiFermoSequestro': {
          method: URLS.enums.statiFermoSequestro.method,
          url: URLS.enums.statiFermoSequestro.url
        },
        '@trasportoACuraDi': {
          method: URLS.enums.trasportoACuraDi.method,
          url: URLS.enums.trasportoACuraDi.url
        },
        '@listaTipiAffidamento': {
          method: URLS.enums.listaTipiAffidamento.method,
          url: URLS.enums.listaTipiAffidamento.url
        },
        '@listaSorgentiVisure': {
          method: URLS.enums.sorgentiVisure.method,
          url: URLS.enums.sorgentiVisure.url
        },
        '@listaStatiDecurtazionePunti': {
          method: URLS.enums.statiDecurtazionePunti.method,
          url: URLS.enums.statiDecurtazionePunti.url
        },
        '@listaPareriControdeduzioni': {
          method: "GET",
          url: "ricorso/lista/tipi-parere"
        },
        //NOTIFICHE
        '@ricercaNotifiche': {
          method: URLS.notifiche.ricerca.method,
          url: URLS.notifiche.ricerca.url
        },
        '@recuperaStatistiche': {
          method: URLS.generali.statisticheSpedizioni.method,
          url: URLS.generali.statisticheSpedizioni.url
        }
      });
    }
  }
})();