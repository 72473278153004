"use strict";

/**
 * Created by gbelli on 08/11/2019
 * Il filtro recupera da un array di traduzioni la lingua desiderata
 */
(function () {
  'use strict';

  angular.module('polcity.filters').filter('language', function () {
    return function (languages, language) {
      return R.find(R.propEq('languageTag', language))(languages).traduzione;
    };
  });
})();