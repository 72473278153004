"use strict";

/**
 * Created by MN 05/12/2019
 */
(function () {
  'use strict';

  angular.module('osFramework').directive('ricercaArticoloCds', [RicercaArticoloCds]);

  function RicercaArticoloCds() {
    return {
      restrict: 'E',
      require: ['ngModel', 'ricercaArticoloCds'],
      scope: {
        entity: '=?',
        label: '@?',
        onArticoloCdsChange: '<?',
        isRequired: '<?',
        name: '@?'
      },
      controller: ['$scope', controllerFn],
      controllerAs: 'ricercaArticoloCdsCtrl',
      templateUrl: 'osFramework/input/ricercaArticoloCds/ricercaArticoloCds.directive.html',
      link: linkFn
    };

    function controllerFn(scope) {
      var me = this;
      me.groupRoles = ['ROLE_ARTICOLO_CDS_RICERCA', 'ROLE_ARTICOLO_CDS_DETTAGLIO'];

      me.getItemText = function (articoloCds) {
        var itemText;
        itemText = articoloCds.codiceLibreria.toUpperCase().concat(' - ', articoloCds.descrizione.toUpperCase());
        return itemText;
      };
    }

    function linkFn(scope, element, attrs, controllers) {
      var ngModelCtrl = controllers[0],
          ricercaArticoloCdsCtrl = controllers[1];

      ngModelCtrl.$render = function () {
        var viewValue = ngModelCtrl.$viewValue;

        if (!viewValue) {
          scope.aggId = null;
          return;
        }

        scope.aggId = viewValue;
      };

      scope.$watch('aggId', function (newVal) {
        if (!newVal) {
          newVal = '';
        }

        ngModelCtrl.$setViewValue(newVal);
      });
    }
  }
})();