"use strict";

/**
 * Created by dan on 01/12/16.
 */
(function () {
  'use strict';
  /**
   *
   * @ngdoc module
   * @name polcity.verbali
   *
   * @requires polcity.api
   * @requires polcity.resources
   * @requires polcity.widgets
   * @requires polcity.usersManagement
   * @requires polcity.maps
   * @requires osListComponent
   * @requires osSideTabs
   * @requires osAsyncDropdown
   *
   * @description
   *
   * Verbali module
   *
   **/

  angular.module('polcity.verbali', ['osListComponent', 'osAsyncDropdown']).run(['VerbaliCommands', run]).constant('InizializzaVerbaleAManoDaUICommand', 'InizializzaVerbaleAManoDaUICommand').constant('$verbaleEvents', {
    setModelloStampaFromTipoAccertamento: 'verbali:setModelloStampaFromTipoAccertamento',
    setMotivoMancataContestazioneFromTipoAccertamento: 'verbali:setMotivoMancataContestazioneFromTipoAccertamento'
  }).constant('$decoratoreType', {
    sanz_acc_fermo_sequestro: 'sanz_acc_fermo_sequestro',
    infrazione_senza_documenti: 'infrazione_senza_documenti',
    sanz_acc_rimozione: 'sanz_acc_rimozione',
    infrazione_velocita: 'infrazione_velocita',
    infrazione_ztl: 'infrazione_ztl',
    infrazione_rosso: 'infrazione_rosso'
  });

  function run(VerbaliCommands) {
    VerbaliCommands.publish();
  }
})();