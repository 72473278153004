"use strict";

(function () {
  'use strict';

  angular.module('osBreadcrumb').component('osBreadcrumb', {
    templateUrl: 'osFramework/osBreadcrumb/osBreadcrumb.component.html',
    controller: 'osBreadcrumbController',
    controllerAs: 'osBreadcrumbCtrl'
  });
})();