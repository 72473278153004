"use strict";

var _api = require("../api");

var _object = require("../object.utils");

angular.module('polcity.verbali').component('verbaliList', {
  templateUrl: 'verbali/verbali.list.component.html',
  controller: 'VerbaliListController',
  controllerAs: 'VerbaliListController'
}).controller('VerbaliListController', ['$scope', 'modalPresenter', '$q', '$filter', 'AliasToApi', '$state', '$injector', 'NotificationTypes', 'uiEvents', 'Command', 'OSNotification', '$rootScope', 'Verbale', '$compile', '$stateParams', '$timeout', VerbaliListController]);

function VerbaliListController($scope, modalPresenter, $q, $filter, AliasToApi, $state, $injector, NotificationTypes, uiEvents, Command, OSNotification, $rootScope, Verbale, $compile, $stateParams, $timeout) {
  var vm = this;
  /**
   * Confiurazioni iniziali della collection Verbali
   */

  $scope.verbali = Verbale.$collection({
    page: 1,
    limit: 10,
    order: 'dataOraVerbale,desc'
  });
  $scope.verbali.$on('before-fetch-many', function (res) {
    $scope.isLoadingList = true;
  });
  $scope.verbali.$on('after-fetch-many', function (res) {
    $scope.isLoadingList = false;
    $scope.page = $scope.verbali.$metadata.search.page;
    $scope.limit = $scope.verbali.$metadata.search.limit;
    $scope.total = $scope.verbali.$metadata.total;
    $scope.filters = $scope.verbali.$getElasticFilters();
    $scope.activeFilters = $scope.verbali.$getActiveFilters();
    $scope.activeSorters = $scope.verbali.$getActiveSorters();
    $scope.verbali.$saveState();
  });
  $scope.verbali.$on('after-fetch-many-error', function (res) {
    $scope.isLoadingList = false; //TODO: do something with errors
  });

  vm.$onInit = function () {
    var state,
        params = {},
        selectedItem = null; //Ripristino lo stato precedente

    if ($stateParams.reloadPreviousState) {
      state = $scope.verbali.$getState();
      params = state.search || {};
      selectedItem = state.selectedItem || null;
      $scope.verbali.$initFilter(state.filters, state.elasticFilters);
    }

    $scope.verbali.$ricercaVeloce(params).$then(function () {
      if (selectedItem) {
        $scope.selectedVerbale = Verbale.$new(selectedItem.aggId);
      }
    });
  };

  const reloadVerbali = selectedVerbale => {
    $scope.verbali.$ricercaVeloce($scope.verbali.$metadata.search).$then(function () {
      if (selectedVerbale) {
        $scope.selectedVerbale = Verbale.$new(selectedVerbale.aggId);
      }
    });
  };
  /**
   * List configuration
   * @param params
   */


  $scope.verbaliSorters = {
    dataOraVerbale: {
      showEmptyOption: true,
      direction: null
    },
    targa: {
      showEmptyOption: true,
      direction: null
    },
    numeroRegistro: {
      showEmptyOption: true,
      direction: null
    }
  };
  $scope.enableFilters = true; //Handlers

  $scope.onChildTap = function (params) {
    $scope.verbali.$saveState();
  };

  $scope.onSortAdd = function (params) {};

  $scope.onRemoveItemSelection = function (params) {
    $scope.verbali.$saveState();
  };

  $scope.onSortRemove = function (params) {};

  $scope.onPageChange = function (params) {};

  $scope.onFilterAdd = function (params) {};

  $scope.onFilterRemove = function (params) {};

  $scope.onFilterReset = function () {};

  $scope.onToggleFollow = function (params) {
    var trIndex = params.item.preferito ? 'verbali.startedFollowing__notification' : 'verbali.stoppedFollowing__notification';
    OSNotification.info({
      translationIndex: trIndex,
      translationParam: {
        number: params.item.$numero
      }
    });
  }; //Serve a VerbaleListDetail


  vm.onToggleFollow = $scope.onToggleFollow;

  vm.archiviaVerbale = (verbale, data) => {
    (0, _api.archiviaVerbale)({
      aggId: verbale.aggId,
      dataOraArchiviazione: data.dataOraArchiviazione,
      motivazioneArchiviazioneId: data.motivazioneArchiviazione.aggId
    }).then(res => {
      if (res.error) {
        OSNotification.error({
          message: (0, _object.getPathArrayOrEmptyArray)(["error", "response", "data", "errors"])(res).map(error => error.message).join("\n")
        });
        return;
      }

      reloadVerbali(verbale);
      OSNotification.success({
        message: "Verbale archiviato"
      });
    }).catch(error => {
      OSNotification.error({
        message: error
      });
    });
  };

  vm.disarchiviaVerbale = verbale => {
    (0, _api.disarchiviaVerbale)({
      aggId: verbale.aggId
    }).then(res => {
      if (res.error) {
        OSNotification.error({
          message: res.message
        });
        return;
      }

      reloadVerbali(verbale);
      OSNotification.success({
        message: "Verbale disarchiviato"
      });
    }).catch(error => {
      OSNotification.error({
        message: error
      });
    });
  };

  vm.annullaVerbale = (verbale, data) => {
    (0, _api.annullaVerbale)({
      aggId: verbale.aggId,
      dataOraAnnulla: data.dataOraAnnullamento,
      motivazioneAnnullamento: data.motivazioneAnnullamento
    }).then(res => {
      if (res.error) {
        console.log(res);
        OSNotification.error({
          message: res.message
        });
        return;
      }

      reloadVerbali(verbale);
      OSNotification.success({
        message: "Verbale annullato"
      });
    }).catch(error => {
      OSNotification.error({
        message: error
      });
    });
  };

  vm.disannullaVerbale = verbale => {
    (0, _api.disannullaVerbale)({
      aggId: verbale.aggId
    }).then(res => {
      if (res.error) {
        OSNotification.error({
          message: (0, _object.getPathArrayOrEmptyArray)(["error", "response", "data", "errors"])(res).map(error => error.message).join("\n")
        });
        return;
      }

      reloadVerbali(verbale);
      OSNotification.success({
        message: "Verbale disannullato"
      });
    }).catch(error => {
      OSNotification.error({
        message: error
      });
    });
  };
  /**
   * Action crea ricorso
   * @param selectedVerbali
   */


  vm.creaRicorso = creaRicorso;

  function creaRicorso(selectedVerbali) {
    var verbali;
    /*var statiVerbaliNonValidiPerIlRicorso = ['Cancellato'], verbali;
     for( var i = 0; i < selectedVerbali.length; i++ ) {
        var verbale = selectedVerbali[i];
         if( R.contains(verbale.statoVerbale, statiVerbaliNonValidiPerIlRicorso) ) {
            OSNotification.info({
                translationIndex: 'verbali.cantMakeRicorso__notification'
            });
            return;
        }
    }*/

    verbali = selectedVerbali.map(function (verbale) {
      return verbale.$getModel();
    });
    $state.go('navigation.ricorso.edit', {
      verbali: verbali
    });
  }
  /*****************************
   * Eliminazione
   *****************************/

  /**
   * Action per l'eliminazione dei verbali
   * @param selectedVerbali
   */


  vm.eliminazioneMultipla = function (selectedVerbali) {
    vm.eliminaVerbali(selectedVerbali).then(function (results) {
      // notifica l'utente dopo aver eliminato tutti i verbali
      OSNotification.success({
        translationIndex: 'verbali.eliminazioneMultipla__success'
      });

      for (var i = 0; i < results.length; i++) {
        $scope.verbali.$remove(results[i]);
      }
    });
  };

  vm.eliminaVerbali = function (selectedVerbali) {
    var deferred = $q.defer();
    modalPresenter.warn([selectedVerbali.length > 1 ? 'verbali.bulkDeleteConfirm.title' : 'verbali.deleteConfirm.title', selectedVerbali.length > 1 ? 'verbali.bulkDeleteConfirm.content' : 'verbali.deleteConfirm.content', selectedVerbali.length > 1 ? 'verbali.bulkDeleteConfirm.confirm' : 'verbali.deleteConfirm.confirm', selectedVerbali.length > 1 ? 'verbali.bulkDeleteConfirm.cancel' : 'verbali.deleteConfirm.cancel']).then(function () {
      getEliminaVerbaliPromiseList(selectedVerbali).then(function (results) {
        deferred.resolve(results);
      }).catch(function (error) {
        deferred.reject(error);
      });
    }).catch(function () {
      deferred.reject();
    });
    return deferred.promise;
  };

  function getEliminaVerbaliPromiseList(verbali) {
    var promises = [];

    if (!verbali.length) {
      throw new Error('Missing parameter exception');
    }

    for (var i = 0; i < verbali.length; i++) {
      promises.push(verbali[i].$destroy({
        aggId: verbali[i].aggId
      }).$asPromise());
    }

    return $q.all(promises);
  }
  /*********************
   * Apis
   *********************/


  function cancellaVebale(params) {
    return Command.execute('@cancellaVebale', params);
  } /////////////////////////


  AliasToApi.add('stato.verbale', 'verbale/lista/stati-verbale');
}