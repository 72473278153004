"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _theme = _interopRequireDefault(require("../../../theme/theme"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const DataSectionContainer = (0, _core.withStyles)({
  root: {
    position: 'relative',
    padding: _theme.default.spacing(4),
    '&:nth-child(even)': {
      background: _theme.default.palette.grey[100]
    },
    '&:nth-child(odd)': {}
  }
})(_core.Box);
var _default = DataSectionContainer;
exports.default = _default;