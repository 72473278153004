"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHorizontalAutoScrollText = void 0;

var _makeStyles = _interopRequireDefault(require("@material-ui/core/styles/makeStyles"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const useHorizontalAutoScrollText = (0, _makeStyles.default)({
  root: {
    display: "inline-block",
    paddingRight: "10%",
    animation: `$autoScrollEffect 4.0s linear infinite`
  },
  "@keyframes autoScrollEffect": {
    from: {
      transform: "translateX(0)"
    },
    to: {
      transform: "translateX(-100%)"
    }
  }
});
exports.useHorizontalAutoScrollText = useHorizontalAutoScrollText;