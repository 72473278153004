"use strict";

angular.module('polcity.acquisizioneDati').component('rilevazioniList', {
  templateUrl: 'acquisizioneDati/rilevazioni/rilevazioniList.component.html',
  controller: 'rilevazioniListController'
}).controller('rilevazioniListController', ['$scope', 'Rilevazione', '$mdDialog', 'Command', 'OSNotification', 'modalPresenter', '$state', '$timeout', RilevazioniListController]);

function RilevazioniListController($scope, Rilevazione, $mdDialog, Command, OSNotification, modalPresenter, $state, $timeout) {
  var me = this;
  /**
   * Confiurazioni iniziali della collection rilevazioni
   */

  $scope.rilevazione = Rilevazione.$collection({
    page: 1,
    limit: 10
  });
  $scope.rilevazione.$on('before-fetch-many', function (res) {
    $scope.isLoadingList = true;
  });
  $scope.rilevazione.$on('after-fetch-many', function (res) {
    $scope.isLoadingList = false;
    $scope.page = $scope.rilevazione.$metadata.search.page;
    $scope.limit = $scope.rilevazione.$metadata.search.limit;
    $scope.total = $scope.rilevazione.$metadata.total;
    $scope.filters = $scope.rilevazione.$metadata.filters;
    $scope.activeFilters = $scope.rilevazione.$getActiveFilters();
    setChartData($scope.filters);
  });
  $scope.rilevazione.$on('after-fetch-many-error', function (res) {
    $scope.isLoadingList = false;
  });
  $scope.rilevazione.$on('after-get-collection-ids', function (res) {
    startProcessConfirmation(res);
  });
  $scope.rilevazione.$on('before-inizializza-processo-verbalizzazione', function (res) {
    $state.go('navigation.dashboard');
  });
  $scope.rilevazione.$on('after-inizializza-processo-verbalizzazione', function (res) {
    notifyUser('success', 'acquisizioneDati.rilevazioni.startInizializzazione__success', res.data.length);
  });
  $scope.$on('upload-new-rilevazioni-file', function () {
    // Ho messo questo timeout perchè elastic deve creare l'indice quindi ho bisogno di ritardare di un
    // istante l'aggiornamento della pagina
    $timeout(function () {
      $scope.rilevazione.$ricercaVeloce();
    }, 500);
  });

  me.$onInit = function () {
    $scope.validazioneRilevazioniRoles = ['ROLE_BPMN_ACCETTA_VALIDAZIONE', 'ROLE_VERBALIZZAZIONE_RIGETTA_VALIDAZIONE'];
    $scope.validazioneFinaleRilevazioniRoles = ['ROLE_VERBALIZZAZIONE_VERBALIZZA_RILEVAZIONE', 'ROLE_VERBALIZZAZIONE_RIGETTA_VALIDAZIONE_FINALE'];
    $scope.filters = [];
    $scope.activeFilters = [];
    setDefaultCounter();
    $scope.rilevazione.$ricercaVeloce();
  };
  /**
   * Filtri temporali
   */


  $scope.firstDateFilterLabel = 'Data acquisizione';
  $scope.firstDateFilterKey = 'dataCreazioneRilevazioneByRange';
  $scope.secondDateFilterLabel = 'Data rilevazione';
  $scope.secondDateFilterKey = 'dataRilevazioneInfrazioneByRange';

  $scope.addTimeRangeFilter = function (filterData) {
    addFilter({
      name: filterData.selectionType,
      value: filterData.timeRange
    });
  };
  /*******************************
   * osList configuration
   *******************************/


  $scope.elasticSearch = true;
  $scope.enableFilters = true;
  /*******************************
   * Charts configuration
   *******************************/

  $scope.tipoRilevatoreChartData = {
    options: {
      filterKey: 'tipoRilevatore',
      legend: {
        display: true,
        position: 'left'
      }
    },
    callBack: onChartClick
  };
  $scope.dataAcquisizioneChartData = {
    options: {
      filterKey: 'dataCreazioneRilevazioneByRange',
      legend: {
        display: false
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    },
    callBack: onChartClick
  };
  $scope.dataAcquisizioneRadarChartData = {
    options: {
      filterKey: 'milestone'
    }
  };
  $scope.dataRilevazioneChartData = {
    options: {
      filterKey: 'dataRilevazioneInfrazioneByRange'
    },
    callBack: onDateChartClick
  };

  function setChartData(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].name === 'tipoRilevatore') {
        setChartDataForTipoRilevatore(data[i].values);
      } else if (data[i].name === 'milestone') {
        setTotalData(data[i].values);
      } else if (data[i].name === 'dataCreazioneRilevazioneByRange') {
        setChartDataForDataAcquisizione(data[i].values);
      }
    }

    setChartDataForRadarChart();
  }

  function setChartDataForTipoRilevatore(data) {
    var tipoRilevatoreChartlabel = [];
    var tipoRilevatoreChartValues = [];
    R.forEach(function (o) {
      tipoRilevatoreChartlabel.push(o.value);
      tipoRilevatoreChartValues.push(o.count);
    }, data);
    $scope.tipoRilevatoreChartData = R.merge($scope.tipoRilevatoreChartData, {
      labels: tipoRilevatoreChartlabel,
      data: tipoRilevatoreChartValues
    });
    return $scope.tipoRilevatoreChartData;
  }

  function setChartDataForDataAcquisizione(data) {
    var dataAcquisizioneChartlabel = [],
        dataAcquisizioneChartValues = [];
    R.forEach(function (o) {
      dataAcquisizioneChartlabel.push(o.value);
      dataAcquisizioneChartValues.push(o.count);
    }, data);
    $scope.dataAcquisizioneChartData = R.merge($scope.dataAcquisizioneChartData, {
      labels: dataAcquisizioneChartlabel,
      data: dataAcquisizioneChartValues
    });
    return $scope.dataAcquisizioneChartData;
  }

  function setChartDataForRadarChart() {
    $scope.dataAcquisizioneRadarChartData.labels = ['Acquisite', 'Non inizializzate', 'Da validare', 'Scartate in validazione', 'Da verbalizzare', 'Scartate in verbalizzazione', 'Verbali creati'];
    $scope.dataAcquisizioneRadarChartData.data = [[$scope.rilevazioniAcquisiteCount, $scope.rilevazioniFallitoStartProcessCount, $scope.rilevazioniInAttesaValidazioneCount, $scope.rilevazioniRigettatePrimaValidazioneCount, $scope.rilevazioniInAttesaValidazioneFinaleCount, $scope.rilevazioniRigettateValidazioneFinaleCount, $scope.rilevazioniVerbalizzateCount]];
  }

  function setTotalData(data) {
    setDefaultCounter();

    for (var i = 0; i < data.length; i++) {
      if (data[i].value === 'rilevazione_acquisita') {
        $scope.rilevazioniAcquisiteCount = data[i].count;
      } else if (data[i].value === 'in_attesa_validazione_immagini') {
        $scope.rilevazioniInAttesaValidazioneCount = data[i].count;
      } else if (data[i].value === 'in_attesa_validazione_finale') {
        $scope.rilevazioniInAttesaValidazioneFinaleCount = data[i].count;
      } else if (data[i].value === 'scartato') {
        $scope.rilevazioniRigettatePrimaValidazioneCount = data[i].count;
      } else if (data[i].value === 'verbalizzato') {
        $scope.rilevazioniVerbalizzateCount = data[i].count;
      } else if (data[i].value === 'validazione_finale_rigettata') {
        $scope.rilevazioniRigettateValidazioneFinaleCount = data[i].count;
      } else if (data[i].value === 'fallito_start_processo') {
        $scope.rilevazioniFallitoStartProcessCount = data[i].count;
      }
    }
  }

  function setDefaultCounter() {
    $scope.rilevazioniAcquisiteCount = 0;
    $scope.rilevazioniInAttesaValidazioneCount = 0;
    $scope.rilevazioniInAttesaValidazioneFinaleCount = 0;
    $scope.rilevazioniRigettatePrimaValidazioneCount = 0;
    $scope.rilevazioniVerbalizzateCount = 0;
    $scope.rilevazioniRigettateValidazioneFinaleCount = 0;
    $scope.rilevazioniFallitoStartProcessCount = 0;
  }

  function onChartClick(element, evt) {
    var filtro = {};

    if (!element.length) {
      return;
    } // selezionando un valore dal grafico devo ricostruire il filtro da passare al model per aggiungerlo
    // alla lista


    filtro.name = element[0]._chart.options.filterKey;

    if (filtro.name === 'dataCreazioneRilevazioneByRange') {
      filtro.value = calculateTimeRange(element);
    } else {
      filtro.value = element[0]._model.label || element[0]._scale.pointLabels[element[0]._index];
    } // filtro.value = element[0]._chart.config.data.datasets[0].data[element[0]._index];


    addFilter(filtro);
  }

  function onDateChartClick(element, evt) {
    onChartClick(element, evt);
  }
  /**
   * Filters management
   */


  function addFilter(filtro) {
    if (filterAlreadyInPlace(filtro)) {
      return;
    }

    $scope.rilevazione.$addFilter(filtro);
  }

  $scope.removeFilter = removeFilter;

  function removeFilter(filtro) {
    $scope.rilevazione.$removeFilter(filtro.name);
  }

  $scope.resetFilter = function () {
    $scope.rilevazione.$resetFilters();
    $scope.$broadcast('reset-date-range');
  };

  function filterAlreadyInPlace(filtro) {
    var activeFilter = $scope.rilevazione.$getActiveFilters(),
        filterIsPresent = false;

    for (var f of activeFilter) {
      if (f.value === filtro.value) {
        filterIsPresent = true;
      } // se ho già un filtro dello stesso tipo devo togliere quello "vecchio" dall'array dei filtri attivi
      // ma solo se ha un valore diverso (click sullo stesso filtro)


      if (f.name === filtro.name && f.value !== filtro.value) {
        removeFilter(f);
      }

      if (f.name === 'dataCreazioneRilevazioneByRange' && filtro.name === 'dataRilevazioneInfrazioneByRange' || f.name === 'dataRilevazioneInfrazioneByRange' && filtro.name === 'dataCreazioneRilevazioneByRange') {
        removeFilter(f);
      }
    }

    return filterIsPresent;
  }
  /**
   * Utils
   */


  function startProcessConfirmation(res) {
    askUserConfirm('info', 'acquisizioneDati.rilevazioni.startProcessAcquisizioneRilevazioneModal__title', 'acquisizioneDati.rilevazioni.startProcessAcquisizioneRilevazioneModal__body', 'acquisizioneDati.rilevazioni.startProcessAcquisizioneRilevazioneModal__confirm_button').then(startProcess.bind(me)).catch(function (error) {
      notifyUser.bind('error', 'acquisizioneDati.rilevazioni.startInizializzazione__error');
    });
  }

  function startProcess() {
    $scope.rilevazione.$inizializzaProcessoVerbalizzazione();
  }

  function notifyUser(type, msg, data) {
    OSNotification[type]({
      translationIndex: msg,
      translationParam: {
        total: data
      }
    });
  }

  function askUserConfirm(type, title, body, confirmBtn) {
    return modalPresenter[type]([title, body, confirmBtn, 'acquisizioneDati.rilevazioni.cancellaRilevazioneModal__undo_button']);
  }

  function calculateTimeRange(element) {
    var timeRange = [],
        date = element[0]._model.label,
        dateMonth = date.substring(0, 2),
        dateYear = date.substring(3, date.length),
        startDate = moment([dateYear, dateMonth - 1]),
        endDate = moment(startDate).endOf('month');
    startDate = moment(startDate).valueOf();
    endDate = moment(endDate).valueOf();
    timeRange.push(startDate);
    timeRange.push(endDate);
    return timeRange;
  }
  /**
   * Handlers
   */


  $scope.onInizializzaValidazioneClick = function () {
    $scope.rilevazione.$getCollectionIds({
      milestone: 'rilevazione_acquisita'
    });
  };

  var scartateAdditionalColumn = [{
    text: 'Causale',
    dataIndex: 'Causale',
    renderer: function (item) {
      return item.causale || '--';
    }
  }],
      verbalizzatoAdditionalColumn = [{
    text: 'Numero verbale',
    dataIndex: 'numero',
    renderer: function (item) {
      return item.numero || '--';
    }
  }, {
    text: 'Numero protocollo',
    dataIndex: 'numeroProtocollo',
    renderer: function (item) {
      return item.numeroProtocollo || '--';
    }
  }, {
    text: 'Targa',
    dataIndex: 'targa',
    renderer: function (item) {
      return item.targa ? item.targa : '--';
    }
  }]; // Lista rilevazioni modal

  $scope.onOpenListaRilevazioniModal = function (milestone, bulkActions, redirectToVerbale, event) {
    var filter = {
      milestone: milestone
    },
        data = $scope.rilevazione.$ricercaVeloce(filter),
        showBulkActions = bulkActions,
        showRedirectToVerbaleBtn = redirectToVerbale,
        addColumn = null;

    if (milestone === 'scartato' || milestone === 'validazione_finale_rigettata') {
      addColumn = scartateAdditionalColumn;
    } else if (milestone === 'verbalizzato') {
      addColumn = verbalizzatoAdditionalColumn;
    }

    $mdDialog.show({
      controller: 'ListaRilevazioniModalController',
      controllerAs: 'me',
      templateUrl: 'acquisizioneDati/rilevazioni/listaRilevazioni/listaRilevazioni.component.html',
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      fullscreen: false,
      locals: {
        rilevazioni: data,
        showBulkActions: showBulkActions,
        showRedirectToVerbaleBtn: showRedirectToVerbaleBtn,
        additionalColumn: addColumn
      }
    }).finally(function () {
      $scope.rilevazione.$ricercaVeloce({
        milestone: null
      });
    });
  }; // Validazione dati


  $scope.onOpenValidazioneRilevazioniModal = function (milestone, ema) {
    var filter = {
      milestone: milestone
    };
    $scope.rilevazione.$ricercaVeloce(filter).$then(function (res) {
      var itemsDaValidare = res;

      if (milestone === 'in_attesa_validazione_immagini') {
        $state.go('navigation.acquisizioneDati.validazioneRilevazioni', {
          milestone: milestone,
          rilevazioniDaValidare: itemsDaValidare
        });
      } else if (milestone === 'in_attesa_validazione_finale') {
        $state.go('navigation.acquisizioneDati.validazioneFinaleRilevazioni', {
          milestone: milestone,
          rilevazioniDaValidare: itemsDaValidare
        });
      }
    });
  };
}