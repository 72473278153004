"use strict";

angular.module('osFramework').directive('ricercaStazioneComando', [RicercaStazioneComando]);

function RicercaStazioneComando() {
  return {
    restrict: 'E',
    require: ['ngModel'],
    scope: {
      entity: '=?',
      label: '@?',
      onStazioneComandoChange: '<?',
      isRequired: '<?',
      name: '@?'
    },
    controller: ['$scope', '$filter', controllerFn],
    controllerAs: 'ricercaStazioneComandoCtrl',
    templateUrl: 'osFramework/input/ricercaStazioneComando/ricercaStazioneComando.directive.html',
    link: linkFn
  };

  function controllerFn(scope, $filter) {
    var me = this;
    me.groupRoles = ['notApplyRoleManagement', 'notApplyRoleManagement'];

    me.getItemText = function (stazioneComando) {
      var itemText;
      itemText = stazioneComando.codiceUnivoco + ' - ' + stazioneComando.riferimentoComuneNome + ' ' + stazioneComando.indirizzo + ' ' + stazioneComando.descrizione;
      return itemText;
    };
  }

  function linkFn(scope, element, attrs, controllers) {
    var ngModelCtrl = controllers[0];

    ngModelCtrl.$render = function () {
      var viewValue = ngModelCtrl.$viewValue;

      if (!viewValue) {
        scope.aggId = null;
        return;
      }

      scope.aggId = viewValue;
    };

    scope.$watch('aggId', function (newVal) {
      ngModelCtrl.$setViewValue(newVal);
    });
  }
}