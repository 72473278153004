"use strict";

var _Dashboard = _interopRequireDefault(require("./Dashboard"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Created by dan on 19/12/16.
 */
(function () {
  'use strict';

  angular.module('polcity.dashboard').provider('dashboardRoutes', ['routesProviderFactoryProvider', dashboardRoutes]);

  function dashboardRoutes(routesProviderFactory) {
    this.routes = [{
      name: 'dashboardAbstract',
      abstract: true,
      url: 'dashboard',
      templateUrl: 'dashboard/dashboard.html'
    }, {
      name: 'dashboard',
      url: 'dashboard',
      isHome: true,
      params: {
        reloadPreviousState: false
      },
      breadcrumbs: {
        isBase: false,
        reloadPreviousState: false
      },
      views: {
        $default: {
          component: _Dashboard.default
        }
      },
      resolve: {
        $label: function () {
          return 'Dashboard';
        }
      }
    }];
    routesProviderFactory.extend(this);
  }
})();