"use strict";

var _ArticoloNormativaEditRoot = _interopRequireDefault(require("./edit/ArticoloNormativaEditRoot"));

var _NuovoArticoloNormativaRoot = _interopRequireDefault(require("./nuovo/NuovoArticoloNormativaRoot"));

var _ArticoliNormativeMasterDetail = _interopRequireDefault(require("./masterDetail/ArticoliNormativeMasterDetail"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

angular.module('polcity.articoliCds').provider('articoliCdsRoutes', ['routesProviderFactoryProvider', routeProvider]);

function routeProvider(routesProviderFactory) {
  this.routes = [{
    name: 'articoliNormativeList',
    role: 'ROLE_ARTICOLO_CDS_RICERCA',
    url: 'articoliNormative?{page:int}&{limit:int}&{query:string}&{activeFilters:string}&{activeSorters:string}&{open:bool}&{activeItem:string}',
    breadcrumbs: {
      isBase: true,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ArticoliNormativeMasterDetail.default
      }
    },
    resolve: {
      $label: () => "Articoli e normative"
    }
  }, {
    name: 'articoliNormativeEdit',
    url: 'articoliNormative/{aggId:[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}}',
    //uuid regex
    role: 'ROLE_ARTICOLO_CDS_MODIFICA',
    params: {
      aggId: null
    },
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _ArticoloNormativaEditRoot.default
      }
    },
    resolve: {
      $label: () => "Modifica articolo / normativa"
    }
  }, {
    name: 'articoliCdsNew',
    url: 'articoliNormative/nuovo',
    role: 'ROLE_ARTICOLO_CDS_AGGIUNGI',
    breadcrumbs: {
      isBase: false,
      reloadPreviousState: true
    },
    views: {
      $default: {
        component: _NuovoArticoloNormativaRoot.default
      }
    },
    resolve: {
      $label: () => "Nuovo articolo / normativa"
    }
  }];
  routesProviderFactory.extend(this);
}