"use strict";

/**
 * Created by MN 10/12/2019
 */
(function () {
  'use strict';

  angular.module('polcity.osContabilita').component('bilancio', {
    templateUrl: 'osContabilita/bilancio/bilancio.component.html',
    controller: 'BilancioController',
    controllerAs: 'bilancioCtrl'
  });
})();