"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMasterDetailState = exports._masterDetailState = exports._activeItem = exports._selectionActive = exports._open = void 0;

var _react = require("react");

var _recoil = require("recoil");

var _utils = require("../../utils");

var _localStorageEffect = _interopRequireDefault(require("./effects/localStorageEffect"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const _open = (0, _recoil.atomFamily)({
  key: '_open',
  default: false
});

exports._open = _open;

const _selectionActive = (0, _recoil.atomFamily)({
  key: 'selectionActive',
  default: false
});

exports._selectionActive = _selectionActive;

const _activeItem = (0, _recoil.atomFamily)({
  key: 'activeItem',
  default: ""
});

exports._activeItem = _activeItem;

const _masterDetailState = (0, _recoil.atomFamily)({
  key: '_masterDetailState',
  default: {
    open: false,
    selectionActive: false,
    activeItem: ""
  },
  effects_UNSTABLE: ({
    name,
    persist
  }) => [...(0, _utils.insertIf)(persist, (0, _localStorageEffect.default)(name))]
});

exports._masterDetailState = _masterDetailState;

const getNs = name => `pc-md-${name}`; // Useful hook to store the master detail state on Recoil in a controlled way


const useMasterDetailState = ({
  name,
  open: openProp,
  activeItem: activeItemProp,
  selectionActive: selectionActiveProp
}) => {
  const [{
    open,
    activeItem,
    selectionActive
  }, setMasterDetailState] = (0, _recoil.useRecoilState)(_masterDetailState({
    name: getNs(name),
    persist: true
  })); //Controlled props

  (0, _react.useEffect)(() => {
    if (openProp === true || openProp === false) setMasterDetailState(state => ({ ...state,
      open: openProp
    }));
  }, [openProp, setMasterDetailState]);
  (0, _react.useEffect)(() => {
    if (selectionActiveProp === true || selectionActiveProp === false) setMasterDetailState(state => ({ ...state,
      selectionActive: selectionActiveProp
    }));
  }, [selectionActiveProp, setMasterDetailState]);
  (0, _react.useEffect)(() => {
    if (activeItemProp) setMasterDetailState(state => ({ ...state,
      activeItem: activeItemProp
    }));
  }, [activeItemProp, setMasterDetailState]);
  return {
    open,
    selectionActive,
    activeItem,
    setState: (0, _recoil.useRecoilCallback)(({
      set
    }) => ({
      prop,
      value
    }) => {
      setMasterDetailState(state => ({ ...state,
        [prop]: value
      }));
    }),
    reset: (0, _recoil.useRecoilCallback)(({
      set
    }) => _ => {
      setMasterDetailState({
        open: false,
        selectionActive: false,
        activeItem: ""
      });
    })
  };
};

exports.useMasterDetailState = useMasterDetailState;