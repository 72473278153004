"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _formik = require("formik");

var _utils = require("../../../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const RilevatoreInfrazioniEffects = () => {
  const {
    values,
    touched,
    setValues,
    setFieldTouched,
    isSubmitting
  } = (0, _formik.useFormikContext)();
  (0, _react.useEffect)(() => {
    if (touched.tipoRilevatore && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        modello: "",
        postazione: "",
        tipoAccesso: "",
        matricola: "",
        tolleranza: "",
        scarto: "",
        numeroVarco: ""
      }));
      setFieldTouched("tipoRilevatore", false);
    }
  }, [values.tipoRilevatore]);
  (0, _react.useEffect)(() => {
    if (touched.comune && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        strada1: null,
        civico1: "",
        kilometro: "",
        direzione1: null,
        posizioneRelativa: "",
        strada2: null,
        civico2: "",
        direzione2: null
      }));
      setFieldTouched("comune", false);
    }
  }, [values.comune]);
  (0, _react.useEffect)(() => {
    if (touched.strada1 && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        civico1: "",
        kilometro: "",
        direzione1: null,
        posizioneRelativa: "",
        strada2: null,
        civico2: "",
        direzione2: null
      }));
      setFieldTouched("strada1", false);
    }
  }, [values.strada1]);
  (0, _react.useEffect)(() => {
    if ((touched.posizioneRelativa || !values.posizioneRelativa) && !isSubmitting) {
      setValues((0, _utils.deepmerge)(values, {
        strada2: null,
        civico2: "",
        direzione2: null
      }));
      setFieldTouched("posizioneRelativa", false);
    }
  }, [values.posizioneRelativa]);
  return null;
};

var _default = RilevatoreInfrazioniEffects;
exports.default = _default;