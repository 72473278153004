"use strict";

/**
 * Created by MN 15/11/2018
 */
(function () {
  'use strict';

  angular.module('polcity.visure', []).config(['$translatePartialLoaderProvider', function ($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('visure');
  }]);
})();