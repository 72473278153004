"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verbaleValidationSchema = void 0;

var Yup = _interopRequireWildcard(require("yup"));

var _utils = require("../utils");

var _startOfDay = _interopRequireDefault(require("date-fns/startOfDay"));

var _isValid = _interopRequireDefault(require("date-fns/isValid"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const numeroAccertamentoMaxNumber = window._env_.NUMERO_ACCERTAMENTO_MAX_NUMBER || 999999;
const required = "Questo campo è obbligatorio";
const maxNumber = "Numero massimo";
const minNumber = "Numero minimo";
const minInfrazioni = "Inserisci almeno 1 infrazione";
const minModelliStampa = "Inserisci almeno 1 modello di stampa";
const infrazioniMalformate = "Si è verificato un problema interno, impossibile proseguire. Contatta il supporto tecnico";
const verbaleValidationSchema = Yup.object().shape({
  aggId: Yup.string().nullable(),
  numero: Yup.number().min(1, `${minNumber} 1`).max(numeroAccertamentoMaxNumber, `${maxNumber} ${numeroAccertamentoMaxNumber}`).required(required),
  tipoAccertamento: Yup.object().required(required).nullable(),
  bollettaIsFree: Yup.boolean().oneOf([true], "Bolletta già in uso").nullable(),
  dataOraVerbale: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(required).nullable().typeError('Data non valida'),
  dataOraVerbaleEqualsDataOraViolazione: Yup.boolean(),
  dataOraVerbalizzazione: Yup.date().when(["dataOraVerbale", "dataOraVerbaleEqualsDataOraViolazione"], {
    is: (dataOraVerbale, dataOraVerbaleEqualsDataOraViolazione) => !dataOraVerbaleEqualsDataOraViolazione || !dataOraVerbaleEqualsDataOraViolazione && (0, _isValid.default)(dataOraVerbale),
    then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').min(Yup.ref('dataOraVerbale'), "Data/ora inferiore a data/ora violazione").required(required).nullable().typeError('Data non valida'),
    otherwise: Yup.date().nullable()
  }),
  luogoAccertamento: Yup.object().shape({
    luogoAccertamentoComune: Yup.object().required(required).nullable(),
    luogoAccertamentoStrada: Yup.object().required(required).nullable()
  }),
  tipoRelazioneStrada: Yup.object().shape({
    luogoAccertamentoTipoRelazioneStradaTipo: Yup.string(),
    luogoAccertamentoTipoRelazioneStradaStrada: Yup.object().when("luogoAccertamentoTipoRelazioneStradaTipo", {
      is: value => value && (value === 'intersezione' || value === 'prossimita'),
      then: Yup.object().required(required).nullable(),
      otherwise: Yup.object().nullable()
    }),
    luogoAccertamentoTipoRelazioneDescrizioneFronte: Yup.string().when("luogoAccertamentoTipoRelazioneStradaTipo", {
      is: value => value && (value === 'fronte' || value === 'corrispondenza'),
      then: Yup.string().required(required),
      otherwise: Yup.string()
    })
  }),
  trasgressore: Yup.object().when(["aggId", 'dataOraVerbale'], (aggId, dataOraVerbale) => {
    const _dataOraVerbale = (0, _isValid.default)(dataOraVerbale) ? (0, _startOfDay.default)(dataOraVerbale) : _utils.todayStartDate;

    return Yup.object().shape({
      trasgressoreTipo: Yup.string().when('trasgressore', {
        is: value => !!value,
        then: Yup.string().required("Campo obbligatorio quando è presente un trasgressore"),
        otherwise: Yup.string().nullable()
      }),
      trasgressoreDataNotifica: Yup.date().when(["trasgressore", "notificatoTrasgressore"], {
        is: (trasgressore, notificatoTrasgressore) => !Boolean(aggId) && Boolean(trasgressore) && Boolean(notificatoTrasgressore),
        then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').min(_dataOraVerbale, "Data inferiore a data violazione").required(required).nullable().typeError('Data non valida'),
        otherwise: Yup.date().nullable()
      })
    }).nullable();
  }).nullable(),
  documentiPresentati: Yup.array().of(Yup.object().shape({
    dataPresentazione: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').required(required).nullable().typeError('Data non valida')
  })),
  pedone: Yup.boolean(),
  veicolo: Yup.object().when('pedone', {
    is: true,
    then: Yup.object(),
    otherwise: Yup.object().shape({
      veicoloTipo: Yup.object().required(required).nullable(),
      veicoloStato: Yup.object().when("veicoloTipo", {
        is: value => value && value.nonTargato === false,
        then: Yup.object().required(required).nullable(),
        otherwise: Yup.object().nullable()
      }),
      veicoloTarga: Yup.string().when("veicoloTipo", {
        is: value => value && value.nonTargato === false,
        then: Yup.string().required(required).nullable()
      })
    })
  }),
  rimorchio: Yup.object().shape({
    veicoloRimorchioPresente: Yup.boolean(),
    veicoloRimorchioTarga: Yup.string().when("veicoloRimorchioPresente", {
      is: value => value === true,
      then: Yup.string().required(required),
      otherwise: Yup.string()
    })
  }),
  obbligato: Yup.object().when(["aggId", 'dataOraVerbale'], (aggId, dataOraVerbale) => {
    const _dataOraVerbale = (0, _isValid.default)(dataOraVerbale) ? (0, _startOfDay.default)(dataOraVerbale) : _utils.todayStartDate;

    return Yup.object().shape({
      obbligatoTipo: Yup.string().when('obbligato', {
        is: value => !!value,
        then: Yup.string().required("Campo obbligatorio quando è presente un obbligato"),
        otherwise: Yup.string().nullable()
      }),
      obbligatoDataNotifica: Yup.date().when(["obbligato", "notificatoObbligato"], {
        is: (obbligato, notificatoObbligato) => !Boolean(aggId) && Boolean(obbligato) && Boolean(notificatoObbligato),
        then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').min(_dataOraVerbale, "Data inferiore a data violazione").required(required).nullable().typeError('Data non valida'),
        otherwise: Yup.date().nullable()
      })
    }).nullable();
  }).nullable(),
  infrazioni: Yup.array().min(1, minInfrazioni).nullable().of(Yup.object({
    decoratori: Yup.object() // https://dev.azure.com/opensoftwaresrl/PolcityKube/_workitems/edit/234/
    .test("decoratori", infrazioniMalformate, (value, context) => {
      if (!value.decoratoriIds.hasOwnProperty("infrazione_velocita") || !value.decoratoriIds.hasOwnProperty("infrazione_rosso") || !value.decoratoriIds.hasOwnProperty("infrazione_senza_documenti") || !value.decoratoriIds.hasOwnProperty("infrazione_ztl") || !value.decoratoriIds.hasOwnProperty("sanz_acc_fermo_sequestro") || !value.decoratoriIds.hasOwnProperty("sanz_acc_rimozione")) {
        return context.createError({
          path: 'infrazioni',
          message: infrazioniMalformate
        });
      }

      return true;
    })
  })),
  sanzioni: Yup.object().shape({
    rimozione: Yup.object().shape({
      rimozioneDataOra: Yup.date().when('applicabile', {
        is: value => Boolean(value),
        then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').nullable().typeError('Data non valida'),
        otherwise: Yup.date().nullable()
      }),
      rimozioneAccertataIl: Yup.date().when('applicabile', {
        is: value => Boolean(value),
        then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').nullable().typeError('Data non valida'),
        otherwise: Yup.date().nullable()
      })
    }).nullable(),
    fermoSequestro: Yup.object().shape({
      dataOperazione: Yup.date().when('applicabile', {
        is: value => Boolean(value),
        then: Yup.date().max(_utils.todayEndDay, 'Date future non ammesse').nullable().typeError('Data non valida'),
        otherwise: Yup.date().nullable()
      }),
      dataScadenza: Yup.date().when('applicabile', {
        is: value => Boolean(value),
        then: Yup.date().min(Yup.ref('dataOperazione'), "Data Scadenza inferiore a Data Operazione").nullable().typeError('Data non valida'),
        otherwise: Yup.date().nullable()
      })
    }).nullable()
  }),
  modelloStampa: Yup.array().min(1, minModelliStampa).nullable()
});
exports.verbaleValidationSchema = verbaleValidationSchema;