"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lingueListColumns = void 0;

var _DataGridColumnTypes = require("../../../react/components/ApiRefGrid/DataGridColumnTypes");

const lingueListColumns = [{
  field: 'languageTag',
  headerName: 'Language tag',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'descrizione',
  headerName: 'Descrizione',
  flex: 1,
  disableColumnMenu: true,
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}, {
  field: 'attiva',
  headerName: 'Traduci in questa lingua',
  flex: 1,
  disableColumnMenu: true,
  valueGetter: ({
    value
  }) => value === true ? 'SI' : 'NO',
  ..._DataGridColumnTypes.dataGridAutoScrollLongTextColumn
}];
exports.lingueListColumns = lingueListColumns;